import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
`

export const Bar = styled.div`
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 auto;
`

export const BarHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    align-items: center;
    align-content: flex-start;
    min-height: 32px;
`

export const Spacer = styled.div`
    flex: 1 1 auto;
`

export const BarTitle = styled.strong`
    flex-grow: 1;
`

export const TaskList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-left: 16px;
    padding-right: 8px;
`