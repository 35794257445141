import { connect } from 'react-redux'
import { AddDocumentCategoryComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddDocumentCategory: (dialogName, categoryName) => dispatch(sendPayloadRequest(dialogName, {categoryName}))
    }
}

export const AddDocumentCategory = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDocumentCategoryComponent)