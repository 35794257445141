import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {EnterUserDataContent} from './contents/enter_userdata'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { CreateUserDialogLayout } from '../_common/layout'
import { GroupSearchSelect } from 'application/components/building_blocks/group_search_select'
import {DIALOG} from 'application/constants'
import { withCreateUserSteps } from '../_common/with_create_user_steps'
import { groupHelpers } from 'application/common'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'



const CreateUser = withCreateUserSteps()((
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onCreateUser, 
        dialog,
        goToCresteUserNextStep,
        goToCresteUserPreviousStep,
        getCreateUserCurrentStep,
        getCreateUserStepsState,
        allBranches
    }
) => {
    const { t } = useTranslation()
    const [userFormData, setUserFormData] = useState(null)
    const [invitation, setInvitation] = useState(true)
    const [selectedGroups, setSelectedGroups] = useState([])
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const handleGoingBack = () => {
        goToCresteUserPreviousStep()
    }

    const onUserCreation = () => {
        const payload = {
            user: userFormData,
            groups: selectedGroups
        }
        const accessibleBranches = groupHelpers.getAvailableBranchesFromGroups(
            selectedGroups, 
            allBranches
        )
        const metaData = {
            dialogToOpen: {
                name: DIALOG.NAME.ASSIGN_PREFERRED_BRANCH,
                title: 'Assign preferred branch'
            },
            dialogToClose: {
                name: dialog.name
            },
            accessibleBranches,
            invitation
        }
        onCreateUser(
            dialog.name,
            payload, 
            metaData
        )
    }

    return  <React.Fragment>
        {
            getCreateUserCurrentStep().id === 0 && 
                <EnterUserDataContent
                    defaultValues={userFormData !== null && userFormData}
                    dialog = {dialog}                    
                    onDataChange= {onDataChange}
                    abort= {abort}
                    setUserFormData= {setUserFormData}

                    getStepsState= {getCreateUserStepsState}
                    goToNextStep= {goToCresteUserNextStep}

                    setFirstName={setFirstName}
                    setLastName={setLastName}

                    firstName={firstName}
                    lastName={lastName}

                    invitation={invitation}
                    setInvitation={setInvitation}
                />
        }
        
        {
            getCreateUserCurrentStep().id === 1 &&            
                <CreateUserDialogLayout
                    getStepsState={getCreateUserStepsState}
                    firstName={firstName}
                    lastName={lastName}
                    form = {
                        <GroupSearchSelect
                            onSelectedGroupsChange={setSelectedGroups}
                        />
                    }
                    footerElements={
                        <React.Fragment>
                            <IconButtonWithLabel
                                iconKey='arrow_back' 
                                label={t('Back')} 
                                onButtonClick={handleGoingBack} 
                            />
                            <ActionButton
                                buttonText={t('Create user')} 
                                onButtonClick={onUserCreation}
                                disabled={
                                    userFormData !== null 
                                && selectedGroups.length < 1 
                                }
                            />
                        </React.Fragment>
                    }
                />
        }
    </React.Fragment> 
})

export const CreateUserComponent = withDialogDataLoader(CreateUser)