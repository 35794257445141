import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { StyleButtonToggle, StyleDisplayToggle, StyledToggleText, StyledWrapperSubCategory, StyledAttachmentsContainer, StyledToggleTextSecondary } from './fragments'
import {FileButton} from 'application/components/controls/file_button'
import { useDocumentsByCategories } from 'application/components/hooks/documents_by_categories/use_documents_by_categories'



const GalleryTreeComponent = (
    { 
        category, 
        theme, 
        onAttachmentClick, 
        damageId, 
        selectedAttachmentId 
    }
) => {
    const {
        documentsByCategory
    } = useDocumentsByCategories(category.id)

    const amountOfDocuments = documentsByCategory.length

    const hasChildren = 
    category.categories !== undefined 
    && category?.categories.length > 0

    const hasContent = amountOfDocuments > 0
    const [isOpen, setIsOpen] = useState(hasContent || hasChildren)

    return <StyledWrapperSubCategory>
        <StyleButtonToggle onClick={() => setIsOpen(!isOpen)}>
            <FontIcon 
                icon={isOpen 
                    ? 'expand_more' 
                    : 'chevron_right'
                } 
                fontSize={theme.fontSize.medium} 
                color={theme.color.gray70} 
            />
            <StyledToggleText>
                {category.name} {hasContent && <StyledToggleTextSecondary>({amountOfDocuments})</StyledToggleTextSecondary>} 
            </StyledToggleText>
        </StyleButtonToggle>
        <StyleDisplayToggle active={isOpen}>
            {
                hasContent && <StyledAttachmentsContainer>
                    {
                        documentsByCategory.map((ad) => {
                            return <FileButton 
                                key={ad.id}
                                isClickable={true}
                                isActive={selectedAttachmentId === ad.id}  
                                onClick={() => {onAttachmentClick({id: ad.id, fileName: ad.name})}}
                                fileName={ad.name}
                            />
                        })
                    }
                </StyledAttachmentsContainer>
            }
            {
                hasChildren && category.categories.map(sc => {
                    return <GalleryTreeComponent
                        key={sc.id}
                        category={sc}
                        theme={theme}
                        onAttachmentClick={onAttachmentClick}
                        damageId={damageId}
                        selectedAttachmentId={selectedAttachmentId}
                    />
                })
            }
        </StyleDisplayToggle>
    </StyledWrapperSubCategory>
}

export const GalleryTree =  withTheme(GalleryTreeComponent)