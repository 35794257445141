import { WIDGET_TYPES } from 'application/constants'

export const KEYS = {
    WORKFLOW_PANEL_FORM_REPAIR_AUTHORIZATION_DISPLAY: 'WORKFLOW_PANEL_FORM_REPAIR_AUTHORIZATION_DISPLAY',
    WORKFLOW_PANEL_FORM_INVOICES: 'WORKFLOW_PANEL_FORM_INVOICES'
}

export const widgetsWorkflowPanelFormSidebar = [
    {
        key: KEYS.WORKFLOW_PANEL_FORM_REPAIR_AUTHORIZATION_DISPLAY,
        type: WIDGET_TYPES.DAMAGE_REPAIR_AUTHORIZATION_DISPLAY,
        title: 'Repair authorization',
        hideHeadline: true
    },
    {
        key: KEYS.WORKFLOW_PANEL_FORM_INVOICES,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES,
        title: 'Invoices',
        hideHeadline: true
    }
]