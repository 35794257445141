import React, { useEffect, useMemo } from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import {TaskDetailsWorkflowPanelComponent} from './component'



const TaskDetailsWorkflowPanelEffectsComponent = (props) => {
    const {
        onLoadTask, 
        selectedTaskLoading, 
        selectedTaskLoaded, 
        task,
        hasTaskList, 
        contextLoaded,
        onRegisterWidgetContext, 
        match
    } = props
    const { sharedResourceId, taskId } = match.params
    const currentTaskId = task !== null ? task.id : null
    
    const widgetList = useMemo(()=>{
        return task?.formConfiguration?.components !== undefined ? task.formConfiguration.components.sort((a, b) => a.index - b.index).map(w=>({
            ...w,
            key: `${w.type}_${task.id}`,
            title: `${w.type}_${task.id}`, //empty later
            hideHeadline: true,
            initialPayload: {
                taskId: task.id,
                sharedResourceId: sharedResourceId,
                damageId: task.damageId,
            }
        })) : []
    },[sharedResourceId, task])

    // useEffect(() => {
    //     taskLoadedAndNotFound && onTaskNotFound()
    // }, [
    //     taskLoadedAndNotFound,
    //     onTaskNotFound
    // ])

    useEffect(() => {
        taskId && (!selectedTaskLoaded || taskId !== currentTaskId) && !selectedTaskLoading && taskId !== '' && hasTaskList && onLoadTask(taskId, sharedResourceId)
    }, [hasTaskList, match.url, onLoadTask, sharedResourceId, selectedTaskLoaded, selectedTaskLoading, taskId, currentTaskId])

    useEffect(() => {
        selectedTaskLoaded && !contextLoaded && taskId !== '' && onRegisterWidgetContext(taskId, widgetList) //just use the taskId as context
    }, [contextLoaded, onRegisterWidgetContext, selectedTaskLoaded, taskId, widgetList])

    return <TaskDetailsWorkflowPanelComponent {...props} taskId={taskId} sharedResourceId={sharedResourceId}/>
}

export const TaskDetailsWorkflowPanelEffects =  withRouter(TaskDetailsWorkflowPanelEffectsComponent)