import { useEffect } from 'react'
import { defaultUserManager } from 'application/common/user_manager'

export const SSOSilentRenewComponent = () => {
    useEffect(() =>
    {
        defaultUserManager
            .signinSilentCallback()
    })
    return null
}
