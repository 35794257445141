import React from 'react'
import { connect } from 'react-redux'
import {DamageStatusSelectComponent} from './component'


const mapStateToProps = (state) => {
    const { shared } = state.pages.damageManager
    const { statusList } = state.common


    return {
        statusList: statusList.data,
        statusListLoaded: statusList.loaded,
        damage: shared.damage,
        damageLoaded: shared.damageLoaded,
    }
}

const Wrapper = (props) => props.damage.state !== undefined 
    ? <DamageStatusSelectComponent {...props} /> 
    : null

export const DamageStatusSelectFormControl =  connect(
    mapStateToProps
)(Wrapper)