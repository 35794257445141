import { useMemo } from 'react'

export const useLawfirmOptions = (webakteAccounts) => {

    const lawfirmOptions = useMemo(()=>{
        return webakteAccounts.map(
            wa => ({
                value: wa.webakteId,
                label: wa.name,
                imageSrc: wa.accountLogoUrl
            })
        )
    }, [webakteAccounts])

    return {
        lawfirmOptions
    }
}