import { NODE_TYPE } from 'application/constants/branch_management'
import { getConnectionType, mapIds } from './mappers'

export const useElementRemove = (onEdgeRemove, onNodeRemove, currentBranch) => {
    
    const removeEdge = (connection) => {
        const connectionType = getConnectionType(connection.elements, connection.source, connection.target)
        const {sourceId, targetId} = mapIds(connectionType, connection.elements, connection)
        onEdgeRemove(sourceId, targetId, connectionType)
    }

    const removeNode = node => onNodeRemove(node.id, node.nodeType)
    const removeButtonHidden = node => {
        if(node?.nodeType === NODE_TYPE.BRANCH){
            return node.id === currentBranch.id
        }

        if(node?.nodeType === NODE_TYPE.GROUP){
            return node.isPredefined
        }

        //roles cannot be removed in this implementation
        return true
    }

    return {
        removeEdge,
        removeNode,
        removeButtonHidden
    }
}