import { ACTIONS } from 'application/constants'

export const showDialogStepsWaitingScreen = ( 
    untranslatedMessage = ''
) => ({
    type: ACTIONS.DIALOG_STEPS_LAYOUT_WAITING_SCREEN_SHOW,
    untranslatedMessage
})

export const hideDialogStepsWaitingScreen = () => ({
    type: ACTIONS.DIALOG_STEPS_LAYOUT_WAITING_SCREEN_HIDE
})