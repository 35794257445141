import React from 'react'
import {MoDocumentListElement} from './list_element'
import styled from 'styled-components'


const StyledCategoryBlock = styled.div`
    margin-bottom: 16px;
`

const StyledCategoryTitle = styled.div`
    margin-bottom: 8px;
    font-size: ${props => props.theme.fontSize.standard};
`

const MoDialogCategoryBlock = ({
    removeItem,
    moName,
    itemsGroup
}
) => ( <StyledCategoryBlock>
    <StyledCategoryTitle>
        {itemsGroup.category}
    </StyledCategoryTitle>
    {
        itemsGroup.items.map(
            (item, i) => {
                return <MoDocumentListElement
                    key={i}
                    document={item}
                    removeItem={removeItem}
                    moName={moName}
                />
            }
        )           
    }
</StyledCategoryBlock>  
) 

export {MoDialogCategoryBlock}