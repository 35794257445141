import { BRANCH_MANAGEMENT } from 'application/constants'


export const groupToBranchRelationship = (elements, sourceId, targetId) => {
    const group = elements.find(e => e.nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP && (e.id === sourceId || e.id === targetId))
    const branch = elements.find(e => e.nodeType === BRANCH_MANAGEMENT.NODE_TYPE.BRANCH && (e.id === sourceId || e.id === targetId))

    return {
        sourceId: group.id,
        targetId: branch.id
    }
}