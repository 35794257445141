import { connect } from 'react-redux'
import {DamageManagerDamageExistsComponent} from './component'


const mapStateToProps = (state) => {

    const {
        damage, 
        damageLoading,
        damageLoaded
    } = state.pages.damageManager.shared

    return {
        damageLoading,
        damageLoaded,
        damage
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export const DamageManagerDamageExists =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDamageExistsComponent)