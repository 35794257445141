import { WIDGET_CONTEXTS } from 'application/constants'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { put } from 'redux-saga/effects'

export function* refreshMessageList(action) {
    yield put(
        refreshWidgetsByContext(WIDGET_CONTEXTS.INBOX)
    )
    yield put(
        refreshWidgetsByContext(WIDGET_CONTEXTS.BRANCH_SPECIFIC_INBOX)
    )
    yield put(
        refreshWidgetsByContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_INBOX)
    )
}