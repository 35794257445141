import { removeItemFromListById } from 'application/common/list_helpers'
import {useCallback, useEffect, useState} from 'react'
import { isPermitted } from 'application/components/context/permissions'
import { PERMISSION_SCOPE } from 'application/constants'

const buildStateGroups = (states, damages) => {
    return states.filter(state => {
        return damages.some(damage => damage.state.id === state.id)
    }).map(state => {
        return {
            state,
            damages: damages.filter(damage => damage.state.id === state.id)
        }
    })
}

export const useDamagesGroupedByState = (states, allDamages, allPermissions = [], neededPermissions = []) => {
    
    const userHasNecessaryPermissions = useCallback((damage) => {
        return isPermitted(allPermissions, [], neededPermissions, PERMISSION_SCOPE.SPECIFIC_BRANCH, true, damage.branch.id)
    }, [allPermissions, neededPermissions])

    const [selectedDamages, setSelectedItems] = useState(allDamages)
    const [processableItems, setProcessableItems] = useState(allDamages.filter(d => userHasNecessaryPermissions(d)))
    const [unprocessableItems, setUnprocessableItems] = useState(allDamages.filter(d => !userHasNecessaryPermissions(d)))
    const [stateGroups, setStateGroups] = useState(buildStateGroups(states, processableItems))

    const handleRemoveDamage = (    
        itemId 
    ) =>  {
        const listWithoutItem = removeItemFromListById(selectedDamages,itemId)
        setSelectedItems(listWithoutItem)
        setProcessableItems(listWithoutItem.filter(d => userHasNecessaryPermissions(d)))
        setUnprocessableItems(listWithoutItem.filter(d => !userHasNecessaryPermissions(d)))
    }

    useEffect(() => {
        setStateGroups(buildStateGroups(states, processableItems))
    }, [processableItems, states])

    return {
        stateGroups,
        filteredSelectedDamages: selectedDamages,
        handleRemoveDamage,
        selectedDamagesCount: selectedDamages.length,
        damagesSelected: selectedDamages.length > 0,
        processableItems,
        unprocessableItems
    }
}