import React from 'react'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import {  StyleSheet } from '@react-pdf/renderer'
import { convertISODateToGermanDateString } from 'application/common/date_time_helpers'
import { exists } from 'application/common/data_helpers'


export const PowerOfAttorneyPDF = ({
    lawfirmInfo = '',
    inMatters = '',
    becauseOf = '',
    branchLocation,
    signatures = null,
    addRvgNote = false,
    lawfirmName = '',
    damageLocation = '',
    incidentDate =  '',
    lawfirm = null
}) => {
    const today = convertISODateToGermanDateString(new Date())
    const incidentDateString = exists(incidentDate) 
        ? convertISODateToGermanDateString(new Date(incidentDate))
        : ''

    const location = `${branchLocation}, ${today}`

    const styles = StyleSheet.create({
        page: {
            padding: 40,
            paddingRight: 80
        },
        headline: {
            fontSize: 20,
            textAlign: 'center', 
        },
        section: {  
            margin: 15
        },
        paragraphHeadline: {
            fontSize: 10
        },
        paragraph: {
            fontSize: 10,
            paddingTop: 5,
            paddingBottom: 5
        },
        list: {
            main: {
                marginLeft: 20
            },
            row: {
                main: {
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 10,
                    padding: 2
                },
                left: {
                    width: 30
                },
                right: {
                    width: 500
                }
            }
        },
        center: {
            textAlign: 'center'
        },
        signField: {
            borderBottom: '2px dotted black',
            height: 20
        },
        signFieldLabel:                {
            display: 'flex', 
            flexGrow: 1, 
            alignItems:'center',
            fontSize: 10,
            paddingTop: 5
        },
        address: {
            table: {
                main: {
                    display: 'flex',
                    flexDirection: 'row'
                },
                leftCell: {
                    width: 60,
                },
                rightCell: {
                    width: 350,
                },
                signField: {
                    borderBottom: '1px solid black',
                    height: 22
                }
            }
        },
        withdraw: {
            paragraph: {
                format:   {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap:'wrap'
                },
                text: {
                    paddingBottom: 2, 
                    fontSize: '8' 
                },
                value: {
                    flexDirection: 'column',
                    borderBottom: '1px solid black', 
                    width: '100px',
                    fontSize: '6px',
                    height: '8px',
                    paddingLeft: '8px'
                },
                margins: {
                    marginTop: '8px'
                }
            },
        }
    })

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.headline}>Vollmacht</Text>
            </View>
      
            <View style={styles.section}>
                <Text style={[styles.paragraph,styles.center]}>
                    Der/ die Unterzeichnende erteilt hiermit
                </Text>
                <Text style={[styles.paragraph,styles.center]}>
                    {lawfirmInfo}
                </Text>
        
                <View style={styles.address.table.main}>
                    <View style={styles.address.table.leftCell}>
                        <Text style={styles.paragraph}>in Sachen</Text>
                        <Text style={styles.paragraph}>wegen</Text>
                        <Text style={styles.paragraph}>Vollmacht.</Text>
                    </View>{/*leftCell*/}
                    <View style={styles.address.table.rightCell}>
                        <View style={{...styles.address.table.signField, height: 'auto'}}>
                            <Text style={{...styles.paragraph, fontSize: 7}}>
                                {inMatters}
                            </Text>
                        </View>
                        <View style={styles.address.table.signField}>
                            <Text style={{...styles.paragraph,fontSize: 7}}>
                                {becauseOf}
                            </Text>
                        </View>
                    </View>{/*rightCell*/}
                </View>{/*table*/}
        
            </View>{/*section*/}
      
            <View style={styles.section}>
                <Text style={[styles.paragraph,{marginBottom: 16}]}>
          Diese Vollmacht erstreckt sich insbesondere auf folgende Befugnisse:
                </Text>

                <View style={styles.list.main} >
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >1.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                 Außergerichtliche Verhandlungen aller Art und Abschluss eines Vergleichs.
                            </Text>
                        </View>
                    </View>{/*list-row*/}

                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >2.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Verteidigung und Vertretung in allen Instanzen.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >3.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Strafanträge zu stellen.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >4.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Empfangnahme von Geld, Wertsachen und Urkunden, insbesondere des Streitgegenstandes und der vom Gegner, von der Justizkasse oder anderen Stellen zu erstattende Kosten.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >5.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Übertragung der Vollmacht ganz oder teilweise auf andere.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >6.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Entgegennahme von Zustellungen und Rücknahme von Rechtsmitteln sowie Verzicht auf solche, Erhebung und Rücknahme von Widerklagen -auch in Ehesachen -sowie Einlegung von Rechtsmitteln.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >7.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Beseitigung des Rechtsstreits durch Vergleich, Verzicht oder Anerkenntnis.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >8.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Vertretung im Konkurs-oder Vergleichsverfahren und in Freigabeprozessen.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
         
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >9.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Empfangnahme von Zustellungen, die auch an die Partei unmittelbar zulässig sind. Diese Zustellungen haben an den Bevollmächtigten zu erfolgen.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >10.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Vertretung in Zwangsvollstreckungsverfahren.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >11.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Vertretung in Sozialgerichtsverfahren.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >12.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Vertretung in behördlichen Verfahren (z. B. Umlegungsverfahren usw.)
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >13.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Alle Nebenverfahren (z.B. Arrest und einstweilige Verfügung, Kostenfestsetzung, Zwangsvollstreckung) einschließlich der aus ihnenerwachsenden besonderen Verfahren.
                            </Text>
                        </View>
                    </View>{/*list-row*/}
          
                    <View style={styles.list.row.main}>
                        <View style={styles.list.row.left} >
                            <Text >14.</Text>
                        </View>
                        <View style={styles.list.row.right}>
                            <Text>
                                Abgabe von Willenserklärungen.
                            </Text>
                        </View>
                    </View>{/*list-row*/}          
                </View>{/*list-main*/}
            </View>{/*section*/}
      
            <View style={styles.section}>
                <View style={{
                    display:'flex', 
                    flexDirection:'row',
                    width: '100%',
                    justifyContent:'space-between',
                    alignItems:'flex-end',
                    paddingLeft: '10mm',
                    paddingRight: '10mm',
                    paddingBottom: '2mm',
                    height: '20mm'
                }}>
                    <Text style={{fontSize: 10, marginTop: 10}}>
                        {location}
                    </Text>

                    {
                        exists(signatures[0]) && <Image
                            style={ {width:'40mm'} }
                            source={ {uri: signatures[0]} }
                        />
                    }                     
                </View>
                <View style={{...styles.signField, height: 'auto'}}></View>
                
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={styles.signFieldLabel}>
                        <Text>Ort,Datum</Text>
                    </View>
                    <View style={styles.signFieldLabel}>
                        <Text>Unterschrift</Text>
                    </View>
                </View>
            </View>{/*section*/}         
      
        </Page>


        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.headline}>Widerrufsbelehrung</Text>
            </View>    
      
            <View style={styles.section}>
                <Text style={styles.paragraphHeadline}>Widerrufsrecht</Text>
                <View style={styles.withdraw.paragraph.format}>
                    <Text style={styles.withdraw.paragraph.text}>
                                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen die Beauftragung der 
                    </Text>
                    <View style={styles.withdraw.paragraph.value}>
                        <Text>{ lawfirmName }</Text>
                    </View>
                    <Text style={styles.withdraw.paragraph.text}>
                                 zur Abwicklung des Schadens aus dem Verkehrsunfall am
                    </Text>
                    <View style={
                        {
                            ...styles.withdraw.paragraph.value,
                            width: '50px'
                        }
                    }>
                        <Text>{incidentDateString}</Text>
                    </View>
                    <Text style={styles.withdraw.paragraph.text}>
                                 in
                    </Text>
                    <View style={
                        {
                            ...styles.withdraw.paragraph.value,
                            width: '80px'
                        }
                    }>
                        <Text>{ damageLocation }</Text>
                    </View>
                    <Text style={styles.withdraw.paragraph.text}>
                                 zu widerrufen. 
                    </Text>
                    <Text style={styles.withdraw.paragraph.text}>
                                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.
                                Um ihr Widerrufsrecht auszuüben, müssen Sie uns die
                    </Text>
                    <View style={{
                        fontSize:'9px',
                        marginTop: '8px'
                    }}>
                        <Text>{ lawfirm?.name || '' }</Text>
                        <Text>{ lawfirm?.address?.line1 || '' }</Text>
                        <Text>{ lawfirm?.address?.postalCode || '' } { lawfirm?.address?.locality || '' }</Text>
                    </View>
                    <Text style={[
                        styles.withdraw.paragraph.text,
                        styles.withdraw.paragraph.margins
                    ]}>
                         mittels einer eindeutigen Erklärung (zum Beispiel einem mit der Post versandten Brief, Telefax,  E-Mail) über Ihren Entschluss, die Beauftragung zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                    </Text>
                    <Text style={[
                        styles.withdraw.paragraph.text,
                        styles.withdraw.paragraph.margins
                    ]}>
                                  Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung Ihres Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                    </Text>
                    <Text style={[styles.paragraphHeadline,styles.withdraw.paragraph.margins]}>
                              Folgen des Widerrufs</Text>
                    <Text style={ styles.withdraw.paragraph.text }>
                              Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                    </Text>
                  
                    <Text style={[
                        styles.withdraw.paragraph.text,
                        styles.withdraw.paragraph.margins
                    ]}>
                                   Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                    </Text>
                  
                  
                  
                  
                  
                  
                    <View style={{
                        flexDirection: 'row', 
                        justifyContent:'space-between', 
                        width:'100%'
                    }}>
                        <View>
                            <Text style={[styles.paragraphHeadline,{marginTop: '16px'}]}>
                                                  Belehrung erhalten 
                            </Text>                          
                            <Text style={[styles.paragraphHeadline,styles.withdraw.paragraph.margins]}>
                                {location}
                            </Text>
                        </View>
                        <View style={{ 
                            width:'250px',
                            marginTop: '16px'
                        }}>

                            <View style={{
                                display:'flex', 
                                flexDirection:'row',
                                width: '100%',
                                justifyContent:'space-between',
                                alignItems:'flex-end',
                                paddingLeft: '10mm',
                                paddingRight: '10mm',
                                paddingBottom: '2mm',
                                height: '20mm'
                            }}>
                                {
                                    exists(signatures[1]) && <Image
                                        style={ {width:'40mm'} }
                                        source={ {uri: signatures[1]} }
                                    />
                                }    
                            </View>
                            <View style={{...styles.signField, height: 'auto'}}></View>
                            <View style={{display: 'flex', flexDirection: 'row'}}>
                                <View style={{...styles.signFieldLabel, alignItems:'flex-start'}}>
                                    <Text>Unterschrift</Text>
                                </View>
                            </View>


                        </View>                      
                    </View>
                  
                  
                  
                    <View style={{marginTop: '16px', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <Text style={styles.withdraw.paragraph.text}>
                                          Ich stimme ausdrücklich zu, dass die 
                        </Text>    
                        <View style={
                            styles.withdraw.paragraph.value
                        }>
                            <Text>{ lawfirmName }</Text>
                        </View>
                        <Text style={styles.withdraw.paragraph.text}>
                            , mit der Dienstleistung sofort beginnt,
                        </Text>
                        <Text style={styles.withdraw.paragraph.text}>
                                 obwohl die Widerrufsfrist noch nicht abgelaufen ist und bin in Kenntnis, dass mein Widerrufsrecht erlischt, wenn die Dienstleistung vollständig erbracht ist (§ 356 Abs. 4 BGB).
                        </Text>            
                    </View>


    
                </View>
            </View>

        </Page>




        {
            addRvgNote && <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.headline}>Hinweis</Text>
                </View>
                <View style={styles.section}>
                    <Text style={[styles.paragraph,{marginBottom: 16}]}>
                            Hiermit  bestätige/n  ich/wir,  daß  ich/wir  vor  Vollmachtserteilung  darauf  hingewiesen  worden bin/sind, daß sich die zu erhebenden Gebühren nach  einem Gegenstandswert richten  (§ 49 b BRAO) und die Abrechnung gemäß Rechtsanwaltsvergütungsgesetz (RVG) erfolgt. 
                    </Text>
                    <Text style={[styles.paragraph,{marginBottom: 16}]}>
                            Weiterhin  wurde/n  ich/wir  darauf  hingewiesen,  daß  Kostenschuldner  für  die  entstehenden Rechtsanwaltsgebühren ich/wir bin/sind.  
                    </Text>
                    <Text style={[styles.paragraph,{marginBottom: 16}]}>
                            Ich/wir   bestätige/n,   daß   die   Mandatserteilung   unabhängig   von   der   Erteilung   einer Kostendeckungszusage durch eine eventuell bestehende Rechtsschutzversicherung erfolgt.
                    </Text>
                </View>
                <View style={styles.section}>
        
                    <View style={{
                        display:'flex', 
                        flexDirection:'row',
                        width: '100%',
                        justifyContent:'space-between',
                        alignItems:'flex-end',
                        paddingLeft: '10mm',
                        paddingRight: '10mm',
                        paddingBottom: '2mm',
                        height: '20mm'
                    }}>
                        <Text style={{fontSize: 10, marginTop: 10}}>
                            {location}
                        </Text>
                        {
                            exists(signatures[2]) && <Image
                                style={ {width:'40mm'} }
                                source={ {uri: signatures[2]} }
                            />
                        }                     
                    </View>
                    <View style={{...styles.signField, height: 'auto'}}></View>
        
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.signFieldLabel}>
                            <Text>Ort,Datum</Text>
                        </View>
                        <View style={styles.signFieldLabel}>
                            <Text>Unterschrift</Text>
                        </View>
                    </View>
                </View>{/*section*/}    
            </Page>
        }







    </Document>
}