import { connect } from 'react-redux'
import {CategorySelectDialog} from './component'


const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(
        d => d.name === props.dialog.name
    )
})


export const CategorySelect = connect(
    mapStateToProps,
    null
)(CategorySelectDialog)