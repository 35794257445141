import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'


export function* dialogGetDataForOtherExpertCommissionSaga(action) {
    const { dialogName, metaData } = action
    const { damageId } = metaData

    const damage = yield resourceCall(
        api.gateway.damage.getDamage, 
        { damageId: damageId }
    )

    const branch = yield resourceCall(
        api.gateway.auth.getBranchById, 
        { branchId: damage.branchId }
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                damage, 
                branch
            }
        )
    )
}