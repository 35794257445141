import { connect } from 'react-redux'
import {RemoveGroup as RemoveGroupComponent} from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { closeDialogByName } from 'application/redux/actions/dialogs'




const mapStateToProps = ( state, props ) => {
    const groupId = props.dialog.payload.groupId 

    const groupData = state.common.userGroups.find(
        group => group.id === groupId
    )
    
    return {
        groupData,
        members: props.dialog.payload?.members || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveGroup: (
            dialogName, 
            payload, 
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload, 
                    metaData
                )
            )
        },
        onCloseDialog: (dialogName) => {
            dispatch(
                closeDialogByName(dialogName)
            )
        }
    }
}

export const RemoveGroup =  connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveGroupComponent)