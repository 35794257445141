import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const inboxReducer =  (state = defaultStore.pages.inbox, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.inbox
    }
    case ACTIONS.INBOX_GET_MESSAGE_BY_ID: return {
        ...state,
        details: null,
        detailsLoaded: false,
    }
    case ACTIONS.INBOX_RESET_MESSAGE_DETAILS: return {
        ...state,
        details: null,
        detailsLoaded: false,
    }
    case ACTIONS.INBOX_POPULATE_MESSAGE_DETAILS: return {
        ...state,
        details: action.messageDetails,
        detailsLoaded: true
    }
    case ACTIONS.INBOX_MESSAGE_NOT_FOUND: return {
        ...state,
        deletedMessages: [...state.deletedMessages, action.messageId]
    }
    default:
        return state
    }
}
