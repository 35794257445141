import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { openDialogByName } from 'application/redux/actions/dialogs'
import * as dataMappers from 'application/api/data_mappers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreateUserSaga(action) {

    yield put(
        showWaitingScreen('Creating user')
    )
    
    const userPayload = dataMappers.exportMappers.users.createUserDataMapper(action.payload.user)
    const groupsPayload = dataMappers.exportMappers.users.updateUserGroupsDataMapper(action.payload.groups)
    const metaData = action.metaData

    const { 
        accessibleBranches,
        invitation
    } = metaData

    const userResponse = yield resourceCall(
        api.gateway.users.postUser, 
        null,
        userPayload
    )

    yield resourceCall(
        api.gateway.users.putUserGroups,
        {
            userId: userResponse.id
        },
        groupsPayload
    )
    
    yield invitation && resourceCall(
        api.gateway.users.postInvitation, 
        {
            userId: userResponse.id
        }
    )

    yield put(
        openDialogByName(
            metaData.dialogToOpen.name,
            false,
            {
                user: {
                    id: userResponse.id,
                    ...userPayload
                },
                accessibleBranches
            },
            'Select preferred branch'
        )
    )

    yield put(
        showToast('Usercreation successfull')
    )

    yield put(hideWaitingScreen())
}