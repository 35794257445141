import { put } from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { addBranchToList } from 'application/redux/actions/common/branches'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export function* createBranchSaga(action) {
    yield put(
        showWaitingScreen('Creating branch')
    )  

    const payload = removePropertiesWithEmptyStrings(action.payload)
    const response = yield resourceCall(
        api.gateway.branches.postCreateBranch, 
        null, 
        payload
    )

    yield resourceCall(
        api.gateway.branches.putUpdateBranchAddress, 
        {
            branchId: response.id
        }, 
        payload.address
    )    

    yield resourceCall(
        api.gateway.branches.putUpdateBranchContact, 
        {
            branchId: response.id
        }, 
        payload.contact
    )

    yield put(
        addBranchToList(
            {
                ...payload, 
                id: response.id
            }
        )
    )
    
    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Branch created')
    )
}