import React from 'react'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { PushBottom16 } from 'application/components/pages/_common'


export const Step2 = ({
    files,
    setFiles
}) => {
    return <React.Fragment>        
        <TaskUploader
            onFileAdded={() => { }}
            disableActiveState={true}                   
            files={files}
            setFiles={setFiles}
        />
        <PushBottom16 />
    </React.Fragment>
}