import React from 'react'
import {ACCIDENT_REPORT_DESCRIPTION} from 'application/constants'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { CheckBoxGroupWithFreetextField } from 'application/components/controls/form'




const AccidentReportDescriptionCheckboxGroup = (
    {
        register,
        errors,
        onChange,
        setValue,
        trigger,
        initialDescriptionValues = [],
        initialDescriptionOther = '',
        name='accidentReportDescription',
        freeTextName='accidentReportDescriptionOther',
        limit=50
    }
) => {

    const {t} = useTranslation()

  

    const options = [
        {
            label: t(ACCIDENT_REPORT_DESCRIPTION.REAR_END_COLLISION),
            value: ACCIDENT_REPORT_DESCRIPTION.REAR_END_COLLISION
        },
        {
            label: t(ACCIDENT_REPORT_DESCRIPTION.RIGHT_OF_WAY_VIOLATION),
            value: ACCIDENT_REPORT_DESCRIPTION.RIGHT_OF_WAY_VIOLATION
        },
        {
            label: t(ACCIDENT_REPORT_DESCRIPTION.LANE_CHANGE),
            value: ACCIDENT_REPORT_DESCRIPTION.LANE_CHANGE
        },
        {
            label: t(ACCIDENT_REPORT_DESCRIPTION.OVERTAKING),
            value: ACCIDENT_REPORT_DESCRIPTION.OVERTAKING
        },
        {
            label: t(ACCIDENT_REPORT_DESCRIPTION.DAMAGE_TO_PARKED_VEHICLE),
            value: ACCIDENT_REPORT_DESCRIPTION.DAMAGE_TO_PARKED_VEHICLE
        },
        {
            label: t(ACCIDENT_REPORT_DESCRIPTION.LEAVING_THE_LANE),
            value: ACCIDENT_REPORT_DESCRIPTION.LEAVING_THE_LANE
        }
    ]

    const mappedOptions = options.map(
        option => {
            return {
                ...option,
                isInitialChecked: initialDescriptionValues.some(
                    initialDescription => option.value === initialDescription
                )
            }
        }
        
    )

    return  <CheckBoxGroupWithFreetextField 
        options={mappedOptions}
        register={register} 
        errors={errors}
        checkBoxGroupName={name}
        freeTextName={freeTextName}
        freeTextConstant={ACCIDENT_REPORT_DESCRIPTION.OTHER}
        onDataChange={onChange}
        setValue={setValue}
        trigger={trigger}
        initialFreeTextValue={initialDescriptionOther}
        limit={limit}
    />
}


AccidentReportDescriptionCheckboxGroup.propTypes = {
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,
    initialDescriptionValues: PropTypes.array,
    initialDescriptionOther: PropTypes.string,   
    name: PropTypes.string,
    freeTextName: PropTypes.string,
    limit: PropTypes.number
}




export {AccidentReportDescriptionCheckboxGroup}