import { fetchRequestBuilderWithToken, fetchHandler, limiter, HTTP_METHOD } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'
import { buildListQuery } from 'application/api/helpers'

export const getAccounts = (
    accessToken, 
    offset, 
    limit, 
    orderBy, 
    direction, 
    filterQuery
) => {
    const queryString = buildListQuery(offset, limit, orderBy, direction, filterQuery)
    const request = fetchRequestBuilderWithToken(`accounts${queryString}`, HTTP_METHOD.GET, accessToken)

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}   