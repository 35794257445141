
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'



export const DocumentDeletedDetails = (
    {
        activity
    }
) => {

    const damageLicensePlate = activity.data?.damage?.licensePlate || ''
    const documentName = activity.data?.name || ''

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={damageLicensePlate} 
        />
        <ActivityDetail 
            label='Document' 
            value={documentName} 
        />
    </ActivityDetailWrapper>
}

DocumentDeletedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}