import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TASK_PRIORITIES } from 'application/constants'
import { Select } from 'application/components/controls/form'
import { PriorityName} from 'application/components/name_mappings/priority_name'

export const PrioritySelect = ({
    name='priority',
    register,
    setValue,
    initialValue = TASK_PRIORITIES.LOW,
    menuPlacement='bottom',
    onChange = () => {}
}) => {
    const {t} = useTranslation()
    const priorityOptions = [
        {
            value: TASK_PRIORITIES.LOW,
            label: <PriorityName priority={TASK_PRIORITIES.LOW} />
        },
        {
            value: TASK_PRIORITIES.MEDIUM,
            label: <PriorityName priority={TASK_PRIORITIES.MEDIUM} />
        },
        {
            value: TASK_PRIORITIES.HIGH,
            label: <PriorityName priority={TASK_PRIORITIES.HIGH} />
        },
        
    ]
    const initialOption = priorityOptions.find(o => o.value === initialValue)

    return <Select 
        label={t('Priority')}
        name={name}
        register={register}
        setValue={setValue}
        options={priorityOptions}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

PrioritySelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}