import React from 'react'
import { WIDGET_CONTEXTS } from 'application/constants'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import {DamageManagerContainer} from '../container'
import { SubPageLayout } from 'application/components/pages/_common'
import {DamageManagerInboxTabbarButtons} from './tab_bar_buttons'


export const DamageManagerInboxComponent = () => {
    return <DamageManagerContainer
        titleSegments={['Inbox']}
        headline='Inbox'
        tabBarButtons={ <DamageManagerInboxTabbarButtons /> }
        mainPaddingLeft='16px'
    >
        <SubPageLayout>
            <WidgetArea 
                widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_INBOX} 
                direction='row' 
                successively={true}
            />
        </SubPageLayout>
    </DamageManagerContainer>
}
