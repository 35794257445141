import styled, { withTheme } from 'styled-components'


const StyledTitleBarRow = styled.div`
    display: ${props => props.forWideScreen ? 'flex' : 'none' };
    width: 100%;
    justify-content: ${props => props.justifyContent};
    height: 48px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom:${props => props.borderBottomValue};

    ${props => props.mediaQuery}
`



const TitleBarRowComponent = (
    {
        children,
        theme,
        borderBottomValue='none',
        justifyContent='space-between',
        shouldBreak = false,
        forWideScreen = true
    }
) => {  
    const mediaQuery = shouldBreak 
        ? `@media (max-width: ${theme.breakpoint.laptopL}px) {
            display: ${forWideScreen ? 'none' : 'flex'};
        }`
        : ''

    return <StyledTitleBarRow 
        justifyContent={justifyContent}
        borderBottomValue={borderBottomValue}
        forWideScreen={forWideScreen}
        shouldBreak={shouldBreak}
        mediaQuery={mediaQuery}
    >
        {children}
    </StyledTitleBarRow>
}

export const TitleBarRow = withTheme(
    TitleBarRowComponent
)