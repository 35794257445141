import {ACTIONS} from 'application/constants'

export const getAllDamagesRequest = (
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
) => ({
    type: ACTIONS.DAMAGES_OVERVIEW_GET_ALL_DAMAGES_REQUEST,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})

export const populateAllDamages = (damagesList, damagesListTotal) => ({
    type: ACTIONS.DAMAGES_OVERVIEW_POPULATE_ALL_DAMAGES,
    damagesList,
    damagesListTotal
})

export const damagesOverviewUpdateStatus = (
    damageId, 
    state,
    showDetails = false
) => ({
    type: ACTIONS.DAMAGES_OVERVIEW_UPDATE_STATUS,
    damageId,
    state,
    showDetails
})

export const damagesOverviewPopulateDamageState = (
    damageId,
    state,
    showDetails = false
) => ({
    type: ACTIONS.DAMAGES_OVERVIEW_POPULATE_DAMAGE_STATE,
    damageId,
    state,
    showDetails
})