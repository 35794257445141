import React from 'react'
// import PropTypes from 'prop-types'
import { WIDGET_CONTEXTS } from 'application/constants'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import { useTranslation } from 'react-i18next'


export const InboxComponent = () => {
    const {t} = useTranslation()

    return <LayoutAllBranches
        titleSegments={['Inbox', 'All branches']}
        headlineSpecific={t('Inbox')}
    >
        <WidgetArea widgetContext={WIDGET_CONTEXTS.INBOX} direction='row' />
    </LayoutAllBranches>
}