import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import { IconButton } from 'application/components/controls/icon_button/component'
import { FontIcon } from '@ec/ui-controls-react'
import { useNavigate } from 'react-router-dom'



const StyledContextMenuItemComponent = styled.li`
    padding: 4px 8px;
    border-radius: 2px;
    color: ${props => props.theme.color.white};
    min-width: 210px;
    height: 30px;
    display: inline-block;
    display: flex;
    flex-direction: row;
    margin-bottom: 1px;
    justify-content: space-between;
    z-index: 2003;
    &:hover{
        background: ${props => props.$disabled ? '#8d8d8d' : props.theme.color.anthracite};
    }
    font-size: ${props => props.theme.fontSize.small};

    & a{
        color: ${props => props.theme.color.white};
        text-decoration: none;
        display: flex;
    }
    background: ${props => props.$disabled ? '#8d8d8d' : props.theme.color.gray70};
    cursor: ${props => props.$disabled ? 'default !important' : 'pointer'};
`

const StyledBox = styled.span`
    align-self: center;
    padding-right: 10px;
    font-size: 15px;
    font-weight: ${props => props.theme.fontWeight.standardRegular};
`

const StyledContextSubMenuList = styled.ul`
    position: absolute;
    min-width: 140px;
    left: 70px;
    list-style-type: none;
    font-size: 14px;

`
const StyledSubMenuContent = styled.li`
	 min-width: 140px;
    height: 20px;
    background: #4d4d4d;
    color: #ffffff;
    padding: 8px;
    border-radius: 1px;
    margin-bottom: 1px;
    position: relative;
    top: 78px;
`

const StyledSubMenu = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    i {
        color: ${props => props.theme.color.white}
    }
`

const StyledMainMenu = styled.div`
    display: flex;
    flex-direction: row;
`

const ContextMenuItemComponent = ({
    onListElementClick,
    iconKey,
    children,
    className = 'context-menu',
    title = '',
    submenu,
    theme,
    onMenuOpen = () => { }, 
    onMenuClose = () => { },
    isLinked = false,
    disabled = false,
    to = ''
}) => {
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState(false)

    const closeMenu = () => {
        setMenuOpen(false)
        onMenuClose()
    }

    const handleMenu = () => {
        if (menuOpen) {
            closeMenu()
        } else {
            setMenuOpen(true)
            onMenuOpen()
        }
    }

    const handleParentSubMenu = () => {
        if (menuOpen) {
            closeMenu()
        }
    }

    const ref = useRef()
    useClickOutside(ref, closeMenu)
    useEscapeKey(closeMenu)

    const Content = () => <React.Fragment>
        <StyledMainMenu>
            <StyledBox>
                <FontIcon fontSize='14px' icon={iconKey} isClickable={true} color='white' />
            </StyledBox>
            <StyledBox>
                {children}
            </StyledBox>
        </StyledMainMenu>
        {
            submenu !== undefined && <StyledSubMenu ref={ref} onClick={handleParentSubMenu}>
                <IconButton
                    onButtonClick={handleMenu}
                    iconKey="keyboard_arrow_right"
                    width="24px"
                    height="24px"
                    iconSize="16px"
                    color={theme.color.anthracite}
                    hoverBackground="none"
                    activeBackground="none"
                />
                {
                    menuOpen && <StyledContextSubMenuList>
                        {submenu.map((s, index) => {
                            return <StyledSubMenuContent key={index}>
                                {s.label}
                            </StyledSubMenuContent>
                        })}
                    </StyledContextSubMenuList>
                }
            </StyledSubMenu>
        }
    </React.Fragment>

    if (isLinked) {
        return <StyledContextMenuItemComponent 
            $disabled={disabled} 
            onClick={() => navigate(to)} 
            className={className} 
            title={title}
        >
            <Content />
        </StyledContextMenuItemComponent>
    }

    return <StyledContextMenuItemComponent $disabled={disabled} onClick={(e) => {
        if(!disabled){
            onListElementClick(e)
        }
    }} className={className} title={title}>
        <Content />
    </StyledContextMenuItemComponent>
}

ContextMenuItemComponent.propTypes = {
    onListElementClick: PropTypes.func,
    iconKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    submenu: PropTypes.array,
    onMenuOpen: PropTypes.func, 
    onMenuClose: PropTypes.func,
    isLinked: PropTypes.bool,
    to: PropTypes.string
}


export const ContextMenuItem =  withTheme(ContextMenuItemComponent)