import React from 'react'
import * as Fragments from './fragments'
import { ActivityDamageTaskLink } from './_common/damage_task_activity_link'


export const TaskAssigneeChangedMetaContent = (
    {
        notification, 
        inline,
        onLinkClick
    }
) => {
    const {payload} = notification
    const oldAssigneeName = payload.oldAssignee?.fullName || ''
    const newAssigneeName = payload.newAssignee?.fullName || ''

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='Task name'
            value={payload.title}
        />
        <Fragments.ActivityDescription 
            label='Assignee'
            value={`${oldAssigneeName} → ${newAssigneeName}`}
        />
        <ActivityDamageTaskLink
            onClick={onLinkClick}
            damageId={payload.damage.id}
            taskId={payload.taskId}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}