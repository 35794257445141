import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { Paragraph } from 'application/components/fragments/typography'
import { TaskListItem } from './task_list_item'
import { FlexBox } from 'application/components/fragments/flex_box'

const DamageTaskBulletList = ({payload, loadingFailed}) => {
    const { t } = useTranslation()
    const {tasks, total} = payload
    
    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }
    return <React.Fragment>
        <FlexBox flexDirection='column'>
            {
                total > 0 
                    ? tasks.map((task, i) => {
                        return <TaskListItem key={i} task={task} />
                    }) 
                    : <Paragraph>
                        {t('No tasks at the moment')}
                    </Paragraph>
            }
        </FlexBox>
    </React.Fragment>
}

DamageTaskBulletList.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageTaskBulletList }