import React from 'react'
import PropTypes from 'prop-types'
import TruncateString from 'react-truncate-string'
import { FontIcon, IconButton } from '@ec/ui-controls-react'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import {FallbackThumbnail} from 'application/components/controls/fallback_thumbnail'
import * as Fragments from './fragments'
import { useTranslation } from 'react-i18next'
import { withThumbnailLoader } from 'application/components/higher_order_components/with_thumbnail_loader'




const Thumbnail = ({ 
    attachment, 
    onAttachmentClick, 
    showCaption = true,
    isActive = false,
    removeFile = () => {},
    removableFiles = false,
    damageId,
    mobileView = false,
    id = null
}) => {
    const {t} = useTranslation()

    return (
        <Fragments.StyledAttachment 
            onClick={ 
                () => onAttachmentClick(
                    attachment,
                    damageId
                )
            } 
            isActive={isActive} 
            className='jest-attachment-thumbnail'
            mobileView={mobileView}
        >
            <Fragments.StyledThumbnail>
                {
                    attachment.hasThumbnail 
                    && !attachment.thumbnailLaoadingFailed 
                        ? attachment.thumbnailIsLoading 
                            ? <ThemedPreloader />
                            : <Fragments.StyledThumbnailImage 
                                src={attachment.thumbnail} 
                                alt={attachment.fileName} 
                            />
                        : <FallbackThumbnail 
                            fileExtension={attachment.fileExtension} 
                        />
                }
                
            </Fragments.StyledThumbnail>
            {
                showCaption && <Fragments.StyledCaption>
                    <TruncateString 
                        text={attachment.fileName} 
                        truncateAt={40}
                    />
                </Fragments.StyledCaption>}
            {
                attachment.isExportedToLawFirm 
                &&  <Fragments.StyledExportMarker>
                    <FontIcon
                        icon='sync'
                        title={t('Available for legal software')}
                    />
                </Fragments.StyledExportMarker>
            }
            {
                !attachment.isExportedToLawFirm 
                && removableFiles
                && <Fragments.StyledRemoveMarker>
                    <IconButton
                        iconKey='delete'
                        title={t('Remove file')}
                        onButtonClick={() => {
                            removeFile(attachment.fileName, id)
                        }}
                    />
                </Fragments.StyledRemoveMarker>
            }
        </Fragments.StyledAttachment>
    )
}


Thumbnail.propTypes = {
    attachment: PropTypes.object.isRequired,
    onAttachmentClick: PropTypes.func.isRequired, 
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool,
    removeFile: PropTypes.func, 
    removableFiles: PropTypes.bool,
    damageId: PropTypes.string,
    mobileView: PropTypes.bool
}

export {Thumbnail} 
export const ThumbnailWithLoader = withThumbnailLoader(Thumbnail)