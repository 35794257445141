import React from 'react'
import PropTypes from 'prop-types'



export const ShortenedString = (
    {
        text = '', 
        shortenAt = 0
    }
) => {

    const shortenedString = text.length > shortenAt-1 
        ? text.substring(0, shortenAt)+'...'
        : text

    return <React.Fragment>
        {shortenedString}
    </React.Fragment>
}

ShortenedString.propTypes = {
    string: PropTypes.string,
    shortenAt: PropTypes.number
}