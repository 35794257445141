import { dateTimeHelpers, validationMethods } from 'application/common'
import { exists } from 'application/common/data_helpers'
import { timerangeConverter } from 'application/common/date_time_helpers'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { DatePicker } from 'application/components/controls/form'
import { IconButton } from 'application/components/controls/icon_button'
import { InfoBox } from 'application/components/controls/info_box'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Label } from 'application/components/fragments/typography'
import { useStatefulForm } from 'application/components/hooks'
import { setQuery, useQuery } from 'application/components/hooks/use_query'
import { Spacer } from 'application/components/pages/_common'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { STATISTICS } from 'application/constants'
import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export const TimeRangeFilter = ({
    routeParameters, 
    onChange = ()=>{}, 
    maxPeriod = STATISTICS.MAXIMUM_STATISTICS_RANGE, 
    label = 'Show created damages from',
    keepQueryOnChange = true,
    disabled = false
}) => {
    const [isValid, setIsValid] = useState(true)
    const [isBefore, setIsBefore] = useState(true)
    const {t} = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const query = useQuery()
    const { FILTERS, OFFSET, LIMIT} = routeParameters
    const { TIME_RANGE } = FILTERS

    const startDateRef = useRef(null)
    const endDateRef = useRef(null)

    const today = new Date()
    const timerange = query.getStringParam(TIME_RANGE)

    const { startDate, endDate } = timerangeConverter.getDateTimesFromRange(timerange)

    const defaultValues = {
        startDate,
        endDate
    }
    
    const {
        errors, 
        control, 
        getValues,
        setValue, 
        register, 
        formStateValues, 
        updateFormState,
        trigger
    } = useStatefulForm( {defaultValues} )

    const handleChange = () => {
        const {startDate, endDate} = getValues()
        const before = dateTimeHelpers.dateIsBefore(startDate, endDate)
        const inRange = maxPeriod === null 
        || dateTimeHelpers.datesRange(startDate, endDate) <= maxPeriod

        setIsValid(before && inRange)
        setIsBefore(before)

        if(before && inRange){
            setQuery(
                navigate, 
                location, 
                location.pathname, 
                [
                    {
                        key: TIME_RANGE,
                        value: timerangeConverter.getRangeFromDateTimes(startDate, endDate)
                    }
                ], 
                keepQueryOnChange,
                [
                    OFFSET,
                    LIMIT
                ]
            )
            onChange(startDate, endDate)
        }
    }
 
    const resetFilter = () => {
        setQuery(
            navigate, 
            location, 
            location.pathname, 
            [], 
            keepQueryOnChange,
            [
                OFFSET,
                LIMIT,
                TIME_RANGE
            ]
        )
        setValue('startDate', '')
        setValue('endDate', '')
        updateFormState()
        onChange('', '')
        startDateRef.current.value = ''
        endDateRef.current.value = ''
    }

    return <FilterFragment.FilterRow className='filterRow'>

        <FilterFragment.FilterLabel className='filterLabel'>
            <Label cssMargin='0 16px 0 0'>
                {t(label)}
            </Label>
        </FilterFragment.FilterLabel>

        <FilterFragment.FilterAction className='filterAction'>

            <FlexBox width='113px'>
                <DatePicker 
                    name='startDate'
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.startDate}
                    control={control}
                    onDateChange={handleChange}
                    isClearable={false}
                    disabledDays={{after: today}}
                    register={register}
                    defaultValue={formStateValues.startDate}
                    updateFormState={updateFormState}
                    hideValidationBox={true}
                    ref={startDateRef}
                    setValue={setValue}
                    trigger={trigger}
                    disabled={disabled}
                />
            </FlexBox>
            <FlexBox flexShrink='1' flexGrow='0' flexBasis='40px'>
                <Label cssMargin='0 8px'>
                    {t('and')}
                </Label>
            </FlexBox>
            
            <FlexBox width='113px'>
                <DatePicker 
                    name='endDate'
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.endDate}
                    control={control}
                    onDateChange={handleChange}
                    isClearable={false}
                    disabledDays={{after: today}}
                    register={register}
                    defaultValue={formStateValues.endDate}
                    updateFormState={updateFormState}
                    hideValidationBox={true}
                    ref={endDateRef}
                    setValue={setValue}
                    trigger={trigger}
                    disabled={disabled}
                />
            </FlexBox>

        </FilterFragment.FilterAction>

        {
            !isValid &&  <WidgetErrorMessage cssPadding='10px'>
                {
                    !isBefore
                        ? t('Start date must be before end date')
                        : <Trans i18nKey='Period must be smaller'>
                                    Period must be smaller than {{maxPeriod}} days
                        </Trans>
                }
            </WidgetErrorMessage>
        }

        {
            exists(timerange) && <FilterFragment.FilterMeta>
                <InfoBox direction='row' cssMargin='0'>
                    <span>{t('Timerange filter active')}</span>
                        
                    <Spacer />
                    <IconButton 
                        iconKey='close' 
                        width='12px' 
                        height='16px' 
                        onButtonClick={resetFilter} 
                    />
                </InfoBox>
            </FilterFragment.FilterMeta>
        }
        
    </FilterFragment.FilterRow>
    
}