import { connect } from 'react-redux'
import { EditComprehensiveDamageTypeComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSaveComprehensiveDamageType: (dialogName, comprehensiveDamageTypeId, payload) => dispatch(sendPayloadRequest(dialogName, payload, {comprehensiveDamageTypeId}))
    }
}

export const EditComprehensiveDamageType = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditComprehensiveDamageTypeComponent)