import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'



const CreatedInfo = (
    {activityData}
) => {
    const {t}  = useTranslation()
    /*
      jobNumber: ""
      licensePlate: "SB TN 103"   
      type: "Unknown"
     */


    return <React.Fragment>
        {
            activityData.licensePlate !== '' && <React.Fragment>
                { t('License plate') + ': ' + activityData.licensePlate }
            </React.Fragment>
        }
        {
            activityData.jobNumber !== '' && <React.Fragment>
              , { t('Jobnumber') + ': ' + activityData.jobNumber }
            </React.Fragment>
        }
        <React.Fragment>
            , { t('Damage type') }: { t(activityData.type) }
        </React.Fragment>
    </React.Fragment>
}

CreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CreatedInfo} 
