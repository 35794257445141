import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    DAMAGE_MANAGER_INBOX_MESSAGE_DETAILS: 'DAMAGE_MANAGER_INBOX_MESSAGE_DETAILS',
    DAMAGE_MANAGER_INBOX_MESSAGE_LIST: 'DAMAGE_MANAGER_INBOX_MESSAGE_LIST',
}

export const widgetsDamageManagerInbox = [
    {
        key: KEYS.DAMAGE_MANAGER_INBOX_MESSAGE_LIST,
        type: WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_LIST,
        title: 'Message List',
        hideHeadline: true,
        dimension: '30%',
        routeParameters: ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_INBOX_MESSAGE_DETAILS,
        type: WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_DETAILS,
        title: 'Message Details',
        hideHeadline: true,
        dimension: '70%',
        accountDependent: true
    } 
]