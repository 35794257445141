import React, { useEffect, useMemo } from 'react'
import { TaskDetails } from './component'
import { useParams } from 'react-router-dom'
import { isDefined } from 'application/common/data_helpers'
import { useLocation } from 'react-router-dom'




export const TaskDetailsEffects = (props) => {
    const params = useParams()
    const path = useLocation().pathname

    const { 
        onLoadTask, 
        selectedTaskLoading, 
        selectedTaskLoaded, 
        task, 
        hasTaskList, 
        contextLoaded, 
        onRegisterWidgetContext 
    } = props
    
    const { 
        damageId,
        taskId
    } = params

    const currentTaskId = task !== null 
        ? task.id 
        : null

    const widgetList = useMemo(()=>{
        return isDefined(task?.formConfiguration?.components) 
            ? task.formConfiguration.components
                .sort(
                    (a, b) => a.index - b.index)
                .map(
                    w=>({
                        ...w,
                        key: `${w.type}_${task.id}`,
                        title: `${w.type}_${task.id}`, //empty later
                        hideHeadline: true,
                        accountDependent: true,
                        initialPayload: {
                            taskId: task.id,
                            damageId: task.damageId
                        }
                    })
                ) 
            : []
    },[task])


    useEffect(() => {
        taskId 
        && (!selectedTaskLoaded || taskId !== currentTaskId) 
        && !selectedTaskLoading 
        && taskId !== '' 
        && hasTaskList 
        && onLoadTask(taskId, damageId)
    }, [currentTaskId, hasTaskList, path, onLoadTask, damageId, selectedTaskLoaded, selectedTaskLoading, taskId])

    useEffect(() => {
        selectedTaskLoaded 
        && !contextLoaded 
        && taskId !== '' 
        && onRegisterWidgetContext(taskId, widgetList) //just use the taskId as context
    }, [contextLoaded, onRegisterWidgetContext, selectedTaskLoaded, taskId, widgetList])

    return <TaskDetails 
        {...props} 
        taskId={taskId} 
        damageId={damageId}
    />
}