import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    DAMAGE_MANAGER_AK_RECEIPT_CONFIRMATION: 'DAMAGE_MANAGER_AK_RECEIPT_CONFIRMATION',
    DAMAGE_MANAGER_AK_LIABILITY_REQUEST: 'DAMAGE_MANAGER_AK_LIABILITY_REQUEST',
    DAMAGE_MANAGER_AK_AKTEN_STATUS: 'DAMAGE_MANAGER_AK_AKTEN_STATUS',
    DAMAGE_MANAGER_AK_INVOICES: 'DAMAGE_MANAGER_AK_INVOICES',
    DAMAGE_MANAGER_AK_REPAIR_CLEARANCE: 'DAMAGE_MANAGER_AK_REPAIR_CLEARANCE',
}

export const widgetsDamageManagerAnwaltKommunikation = [
    {
        key: KEYS.DAMAGE_MANAGER_AK_REPAIR_CLEARANCE,
        type: WIDGET_TYPES.DAMAGE_AK_REPAIR_CLEARANCE,
        title: 'Liability request',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_AK_LIABILITY_REQUEST,
        type: WIDGET_TYPES.DAMAGE_AK_LIABILITY_REQUEST,
        title: 'Liability request',
        hideHeadline: true,
        accountDependent: true,
        dependentWidgetKeys: [
            KEYS.DAMAGE_MANAGER_AK_AKTEN_STATUS,
            KEYS.DAMAGE_MANAGER_AK_RECEIPT_CONFIRMATION
        ]
    },
    {
        key: KEYS.DAMAGE_MANAGER_AK_AKTEN_STATUS,
        type: WIDGET_TYPES.DAMAGE_AK_AKTEN_STATUS,
        title: 'Aktenstatus',
        hideHeadline: true,
        accountDependent: true,
        dependsOnOtherWidget: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_AK_RECEIPT_CONFIRMATION,
        type: WIDGET_TYPES.DAMAGE_AK_RECEIPT_CONFIRMATION,
        title: 'Receipt confirmation',
        hideHeadline: true,
        accountDependent: true,
        dependsOnOtherWidget: true
    }
]

export const widgetsDamageManagerAnwaltKommunikationInvoices = [
    {
        key: KEYS.DAMAGE_MANAGER_AK_INVOICES,
        type: WIDGET_TYPES.DAMAGE_AK_INVOICES,
        title: 'Invoices',
        hideHeadline: true,
        accountDependent: true
    }
]