import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiFilestream } from 'application/api/helpers'

export const getThumbnailDataById = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/documents/${params.attachmentId}/thumbnail`, 
                HTTP_METHOD.GET
            )
            return apiFilestream(request, [204])
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents/${params.attachmentId}/thumbnail`, 
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiFilestream(request, [204])
        },
    }
)