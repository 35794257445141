import React from 'react'
import { useTranslation } from 'react-i18next'
import { exists, notNull } from 'application/common/data_helpers'
import { LicensePlate } from 'application/components/controls/license_plate'
import { 
    CardWrapper,
    CardHeadline,
    CardContent,
    CardMeta,
    CardInfoRow,
    CardPreHeadline
} from 'application/components/fragments/cards'

export const VehicleCard = ({
    vehicle,
    stretch = false,
    children = null
}) => {
    const {t} = useTranslation()
    const hasVehicle = exists(vehicle?.licensePlate) || exists(vehicle?.make) || exists(vehicle?.model) || exists(vehicle?.chassisNumber)

    if(!hasVehicle) {
        return <React.Fragment />
    }

    return <CardWrapper stretch={stretch}>
        <CardContent>
            <CardPreHeadline>
                {t('Vehicle data')}
            </CardPreHeadline>
            {
                exists(vehicle?.licensePlate) && <CardHeadline><LicensePlate licensePlate={vehicle.licensePlate}/></CardHeadline>
            }
            {
                (exists(vehicle?.make) || exists(vehicle?.model)) && <CardInfoRow icon='drive_eta' hasContent={true}>
                    {vehicle?.make} {vehicle?.model}
                </CardInfoRow>
            }
            {
                exists(vehicle?.chassisNumber) && <CardInfoRow icon='tag' hasContent={true}>
                    {vehicle?.chassisNumber}
                </CardInfoRow>
            }
        </CardContent>
        {
            notNull(children) && <CardMeta>{children}</CardMeta>
        }
    </CardWrapper>
}