import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput } from 'application/components/controls/form'
import { useStatefulForm } from 'application/components/hooks'
import { TwoColumns } from 'application/components/forms/_common'

const DamagePoliceForm = ({formData = {}, damageId, onSave, disabled}) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        precinct: formData?.policeRecord?.precinct || '',
        fileNumber: formData?.policeRecord?.fileNumber || ''
    }

    const {register, errors, handleSubmit, getValues, formStateValues, updateFormState} = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <React.Fragment>
        <TwoColumns 
            columnLeft={
                <TextInput 
                    label={t('Police group')}
                    name='precinct'
                    register={register}
                    validate={null}
                    error={errors.precinct}
                    onChange={updateFormState}
                    disabled={disabled}
                />
            }
            columnRight={
                <TextInput 
                    label={t('File number')}
                    name='fileNumber'
                    register={register}
                    validate={null}
                    error={errors.fileNumber}
                    onChange={updateFormState}
                    disabled={disabled}
                />
            }
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save police data')} 
                disabled={false} 
                onButtonClick={handleSubmit(onSubmitSuccess)} />
        </FlexBox>
        
    </React.Fragment>
}

export { DamagePoliceForm }