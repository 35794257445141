import React from 'react'
import styled from 'styled-components'
import { ToastContainer, Flip, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const StyledToast = styled.div`
    & .Toastify__toast{
        min-height: 35px;
        padding-top: 9px;
        & .Toastify__toast-body{
            margin-top: -2px;
        }
    }
    & .Toastify__toast-container--top-center{
        width: 420px;
        margin-left: -210px;
        padding-top: 0;
        top: 0;
        right: 0;
        @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
            margin-left: 0;
            width: 100vw;
        }
    }
    & .Toastify__toast-container--top-left{
        width: 420px;
        padding-top: 0;
        padding-left: 0;
        top: 0;
        left: 0;
        @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
            width: 100vw;
        }
    }
    & .Toastify__toast--success{
        padding: 16px;        
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        min-height: 50px;
    }

    & .Toastify__toast--error{
        padding: 16px;        
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        min-height: 50px;
    }
`

export const Toaster = () => {
    return <StyledToast>
        <ToastContainer 
            autoClose={1200} 
            position={toast.POSITION.BOTTOM_RIGHT} 
            transition={Flip} 
        />
    </StyledToast>
}