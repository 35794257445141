import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, setQuery, deleteQuery } from 'application/components/hooks/use_query'
import { IconButton } from 'application/components/controls/icon_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists } from 'application/common/data_helpers'
import { Label } from 'application/components/fragments/typography'
import { useNavigate, useLocation } from 'react-router-dom'
import { TextInputPureComponent } from 'application/components/controls/form/text_input'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { Spacer } from 'application/components/pages/_common'
import { ActionButton } from 'application/components/controls/action_button'

export const DamageIdleTimeFilter = (
    { 
        routeParameters
    }
) => {
    const query = useQuery()
    const { FILTERS, OFFSET, LIMIT } = routeParameters
    const routeIdleTime = FILTERS.IDLE_TIME
    
    const selectedIdleTime = query.getStringParam(routeIdleTime)
    const [idleTime, setIdleTime] = useState(exists(selectedIdleTime) ? selectedIdleTime : '0')
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()


    const handleSelectIdleTime = useCallback(value => {
        setQuery(
            navigate, 
            location, 
            location.pathname, 
            [
                {
                    key: routeIdleTime,
                    value: value
                }
            ], 
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
    }, [LIMIT, OFFSET, location, navigate, routeIdleTime])

    // useEffect(()=>{
    //     handleSelectIdleTime(idleTime)
    // },[handleSelectIdleTime, idleTime])

    const handleResetIdleTime = () => {
        deleteQuery(
            navigate, 
            location, 
            location.pathname,
            [routeIdleTime, OFFSET, LIMIT],
            true
        )
    }

    return <FilterFragment.FilterRow>

        <FilterFragment.FilterLabel>
            <Label cssMargin='0 16px 0 0'>
                {t('No changes in the last X days')}
            </Label>
        </FilterFragment.FilterLabel>


        <FilterFragment.FilterAction>
            <FlexBox width='60px'>
                <TextInputPureComponent 
                    inputType="number" 
                    value={idleTime} 
                    onChange={e => {
                        setIdleTime(e.target.value)
                    }}
                    controlled={true}
                />
            </FlexBox>
            <Spacer basis='8px' />
            <ActionButton
                buttonText={t('Apply')}
                onButtonClick={e => {
                    handleSelectIdleTime(idleTime)
                }}
            />
        </FilterFragment.FilterAction>

        {
            exists(selectedIdleTime) 
                    && <FilterFragment.FilterMeta>
                        <InfoBox
                            direction='row' 
                            cssMargin='0'
                        >
                            <span>{t('No changes in the last')} <strong>{selectedIdleTime}</strong> {t('days')}</span>
                    
                            <Spacer />
                            <IconButton 
                                iconKey='close' 
                                width='12px' 
                                height='16px' 
                                onButtonClick={handleResetIdleTime} 
                            />
                        </InfoBox>
                    </FilterFragment.FilterMeta>
        }

    </FilterFragment.FilterRow>

}
