import React from 'react'
import styled, { withTheme } from 'styled-components'


const round = (number) => Math.round((( number ) + Number.EPSILON) * 100) / 100

const calculateWidth = (column, columns) => {
    if(isNaN(column.width)){
        return column.width
    }
    const overallWidth = columns.map(c => c.width).reduce((partialSum, a) => partialSum + a, 0)
    const widthMod = round(100 / overallWidth)
    const newWidth = round(column.width * widthMod)
    return `${newWidth}%`
}



export const StyledTableCell = styled.div`
    text-decoration: none !important;
    flex: 0 0 ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    min-width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    max-width: ${props => props.cssMaxWidth !== undefined ? props.cssMaxWidth : '100%'};
    font-size: ${props => props.isRow ? props.theme.fontSize.standard : props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    display: flex;
    flex-direction: ${props => props.column ? 'column' : 'row'};
    flex-grow: ${props => props.flexGrow ? props.flexGrow : '1'};
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    align-items: center;
    word-break: break-all;

    @media (max-width: ${props => props.breakpoint}px) {
        display: none;
    }
`



const TableCellComponent = ({
    column, //current column
    children,
    configuration,
    theme
}) => {
    const width = calculateWidth(column, configuration.columns)
    const breakpoint = column?.breakpoint || '0'

    return <StyledTableCell 
        justify={column.justify} 
        cssWidth={width}
        breakpoint={breakpoint}
    >
        { children }
    </StyledTableCell>
}

export const TableCell = withTheme(
    TableCellComponent
)