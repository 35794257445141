import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, HiddenField, PureSelect } from 'application/components/controls/form'
import { FormElementWrapper, FormElementGroup } from 'application/components/controls/form/_common'
import { nameHelpers } from 'application/common'
import {CustomerCard} from 'application/components/building_blocks/customer_card'
import { FlexBox } from 'application/components/fragments/flex_box'
import { VehicleCard } from 'application/components/building_blocks/vehicle_card'
import { TwoColumns } from 'application/components/forms/_common'
import { exists } from 'application/common/data_helpers'


export const CustomerSearchComponent =  ({
    register,
    setValue,
    errors,
    customerList,
    name = 'customerSearch',
    hiddenFieldName = 'customerId',
    onDataChange = () => {},
    onChange = () => {},
    onSearchCustomer,
    dialog,
    isActive = true,
    initialCustomerData = null
}) => {
    const {t} = useTranslation()
    const [customerSearchFilter, setCustomerSearchFilter] = useState('lastName')
    const [customerSearchActive, setCustomerSearchActive] = useState(false)
    const [customerListState, setCustomerListState] = useState(customerList)
    const [existingCustomerData, setExistingCustomerData] = useState(initialCustomerData)

    useEffect(()=>{
        setCustomerListState(customerList)
    }, [customerList])
    
    useEffect(()=>{
        if(!isActive){
            setCustomerSearchFilter('lastName')
            setExistingCustomerData(null)
            setCustomerListState([])
        }
    }, [isActive])

    const searchOptions = [{
        value: 'lastName',
        label: t('Last name')
    },{
        value: 'companyName',
        label: t('Company name')
    },{
        value: 'licensePlate',
        label: t('License plate')
    },{
        value: 'chassisNumber',
        label: t('Chassis number')
    }]

    const selectedSearchOption = searchOptions.find(o => o.value === customerSearchFilter)

    const getOptionLabel = customer => {
        if(exists(customer?.preferredVehicleInfo?.chassisNumber) && exists(customer?.preferredVehicleInfo?.licensePlate)){
            return `${nameHelpers.getFullName(customer)} ( ${customer.preferredVehicleInfo.licensePlate} / ${customer.preferredVehicleInfo.chassisNumber} )`
        }
        if(exists(customer?.preferredVehicleInfo?.licensePlate)){
            return `${nameHelpers.getFullName(customer)} ( ${customer.preferredVehicleInfo.licensePlate} )`
        }
        if(exists(customer?.preferredVehicleInfo?.chassisNumber)){
            return `${nameHelpers.getFullName(customer)} ( ${customer.preferredVehicleInfo.chassisNumber} )`
        }
        return nameHelpers.getFullName(customer)
    }

    const customerOptions = customerListState.map(b => ({
        label: getOptionLabel(b),
        value: b.id
    }))

    const handleSearchCustomer = search => {
        if(customerSearchActive && search.length > 2){
            onSearchCustomer(dialog.name, search, customerSearchFilter)
        }  
    }
 
    return <React.Fragment>
        <HiddenField name={hiddenFieldName} register={register} />
        <FlexBox>
            <FormElementWrapper>
                <label>{t('Search for existing customer')}</label>
                <FormElementGroup>
                    <PureSelect 
                        options={searchOptions}
                        value={selectedSearchOption}
                        onChange={option => {
                            setCustomerSearchFilter(option.value)
                            setCustomerListState([])
                            setExistingCustomerData(null)
                            setValue(name, null)
                        }}
                        cssFlexBasis='200px'
                        isSearchable={false}
                    />
                    <Select 
                        register={register}
                        name={name}
                        validate={null}
                        setValue={setValue}
                        options={customerOptions} 
                        onChange={option => {
                            const customerId = option === null ? '' : option.value
                            onDataChange()
                            onChange(customerId, option === null ? null : customerList.find(b => b.id === option.value))
                            setValue(hiddenFieldName, customerId)
                            setExistingCustomerData(option === null ? null : customerList.find(b => b.id === option.value))
                        }}
                        onBlur={()=>{setCustomerSearchActive(false)}}
                        onFocus={()=>{setCustomerSearchActive(true)}}
                        onInputChange={handleSearchCustomer}
                        noOptionsMessage={t('No customers found')}
                        placeholder={t('Enter at least 3 characters')}
                        isLoading={dialog.payload.customerListLoading && customerSearchActive}
                        isClearable={true}
                        error={errors[name]}
                        errorMessageOverride='Select an existing customer'
                        defaultValue={customerOptions.find(b => b.value === initialCustomerData?.id)}
                    />
                </FormElementGroup>
            </FormElementWrapper>
        </FlexBox>
        
        {
            existingCustomerData !== null && <TwoColumns 
                columnLeft={
                    <CustomerCard 
                        customer={existingCustomerData} 
                        cssMargin='8px 0 0 0'
                        stretch={true}
                        showQrCodeLink={false}
                    />
                }  
                columnRight={
                    <VehicleCard 
                        vehicle={existingCustomerData.preferredVehicleInfo} 
                        stretch={true}
                    />
                }
            />
        }
    </React.Fragment>
}