import { connect } from 'react-redux'
import { MassOperationsDocumentSelect as MoDocumentComponent } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { downloadAttachment } from 'application/redux/actions/attachments'


const mapStateToProps = (state) => {
    return {
        categories: state.common.categories,
        permissions: state.auth.permissions
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onOpenMoDialog: (
            dialogName,
            initiallyLoaded,
            payload,
            dialogTitle
        ) => {

            dispatch(
                openDialogByName(
                    dialogName,
                    initiallyLoaded,
                    payload,
                    dialogTitle
                )
            )
        },
        downloadAttachment: (
            id,
            attachmentName,
            damageId
        ) => dispatch(
            downloadAttachment(
                id,
                attachmentName,
                damageId
            )
        ),
    }
}

export const MassOperationsDocumentSelect = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoDocumentComponent) 