import styled from 'styled-components'

export const ActivityWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding: 12px 0px;
    border-bottom: 1px solid ${props => props.theme.color.gray20}
`



export const StyleDisplayToggle = styled.div`
    display: ${props => props.active ? 'block' : 'none'}
`