import { connect } from 'react-redux'
import { logoutRequest } from 'application/redux/actions/auth'
import {SimpleTitleBar} from './component'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logoutRequest())
    }
}

export const TitleBarSimple = connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleTitleBar)