
import React from 'react'
import {StyledTableRowLink} from './fragments'
import { withTheme } from 'styled-components'

const LinkedTableRowComponent = (
    {
        damageId,
        isColoured = false,
        theme,
        children,
        href,
        cssHeight
    }
) => {
    const rowColor = isColoured
        ?  theme.color.gray5
        :  theme.color.white

    return <StyledTableRowLink
        key={damageId}
        rowcolor={rowColor}
        bordercolor={rowColor}
        to={href}
        cssheight={cssHeight}
    >
        {children}
    </StyledTableRowLink>
}

export const LinkedTableRow = withTheme(LinkedTableRowComponent)