import { setSharedResourceData, getSharedResourceData } from 'application/redux/actions/shared_resource'
import { connect } from 'react-redux'
import {LayoutBaseSharedResource} from './layout_base_shared_resource'

const mapDispatchToProps = dispatch => {
    return {
        onSaveSharedResourceData: (id, type) => {
            dispatch(setSharedResourceData(id, type))
        },
        onLoadSharedResourceData: () => {
            dispatch(getSharedResourceData())
        }
    }
}

export const LayoutSharedResource = connect(
    state => ({
        sharedResource: state.sharedResource,
        waitingScreen: state.waitingScreen,
        dialogsOpen: state.dialogs.dialogsOpen
    }),
    mapDispatchToProps
)(LayoutBaseSharedResource)