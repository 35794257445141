import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledDamageDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background: linear-gradient(90deg, rgba(14,145,193,1) 0%, rgba(40,72,152,1) 100%);
    padding: 8px 16px;
    border-radius: 5px;
    align-items: center;
`

export const StyledSimpleDescriptionRow = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    color: white;
    margin-top: 7px;
    //letter-spacing: .1rem;
`

export const StyledDescriptionRow = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 12px;
`

export const StyledDescriptionLabel = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    color: white;
    font-weight: 300;
    margin-bottom: 4px;
`

export const StyledDescriptionText = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    color: white;
    font-weight: bold;
`

export const StyledDamageLinkContainer = styled.div`
    width: 100%;
    align-items: flex-start;
    position: relative;
    margin-top: 32px;
`

export const StyledDamageLink = styled(NavLink)`
    width: 168px;
    display: flex;
    flex-direction: row;
    background: white;
    position: absolute;
    text-decoration: none;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallBold};
    color: ${props => props.theme.color.gray70};
    border-radius: 6px;
    height: 35px;
    border: 1px solid ${props => props.theme.color.gray15};
    align-items: center;
    justify-content: center;
    top: -12px;
    left: 0;

    &:hover{
        background: ${props => props.theme.color.gray5};
    }
`
