import { all, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetDeclarationOfAsssigmentDataSaga(action) {

    const { 
        dialogName, 
        metaData
    } = action
    
    const { 
        damageId
    } = metaData

    const [
        customer,
        opponentInsuranceData
    ] = yield all(
        [
            resourceCall(
                api.gateway.damage.getVehicleKeeperByDamageId, 
                {
                    damageId
                }
            ),
            resourceCall(
                api.gateway.damage.getLiabilityData, 
                { damageId }
            )
        ]        
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                customer,
                opponentInsuranceData
            }
        )
    )
}