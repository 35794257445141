import { connect } from 'react-redux'
import {UploaderComponent} from './component'
import { showToast } from 'application/redux/actions/notifications'
import { NOTIFICATION } from 'application/constants'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onFilesRejected: (files) => {
            dispatch(
                showToast(
                    'Invalid files have been removed', 
                    NOTIFICATION.LEVEL.WARNING
                )
            )
            for(const file of files){
                dispatch(
                    showToast(
                        file.name, 
                        NOTIFICATION.LEVEL.WARNING
                    )
                )
            }
        }
    }
}

export const DragAndDropUploader = connect(
    mapStateToProps,
    mapDispatchToProps
)(UploaderComponent)