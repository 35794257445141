import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { Link } from 'application/components/controls/hyperlink'
import { ROUTES, ACTIVITY_RELATED_ENTITY_TYPES } from 'application/constants'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

export const InvoiceCreatedActivity = ({ activity }) => {
    const { t } = useTranslation()
    const damageId = activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id
    const { damageManagerLink } = useApplicationLinks()

    const link = damageManagerLink(
        ROUTES.DAMAGE_MANAGER_INVOICES,
        damageId
    )

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Invoice created')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>{t('Bill number')}: {activity.data.number}</Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph><Link to={link}>{t('show invoices')}</Link></Fragments.ActivityParagraph>
    </React.Fragment>
}