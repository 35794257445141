import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetSubHeadline, WidgetErrorMessage, WidgetInformation, WidgetTextCentered } from 'application/components/widgets/_common'
import { StaticCollapsable } from 'application/components/controls/collapsable'
import { RepairClearanceName } from 'application/components/name_mappings/repair_clearance'
import { RepairAuthorizationIndicator } from 'application/components/controls/repair_authorization_indicator'




const DamageAKRepairClearance = (props) => {

    const {t} = useTranslation()

    const {        
        payload, 
        loadingFailed, 
        isLoaded
    } = props

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }
    
    if(isLoaded && !payload.hasData){
        return <WidgetInformation>
            {t('No information regarding repair clearance yet')}
        </WidgetInformation>
    }

    const {data} = payload
    const {repairAuthorization} = data

    return <React.Fragment>
        <WidgetSubHeadline>
            {t('Repair clearance')}
        </WidgetSubHeadline>
        <StaticCollapsable>
            <WidgetTextCentered>
                <RepairAuthorizationIndicator 
                    repairAuthorization={repairAuthorization}
                />
                &nbsp;
                <RepairClearanceName 
                    repairClearanceType={repairAuthorization} 
                />
            </WidgetTextCentered>
        </StaticCollapsable>
       
       
    </React.Fragment>
}

DamageAKRepairClearance.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageAKRepairClearance }