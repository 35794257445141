import { STORAGE } from 'application/constants'

export const sharedResourcePin = {
    store: (id, pin) => {
        const oldStorageEntry = localStorage.getItem(STORAGE.SHARED_RESOURCE_PINS)
        localStorage.removeItem(STORAGE.SHARED_RESOURCE_PINS)

        const newStorageEntry = {
            pins: oldStorageEntry === null
                ? [{
                    id,
                    pin
                }]
                : [...(JSON.parse(oldStorageEntry)).pins.filter(p => p.id !== id), {
                    id,
                    pin
                }]
        } 
        
        localStorage.setItem(STORAGE.SHARED_RESOURCE_PINS, JSON.stringify(newStorageEntry))
    },
    get: (id) => {
        const storageEntry = localStorage.getItem(STORAGE.SHARED_RESOURCE_PINS)
        if(storageEntry === null){
            return ''
        }
        const parsedStorageEntryPins = (JSON.parse(storageEntry)).pins
        const pinEntry = parsedStorageEntryPins.find(p => p.id === id)

        if(pinEntry === undefined){
            return ''
        }

        return pinEntry.pin
    },
    delete: (id) => {
        const oldStorageEntry = localStorage.getItem(STORAGE.SHARED_RESOURCE_PINS)
        if(oldStorageEntry !== null){
            const newStorageEntry = {
                pins: (JSON.parse(oldStorageEntry)).pins.filter(p => p.id !== id)
            }

            localStorage.setItem(STORAGE.SHARED_RESOURCE_PINS, JSON.stringify(newStorageEntry))   
        }
    },
    deleteStorage: () => {
        localStorage.removeItem(STORAGE.SHARED_RESOURCE_PINS)
    }
}