import React from 'react'
import { Table } from 'application/components/tables/refactored/_common'
import { configuration } from './configuration'

export const TableDamagesLastCreated = (
    {
        damagesListLoading,
        damagesListLoaded,
        damagesListTotal,
        damagesList,
    }
) => {
    return <Table 
        configuration={configuration}
        elementsLoading={damagesListLoading}
        elementsTotal={damagesListTotal}
        elementsLoaded={damagesListLoaded}
        elements={damagesList}
        emptyMessage='No damages existing yet'
    />
}