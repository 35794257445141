import { connect } from 'react-redux'
import { EditGroupComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSaveGroup: (dialogName, groupId, groupData) => dispatch(sendPayloadRequest(dialogName, groupData, {groupId}))
    }
}

export const EditGroup = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditGroupComponent)