
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailBox, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'



export const VehicleKeeperChangedDetails = (
    {
        activity
    }
) => {

    const oldName = activity.data?.oldVehicleKeeperFullName || ''
    const newName = activity.data?.newVehicleKeeperFullName || ''

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={activity.data.damage.licensePlate} 
        />
        <ActivityDetailBox>
            {oldName} <RightArrow/> {newName}    
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

VehicleKeeperChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}