import React from 'react'
import {DocumentSidebar} from 'application/components/building_blocks/document_sidebar'
import { useMO } from 'application/components/hooks'
import { MASS_OPERATIONS } from 'application/constants'

export const DamageManagerDocumentsSidebarComponent = (
    {
        documentCenterAttachmentDetails, 
        onHideAttachmentDetails, 
        onChangeCategory,
        damageId
    }
) => {
    const {
        moIsActive
    } = useMO(`${MASS_OPERATIONS.TYPE.DOCUMENTS}_${damageId}`)

    return !moIsActive
        && documentCenterAttachmentDetails.visible 
        && <DocumentSidebar
            attachmentDetails={documentCenterAttachmentDetails}
            onClose={onHideAttachmentDetails}
            showCategorySelect={true}
            onChangeCategory={onChangeCategory}
        />
}