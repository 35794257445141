import { put, takeLeading, select } from 'redux-saga/effects'
import { ACTIONS, MODULE_NAME, MODULE_SCOPE } from 'application/constants'
import { populateModules } from 'application/redux/actions/modules'
import { getDekraFlag } from 'application/redux/selectors'
import { FEATURE_FLAGS } from 'application/constants/feature_flags'
import { populateFeatureFlags } from 'application/redux/actions/feature_flags'
// import { resourceCall } from '../../helper/resource_call_decorator'
// import { api } from 'application/api'

export function* requestModulesSaga(action) {
    try {
        const {accountId} = action

        //feature flags
        const CONTRACTORS_UI_AVAILABLE = parseInt(process.env.REACT_APP_CONTRACTORS_EDITOR_AVAILABLE) === 1

        const activeFeatures = []

        if(CONTRACTORS_UI_AVAILABLE){
            activeFeatures.push(FEATURE_FLAGS.CONTRACTORS_UI)
        }

        yield put(populateFeatureFlags(activeFeatures))

        //modules

        // const moduleResponse = yield resourceCall(
        //     api.gateway.modules.getModules
        // )

        // console.log('TN::: modules', moduleResponse)

        var exampleStructure = {
            'modules': {
                '137938004579713024': [
                    {
                        'id': '10010',
                        'name': 'QrCodeModule',
                        'scope': 'Branch',
                        'settings': {
                            'lifeTime': '10'
                        }
                    },
                    {
                        'id': '10',
                        'name': 'DekraModule',
                        'scope': 'Account',
                        'settings': {
                            'customerNumber': '1545'
                        }
                    },
                    {
                        'id': '777',
                        'name': 'ClipboardSignatureModule',
                        'scope': 'Account',
                        'settings': {}
                    }
                ],
                '137938004663599104': [
                    {
                        'id': '10030',
                        'name': 'WebakteModule',
                        'scope': 'Branch',
                        'settings': {}
                    },
                    {
                        'id': '10',
                        'name': 'DekraModule',
                        'scope': 'Account',
                        'settings': {
                            'customerNumber': '1545'
                        }
                    },
                    {
                        'id': '666',
                        'name': 'AnotherAccountModule',
                        'scope': 'Account',
                        'settings': {
                            'customerNumber': '1545'
                        }
                    },
                    {
                        'id': '777',
                        'name': 'ClipboardSignatureModule',
                        'scope': 'Account',
                        'settings': {}
                    }
                ]
            }
        }

        const accountModules = Object.entries(exampleStructure.modules).reduce((previousBranchModules, currentBranches) => {
            const [, currentBranchModules] = currentBranches

            const moreAccountModules = currentBranchModules
                .filter(module => module.scope === MODULE_SCOPE.ACCOUNT)
                .filter(module => !previousBranchModules.find(prevModule => prevModule.id === module.id))

            return [...previousBranchModules, ...moreAccountModules]
        }, [])

        const branchModules = Object.entries(exampleStructure.modules).map(branchObject => {
            const [branchId, modules] = branchObject
            return {
                branchId,
                modules
            }
        })

        //for now, only apply mocked zyrull modules & provisional feature flags & DEKRA
        const ZYRULL_ACCOUNT_IDS = process.env.REACT_APP_QR_STICKER_ACCOUNT_IDS.split(',') || []
        const CUSTOMER_BATCH_IMPORT_ACCOUNT_IDS = process.env.REACT_APP_CUSTOMER_BATCH_IMPORT_ACCOUNT_IDS.split(',') || []
        const canCommissionDekra = yield select(getDekraFlag)

        let appliedModules = ZYRULL_ACCOUNT_IDS.some(id => id === accountId)
            ? [
                {
                    name: MODULE_NAME.ZYRULL_QR_CODE_STICKER,
                    scope: MODULE_SCOPE.BRANCH
                },
                {
                    name: MODULE_NAME.SMS_MESSAGE_CHANNEL,
                    scope: MODULE_SCOPE.ACCOUNT
                },
            ]
            : []

        if(CUSTOMER_BATCH_IMPORT_ACCOUNT_IDS.some(id => id === accountId)){
            appliedModules.push({
                name: MODULE_NAME.BATCH_CUSTOMER_IMPORT,
                scope: MODULE_SCOPE.ACCOUNT
            })
        }

        if(canCommissionDekra){
            appliedModules.push({
                name: MODULE_NAME.DEKRA_COMMISSION,
                scope: MODULE_SCOPE.ACCOUNT
            })
        }

        appliedModules.push({
            name: MODULE_NAME.CLIPBOARD_SIGNATURE,
            scope: MODULE_SCOPE.ACCOUNT
        })

        yield put(
            populateModules(
                accountId,
                appliedModules,
                accountModules,
                branchModules
            )
        )
    } catch (e) {

    }
}

export function* modulesWatcher() {
    yield takeLeading(
        ACTIONS.REQUEST_MODULES,
        requestModulesSaga
    )
}
