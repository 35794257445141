import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getNotificationSettingsOptions = () => {

    return {
        authenticated: () => {
            const request = fetchRequestBuilder('notifications', HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null
    }
}