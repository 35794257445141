import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageComprehensiveDamageTypeChangedActivity = ({activity}) => {
    const {t} = useTranslation()

    const oldTypeName = activity.data?.oldType?.name || ''
    const newTypeName = activity.data?.newType?.name || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Comprehensive damage type changed')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>{oldTypeName}<RightArrow />{newTypeName}</Fragments.ActivityParagraph>
    </React.Fragment>
}