import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetBranchKickstartResourceSaga(action) {

    const { 
        dialogName, 
        metaData
    } = action

    const { 
        branch
    } = metaData
    
    const createBranchKickstartResponse = yield resourceCall(
        api.gateway.branches.postCreateQrCode, 
        { branchId: branch.id }
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                sharedResourceId: createBranchKickstartResponse.proxyShareId
            }
        )
    )
}