import { exists } from './data_helpers'

export const getFullName = (user, leadingLastName = false) => {
    if(user === null || user === undefined){
        return ''
    }

    if(exists(user.companyName)){
        return user.companyName
    }
    
    const lastName = user.lastName !== undefined && user.lastName !== null 
        ? user.lastName 
        : user.name !== undefined && user.name !== null 
            ? user.name 
            : user.bearbeiterLastName !== undefined && user.bearbeiterLastName !== null 
                ? user.bearbeiterLastName 
                : ''

    const firstName = user.firstName !== undefined && user.firstName !== null 
        ? user.firstName 
        : user.vorname !== undefined && user.vorname !== null 
            ? user.vorname 
            : user.bearbeiterFirstName !== undefined && user.bearbeiterFirstName !== null 
                ? user.bearbeiterFirstName 
                : ''
                
    if(firstName === ''){
        return lastName
    }

    if(leadingLastName){
        return `${lastName} ${firstName}`.trim()
    }
    
    return `${firstName} ${lastName}`.trim()
}

export const getActivityBearbeiterName = (activity) => {
    if(activity.externalUser !== null && activity.externalUser !== undefined && activity.externalUser.lastName !== undefined && activity.externalUser.lastName !== null && activity.externalUser.lastName !== ''){
        return activity.externalUser.lastName
    }
    if(activity.user === null || activity.user === undefined){
        return 'unbekannter Absender'
    }
    if(activity.user.lastName !== null && activity.user.lastName !== undefined && activity.user.lastName !== ''){
        if(activity.user.firstName !== null && activity.user.firstName !== undefined && activity.user.firstName !== ''){
            return `${activity.user.firstName} ${activity.user.lastName}`
        }
        return activity.user.lastName
    }
    return 'unbekannter Absender'
}


/**
 *@param anwalt object - return name (String) of given Anwalt from Damage 
 */
export const getAnwaltName = (anwalt) => {
    const noAnwaltExistsFeedback = 'Kein Anwalt beauftragt'

    if ( !exists(anwalt) ) return noAnwaltExistsFeedback

    return exists(anwalt.ansprechpartner) 
        ? anwalt.ansprechpartner 
        : noAnwaltExistsFeedback
}