import React from 'react'
import { withRouter } from 'application/components/higher_order_components/with_router'
import { SHARED_RESOURCE_TYPE } from 'application/constants'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation'
import { KickstartExternalContent } from './content'

export const KickstartExternalComponent = withSharedResourceValidation()(withRouter((
    {
        sharedResource,
        damageDataSent,
        damageDataSending,
        onCreateDamageKickstart
    }
) => {
    const dataLoaded = sharedResource.dataLoaded
    return <LayoutSharedResource
        sharedResourceType={SHARED_RESOURCE_TYPE.KICKSTART}
        dataInitiallyLoaded={false}
    >        
        {
            dataLoaded && <KickstartExternalContent 
                onCreateDamageKickstart={onCreateDamageKickstart}
                sharedResource={sharedResource}
                damageDataSending={damageDataSending}
                damageDataSent={damageDataSent}
            />
        }
    </LayoutSharedResource>
}))