import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { put } from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* deleteBranchFromDialogSaga(action) {

    const {branchId} = action.metaData
    
    yield resourceCall(
        api.gateway.branches.deleteBranchById, 
        {
            branchId
        }
    )

    yield put(
        showToast('Branch removed')
    )

}