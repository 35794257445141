import React from 'react'
import { RelatedDamageLink, RelatedInvoiceLink } from 'application/components/controls/activity_links/common'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'

export const PaymentActivityLinks = (
    {
        activity
    }
) => {
    return <ForAnyBranchPermissions permissions={[USER_PERMISSONS.DAMAGE.READ, USER_PERMISSONS.INVOICE.READ]} allNeeded={true}>
        <RelatedDamageLink activity={activity} />
        <RelatedInvoiceLink activity={activity} />
    </ForAnyBranchPermissions>
}