import React, {useState} from 'react'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import styled from 'styled-components'
import uniqid from 'uniqid'
import { Controller } from 'react-hook-form'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'

const StyledTextInputWithLabel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${props => props.pushBottom ? '8px' : '0px'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};


    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }

    .intl-tel-input input {
        color: ${props => props.theme.color.anthracite};
        border: 1px solid ${props => props.theme.color.gray15};
        height: 38px;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        border-radius: 0px;
        outline: 0;
        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
            height: 41px;
            font-size: ${props => props.theme.fontSize.medium18};
        }
        &:hover{
            border: 1px solid ${props => props.disabled ?  props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red   : props.theme.color.lightPowderGray};
        }
    }

    .intl-tel-input .country-list{
        width: 100%;
        top: ${props => props.dropdownPosition === 'bottom' ? '31px' : '-199px'};
    }
`

export const PhoneNumberInput = ({ 
    name, 
    onChange, 
    control, 
    validate, 
    error, 
    hideValidationMessage = false, 
    errorMessageOverride = '', 
    label = '', 
    defaultValue = '', 
    setValue, 
    dropdownPosition='top',
    allowLandLine=false
}) => {
    const { getNumberType, numberType } = window.intlTelInputUtils
    const getNumberTypeString = (type) => Object.keys(numberType).find(key => numberType[key] === type)

    const isValidNumberType = (fullNumber) => {
        if(fullNumber === ''){
            return true
        }
        const currentNumberType = getNumberTypeString(getNumberType(fullNumber))
        if(allowLandLine){
            return currentNumberType === 'MOBILE' 
            || currentNumberType === 'FIXED_LINE_OR_MOBILE' 
            || currentNumberType === 'FIXED_LINE'
        }
        return currentNumberType === 'MOBILE' 
        || currentNumberType === 'FIXED_LINE_OR_MOBILE'
    }

    const validationMessage = allowLandLine 
        ? 'Valid phone number required' 
        : 'Valid mobile number required'

    const [isValid, setIsValid] = useState(true)
    const mergedValidate = {
        validatePhone: () => {
            return isValid || validationMessage
        },
        ...validate
    }
    const htmlId = uniqid()
    const handleChange = v => onChange(v)
    
    return <StyledTextInputWithLabel dropdownPosition={dropdownPosition}>
        <label 
            className='label' 
            htmlFor={htmlId}
        >
            {label}
            <RequiredFieldMarker 
                validate={mergedValidate} 
            />
        </label>
        <Controller
            control={control}
            valueName={name}
            name={name}
            rules={{ validate: mergedValidate }}
            defaultValue={defaultValue}
            render={
                ({
                    field: { onChange }
                }) => (
                    <IntlTelInput
                        nationalMode={false}
                        format={false}
                        fieldName={name}
                        defaultValue={defaultValue}
                        onPhoneNumberChange={
                            (valid, val, countryData, fullNumber) => {
                                setValue(name, fullNumber)
                                setIsValid((valid || val === '') && isValidNumberType(fullNumber))
                                onChange(fullNumber)
                                handleChange(fullNumber)
                            }
                        }
                        defaultCountry='de'
                        fieldId={htmlId}
                        autoHideDialCode={true}
                        separateDialCode={false}
                        preferredCountries={['de', 'at', 'ch']}
                        placeholder={ 
                            allowLandLine 
                                ? '+49 30 3432622-113'
                                : undefined
                        }
                    />
                )
            }
        />

        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </StyledTextInputWithLabel>
}