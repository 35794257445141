import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetSubHeadline, WidgetErrorMessage, WidgetInformation, WidgetText } from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'
import { StateName } from 'application/components/name_mappings/state_name'




const DamageAKAktenStatus = (props) => {
    const {
        payload, 
        loadingFailed, 
        isLoaded
    } = props

    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }
    
    if(isLoaded && !payload.hasData){
        return <WidgetInformation>
            {t('No information regarding file status yet')}
        </WidgetInformation>
    }

    const {data} = payload
    const status = data?.caseFileInfo?.status

    return <React.Fragment>
        <WidgetSubHeadline>
            {t('Aktenstatus')}
        </WidgetSubHeadline>
        <Collapsable 
            initiallyCollapsed={false} 
            headline={t('Information')}
        >
            {
                status && <WidgetText>
                    {t('Akten status')}: <strong>
                        <StateName 
                            state={status}
                        />
                    </strong>
                </WidgetText>
            }
            <br/>
        </Collapsable>       
    </React.Fragment>
}

DamageAKAktenStatus.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageAKAktenStatus }