import React from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { ErrorBox, ErrorLabel } from 'application/components/fragments/error_information'
import { exists } from 'application/common/data_helpers'
import PropTypes from 'prop-types'




export const ValidationErrorMessage = (
    {
        hideValidationMessage = false,
        hideValidationBox = false,
        error = null,
        errorMessageOverride = ''
    }
) => {
    const {t} = useTranslation()

    const message = errorMessageOverride !== '' 
        ? errorMessageOverride 
        : error !== null 
        && exists(error.message) 
            ? error.message 
            : ''

    const showMessage = !hideValidationMessage && error !== null

    return <React.Fragment>
        {
            !hideValidationBox && <ErrorBox>
                {
                    showMessage && <ErrorLabel>
                        {
                            i18n.exists(message) 
                                ? t(message) 
                                : message
                        }
                    </ErrorLabel>
                }
            </ErrorBox>
        }
    </React.Fragment>
}



ValidationErrorMessage.propTypes = {
    hideValidationMessage: PropTypes.bool,
    error:  PropTypes.object,
    errorMessageOverride: PropTypes.string
}