
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'

const DamageStateDetails = (
    {activity}
) => {
    const {
        name
    } = activity.data

    return <ActivityDetailWrapper>
        <ActivityDetail label='Status name' value={name} />
    </ActivityDetailWrapper>
}

DamageStateDetails.propTypes = {
    activity: PropTypes.object.isRequired
}

export {DamageStateDetails}