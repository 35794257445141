import { ActionButton } from 'application/components/controls/action_button'
import { ActivityCard } from 'application/components/controls/activity_card'
import {Collapsable} from 'application/components/controls/collapsable/component'
import { PushBottom24, SubPageLayout } from 'application/components/pages/_common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BoxActivity, ContainerActivityLog } from './fragments'
import { categorizedBasedTime} from './helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'




export const ActivityLogWorkflowPanelComponent = ({
    activities,
    total,
    activityLoading,
    onLoadMoreActivityLog
}) => {
    const {t} = useTranslation()
    const currentActivities = activities.length

    const ranges = categorizedBasedTime(activities)

    return <SubPageLayout>
        <ContainerActivityLog>
            <FlexBox
                flexDirection='column'
            >
                {
                    ranges.map((range, index) => {
                        const {items} = range
                        return <React.Fragment key={index}>
                            {
                                items.length > 0 && <React.Fragment>
                                    <Collapsable 
                                        headline={t(range.key)} 
                                        initiallyCollapsed={false} 
                                        background='#fff'
                                        padding='0'
                                    >
                                        <BoxActivity>
                                            {
                                                items.sort(
                                                    (a, b) => a.occurredAt < b.occurredAt 
                                                        ? 1 
                                                        : -1
                                                ).map((item, itemIndex)=>{
                                                    return <ActivityCard
                                                        key={itemIndex}
                                                        activity={item}
                                                    />
                                                })
                                            }
                                        </BoxActivity>
                                    </Collapsable>
                                    <PushBottom24 />
                                </React.Fragment>
                            }
                        </React.Fragment>
                    })
                }

                {
                    activityLoading 
                        ? <ThemedPreloader />
                        : <React.Fragment>
                            {
                                total > currentActivities && <ActionButton
                                    strechted={true}
                                    buttonText={t('More activities')}
                                    onButtonClick={() => onLoadMoreActivityLog(currentActivities)}
                                />
                            }
                        </React.Fragment>
                }
               
             
            </FlexBox>
        </ContainerActivityLog>
    </SubPageLayout>
}