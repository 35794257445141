import { connect } from 'react-redux'
import {MassOperationEffects} from './effects'
import {moRegister} from 'application/redux/actions/mass_operations'


const mapStateToProps = state => state



const mapDispatchToProps = dispatch => {
    return {
        onRegisterMassOperation: (
            moName, 
            moType
        ) => {
            dispatch(
                moRegister(
                    moName, 
                    moType
                )
            )
        }
    }
}

export const MassOperationPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(MassOperationEffects) 

