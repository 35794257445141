import React from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { SubPageLayout, PushBottom16 } from 'application/components/pages/_common'
import {MyTasksOverviewTable} from 'application/components/tables/common/tasks/all_branches/my_tasks'
import { configuration } from 'application/components/tables/common/tasks/all_branches/my_tasks/configuration'
import {USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { TaskStateFilterCheckboxGroup, ExternalFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'

const MyTasks = (
    { 
        tasksList, 
        tasksListLoading, 
        tasksListLoaded, 
        tasksListTotal, 
        onLoadTasksList,
        updateConstraint,
        moName,
    }
) => {

    return <SubPageLayout>
        <FilterFragment.FilterContainer>
            <TaskStateFilterCheckboxGroup
                pagination={configuration.pagination}
                disabled={tasksListLoading}
            /> 
            <ExternalFilterCheckboxGroup  
                pagination={configuration.pagination}
                disabled={tasksListLoading}
                spaceLeft={true}
            /> 
        </FilterFragment.FilterContainer>
        <ForAnyBranchPermissions 
            permissions={
                [
                    USER_PERMISSONS.TASK.DELETE
                ]
            } 
            allNeeded={false}
        >
            {/* <MassOperationPanel
                moName={moName}
                label={'Edit multiple tasks'}
                itemDesignation={'Task'}
                itemsDesignation={'Tasks'}
            >
               <MassOperationsTasksSelect
                    moName={moName}
                    permissionScope={PERMISSION_SCOPE.ALL_BRANCHES}
                />
            </MassOperationPanel>   */}
        </ForAnyBranchPermissions>
        <PushBottom16/>
        <MyTasksOverviewTable 
            tasksListLoading={tasksListLoading}
            tasksListLoaded={tasksListLoaded}
            tasksListTotal={tasksListTotal}
            tasksList={tasksList}
            moName={moName}
            onLoadTasks={onLoadTasksList}
            updateConstraint={updateConstraint}
        />
    </SubPageLayout>
}

export const MyTasksComponent = withRouter(MyTasks)