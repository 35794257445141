import { DIALOG } from 'application/constants'
import { sendPayloadRequest, switchDialog } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteCustomerComponent } from './component'
import { closeDialogByName } from 'application/redux/actions/dialogs'
import { openOnlyCurrentAccordeon } from 'application/redux/actions/main_navigation_sidebar'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteCustomer: (customerId, payload, successMsg) => {
            dispatch(
                sendPayloadRequest(
                    DIALOG.NAME.DELETE_CUSTOMER, 
                    { 
                        customerId, 
                        successMsg 
                    },
                    { 
                        ...payload
                    }
                )
            )
        },
        onEditCustomer: (
            nameToClose,
            nameToOpen,
            initiallyLoaded, 
            payload,
            title
        ) => {
            dispatch(
                switchDialog(
                    nameToClose,
                    nameToOpen,
                    initiallyLoaded, 
                    payload,
                    title
                )
            )
        },
        onCloseDialog: (dialogName) => {
            dispatch(
                closeDialogByName(dialogName)
            )
        },
        onOpenMenuAccordion: (accordionName) => {
            dispatch(
                openOnlyCurrentAccordeon(accordionName)
            )
        }
    }
}

export const DeleteCustomer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteCustomerComponent)