import React from 'react'
import { Page, Text, View, Image, Document} from '@react-pdf/renderer'
import {
    page,
    row,
    headerColumnLeft,
    field,
    kSchaden,
    fieldSmall,
    headerColumnRight,
    column16,
    center,
    zentralVerbandInfoColumn,
    headLine,
    telefaxInfoField,
    blackBorder,
    noteBoxNote,
    subItem,
    leftColumn,
    column,
    textAreaWithLabel,
    telField,
    checkBoxWithLabelSmall,
    checkBoxWithLabel,
    fontSize,
    rightColumn,
    paragraphRegular,
    signatureField
} from './styles'
import { exists } from 'application/common/data_helpers'


const fillCheckBox = (checked = false) => {
    return checked ? 'x' : ''
}


export const CostCoveragePdf = ({
    values = null
}) => {
    return <Document>
        <Page size="A4" style={[page]}>

            {/* header beginn */}
            <View style={[row]}>        

                {/* header leftcolumn */}
                <View style={[headerColumnLeft]}>

                    {/* field */}
                    <View style={[field.common]}>
                        <View style={[field.placeHolder]}>
                            <Text>Name des Versicherers</Text>
                        </View>
                        <View style={[field.value]}>
                            <Text>{values.insurance.name}</Text>
                        </View>
                    </View>

                    <View style={[kSchaden]}>
                        <Text>Abteilung K-Schaden</Text>
                    </View>

                    {/* field small*/}
                    <View style={[fieldSmall.common]}>
                        <View style={[fieldSmall.placeHolder]}>
                            <Text>Fax-Nummer oder E-Mail-Adresse des Versicherers:</Text>
                        </View>
                        <View style={[fieldSmall.value]}>
                            <Text>{values.insurance.fax}</Text>
                        </View>
                    </View>
                </View>

                {/* header rightcolumn */}
                <View style={[
                    headerColumnRight,
                    {
                        paddingLeft:'3mm',
                        paddingRight: '1mm',
                        flexDirection:'row'
                    }
                ]}>
                    <View style={[ column16,center]} >
                        <Image
                            style={ {width:'15mm'} }
                            source={ {uri: '/images/logos/kar.png'} }
                        />
                    </View>
                    <View style={[ 
                        zentralVerbandInfoColumn, 
                        center, 
                        {paddingLeft:'6'}
                    ]} >
                        <Text style={{fontSize:8}}>
                            Diese Übernahmebestätigung wird vom Zentralverband
                            Deutsches Kraftfahrzeuggewerbe e.V. (ZDK) und Zentralverband
                            Karosserie- und Fahrzeugtechnik e.V. (ZKF)
                            unverbindlich empfohlen.
                        </Text>
                    </View>
                    <View style={[ column16, center]} >
                        <Image
                            style={ {width:'15mm'} }
                            source={ {uri: '/images/logos/kfz-gewerbe.png'} }
                        />
                    </View>
                </View>

            </View> {/* header end */}  

            {/* Headline  */}  
            <View style={[headLine]}>
                <Text>Reparaturkosten-Übernahmebestätigung (Zahlungsanweisung)</Text>
            </View>

            {/* Telefax- / Hinweisbox  */}  
            <View style={[row]}>

                {/* Telefaxbox  */}  
                <View style={headerColumnLeft}>
                    <View style={telefaxInfoField}>
                        <View style={field.placeHolder}>
                            <Text style={{width:150}}>
                                Nach Bestätigung vom Versicherer zurückzusenden an
                                (Anschrift des Reparaturbetriebes)
                            </Text>
                        </View>
                        <View style={{flexDirection: 'column', fontSize: fontSize.regular}}>
                            <Text>{values.repairShop.address.companyName}</Text>
                            <Text>{values.repairShop.address.street}</Text>
                            <Text>{values.repairShop.address.postalCode + ' ' + values.repairShop.address.locality}</Text>
                            <Text>{values.repairShop.contact.phoneNumber}</Text>
                        </View>
                        <View style={column}>
                            <View style={[field.placeHolder,  {marginTop:'2mm'}]}>
                                <Text>Telefax / Email </Text>
                            </View>
                            <View style={[{fontSize:fontSize.regular}, {height:'20px'}]}>
                                <Text>{values.repairShop.contact.fax}</Text>
                                <Text>{values.repairShop.contact.email}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                
                {/* HinweisBox  */}  
                <View style={[
                    headerColumnRight,
                    {
                        height: '26mm', 
                        borderLeft:'none',
                        ...blackBorder
                    }
                ]}>
                    <View style={[noteBoxNote]}>
                        <Text>HINWEISE</Text>
                    </View>
                    <View style={[
                        {
                            marginLeft: 20, 
                            marginTop:-30 , 
                            fontSize: 8
                        }
                    ]}>
                        <Text>- Teil A + B ausfüllen</Text>
                        <Text>- Formular ist vom Geschädigten zu unterschreiben sowie per E-Mail oder</Text>
                        <Text style={{paddingLeft: 6}}> Telefax an den zuständigen Versicherer zu senden</Text>  
                        <View style={row}>
                            <Text> - Beizufügen ist eine Reparaturkalkulation (oder SV-Gutachten) und ggf. jeweils eine</Text>
                        </View>
                        <Text style={{paddingLeft: 6}}>eigene Abschleppkosten- und/oder Mietwagenrechnung.</Text>
                        <View style={row}>
                            <Text> - Diese Reparaturkosten-Übernahmebestätigung ersetzt nicht die Schadenanzeige</Text>
                        </View>
                        <Text style={{paddingLeft: 6}}>des Versicherungsnehmers (VN) an seinen Versicherer.</Text>
                    </View>
                </View>{/* HinweisBox  end*/} 

            </View>{/* Telefax- / Hinweisbox end */}  

            {/* Subitem  */}
            <View style={[
                subItem, 
                {justifyContent: 'space-between'}
            ]}>
                <Text>A. Erklärung des Halters des beschädigten Fahrzeugs zum Schaden vom:</Text>
                <View style={[fieldSmall.common, {width: '51mm'}]}>
                    <View style={[field.placeHolder]}>
                        <Text>Datum/Uhrzeit:</Text>
                    </View>
                    <View style={[field.value]}>
                        <Text>{values.incidentDate}</Text>
                    </View>
                </View>
            </View> {/* Subitem end */}
      
            {/* Form*/}
            <View style={[row]}>

                {/*LeftColumn*/}
                <View style={[leftColumn, {marginTop:'2mm'}]}>
                    {/*TextareaWithLabel*/}
                    <View style={column}>
                        <Text style={textAreaWithLabel.label}>
                            Name und Anschrift des Halters des beschädigten Fahrzeugs 
                        </Text>
                        <View style={[textAreaWithLabel.common,{justifyContent:'center'}]}>
                            <Text style={textAreaWithLabel.value}>
                                {values.vehicle.keeper.name}
                            </Text>
                            <Text style={textAreaWithLabel.value}>
                                {values.vehicle.keeper.address.street}
                            </Text>
                            <Text style={textAreaWithLabel.value}>
                                {values.vehicle.keeper.address.postalCode +  values.vehicle.keeper.address.locality}
                            </Text>
                        </View>              
                    </View>{/*TextareaWithLabel end*/}
  
  
                    {/*row*/}
                    <View style={[row, {marginTop: 4}]}>
                        {/*LeftColumn*/}
                        <View style={[leftColumn]}>
                            {/* field small*/}
                            <View style={telField.common}>
                                <View style={[telField.placeHolder]}>
                                    <Text>Telefon tagsüber:</Text>
                                </View>
                                <View style={[telField.value]}>
                                    <Text>{values.vehicle.keeper.dayPhone} </Text>
                                </View>
                            </View>           
      
      
                            {/*ComprehensiveGroup*/}
                            <View style={[row, {marginTop:4}]}>
    
                                <View style={[column,{flexGrow:1}]}>
                                    <Text style={{fontSize: fontSize.small}}>Teilkasko</Text>
                                </View>
                                <View style={[column,{flexGrow:1}]}>
                                    {/* Checkbox with label  */}
                                    <View style={checkBoxWithLabelSmall.wrapper}>
                                        <View style={checkBoxWithLabelSmall.checkBox}>
                                            <Text style={checkBoxWithLabelSmall.value}>
                                                { 
                                                    fillCheckBox( 
                                                        values.vehicle.comprehensive.partially === false
                                                    )
                                                }
                                            </Text>
                                        </View>
                                        <Text style={checkBoxWithLabelSmall.label}>
                                            nein 
                                        </Text>
                                    </View>{/* Checkbox with label end */}

                                    {/* Checkbox with label  */}
                                    <View style={checkBoxWithLabelSmall.wrapper}>
                                        <View style={checkBoxWithLabelSmall.checkBox}>
                                            <Text style={checkBoxWithLabelSmall.value}>
                                                { 
                                                    fillCheckBox( 
                                                        values.vehicle.comprehensive.partially === true
                                                    )
                                                }
                                            </Text>
                                        </View>
                                        <Text style={checkBoxWithLabelSmall.label}>
                                            ja
                                        </Text>
                                    </View>{/* Checkbox with label end */}                  
                                </View>
                                <View style={[column,{flexGrow:1}]}>
                                    {/* field */}
                                    <View style={[
                                        field.common,
                                        {
                                            height: 20, 
                                            justifyContent: 'center'
                                        }
                                    ]}>
                                        <View style={[field.value]}>
                                            <Text>{values?.vehicle?.comprehensive?.partiallyAmount ?? '0'} € </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={[
                                    column,
                                    {
                                        justifyContent:'center', 
                                        flexGrow:1, 
                                        paddingLeft: 2
                                    }
                                ]}>
                                    <Text style={
                                        {fontSize: field.value.fontSize}
                                    }>SB</Text>
                                </View>
                            </View> {/*ComprehensiveGroup end*/} 
      
      
      
                        </View> {/*LeftColumn end*/}


                        {/*RightColumn*/}
                        <View style={rightColumn}>
                            {/* field small*/}
                            <View style={telField.common}>
                                <View style={telField.placeHolder}>
                                    <Text>Telefon privat:</Text>
                                </View>
                                <View style={telField.value}>
                                    <Text>{values.vehicle.keeper.privatPhone} </Text>
                                </View>
                            </View>
                            {/*ComprehensiveGroup*/}
                            <View style={[row, {marginTop:4}]}>
    
                                <View style={[column,{flexGrow:1}]}>
                                    <Text style={{fontSize: fontSize.small}}>Vollkasko</Text>
                                </View>
                                <View style={[column,{flexGrow:1}]}>
                                    {/* Checkbox with label  */}
                                    <View style={checkBoxWithLabelSmall.wrapper}>
                                        <View style={checkBoxWithLabelSmall.checkBox}>
                                            <Text style={checkBoxWithLabelSmall.value}>
                                                { 
                                                    fillCheckBox( 
                                                        values.vehicle.comprehensive.fully === false
                                                    )
                                                }
                                            </Text>
                                        </View>
                                        <Text style={checkBoxWithLabelSmall.label}>
                                            nein
                                        </Text>
                                    </View>{/* Checkbox with label end */}

                                    {/* Checkbox with label  */}
                                    <View style={checkBoxWithLabelSmall.wrapper}>
                                        <View style={checkBoxWithLabelSmall.checkBox}>
                                            <Text style={checkBoxWithLabelSmall.value}>
                                                { 
                                                    fillCheckBox( 
                                                        values.vehicle.comprehensive.fully === true
                                                    )
                                                }
                                            </Text>
                                        </View>
                                        <Text style={checkBoxWithLabelSmall.label}>
                                            ja
                                        </Text>
                                    </View>{/* Checkbox with label end */}                  
                                </View>
                                <View style={[column,{flexGrow:2}]}>
                                    {/* field */}
                                    <View style={[
                                        field.common, 
                                        {
                                            height: 20, 
                                            justifyContent: 'center'
                                        }
                                    ]}>
                                        <View style={field.value}>
                                            <Text>{values?.vehicle?.comprehensive?.fullyAmount ?? '0'} €</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={[
                                    column,
                                    {
                                        justifyContent:'center', 
                                        flexGrow:1, 
                                        paddingLeft: 2
                                    }
                                ]}>
                                    <Text style={{fontSize: field.value.fontSize}}>SB</Text>
                                </View>
                            </View> {/*ComprehensiveGroup end*/} 
                        </View> {/*RightColumn end*/}
                    </View>{/*row end*/}  
                    {/*UnderlinedRowSmall*/}
                    <View style={[row, {height:'6mm'}]}>
                        <Text style={
                            {
                                fontSize: fontSize.small,
                                paddingRight: '6mm',
                                justifyContent:'flex-end'
                            }
                        }>
                      Versichert bei: 
                        </Text>
                        <View style={ 
                            {
                                borderBottom: '0.5px solid black', 
                                width: '100%',
                                justifyContent: 'center'
                            } 
                        }>
                            <Text style={
                                {
                                    fontSize: fontSize.small, 
                                    paddingLeft: '1mm'
                                }
                            }>
                                {values.vehicle.insurance.name}
                            </Text>
                        </View>
                    </View>{/*UnderlinedRowSmall end*/}
              
                    {/*CarInfoGroup*/}
                    <View style={{...row, flexDirection: 'column', marginTop:'2mm'}}>
                        <View style={row}>
                            <Text style={textAreaWithLabel.label}>
                                Angaben des beschädigten Fahrzeugs:
                            </Text>
                        </View>
                        <View style={row}>
                            <View style={[leftColumn]}>
                                {/* field small*/}
                                <View style={{...fieldSmall.common, borderBottom:'none',height:'7mm'}}>
                                    <View style={fieldSmall.placeHolder}>
                                        <Text>Hersteller und Typ:</Text>
                                    </View>
                                    <View style={[fieldSmall.value]}>
                                        <Text>
                                            { values.vehicle.manufacturer + ' ' +  values.vehicle.type } 
                                        </Text>
                                    </View>
                                </View> 
                                <View style={{...fieldSmall.common, height:'7mm'}}>
                                    <View style={fieldSmall.placeHolder}>
                                        <Text>Km-Stand lt. Tacho und Erstzulassung:</Text>
                                    </View>
                                    <View style={[fieldSmall.value]}>
                                        <Text>
                                            {values.vehicle.km}, {values.vehicle.firstRegistrationDate}
                                        </Text>
                                    </View>
                                </View> 
                            </View>
                            <View style={[rightColumn]}>
                                {/* field small*/}
                                <View style={{...fieldSmall.common, borderBottom:'none', height:'7mm'}}>
                                    <View style={fieldSmall.placeHolder}>
                                        <Text>Amtliches Kennzeichen:</Text>
                                    </View>
                                    <View style={fieldSmall.value}>
                                        <Text>{values.vehicle.licensePlate}</Text>
                                    </View>
                                </View>     
                                {/* field small*/}
                                <View style={{...fieldSmall.common, height:'7mm'}}>
                                    <View style={fieldSmall.placeHolder}>
                                        <Text>In der Werkstatt seit:</Text>
                                    </View>
                                    <View style={fieldSmall.value}>
                                        <Text>{values.vehicle.inRepairSince}</Text>
                                    </View>
                                </View>   
                            </View>                        
                        </View>
                    </View>{/*CarInfoGroup end */}
                    <View style={[row, {marginTop:'2mm'}]}>
                        {/* Checkbox with label  */}
                        <View style={checkBoxWithLabel.wrapper}>
                            <View style={checkBoxWithLabel.checkBox}>
                                <Text style={checkBoxWithLabel.value}>
                                    { 
                                        fillCheckBox( 
                                            values.vehicle.commissioningExpert
                                        )
                                    }
                                </Text>
                            </View>
                            <Text style={checkBoxWithLabel.label}>
                                        Ein Sachverständiger wurde beauftragt
                            </Text>
                        </View>{/* Checkbox with label end */} 
                    </View>
  
                </View>{/*LeftColumn end*/}
  




                {/*RightColumn*/}
                <View style={[rightColumn]}>
 
  
                    {/*TextareaWithLabel*/}
                    <View style={{...column, marginTop:'2mm'}}>
                        <Text style={textAreaWithLabel.label}>
                            Name und Anschrift des Versicherungsnehmers (Unfallgegner)
                        </Text>
                        <View style={[textAreaWithLabel.common]}>
                            <View style={[field.placeHolder, {fontWeight: 'bold'}]}>
                                <Text style={{width:150}}>
                                    Nur auszufüllen im Haftpflichtschadensfall
                                </Text>
                            </View>
                            <View style={{marginTop: 6}}></View>
                            <Text style={textAreaWithLabel.value}>
                                { values.opponentVehicle.keeper.name}
                            </Text>
                            <Text style={textAreaWithLabel.value}>
                                {values.opponentVehicle.keeper.address.street}
                            </Text>
                            <Text style={textAreaWithLabel.value}>
                                {values.opponentVehicle.keeper.address.postalCode + ' ' + values.opponentVehicle.keeper.address.locality}
                            </Text>
                        </View>              
                    </View>{/*TextareaWithLabel end*/}
  
                    {/*row*/}
                    <View style={[row, {marginTop: 4}]}>
                        {/*LeftColumn*/}
                        <View style={[leftColumn]}>
                            {/* field small*/}
                            <View style={[telField.common]}>
                                <View style={[telField.placeHolder]}>
                                    <Text>Telefon tagsüber:</Text>
                                </View>
                                <View style={[telField.value]}>
                                    <Text>{values.opponentVehicle.keeper.dayPhone}</Text>
                                </View>
                            </View>           

                            {/*TextareaWithLabel*/}
                            <View style={column}>
                                <Text style={[
                                    textAreaWithLabel.label,
                                    {paddingTop: '1mm'}
                                ]}>
                                    Amtliches Kennzeichen:
                                </Text>
                                <View style={[textAreaWithLabel.common,{justifyContent:'center', height: 20}]}>
                                    <Text style={textAreaWithLabel.value}>
                                        {values.opponentVehicle.licensePlate}
                                    </Text>
                                </View>              
                            </View>{/*TextareaWithLabel end*/}

                        </View> {/*LeftColumn end*/}


                        {/*RightColumn*/}
                        <View style={[rightColumn]}>
                            {/* field small*/}
                            <View style={[telField.common]}>
                                <View style={[telField.placeHolder]}>
                                    <Text>Telefon privat:</Text>
                                </View>
                                <View style={[telField.value]}>
                                    <Text>{values.opponentVehicle.keeper.privatPhone}</Text>
                                </View>
                            </View>

                            {/*TextareaWithLabel*/}
                            <View style={column}>
                                <Text style={[
                                    textAreaWithLabel.label,
                                    {paddingTop: '1mm'}
                                ]}>
                                    Versicherungsschein-Nr.:
                                </Text>
                                <View style={[
                                    textAreaWithLabel.common,
                                    {justifyContent:'center', height: 20}
                                ]}>
                                    <Text style={textAreaWithLabel.value}>
                                        {values.opponentVehicle.claimNumber}
                                    </Text>
                                </View>              
                            </View>{/*TextareaWithLabel end*/}

                        </View> {/*RightColumn end*/}
                    </View>{/*row end*/}
  

                    {/*TextareaWithLabel*/}
                    <View style={[column, {marginTop:'2mm'}]}>
                        <Text style={textAreaWithLabel.label}>
                        Name und Anschrift des Versicherers
                        </Text>
                        <View style={[
                            textAreaWithLabel.common, 
                            {height: '11mm', justifyContent: 'space-between'},
                      
                        ]}>
                      
                            <View style={
                                {
                                    flexDirection:'row', 
                                    justifyContent: 'center', 
                                    marginTop: 6
                                }
                            }>
                                <Text style={textAreaWithLabel.value}>
                                    {values.opponentVehicle.insurance.name}
                                </Text>
                                <Text style={textAreaWithLabel.value}>
                                    {values.opponentVehicle.insurance.address.street}
                                </Text>
                                <Text style={textAreaWithLabel.value}>
                                    {
                                        values.opponentVehicle.insurance.address.postalCode 
                                        + ' '
                                        + values.opponentVehicle.insurance.address.locality 
                                    }
                                </Text>
                            </View>
                            <View style={ 
                                {
                                    flexDirection: 'row' , 
                                    alignItems: 'flex-end'
                                } 
                            }>                          
                                <Text style={[
                                    textAreaWithLabel.label
                                ]}>
                                Telefon:
                                </Text>
                                <Text style={textAreaWithLabel.value}>
                                    {values.opponentVehicle.insurance.contact.phone}
                                </Text>
                            </View>
                        </View>              
                    </View>{/*TextareaWithLabel end*/}               



                    <View style={[row, {marginTop:'2mm'}]}>
                        {/* Checkbox with label  */}
                        <View style={checkBoxWithLabel.wrapper}>
                            <Text style={checkBoxWithLabel.label}>
                                        Geschädigter ist vorsteuerabzugsberechtigt: 
                            </Text>
                            <View style={[
                                checkBoxWithLabel.checkBox,
                                {marginLeft: '1mm'}
                            ]}>
                                <Text style={checkBoxWithLabel.value}>
                                    {
                                        fillCheckBox( values?.vehicle?.keeper?.isEntitledToTaxDeduction )
                                    }
                                </Text>
                            </View>
                            <Text style={checkBoxWithLabel.label}>
                                    ja
                            </Text>
                            <Text style={checkBoxWithLabel.label}>
                                        /
                            </Text>
                            <View style={[
                                checkBoxWithLabel.checkBox,
                                {marginLeft: '1mm'}
                            ]}>
                                <Text style={checkBoxWithLabel.value}>
                                    {
                                        fillCheckBox( values?.vehicle?.keeper?.isEntitledToTaxDeduction === false )
                                    }
                                </Text>
                            </View>
                            <Text style={checkBoxWithLabel.label}>
                                        nein
                            </Text>
                        </View>{/* Checkbox with label end */} 
                    </View>           
                </View>{/*RightColumn end*/}    
          
            </View>{/* Form end*/}
      
            <View style={{...row, flexDirection:'column', marginTop:'4mm'}}>
                <Text style={{
                    fontSize:fontSize.regular, 
                    fontWeight: 'bold'
                }}>
                  Kurze Unfallbeschreibung
                </Text>
        
        
                <View style={{...row, marginTop:'2mm'}}>
                    {/* Checkbox with label  */}
                    <View style={checkBoxWithLabel.wrapper}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox(
                                        values.damage.collision
                                    )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        Auffahrunfall
                        </Text>
                    </View>{/* Checkbox with label end */} 
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft:'2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox(
                                        values.damage.rightOfWayInfringement
                                    )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        Vorfahrtsverletzung
                        </Text>
                    </View>{/* Checkbox with label end */} 
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft:'2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox(
                                        values.damage.laneChange
                                    )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        Fahrspurwechsel
                        </Text>
                    </View>{/* Checkbox with label end */} 
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft:'2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox(
                                        values.damage.overTake
                                    )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        Überholen
                        </Text>
                    </View>{/* Checkbox with label end */} 
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft:'2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox(
                                        values.damage.parkedCarDemolished
                                    )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        geparktes Fahrzeug beschädigt
                        </Text>
                    </View>{/* Checkbox with label end */}
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft:'2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox( values.damage.offTheRoadway )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        Abkommen von der Fahrbahn
                        </Text>
                    </View>{/* Checkbox with label end */}
                </View>{/*row end*/}
        
                {/*UnderlinedRow*/}
                <View style={{...row, marginTop: '1mm'}}>
                    {/* Checkbox with label  */}
                    <View style={checkBoxWithLabel.wrapper}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox( values.damage.other.isOther )
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                        Sonstiges:
                        </Text>
                    </View>{/* Checkbox with label end */}

                    <View style={ 
                        {
                            borderBottom: '0.5px solid black', 
                            width: '100%',
                            justifyContent: 'center',
                            marginLeft: '2mm'
                        } 
                    }>
                        <Text style={
                            {
                                fontSize: fontSize.regular, 
                                paddingLeft: '1mm'
                            }
                        }>
                            { values.damage.other.description }
                        </Text>
                    </View>
                </View>{/*UnderlinedRow end*/}
        
                {/*UnderlinedRow*/}
                <View style={{...row, marginTop: '1mm'}}>
                    {/* Checkbox with label  */}
                    <View style={checkBoxWithLabel.wrapper}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox(
                                        values.police.reported
                                    )
                                }
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        Der Unfall ist polizeilich gemeldet. Aktenzeichen und Dienststelle:
                        </Text>
                    </View>{/* Checkbox with label end */}

                    <View style={ 
                        {
                            flexShrink: 1,
                            flexGrow: 1,
                            borderBottom: '0.5px solid black', 
                            justifyContent: 'center',
                            marginLeft: '2mm'
                        } 
                    }>
                        <Text style={
                            {
                                fontSize: fontSize.regular, 
                                paddingLeft: '1mm'
                            }
                        }>
                            {
                                values.police.fileNumber 
                                + ' ' 
                                + values.police.department
                            }
                        </Text>
                    </View>
                </View>{/*UnderlinedRow end*/}
      








                {/* Subitem  */}
                <View style={[
                    subItem
                ]}>
                    <Text style={{marginLeft: '1mm'}}>
                      B. Zahlungsanweisung
                    </Text>
                </View> {/* Subitem end */}
                <Text style={paragraphRegular}>
                    Der Geschädigte/VN weist das Versicherungsunternehmen unwiderruflich an, die Reparaturkosten entsprechend der Bestätigung aus C direkt an
                    den Reparaturbetrieb zu zahlen. Das Versicherungsunternehmen erklärt sich durch seine Unterschrift unter C damit einverstanden. Diese
                    Zahlung wird auf die Ansprüche des Geschädigten angerechnet. Der Geschädigte versichert, die Schadensregulierung selbst durchzusetzen und
                    beim leistungsverpflichteten Versicherer den Schaden zu melden. Der Geschädigte wird die Reparaturkosten gegenüber dem Reparaturbetrieb
                    selbst ausgleichen, soweit eine Zahlung durch das Versicherungsunternehmen an den Reparaturbetrieb nicht oder nicht in voller Höhe der
                    Reparaturkosten erfolgt. Dies gilt auch für den Betrag der gesetzlichen Mehrwertsteuer im Falle der Vorsteuerabzugsberechtigung des
                    Geschädigten.
                </Text>
                <Text style={paragraphRegular}>
                    Der Geschädigte/VN weist den Versicherer unwiderruflich an, darüber hinaus folgende Kosten an den Reparaturbetrieb zu zahlen:
                </Text>



                {/*UnderlinedRow*/}
                <View style={{
                    ...row, 
                    marginTop: '1mm'
                }}>
                    {/* Checkbox with label  */}
                    <View style={checkBoxWithLabel.wrapper}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                { fillCheckBox(values.damage.paymentOrder.mercantileDepreciation) }
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        merkantile Wertminderung
                        </Text>
                    </View>{/* Checkbox with label end */}
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                { fillCheckBox(values.damage.paymentOrder.rentalCar) }
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                Mietwagenkosten
                        </Text>
                    </View>{/* Checkbox with label end */}
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                { fillCheckBox(values.damage.paymentOrder.towing) }
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                            Abschleppkosten
                        </Text>
                    </View>{/* Checkbox with label end */}


                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                { fillCheckBox(values.damage.paymentOrder.flatRateDamage) }
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        Schadenpauschale
                        </Text>
                    </View>{/* Checkbox with label end */}



                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm'
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {
                                    fillCheckBox( values.damage.paymentOrder.other.isOther)
                                }
                            </Text>
                        </View>
                        <Text style={checkBoxWithLabel.label}>
                                   
                        </Text>
                    </View>{/* Checkbox with label end */}

                    <View style={ 
                        {
                            borderBottom: '0.5px solid black', 
                            width: '200px',
                            justifyContent: 'center',
                            marginLeft: '2mm'
                        } 
                    }>
                        <Text style={
                            {
                                fontSize: fontSize.regular, 
                                paddingLeft: '1mm'
                            }
                        }>
                            { values.damage.paymentOrder.other.description }
                        </Text>
                    </View>
                </View>{/*UnderlinedRow end*/}


                

                {/*UnderlinedRow*/}
                <View style={{
                    ...row, 
                    marginTop: '4mm',
                    alignItems: 'flex-end'
                }}>
                    <Text style={
                        {
                            fontSize: fontSize.regular, 
                            paddingLeft: '1mm'
                        }
                    }>
                        Datum und Unterschrift des Geschädigten/VN:
                    </Text>
                    <View style={ 
                        {
                            borderBottom: '0.5px solid black', 
                            width: '400px',
                            justifyContent: 'center',
                            alignItems:'flex-end',
                            marginLeft: '2mm',
                            flexDirection:'row'
                        } 
                    }>
                        <Text style={field.value}>
                            {values.today} ,
                        </Text>
                        {
                            exists(values.signatures[0])
                                ? <Image
                                    style={ 
                                        {
                                            width:'14mm', 
                                            height:'7mm'
                                        }
                                    }
                                    source={ {uri: values.signatures[0]} }
                                />
                                : <View
                                    style={{height:'7mm'}}
                                />
                        }    
                          
                    </View>{/*UnderlinedRow end*/}
                </View>


        
                {/* Subitem  */}
                <View style={[
                    subItem, 
                    {justifyContent: 'space-between'}
                ]}>
                    <Text>C. Bestätigung des Kraftfahrtversicherers zur Schaden-Nr.:</Text>
                    <View style={[fieldSmall.common, {width: '77mm'}]}>
                        <View style={{
                            ...field.value, 
                            justifyContent: 'center', 
                            height: '5mm'  
                        }}>
                            <Text>{values.damage.confirmationText}</Text>
                        </View>
                    </View>
                </View> {/* Subitem end */}
        
        
                <View style={{
                    ...row, 
                    marginTop: '2mm', 
                    alignItems: 'center'
                }}>
                    <Text style={{fontSize: fontSize.regular}}>
                    1. Der Versicherungsnehmer haftet 
                    </Text>
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm',
                        marginTop: 0
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {fillCheckBox(values.policyholder.adhesion.full)}
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        zu 100%
                        </Text>
                    </View>{/* Checkbox with label end */}
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm',
                        marginTop: 0
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {fillCheckBox(values.policyholder.adhesion.other.isOther)}
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        zu
                        </Text>
                    </View>{/* Checkbox with label end */}
                    {/* field small*/}
                    <View style={{
                        ...fieldSmall.common, 
                        width: '20mm',
                        marginLeft: '2mm',
                        justifyContent: 'center'
                    }}>
                        <View style={
                            fieldSmall.value
                        }>
                            <Text>
                                {values.policyholder.adhesion.other.value}
                            </Text>
                        </View>
                    </View> 
                
                    <Text style={{
                        fontSize: fontSize.regular,
                        marginLeft: '1mm'
                    }}>
                                    % der Reparaturkosten
                    </Text>
                    {/* Checkbox with label  */}
                    <View style={{
                        ...checkBoxWithLabel.wrapper,
                        marginLeft: '2mm',
                        marginTop: 0
                    }}>
                        <View style={checkBoxWithLabel.checkBox}>
                            <Text style={checkBoxWithLabel.value}>
                                {fillCheckBox(values.policyholder.adhesion.other.unknown)}
                            </Text>
                        </View>
                        <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        Haftungsfrage ist noch nicht geklärt
                        </Text>
                    </View>{/* Checkbox with label end */}                  
                </View>
        
                {/*CURRENT*/}
        
                <View style={row}>
                    <View style={{...column, width:'24mm'}}>
                        <Text style={{fontSize: fontSize.regular}}>
                        2. Der Versicherer
                        </Text>
                    </View>
                    <View style={column}>
                        <View style={row}>
                            {/* Checkbox with label  */}
                            <View style={checkBoxWithLabel.wrapper}>
                                <View style={checkBoxWithLabel.checkBox}>
                                    <Text style={checkBoxWithLabel.value}></Text>
                                </View>
                                <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        verzichtet auf eine Besichtigung
                                </Text>
                            </View>{/* Checkbox with label end */}
                            {/* Checkbox with label  */}
                            <View style={{
                                ...checkBoxWithLabel.wrapper,
                                marginLeft: '45mm'
                            }}>
                                <View style={checkBoxWithLabel.checkBox}>
                                    <Text style={checkBoxWithLabel.value}></Text>
                                </View>
                                <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        bittet um Fotos des beschädigten Fahrzeugs
                                </Text>
                            </View>{/* Checkbox with label end */}
                        </View>{/*row end*/}

                        <View style={row}>
                            {/* Checkbox with label  */}
                            <View style={checkBoxWithLabel.wrapper}>
                                <View style={checkBoxWithLabel.checkBox}>
                                    <Text style={checkBoxWithLabel.value}></Text>
                                </View>
                                <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        wird einen Sachverständigen beauftragen
                                </Text>
                            </View>{/* Checkbox with label end */}
                        </View>{/*row end*/}
                        <View style={row}>
                            {/* Checkbox with label  */}
                            <View style={checkBoxWithLabel.wrapper}>
                                <View style={checkBoxWithLabel.checkBox}>
                                    <Text style={checkBoxWithLabel.value}></Text>
                                </View>
                                <Text style={{...checkBoxWithLabel.label, flexGrow: 1}} >
                                        erteilt Reparaturfreigabe bis zu einem Betrag von
                                </Text>
                            </View>{/* Checkbox with label end */}
                            <View style={[
                                fieldSmall.common, 
                                {
                                    width: '83mm', 
                                    marginLeft:'2mm',
                                    marginTop: '-1mm'
                                }
                            ]}>
                                <View style={{
                                    ...field.value, 
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    height: '5mm',
                                    alignItems: 'center'
                                }}>
                                    <Text>   </Text>
                                    <Text>€</Text>
                                </View>
                            </View>
                        </View>{/*row end*/}
                  
                  
                    </View>{/*column end*/}
                </View>{/*row end*/}
                <View style={row}>
                    <View style={{...column, width:'24mm'}}>
                        <Text style={{fontSize: fontSize.regular}}>
                        3. Bestätigung
                        </Text>
                    </View>
                    <View style={{...column, width: '150mm'}}>
                        <Text style={paragraphRegular}>
                    Das Versicherungsunternehmen zahlt die Reparaturkosten entsprechend der angegebenen Haftungsquote bis zu dem unter C2 genannten
                    Betrag (im Kaskofall abzüglich einer Selbstbeteiligung von 
                    € <Text> 
                                {/* chb:: Selbstbeteiligungssumme von Versicherung auszufüllen? */}
                                ____________
                            </Text>) nach ordnungsgemäßer Durchführung der Reparatur und Vorlage
                    der Rechnung bei der Versicherung direkt an den Reparaturbetrieb.
                        </Text>
                    </View>{/*column end*/}
                </View>{/*row end*/}
   
        
                <View style={row}>
                    {/*SignatureField*/}
                    <View style={{
                        ...row, 
                        flexDirection: 'column', 
                        flexGrow: 1
                    }}>
                        <View style={{width: 'auto'}}>
                            <View style={signatureField.common}/>
                        </View>
                        <Text style={signatureField.label}>
                      Datum und Stempel der Versicherung
                        </Text>                  
                    </View>{/*SignatureField end*/}
                    
                    <View style={{width: '5mm'}}></View>
                
                    {/*SignatureField*/}
                    <View style={{
                        ...row, 
                        flexDirection: 'column',
                        flexGrow: 1
                    }}>
                        <View style={{width: 'auto'}}>
                            <View style={signatureField.common}/>
                        </View>
                        <Text style={signatureField.label}>
                            Unterschrift des Versicherungsbevollmächtigten
                        </Text>                  
                    </View>{/*SignatureField end*/}
                </View>
        
          
        
        
            </View>


            <View style={{
                width: '150mm',
                transform: 'rotate(270deg)',
                marginTop: -215,
                marginLeft: -225,
                fontSize: fontSize.regular,
            }}>
                <Text> Stand: 01/2024 © Zentralverband Deutsches Kraftfahrzeuggewerbe e.V., 53129 Bonn</Text>
            </View>


        </Page>
    </Document>
}