import { useState } from 'react'
import { geoCode, getMapAddress, isCompleteAddress } from 'application/common/address_helper'
import PropTypes from 'prop-types'


const useGeoDataSubmit = (
    address,
    setValue,
    onSubmit,
    markerMoved = false,
    nameLat = 'address.coordinates.latitude',
    nameLong = 'address.coordinates.longitude'
) => {
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [useGeoCoding, setUseGeoCoding] = useState(markerMoved)
    const onSubmitSuccess = () => {
        setSubmitDisabled(true)

        if ( !isCompleteAddress(address)  || !useGeoCoding ) {
            onSubmit()
            setSubmitDisabled(false)
        } else {
            geoCode(
                getMapAddress(address), 
                ({
                    lat='', 
                    lon=''
                }) => {
                    setValue(nameLat, lat)
                    setValue(nameLong, lon)
                    onSubmit(lat, lon)
                    setSubmitDisabled(false)
                }
            )
        }
    
    }


    return {
        onSubmitSuccess,
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } 
}

useGeoDataSubmit.propTypes = {
    formStateValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    markerMoved: PropTypes.bool
}

export  { useGeoDataSubmit }