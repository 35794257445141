import { all, call, put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreateMessageWorkflowPanelSaga(action) {
    yield put(
        showWaitingScreen('Creating message')
    )
    const {attachments} = action.payload

    const postAttachmentsServerCalls = attachments.map(
        file => function* () {        
            yield put(
                addNewSubMessage(file.metaData.name)
            )
            const response = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: action.metaData.damageId,
                    categoryId: action.metaData.categoryId
                },
                {
                    file: file.metaData
                }
            )
            return response
        }
    )

    const attachmentsResponses = yield all(
        postAttachmentsServerCalls.map(sc => call(sc))
    )

    const attachmentsIds = attachmentsResponses.map(res => res.id)

    yield resourceCall(
        api.gateway.messages.postMessage, 
        { damageId : action.metaData.damageId },
        {
            ...action.payload,
            attachments: attachmentsIds
        }
    )
    yield put(showToast('Message has been successfully sent'))
    yield put(hideWaitingScreen())
}