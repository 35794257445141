import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'
import {MessageDetails} from 'application/components/building_blocks/message/details'

const DamageManagerMessageDetailsWidget = ({ widgetKey, match, payload, deleteMessage, loadingFailed, downloadAttachment, messageDetails, attachmentMeta }) => {
    const { t } = useTranslation()

    if (loadingFailed) {
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    return <React.Fragment>
        <MessageDetails 
            damageId={match.params.damageId}
            showDamageLink={false}
        />
    </React.Fragment>
}

DamageManagerMessageDetailsWidget.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const DamageManagerMessageDetailsWidgetComponent =  withRouter(DamageManagerMessageDetailsWidget)