import { connect } from 'react-redux'
import { ForCurrentBranchPermissionsComponent } from './component'

const mapStateToProps = (state) => {
    return {
        branchPermissions: state.auth.permissions
    }
}

export const ForCurrentBranchPermissions = connect(
    mapStateToProps
)(ForCurrentBranchPermissionsComponent)