import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { FILTERS, INFOBOX } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'

export const CommissionExpertStep0 = (
    { 
        goToNextStep,
        files,
        comprehensiveData,
        setFlaggedFiles,
        onDataChange
    }
) => {
    const { t } = useTranslation()
    const isPartialCasco = comprehensiveData.insurance?.coverage === FILTERS.VALUES.DAMAGES.COVERAGE.PARTIAL

    return <React.Fragment>
        <DialogMainContent>
            {
                isPartialCasco 
                    ? <InfoBox 
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0'
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <span>{t('DEKRA commission is currently not possible for Partial Casco damage cases.')}</span>
                        <Spacer />
                    </InfoBox>
                    : <React.Fragment>
                        <Paragraph cssMargin="0 0 32px">
                            {t('You can upload files that you want to provide to the DEKRA expert.')}
                            <br />
                            {t('In most cases they will need a cost coverage confirmation.')}
                        </Paragraph>
                        <FlexBox
                            flexGrow='0'
                            flexBasis='200px'
                        >
                            <TaskUploader
                                onFileAdded={() => { }}
                                disableActiveState={true}
                                files={files}
                                setFiles={setFlaggedFiles}
                                onDataChange={onDataChange}
                            />
                        </FlexBox>
                    </React.Fragment>
            }
            


            
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                disabled={true}
                onButtonClick={()=>{}} 
            />
            <ActionButton 
                buttonText={t('Next')} 
                onButtonClick={goToNextStep} 
                disabled={isPartialCasco}
            />
        </StepsLayoutFooter>
    </React.Fragment>
}