import { ACTIONS } from 'application/constants'

export const requestStatusList = () => ({
    type: ACTIONS.COMMON_REQUEST_STATUS_LIST, 
})

export const populateStatusList = (statusListData, unmappedStatesData = []) => ({
    type: ACTIONS.COMMON_POPULATE_STATUS_LIST, 
    statusListData,
    unmappedStatesData
})

export const updateCustomStatusList = (requestCustomStates) => ({
    type: ACTIONS.COMMON_UPDATE_CUSTOM_STATUS_LIST,
    requestCustomStates
})