import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'



export function* dialogEditCustomerSaga(action) {
    yield put(
        showWaitingScreen('Saving customer')
    )
    const mappedCustomerPayload = removePropertiesWithEmptyStrings(action.payload.customer)

    const {
        deleteLegalProtectionInsurance = false,
        customerId
    } = action.metaData

    const params = { customerId }
    yield resourceCall(
        api.gateway.customer.putCustomer, 
        params, 
        mappedCustomerPayload
    )

    if (action?.payload?.legalProtectionInsurance) {
        const mappedLegalProtectionInsurancePayload = removePropertiesWithEmptyStrings(
            { ...action.payload.legalProtectionInsurance }
        )
        yield resourceCall(
            api.gateway.customer.putLegalProtectionInsurance,
            params, 
            mappedLegalProtectionInsurancePayload
        )
    }
    
    yield deleteLegalProtectionInsurance && resourceCall(
        api.gateway.customer.deleteLegalProtectionInsurance,
        params
    )

    yield put(
        showToast(action.metaData.successMsg)
    )
    yield put(
        hideWaitingScreen()
    )
}