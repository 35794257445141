import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { RepairAuthorizationMiniDisplay } from 'application/components/building_blocks/repair_authorization_mini_display'

export const DamageRepairAuthorizationDisplay = (
    {
        payload,
        loadingFailed
    }
) => {
    const { t } = useTranslation()

    if (loadingFailed) {
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <RepairAuthorizationMiniDisplay 
        repairAuthorizationType={payload.response.repairAuthorization}
    />
}

DamageRepairAuthorizationDisplay.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}