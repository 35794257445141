import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom24 } from 'application/components/pages/_common'
import { ClearableList } from 'application/components/building_blocks/clearable_list'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { getGroupTextInfo } from 'application/components/controls/group_text_info'
import { useStatefulForm } from 'application/components/hooks'

const SelectionList = (
    {
        list,
        setSelectedGroupOptions,
        availableGroupOptions,
        setAvailableGroupOptions
    }
) => {
    const handleRemoveElement = (option) => {
        setSelectedGroupOptions(
            list.filter(
                listElement => listElement.value !== option.value
            )
        )

        const containsOption = availableGroupOptions.find(
            availableOption => availableOption.value === option.value
        )
        
        if (containsOption) return 


        setAvailableGroupOptions(
            [
                ...availableGroupOptions,
                option
            ]
        )
    }

    return <ClearableList 
        list={list} 
        onClear={handleRemoveElement} 
        getElementToolTip={(element) => {
            return getGroupTextInfo(element.group)
        }}
    />
}

const GroupSearchSelectComponentInner = (
    {

        groupsLoading = false,
        groups,
        initialSelectedGroups = [],
        onSelectedGroupsChange = () => {}
    }
) => {
    const buildOptions = (all, selected) => {
        return all
            .filter(g => !selected.some(isg => isg.id === g.id))
            .sort((a, b) => a.id - b.id)
            .map(
                (group) => {
                    return {
                        label: group.name,
                        value: group.id,
                        group
                    }
                }
            )
    }

    const [selectedGroupOptions, setSelectedGroupOptions] = useState(initialSelectedGroups.map(
        (group) => {
            return {
                label: group.name,
                value: group.id,
                group: groups.find(g => g.id === group.id)
            }
        }
    ))
    const [availableGroupOptions, setAvailableGroupOptions] = useState(buildOptions(groups, initialSelectedGroups))

    //bubble changes of selected groups to parent component
    useEffect(()=>{
        onSelectedGroupsChange(selectedGroupOptions.map(option => option.group))
    },[onSelectedGroupsChange, selectedGroupOptions])

    //rebuild options after a selected group or list of available groups changed
    useEffect(()=>{
        setAvailableGroupOptions(buildOptions(groups, selectedGroupOptions.map(option => option.group)))
    }, [groups, selectedGroupOptions])

    const {t} = useTranslation()

    const {
        register, 
        errors, 
        setValue
    } = useStatefulForm()

    const handleSelection = (groupOption) => {

        if (groupOption === null) return

        const containsGroupOption = selectedGroupOptions.find(
            selectedGroupOption => selectedGroupOption.value === groupOption.value
        )
        
        if (containsGroupOption) return 

        setSelectedGroupOptions(
            [
                ...selectedGroupOptions,
                groupOption
            ]
        )
    }

    return <FlexBox flexDirection='column'>
        <PushBottom24/>
        <Select
            label={t('Assign groups')}
            name='assignedTo'
            register={register}
            setValue={setValue}
            options={availableGroupOptions}
            error={errors.assignedTo}
            validate={null}
            onChange={handleSelection}
            noOptionsMessage={t('No groups available')}
            placeholder={t('Select groups')}
            isLoading={groupsLoading}
            hideValidationBox={true}
            defaultValue={null}
        />
        <FlexBox 
            flexDirection='column'
            paddingTop='16px'
        >
            <SelectionList 
                list={selectedGroupOptions}
                setSelectedGroupOptions={setSelectedGroupOptions}
                setAvailableGroupOptions={setAvailableGroupOptions}
                availableGroupOptions = {availableGroupOptions}
            />
        </FlexBox>
    </FlexBox>

}

const GroupSearchSelectComponent = (props) => {
    const {
        groupsLoading = false,
        groupsLoaded = false
    } = props

    if(groupsLoading || !groupsLoaded){
        return <FlexBox flexDirection='column'>
            <ThemedPreloader />
        </FlexBox>
    }

    return <GroupSearchSelectComponentInner {...props} />
}

export { GroupSearchSelectComponent }