import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { takeLatest } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* downloadAttachmentSaga(action) {
    try {
        yield resourceCall(
            api.gateway.attachments.downloadDocumentById,
            {
                damageId: action.damageId, //in wfp, this is undefined but handled by the resourceCall method
                documentId: action.id
            },
            {
                documentName: action.attachmentName
            }
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* downloadAttachmentWatcher() {
    yield takeLatest(ACTIONS.DOWNLOAD_ATTACHMENT_BY_ID_REQUEST, downloadAttachmentSaga)
}