import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16 } from 'application/components/pages/_common'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { useComprehensiveDamageTypes } from 'application/components/hooks/use_comprehensive_damage_types'
import {  FontIcon } from '@ec/ui-controls-react'
import { StyledComprehensiveDamageTypeItem, StyledButton } from './fragments'
import { useTheme } from 'styled-components'
import { ActionButton } from 'application/components/controls/action_button'
import { Paragraph } from 'application/components/fragments/typography'

export const ComprehensiveDamageTypesEditor = (props) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {
        comprehensiveDamageTypes,
        comprehensiveDamageTypesLoaded,
        editComprehensiveDamageType,
        deleteComprehensiveDamageType,
        addComprehensiveDamageType
    } = useComprehensiveDamageTypes()

    if(!comprehensiveDamageTypesLoaded){
        return <ThemedPreloader />
    }


    return <FlexBox flexDirection='column' flexBasis='100%'>

        {
            comprehensiveDamageTypes.length === 0 && <Paragraph>
                {t('There are no custom comprehensive damage types yet.')}
            </Paragraph>
        }

        {
            comprehensiveDamageTypes.map((type) => {
                return <StyledComprehensiveDamageTypeItem key={type.id}>{type.name}
                    <FlexBox flexDirection='row' flexShrink='1' flexBasis='auto' width='auto'>
                        <StyledButton
                            onClick={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                editComprehensiveDamageType(type)
                            }}
                        >
                            <FontIcon 
                                icon='edit'
                                fontSize={theme.fontSize.medium} 
                                color={theme.color.anthracite}
                                isClickable={true}
                            />
                        </StyledButton>
                        <StyledButton
                            onClick={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                deleteComprehensiveDamageType(type)
                            }}
                        >
                            <FontIcon 
                                icon='delete'
                                fontSize={theme.fontSize.medium} 
                                color={theme.color.anthracite}
                                isClickable={true}
                            />
                        </StyledButton>
                    </FlexBox>
                </StyledComprehensiveDamageTypeItem>
            })
        }
        <PushBottom16 />
        <ActionButton
            onButtonClick={addComprehensiveDamageType}
            buttonText={t('Add comprehensive damage type')}
        />
    </FlexBox>
}