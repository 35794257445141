import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { EditUserGroupsComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSaveUserGroups: (
            dialogName,
            userId, 
            payload
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload,
                    { 
                        userId,
                    }
                )
            )
        },
    }
}

export const EditUserGroups = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUserGroupsComponent)