import { useEffect, useState, useCallback } from 'react'
import { getVisibleNodeCount } from 'react-sortable-tree'

const mapCategoryToNode = category => {
    const hasSubcategories = category.categories !== undefined && category.categories.length > 0
    return {
        title: category.name,
        id: category.id,
        expanded: true,
        isDefault: category.isDefault,
        canBeDeleted: !category.isDefault && !hasSubcategories,
        canHaveChildren: !category.isDefault,
        canBeChild: !category.isDefault,
        children: hasSubcategories ? category.categories.map(sc => mapCategoryToNode(sc)) : []
    }
}

const updateCanBeDeletedFlag = treeData => {
    return treeData.map(cat => ({
        ...cat,
        canBeDeleted: !cat.isDefault && !(cat.children !== undefined && cat.children.length > 0),
        children: updateCanBeDeletedFlag(cat.children)
    }))
}

const treeStructureMapper = categories => ({
    treeData: categories.map(c => mapCategoryToNode(c))
})

export const useCategoryTree = (
    categories, 
    categoriesLoaded, 
    nodeHeight = 52
) => {
    const [tree, setTree] = useState(treeStructureMapper([]))
    const [height, setHeight] = useState(0)

    const setCategoryTree = useCallback(treeData => {
        setTree(treeData)
        setHeight(getVisibleNodeCount(treeData) * nodeHeight)
    }, [nodeHeight])

    const onChange = treeData => {
        setCategoryTree({treeData: updateCanBeDeletedFlag(treeData)})
    }

    useEffect(()=>{
        categoriesLoaded && setCategoryTree(treeStructureMapper(categories))
    }, [categories, categoriesLoaded, setCategoryTree])

    return {
        tree,
        height,
        onChange
    }
}