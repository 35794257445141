
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import { Link } from 'application/components/controls/hyperlink'
import { ShortenedString } from 'application/components/controls/shortened_string'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const MessageCreatedActivity = (
    {
        activity
    }
) => {
    const {t} = useTranslation()
    const {damageManagerLink} = useApplicationLinks()
    const messageId = activity.data.id

    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    const messageLink = damageManagerLink(
        ROUTES.DAMAGE_MANAGER, 
        damageId
    ) + '/messages/' + messageId

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Message created')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Subject')}: <ShortenedString text={activity.data.subject} shortenAt={35}/> 
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <Link to={messageLink}>
                {t('Show message')}
            </Link>
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

MessageCreatedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}