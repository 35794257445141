import React from 'react'
import { ACTIVITY_TYPE } from 'application/constants'
import * as payment from './payment'
import * as damage from './damage'
import * as assignee from './assignee'
import * as invoice from './invoice'
import * as lawyer from './lawyer'
import * as task from './task'
import * as vehicle from './vehicle'
import * as document from './document'
import * as message from './message'
import * as contactInfo from './contact_info'
import * as casefileInfo from './casefile_info'
import { LiabilityInquiryUpdatedInfo } from './liability_inquiry_info'

const DefaultInfo = (
    { activityData }
) => {
    return <React.Fragment />
}



export const getActivityInfoByType = activityType => {
    switch (activityType) {
    case ACTIVITY_TYPE.PAYMENT_CREATED:
        return payment.CreatedInfo
    case ACTIVITY_TYPE.PAYMENT_CANCELLED:
        return payment.CanceledInfo
    case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
        return assignee.AssigneeChangedInfo
    case ACTIVITY_TYPE.DAMAGE_CREATED:
        return damage.CreatedInfo
    case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
        return damage.JobNumberChangedInfo
    case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
        return damage.StatusChangedInfo
    case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
        return damage.TypeChangedInfo
    case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
        return damage.DescriptionChangedInfo
    case ACTIVITY_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
        return damage.IncidentDateChangedInfo
    case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
        return damage.NoteChangedInfo
    case ACTIVITY_TYPE.INVOICE_CREATED:
        return invoice.CreatedInfo
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
        return lawyer.CommunicationInfo
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
        return lawyer.CommunicationAbortedInfo
    case ACTIVITY_TYPE.ACKNOWLEDGEMENT_UPDATED:
        return lawyer.AcknowledgementUpdatedInfo
    case ACTIVITY_TYPE.TASK_CREATED:
        return task.CreatedInfo
    case ACTIVITY_TYPE.TASK_DELETED:
        return task.DeletedInfo
    case ACTIVITY_TYPE.TASK_STATE_CHANGED:
        return task.StateChangedInfo
    case ACTIVITY_TYPE.TASK_COMMENT_CREATED:
        return task.CommentCreatedInfo
    case ACTIVITY_TYPE.VEHICLE_UPDATED:
        return vehicle.VehicleUpdatedInfo
    case ACTIVITY_TYPE.VEHICLE_KEEPER_CHANGED:
        return vehicle.VehicleKeeperChangedInfo
    case ACTIVITY_TYPE.DOCUMENT_CREATED:
        return document.CreatedInfo
    case ACTIVITY_TYPE.DOCUMENT_DELETED:
        return document.DeletedInfo
    case ACTIVITY_TYPE.MESSAGE_CREATED:
        return message.CreatedInfo
    case ACTIVITY_TYPE.MESSAGE_DELETED:
        return message.DeletedInfo
    case ACTIVITY_TYPE.REPAIRAUTHORIZATION_CHANGED:
        return damage.RepairAuthorizationChangedInfo
    case ACTIVITY_TYPE.CONTACT_INFO_UPDATED:
        return contactInfo.ContactUpdatedInfo
    case ACTIVITY_TYPE.CASEFILE_INFO_UPDATED:
        return casefileInfo.CasefileUpdatedInfo
    case ACTIVITY_TYPE.LIABILITY_INQUIRY_UPDATED:
        return LiabilityInquiryUpdatedInfo
    default:
        return DefaultInfo
    }
}