import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ACTIONS, USER_PERMISSONS } from 'application/constants'
import { handleError } from '../../errors'
import { populateAccountSettings, syncAccountSettings } from 'application/redux/actions/ui_settings'
import { accountSettingsIntegrityCheck } from './account_settings_integrity_check'
import { exists } from 'application/common/data_helpers'
import { showToast } from 'application/redux/actions/notifications'
import { isPermittedInAnyBranch } from 'application/components/context/permissions'
import { getAllPermissions } from 'application/redux/selectors'

export function* requestAccountSettingsSaga(action) {
    try {
        const {accountId} = action

        const settingsResponse = yield resourceCall(
            api.gateway.uiSettings.getAccountSettings
        )

        const settings = exists(settingsResponse.version)
            ? settingsResponse
            : settingsResponse.matches[0].value

        const {
            isUpToDate,
            updatedSettings
        } = yield call(accountSettingsIntegrityCheck, settings)

        const allPermissions = yield select(getAllPermissions)
        const isPermittedToUpdateAccountSettings = yield call(isPermittedInAnyBranch, allPermissions, [USER_PERMISSONS.SETTINGS.UPDATE], true)

        if(!isUpToDate && isPermittedToUpdateAccountSettings){
            yield resourceCall(
                api.gateway.uiSettings.updateAccountSettings,
                null,
                updatedSettings
            )
        }

        yield put(populateAccountSettings(accountId, updatedSettings))
    } catch (e) {
        yield handleError(e)
    }
}

export function* accountSettingsWatcher() {
    yield takeLatest(
        ACTIONS.UI_SETTINGS_GET_ACCOUNT_SETTINGS_REQUEST,
        requestAccountSettingsSaga
    )
}

function* saveAccountSettingsSaga(action) {
    try {

        yield put(syncAccountSettings(action.settings))

        if(action.saveDirectly){
            yield resourceCall(
                api.gateway.uiSettings.updateAccountSettings,
                null,
                action.settings
            )

            yield put(showToast('Settings saved'))
        }

    } catch (e) {
        yield handleError(e)
    }
}

export function* saveAccountSettingsWatcher() {
    yield takeLatest(
        ACTIONS.UI_SETTINGS_SAVE_ACCOUNT_SETTINGS_REQUEST,
        saveAccountSettingsSaga
    )
}