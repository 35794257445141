import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'

export const AcknowledgementUpdatedActivity = (
    {activity}
) => {
    const {t} = useTranslation()
    const status = activity.data.newAcknowledgement?.commissionStatus ?? 'Unknown'
    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Acknowledgement updated')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Status')}: {t(status)}
        </Fragments.ActivityParagraph>
    </React.Fragment>
}