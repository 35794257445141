import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'



export const PermissionChangesInfobox = () => {
    const {t} = useTranslation()

    return <InfoBox 
        type={INFOBOX.TYPES.WARNING} 
        direction='row' 
        cssMargin='0'
    >
        <FontIcon icon='info_outline' />
        <Spacer basis='10px' />
        <span>{t('Changes concerning branches or the permission system may take up to 10 minutes to take effect.')}</span>
        <Spacer />
    </InfoBox>
}