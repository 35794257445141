import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_CONTEXTS } from 'application/constants'
import {LawyerCommunicationComponent} from './component'
import { DamageManagerDamageExists } from 'application/components/pages/internal/damage_manager/common/damage_exists'


const LawyerCommunicationEffectsComponent = (props) => {

    const { 
        onRefreshWidgetContexts, 
        match 
    } = props

    const { damageId } = match.params

    useEffect(
        () => {
            onRefreshWidgetContexts(
                [
                    WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION
                ]
            )
        }, [onRefreshWidgetContexts, damageId]
    )

    return <DamageManagerDamageExists>
        <LawyerCommunicationComponent {...props} />
    </DamageManagerDamageExists>
}

LawyerCommunicationEffectsComponent.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired
}

export const LawyerCommunicationEffects =  withRouter(LawyerCommunicationEffectsComponent)