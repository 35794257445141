import { ACTIONS } from 'application/constants'

export const loadAssignees = (
    dialogName,
    offset = 0,
    limit = 25,
    orderBy = 'lastname',
    direction = 'desc',
    filterQuery = []
) => ({
    type: ACTIONS.ASSIGNEES_LOAD_ASSIGNEES,
    dialogName,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})