import { populateSharedResourceData } from 'application/redux/actions/shared_resource'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { getAccountSettingsRequest } from 'application/redux/actions/ui_settings'

export function* sharedResourceQrDataSaga(){

    yield put(getAccountSettingsRequest(null))

    const usersResponse = yield (
        resourceCall(api.gateway.users.getUsersList)
    )

    const customerResponse = yield (
        resourceCall(api.gateway.customer.getCustomerById)
    )

    yield put(
        populateSharedResourceData({
            adminUser: usersResponse.users[0],
            customer: customerResponse
        })
    )
}