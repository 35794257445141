import { compose } from 'redux'
import { connect } from 'react-redux'
import { EditKickstartBranchAssigneesComponent } from './component'
import { commonGetUsersRequestDebounced } from 'application/redux/actions/common'
import { ListFilter } from 'application/filters'
import * as filters from 'application/filters'
import { withBranches } from 'application/components/higher_order_components'

const mapStateToProps = state => ({
    usersList: state.common.usersList,
    usersListLoading: state.common.usersListLoading
})

const mapDispatchToProps = dispatch => ({
    onSearchUser: (search) => dispatch(
        commonGetUsersRequestDebounced(
            0,
            10, 
            new ListFilter(filters.users.name)
                .setValue(search)
                .getQuery()
        )
    )
})

export const EditKickstartBranchAssignees =  compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withBranches
)(EditKickstartBranchAssigneesComponent)
