import React, {useState} from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { CustomerSearch } from 'application/components/controls/form/customer_search'
import { exists } from 'application/common/data_helpers'
import { useStatefulForm } from 'application/components/hooks'

export const SwitchDamageCustomerComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onSwitchCustomer,
        dialog
    }
) => {

    const [selectedCustomer, setSelectedCustomer] = useState(null)

    const {
        register,
        errors, 
        handleSubmit, 
        getValues, 
        setValue,
        updateFormState 
    } = useStatefulForm({
        defaultValues: {
            customerId: ''
        }
    })
    const {customerList} = dialog.payload
    

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const onSubmitSuccess = () => {
        onSwitchCustomer(
            dialog.name,
            dialog.payload.damageId, 
            getValues().customerId,
            selectedCustomer
        )            
    }

    return <React.Fragment>
        <DialogMainContent cssHeight='300px'>
            <FlexBox 
                flexDirection='column' 
            >
                <CustomerSearch 
                    customerList={customerList}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    onDataChange={onDataChange}
                    onChange={(customerId, customer)=>{
                        setSelectedCustomer(customer)
                    }}
                    dialog={dialog}
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            disabled={!exists(getValues().customerId)}
            submitText='Confirm new customer'
        />
    </React.Fragment>
}