import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getBranchContractorIds = () => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                'billingbranches', 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource:  () => null
    }
)