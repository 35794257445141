import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { withTheme } from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'



export const TaskDamageLiabilityInsuranceFormComp = withTheme(
    withRouter(
        (
            {
                widgetKey, 
                payload, 
                loadingFailed, 
                onSave, 
                match, 
                currentDamage, 
                theme
            }
        ) => {
            const {t} = useTranslation()
            const {damageId} = match.params

            if(loadingFailed){
                return <WidgetErrorMessage>
                    {t('Liability data could not be loaded')}
                </WidgetErrorMessage>
            }

            return <FlexBox flexDirection='column'>
                <Collapsable 
                    headline={t('Liability insurance')} 
                    initiallyCollapsed={true}
                >
  
                    <DamageForms.DamageLiabilityInsuranceDataForm 
                        onSave={onSave}  
                        damageId={damageId}
                        formData={payload.formData}
                    />
                </Collapsable>
            </FlexBox>
        }
    )
)

TaskDamageLiabilityInsuranceFormComp.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}