import { ACTIONS } from 'application/constants'

export const storeErrorDetails = (payload, errorId) => ({
    type: ACTIONS.ERRORS_STORE_DETAILS,
    payload, 
    errorId
})

export const showErrorDetails = (errorId) => ({
    type: ACTIONS.ERRORS_SHOW_DETAILS,
    errorId
})

export const hideErrorDetails = () => ({
    type: ACTIONS.ERRORS_HIDE_DETAILS
})