import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PushBottom48, PushBottom8 } from 'application/components/pages/_common'
import { AnnouncementParagraph, AnnouncementListItemTitle, ChangelogList, ChangelogTag, AnnouncementListItem } from './fragments'
import { Headline2, Paragraph } from 'application/components/fragments/typography'
import { LayoutAllBranches } from 'application/components/pages/_layout/layout_all_branches'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useAnnouncements } from 'application/components/hooks/announcements/use_announcements'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { groupObjectsByAttributes } from 'application/common/list_helpers'
import { DateComponent } from 'application/components/controls/date'
import { EarlyAccessNote } from 'application/components/controls/early_access_note'


export const ChangelogComponent = () => {
    const {t} = useTranslation()
    const {
        features,
        announcementsLoaded,
        announcementsLoading
    } = useAnnouncements()

    const changeLogTimeRange = isNaN(parseInt(process.env.REACT_APP_CHANGELOG_TIME_RANGE_IN_DAYS)) 
        ? 30
        : parseInt(process.env.REACT_APP_CHANGELOG_TIME_RANGE_IN_DAYS)

    const announcementsWithShortedDates = features.filter(
        a => new Date(a.createdAt) > new Date(new Date().setDate(new Date().getDate() - changeLogTimeRange))
    ).map(
        a => {
            return {
                ...a,
                createdAt: a.createdAt.substring(0, 10)+'T00:00:00.000000Z'
            }
        }
    )

    const groupedAnnouncementslist = groupObjectsByAttributes(
        announcementsWithShortedDates, 
        'releaseAt'
    )

    return <LayoutAllBranches
        titleSegments={['Whats`s new?']}
        headlineGeneric={t('Whats`s new?')}
        headlineChildren={<EarlyAccessNote />}
    >
        <PushBottom8 />
        <Paragraph>
            {t('You can find Information about new features and bugfixes of e.sy 360 here.')}
            <br/>
            <Trans i18nKey='Only Updates of the last X days will be visible.'>
                {{changeLogTimeRange}}
            </Trans>
        </Paragraph>
        {
            announcementsLoaded 
                && features.length === 0 
                && <Paragraph>
                    {t('There are no announcements at the moment.')}
                </Paragraph>
        }
        <PushBottom48 />
        <FlexBox width='60%' flexDirection='column'>
            <React.Fragment>
                <FlexBox flexDirection='column'>
                    {
                        announcementsLoading && <ThemedPreloader />
                    }
                    
                    { 
                        !announcementsLoading  
                        && announcementsLoaded 
                        && groupedAnnouncementslist.sort((a, b) => {
                            return a.releaseAt > b.releaseAt ? -1 : 1
                        }).map(
                            (aGroup, index) => <React.Fragment key={index}>
                                <Headline2>
                                    <ChangelogTag>UPDATE</ChangelogTag>
                                    <DateComponent 
                                        date={aGroup.releaseAt} 
                                    />
                                </Headline2>
                                <ChangelogList>
                                    {                                           
                                        aGroup.objects.map(
                                            (a, aIndex) => <AnnouncementListItem key={aIndex}>
                                                <AnnouncementListItemTitle>
                                                    {a.title}
                                                </AnnouncementListItemTitle>
                                                <AnnouncementParagraph>
                                                    { a.body }
                                                </AnnouncementParagraph>
                                            </AnnouncementListItem>
                                        )
                                    }
                                </ChangelogList>
                            </React.Fragment>
                        ) 
                    }  
                </FlexBox>
            </React.Fragment>
        </FlexBox>
    </LayoutAllBranches>
}