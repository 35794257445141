import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    CONTROLLING_STATISTICS_DAMAGE_COUNT: 'WIDGET_CONTROLLING_STATISTICS_DAMAGE_COUNT',

}

export const layoutControllingStatistics= {
    LARGE: [
        {i: KEYS.CONTROLLING_STATISTICS_DAMAGE_COUNT, x: 0, y: 0, w: 6, h: 3}
    ]
}

export const widgetsControllingStatistics = [
    {
        key: KEYS.CONTROLLING_STATISTICS_DAMAGE_COUNT,
        type: WIDGET_TYPES.CONTROLLING_STATISTICS_DAMAGE_COUNT,
        title: 'Statistics',
        isSecondary: false,
        hideBorder: true,
        isBlank: true,
        hideHeadline: true,
        routeParameters: ROUTE_PARAMETERS.WIDGET_CONTROLLING_STATISTICS_DAMAGES_COUNT.FILTERS,
        accountDependent: true
    }
]
