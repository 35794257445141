import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { activities, ListFilter } from 'application/filters'

export function* widgetDamageActivityLogSaga(action) {
    const { damageId } = action.match.params

    const {
        offset = 0,
        limit = 5,
        reloaded = false
    } = action.payload

    const filterQuery = new ListFilter( 
        activities.damageIdWithChildren
    ).setValue(
        damageId
    ).getQuery()

    const response = yield resourceCall(
        api.gateway.activities.getActivities,
        { 
            offset,
            limit,
            filterQuery
        }
    )
    
    yield put(
        updateWidget(
            action.key,
            action.dependentWidgetKeys,
            {
                activities: response.activities,
                total: response.total,
                reloaded
            }
        )
    )
}