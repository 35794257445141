import { BRANCH_MANAGEMENT } from 'application/constants'
import { xPosition, yPosition } from './get_node_position'

export const getNodePosition = (nodeType, nodeIndex, nodeCount) => {

    const nodeX = () => {
        switch (nodeType) {
        case BRANCH_MANAGEMENT.NODE_TYPE.ROLE: return xPosition(0)
        case BRANCH_MANAGEMENT.NODE_TYPE.GROUP: return xPosition(1)
        case BRANCH_MANAGEMENT.NODE_TYPE.BRANCH: return xPosition(2)
        default: return xPosition(0)
        }
    }

    const nodeY = () => {
        switch (nodeType) {
        case BRANCH_MANAGEMENT.NODE_TYPE.ROLE: return yPosition(nodeIndex, 0, nodeCount)
        case BRANCH_MANAGEMENT.NODE_TYPE.GROUP: return yPosition(nodeIndex, 1, nodeCount)
        case BRANCH_MANAGEMENT.NODE_TYPE.BRANCH: return yPosition(nodeIndex, 2, nodeCount)
        default: return yPosition(0, 0)
        }
    }
    

    return {
        x: nodeX(),
        y: nodeY()
    }
}

export const buildNode = (data, nodeType, nodeIndex, theme, nodeCount, isDisabled = false, branchesDisabled = false) => {
    const {color} = theme
    
    const defaults = {
        id: data.id,
        nodeType,
        type: BRANCH_MANAGEMENT.ELEMENT_TYPE.NODE_CUSTOM,
        data: {label: data.name, nodeType, id: data.id, isDisabled, branchesDisabled, ...data},
        position: getNodePosition(nodeType, nodeIndex, nodeCount),
        draggable: false,
        selectable: !isDisabled
    }


    switch (nodeType) {
    case BRANCH_MANAGEMENT.NODE_TYPE.ROLE:
        return {
            sourcePosition: 'right',
            type: 'input',
            ...defaults,
            data: {...defaults.data, color: color.rolePrimary}
        }
    case BRANCH_MANAGEMENT.NODE_TYPE.GROUP:
        return {
            sourcePosition: 'right',
            targetPosition: 'left',
            ...defaults,
            data: {...defaults.data, color: color.groupPrimary}
            
        }
    case BRANCH_MANAGEMENT.NODE_TYPE.BRANCH:
        return {
            type: 'output',
            targetPosition: 'left',
            ...defaults,
            data: {...defaults.data, color: color.branchPrimary}
        }
    
    default:
        return {
            sourcePosition: 'right',
            ...defaults
        }
    }
}
