import { connect } from 'react-redux'

import { DamageManagerInboxTabbarButtonsComponent } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'
import { refreshSharedLawfirm } from 'application/redux/actions/pages/damage_manager/shared'

const mapStateToProps = state => {
    return {
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damage: state.pages.damageManager.shared.damage,
        lawfirmState: state.pages.damageManager.shared.lawfirm?.state,
        hasLawfirm: state.pages.damageManager.shared.hasLawfirm
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateMessage: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_MESSAGE_FOR_LAWFIRM,
                false,
                payload,
                'New message for lawfirm'
            )
        ),
        onRefreshLawfirm: (damageId) => dispatch(refreshSharedLawfirm(damageId))
    }
}

export const DamageManagerInboxTabbarButtons = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerInboxTabbarButtonsComponent)