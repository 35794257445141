import styled from 'styled-components'

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin-bottom: 32px;
`

export const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 48px;
`

export const FilterLabel = styled.div`
    flex: 0 0 240px;
`

export const FilterAction = styled.div`
    display: flex;
    flex-direction: row;
    width: 146px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 16px;
    align-items: center;
    max-width: ${props => props.maxWidth 
        ? props.maxWidth
        : 'none' };
    flex-wrap: ${props => props.flexWrap 
        ? props.flexWrap
        : 'no-wrap' };
`

export const FilterMeta = styled.div`
    flex: 0 1 420px;
`
