import { connect } from 'react-redux'
import { ForActiveBranchComponent } from './component'

const mapStateToProps = (state) => {
    return {
        branchActive: state.auth.currentBranch !== null
    }
}

export const ForActiveBranch = connect(
    mapStateToProps
)(ForActiveBranchComponent)