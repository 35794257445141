import { connect } from 'react-redux'
import { updatePin, setReturnUrl } from 'application/redux/actions/shared_resource'
import {SharedResourceRedirectComponent} from './component'

const mapStateToProps = state => ({
    sharedResource: state.sharedResource
})

const mapDispatchToProps = dispatch => {
    return {
        onSetReturnUrl: returnUrl => dispatch(setReturnUrl(returnUrl)),
        onUpdatePin: (pin) => dispatch(updatePin(pin))
    }
}

export const SharedResourceRedirect = connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedResourceRedirectComponent)