import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { MessageListInternal } from 'application/components/building_blocks/message/list'
import { useLocation } from 'react-router-dom'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withRouter } from 'application/components/higher_order_components/with_router'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'


const DamageManagerInboxMessageList = (
    {
        widgetKey,
        match,
        payload,
        loadingFailed,
        onPaginationChange
    }
) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { damageManagerLink } = useApplicationLinks()
    if (loadingFailed) {
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    return <FlexBox>
        <MessageListInternal
            getMessageUrl={(damageId, messageId) => {
                return damageManagerLink(
                    ROUTES.DAMAGE_MANAGER_INBOX + '/' + messageId,
                    damageId
                ) + location.search
            }}
            baseUrlParams={ROUTE_PARAMETERS.DAMAGE_MANAGER_INBOX}
            messages={payload.messages}
            messagesTotal={payload.total}
            selectedMessageId={match.params.messageId}
            onPaginationChange={
                (offset, limit) => {
                    onPaginationChange(widgetKey, offset, limit)
                }
            }
        />
    </FlexBox>
}

DamageManagerInboxMessageList.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const DamageManagerInboxMessageListComponent = withRouter(
    DamageManagerInboxMessageList
)