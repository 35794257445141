import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* createGroupSaga(action) {
    yield put(showWaitingScreen('Creating user group'))
    yield resourceCall(
        api.gateway.groups.createGroup, 
        null,
        action.payload
    )
    yield put(hideWaitingScreen())
}