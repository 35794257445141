import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const deleteGroup = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(`groups/${params.groupId}`, HTTP_METHOD.DELETE)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)