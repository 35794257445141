import styled, {keyframes} from 'styled-components'

const BackgroundPosition = keyframes`
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }

`

export const StyledMenu = styled.div`
    min-height: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.color.white};
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${BackgroundPosition};
    animation-timing-function: linear;
    background: fff;
    background: ${props => props.isLoading ? `linear-gradient(to right, ${props.backgroundColor} 8%, ${props.animationColor} 18%, ${props.backgroundColor} 28%)`: props.backgroundColor};

    ${({ animationRunning }) => animationRunning && `
        & * {
            pointer-events: none !important;
        }
    `}
`

export const MenuName = styled.div`
    display:flex;
    align-items: center;
    padding-left: 8px;
    flex-basis: 142px;
    font-size: ${props => props.theme.fontSize.standard};
`



