import i18next from 'i18next'
import { applicationToken } from 'application/storage'
import { buildApiUrl } from 'application/api/helpers'
import { HTTP_METHOD } from 'application/api/request_builders'
import { parseJwt } from './decode_jwt'
import { store } from 'application/redux/create_store'
import { AccountMismatchError } from 'application/api/errors/account_mismatch_error'

export const fetchRequestBuilderWithToken = (
    apiEndpoint, 
    method, 
    token, 
    payload = {}, 
    absolute = false, 
    omitToken = false
) => {
    const tokenData = parseJwt(token)

    if(tokenData.account !== undefined){
        const state = store.getState()
        if(state.auth.currentAccountId !== null && tokenData.account !== state.auth.currentAccountId){
            throw new AccountMismatchError(tokenData.account, state.auth.currentAccountId)
        }
    }

    const url = buildApiUrl(apiEndpoint, absolute)

    let data = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18next.language
        },
        mode: 'cors'
    }

    data = method !== HTTP_METHOD.GET ? {
        ...data,
        body: JSON.stringify(payload)
    } : data

    data.headers = !omitToken ? {
        ...data.headers,
        'Authorization': 'Bearer ' + token
    } : data.headers

    return {
        data,
        url
    }
}

export const fetchRequestBuilder = (
    apiEndpoint, 
    method = HTTP_METHOD.POST, 
    payload = {}, 
    absolute = false, 
    omitToken = false, 
    storage = applicationToken
) => {
    const state = store.getState()
    const token = storage.get(state.auth.currentAccountId)
    
    return fetchRequestBuilderWithToken(
        apiEndpoint, 
        method, 
        token, 
        payload, 
        absolute,
        omitToken
    )
}