import React from 'react'
import styled from 'styled-components'
import { withTheme } from 'styled-components'
import { Percentage } from 'application/components/charts/_common/percentage'
import { Spacer } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'

const Open = styled.div`
    flex: 0 0 ${props => props.cssWidth};
    height: 16px;
    background-color: ${props => props.theme.color.gray20};
`

const Payed = styled.div`
    flex: 0 0 ${props => props.cssWidth};
    height: 16px;
    background-color: ${props => props.theme.color.primary};
`

const VacantPostionsBarComponent = (
    {
        unpaidTotal = null, 
        paidTotal = null, theme, 
        cssWidth = '100%', 
        invertValue = true
    }
) => {
    const {t} = useTranslation()

    const noData = unpaidTotal === null 
    || paidTotal === null 
    || paidTotal + unpaidTotal === 0

    if(noData){
        return <span>{t('no invoices')}</span>
    }

    const percentage = ((paidTotal * 100) / (paidTotal + unpaidTotal)).toFixed(2)
    const title = `${t('Vacant positions')}: ${unpaidTotal}€ / ${t('Payed')}: ${paidTotal}€ / ${t('Total')}: ${paidTotal + unpaidTotal}€`

    return <FlexBox  
        flexShrink='0' 
        flexBasis={cssWidth} 
        title={title}
    >
        <FlexBox>
            <FlexBox  
                flexShrink='0' 
                flexBasis='calc(100% - 56px)'
            >
                <Payed cssWidth={`${percentage}%`}/>
                <Open cssWidth={`${100 - percentage}%`}/>
            </FlexBox>
            <Spacer flexBasis='6px'/>
            <FlexBox 
                flexShrink='0'
                flexBasis='50px' 
                alignItems='center'
            >
                <Percentage 
                    invertValue={invertValue} 
                    value1={paidTotal} 
                    value2={unpaidTotal} 
                    $cssFontSize={theme.fontSize.small}
                />
                {
                    unpaidTotal === 0 
                    && <strong>
                        <FontIcon 
                            color={theme.color.success} 
                            icon='done' 
                            fontSize={theme.fontSize.standard}
                        />
                    </strong>
                }
            </FlexBox>
        </FlexBox>
    </FlexBox>
}

export const VacantPositionsBar = withTheme(
    VacantPostionsBarComponent
)