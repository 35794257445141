import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { DAMAGE_TYPE } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'
import { withTheme } from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'


export const TaskDamageWitnessDataFormComp = withTheme(
    withRouter(
        ({
            widgetKey, 
            payload, 
            loadingFailed, 
            onSave, 
            match, 
            currentDamage, 
            theme
        }) => {
            const {t} = useTranslation()
            const {formData} = payload
            const {damageId} = match.params
    
            if(loadingFailed){
                return <WidgetErrorMessage>{t('Witness data could not be loaded')}</WidgetErrorMessage>
            }
    
            return <FlexBox flexDirection='column'>
                <Collapsable 
                    headline={t('Witness')} 
                    initiallyCollapsed={true}
                >
                    {
                        currentDamage.type !== DAMAGE_TYPE.LIABILITY && <Paragraph>
                            <FontIcon 
                                icon='warning' 
                                color={theme.color.warning} 
                                fontSize={theme.fontSize.standard} 
                            /> 
                            {t('Witness data only available for liability cases')}
                        </Paragraph>
                    }
                    <DamageForms.DamageWitnessForm 
                        onSave={onSave}  
                        damageId={damageId}
                        formData={formData}
                        disabled={currentDamage.type !== DAMAGE_TYPE.LIABILITY}
                    />
                </Collapsable>
            </FlexBox>
        }
    )
)

TaskDamageWitnessDataFormComp.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}