import { connect } from 'react-redux'
import { MassOperationsDamagesSelect as MoDamagesSelectComponent} from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getFirstObjectByOwnPropKey } from 'application/common/list_helpers'
import { MASS_OPERATIONS } from 'application/constants'



const mapStateToProps = (state, ownProps) => {
    const registeredMassOperations = state.massOperations.registeredMassOperations 

    return {
        mo: getFirstObjectByOwnPropKey(
            registeredMassOperations, 
            MASS_OPERATIONS.KEY.NAME, 
            ownProps.moName
        ),
        allPermissions: state.auth.allPermissions
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onOpenMoDialog: (
            dialogName,
            initiallyLoaded,
            payload,
            dialogTitle
        ) => {

            dispatch(
                openDialogByName(
                    dialogName,
                    initiallyLoaded, 
                    payload, 
                    dialogTitle
                )
            )
        }
    }
}

export const MassOperationsDamagesSelect =  connect(
    mapStateToProps,
    mapDispatchToProps
)(MoDamagesSelectComponent) 