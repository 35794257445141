import { connect } from 'react-redux'
import { DamageAKInvoicesComponent } from './component'


const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {}
}

export const DamageAKInvoices = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageAKInvoicesComponent)
