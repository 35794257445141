import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* addCustomStateSaga(action) {
    try {
        const {lifeCycleState, index, requestCustomStates} = action.metaData

        const customStateResponse = yield resourceCall(
            api.gateway.damageState.addCustomState, 
            null,
            action.payload
        )

        const { id } = customStateResponse

        yield resourceCall(
            api.gateway.damageState.putMapLifecycleState,
            null,
            {
                ...requestCustomStates,
                [id]: {
                    lifeCycleState,
                    index
                }
            }
        )

        yield put(showToast('State created'))
    } catch (e) {
        yield handleError(e, action)
    }
}