import React from 'react'
import {DateComponent} from 'application/components/controls/date'
import TruncateString from 'react-truncate-string'
import {CustomerNameDamage} from 'application/components/controls/customer_name_damage/component'
import { DamageTypeName } from 'application/components/name_mappings/damage_type_name'
import {JobNumber} from 'application/components/controls/job_number'

const tableName = 'moDialogDamageDelete'

export const tableConfiguration = {
    name: tableName,
    columns: [
        {
            width: '15%',
            label: 'License plate',
            CellContent: ({element}) => <TruncateString text={element.vehicle.licensePlate} truncateAt={50} />
        },
        {
            width: '20%',
            label: 'Customer / owner',
            CellContent: ({element}) => <CustomerNameDamage damage={element} />
        },
        {
            width: '20%',
            label: 'Creation date',
            CellContent: ({element}) => <DateComponent date={element.createdAt} />
        },
        {
            width: '20%',
            label: 'Damage type',
            CellContent: ({element}) => <DamageTypeName damageType={element.type} />
        },
        {
            width: '15%',
            label: 'Job number',
            CellContent: ({element}) => <JobNumber number={element.jobNumber} />
        }
    ],
}
