import { connect } from 'react-redux'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG, UPDATE_CONSTRAINTS } from 'application/constants'
import { downloadTaskDocuments } from 'application/redux/actions/attachments'
import {AllTasksComponent} from './component'
import { getAllTasksRequest } from 'application/redux/actions/pages/my_tasks/all_tasks'
import { getUpdateConstraint } from 'application/redux/selectors'
import { MASS_OPERATIONS } from 'application/constants'



const mapStateToProps = state => {
    const {
        tasksListLoaded,
        tasksListLoading,
        tasksList,
        tasksListTotal,
    } = state.pages.myTasks.allTasks
    return {
        auth: state.auth,
        allTasks: state.pages.myTasks.allTasks,
        tasksListLoaded,
        tasksListLoading,
        tasksList,
        tasksListTotal,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.TASKS_TABLES),
        moName: MASS_OPERATIONS.NAME.TASKS_OVERVIEW_CURRENT_BRANCH_ALL_TASKS
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadTasksList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getAllTasksRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        ),

        onDeleteTask: (payload) => dispatch(openDialogByName(DIALOG.NAME.DELETE_TASK, true, payload, 'Delete task')),
        onDownloadDocuments: (payload) => dispatch(downloadTaskDocuments(payload.damageId, payload.id)),
    }
}

export const AllTasks = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTasksComponent)