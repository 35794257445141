import styled from 'styled-components'


export const StyledMenuSidebarIcon = styled.div`
    display: flex;
    height: 48px;
    width: 48px;
    justify-content: center;
    align-items: center;

    :hover {
        background: ${props => props.hoverBackground};
        cursor: ${props => props.cursor};
        color: ${props => props.hoverColor};
    }

    & i {
        font-size: ${props => props.iconSize};
    }


`


