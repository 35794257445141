import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import {ActivityDamageLink} from './_common/damage_activity_link'



export const DamageNoteChangedMetaContent = (
    {
        notification, 
        onLinkClick, 
        inline
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification
    
    const newNote = payload.newNoteContent 
        ? payload.newNoteContent 
        : t('Not set')
        
    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='Case notes'
            value={newNote}
        />
        <ActivityDamageLink 
            onClick={onLinkClick} 
            damageId={payload.damage.id}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}