import React, { useMemo } from 'react'
import { Form } from '../form_builder'
import { dataMapper } from './helper'


export const FormRendererComponent = React.forwardRef(
    (
        { 
            formRendererDescription, 
            onSubmitFormData, 
            defaultValues = {} 
        }, ref
    ) => {
        const mappedDescription = useMemo(
            () => {
                return dataMapper(JSON.parse(formRendererDescription))
            },
            [formRendererDescription]
        )

        return <Form 
            ref={ref} 
            descriptions={mappedDescription} 
            onSubmit={onSubmitFormData}
            defaultValues={defaultValues} 
            showSubmitButton={ref ? false : true}
        />
    })