import { getUsersForDialogRequestDebounced } from 'application/redux/actions/common'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { ReopenTaskComponent } from './component'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onReopenTask: (
            dialogName, 
            taskId, 
            damageId, 
            damageStateId, 
            damageStateHasChanged,
            assigneeId,
            assigneeFirstName,
            assigneeLastName,
            assigneeHasChanged
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    { 
                        damageStateId, 
                        damageStateHasChanged, 
                        assigneeId, 
                        assigneeFirstName, 
                        assigneeLastName, 
                        assigneeHasChanged 
                    }, 
                    { 
                        taskId, 
                        damageId
                    }
                )
            )
        },
        onSearchUser: (name, search) => dispatch(
            getUsersForDialogRequestDebounced(
                name, 
                0, 
                10, 
                new ListFilter(
                    filters.users.name
                ).setValue(
                    search
                ).getQuery()
            )
        )
    }
}

export const ReopenTask = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReopenTaskComponent)