import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    WORKFLOW_PANEL_AKTEN_STATUS: 'WORKFLOW_PANEL_FORM_AKTEN_STATUS',
    WORKFLOW_PANEL_LIABILITY_REQUEST: 'WORKFLOW_PANEL_FORM_LIABILITY_REQUEST',
    WORKFLOW_PANEL_RECEIPT_CONFIRMATION: 'WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION',
    WORKFLOW_PANEL_INVOICES: 'WORKFLOW_PANEL_FORM_INVOICES',
    WORKFLOW_PANEL_REPAIR_CLEARANCE: 'WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE',
    WORKFLOW_PANEL_INSURANCE_INFORMATION: 'WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION'
}

export const layoutWorkflowPanelForm= {
    LARGE: [
        { i: KEYS.WORKFLOW_PANEL_RECEIPT_CONFIRMATION, x: 0, y: 0, w: 3, h: 10 },
        { i: KEYS.WORKFLOW_PANEL_LIABILITY_REQUEST, x: 4, y: 0, w: 3, h: 6 },
        { i: KEYS.WORKFLOW_PANEL_INSURANCE_INFORMATION, x: 4, y: 6, w: 3, h: 5 },
        { i: KEYS.WORKFLOW_PANEL_AKTEN_STATUS, x: 0, y: 10, w: 3, h: 5 },
        { i: KEYS.WORKFLOW_PANEL_REPAIR_CLEARANCE, x: 4, y: 11, w: 3, h: 4 },
        // { i: KEYS.WORKFLOW_PANEL_INVOICES, x: 0, y: 13, w: 12, h: 5 },
    ]
}

export const widgetsWorkflowPanelForm = [
    {
        key: KEYS.WORKFLOW_PANEL_RECEIPT_CONFIRMATION,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION,
        title: 'Receipt confirmation',
        hideHeadline: false,
        dependentWidgetKeys: [
            KEYS.WORKFLOW_PANEL_LIABILITY_REQUEST,
            KEYS.WORKFLOW_PANEL_AKTEN_STATUS
        ]
        // isBlank: true,
    },
    {
        key: KEYS.WORKFLOW_PANEL_INSURANCE_INFORMATION,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION,
        title: 'Liability insurance opponent',
        hideHeadline: false,
        dependentWidgetKeys: [
            KEYS.WORKFLOW_PANEL_LIABILITY_REQUEST
        ]
        // isBlank: true,
    },
    {
        key: KEYS.WORKFLOW_PANEL_LIABILITY_REQUEST,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_LIABILITY_REQUEST,
        title: 'Liability request',
        hideHeadline: false,
        dependsOnOtherWidget: true
        // isBlank: true,
    },
    {
        key: KEYS.WORKFLOW_PANEL_AKTEN_STATUS,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_AKTEN_STATUS,
        title: 'Akten status',
        hideHeadline: false,
        dependsOnOtherWidget: true
        // isBlank: true,
    },
   
    
    // {
    //     key: KEYS.WORKFLOW_PANEL_INVOICES,
    //     type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES,
    //     title: 'Invoices',
    //     hideHeadline: false
    //     isBlank: true,
    // },
    
    {
        key: KEYS.WORKFLOW_PANEL_REPAIR_CLEARANCE,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE,
        title: 'Repair clearance',
        hideHeadline: false
        // isBlank: true,
    },
]