import { useEffect } from 'react'

export const useEscapeKey = (callback, additionalCondition = true) => {
    const keyHandler = (e) => {
        if (e.key === 'Escape') {
            additionalCondition && callback()
        }
    }
    useEffect(() => {
        window.addEventListener('keyup', keyHandler)
        return () => {
            window.removeEventListener('keyup', keyHandler)
        }
    })
}