import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_CONTEXTS } from 'application/constants'
import {DamageManagerInvoicesComponent} from './component'
import { DamageManagerDamageExists } from 'application/components/pages/internal/damage_manager/common/damage_exists'




const DamageManagerInvoicesEffectsComponent = (props) => {
    const { onRefreshWidgetContexts, match } = props
    const { damageId } = match.params



    useEffect(() => {
        onRefreshWidgetContexts([WIDGET_CONTEXTS.DAMAGE_MANAGER_INVOICES])
    }, [onRefreshWidgetContexts, damageId])

    return <DamageManagerDamageExists> 
        <DamageManagerInvoicesComponent {...props} />
    </DamageManagerDamageExists>
}

DamageManagerInvoicesEffectsComponent.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired
}

export const DamageManagerInvoicesEffects = withRouter(DamageManagerInvoicesEffectsComponent)