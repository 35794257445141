import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton } from 'application/modules/dynamic-form/controls/action_button'

export const Submit = React.forwardRef(({ description, showSubmitButton }, ref) => {
    const { t } = useTranslation()
    
    return <ActionButton
        ref={ref}
        visible={showSubmitButton}
        buttonText={t(description.label)} />
})
