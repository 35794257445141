import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* editComprehensiveDamageTypeSaga(action) {
    try {

        yield resourceCall(
            api.gateway.comprehensiveDamageTypes.putComprehensiveDamageTypes, 
            action.metaData,
            action.payload
        )

        yield put(showToast('Damage type saved'))
        
    } catch (e) {
        yield handleError(e, action)
    }
}