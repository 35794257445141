import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTitleBar, Cell, TitleBarHeadline } from 'application/components/pages/internal/_title_bars/_common'
import { ROUTES } from 'application/constants'
import { Spacer } from 'application/components/pages/_common'
import { UserSection } from 'application/components/pages/internal/_title_bars/_common/user_section'
import { useParams } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { IconLink } from 'application/components/controls/icon_link/component'
import { withTheme } from 'styled-components'
import { exists, isDefined } from 'application/common/data_helpers'
import { TitlebarAccountLogo } from 'application/components/building_blocks/title_bar_account_logo'
import { DamagesSearch } from 'application/components/building_blocks/damages_search'
import { TitleBarRow } from 'application/components/pages/internal/_title_bars/_common/row'
import { DamageManagerTitleBarButtons } from './damage_manager_titlebar_buttons'



export const TitleBarDamageManagerComponent = withTheme(
    ({
        damage,
        openDialogCreateLawyers,
        onOpenVehicleAndKeeperQRCodeCreationDialog,
        branchId,
        onCreateMessageForLawyer,
        damageLoaded,
        hasLawfirm,
        lawfirmState,
        onCreateCostCoverage,
        openDialogCommissionDekraExpert,
        openDialogCommissionOtherExpert,
        currentBranchId,
        theme,
        hasMultipleBranches,
        onCreatePowerOfAttorney,
        expertFound,
        // canCommissionDekra,
        onOpenSmartConnectDialog
    }) => {
        const { t } = useTranslation()
        const { accountId } = useParams()

        const {
            internalAccountLink,
            branchLink
        } = useApplicationLinks()

        const exitUrl = exists(currentBranchId) && hasMultipleBranches
            ? branchLink(
                ROUTES.BRANCH_SPECIFIC_DASHBOARD,
                currentBranchId
            )
            : internalAccountLink(
                ROUTES.DASHBOARD
            )

        const handleCommissionLawfirm = () => {
            openDialogCreateLawyers(damage.id, false, damage.branchId)
        }

        const handleOpenDialogCommissionDekraExpert = () => {
            openDialogCommissionDekraExpert(damage.id)
        }

        const handleOpenDialogCommissionOtherExpert = () => {
            openDialogCommissionOtherExpert(damage.id)
        }

        const handleOpenQRCodeCreationDialog = () => {
            const {
                licensePlate = null,
                make = null,
                model = null,
                chassisNumber = null,
                keeper = null
            } = damage.vehicle

            const payload = {
                licensePlate,
                branchId,
                customerId: keeper.customerId,
                make,
                model,
                chassisNumber
            }
            onOpenVehicleAndKeeperQRCodeCreationDialog(payload)
        }

        const hasCustomer = isDefined(damage?.vehicle?.keeper?.customerId)

        return (
            <StyledTitleBar>
                <TitleBarRow
                    shouldBreak={true}
                    forWideScreen={true}
                >
                    <Cell>
                        <TitlebarAccountLogo
                            accountId={accountId}
                        />
                        <Spacer cssMargin='0 0 0 16px' />
                        <TitleBarHeadline>
                            {t('Damage management')}
                        </TitleBarHeadline>
                    </Cell>
                    <Cell>
                        <DamageManagerTitleBarButtons
                            handleCommissionLawfirm={handleCommissionLawfirm}
                            handleOpenDialogCommissionDekraExpert={handleOpenDialogCommissionDekraExpert}
                            handleOpenDialogCommissionOtherExpert={handleOpenDialogCommissionOtherExpert}
                            handleOpenQRCodeCreationDialog={handleOpenQRCodeCreationDialog}
                            hasCustomer={hasCustomer}
                            damage={damage}
                            onCreateMessageForLawyer={onCreateMessageForLawyer}
                            damageLoaded={damageLoaded}
                            hasLawfirm={hasLawfirm}
                            lawfirmState={lawfirmState}
                            onCreateCostCoverage={onCreateCostCoverage}
                            onCreatePowerOfAttorney={onCreatePowerOfAttorney}
                            expertFound={expertFound}
                            onOpenSmartConnectDialog={onOpenSmartConnectDialog}
                        />
                    </Cell>
                    <Cell>
                        <DamagesSearch />
                        <UserSection />
                        <IconLink
                            to={exitUrl}
                            iconKey="close"
                            cssHeight='48px'
                            cssWidth='48px'
                            iconSize='24px'
                            textAlign='center'
                            cssHoverBackground={theme.color.black10}
                            title={t('To dashboard')}
                        />
                    </Cell>
                </TitleBarRow>

                <TitleBarRow
                    shouldBreak={true}
                    forWideScreen={false}
                    borderBottomValue={'2px solid white'}
                >
                    <Cell className='cell-account'>
                        <TitlebarAccountLogo
                            accountId={accountId}
                        />
                        <Spacer cssMargin='0 0 0 16px' />
                        <TitleBarHeadline>
                            {t('Damage management')}
                        </TitleBarHeadline>
                    </Cell>
                    <Cell className='cell-user-section' >
                        <UserSection />
                        <IconLink
                            to={exitUrl}
                            iconKey="close"
                            cssHeight='48px'
                            cssWidth='48px'
                            iconSize='24px'
                            textAlign='center'
                            cssHoverBackground={theme.color.black10}
                            title={t('To dashboard')}
                        />
                    </Cell>
                </TitleBarRow>
                <TitleBarRow
                    shouldBreak={true}
                    forWideScreen={false}
                    justifyContent='space-between'
                >
                    <Cell className='cell-button-bar' >
                        <DamageManagerTitleBarButtons
                            handleCommissionLawfirm={handleCommissionLawfirm}
                            handleOpenDialogCommissionDekraExpert={handleOpenDialogCommissionDekraExpert}
                            handleOpenDialogCommissionOtherExpert={handleOpenDialogCommissionOtherExpert}
                            handleOpenQRCodeCreationDialog={handleOpenQRCodeCreationDialog}
                            hasCustomer={hasCustomer}
                            damage={damage}
                            onCreateMessageForLawyer={onCreateMessageForLawyer}
                            damageLoaded={damageLoaded}
                            hasLawfirm={hasLawfirm}
                            onCreateCostCoverage={onCreateCostCoverage}
                            onCreatePowerOfAttorney={onCreatePowerOfAttorney}
                            expertFound={expertFound}
                            onOpenSmartConnectDialog={onOpenSmartConnectDialog}
                        />
                    </Cell>
                    <Cell>
                        <DamagesSearch />
                    </Cell>
                </TitleBarRow>
            </StyledTitleBar>
        )
    }
)
