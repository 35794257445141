import { put, takeEvery } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ListFilter } from 'application/filters/filter_class'
import * as filters from 'application/filters'
import { populateDocumentsByCategory, addDocumentsByCategory } from 'application/redux/actions/documents_by_categories'




function* loadDocumentsByCategorySaga(action) {
    const categoryId = action.categoryId

    const {
        documents = [],
        total = 0
    } = yield resourceCall(
        api.gateway.attachments.getAttachmentsByDamageId,
        {
            damageId: action.damageId,
            offset: action.offset,
            limit: action.limit,
            filterQuery: 
                    new ListFilter(
                        filters.documents.documentsByCategoryId
                    )
                        .setValue(categoryId)
                        .getQuery()                    
        }
    )      

    yield put(
        action.addDocumentsToLoadedDocs 
            ? addDocumentsByCategory(
                categoryId,
                documents
            )
            : populateDocumentsByCategory(
                categoryId,
                documents,
                total
            )
    )
}


export function* loadDocumentsByCategoryWatcher() {
    yield takeEvery(
        ACTIONS.DOCUMENTS_BY_CATEGORIES_LOAD_DOCUMENTS, 
        loadDocumentsByCategorySaga
    )
}