import React, { useEffect } from 'react'
import {DamageManagerDocumentsComponent} from './component'
import { DamageManagerDamageExists } from 'application/components/pages/internal/damage_manager/common/damage_exists'
import { MASS_OPERATIONS } from 'application/constants'
import { useMO } from 'application/components/hooks'
import { useDocsByCategoriesRegisterResource } from 'application/components/hooks/documents_by_categories/use_register_resource'
import { useParams } from 'react-router-dom'





export const DamageManagerDocumentsEffects = (props) => {
    const { 
        onHideAttachmentDetails, 
        sidebarDamageId 
    } = props
    const { damageId } = useParams()
    const shouldHideSidebar = damageId !== sidebarDamageId
    useDocsByCategoriesRegisterResource(damageId)

    useEffect(() => {
        shouldHideSidebar && onHideAttachmentDetails() //hide sidebar when new document center data is loaded
    }, [onHideAttachmentDetails, shouldHideSidebar])

    const moType = MASS_OPERATIONS.TYPE.DOCUMENTS
    const moName = `${moType}_${damageId}` 

    const {
        moSetInactive,
        mo
    } = useMO(moName)

    const moExists = mo !== null

    useEffect(
        () => {
            moExists 
            && moSetInactive()                  
        },
        [moSetInactive, moExists] 
    ) 

    return <DamageManagerDamageExists>
        <DamageManagerDocumentsComponent 
            {...props}
            onHideAttachmentDetails={onHideAttachmentDetails}
            moName={moName}
            moType={moType}
        />
    </DamageManagerDamageExists>
}  