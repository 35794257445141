import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { moClearAllItemsByName } from 'application/redux/actions/mass_operations'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogMoTasksDeleteSaga(action) {

    const { ids } = action.payload

    const { 
        moName
    } = action.metaData  

    try{   
        yield put(
            showWaitingScreen('Tasks would be deleted...')
        )
        yield resourceCall(
            api.gateway.tasks.deleteTasks,
            null,
            {ids}
        )
    }catch(e){
        console.error(e)
    }
    yield put(
        moClearAllItemsByName(moName)
    )

    yield put( 
        showToast('Tasks are deleted') 
    )
    yield put( 
        hideWaitingScreen() 
    )

}