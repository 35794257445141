import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_TYPES } from 'application/constants'
import {WorkflowPanelInboxComponent} from './component'



const WorkflowPanelInboxEffectsC = (props) => {
    const { 
        onRefreshWidgetType, 
        match 
    } = props
    
    const { sharedResourceId } = match.params

    useEffect(() => {
        onRefreshWidgetType(WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_LIST)
    }, [onRefreshWidgetType, sharedResourceId])

    return <WorkflowPanelInboxComponent {...props} />
}

WorkflowPanelInboxEffectsC.propTypes = {
    onRefreshWidgetType: PropTypes.func.isRequired,
}

export const WorkflowPanelInboxEffects = withRouter(WorkflowPanelInboxEffectsC)