import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/modules/dynamic-form/controls/text_input'
import { InsuranceSelect } from 'application/modules/dynamic-form/controls/insurance_select'
import { validationMethods } from 'application/modules/dynamic-form/controls/common'
import { TwoColumns } from 'application/modules/dynamic-form/controls/two_columns'
import { PureSelectWithLabel } from 'application/modules/dynamic-form/controls/select/pure_select_with_label'
import PropTypes from 'prop-types'
import { PushBottom8 } from 'application/modules/dynamic-form/controls/styled_components'
import { SubHeadline } from 'application/modules/dynamic-form/controls/fragments/dialog'
import { FlexBox } from 'application/modules/dynamic-form/controls/fragments/flex_box'
import { exists } from 'application/modules/dynamic-form/controls/common/data_helpers'
import { withTheme } from 'styled-components'



const InsuranceInputGroupComponent = withTheme(({
    register,
    setValue,
    formStateValues,
    updateFormState,
    errors,
    reset,
    insuranceSelectInputName,
    insuranceTextName,
    insuranceSelectInputErrors = null,
    insuranceTextErrors = null,
    formTitle = 'Insurance',
    menuPlacement='bottom',
    validate={notEmpty: validationMethods.notEmpty},
    theme
}) => {
    const {t} = useTranslation()

    const [insuranceSelectionFromList, setInsuranceSelectionFromList] = useState(true)

    const insuranceTypeOptions = [
        {
            label: t('Select from list'),
            value: true
        },
        {
            label: t('Other insurance'),
            value: false
        }
    ]

    const removeCompanyIdFromValidationForm = () => {
        let formStateValuesWithoutCompanyId = formStateValues
        delete formStateValuesWithoutCompanyId[insuranceSelectInputName]
        updateFormState()
        reset(formStateValuesWithoutCompanyId)
        setValue(insuranceSelectInputName, null)
    }


    const handleSetInsuranceSelectionType = (value) => {
        setInsuranceSelectionFromList(value)
        !value && removeCompanyIdFromValidationForm()
    }


    return <FlexBox flexDirection='column'>
        {
            exists(formTitle) && <React.Fragment>
                <SubHeadline >{t(formTitle)}</SubHeadline>
                <PushBottom8/>
            </React.Fragment>
        }
        <TwoColumns
            breakPoint={theme.breakpoint.medium}
            columnLeft={
                <PureSelectWithLabel
                    options = {insuranceTypeOptions}
                    defaultValue = {insuranceSelectionFromList}
                    setValue = {handleSetInsuranceSelectionType}
                    untranslatedLabel = 'Insurance name'
                    menuPlacement={menuPlacement}
                />
            }
            columnRight={
                insuranceSelectionFromList
                    ?
                    <InsuranceSelect
                        register={register}
                        setValue={setValue}
                        name={insuranceSelectInputName}
                        initialValue={formStateValues[insuranceSelectInputName]}
                        onChange={updateFormState}
                        validate={validate}
                        error={insuranceSelectInputErrors !== null ? insuranceSelectInputErrors : errors[insuranceSelectInputName]}
                        menuPlacement={menuPlacement}
                        maxMenuHeight={204}
                    />
                    :
                    <TextInput
                        label={t('Name of other insurance')}
                        name={insuranceTextName}
                        register={register}
                        validate={validate}
                        error={insuranceTextErrors !== null ? insuranceTextErrors : errors[insuranceTextName]}
                        onChange={updateFormState}
                    />

            }
        />
    </FlexBox>
})


InsuranceInputGroupComponent.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    formStateValues: PropTypes.object.isRequired,
    insuranceSelectInputName: PropTypes.string.isRequired,
    insuranceTextName: PropTypes.string.isRequired,
    setFormStateValues: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export {InsuranceInputGroupComponent}