import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import PropTypes from 'prop-types'
import { getActivityLinksByType } from 'application/components/controls/activity_links'



export const TaskCommentCreatedActivity = (
    { activity }
) => {
    const {t} = useTranslation()

    const ActivityLinks = getActivityLinksByType(activity.type)

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t(`${activity.type}Title`)}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Title')}: {activity.data.task.title}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <ActivityLinks activity={activity}/>   
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

TaskCommentCreatedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}