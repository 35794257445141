import { takeLeading, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateAnnouncements } from 'application/redux/actions/pages/changelog/announcements'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { ListFilter, announcements } from 'application/filters'
import { ANNOUNCEMENTS } from 'application/constants/announcements'


function* getAnnouncementsSaga(action) {
    try {
        const {
            offset = 0, 
            limit = 100, 
            orderBy='iterator',
            direction = 'desc',
        } = action

        const filters = action.announcementType === ANNOUNCEMENTS.TYPE.ALL
            ? [
                new ListFilter(announcements.pastAnnouncements)
            ]
            : [
                new ListFilter(announcements.type).setValue(action.announcementType),
                new ListFilter(announcements.pastAnnouncements)
            ]

        const filterQuery = composeFilterQuery(filters)

        const response = yield resourceCall(
            api.gateway.announcements.getAnnouncements, 
            {
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery
            }
        )

        yield put(
            populateAnnouncements(response)
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* changelogWatcher() {
    yield takeLeading(
        ACTIONS.CHANGELOG_GET_ANNOUNCEMENTS, 
        getAnnouncementsSaga
    )
}