import styled from 'styled-components'


export const PreloaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
    flex-direction: column;
    margin: 0 auto;
`

export const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    padding: 0 20px;
    height: 64px;
    position: fixed; 
    background: ${props => props.theme.color.white};
    bottom: 0;
`