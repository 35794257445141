import { getWidgetContextMeta } from './get_widget_context_meta'

export const widgetListStore = widgetContextName => {
    const { storage, defaults } = getWidgetContextMeta(widgetContextName)

    return {
        store: (list) => {
            localStorage.removeItem(storage.list)
            localStorage.setItem(storage.list, JSON.stringify({list}))
        },
        get: () => {
            if(storage === null){
                return defaults.list
            }
            const storageEntry = localStorage.getItem(storage.list)
            return storageEntry === null ? defaults.list : (JSON.parse(storageEntry)).list
        },
        delete: () => {
            localStorage.removeItem(storage.list)
        }
    }
}