import { useEffect }  from 'react'
import { useDispatch } from 'react-redux'
import {   registerDocumentsByCategory } from 'application/redux/actions/documents_by_categories'
import { useSelector } from 'react-redux'
import {  isNull } from 'application/common/data_helpers'
import { getDocumentsByCategory, getDocsByCatsResourceId } from 'application/redux/selectors'



export const useDocsByCategoriesRegisterDocCategory = (
    category
) => {
    const resourceId = useSelector(getDocsByCatsResourceId)
    const categoryIsNoParentCategory = category.categories.length === 0
    const categoryId = category.id
    const dispatch = useDispatch()
    const documentsByCategory = useSelector(
        getDocumentsByCategory(categoryId)
    )
    const categoryIsRegistered = !isNull(documentsByCategory)

    useEffect(
        () => {
            categoryIsNoParentCategory
            && !isNull(resourceId)
            && !categoryIsRegistered
            && dispatch(
                registerDocumentsByCategory(
                    categoryId
                )
            ) 
        },
        [dispatch, categoryId, categoryIsNoParentCategory, categoryIsRegistered, resourceId], 
    )

    return { 
        documentsByCategoryIsRegistered: categoryIsRegistered
    }

}