import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { populateSelectedTask, updateTaskInList } from 'application/redux/actions/pages/damage_manager/tasks'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { put } from 'redux-saga/effects'


export function* dialogAssignTaskSaga(action) {

    const {
        damageId,
        taskId
    } = action.metaData

    const {
        assignTask,
        task
    } = action.payload

    try {
        yield resourceCall(
            api.gateway.tasks.assignTask,
            {
                damageId,
                taskId
            },
            assignTask
        )       
        yield put(
            updateTaskInList(
                taskId,
                task                
            )
        )
        yield put(
            populateSelectedTask(task)
        )
        yield put(
            showToast('Task has been assigned')
        )
    } catch (e) {
        yield handleError(e, action)
    }
}