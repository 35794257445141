import { ACTIONS } from 'application/constants'

export const toggleMainNavigationSidebar = () => ({
    type: ACTIONS.MAIN_NAVIGATION_SIDEBAR_TOGGLE,
})

export const registerAccordeonMenuState = (
    name,
    accordionOpen
) => ({
    type: ACTIONS.MAIN_NAVIGATION_REGISTER_ACCORDION_MENU_STATE,
    name,
    accordionOpen
})

export const openOnlyCurrentAccordeon = (name) => ({
    type: ACTIONS.MAIN_NAVIGATION_OPEN_ONLY_CURRENT_ACCORDION,
    name
})

export const closeAccordions = () => ({
    type: ACTIONS.MAIN_NAVIGATION_CLOSE_ACCORDIONS
})


