import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { ActivityDamageTaskLink } from './_common/damage_task_activity_link'



export const TaskStateChangedMetaContent = (
    {
        notification, 
        inline,
        onLinkClick
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification
        
    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='Task name'
            value={payload.title}
        />
        <Fragments.ActivityDescription 
            label='State'
            value={`${t(payload.oldState)} → ${t(payload.newState)}`}
        />
        <ActivityDamageTaskLink
            onClick={onLinkClick}
            damageId={payload.damage.id}
            taskId={payload.taskId}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}