import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const featureFlagsReducer = (
    state = defaultStore.featureFlags, 
    action
) => {
    switch (action.type) {

    case ACTIONS.POPULATE_FEATURE_FLAGS: {
        return {
            ...state,
            loaded: true,
            loading: false,
            activeFeatures: action.activeFeatures
        }
    }
    default:
        return state
    }
}