import React, {useEffect} from 'react'

export const withStatuslistLoaderComponent = Component => (props) => {

    const { 
        onLoadStatusList, 
        statusList
    } = props

    useEffect(
        () => {
            !statusList.loaded 
            && !statusList.loading 
            && onLoadStatusList()
        }, 
        [onLoadStatusList, statusList.loaded, statusList.loading]
    )

    if(!statusList.loaded){
        return null
    }
         
    return <Component  {...props} />
}