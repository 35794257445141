import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextArea } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { useStatefulForm } from 'application/components/hooks'

const EditCaseNotes = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onEditTask,
        dialog, 
        match 
    }
) => {
    const { t } = useTranslation()

    const defaultValues = {
        noteContent: dialog.payload.note?.content || ''
    }

    const { 
        register, 
        errors, 
        handleSubmit, 
        getValues 
    } = useStatefulForm( { defaultValues} )

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }
    const damageId = match.params.damageId
    const onSubmitSuccess = () => { 
        onEditTask(dialog.name, {
            ...getValues(),
        }, damageId)
    }

    const onError = (errors, e) => console.log(errors, e)

    return <React.Fragment>
        <DialogMainContent>
            <TextArea
                label={t('Case notes')}
                name='noteContent'
                register={register}
                validate={null}
                error={errors.noteContent}
                onChange={onDataChange}
            />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Edit case notes'
        />
    </React.Fragment>

}

export const EditCaseNotesComponent = withDialogDataLoader(
    withRouter(EditCaseNotes)
) 