import React from 'react'
import { StyledTableCell } from '../table_cell'
import { useTranslation } from 'react-i18next'
import { TableHeaderLink } from 'application/components/controls/table_header_link'
import { TableCell } from '../table_cell'
import { exists, isDefined } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import styled from 'styled-components'






const StyledTableHeader = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.color.gray15};
    padding: 4px 8px;
    height: 28px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    justify-content: space-between;
    width: ${props => props.width ? props.width : '100%'};
`








export const TableHeaderRow = (
    {   
        sorting,
        columns,
        configuration
    }
) => {
    const {t} = useTranslation()
    const contextMenuWidth = configuration.contextMenuWidth || 0
    const hasDetails = exists(configuration?.rows?.details) 
    const placeHolderWidth = 48
    const placeHolderMenuWidth = hasDetails ? placeHolderWidth : 0
    const fixedSpace = contextMenuWidth + placeHolderMenuWidth 
    const flexibleCellContainerWidth = `calc(100% - ${fixedSpace}px)`

    return <StyledTableHeader>
        {
            hasDetails && <StyledTableCell 
                flexGrow='0' 
                cssWidth={placeHolderWidth + 'px'}
                cssMaxWidth={placeHolderWidth + 'px'} 
            />
        }
        <FlexBox 
            flexBasis={flexibleCellContainerWidth} 
            flexGrow='0' 
            flexShrink='0'
        > 
            {
                columns.map(
                    (thcell, i) => {
                        return <TableCell
                            key={i}
                            column={thcell} 
                            configuration={configuration} 
                        >
                            {
                                thcell.hasSorting 
                                    ? <TableHeaderLink 
                                        {...sorting}
                                        sortParamValue={thcell.sortParamValue}
                                    >
                                        {t(thcell.label)}
                                    </TableHeaderLink>
                                
                                    : t(thcell.label)
                            }
                            {
                                isDefined(thcell.secondLabel) && <React.Fragment>
                                    <br />
                                    { t(thcell.secondLabel) }
                                </React.Fragment>
                            }
                        </TableCell>
                    }
                )
            }
        </FlexBox>
        {
            exists(configuration.ContextMenu) 
            && <StyledTableCell 
                className='header-context-menu'
                flexGrow='0' 
                cssWidth={`${configuration.contextMenuWidth}px`} 
                cssMaxWidth={`${configuration.contextMenuWidth}px`}
            />
        }
    </StyledTableHeader>
}