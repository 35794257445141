import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import PropTypes from 'prop-types'
import { isEmptyString } from 'application/common/data_helpers'



const TaskDeletedActivity = (
    {
        activity
    }
) => {
    const {t} = useTranslation()
            
    const licensePlate = activity.data.damage?.licensePlate || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t(`${activity.type}Title`)}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Title')}: {activity.data.title}
        </Fragments.ActivityParagraph>
        {
            !isEmptyString(licensePlate) && <Fragments.ActivityParagraph>
                {t('Licenseplate')}: {activity.data.damage.licensePlate}
            </Fragments.ActivityParagraph>
        }
    </React.Fragment>
}

TaskDeletedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}

export { TaskDeletedActivity }