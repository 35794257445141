
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { BaseTableRow, baseTableRowCSS } from 'application/components/tables/refactored/_common/rows/base'
import { exists } from 'application/common/data_helpers'


const StyledSelectableTableRow = styled.div`
    ${baseTableRowCSS}

    border-top: 2px solid ${props => props.bordercolor}; 
    border-bottom: 2px solid ${props => props.bordercolor}; 

     & *:not(.context-menu){
        cursor: inherit !important;
    }
    cursor: pointer;
`


const SelectableTableRowBaseComponent = (
    {
        theme,
        element,
        selectItem,
        deselectItem,
        cssHeight,
        configuration,
        flexibleCellContainerWidth,
        moIsActive = false,
        className = 'table-row-selectable',
        isSelected = false,
        isColoured = false
    }
) => {
    const handleOnClick = () => {
        isSelected 
            ? deselectItem(element.id) 
            : selectItem(element)
    }

    const hasDetails = exists(configuration?.rows?.details) 

    const rowColor = isColoured
        ?  theme.color.gray5
        :  theme.color.white
        
    const selectedBackgroundColor = isSelected 
        ? theme.color.primaryBackground
        : rowColor

    const borderColor =  isSelected
        ? !isColoured 
            ? theme.color.white
            : theme.color.primaryBackground
        : isColoured 
            ? rowColor
            : theme.color.white
 
    return <StyledSelectableTableRow
        cssheight={cssHeight}
        onClick={handleOnClick}
        backgroundcolor={selectedBackgroundColor}
        bordercolor={borderColor}
    >
        <BaseTableRow 
            element={element}
            configuration={configuration}
            flexibleCellContainerWidth={flexibleCellContainerWidth}
            isSelected={isSelected}
            moIsActive={moIsActive}
            className={className}
            hasDetails={hasDetails}
        />
    </StyledSelectableTableRow>
}

export const SelectableTableRowBase = withTheme(
    SelectableTableRowBaseComponent
)