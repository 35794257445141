import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTitleBar, Cell, TitleBarHeadline } from 'application/components/pages/internal/_title_bars/_common'
import { USER_PERMISSONS } from 'application/constants'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import {ActionButtonBar} from 'application/components/controls/action_button_bar/component'
import { Spacer } from 'application/components/pages/_common'
import { TitleBarActionButton } from 'application/components/controls/action_button_titlebar'
import { withTheme } from 'styled-components'
// import {DekraButton} from 'application/components/controls/dekra_button'
import { UserSection } from 'application/components/pages/internal/_title_bars/_common/user_section'
import { TitlebarAccountLogo } from 'application/components/building_blocks/title_bar_account_logo'
import {DamagesSearch} from 'application/components/building_blocks/damages_search'
import { TitleBarRow } from 'application/components/pages/internal/_title_bars/_common/row'



export const TitleBar = withTheme(
    (
        { 
            onCreateDamage, 
            onCreateDamageKickstart, 
            currentAccount, 
            hasAccount,
            theme
        }
    ) => {
        const {t} = useTranslation()

        return (
            <StyledTitleBar>
                <TitleBarRow>
                    <Cell>
                        <TitlebarAccountLogo
                            accountId={currentAccount.id}
                        />
                        <Spacer cssMargin='0 0 0 16px' />
                        {
                            hasAccount && <TitleBarHeadline>{currentAccount.name}</TitleBarHeadline>
                        }  
                    </Cell>
                    <Cell>
                        <ForCurrentBranchPermissions
                            permissions={[
                                USER_PERMISSONS.DAMAGE.CREATE, 
                                USER_PERMISSONS.CUSTOMER.CREATE, 
                                USER_PERMISSONS.CUSTOMER.READ
                            ]}
                            allNeeded={true} 
                        >
                            <ActionButtonBar 
                                inline={true}
                                gap='0'
                            >
                                <TitleBarActionButton
                                    onButtonClick={onCreateDamage} 
                                    buttonText={t('Damage')} 
                                    icon='post_add'
                                    className='jestCreateDamageButton'
                                    isFirst={true}
                                    hoverBackground={theme.color.black10}
                                />
                                <TitleBarActionButton 
                                    onButtonClick={onCreateDamageKickstart} 
                                    buttonText={t('Kickstart')} 
                                    icon='auto_fix_high'
                                    hoverBackground={theme.color.black10}
                                />
                                {/* <DekraButton /> */}
                            </ActionButtonBar>
                        </ForCurrentBranchPermissions>
                    </Cell>
                    <Cell>
                        <DamagesSearch />        
                        <UserSection />
                    </Cell>
                </TitleBarRow>
            </StyledTitleBar>
        )
    }
)
