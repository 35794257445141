import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import { exists } from 'application/common/data_helpers'

export const ContactInfoUpdatedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Contactinfo has been updated')}
        </Fragments.ActivityTitle>
        
        <Fragments.ActivityParagraph>
            {
                exists(activity.data.oldContactInfo) && <React.Fragment>
                    {activity.data.oldContactInfo.name}<RightArrow />
                </React.Fragment>
            }
            {activity.data.newContactInfo.name}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            {
                exists(activity.data.oldContactInfo) && <React.Fragment>
                    {activity.data.oldContactInfo.phoneNumber}<RightArrow />
                </React.Fragment>
            }
            {activity.data.newContactInfo.phoneNumber}
        </Fragments.ActivityParagraph>
    </React.Fragment>
}