import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { validationMethods } from 'application/common'
import { PhoneNumberInput, TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom24} from 'application/components/pages/_common'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { GeoCodingControl } from 'application/components/controls/geocoding_control'
import { useGeoDataSubmit} from 'application/components/hooks'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from '../_partial_forms'
import { TwoColumns } from 'application/components/forms/_common'
import { SubHeadline } from 'application/components/fragments/dialog'








const EditAccountForm = (
    {
        name ='',
        address= null, 
        onSave,
        markerMoved = false,
        contact = null
    }
) => {
    const {t} = useTranslation() 

    const defaultValues = {
        name,
        address: {
            line1: address?.line1 || '',
            postalCode: address?.postalCode || '',
            locality: address?.locality || '',
            coordinates: {
                latitude: address?.coordinates?.latitude || '',
                longitude: address?.coordinates?.longitude || ''
            }
        },
        contact: {
            email: contact?.email || '',
            phoneNumber: contact?.phoneNumber || ''
        }
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue,
        formStateValues, 
        updateFormState,
        control
    } = useStatefulForm({defaultValues})

    const handleSubmitWithCoordinates = (
        lat, 
        lon
    ) => {
        const accountData =   {
            latitude: lat,
            longitude: lon,
            ...getValues()        
        }

        onSave(
            accountData
        )
    }   

    const {
        onSubmitSuccess,
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } = useGeoDataSubmit(
        formStateValues.address,
        setValue,
        handleSubmitWithCoordinates,
        !markerMoved,
        'address.coordinates.latitude',
        'address.coordinates.longitude'
    )

    const onChange = () =>{
        updateFormState({
            line1: getValues().line1,
            postalCode: getValues().postalCode,
            locality: getValues().locality
        })
    }




    return <FlexBox 
        flexDirection='column'
        alignItems='flext-start'
    >
        <SubHeadline>{t('Account data')}</SubHeadline>
        <FlexBox   
            flexDirection='column'
        >
            <TextInput 
                label={t('Company name')}
                name='name'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.name}
            />

            <TwoColumns
                columnLeft={ 
                    <TextInput
                        label={t('Email')}
                        name='contact.email'
                        register={register}
                        validate={{
                            mustBeEmail: validationMethods.mustBeEmail
                        }}
                        error={errors.contact?.email}
                        onChange={onChange}
                    />
                }
                columnRight={
                    <PhoneNumberInput
                        defaultValue={contact.phoneNumber}
                        validate={null}
                        control={control}
                        label={t('Phone number (mobile or landline)')}
                        name='contact.phoneNumber'
                        register={register}
                        error={errors.contact?.phoneNumber}
                        onChange={onChange}
                        setValue={setValue}
                        allowLandLine={true}
                    />
                }
            />
            <PushBottom24 />
            <AddressDataPartialForm 
                register={register}
                errors={errors}
                onChange={onChange}
                setValue={setValue}
            />

            <PushBottom24 />
            <GeoCodingControl
                register={register}
                useGeoCoding={useGeoCoding}
                setUseGeoCoding={setUseGeoCoding}
                errors={errors}
                setValue={setValue}
                defaultLatitude={defaultValues?.address?.coordinates?.latitude}
                defaultLongitude={defaultValues?.address?.coordinates?.longitude}
                markerMoved={markerMoved}
            /> 
            <PushBottom24 />
            <FlexBox justifyContent='space-between'>
                <FlexBox flexBasis='100px' flexGrow='0' alignItems='center'>
                    {
                        submitDisabled && <ThemedPreloader 
                            small={true} 
                            preloaderText={t('geocoding...' )}
                            cssDirection='row' 
                            paddingTop={0} 
                        />
                    }
                </FlexBox>
                <ActionButton 
                    buttonText={t('Save account data')} 
                    onButtonClick={handleSubmit(onSubmitSuccess)}
                    disabled={submitDisabled}
                />
            </FlexBox>
            <PushBottom24 />
            <PushBottom24 />
        </FlexBox>
    </FlexBox>
}

export { EditAccountForm }