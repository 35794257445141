import { connect } from 'react-redux'
import {DialogsComponent} from './component'
import { closeDialogByName } from 'application/redux/actions/dialogs'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        dialogs: state.dialogs,
        galleryVisible: false,
        waitingScreen: state.waitingScreen,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCloseDialog: (name, contexts) =>{
            dispatch(closeDialogByName(name))
            for(const context of contexts){
                dispatch(refreshWidgetsByContext(context))
            }
            // dispatch(closeAttachmentDetailsDialog())
            // dispatch(clearCaseHistory())
            // dispatch(clearTaskHistory())
            // dispatch(clearDocumentsOnQueue())
            // dispatch(commentsClearStore())
        },
    }
}

export const Dialogs = connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogsComponent)