import React from 'react'
import { ErrorPageBase } from 'application/components/pages/errors/_common'


const NoPermissionsPage = () => {
    return <ErrorPageBase 
        errorMessage='You have no permissions for this app'
        linkText=''
        linkURL=''
        mainLogoURL=''
    />
}

export {NoPermissionsPage}