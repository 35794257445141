import { put, takeEvery } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* updateDocumentSaga(action) {
    try {
        yield resourceCall(
            api.gateway.attachments.updateAttachment, 
            {
                damageId: action.damageId,
                attachmentId: action.attachmentId
            }, 
            action.payload
        )
        yield put(showToast('Document updated'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateDocumentWatcher() {
    yield takeEvery(ACTIONS.DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT, updateDocumentSaga)
}