import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'
import { useTranslation } from 'react-i18next'



export const LiabilityInquiryUpdatedDetails = (
    {
        activity
    }
) => {
    const {t} = useTranslation()

    const {
        newLiabilityInquiry
    } = activity.data

    const status = newLiabilityInquiry?.status || ''
    const info = newLiabilityInquiry?.info || ''



    return <ActivityDetailWrapper>
        <ActivityDetail 
            label='Liability type'
            value={t(status)} 
        />
        <ActivityDetail 
            label='Liability request type'
            value={t(info)} 
        />
    </ActivityDetailWrapper>
}

LiabilityInquiryUpdatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}