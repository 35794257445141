import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { buildListQuery } from 'application/api/helpers'
import { apiCall } from 'application/api/helpers'

export const getDamageManagerAllMessageList = (params) => {
    const {
        offset, 
        limit, 
        orderBy='createdAt',
        direction='desc',
        filterQuery = []
    } = params

    const queryString = buildListQuery(offset, limit, orderBy, direction, filterQuery)

    return {
        authenticated: () => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/messages${queryString}`, HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null
    }
}