import { updateAccount } from 'application/redux/actions/common'
import { connect } from 'react-redux'
import {AdministrationSettingsAccountDataComponent} from './component'



const mapStateToProps = state => {
    return {
        currentAccount: state.auth.currentAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveAccount: (
            accountData
        ) => dispatch(
            updateAccount(
                accountData
            )
        )
    }
}

export const AdministrationSettingsAccountData =  connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministrationSettingsAccountDataComponent)