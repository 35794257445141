import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, TextArea, DatePicker } from 'application/components/controls/form'
import { PrioritySelect } from 'application/components/controls/form/priority_select'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useStatefulForm } from 'application/components/hooks'
import { TwoColumns } from 'application/components/forms/_common'
import { TASK_PRIORITIES } from 'application/constants'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { isBeforeOtherDate } from 'application/common/date_time_helpers'




const CreateTaskworkflowPanel = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onCreateTaskWorkflowPanel, 
        dialog 
    }
) => {
    const damageIncidentDate = dialog.payload.damage?.incidentDate
    const { t } = useTranslation()

    const defaultValues = {
        priority: TASK_PRIORITIES.MEDIUM,
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        formStateValues,
        updateFormState,
        trigger
    } = useStatefulForm(
        {defaultValues}
    )
    
    const { sharedResourceId } = dialog.payload

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const onSubmitSuccess = () => { 
        onCreateTaskWorkflowPanel(dialog.name, sharedResourceId, {
            ...getValues()
        })
    }

    const onError = (errors, e) => console.log(errors, e)


    return <React.Fragment>
        <DialogMainContent>
            <TwoColumns
                columnLeft={
                    <TextInput 
                        label={t('Title')}
                        name='title'
                        register={register}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.title}
                        onChange={onDataChange}
                    />
                }
                columnRight={
                    <DatePicker 
                        label={t('Deadline')}
                        name='deadline'
                        validate={null}
                        error={errors.deadline}
                        onDateChange={onDataChange}
                        control={control}
                        register={register}
                        defaultValue={formStateValues.deadline}
                        setValue={setValue}
                        trigger={trigger}
                    />
                }
            />
            <PrioritySelect 
                register={register}
                setValue={setValue}
                initialValue={formStateValues.priority}
                error={errors.priority}
                onChange={onDataChange}
            />
            <TextArea 
                label={t('Description')}
                name='description'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.description}
                onChange={onDataChange}
            />
        </DialogMainContent>
        <Footer
            showWarning={
                isBeforeOtherDate(
                    formStateValues.deadline,
                    damageIncidentDate
                )
            }
            untranslatedWarningText={'Deadline is before incident date'}
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Create Task'
        />
    </React.Fragment>

}

export const CreateTaskworkflowPanelComponent = withDialogDataLoader(CreateTaskworkflowPanel)