import { put } from 'redux-saga/effects'
import { populateUserData } from 'application/redux/actions/auth'

export function* syncCurrentUserSaga (action){
    const {previousUserData, shouldSendPreferredBranch} = action.metaData
    const {user, preferredBranch} = action.payload

    yield put(populateUserData({
        ...previousUserData,
        ...user,
        preferredBranch: shouldSendPreferredBranch ? preferredBranch : previousUserData.preferredBranch
    }))
}