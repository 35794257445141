import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { FlexBox } from 'application/components/fragments/flex_box'



const  TaskDamageRepairStartedAtFormComponent = withRouter(
    (
        {
            widgetKey, 
            payload, 
            loadingFailed,
            onSave, 
            match
        }
    ) => {
        const {t} = useTranslation()
        const {damageId} = match.params

        if(loadingFailed){
            return <WidgetErrorMessage>
                {t('In Repair since data could not be loaded')}
            </WidgetErrorMessage>
        }
    
        return <FlexBox flexDirection='column'>
            <Collapsable 
                headline={t('In Repair since')} 
                initiallyCollapsed={false}
            >
                <FlexBox
                    height='250px'
                    flexDirection='column'
                >
                    <DamageForms.DamageRepairStartedAtForm 
                        onSave={onSave} 
                        damageId={damageId}
                        incidentDate = { payload.formData?.incidentDate }
                        repairStartedAt = { payload.formData?.repairStartedAt }
                    />
                </FlexBox>
            </Collapsable>
        </FlexBox>
    }
)

TaskDamageRepairStartedAtFormComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamageRepairStartedAtFormComponent }