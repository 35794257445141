import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import { FlexBox } from 'application/components/fragments/flex_box'

export const TaskDamageVehicleDataFormComp = (
    {
        widgetKey, 
        payload, 
        loadingFailed, 
        onSave
    }
) => {
    const {t} = useTranslation()
    const {formData, damageId} = payload
     
    if(loadingFailed){
        return <WidgetErrorMessage>{t('Vehicle data could not be loaded')}</WidgetErrorMessage>
    }
    
    return <FlexBox flexDirection='column'>
        <Collapsable
            headline={t('Vehicle')} 
            initiallyCollapsed={true}
        >
            <DamageForms.DamageVehicleForm 
                onSave={onSave}  
                damageId={damageId}
                formData={formData}
            />
        </Collapsable>
    </FlexBox>
}

TaskDamageVehicleDataFormComp.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}