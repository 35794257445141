import React from 'react'
import { useTranslation } from 'react-i18next'
import { VEHICLE_STATE } from 'application/constants/vehicle_state'

export const VehicleStateName = ({ vehicleState }) => {
    const { t } = useTranslation()
    const mapVehicleStateName = (type) => {
        const names = new Map([
            [VEHICLE_STATE.NOT_READY_TO_DRIVE, 'Not ready to drive'],
            [VEHICLE_STATE.READY_TO_DRIVE_NOT_SAFE_FOR_TRAFFIC, 'Ready to drive, not safe for traffic'],
            [VEHICLE_STATE.READY_TO_DRIVE_SAFE_FOR_TRAFFIC, 'Ready to drive, safe for traffic'],
            [VEHICLE_STATE.NOT_SET, 'Not set'],
        ])

        return names.get(type)
    }

    return <React.Fragment>
        {t(mapVehicleStateName(vehicleState))}
    </React.Fragment>
}