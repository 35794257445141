import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput, YesNoSelect, PhoneNumberInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { useTranslation } from 'react-i18next'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { TwoColumns } from 'application/components/forms/_common'
import { SubHeadline } from 'application/components/fragments/dialog'
import { getFullName } from 'application/common/name_helpers'

export const CommissionDekraCustomerPartialForm = ({
    formHook,
    customer
}) => {
    const {t} = useTranslation()

    const {
        mustBeEmail,
        notEmpty,
        mustBeGermanPostalCode
    } = validationMethods

    const {
        register, 
        errors, 
        control,
        updateFormState,
        setValue,
    } = formHook

    return <FlexBox flexDirection='column'>
        <SubHeadline>{t('Complete customer data')}: {getFullName(customer)}</SubHeadline>
        <AddressDataPartialForm
            register={register}
            disabled={false}
            nameStreet = 'customer.address.line1'
            namePostalCode = 'customer.address.postalCode'
            nameCity = 'customer.address.locality'
            setValue={formHook.setValue}
            validate={{ notEmpty: validationMethods.notEmpty }}
            validatePostalCode={{notEmpty, mustBeGermanPostalCode}}
            errorNameStreet={errors.customer?.address?.line1}
            errorNamePostalCode={errors.customer?.address?.postalCode}
            errorNameCity={errors.customer?.address?.locality}
        />
        <TextInput
            label={t('Email')}
            name='email'
            register={register}
            validate={{mustBeEmail}}
            error={errors.email}
            onChange={updateFormState}
        />
        <PhoneNumberInput
            validate={{notEmpty}}
            control={control}
            label={t('Mobile phone number')}
            name='customer.mobilePhoneNumber'
            register={register}
            error={errors.customer?.mobilePhoneNumber}
            onChange={updateFormState}
            setValue={setValue}
        />
        <TextInput
            label={t('IBAN')}
            name='customer.iban'
            register={register}
            validate={{mustBeIBAN: validationMethods.mustBeIBAN}}
            error={errors.customer?.iban}
            onChange={updateFormState}
        />
        <TwoColumns
            columnLeft={
                <TextInput
                    label={t('Customer number')}
                    name='customer.externalReferenceId'
                    register={register}
                    validate={null}
                    error={errors.customer?.externalReferenceId}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <YesNoSelect
                    register={register}
                    setValue={setValue}
                    initialValue={false}
                    name='customer.isEntitledToTaxDeduction'
                    label={t('Is entitled to tax deduction')}
                    onChange={updateFormState}
                    menuPlacement='top'
                />
            }
        />
    </FlexBox>
}