import { INFOBOX } from 'application/constants'

const { IconButton } = require('@ec/ui-controls-react')
const { InfoBox } = require('application/components/controls/info_box')
const { Spacer } = require('application/components/pages/_common')

export const BranchFilterPill = ({
    branch,
    onRemove,
    disabled
}) => {

    return <InfoBox direction='row' cssMargin='0 8px 8px 0' type={INFOBOX.TYPES.WARNING} cssWidth='auto'>
        <span>{branch.name}</span>
    
        <Spacer />
        {
            !disabled 
                ? <IconButton
                    iconKey='close' 
                    width='12px' 
                    height='16px' 
                    onButtonClick={() => {
                        onRemove(branch)
                    }} 
                />
                : <IconButton
                    iconKey='close' 
                    width='12px' 
                    height='16px' 
                    color='#aaaaaa'
                    onButtonClick={() => {}} 
                />
        }
    </InfoBox>
}