import { CONNECTION_TYPE } from 'application/constants/branch_management'
import { buildEdge } from './build_edge'

export const getEdges = (groups, roles, branches, branchesDisabled = false) => {
    const edges = groups.map(group => {
        const roleEdges = group.roles.map(role => {
            const connectedNodes = [group, roles.find(r => r.id === role.id)]
            const isStatic = group.isPredefined
            return buildEdge(role.id, group.id, CONNECTION_TYPE.GROUP_TO_ROLE, branchesDisabled, connectedNodes, isStatic)
        })
        const branchEdges = group.canAccessAllBranches 
            ? []
            : group.branches.map(branch => {
                const connectedNodes = [group, branches.find(b => b.id === branch.id)]
                return buildEdge(group.id, branch.id, CONNECTION_TYPE.GROUP_TO_BRANCH, branchesDisabled, connectedNodes)
            })
        const staticBranchEdges = !group.canAccessAllBranches 
            ? []
            : branches.map(branch => {
                const connectedNodes = [group, branches.find(b => b.id === branch.id)]
                return buildEdge(group.id, branch.id, CONNECTION_TYPE.GROUP_TO_BRANCH, branchesDisabled, connectedNodes, true)
            })
        return [...roleEdges, ...branchEdges, ...staticBranchEdges]
    })
    return edges.flat()
}