import { call, put, all } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ListFilter } from 'application/filters/filter_class'
import * as filters from 'application/filters'

export function* dialogGetDamageInvoicesSaga(action) {
    const { dialogName, metaData } = action
    const { damageId } = metaData

    const invoicesResponse = yield resourceCall(
        api.gateway.invoices.getInvoices, 
        {
            damageId: damageId
        }
    )

    const getPaymentsServerCalls = invoicesResponse.invoices.map(
        invoice => function* () {
            const paymentResponse = yield resourceCall(
                api.gateway.invoices.getPayments, 
                {
                    damageId: damageId, 
                    invoiceId: invoice.id
                }
            )
            invoice.payments = paymentResponse.payments
        }
    )

    yield all(
        getPaymentsServerCalls.map( sc => call(sc) )
    )

    const getDocumentsServerCalls = invoicesResponse.invoices.map(
        invoice => function* () {
            const documentsResponse = yield resourceCall(
                api.gateway.attachments.getAttachmentsByDamageId,
                {
                    damageId: damageId,
                    filterQuery: new ListFilter(
                        filters.documents.parentInvoiceId
                    )
                        .setValue(invoice.id)
                        .getQuery()
                }
            )
            invoice.documents = documentsResponse.documents
        }
    )

    yield all(
        getDocumentsServerCalls.map( sc => call(sc) )
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                hasData: invoicesResponse !== '', 
                data: invoicesResponse
            }
        )
    )
}