import { connect } from 'react-redux'
import { KickstartExternalComponent } from './component'
import { createDamageKickstartRequest } from 'application/redux/actions/ext_create_damage'

const mapStateToProps = state => ({
    damageDataSent: state.pages.extCreateDamageKickstart.damageDataSent,
    damageDataSending: state.pages.extCreateDamageKickstart.damageDataSending
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamageKickstart: (payload) => dispatch(createDamageKickstartRequest(payload))
    }
}

export const KickstartExternal= connect(
    mapStateToProps,
    mapDispatchToProps
)(KickstartExternalComponent)