import styled from 'styled-components'

export const StyledSidebar = styled.div`
    position: fixed;
    right: 0;
    width: 540px;
    height: 100vh;
    background: ${props => props.theme.color.gray20};
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 8px;
    flex: 0 0 100vh;
    box-shadow: -4px 1px 16px 0px rgba(0,0,0,0.36);
`