import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { TASK_STATES } from 'application/constants'


const StyledTaskStatusIcon = styled.div`
    width: ${props => props.small ? '16px' : '24px'};
    height: ${props => props.small ? '16px' : '24px'};
    min-width: ${props => props.small ? '16px' : '24px'};
    min-height: ${props => props.small ? '16px' : '24px'};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.cssBorder};
    border-radius: ${props => props.small ? '8px' : '12px'};;
    background-color: ${props => props.cssBackground};
    & > i {
        color: ${props => props.cssIconColor};
        font-size: ${props => props.small ? props.theme.fontSize.small : props.theme.fontSize.medium};
    }
`

const getStyle = (state, isAssigned, isActive, theme) => {
    switch (state) {
    case TASK_STATES.TO_DO:
        if(isAssigned){
            return {
                border: isActive ? theme.color.anthracite : theme.color.gray15,
                background: isActive ? theme.color.anthracite : theme.color.gray15,
                icon: 'person',
                iconColor: theme.color.white
            }   
        }
        return {
            border: isActive ? theme.color.anthracite : theme.color.gray15,
            background: theme.color.white,
            icon: '',
            iconColor: theme.color.white
        }     
    case TASK_STATES.DONE:
        return {
            border: theme.color.green,
            background: theme.color.green,
            icon: 'check',
            iconColor: theme.color.white
        }  
    case TASK_STATES.TO_REVIEW:   
        return {
            border: theme.color.yellow,
            background: theme.color.yellow,
            icon: 'remove_red_eye',
            iconColor: theme.color.white
        }  
    default:
        return {
            border: isActive ? theme.color.anthracite : theme.color.gray15,
            background: theme.color.white,
            icon: '',
            iconColor: theme.color.white
        }     
    }
}

const TaskStatusIconComponent = ({ state, isActive, isAssigned, theme, small = false }) => {
    const style = getStyle(state, isAssigned, isActive, theme)
    return <StyledTaskStatusIcon cssBackground={style.background} cssBorder={style.border} cssIconColor={style.iconColor} small={small}>
        {
            style.icon !== '' && <i className="material-icons">
                {style.icon}
            </i>
        }
    </StyledTaskStatusIcon>
}


TaskStatusIconComponent.propTypes = {
    state: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired
}

export const TaskStatusIcon = withTheme(TaskStatusIconComponent)