import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { handleError } from '../../errors'
import { populateUserSettings, syncUserSettings } from 'application/redux/actions/ui_settings'
import { userSettingsIntegrityCheck } from './user_settings_integrity_check'
import { exists } from 'application/common/data_helpers'
import { showToast } from 'application/redux/actions/notifications'

function* requestUserSettingsSaga(action) {
    try {
        const {accountId} = action
        
        const settingsResponse = yield resourceCall(
            api.gateway.uiSettings.getUserSettings
        )

        const settings = exists(settingsResponse.version)
            ? settingsResponse
            : settingsResponse.matches[0].value

        const {
            isUpToDate,
            updatedSettings
        } = yield call(userSettingsIntegrityCheck, settings)
        
        if(!isUpToDate){
            yield resourceCall(
                api.gateway.uiSettings.updateUserSettings,
                null,
                updatedSettings
            )
        }

        yield put(populateUserSettings(accountId, updatedSettings))
    } catch (e) {
        yield handleError(e)
    }
}



export function* userSettingsWatcher() {
    yield takeLatest(
        ACTIONS.UI_SETTINGS_GET_USER_SETTINGS_REQUEST, 
        requestUserSettingsSaga
    )
}

function* saveUserSettingsSaga(action) {
    try {
        yield put(syncUserSettings(action.settings))

        if(action.saveDirectly){
            yield resourceCall(
                api.gateway.uiSettings.updateUserSettings,
                null,
                action.settings
            )

            yield put(showToast('Settings saved'))
        }
    } catch (e) {
        yield handleError(e)
    }
}

export function* saveUserSettingsWatcher() {
    yield takeLatest(
        ACTIONS.UI_SETTINGS_SAVE_USER_SETTINGS_REQUEST, 
        saveUserSettingsSaga
    )
}