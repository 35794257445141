import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { PushBottom16} from 'application/components/pages/_common'
import { JsonTree } from 'application/components/building_blocks/json_tree'
import { Headline2 } from 'application/components/fragments/typography'
import { BulletList, BulletListItem } from 'application/components/widgets/_common'

export const ErrorTree = ({
    complete,
    dataLoaded,
    invalidCustomers
}) => {
    const theme = useTheme()
    const {t} = useTranslation()
    return <React.Fragment>
        {
            !complete && dataLoaded && invalidCustomers.length > 0 && <React.Fragment>
                <InfoBox 
                    type={INFOBOX.TYPES.ALERT} 
                    direction='row' 
                    cssMinHeight='208px'
                >
                    <FontIcon icon='report_problem' color={theme.color.error} />
                    <Spacer basis='10px' />
                    <div>
                        <strong>{invalidCustomers.length} {t('invalid records')}</strong>
                        <br />
                        <br />
                        <span>{t('The records are validated by these rules')}:</span>
                        <br />
                        <br />
                        <BulletList>
                            <BulletListItem cssColor='inherit'><strong>lastName</strong>: {t('required field')}</BulletListItem>
                            <BulletListItem cssColor='inherit'><strong>firstName</strong>: {t('required field')}</BulletListItem>
                            <BulletListItem cssColor='inherit'><strong>phoneNumber</strong>: {t('must be a valid format (e.g. +49681-9508280), not required')}</BulletListItem>
                            <BulletListItem cssColor='inherit'><strong>mobilePhoneNumber</strong>: {t('must be a valid format (e.g. +49171-7352810), not required')}</BulletListItem>
                            <BulletListItem cssColor='inherit'><strong>email</strong>: {t('must be a valid email address, not required')}</BulletListItem>
                        </BulletList>
                    </div>
                    <Spacer />
                </InfoBox>
                <Headline2>{t('Invalid records')}:</Headline2>
                <PushBottom16 />
                <JsonTree jsonData={invalidCustomers} shouldInitiallyExpand={(level) => level < 2}/>
            </React.Fragment>
        }
    </React.Fragment>
}