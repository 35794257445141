import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { Paragraph } from 'application/components/fragments/typography'

const DeleteInvoice = (
    { 
        abort, 
        dialog, 
        onDeleteInvoice
    }
) => {
    const {t} = useTranslation()
    const payload = dialog.payload
    const invoiceType = payload.type
    const amount = payload.grossTotal
    const billNumber = payload.number.toString()
    const successMsg = invoiceType + ' - ' + billNumber + t('has been successfully deleted')

    const handleDeleteInvoice = () => {
        const payloadToSend = { 
            parentId: payload.parentId,  
            invoiceId: payload.id, 
            successMsg: successMsg
        } 
        const metaData = { 
            dialogToOpenData: payload?.dialogToOpenData || null,
            shouldOpenDialog: payload?.shouldOpenDialog || false
        }
        onDeleteInvoice(
            dialog.name, 
            payloadToSend,
            metaData
        )
    }


    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <Paragraph>
                    {t('do you want to delete this invoice?')}
                </Paragraph>
                <Paragraph>
                    <strong>{t('Bill number')}</strong>: {billNumber}
                    <br />
                    <strong>{t('invoice type')}</strong>: {invoiceType}
                    <br />
                    <strong>{t('Amount')}</strong>: {amount}
                </Paragraph>
            </FlexBox>
        </DialogMainContent>
        
        <Footer
            onAbort={abort}
            onSubmit={handleDeleteInvoice}
            submitText='Delete invoice'
        />
    </React.Fragment>

}

export const DeleteInvoiceComponent = withDialogDataLoader(DeleteInvoice)