import { connect } from 'react-redux'
import {ActivityLogComponent} from './component'
import { getActivitiesRequest } from 'application/redux/actions/pages/activity_log'
import { UPDATE_CONSTRAINTS } from 'application/constants'
import { getUpdateConstraint } from 'application/redux/selectors'

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn,
        activityLog: state.pages.activityLog,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.ACTIVITY_LOG)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadActivitiesList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getActivitiesRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export const ActivityLog = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityLogComponent)
