import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { CommissionExpertComponent } from './component'

const mapStateToProps = state => (
    {
        canCommissionDekra: state.auth.currentAccount?.canCommissionDekra || false
    }
)

const mapDispatchToProps = dispatch => {
    return {
        openDialogCommissionDekraExpert: (
            damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER, 
                false, 
                { damageId }, 
                'Commission expert'
            )
        ),
        openDialogCommissionOtherExpert: (
            damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMMISSION_OTHER_EXPERT, 
                false, 
                { damageId }, 
                'Commission expert'
            )
        )
    }
}

export const CommissionExpert = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionExpertComponent)