import { PureSelect } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { Label } from 'application/components/fragments/typography'
import { Spacer } from 'application/components/pages/_common'
import { ActionButton } from '@ec/ui-controls-react'
import { useState } from 'react'
import { StyledTextInputWithLabel, Wrapper } from 'application/components/controls/search_combo_box/fragments'
import { customers as customersFilters } from  'application/filters/customers_filters'
import { useQuery, setQuery } from 'application/components/hooks/use_query'
import { useLocation, useNavigate } from 'react-router-dom'
import { exists } from 'application/common/data_helpers'
import { InfoBox } from 'application/components/controls/info_box'
import { IconButton } from 'application/components/controls/icon_button'

export const TablePrivateCustomersSearchFilter = () => {
    const query = useQuery()
    const navigate = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()

    const options = [
        {
            value: 'lastName',
            label: t('Last name')
        },
        {
            value: 'licensePlate',
            label: t('License plate')
        },
        {
            value: 'chassisNumber',
            label: t('Chassis number')
        }
    ]

    const lastNameFilterKey = customersFilters.findPrivateLastName.filterKey
    const licensePlateFilterKey = customersFilters.preferredVehicleLicensePlate.filterKey
    const chassisNumberFilterKey = customersFilters.preferredVehicleChassisNumber.filterKey

    const currentSearchTermFromQuery = exists(query.getStringParam(lastNameFilterKey)) ? query.getStringParam(lastNameFilterKey)
        : exists(query.getStringParam(licensePlateFilterKey)) ? query.getStringParam(licensePlateFilterKey)
            : exists(query.getStringParam(chassisNumberFilterKey)) ? query.getStringParam(chassisNumberFilterKey)
                : ''

    const optionValueFromQuery = exists(query.getStringParam(chassisNumberFilterKey)) ? 'chassisNumber' 
        : exists(query.getStringParam(licensePlateFilterKey)) ? 'licensePlate'
            : 'lastName'

    const [searchTerm, setSearchTerm] = useState(currentSearchTermFromQuery)
    const [selectedSearchField, setSelectedSearchField] = useState(options.find(option => option.value === optionValueFromQuery))
    const [currentSearchTerm, setCurrentSearchTerm] = useState(currentSearchTermFromQuery)

    const onSelectChange = (option) => {
        setSearchTerm('')
        setSelectedSearchField(option)
    }

    const search = () => {
        const filterKey = selectedSearchField.value === 'lastName' ? lastNameFilterKey
            : selectedSearchField.value === 'licensePlate' ? licensePlateFilterKey
                : chassisNumberFilterKey

        setQuery(
            navigate, 
            location, 
            location.pathname,
            [
                {
                    key: filterKey,
                    value: searchTerm
                }
            ],
            false
        )

        setCurrentSearchTerm(searchTerm)
    }

    const handleResetSearch = () => {
        setSearchTerm('')
        setCurrentSearchTerm('')
        setQuery(
            navigate, 
            location, 
            location.pathname, 
            [], 
            false
        )
    }

    const handleEnter = (event) => {
        if (event.nativeEvent.which === 13 || event.nativeEvent.keyCode === 13) {
            search()
        }
    }
    return <FilterFragment.FilterRow>

        <FilterFragment.FilterLabel>
            <Label cssMargin='0 16px 0 0'>
                {t('Search private customer')}
            </Label>
        </FilterFragment.FilterLabel>

        <FilterFragment.FilterAction>
            <FlexBox width='340px'>
                <Wrapper>
                    <PureSelect
                        options={options}
                        onChange={onSelectChange}
                        isSearchable={false}
                        isClearable={false}
                        cssHeight='35px'
                        value={selectedSearchField}
                    />
                    <StyledTextInputWithLabel>
                        <input
                            type="text" 
                            value={searchTerm} 
                            onChange={e => {
                                setSearchTerm(e.target.value)
                            }}
                            placeholder={t(selectedSearchField.label)}
                            onKeyDown={handleEnter}
                        />
                    </StyledTextInputWithLabel>
                </Wrapper>
            </FlexBox>
            
            <Spacer basis='8px' />
            <ActionButton
                buttonText={t('Search')}
                onButtonClick={search}
            />
        </FilterFragment.FilterAction>
        {
            exists(currentSearchTerm) 
                    && <FilterFragment.FilterMeta>
                        <InfoBox
                            direction='row' 
                            cssMargin='0'
                        >
                            <span>{t('Search')}: <strong>"{currentSearchTerm}"</strong> in <strong>{t(selectedSearchField.label)}</strong></span>
                    
                            <Spacer />
                            <IconButton
                                iconKey='close' 
                                width='12px' 
                                height='16px' 
                                onButtonClick={handleResetSearch} 
                            />
                        </InfoBox>
                    </FilterFragment.FilterMeta>
        }
        
    </FilterFragment.FilterRow>
}