import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetLawfirmCommissionStateSaga(action) {

    const { 
        dialogName, 
        metaData
    } = action
    
    const { 
        damageId, 
    } = metaData
    
    const lawfirmResponse = yield resourceCall(
        api.gateway.lawyerCommunication.getLawfirm, 
        {
            damageId
        }
    )


    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                commissionState: lawfirmResponse.state
            }
        )
    )
}