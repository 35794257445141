import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DamageInvoicesHeaderComponent } from './header'

const mapDispatchToProps = dispatch => {
    return {
        onChangeAllCollapsables: (dialogName, initiallyCollapsed) => dispatch(
            updateDialogDataByName( dialogName, {initiallyCollapsed} )
        )
    }
}

export const DamageInvoicesHeader =  connect(
    () =>({}),
    mapDispatchToProps
)(DamageInvoicesHeaderComponent)