import { connect } from 'react-redux'
import { SwitchDamageCustomerComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSwitchCustomer: (
            name,
            damageId, 
            customerId,
            customer
        ) => dispatch(
            sendPayloadRequest(
                name, 
                {
                    customerId,
                    customer
                },
                {
                    damageId
                }
            )
        ),
    }
}

export const SwitchDamageCustomer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SwitchDamageCustomerComponent)