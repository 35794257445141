
import React from 'react'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ItemsPerPageSelection } from 'application/components/tables/common/_common'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledTotal = styled.div`
    background: transparent;
    font-size: 12px;
    font-weight: 400;
    padding: 16px 0px;
`


const TableFooter = (
    {
        pagination,
        tableName = '',
        elementsTotal = 0
    }
) => {
    const {t} = useTranslation()

    if (pagination.hidePagination) {
        return <React.Fragment/>
    }

    const itemsPerPage = pagination.getItemsPerPage()

    return <FlexBox paddingTop='32px' paddingBottom='16px'>
        <FlexBox>
            <RoutedPagination
                totalSize={elementsTotal}
                parameterNames={pagination}
                itemsPerPage={itemsPerPage}
            />
        </FlexBox>
        <FlexBox 
            height={'inherit'} 
            justifyContent='center'
            alignItems='center'
        >
            <StyledTotal>{
                `${t('Total')}: `
            }
            <strong>{elementsTotal}</strong>
            </StyledTotal> 
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
            <ItemsPerPageSelection 
                tableName={tableName}
                offsetKey={pagination.offset}
                limitKey={pagination.limit}
                options={pagination.itemsPerPageOptions}
                selectedOptionValue={itemsPerPage}
            />
        </FlexBox>
    </FlexBox>                 
}

TableFooter.propTypes = {
    pagination: PropTypes.object.isRequired,
    tableName: PropTypes.string,
    elementsTotal: PropTypes.number    
}

export {TableFooter}