import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import {STEPS_BAR_STATE} from 'application/constants/steps_bar'


const StyledStepIcon = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 38px;
    text-align: center;
    padding-top: 7px;
    background: ${props => props.background};
    color:  ${props => props.theme.color.white};
    & i{
        font-size: 20px;
    }
`


const MappedStepIconComponent = (
    {
        state,
        numbering,
        theme
    }
) => {

    const backgroundColorMapping = (state) => {
        switch (state) {
        case STEPS_BAR_STATE.DONE: 
            return theme.color.anthracite
        case STEPS_BAR_STATE.CURRENT: 
            return theme.color.primary
        case STEPS_BAR_STATE.UNDONE: 
        default: 
            return '#a6a6a6'
        }
    }

    const iconContentMapping = (state) => {
        switch (state) {
        case STEPS_BAR_STATE.DONE: 
            return <i className='material-icons'>done</i>
        case STEPS_BAR_STATE.CURRENT: 
        case STEPS_BAR_STATE.UNDONE: 
        default: 
            return numbering
        }
    }

    const mappedBackgroundColor = backgroundColorMapping(state)
    const mappedIconContent = iconContentMapping(state)

    return <StyledStepIcon background={mappedBackgroundColor}>
        {mappedIconContent}
    </StyledStepIcon>
}

MappedStepIconComponent.propTypes = {
    state: PropTypes.number.isRequired,
    numbering: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired
}

export const MappedStepIcon = withTheme(MappedStepIconComponent)