import { call, put, takeLeading, all } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api, dataMappers } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { updateWorkflowPanelFormSagas } from '../../../common/wp_api_mappers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { COMMISSION_STATUS } from 'application/constants'

export function* updateAktenStatusSaga(action) {
    yield resourceCall(
        api.gateway.lawyerCommunication.putCasefile, 
        null,
        action.payload
    )
    yield put(showToast('Case status data saved'))
}

export function* updateLiabilityRequestSaga(action) {    
    yield resourceCall(
        api.gateway.lawyerCommunication.putLiabilityInquiry,
        null,
        action.payload
    )
    yield put(showToast('Liability request data saved'))
}

export function* updateReceiptConfirmationSaga(action) {
    yield all([
        resourceCall(
            api.gateway.lawyerCommunication.putAcknowledgement,
            null,
            {
                commissionStatus: COMMISSION_STATUS.ACCEPTED,
                note: action.payload.note
            }
        ),
        resourceCall(
            api.gateway.lawyerCommunication.putContactInformation,
            null,
            dataMappers.exportMappers.lawfirm.putContactInformation(action.payload)
        ),

        resourceCall(
            api.gateway.lawyerCommunication.putCasefile,
            null,
            {
                referenceId: action.payload.referenceId,
                status: action.payload.status,
                note: action.payload.caseNote
            }
        )
    ])

    yield put(showToast('Receipt confirmation data saved'))
}

export function* updateInsuranceInformationSaga(action) {

    yield all([
        resourceCall(
            api.gateway.damage.updateLiabilityClaim,
            null,
            action.payload.liability.claimPayload
        ),

        resourceCall(
            api.gateway.damage.updateLiabilityInsurance,
            null,
            action.payload.liability.insurancePayload
        )
    ])
    yield put(showToast('Insurance information saved'))
}

export function* updateRepairClearanceSaga(action, pin) {
    yield resourceCall(
        api.gateway.damage.updateRepairAuthorization,
        null, 
        action.payload
    )
    yield put(showToast('Repair clearance data saved'))
}

function* updateWorkflowPanelSaga(action) {
    try {
        const sagas = yield call(updateWorkflowPanelFormSagas, action.key)
        for (const saga of sagas) {
            yield saga(action)
        }
    } catch (e) {
        yield handleError(e)
    }
}

export function* updateWorkflowPanelFormWatcher() {
    yield takeLeading(ACTIONS.COMMON_WP_UPDATE, updateWorkflowPanelSaga)
}