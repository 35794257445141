import React from 'react'
import { FilterRadioCheckboxGroup }  from '../radio_checkbox_group'
import PropTypes from 'prop-types'
import * as filters from 'application/filters'
import { FILTERS } from 'application/constants/filters'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { Label } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'

const TaskStateFilterCheckboxGroup = (
    {
        pagination,
        disabled = false,
        spaceLeft = false
    }
) => {
    const {t} = useTranslation()
    const TASKFILTERS = FILTERS.VALUES.TASKS
    const filter = filters.tasks.state

    const checkboxDefinitions = [
        {
            label: 'Opened',
            value:  TASKFILTERS.STATE.OPEN
        },
        {
            label: 'Done',
            value:  TASKFILTERS.STATE.DONE
        },
        {
            label: 'All',
            value:  TASKFILTERS.STATE.ALL
        }
    ]

    return <FilterFragment.FilterRow className='stateFilterRow'>
        <FilterFragment.FilterLabel>
            <Label>
                {t('State')}
            </Label>
        </FilterFragment.FilterLabel>
        <FilterFragment.FilterAction>
            <FilterRadioCheckboxGroup
                OFFSET={pagination.offset}
                LIMIT={pagination.limit}
                filter={filter}
                disabled={disabled}
                checkboxDefinitions={checkboxDefinitions}
                spaceLeft={spaceLeft}
            />               
        </FilterFragment.FilterAction>
    </FilterFragment.FilterRow> 
}

TaskStateFilterCheckboxGroup.propTypes = {
    pagination: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    spaceLeft: PropTypes.bool
}

export {TaskStateFilterCheckboxGroup}