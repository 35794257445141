import { stringIsFilled } from 'application/common/data_helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'


export const DocumentCreatedActivity = (
    {activity}
) => {

    const {t} = useTranslation()

    const damageLicensePlate = activity.data?.damage?.licensePlate || ''
    const documentName = activity.data?.name || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Document created')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Damage')} : {stringIsFilled(damageLicensePlate) && damageLicensePlate}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            {t('Document')} : {documentName}
        </Fragments.ActivityParagraph>
    </React.Fragment>
}