import { takeLeading, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { batchImportComplete } from 'application/redux/actions/pages/customers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { hideWaitingScreen, showWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { createArrayChunks, removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

function* customersBatchImportSaga(action) {
    try {
        const {
            customersList
        } = action

        const mappedCustomers = customersList.map(c => ({
            payload: removePropertiesWithEmptyStrings(c.customer)
        }))

        const chunkSize = process.env.REACT_APP_BULK_IMPORT_CHUNK_SIZE
        const customerChunks = createArrayChunks(mappedCustomers, chunkSize)

        for (let index = 0; index < customerChunks.length; index++) {
            yield put(
                showWaitingScreen(`Kunde wird angelegt: ${(index + 1) * chunkSize} / ${customersList.length}`, false)
            )

            yield put(
                addNewSubMessage('Please don\'t close this window while the operation is running')
            )
    
            yield resourceCall(
                api.gateway.customer.postBulkCustomers, 
                null,
                customerChunks[index]
            )
        }

        yield put(
            batchImportComplete()
        )
        yield put(
            hideWaitingScreen()
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* customerBatchImportWatcher() {
    yield takeLeading(
        ACTIONS.CUSTOMERS_SEND_BATCH_IMPORT_REQUEST, 
        customersBatchImportSaga
    )
}