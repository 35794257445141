import { put, takeLeading, takeLatest, all } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateCategoriesWorkflowPanel, populateLawfirmWorkflowPanel, populateDamageDataWorkflowPanel, populateAccountDataWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/shared'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'




function* getCategoriesWorkflowPanelSaga(action) {
    try {
        const categoriesResponse = yield resourceCall(
            api.gateway.categories.getCategoriesByDamageId
        )
        yield put(
            populateCategoriesWorkflowPanel(categoriesResponse.categories)
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* workflowPanelCategoriesWatcher() {
    yield takeLeading(
        ACTIONS.WORKFLOW_PANEL_SHARED_CATEGORIES_REQUEST, 
        getCategoriesWorkflowPanelSaga
    )
}

function* getDamageDataWorkflowPanelSaga(action) {
    try {

        const [
            accountResponse, 
            branchResponse
        ] = yield all([
            resourceCall(api.gateway.auth.getAccountById),
            resourceCall(api.gateway.auth.getBranchById)
        ])

        yield put(
            populateAccountDataWorkflowPanel(accountResponse, branchResponse)
        )

        const response = yield resourceCall(
            api.gateway.damage.getDamage
        )
        yield put(
            populateDamageDataWorkflowPanel(response)
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* workflowPanelVehicleWatcher() {
    yield takeLatest(
        ACTIONS.WORKFLOW_PANEL_SHARED_DAMAGE_DATA_REQUEST, 
        getDamageDataWorkflowPanelSaga
    )
}

function* getLawfirmWorkflowPanelSaga(action) {
    try {
        const response = yield resourceCall(
            api.gateway.lawyerCommunication.getLawfirm
        )
        yield put(populateLawfirmWorkflowPanel(response))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* workflowPanelLawfirmWatcher() {
    yield takeLatest(
        ACTIONS.WORKFLOW_PANEL_SHARED_LAWFIRM_REQUEST, 
        getLawfirmWorkflowPanelSaga
    )
}