import { takeLeading, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateAllTasks } from 'application/redux/actions/pages/my_tasks/all_tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getAllTasksSaga(action) {
    try {
        const { 
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        } = action
        
        const response = yield resourceCall(
            api.gateway.tasks.getTasks,
            {
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            }
        )
            
        yield put(
            populateAllTasks(
                response.tasks, 
                response.total
            )
        )
        
    } catch (e) {
        yield handleError(e)
    }
}



export function* getAllTasksWatcher() {
    yield takeLeading(ACTIONS.MY_TASKS_GET_ALL_TASKS_REQUEST, getAllTasksSaga)
}