
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'
import PropTypes from 'prop-types'




export const DamageManagerDamageExistsComponent = ({
    damageLoaded = false,
    damageLoading = false,
    damage = null,
    children
}) => {
    const {
        internalAccountLink
    } = useApplicationLinks()

    if (
        damageLoaded 
        && !damageLoading
        && damage === null
    ) {
        return <Navigate 
            to={
                internalAccountLink(
                    ROUTES.DAMAGE_NOT_FOUND
                )
            }
        />  
    }
    
    return children
}

DamageManagerDamageExistsComponent.propTypes = {
    damageLoaded: PropTypes.bool,
    damageLoading: PropTypes.bool,
    damage: PropTypes.object,
    children: PropTypes.object
}
