
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetailWrapper, BranchDetail, ActivityDetail, ActivityDetailBox } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'
import {ShortenedString} from 'application/components/controls/shortened_string'




export const DamageNoteChangedDetails = (
    {
        activity
    }
) => {

    const {
        damage,
    } = activity.data

    const oldNote = activity.data?.oldNoteContent || ''
    const newNote = activity.data?.newNoteContent || ''

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetailBox>
            <ShortenedString
                text={oldNote} 
                shortenAt={20}
            />
            <RightArrow />
            <ShortenedString 
                text={newNote}  
                shortenAt={20}
            />
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DamageNoteChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}