import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'



const CreatedInfo = (
    {
        activityData
    }
) => {
    const {t}  = useTranslation()

    return <React.Fragment>
        {
            activityData.title !== '' && <React.Fragment>
                {t('Task')}: {activityData.title}
            </React.Fragment>
        }
    </React.Fragment>
}

CreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CreatedInfo}
