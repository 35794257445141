import { toggleHelpSidebar } from 'application/redux/actions/pages/branch_management'
import { connect } from 'react-redux'
import {HelpSidebarC} from './component'

const mapStateToProps = state => ({
    isVisible: state.pages.branchManagement.helpSidebarVisible
})


const mapDispatchToProps = dispatch => {
    return {
        onSidebarClose: () => dispatch(toggleHelpSidebar(false))
    }
}

export const HelpSidebar = connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpSidebarC)