import { useCallback, useEffect, useState } from 'react'
import { detect } from 'detect-browser'

export const useClipboardPaste = (pasteCallback = () => {}) => {
    const browser = detect()
    const clipboardSupported = browser.name !== 'firefox'

    const [incompatibleClipboardData, setIncompatibleClipboardData] = useState(false)

    const clipboardToDataUrl = (item, callback = () => {}) => {
        if (item.kind === 'file') {
            var blob = item.getAsFile()
            var reader = new FileReader()
            reader.onload = function (event) {
                callback(event.target.result)
            } 
            reader.readAsDataURL(blob)
        }
    }

    async function pasteImage() {
        if(!clipboardSupported){
            return
        }
        try {
            const permission = await navigator.permissions.query({
                name: 'clipboard-read',
            })
            if (permission.state === 'denied') {
                throw new Error('Not allowed to read clipboard.')
            }
            const clipboardContents = await navigator.clipboard.read()
            for (const item of clipboardContents) {
                if (!item.types.includes('image/png')) {
                    setIncompatibleClipboardData(true)
                }else{
                    const blob = await item.getType('image/png')
                    pasteCallback(URL.createObjectURL(blob))
                    setIncompatibleClipboardData(false)
                }
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    const documentPaste = useCallback(event => {
        var items = (event.clipboardData || event.originalEvent.clipboardData).items
        const item = items[0]
        if (item.type !== 'image/png') {
            setIncompatibleClipboardData(true)
        }else{
            clipboardToDataUrl(item, dataUrl => {
                pasteCallback(dataUrl)
            })
            setIncompatibleClipboardData(false)
        }
    }, [pasteCallback])

    useEffect(()=>{
        if(!clipboardSupported){
            document.addEventListener('paste', documentPaste)
        }

        return () => {
            document.removeEventListener('paste', documentPaste)
        }
    }, [clipboardSupported, documentPaste, pasteCallback])


    return {
        clipboardSupported,
        incompatibleClipboardData,
        pasteImage
    }
}