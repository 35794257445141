import { isNull } from 'application/common/data_helpers'
import { getDocsByCatsResourceId } from 'application/redux/selectors'
import { useSelector } from 'react-redux'



export const useDocumentsResourceFromState = () => {
    const resourceId = useSelector(getDocsByCatsResourceId)

    const sharedResource = useSelector(
        state => state.sharedResource
    )

    const pageIsSharedResourceRelated = !isNull(
        sharedResource?.resourceType || null
    ) 

    const finalResourceId = pageIsSharedResourceRelated
        ? sharedResource.id
        : resourceId

    return {
        isInternal: !pageIsSharedResourceRelated,
        resourceId: finalResourceId
    }

}