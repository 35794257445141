import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_CONTEXTS } from 'application/constants'
import {WorkflowPanelTasksComponent} from './component'


const WorkflowPanelTasksEffectsC = (props) => {
    const { onRefreshWidgetContexts, onLoadTasks, loggedIn, match } = props
    const { sharedResourceId } = match.params

    useEffect(() => {
        onRefreshWidgetContexts([WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR])
    }, [onRefreshWidgetContexts, sharedResourceId])

    useEffect(() => {
        loggedIn && onLoadTasks(sharedResourceId)
    }, [loggedIn, onLoadTasks, sharedResourceId])

    return <WorkflowPanelTasksComponent {...props} />
}

WorkflowPanelTasksEffectsC.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired,
    tasksLoaded: PropTypes.bool.isRequired,
    tasksLoading: PropTypes.bool.isRequired,
    onLoadTasks: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
}

export const WorkflowPanelTasksEffects =  withRouter(WorkflowPanelTasksEffectsC)