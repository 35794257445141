import styled from 'styled-components'

export const StyledDamageSearch = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: inherit;
    ${props => props.open ? `background: ${props.theme.color.white};` : ''} 
    ${
    props => props.open 
        ? 
        `border-right: 1px solid ${props.theme.color.gray15};
         border-left: 1px solid ${props.theme.color.gray15};` 
        : 
        ''
}`



export  const StyledSearchBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 400px;
    height: 35px;
    padding: 0 8px;
`

export  const StyledDamageResultsOverlay = styled.div`
    position: absolute;
    top: 48px;
    right: -1px;
    width: 850px;
    border: 1px solid ${props =>  props.theme.color.gray15};
    border-top: 0;
    background: ${props => props.theme.color.white};
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
      box-shadow: 1px 2px 2px #0c151559;
`


export  const TextNavLinkButton = styled.button`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border: 0;
  background: ${props => props.theme.color.white};

  & > i {
    color: ${props => props.theme.color.primary};
  }
  &:visited, &:hover &:active{
    color: ${props => props.theme.color.primary};
  }
`