import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateSharedLawfirm } from 'application/redux/actions/pages/damage_manager'

function* refreshSharedLawfirmSaga(action) {
    try {
        const damageId = action.damageId
        const lawfirmResponse = yield resourceCall(
            api.gateway.lawyerCommunication.getLawfirm,
            { damageId }
        )
        yield put(
            populateSharedLawfirm(true, lawfirmResponse)
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* refreshSharedLawfirmSagaWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_SHARED_REFRESH_LAWFIRM, 
        refreshSharedLawfirmSaga
    )
}