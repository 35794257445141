import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Header = styled.div`
    display: flex;
    position: fixed;
    height: 94px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    align-items: center;
    padding: 20px 48px;
    justify-content: space-between;
    background: white;
    z-index: 1000;
`

export const ImageLogo = styled.img`
    width: 60px;
`

export const LayoutWelcome = styled.div`
    overflow: auto;
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    max-width: 100%;
    line-height: 1.75;
    margin-top: 94px;
`

export const TextWrapper = styled.div`
    padding: 0;
    overflow: hidden;
`

export const InfoMainWelcomeWrapper = styled.div`
    display: flex;
    padding: ${props => props.topPadding !== undefined ? `${props.topPadding} 48px 0` : '48px 48px 0' } ;
    margin: auto;
    background: ${props => props.background !== undefined ? props.background : props.theme.color.white};
    align-items: center;
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        max-width: 100%;
        flex-direction: ${props => props.reverse !== undefined ? 'column-reverse': 'column'};
    }
`

export const ImageTopMain = styled.img`
       width: 560px;
       max-width: 90%;
       margin: auto;
        @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
            width: 100%;
            max-width: 100%;
            height: auto;
            margin-bottom: 24px;
        }
`
export const BreakLine28 = styled.div`
    margin-bottom: 28px;
`

export const BreakLine32 = styled.div`
    margin-bottom: 32px;
`

export const FooterWelcomeWrapper = styled.footer`
    padding: 32px;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       background: ${props => props.theme.color.black};
    }
`

export const FooterWelcomeCompanyName = styled.span`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.small};
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       color: ${props => props.theme.color.white};
    }
`

export const FooterWelcomeNavlink = styled(NavLink)`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.small};
    text-decoration: none;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       color: ${props => props.theme.color.white};
    }
    
`

export const FooterLink = styled.a`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.small};
    margin-left: 8px;
    text-decoration: none;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
       color: ${props => props.theme.color.white};
    }
    `

export const IconBarWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`


export const IconBar = styled.div`
    padding-top: 24px;
    display: flex;
    background: ${props => props.background !== undefined ? props.background : props.theme.color.white};
    justify-content: center;
    flex-wrap: wrap;
    width: 660px;

    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        width: 440px;
    }

    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        width: auto;
        justify-content: flex-start;
    }

`




export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    width: 180px;
    margin-bottom: 24px;
    text-align:center;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        flex-direction: row;
        text-align: left;
        width: 100%;
        margin-left:0;
        &>p{
            margin-left: 8px;
        }
    }
`

export const SuperTitle = styled.h3`
    display: flex;
    color: ${props => props.theme.color.gray44};
    font-style: italic;
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.medium18};
    margin: 0;
    align-items: center;
    &:after{
        content: "";
        display: block;
        height: 2px;
        line-height: 0;
        margin-left: 20px;
        width: 50px;
        background: ${props => props.theme.color.primary};
    }
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        font-size: ${props => props.theme.fontSize.standard};
    }
`

export const Claim = styled.h1`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.primary};
    text-transform: uppercase;
    margin: 0;
    line-height: ${props => props.theme.fontSize.xLarge};
    max-width: 100%;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        font-size: ${props => props.theme.fontSize.headline1};
        line-height: 28px;
    }
`

export const Title = styled.h2`
    font-size: ${props => props.theme.fontSize.medium32}; 
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.primary};
    text-transform: uppercase;
    margin: 0;
    line-height: ${props => props.theme.fontSize.large};
    max-width: 100%;
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        font-size: ${props => props.theme.fontSize.headline2};
        line-height: ${props => props.theme.fontSize.headline1};
    }
`

export const Description = styled.p`
    font-size: ${props => props.theme.fontSize.headline3};
    font-weight: ${props => props.theme.fontWeight.standardLight};
    margin: 8px 0 0;
    line-height: 28px;
`

export const ButtonContainer = styled.p`
    font-size: ${props => props.theme.fontSize.headline3};
    font-weight: ${props => props.theme.fontWeight.standardLight};
    margin: 16px 0 0;
`

export const LandingPageIcon = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: ${props => props.theme.color.gray10};
    flex: 0 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img{
        width: 60%;
        opacity: 0.6;
    }

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        flex: 0 0 60px;
    }
`

export const ImageTitle = styled.span`
    font-weight: ${props => props.theme.fontWeight.standardBold};
    color: ${props => props.theme.color.primary};
    margin-top: 8px;
    margin-bottom: 4px;
    line-height: 20px;
`

export const ImageDescription = styled.span`
    color: ${props => props.theme.color.anthracite50};
    line-height: 20px;

    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        display: none;
    }

`