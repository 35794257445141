import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput, CheckboxWithLabel} from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import PropTypes from 'prop-types'


export const GeoCodingControl = ( 
    {
        register,
        useGeoCoding,
        defaultLatitude,
        defaultLongitude,
        setUseGeoCoding = () => {},
        errors = {},
        markerMoved = false
    }
) => {
    const {t} = useTranslation()
    const latitudeName = 'address.coordinates.latitude'
    const longitudeName = 'address.coordinates.longitude'
    
    useEffect(
        ()=>{
            setUseGeoCoding(!markerMoved)
        }, [markerMoved, setUseGeoCoding]
    )

    return <React.Fragment>
        <CheckboxWithLabel
            name='useGeoCoding'
            label={t('Determine coordinates automatically')}
            isRequired={false} 
            isSelected={useGeoCoding}
            onCheckboxChange={
                ()=>{
                    setUseGeoCoding(!useGeoCoding)
                }
            } 
        />
        <TwoColumns 
            columnLeft={
                <TextInput
                    label={t('Latitude')}
                    name={latitudeName}
                    register={register}
                    validate={
                        {
                            latitude: validationMethods.mustBeValidLatitude
                        }
                    }
                    error={errors?.address?.coordinates?.latitude}
                    readOnly={useGeoCoding}
                    defaultValue={defaultLatitude}
                />
            }
            columnRight={
                <TextInput
                    label={t('Longitude')}
                    name={longitudeName}
                    register={register}
                    validate={
                        {
                            longitude: validationMethods.mustBeValidLongitude
                        }
                    }
                    error={errors?.address?.coordinates?.longitude}
                    readOnly={useGeoCoding}
                    defaultValue={defaultLongitude}
                />
            }
        />
    </React.Fragment>
}

GeoCodingControl.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    useGeoCoding: PropTypes.bool.isRequired,
    defaultLatitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultLongitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setUseGeoCoding: PropTypes.func,
    errors: PropTypes.object, 
    markerMoved: PropTypes.bool,
}