import React from 'react'
import { fileHelpers } from 'application/common'
import { StyledFileButton, StyledExtension } from './fragments'
import TruncateString from 'react-truncate-string'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'



export const SingleFile =  withTheme(
    ({
        fileName,
        htmlId,
        onCheckboxChange = () => {},
        checkboxDisabled = false,
        isExportedToLawFirm = false,
        removeFile,
        theme
    }) => {
        const {t} = useTranslation()
        const extension = fileHelpers.getFileExtension(fileName).toLowerCase()

        const handleCheckboxChange = () => {
            onCheckboxChange(htmlId, !isExportedToLawFirm)
        }

        return <StyledFileButton title={fileName}>
            <FlexBox
                paddingTop='6px'
                paddingRight='8px'
                paddingLeft='8px'
                paddingBottom='6px'
            >
                <FlexBox 
                    flexBasis='auto' 
                    flexGrow='0' 
                    flexShrink='1' 
                    cssRules={'overflow: hidden;'}
                >
                    <TruncateString text={fileName}truncateAt={40}/>
                </FlexBox>
                <FlexBox flexBasis='30px'>
                    <StyledExtension>.{extension}</StyledExtension>
                </FlexBox>
            </FlexBox>

            <FlexBox 
                justifyContent='space-between'
                paddingTop='6px'
                paddingRight='8px'
                paddingLeft='8px'
                paddingBottom='6px'
                cssRules='border-top:1px solid #d9d9d9;'
            >
                <PureCheckBox 
                    label={t('Make available for legal software')}
                    checked={isExportedToLawFirm}
                    onChange={handleCheckboxChange}
                    small={true}
                    disabled={checkboxDisabled}
                />
                <FlexBox 
                    width='10%'
                    justifyContent='flex-end'
                >
                    <IconButton
                        border={true} 
                        iconSize='16px' 
                        iconKey='close' 
                        onButtonClick = {() => removeFile(htmlId)}
                        height='16px'
                        width='16px'
                        color={theme.color.primary}
                    />
                </FlexBox>
            </FlexBox>
        </StyledFileButton>
    }
)