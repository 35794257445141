import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PartialForms } from 'application/components/forms'
import { GeoCodingControl } from 'application/components/controls/geocoding_control'
import { PushBottom24 } from 'application/components/pages/_common'
import { useStatefulForm } from 'application/components/hooks'
import { useGeoDataSubmit} from 'application/components/hooks'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'



const CreateBranch = (
    { 
        abort, 
        dialog, 
        onAddBranch 
    }
) => {

    const { 
        register, 
        setValue,
        errors,
        control,
        handleSubmit, 
        getValues,
        formStateValues, 
        updateFormState
    } = useStatefulForm()

    const handleSubmitWithCoordinates = (lat = null, lon = null) => {
        onAddBranch(
            dialog.name, 
            {
                latitude: lat,
                longitude: lon,
                ...getValues()
            }
        )
    }

    const {
        onSubmitSuccess, 
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } =  useGeoDataSubmit(
        formStateValues.address,
        setValue,
        handleSubmitWithCoordinates
    )

    const handleOnAddressChange = () => {
        updateFormState({
            ...getValues()
        })
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox
                flexDirection='column'
            >
                <PartialForms.BranchDataPartialForm 
                    register={register}
                    errors={errors}
                    onChange={handleOnAddressChange}
                    control={control}
                    setValue={setValue}
                    defaultValuePhoneNumber={formStateValues.phoneNumber}
                />
                <PushBottom24 />
                <GeoCodingControl
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    setUseGeoCoding={setUseGeoCoding}
                    useGeoCoding={useGeoCoding}
                /> 
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)} 
            submitText='Add branch'
            disabled={submitDisabled}
        />
    </React.Fragment>
}

export const CreateBranchComponent = withDialogDataLoader(CreateBranch)