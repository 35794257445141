
import React from 'react'
import { validationMethods } from 'application/common'
import { CheckboxWithLabel, TextInput } from 'application/components/controls/form'
import { PhoneNumberInput } from 'application/components/controls/form/phone_number_input'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16 } from 'application/components/pages/_common'
import { TwoColumns } from 'application/components/forms/_common'




const UserDataFormComponent = (
    {
        defaultPhoneNumber,
        defaultMobilePhoneNumber,
        register, 
        errors,
        control,
        setValue,
        onDataChange,
        setFirstName,
        setLastName,
        t,
        invitation, 
        setInvitation
    }
) => {
    const { notEmpty, mustBeEmail } = validationMethods

    return   <FlexBox 
        flexBasis='55%' 
        paddingLeft='16px'
        flexDirection='column'
    >
        <TextInput
            label={t('Firstname')}
            name='firstName'
            register={register}
            validate={{notEmpty}}
            error={errors.firstName}
            onChange={(e) =>
            {
                onDataChange()
                setFirstName(e.target.value)}
            }
        />
        <TextInput
            label={t('Lastname')}
            name='lastName'
            register={register}
            validate={{notEmpty}}
            error={errors.lastName}
            onChange={(e) =>
            {onDataChange()
                setLastName(e.target.value)
            }}
        />
        <TextInput
            label={t('Company')}
            name='company'
            register={register}
            validate={{notEmpty}}
            error={errors.company}
            onChange={onDataChange}
        />
        <TextInput
            label={t('Email')}
            name='email'
            register={register} 
            validate={{
                notEmpty,
                mustBeEmail
            }}
            error={errors.email}
            onChange={onDataChange}
        />
        {/* <PhoneNumberInput
            defaultValue={defaultPhoneNumber}
            validate={null}
            control={control}
            label={t('Phone number (mobile or landline)')}
            name='phoneNumber'
            register={register}
            error={errors.phoneNumber}
            onChange={onDataChange}
            setValue={setValue}
            allowLandLine={true}
        /> */}
        <TwoColumns
            columnLeft={
                <PhoneNumberInput
                    defaultValue={defaultPhoneNumber}
                    validate={null}
                    control={control}
                    label={t('Phone number')}
                    name='phoneNumber'
                    register={register}
                    error={errors.phoneNumber}
                    onChange={onDataChange}
                    setValue={setValue}
                    allowLandLine={true}
                />
            }
            columnRight={
                <PhoneNumberInput
                    defaultValue={defaultMobilePhoneNumber}
                    validate={null}
                    control={control}
                    label={t('Mobile phone number')}
                    name='mobilePhoneNumber'
                    register={register}
                    error={errors.mobilePhoneNumber}
                    onChange={onDataChange}
                    setValue={setValue}
                    allowLandLine={false}
                />
            }
        />
        <PushBottom16/>
        <CheckboxWithLabel
            label={t('Send invitation')}
            onCheckboxChange={
                () => {
                    setInvitation(!invitation)
                }
            }
            isSelected={invitation}
        />
        <PushBottom16/>
    </FlexBox>
}


export {UserDataFormComponent}