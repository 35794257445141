import React from 'react'
import { MobileTimeLineStep } from 'application/components/building_blocks/mobile_time_line'
import { Trans, useTranslation } from 'react-i18next'
import { PushBottom16, PushBottom32 } from 'application/components/pages/_common'
import { AnimatedCheckmark } from 'application/components/controls/animated_checkmark'
import { FormHeadline, Paragraph } from 'application/components/fragments/typography'
import { PreloaderContainer } from 'application/components/pages/external/create_damage/sub_components/fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { useAccountLogoLoader } from 'application/components/hooks/account_logo/use_account_logo_loader'

export const Step3Component = (
    {
        currentTimelineStepIndex,
        damageDataSending,
        damageDataSent,
        documentsSending,
        documentsSent,
        files
    }
) => {
    const {t} = useTranslation()
    const hasFiles = files.length > 0

    const {
        accountName
    } = useAccountLogoLoader(
        null
    )

    return <MobileTimeLineStep
        index={3}
        isActive={currentTimelineStepIndex === 3}
        label={t('Ready')}
        isLast={true}
    >
        <PushBottom32/>
        <FlexBox flexDirection='column'>

            {
                damageDataSending && <FlexBox>
                    <PreloaderContainer>
                        <ThemedPreloader preloaderText={
                            t('Sending damage data')
                        } />
                    </PreloaderContainer>
                </FlexBox>
            }

            {
                damageDataSent &&  <FlexBox 
                    flexDirection='column'
                    alignItems='center'
                >
                    <AnimatedCheckmark />
                    <FormHeadline>
                        {t('Damage report sent')}
                    </FormHeadline>
                    {
                        damageDataSent && !hasFiles && <Paragraph cssAlign='center'>
                            <Trans i18nKey='A representative will be in touch with you shortly.'>
                                Ein Sachbearbeiter von { {accountName} } setzt sich in Kürze mit Ihnen in Verbindung.
                            </Trans>
                        </Paragraph>
                    }
                </FlexBox>
            }

            {
                hasFiles && <React.Fragment>
                    {
                        documentsSending && <FlexBox>
                            <PreloaderContainer>
                                <ThemedPreloader preloaderText={t('Sending photos')} />
                            </PreloaderContainer>
                        </FlexBox>
                    }
                    {
                        documentsSent &&  <FlexBox 
                            flexDirection='column' 
                            alignItems='center'
                        >
                            <PushBottom16 />
                            <AnimatedCheckmark />
                            <FormHeadline>
                                {t('photos sent')}
                            </FormHeadline>
                            <Paragraph cssAlign='center'>
                                <Trans i18nKey='A representative will be in touch with you shortly.'>
                            Ein Sachbearbeiter von { {accountName} } setzt sich in Kürze mit Ihnen in Verbindung.
                                </Trans>
                            </Paragraph>
                        </FlexBox>
                    }
                </React.Fragment>
            }
           
        </FlexBox>
    </MobileTimeLineStep>
}