import { connect } from 'react-redux'
import {Step3Component} from './component'

const mapStateToProps = state => ({
    damageDataSent: state.pages.extCreateDamage.damageDataSent,
    damageDataSending: state.pages.extCreateDamage.damageDataSending,
    documentsSent: state.pages.extCreateDamage.documentsSent,
    documentsSending: state.pages.extCreateDamage.documentsSending,
})

export const Step3 = connect(
    mapStateToProps
)(Step3Component)