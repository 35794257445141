import React from 'react'
import { ROUTE_PARAMETERS } from 'application/constants'
import { ListOfGroups } from 'application/components/name_mappings/list_of_groups'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'



const tableName = STORAGE.TABLE_NAME.ASSIGNEES

export const configuration = {
    name: tableName,
    filters: {
        match: [],
        query: [
            new ListFilter(filters.users.lastName),
            new ListFilter(filters.users.email)
        ],
        property: [
            new ListFilter(filters.users.getUsersForSpecificBranch)
        ],
        static: []
    },
    sorting:  {
        sortParamKey: ROUTE_PARAMETERS.ASSIGNEES.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING,
        defaultSortParamValue: ROUTE_PARAMETERS.ASSIGNEES.ORDER_BY.VALUES.LASTNAME,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.ASSIGNEES.OFFSET,
        limit: ROUTE_PARAMETERS.ASSIGNEES.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName, 25) 
        }
    },
    columns: [
        {
            width: 25,
            label: 'Firstname',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <React.Fragment>{element.firstName}</React.Fragment>
        },
        {
            width: 25,
            label: 'Lastname',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.ASSIGNEES.ORDER_BY.VALUES.LASTNAME,
            CellContent: ({element}) => <React.Fragment>{element.lastName}</React.Fragment>
        },
        {
            width: 25,
            label: 'Email',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.ASSIGNEES.ORDER_BY.VALUES.EMAIL,
            CellContent: ({element}) => <React.Fragment>{element.email}</React.Fragment>
        },   
        {
            width: 25,
            label: 'User group',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <ListOfGroups groups={element.groups} />
        }
    ],
    rows: {
        selectable: {
            Component: null,
            additionalProps: null
        },
        linked: null,
        details: null
    },  
    massOperations: {
        available: false
    }
}
