import React from 'react'
import { exists } from 'application/common/data_helpers'
import { Paragraph } from 'application/components/fragments/typography'
import { StyledError } from './fragments'

export const ServerError = ({payload}) => {

    if(!exists(payload.isServerError)){
        return null
    }

    return <React.Fragment>
        <Paragraph cssMargin='0 0 24px'>Type: Server Error</Paragraph>
        <StyledError>
        
            {
                Object.keys(payload).map(function(key, index) {
                    return <React.Fragment key={index}>
                        <Paragraph cssMargin='16px 0 4px'>[{key}]</Paragraph>
                        <span>{payload[key].toString()}</span>
                    </React.Fragment>
                })
            }

        </StyledError>
    </React.Fragment>
}