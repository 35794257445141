import React from 'react'
import { useTranslation } from 'react-i18next'
import { DamageStatesVisualizerItem } from './damage_state_item'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Headline3 } from 'application/components/fragments/typography'
import { PushBottom24, Spacer } from 'application/components/pages/_common'
import { withStatuslistLoader } from 'application/components/higher_order_components/with_status_list_loader'
import { useLifeCycleStatesMappings } from 'application/components/hooks'
import { LIFECYCLE_STATES } from 'application/constants'

export const DamageStateEditorComponent = withStatuslistLoader(
    (props) => {
        const {t} = useTranslation()

        const {
            statusList,
            onAddCustomState,
            onDeleteCustomState,
            onEditCustomState,
            onUpdateCustomStateIndex
        } = props

        const {
            data,
            unmappedStatesData
        } = statusList

        const {
            treeCustomStates,
            requestCustomStates,
            updateIndex,
            maxCustomStatesReached
        } = useLifeCycleStatesMappings(data, unmappedStatesData)

        return <FlexBox flexDirection='column' flexBasis='100%'>
            <FlexBox>
                <FlexBox flexBasis='240px'><Headline3 justifyContent='center'>{t('System state')}</Headline3></FlexBox>
                <Spacer basis='10px' />
                <FlexBox flexBasis='auto' flexGrow='1'><Headline3>{t('associated custom states')}</Headline3></FlexBox>
            </FlexBox>
            <PushBottom24 />
            {
                LIFECYCLE_STATES.map((l, i)=><DamageStatesVisualizerItem 
                    key={l.name} 
                    lifeCycleState={l} 
                    customStates={treeCustomStates[l.name]} 
                    onAddCustomState={(lifeCycleState, index) => {
                        onAddCustomState(lifeCycleState, index, requestCustomStates)
                    }}
                    onDeleteCustomState={onDeleteCustomState}
                    onEditCustomState={onEditCustomState}
                    onUpdateCustomStateIndex={(lifeCycleState, treeData) => {
                        onUpdateCustomStateIndex(updateIndex(lifeCycleState, treeData))
                    }}
                    maxCustomStatesReached={maxCustomStatesReached}
                /> )
            }    
        </FlexBox>
    }
)