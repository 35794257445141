// import { isDefined } from 'application/common/data_helpers'
import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'







export const documentCenterReducer = (
    state = defaultStore.pages.damageManager.documentCenter, 
    action
) => {


    // const hasCurrentDocumentsByCategory = (currentCategoryId) =>  isDefined(
    //     state.documentsByCategories.find(
    //         documentsByCategory => documentsByCategory.id === currentCategoryId
    //     )
    // )



    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damageManager.documentCenter
    }
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST: {
        return {
            ...state,
            documents: [],
            documentsLoading: true,
            documentsLoaded: false,
            damageId: state.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_RESET: {
        return {
            ...state,
            documents: [],
            documentsLoading: false,
            documentsLoaded: false,
            damageId: state.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_POPULATE_DOCUMENTS: {
        return {
            ...state,
            documents: action.documents,
            documentsLoading: false,
            documentsLoaded: true,
            damageId: action.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SHOW_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: true,
                attachment: action.attachment,
                damageId: action.damageId
            }
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_SHOW_ATTACHMENT_DETAILS: //hide details in document center if details in task view is shown
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_HIDE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: false,
                attachment: null,
                damageId: null
            }
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_UPDATE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                ...state.attachmentDetails,
                attachment: {
                    ...state.attachmentDetails.attachment,
                    ...action.attachment
                }
            },
            // documentsByCategories: state.documentsByCategories.map(
            //     documentsByCategory => documentsByCategory.id === action.categoryId 
            //         ? {
            //             ...documentsByCategory,
            //             documents: documentsByCategory.documents.map(
            //                 document => document.id === action.attachmentId 
            //                     ? {
            //                         ...document,
            //                         isExportedToLawFirm: action.isExportedToLawFirm
            //                     }
            //                     : document
            //             )
            //         }
            //         : documentsByCategory
            // )  
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_REMOVE_ATTACHMENT: {
        return {
            ...state,
            documents: state.documents.filter(d => d.id !== action.attachmentId)
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SET_ARCHIVE_DOWNLOADING: {
        return {
            ...state,
            archiveDownloading: action.archiveDownloading
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT: {
        return {
            ...state,
            documents: state.documents.map(document => 
                document.id === action.attachmentId
                    ? {
                        ...document,
                        ...action.payload
                    } 
                    : document
            )
        }
    }   





    // case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_REGISTER_DOCUMENTS_BY_CATEGORY: {
    //     return {
    //         ...state,
    //         documentsByCategories: hasCurrentDocumentsByCategory(action.categoryId)
    //             ? state.documentsByCategories
    //             : [
    //                 ...state.documentsByCategories,
    //                 {
    //                     id: action.categoryId,
    //                     documents: [],
    //                     loaded: false,
    //                     loading: false
    //                 }
    //             ]
    //     }
    // }  


    // case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_LOAD_DOCUMENTS_BY_CATEGORY: {
    //     return {
    //         ...state,
    //         documentsByCategories: state.documentsByCategories.map(
    //             documentsByCategory => documentsByCategory.id === action.categoryId 
    //                 ? {
    //                     ...documentsByCategory,
    //                     loading: true
    //                 }
    //                 : documentsByCategory
    //         )            
    //     }
    // }  
    
    // case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_POPULATE_DOCUMENTS_BY_CATEGORY: {
    //     return {
    //         ...state,
    //         documentsByCategories: state.documentsByCategories.map(
    //             documentsByCategory => {

    //                 return documentsByCategory.id === action.categoryId 
    //                     ? {
    //                         ...documentsByCategory,
    //                         loading: false,
    //                         loaded: true,
    //                         documents: action.documents,
    //                         total: action.total
    //                     }
    //                     : documentsByCategory
    //             }              
    //         ) 
    //     }
    // }
    // case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_ADD_DOCUMENTS_BY_CATEGORY: {
    //     return {
    //         ...state,
    //         documentsByCategories: state.documentsByCategories.map(
    //             documentsByCategory => {
    //                 return documentsByCategory.id === action.categoryId 
    //                     ? {
    //                         ...documentsByCategory,
    //                         loading: false,
    //                         loaded: true,
    //                         documents: [
    //                             ...documentsByCategory.documents,
    //                             ...action.documents
    //                         ]
    //                     }
    //                     : documentsByCategory
    //             }              
    //         ) 
    //     }
    // }
    // case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_REMOVE_ATTACHMENT_BY_CATEGORY: {
    //     return {
    //         ...state,
    //         documentsByCategories: state.documentsByCategories.map(
    //             documentsByCategory => documentsByCategory.id === action.categoryId 
    //                 ? {
    //                     ...documentsByCategory,
    //                     documents: documentsByCategory.documents.filter(
    //                         d => d.id !== action.attachmentId 
    //                     ),
    //                     total: documentsByCategory.total - 1,
    //                     loaded: true
    //                 }
    //                 : documentsByCategory
    //         )  
    //     }
    // }  
    // case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_RESET_DOCUMENTS_BY_CATEGORY: {
    //     return {
    //         ...state,
    //         documentsByCategories: state.documentsByCategories.map(
    //             documentsByCategory => ({
    //                 ...documentsByCategory,
    //                 documents: [],
    //                 total: 0,
    //                 loaded: false,
    //                 loading: false
    //             })
    //         )
    //     }
    // }
    default:
        return state
    }
}