import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { ErrorBox, ErrorLabel } from 'application/components/fragments/error_information'
import { FlexBox } from 'application/components/fragments/flex_box'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'





export const CategorySelectWithUploaderStep0 = ({
    goToNextStep,
    files,
    setFiles,
    onDataChange,
    damageId
}) => { 
    const { t } = useTranslation()
    const [showValidation, setShowValidation] = useState(false)
 
    const handleFileValidation = () => {
        if(files.length > 0){
            setShowValidation(false)
            goToNextStep()
        }else{
            setShowValidation(true)
        }
    }
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox
                flexGrow='0'
                flexBasis='200px'
            >
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={files}
                    setFiles={setFiles}
                    setShowValidation={setShowValidation}
                    onDataChange={onDataChange}
                    damageId={damageId}       
                />
            </FlexBox>
            {
                showValidation && <React.Fragment>
                    <ErrorBox>
                        <ErrorLabel>
                            {
                                t('Please select at least one file')
                            }
                        </ErrorLabel>
                    </ErrorBox>
                </React.Fragment>
            }
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                disabled={true}
                onButtonClick={()=>{}} 
            />
            <ActionButton 
                buttonText={t('Next')} 
                onButtonClick={handleFileValidation} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}