  
import React, {useEffect} from 'react'
import styled from 'styled-components'
import { CallbackComponent } from 'redux-oidc'
import { useTranslation } from 'react-i18next'

import { defaultUserManager } from 'application/common/user_manager'
import { GlobalStyle } from 'application/style'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { ROUTES } from 'application/constants'
import { useNavigate } from 'react-router-dom'

const StyledMessage = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 40px;

`

export const RegistrationCallbackComponent = ({onConnectUser, userConnected}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        userConnected && navigate(ROUTES.CHOOSE_ACCOUNT)
    }, [navigate, userConnected])
    
    return (
        <CallbackComponent
            userManager={defaultUserManager}
            successCallback={(user) => {
                onConnectUser(user.state.sharedResourceId, user.access_token, user.id_token)
            }}
            errorCallback={error => {
                console.error(error)
                navigate('/')
            }}
        >
            <React.Fragment>
                <GlobalStyle />
                <StyledMessage>
                    <ThemedPreloader preloaderText={t('Your user is being activated') + '...'}  />
                </StyledMessage>
            </React.Fragment>
        </CallbackComponent>
    )
}