import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { CreateMessageWorkflowPanelComponent } from './component'

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth,
        dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateMessageWorkflowPanel: (dialogName, payload, metaData) => dispatch(
            sendPayloadRequest(dialogName, payload, metaData)
        ),
    }
}

export const CreateMessageWorkflowPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateMessageWorkflowPanelComponent)