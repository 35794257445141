import { ROUTES, USER_PERMISSONS } from 'application/constants'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import PropTypes from 'prop-types'
import {IconLink} from 'application/components/building_blocks/main_navigation_sidebar/_common/icon_link'
import {MenuIconLink} from 'application/components/building_blocks/main_navigation_sidebar/_common/menu_icon'
import { buildToolTip } from '../../helper_functions'
import { addWildCard } from 'application/common/route_helpers'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'



export const MenuButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    &>button {
        border: 1px solid ${props => props.theme.color.white};
        background: ${props => props.theme.color.primary};
    }

    &>button:hover {
        background: ${props => props.theme.color.primaryHover};
        color: ${props => props.theme.color.white};
    }

`


export const BranchSpecificAccordionMenuComponent = (
    {
        onOpenChangeBranchDialog, 
        primaryColor,
        primaryHoverColor,
        currentBranch,
        hasMultipleBranches
    }
) => {
    const {t} = useTranslation()

    const {
        branchLink
    } = useApplicationLinks()

    const matchDamageOverview = notNull(
        useMatch({
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW)
        })
    )
    const matchDashBoard = notNull(
        useMatch(ROUTES.BRANCH_SPECIFIC_DASHBOARD)
    )
    const matchTasksOverview = notNull(
        useMatch({ 
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW) 
        })
    )
    const matchInbox = notNull(
        useMatch({ 
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_INBOX) 
        })
    )
    const matchControlling = notNull(   
        useMatch({
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_CONTROLLING) 
        })
    )
    const matchActivityLog = notNull(
        useMatch(ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG)
    )

    if(currentBranch === null){
        return null
    }

    const branchId = currentBranch.id
    const branchName = currentBranch.name

    return  <React.Fragment>
        <IconLink 
            toolTip={ buildToolTip(branchName, 'Overview', t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={ 
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_DASHBOARD, 
                    branchId
                )
            }
            iconKey={'widgets'}
            isActive={matchDashBoard}
        />
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.DAMAGE.READ]} 
            allNeeded={false}
        >
            <IconLink 
                toolTip={ buildToolTip(branchName, 'Damages', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW,
                        branchId
                    )
                }
                iconKey={'directions_car'}
                isActive={matchDamageOverview}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.TASK.READ]} 
            allNeeded={false}
        >
            <IconLink 
                toolTip={ buildToolTip(branchName, 'Tasks', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={
                    branchLink( 
                        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW, 
                        branchId
                    )
                }
                iconKey={'description'}
                isActive={matchTasksOverview}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.MESSAGE.READ]} 
            allNeeded={false}
        >
            <IconLink 
                toolTip={ buildToolTip(branchName, 'Inbox', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_INBOX, 
                        branchId
                    )
                }
                iconKey={'mail_outline'}
                isActive={matchInbox}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
        >
            <IconLink 
                toolTip={ buildToolTip(branchName, 'Controlling', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_CONTROLLING,
                        branchId
                    )
                }
                iconKey={'bar_chart'}
                isActive={matchControlling}
            />
        </ForCurrentBranchPermissions>
        <IconLink 
            toolTip={ buildToolTip(branchName, 'Activities', t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={ 
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG, 
                    branchId
                )
            }
            iconKey={'list'}
            isActive={matchActivityLog}
        />
        {
            hasMultipleBranches && <MenuIconLink
                toolTip={ t('Change branch') }
                iconKey={'swap_horiz'}
                isClickable={true}
                onClick={onOpenChangeBranchDialog}
                hoverBackground={primaryHoverColor}
            />
        }
    </React.Fragment>
}

BranchSpecificAccordionMenuComponent.propTypes = { 
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    onOpenChangeBranchDialog: PropTypes.func,
    currentBranch: PropTypes.object,
    hasMultipleBranches: PropTypes.bool
}


