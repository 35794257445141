import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Spacer, PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

export const DeleteComprehensiveDamageTypeComponent = withDialogDataLoader((
    { 
        abort, 
        dialog, 
        onDeleteComprehensiveDamageType
    }
) => {
    const {t} = useTranslation()

    const { 
        name,
        id
    } = dialog.payload

    const hasDamages =  dialog.payload.damagesResponse.total > 0
        
    return <React.Fragment>
        <DialogMainContent>
            
            {
                hasDamages
                    ? <React.Fragment>
                        <PushBottom16 />
                        <InfoBox 
                            type={INFOBOX.TYPES.ALERT} 
                            direction='row' 
                            cssMargin='0' 
                            cssPadding='16px'
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <span>
                                    {t('This damage type can\'t be deleted, because there are still damage cases associated with it.')}
                                </span>
                                <Spacer />
                            </FlexBox> 
                        </InfoBox>
                    </React.Fragment>
                    : <Paragraph cssPadding='16px 0'>
                        <Trans i18nKey='Are you sure you want to delete this comprehensive damage type?'>
                    Do you want to delete <strong>{{name}}</strong> ?
                        </Trans>
                    </Paragraph>
            }
            
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            submitText='Delete'
            onSubmit={
                () => onDeleteComprehensiveDamageType(
                    dialog.name, 
                    id,
                    name
                )
            }
            disabled={hasDamages}
        />
    </React.Fragment>
})