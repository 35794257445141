import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { updateAdditionalDamageData, updateRepairSchedule } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { showAttachmentDetailsWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { connect } from 'react-redux'
import { TaskDamageRepairScheduleComponent } from './component'


const mapStateToProps = state => ({
    currentDamage: state.pages.damageManager.shared.damage,
    selectedTaskDocuments: state.sharedResource.id === null
        ? state.pages.damageManager.tasks.selectedTaskDocuments
        : state.pages.workflowPanel.tasks.selectedTaskDocuments,
    isExternal: state.sharedResource.id !== null,
    sharedResourceId: state.sharedResource.id !== null,
})

const mapDispatchToProps = dispatch => {
    return {
        onSaveAdditionalDamageData: (damageId, payload) => dispatch(
            updateAdditionalDamageData({damageId}, payload)
        ),
        onSaveRepairScheduleData: (damageId, payload) => dispatch(
            updateRepairSchedule({damageId}, payload)
        ),
        onFilesAdded: (
            files, 
            taskId, 
            damageId,
            widgetContexts, 
            isExternal
        ) => {
            const payload =                     {
                files,
                taskId,
                damageId, 
                widgetContexts, 
                useDefaultCategory: true
            }
    
            if(isExternal){
                dispatch(
                    openDialogByName(
                        DIALOG.NAME.CATEGORY_UPLOAD_WORKFLOW_PANEL, 
                        true, 
                        payload, 
                        'Assign category'
                    )
                )
            }else{
                dispatch(
                    openDialogByName(
                        DIALOG.NAME.CATEGORY_SELECT_FROM_TASK_WIDGET,
                        true, 
                        {
                            ...payload,
                            useDefaultCategory: false
                        }, 
                        'Assign category'
                    )
                )
            }
        },
        onShowAttachmentDetails: (
            attachment, 
            damageid, 
            taskId, 
            isExternal, 
            sharedResourceId
        ) => {
            if(isExternal){
                dispatch(
                    showAttachmentDetailsWorkflowPanel(
                        attachment, 
                        damageid, 
                        sharedResourceId, 
                        taskId
                    )
                )
            }else{
                dispatch(
                    showAttachmentDetails(
                        attachment, 
                        damageid, 
                        taskId
                    )
                )
            }
        }
    }
}

export const TaskDamageRepairSchedule = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageRepairScheduleComponent)