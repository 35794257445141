import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { notNull } from 'application/common/data_helpers'



export const DescriptionChangedInfo = (
    {activityData}
) => {

    const {t} = useTranslation()

    const {
        damage = null
    } = activityData

    return <FlexBox>
        {
            notNull(damage) && <React.Fragment>
                { t('Damage') + ': ' + damage.licensePlate }
            </React.Fragment>
        }
    </FlexBox>
}

DescriptionChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}
