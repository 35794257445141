import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* deleteCustomStateSaga(action) {
    try {
        const {customStateId} = action.metaData

        yield resourceCall(
            api.gateway.damageState.deleteCustomState, 
            {
                customStateId
            }
        )
        yield put(showToast('State deleted'))
    } catch (e) {
        yield handleError(e, action)
    }
}