import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer, PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'

export const EditComprehensiveDamageTypeComponent = (
    { 
        abort, 
        dialog, 
        onSaveComprehensiveDamageType 
    }
) => {
    const { 
        name,
        id
    } = dialog.payload

    const {t} = useTranslation()

    const defaultValues = {
        name
    }

    const { 
        register,
        errors, 
        handleSubmit,
        getValues 
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => {
        onSaveComprehensiveDamageType(
            dialog.name, 
            id, 
            getValues()
        )
    }
    
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <PushBottom16 />
                <InfoBox 
                    type={INFOBOX.TYPES.STANDARD} 
                    direction='row' 
                    cssMargin='0' 
                    cssPadding='16px'
                >
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <FlexBox flexDirection='column'>
                        <span>
                            {t('If you change this damage type, existing damage cases with this type will be affected by the change.')}
                        </span>
                        <Spacer />
                    </FlexBox> 
                </InfoBox>
                <PushBottom32 />
                <TextInput
                    label={t('Name')}
                    name='name'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.name}
                    autoFocus={true}
                    onKeyDownEnter={handleSubmit(onSubmitSuccess)}
                />  
            </FlexBox>
            <PushBottom32 />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save comprehensive damage type'
        />
    </React.Fragment>
}