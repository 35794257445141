import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStatefulForm } from 'application/components/hooks'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { CategorySelect } from 'application/components/controls/form/category_select'
import { TaskSelect } from 'application/components/controls/form/task_select'
import { VDivider } from 'application/components/fragments/v_divider'
import { FilesListWithLawyerFlag } from 'application/components/building_blocks/files_list_with_lawyer_flag'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'






export const CategorySelectWithUploaderStep1 = ({
    goToPreviousStep,
    files,
    setFiles,
    setTaskId,
    setCategoryId,
    initialCategoryId,
    damageId,
    onSubmit,
    useDefaultCategory
}) => {
    const { t } = useTranslation()
    
    const defaultValues = {
        category: initialCategoryId
    }

    const {register, setValue, updateFormState} = useStatefulForm({defaultValues})
    const hasFiles = files.length > 0

    return <React.Fragment>
        <DialogMainContent cssHeight='300px'>
            {
                hasFiles
                    ?
                    <FlexBox 
                        flexBasis='100%' 
                    >
                        <FlexBox 
                            flexDirection='column' 
                            flexBasis='50%'
                        >
                            <FilesListWithLawyerFlag 
                                files={files}
                                setFiles={setFiles}  
                            />
                        </FlexBox>
                        <VDivider height='174px' />
                        <FlexBox 
                            flexBasis='40%' 
                            flexDirection='column'
                        >
                            <FlexBox height='71px'>
                                <CategorySelect 
                                    register={register}
                                    setValue={setValue}
                                    initialValue={defaultValues.category}
                                    onChange={option => {
                                        setCategoryId(option.value)
                                        updateFormState()
                                    }}
                                    fixedDefaultCategory={useDefaultCategory}
                                    disabled={useDefaultCategory}
                                />
                            </FlexBox>
                            {
                                !useDefaultCategory && <FlexBox height='71px'>
                                    <TaskSelect
                                        damageId={damageId} 
                                        register={register}
                                        setValue={setValue}
                                        onChange={option => {
                                            setTaskId(option.value)
                                            updateFormState()
                                        }}
                                    />
                                </FlexBox>
                            }
                        </FlexBox>
                    </FlexBox>
                    :
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='14px 0 0 0'
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <span>{t('There are no files')}</span>
                        <Spacer />
                    </InfoBox>
            }

        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={() => {
                    goToPreviousStep()
                }} 
            />
            <ActionButton
                buttonText={t('Save')} 
                onButtonClick={onSubmit} 
                disabled={!hasFiles}
            />
        </StepsLayoutFooter>
    </React.Fragment>
}