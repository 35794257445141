import React from 'react'
import { FilterRadioCheckboxGroup }  from '../radio_checkbox_group'
import PropTypes from 'prop-types'
import * as filters from 'application/filters'
import { FILTERS } from 'application/constants/filters'
import { Label } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'

const ExternalFilterCheckboxGroup = (
    {
        pagination,
        disabled = false,
        spaceLeft = false
    }
) => {
    const {t} = useTranslation()
    const TASKFILTERS = FILTERS.VALUES.TASKS
    const filter = filters.tasks.external

    const checkboxDefinitions = [
        {
            label: 'Internal & external tasks',
            value: TASKFILTERS.IS_EXTERNAL.ALL
        },
        {
            label: 'Only external tasks',
            value: TASKFILTERS.IS_EXTERNAL.TRUE
        }
    ]


    return <FilterFragment.FilterRow className='originFilterRow'>
        <FilterFragment.FilterLabel>
            <Label>
                {t('Origin')}
            </Label>
        </FilterFragment.FilterLabel>
        <FilterFragment.FilterAction>
            <FilterRadioCheckboxGroup
                OFFSET={pagination.offset}
                LIMIT={pagination.limit}
                filter={filter}
                disabled={disabled}
                checkboxDefinitions={checkboxDefinitions}
            />
        </FilterFragment.FilterAction>
    </FilterFragment.FilterRow>                
}

ExternalFilterCheckboxGroup.propTypes = {
    pagination: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    spaceLeft: PropTypes.bool
}

export {ExternalFilterCheckboxGroup}