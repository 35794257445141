import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ToggleSwitchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 34px;
`

const Switcher = styled.div`
    background: lightgrey;
    width: 60px;
    height: 34px;
    border-radius: 26px;
    cursor: pointer;
    background: ${props => props.active 
        ? props.theme.color.white
        : props.theme.color.gray10
};
    transition: .4s;
`

const Slider = styled.div`
    margin-top: 4px;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    margin-left: ${props => props.active ? '30px' :  '4px' };
    background: ${props => props.active 
        ? props.theme.color.primary
        : props.theme.color.anthracite50
};
    transition: .4s;

    &:after{
        content: "\\2713";
        opacity: ${props => props.active ? '1' : '0'};
        color: ${props => props.theme.color.white};
        display: flex;
        width: inherit;
        height: inherit;
        align-items: center;
        justify-content: center;
        transition: opacity .4s; 
    }
`

const Label = styled.label`

    color: ${props => props.isActive 
        ? props.theme.color.primary 
        :  props.theme.color.anthracite };

    font-size: ${props => props.theme.fontSize.standard};
    padding-left: 8px;
    padding-right: 8px;


`

export const ToggleSwitch = (
    {
        onChange,
        label = '',
        initiallyActive = false
    }
) => {
    const [isActive, setIsActive] = useState(initiallyActive)

    useEffect(() => {
        setIsActive(initiallyActive)
    },[initiallyActive])


    return <ToggleSwitchWrapper>
        <Switcher
            className={'jest-toggle-switch-test-5000'}
            active={isActive}
            onClick={
                () => {
                    onChange(!isActive)
                    setIsActive(!isActive)
                }
            }
        >
            <Slider active={isActive} />
        </Switcher>
        <Label isActive={isActive} >
            {label}
        </Label>
    </ToggleSwitchWrapper>
}

ToggleSwitch.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    initiallyActive: PropTypes.bool
}