
import styled from 'styled-components'

export const DisabledDatePicker = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
    cursor: default;

    width: 100%;
    height: 38px;

    font-size: ${props => props.theme.fontSize.standard};
    background-color: ${props => props.theme.color.gray5};


    border-top: 1px solid ${props => props.theme.color.gray15};
    border-left: 1px solid ${props => props.theme.color.gray15};
    border-bottom: 1px solid ${props => props.theme.color.gray15};

    & span {
        color: ${ props => props.theme.color.anthracite50}
    }
`


export const StyledDatePicker = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};


    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }



    & input:focus{
        outline: none;
    }

    & input::placeholder{
        color: ${props => props.theme.color.anthracite50};
        margin-left: 8px;
    }

    & input {
        box-sizing: border-box;
        width: 100%;
        height: 38px;
        padding: 0 8px;
        border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
        cursor: default;
    }
`


export const Row = styled.div`
    display: flex;
    flex-direction: row;
`

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => props.isClearable ? 'pointer' : 'default'};

    width: ${props => props.isClearable ? '34px' : '1'};
    height: 38px;

    border-top: 1px solid  ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
    border-right: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
    border-bottom: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};

    background-color: ${props => props.disabled ? props.theme.color.gray5 : props.theme.color.white};


    & > i.material-icons {
        color:  ${props => props.disabled ? props.theme.color.gray15 :  props.theme.color.gray15 };
    }

    & > i.material-icons:hover {
        color: ${props => props.disabled ?   props.theme.color.gray15 : props.theme.color.lightPowderGray};
    }

    & > i.material-icons:active {
        color: ${props => props.disabled ?  props.theme.color.gray15   :  props.theme.color.anthracite};
    }
`

export const HiddenTextField = styled.input`
    display: none;
`