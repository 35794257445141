import { put, takeLatest } from 'redux-saga/effects'

import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateGroupMembers } from 'application/redux/actions/pages/branch_management'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getGroupMembersSaga(action) {
    try {
        const { offset, limit, orderBy, direction, filterQuery} = action
        const response = yield resourceCall(
            api.gateway.users.getUsersList  , 
            {
                offset, 
                limit, 
                orderBy, 
                direction, 
                filterQuery
            }
        )

        yield put(populateGroupMembers(action.groupId, response.users, response.total))
    } catch (e) {
        yield handleError(e)
    }
}

export function* getGroupMembersWatcher() {
    yield takeLatest(ACTIONS.BRANCH_MANAGEMENT_GET_GROUP_MEMBERS_REQUEST, getGroupMembersSaga)
}