import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {WidgetBoardEffects} from './effects'
import { saveWidgetLayout, saveWidgetList, setStaticMode, removeWidget } from 'application/redux/actions/widget_contexts'
import { registerWidget } from 'application/redux/actions/widgets'

const mapStateToProps = (state, props) => {
    return {
        registeredWidgets: state.widgets.registeredWidgets
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onWidgetLayoutChange: (widgetContext, widgetLayout, widgetList) => {
            dispatch(saveWidgetLayout(widgetContext, widgetLayout))
            dispatch(saveWidgetList(widgetContext, widgetList))
        },
        onRegisterWidgets: (widgetContext, widgetList) => {
            for(const widget of widgetList){
                dispatch(registerWidget(widget.key, widget.type, widgetContext, widget.title, widget.initialPayload, widget.hideHeadline, widget.accountDependent, widget.dependentWidgetKeys, widget.dependsOnOtherWidget))
            }
        },
        onUpdateStaticMode: (widgetContext, isStatic) => dispatch(setStaticMode(widgetContext, isStatic)),
        onRemoveWidget: (widgetContext, widgetKey) => {
            dispatch(removeWidget(widgetContext, widgetKey))
        }
    }
}

export const WidgetBoardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WidgetBoardEffects)

WidgetBoardContainer.propTypes = {
    widgetContext: PropTypes.string.isRequired
}