import React from 'react'
import {RegistrationLayout} from 'application/components/pages/_registration_layout'
import { LoginForm, RegistrationForm } from './forms'
import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation/with_shared_resource_validation'

const RegistrationPageC = ({match}) => {
    const query = useQuery()

    const sharedResourceIdFromUrl = match.params.sharedResourceId
    const userCreated = query.getStringParam(ROUTE_PARAMETERS.REGISTRATION.USER_CREATED, '') === 'true' 
    const registrationUrl = `${process.env.REACT_APP_SSO_SIGNUP}?returnurl=${window.location.origin}/registration/${sharedResourceIdFromUrl}?${ROUTE_PARAMETERS.REGISTRATION.USER_CREATED}=true`
    const firstName = query.getStringParam(ROUTE_PARAMETERS.REGISTRATION.FIRST_NAME)
    const lastName = query.getStringParam(ROUTE_PARAMETERS.REGISTRATION.LAST_NAME)
    const salutation = firstName === '' ? ',' : `${firstName} ${lastName},`
    
    return <RegistrationLayout 
        leftAreaWidth='50%' 
        rightAreaWidth='50%'
    >
        {
            !userCreated && <RegistrationForm                
                salutation = {salutation}
                registrationUrl = {registrationUrl}
                sharedResourceId = {sharedResourceIdFromUrl}
            />
        }
        {
            userCreated && <LoginForm
                sharedResourceId={sharedResourceIdFromUrl} 
            />
        }
    </RegistrationLayout>
}

export const RegistrationPage =  withSharedResourceValidation()(RegistrationPageC)