import {getWidgetContextMeta} from './get_widget_context_meta'
import {widgetLayoutStore} from './layout_storage'
import {widgetListStore} from './list_storage'

export const widgetContextBuilder = widgetContextName => {
    const boardConfiguration = getWidgetContextMeta(widgetContextName).boardConfiguration
    const widgetLayout = widgetLayoutStore(widgetContextName).get()
    const widgetList = widgetListStore(widgetContextName).get()
    
    return {
        key: widgetContextName,
        widgetLayout,
        widgetList,
        boardConfiguration
    }
}