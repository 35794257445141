import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* kickstartUploadFilesSubSaga (files, createDamageResponse, lawfirm) {
    if(files.length > 0){
        for(const file of files){
            const documentResponse = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: createDamageResponse.id
                },
                {
                    file: file.metaData
                }
            )

            if(lawfirm.commissionLawfirm){
                yield resourceCall(
                    api.gateway.attachments.updateAttachment,
                    {
                        damageId: createDamageResponse.id,
                        attachmentId: documentResponse.id
                    },
                    {
                        isExportedToLawFirm: true
                    }
                )
            }
            
        }
    }else{
        yield
    }
}