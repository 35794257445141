import React from 'react'
import { useClipboardPaste } from 'application/components/hooks/use_clipboard_paste'
import { ActionButton } from 'application/components/controls/action_button'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ErrorLabel } from 'application/components/fragments/error_information'

const StyledDocumentPasteArea = styled.div`
    border: 2px ${props => props.theme.color.gray20} dashed;
    padding: 16px;
    margin-right: 16px;
    color: ${props => props.theme.color.anthraciteLight};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.fontSize.headline2};
`

export const PasteSignature = ({
    onPaste = () => {},
    buttonText = 'Paste signature from clipboard'
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const {
        clipboardSupported,
        pasteImage,
        incompatibleClipboardData
    } = useClipboardPaste(onPaste)

    return <React.Fragment>
        {
            clipboardSupported
                ? <ActionButton 
                    onButtonClick={pasteImage}
                    buttonText={t(buttonText)}
                />
                : <StyledDocumentPasteArea>
                    {t('Click in here after you copied an image of a signature to your clipboard and insert it to the signature field with CTRL+V.')}
                </StyledDocumentPasteArea>
        }

        {
            incompatibleClipboardData && <ErrorLabel
                $fontSize={theme.fontSize.small}
            >{t('The data in the clipboard is not an image.')}</ErrorLabel>
        }
    </React.Fragment>
}