import React from 'react'

import * as Fragments from './fragments'
import { LayoutBase } from './layout_base_container'
import { TitleBarDamageManager, SidebarDamageManager } from 'application/components/pages/internal/_title_bars'
import { RealtimeNotificationsSidebar } from 'application/components/building_blocks/realtime_notifications_sidebar'
import { PageTitle } from 'application/components/controls/page_title'

export const LayoutDamageManager = ({ children, titleSegments=[], className = '' }) => {
    return <React.Fragment>
        <PageTitle segments={titleSegments} />
        <RealtimeNotificationsSidebar />
        <LayoutBase className={className}>
            <Fragments.Header className='damageManagerHeader'>
                <TitleBarDamageManager />
            </Fragments.Header>
            <Fragments.Main className='damageManagerMain'>
                <SidebarDamageManager />
                <Fragments.DamageManagerContent className='damageManagerContent' cssPaddingTop='0' cssPaddingLeft='0' cssPaddingRight='0'>
                    {children}
                </Fragments.DamageManagerContent>
            </Fragments.Main>
        </LayoutBase>
    </React.Fragment>
}
