import React   from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import {Footer} from 'application/components/dialogs/users/create/_common/footer'
import { PushBottom16, PushBottom32 } from 'application/components/pages/_common'
import {StepsBar} from 'application/components/building_blocks/steps_bar'
import {UserDetailsSidebar} from 'application/components/dialogs/users/create/_common/sidebar'





export const CreateUserDialogLayout = (
    {
        getStepsState,
        firstName,
        lastName,
        form,
        footerElements
    }
) => {

    return <React.Fragment>
        <FlexBox
            flexDirection='column'
            cssRules='
                padding: 0 16px;
                margin-bottom: 68px;
                overflow-y: auto;
                min-height: 480px;
            '
        >
            <PushBottom16/>
            <StepsBar         
                getStepsState={getStepsState}
            />
            <PushBottom32/>
            <FlexBox flexDirection='row'>
                <FlexBox
                    flexBasis='35%'
                    flexDirection='column'
                    height='400px'
                    justifyContent='center'
                >
                    <UserDetailsSidebar 
                        firstName = {firstName}
                        lastName = {lastName}
                    />
                </FlexBox>
                <FlexBox 
                    flexDirection='column' 
                    flexBasis='65%'
                >
                    {form}
                </FlexBox>
            </FlexBox>
        </FlexBox>


        <Footer>
            {footerElements}
        </Footer>
    </React.Fragment>
}