import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { buildListQuery } from 'application/api/helpers'
import { apiCall } from 'application/api/helpers'

export const getDamages = (params) => (
    {
        authenticated: () => {
            const {
                offset, 
                limit, 
                orderBy='id',
                direction='desc',
                filterQuery=[]
            } = params

            const queryString = buildListQuery(offset, limit, orderBy, direction, filterQuery)
            const request = fetchRequestBuilder(`controlling/damages${queryString}`, HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)