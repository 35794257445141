export const ACTIVITY_TYPE = {
    ACCOUNT_CREATED: 'AccountCreatedActivity',
    ACCOUNT_INVITATION_CREATED: 'AccountInvitationCreatedActivity',
    BRANCH_CREATED: 'BranchCreatedActivity',
    BRANCH_DELETED: 'BranchDeletedActivity',
    COMPREHENSIVE_DAMAGE_TYPE_CHANGED: 'ComprehensiveDamageTypeChangedActivity', //damage entity
    COMPREHENSIVE_DAMAGE_TYPE_CREATED: 'ComprehensiveDamageTypeCreatedActivity', //ComprehensiveDamageType entity
    COMPREHENSIVE_DAMAGE_TYPE_DELETED: 'ComprehensiveDamageTypeDeletedActivity', //ComprehensiveDamageType entity
    COMPREHENSIVE_DAMAGE_TYPE_RENAMED: 'ComprehensiveDamageTypeRenamedActivity', //ComprehensiveDamageType entity
    DAMAGE_ASSIGNEE_CHANGED: 'DamageAssigneeChangedActivity',
    DAMAGE_CREATED: 'DamageCreatedActivity',
    DAMAGE_DELETED: 'DamageDeletedActivity',
    DAMAGE_JOB_NUMBER_CHANGED: 'DamageJobNumberChangedActivity',
    DAMAGE_STATE_CREATED: 'DamageStateCreatedActivity', //damageState entity
    DAMAGE_STATE_CHANGED: 'DamageStateChangedActivity', //damage entity
    DAMAGE_STATE_DELETED: 'DamageStateDeletedActivity', //damageState entity
    DAMAGE_TYPE_CHANGED: 'DamageTypeChangedActivity',
    DAMAGE_DESCRIPTION_CHANGED: 'DamageDescriptionChangedActivity',
    DAMAGE_INCIDENT_DATE_CHANGED: 'DamageIncidentDateChangedActivity',
    DAMAGE_NOTE_CHANGED: 'DamageNoteChangedActivity',
    INVOICE_CREATED: 'InvoiceCreatedActivity',
    INVOICE_DELETED: 'InvoiceDeletedActivity',
    LAWFIRM_COMMUNICATION_INITIATED: 'LawFirmCommunicationInitiatedActivity',
    LAWFIRM_COMMUNICATION_ABORTED: 'LawFirmCommunicationAbortedActivity',
    PAYMENT_CANCELLED: 'PaymentCancelledActivity',
    PAYMENT_CREATED: 'PaymentCreatedActivity',
    USER_CREATED: 'UserCreatedActivity',
    USER_DATA_CHANGED: 'UserDataChangedActivity',
    USER_DELETED: 'UserDeletedActivity',
    USER_LINKED: 'UserLinkedActivity',
    USER_INVITATION_CREATED: 'UserInvitationCreatedActivity',
    TASK_CREATED: 'TaskCreatedActivity',
    TASK_DELETED: 'TaskDeletedActivity',
    TASK_STATE_CHANGED: 'TaskStateChangedActivity',
    TASK_COMMENT_CREATED: 'TaskCommentCreatedActivity',
    VEHICLE_UPDATED: 'VehicleUpdatedActivity',
    VEHICLE_KEEPER_CHANGED: 'VehicleKeeperChangedActivity',
    DOCUMENT_CREATED: 'DocumentCreatedActivity',
    DOCUMENT_DELETED: 'DocumentDeletedActivity',
    MESSAGE_CREATED: 'MessageCreatedActivity',
    MESSAGE_DELETED: 'MessageDeletedActivity',
    EXPERT_COMMUNICATION_DEKRA_INITIATED: 'ExpertCommunicationDekraInitiatedActivity',
    EXPERT_COMMUNICATION_DEKRA_ESTABLISHED: 'ExpertCommunicationDekraEstablishedActivity',
    EXPERT_COMMUNICATION_DEKRA_FAILED: 'ExpertCommunicationDekraFailedActivity',
    EXPERT_COMMUNICATION_OTHER_INITIATED: 'ExpertCommunicationOtherInitiatedActivity',
    ACCOUNT_LOCKED: 'AccountLockedActivity',
    ACCOUNT_UNLOCKED: 'AccountUnlockedActivity',
    USER_LOCKED: 'UserLockedActivity',
    USER_UNLOCKED: 'UserUnlockedActivity',
    REPAIRAUTHORIZATION_CHANGED: 'RepairAuthorizationChangedActivity',
    CONTACT_INFO_UPDATED: 'ContactInfoUpdatedActivity',
    CASEFILE_INFO_UPDATED: 'CaseFileInfoUpdatedActivity',
    LIABILITY_INQUIRY_UPDATED:'LiabilityInquiryUpdatedActivity',
    ACKNOWLEDGEMENT_UPDATED:'AcknowledgementUpdatedActivity'
}

export const ACTIVITY_RELATED_ENTITY_TYPES = {
    DAMAGE: 'Damage',
    INVOICE: 'Invoice',
    PAYMENT: 'Payment'
}