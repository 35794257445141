import styled from 'styled-components'

export const ErrorBox = styled.div`
    min-height: 16px;
`

export const ErrorLabel = styled.span`
    font-size: ${props => props.$fontSize ? props.$fontSize : props.theme.fontSize.xSmall};
    margin-top: 8px;
    font-weight: 700;
    color: ${props => props.theme.color.red};
`