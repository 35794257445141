import React, { memo } from 'react'

import { LeftHandle, RightHandle } from './handles'
import { CustomNodeCard, RemoveNodeButton } from './fragments'
import { CustomNodeLower } from './custom_node_lower'
import { CustomNodeUpper } from './custom_node_upper'
import { BRANCH_MANAGEMENT } from 'application/constants'

export const CustomNode = memo(
    (props) => {
        const { 
            data, 
            onRemoveButtonClick, 
            selected, 
            onNodeClick = () => {}, 
            removeButtonHidden = () => true 
        } = props
    
        const {
            label, 
            nodeType, 
            color, 
            isDisabled, 
            branchesDisabled
        } = data

        const handleDelete = e => {
            if(!isDisabled){
                e.preventDefault()
                e.stopPropagation()
                onRemoveButtonClick(data)
            }
        }

        const handleClick = e => {
            if(!isDisabled){
                onNodeClick(data.id, data.nodeType)
            }
        }

        const isLocked = nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP && data.isPredefined

        return <React.Fragment>
            <LeftHandle 
                nodeType={nodeType} 
                data={data}
                branchesDisabled={branchesDisabled}
            />
            
            <CustomNodeCard 
                borderColor={color} 
                selected={selected} 
                onClick={handleClick} 
                isDisabled={isDisabled}
            >
                {
                    !isDisabled 
                && !removeButtonHidden(data) 
                && <RemoveNodeButton 
                    selected={selected} 
                    onClick={handleDelete}>
                    x
                </RemoveNodeButton>
                }
                <CustomNodeUpper 
                    label={label}
                    selected={selected}
                    isLocked={isLocked}
                    data={data}
                />
                <CustomNodeLower
                    nodeData={data}
                    selected={selected}
                />
            </CustomNodeCard>
           
            <RightHandle 
                nodeType={nodeType} 
                branchesDisabled={branchesDisabled}
                data={data}
            />
        </React.Fragment>
    }
)