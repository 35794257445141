import { connect } from 'react-redux'
import { Step2Component } from './component'
import { createExtDamageRequest } from 'application/redux/actions/ext_create_damage'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: (                
            damageData,
            filesData
        ) => dispatch(
            createExtDamageRequest(
                damageData,
                filesData
            )
        )
    }
}

export const Step2 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2Component)