import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiFilestream } from 'application/api/helpers'

export const getAttachmentDataById = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/documents/${params.attachmentId}/file`, 
                HTTP_METHOD.GET
            )
            return apiFilestream(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents/${params.attachmentId}/file`, 
                params.pin,
                HTTP_METHOD.GET
            )
            return apiFilestream(request)
        },
    }
)