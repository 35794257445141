import React from 'react'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { FullName } from 'application/components/controls/full_name'
import {DateComponent} from 'application/components/controls/date'
import { TasksTableContextMenu } from '../../_common/context_menu'
import {Deadline} from 'application/components/controls/deadline'
import { StateName } from 'application/components/name_mappings/state_name'
import { TaskDetails } from '../../_common/task_details'
import TruncateString from 'react-truncate-string'
import { TaskCreator } from '../../_common/task_creator'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'


const tableName = STORAGE.TABLE_NAME.TASKS.ALL_BRANCHES_MY

export const configuration = {
    name: tableName,
    filters: {
        match: [],
        query: [
            new ListFilter(filters.tasks.state),
            new ListFilter(filters.tasks.external)
        ],
        property: [
            new ListFilter(filters.tasks.assigneeCurrentUserId)
        ],
        static: []
    },
    sorting: {
        sortParamKey: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.VALUES.DEADLINE,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.MY_TASKS.OFFSET,
        limit: ROUTE_PARAMETERS.MY_TASKS.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName)
        }
    },
    href: (element, applicationLinksHook) => applicationLinksHook.internalAccountLink(
        ROUTES.DAMAGE_MANAGER_BASE + element.damage.id + '/tasks/' + element.id
    ),
    columns: [
        {
            width: 8,
            label: 'Deadline',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.VALUES.DEADLINE,
            CellContent: ({element}) => <Deadline deadlineFromTask={element.deadline} />
        },
        {
            width: 12,
            label: 'Title',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.VALUES.TITLE,
            CellContent: ({element}) => <TruncateString text={element.title} truncateAt={50} />
        },
        {
            width: 12,
            label: 'Creator',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <TaskCreator task={element} />
        },
        {
            width: 11,
            label: 'Assignee',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FullName person={element.assignee} />
        },
        {
            width: 10,
            label: 'State',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <StateName state={element.state} />
        },
        {
            width: 11,
            label: 'Details',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <TaskDetails task={element} />
        },
        {
            width: 11,
            label: 'Customer / owner',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FullName person={element.damage.vehicle.keeper} />
        },
        {
            width: 10,
            label: 'License plate',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.VALUES.LICENSE_PLATE,
            CellContent: ({element}) => <React.Fragment>{element.damage.vehicle.licensePlate}</React.Fragment>
        },
        {
            width: 10,
            label: 'Creation date',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.MY_TASKS.ORDER_BY.VALUES.CREATION_DATE,
            CellContent: ({element}) => <DateComponent date={element.createdAt} />
        }
    ],
    ContextMenu: (
        { 
            element, 
            moIsActive
        }
    ) => moIsActive 
        ? <React.Fragment /> 
        : <TasksTableContextMenu task={element} />,
    contextMenuWidth: '34px',
    massOperations: {
        available: true  //are mass operations possible for this table?
    }
}
