
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ActivityDetailWrapper, BranchDetail, ActivityDetail, ActivityDetailBox } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'
import { ShortenedString } from 'application/components/controls/shortened_string'




export const RepairAuthorizationChangedDetails = (
    {
        activity
    }
) => {
    const { t } = useTranslation()

    const {
        damage,
    } = activity.data

    const oldStatus = t(activity.data?.oldRepairAuthorization) || ''
    const newStatus = t(activity.data?.newRepairAuthorization) || ''

    return <ActivityDetailWrapper>
        <BranchDetail
            activity={activity}
        />
        <ActivityDetail
            label='Licenseplate'
            value={damage.licensePlate}
        />
        <ActivityDetailBox>
            <ShortenedString
                text={oldStatus}
                shortenAt={20}
            />
            <RightArrow />
            <ShortenedString
                text={newStatus}
                shortenAt={20}
            />
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

RepairAuthorizationChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}