import React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'




const LiabilityInquiryUpdatedInfo = (
    {activityData}
) => {
    const {t} = useTranslation()
    const {
        oldLiabilityInquiry,
        newLiabilityInquiry
    } = activityData

    const oldStatus = oldLiabilityInquiry?.status || ''
    const newStatus = newLiabilityInquiry?.status || ''

    const oldInfo = oldLiabilityInquiry?.info || ''
    const newInfo = newLiabilityInquiry?.info || ''

    return <React.Fragment>
        {t('Liability type')}: {t(oldStatus)}<RightArrow />{t(newStatus)} <br/>
        {t('Request detail')}: {t(oldInfo)}<RightArrow />{t(newInfo)}       
    </React.Fragment>
}

LiabilityInquiryUpdatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {LiabilityInquiryUpdatedInfo} 