import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { users } from 'application/filters'


export function* dialogGetInitialExpertsSaga(action) {
    const { dialogName } = action

    const expertsResponse = yield resourceCall(
        api.gateway.users.getUsersList,
        {
            offset: 0, 
            limit: 25, 
            direction: 'asc',
            filterQuery: users.experts.getFilterQuery()
        }
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                initialExperts: expertsResponse?.users || [] 
            }
        )
    )
}