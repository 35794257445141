import { connect } from 'react-redux'
import { DamageInvoicesComponent } from './component'

const mapStateToProps = state => ({
    accountId: state.auth.currentAccount.id
})


const mapDispatchToProps = dispatch => {
    return {}
}


export const DamageInvoices = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageInvoicesComponent)