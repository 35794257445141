
import React from 'react'
import { REALTIME_NOTIFICATION_TYPE } from 'application/constants'
import { withTheme } from 'styled-components'
import { GravatarWithInitial } from 'application/components/controls/gravatar'





export const NotificationIconComponent = ({
    notificationType,
    theme
}) => {

    const mapNotificationIcon = (type) => {

        switch (type) {
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_ASSIGNEE_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_CREATED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_DESCRIPTION_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_NOTE_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.REPAIRAUTHORIZATION_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_STATE_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_TYPE_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.VEHICLE_KEEPER_CHANGED:
        case REALTIME_NOTIFICATION_TYPE.VEHICLE_UPDATED:
        case REALTIME_NOTIFICATION_TYPE.LIABILITY_INQUIRY_UPDATED:
            return 'directions_car'
        case REALTIME_NOTIFICATION_TYPE.TASK_CREATED:
        case REALTIME_NOTIFICATION_TYPE.TASK_STATE_CHANGED:
            return 'assignment'
        case REALTIME_NOTIFICATION_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
        case REALTIME_NOTIFICATION_TYPE.CASEFILE_INFO_UPDATED:
        case REALTIME_NOTIFICATION_TYPE.ACKNOWLEDGEMENT_UPDATED:
            return 'gavel'
        case REALTIME_NOTIFICATION_TYPE.DOCUMENT_CREATED:
            return 'attachment'
        case REALTIME_NOTIFICATION_TYPE.MESSAGE_CREATED:
            return 'email'
        case REALTIME_NOTIFICATION_TYPE.INVOICE_CREATED:
            return 'euro'
        case REALTIME_NOTIFICATION_TYPE.TASK_COMMENT_CREATED:
            return 'chat'
        case REALTIME_NOTIFICATION_TYPE.DAMAGE_DELETED:
        case REALTIME_NOTIFICATION_TYPE.TASK_DELETED:
        case REALTIME_NOTIFICATION_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
        case REALTIME_NOTIFICATION_TYPE.INVOICE_DELETED:
        case REALTIME_NOTIFICATION_TYPE.DOCUMENT_DELETED:
        case REALTIME_NOTIFICATION_TYPE.MESSAGE_DELETED:
            return 'clear'
        case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:
        case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:
        case REALTIME_NOTIFICATION_TYPE.CONTACT_INFO_UPDATED:
            return 'person'
        case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:
            return 'done'
        case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:
            return 'close'
        default:
            return 'notifications'
        }
    }

    const icon = mapNotificationIcon(notificationType)

    return <GravatarWithInitial
        backgroundColor={theme.color.gray70}
        large='40'
        icon={icon}
    />
}

export const NotificationIcon = withTheme(NotificationIconComponent)