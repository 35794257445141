
export const ROLE = {
    NAME: {
        ADMIN: 'Admin',
        USER: 'User', 
        DAMAGE_MANAGEMENT_PERMISSIONS:'DamageManagementPermissions',
        CONTROLLING_PERMISSIONS:'ControllingPermissions',
        COMMISSION_PERMISSIONS: 'CommissioningPermissions',
        DELETION_PERMISSIONS: 'DeletionPermissions'
    }
}