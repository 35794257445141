import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'

export const ExpertCommissionDekraFailedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('DEKRA expert communication failed')}
        </Fragments.ActivityTitle>
    </React.Fragment>
}