import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'
import { moClearAllItemsByName } from 'application/redux/actions/mass_operations'
import { resetDocumentCenter } from 'application/redux/actions/pages/damage_manager/documents'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { loadDocumentsByCategory } from 'application/redux/actions/documents_by_categories'

export function* dialogMoDocumentsChangeCategorySaga(action) {
    
    const { selectedDocuments } = action.payload

    const { 
        damageId, 
        moName,
        categoryId,
        updateCategories
    } = action.metaData  

    yield put(
        showWaitingScreen('Changing category...')
    )

    for(const document of selectedDocuments){
        yield put(
            addNewSubMessage(document.fileName + ' ... ')
        )
        yield resourceCall(
            api.gateway.attachments.changeCategory, 
            {
                damageId: damageId,
                attachmentId: document.id
            },
            {
                id: categoryId
            }
        )
        yield put(
            addToLastSubMessage('✓')
        )
    }

    for(const categoryId of updateCategories){
        yield put(
            loadDocumentsByCategory(
                damageId,
                categoryId,
                0,
                10
            )
        )
    }
    

    yield put(
        moClearAllItemsByName(moName)
    )
    yield put(
        resetDocumentCenter()
    )
    yield put( 
        showToast('Category changed') 
    )
    yield put( 
        hideWaitingScreen() 
    )
}