import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Paragraph } from 'application/components/fragments/typography'
import { INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import { FontIcon } from '@ec/ui-controls-react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const DeleteTaskWorkflowPanel = (
    { 
        abort, 
        dialog, 
        onDeleteTaskWorkflowPanel 
    }
) => {
    const {t} = useTranslation()
    const payload = dialog.payload

    const title = payload.task.title
    const successMsg = `"${title}" ${t('has been successfully deleted')}` 

    return <React.Fragment>
        <DialogMainContent flexDirection='column'>
            <Paragraph>
                {t('Do you want to delete this task?')}
            </Paragraph>
            <Paragraph>
                <strong>{title}</strong>
            </Paragraph>
            <PushBottom16/>
            <InfoBox 
                type={INFOBOX.TYPES.WARNING} 
                direction='row' 
                cssMargin='0' 
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <span>
                    {t('Task related documents are not deleted. These can still be accessed via the damage manager')}
                </span>
                <Spacer />
            </InfoBox>
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={
                () => onDeleteTaskWorkflowPanel(
                    dialog.name, 
                    payload.task.id, 
                    successMsg
                )
            }
            submitText='Delete task'
        />
    </React.Fragment>
}

export const DeleteTaskWorkflowPanelComponent = withDialogDataLoader(DeleteTaskWorkflowPanel) 