import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateAttachment = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/documents/${params.attachmentId}`, 
                HTTP_METHOD.PUT, 
                payload
            )
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents/${params.attachmentId}`, 
                params.pin, 
                HTTP_METHOD.PUT,
                payload
            )
            return apiCall(request)
        },
        kickstart: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/damages/${params.damageId}/documents/${params.attachmentId}`, 
                params.pin, 
                HTTP_METHOD.PUT,
                payload
            )
            return apiCall(request)
        }
    }
)