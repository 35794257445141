
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'
import { useTranslation } from 'react-i18next'

export const InvoiceCreatedDetails = (
    {
        activity
    }
) => {
    const {t} = useTranslation() 
    const {
        number,
        type,
        damage
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetail label='Invoice number' value={number} />
        <ActivityDetail label='Type' value={t(type)} />
    </ActivityDetailWrapper>
}

InvoiceCreatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}