import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const dashboardReducer =  (state = defaultStore.pages.dashboard, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.dashboard
    }
    default:
        return state
    }
}
