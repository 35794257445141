import { connect } from 'react-redux'
import {CommissionLawfirmComponent} from './component'
import { openDialogByName, sendPayloadRequest, updateDialogDataByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onLawfirmCommission: (
            name, 
            payload, 
            successMsg, 
            match, 
            changeMode
        ) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                { 
                    match, 
                    successMsg, 
                    changeMode 
                }
            )
        ),
        updateDialog: (name) => dispatch(
            updateDialogDataByName(
                name, 
                { 
                    usersList: [], 
                    usersListLoading: false, 
                }
            )
        ),
        onChangeCustomerData: (
            customer,
            damageId,
            dialogName,
            recommendContactData
        ) =>{
            dispatch(
                openDialogByName(
                    DIALOG.NAME.EDIT_CUSTOMER_FROM_DIALOG,
                    false,
                    {
                        customer,
                        damageId,
                        fromDialogName: dialogName,
                        recommendContactData
                    },
                    'Edit customer'
                )
            )
        } 
    }
}

export const CommissionLawfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionLawfirmComponent)