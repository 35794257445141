import { setQuery } from 'application/components/hooks/use_query'



export function* removeQueryFromURLSaga(action) {
    const {
        navigate,
        location
    } = action.metaData
    
    yield setQuery(
        navigate, 
        location, 
        location.pathname, 
        [], 
        false
    )
}