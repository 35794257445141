import { sendPayloadRequest} from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { CreateCostCoverageComponent } from './component'


const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
    damage: state.pages.damageManager.shared.damage,
    accessibleBranches: state.common.accessibleBranches
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (
            dialogName, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload, 
                    metaData
                )
            )
        }
    }
}

export const CreateCostCoverage = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCostCoverageComponent)