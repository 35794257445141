import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)


export const categorizedBasedTime = (payload) => {
    
    const today = []
    const lastWeek = []
    const lastMonth = []
    const olderArr = []

    const now = new Date()
    const aDayAgo = now.getTime() - (12 * 60 * 60 * 1000)
    const aWeekAgo = now.getTime() - (24 * 60 * 60 * 1000) * 7
    const aMonthAgo = now.getTime() - (24 * 60 * 60 * 1000) * 31
    const older = now.getTime() - (24 * 60 * 60 * 1000) * 7300 //(last 20 years should be enough, right?)

    payload.forEach(p => {
        if (moment.range(aDayAgo, now).contains(new Moment(p.occurredAt)))
            today.push(p)
        if (moment.range(aWeekAgo, aDayAgo).contains(new Moment(p.occurredAt)))
            lastWeek.push(p)
        if (moment.range(aMonthAgo, aWeekAgo).contains(new Moment(p.occurredAt)))
            lastMonth.push(p)
        if (moment.range(older, aMonthAgo).contains(new Moment(p.occurredAt)))
            olderArr.push(p)
    })

    const ranges = [
        {
            key: 'Today',
            range: aDayAgo,
            items: today
        },
        {
            key: 'Last week',
            range: aWeekAgo,
            items: lastWeek
        },
        {
            key: 'Last month',
            range: aMonthAgo,
            items: lastMonth
        },
        {
            key: 'Older',
            range: older,
            items: olderArr
        }
        
    ]

    return ranges
}


