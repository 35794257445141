import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'



export const usersReducer =  (
    state = defaultStore.pages.users, 
    action
) => {
    switch (action.type) {
    case ACTIONS.USERS_SET_USERS_LOADING: {
        return {
            ...state,
            usersList: {
                users: [],
                total: 0
            },
            loaded: false,
            loading: true
        }

    }
    case ACTIONS.USERS_POPULATE_USERS:
        return {
            ...state,
            usersList: action.usersList,
            loaded: true,
            loading: false
        }
    default:
        return state
    }
}
