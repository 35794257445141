import { connect } from 'react-redux'
import { getSharedResourceById } from 'application/redux/actions/shared_resource'

export const withSharedResourceValidationContainer = (redirectUrl) => connect(

    state => (
        {
            sharedResource: state.sharedResource,
            redirectUrl
        }
    ), 

    dispatch => (
        { 
            onGetSharedResourceById : (sharedResourceId) => {
                dispatch(
                    getSharedResourceById(sharedResourceId)
                )
            }   
        }
    )
    
)