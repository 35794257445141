import React from 'react'
import styled from 'styled-components'
import { ToggleSwitch } from 'application/components/controls/toggle-switch'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Spacer } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'
import { SETTINGS } from 'application/constants'
import { AVAILABLE_SMS_NOTIFICATIONS } from 'application/constants/realtime_notifications'

export const StyledSettingsCard = styled.div`
    padding: 4px 8px;
    background-color: ${props => props.theme.color.gray15};
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SettingName = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    /*margin-bottom: 8px; not needed without description */
`

const SettingDescription = styled.div`
    font-size: ${props => props.theme.fontSize.small};
`

export const ToggleSwitchSettingCard = (
    {
        name,
        description,
        onChange,
        switches = [],
        notificationType
    }
) => {
    const {t} = useTranslation()
    return <StyledSettingsCard>
        <FlexBox flexDirection='column'>
            <SettingName>
                {t(name)}
            </SettingName>
            <SettingDescription>
                {t(description)}
            </SettingDescription>
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
            {
                switches.map((s, i) => {

                    const isApplied = s.key === SETTINGS.KEYS.NOTIFICATIONS_CHANNEL_TYPES.SMS
                        ? AVAILABLE_SMS_NOTIFICATIONS.includes(notificationType)
                        : true

                    return isApplied 
                        ? <React.Fragment key={i}>
                            <Spacer basis='8px' />
                            <ToggleSwitch
                                label={t(`${s.key}SettingTitle`)}
                                initiallyActive={s.value}
                                onChange={(value) => {
                                    onChange(s.key, value)
                                }}
                            />
                        </React.Fragment>
                        : <React.Fragment />
                })
            }

        </FlexBox>
    </StyledSettingsCard>
}