import { isDefined } from 'application/common/data_helpers'
import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

//default data structure:
// documentsByCategories: {
//     resourceId: null,
//     categories: []
// }

export const documentsByCategoriesReducer = (
    state = defaultStore.documentsByCategories, 
    action
) => {
    const hasCurrentDocumentsByCategory = (currentCategoryId) =>  isDefined(
        state.categories.find(
            category => category.id === currentCategoryId
        )
    )
    switch (action.type) {
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_REGISTER_RESOURCE_ID: { 
        return  {
            resourceId: action.resourceId,
            categories: []
        } 
    }
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_REGISTER_CATEGORY: {
        return {
            ...state,
            categories: hasCurrentDocumentsByCategory(action.categoryId)
                ? state.categories
                : [
                    ...state.categories,
                    {
                        id: action.categoryId,
                        documents: [],
                        loaded: false,
                        loading: false
                    }
                ]
        } 
    }    
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_UPDATE_DOCUMENT: {
        return  {
            ...state,
            categories: state.categories.map(
                category => category.id === action.categoryId 
                    ? {
                        ...category,
                        documents: category.documents.map(
                            document => document.id === action.documentId
                                ? {
                                    ...document,
                                    ...action.document
                                }
                                : document
                        )
                    }
                    : category
            ) 
        }    
    }     
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_LOAD_DOCUMENTS: {
        return {
            ...state,
            categories: state.categories.map(
                category => category.id === action.categoryId 
                    ? {
                        ...category,
                        loading: true
                    }
                    : category
            ) 
        }           
    }          
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_POPULATE_DOCUMENTS: {
        return {
            ...state,
            categories: state.categories.map(
                category => {    
                    return category.id === action.categoryId 
                        ? {
                            ...category,
                            loading: false,
                            loaded: true,
                            documents: action.documents,
                            total: action.total
                        }
                        : category
                }              
            ) 
        }
    }
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_ADD_DOCUMENTS: {
        return {
            ...state,
            categories: state.categories.map(
                category => {
                    return category.id === action.categoryId 
                        ? {
                            ...category,
                            loading: false,
                            loaded: true,
                            documents: [
                                ...category.documents,
                                ...action.documents
                            ]
                        }
                        : category
                }              
            ) 
        }     
    }
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_REMOVE_DOCUMENT: {
        return {
            ...state,
            categories: state.categories.map(
                category => category.id === action.categoryId 
                    ? {
                        ...category,
                        documents: category.documents.filter(
                            d => d.id !== action.documentId 
                        ),
                        total: category.total - 1,
                        loaded: true
                    }
                    : category
            )  
        }       
    }  
    case ACTIONS.DOCUMENTS_BY_CATEGORIES_RESET_DOCUMENTS: {
        return {
            ...state,
            categories: state.categories.map(
                documentsByCategory => ({
                    ...documentsByCategory,
                    documents: [],
                    total: 0,
                    loaded: false,
                    loading: false
                })
            )
        }
    }

    default:
        return state
    }
}