import { connect } from 'react-redux'
import {MyTasksOverviewTableComponent} from './component'

const mapStateToProps = state => {
    return {
        currentUserId: state.auth.user.id
    }
}

export const MyTasksOverviewTable = connect(
    mapStateToProps
)(MyTasksOverviewTableComponent)