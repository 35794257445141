import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStatefulForm } from 'application/components/hooks'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { validationMethods } from 'application/common'
import { useGravatar } from 'application/components/hooks/use_gravatar'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput } from 'application/components/controls/form'
import { PhoneNumberInput } from 'application/components/controls/form/phone_number_input'
import { PartialForms } from 'application/components/forms'
import { TwoColumns } from 'application/components/forms/_common'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'

export const CommissionExpertStep2 = (
    { 
        goToNextStep,
        goToPreviousStep,
        setStep2data,
        damage,
        step2data
    }
) => {
    const { t } = useTranslation()
    
    const {
        mustBeEmail,
        notEmpty,
        mustBeGermanPostalCode
    } = validationMethods

    const {
        setFirstName,
        setLastName,
        setCompanyName,
        getInitials,
        getFullName 
    } = useGravatar(
        step2data.firstName,
        step2data.lastName,
        step2data.isCompany,
        step2data.companyName
    )

    const defaultValues = {
        isEntitledToTaxDeduction: false,
        firstName: '',
        lastName: '',
        address: {
            line1: '',
            postalCode: '',
            locality: '',
            ...step2data.address
        },
        email: '',
        iban: '',
        externalReferenceId: '',
        mobilePhoneNumber: '',
        isCompany: false,
        ...step2data
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue, 
        control, 
        updateFormState
    } = useStatefulForm({defaultValues})
  

    const onSubmitSuccess = () => {
        setStep2data(getValues())
        goToNextStep()
    }

    const handleGoToPreviousStep = () => {
        setStep2data(getValues())
        goToPreviousStep()
    }

    return <React.Fragment>
        <DialogMainContent flexDirection='row'>
         
            <GravatarComponent
                getInitials = {getInitials}
                getFullName = {getFullName}
                cssWidth='40%' 
            />

            <FlexBox 
                flexBasis='60%' 
                flexDirection='column'
            >
                {
                    !step2data.isCompany 
                        ? <React.Fragment>
                            <TextInput
                                label={t('Firstname')}
                                name='firstName'
                                register={register}
                                validate={{notEmpty}}
                                error={errors.firstName}
                                onChange={(e) => {
                                    updateFormState()
                                    setFirstName(e.target.value)
                                }}
                            />
                            <TextInput
                                label={t('Lastname')}
                                name='lastName'
                                register={register}
                                validate={{notEmpty}}
                                error={errors.lastName}
                                onChange={(e) => {
                                    updateFormState()
                                    setLastName(e.target.value)
                                }}
                            />
                        </React.Fragment>
                        : 
                        <TextInput
                            label={ t('Company name')}
                            name='companyName'
                            register={register}
                            validate={{notEmpty}}
                            error={errors.companyName}
                            onChange={(e) => {
                                updateFormState()
                                setCompanyName(e.target.value)
                            }}
                        />
                }
                        
                <PartialForms.AddressDataPartialForm
                    register={register}
                    onChange={updateFormState}
                    validate={{notEmpty}}
                    validatePostalCode={{notEmpty, mustBeGermanPostalCode}}
                    errorNameStreet={errors.address?.line1}
                    errorNamePostalCode={errors.address?.postalCode}
                    errorNameCity={errors.address?.locality}
                    setValue={setValue}
                />

                <TextInput
                    label={t('Email')}
                    name='email'
                    register={register}
                    validate={{mustBeEmail}}
                    error={errors.email}
                    onChange={updateFormState}
                />
                <PhoneNumberInput
                    defaultValue={step2data.mobilePhoneNumber}
                    validate={{notEmpty}}
                    control={control}
                    label={t('Mobile phone number')}
                    name='mobilePhoneNumber'
                    register={register}
                    error={errors.mobilePhoneNumber}
                    onChange={updateFormState}
                    setValue={setValue}
                />
                <TextInput
                    label={t('IBAN')}
                    name='iban'
                    register={register}
                    validate={{mustBeIBAN: validationMethods.mustBeIBAN}}
                    error={errors.iban}
                    onChange={updateFormState}
                />
                <TwoColumns
                    columnLeft={
                        <TextInput
                            label={t('Customer number')}
                            name='externalReferenceId'
                            register={register}
                            validate={null}
                            error={errors.externalReferenceId}
                            onChange={updateFormState}
                        />
                    }
                    columnRight={
                        <YesNoSelect
                            register={register}
                            setValue={setValue}
                            initialValue={defaultValues.isEntitledToTaxDeduction}
                            name='isEntitledToTaxDeduction'
                            label={t('Is entitled to tax deduction')}
                            onChange={updateFormState}
                            menuPlacement='top'
                        />
                    }
                />

            </FlexBox>

        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={handleGoToPreviousStep} 
            />
            <ActionButton 
                buttonText={t('Next')} 
                onButtonClick={
                    handleSubmit(onSubmitSuccess)
                } 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}