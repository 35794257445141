
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'
import { useTranslation } from 'react-i18next'

export const LawfirmCommunicationAcknowledgementUpdated = (
    {
        activity
    }
) => {
    const {t} = useTranslation()

    const {
        lawFirm,
        damage,
        newAcknowledgement
    } = activity.data

    return <ActivityDetailWrapper>
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetail label='Lawfirm' value={lawFirm?.name ?? 'Unknown'} />
        <ActivityDetail label='Status' value={t(newAcknowledgement?.commissionStatus ?? 'Unknown')} />
    </ActivityDetailWrapper>
}

LawfirmCommunicationAcknowledgementUpdated.propTypes = {
    activity: PropTypes.object.isRequired
}