import { ACTIONS } from 'application/constants'

export const addRole = (roleName, roleId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_ADD_ROLE,
    roleName,
    roleId
})

export const removeRole = (roleId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_REMOVE_ROLE,
    roleId
})

export const removeGroup = (groupId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP,
    groupId
})

export const removeBranch = (branchId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_REMOVE_BRANCH,
    branchId
})


export const addGroupRoleRelationship = (groupId, roleId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_ADD_GROUP_ROLE_RELATIONSHIP,
    groupId, 
    roleId
})

export const addGroupBranchRelationship = (groupId, branchId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_ADD_GROUP_BRANCH_RELATIONSHIP,
    groupId, 
    branchId
})

export const removeGroupRoleRelationship = (groupId, roleId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP_ROLE_RELATIONSHIP,
    groupId, 
    roleId
})

export const removeGroupBranchRelationship = (groupId, branchId) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP_BRANCH_RELATIONSHIP,
    groupId, 
    branchId
})

export const toggleRoleSidebar = (
    isVisible, 
    roleId = null
) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_TOGGLE_ROLE_SIDEBAR,
    isVisible, 
    roleId
})

export const toggleGroupSidebar = (
    isVisible, 
    groupId = null
) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_TOGGLE_GROUP_SIDEBAR,
    isVisible, 
    groupId
})

export const toggleBranchSidebar = (
    isVisible, 
    branchId = null
) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_TOGGLE_BRANCH_SIDEBAR,
    isVisible, 
    branchId
})

export const closeAllSidebars = () => ({
    type: ACTIONS.BRANCH_MANAGEMENT_CLOSE_ALL_SIDEBARS
})

export const toggleHelpSidebar = (isVisible) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_TOGGLE_HELP_SIDEBAR,
    isVisible
})

export const updateGroup = (
    groupId, 
    payload
) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_UPDATE_GROUP,
    groupId, 
    payload
})

//group members
export const getGroupMembersRequest = (      
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_GET_GROUP_MEMBERS_REQUEST,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})

export const populateGroupMembers = (
    groupId, 
    groupMembersList, 
    groupMembersTotal
) => ({
    type: ACTIONS.BRANCH_MANAGEMENT_POPULATE_GROUP_MEMBERS,
    groupId, 
    groupMembersList, 
    groupMembersTotal
})