import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetTaskVehicleDataSaga(action){
    const { damageId } = action.match.params
    const response = yield resourceCall(
        api.gateway.damage.getVehicleByDamageId, 
        {
            damageId: damageId
        }
    )
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {formData: response, damageId: damageId}))
}