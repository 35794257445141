import React from 'react'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { StyledIconContainer, StyledIndicator,StyledCounter } from './fragments'

const RealtimeNotificationsIndicator = ({onShowSidebar, hasNewNotifications, notifications, theme}) => {
    return <StyledIndicator onClick={onShowSidebar}>
        {
            hasNewNotifications && <StyledCounter>{notifications.length}</StyledCounter>
        }
        <StyledIconContainer hasNewNotifications={hasNewNotifications}>
            <FontIcon 
                icon={hasNewNotifications ? 'notifications_active' : 'notifications'}
                color={hasNewNotifications ? theme.color.primary : theme.color.anthracite50}
                isClickable={true}
            />
        </StyledIconContainer>
    </StyledIndicator>
}

export const RealtimeNotificationsIndicatorComponent = withTheme(RealtimeNotificationsIndicator)