import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { put, all } from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetWorkflowPanelReceiptConfirmationSaga(action) {
    
    const [
        generalCommunicationResponse, 
        customerResponse
    ] = yield all([
        resourceCall(
            api.gateway.lawyerCommunication.getLawfirm
        ),
        resourceCall(
            api.gateway.damage.getVehicleKeeperByDamageId
        )
    ])
    
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {
        formData: {
            generalCommunication: generalCommunicationResponse
        },
        customer: customerResponse
    }
    ))
}