import { combineReducers } from 'redux'

// import commonData from './common_data'
import {tasksReducer} from './tasks'
import {sharedReducer} from './shared'
import {userDataReducer} from './user_data'
import {activityLogReducer} from './activity_log'
import {documentCenterReducer} from './document_center'
import {inboxReducer} from './inbox'

export const workflowPanelReducer =  combineReducers({
    // commonData,
    tasks: tasksReducer,
    shared: sharedReducer,
    userData: userDataReducer,
    activityLog: activityLogReducer,
    documentCenter: documentCenterReducer,
    inbox: inboxReducer,
})