import {
    StringContainsFilter, StaticFilter,
    OrOperator,
    StringEqualsFilter,
    AndOperator,
    StartClosure,
    EndClosure,
} from '@ec/filter-sort-page-query-js'

export const users = {
    groupId: {
        filterName: 'users_groupId',
        filterKey: 'groupId',
        label: 'group Id',
        defaultValue: null,
        getFilterQuery: value => ([
            new StaticFilter(`groups[id == "${value}"]`)
        ])
    },
    name: {
        filterName: 'users_name',
        filterKey: 'auun',  //au = all users  un = User_Name
        label: 'Name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('lastName', value, false),
            new OrOperator(),
            new StringContainsFilter('email', value, false)
        ])
    },
    email: {
        filterName: 'users_email',
        filterKey: 'email',
        label: 'Email',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('email', value, false)
        ])
    },
    lastName: {
        filterName: 'users_last_name',
        filterKey: 'lastName',
        label: 'Lastname',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('lastName', value, false)
        ])
    },
    getUsersInAdminGroups: {
        filterName: 'users_adminUsers',
        filterKey: 'uiag',
        label: 'Admin users',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StaticFilter('groups[roles[id == 101]]')
        ])
    },
    getUsersForSpecificBranch:{
        filterName: 'users_branchUsers',
        filterKey: 'uisb',
        label: 'Branch users',
        defaultValue: null,
        isStatic: false,
        getFilterQuery: (value) => ([
            new StaticFilter(`groups[branches[id == ${value}] or CanAccessAllBranches]`)
        ])
    },
    experts: {
        filterName: 'users_experts',
        filterKey: 'aue',
        label: 'Experts',
        defaultValue: null,
        isStatic: false,
        getFilterQuery: (value) => ([
            new StringEqualsFilter('company', 'Gutachter', false),
            new AndOperator(),
            new StartClosure(),
            new StringContainsFilter('firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('lastName', value, false),
            new OrOperator(),
            new StringContainsFilter('email', value, false),
            new EndClosure()
        ])
    }
}
