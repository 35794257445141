import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import {useGravatar} from 'application/components/hooks/use_gravatar'
import { exists, notNull } from 'application/common/data_helpers'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { DIALOG, INFOBOX } from 'application/constants'
import { useCustomerDataPlausibility, useStatefulForm } from 'application/components/hooks'
import { CustomerDataPartialForm } from 'application/components/forms/_partial_forms/customer_data_partial_form'
import { emptyCustomer } from 'application/common/empty_customer'

const EditCustomer = ( 
    { 
        abort, 
        setAbortBlocked,
        setDataChanged, 
        onEditCustomer, 
        dialog,
    }
) => {
    const { t } = useTranslation()

    const {
        customer = null
    } = dialog.payload

    const customerData = notNull(customer) 
        ? customer
        : emptyCustomer

    const defaultValues = {
        ...emptyCustomer,
        ...customerData
    }  
    const formHook = useStatefulForm({defaultValues})

    const { 
        handleSubmit, 
        getValues, 
        formStateValues,
        updateFormState
    } = formHook



    const {
        setFirstName,
        setLastName,
        setCompanyName,
        getInitials,
        getFullName 
    } = useGravatar(
        customerData.firstName,
        customerData.lastName,
        formStateValues.isCompany,
        customerData.companyName
    )

    const onDataChange = () => {
        updateFormState()
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const onSubmitSuccess = () => {
        const legalInsuranceCompany = getValues().legalProtectionInsurance.company
        const hasLegalInsurance = exists(legalInsuranceCompany?.id) || exists(legalInsuranceCompany?.name) 

        const mapPostToPutPayload = () => {
            const customerData = getValues()
            delete customerData.legalProtectionInsurance
            return hasLegalInsurance 
                ?  {
                    customer: customerData,
                    legalProtectionInsurance: getValues().legalProtectionInsurance
                }
                : {
                    customer: customerData
                } 
        }

        const payloadToSend = dialog.name === DIALOG.NAME.CREATE_CUSTOMER
            ? { 
                customer:getValues() 
            }
            : mapPostToPutPayload()

        const metaData = {
            customerId: customerData.customerId,
            isCompany: getValues().isCompany,
            damageId: dialog.payload?.damageId || null,
            fromDialogName: dialog.payload?.fromDialogName || null,
            successMsg: getFullName() + ' ' + t('has been saved'),
            deleteLegalProtectionInsurance: exists(customer?.legalProtectionInsurance) && !hasLegalInsurance
        }

        onEditCustomer(
            dialog.name, 
            payloadToSend, 
            metaData
        )
    }

    const {
        infoBox = null,
        recommendContactData = false
    } = dialog.payload

    const { 
        customerDataIsPlausible 
    } = useCustomerDataPlausibility(formStateValues)

    const showRecommendContactDataInfoBox = recommendContactData && !customerDataIsPlausible

    return <React.Fragment>
        <DialogMainContent flexDirection='row'>
            <GravatarComponent
                getInitials = {getInitials}
                getFullName = {getFullName}
                cssWidth='40%' 
            >
                {                    
                    notNull(infoBox) && <FlexBox 
                        paddingLeft='16px'
                        paddingRight='32px'
                        paddingTop='16px'
                    >
                        <InfoBox
                            type={infoBox.type} 
                            direction='row' 
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <span>
                                    {t(infoBox.text)}
                                </span>
                            </FlexBox>
                        </InfoBox>
                    </FlexBox>
                }

                {                    
                    showRecommendContactDataInfoBox && <FlexBox 
                        paddingLeft='16px'
                        paddingRight='32px'
                        paddingTop='16px'
                    >
                        <InfoBox
                            type={INFOBOX.TYPES.WARNING} 
                            direction='row' 
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <span>
                                    {t('Please provide at least email or phone number.')}
                                </span>
                            </FlexBox>
                        </InfoBox>
                    </FlexBox>
                }
            </GravatarComponent>
            <FlexBox 
                flexBasis='60%' 
                flexDirection='column'
            >
                <CustomerDataPartialForm 
                    formHook={formHook}
                    onDataChange={onDataChange}
                    onFirstNameChange={setFirstName}
                    onLastNameChange={setLastName}
                    onCompanyNameChange={setCompanyName}
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save'
        />
    </React.Fragment>
}


export const EditCustomerComponent = withDialogDataLoader(
    EditCustomer
) 