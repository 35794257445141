import React from 'react'
import { useMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { matchRoute, matchRoutes } from 'application/common/route_helpers'


export const HookRoute = (
    {
        path,
        children,
        all = false
    }
) => {
    const displayChildren = all 
        ? matchRoutes(path, useMatch)
        : matchRoute(path, useMatch)

    return <React.Fragment>
        { displayChildren  && children }  
    </React.Fragment>
}


HookRoute.propTypes = {
    path: PropTypes.string.isRequired,
    children: PropTypes.oneOfType(
        [
            PropTypes.array, 
            PropTypes.node
        ]
    ).isRequired,
    all: PropTypes.bool
}