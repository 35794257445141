import { connect } from 'react-redux'
import {DamageManagerDocumentsEffects} from './effects'
import { hideAttachmentDetails, showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'

const mapStateToProps = (state) => {
    return {
        documents: state.pages.damageManager.documentCenter.documents,
        categories: state.common.categories,
        sidebarDamageId: state.pages.damageManager.documentCenter.attachmentDetails.damageId,
        documentCenterAttachmentDetails: state.pages.damageManager.documentCenter.attachmentDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {        
        onAttachmentClick: (
            attachment, 
            damageId
        ) =>{           
            dispatch(
                showAttachmentDetails(
                    attachment, 
                    damageId
                )
            )
        },
        onHideAttachmentDetails: () => dispatch(
            hideAttachmentDetails()
        )
    }
}

export const DamageManagerDocuments = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDocumentsEffects)