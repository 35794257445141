import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall, buildListQuery } from 'application/api/helpers'


export const getActivities = (params) => {

    return {
        authenticated: () => {

            const {
                offset = 0, 
                limit = 10, 
                orderBy = 'occurredAt',
                direction = 'desc',
                filterQuery = []
            } = params
        
            const listQuery = buildListQuery(
                offset, 
                limit, 
                orderBy, 
                direction, 
                filterQuery
            )

            const request = fetchRequestBuilder( 
                `activities${listQuery}`,
                HTTP_METHOD.GET
            )

            return apiCall(request)
        },
        sharedResource: () => {
            const {
                offset = 0, 
                limit = 10, 
                orderBy = 'occurredAt',
                direction = 'desc',
                filterQuery = []
            } = params
        
            const listQuery = buildListQuery(
                offset, 
                limit, 
                orderBy, 
                direction, 
                filterQuery
            )

            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/activities${listQuery}`,
                params.pin,
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
}