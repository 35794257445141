import { connect } from 'react-redux'

import {DamageManagerOverviewEffects} from './effects'
import { WIDGET_CONTEXTS } from 'application/constants'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { resetWidgetLayout } from 'application/redux/actions/widget_contexts'
import { setStaticMode } from 'application/redux/actions/widget_contexts'

const mapStateToProps = state => {
    return {
        isStatic: state.widgetContexts.contexts.filter(c => c.key === WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW).length > 0 
            ? state.widgetContexts.contexts.find(c => c.key === WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW).boardConfiguration.isStatic 
            : false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetStaticMode: (contextKey, isStatic) => dispatch(setStaticMode(contextKey, isStatic)),
        onResetWidgetBoard: (contextKey) => {
            dispatch(resetWidgetLayout(contextKey))
        },
        onRefreshWidgetContext: (context) => {
            dispatch(refreshWidgetsByContext(context))
        }
    }
}

export const DamageManagerOverview = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerOverviewEffects)