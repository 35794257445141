import { PureSelect, Select } from 'application/components/controls/form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'


const BranchSelectComponent = ({
    register,
    setValue,
    name='branchId',
    menuPlacement='bottom',
    branches = [],
    onChange = () => {},
    initialBranchId = null,
    label = 'Branch',
    disabled = false,
    ignoreBranchIds = []
}) => {
    const {t} = useTranslation()
    
    const options = branches
        .filter(b => !ignoreBranchIds.includes(b.id))
        .map(
            b => ({
                value: b.id,
                label: b.name
            })
        )

    const initialOption = initialBranchId === null 
        ? options[0] 
        : options.find(o => o.value === initialBranchId)
    
    return <Select 
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={options}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        disabled={disabled}
    />
}

BranchSelectComponent.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    name: PropTypes.string,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
    branches: PropTypes.array,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    initialBranchId: PropTypes.string
}

const PureBranchSelectComponent = ({
    branches,
    ignoreBranchIds = [],
    initialBranchId = null,
    label = 'Branch',
    disabled = false,
    menuPlacement='bottom',
    onChange = () => {},
    isClearable = true,
    placeholder = 'Select branch'
}) => {
    const {t} = useTranslation()
    
    const options = branches
        .filter(b => !ignoreBranchIds.includes(b.id))
        .map(
            b => ({
                value: b.id,
                label: b.name,
                branch: b
            })
        )

    const initialOption = initialBranchId === null 
        ? isClearable 
            ? null 
            :options[0] 
        : options.find(o => o.value === initialBranchId)

    return <PureSelect 
        options={options}
        onChange={onChange}
        label={label}
        disabled={disabled}
        menuPlacement={menuPlacement}
        value={initialOption}
        isClearable={isClearable}
        placeholder={t(placeholder)}
    />
}

export { BranchSelectComponent, PureBranchSelectComponent }

