import { FlexBox } from 'application/components/fragments/flex_box'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PureSelect } from '../pure_component'
import PropTypes from 'prop-types'


const StyledLabel = styled.label`
    padding-left: 0;
    margin-bottom: 4px;
    font-size: ${props => props.theme.fontSize.small};
`

const PureSelectWithLabel = (
    {
        options = [],
        defaultValue = null,
        setValue = () => {},
        isClearable = false,
        untranslatedLabel = '',
        menuPlacement = 'bottom'
    }
) => {
    const { t } = useTranslation()

    const defaultOption = options.find(
        option => option.value === defaultValue
    ) || null

    const [
        currentOption,
        setCurrentOption
    ] = useState(defaultOption)

    const handleOnChange = (option) => {
        setCurrentOption(option)
        setValue(option.value)
    }

    return  <FlexBox
        height='75px'
        flexDirection='column'
    >
        <StyledLabel fontSize='12px'>
            {t(untranslatedLabel)}
        </StyledLabel>
        <PureSelect
            options={options}
            value={currentOption}
            onChange={handleOnChange}
            isClearable={isClearable}
            menuPlacement={menuPlacement}
        />
    </FlexBox>
}

PureSelectWithLabel.propTypes = {
    options: PropTypes.array,
    defaultOption: PropTypes.object,
    setValue: PropTypes.func,
    isClearable: PropTypes.bool,
    untranslatedLabel: PropTypes.string,
}

export {PureSelectWithLabel}