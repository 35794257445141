import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'

import { WIDGET_CONTEXTS } from 'application/constants'
import {DamageManagerOverviewComponent} from './component'

const DamageManagerOverviewEffectsComponent = (props) => {
    const { onRefreshWidgetContext, match } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW)
    }, [onRefreshWidgetContext, damageId])

    return <DamageManagerOverviewComponent {...props} />
}

DamageManagerOverviewEffectsComponent.propTypes = {
    onRefreshWidgetContext: PropTypes.func.isRequired,
}

export const DamageManagerOverviewEffects = withRouter(DamageManagerOverviewEffectsComponent)