import React from 'react'
import { exists } from 'application/common/data_helpers'
import { Paragraph } from 'application/components/fragments/typography'
import { StyledError } from './fragments'

export const ClientError = ({payload}) => {

    if(!exists(payload.stack)){
        return null
    }

    return <React.Fragment>
        <Paragraph cssMargin='0 0 24px'>Type: Client Error</Paragraph>
        <StyledError>
            {
                exists(payload.message) && <React.Fragment>
                    <Paragraph>[message]</Paragraph> 
                    {payload.message}
                </React.Fragment>
            }
            <Paragraph cssMargin='8px 0'>[stack]</Paragraph> 
            {payload.stack}
        </StyledError>
    </React.Fragment>
}