import { ACTIONS } from 'application/modules/dynamic-form/constants/index'

export const updateFormEditorDescription = (formEditorDescription) => ({
    type: ACTIONS.UPDATE_FORM_EDITOR_DESCRIPTION,
    formEditorDescription,
})

export const updateFormRendererDescription = (formRendererDescription) => ({
    type: ACTIONS.UPDATE_FORM_RENDERER_DESCRIPTION,
    formRendererDescription,
})

export const submitDynamicFormEditorDescription = (data) => ({
    type: ACTIONS.SUBMIT_DYNAMIC_FORM_EDITOR_DESCRIPTION,
    data,
})

export const submitDynamicFormData = (data) => ({
    type: ACTIONS.SUBMIT_DYNAMIC_FORM_DATA,
    data,
})