import React, {useEffect, useState} from 'react'
import { CurrencyInput } from '../currency_input'
import { HiddenField } from '../hidden/component'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PureSelect } from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import { exists } from 'application/common/data_helpers'
import { CURRENCY_INPUT } from 'application/constants/currency'



const StyledLabel = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    margin-bottom: 4px;
`


export const DeductibleInput =  (
    { 
        label = 'Is decuctible',
        name = 'deductable', 
        setValue = () => {},
        register, 
        error, 
        initialValue = CURRENCY_INPUT.ZERO_VALUE_STRING.FLOAT
    } 
) => {
    const {t} = useTranslation()

    const deductibleOptions = [
        {
            label: t('Yes'),
            value: true
        },
        {
            label: t('No'),
            value: false
        }
    ]

    const [textInputVisible, setTextInputVisible] = useState( 
        exists(initialValue) 
        && initialValue !== CURRENCY_INPUT.ZERO_VALUE_STRING.FLOAT 
    )

    const defaultOption = textInputVisible
        ? deductibleOptions[0]
        : deductibleOptions[1]

    const [isDeductibleOption, setIsDeductibleOption] = useState( 
        defaultOption
    )

    const handleSelectChange = (option) => {
        setIsDeductibleOption(option)
        setTextInputVisible(option.value)
    }
    
    useEffect(()=>{
        if(!textInputVisible){
            setValue(name, CURRENCY_INPUT.ZERO_VALUE_STRING.FLOAT)
        }
    },[
        name, setValue, textInputVisible]
    )


    return <React.Fragment>
        <TwoColumns
            columnLeft={
                <FlexBox 
                    height='72px' 
                    flexDirection='column'
                >
                    <StyledLabel>
                        {t(label)}
                    </StyledLabel>
                    <PureSelect
                        label={'Is deductible?'}
                        options={deductibleOptions}
                        value={isDeductibleOption}
                        onChange={handleSelectChange}
                        isClearable={false}
                    />
                </FlexBox>
            }
            columnRight={
                textInputVisible 
                    ? 
                    <CurrencyInput 
                        name={name} 
                        register={register} 
                        error={error} 
                        label={t('Amount')}
                    />
                    : <HiddenField 
                        name={name} 
                        register={register} 
                    />
            }
        />
    </React.Fragment>
}
