import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    DAMAGE_MANAGER_INVOICES_LIST: 'DAMAGE_MANAGER_INVOICES_LIST_INSTANCE',
    DAMAGE_MANAGER_CREATE_INVOICE: 'DAMAGE_MANAGER_CREATE_INVOICE_INSTANCE'
}

export const widgetsDamageManagerInvoices = [
    {
        key: KEYS.DAMAGE_MANAGER_CREATE_INVOICE,
        type: WIDGET_TYPES.DAMAGE_MANAGER_CREATE_INVOICE,
        title: '',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_INVOICES_LIST,
        type: WIDGET_TYPES.DAMAGE_MANAGER_INVOICES_LIST,
        title: '',
        hideHeadline: true,
        accountDependent: true
    } 
]