import React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'application/components/fragments/typography'

const JobNumberChangedInfo = (
    {activityData}
) => {
    const past = activityData.oldNumber
    const current = activityData.newNumber

    return <React.Fragment>
        {past}<RightArrow />{current}
    </React.Fragment>
}

JobNumberChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {JobNumberChangedInfo} 