import { put } from 'redux-saga/effects'
import Moment from 'moment'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { routeHelpers } from 'application/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { common, controlling, ListFilter } from 'application/filters'
import { composeFilterQuery } from 'application/common/filter_helpers'

export function* widgetBranchSpecificControllingStatisticsDamagesCountSaga(action){
    const { branchId } = action.match.params
    const today = new Date()
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)

    const startDate = routeHelpers.getUrlParamValueDate(action.payload.routeParameters.START_DATE, firstOfMonth)
    const endDate = routeHelpers.getUrlParamValueDate(action.payload.routeParameters.END_DATE, today)

    const paramFormat = 'YYYY.MM.DD'
    const startDateParam = new Moment(startDate).format(paramFormat)
    const endDateParam = new Moment(endDate).format(paramFormat)

    const filterQuery = composeFilterQuery([
        new ListFilter(controlling.damageCreationCount).setValue({ startDate: startDateParam, endDate: endDateParam}),
        new ListFilter(common.branchId).setValue(branchId)
    ])

    const serverResponse = yield resourceCall(
        api.gateway.controlling.getDamagesStatistics, 
        {
            filterQuery
        }
    )

    const chartData = {
        all:  serverResponse.gesamtDamageslist,
        liability: serverResponse.haftpflichtFaellelist,
        casco: serverResponse.kaskoFaellelist,
        labels: serverResponse.damageDatumList
    }

    yield put(updateWidget(action.key, action.dependentWidgetKeys, {chartData}))
}