import styled from 'styled-components'

export const HeadlineSidebar = styled.h3`
  font-size: ${props => props.theme.fontSize.headline2};
  font-weight: ${props => props.theme.fontWeight.headline2};
  color: ${props => props.cssColor ? props.cssColor : props.theme.color.anthracite};
  margin: 0;
  flex: 0 0 48px;
`

export const ContentSidebar = styled.div`
  flex: 1 1 auto;
  margin: 0;
`

export const FooterSidebar = styled.div`
  display: flex;
  flex: 0 0 34px;
  justify-content: space-between;
`