import React from 'react'
import {ROLE} from 'application/constants'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'





const getRoleTranslationString = (roleName) => {
    switch (roleName) {
    case ROLE.NAME.ADMIN:
        return 'Admin rights'
    case ROLE.NAME.USER:
        return 'User rights'
    case ROLE.NAME.DAMAGE_MANAGEMENT_PERMISSIONS:
        return 'Damage Management permissions'
    case ROLE.NAME.CONTROLLING_PERMISSIONS:
        return  'Controlling permissions'
    case ROLE.NAME.COMMISSION_PERMISSIONS:
        return 'Comission permissions'
    case ROLE.NAME.DELETION_PERMISSIONS:
        return 'Deletion permissions'
    default:
        return ''
    }
}



const RoleNameMapperComponent = (
    {
        roleName
    }
) => {   
    const {t} = useTranslation()

    return <React.Fragment>
        {
            t( getRoleTranslationString(roleName) )
        }
    </React.Fragment>
}

RoleNameMapperComponent.propTypes = {
    roleName: PropTypes.string.isRequired
}


export { RoleNameMapperComponent }