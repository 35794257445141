
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'

const DamageDeletedDetails = (
    {
        activity
    }
) => {
    const {
        jobNumber,
        licensePlate,
        type
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={licensePlate} />
        <ActivityDetail label='Jobnumber' value={jobNumber} />
        <ActivityDetail label='Type' value={type} />
    </ActivityDetailWrapper>
}

DamageDeletedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}

export {DamageDeletedDetails}