import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { moClearAllItemsByName } from 'application/redux/actions/mass_operations'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogMoDamagesDeleteSaga(action) {

    const { selectedItems } = action.payload

    const { 
        moName
    } = action.metaData  

    try{   
        yield put(
            showWaitingScreen('Damages would be deleted...')
        )
    
        for(const damage of selectedItems){
            yield resourceCall(
                api.gateway.damage.deleteDamageById, 
                {
                    damageId: damage.id
                }
            )
        }
    }catch(e){
        console.error(e)
    }
    yield put(
        moClearAllItemsByName(moName)
    )

    yield put( 
        showToast('Damages are deleted') 
    )
    yield put( 
        hideWaitingScreen() 
    )

}