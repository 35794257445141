import React from 'react'
import { WIDGET_CONTEXTS } from 'application/constants'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import { useTranslation } from 'react-i18next'


export const InboxComponent = () => {
    const {t} = useTranslation()
    return <LayoutCurrentBranch
        titleSegments={['Inbox']}
        headline={t('Inbox')}
    >
        <WidgetArea 
            widgetContext={WIDGET_CONTEXTS.BRANCH_SPECIFIC_INBOX} 
            direction='row' 
        />
    </LayoutCurrentBranch>
}