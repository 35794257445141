import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IndicatorWithColor } from 'application/components/controls/indicator/override_color_indicator'
import { useTranslation } from 'react-i18next'
import { LocalTime } from 'application/components/controls/local_time'
import {DateComponent} from 'application/components/controls/date'

const CommentWrapper = styled.div`
    background-color: ${props=>props.color !== null ? props.color : 'transparent'} ;
    padding: 8px 16px 8px 8px;
    &>div{
        border-bottom: 1px solid ${props => props.theme.color.gray20};
    }
    &:last-child>div{
        border-bottom: 0;
    }
`

const CommentWrapperInner = styled.div`
    padding-bottom: 8px;
    display: flex;
    flex-flow: column nowrap;
    font-size: ${props => props.theme.fontSize.small};
`

const BarInformation = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: ${props => props.withIcon ? '4px' : '8px'};
`

const NeedSpace = styled.div`
    margin-right: ${props => props.noNeedSpace ? '0px' : '8px'};
    display:flex;
    align-items: center;
`

const BarComment = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    line-height: ${props => props.theme.fontSize.medium18};
    word-break: break-all;
    white-space: pre-line;
`

const FontStyleInformation = styled.span`
    color: ${props => props.theme.color.anthracite};
    font-weight: ${props => props.isName ? props.theme.fontWeight.standardBold : props.theme.fontWeight.medium18};
`

export const CommentBox = ({iconVisible, color=null, creatorName, creationDate, text}) => {
    const {t} = useTranslation()
    return <CommentWrapper color={color}>
        <CommentWrapperInner>
            <BarInformation withIcon={iconVisible}>
                {iconVisible && <NeedSpace noNeedSpace={true}>
                    <IndicatorWithColor overrideColorWith='gray20' iconSize='16px' iconKey='chat_bubble_outline' />
                </NeedSpace>}
                <NeedSpace>
                    <FontStyleInformation isName={true}>
                        {creatorName}
                    </FontStyleInformation>
                </NeedSpace>

            </BarInformation>
            <BarInformation>
                <NeedSpace noNeedSpace={true}>
                    <FontStyleInformation isName={false}>
                        <DateComponent date={creationDate} />&nbsp;{t('at')}&nbsp;
                        <LocalTime date={creationDate} />
                    </FontStyleInformation>
                </NeedSpace>
            </BarInformation>
            <BarComment>
                {text}
            </BarComment>
        </CommentWrapperInner>
    </CommentWrapper>
}

CommentBox.propTypes = {
    iconVisible:  PropTypes.bool,
    assigneeFirstname: PropTypes.string,
    assigneeLastname: PropTypes.string,
    creationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    text: PropTypes.string
}



