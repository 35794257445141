import React from 'react'
import { useTranslation } from 'react-i18next'
import { WidgetInformation } from 'application/components/widgets/_common'
import { withTheme } from 'styled-components'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'





const TableContentComponent = (
    {   
        elementsLoading,
        elementsTotal,
        elementsLoaded,
        elements,
        configuration,
        emptyMessage,
        isSelectable = false,
        selectItem = () => {},
        deselectItem = () => {},
        selectedItems = [],
        theme,
        AppliedRowComponent,
        moIsActive = false,
        hasMassOperations = false
    }
) => {
    const {t} = useTranslation()
    const cssHeight = configuration.rowHeight || '44px'

    const applicationLinksHook = useApplicationLinks() 

    const checkIfItemIsSelected = (itemId, selectedItems) => {
        const itemFound =  selectedItems.find( sitem => sitem.id === itemId)
        return itemFound !== undefined
    }

    const indexIsEven = (i) => {
        return i % 2 === 0
    } 

    return <React.Fragment>
        {
            !elementsLoading 
            && elements.length > 0 
            && elements.map(
                (element, i) => {
                    const href= configuration?.rows?.linked?.additionalProps?.href(
                        element,
                        applicationLinksHook
                    ) || null

                    const isSelected = hasMassOperations || isSelectable
                        ? checkIfItemIsSelected(element.id, selectedItems) 
                        : false

                    const rowIsColoured = indexIsEven(i) 
                    const rowColor = rowIsColoured 
                        ?  theme.color.gray5
                        :  theme.color.white        
                        
                    return <AppliedRowComponent 
                        key={element.id}
                        element = {element}
                        selectItem= {selectItem}
                        deselectItem = {deselectItem}
                        isSelected={isSelected}
                        cssHeight={cssHeight}
                        configuration = {configuration}
                        isColoured = {rowIsColoured}
                        backgroundColor = {rowColor}
                        moIsActive={moIsActive}
                        href={href}
                    /> 
                }
            )
        }
        {
            elementsLoaded 
            && elementsTotal === 0 
            && <WidgetInformation>
                {t(emptyMessage)}
            </WidgetInformation>
        }
    </React.Fragment>
}

export const TableContent = withTheme(TableContentComponent)