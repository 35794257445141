import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetTaskCommentsSaga(action){
    
    const { 
        damageId, 
        taskId,
    } = action.match.params

    const {
        offset = 0, 
        limit = 6,
    } = action.payload

    const commentsResponse = yield resourceCall(
        api.gateway.comments.getCommentsByTask, 
        {
            damageId: damageId, 
            taskId,
            offset,
            limit
        }
    )
    
    yield put(
        updateWidget(
            action.key, 
            action.dependentWidgetKeys,
            {
                list: commentsResponse.comments,
                total: commentsResponse.total
            }
        )
    )
}