import { exists } from 'application/common/data_helpers'
import React from 'react'
import {MessageList} from '../base'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { Navigate } from 'react-router-dom'




const MessageListInternalComponent = (
    { 
        getMessageUrl, 
        baseUrlParams,
        messages, 
        selectedMessageId, 
        messagesTotal, 
        onPaginationChange,
        match
    }
) => {
    if(!messages){
        return null
    }
    
    if(messagesTotal > 0 && selectedMessageId === undefined){
        
        const firstMessage = messages[0]

        const damageId = exists(firstMessage.damage) //refactor this with optional chaining?
            ? firstMessage.damage.id
            : match.params.damageId
            
        if( 
            !exists(match.params.branchId) 
             || firstMessage.branch.id === match.params.branchId
        ){
            return <Navigate
                to={
                    getMessageUrl(
                        damageId,
                        firstMessage.id                       
                    )
                } 
            />
        }
    }

    const getParentRessourceId = (message) => {
        return exists(message.damage) 
            ? message.damage.id 
            : match.params.damageId
    }

    return <MessageList
        getMessageUrl = {getMessageUrl} 
        baseUrlParams = {baseUrlParams} 
        messages = {messages} 
        selectedMessageId = {selectedMessageId} 
        messagesTotal = {messagesTotal} 
        onPaginationChange = {onPaginationChange}
        getParentRessourceId = {getParentRessourceId}
    />
}

export const MessageListInternal = withRouter(
    MessageListInternalComponent
)