import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DatePicker } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import PropTypes from 'prop-types'
import { useStatefulForm } from 'application/components/hooks'



const DamageRepairStartedAtForm = ({
    damageId, 
    onSave,
    repairStartedAt = null,
    incidentDate = null,
    name = 'repairStartedAt'
}) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        [name]: repairStartedAt
    }

    const {
        errors,
        handleSubmit,
        getValues,
        control,
        register,
        formStateValues,
        setValue,
        trigger
    } = useStatefulForm({ defaultValues })


    const onSubmitSuccess = () => { 
        const metaData = {damageId}
        const payload = getValues()
        onSave(metaData, payload)
    }

    return <React.Fragment>
        <FlexBox>
            <DatePicker
                label={t('In Repair since')}
                name={name}
                validate={
                    { notEmpty: validationMethods.notEmpty }
                }
                error={errors[name]}
                // onDateChange={handleChange}
                control={control}
                disabledDays={
                    { before: new Date(incidentDate) }
                }
                register={register}
                defaultValue={formStateValues[name]}
                setValue={setValue}
                trigger={trigger}
            />
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </React.Fragment>
}

DamageRepairStartedAtForm.propTypes = {
    damageId: PropTypes.string.isRequired, 
    onSave: PropTypes.func.isRequired,
    repairStartedAt: PropTypes.string,
    incidentDate: PropTypes.string,
    name: PropTypes.string
}

export { DamageRepairStartedAtForm }