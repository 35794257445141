import { exists } from 'application/common/data_helpers'
import React, { useEffect } from 'react'
import {MassOperationPanel} from './component'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { useTranslation } from 'react-i18next'
import { useMO } from 'application/components/hooks'

const MassOperationEffects = (
    {  
        onRegisterMassOperation,
        moName,
        moType,
        ...rest 
    }
) => {
    const {
        moClearAllItems,
        moSetInactive,
        moSetActive,
        mo
    } = useMO(moName)

    const {t} = useTranslation()

    useEffect(
        () => {
            !exists(mo) && onRegisterMassOperation(
                moName,
                moType
            )
        }, [mo, moName, moType, onRegisterMassOperation])

    return exists(mo) 
        ?  <MassOperationPanel
            mo = {mo}
            moClearAllItems = {moClearAllItems}
            moSetInactive = {moSetInactive}
            moSetActive = {moSetActive}
            {...rest} 
        />
        : <ThemedPreloader 
            preloaderText={t('Selectionmode is loading...')} 
        /> 
   
}

export { MassOperationEffects }
