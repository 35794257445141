import React from 'react'
import PropTypes from 'prop-types'
import { AssigneeNameActivity } from 'application/components/controls/activity_assignee_name'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'

const AssigneeChangedInfo = (
    {activityData}
) => {

    return <FlexBox>
        <AssigneeNameActivity assignee={activityData.oldAssignee}/>
        <RightArrow />  
        <AssigneeNameActivity assignee={activityData.newAssignee}/>
    </FlexBox>
}


AssigneeChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {AssigneeChangedInfo} 