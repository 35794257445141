import styled from 'styled-components'
import { Handle } from 'react-flow-renderer'

export const CustomHandle = styled(Handle)`
    top: 50% !important;
    width: 10px;
    height: 10px;
    z-index: 1;
    background: ${props => props.$isDisabled ? '#ccc' : '#555'} !important;
`
