import { ACTIONS,SHARED_RESOURCE_TYPE } from 'application/constants'
import { call, takeLatest, select, all } from 'redux-saga/effects'
import { sharedResourceKickstartDataSaga, sharedResourceDefaultDataSaga, sharedResourceQrDataSaga } from './shared_resource_data_sagas'
import { getSharedResourceType } from 'application/redux/selectors'
import { requestAccountSettingsSaga } from '../ui_settings/account_settings_saga'

export const getSharedResourceDataSagas = (sharedResourceType) => {
    switch (sharedResourceType) {
    case SHARED_RESOURCE_TYPE.KICKSTART:
        return [sharedResourceKickstartDataSaga, requestAccountSettingsSaga]
    case SHARED_RESOURCE_TYPE.QR:
        return [sharedResourceQrDataSaga]
    default:
        return [sharedResourceDefaultDataSaga]
    }
}


function* fetchSharedResourceDataSaga(action) {
    try {
        const sharedResourceType = yield select(getSharedResourceType)

        const sagas = yield call(
            getSharedResourceDataSagas,
            sharedResourceType
        )
        yield all(
            sagas.map(
                saga => call(saga, action)
            )
        )
    } catch (error) {

    }
}

export function* fetchSharedResourceDataWatcher() {
    yield takeLatest(
        ACTIONS.SHARED_RESOURCE_GET_SHARED_RESOURCE_DATA,
        fetchSharedResourceDataSaga
    )
}