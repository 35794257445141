import { ROUTE_PARAMETERS } from 'application/components/pages/external/create_damage/sub_components/route_parameters'
import { buildQueryString } from 'application/common/route_helpers'
import { useMemo } from 'react'

export const useCreateDamageUrl = (
    licensePlate, 
    make,
    model,
    chassisNumber,
    proxyShareId
) => {
    const createDamageUrl = useMemo(
        () => {
            const queryString = buildQueryString(
                [
                    {
                        key: ROUTE_PARAMETERS.LICENSE_PLATE_PARAM, 
                        value: licensePlate
                    },
                    {
                        key: ROUTE_PARAMETERS.MAKE_PARAM, 
                        value: make
                    },
                    {
                        key: ROUTE_PARAMETERS.MODEL_PARAM, 
                        value: model
                    },
                    {
                        key: ROUTE_PARAMETERS.CHASSIS_NUMBER_PARAM, 
                        value: chassisNumber
                    }
                ]
            )
            return `${window.location.protocol}//${window.location.hostname}/external/createdamage/${proxyShareId}${encodeURI(queryString)}`

        }, [chassisNumber, licensePlate, make, model, proxyShareId])

    return {
        createDamageUrl
    }
}