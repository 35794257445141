import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import {comprehensiveDataStructure} from 'application/components/forms/_configurations'
import { exists } from 'application/common/data_helpers'



export const TaskDamageComprehensiveInsuranceDataComponent = withRouter(
    (
        {
            // widgetKey,  
            payload, 
            loadingFailed, 
            onSave, 
            match
        }
    ) => {
        const {t} = useTranslation()
        const {damageId} = match.params

        if(loadingFailed){
            return <WidgetErrorMessage>{t('Damage data could not be loaded')}</WidgetErrorMessage>
        }
        //ToDo: move mapper, form hook & insuranceSelectionFromListInitiallySelected back to form
        const {
            mapDefaultValues
        } = comprehensiveDataStructure
        
        const defaultValues = mapDefaultValues(
            payload.formData
            // , 
            // apiToInput
        )
        const formHook = useStatefulForm({defaultValues})
        const insuranceSelectionFromListInitiallySelected = !exists(payload.formData.insurance) || exists(payload.formData.insurance?.company?.id)

        return <FlexBox flexDirection='column'>
            <Collapsable 
                headline={t('Comprehensive data')} 
                initiallyCollapsed={true}
            >
                <DamageForms.DamageComprehensiveInsuranceDataForm 
                    onSave={onSave}  
                    damageId={damageId}
                    stateFulFormHook={formHook}
                    insuranceSelectionFromListInitiallySelected={insuranceSelectionFromListInitiallySelected}
                />
            </Collapsable>
        </FlexBox>
    }
)

TaskDamageComprehensiveInsuranceDataComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}