import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Widget} from 'application/components/building_blocks/widget'


const StyledWidgetArea = styled.div`
    flex: ${props => props.successively ? '0 0 auto' : '1 1 100%'};
    display: flex;
    flex-direction: ${props => props.direction};
    width: ${props => props.successively ? '100%' : 'auto'};
    height: ${props => props.successively ? '100%' : 'auto'};

   & > div {
    margin-bottom: ${props => props.childrensMargin};
   }
`

export const WidgetAreaComponent = ({
    hasContext,
    widgetList,
    direction = 'column',
    successively = false,
    childrensMargin = '0'
}) => {
    //fail safe
    if(!hasContext){ return <React.Fragment/> }

    const widgetWidth = direction === 'column' && !successively ? '100%' : 'auto'
    const widgetHeight = direction === 'row' && !successively ? '100%' : 'auto'

    return <StyledWidgetArea
        childrensMargin = {childrensMargin}
        direction={direction}
        successively={successively}
    >
        {
            widgetList.map(w => {
                return <Widget
                    key={w.key}
                    widgetKey={w.key}
                    type={w.type}
                    isBlank={w.isBlank || true}
                    isSecondary={w.isSecondary || false}
                    hideBorder={w.hideBorder || false}
                    dimension={w.dimension}
                    cssWidth={widgetWidth}
                    cssHeight={widgetHeight}
                    successively={successively}
                />
            })
        }
    </StyledWidgetArea>
}

WidgetAreaComponent.propTypes = {
    hasContext: PropTypes.bool.isRequired,
    widgetList: PropTypes.array.isRequired,
    direction: PropTypes.string,
    successively: PropTypes.bool
}