import { exists } from 'application/common/data_helpers'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'
import { DATE_INPUT_TYPES } from 'application/constants'
import moment from 'moment'
import { useMemo } from 'react'

export const useDateTimeResolver = (dateInputType = DATE_INPUT_TYPES.DATE, disabledDays = {}) => {
    const targetValueResolver = value => {
        if(!exists(value)){
            return null
        }
        switch (dateInputType) {
        case DATE_INPUT_TYPES.DATE:
            return moment(new Date(value).setHours(0,0,0,0)).utcOffset(0, true).format()
        default:
            return moment(new Date(value).setHours(0,0,0,0)).utcOffset(0, true).format()
        }
    }

    const defaultValueResolver = value => {
        if(!exists(value)){
            return ''
        }
        switch (dateInputType) {
        case DATE_INPUT_TYPES.DATE:
            return value.split('T')[0]
        default:
            return value.split('T')[0]
        }
    }

    const range = useMemo(() => {
        const format = (val) => val.toISOString().substr(0, 10)

        const max = exists(disabledDays.after)
            ? format(disabledDays.after)
            : ''

        const min = exists(disabledDays.before)
            ? format(disabledDays.before)
            : ''

        return removePropertiesWithEmptyStrings({
            min,
            max
        })
    }, [disabledDays])

    return {
        targetValueResolver,
        defaultValueResolver,
        range
    }
}