import React from 'react'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import {  StyleSheet } from '@react-pdf/renderer'


export const ZyrullQrStickerPDF = ({
    qrCodeSrc
}) => {

    const styles = StyleSheet.create({
        page: {
            padding: 40,
            paddingRight: 80
        },
        headline: {
            fontSize: 20,
            textAlign: 'center', 
        },
        section: {  
            margin: 15
        },
        paragraph: {
            fontSize: 10,
            paddingTop: 5,
            paddingBottom: 5
        },
        list: {
            main: {
                marginLeft: 20
            },
            row: {
                main: {
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 10,
                    padding: 2
                },
                left: {
                    width: 30
                },
                right: {
                    width: 500
                }
            }
        },
        center: {
            textAlign: 'center'
        },
        signField: {
            borderBottom: '2px dotted black',
            height: 20
        },
        signFieldLabel:                {
            display: 'flex', 
            flexGrow: 1, 
            alignItems:'center',
            fontSize: 10,
            paddingTop: 5
        },
        address: {
            table: {
                main: {
                    display: 'flex',
                    flexDirection: 'row'
                },
                leftCell: {
                    width: 60,
                },
                rightCell: {
                    width: 350,
                },
                signField: {
                    borderBottom: '1px solid black',
                    height: 22
                }
            }
        }
    })

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.headline}>ZYRULL</Text>
            </View>
      
            <Image
                style={ {width:'40mm'} }
                source={ {uri: qrCodeSrc} }
            />
      
        </Page>
    </Document>
}