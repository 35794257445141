import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetSubHeadline, WidgetErrorMessage } from 'application/components/widgets/_common'


export const LastTasksComponent = ({widgetKey, payload, loadingFailed}) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    return <React.Fragment>
        <WidgetSubHeadline>
        Letzte Aufgaben
        </WidgetSubHeadline>
    </React.Fragment>
}

LastTasksComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}