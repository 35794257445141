import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { VEHICLE_STATE } from 'application/constants/vehicle_state'
import { VehicleStateName } from 'application/components/name_mappings/vehicle_state'

export const VehicleStateSelect = ({
    name = 'state',
    register,
    setValue,
    validate = null,
    initialValue,
    error,
    menuPlacement = 'bottom',
    onChange
}) => {
    const { t } = useTranslation()
    const vehicleStateOptions = [
        {
            value: VEHICLE_STATE.NOT_SET,
            label: <VehicleStateName vehicleState={VEHICLE_STATE.NOT_SET} />
        },
        {
            value: VEHICLE_STATE.NOT_READY_TO_DRIVE,
            label: <VehicleStateName vehicleState={VEHICLE_STATE.NOT_READY_TO_DRIVE} />
        },
        {
            value: VEHICLE_STATE.READY_TO_DRIVE_NOT_SAFE_FOR_TRAFFIC,
            label: <VehicleStateName vehicleState={VEHICLE_STATE.READY_TO_DRIVE_NOT_SAFE_FOR_TRAFFIC} />
        },
        {
            value: VEHICLE_STATE.READY_TO_DRIVE_SAFE_FOR_TRAFFIC,
            label: <VehicleStateName vehicleState={VEHICLE_STATE.READY_TO_DRIVE_SAFE_FOR_TRAFFIC} />
        }
    ]

    const initialOption = vehicleStateOptions.find(o => o.value === initialValue) ?? vehicleStateOptions[0]

    return <Select
        label={t('Vehicle state')}
        error={error}
        name={name}
        register={register}
        setValue={setValue}
        options={vehicleStateOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        cssHeight={'38px'}
    />
}

VehicleStateSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}