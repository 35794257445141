import { connect } from 'react-redux'
import {WelcomePageComponent} from './component'

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export const WelcomePage = connect(
    mapStateToProps
)(WelcomePageComponent)