
import React from 'react'
import { withTheme } from 'styled-components'
import {StyledTableRow } from '../fragments'




const SelectableTableRowBaseComponent = (
    {
        element,
        selectItem,
        deselectItem,
        isSelected = false,
        isColoured = false,
        children,
        theme,
        cssHeight
    }
) => {

    const handleOnClick = () => {
        isSelected 
            ? deselectItem(element.id) 
            : selectItem(element)
    }

    const rowColor = isColoured
        ?  theme.color.gray5
        :  theme.color.white
        
    const backgroundColor = isSelected 
        ? theme.color.primaryBackground
        : rowColor

    const borderColor =  isSelected
        ? !isColoured 
            ? theme.color.white
            : theme.color.primaryBackground
        : isColoured 
            ? rowColor
            : theme.color.white

    return <StyledTableRow
        cssHeight={cssHeight}
        onClick={handleOnClick}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
    >
        {children}
    </StyledTableRow>
}

export const SelectableTableRowBase = withTheme(SelectableTableRowBaseComponent)