import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const inboxReducer =  (
    state = defaultStore.pages.damageManager.inbox, 
    action
) => {

    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damageManager.inbox
    } 
    default:
        return state
    }
}