export const permissionSets = [
    {
        title: 'Damage Management',
        description: 'Schäden können sowohl mit Neukunden als auch Bestandskunden angelegt werden. Schäden können gelöscht werden. Schäden können auf andere Filialen übertragen werden.',
        includesGlobalPermissions: true,
        includesBranchPermissions: true,
        permissions: ['damages:create', 'damages: delete', 'customers:create', 'customers:read']
    },
    {
        title: 'Aufgaben bearbeiten',
        description: 'Aufgaben erstellen, bearbeiten, zuweisen, löschen. Dateien können an Aufgaben angehängt und in Kategorien eingeordnet werden.',
        includesGlobalPermissions: true,
        includesBranchPermissions: true,
        permissions: ['aufgaben:create', 'aufgaben:read', 'aufgaben:update', 'aufgaben:delete', 'users:read', 'kategorien:read']
    },
    {
        title: 'Benutzerverwaltung',
        description: 'Benutzer anlegen, bearbeiten, löschen',
        includesGlobalPermissions: true,
        includesBranchPermissions: false,
        permissions: ['users:create', 'users:read', 'users:update', 'users:delete']
    }

]