import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { SubPageLayout } from 'application/components/pages/_common'
import { AccountForms } from 'application/components/forms'
import { PushBottom24 } from 'application/components/pages/_common'
import { EditableMap } from 'application/components/building_blocks/open_street_map'
import { Spacer, PushBottom16 } from 'application/components/pages/_common'
import { getLatLongForMap } from 'application/common/address_helper'
import { FlexBox } from 'application/components/fragments/flex_box'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'





export const AdministrationSettingsAccountDataComponent = ({
    currentAccount,
    onSaveAccount
}) => {
    const {t} = useTranslation()
    const latLong = getLatLongForMap(currentAccount.address)
    const [address, setAddress] = useState(currentAccount.address || null)
    const [markerMoved, setMarkerMoved] = useState(false)


    return <SubPageLayout>
        <PushBottom24 />
        <FlexBox flexDirection='row'>
            <FlexBox 
                flexBasis='1000px' 
                flexGrow='0' 
                flexShrink='1'
            >
                <FlexBox 
                    flexShrink='1' 
                    flexDirection='column'
                >
                    <AccountForms.EditAccountForm 
                        name={currentAccount.name}
                        address={address}
                        onSave={onSaveAccount} 
                        markerMoved={markerMoved}
                        contact={currentAccount.contact}
                    />
                </FlexBox>

                <Spacer basis='32px' />
                <FlexBox 
                    flexDirection='column' 
                    flexBasis='308px' 
                    flexShrink='0' 
                    flexGrow='1'
                >                   
                    {
                        latLong !== null && <React.Fragment>

                            <InfoBox
                                type={INFOBOX.TYPES.WARNING} 
                                direction='row'
                                cssMargin='0' 
                                cssMaxWidth='404px'
                            >
                                <FontIcon icon='info_outline' />
                                <Spacer basis='10px' />
                                <span><strong>Tip:</strong> {t('Move the marker on the map to correct your companies position.')}</span>
                                <Spacer />
                            </InfoBox>

                            <PushBottom16 />


                            <EditableMap 
                                latLong={latLong}
                                mapWidth='308px'
                                mapHeight='308px'
                                hideMapLink={true}
                                onMarkerDragEnd={
                                    (latLong)=>{
                                        setAddress({
                                            ...address,
                                            coordinates: {
                                                latitude: latLong.lat,
                                                longitude: latLong.lng
                                            }
                                        })
                                        setMarkerMoved(false)
                                        setMarkerMoved(true)
                                    }}
                            />

                        </React.Fragment>
                    }
                </FlexBox>
            </FlexBox>
        </FlexBox>
       
        
        
    </SubPageLayout>
}