import React from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import {DashboardContent} from './content'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { DashboardConfigurator } from 'application/components/controls/dashboard_configurator'
import { UI_SETTINGS } from 'application/constants/ui_settings'

const Dashboard = () => {
    const {t} = useTranslation()

    return <LayoutCurrentBranch
        titleSegments={['Overview']}
        headline={t('Overview')}
        headlineChildren={<DashboardConfigurator settingKey={UI_SETTINGS.USER.BRANCH_DASHBOARD_CONFIG} />}
    >
        <DashboardContent />
    </LayoutCurrentBranch>
}

export const DashboardComponent = withLoggedInUser(Dashboard)