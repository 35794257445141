
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetailBox, ActivityDetailWrapper, BranchDetail, ActivityDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageJobnumberChangedDetails = (
    {
        activity
    }
) => {

    const {
        oldNumber,
        newNumber,
        damage
    } = activity.data

    const oldJobNumber = oldNumber || ''
    const newJobNumber = newNumber || ''

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetailBox>
            {oldJobNumber}<RightArrow />{newJobNumber}   
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DamageJobnumberChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}