import React from 'react'
import uniqid from 'uniqid'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/modules/dynamic-form/controls/_common'
import { DisabledDatePicker, Row, StyledDatePicker, HiddenTextField } from './fragments'
import { DATE_INPUT_TYPES } from 'application/modules/dynamic-form/constants'
import { useDateTimeResolver } from './use_date_time_resolver'
import { useRegister } from 'application/modules/dynamic-form/hooks/use_register'

const DatePickerComponent = React.forwardRef((
    {
        onBlur = () => {},
        defaultValue,
        name,
        control,
        onDateChange,
        isReadOnly = false,
        htmlId,
        disabledDays,
        register,
        validate=null,
        dateInputType=DATE_INPUT_TYPES.DATE,
        setValue,
        trigger
    },
    ref
) => {
    const {
        defaultValueResolver,
        targetValueResolver,
        range
    } = useDateTimeResolver(dateInputType, disabledDays)

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate)

    return <React.Fragment>
        <HiddenTextField
            type='text'
            {...registerProps}
        />
        <input
            type={dateInputType}
            onChange={(e) => {
                setValue(name, targetValueResolver(e.target.value))
                trigger(name)
                onDateChange(targetValueResolver(e.target.value))
                onRegisterChange(e)
            }}
            defaultValue={defaultValueResolver(defaultValue)}
            readOnly={isReadOnly}
            id={htmlId}
            onBlur={onBlur}
            ref={ref}
            {...range}
        />

    </React.Fragment>
})

export const DatePicker =  React.forwardRef((
    {
        label = '',
        name,
        control,
        validate = {},
        error,
        onDateChange = () => {},
        hideValidationMessage = false,
        hideValidationBox = false,
        defaultValue = null,
        errorMessageOverride='',
        isReadOnly = false,
        disabled = false,
        disabledDays = {},
        register,
        updateFormState=()=>{},
        setValue=()=>{},
        trigger=()=>{}
    },
    ref
) => {
    const htmlId = uniqid()

    return <StyledDatePicker>
        {
            label !== '' && <label
                className='label'
                htmlFor={htmlId}
            >
                {label}
                <RequiredFieldMarker
                    validate={validate}
                />
            </label>
        }



        <Row>
            {
                disabled

                    ? <DisabledDatePicker>
                        <span></span>
                    </DisabledDatePicker>


                    : <DatePickerComponent
                        register={register}
                        name={name}
                        control={control}
                        onDateChange={(val)=>{
                            onDateChange(val)
                            updateFormState()
                        }}
                        isReadOnly={isReadOnly}
                        htmlId={htmlId}
                        disabledDays={disabledDays}
                        validate={validate}
                        defaultValue={defaultValue}
                        ref={ref}
                        setValue={setValue}
                        trigger={trigger}
                    />
            }


        </Row>


        <ValidationErrorMessage
            hideValidationMessage={hideValidationMessage}
            hideValidationBox={hideValidationBox}
            error={error}
            errorMessageOverride={errorMessageOverride}
        />
    </StyledDatePicker>
})