import {ACTIONS} from 'application/constants'


export const moRegister = (
    moName, 
    moType, 
    selectedItems = []
) => ({
    type: ACTIONS.MOS_REGISTER_MO,
    moName,
    moType,
    selectedItems
})

export const moUnregisterByName = (    
    moName
) => ({
    type: ACTIONS.MOS_UNREGISTER_MO_BY_NAME,
    moName
})


export const moSetActiveByName = (
    moName
) => ({
    type: ACTIONS.MOS_SET_MO_ACTIVE_BY_NAME,
    moName
})

export const moSetInactiveByName = (
    moName
) => ({
    type: ACTIONS.MOS_SET_MO_INACTIVE_BY_NAME,
    moName
})






/** 
 * Adds selected Item to state.massOperations.registeredMassOperations.<currentMo>.selectedItems
 * 
 * @param {string} moName - identifier combined  MASS_OPERATIONS.TYPE.<MOType>_<item.id>
 * @param {object} item - selected item with property id
 * @returns list in store with selected item
 */
export const moAddSelectedItemByName = (
    moName, 
    item
) => ({
    type: ACTIONS.MOS_ADD_ITEM_BY_MO_NAME,
    moName,
    item
})

/** 
 *  * Removes selected Item from state.massOperations.registeredMassOperations.<currentMo>.selectedItems
 * 
 * @param {string} moName - identifier combined  MASS_OPERATIONS.TYPE.<MOType>_<item.id>
 * @param {string} id - id of item to remove
 * @returns list in store without selected item
 */
export const moRemoveSelectedItemByName = (
    moName, 
    id
) => ({
    type: ACTIONS.MOS_REMOVE_ITEM_BY_MO_NAME,
    moName,
    id
})



/**
 * Clear selected items, sets items from action
 * 
 * @param {string} moName 
 * @param {array} items 
 * @returns void 
 */
export const moSetSelectedItemsByName  = (
    moName,
    selectedItems=[]
) => ({
    type: ACTIONS.MOS_SET_ITEMS_BY_MO_NAME,
    moName,
    selectedItems
})


/** 
 *  * Removes all Items from state.massOperations.registeredMassOperations.<currentMo>.selectedItems
 * 
 * @param {string} moName - identifier combined  MASS_OPERATIONS.TYPE.<MOType>_<item.id>
 * @returns void
 */

export const moClearAllItemsByName  = (
    moName
) => ({
    type: ACTIONS.MOS_CLEAR_ALL_ITEMS_BY_MO_NAME,
    moName
})