import styled from 'styled-components'

export const LegendContainer = styled.div`
    position: ${props => props.cssPosition !== undefined ? props.cssPosition : 'absolute'};
    width: 100%;
    display: flex; 
    flex-direction: row;
    padding: 8px 0;
    /*justify-content: center;*/
`

export const LegendLabel = styled.div`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    margin-right: 16px;
`

export const LegendIndicator = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${props => props.cssBackground};
    margin-right: 8px;
`