import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TabsNavigation } from 'application/components/building_blocks/tabs'
import { WidgetErrorMessage} from 'application/components/widgets/_common'
import { WidgetTabsNavigation } from 'application/components/widgets/_common'

import {CustomerDisplay} from 'application/components/building_blocks/customer_display'
import { PushBottom8 } from 'application/components/pages/_common'

const DamageVehicleOwnerWidget = ({payload, loadingFailed}) => {
    const {t} = useTranslation()
    const [geschaedigterVisible, setGeschaedigterVisible] = useState(false)

    if(loadingFailed) {
        return <WidgetErrorMessage>{t('vehicle owner could not be loaded')}</WidgetErrorMessage>
    }

    const { victim, opponent } = payload

    return <React.Fragment>
        <WidgetTabsNavigation>
            <TabsNavigation
                smallFont={true}
                items={[
                    {
                        label: t('Customer / owner'),
                        onItemClick: () => { setGeschaedigterVisible(false) }
                    },
                    {
                        label: t('Opponent'),
                        onItemClick: () => { setGeschaedigterVisible(true) }
                    }
                ]}
                initialActiveTabIndex={0}
            />
        </WidgetTabsNavigation>
        <PushBottom8 />
        {
            !geschaedigterVisible && victim !== undefined && !loadingFailed && <CustomerDisplay customer={victim} />
        }
        {
            geschaedigterVisible && opponent?.vehicle?.keeper !== undefined && !loadingFailed && <CustomerDisplay customer={opponent.vehicle.keeper} />
        }
    </React.Fragment>
}

DamageVehicleOwnerWidget.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const DamageVehicleOwner = DamageVehicleOwnerWidget