import React from 'react'
import { AccidentReportDescriptionSelect } from 'application/components/controls/form/accident_report_description_select'
import { useState } from 'react'
import { ACCIDENT_REPORT_DESCRIPTION } from 'application/constants'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { useTranslation } from 'react-i18next'
import { TwoColumns } from 'application/components/forms/_common'
import { useEffect } from 'react'



export const AccidentReportDescriptionSelectGroup = ({
    register,
    setValue,
    onChange,
    errors,
    typeName,
    otherName,
    name='accidentReportDescription',
    otherValue = null,
    typeValue = null
}) => {
    const {t} = useTranslation()
    const [accidentType, setAccidentType] = useState(typeValue)

    const handleChange = (option) => {
        setAccidentType(option.value)
        onChange()
    }

    const errorOther = errors[name] === undefined 
        ? null 
        : errors[name][otherName]

    const fullOtherName = `${name}.${otherName}`

    useEffect(
        () => {
            setValue(fullOtherName, otherValue)
        },[fullOtherName, otherValue, setValue]
    )

    return <TwoColumns
        columnLeft={
            <AccidentReportDescriptionSelect 
                register={register}
                setValue={setValue}
                onChange={handleChange}
                name={`${name}.${typeName}`}
                initialValue={accidentType}
            />
        }
        columnRight={
            accidentType === ACCIDENT_REPORT_DESCRIPTION.OTHER 
                ? <TextInput 
                    label={t('Other')+':'}
                    name={`${name}.${otherName}`}
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errorOther}
                />
                : null
        }
    />
}