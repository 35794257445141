import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const uiSettingsReducer =  (state = defaultStore.uiSettings, action) => {
    switch (action.type) {
    case ACTIONS.UI_SETTINGS_POPULATE_ACCOUNT_SETTINGS: {
        return {
            ...state,
            accountSettings: action.settings,
            accountSettingsLoaded: true,
            accountId: action.accountId
        }
    }
    case ACTIONS.UI_SETTINGS_POPULATE_USER_SETTINGS: {
        return {
            ...state,
            userSettings: action.settings,
            userSettingsLoaded: true,
            accountId: action.accountId
        }
    }
    case ACTIONS.UI_SETTINGS_SYNC_ACCOUNT_SETTINGS: {
        return {
            ...state,
            accountSettings: action.settings
        }
    }
    case ACTIONS.UI_SETTINGS_SYNC_USER_SETTINGS: {
        return {
            ...state,
            userSettings: action.settings
        }
    }
    default:
        return state
    }
}
