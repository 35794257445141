import { connect } from 'react-redux'
import { updateWitness } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageWitnessDataFormComp } from './component'

const mapStateToProps = state => ({
    currentDamage: state.pages.damageManager.shared.damage
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (damageId, damageData) => dispatch(updateWitness(damageId, damageData))
    }
}

export const TaskDamageWitnessDataForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageWitnessDataFormComp)