
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'
import { shortenString } from 'application/common/string_helper'
import { useTranslation } from 'react-i18next'

export const PaymentCanceledDetails = (
    {
        activity
    }
) => {
    const {t} = useTranslation()
    
    const {
        amount = '',
        cancellationReason = '',
        invoice =  {
            number: ''
        },
        damage
    } = activity.data
    
    const shortenedCancellationReason = shortenString(cancellationReason)

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetail label='Invoice number' value={invoice.number} />
        <ActivityDetail label='Type' value={t(invoice.type)} />
        <ActivityDetail label='Amount of payment' value={amount} isCurrency={true} />
        <ActivityDetail label='Cancellation reason' value={shortenedCancellationReason} title={cancellationReason} />
    </ActivityDetailWrapper>
}

PaymentCanceledDetails.propTypes = {
    activity: PropTypes.object.isRequired
}