
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'


export const TaskDetails = (
    {
        activity
    }
) => {

    const {
        title,
        damage
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetail 
            label='Title' 
            value={title} 
        />
    </ActivityDetailWrapper>
}

TaskDetails.propTypes = {
    activity: PropTypes.object.isRequired
}