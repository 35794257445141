export const PERMISSION_SCOPE = {
    CURRENT_BRANCH: 'currentBranch',
    SPECIFIC_BRANCH: 'specificBranch',
    ALL_BRANCHES: 'allBranches',
    SHARED_RESOURCE: 'sharedResource',
    NONE: 'none'
}

export const USER_PERMISSONS = {
    ACCOUNT: {
        UPDATE: 'account:update'
    },
    BRANCH: {
        CREATE: 'branch:create',
        UPDATE: 'branch:update'
    },
    COMPREHENSIVE_DAMAGE_TYPE: {
        CREATE: 'comprehensive_damage_type:create',
        DELETE: 'comprehensive_damage_type:delete',
        UPDATE: 'comprehensive_damage_type:update'
    },
    CONTROLLING: {
        READ: 'controlling:read'
    },
    CUSTOMER: {
        CREATE: 'customer:create',
        DELETE: 'customer:delete',
        READ: 'customer:read',
        UPDATE: 'customer:update',
    },
    DAMAGE: {
        COMMISSION: 'damage:commission',
        CREATE: 'damage:create',
        DELETE: 'damage:delete',
        READ: 'damage:read', //implizit: read damage state, read comprehensive_damage_type
        UPDATE: 'damage:update'
    },
    DAMAGE_STATE: {
        CREATE: 'damage_state:create',
        DELETE: 'damage_state:delete',
        UPDATE: 'damage_state:update'
    },
    DOCUMENT: {
        CREATE: 'document:create',
        DELETE: 'document:delete',
        READ: 'document:read', //read meta data + binary data, implizit: read categories
        UPDATE: 'document:update'
    },
    DOCUMENT_CATEGORY: {
        CREATE: 'document_category:create',
        DELETE: 'document_category:delete',
        UPDATE: 'document_category:update'
    },
    GROUP: {
        CREATE: 'group:create',
        DELETE: 'group:delete',
        READ: 'group:read',
        UPDATE: 'group:update'
    },
    INVOICE: {
        CREATE: 'invoice:create',
        DELETE: 'invoice:delete',
        READ: 'invoice:read',
        UPDATE: 'invoice:update'
    },
    MESSAGE: {
        DELETE: 'message:delete',
        READ: 'message:read'
    },
    PAYMENT: {
        CANCEL: 'payment:cancel',
        CREATE: 'payment:create',
        READ: 'payment:read',
        UPDATE: 'payment:update'
    },
    ROLE: {
        READ: 'role:read'
    },
    TASK: {
        CREATE: 'task:create',
        DELETE: 'task:delete',
        DELETE_COMMENT: 'task:delete_comment',
        READ: 'task:read',
        UPDATE: 'task:update'
    },
    USER: {
        CREATE: 'user:create',
        DELETE: 'user:delete',
        READ: 'user:read',
        UPDATE: 'user:update'
    },
    USER_INVITATION: {
        CREATE: 'user_invitation:create',
        DELETE: 'user_invitation:delete',
        READ: 'user_invitation:read'
    },
    SETTINGS: {
        UPDATE: 'settings:update'
    },
    MISSING: 'missingpermission'
}