import React from 'react'
import { useTranslation } from 'react-i18next'
import { YesNoSelect as YNS } from 'application/modules/dynamic-form/controls/yes_no_select'

export const YesNoSelect = ({ description, formHook }) => {
    const {
        register,
        setValue,
    } = formHook

    const { t } = useTranslation()

    return <div style={description._config?.css}>
        <YNS
            label={t(description.label)}
            name={description.name}
            register={register}
            setValue={setValue}
        />
    </div>
}
