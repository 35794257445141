import React from 'react'
import { DamageLink } from 'application/components/controls/activity_links/common'
import { USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'

export const DamageActivityLinks = (
    {
        activity
    }
) => {
    return <ForAnyBranchPermissions 
        permissions={[USER_PERMISSONS.DAMAGE.READ]}
    >
        <DamageLink activity={activity} />
    </ForAnyBranchPermissions>
}