import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { createCustomerDataMapper } from 'application/api/data_mappers/export/customer'



export function* dialogCreateCustomerSaga(action) {
    yield put(
        showWaitingScreen('Creating customer')
    )

    const mappedPayload = createCustomerDataMapper(action.payload.customer)

    yield resourceCall(
        api.gateway.customer.postCustomer, 
        {
            customerId: action.metaData.customerId
        }, 
        mappedPayload
    )

    yield put(
        showToast(action.metaData.successMsg)
    )
    yield put(
        hideWaitingScreen()
    )
}