import { fetchRequestBuilderWithToken, fetchHandler, limiter, HTTP_METHOD } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'


export const getPreferredAccount = (accessToken) => {

    const request = fetchRequestBuilderWithToken(
        'preferredaccount',
        HTTP_METHOD.GET, 
        accessToken
    )

    return limiter.schedule(
        () => fetchHandler(
            request.url, 
            request.data
        )
    ).then(handleApiResponse)
}