import React from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import {TreeView} from 'application/components/building_blocks/tree_view'
import { useCategoryTree } from './use_category_tree'


const CategoryTree = ({
    categories,
    categoriesLoaded,
    onAddDocumentCategory,
    onDeleteDocumentCategory,
    onMoveDocumentCategory,
    onEditDocumentCategory,
    theme
}) => {
    const maxDepth = parseInt(process.env.REACT_APP_MAX_DOCUMENT_CATEGORY_DEPTH)
    const {t} = useTranslation()
    
    const { 
        tree, 
        height, 
        onChange
    } = useCategoryTree(categories, categoriesLoaded, theme.treeView.nodeDimension)


    if(!categoriesLoaded){
        return <ThemedPreloader />
    }

    return <React.Fragment>
        <TreeView
            tree={tree}
            onChange={onChange}
            height={height}
            onNodeDelete={node => {
                onDeleteDocumentCategory(node.id, node.title)
            }}
            onNodeMove={onMoveDocumentCategory}
            onNodeClick={node => {
                onEditDocumentCategory(node.id, node.title)
            }}
            canNodeHaveChildren={node => node.canHaveChildren}
            canDragNode={({node}) => node.canBeChild}
            maxDepth={maxDepth}
        />
        <IconButtonWithLabel
            label={t('Add category')}
            iconKey='add'
            onButtonClick={onAddDocumentCategory}
        />
    </React.Fragment>
}

export const CategoryTreeComponent =  withTheme(CategoryTree)