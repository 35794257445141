import {
    AndOperator,
    GreaterOrEqualThanFilter,
    GreaterThanFilter,
    LesserOrEqualThanFilter,
    StringEqualsFilter,
    StringNotEqualsFilter
} from '@ec/filter-sort-page-query-js'
import { exists } from 'application/common/data_helpers'
import { DAMAGE_TYPE, FILTERS } from 'application/constants'

export const controlling = {
    vacantPositionsByType: {
        filterName: 'controlling_vacantPositionsByType',
        filterKey: 'cvpbt',
        label: 'Vacant Position',
        defaultValue: null,
        getFilterQuery: (value) => ([
            new StringEqualsFilter('damage.type', value, true)
        ])
    },
    vacantPositionsByComprehensiveDamageType: {
        filterName: 'controlling_vacantPositionsByComprehensiveDamageType',
        filterKey: 'cvpbcdt',
        label: 'Vacant Position',
        defaultValue: null,
        getFilterQuery: (value) => ([ //value is id of the account specific comprehensive damage types
            new StringEqualsFilter('damage.comprehensiveType.id', value, true)
        ])
    },
    //legacy
    damageCreationCount: {
        filterName: 'controlling_damageCreationCount',
        filterKey: 'cdcc',
        label: 'Created damages',
        defaultValue: null,
        getFilterQuery: (value) => {
            const { startDate, endDate } = value
            return [
                new GreaterOrEqualThanFilter('damage.startDate', startDate, false),
                new AndOperator(),
                new LesserOrEqualThanFilter('damage.endDate', endDate, false)
            ]
        }
    },
    globalPositionsTimeRange: {
        filterName: 'controlling_globalPositionsTimeRange',
        filterKey: 'wcgvptr',
        label: 'Global positions',
        defaultValue: null,
        getFilterQuery: (value) => {
            const { startDate, endDate } = value
            return [
                new GreaterOrEqualThanFilter('damage.createdAt',  `${startDate}T00:00:00`, true),
                new AndOperator(),
                new LesserOrEqualThanFilter('damage.createdAt', `${endDate}T23:59:59`, true)
            ]
        }
    },
    damageType: {
        filterName: 'controlling_damageType',
        filterKey: 'wcgvpdt',
        label: 'Damagetype',
        defaultValue: DAMAGE_TYPE.ALL,
        getFilterQuery: (value) => {
            switch(value){
            case DAMAGE_TYPE.ALL: 
                return []
            default: 
                return [
                    new StringEqualsFilter(
                        'damage.type', 
                        value,
                        true
                    )
                ]
            }
        }
    },
    invoiceType: {
        filterName: 'controlling_invoiceType',
        filterKey: 'wcgvpit',
        label: 'invoice type',
        defaultValue: 'All',
        getFilterQuery: (value) => {
            switch(value){
            case 'All': 
                return []
            default: 
                return [
                    new StringEqualsFilter(
                        'type', 
                        value,
                        true
                    )
                ]
            }
        }
    },
    compareBranches: {
        filterName: 'controlling_compareBranches',
        filterKey: 'wcgvpcb',
        label: 'Compare Branches',
        defaultValue: 'All',
        getFilterQuery: (value) => {
            // filter query not implemented because right now, every branch is a distinct request
            // instead return branchIds as an array
            // usage:
            // src\application\redux\saga\sagas\widgets\widget_controlling_global_vacant_positons_saga.js
            if(!exists(value)){
                return ([])
            }
            return value.split('|')
        }
    },
    state: {
        filterName: 'damages_state',
        filterKey: 'sods',
        label: 'Damage state',
        defaultValue: FILTERS.VALUES.DAMAGES.STATE.IN_PROGRESS,
        isStatic: false,
        getFilterQuery: value => {
            switch(value){
            case FILTERS.URL.VALUES.DAMAGES.STATE.IN_PROGRESS:
                return  [
                    new StringNotEqualsFilter(
                        FILTERS.QUERY_KEYS.INVOICES.DAMAGE_STATE, 
                        FILTERS.VALUES.DAMAGES.STATE.DONE, 
                        true
                    )
                ]
            case FILTERS.URL.VALUES.DAMAGES.STATE.FINISHED: 
                return [
                    new StringEqualsFilter(
                        FILTERS.QUERY_KEYS.INVOICES.DAMAGE_STATE, 
                        FILTERS.VALUES.DAMAGES.STATE.DONE, 
                        true
                    )
                ]
            case FILTERS.URL.VALUES.DAMAGES.STATE.ALL: 
                return []
            default: 
                return [
                    new StringNotEqualsFilter(
                        FILTERS.QUERY_KEYS.INVOICES.DAMAGE_STATE, 
                        FILTERS.VALUES.DAMAGES.STATE.DONE, 
                        true
                    )
                ]
            }  
        }
    },
    customState: {
        filterName: 'damages_customstate',
        filterKey: 'sodcs',
        label: 'Custom state',
        defaultValue: null,
        isStatic: false,
        getFilterQuery: value => {
            if(!exists(value)){
                return []
            }

            return [
                new StringEqualsFilter(
                    'damage.state.id', 
                    value, 
                    true
                )
            ]
        }
    },
    hasGrossTotal:  {
        filterName: 'controlling_damages_hasGrossTotal',
        filterKey: 'cdhgt',
        label: 'Has gross total',
        defaultValue: '0',
        isStatic: true,
        getFilterQuery: () => {
            return [
                new GreaterThanFilter(
                    'unpaidTotal', 
                    '0', 
                    true
                )
            ]
        }
    },
}