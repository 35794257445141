export const MASS_OPERATIONS = {
    TYPE: {
        DOCUMENTS: 'DOCUMENTS',
        FOTOS: 'Fotos',
        DAMAGES_OVERVIEW: 'DAMAGES_OVERVIEW',
        ACTIVITIES: 'ACTIVITIES'
    },
    NAME: {
        DAMAGES_OVERVIEW_MY_DAMAGES: 'DAMAGES_OVERVIEW_MY_DAMAGES',
        DAMAGES_OVERVIEW_ALL_DAMAGES: 'DAMAGES_OVERVIEW_ALL_DAMAGES', 
        TASKS_OVERVIEW_CURRENT_BRANCH_MY_TASKS: 'TASKS_OVERVIEW_CURRENT_BRANCH_MY_TASKS',
        TASKS_OVERVIEW_CURRENT_BRANCH_ALL_TASKS: 'TASKS_OVERVIEW_CURRENT_BRANCH_ALL_TASKS', 
        TASKS_OVERVIEW_ALL_BRANCHES_MY_TASKS: 'TASKS_OVERVIEW_ALL_BRANCHES_MY_TASKS',
        TASKS_OVERVIEW_ALL_BRANCHES_ALL_TASKS: 'TASKS_OVERVIEW_ALL_BRANCHES_ALL_TASKS',
        ACTIVITIES_ALL_BRANCHES: 'ACTIVITIES_ALL_BRANCHES'
    },
    KEY: {
        NAME: 'moName',
        TYPE: 'moType',
        IS_ACTIVE: 'isActive'
    }
}