
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ActivityDetailBox, ActivityDetailWrapper, BranchDetail, ActivityDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'

export const ComprehensiveDamageTypeChangedDetails = (
    {
        activity
    }
) => {
    const {t} = useTranslation()

    const {
        oldType = null,
        newType = null,
        damage
    } = activity.data

    const oldTypeName = oldType?.name || ''
    const newTypeName = newType?.name || ''

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetailBox>
            {t(oldTypeName)}<RightArrow />{t(newTypeName)} 
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

ComprehensiveDamageTypeChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}