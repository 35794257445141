import React from 'react'
import styled, { useTheme, css } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const StyledAnnouncementButtonWrapper = styled.div`
    position: relative;
`

const announcementButtonBase = css`
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid ${props => props.$primaryColor};
    background-color: ${props => props.$secondaryColor};
    color: ${props => props.$fontColor};
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.theme.fontSize.standard};
    text-decoration: none;

    & i {
        color: ${props => props.$fontColor};
        font-size: ${props => props.theme.fontSize.standard};
        margin-right: ${props => props.$collapsed ? '0' : '4px'};
    }
`

const StyledAnnouncementLink = styled.a`
    ${announcementButtonBase}
`

const StyledAnnouncementNavLink = styled(NavLink)`
    ${announcementButtonBase}
`

export const AnnouncementButton = ({
    buttonText = 'Feedback',
    icon = 'question_answer',
    children,
    primaryColor = null,
    secondaryColor = null,
    fontColor = null,
    collapsed = false,
    to = '#',
    variant = 'link' //'navlink'

}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const pColor = primaryColor ? primaryColor : theme.color.feedbackColor
    const sColor = secondaryColor ? secondaryColor : theme.color.feedbackColor
    const fColor = fontColor ? fontColor : theme.color.white



    return <StyledAnnouncementButtonWrapper>

        {
            variant === 'link' && <StyledAnnouncementLink
                $primaryColor={pColor}
                $secondaryColor={sColor}
                $fontColor={fColor}
                $collapsed={collapsed}
                href={to}
                target='_blank'
            >
                <FontIcon icon={icon} />{ !collapsed && <React.Fragment>{t(buttonText)}</React.Fragment>}
            </StyledAnnouncementLink>
        }

        {
            variant === 'navlink' && <StyledAnnouncementNavLink
                $primaryColor={pColor}
                $secondaryColor={sColor}
                $fontColor={fColor}
                $collapsed={collapsed}
                to={to}
            >
                <FontIcon icon={icon} />{ !collapsed && <React.Fragment>{t(buttonText)}</React.Fragment>}
            </StyledAnnouncementNavLink>
        }

        {children}
    </StyledAnnouncementButtonWrapper>
}