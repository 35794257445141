import { exists } from 'application/common/data_helpers'
import React from 'react'
import styled from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useAccountLogoLoader } from 'application/components/hooks/account_logo/use_account_logo_loader'
import { PushBottom10 } from '../../_common'
import { TitlebarAccountLogo } from 'application/components/building_blocks/title_bar_account_logo'

const StyledAccountHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    background-color: ${props => props.theme.color.white};
    border-bottom: 1px solid ${props => props.theme.color.gray15};
    z-index: 1000;
    display: flex;
    padding-top: 8px;
    justify-content: center;
`
const StyledAccountName = styled.h1`
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.fontSize.headline1};
    font-weight: normal;
    color: ${props => props.theme.color.anthracite};
`

const StyledSubtitle = styled.h2`
    margin: 0;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: normal;
    color: ${props => props.theme.color.anthracite};
    text-align: center;
`

export const AccountHeader = ({
    subtitle = '',
    accountId = null
}) => {

    const {
        accountLogoAvailable,
        accountName
    } = useAccountLogoLoader(
        accountId
    )

    const textsMatch = accountName === subtitle

    return <StyledAccountHeader>
        <FlexBox 
            width='auto' 
            flexDirection='column'
            justifyContent='center'
        >
            {
                accountLogoAvailable 
                    ? <TitlebarAccountLogo 
                        accountId={accountId}
                        cssMinHeight={'50px'}
                        cssHeight={'50px'}
                        useEsyLogoPlaceholder={false} 
                    />
                    : <StyledAccountName>{accountName}</StyledAccountName>
            }
            <PushBottom10 />
            {
                exists(subtitle) && !textsMatch && <StyledSubtitle>{subtitle}</StyledSubtitle>
            }
        </FlexBox>
    </StyledAccountHeader>
}