import { WIDGET_TYPES } from 'application/constants'
import { refreshLawfirmSaga, refreshRepairClearanceSaga } from '../post_processors'
import { updateAktenStatusSaga, updateLiabilityRequestSaga, updateReceiptConfirmationSaga, updateRepairClearanceSaga, updateInsuranceInformationSaga } from '../pages/damage_manager/workflow_panel'



export const updateWorkflowPanelFormSagas = (key) => {
    switch (key) {
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_AKTEN_STATUS:
        return [
            updateAktenStatusSaga, 
            refreshLawfirmSaga
        ]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_LIABILITY_REQUEST:
        return [
            updateLiabilityRequestSaga, 
            refreshLawfirmSaga
        ]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION:
        return [
            updateReceiptConfirmationSaga, 
            refreshLawfirmSaga
        ]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION:
        return [
            updateInsuranceInformationSaga
        ]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE:
        return [
            updateRepairClearanceSaga, 
            refreshRepairClearanceSaga
        ]
    default:
        return []
    }
}