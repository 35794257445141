import { withLoggedInUser } from 'application/components/context/logged_in'
import { DIALOG, UPDATE_CONSTRAINTS } from 'application/constants'
import { usersGetUsersRequest } from 'application/redux/actions/pages/users'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getUpdateConstraint } from 'application/redux/selectors'
import { connect } from 'react-redux'

import {AdministrationUsersComponent} from './component'

const mapStateToProps = state => {
    const usersPage = state.pages.users

    return {
        usersListLoaded: usersPage.loaded,
        usersListLoading: usersPage.loading,
        usersList: usersPage.usersList,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.USERS_TABLES)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadUsersList: ( 
            offset, 
            limit, 
            orderBy, 
            direction,
            filterQuery
        ) => dispatch(
            usersGetUsersRequest(
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery
            )
        ),
        onCreateUser: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_USER, 
                false, 
                {}, 
                'Create User'
            )
        )
    }
}

export const  AdministrationUsers = connect(
    mapStateToProps,
    mapDispatchToProps
)( withLoggedInUser(AdministrationUsersComponent) )