import React, { useEffect } from 'react'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { Navigate } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'
import { exists } from 'application/common/data_helpers'
import * as storage from 'application/storage'
import { useParams } from 'react-router-dom'

export const AccountPreloaderComponent = ({
    currentAccount,
    currentUser,
    currentAccountLoaded,
    currentAccountLoading,
    onLoadAccount,
    loggedIn
}) => {
    const {accountId} = useParams()

    const {
        internalAccountLink,
        branchLink
    } = useApplicationLinks()

    useEffect(
        () => {
            if(!currentAccountLoaded && !currentAccountLoading){
                storage.currentBranch.delete(accountId)
                onLoadAccount(accountId)
            }
        }, [accountId, currentAccountLoaded, currentAccountLoading, onLoadAccount]
    )

    if(currentAccountLoaded && loggedIn){
        const defaultBranchId = exists(currentUser.preferredBranch) 
            ? currentUser.preferredBranch.id
            : currentAccount.branches[0]
        storage.currentBranch.store(accountId, defaultBranchId)

        if(currentAccount.branches.length === 1){
            return <Navigate to={
                internalAccountLink(
                    ROUTES.DASHBOARD
                )
            }
            />
        }
        return <Navigate 
            to={
                branchLink( 
                    ROUTES.BRANCH_SPECIFIC_DASHBOARD,
                    defaultBranchId
                )
            }
        />
    }
    return <PagePreloader text='loading company data' />
}