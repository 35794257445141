import {
    StringEqualsFilter, StaticFilter,
    OrOperator, AndOperator,
    GreaterOrEqualThanFilter, LesserOrEqualThanFilter
} from '@ec/filter-sort-page-query-js'
import { timerangeConverter } from 'application/common/date_time_helpers'
import { FILTERS } from 'application/constants/filters'

export const activities = {
    damageIdWithChildren: {
        filterName: 'activities.damageIdChildren',
        filterKey: 'adidc',
        label: 'Damage related activities',
        defaultValue: null,
        getFilterQuery: value => ( [
            new StringEqualsFilter('entity.Id', value, true),
            new OrOperator(),
            new StaticFilter(`relatedEntities[id == "${value}"]`)
        ])
    },
    damageId: {
        filterName: 'activities.damageId',
        filterKey: 'adid',
        label: 'Damage related activities',
        defaultValue: null,
        getFilterQuery: value => ( [
            new StringEqualsFilter('entity.id', value, true)
        ])
    },
    activityType: {
        filterName: 'activityType',
        filterKey: 'actfat',
        label: 'Activity Type',
        defaultValue: FILTERS.VALUES.ACTIVITIES.TYPE.ALL,
        getFilterQuery: value => (
            value === FILTERS.VALUES.ACTIVITIES.TYPE.ALL
                ? []
                : [
                    new StringEqualsFilter('type', value, false)
                ]
        )
    },
    timeRange: {
        filterName: 'activity_timerange',
        filterKey: 'actfatr',
        label: 'Activities time range',
        defaultValue: null,
        getFilterQuery: (value) => {
            const { startDate, endDate } = timerangeConverter.getDateTimesFromRange(value)
            return [
                new GreaterOrEqualThanFilter('occurredAt',  `${startDate}T00:00:00`, true),
                new AndOperator(),
                new LesserOrEqualThanFilter('occurredAt', `${endDate}T23:59:59`, true)
            ]
        }
    },
    relatedEntityType: {
        filterName: 'activity_entity_type',
        filterKey: 'actfet',
        label: 'Related entity',
        defaultValue: FILTERS.VALUES.ACTIVITIES.ENTITY_TYPE.ALL,
        getFilterQuery: value => (
            value === FILTERS.VALUES.ACTIVITIES.ENTITY_TYPE.ALL
                ? []
                : [
                    new StringEqualsFilter('entity.type', value, false)
                ]
        )
    }
}