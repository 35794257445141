export const SETTINGS = {
    KEYS: {
        NOTIFICATIONS_CHANNEL: 'channelType',
        NOTIFICATIONS_CHANNEL_TYPES: {
            EMAIL: 'Email',
            REALTIME_NOTIFICATION: 'RealtimeNotification',
            SMS: 'Sms'
        },
        NOTIFICATIONS_DELIVERY_SCHEDULE: 'deliverySchedule'
    }
}

export const DELIVERY_SCHEDULES = [
    {
        value: '@immediately',
        label: 'Send notifications immediately'
    },
    {
        value: '0 * * * *',
        label: 'Every hour'
    },
    {
        value: '0 */3 * * *',
        label: 'Every 3 hours'
    },
    {
        value: '0 */6 * * *',
        label: 'Every 6 hours'
    },
    {
        value: '0 */12 * * *',
        label: 'Every 12 hours'
    },
    {
        value: '0 8 * * *',
        label: 'Once every day'
    }
]