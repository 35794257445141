import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import {titleGenerator} from './title_generator'
import { useTranslation } from 'react-i18next'

export const PageTitle = ({segments = []}) => {
    const {t} = useTranslation()
    
    const translatedSegments = segments.map(s => t(s))
    const title = titleGenerator(translatedSegments)

    return <Helmet>
        <title>{title}</title>
    </Helmet>
}

PageTitle.propTypes = {
    segments: PropTypes.arrayOf(PropTypes.string)
}