import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { ActionButton } from 'application/components/controls/action_button'
import { PushBottom8, Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'

export const SuccessMessage = ({
    complete,
    onReset,
    progress,
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return <React.Fragment>
        {
            complete && <InfoBox
                type={INFOBOX.TYPES.SUCCESS} 
                direction='row' 
            >
                <FontIcon icon='check' color={theme.color.success} />
                <Spacer basis='10px' />
                <div>
                    <Paragraph cssAlign='center'>
                        {progress} {t('customers were successfully imported')}.
                    </Paragraph>
                    <PushBottom8 />
                    <Paragraph cssAlign='center'>
                        <ActionButton 
                            buttonText={t('New import')} 
                            onButtonClick={(e)=>{
                                onReset(e)
                            }} />
                    </Paragraph>
                </div>
            </InfoBox>
        }
    </React.Fragment>

}