import { useSelector } from 'react-redux'
import { notNull } from 'application/common/data_helpers'
import { getDocumentsByCategory, getDocsByCatsResourceId } from 'application/redux/selectors'


export const useDocumentsByCategories = (
    categoryId
) => {
    const category = useSelector(
        getDocumentsByCategory(categoryId)
    )
    const documentsByCategoryExist = notNull(category)
    const documentsByCategoryLoaded = documentsByCategoryExist 
        ? category?.loaded
        : false
    const documentsByCategoryLoading = documentsByCategoryExist 
        ? category?.loading
        : false
    const resourceId = useSelector(getDocsByCatsResourceId)


    const allDocuments = Object.values(
        useSelector(
            state => state.documentsByCategories.categories
        )
    ).flatMap( 
        cat => cat?.documents || []
    )

    return {
        total: category?.total || 0,
        documentsByCategory: category?.documents || [],
        parentId: resourceId ,
        documentsByCategoryLoading,
        documentsByCategoryLoaded,
        allDocuments
    }

}