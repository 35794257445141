import styled from 'styled-components'
import PropTypes from 'prop-types'


export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.width};
  color: ${props => props.theme.color.anthracite};
  & i {
    font-size: 16px;
  }
`


export const SimpleIcon = (
    {
        iconKey='edit',
        width='22px',
        iconSize='16px'
    }
) => {
    return <StyledIcon
        iconSize={iconSize}
        width={width}
    >
        <i className='material-icons'>
            {iconKey}
        </i>
    </StyledIcon>
}

SimpleIcon.propTypes = {
    iconKey: PropTypes.string,
    width: PropTypes.string,
    iconSize: PropTypes.string,
}