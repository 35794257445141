import { costCoveragePdfBuilder } from './builders/create_cost_coverage/builder'
import { declarationOfAssignmentPdfBuilder } from './builders/declaration_of_assignment/builder'
import { powerOfAttorneyPdfBuilder } from './builders/power_of_attorney/builder'
import { repairSchedulePdfBuilder } from './builders/repair_schedule/builder'
import { zyrullQrStickerBuilder } from './builders/zyrull_qr_sticker/builder'
import { useDynamicPdf } from './use_dynamic_pdf'

const pdfBuilders = {
    powerOfAttorneyPdfBuilder,
    costCoveragePdfBuilder,
    zyrullQrStickerBuilder,
    declarationOfAssignmentPdfBuilder,
    repairSchedulePdfBuilder
}

export {
    pdfBuilders, useDynamicPdf
}
