import { exportComponentAsPNG } from 'react-component-export-image'
import React, { useRef } from 'react'
import {ActionButton} from 'application/components/controls/action_button'
import {ZyrullSticker} from './sticker'
import { getFullName } from 'application/common/name_helpers'

export const CreateZyrullQrStickerButton = ({
    qrCodeProps,
    customer,
    licensePlate
}) => {
    const customerFullName = getFullName(customer, true)
    const componentRef = useRef()
    const fileNameBase = `${getFullName(customer, true)} - ${licensePlate} - sticker`
    const fileName = fileNameBase.replace(/[/\\?%*:|"<>]/g, '').replace('.', '').substring(0, 249)

    return <React.Fragment>
        <ZyrullSticker 
            ref={componentRef} 
            qrCodeProps={qrCodeProps}
            customerFullName={customerFullName}
            licensePlate = {licensePlate}
        />
        <ActionButton
            onButtonClick={
                () => exportComponentAsPNG(
                    componentRef,
                    { fileName }
                )
            } 
            buttonText='Download Sticker'
        />
    </React.Fragment>
}