import React from 'react'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'

export const ForLoggedInUsersComponent = (
    {
        loggedIn, 
        permissionsLoaded,
        children
    }
) => {
    return <React.Fragment>
        { 
            loggedIn && permissionsLoaded
                ? children
                : <PagePreloader />
        }
    </React.Fragment>
}