import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { takeLatest } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* downloadTaskDocumentsSaga(action) {
    try {
        yield resourceCall(
            api.gateway.attachments.downloadTaskDocumentsArchive, 
            {
                damageId: action.damageId, 
                taskId: action.taskId
            }
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* downloadTaskDocumentsWatcher() {
    yield takeLatest(ACTIONS.DOCUMENTS_DOWNLOAD_TASK_DOCUMENTS, downloadTaskDocumentsSaga)
}