import React from 'react'
import { exists } from 'application/common/data_helpers'
import { UI_SETTINGS_DATA_TYPE } from 'application/constants/ui_settings'
import { DescriptionField } from './setting_description'
import { SettingHeader } from './setting_header'
import { SettingsChildren} from './setting_children'
import { 
    ContentWrapper,
    StyledDivider,
    StyledSetting
} from './fragments'
 
export const Setting = ({
    definition,
    updateSettings,
    getSetting
}) => {

    const isPrimitive = 
           definition.dataType === UI_SETTINGS_DATA_TYPE.BOOL 
        || definition.dataType === UI_SETTINGS_DATA_TYPE.INTEGER 
        || definition.dataType === UI_SETTINGS_DATA_TYPE.STRING

    const showDivider = exists(definition.description) || !isPrimitive

    return <StyledSetting>
        <SettingHeader 
            definition={definition}
            updateSettings={updateSettings}
            getSetting={getSetting}
        />

        { 
            showDivider && <StyledDivider />
        }

        {
            isPrimitive && <DescriptionField
                description={definition.description} 
            />
        }

        {
            !isPrimitive && <ContentWrapper>
                <SettingsChildren 
                    definition={definition}
                    updateSettings={updateSettings}
                    getSetting={getSetting}
                />
            </ContentWrapper>
        }
    </StyledSetting>

}