export const LIFECYCLE_STATES = [
    {
        name: 'New',
        label: 'New'
    },
    {
        name: 'InDataAcquisition',
        label: 'In data acquisition'
    },
    {
        name: 'InPlaning',
        label: 'In planing'
    },
    {
        name: 'WaitingOnAssessment',
        label: 'Waiting on assessment'
    },
    {
        name: 'WaitingForRelease',
        label: 'Waiting for release'
    },
    {
        name: 'WaitingForAppointment',
        label: 'Waiting for appointment'
    },
    {
        name: 'AppointmentScheduled',
        label: 'Appointment scheduled'
    },
    {
        name: 'BeingRepaired',
        label: 'Being repaired'
    },
    {
        name: 'ReadyForPickup',
        label: 'Ready for pickup'
    },
    {
        name: 'InBilling',
        label: 'In billing'
    },
    {
        name: 'Done',
        label: 'Done'
    }
]