import React from 'react'
import { ErrorPageBase } from 'application/components/pages/errors/_common'


export const SharedResourceInvalid = () => {
    return <ErrorPageBase 
        errorMessage='This resource is not valid anymore'
        linkURL=''
    />
}
