import React, { useState } from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { withStatuslistLoader } from 'application/components/higher_order_components'
import { DamageStateSelectComponent } from 'application/components/controls/damage_status_select'
import { setQuery } from 'application/components/hooks/use_query'
import * as filters from 'application/filters'
import { useQuery } from 'application/components/hooks/use_query'

export const DamageCustomStateFilter = withStatuslistLoader(({
    statusList,
    OFFSET,
    LIMIT,
    onChange = () => {}
}) => {
    const filter = filters.damages.customState

    const location = useLocation()
    const navigate = useNavigate()
    const query = useQuery()
    const [currentStateId, setCurrentStateId] = useState(query.getStringParam(filter.filterKey))

    const handleChange = (customStateId) => {
        setCurrentStateId(customStateId)
        setQuery(
            navigate, 
            location, 
            location.pathname,
            [
                {
                    key: filter.filterKey,
                    value: customStateId
                }
            ],
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
        onChange()
    } 

    return <DamageStateSelectComponent 
        statusList={statusList}
        statusListLoaded={statusList.loaded}
        damage={{
            state: {
                id: currentStateId
            }
        }}
        damageLoaded={true}
        onUpdateState={handleChange}
    />
}) 