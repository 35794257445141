
import React, { useState } from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import styled from 'styled-components'
import { DASHBOARD_CONFIGURATION } from 'application/constants'
import { DescriptionField } from '../common/setting_description'
import { useTranslation } from 'react-i18next'
import { PushBottom16 } from 'application/components/pages/_common'

const StyledDashboardConfigurationItem = styled.div`
    padding: 8px;
    border: 1px solid ${props => props.theme.color.anthracite};
    background: ${props => props.$isActive ? props.theme.color.primaryBackground : props.theme.color.gray15};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    & span{
        font-size: ${props => props.theme.fontSize.small};
        color: ${props => props.theme.color.anthracite};
    }

`

export const EditDashboardConfiguration = ({
    definition,
    updateSettings,
    getSetting
}) => {
    const {t} = useTranslation()
    const setting = getSetting(definition.key)
    const [activeItem, setActiveItem] = useState(setting)

    const handleClick = (config) => {
        setActiveItem(config)
        updateSettings(definition.key, config)
    }

    return <FlexBox flexDirection='column'>

        <DescriptionField
            description={definition.description}
        />

        <PushBottom16 />

        <FlexBox cssRules='gap: 16px;' justifyContent='flex-end'>
            <StyledDashboardConfigurationItem 
                onClick={()=>{handleClick(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES)}}
                $isActive={activeItem === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES}
            >
                <FontIcon 
                    icon='local_car_wash' 
                    isClickable={true}
                />
                <span>{t('Damages & Releases')}</span>
            </StyledDashboardConfigurationItem>

            <StyledDashboardConfigurationItem
                onClick={()=>{handleClick(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING)}}

                $isActive={activeItem === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING}
            >
                <FontIcon 
                    icon='bar_chart' 
                    isClickable={true}
                />
                <span>{t('Controlling & Statistics')}</span>
            </StyledDashboardConfigurationItem>
        </FlexBox>

    </FlexBox>
}