import { ACTIONS } from 'application/constants'

export const getAccountSettingsRequest = (accountId) => ({
    type: ACTIONS.UI_SETTINGS_GET_ACCOUNT_SETTINGS_REQUEST,
    accountId
})

export const getUserSettingsRequest = (accountId, userId) => ({
    type: ACTIONS.UI_SETTINGS_GET_USER_SETTINGS_REQUEST,
    accountId, userId
})

export const populateAccountSettings = (accountId, settings) => ({
    type: ACTIONS.UI_SETTINGS_POPULATE_ACCOUNT_SETTINGS,
    accountId, settings
})

export const populateUserSettings = (accountId, settings) => ({
    type: ACTIONS.UI_SETTINGS_POPULATE_USER_SETTINGS,
    accountId, settings
})

export const saveAccountSettingsRequest = (settings, saveDirectly = false) => ({
    type: ACTIONS.UI_SETTINGS_SAVE_ACCOUNT_SETTINGS_REQUEST,
    settings, saveDirectly
})

export const saveUserSettingsRequest = (userId, settings, saveDirectly = false) => ({
    type: ACTIONS.UI_SETTINGS_SAVE_USER_SETTINGS_REQUEST,
    userId, settings, saveDirectly
})

export const syncAccountSettings = (settings) => ({
    type: ACTIONS.UI_SETTINGS_SYNC_ACCOUNT_SETTINGS,
    settings
})

export const syncUserSettings = (settings) => ({
    type: ACTIONS.UI_SETTINGS_SYNC_USER_SETTINGS,
    settings
})

