import { STORAGE } from 'application/constants'

export const sharedResourceId = {
    store: (id) => {
        localStorage.removeItem(STORAGE.SHARED_RESOURCE_ID)
        localStorage.setItem(STORAGE.SHARED_RESOURCE_ID, id)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.SHARED_RESOURCE_ID)
        return storageEntry === null ? '' : storageEntry
    },
    delete: () => {
        localStorage.removeItem(STORAGE.SHARED_RESOURCE_ID)
    }
}