import { put } from 'redux-saga/effects'
import { requestDamage } from 'application/redux/actions/pages/damage_manager'
import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { WIDGET_TYPES } from 'application/constants'

export function* refreshDamageManagerDamageSaga(action) {
    const { damageId } = action
    yield put( 
        requestDamage(damageId) 
    )
    yield put(
        refreshWidgetsByType(WIDGET_TYPES.TASK_DAMAGE_BASIC_DATA_FORM)
    )
}