import { connect } from 'react-redux'
import { ForAnyBranchPermissionsComponent } from './component'

const mapStateToProps = (state) => {
    return {
        allBranchPermissions: state.auth.allPermissions
    }
}

export const ForAnyBranchPermissions = connect(
    mapStateToProps
)(ForAnyBranchPermissionsComponent)