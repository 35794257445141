import React from 'react'
import { LayoutDamageManager } from 'application/components/pages/_layout'
import * as Common from 'application/components/pages/_common'
import { exists } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'

export const DamageManagerComponent = (
    { 
        damageLoading, 
        damage, 
        children,
        sidebar = null,
        tabBarButtons = null,
        paddingRight = '16px',
        titleSegments=[],
        headline='',
        mainPaddingLeft='0'

    }
) => {
    const {t} = useTranslation()

    const titleSegmentsWithLicensePlate = damageLoading  
        ? titleSegments
        : [damage?.vehicle?.licensePlate, ...titleSegments] 

    return <LayoutDamageManager className='layoutDamageManager' titleSegments={titleSegmentsWithLicensePlate}>
        {
            (exists(headline) || exists(tabBarButtons)) && <Common.MainHeader className='mainHeader'><span>{t(headline)}</span> <span>{ tabBarButtons }</span></Common.MainHeader>
        }
        <Common.Container className='container'>
            <Common.Main className='main' paddingLeft={mainPaddingLeft} paddingRight={paddingRight}>
                <Common.VerticalContainer className='vertical'>
                    { children }
                </Common.VerticalContainer>
            </Common.Main>
            {
                sidebar !== null && <Common.Sidebar>
                    { sidebar }
                </Common.Sidebar>
            }            
        </Common.Container>
    </LayoutDamageManager>
}