import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const deleteMessageById = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/messages/${params.messageId}`, HTTP_METHOD.DELETE)
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/messages/${params.messageId}`, params.pin, HTTP_METHOD.DELETE)
            return apiCall(request)
        }
    }
)