import { getFullName } from 'application/common/name_helpers'
import React from 'react'
import { CreateDamageCustomerStep } from '../../_common/customer_step'

export const KickstartStep0 = (
    { 
        abort, 
        dialog,
        onDataChange,
        goToNextStep,
        setCustomerData,
        resetCustomerData,
        customerData,
        changeSubLabel,
        currentStepIndex,
        applyPreferredVehicleData,
        setApplyPreferredVehicleData,
        existingCustomerWasChanged,
        setExistingCustomerWasChanged,
        existingCustomer,
        setExistingCustomer
    }
) => {
    const handleSetCustomerData = data => {
        setCustomerData(data)
        changeSubLabel(getFullName(data.values), currentStepIndex)
    }

    return <CreateDamageCustomerStep 
        abort={abort}
        goToNextStep={goToNextStep}
        dialog={dialog}
        setCustomerData={handleSetCustomerData}
        resetCustomerData={resetCustomerData}
        onDataChange={onDataChange}
        initialCustomerValues={customerData?.values}
        applyPreferredVehicleData={applyPreferredVehicleData}
        setApplyPreferredVehicleData={setApplyPreferredVehicleData}
        isExistingCustomer={customerData?.existingCustomer}
        existingCustomerWasChanged={existingCustomerWasChanged}
        setExistingCustomerWasChanged={setExistingCustomerWasChanged}
        existingCustomer={existingCustomer}
        setExistingCustomer={setExistingCustomer}
    />
}