import { connect } from 'react-redux'

import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { TitleBar } from './component'

const mapStateToProps = (state) => {
    const currentAccount = state.auth.currentAccount

    return {
        hasAccount: currentAccount !== null,
        currentAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_DAMAGE, 
                false, 
                {
                    usersList: [], 
                    usersListLoading: false, 
                    customerList: [], 
                    customerListLoading: false
                }, 
                'Create damage case'
            )
        ),
        onCreateDamageKickstart: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_DAMAGE_KICKSTART, 
                false, 
                {
                    usersList: [], 
                    usersListLoading: false, 
                    customerList: [], 
                    customerListLoading: false
                }, 
                'Create damage case'
            )
        )
    }
}

export const TitleBarMain = connect(
    mapStateToProps,
    mapDispatchToProps
)(TitleBar)