import { connect } from 'react-redux'
import {DialogExpertSearchSelectComponent} from './component'
import { notNull } from 'application/common/data_helpers'
import {getExpertsForDialogRequest} from 'application/redux/actions/expert_search'



const mapStateToProps = (state, props) => {

    const {
        dialogName = null
    } = props

    const dialog = notNull(dialogName) && state.dialogs.registeredDialogs.find( 
        d => d.name === dialogName 
    )

    const {
        initialExperts = [],
        foundExperts = [],
        foundExpertsLoading = false
    } = dialog.payload

    return {
        foundExperts,
        foundExpertsLoading,
        initialExperts
    }
}



const mapDispatchToProps = dispatch => {
    return {
        onSearchExpert: (
            dialogName, 
            searchTerm
        ) => dispatch(
            getExpertsForDialogRequest(
                dialogName,
                searchTerm
            )
        )
    }
}

export const DialogExpertSearchSelect =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogExpertSearchSelectComponent)