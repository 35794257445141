import React from 'react'
import { TextInput, TextArea } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { useTranslation } from 'react-i18next'

export const GroupDataPartialForm = ({
    nameGroupName = 'name',
    nameGroupDescription = 'description',
    register,
    errors,
    disabled = false,
    onChange = () => {},
}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <TextInput
            label={t('Name')} 
            name={nameGroupName}
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors[nameGroupName]}
            autoFocus={true}
            onChange={onChange}
            disabled={disabled}
        />
        <TextArea
            label={t('Description')}
            name={nameGroupDescription}
            register={register}
            validate={null}
            error={errors[nameGroupDescription]}
            onChange={onChange}
            disabled={disabled}
        />
    </React.Fragment>
}