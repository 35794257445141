import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'



export function* dialogDeleteGroupGetMembersSaga(action) {
    yield put(showWaitingScreen('Loading groupmembers'))

    const { groupId } = action.metaData

    const listFilter = new ListFilter( filters.users.groupId )
        .setValue(groupId)

    const filterQuery = listFilter.getQuery()

    const params = {
        offset: 0, 
        limit: 10, 
        filterQuery
    }

    const groupMembersResponse = yield resourceCall(
        api.gateway.users.getUsersList,
        params
    )

    yield put(
        updateDialogDataByName(
            action.dialogName, 
            {
                members: groupMembersResponse
            }
        )
    )

    yield put(hideWaitingScreen())  
    yield put(showToast('Groupmembers loaded'))
}