import { connect } from 'react-redux'
import {RealtimeNotificationsSidebarComponent} from './component'
import { hideSidebar } from 'application/redux/actions/realtime_notifications'

const mapStateToProps = (state, props) => {
    return {
        sidebarVisible: state.realtimeNotifications.sidebarVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onHideSidebar: () => dispatch(hideSidebar())
    }
}

export const RealtimeNotificationsSidebar =  connect(
    mapStateToProps,
    mapDispatchToProps
)(RealtimeNotificationsSidebarComponent)