import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ThumbnailWithLoader } from 'application/components/building_blocks/attachments/thumbnails'


export const InvoiceDocumentsComponent = (
    {
        invoiceData, 
        onDownloadAttachment, 
        damageId
    }
) => {
    const { t } = useTranslation(),

        handleDownloadAttachment = (attachment) => {
            onDownloadAttachment(
                attachment.id,
                attachment.fileName,
                damageId
            )
        }

    return <FlexBox flexDirection='column'>
        <Fragments.InvoiceInformation>
            <Fragments.InvoiceInformationLabel>
                {t('Documents')}
            </Fragments.InvoiceInformationLabel>
        </Fragments.InvoiceInformation>
        <FlexBox flexWrap='wrap'>
            {
                invoiceData.documents.map(
                    (attachment, fileIndex) => {
                        return <ThumbnailWithLoader
                            key={fileIndex}
                            attachmentId={attachment.id}
                            attachmentName={attachment.name}
                            damageId={damageId}
                            taskId={attachment.resourceId}
                            onAttachmentClick={handleDownloadAttachment}
                            extendedData={
                                {
                                    category: attachment.category,
                                    isExportedToLawFirm: attachment.isExportedToLawFirm
                                }
                            }
                        />
                    }
                )
            }

        </FlexBox>
    </FlexBox>
}