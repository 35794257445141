import { BRANCH_MANAGEMENT } from 'application/constants'


export const getConnectionType = (elements, sourceId, targetId) => {
    const source = elements.find(e => e.id === sourceId)
    const target = elements.find(e => e.id === targetId)
    
    if(source.nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE || target.nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE){
        return BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_ROLE
    }
    return BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_BRANCH

}