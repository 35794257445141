import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { PureSelect } from 'application/components/controls/form'
import { getBranchContractorIds, getContractorsListByIds } from 'application/redux/selectors'
import { CONTRACTOR_TYPE } from 'application/constants/contractors'
import { useTranslation } from 'react-i18next'


const StyledContractorSelect = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &>label {
        margin-bottom: 4px;
        font-size: 12px;
    }
`

export const ContractorSelect = ({
    contractorType = CONTRACTOR_TYPE.EXPERT,
    branchId,
    label = 'contractor',
    onContractorChange
}) => {
    const {t} = useTranslation()

    const contractorIds = useSelector(getBranchContractorIds(branchId, contractorType))
    const filteredContractorsList = useSelector(getContractorsListByIds(contractorIds, contractorType))

    const options = filteredContractorsList.map(c => ({
        value: c.id,
        label: c.name,
        contractor: c
    }))

    const handleChange = (option) => {
        onContractorChange(option?.contractor || null)
    }

    return <StyledContractorSelect>
        <label 
            className='label' 
        >
            {label}
        </label>
        <PureSelect
            options={options}
            onChange={handleChange}
            placeholder={t('Search expert')}
            isClearable={true}
        />
    </StyledContractorSelect>
}