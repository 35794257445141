import React from 'react'
import styled, { withTheme } from 'styled-components'
import { QRCodeCanvas } from 'qrcode.react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Paragraph } from 'application/components/fragments/typography'



const QrCodeWrapper = styled.div`
    width: ${props => props.edge};
    height: ${props => props.edge};

    & canvas{
        width: inherit !important;
        height: inherit !important;
    }
`

export const QrCode = withTheme(({
    htmlId = 'qrCode',
    url,
    showClearTextUrl = true,
    showImage = true,
    level = 'M',
    theme,
    edge = '250px'
}) => {
    const imageSrc = '/images/logos/esy360_logo_qr.png'

    const imageSettings = showImage 
        ? { 
            src: imageSrc,
            width: 200,
            height: 242
        }
        : null

    return <FlexBox 
        flexDirection='column' 
        justifyContent='center'
        alignItems='center'
        flexBasis={edge}
    >
        <QrCodeWrapper edge={edge} >
            <QRCodeCanvas
                id={htmlId}
                value={url}
                size={1000}
                includeMargin={true}
                imageSettings={imageSettings}
                level={level}
            />
        </QrCodeWrapper>
        {
            showClearTextUrl && <FlexBox
                justifyContent='center'
                alignItems='center'
                paddingTop='16px'
                cssRules={`font-size:${theme.fontSize.small};word-break:break-all;`}
            >
                <Paragraph cssAlign='center'>
                    {url}
                </Paragraph>
            </FlexBox>
        }
    </FlexBox>
})