import React from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { Trans, useTranslation } from 'react-i18next'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { PushBottom16, PushBottom24, Spacer } from 'application/components/pages/_common'
import { SimpleList } from 'application/components/building_blocks/simple_list'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const groupIsConnectedToThisBranch = (group, branchId) => {
    return group.branches.some( branch => branch.id === branchId)
}

const groupIsSetManually = (group) => {
    return !group.canAccessAllBranches 
}

const countGroupConnectionsOrManuallySetting = (userGroups, branchId) => {
    return userGroups
        .filter(g => groupIsConnectedToThisBranch(g, branchId) && groupIsSetManually(g))
        .length
}


const RemoveBranchComponent = withDialogDataLoader( 
    (
        { 
            abort, 
            dialog, 
            onRemoveBranch,
            branchData,
            userGroups
        }
    ) => {

        const {
            branchId,
            damagesTotal,
            hasKickstartSharedResource = false
        } = dialog.payload
 
        const branchName = branchData.name
        const amountOfConnectedGroups = countGroupConnectionsOrManuallySetting(userGroups, branchId)
        const hasDamages = damagesTotal > 0
        const branchIsConnected = amountOfConnectedGroups > 0
        const notDeletable = hasDamages || branchIsConnected
   
        const {t} = useTranslation()



        const messages = {
            success: [],
            error: []
        }


        hasDamages && messages.error.push(
            damagesTotal > 1 
                ? damagesTotal + ' ' + t('damages are assigned to this branch')
                : damagesTotal + ' ' + t('damage is assigned to this branch') 
        )

        branchIsConnected && messages.error.push(
            amountOfConnectedGroups > 1 
                ? amountOfConnectedGroups + ' ' + t('groups are connected to this branch')
                : amountOfConnectedGroups + ' ' + t('group is connected to this branch')
        )


        !hasDamages && messages.success.push(
            t('no damages are assigned to this branch')
        )

        !branchIsConnected && messages.success.push(
            t('no groups are connected to this branch')
        )
 
        const hasErrorMessages = messages.error.length > 0
        const hasSuccessMessages = messages.success.length > 0



        const handleRemoveBranch = () => {
            const payload = {}
            const metaData = {branchId}

            onRemoveBranch(
                dialog.name,
                payload,
                metaData
            )
        }
  

        return <React.Fragment>
            <FlexBox 
                justifyContent='center'
                paddingBottom='100px'
                flexDirection='column'
                paddingLeft='16px'
                paddingRight ='16px'
            >
                {
                    hasErrorMessages && <FlexBox flexDirection='column'>
                        <PushBottom16/>
                        <InfoBox
                            type={INFOBOX.TYPES.ALERT} 
                            direction='row' 
                            cssMargin='0' 
                            cssPadding='16px'
                        >
                            <FontIcon icon='warning' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <FlexBox flexDirection='column'>
                                    <span>
                                        {t('This branch')}  &nbsp; "<strong>{branchName}</strong>" &nbsp;{t('Cannot be deleted.')}
                                    </span>
                                    <span>
                                        {t('This feature will be available in one of the next releases.') } 
                                    </span>
                                </FlexBox>
                                <PushBottom16/>
                                <FlexBox>
                                    <SimpleList 
                                        list={messages.error}
                                        iconKey='close'
                                        type = 'error'
                                        iconSize = '14px'
                                    />   
                                </FlexBox>
                            </FlexBox>
                        </InfoBox>       
                    </FlexBox>
                }
                {
                    hasKickstartSharedResource && <FlexBox flexDirection='column'>
                        <PushBottom16/>
                        <InfoBox
                            type={INFOBOX.TYPES.WARNING} 
                            direction='row' 
                            cssMargin='0' 
                            cssPadding='16px'
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <FlexBox flexDirection='column'>
                                    <span>
                                        { t('A Kickstart QR code exists for this branch. If you delete the branch, you will no longer be able to use the kickstart function') }
                                    </span>
                                </FlexBox>
                            </FlexBox>
                        </InfoBox>
                        <PushBottom16/>      
                    </FlexBox>                    
                }
                {
                    !hasErrorMessages 
                && hasSuccessMessages 
                && <FlexBox flexDirection='column'>
                    <Trans i18nKey='do you want to delete this branch?'>
                        {{branchName}}
                    </Trans>
                    <PushBottom24/>
                    <FlexBox>
                        <SimpleList 
                            list={messages.success}
                            iconKey='check'
                            type = 'success'
                        /> 
                    </FlexBox>
                </FlexBox>                
                }
            </FlexBox>
            <Footer
                onAbort={abort}
                onSubmit={handleRemoveBranch}
                submitText='Delete branch'
                disabled={notDeletable}
            />
        </React.Fragment>
    }
)

export {RemoveBranchComponent}