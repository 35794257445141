import { IMAGES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledHeader = styled.span`
    display: flex;
    align-items: center;
    & img{
        margin-right: 8px;
        position: relative;
        top: 1px;
        height: 26px;
    }
`

export const AdditionalHeader = ({title}) => {
    const { t } = useTranslation()
    return <StyledHeader>
        <img 
            src={IMAGES.DEKRA.SIGN}
            alt='dekra' 
        />
        <span>{t(title)}</span>
    </StyledHeader>
}