import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api, dataMappers } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { notNull } from 'application/common/data_helpers'

export function* dialogCreateInvoiceLawyerCommunicationSaga(action) {
    
    const {
        invoice
    } = action.payload

    const payload = notNull(invoice) 
        ? action.payload.invoice
        : action.payload

    const convertedPayload = dataMappers.exportMappers.invoices.createInvoice(
        payload
    )
    
    yield put(
        showWaitingScreen('Creating invoice')
    )

    let invoiceResponse = yield resourceCall(
        api.gateway.invoices.postInvoice, 
        {
            damageId: action.metaData.parentId
        }, 
        convertedPayload
    )
    
    action.metaData.invoiceId = invoiceResponse.id

    yield put(
        showToast(action.metaData.successMsg)
    )
    
    yield put(
        hideWaitingScreen()
    )
}