import React from 'react'
import { 
    CardWrapper,
    CardContent,
    CardMeta,
    CardHeadline,
    CardInfoRow
} from 'application/components/fragments/cards'
import { exists, notNull } from 'application/common/data_helpers'

export const LawfirmCard = ({
    lawfirm,
    small = false,
    children = null,
    stretch = false
}) => {
    return <CardWrapper 
        small={small}
        stretch={stretch}
    >
        <CardContent>
            <CardHeadline>
                {lawfirm.name}
            </CardHeadline>
            {
                exists(lawfirm.address) && <CardInfoRow icon='place' hasContent={true}>
                    { lawfirm.address?.line1 }
                    <br/>
                    { lawfirm.address?.postalCode } { lawfirm.address?.locality }
                </CardInfoRow>
            }
        </CardContent>
        {
            notNull(children) && <CardMeta>{children}</CardMeta>
        }
    </CardWrapper>
}