import Moment from 'moment'
import { getOffset } from './date_time_helpers'
import { notNull } from './data_helpers'


export const buildQueryString = (params) => {
    let queryString = '?'
    for (const param of params) {
        queryString += `${param.key}=${param.value}&` 
    }
    return queryString.substring(0, queryString.length - 1)
} 

export const getParamValueByKey = (paramsArray, key, fallback = '') =>{
    const entry = paramsArray.find(p => p.key === key)

    if(entry === undefined){
        return fallback
    }

    return     entry.value === undefined 
            || entry.value === null 
            || entry.value === '' 
        ? fallback 
        : entry.value.toString()
}

export const getUrlParamValueString = (paramName, fallback = '') => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if(!urlParams.has(paramName)){
        return fallback
    }
    return urlParams.get(paramName)
}

export const getUrlParamValueNumber = (paramName, fallback = 0) => {
    const value = getUrlParamValueString(paramName, fallback)
    const parsed = parseInt(value)
    if (isNaN(parsed)) { 
        return fallback 
    }
    return parsed
}

export const getUrlParamValueDate = (param, fallback) => {
    const urlParamValue = getUrlParamValueString(param, fallback)
    const date = new Date(urlParamValue)
    const dateOffset = getOffset(date)
    return new Moment(dateOffset).toISOString()
}


/**
 * generates urlKey by urlKeyName for react-router in the form "/:urlKeyName"
 * @param {string} urlKeyName name of variable to replace with value from URL "/:urlKeyName"
 */
const getURLKey = (urlKeyName) => {
    return notNull(urlKeyName) 
        ? `/:${urlKeyName}`
        : ''
}

/**
 * replaces "/:urlKeyName" with urlValue
 * @param {string} urlValue 
 * @param {object} params  prop from withRouter()
 * @param {string} urlKey 
 * @return {string} "value" 
 */
const getURLValue = (
    urlValue, 
    params, 
    urlKey
) => {
    return notNull(urlValue) 
        ? urlValue 
        : params[urlKey]
}


/**
 * replaces router variable "/:variableName" with given value
 * @param {string} urlKeyName name of variable to replace with value from URL "/:urlKeyName"
 * @param {string} urlValue value to replace string "/:variableName"
 * @param {string} href  URL string
 * @param {object} params  prop from withRouter()
 * @return {string} Url with replaced value /:urlKeyName -> /:urlValue
*/
export const setURLValue = (
    urlKeyName = null,
    urlValue = null,
    href,
    params
) => {
    const urlKey = getURLKey(urlKeyName)

    const value = getURLValue(
        urlValue, 
        params, 
        urlKey
    )

    return href.includes(urlKey) 
        ? href.replace(
            urlKey, 
            `/${value}`
        )
        : href
}



//ToDo: move to hook useRouteMatching
export const addWildCard = (routeString) => {
    return routeString + '/*'
}

//ToDo: move to hook useRouteMatching
/**
 * 
 * @param {string} path url sheme /url/:id/subUrl ...
 * @param {hook} match useMatch
 * @returns boolean
 */
export const matchRoute = (path, match) => {
    return notNull(
        match(path)
    )
}

//ToDo: move to hook useRouteMatching
/**
 * 
 * @param {string} path url sheme /url/:id/subUrl ...
 * @param {hook} match useMatch
 * @returns boolean if it matches all routes with prefix path  /path/* all following routes will be matched
 */
export const matchRoutes = (path, match) => {
    return notNull(
        match(
            { path: addWildCard(path) }
        )
    )
}