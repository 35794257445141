import React from 'react'
import { Headline3 } from 'application/components/fragments/typography'
import { StyledDescription } from './_fragments'
import { useTranslation } from 'react-i18next'
import { BulletList, BulletListItem } from 'application/components/widgets/_common'
import { PushBottom16 } from 'application/components/pages/_common'

export const RoleDeletionDescription = () => {
    const {t} = useTranslation()

    return <StyledDescription>
        <Headline3>
            {t('Users will have these permissions')}
        </Headline3>
        <PushBottom16 />
        <BulletList>
            <BulletListItem>{t('Delete damage cases')}</BulletListItem>
            <BulletListItem>{t('Delete tasks')}</BulletListItem>
            <BulletListItem>{t('Delete invoices')}</BulletListItem>
            <BulletListItem>{t('Delete customers')}</BulletListItem>
        </BulletList>
    </StyledDescription>
}