import { validationMethods } from 'application/common'
import { useCallback, useMemo } from 'react'
import parsePhoneNumber from 'libphonenumber-js'
import parseMobile from 'libphonenumber-js/mobile'
import { getFullName } from 'application/common/name_helpers'
import { CUSTOMER_ORIGIN } from 'application/constants'
import { exists } from 'application/common/data_helpers'

export const useBatchCustomerImportValidation = (queuedCustomers, forBusinessCustomers) => {
    const { getNumberType, numberType } = window.intlTelInputUtils


    const getValdidationErrors = useCallback((customer, business) => {
        const phoneNumberIsValidType = (n) =>{
            return getNumberType(n) === numberType.FIXED_LINE
                || getNumberType(n) === numberType.FIXED_LINE_OR_MOBILE
                || getNumberType(n) === numberType.MOBILE
        } 
    
        const parsedPhoneNumber = parsePhoneNumber(customer.phoneNumber.replace(/\s/g, '').replace(' ', ''))
        const parsedMobilePhoneNumber = parseMobile(customer.mobilePhoneNumber.replace(/\s/g, '').replace(' ', ''))

        let validationErrors = []

        if(typeof validationMethods.mustBeEmail(customer.email) === 'string'){
            validationErrors.push('email')
        }

        if(typeof validationMethods.notEmpty(customer.firstName) === 'string' && !business){
            validationErrors.push('firstName')
        }

        if(typeof validationMethods.notEmpty(customer.lastName) === 'string'){
            validationErrors.push('lastName')
        }

        if(exists(customer.phoneNumber)){
            if(parsedPhoneNumber === undefined || !parsedPhoneNumber.isValid() || !phoneNumberIsValidType(customer.phoneNumber)){
                validationErrors.push('phoneNumber')
            }
        }

        if(exists(customer.mobilePhoneNumber)){
            if(parsedMobilePhoneNumber === undefined || !parsedMobilePhoneNumber.isValid() || !phoneNumberIsValidType(customer.mobilePhoneNumber)){
                validationErrors.push('mobilePhoneNumber')
            }
        }

        return validationErrors
    }, [getNumberType, numberType])

    const heuristicCustomerMapping = (rawCustomer) => {
        const customer = {
            ...rawCustomer,
            email: rawCustomer.email.replace(' ', ''),
            phoneNumber: rawCustomer.phoneNumber.replace(/\s/g, '').replace(' ', ''),
            mobilePhoneNumber: rawCustomer.mobilePhoneNumber.replace(/\s/g, '').replace(' ', '')
        }

        const isEmail = value => {
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
        }

        const isMobilePhoneNumber = value => {
            return value.startsWith('++491') || value.startsWith('+491') || value.startsWith('01')
        }

        const getCountryPhoneNumber = value => {
            if(!exists(value)){
                return ''
            }

            if(value.startsWith('++') || value.startsWith('+')){
                return value
            }

            if(value.startsWith('0')){
                return `+49${value.substring(1)}` //'+49' + value.substring(1)
            }

            return ''
        }

        return {
            ...customer,
            email: isEmail(customer.email) 
                ? customer.email 
                : isEmail(customer.mobilePhoneNumber) 
                    ? customer.mobilePhoneNumber 
                    : isEmail(customer.phoneNumber) 
                        ? customer.phoneNumber 
                        : '',
            mobilePhoneNumber: isMobilePhoneNumber(customer.mobilePhoneNumber) ? getCountryPhoneNumber(customer.mobilePhoneNumber) : '',
            phoneNumber: getCountryPhoneNumber(customer.phoneNumber)
        }
    }

    const writeCustomerData = useCallback((customer, index, rawCustomer, business) => {
        const {
            licensePlate,
            make,
            model,
            chassisNumber,
            city,
            postalCode,
            street,
            firstName,
            lastName,
            ...customerRest
        } = customer

        let annotation = 'Der Kunde wurde aus folgenden Rohdaten importiert: \n\n'

        for (const key in rawCustomer) {
            if (Object.hasOwnProperty.call(rawCustomer, key)) {
                const value = rawCustomer[key]
                annotation += `${key}: ${value} \n`
            }
        }

        return {
            customer: business 
                ? {
                    ...customerRest,
                    origin: CUSTOMER_ORIGIN.BATCH_IMPORT,
                    companyName: lastName,
                    isCompany: true,
                    address: {
                        line1: street,
                        postalCode,
                        locality: city
                    },
                    preferredVehicleInfo: {
                        licensePlate,
                        make,
                        model,
                        chassisNumber
                    },
                    annotation
                }
                : {
                    ...customerRest,
                    firstName,
                    lastName,
                    origin: CUSTOMER_ORIGIN.BATCH_IMPORT,
                    address: {
                        line1: street,
                        postalCode,
                        locality: city
                    },
                    preferredVehicleInfo: {
                        licensePlate,
                        make,
                        model,
                        chassisNumber
                    },
                    annotation
                },
            rawCustomer,
            index,
            isValid: getValdidationErrors(customer, business).length === 0,
            importErrors: getValdidationErrors(customer, business).join(', '), 
            name: getFullName(customer)
        }
    }, [getValdidationErrors])

    const repairCustomer = useCallback(customer => {
        const mappedCustomer = heuristicCustomerMapping(customer)
        const parsedPhoneNumber = parsePhoneNumber(mappedCustomer.phoneNumber)
        const parsedMobilePhoneNumber = parseMobile(mappedCustomer.mobilePhoneNumber)

        const formattedPhoneNumber = parsedPhoneNumber?.formatInternational() || ''
        const formattedMobilePhoneNumber = parsedMobilePhoneNumber?.formatInternational() || ''
        return {
            ...mappedCustomer,
            phoneNumber: formattedPhoneNumber,
            mobilePhoneNumber: formattedMobilePhoneNumber
        }
    }, [])

    const cleanupCustomer = useCallback((customer, business) => {

        const validationErrors = getValdidationErrors(customer, business)

        if(validationErrors.includes('phoneNumber')){
            customer.phoneNumber = ''
            validationErrors.splice(validationErrors.indexOf('phoneNumber'), 1)
        }

        if(validationErrors.includes('mobilePhoneNumber')){
            customer.mobilePhoneNumber = ''
            validationErrors.splice(validationErrors.indexOf('mobilePhoneNumber'), 1)
        }

        if(validationErrors.includes('email')){
            customer.email = ''
            validationErrors.splice(validationErrors.indexOf('email'), 1)
        }

        return {
            ...customer
        }
    }, [getValdidationErrors])

    const processedCustomers = useMemo(()=>{
        return queuedCustomers.map((rawCustomer,index) => {
            const customer = writeCustomerData(rawCustomer, index, rawCustomer, forBusinessCustomers)
            const repairedCustomer = writeCustomerData(repairCustomer(rawCustomer), index, rawCustomer, forBusinessCustomers)
            const cleanedCustomer = writeCustomerData(cleanupCustomer(repairCustomer(rawCustomer), forBusinessCustomers), index, rawCustomer, forBusinessCustomers)

            return {
                rawCustomer: customer,
                repairedCustomer,
                cleanedCustomer
            }
        })
    },[cleanupCustomer, queuedCustomers, repairCustomer, writeCustomerData, forBusinessCustomers])

    const validCustomers = useMemo(()=>{
        return processedCustomers.map(c => c.rawCustomer).filter(c => c.isValid)
    }, [processedCustomers])
    
    const invalidCustomers = useMemo(()=>{
        return processedCustomers.map(c => c.rawCustomer).filter(c => !c.isValid)
    }, [processedCustomers])

    const validRepairedCustomers = useMemo(()=>{
        return processedCustomers.map(c => c.repairedCustomer).filter(c => c.isValid)
    }, [processedCustomers])

    const invalidRepairedCustomers = useMemo(()=>{
        return processedCustomers.map(c => c.repairedCustomer).filter(c => !c.isValid)
    }, [processedCustomers])

    const validCleanedCustomers = useMemo(()=>{
        return processedCustomers.map(c => c.cleanedCustomer).filter(c => c.isValid)
    }, [processedCustomers])

    const invalidCleanedCustomers = useMemo(()=>{
        return processedCustomers.map(c => c.cleanedCustomer).filter(c => !c.isValid)
    }, [processedCustomers])

    return {
        validCustomers,
        invalidCustomers,
        validRepairedCustomers,
        invalidRepairedCustomers,
        validCleanedCustomers,
        invalidCleanedCustomers
    }
}