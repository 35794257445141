import {useState} from 'react'

export const useGeoLocationTracking = (
    callback=()=>{}
) => {
    const geolocationHelpLinks = [
        {
            browser: 'Chrome & Edge',
            link: 'https://support.google.com/chrome/answer/142065?hl=de'
        },
        {
            browser: 'Firefox',
            link: 'https://support.mozilla.org/de/kb/gibt-firefox-meinen-standort-websites-frei#w_widerruf-einer-berechtigung-die-sie-einer-website-erteilt-haben'
        }
    ]

    const [geoLocationBlocked, setGeoLocationBlocked] = useState(false)
    const [trackingSuccessful, setTrackingSuccessful] = useState(false)
    const [trackingPending, setTrackingPending] = useState(false)

    const getMessage = (position) => {
        return position === null ? '' : `${position.coords.latitude},${position.coords.longitude}`
    }

    const geoLocationSuccess = (position) => {
        setTrackingPending(false)
        setGeoLocationBlocked(false)
        setTrackingSuccessful(true)
        callback({
            message: getMessage(position), 
            position,
            success: true,
            error: null
        })
    }
      
    const geoLocationError = (error) => {
        setTrackingPending(false)
        setGeoLocationBlocked(true)
        callback({
            message: getMessage(null), 
            position: null,
            success: false,
            error
        })
    }

    const trackLocation = () => {
        setTrackingPending(true)
        navigator.geolocation.getCurrentPosition(geoLocationSuccess, geoLocationError)
    }

    return {
        trackLocation,
        geoLocationBlocked,
        trackingSuccessful,
        geolocationHelpLinks,
        getMessage,
        trackingPending
    }
}