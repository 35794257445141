import { connect } from 'react-redux'
import { EditableInvoiceComponent } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'



const mapStateToProps = state => ({
    accountId: state.auth.currentAccount.id
})


const mapDispatchToProps = dispatch => {
    return {
        addDocuments: (
            parentId, 
            invoiceId, 
            invoiceData,
            dialogToOpenData,
            shouldOpenDialog
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.ADD_DOCUMENTS_TO_INVOICE, 
                true, 
                { 
                    parentId, 
                    damageId: parentId,
                    invoiceId,
                    dialogToOpenData,
                    shouldOpenDialog,
                    ...invoiceData 
                }, 
                'Add documents to invoice'
            )
        ),
        addPayment: (
            parentId, 
            payload,
            dialogToOpenData,
            shouldOpenDialog
        )  => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_PAYMENT_LAWYER_COMMUNICATION, 
                true, 
                {
                    parentId,
                    dialogToOpenData,
                    shouldOpenDialog,
                    ...payload
                }
                , 
                'Add payment'
            )
        ),
        cancelPayment: (
            parentId, 
            payload,
            dialogToOpenData,
            shouldOpenDialog
        )  => dispatch(
            openDialogByName(
                DIALOG.NAME.CANCEL_PAYMENT_LAWYER_COMMUNICATION, 
                true, 
                {
                    parentId,
                    dialogToOpenData,
                    shouldOpenDialog,
                    ...payload
                }, 
                'Cancel payment'
            )
        ),
        deleteInvoice: (
            parentId, 
            payload,
            dialogToOpenData,
            shouldOpenDialog
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_INVOICE_LAWYER_COMMUNICATION, 
                true, 
                { 
                    parentId, 
                    dialogToOpenData,
                    shouldOpenDialog,
                    ...payload 
                }, 
                'Delete payment'
            )
        ),

    }
}


export const EditableInvoice =  connect(
    mapStateToProps,
    mapDispatchToProps
)(EditableInvoiceComponent)