import React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'



export const VehicleKeeperChangedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const oldName = activity.data?.oldVehicleKeeperFullName || ''
    const newName = activity.data?.newVehicleKeeperFullName || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Vehiclekeeper changed')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {oldName} <RightArrow /> {newName}
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

VehicleKeeperChangedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}