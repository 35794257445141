import { connect } from 'react-redux'
import { WidgetLoadMoreButton as WidgedLoadMoreButtonComponent } from './component'
import { requestWidgetData } from 'application/redux/actions/widgets'



const mapStateToProps = (state, props) => ({
    widget: state.widgets.registeredWidgets.find(w => w.key === props.widgetKey)
})

const mapDispatchToProps = dispatch => {
    return {
        onLoadMore: (
            key,
            widgetType,
            payload,
            match,
            urlParams = [],
            dependentWidgetKeys = [],
            dependsOnOtherWidget = false
        ) => dispatch(
            requestWidgetData(
                key,
                widgetType,
                payload,
                match,
                urlParams,
                dependentWidgetKeys,
                dependsOnOtherWidget
            )
        )
    }
}

export const WidgetLoadMoreButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(WidgedLoadMoreButtonComponent)