import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { LawfirmForms } from 'application/components/forms'
import { PushBottom24 } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'


export const LawfirmAktenStatusComponent = (
    {
        widgetKey,
        payload, 
        loadingFailed, 
        onSave
    }
) => {
    const {t} = useTranslation()
    const { formData } = payload

    const handleSave = (payload) => {
        onSave(widgetKey, payload)
    } 

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('Akten status data could not be loaded')}
        </WidgetErrorMessage>
    }
    
    return <FlexBox flexDirection='column'>
        <PushBottom24 />
        <LawfirmForms.LawfirmAktenStatusForm
            onSave={handleSave}
            formData={formData}
        />
    </FlexBox>
}

LawfirmAktenStatusComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}