import React   from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import {StepsBar} from 'application/components/building_blocks/steps_bar'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { useTranslation } from 'react-i18next'




export const DialogStepsLayoutComponent = (
    {
        getStepsState,
        children,
        dialogStepsWaitingScreen = {
            show: false,
            untranslatedMessage: ''
        },
    }
) => {
    const {t} = useTranslation()

    const {
        show,
        untranslatedMessage
    } = dialogStepsWaitingScreen

    return <React.Fragment>
        <FlexBox
            flexDirection='column'
            cssRules='
                padding: 0 16px;
                overflow-y: auto;
                min-height: 480px;
            '
        >
            <PushBottom16 />
            <StepsBar         
                getStepsState={getStepsState}
            />
            <PushBottom32/>
            {
                show 
                    ? <FlexBox alignItems='center' height='100%'>
                        <ThemedPreloader 
                            preloaderText={t(untranslatedMessage)} 
                        />
                    </FlexBox>
                    : <React.Fragment>
                        {children}
                    </React.Fragment>
            }
        </FlexBox>
    </React.Fragment>
}