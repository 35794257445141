import React  from 'react'
import {StyledMenuSidebarIcon} from './fragments'
import PropTypes from 'prop-types'



export const MenuIconLink = (
    {
        hoverBackground = '',
        hoverColor ='',
        isClickable = false,
        onClick = () => {},
        iconKey = 'menu',
        iconSize = '18px',
        toolTip = '',
        secondIcon = <React.Fragment/>
    }
) => {


    const handleOnClick = () => {
        return isClickable 
            ? onClick()
            : () => {}
    }

    const cursor = isClickable ? 'pointer' : 'auto'

    return  <StyledMenuSidebarIcon 
        className='jest-sidebar-menu-icon-link'
        cursor={cursor}
        onClick={handleOnClick}
        hoverBackground={hoverBackground}
        iconSize={iconSize}
        title={toolTip}
        hoverColor={hoverColor}
    >
        <i className="material-icons">{iconKey}</i>
        {secondIcon}
    </StyledMenuSidebarIcon>
}

MenuIconLink.propTypes = {
    hoverBackground: PropTypes.string,
    hoverColor: PropTypes.string,
    isClickable: PropTypes.bool,
    onClick: PropTypes.func,
    iconKey: PropTypes.string,
    iconSize: PropTypes.string,
    toolTip: PropTypes.string,
    secondIcon: PropTypes.object
}