import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateDynamicFormLiabilityData = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                `dynamicform/damages/${params.damageId}/${params.endpoint}`,
                HTTP_METHOD.PUT,
                payload
            )
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(`external/qr/${params.sharedResourceId}/dynamicform/damages/${params.damageId}/${params.endpoint}`,
                params.pin,
                HTTP_METHOD.PUT,
                payload)
            return apiCall(request)
        },
    }
)