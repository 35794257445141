import {
    LesserOrEqualThanFilter,
    StringEqualsFilter,
} from '@ec/filter-sort-page-query-js'

export const announcements = {
    type: {
        filterName: 'announcements_type',
        filterKey: 'aat',  //all announcements types
        label: 'Announcement type',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter(
                'type', 
                value, 
                true
            )
        ])
    },
    pastAnnouncements: {
        filterName: 'announcements_past',
        filterKey: 'apa',  //all past announcements
        label: 'Announcement of the past',
        isStatic: true,
        defaultValue: null,
        getFilterQuery: () => {
            const today = new Date().toISOString().split('.')[0]
            return [
                new LesserOrEqualThanFilter('releaseAt', today, true)
            ]
        }
    },
}