import { fetchRequestBuilderWithoutToken, fetchHandler, limiter, HTTP_METHOD } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'


export const getSharedResourceById = (sharedResourceId) => {
    const request = fetchRequestBuilderWithoutToken(
        `sharedResources/${sharedResourceId}`, 
        HTTP_METHOD.GET,
        {},
        false
    )
    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}