import { useSteps } from 'application/components/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'




export const withCommissionLawfirmSteps = (initialStepIndex = 0) => (Component) => (
    { ...props }
) => {
    const { t } = useTranslation()

    const initialSteps = [
        {
            id: 0,
            label: t('Choose lawfirm')
        },
        {
            id: 1,
            label: t('Check customer data')
        },
        {
            id: 2,
            label: t('Documents & images')
        }
    ]


    const {
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep
    } = useSteps(initialSteps, initialStepIndex)


    return <Component 
        {...props} 
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        getCurrentStep={getCurrentStep}
        getStepsState={getStepsState}
    />
}