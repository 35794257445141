
import React from 'react'
import { SelectableTableRowBase } from './base'



export const MoSelectableTableRow = (
    {
        element,
        selectItemByMOName,
        deselectItemByMOName,
        isSelected = false,
        isColoured = false,
        moName='',
        children,
        cssHeight
    }
) => {
    const handleSelect = (element) => {
        selectItemByMOName(moName, element)
    }

    const handleDeselect = (elementId) => {
        deselectItemByMOName(moName, elementId) 
    }

    return <SelectableTableRowBase
        element = {element}
        selectItem = {handleSelect}
        deselectItem = {handleDeselect}
        isSelected = {isSelected} 
        isColoured = {isColoured} 
        cssHeight = {cssHeight}
    >
        {children}
    </SelectableTableRowBase>
}