import React from 'react'
import { ROUTES } from 'application/constants'
import {DamageLicensePlate} from 'application/components/controls/damage_licenseplate'
import { DamageTypeName } from 'application/components/name_mappings/damage_type_name'
import {JobNumber} from 'application/components/controls/job_number'
import { FullName } from 'application/components/controls/full_name'
import {DateComponent} from 'application/components/controls/date'
import { FlexBox } from 'application/components/fragments/flex_box'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'
import { EditableDamageStatus } from 'application/components/controls/editable_damage_state'




export const configuration = {
    //no pagination (hide=true) -> no tableName needed
    sorting: {
        sortParamKey: '',
        directionParamKey: '',
        defaultSortParamValue: '',
        defaultDirectionParamValue: ''
    },
    pagination: {
        hidePagination: true
    },
    columns: [
        {
            width: 15,
            label: 'A.-Nr.',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <JobNumber number={element.jobNumber} />
        },
        {
            width: 11,
            label: 'License plate',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DamageLicensePlate damage={element}/>
        },
        {
            width: 14,
            label: 'Damage Type',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => <DamageTypeName
                damageType={element.type} 
                isSelected={isSelected}
            />
        },
        {
            width: 20,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: (
                {element, isSelected}
            ) => <EditableDamageStatus
                element={element}
                isSelected={isSelected}
            />
        },
        {
            width: 15,
            label: 'Assignee',
            secondLabel: 'Lawfirm',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FlexBox flexDirection='column'>
                <FullName person={element.assignee} />
                <LawFirmName lawFirm={element.lawFirm} />
            </FlexBox>
        },
        {
            width: 15,
            label: 'last change',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DateComponent date={element.modifiedAt} />
        }
    ],
    ContextMenu: () => null,
    contextMenuWidth: 0,
    rowHeight: '36px',
    rows: {
        linked: {
            Component: null,
            additionalProps: {
                href: (element, applicationLinksHook) => applicationLinksHook.internalAccountLink(
                    ROUTES.DAMAGE_MANAGER_BASE + element.id + '/overview'
                )
            }
        },
        selectable: null,
        details: null
    },
    massOperations: {
        available: false
    }
}
