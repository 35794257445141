import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'
import { moClearAllItemsByName } from 'application/redux/actions/mass_operations'
import { resetDocumentCenter } from 'application/redux/actions/pages/damage_manager/documents'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogMoDocumentsIsExportedToLawfirmSaga(action) {
    
    const { selectedDocuments } = action.payload

    const { 
        damageId, 
        moName
    } = action.metaData  

    yield put(
        showWaitingScreen('Marking for legal software...')
    )

    for(const document of selectedDocuments){
        yield put(
            addNewSubMessage(document.fileName + ' ... ')
        )

        yield resourceCall(
            api.gateway.attachments.updateAttachment,
            {
                damageId: damageId,
                attachmentId: document.id
            },
            {
                isExportedToLawFirm: true
            }
        )
        yield put(
            addToLastSubMessage('✓')
        )
    }

    yield put(
        moClearAllItemsByName(moName)
    )
    yield put(
        resetDocumentCenter()
    )
    yield put( 
        showToast('Documents were made available') 
    )
    yield put( 
        hideWaitingScreen() 
    )
}