import i18next from 'i18next'
import { applicationToken } from 'application/storage'
import { buildApiUrl } from 'application/api/helpers'
import { HTTP_METHOD } from 'application/api/request_builders'
import { store } from 'application/redux/create_store'

export const fileUploadRequestBuilder = (
    apiEndpoint, 
    file, 
    params = [], 
    absolute = false, 
    omitToken = false,
    omitFileName = false,
    method = HTTP_METHOD.POST
) => {
    const url = buildApiUrl(apiEndpoint, absolute)
    const state = store.getState()
    
    let formData = new FormData()
    if (file !== null) {
        formData.append('File', file, file.name)
        if(!omitFileName){
            formData.append('Name', file.name)
        }        
    }
    

    for(const param of params){
        formData.append(param.key, param.value)
    }

    let data = {
        method,
        headers: { 
            'Accept-Language': i18next.language
        },
        mode: 'cors',
        body: formData
    }
    
    data.headers = !omitToken ? {
        ...data.headers,
        'authorization': 'Bearer ' + applicationToken.get(state.auth.currentAccountId)
    } : data.headers

    return {
        data,
        url
    }
}