import styled from 'styled-components'

/**
 * @param {number} sideBarWidth - defines width of sidebar 
 * @param {number} marginLeft - defines left margin of sidebar, in order to open/close the sidebar
 */

export const MenuSidebar = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex: 1 0 ${props => props.width};
    width: ${props => props.width};
    max-width: ${props => props.width};
    height: calc(100vh - 48px);
    background-color: ${props => props.theme.color.gray10};
`