import React from 'react'
import {
    StyledDamageManagerVehicleGradient,
    StyledDamageManagerVehicleHeader,
    StyledDamageManagerVehicleBody
} from './fragments'
import { LicensePlate } from 'application/components/controls/license_plate'
import { MakeModel } from 'application/components/controls/make_model'

export const DamageManagerVehicle = ({
    damageLoaded,
    vehicle
}) => {
    return <React.Fragment>
        <StyledDamageManagerVehicleHeader>
            <StyledDamageManagerVehicleGradient>
                {
                    damageLoaded && <LicensePlate 
                        licensePlate={vehicle?.licensePlate} 
                    />
                }
            </StyledDamageManagerVehicleGradient>
        
        </StyledDamageManagerVehicleHeader>
        <StyledDamageManagerVehicleBody>
            {
                damageLoaded && <MakeModel 
                    make={vehicle?.make} 
                    model={vehicle?.model} 
                />
            }
        </StyledDamageManagerVehicleBody>
    </React.Fragment>
}