import {  hideAttachmentDetailsDCWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/documents'
import { hideAttachmentDetailsWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { connect } from 'react-redux'
import {LawfirmComponent} from './component'


const mapStateToProps = state => {
    return {
        selectedTaskLoaded: state.pages.workflowPanel.tasks.selectedTaskLoaded,
        tasksAttachmentDetails: state.pages.workflowPanel.tasks.attachmentDetails,
        documentCenterAttachmentDetails: state.pages.workflowPanel.documentCenter.attachmentDetails,
        // hasAttachments: state.pages.workflowPanel.documentCenter.documents.length > 0,
        // contactPerson: state.pages.workflowPanel.userData.ansprechpartner,
        documentCenterArchiveDownloading: state.pages.workflowPanel.documentCenter.archiveDownloading,
        damageLoaded: state.pages.workflowPanel.shared.damageLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onCreateInvoice: (sharedResourceId) => dispatch(openDialogByName(DIALOG.NAME.CREATE_INVOICE_WORKFLOW_PANEL, true, { sharedResourceId }, 'Create invoice')),
        onTaskAttachmentDetailsClose: () => dispatch(hideAttachmentDetailsWorkflowPanel()),
        onDocumentCenterAttachmentDetailsClose: () => dispatch(hideAttachmentDetailsDCWorkflowPanel()),
        // onDownloadAttachmentsWFPZipRequest: (sharedResourceId, contactPerson) => dispatch(downloadAttachmentsWFPZipRequest(sharedResourceId, contactPerson))
    }
}

export const Lawfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmComponent)