import { combineReducers } from 'redux'
import { reducer as oidcReducer } from 'redux-oidc'
import {authReducer} from './auth'
import {notificationsReducer} from './notifications'
import {dialogsReducer}  from './dialogs'
import {pagesReducer} from './pages'
import {widgetsReducer} from './widgets'
import {widgetContextsReducer} from './widget_contexts'
import {commonReducer} from './common'
import {attachmentsReducer} from './attachments'
import {waitingScreenReducer} from './waiting_screen'
import {galleryReducer} from './gallery'
import { ACTIONS } from 'application/constants'
import {defaultStore} from './default_store'
import {registrationReducer} from './registration'
import {realtimeNotificationsReducer} from './realtime_notifications'
import {massOperationsReducer} from './mass_operations'
import {sharedResourceReducer} from './shared_resource'
import {mainNavigationSidebarReducer} from './main_navigation_sidebar'
import {updateSchedulerReducer} from './update_scheduler'
import {errorsReducer} from './errors'
import {dialogStepsWaitingScreenReducer} from './dialog_steps_waiting_screen'
import {accountLogoReducer} from './account_logo'
import {damagesSearchReducer} from './damages_search'
import { modulesReducer } from './modules'
import { uiSettingsReducer } from './ui_settings'
import { contractorsReducer } from './contractors'
import {documentsByCategoriesReducer} from './documents_by_categories'
import { featureFlagsReducer } from './feature_flags'
import { lawfirmsReducer } from './lawfirms'

const reducers = combineReducers({
    auth: authReducer,
    attachments: attachmentsReducer,
    notifications: notificationsReducer,
    dialogs: dialogsReducer,
    pages: pagesReducer,
    widgets: widgetsReducer,
    widgetContexts: widgetContextsReducer,
    common: commonReducer,
    waitingScreen: waitingScreenReducer,
    gallery: galleryReducer,
    registration: registrationReducer,
    realtimeNotifications: realtimeNotificationsReducer,
    oidc: oidcReducer,
    massOperations: massOperationsReducer,
    sharedResource: sharedResourceReducer,
    mainNavigationSidebar: mainNavigationSidebarReducer,
    updateScheduler: updateSchedulerReducer,
    errors: errorsReducer,
    dialogStepsWaitingScreen: dialogStepsWaitingScreenReducer,
    accountLogo: accountLogoReducer,
    modules: modulesReducer,
    damagesSearch: damagesSearchReducer,
    uiSettings: uiSettingsReducer,
    contractors: contractorsReducer,
    documentsByCategories: documentsByCategoriesReducer,
    featureFlags: featureFlagsReducer,
    lawfirms: lawfirmsReducer
})

export const rootReducer = (state, action) => {
    switch (action) {
    case ACTIONS.AUTH_LOGOUT_REQUEST:
        return {
            ...defaultStore, //reset the complete store on logout
            //except:
            auth: state.auth, //is handled by auth reducer -> case ACTIONS.AUTH_LOGOUT_REQUEST:
            notifications: state.notifications //notifications should survive logout
        }
    default:
        return reducers(state, action)
    }
}
