import React from 'react'
import { TaskLink, RelatedDamageLink } from 'application/components/controls/activity_links/common'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'




export const TaskActivityLinkSeparateTaskData = (
    {
        activity
    }
) => {
    return <ForAnyBranchPermissions 
        permissions={
            [
                USER_PERMISSONS.TASK.READ, 
                USER_PERMISSONS.DAMAGE.READ
            ]
        } 
        allNeeded={true}
    >
        <RelatedDamageLink activity={activity} />
        <TaskLink
            activity={activity} 
            taskDataIsSeparate={true}
        />
    </ForAnyBranchPermissions>
}