import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import styled from 'styled-components'


const StyledButtonWrapper = styled.div`
    button {
        width: ${props => props.stretched ? '100%' : 'auto'};
    }
`


const WidgetLoadMoreButtonComponent = (
    {
        match,
        widget,
        widgetKey,
        widgetType,
        onLoadMore,
        list = [],
        total= 0,
        limit = 10,
        loadMoreButtonText = 'Load more',
        stretched = true
    }
) => {
    const {t} = useTranslation()
    const numberOfElementsLoaded = list.length
    const nothingLeftToLoad = numberOfElementsLoaded >= total
    const extendetLimit = numberOfElementsLoaded + limit

    const getButtonLabel = (buttonText) => {
        return `${numberOfElementsLoaded}/${total} - ` + t(buttonText)
    }

    const displayedLoadMoreText =  getButtonLabel(loadMoreButtonText)


    const handleOnLoadMore = () => {
        const payload = {
            offset: 0,
            limit: extendetLimit,
            reloaded: true
        }
        onLoadMore(
            widgetKey,
            widgetType,
            payload,
            match,
            null,
            widget.dependentWidgetKeys,
            widget.dependsOnOtherWidget
        )
    }

    return <StyledButtonWrapper stretched={stretched}>
        {
            !nothingLeftToLoad && <ActionButton 
                buttonText={displayedLoadMoreText} 
                onButtonClick={handleOnLoadMore}
            />
        }
    </StyledButtonWrapper>
}



WidgetLoadMoreButtonComponent.propTypes = {
    match: PropTypes.object.isRequired,
    widgetKey: PropTypes.string.isRequired,
    widgetType: PropTypes.string.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    list: PropTypes.array,
    total: PropTypes.number,
    limit: PropTypes.number,
    loadMoreButtonText: PropTypes.string,
    stretched: PropTypes.bool
}



export const WidgetLoadMoreButton = withRouter(
    WidgetLoadMoreButtonComponent
)