import React, { useEffect } from 'react'

import {ThemedPreloader, CenteredPreloader} from 'application/components/controls/themed_preloader'

export const DialogDataEffects = (props) => {

    const { 
        loggedIn, 
        dialogName, 
        isLoaded, 
        isLoading, 
        metaData, 
        onLoadDialogData
    } = props
    
    useEffect(
        () => {
            loggedIn
            && !isLoaded 
            && !isLoading 
            && onLoadDialogData(dialogName, metaData)
        }, 
        [
            dialogName, 
            isLoaded, 
            isLoading, 
            loggedIn, 
            metaData, 
            onLoadDialogData
        ]
    )

    if(!isLoaded){
        return <CenteredPreloader>
            <ThemedPreloader />
        </CenteredPreloader>
    }

    return <React.Fragment>
        {props.children}
    </React.Fragment>
}