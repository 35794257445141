import React from 'react'
import {LanguageSwitchHoc} from 'application/components/building_blocks/language_switch/hoc'
import * as Fragments from './fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import {MenuIconLink} from './_common/menu_icon'
import {AccordionMenu} from './_common/accordion_menu'
import { withTheme } from 'styled-components'
import {BranchSpecificAccordionMenu} from './accordion_menu_contents/open/branch_specific'
import {BranchSpecificAccordionMenuClosed} from './accordion_menu_contents/closed/branch_specific'
import {AdministrationAccordionOpen} from './accordion_menu_contents/open/administration'
import {AdministrationAccordionClosed} from './accordion_menu_contents/closed/administration'
import {AllBranchesAccordion} from './accordion_menu_contents/open/all_branches'
import {AllBranchesAccordionClosed} from './accordion_menu_contents/closed/all_branches'
import PropTypes from 'prop-types'
import { BRANCH_BASE, ADMINISTRATION_BASE, ALL_BRANCHES_BASE } from 'application/constants/routes'
import { useLocation } from 'react-router-dom'
import { AnnouncementAndFeedbackButtons } from './_common/feedback_buttons'



const openCloseSidebar = (sideBarOpen) => {
    return sideBarOpen 
        ? '210px'
        : '48px'
} 


const MainNavigationSidebar = (
    {
        auth,
        sideBarOpen,
        theme,
        onOpenChangeBranchDialog,
        onToggleSidebar,
        currentBranchLoaded,
        hasMultipleBranches
    }
) => {
    const url  = useLocation().pathname
    const sideBarWidth = openCloseSidebar(sideBarOpen, theme)
    const handleSetOpen = () => {
        onToggleSidebar()
    }

    const primaryColor = theme.color.primary
    const hoverColor = theme.color.primaryHover
    const currentBranchName = auth.currentBranch?.name || ''

    return <Fragments.MenuSidebar
        sideBarOpen={sideBarOpen}
        width={sideBarWidth}
    >
        <FlexBox
            flexDirection='column'
        >
            <FlexBox
                justifyContent='flex-end'
                height='inherit'
            >
                <MenuIconLink
                    onClick={handleSetOpen}
                    iconKey='menu'
                    color={theme.color.white}
                    isClickable={true}
                    hoverColor={theme.color.primary}
                />
            </FlexBox>
            {
                hasMultipleBranches && <AccordionMenu
                    primaryColor={primaryColor}
                    menuName={currentBranchName}
                    accordionOpen={url.includes(BRANCH_BASE)}
                    menuIconKey={'store'}
                    sideBarOpen={sideBarOpen}
                    isLoading={!currentBranchLoaded}
                > 
                    {
                        sideBarOpen 
                            ? <BranchSpecificAccordionMenu
                                onOpenChangeBranchDialog={onOpenChangeBranchDialog}
                                primaryColor={primaryColor}
                                primaryHoverColor={hoverColor}
                            />
                            : <BranchSpecificAccordionMenuClosed
                                onOpenChangeBranchDialog={onOpenChangeBranchDialog}                                
                                primaryColor={primaryColor}
                                primaryHoverColor={hoverColor}
                            />
                    }
                </AccordionMenu>  
            }
             
            <AccordionMenu
                primaryColor={
                    hasMultipleBranches 
                        ? theme.color.anthracite 
                        : primaryColor
                }
                menuName={
                    hasMultipleBranches 
                        ? 'All Branches' 
                        : currentBranchName
                }
                menuIconKey={
                    hasMultipleBranches
                        ? 'language' 
                        : 'store'
                }
                accordionOpen={url.includes(ALL_BRANCHES_BASE)}
                sideBarOpen={sideBarOpen}
                isLoading={false}
            >
                {
                    sideBarOpen 
                        ? <AllBranchesAccordion                            
                            primaryColor={
                                hasMultipleBranches 
                                    ? theme.color.anthracite 
                                    : primaryColor
                            }
                            primaryHoverColor={
                                hasMultipleBranches 
                                    ? theme.color.anthracite50 
                                    : hoverColor
                            }
                        />
                        : <AllBranchesAccordionClosed                            
                            primaryColor={
                                hasMultipleBranches 
                                    ? theme.color.anthracite 
                                    : primaryColor
                            }
                            primaryHoverColor={
                                hasMultipleBranches 
                                    ? theme.color.anthracite50 
                                    : hoverColor
                            }
                            menuName={'All Branches'}
                        />
                }
            </AccordionMenu>          
            <AccordionMenu
                primaryColor={theme.color.anthracite}
                menuName={'Administration'}
                menuIconKey={'settings'}
                accordionOpen={url.includes(ADMINISTRATION_BASE)}
                sideBarOpen={sideBarOpen}
                isLoading={false}
            >
                {
                    sideBarOpen 
                        ? <AdministrationAccordionOpen                            
                            primaryColor={theme.color.anthracite}
                            primaryHoverColor={theme.color.anthracite50}
                        /> 
                        : <AdministrationAccordionClosed
                            primaryColor={theme.color.anthracite}
                            primaryHoverColor={theme.color.anthracite50}
                            menuName={'Administration'}
                        /> 
                }
            </AccordionMenu>            
        </FlexBox>
        <AnnouncementAndFeedbackButtons collapsed={!sideBarOpen} />
        <LanguageSwitchHoc cssMargin={sideBarOpen ? '0 0 12px 13px': '0 0 12px 5px'} />
    </Fragments.MenuSidebar>
}

MainNavigationSidebar.propTypes = {
    auth: PropTypes.object.isRequired,
    sideBarOpen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
    onOpenChangeBranchDialog: PropTypes.func.isRequired,
    onToggleSidebar: PropTypes.func.isRequired,
    hasMultipleBranches: PropTypes.bool
}


export const MainNavigationSidebarComponent =  withTheme(MainNavigationSidebar)