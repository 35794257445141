import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'
import { applicationToken } from 'application/storage'
import * as storage from 'application/storage'

export const authReducer = (
    state = defaultStore.auth, 
    action
) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        applicationToken.deleteStorage()
        return defaultStore.auth
    }
    case ACTIONS.AUTH_WRITE_APPLICATION_TOKEN: {
        applicationToken.store(action.accountId, action.accessToken)
        return {
            ...state,
            accessToken: action.accessToken
        }
    }
    case ACTIONS.AUTH_POPULATE_PERMISSIONS: {
        return {
            ...state,
            allPermissions: action.permissions
        }
    }
    case ACTIONS.AUTH_POPULATE_CURRENT_BRANCH_PERMISSIONS: {
        return {
            ...state,
            permissions: action.permissions.find(p => p.branchId === action.currentBranchId).permissions
        }
    }
    case ACTIONS.AUTH_POPULATE_USER_DATA: {
        return {
            ...state,
            user: action.userData
        }
    }
    case ACTIONS.AUTH_LOGIN_SUCCESS: {
        return {
            ...state,
            loggedIn: true,
            loggedOut: false,
        }
    }   
    case ACTIONS.AUTH_RESET_LOGIN: {
        return {
            ...state,
            loggedIn: false
        }
    }  
    case ACTIONS.AUTH_SET_RETURN_URL: {
        return {
            ...state,
            returnUrl: action.returnUrl
        }
    }     
    case ACTIONS.OIDC_USER_EXPIRED: {
        applicationToken.deleteStorage()
        return {
            ...state,
            loggedOut: true,
            returnUrl: ''
        }
    }   
    case ACTIONS.AUTH_LOGOUT_REQUEST: {
        applicationToken.deleteStorage()
        return {
            ...state,
            loggedOut: true,
            manualLoggedOut: true,
            returnUrl: ''
        }
    }       
    case ACTIONS.AUTH_POPULATE_ACCOUNTS: return {
        ...state,
        accounts: {
            ...state.accounts,
            data: action.accounts,
            total: action.total,
            loading: false,
            loaded: true,
            firstAccountId: action.accounts.length ? action.accounts[0].id : null
        }
    }
    case ACTIONS.AUTH_POPULATE_ACCOUNTS_TOTAL: return {
        ...state,
        accounts: {
            ...state.accounts,
            total: action.total,
            firstAccountId: action.firstAccountId,
            firstAccountLocked: action.firstAccountLocked
        }
    }
    case ACTIONS.AUTH_SET_CURRENT_ACCOUNT_LOADED_STATE: return {
        ...state,
        currentAccountLoading: action.isLoading,
        currentAccountLoaded: action.isLoaded
    } 
    case ACTIONS.AUTH_REQUEST_CURRENT_ACCOUNT_BY_ID: {
        return {
            ...state,
            currentAccountLoaded: false,
            currentAccountLoading: true
        }
    }
    case ACTIONS.AUTH_SET_CURRENT_ACCOUNT_ID: {
        return {
            ...state,
            currentAccountId: action.accountId
        }
    }
    case ACTIONS.AUTH_POPULATE_CURRENT_ACCOUNT: {
        return {
            ...state,
            currentAccount: action.account,
            currentAccountLoaded: true,
            currentAccountLoading: false
        }
    }
    case ACTIONS.AUTH_SYNC_CURRENT_ACCOUNT: {
        return {
            ...state,
            currentAccount: {
                ...state.currentAccount,
                ...action.accountData
            }
        }
    }
    case ACTIONS.AUTH_POPULATE_DEFAULT_BRANCH_INFO: {
        return {
            ...state,
            defaultBranchId: action.accessibleBranches[0],
            hasMultipleBranches: action.accessibleBranches.length > 1
        }
    }
    case ACTIONS.AUTH_RESET_CURRENT_BRANCH: {
        storage.currentBranch.deleteStorage()
        return {
            ...state,
            currentBranch: null,
            currentBranchLoaded: false,
            currentBranchLoading: false,
            defaultBranchId: null,
            hasMultipleBranches: false
        }
    }
    case ACTIONS.AUTH_RESET_CURRENT_USER: return {
        ...state,
        user: {}
    }
    case ACTIONS.AUTH_REQUEST_CURRENT_BRANCH_CHANGE: return {
        ...state,
        currentBranch: null,
        currentBranchLoaded: false,
        currentBranchLoading: true
    }
    case ACTIONS.AUTH_POPULATE_CURRENT_BRANCH: return {
        ...state,
        currentBranch: action.branch,
        currentBranchLoaded: true,
        currentBranchLoading: false
    }
    case ACTIONS.AUTH_NO_PERMISSIONS_FOUND: {
        return {
            ...state,
            permissionsFound: false
        }
    }
    case ACTIONS.AUTH_PERMISSIONS_FOUND: {
        return {
            ...state,
            permissionsFound: true,
            accounts: {
                ...state.accounts,
                found: true
            }
        }
    } 

    case ACTIONS.AUTH_NO_ACCOUNTS_FOUND: {
        return {
            ...state,
            accounts: {
                ...state.accounts,
                found: false
            }
        }
    } 

    case ACTIONS.AUTH_ACCOUNTS_FOUND: {
        return {
            ...state,
            accounts: {
                ...state.accounts,
                found: true
            }
        }
    } 

    case ACTIONS.AUTH_SESSION_EXPIRED: {
        return {
            ...state,
            sessionExpired: true
        }
    }

    case ACTIONS.AUTH_POPULATE_PREFERRED_ACOUNT: {
        return {
            ...state,
            preferredAccount: {
                ...state.preferredAccount,
                data: action.preferredAccount,
                loaded: true,
                found: true
            }
        }
    }

    case ACTIONS.AUTH_PREFERRED_ACOUNT_NOT_FOUND: {
        return {
            ...state,
            preferredAccount: {
                ...state.preferredAccount,
                found: false,
                loaded: true
            }
        }
    }

    case ACTIONS.AUTH_PREFERRED_ACOUNT_FOUND: {
        return {
            ...state,
            preferredAccount: {
                ...state.preferredAccount,
                found: true
            }
        }
    }

    case ACTIONS.AUTH_PREFERRED_ACOUNT_REFRESH_PREFERRED_ACCOUNT: {
        return {
            ...state,
            preferredAccount: {
                ...state.preferredAccount,
                data: null,
                found: false,
                loaded: false
            }
        }
    }
    case ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_SUCCESS: {
        return {
            ...state,
            loggedIn: true
        }
    }
    case ACTIONS.SHARED_RESOURCE_LOGOUT: {
        return {
            ...state,
            loggedIn: false,
            loggedOut: true
        }  
    }
    default:
        return state
    }
}
