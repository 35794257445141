import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { CommissionOtherExpertPartialForm } from 'application/components/forms/_partial_forms'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { ActionButton } from '@ec/ui-controls-react'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { useTranslation } from 'react-i18next'
import { StyledLockedMessage } from 'application/components/building_blocks/account_box/fragments'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { useInspectionDatePlausibility, useStatefulForm } from 'application/components/hooks'





export const Step0 = (
    (
        {
            incidentDate,
            goToNextStep,
            dialogName,
            onDataChange,
            expertCommissionData,
            setExpertCommissionData,
            branchId,
            branchName = '',
            branchAddress = {}
        }
    )=>{
        const {t} = useTranslation()

        const defaultValues = {
            name: expertCommissionData?.name || '',
            email: expertCommissionData?.email || '',
            inspectionInfoName: expertCommissionData?.inspectionInfoName || branchName,
            inspectionSite: 
                expertCommissionData?.inspectionSite 
                    ? { ...expertCommissionData.inspectionSite} 
                    : { ...branchAddress }
            ,
            inspectionDate: expertCommissionData?.inspectionDate || null
        }
    
        const formHook = useStatefulForm(
            {defaultValues}
        )
    
        const {
            handleSubmit, 
            getValues,
            formStateValues
        } = formHook
    
        const {inspectionDateIsBeforeIncidentDate} = useInspectionDatePlausibility(
            formStateValues.inspectionDate,
            incidentDate
        ) 

        const handleGoToNextStep = () => {
            setExpertCommissionData( getValues() )
            goToNextStep()
        }


        return<React.Fragment>
            <DialogMainContent>
                <CommissionOtherExpertPartialForm
                    formHook={formHook} 
                    onDataChange={onDataChange}
                    dialogName={dialogName}
                    usedInDialog={true}
                    branchId={branchId}
                    includePdf={false}
                />
            </DialogMainContent>
            <StepsLayoutFooter>
                {
                    inspectionDateIsBeforeIncidentDate && <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        cssMargin='' 
                        cssPadding='8px 16px 8px 8px'
                        cssMinHeight='unset'
                        direction='row'
                    >
                        <FontIcon icon='error_outline' />
                        <Spacer basis='10px' />
                        <StyledLockedMessage>{t('The viewing date is in the past.')}</StyledLockedMessage>
                    </InfoBox> 
                }
                <IconButtonWithLabel
                    iconKey='arrow_back' 
                    label={t('Back')} 
                    disabled={true}
                    onButtonClick={()=>{}} 
                />
                <ActionButton
                    buttonText={t('Next')} 
                    onButtonClick={
                        handleSubmit(handleGoToNextStep)
                    } 
                />
            </StepsLayoutFooter>
        </React.Fragment>
    })