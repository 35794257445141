import { exists, isNull } from 'application/common/data_helpers'
import { requestCurrentAccountLogoByAccountId } from 'application/redux/actions/account_logo'
import { getAccountId } from 'application/redux/selectors'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'



export const useAccountLogoLoader = (aId = null) => {
    const  dispatch = useDispatch()
    const accountLogo = useSelector( state => state.accountLogo || null )
    const filePath = accountLogo?.blobUrl || null
    const accountLogoLoading = accountLogo?.loading || false
    const accountLogoLoaded = accountLogo?.loaded || false 
    const accountId = useSelector(getAccountId)
    const hasCurrentAccount = exists(accountId)
    const accountLogoNotFound = !hasCurrentAccount || (accountLogoLoaded && isNull(filePath))
    const accountLogoFound = accountLogoLoaded && !isNull(filePath)

    useEffect(
        () => {          
            hasCurrentAccount
            && !accountLogoLoaded
            && !accountLogoLoading
            && dispatch(requestCurrentAccountLogoByAccountId(aId || accountId) ) 
        }, 
        [dispatch, accountLogoLoaded, accountLogoLoading, hasCurrentAccount, accountId, aId]    
    )

    return {
        accountLogoLoading,
        accountLogoLoaded,
        accountLogoNotFound,
        accountLogoFound,
        filePath,
        accountName: accountLogo.accountName,
        accountLogoAvailable: accountLogoLoaded && accountLogoFound
    }

}