import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const changeTaskState = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/tasks/${params.taskId}/state`, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/damage/tasks/${params.taskId}/state`, params.pin, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        }
    }
)