import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateSharedLawfirm } from 'application/redux/actions/pages/damage_manager'
import { COMMISSION_STATE } from 'application/constants'

export function* dialogCommisionLawfirmSaga(action) {
    const { damageId } = action.metaData.match.params
    yield put(showWaitingScreen('Assigning lawyer'))

    const lawfirm = action.payload.lawfirm
    const files = action.payload.files

    yield resourceCall(
        api.gateway.damage.updateDamageLawfirm,
        {
            damageId: damageId
        },
        {
            name: lawfirm.name,
            webakteId: lawfirm.webakteId
        }
    )

    if (files.length > 0) {
        for (const file of files) {
            const documentResponse = yield resourceCall(
                api.gateway.attachments.postAttachment,
                {
                    damageId: damageId
                },
                {
                    file: file.metaData
                }
            )

            yield resourceCall(
                api.gateway.attachments.updateAttachment,
                {
                    damageId: damageId,
                    attachmentId: documentResponse.id
                },
                {
                    isExportedToLawFirm: true
                }
            )

        }
    }

    yield put(populateSharedLawfirm(true, {
        ...lawfirm,
        state: COMMISSION_STATE.PENDING
    }))
    yield put(showToast(action.metaData.successMsg))
    yield put(hideWaitingScreen())
}