import React from 'react'
import styled from 'styled-components'
import { QrCode } from 'application/components/building_blocks/qr_code'


const StyledStickerFrame = styled.div`
    position: absolute;
    width: 660px;
    height: 466px;
    background: white;
    top: 200000px;
`



const StyledBrandLogo = styled.img`
    position: absolute;
    top: 38px;
`

const StyledSeatLogo = styled(StyledBrandLogo)`
    left: 410px;
    height: 29px;
    top: 14px;
`

const StyledCupraLogo = styled(StyledBrandLogo)`
    left: 455px;
    height: 29px;
    top: 14px;
`

const StyledVwLogo = styled(StyledBrandLogo)`
    left: 508px;
    height: 28px;
    top: 14px;
`

const StyledSkodaLogo = styled(StyledBrandLogo)`
    left: 551px;
    height: 31px;
    top: 12px;
`

const StyledVwServiceLogo = styled(StyledBrandLogo)`
    left: 590px;
    height: 30px;
    top: 13px;
`

const StyledQRCodeContainer = styled.div`
    position: absolute;
    top: 87px;
    right: 24px;
`

const StyledLogo = styled.img`
    position: absolute;
    left: 14px;
    top: 103px;
    width: 371px;
`



const Info24 = styled.strong`
    position: absolute;
    top: 260px;
    left: 32px;
    font-family: Helvetica;
    font-size: 30px;
    color:${props => props.theme.color.red};
`

const LicensePlate = styled.span`
    top: 213px;
    left: 32px;
    position: absolute;
`
const Name = styled.span`
    top: 234px;
    position: absolute;
    left: 32px;
`


const StyledPanelBase = styled.div`
    position: absolute;
    width: 652px;
    left: 1mm;
    background: rgb(65, 86, 100);
`
const StyledBrandsPanel = styled(StyledPanelBase)`
    top: 1mm;
    height: 50px;
` 

const StyledPanel = styled(StyledPanelBase)`
    top: 297px;
    height: 97px;
` 





const StyledArrow = styled.img`
    position: absolute;
    top: 336px;
    left: 385px;
    width: 65px;
`
const InfoScann = styled.span`
    position: absolute;
    top: 313px;
    left: 32px;
    font-family: Helvetica;
    font-size: 29px;
    color: white;
`

const CallBack = styled.span`
    position: absolute;
    top: 351px;
    left: 32px;
    font-family: Helvetica;
    font-size: 21px;
    color: white;
`

const Other = styled.span`   
    position: absolute;
    top: 417px;
    left: 90px;
    font-family: Helvetica;
    font-size: 18px;
`

const PhoneIcon = styled.i`   
    position: absolute;
    top: 416px;
    left: 139px;
    color: rgb(65, 86, 100);
    font-size: 25px;
`

const PhoneNumber = styled.span`   
    position: absolute;
    top: 416px;
    left: 168px;
    font-family: Helvetica;
    font-size: 21px;
`

const Powered = styled.span`   
    position: absolute;
    top: 405px;
    left: 502px;
    font-family: Helvetica;
    font-size: 18px;
`


const Styled360Logo = styled.img`
    position: absolute;
    left: 600px;
    top: 404px;
    width: 43px;
`

export const ZyrullSticker = React.forwardRef(
    (props, ref) => {
        const {
            qrCodeProps,
            customerFullName = '',
            licensePlate = ''
        } = props


        const getBrandLogoPath = (brandName) =>   `/images/zyrull/logos/${brandName}.png`
                 
        

        return <StyledStickerFrame ref={ref}>
            <StyledBrandsPanel/>
            <StyledSeatLogo
                src={getBrandLogoPath('seat')}
                alt='Seat Logo'
            />
            <StyledCupraLogo
                src={getBrandLogoPath('cupra')}
                alt='Cupra Logo'
            />
            <StyledVwLogo
                src={getBrandLogoPath('vw')}
                alt='VW Logo'
            />
            <StyledSkodaLogo
                src={getBrandLogoPath('skoda')}
                alt='Skoda Logo'
            />
            <StyledVwServiceLogo
                src={getBrandLogoPath('vw_service')}
                alt='VW Service Logo'
            /> 
            <StyledPanel/>
            <StyledLogo
                src='/images/zyrull/logo.jpg' 
                alt='Zyrull Logo'
            />
            <StyledArrow
                src='/images/zyrull/arrow.jpg' 
                alt='arrow'
            />
            <StyledQRCodeContainer>
                <QrCode 
                    {
                        ...qrCodeProps
                    }
                    level = 'L'
                />            
            </StyledQRCodeContainer>
            <LicensePlate>
                {licensePlate}
            </LicensePlate>
            <Name>
                {customerFullName}
            </Name>
            <Info24>
                24h Pannenservice
            </Info24>
            <InfoScann>
                Einfach QR-Code scannen
            </InfoScann>
            <CallBack>
                und gleich einen Rückruf erhalten
            </CallBack>
            <Other>oder</Other>
            <PhoneIcon className="material-icons">
                    call
            </PhoneIcon>
            <PhoneNumber>
                0172 6844925
            </PhoneNumber>
            <Powered>powered by</Powered>
            <Styled360Logo
                src='/images/logos/logo192.png' 
                alt='360Logo'
            />
        </StyledStickerFrame>
    }
)