import { DASHBOARD_CONFIGURATION } from './dashboard_configuration'
import { MODULE_NAME } from './modules'

export const UI_SETTINGS = {
    ACCOUNT: {
        SETTINGS_VERSION: 'UI_SETTING_ACCOUNT_SETTINGS_VERSION',
        PREFER_EXISTING_CUSTOMER_ON_DAMAGE_CREATION: 'UI_SETTING_ACCOUNT_PREFER_EXISTING_CUSTOMER_ON_DAMAGE_CREATION',
        ADD_RVG_NOTE_TO_POWER_OF_ATTORNEY_PDFS: 'UI_SETTING_ACCOUNT_ADD_RVG_NOTE_TO_POWER_OF_ATTORNEY_PDFS',
        EMERGENCY_USER: 'UI_SETTING_ACCOUNT_EMERGENCY_USER',
        KICKSTART_FORM_RENDERER_STRUCTURE: 'UI_SETTING_ACCOUNT_KICKSTART_FORM_RENDERER_STRUCTURE',
        EXTERNAL_DAMAGE_CREATION_CATEGORY: 'UI_SETTING_ACCOUNT_EXTERNAL_DAMAGE_CREATION_CATEGORY',
        EXTERNAL_DAMAGE_CREATION_BRANCH_ASSIGNEES: 'EXTERNAL_DAMAGE_CREATION_BRANCH_ASSIGNEES',
        EXTERNAL_KICKSTART_SIMPLE: 'EXTERNAL_KICKSTART_SIMPLE',
        EXTERNAL_KICKSTART_CONFIRMATION_TEXT: 'EXTERNAL_KICKSTART_CONFIRMATION_TEXT'
    },
    USER: {
        SETTINGS_VERSION: 'UI_SETTING_USER_SETTINGS_VERSION',
        HIDE_FEEDBACK_NOTICE: 'UI_SETTING_USER_SETTINGS_HIDE_FEEDBACK_NOTICE',
        LAST_SEEN_ANNOUNCEMENT: 'UI_SETTING_USER_SETTINGS_LAST_SEEN_ANNOUNCEMENT',
        GENERIC_DASHBOARD_CONFIG: 'UI_SETTING_USER_SETTINGS_GENERIC_DASHBOARD_CONFIG',
        BRANCH_DASHBOARD_CONFIG: 'UI_SETTING_USER_SETTINGS_BRANCH_DASHBOARD_CONFIG',
        USE_LEGACY_DAMAGE_TABLES: 'UI_SETTING_USE_LEGACY_DAMAGE_TABLES'
    }
}

export const UI_SETTINGS_TYPE = {
    ACCOUNT: 'account',
    USER: 'user'
}

export const UI_SETTINGS_DATA_TYPE = {
    INTEGER: 'int',
    STRING: 'string',
    BOOL: 'bool',
    DASHBOARD_CONFIGURATION: 'dashboardConfiguration',
    USER_SELECT: 'userSelect',
    SERIALIZED_JSON: 'serializedJson',
    CATEGORY_SELECT: 'categorySelect',
    KICKSTART_BRANCH_ASSIGNEES: 'kickstartBranchAssignees'
}


//default values are used to control the UI before the UI settings are loaded
//they are never sent to the server and are only used tempoary to prevent flickering or strange UI behaviour
//they do not necessarily match the value from the default store, as that value is used to write the initial db structure
export const settingsDefinitions = [
    {
        label: 'Account settings version',
        description: '',
        key: UI_SETTINGS.ACCOUNT.SETTINGS_VERSION,
        selector: () => store => store.uiSettings.accountSettings?.version,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: false,
        dataType: UI_SETTINGS_DATA_TYPE.INTEGER,
        path: 'version',
        defaultValue: 1,
        requiredModules: []
    },
    {
        label: 'Prefer existing customers',
        description: 'When creating a new damage case, should the existing customer search be shown by default?',
        key: UI_SETTINGS.ACCOUNT.PREFER_EXISTING_CUSTOMER_ON_DAMAGE_CREATION,
        selector: () => store => store.uiSettings.accountSettings?.preferExistingCustomerOnDamageCreation,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.BOOL,
        path: 'preferExistingCustomerOnDamageCreation',
        defaultValue: true,
        requiredModules: []
    },
    {
        label: 'User settings version',
        description: '',
        key: UI_SETTINGS.USER.SETTINGS_VERSION,
        selector: () => store => store.uiSettings.userSettings?.version,
        type: UI_SETTINGS_TYPE.USER,
        editable: false,
        dataType: UI_SETTINGS_DATA_TYPE.INTEGER,
        path: 'version',
        defaultValue: 1,
        requiredModules: []
    },
    {
        label: 'Last seen announcement',
        description: '',
        key: UI_SETTINGS.USER.LAST_SEEN_ANNOUNCEMENT,
        selector: () => store => store.uiSettings.userSettings?.lastSeenAnnouncement,
        type: UI_SETTINGS_TYPE.USER,
        editable: false,
        dataType: UI_SETTINGS_DATA_TYPE.STRING,
        path: 'lastSeenAnnouncement',
        defaultValue: 10000,
        requiredModules: []
    },
    {
        label: 'Add RVG note for power of attorney PDFs by default',
        description: '',
        key: UI_SETTINGS.ACCOUNT.ADD_RVG_NOTE_TO_POWER_OF_ATTORNEY_PDFS,
        selector: () => store => store.uiSettings.accountSettings?.addRvgToPowerOfAttorneyPdfs,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.BOOL,
        path: 'addRvgToPowerOfAttorneyPdfs',
        defaultValue: true,
        requiredModules: []
    },
    {
        label: 'Configuration of the current branch overview',
        description: 'Which widgets do you want to show by default in the overview that shows the data of the currently selected branch?',
        key: UI_SETTINGS.USER.BRANCH_DASHBOARD_CONFIG,
        selector: () => store => store.uiSettings.userSettings?.branchDashboardConfig,
        type: UI_SETTINGS_TYPE.USER,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.DASHBOARD_CONFIGURATION,
        path: 'branchDashboardConfig',
        defaultValue: DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES,
        requiredModules: []
    },
    {
        label: 'Configuration of the generic overview',
        description: 'Which widgets do you want to show by default in the overview that aggregates the data of all accessible branches?',
        key: UI_SETTINGS.USER.GENERIC_DASHBOARD_CONFIG,
        selector: () => store => store.uiSettings.userSettings?.genericDashboardConfig,
        type: UI_SETTINGS_TYPE.USER,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.DASHBOARD_CONFIGURATION,
        path: 'genericDashboardConfig',
        defaultValue: DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES,
        requiredModules: []
    },
    {
        label: 'Hide feedback notice',
        description: '',
        key: UI_SETTINGS.USER.HIDE_FEEDBACK_NOTICE,
        selector: () => store => store.uiSettings.userSettings?.hideFeedbackNotice,
        type: UI_SETTINGS_TYPE.USER,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.BOOL,
        path: 'hideFeedbackNotice',
        defaultValue: true,
        requiredModules: []
    },
    {
        label: 'Breakdown service user',
        description: 'Select the user that should get an SMS after a customer reports a damage.',
        key: UI_SETTINGS.ACCOUNT.EMERGENCY_USER,
        selector: () => store => store.uiSettings.accountSettings?.emergencyUser,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.USER_SELECT,
        path: 'emergencyUser',
        defaultValue: '',
        requiredModules: [MODULE_NAME.ZYRULL_QR_CODE_STICKER]
    },
    {
        label: 'Use simple damage tables',
        description: 'Simple damage tables just link to the damage, while advanced damage tables provide more information within the table.',
        key: UI_SETTINGS.USER.USE_LEGACY_DAMAGE_TABLES,
        selector: () => store => store.uiSettings.userSettings?.useLegacyDamageTables,
        type: UI_SETTINGS_TYPE.USER,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.BOOL,
        path: 'useLegacyDamageTables',
        defaultValue: false,
        requiredModules: []
    },
    {
        label: 'Kickstart form renderer structure',
        description: '',
        key: UI_SETTINGS.ACCOUNT.KICKSTART_FORM_RENDERER_STRUCTURE,
        selector: () => store => store.uiSettings.accountSettings?.kickstartFormRendererStructure,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: false,
        dataType: UI_SETTINGS_DATA_TYPE.SERIALIZED_JSON,
        path: 'kickstartFormRendererStructure',
        defaultValue: '[]'
    },
    {
        label: 'Default document category for new damages',
        description: 'Select the categories for documents that were uploaded on the fly while creating a damage via a QR code.',
        key: UI_SETTINGS.ACCOUNT.EXTERNAL_DAMAGE_CREATION_CATEGORY,
        selector: () => store => store.uiSettings.accountSettings?.externalDamageCategoryId,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.CATEGORY_SELECT,
        path: 'externalDamageCategoryId',
        defaultValue: null,
        requiredModules: [MODULE_NAME.ZYRULL_QR_CODE_STICKER]
    },
    {
        label: 'Default damage assignee for kickstart damages',
        description: 'Which user should be assigned to damages created via the qr code kickstart form? If no user is selected, the default account administrator will be used.',
        key: UI_SETTINGS.ACCOUNT.EXTERNAL_DAMAGE_CREATION_BRANCH_ASSIGNEES,
        selector: () => store => store.uiSettings.accountSettings?.externalDamageBranchAssignees,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.KICKSTART_BRANCH_ASSIGNEES,
        path: 'externalDamageBranchAssignees',
        defaultValue: '',
        requiredModules: []
    },
    {
        label: 'Simplify kickstart form',
        description: 'Show a simplified version of the kickstart form that only asks for the most important information.',
        key: UI_SETTINGS.ACCOUNT.EXTERNAL_KICKSTART_SIMPLE,
        selector: () => store => store.uiSettings.accountSettings?.externalKickstartSimple,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.BOOL,
        path: 'externalKickstartSimple',
        defaultValue: false,
        requiredModules: []
    },
    {
        label: 'Kickstart confirmation text',
        description: 'The text that is shown after a Kickstart form has been sent.',
        key: UI_SETTINGS.ACCOUNT.EXTERNAL_KICKSTART_CONFIRMATION_TEXT,
        selector: () => store => store.uiSettings.accountSettings?.externalKickstartConfirmationText,
        type: UI_SETTINGS_TYPE.ACCOUNT,
        editable: true,
        dataType: UI_SETTINGS_DATA_TYPE.STRING,
        path: 'externalKickstartConfirmationText',
        defaultValue: false,
        requiredModules: []
    }
]

