import React from 'react'
import Sticky from 'react-sticky-el'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import {TaskHighlightBar} from 'application/components/building_blocks/task_highlight_bar'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import * as Fragments from './fragments'
import {TaskOperationsInternal} from 'application/components/building_blocks/task_operations_internal'
import { exists } from 'application/common/data_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

export const TaskDetails = ({
    selectedTaskLoaded,
    selectedTaskLoading,
    task,
    onAssignTask,
    taskId,
    onSearchUser,
    userList,
    userListLoading,
    damage
}) => {

    const {
        damageManagerLink,
        params
    } = useApplicationLinks()

    const handleAssignTask = (
        userId,
        userFirstName,
        userLastname
    ) => {
        onAssignTask(
            damage.id,
            taskId,
            userId,
            userFirstName,
            userLastname
        )
    }

    const handleSearchUser = searchTerm => {
        onSearchUser(searchTerm, damage.branchId)
    }

    const taskExternal = task !== null && exists(task.creator) && !exists(task.creator.userId)

    if (selectedTaskLoading) {
        return <ThemedPreloader />
    }
    if (!selectedTaskLoaded || task === null) {
        return <React.Fragment />
    }
    if (task.taskWasDeleted && taskId === params.taskId) {
        return <Navigate to={
            damageManagerLink(
                ROUTES.DAMAGE_MANAGER_TASKS,
                params.damageId
            )
        } />
    }
    return <React.Fragment>
        <Sticky scrollElement=".taskScrollArea">
            <React.Fragment>
                <Fragments.Row>
                    <TaskHighlightBar
                        state={task.state}
                        priority={task.priority}
                        onAssignUser={handleAssignTask}
                        onSearchUser={handleSearchUser}
                        userList={userList}
                        userListLoading={userListLoading}
                        assignee={task.assignee}
                        taskTitle={task.title}
                        branchId={damage.branchId}
                        damageId={damage.id}
                        damage={damage}
                        task={task}
                        taskExternal={taskExternal}
                    />
                </Fragments.Row>
            </React.Fragment>
        </Sticky>

        <Fragments.Row>
            <Fragments.Description>{task.description}</Fragments.Description>
        </Fragments.Row>
        <Fragments.Row>
            <WidgetArea widgetContext={taskId} successively={true} />
        </Fragments.Row>
        <Fragments.Footer>
            <TaskOperationsInternal
                task={task}
                taskExternal={taskExternal}
                damageId={params.damageId}
            />
        </Fragments.Footer>
    </React.Fragment>

}