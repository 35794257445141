import { connect } from 'react-redux'
import {DamageNotFoundEffects} from './effects'
import {  resetDamage } from 'application/redux/actions/pages/damage_manager'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { withAccountsLoader } from 'application/components/higher_order_components'

const mapStateToProps = state => ({
    currentBranchId: state.auth.currentBranch?.id || '',
    accounts: state.auth.accounts
})

const mapDispatchToProps = dispatch => ({
    onResetDamage: () => dispatch(
        resetDamage()
    )
})


export const DamageNotFoundContainer =  connect(
    mapStateToProps,
    mapDispatchToProps
)( 
    withLoggedInUser(
        withAccountsLoader()(
            DamageNotFoundEffects 
        )
    )
)