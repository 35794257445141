import { IconButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'

const StyledTableRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 35px;
    padding: 4px 8px;

    margin-bottom: 8px;

    font-size: ${props => props.theme.fontSize.small};
    background: ${props => props.theme.color.gray5};
    *border: 1px solid ${props => props.theme.color.gray10};

    & i {
        font-size: ${props => props.theme.fontSize.medium};
    }
`





const MoTableRowComponent = (
    {
        element,
        removeElement,
        theme,
        tableConfig
    }
) => {
    
    const handleRemoveElement = () => {
        removeElement(
            element.id
        )
    }

    return <StyledTableRow>

        <FlexBox alignItems='center'>
            {
                tableConfig.columns.map(
                    (column, i) => {
                        return <FlexBox
                            key={i}
                            width={column.width}
                            flexGrow='0'
                            cssRules='overflow:hidden;'
                            paddingRight='8px'
                        >
                            <column.CellContent 
                                element={element} 
                            />
                        </FlexBox>
                    }
                )
            }
            <FlexBox 
                width='10%'
                justifyContent='flex-end'
            >
                <IconButton
                    border={true} 
                    fontSize='inherit' 
                    iconKey='close' 
                    onButtonClick = {handleRemoveElement}
                    height='35px'
                    width='35px'
                    color={theme.color.primary}
                />
            </FlexBox>
        </FlexBox>
    </StyledTableRow>
}

MoTableRowComponent.propTypes = {
    element: PropTypes.object.isRequired,
    removeElement: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    tableConfig: PropTypes.object.isRequired
}

export const MoTableRow = withTheme(MoTableRowComponent)