import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { PowerOfAttorneyForm } from 'application/components/forms/power_of_attorney'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { exists } from 'application/common/data_helpers'

export const DocumentsStepComponent = withRouter((
    { 
        //from component
        abort, 
        dialogName,
        goToPreviousStep,
        files,
        setFiles,
        customer,
        lawfirm,
        liabilityData,
        changeMode,
        match,
        //from container
        onLawfirmCommission,
        damage,
        currentBranch
    }
) => {
    const { t } = useTranslation()
    const successMsg = t('Lawyer commissioned')



    const onSubmitSuccess = () => {
        const metaData =  { 
            match, 
            successMsg, 
            changeMode,
            customerId: customer.customerId
        }

        const hasLegalProtection = customer?.legalProtectionInsurance
        && ( exists(customer?.legalProtectionInsurance?.company?.name)
        || exists(customer?.legalProtectionInsurance?.company?.id) )
    
        const basePayload = {
            customer,
            lawfirm,
            files
        }

        const payload = hasLegalProtection
            ? {
                ...basePayload,
                legalProtectionInsurance: customer.legalProtectionInsurance,
            }
            : basePayload


        onLawfirmCommission(
            dialogName, 
            payload, 
            metaData
        )
    }

    const tempDamage = {
        ...damage,
        liabilityData
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <React.Fragment>
                    <PowerOfAttorneyForm
                        lawfirm = {lawfirm}
                        customer = {customer}
                        branch = {currentBranch}
                        files={files}
                        setFiles={setFiles}
                        submitButtonText='Create power of attorney'
                        damage={tempDamage}
                    />                  
                </React.Fragment>    
                <SubHeadline>{t('Documents')}</SubHeadline>
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={files}
                    setFiles={setFiles}
                />             
            </FlexBox>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={goToPreviousStep} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={changeMode ? t('Confirm change') : t('Send commission')} 
                onButtonClick={onSubmitSuccess} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
})