import React from 'react'
import {BranchSidebar} from './branch_sidebar'
import {RoleSidebar} from './role_sidebar'
import {GroupSidebar} from './group_sidebar'
import {HelpSidebar} from './help_sidebar'

export const BrachnmanagementSidebars = (props) => {
    return <React.Fragment>
        <RoleSidebar {...props} />
        <BranchSidebar {...props} />
        <GroupSidebar {...props} />
        <HelpSidebar {...props} />
    </React.Fragment>
}

export { SidebarPlaceholder } from './_common'