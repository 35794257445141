import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { updateTaskInListWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export function* dialogEditTaskWorkflowPanelSaga(action) {
    const taskId = action.payload.id

    yield put(showWaitingScreen('Saving task'))
    yield resourceCall(
        api.gateway.tasks.updateTask, 
        {
            taskId
        }, 
        removePropertiesWithEmptyStrings(action.payload)
    )
    yield put(updateTaskInListWorkflowPanel(taskId, action.payload))
    yield put(showToast('Task has been successful edited'))
    yield put(hideWaitingScreen())
}