import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* kickstartCommissionLawfirmSubSaga (lawfirm, createDamageResponse) {
    if(lawfirm.commissionLawfirm){
        const {selectedLawfirm} = lawfirm
        yield resourceCall(
            api.gateway.damage.updateDamageLawfirm, 
            {
                damageId: createDamageResponse.id
            },
            {
                name: selectedLawfirm.name,
                webakteId: selectedLawfirm.webakteId
            }
        )
    }else{
        yield
    }
}