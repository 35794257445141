import React from 'react'
import { ErrorPageBase } from 'application/components/pages/errors/_common'


const NotFoundPage = () => {
    return <ErrorPageBase 
        errorMessage='Page not found'
    />
}

export {NotFoundPage}