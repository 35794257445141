import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { usersPopulateUsers, usersSetUsersLoading } from 'application/redux/actions/pages/users'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'




function* usersGetUsersSaga(action) {
    try {
        yield put(
            usersSetUsersLoading()
        )
        const usersResponse = yield resourceCall(
            api.gateway.users.getUsersList, 
            {
                offset: action.offset, 
                limit: action.limit, 
                orderBy: action.orderBy, 
                direction: action.direction, 
                filterQuery: action.filterQuery
            }
        )
        yield put(
            usersPopulateUsers(usersResponse)
        )
    } catch (e) {
        yield handleError(e, action)
    }
}



export function* getUsersWatcher() {
    yield takeLeading(
        ACTIONS.USERS_GET_USERS_REQUEST, 
        usersGetUsersSaga
    )
}