import React, { useState } from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCategoriesLoader } from 'application/components/higher_order_components'
import { withCommissionExpertSteps } from './with_commission_expert_steps'
import { CommissionExpertStep0 } from './component_step_0'
import { CommissionExpertStep1 } from './component_step_1'
import { CommissionExpertStep2 } from './component_step_2'
import { CommissionExpertStep3 } from './component_step_3'
import { compose } from 'redux'
import { DIALOG, EXPERT_TYPE, INFOBOX } from 'application/constants'
import { DialogStepsLayout } from '../../_common/steps_layout'
import { InfoBox } from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { LicensePlate } from 'application/components/controls/license_plate'
import { DateComponent } from 'application/components/controls/date'
import { useTranslation } from 'react-i18next'


const CommissionExpertDialog = (({
    getStepsState, 
    goToNextStep,
    goToPreviousStep,
    getCurrentStep,
    onCommissionExpert,
    dialog,
    setAbortBlocked, 
    setDataChanged,
    categories
})=>{
    const {t} = useTranslation()
    const { damage, branch, customer, comprehensiveData } = dialog.payload

    const getBranchInfo = () => {
        return {
            name: branch.name,       
            address: {
                line1: '',
                postalCode: '',
                locality: '',
                ...branch.address
            },     
            contact: {
                email: '',
                phoneNumber: '',
                ...branch.contact
            }
        }
    }

    const step2InitialData = {
        inspectionInfo: {
            name: branch.name || '',
            site: {
                line1: '',
                postalCode: '',
                locality: '',
                ...branch.address
            },
            contact: {
                email: '',
                phoneNumber: '',
                ...branch.contact
            },
            date: null
        },
        branchInfo: getBranchInfo(),
        type: EXPERT_TYPE.DEKRA,
        note: ''
    }

    const [documents, setDocuments] = useState([]) //the array of uploaded files
    const [damageData, setDamageData] = useState(damage) //the damage form data
    const [customerData, setCustomerData] = useState(customer)
    const [commissionExpertData, setCommissionExpertData] = useState(step2InitialData) //the branch address form data
    const [inspectionAdressIsBranchAdress, setInspectionAdressIsBranchAdress] = useState(true)

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const handleOnCommissionExpert = (commissionExpertFormData) => {
        const defaultCategory = categories.find(c => c.isDefault)

        const commissionExpertPayload = inspectionAdressIsBranchAdress 
            ? {
                ...commissionExpertFormData,
                inspectionInfo: {
                    ...commissionExpertFormData.inspectionInfo,
                    name: commissionExpertFormData.branchInfo.name,
                    site: commissionExpertFormData.branchInfo.address,
                    contact: commissionExpertFormData.branchInfo.contact
                }
            }
            : commissionExpertFormData

        const payload = {
            commissionExpertPayload,
            damageData,
            customerData: {
                ...customer,
                address: {
                    line1: '',
                    postalCode: '',
                    locality: '',
                    ...customerData.address
                },
                ...customerData
            },
            documents
        }

        const metaData = {
            categoryId:defaultCategory.id,
            damageId: damage.id
        }

        onCommissionExpert(
            dialog.name, 
            payload,
            metaData
        )
    }


    return <DialogStepsLayout getStepsState={getStepsState}>
        {
            dialog.name === DIALOG.NAME.COMMISSION_DEKRA_EXPERT && <InfoBox  type={INFOBOX.TYPES.WARNING} direction='row' cssMargin='0 16px 16px' cssWidth='auto'>
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <LicensePlate licensePlate={damage.vehicle.licensePlate} />
                <Spacer basis='10px' />
                <span>
                    {t('Damage date')}: <DateComponent date={damage.incidentDate} />
                </span>
            </InfoBox>
        }
       
        {
            getCurrentStep().id === 0 && <CommissionExpertStep0
                goToNextStep={goToNextStep}
                files={documents}
                setFlaggedFiles={setDocuments}
                onDataChange={onDataChange}
                comprehensiveData={comprehensiveData}
            />
        }
        {
            getCurrentStep().id === 1 && <CommissionExpertStep1
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                setStep1data={data => {
                    setDamageData(data)
                    onDataChange()
                }}
                step1data={damageData}
                damage={damage}
            />
        }
        {
            getCurrentStep().id === 2 && <CommissionExpertStep2
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                setStep2data={data => {
                    setCustomerData(data)
                    onDataChange()
                }}
                step2data={customerData}
                damage={damage}
            />
        }

        {
            getCurrentStep().id === 3 && <CommissionExpertStep3
                goToPreviousStep={goToPreviousStep}
                onCommissionExpert={handleOnCommissionExpert}
                setStep2data={data => {
                    setCommissionExpertData(data)
                    onDataChange()
                }}
                step2data={commissionExpertData}
                inspectionAdressIsBranchAdress={inspectionAdressIsBranchAdress}
                setInspectionAdressIsBranchAdress={setInspectionAdressIsBranchAdress}
                getBranchInfo={getBranchInfo}
                incidentDate={damage.incidentDate}
            />
        }
    </DialogStepsLayout>
})

export const CommissionExpertDialogComponent = compose(
    withCommissionExpertSteps(),
    withDialogDataLoader,
    withCategoriesLoader
)(CommissionExpertDialog)