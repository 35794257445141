import React from 'react'
import { useTranslation } from 'react-i18next'
import { DisabledText } from 'application/components/fragments/typography'
import { isDefined } from 'application/common/data_helpers'

export const DamageTypeName = (
    {
        damageType, 
        isSelected = false
    }
) => {
    const {t} = useTranslation()
    const unknown = !isDefined(damageType) || damageType === 'Unknown'

    if(unknown){
        return <DisabledText isSelected={isSelected}>
            {t('not known')}
        </DisabledText>
    }

    return <React.Fragment>
        { t(damageType) }
    </React.Fragment>
}