import { connect } from 'react-redux'
import { updateOpponentVehicle } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageOpponentVehicleFormComp } from './component'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (damageId, damageData) => dispatch(updateOpponentVehicle(damageId, damageData))
    }
}

export const TaskDamageOpponentVehicleForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageOpponentVehicleFormComp)