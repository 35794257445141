import { put } from 'redux-saga/effects'
import { closeDialogByName } from 'application/redux/actions/dialogs'
import { openDialogByName } from 'application/redux/actions/dialogs'


export function* switchDialogSaga(action) {

    const {
        nameToClose,
        nameToOpen,
        initiallyLoaded, 
        payload,
        title
    } = action

    yield put(
        closeDialogByName(nameToClose)
    )
    yield put(
        openDialogByName(
            nameToOpen,
            initiallyLoaded, 
            payload,
            title
        )
    )
}