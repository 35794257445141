import React from 'react'
import {ActionButtonComponent} from './action_button'
import styled from 'styled-components'

const StyledActionButton = styled.div`
    button {
        background-color: ${props => props.buttonBackground ? props.buttonBackground : props.secondary ? 'transparent' : props.theme.color.gray70};
        padding: 0 16px ;
        margin-right: ${props => props.marginRight};
        :hover {
            background: ${
    props => props.hoverBackground 
        ? props.hoverBackground 
        : props.secondary 
            ? props.theme.color.gray5 
            : props.theme.color.anthracite
};
        }
        
        :active {
            background: ${
    props => props.hoverBackground 
        ? props.hoverBackground 
        : props.secondary 
            ? props.theme.color.gray10 
            : props.theme.color.black
};
                outline: none;
        }
        
        :focus {
            outline: none;
        }
        height: inherit;
    }   
    display: flex;
`

export const OverridedActionButton = ({
    hoverBackground = '',
    activeBackground = '',
    buttonClassName = 'action-button',
    buttonText = '',
    strechted = false,
    disabled = false,
    onButtonClick,
    secondary = false,
    icon = '',
    marginRight = '0',
    buttonBackground = null
}) => {
    return <StyledActionButton 
        marginRight={marginRight} 
        className={buttonClassName}
        hoverBackground={hoverBackground} 
        activeBackground={activeBackground}
        secondary={secondary}
        buttonBackground={buttonBackground}
    >
        <ActionButtonComponent
            className={buttonClassName}
            buttonText={buttonText}
            strechted={strechted}
            disabled={disabled}
            onButtonClick={onButtonClick}
            secondary={secondary} 
            icon={icon}
        />
    </StyledActionButton>
}