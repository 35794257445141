import React, { useState } from 'react'
import { withRouter } from 'application/components/higher_order_components/with_router'
import { SHARED_RESOURCE_TYPE } from 'application/constants'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { ResponsiveMobileContainer } from '../_common'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation'
import { MobileTimeLine } from 'application/components/building_blocks/mobile_time_line'
import { useTimeline } from 'application/components/hooks'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { DAMAGE_TYPE } from 'application/constants'
import { useQuery } from 'application/components/hooks/use_query'
import { ROUTE_PARAMETERS } from 'application/components/pages/external/create_damage/sub_components/route_parameters'
import { PushBottom32 } from 'application/components/pages/_common'
import { ExtCreateDamageHeader } from 'application/components/pages/external/create_damage/sub_components/header'
import { Step0, Step1, Step2, Step3} from './steps'
import { todayToUtcString } from 'application/common/date_time_helpers'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { exists } from 'application/common/data_helpers'

const CreateDamageExternalComponentContent = ({
    sharedResource
}) => {
    const [files, setFiles] = useState([])

    const {
        currentTimelineStepIndex,
        gotoNextStep,
        gotoPreviousStep,
    } = useTimeline(4)

    const fallBackAdminUserId = sharedResource?.data?.adminUser?.id || null
    const customer = sharedResource?.data?.customer

    const {
        getSetting
    } = useUiSettings()

    const adminUserUserFromSettings = getSetting(UI_SETTINGS.ACCOUNT.EMERGENCY_USER)

    const query = useQuery()

    const defaultValues = {
        type: DAMAGE_TYPE.UNKNOWN, //hidden field
        assignee: {
            userId: exists(adminUserUserFromSettings) ? adminUserUserFromSettings : fallBackAdminUserId, //hidden field
        },
        vehicle: {
            licensePlate: query.getStringParam(ROUTE_PARAMETERS.LICENSE_PLATE_PARAM),
            make: query.getStringParam(ROUTE_PARAMETERS.MAKE_PARAM),
            model: query.getStringParam(ROUTE_PARAMETERS.MODEL_PARAM),
            chassisNumber: query.getStringParam(ROUTE_PARAMETERS.CHASSIS_NUMBER_PARAM),
        },
        incidentDate: todayToUtcString(),
        description: '',
        note: 'Keine Koordinaten angegeben.',
        mobilePhoneNumber: customer.mobilePhoneNumber || ''
    }       
    const createDamageFormHook = useStatefulForm({ defaultValues })

    return <ResponsiveMobileContainer>
        <MobileTimeLine>
            <Step0
                currentTimelineStepIndex={currentTimelineStepIndex}
                gotoNextStep={gotoNextStep}
                createDamageFormHook={createDamageFormHook}
            />
            <Step1 
                currentTimelineStepIndex={currentTimelineStepIndex}
                gotoPreviousStep={gotoPreviousStep}
                gotoNextStep={gotoNextStep}
                createDamageFormHook={createDamageFormHook}  
                files={files}
                setFiles={setFiles}             
            />
            <Step2
                currentTimelineStepIndex={currentTimelineStepIndex}
                gotoPreviousStep={gotoPreviousStep}
                gotoNextStep={gotoNextStep}
                createDamageFormHook={createDamageFormHook}  
                files={files}
                setFiles={setFiles}             
            />
            <Step3
                currentTimelineStepIndex={currentTimelineStepIndex}
                files={files}
            />
        </MobileTimeLine>       
    </ResponsiveMobileContainer>
}

const CreateDamageExternalComponent = (
    {        
        sharedResource,
    }
) => {

    const {
        accountSettingsLoaded
    } = useUiSettings()

    const dataLoaded = sharedResource.dataLoaded

    return <LayoutSharedResource
        sharedResourceType={SHARED_RESOURCE_TYPE.QR}
        dataInitiallyLoaded={false}
    >
        {
            dataLoaded && accountSettingsLoaded && <React.Fragment>
                <ExtCreateDamageHeader sharedResource={sharedResource} />
                <PushBottom32/>
                <PushBottom32/>
                <PushBottom32/>
                <CreateDamageExternalComponentContent sharedResource={sharedResource} />
            </React.Fragment>
        }
    </LayoutSharedResource>
}

export const CreateDamageExternal = withSharedResourceValidation()(
    withRouter(CreateDamageExternalComponent)
)