import { select, all } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { getCurrentBranchId } from 'application/redux/selectors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { exists } from 'application/common/data_helpers'

const liabilityDataMappers = {
    accidentReport: payload => payload,
    vehicle: payload => payload,
    vehicleKeeper: payload => payload,
    liabilityClaim: payload => payload,
    policeRecord: payload => payload,
    witness: payload => payload,
    insuranceCompany: payload => payload,
    liabilityInsurance: payload => {
        const {certificateNumber, ...company} = payload
        return {
            certificateNumber: payload.certificateNumber, // certificate number is mandatory but kickstart form does not ask for it, should we make it required on the client?
            company: {
                ...company
            }
        }
    }
}








export function* kickstartCreateDamageSubSaga (action, assigneeId = null) {
    const currentBranchId = yield select(getCurrentBranchId)
    const damageData = action.payload.damageData
    const customer = damageData.customer
    const damage = damageData.damage
    const vehicle = damage.vehicle
    const createCustomerPayload = dataMappers.exportMappers.damage.createCustomerDataMapper(
        customer, 
        vehicle
    )
    let customerId = customer?.id
    
    if(!exists(customerId)){
        const createCustomerResponse = yield resourceCall(
            api.gateway.customer.postCustomer,
            null,
            createCustomerPayload
        )
        customerId = createCustomerResponse.id
    }

    const damagePayload = dataMappers.exportMappers.damage.createDamageKickstartDataMapper(
        damage, 
        customerId,
        assigneeId
    )

    const createDamageResponse = yield resourceCall(
        api.gateway.damage.createDamage,
        {
            branchId: currentBranchId
        },
        damagePayload
    )

    const liabilityData = damage.liabilityData

    if (!liabilityData) {
        return {
            createDamageResponse,
            customerId
        }
    }

    const updateLiabilityDataEffects = []
    for (const key of Object.keys(liabilityData)){

        const entity = liabilityData[key]
        const payload = liabilityDataMappers[key](entity)
        const effect = resourceCall(
            api.gateway.damage.updateDynamicFormLiabilityData,
            {
                damageId: createDamageResponse.id,
                endpoint: key,
            },
            payload
        )
        updateLiabilityDataEffects.push(effect)
    }

    if (updateLiabilityDataEffects.length)
    {
        yield all(updateLiabilityDataEffects)
    }

    return {
        createDamageResponse,
        customerId
    }
}