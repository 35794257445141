import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const DeletedInfo = (
    {
        activityData,
    }
) => {
    const {t}  = useTranslation()

    return <React.Fragment>
        {
            activityData.title !== '' && <React.Fragment>
                {t('Task')}: {activityData.title}
            </React.Fragment>
        }
    </React.Fragment>
}

DeletedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export  {DeletedInfo}
