import { useState } from 'react'
import { useStoreActions } from 'react-flow-renderer'
import { edgeSelectionHandler } from '../logic/edges'
import { getConnectionType, mapIds } from './mappers'

export const useNodeConnection = (elements, setElements, onNodeSelect = () => {}, onNodeConnect = () => {}) => {
    const [ selected, setSelected ] = useState(null)
    const { setSelectedElements } = useStoreActions(actions => actions)

    const selectionChange = selectedElements => {
        setSelected(selectedElements === null ? selected : selectedElements)
        edgeSelectionHandler(elements, setElements, selectedElements, setSelectedElements, onNodeSelect)
    }

    const connectNode = connection => {
        const connectionType = getConnectionType(elements, connection.source, connection.target)
        const {sourceId, targetId} = mapIds(connectionType, elements, connection)
        onNodeConnect(sourceId, targetId, connectionType)

        if(selected !== null){
            setSelectedElements(selected)
        }
    }

    return {
        selectionChange,
        connectNode,
        setSelected
    }
}