import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { TextInput } from 'application/components/controls/form'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { TwoColumns } from 'application/components/forms/_common'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { validationMethods } from 'application/common'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from '../_partial_forms'

const DamageOpponentDriverForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()

    const defaultValues = {
        firstName: formData?.driver?.firstName || null,
        lastName: formData?.driver?.lastName || null,
        line1: formData?.driver?.address?.line1 || null,
        postalCode: formData?.driver?.address?.postalCode || null,
        locality: formData?.driver?.address?.locality || null,
        vehicleKeeperIsDriver: formData?.vehicleKeeperIsDriver
    }
    
    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue,
        formStateValues,
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <FlexBox flexDirection='column'>
        <TwoColumns 
            columnLeft={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.vehicleKeeperIsDriver}
                    name='vehicleKeeperIsDriver'
                    label={t('Keeper is driver')}
                    onChange={updateFormState}
                />
            }
        />
        {
            !formStateValues.vehicleKeeperIsDriver && <React.Fragment>
                <TwoColumns
                    columnLeft={
                        <TextInput 
                            label={t('First name')}
                            name='firstName'
                            register={register}
                            validate={formStateValues.vehicleKeeperIsDriver ? null : {notEmpty: validationMethods.notEmpty}}
                            error={errors.firstName}
                            onChange={updateFormState}
                        />
                    }

                    columnRight={
                        <TextInput 
                            label={t('Last name')}
                            name='lastName'
                            register={register}
                            validate={formStateValues.vehicleKeeperIsDriver ? null : {notEmpty: validationMethods.notEmpty}}
                            error={errors.lastName}
                            onChange={updateFormState}
                        />
                    }
                />

                <AddressDataPartialForm
                    register={register}
                    errors={errors}
                    onChange={updateFormState}
                    setValue={setValue}
                />
            </React.Fragment>
        }
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save opponent driver data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { DamageOpponentDriverForm }