import { exists } from './data_helpers'

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item))
}
  
/**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target
    const source = sources.shift()
  
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} })
                mergeDeep(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    }
  
    return mergeDeep(target, ...sources)
}

/**
   * Return list of objects by list of ids
   * 
   * @param listOfIds array of ids
   * @param listOfObjects Array of objects with an id key
   * @returns {Array} list of objects with ids of listOfIds
*/

export const selectObjectsByIds = (listOfIds, listOfObjects) => {
    return listOfObjects.filter(o => listOfIds.includes(o.id))
}

/**
   * Return list of objects with unique ids
   * 
   * @param objects the array of objects, must have id property
   * @returns {Array} list of objects with unique ids
*/
export const removeDuplicateIdsFromArrayOfObjects = objects => {
    return objects
        .filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
}

/**
 * Remove Properties with empty/null/undefined string values and empty objects from object recursively
 * @param {object} object 
 * @returns object without empty string values
 */
export const removePropertiesWithEmptyStrings = (object) => {
    return Object.fromEntries(
        Object.entries(object)
            .filter(([_, v]) => exists(v))
            .map(([k, v]) => {
                if(v instanceof Date){
                    return [k, v.toISOString()]
                }
                return [k, v]
            })
            .map(
                ([k, v]) => [k, v === Object(v) 
                    ? removePropertiesWithEmptyStrings(v) 
                    : v
                ]
            )
            .filter(([_, v]) => ( typeof v === 'object' && Object.keys(v).length > 0 ) 
            || typeof v !== 'object')
    )
}

/**
 * create chunks of a large array
 * @param {array} inputArray
 * @param {number} chunkSize default is 100
 * @returns array of arrays with the defined chunksize
 */

export const createArrayChunks = (inputArray, chunkSize = 100) => {
    return inputArray.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/chunkSize)
      
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }
      
        resultArray[chunkIndex].push(item)
      
        return resultArray
    }, [])
}




export const isEmptyObject = (object) => {
    return Object.keys(object).length === 0
}


/**
 * 
 * @param {object} object 
 * @param {string} path path to object property 
 * @returns objects property value
 */
export const getObjectsFieldByPath = (
    object = {}, 
    path = ''
) => {
    if (isEmptyObject(object) ||  path === '') 
        return null
    const pathArray = path.split('.')
    return pathArray.reduce(
        (result, key) => {
            return result[key]
        },
        object
    )

}

/**
 * 
 * @param {object} object 
 * @param {string} path path to object property 
 * @returns object with empty property value
 */
export const objectEmptyPropertyValue = (
    object = {}, 
    path = ''
) => {
    if (isEmptyObject(object) || path === '') 
        return null
    const pathArray = path.split('.')
    const lastKey = pathArray.pop()
    const nestedObject = pathArray.reduce(
        (result, key) => {
            return result[key]
        },
        object
    )
    nestedObject[lastKey] = ''
}

/**
 * 
 * @param {object} object 
 * @param {string} path path to object property 
 * @returns object with empty property value
 */
export const removeObjectProperty = (obj, path) => {
    const pathArray = path.split('.')
    const lastKey = pathArray.pop()
    const nestedObject = pathArray.reduce((acc, key) => acc[key], obj)
  
    if (nestedObject && lastKey in nestedObject) {
        delete nestedObject[lastKey]
    }
}