
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'

export const ExpertCommunicationOther = (
    {
        activity
    }
) => {
    const {
        damage
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
    </ActivityDetailWrapper>
}

ExpertCommunicationOther.propTypes = {
    activity: PropTypes.object.isRequired
}