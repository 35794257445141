import React from 'react'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import { WIDGET_CONTEXTS } from 'application/constants'
import styled from 'styled-components'


const StyledOverViewSideBar = styled.div`
display: block;
`

export const DamageManagerOverviewSidebarComponent = () => <StyledOverViewSideBar>
    <WidgetArea 
        widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW_SIDEBAR} 
        childrensMargin = '24px'
    />
</StyledOverViewSideBar>