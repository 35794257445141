import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'



export const getAccountById = (params) => ({
    authenticated: () => {
        const request = fetchRequestBuilder(
            `accounts/${params.accountId}`,
            HTTP_METHOD.GET
        )
        return  apiCall(request)
    },
    sharedResource: () =>  {
        const request = fetchRequestBuilderPin(
            `accounts/${params.accountId}`,
            params.pin,
            HTTP_METHOD.GET
        )
        return  apiCall(request)
    }
})
