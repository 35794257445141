import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { DAMAGE_TYPE } from 'application/constants'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { controlling, common, ListFilter } from 'application/filters'
import { composeFilterQuery } from 'application/common/filter_helpers'

export function* widgetBranchSpecificControllingVacantPositionsLiabilitySaga(action){
    const { branchId } = action.match.params

    const filterQuery = composeFilterQuery([
        new ListFilter(controlling.vacantPositionsByType).setValue(DAMAGE_TYPE.LIABILITY),
        new ListFilter(common.branchId).setValue(branchId)
    ])

    const response = yield resourceCall(
        api.gateway.controlling.getVacantPositions, 
        {
            filterQuery
        }
    )
    
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {response}))
}