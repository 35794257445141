import i18next from 'i18next'
import { buildApiUrl } from 'application/api/helpers'
import { HTTP_METHOD } from 'application/api/request_builders'

export const fetchRequestBuilderPin = (
    apiEndpoint, pin = '', 
    method = HTTP_METHOD.POST, 
    payload, 
    absolute = false, 
    omitToken = false
) => {
    const url = buildApiUrl(apiEndpoint, absolute)
    
    let data = {
        method,
        headers: { 
            'Content-Type': 'application/json',
            'Accept-Language': i18next.language
        },
        mode: 'cors'
    }

    data = method !== HTTP_METHOD.GET ? {
        ...data,
        body: JSON.stringify(payload)
    } : data

    data.headers = !omitToken ? {
        ...data.headers,
        'Authorization': 'Bearer ' + pin
    } : data.headers

    return {
        data,
        url
    }
}