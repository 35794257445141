import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import styled from 'styled-components'
import PropTypes from 'prop-types'



const StyledIcon = styled.div`
    display:flex;
    align-items:center;
    font-size: ${ props => props.iconSize };
    color: ${ 
    props => props.type === 'error'
        ? props.theme.color.error 
        : props.theme.color.success
};
`

const StyledMessage = styled.div`
    font-size: ${props => props.fontSize};
`



const SimpleList = (
    {
        list,
        iconKey,
        type = 'error',
        fontSize = '14px',
        iconSize = '16px'
    }
) => {

    return <FlexBox flexDirection='column'>
        {
            list.map(
                (element, index) => {
                    return <FlexBox key={index} alignItems='center'>
                        <StyledIcon 
                            type={type}
                            iconSize={iconSize}
                        >
                            <i className='material-icons'>{iconKey}</i>
                        </StyledIcon>
                        <FlexBox paddingLeft='8px'>
                            <StyledMessage 
                                type={type}
                                fontSize={fontSize}
                            >
                                {element}
                            </StyledMessage>
                        </FlexBox> 
                    </FlexBox>
                }
            )
        }
    </FlexBox>
}

SimpleList.propTypes = {
    list: PropTypes.array.isRequired,
    iconKey: PropTypes.string.isRequired,
    type: PropTypes.string,
    fontSize: PropTypes.string,
    iconSize: PropTypes.string
}

export {SimpleList}