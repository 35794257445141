import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FileIcon, defaultStyles } from 'react-file-icon'

const StyledFallbackThumbnailComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${props => props.isActive ? props.theme.color.primary : 'transparent'};
    width: ${props => props.theme.thumbnail.width - 2}px;
    height: ${props => props.theme.thumbnail.height - 2}px;
    &>svg{
        width: 46px;
    }
`
const FallbackThumbnailComponent = ({ 
    fileExtension = '',
    isActive = false
}) => {
    return (
        <StyledFallbackThumbnailComponent className='StyledFallbackThumbnailComponent' isActive={isActive}><FileIcon extension={fileExtension} {...defaultStyles[fileExtension]}/></StyledFallbackThumbnailComponent>
    )
}

FallbackThumbnailComponent.propTypes = {
    fileExtension: PropTypes.string.isRequired,
    isActive: PropTypes.bool
}

export  { FallbackThumbnailComponent }