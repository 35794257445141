import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import {CustomersOverview as CustomersOverviewComponent} from './component'


const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenCreateCustomerDialog: () => {
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CREATE_CUSTOMER,
                    true, 
                    {}, 
                    'Create customer'
                )
            )
        }
    }
}


export const CustomersOverview = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersOverviewComponent)