export const INVOICE_TYPE = {
    UNKNOWN: 'Unknown',
    REPAIR: 'Repair',
    RENTAL_CAR: 'RentalCar',
    TOWING: 'Towing',
    EXPERT: 'Expert',
    MISC: 'Misc',
    FICTITIOUS_REPAIR: 'FictitiousRepair',
    FLAT_RATE_FEE: 'FlatRateFee',
    IMPAIRMENT: 'Impairment',
    LOSS_OF_USE: 'LossOfUse',
}