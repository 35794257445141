import { DIALOG } from 'application/constants'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteDamageComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteDamage: (damageId, successMsg) => {
            dispatch(sendPayloadRequest(
                DIALOG.NAME.DELETE_DAMAGE, 
                { 
                    damageId, 
                    successMsg 
                }
            )
            )
        },
    }
}

export const DeleteDamage = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteDamageComponent)