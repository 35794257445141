import { connect } from 'react-redux'
import { GroupSearchSelectComponent } from './component'
import { withGroupsLoader } from 'application/components/higher_order_components'

const mapStateToProps = (state) => {
    return  {
        groups: state.common.userGroups,
        groupsLoading: state.common.userGroupsLoading,
        groupsLoaded: state.common.userGroupsLoaded
    }
}
   
export const GroupSearchSelect = connect(
    mapStateToProps
)(
    withGroupsLoader(GroupSearchSelectComponent)
)