import { defaultStore } from 'application/redux/reducers/default_store'
import { uiSettingsUpdateRunner } from './ui_settings_update_runner'

const userSettingsUpdaters = [
    {
        toVersion: 1,
        update: () => {
            return defaultStore.uiSettings.userSettings
        }
    },
    {
        toVersion: 2,
        update: (settings) => {
            return {
                ...settings,
                useLegacyDamageTables: false,
                version: 2
            }
        }
    }
]

export const userSettingsIntegrityCheck = (userSettings = {
    version: 0
}) => {

    const newestUserSettingsVersion = parseInt(process.env.REACT_APP_USER_SETTINGS_VERSION)
    const isUpToDate = userSettings.version === newestUserSettingsVersion
    const updatedSettings = isUpToDate 
        ? userSettings : 
        uiSettingsUpdateRunner(userSettings, userSettingsUpdaters, newestUserSettingsVersion)

    return {
        isUpToDate,
        updatedSettings
    }
}