import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import { CaseFileStatusName } from 'application/components/controls/casefile_status_name'
import { isDefined } from 'application/common/data_helpers'

export const CasefileInfoUpdatedActivity = (
    {activity}
) => {
    const {t} = useTranslation()


    const {
        oldCaseFileInfo,
        newCaseFileInfo
    } = activity.data

    const hasCasefileInfoStatus = isDefined(newCaseFileInfo?.caseFileStatus) 
                               || isDefined(oldCaseFileInfo?.caseFileStatus)
    const hasReferenceId = isDefined(newCaseFileInfo?.referenceId) 
                        || isDefined(oldCaseFileInfo?.referenceId)
    const hasNote = isDefined(newCaseFileInfo?.note) 
                 || isDefined(oldCaseFileInfo?.note)

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Casefileinformation has been updated')}
        </Fragments.ActivityTitle>
        {
            hasCasefileInfoStatus && <Fragments.ActivityParagraph>
                <CaseFileStatusName 
                    caseFileStatusString =  {oldCaseFileInfo?.caseFileStatus}
                />
                <RightArrow />
                <CaseFileStatusName 
                    caseFileStatusString = {newCaseFileInfo?.caseFileStatus}
                />
            </Fragments.ActivityParagraph>
        }
        {
            hasReferenceId && <Fragments.ActivityParagraph>
                {oldCaseFileInfo?.referenceId}
                <RightArrow />
                {newCaseFileInfo?.referenceId}
            </Fragments.ActivityParagraph>
        }
        {
            hasNote &&  <Fragments.ActivityParagraph>
                {oldCaseFileInfo?.note}<RightArrow />{newCaseFileInfo?.note}
            </Fragments.ActivityParagraph>
        }
    </React.Fragment>
}