import React, {useCallback} from 'react'
import { useDropzone } from 'react-dropzone'
import { fileHelpers } from 'application/common'
import * as Fragments from './fragments'
import { useAccept } from 'application/components/hooks'


export const UploaderComponent = ({
    onFileAdded, 
    onFilesRejected,
    onFilesAddStart = () => {},
    children,
    onFilesAdded,
    disabled=false,
    mobileView = false,
    multiple = true,
    allowedFileExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS
}) => {
    const { accept } = useAccept(allowedFileExtensions)
    
    const options = {
        multiple,
        disabled: disabled,
        accept,
        onDropRejected: onFilesRejected,
        minSize: 1,
        onDrop: useCallback(
            acceptedFiles => { 
                fileHelpers.readFiles(
                    acceptedFiles, 
                    onFileAdded, 
                    onFilesAdded
                )
            }, [onFileAdded, onFilesAdded]
        )
    }
    const {
        getRootProps, 
        getInputProps/*, isDragActive*/
    } = useDropzone({...options})
    const {onClick, ...rootProps} = getRootProps()
    return <Fragments.DropZone 
        disabled={disabled}
        mobileView={mobileView}
        {...rootProps}
    >
        <input {...getInputProps()} />
        {children}
    </Fragments.DropZone>
}