import React from 'react'
import { useTranslation } from 'react-i18next'
import { exists } from 'application/common/data_helpers'
import { getFullName } from 'application/common/name_helpers'
import {
    CardContent,
    CardPreHeadline,
    CardHeadline,
    CardInfoRow,
} from 'application/components/fragments/cards'

export const CustomerDisplay = ({ customer }) => {
    const { t } = useTranslation()

    return (
        <CardContent>
            <CardPreHeadline>
                {customer.isCompany ? (
                    <React.Fragment>{t('Business customer')}</React.Fragment>
                ) : (
                    <React.Fragment>{t('Private customer')}</React.Fragment>
                )}
            </CardPreHeadline>

            <CardHeadline>{getFullName(customer)}</CardHeadline>

            <CardInfoRow icon="place" hasContent={exists(customer.address)}>
                {customer.address?.line1}
                <br />
                {customer.address?.postalCode} {customer.address?.locality}
            </CardInfoRow>

            <CardInfoRow icon="mail_outline" hasContent={exists(customer.email)}>
                {customer.email}
            </CardInfoRow>

            <CardInfoRow icon="phone" hasContent={exists(customer.phoneNumber)}>
                {customer.phoneNumber}
            </CardInfoRow>

            <CardInfoRow
                icon="smartphone"
                hasContent={exists(customer.mobilePhoneNumber)}
            >
                {customer.mobilePhoneNumber}
            </CardInfoRow>

            <CardInfoRow icon="credit_card" hasContent={exists(customer.iban)}>
                {customer.iban ? customer.iban.replace(/\s+/g, '') : ''}
            </CardInfoRow>
            <CardInfoRow 
                icon="wysiwyg" 
                hasContent={exists(customer.legalProtectionInsurance?.company?.name)}
            >
                {customer.legalProtectionInsurance?.company?.name}
            </CardInfoRow>

        </CardContent>
    )
}
