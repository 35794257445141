import React from 'react'
import { StyledMoParagraph } from './fragments'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

const MoMappedFeedBack = (
    {
        itemsDesignation,
        itemDesignation,
        itemsCount = 0
    }
) => {
    const {t} = useTranslation()

    const mapFeedBackKey = (itemsCount) => {       
        switch (itemsCount) {
        case 0:    
            return 'No elements have been selected yet'
        case 1:
            return 'One element is selected. Wich action would you apply to your selection?'
        default: 
            return  'elements are selected. Wich action would you apply to your selection?' 
        }
    }


    const mapDesignation = (itemsCount) => {       
        switch (itemsCount) {
        case 0:    
            return itemsDesignation
        case 1:
            return itemDesignation
        default: 
            return  itemsDesignation
        }
    }


    return  <StyledMoParagraph>
        {
            itemsCount > 1 && <span> {itemsCount} </span> 
        }
        <Trans 
            i18nKey={mapFeedBackKey(itemsCount)}   
        >
                    "{ t( mapDesignation(itemsCount) ) }"
        </Trans>
    </StyledMoParagraph>    
}

MoMappedFeedBack.propTypes = {
    itemsDesignation: PropTypes.string.isRequired,
    itemDesignation: PropTypes.string.isRequired,
    itemsCount: PropTypes.number,
}

export { MoMappedFeedBack }