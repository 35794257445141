import { ACTIONS } from 'application/constants'

//categories
export const getCategoriesRequest = (accountId) => ({
    type: ACTIONS.COMMON_GET_CATEGORIES_REQUEST,
    accountId
})

export const populateCategories = (categories, accountId) => ({
    type: ACTIONS.COMMON_POPULATE_CATEGORIES,
    categories,
    accountId
})

export const changeAttachmentCategory = (damageId, attachmentId, category) => ({
    type: ACTIONS.COMMON_CHANGE_ATTACHMENT_CATEGORY,
    damageId,
    attachmentId,
    category
})

export const changeAttachmentCategoryFromTask = (damageId, attachmentId, category) => ({
    type: ACTIONS.COMMON_CHANGE_ATTACHMENT_CATEGORY_FROM_TASK,
    damageId,
    attachmentId,
    category
})

export const moveCategory = payload => ({
    type: ACTIONS.COMMON_MOVE_CATEGORY,
    payload
})

export const resetCategories = () => ({
    type: ACTIONS.COMMON_RESET_CATEGORIES
})