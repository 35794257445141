import { ACTIONS } from 'application/constants'

export const logoutRequest = () => ({
    type: ACTIONS.AUTH_LOGOUT_REQUEST
})

export const loginRequestSuccess = () => ({
    type: ACTIONS.AUTH_LOGIN_SUCCESS
})

export const resetLogin = () => ({
    type: ACTIONS.AUTH_RESET_LOGIN
})

export const setReturnUrl = (returnUrl) => ({
    type: ACTIONS.AUTH_SET_RETURN_URL,
    returnUrl
})