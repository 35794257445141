import React, { useMemo, useState } from 'react'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { ActionButton } from '@ec/ui-controls-react'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { useTranslation } from 'react-i18next'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'
import { PdfSignature } from 'application/components/building_blocks/pdf_signature'
import {  pdfBuilders } from 'application/components/dynamic_pdfs'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useSelector } from 'react-redux'
import { useStatefulForm } from 'application/components/hooks'
import { validationMethods } from 'application/common'
import { PartialForms } from 'application/components/forms'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'




export const Step1 = (
    (
        {
            goToPreviousStep,
            files,
            setFiles,
            expertCommissionData,
            handleOnCommissionExpert,
            opponentInsuranceData,
            customer
        }
    )=>{
        const {t} = useTranslation()
        const hasData = expertCommissionData !== null
        const hasFiles = files.length > 0
        const disableSubmit = !hasData
        const [showSignablePdf, setShowSignablePdf] = useState(false)
        const branch = useSelector(state => state.auth.currentBranch)
        const [addCancellationPolicy, setAddCancellationPolicy] = useState(true)
        const submitButtonText = hasFiles ? 'Send commission' : 'Send commission without documents'

        const signatureFields = useMemo(
            ()=>{
                return addCancellationPolicy 
                    ? 
                    [
                        {
                            label: 'Assignment',
                            description: ''
                        }, 
                        {
                            label: 'Data protection',
                            description: ''
                        },
                        {
                            label: 'Revocation',
                            description: ''
                        }
                    ]
                    : 
                    [
                        {
                            label: 'Assignment',
                            description: ''
                        }, 
                        {
                            label: 'Data protection',
                            description: ''
                        }
                    ]
            },[addCancellationPolicy]
        )

        const damage = useSelector( state => state.pages.damageManager.shared.damage)

        const defaultValues = {
            address: {
                line1: expertCommissionData?.address?.line1 || '',
                locality: expertCommissionData?.address?.locality || '',
                postalCode: expertCommissionData?.address?.postalCode || ''
            },
            contact: {
                email: expertCommissionData?.email || '',
                phoneNumber: expertCommissionData?.phoneNumber || ''
            }
        }

        const {
            register,
            setValue, 
            updateFormState,
            errors,
            getValues,
            handleSubmit,
            control,
            formStateValues
        } = useStatefulForm({defaultValues})



        const pdfValues =    {
            expertData: {
                ...expertCommissionData,
                address: getValues().address,
                contact: getValues().contact
            },
            damage: {
                date: damage?.incidentDate || '',
                time: '',
                location: opponentInsuranceData?.accidentReport?.location?.description || '', 
                reportNumber: '', 
                claimNumber: damage?.comprehensiveData?.claim?.number || '',
                amount: '' 
            },
            client: { 
                firstName: customer?.firstName || '',
                lastName: customer?.lastName || '',
                street: customer?.address?.line1 || '',
                postalCode: customer?.address?.postalCode || '',
                locality: customer?.address?.postalCode || '',
                eligibleForInputTaxDeduction: customer?.isEntitledToTaxDeduction || '',
                vehicle: {
                    licensePlate: damage?.vehicle?.licensePlate || '',
                    chassisNumber: damage?.vehicle?.chassisNumber || '',
                    make: damage?.vehicle?.make || '',
                    model: damage?.vehicle?.model || ''
                }
            }, 
            tortFeasor: {
                firstName: opponentInsuranceData?.vehicle?.keeper?.firstName || '',
                lastName: opponentInsuranceData?.vehicle?.keeper?.lastName || '',
                street: opponentInsuranceData?.vehicle?.keeper?.address?.line1 || '',
                postalCode: opponentInsuranceData?.vehicle?.keeper?.address?.postalCode || '',
                locality: opponentInsuranceData?.vehicle?.keeper?.address?.locality || '',
                vehicle: {
                    licensePlate: opponentInsuranceData?.vehicle?.licensePlate || '',
                    chassisNumber: opponentInsuranceData?.vehicle?.chassisNumber || '',
                    make: opponentInsuranceData?.vehicle?.make || '',
                    model: opponentInsuranceData?.vehicle?.model || '',
                    insurance: {
                        name: opponentInsuranceData?.insurance?.company?.name || '',
                        claimNumber: opponentInsuranceData?.insurance?.certificateNumber || ''
                    }
                },
                location: opponentInsuranceData?.accidentReport?.location?.description || ''
            },
            locality:  branch?.address?.locality || '',
            signatures: []
        }

        const onSubmitSuccess = () => {
            setShowSignablePdf(!showSignablePdf)
        }

        return<React.Fragment>
            <DialogMainContent>
                {
                    showSignablePdf && <PdfSignature
                        pdfBuilder={pdfBuilders.declarationOfAssignmentPdfBuilder}
                        pdfValues={pdfValues} 
                        pdfFileName={`Abtretungserklärung-${pdfValues.client.firstName}-${pdfValues.client.lastName}-${pdfValues.client.vehicle.licensePlate}.pdf`}
                        onSave={
                            (url, attachment)=>{
                                setFiles([...files, attachment])
                                setShowSignablePdf(false)
                            }
                        }
                        abort={
                            ()=>{ setShowSignablePdf(false) }
                        }
                        headline='Declaration of assignment'
                        saveButtonText='Done'
                        signatureFields={signatureFields}
                    />
                }
                <SubHeadline>
                    {t('Contact details of the assessor')}
                </SubHeadline>
                <PartialForms.AddressDataPartialForm
                    onChange={updateFormState}
                    nameStreet = 'address.line1'
                    namePostalCode = 'address.postalCode'
                    nameCity = 'address.locality'
                    register={register}
                    errorNameStreet={errors?.address?.line1}
                    errorNamePostalCode={errors?.address?.postalCode}
                    errorNameCity={errors?.address?.locality}
                    validate={null}
                    validatePostalCode={
                        { mustBeGermanPostalCode: validationMethods.mustBeGermanPostalCode }
                    }
                    setValue={setValue}
                />
                <PushBottom16 />
                <PartialForms.ContactDataPartialForm 
                    namePhoneNumber = 'contact.phoneNumber'
                    nameEmail='contact.email'
                    errorNamePhoneNumber={errors?.contact?.phoneNumber}
                    errorNameEmail={errors?.contact?.email}
                    register={register}
                    errors={errors}
                    onChange={updateFormState}
                    control={control}
                    setValue={setValue}
                    defaultValuePhoneNumber={formStateValues?.branchInfo?.contact?.phoneNumber || ''}
                    phoneValidationRules={null}
                />    
                <PushBottom16 />
                <FlexBox width='auto'>
                    <ActionButton
                        buttonText={t('Generate declaration of assignment')}
                        onButtonClick={
                            handleSubmit(onSubmitSuccess)
                        }
                    /> 
                    <Spacer basis='16px'/>
                    <PureCheckBox 
                        label={t('Add cancellation policy')}
                        checked={addCancellationPolicy}
                        onChange={setAddCancellationPolicy}
                    />
                </FlexBox>
                <PushBottom16 />
                <PushBottom16 />
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={files}
                    setFiles={setFiles}
                />   
            </DialogMainContent>
            <StepsLayoutFooter>
                <IconButtonWithLabel
                    iconKey='arrow_back' 
                    label={t('Back')} 
                    onButtonClick={goToPreviousStep} 
                />
                <ActionButton
                    buttonText={t(submitButtonText)} 
                    onButtonClick={handleOnCommissionExpert}
                    disabled={disableSubmit}
                />
            </StepsLayoutFooter>
        </React.Fragment>
    })