import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const postCustomer = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                'customers',
                HTTP_METHOD.POST,
                payload
            )
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/customers`,
                params.pin,
                HTTP_METHOD.POST,
                payload
            )
            return apiCall(request)
        },
        kickstart: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/customers`,
                params.pin,
                HTTP_METHOD.POST,
                payload
            )
            return apiCall(request)
        }
    }
)