import { put, all } from 'redux-saga/effects'

import { api, dataMappers } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageVehicleOwnerSaga(action){
    const { damageId } = action.match.params

    const [victimData, opponentData] = yield all([
        resourceCall(
            api.gateway.damage.getVehicleKeeperByDamageId, 
            {
                damageId: damageId
            }
        ),
        resourceCall(
            api.gateway.damage.getOpponentKeeper, 
            {
                damageId: damageId
            }
        )
    ])

    const response = dataMappers.importMappers.damage.damageVehicleDataMapper(victimData, opponentData)
    yield put(updateWidget(action.key, action.dependentWidgetKeys, response))
}