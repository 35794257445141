import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const MessageListWrapper = styled.div`
    background: ${(props) => props.theme.color.white};
    width: 100%;
    height: inherit;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-top: 1px;
    overflow: auto;
    padding-bottom: 60px;
    span {
        padding-left: 16px;
    }
`

export const WrapperLink = styled(NavLink)`
    padding-bottom: 32px;
    text-decoration: none;
`