import { withStatuslistLoader } from 'application/components/higher_order_components'
import { damagesOverviewUpdateStatus } from 'application/redux/actions/pages/damages_overview/all_damages'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useClickOutside } from 'application/components/hooks'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'


const StyledStatusSelect = styled.div`
    position: relative;
    width: 100%;
    min-width: 150px;
    background: ${props => props.theme.color.white};
    border: 1px solid ${props => props.theme.color.gray20};
`

const StyledDropdown = styled.ul`
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    background: ${props => props.theme.color.white};
    border: 1px solid ${props => props.theme.color.gray20};
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    height: 200px;
    top: -18px;
`
const listOptionCss = `
    display: flex;
    align-items: center; 
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    word-break: break-word;
`


const StyledOption = styled.li`
    ${listOptionCss}
    background: ${props => props.isActive 
        ? props.theme.color.primary
        : props.theme.color.white
};
    color: ${props => props.isActive
        ? props.theme.color.white
        : props.theme.color.anthracite
};
    :hover {
        background:${props => props.isActive
        ? props.theme.color.primary
        : '#deebff'
};
    }
`

const StyledInitialField = styled.div`
    ${listOptionCss}
    width: 100%;
    opacity: 1; /* Firefox */
    background: ${props => props.loading === 'true' 
        ? props.theme.color.smokewhite
        : props.theme.color.white };
`



const DamageStateRowSelectComponent = withStatuslistLoader(
    (
        {
            //parent
            damageState,
            setDamageState,
            editable,
            setEditable,
            damageId,
            //container
            onUpdateState,
            //withStatusListloader
            statusList = {
                data: [],
                loaded: false,
                loading: false
            },
            showDetails
        }
    ) => {
        const {t} = useTranslation()
        const statusSelectRef = useRef()
        useClickOutside(statusSelectRef, () => setEditable(false))

        const handleClick = (e, handle) => {
            e.preventDefault()
            e.stopPropagation()
            handle()
        }

        if (statusList.loading || !statusList.loaded ) {
            return <StyledStatusSelect>
                <StyledInitialField 
                    loading={ statusList.loading ? 'true' : 'false' }
                >
                    {t('loading...')}
                </StyledInitialField>
            </StyledStatusSelect>
        }
        

        const options = statusList.data.map(
            status => ({
                id: status.id,
                label: status.name
            })
        )

        const getSelectedState = (option) =>  statusList.data.find(
            s => s.id === option.id
        )

        return <StyledStatusSelect
            ref={statusSelectRef}
        >
            <StyledDropdown>
                {
                    options.map( 
                        (option, i) => <StyledOption
                            key={i}
                            isActive={option.id === damageState.id}
                            onClick={
                                (e)=>{
                                    handleClick(
                                        e,
                                        () => {
                                            onUpdateState(
                                                damageId,
                                                getSelectedState(option),
                                                showDetails
                                            )
                                            setDamageState(
                                                getSelectedState(option)
                                            )
                                            setEditable(!editable)
                                        }
                                    )
                                }
                            }
                        >
                            {option.label}
                        </StyledOption>
                    )
                }
            </StyledDropdown>
        </StyledStatusSelect>
    }
)

DamageStateRowSelectComponent.propTypes = {
    damageState: PropTypes.object.isRequired,
    setDamageState: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    setEditable: PropTypes.func.isRequired,
    damageId: PropTypes.string.isRequired,
    onUpdateState: PropTypes.func.isRequired,
    statusList: PropTypes.object
}



const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onUpdateState: (
            damageId,
            stateId,
            isOpen     
        ) => dispatch(
            damagesOverviewUpdateStatus(
                damageId, 
                stateId,
                isOpen
            )
        ),
    
    }
}

export const DamageStateRowSelect = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageStateRowSelectComponent)

