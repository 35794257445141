import { fetchRequestBuilderWithToken, fetchHandler, limiter, HTTP_METHOD } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'


export const putPreferredAccount = (
    payload, 
    accessToken
) => {

    const request = fetchRequestBuilderWithToken(
        'preferredaccount', 
        HTTP_METHOD.PUT, 
        accessToken,
        payload
    )

    return limiter.schedule(
        () => fetchHandler(
            request.url, 
            request.data
        )
    ).then(handleApiResponse)
}