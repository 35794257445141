import styled from 'styled-components'

export const TextInputWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;

    & input{
        color: ${props => props.theme.color.anthracite};
        border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
        height: 38px;
        padding-left: 8px;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        box-sizing: border-box;

        &:hover {
            border: 1px solid ${props => props.disabled ?  props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red   : props.theme.color.lightPowderGray};
        }
    
        &:active {
            border: 1px solid ${props => props.disabled ? props.theme.color.gray15   : props => props.theme.color.anthracite};
        }
    
        &:disabled, &:read-only {
            background-color: ${props => props.theme.color.gray5};
            color: ${props => props.theme.color.anthracite50};
            &:hover{
                border: 1px solid ${props => props.theme.color.gray15};
            }
        }
    
        &:focus{
            outline: none;
        }
    
        &:invalid{
            border: 1px solid ${props => props.hasPattern ? props.theme.color.red : props.theme.color.gray15};
        }
    }
`