import { IconButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import React from 'react'
import styled, { withTheme } from 'styled-components'

const StyledListElement = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 35px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;

    margin-bottom: 8px;

    font-size: ${props => props.theme.fontSize.small};
    background: ${props => props.theme.color.gray5};
    *border: 1px solid ${props => props.theme.color.gray10};

    & i {
        font-size: ${props => props.theme.fontSize.medium};
    }
`

const StyledFileName = styled.div`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.small};
    font-weight:  ${props => props.theme.fontWeight.smallBold};
`



const MoDocumentListElementComponent = ({
    document,
    removeItem = () => {},
    theme,
    hideRemoveButton = false
}) => {
    
    const handleRemoveItem = () => {
        removeItem(
            document.id
        )
    }

    return <StyledListElement>
        <FlexBox>
            <StyledFileName>
                {document.fileName}           
            </StyledFileName>
        </FlexBox>
        {
            !hideRemoveButton && <FlexBox
                justifyContent='flex-end'
            >
                <IconButton
                    border={true} 
                    fontSize='inherit' 
                    iconKey='close' 
                    onButtonClick = {handleRemoveItem}
                    height='35px'
                    width='35px'
                    color={theme.color.primary}
                />
            </FlexBox>
        }
        
    </StyledListElement>
}



export const MoDocumentListElement = withTheme(MoDocumentListElementComponent)