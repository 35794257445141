import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteMessageComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteMessage: (
            name, 
            payload,
            metaData            
        ) => {
            dispatch(
                sendPayloadRequest(
                    name, 
                    payload,
                    metaData
                )
            )
        },
    }
}

export const DeleteMessageDialog =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteMessageComponent)