import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import * as dataMappers from 'application/api/data_mappers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogUpdateUserGroupsSaga(action) {

    yield put(
        showWaitingScreen('Saving groups')
    )
    
    const groupsPayload = dataMappers.exportMappers.users.updateUserGroupsDataMapper(action.payload.groups)
    const userId = action.metaData.userId

    yield resourceCall(
        api.gateway.users.putUserGroups,
        {
            userId
        },
        groupsPayload
    )


    yield put(
        showToast('Groups saved')
    )

    yield put(hideWaitingScreen())
}