import { pdf } from '@react-pdf/renderer'
import { RepairSchedulePdf } from './component'
import { repairScheduleValuesMapping } from './repair_schedule_values_mapping'


export const repairSchedulePdfBuilder = (            
    pdfValues
) => {

    const {
        damageData,
        repairScheduleData,
        customer,
        currentBranch,
        accountLogo,
        currentAccount,
        signatures
    } = pdfValues

    const mappedValues = repairScheduleValuesMapping(
        damageData,
        repairScheduleData,
        customer
    )

    return pdf(
        <RepairSchedulePdf 
            values={{
                ...mappedValues,
                currentBranch,
                currentAccount,
                accountLogo,
                signatures
            }}
        />
    )
}