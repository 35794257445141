import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { validationMethods } from 'application/common'
import { withTheme } from 'styled-components'

export const VehicleDataPartialForm = withTheme(({
    register,
    disabled = false,
    onChange = () => {},
    nameLicensePlate = 'licensePlate',
    nameMake = 'make',
    nameModel = 'model',
    hasChassisNumber = false,
    nameChassisNumber = 'chassisNumber',
    errorLicensePlate = null,
    validateLicensePlate = {notEmpty: validationMethods.notEmpty},
    theme
}) => {
    const {t} = useTranslation()
    return <FlexBox 
        flexDirection='column'
    >
        <FlexBox
            breakPoint={theme.breakpoint.medium}
            breakPointCssRules='flex-direction: column;'
        >
            <FlexBox 
                flexBasis='160px' 
                flexGrow='0' flexShrink='0'
                breakPoint={theme.breakpoint.medium}
                breakPointCssRules='flex-basis: auto;'
            >
                <TextInput 
                    label={t('License plate')}
                    name={nameLicensePlate}
                    register={register}
                    validate={validateLicensePlate}
                    error={errorLicensePlate}
                    onChange={onChange}
                    disabled={disabled}
                />
            </FlexBox>
            <FlexBox 
                flexBasis='12px' 
                flexGrow='0' 
                flexShrink='0' 
                breakPoint={theme.breakpoint.medium}
                breakPointCssRules='display: none;'
            />
            <FlexBox>
                <TextInput 
                    label={t('Vehicle brand')}
                    name={nameMake}
                    register={register}
                    validate={null}
                    onChange={onChange}
                    disabled={disabled}
                />
            </FlexBox>
            <FlexBox 
                flexBasis='12px' 
                flexGrow='0' 
                flexShrink='0' 
                breakPoint={theme.breakpoint.medium}
                breakPointCssRules='display: none;'
            />
            <FlexBox>
                <TextInput 
                    label={t('Vehicle model')}
                    name={nameModel}
                    register={register}
                    validate={null}
                    onChange={onChange}
                    disabled={disabled}
                />
            </FlexBox>
        </FlexBox>
        {
            hasChassisNumber && <FlexBox>
                <TextInput 
                    label={t('Chassis number')}
                    name={nameChassisNumber}
                    register={register}
                    validate={null}
                    onChange={onChange}
                    disabled={disabled}
                />
            </FlexBox>
        }
    </FlexBox>
})