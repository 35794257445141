import { connect } from 'react-redux'
import {ExportToLawFirmConfirmationComponent} from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'



const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(
        d => d.name === props.dialog.name
    )
})


const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (
            dialogName,
            payload,
            metaData
        ) => dispatch(
            sendPayloadRequest(
                dialogName,
                payload, 
                metaData
            )
        )
    }
}

export const ExportToLawFirmConfirmation = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportToLawFirmConfirmationComponent)