import React from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { withTheme } from 'styled-components'
import * as Legend from 'application/components/charts/_common/legend'
import { Spacer } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'


export const DamageCountChartComponent = ({chartData, theme}) => {
    const {t} = useTranslation()

    const dataSetDefaults = {
        fill: false,
        lineTension: 0.1,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10
    }

    const data = {
        labels: chartData.labels,
        datasets: [
            {
                label: t('All damages'),
                borderColor: theme.color.primary,
                data: chartData.all,
                tooltipLabel: t('damages overall'),
                ...dataSetDefaults
            },
            {
                label: t('Liability'),
                borderColor: theme.color.lime,
                data: chartData.liability,
                tooltipLabel: t('liability damages'),
                ...dataSetDefaults
            },
            {
                label: t('Casco'),
                borderColor: theme.color.orange,
                data: chartData.casco,
                tooltipLabel: t('casco damages'),
                ...dataSetDefaults
            }
        ]
    }

    const options = {
        responsive: true,
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 4
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: tooltipItem => `${tooltipItem.value} ${data.datasets[tooltipItem.datasetIndex].tooltipLabel}`
            }
        }
    }
    return <FlexBox flexDirection='column'>
        <Legend.LegendContainer cssPosition='static'>
            <Legend.LegendIndicator cssBackground={theme.color.primary} />
            <Legend.LegendLabel>{t('All damages')}</Legend.LegendLabel>
            <Legend.LegendIndicator cssBackground={theme.color.lime} />
            <Legend.LegendLabel>{t('Liability')}</Legend.LegendLabel>
            <Legend.LegendIndicator cssBackground={theme.color.orange} />
            <Legend.LegendLabel>{t('Casco')}</Legend.LegendLabel>
        </Legend.LegendContainer>
        <Spacer basis='10px' />
        <Line 
            data={data}
            options={options}
            height={80}
        />
    </FlexBox>
}

export const DamageCountChart = withTheme(DamageCountChartComponent)