import { connect } from 'react-redux'
import {UserMenuComponent}  from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import {DIALOG} from 'application/constants'
import { logoutRequest } from 'application/redux/actions/auth'


const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        accountsTotal: state.auth.accounts.total,
        accountsFound : state.auth.accounts.found
    }
}

const mapDispatchToProps = dispatch => {

    return {
        onEditUser: (payload) => { 
            dispatch(
                openDialogByName(
                    DIALOG.NAME.EDIT_CURRENT_USER,
                    true, 
                    payload,
                    'Edit user'
                )
            )
        },
        onEditNotificationSettings: (payload) => {
            dispatch(
                openDialogByName(
                    DIALOG.NAME.USER_NOTIFICATION_SETTINGS,
                    false, 
                    {
                        userId: payload.id
                    },
                    'Edit user notification settings'
                )
            )
        },
        onLogout: () => dispatch( logoutRequest() ),
    }
}

export const UserMenu = connect (
    mapStateToProps,
    mapDispatchToProps
) (UserMenuComponent)

