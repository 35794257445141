import React from 'react'
import styled from 'styled-components'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'

const StyledCheckboxBar = styled.div`
    padding: 8px;
    background-color: ${props => props.theme.color.gray10};
    margin-bottom: 16px;
    opacity: ${props => props.disabled ? '0.5' : '1'};
`

export const CheckboxBar = ({
    label,
    checked,
    onChange,
    disabled=false
}) => {
    return <StyledCheckboxBar disabled={disabled}>
        <PureCheckBox 
            label={label} 
            checked={checked} 
            onChange={onChange}  
            disabled={disabled}
        />
    </StyledCheckboxBar>
}