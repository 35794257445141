import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'
import { ShortenedString } from 'application/components/controls/shortened_string'

const NoteChangedInfo = (
    {activityData}
) => {

    const {t} = useTranslation()

    const {
        damage,
    } = activityData

    const oldNote = activityData?.oldNoteContent || ''
    const newNote = activityData?.newNoteContent || ''

    return <FlexBox>
        <React.Fragment>
            {t('Damage')} : {damage.licensePlate}, &nbsp;
        </React.Fragment>
        <ShortenedString
            text={oldNote} 
            shortenAt={20}
        />
        <RightArrow />
        <ShortenedString 
            text={newNote}  
            shortenAt={20}
        />
    </FlexBox>
}



NoteChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}



export {NoteChangedInfo} 