import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    DAMAGE_MANAGER_TASK_COMMENTS: 'DAMAGE_MANAGER_COMMENTS'
}

export const widgetsDamageManagerTasksSidebar = [
    {
        key: KEYS.DAMAGE_MANAGER_TASK_COMMENTS,
        type: WIDGET_TYPES.TASK_COMMENTS,
        title: 'Comments',
        hideHeadline: true,
        accountDependent: true
    }
]