import React from 'react'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { ActionButton } from 'application/components/controls/action_button'
import {ActionButtonBar} from 'application/components/controls/action_button_bar'
import { useTranslation } from 'react-i18next'



export const DamageManagerDocumentsTabbarButtonsC = (
    {
        documentCenterArchiveDownloading,
        damageId,
        customerId,
        onCreatePowerOfAttorney,
        hasLawfirm
    }
) => {
    const {t} = useTranslation()
    
    return <ActionButtonBar
        inline={true} 
        buttonsAlign='flex-end'>       
        {
            documentCenterArchiveDownloading 
            && <ThemedPreloader 
                small={true} 
                paddingTop='0' 
            />
        }        
        {
            hasLawfirm && <ActionButton
                onButtonClick={
                    () => onCreatePowerOfAttorney(
                        {
                            damageId, 
                            customerId
                        }
                    )
                } 
                buttonText={t('Power of attorney')}
            />
        }
    </ActionButtonBar>
}