import { fetchRequestBuilderWithoutToken, fetchHandler, limiter, HTTP_METHOD } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const connectAccount = (sharedResourceId, identityToken, payload) => {
    const request = fetchRequestBuilderWithoutToken('createaccount/' + sharedResourceId, HTTP_METHOD.POST, {
        name: payload.accountName,
        identityToken
    })

    return limiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}