export const REASONS_FOR_PAYMENT_REDUCTION = {
    SHIPMENT: 'Shipment', 
    RETAIL_PRICE_SURCHARGE: 'RetailPriceSurcharge', 
    SURVEYING: 'Surveying',
    CLEANING: 'Cleaning',
    DISINFECTION: 'Disinfection', 
    FINISHING: 'Finishing', 
    TEST_DRIVE: 'TestDrive',
    CALIBRATION: 'Calibration',
    OTHER: 'Other'
}