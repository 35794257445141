import styled from 'styled-components'

export const ActivityTitle = styled.h3`
    margin-top: 0;
    margin-bottom: 4px;
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};
    font-weight: ${props => props.theme.fontWeight.standardBold};
`

export const ActivityParagraph = styled.p`
    margin-top: 4px;
    margin-bottom: 0;
    font-size: ${props => props.theme.fontSize.small};
`