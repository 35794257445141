import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists } from 'application/common/data_helpers'
import { FormElementWrapper } from '../../_common'
import { ValidationErrorMessage } from 'application/components/controls/form/_common'
import { useRegister } from 'application/components/hooks/use_register'

export const StyledHiddenRadioButton = styled.input`
    display: none;

    /* CHECKED STYLES */
    :checked + label {
        background-color: ${props => props.theme.color.primaryBackground};
    }

    +label:after{
        content: ' ';
        font-weight: bold;
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        columns: ${props => props.theme.color.white};
        background-color: ${props => props.theme.color.white};
        border: 2px solid ${props => props.theme.color.gray15};
        color: ${props => props.theme.color.primary};
        border-radius: 16px;
    }

    :checked +label:after{
        content: '✓';
        border: 2px solid ${props => props.theme.color.primary};
    }
`

const StyledLabel = styled.label`
    cursor: pointer;
    background-color: ${props => props.theme.color.gray5};
    position: relative;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 62px;

    & div {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 8px;
        margin-right: 8px;
        flex: 0 0 120px;
    }

    & img{
        max-height: calc(100% - 16px);
        max-width: 112px;
    }

`

const RadioImageButtonGroup = (
    {
        options,
        register,
        validate,
        name,
        errors,
        onChange = () => {},
        cssWidth = '100%', 
        cssMargin='0', 
        disabled = false,
        errorMessageOverride='',
        hideValidationMessage = false, 
        error, 

    }
) => {
    const handleChange = (e) => {
        onChange(e.target.value)
    }

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate, handleChange)


    return <FormElementWrapper
        cssWidth={cssWidth}
        cssMargin={cssMargin} 
        disabled={disabled}
    >
        {
            options.map(
                (option, i) => {
                    
                    const {
                        imageSrc = '',
                        value = '',
                        label = ''
                    } = option

                    return <FlexBox key={i} flexDirection='row'>
                        <StyledHiddenRadioButton
                            type="radio"
                            onChange={onRegisterChange}
                            {...registerProps}
                            value={value}
                            id={`radio-${value}`}
                        />
                        <StyledLabel htmlFor={`radio-${value}`} hasLogo={exists(imageSrc)}>
                            <div>
                                {
                                    exists(imageSrc) && 
                                    <img 
                                        src={imageSrc}
                                        alt={label}
                                    />
                                
                                }
                            </div>
                            <span>
                                {label}
                            </span>
                        </StyledLabel>
                    </FlexBox>
                } 
            )
        }
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </FormElementWrapper>
}


RadioImageButtonGroup.propTypes = {
    options: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    validate: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func
}

export { RadioImageButtonGroup }