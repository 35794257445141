import { connect } from 'react-redux'
import { TabsLinksComponent } from './tabs_links'

const mapStateToProps = state =>( {
    branchPermissions: state.auth.permissions,
    allPermissions: state.auth.allPermissions
})

export const TabsLinks = connect(
    mapStateToProps
)(TabsLinksComponent)