import { put, takeLeading, all } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateAccessibleBranches } from 'application/redux/actions/common/branches'
import { selectObjectsByIds } from 'application/common/object_helpers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getAccessibleBranchesSaga(action) {
    try {
        
        const [
            allBranchesResponse, 
            accessibleBranchIdsResponse
        ] = yield all([
            resourceCall(api.gateway.auth.getAllBranches),
            resourceCall(api.gateway.auth.getAccessibleBranches)
        ])
    
        const accessibleBranches = selectObjectsByIds(
            accessibleBranchIdsResponse.branches,
            allBranchesResponse.branches
        )
        
        yield put(populateAccessibleBranches(accessibleBranches, allBranchesResponse.branches))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getAccessibleBranchesWatcher() {
    yield takeLeading(ACTIONS.COMMON_GET_ACCESSIBLE_BRANCHES_REQUEST, getAccessibleBranchesSaga)
}