/**
 * 
 * @param {array} allBranchesPermissions the permission sets for all branches, usually taken from redux store: state.auth.allPermissions 
 * @param {array} neededPermissions the permissions to be checked
 * @param {bool} allNeeded are all permissions needed or only one of them
 * @returns {bool} true, if the permission(s) are given in at least one branch
 */ 
export const isPermittedInAnyBranch = (
    allBranchesPermissions, 
    neededPermissions, 
    allNeeded = false
) => {
    if( neededPermissions.length < 1 ){
        return true
    }

    const aggregatedPermissions = allBranchesPermissions.map(a => a.permissions)
    const flattenedPermissions = [...new Set(aggregatedPermissions.flat())]

    return allNeeded 
        ? neededPermissions.every( r => flattenedPermissions.includes(r) )
        : neededPermissions.some( r => flattenedPermissions.includes(r) )
}