import React, { useEffect } from 'react'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import { exists } from 'application/common/data_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'



export const PreferredAccountPreloaderComponent = (
    {
        preferredAccount,
        onLoadPreferredAccount
    }
) => {
    const {accountLink} = useApplicationLinks()

    const isLocked = exists(preferredAccount.data) 
    && (preferredAccount.data.isLocked 
    || preferredAccount.data.user.isLocked)

    useEffect(
        () => {
            !preferredAccount.loaded
                && !preferredAccount.found
            && onLoadPreferredAccount()
        }, [
            onLoadPreferredAccount, 
            preferredAccount.found, 
            preferredAccount.loaded
        ]
    )

    if( 
        preferredAccount.loaded
            && preferredAccount.found
            && !isLocked
    ){
        return <Navigate 
            to={ 
                accountLink(
                    preferredAccount.data.id, 
                    ROUTES.ACCOUNT_PRELOADER
                )
            } 
        />
    }

    if( 
        preferredAccount.loaded
            && (
                !preferredAccount.found
                || isLocked
            )
    ){
        return <Navigate
            to={ROUTES.CHOOSE_ACCOUNT} 
        />
    }

    return <PagePreloader text='loading company data' />
}