import {  put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { api } from 'application/api'

export function* dialogCreateCustomerQRCodeSaga(action) {
    yield put(
        showDialogStepsWaitingScreen(
            'Generate QR code ...'
        )
    )

    const response = yield resourceCall(
        api.gateway.customer.putCustomerQRCodeResource,
        { 
            customerId: action.payload.customer.id,
            branchId: action.payload.branchId 
        }
    )

    yield put(
        updateDialogDataByName(
            action.dialogName,
            {
                ...action.payload,
                ...response
            }
        )
    )
    yield put(
        hideDialogStepsWaitingScreen()
    )
    yield put(
        showToast('QR-Code generated')
    )
}