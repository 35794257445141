import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { VacantPostionsChart, VacantPostionsInfo } from 'application/components/charts'
import { FlexBox } from 'application/components/fragments/flex_box'


const ControllingVacantPositionsContent = (
    {
        unpaidTotal, 
        paidTotal
    }
) => {
    const {t} = useTranslation()
    
    if(unpaidTotal + paidTotal === 0){
        return <WidgetErrorMessage>
            {t('No data available')}
        </WidgetErrorMessage>
    }

    return <FlexBox flexDirection='column' flexBasis='100%'>
        <FlexBox flexGrow='1'>       
            <VacantPostionsChart 
                unpaidTotal={unpaidTotal}
                paidTotal={paidTotal}
            />
        </FlexBox>
        <FlexBox
            flexGrow='0'
            flexShrink='0' 
            flexBasis='90px' 
            alignItems='flex-end'
        >
            <VacantPostionsInfo 
                unpaidTotal={unpaidTotal}
                paidTotal={paidTotal}
            />
        </FlexBox>
    </FlexBox>
}

ControllingVacantPositionsContent.propTypes = {
    unpaidTotal: PropTypes.number.isRequired,
    paidTotal: PropTypes.number.isRequired
}

export { ControllingVacantPositionsContent }