import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api, dataMappers } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { incrementCommentsTotalInTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { updateWidget } from 'application/redux/actions/widgets'




function* createCommentSaga(action) {
    try {
        const payload = dataMappers.exportMappers.comments.createCommentDataMapper(action)

        yield resourceCall(
            api.gateway.comments.createComment, 
            {
                damageId: action.damageId, 
                taskId: action.taskId,
            },
            payload
        )
        yield put(
            incrementCommentsTotalInTask(action.taskId)
        )
        yield put(
            showToast('Comment saved')
        )
    } catch (e) {
        yield handleError(e, action)
    }
}



function* createCommentWorkflowPanelSaga(action) {
    try {
        const payload = dataMappers.exportMappers.comments.createCommentWorkflowPanelDataMapper(action)
        yield resourceCall(
            api.gateway.comments.createComment, 
            {
                taskId: action.taskId,
            },
            payload
        )
        yield put(
            incrementCommentsTotalInTask(action.taskId)
        )
        yield put(
            showToast('Comment saved')
        )
    } catch (e) {
        yield handleError(e, action)
    }
}


export function* createCommentWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_COMMENT_CREATE, 
        createCommentSaga
    )
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_COMMENT_CREATE_WORKFLOW_PANEL, 
        createCommentWorkflowPanelSaga
    )
}







function* loadMoreCommentsSaga(action) {
    try {

        const {
            widgetKey,
            damageId,
            taskId,
            currentComments,
            offset,
            limit
        } = action

        const commentsByTaskResponse = yield resourceCall(
            api.gateway.comments.getCommentsByTask, 
            {
                damageId, 
                taskId,
                offset,
                limit
            }
        )

        const commentsTotal = commentsByTaskResponse.total

        const newComments = [ 
            ...currentComments, 
            ...commentsByTaskResponse.comments
        ]

        const newWidgedPayload = {
            comments: newComments,
            total: commentsTotal
        }

        yield put(
            updateWidget(
                widgetKey, 
                [],
                newWidgedPayload
            )
        )
        yield put(
            showToast('Comment saved')
        )
    } catch (e) {
        yield handleError(e, action)
    }
}



export function* loadMoreCommentsWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_COMMENTS_LOAD_MORE, 
        loadMoreCommentsSaga
    )
}