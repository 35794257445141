import { call, put, takeLeading } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { createAccountCallback } from 'application/redux/actions/registration'

function* createAccountSaga(action) {
    try {
        yield call(api.gateway.registration.connectAccount, action.sharedResourceId, action.idToken, action.formData)
        yield put(createAccountCallback())
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* createAccountWatcher() {
    yield takeLeading(ACTIONS.REGISTRATION_CREATE_ACCOUNT_REQUEST, createAccountSaga)
}