import { exists } from 'application/common/data_helpers'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'
import { FILTERS } from 'application/constants'

export const createDamageDataMapper = (payload, customerId) => {
    return removePropertiesWithEmptyStrings({
        ...payload,
        vehicle: {
            ...payload.vehicle,
            keeper: {
                customerId
            }
        }
    })
}

export const createDamageKickstartDataMapper = (
    payload, 
    customerId, 
    assigneeId = null
) => {
    if(exists(assigneeId)){
        return removePropertiesWithEmptyStrings({
            ...payload,
            assignee: {
                userId : assigneeId
            },
            vehicle: {
                ...payload.vehicle,
                keeper: {
                    customerId
                }
            }
        })
    }

    return removePropertiesWithEmptyStrings({
        ...payload,
        vehicle: {
            ...payload.vehicle,
            keeper: {
                customerId
            }
        }
    })
}

export const createCustomerDataMapper = (payload, preferredVehicleInfo = {}) => {
    const name = payload.isCompany
        ? {
            'isCompany': payload.isCompany,
            'companyName': payload.companyName
        } : {
            'firstName': payload.firstName,
            'lastName': payload.lastName,
            'isCompany': payload.isCompany,
        }

    return removePropertiesWithEmptyStrings({
        ...payload,
        ...name,
        preferredVehicleInfo
    })
}

export const updateComprehensiveDataDataMapper = payload => {
    if(payload.coverage === FILTERS.VALUES.DAMAGES.COVERAGE.NONE){
        return {
            'coverage': payload.coverage
        }
    }
    return {
        'company': {
            'id': payload.insuranceId
        },
        'certificateNumber': payload.certificateNumber,
        'coverage': payload.coverage,
        'deductable': payload.deductable
    }
}

export const updateComprehensiveDataClaimDataMapper = payload => {
    return {
        number: payload.claimNumber
    }
}

export const updateComprehensiveDateTypeDataMapper = payload => {
    return {
        id: payload.comprehensiveDamageTypeId
    }
}

export const updateWitnessDataDataMapper = payload => {
    return {
        'firstName': payload.firstName,
        'lastName': payload.lastName,
        'address': {
            'line1': payload.line1,
            'locality': payload.locality,
            'postalCode': payload.postalCode
        }
    }
}

export const opponentDriverDataMapper = payload => {
    return {
        'firstName': payload.firstName,
        'lastName': payload.lastName,
        'isKeeper': payload.vehicleKeeperIsDriver,
        'address': {
            'line1': payload.line1,
            'locality': payload.locality,
            'postalCode': payload.postalCode
        }
    }
}


export const opponentKeeperDataMapper = payload => {
    return payload.isCompany
        ? {
            'companyName': payload.companyName,
            'isCompany': payload.isCompany,
            'address': {
                'line1': payload.line1,
                'locality': payload.locality,
                'postalCode': payload.postalCode
            }
        } : {
            'firstName': payload.firstName,
            'lastName': payload.lastName,
            'isCompany': payload.isCompany,
            'address': {
                'line1': payload.line1,
                'locality': payload.locality,
                'postalCode': payload.postalCode
            }
        }
}

export const opponentKeeperAndDriverDataMapper = payload => {
    return {
        keeperPayload: opponentKeeperDataMapper(payload),
        driverPayload: payload.vehicleKeeperIsDriver
            ? {
                'firstName': payload.firstName,
                'lastName': payload.lastName,
                'isKeeper': true,
                'address': {
                    'line1': payload.line1,
                    'locality': payload.locality,
                    'postalCode': payload.postalCode
                },
            }
            : {
                'firstName': payload.driverFirstName,
                'lastName': payload.driverLastName,
                'isKeeper': false,
                'address': {
                    'line1': payload.driverLine1,
                    'locality': payload.driverLocality,
                    'postalCode': payload.driverPostalCode
                },
            }
    }
}


export const mapKeeperAsDriver = (keeper) => {
    const {
        isCompany,
        ...restOfKeeper
    } = keeper

    return {
        ...restOfKeeper,
        isKeeper: true
    }
}

export const mapKeeperToCompany = (keeper) => {
    const {
        firstName,
        lastName,
        ...restOfKeeper
    } = keeper

    return {
        ...restOfKeeper
    }
}

export const liabilityInsuranceDataMapper = payload => {
    return {
        certificateNumber: payload.certificateNumber,
        company: {
            id: payload.insuranceId
        }
    }
}

export const liabilityClaimDataMapper = payload => {
    return {
        number: payload.claimNumber
    }
}

export const damageNoteDataMapper = payload => {
    return {
        content: payload.noteContent
    }
}

export const vehicleProprietorDataMapper = payload => {
    if(payload.proprietaryReason === FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET){
        return {
            ...payload,
            isCompany: false,
            firstName: null,
            lastName: null,
            companyName: null,
            contractNumber: null
        }
    }

    return payload
}