import { PowerOfAttorneyPDF } from './component'
import { pdf } from '@react-pdf/renderer'

export const powerOfAttorneyPdfBuilder = (            
    pdfValues
) => {

    const {
        lawfirm,
        branch,
        inMatters,
        becauseOf,
        signatures,
        addRvgNote,
        damageLocation,
        incidentDate
    } = pdfValues

    const lawfirmName = lawfirm?.name || ''
    const lawFirmLine1 = lawfirm?.address?.line1 || ''
    const lawfirmPostalcode = lawfirm?.address?.postalCode || ''
    const lawfirmLocality = lawfirm?.address?.locality || ''
    const lawfirmInfo = `${lawfirmName}, ${lawFirmLine1}, ${lawfirmPostalcode}, ${lawfirmLocality}`
    const branchPostalCode = branch?.address?.postalCode || ''
    const branchLocality = branch?.address?.locality || ''
    const branchLocation = `${branchPostalCode} ${branchLocality}`


    const mappedPdfValues = {
        lawfirmInfo,
        inMatters,
        becauseOf,
        branchLocation,
        signatures,
        addRvgNote,
        lawfirmName,
        damageLocation,
        incidentDate,
        lawfirm
    }

    return pdf(
        <PowerOfAttorneyPDF 
            { ...mappedPdfValues }
        />
    )
}