import { connect } from 'react-redux'

import { ControllingGlobalVacantPositionsComponent } from './component'
import { refreshWidgetByKey } from 'application/redux/actions/widgets'

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (key) => dispatch(refreshWidgetByKey(key))
    }
}

export const ControllingGlobalVacantPositions = connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllingGlobalVacantPositionsComponent)