import React from 'react'
import uniqid from 'uniqid'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'
import { FormElementWrapper } from '../_common'
import { TextInputPureComponent } from './pure_component'
import { useRegister } from 'application/components/hooks/use_register'


export const TextInput = ({ 
    label, 
    name, 
    register, 
    onKeyDown = () => {}, 
    onKeyDownEnter = () => {}, 
    validate = null,
    error, 
    hideValidationMessage = false, 
    defaultValue='', 
    inputType='text', 
    errorMessageOverride='',
    onChange=()=>{}, 
    cssWidth = '100%', 
    cssMargin='0', 
    autoFocus=false, 
    disabled = false,
    readOnly = false,
    placeholder = '',
    required = false
} ) => {
    const htmlId = uniqid()

    const hasNotEmptyProperty = validate !== null 
    && typeof validate !== 'function' 
        ? validate.hasOwnProperty('notEmpty') 
        : false

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate, onChange)

    return <FormElementWrapper 
        cssWidth={cssWidth}
        cssMargin={cssMargin} 
        disabled={disabled}
    >
        <label 
            className='label' 
            htmlFor={htmlId}
        >
            {label}
            <RequiredFieldMarker 
                validate={validate}
            />
        </label>
        <TextInputPureComponent 
            autoFocus={autoFocus} 
            onKeyDown={onKeyDown}
            onKeyDownEnter={onKeyDownEnter}
            htmlId={htmlId} 
            defaultValue={defaultValue} 
            inputType={inputType} 
            onChange={onRegisterChange} 
            disabled={disabled} 
            readOnly={readOnly}
            placeholder={placeholder}
            {...registerProps}
            required={hasNotEmptyProperty}
        />
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </FormElementWrapper>
}