import { defaultStore } from '../default_store'
import {ACTIONS} from 'application/constants/actions'
import { getFirstObjectByOwnPropKey, removeObjectFromListByKeyValue, removeItemFromListById } from 'application/common/list_helpers'
import { MASS_OPERATIONS } from 'application/constants/mass_operations'
import { contains, containsId } from 'application/common/data_helpers'



export const massOperationsReducer = (
    state = defaultStore.massOperations, 
    action
) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.massOperations
    }
    case ACTIONS.MOS_REGISTER_MO: {
        return {
            ...state,
            registeredMassOperations: [
                ...state.registeredMassOperations,
                {
                    moName: action.moName,
                    moType: action.moType,
                    selectedItems: action.selectedItems,
                    isActive: false
                }
            ]
        }
    }


    case ACTIONS.MOS_UNREGISTER_MO_BY_NAME: {

        const registeredMosLeft = removeObjectFromListByKeyValue(
            state.registeredMassOperations,
            MASS_OPERATIONS.KEY.NAME,
            action.moName
        )

        return {
            ...state,
            registeredMassOperations: registeredMosLeft
        }
    }


    case ACTIONS.MOS_SET_MO_ACTIVE_BY_NAME: {
        const mos = state.registeredMassOperations
        return {
            ...state,
            registeredMassOperations: mos.length > 0 
                ?  mos.map(
                    mo => {
                        return mo.moName === action.moName
                            ? {
                                ...mo,
                                isActive: true 
                            }
                            : mo
                    }
                )
                : []
        }
    }


    case ACTIONS.MOS_SET_MO_INACTIVE_BY_NAME: {
        const mos = state.registeredMassOperations

        return {
            ...state,
            registeredMassOperations: mos.length > 0 
                ?  mos.map(
                    mo => {
                        return mo.moName === action.moName
                            ? {
                                ...mo,
                                isActive: false,
                                selectedItems: []
                            }
                            : mo
                    }
                )
                : []
        }
    }


    case ACTIONS.MOS_ADD_ITEM_BY_MO_NAME: {

        const currentMo = getFirstObjectByOwnPropKey(
            state.registeredMassOperations, 
            MASS_OPERATIONS.KEY.NAME,
            action.moName
        )

        const taskInCurrentMo = contains(
            currentMo.selectedItems,
            action.item
        )

        const newMo = taskInCurrentMo 
            ? currentMo 
            : {
                ...currentMo,
                selectedItems: [ ...currentMo.selectedItems, action.item]
            }

        return {
            ...state,
            registeredMassOperations: state.registeredMassOperations.map(
                mo => {
                    return  mo.moName === action.moName 
                        ? newMo 
                        : mo
                }
            )
        }
    }


    case ACTIONS.MOS_REMOVE_ITEM_BY_MO_NAME: {

        const currentMo = getFirstObjectByOwnPropKey(
            state.registeredMassOperations, 
            MASS_OPERATIONS.KEY.NAME,
            action.moName
        )

        const itemInCurrentMo = containsId(
            currentMo.selectedItems, 
            action.id
        )

        const newMo = itemInCurrentMo 
            ? {
                ...currentMo,
                selectedItems: removeItemFromListById(
                    currentMo.selectedItems,
                    action.id
                )
            }
            : currentMo

        return {
            ...state,
            registeredMassOperations: state.registeredMassOperations.map(
                mo => {
                    return  mo.moName === action.moName 
                        ? newMo 
                        : mo
                }
            )
        }
    }


    
    case  ACTIONS.MOS_SET_ITEMS_BY_MO_NAME: {
        const currentMo = getFirstObjectByOwnPropKey(
            state.registeredMassOperations, 
            MASS_OPERATIONS.KEY.NAME,
            action.moName
        )
        const newMo = {
            ...currentMo,
            selectedItems: action.selectedItems
        }

        return {
            ...state,
            registeredMassOperations: state.registeredMassOperations.map(
                mo => {
                    return  mo.moName === action.moName 
                        ? newMo 
                        : mo
                }
            )
        } 

    }


    case ACTIONS.MOS_CLEAR_ALL_ITEMS_BY_MO_NAME: {

        const currentMo = getFirstObjectByOwnPropKey(
            state.registeredMassOperations, 
            MASS_OPERATIONS.KEY.NAME,
            action.moName
        )

        const newMo = {
            ...currentMo,
            selectedItems: []
        }

        return {
            ...state,
            registeredMassOperations: state.registeredMassOperations.map(
                mo => {
                    return  mo.moName === action.moName 
                        ? newMo 
                        : mo
                }
            )
        }
    }



    

    default:
        return state
    }
}