import { useSelector, useDispatch } from 'react-redux'
import { getCurrentAccountId, getSharedResourceId } from 'application/redux/selectors'
import { getLawfirmsRequest } from 'application/redux/actions/lawfirms'
import { useCallback, useEffect } from 'react'
import { useReduxStoreLookUp } from './use_redux_store_lookup'

export const useLawfirms = (branchId = null) => {
    const dispatch = useDispatch()
    const {
        lookUp
    } = useReduxStoreLookUp()

    const currentAccountId = useSelector(getCurrentAccountId)
    const sharedResourceId = useSelector(getSharedResourceId)

    const {
        loaded,
        loading,
        accountId,
        lawfirmsList
    } = useSelector(state => state.lawfirms)

    const getLawfirms = useCallback(() => {
        dispatch(getLawfirmsRequest(currentAccountId, branchId))
    }, [branchId, currentAccountId, dispatch])
    
    useEffect(()=>{
        const currentLawfirmState = lookUp(state => state.lawfirms)
        if(!currentLawfirmState.loaded && !currentLawfirmState.loading && (currentAccountId !== accountId || sharedResourceId !== null)){
            getLawfirms()
        }

    }, [accountId, currentAccountId, getLawfirms, loaded, loading, lookUp, sharedResourceId])

    return {
        loaded,
        loading,
        lawfirmsList
    }
}