import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import {TreeView} from 'application/components/building_blocks/tree_view'
import { useCustomStateTree } from './use_damage_state_tree'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { withTheme } from 'styled-components'
import { PushBottom10, Spacer } from 'application/components/pages/_common'

const LifeCycleState = ({lifeCycleState, customStates}) => {
    const {t} = useTranslation()
    return <Fragments.StyledLifeCycleStateItem>
        <Fragments.StyledLifeCycleStateLabel>
            <FlexBox cssRules='padding: 10px;'>
                <Paragraph cssMargin='0' cssAlign='right'>{t(lifeCycleState.label)}</Paragraph>
            </FlexBox>
            <FlexBox cssRules='align-self: stretch; width: auto; gap: 10px;' flexWrap='wrap' flexShrink='1' flexDirection='column' justifyContent  ='space-around'>
                {customStates.map((item, index) => {
                    return <Fragments.StyledTreeMarker key={index} isSingle={customStates.length === 1} />
                })}
            </FlexBox>
        </Fragments.StyledLifeCycleStateLabel>
    </Fragments.StyledLifeCycleStateItem>
}

export const DamageStatesVisualizerItem =  withTheme(
    ({
        lifeCycleState,
        customStates = [],
        onAddCustomState,
        onDeleteCustomState,
        onEditCustomState,
        onUpdateCustomStateIndex,
        maxCustomStatesReached,
        theme
    }) => {
        const {t} = useTranslation()
        const {tree, height, treeLoaded, onChange} = useCustomStateTree(customStates, theme.treeView.nodeDimension)
        const hasCustomStates = customStates.length > 0

        return <FlexBox>
            {
                treeLoaded && <React.Fragment>
                    <LifeCycleState lifeCycleState={lifeCycleState} customStates={customStates}/>
                    <Spacer basis='10px' />
                    <FlexBox flexBasis='auto' flexGrow='1'>
                        <Fragments.StyledDamageStateItem>
                            <TreeView
                                tree={tree}
                                onChange={onChange}
                                onNodeDelete={node => {
                                    onDeleteCustomState(node.id, node.title, lifeCycleState.name)
                                }}
                                onNodeMove={(data)=>{
                                    onUpdateCustomStateIndex(lifeCycleState, data.treeData)
                                }}
                                onNodeClick={node => {
                                    onEditCustomState(node.id, node.title, node.iconName)
                                }}
                                canNodeHaveChildren={node => node.canHaveChildren}
                                canDragNode={({node}) => node.canBeChild}
                                maxDepth={1}
                                height={height}
                                hideTreeStructure={true}

                            />
                            {
                                !hasCustomStates && <PushBottom10 />
                            }
                            <IconButtonWithLabel
                                label={t('Add state')}
                                iconKey='add'
                                onButtonClick={()=>{
                                    onAddCustomState(lifeCycleState.name, customStates.length)
                                }}
                                disabled={maxCustomStatesReached}
                                title={
                                    maxCustomStatesReached 
                                        ? t('The maximum number of custom states has been reached')
                                        : t('Add state')
                                }
                            />
                        </Fragments.StyledDamageStateItem>
                    </FlexBox>
                </React.Fragment>
            }      
        </FlexBox>
    }
)