import React from 'react'
import { DocumentsByCategory } from 'application/components/building_blocks/documents_by_category'
import { SubPageLayout } from 'application/components/pages/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useParams } from 'react-router-dom'
import { useDocsByCategoriesRegisterResource } from 'application/components/hooks/documents_by_categories/use_register_resource'



const DocumentsContent = ({
    categories,
    categoriesLoaded,
    match
}) => {
    const {sharedResourceId} = useParams()
    useDocsByCategoriesRegisterResource(sharedResourceId)
    
    return <SubPageLayout paddingRight='16px'>
        <FlexBox flexDirection='column'>
            {categoriesLoaded && <React.Fragment>
                {
                    categories.map(c => {
                        return <DocumentsByCategory
                            key={c.id}
                            category={c}
                            match={match}
                        />
                    })
                }
            </React.Fragment>           
            }
        </FlexBox>
    </SubPageLayout>
}

export const DocumentsContentComponent = withRouter(
    DocumentsContent
)