import React from 'react'
import { DocumentsByCategory } from 'application/components/building_blocks/documents_by_category'
import { SubPageLayout } from 'application/components/pages/_common'
import { MassOperationPanel } from 'application/components/building_blocks/mass_operations'
import {DamageManagerContainer} from '../container'
import { FlexBox } from 'application/components/fragments/flex_box'
import { MassOperationsDocumentSelect } from './mass_operation_select'
import {DamageManagerDocumentsSidebar} from './sidebar'
import {DamageManagerDocumentsTabbarButtons} from './tab_bar_buttons'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'
import { useParams } from 'react-router-dom'

export const DamageManagerDocumentsComponent = (
    {
        categories = [],
        moName,
        moType,
        onAttachmentClick,
        onHideAttachmentDetails
    }
) => {

    const {damageId} = useParams()

    return <DamageManagerContainer
        titleSegments={['Documents']}
        headline='Documents & Pictures'
        tabBarButtons={ <DamageManagerDocumentsTabbarButtons /> }
        sidebar={ <DamageManagerDocumentsSidebar /> }
    >
        <SubPageLayout 
            paddingRight='16px' 
            paddingLeft='16px'
            paddingTop='8px'
        >
            <FlexBox 
                flexDirection='column'
                paddingBottom='24px'
            >
                <ForCurrentBranchPermissions 
                    permissions={
                        [
                            USER_PERMISSONS.DOCUMENT.UPDATE, 
                            USER_PERMISSONS.DOCUMENT.DELETE
                        ]
                    } 
                    allNeeded={false}
                >
                    <MassOperationPanel
                        moName={moName}
                        moType={moType}
                        label={'Edit multiple documents'}
                        itemDesignation={'document'}
                        itemsDesignation={'documents'}
                        moOpenAdditionalAction={onHideAttachmentDetails}
                    >
                        <MassOperationsDocumentSelect
                            moName={moName}
                            damageId={damageId}
                        />
                    </MassOperationPanel>
                </ForCurrentBranchPermissions>


                

                {
                    categories.map( c => {
                        return <DocumentsByCategory
                            key={c.id}
                            category={c}
                            moName={moName}
                            onAttachmentClick={onAttachmentClick}
                        />                            
                    })
                }
            </FlexBox>
        </SubPageLayout>
    </DamageManagerContainer>
}