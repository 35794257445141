import React from 'react'
import {CustomersTable} from 'application/components/tables/common/customers'
import { SubPageLayout, PushBottom32 } from 'application/components/pages/_common'
import { withCustomersLoader } from 'application/components/higher_order_components/with_customers_loader'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { TablePrivateCustomersSearchFilter } from 'application/components/building_blocks/filters/table_private_customers_search_filter/component'

const CustomersContentComponent = ( 
    {
        customersList,
        customersListLoaded,
        customersListLoading,
        customersListTotal,
        updateConstraint,
        onLoadCustomersList
    }
) => {
    return <SubPageLayout>
        <FilterFragment.FilterContainer>
            <TablePrivateCustomersSearchFilter />
        </FilterFragment.FilterContainer>
        <PushBottom32 />
        <CustomersTable
            customersList={customersList}
            customersListLoaded={customersListLoaded}
            customersListLoading={customersListLoading}
            customersListTotal={customersListTotal}
            onLoadCustomersList={onLoadCustomersList}
            updateConstraint={updateConstraint}
        />
        <PushBottom32 />
    </SubPageLayout>     
}

export const CustomersContent = withCustomersLoader(
    CustomersContentComponent
)