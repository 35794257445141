import * as LegendElements from 'application/components/charts/_common/legend'
import { Percentage } from 'application/components/charts/_common/percentage'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'

const Outer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const Inner = styled.div`
    position: absolute;
    width: 100%;
    height: 160px;
    top: 40px;
    display: flex;
    align-items: ${props => props.cssAlign ? props.cssAlign : 'center'};
    justify-content: center;
`

const VacantPostionsChartComponent = ({unpaidTotal, paidTotal, theme}) => {
    ChartJS.register(ArcElement, Tooltip, Legend)
    ChartJS.unregister(ChartDataLabels)

    const {t} = useTranslation()

    const data = {
        datasets: [{
            data: [unpaidTotal, paidTotal],
            backgroundColor: [
                theme.color.gray20,
                theme.color.primary
            ],
            hoverBackgroundColor: [
                theme.color.gray20,
                theme.color.primary
            ]
        }]
    }

    const options = {
        cutout: '65%',
        responsive: false,
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }

    return <Outer>
        <LegendElements.LegendContainer>
            <LegendElements.LegendIndicator 
                cssBackground={theme.color.primary} />
            <LegendElements.LegendLabel>
                {t('Paid off')}
            </LegendElements.LegendLabel>
            <LegendElements.LegendIndicator 
                cssBackground={theme.color.gray20} />
            <LegendElements.LegendLabel>
                {t('Opened')}
            </LegendElements.LegendLabel>
        </LegendElements.LegendContainer>
        <Inner cssAlign='flex-start'>
            <Doughnut data={data} options={options} />
        </Inner>
        <Inner>
            <Percentage 
                value1={paidTotal} 
                value2={unpaidTotal} 
            />
        </Inner>
    </Outer>
}

export const VacantPostionsChart = withTheme(
    VacantPostionsChartComponent
)