import React from 'react'
import { TextInput } from 'application/modules/dynamic-form/controls/text_input'
import { validationMethods } from 'application/common'
import PropTypes from 'prop-types'

export const TextInputGermanDate = (
    {
        register,
        error,
        onChange,
        name,
        label = '',
        placeholder = 'TT.MM.JJJJ',
        validate = null,
    }
) => {

    return <TextInput
        label={label}
        name={name}
        register={register}
        validate={
            {
                mustBeGermanDateFormat: validationMethods.mustBeGermanDateFormat,
                ...validate
            }
        }
        error={error}
        onChange={onChange}
        placeholder={placeholder}
    />
}

TextInputGermanDate.propTypes = {
    register: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validate: PropTypes.object,
    error: PropTypes.object
}
