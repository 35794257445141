import React from 'react'
import styled from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'

const StyledButton = styled.button`
    color: ${props => props.secondary ? props.theme.color.gray70 : props.theme.color.white};
    & i {
        color: ${props => props.secondary ? props.theme.color.gray70 : props.theme.color.white} !important;
        margin-right: 4px;
        margin-left: -4px;
    }
    background-color: ${props => props.secondary ? 'transparent' : props.theme.color.gray70};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    padding: 9px 16px;
    border: 0;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    min-width: 80px;
    width:${props => props.strechted ? '100%' : 'auto'};
    border-radius: 2px;
    cursor: pointer;
    display: flex; 
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    opacity: ${props => props.disabled ? props.theme.disabledOpacity : '1'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};

    @media (max-width: ${props => props.theme.breakpoint.large}px) {
        padding: 0;
    }

    @media (max-width: ${props => props.theme.breakpoint.medium}px) {
        & > span {
            font-size:${props => props.theme.fontSize.small};
        }
    }
`

export const ActionButtonComponent = (
    { 
        buttonText, 
        className = '', 
        strechted = false, 
        disabled = false, 
        onButtonClick, 
        secondary = false, 
        icon = '' 
    }
) => {
    return <StyledButton 
        secondary={secondary} 
        className={className} 
        strechted={strechted} 
        onClick={onButtonClick} 
        disabled={disabled}
    >
        {
            icon !== '' && <FontIcon 
                icon={icon} 
                isClickable={true} 
            />
        } 
        <span>{buttonText}</span> 
    </StyledButton>
}
