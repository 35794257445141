import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { ActionButton } from 'application/components/controls/action_button'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { PushBottom32, Spacer } from 'application/components/pages/_common'
import { INFOBOX, COMMISSION_STATE } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { BreakLine16 } from 'application/components/building_blocks/document_sidebar/fragments'
import { compose } from 'redux'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { useStatefulForm } from 'application/components/hooks'
import {  notNull } from 'application/common/data_helpers'
import { LawfirmSelect } from 'application/components/controls/form/lawfirm_select'
import { useLawfirms } from 'application/components/hooks/use_lawfirms'

const CommissionLawfirmStep = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        dialog, 
        setLawfirm,
        goToNextStep,
        lawfirm = null
    }
) => {
    const {t} = useTranslation()

    const {
        loaded,
        lawfirmsList
    } = useLawfirms(dialog.payload.branchId)

    const initialLawfirmOption = notNull(lawfirm) 
        ? {
            name: lawfirm?.name || '',
            id: lawfirm.webakteId
        }
        : null

    const defaultValues = { lawfirm: lawfirm?.webakteId || ''}

    const formhook = useStatefulForm({defaultValues})

    const { 
        handleSubmit, 
        getValues
    } = formhook
   
    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const {
        changeMode 
    } = dialog.payload

    const lawfirmState = dialog.payload.lawfirm?.state || null
    const changeForbidden = dialog.isLoaded && changeMode && lawfirmState === COMMISSION_STATE.PENDING
    const hasLawyersList = loaded && lawfirmsList.length > 0
    const showWarning = changeForbidden || !hasLawyersList

    const onSubmitSuccess = () => {
        setLawfirm(lawfirmsList.find(l => l.webakteId === getValues().lawfirm))
        goToNextStep()
    }

    if(!loaded){
        return null
    }

    if(showWarning){
        return <React.Fragment>
            <DialogMainContent>

                <InfoBox 
                    type={INFOBOX.TYPES.WARNING} 
                    direction='row' 
                    cssMargin='0' 
                >
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <span>
                        {
                            changeForbidden
                                ? t('The connection to the previous lawfirm has not been established yet. Please wait a couple of minutes and try again.')
                                : t('No connected lawfirms found.')
                        }
                    </span>
                </InfoBox>

            </DialogMainContent>
            <StepsLayoutFooter>
                <IconButtonWithLabel
                    iconKey='not_interested' 
                    label={t('Cancel')} 
                    onButtonClick={abort} 
                />
                <FlexBox width='8px'/>
                <ActionButton 
                    buttonText={t('Next')} 
                    onButtonClick={handleSubmit(onSubmitSuccess)} 
                    disabled={showWarning}
                />
            </StepsLayoutFooter>
        </React.Fragment>
    }
    




    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='row'>
                
                <FlexBox flexDirection='column'>
                    <SubHeadline>{t('Choose lawfirm')}</SubHeadline>
                    <BreakLine16 />
                    <FlexBox 
                        flexDirection='column'
                        justifyContent='center'
                    >
                        <LawfirmSelect 
                            formhook={formhook}
                            initialLawfirmOption={initialLawfirmOption}
                            onDataChange={onDataChange}
                            branchId={dialog.payload.branchId}
                        />
                    </FlexBox>           
                </FlexBox>

            </FlexBox>
            
            
            <PushBottom32/>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <ActionButton 
                buttonText={t('Next')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
                disabled={showWarning}
            />
        </StepsLayoutFooter>


    </React.Fragment>
}

export const CommissionLawfirmStepComponent = compose(
    withDialogDataLoader
) (CommissionLawfirmStep)