import { damageManagerUpdateStatus } from 'application/redux/actions/pages/damage_manager/overview'
import { connect } from 'react-redux'
import {DamageStateSelectComponent} from './component'


const mapStateToProps = (state) => {
    return {
        statusListLoaded: state.common.statusList.loaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateState: (
            stateId,
            damageId
        ) => dispatch(
            damageManagerUpdateStatus(
                damageId, 
                stateId
            )
        ),
    
    }
}

export const DamageStateSelect =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageStateSelectComponent)