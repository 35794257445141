import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const dialogStepsWaitingScreenReducer = (
    state = defaultStore.dialogStepsWaitingScreen, 
    action
) => {
    switch (action.type) {
    case ACTIONS.DIALOG_STEPS_LAYOUT_WAITING_SCREEN_SHOW: {
        return {
            ...state,
            show: true,
            untranslatedMessage: action.untranslatedMessage
        }
    }
    case ACTIONS.DIALOG_STEPS_LAYOUT_WAITING_SCREEN_HIDE: {
        return {
            ...state,
            show: false,
            untranslatedMessage: ''
        }
    }    
    default:
        return state
    }
}
