import { connect } from 'react-redux'
import { AddCustomStateComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddCustomState: (dialogName, payload, metaData) => dispatch(sendPayloadRequest(dialogName, payload, metaData))
    }
}

export const AddCustomState = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCustomStateComponent)