import { ZyrullQrStickerPDF } from './component'
import { pdf } from '@react-pdf/renderer'

export const zyrullQrStickerBuilder = (            
    pdfValues
) => {
    return pdf(
        <ZyrullQrStickerPDF 
            qrCodeSrc = {pdfValues.qrCodeSrc}
        />
    )
}