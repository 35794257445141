import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledMessageSubject = styled.h3`
    color: ${(props) => props.theme.color.gray70};
    font-size: inherit;
    font-weight: ${(props) => props.theme.fontWeight.headline3};
    padding: 0;
    margin: 0;
    text-transform: capitalize;
`

const StyledNoSubject = styled.h3`
    color: ${(props) => props.theme.color.gray70};
    font-size: inherit;
    font-weight: ${(props) => props.theme.fontWeight.standardRegular};
    margin: 0;
    padding: 0;
    font-style: italic;
`

export const MessageSubject = ({subject = ''}) => {
    const {t} = useTranslation()
    if(subject === '' || subject === null){
        return <StyledNoSubject>[{t('without subject')}]</StyledNoSubject>
    }
    return <StyledMessageSubject>{subject}</StyledMessageSubject>
}