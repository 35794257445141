import React from 'react'
import {
    StyledUserMenu
} from './fragments'
import {ContextMenu} from 'application/components/controls/context_menu'
import {ContextMenuItem} from 'application/components/controls/context_menu_item'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ROUTES } from 'application/constants'
import { dataMappers } from 'application/api'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const UserMenuComponent = (
    {
        onEditUser,
        currentUser,
        accountsTotal,
        accountsFound,
        onLogout,
        onEditNotificationSettings
    }
) => {
    const { t } = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const handleOnEditUser = (currentUser) => {
        const mappedPayload = dataMappers.importMappers.user.userFormDataMapper(currentUser)
        onEditUser(mappedPayload)
    }

    const handleOnEditNotificationSettings = (currentUser) => {
        onEditNotificationSettings(currentUser)
    }

    return  <StyledUserMenu>
        <ContextMenu 
            iconKey="account_circle"
            height= {'48px'}
            width= {'48px'}
            iconSize = {'32px'}
            userName = {''}
            top={'21px'}
        >
            <ContextMenuItem
                iconKey='edit'
                onListElementClick={ () => handleOnEditUser(currentUser) }
            >
                {t('Edit')}
            </ContextMenuItem>
            <ContextMenuItem
                iconKey='notifications'
                onListElementClick={ () => handleOnEditNotificationSettings(currentUser) }
            >
                {t('Notification settings')}
            </ContextMenuItem>

            {
                accountsFound && accountsTotal > 1 && <ContextMenuItem
                    iconKey='cached'
                    to={ROUTES.CHOOSE_ACCOUNT}
                    isLinked={true}
                >
                    {t('Switch account')} 
                </ContextMenuItem>  
            }
                   
            <ContextMenuItem
                iconKey='notes'
                to={ internalAccountLink(ROUTES.CHANGELOG) }
                isLinked={true}
            >
                {t('Whats`s new?')} 
            </ContextMenuItem>
            <ContextMenuItem
                iconKey='exit_to_app'
                onListElementClick={() => onLogout()}
            >
                {t('Logout')}
            </ContextMenuItem>
        </ContextMenu>
    </StyledUserMenu>                
}

UserMenuComponent.propTypes = {
    currentUser: PropTypes.object.isRequired, 
    onEditUser: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
}