import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { ControllingVacantPositionsContent } from 'application/components/widgets/_common/vacant_postions_content'

const ControllingVacantPositionsKasko = ({payload, loadingFailed, theme}) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }
    const {unpaidTotal, paidTotal} = payload.response
    
    return <ControllingVacantPositionsContent unpaidTotal={unpaidTotal} paidTotal={paidTotal} />
}

ControllingVacantPositionsKasko.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { ControllingVacantPositionsKasko }