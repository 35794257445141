import React, {useEffect} from 'react'
import {DamageNotFoundPage} from './component'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'
import { useParams } from 'react-router-dom'



export const DamageNotFoundEffects = (props) => {

    const { 
        onResetDamage,
        accounts
    } = props

    const {
        damageId = ''
    } = useParams()

    const {
        internalAccountLink
    } = useApplicationLinks()

    useEffect(
        () => {
            onResetDamage()
        }, [onResetDamage,damageId]
    )

    if(!accounts.loaded){
        return null
    }

    const linkURL = internalAccountLink(
        ROUTES.DASHBOARD
    )

    return <DamageNotFoundPage linkURL={linkURL}>
        {props.children}
    </DamageNotFoundPage>
}