import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { connect } from 'react-redux'
import {InboxEffects} from './effects'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { resetMessageDetails } from 'application/redux/actions/pages/inbox'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetType: (type) => dispatch(refreshWidgetsByType(type)),
        onResetMessageDetails: () => dispatch(resetMessageDetails())
    }
}

export const Inbox = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(InboxEffects))