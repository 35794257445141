
import React from 'react'
import { CustomerDisplay } from 'application/components/building_blocks/customer_display/component'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { withTheme } from 'styled-components'
import { CardMeta, CardWrapper } from 'application/components/fragments/cards'

export const CustomerCardComponent = withTheme(
    (
        { 
            customer = null,
            showQrCodeLink = true,
            onQRCodeCreation = () => {},
            theme,
            stretch = false
        }
    ) => {
        const handleOnQrCodeClick = () => {
            onQRCodeCreation({
                customerId: customer.customerId
            })
        }

        if(customer === null){
            return null
        }
        return <CardWrapper stretch={stretch}>

            <CustomerDisplay 
                customer={customer}
            />

            <CardMeta>
                {
                    showQrCodeLink && <IconButtonWithLabel 
                        onButtonClick={handleOnQrCodeClick}
                        iconKey='save_alt'
                        label='QR Code'
                        fontSize={theme.fontSize.small}
                    />
                }
            </CardMeta>

        </CardWrapper>
    }
)
