import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {DamageStatus} from 'application/components/controls/damage_status'
import styled from 'styled-components'
import { DamageStateRowSelect } from './damage_state_row_select'
import { FlexBox } from 'application/components/fragments/flex_box'


const StyledStateSelectComponent = styled.div`
    & i {
        font-size 14px;
        font-style normal;
        font-weight 400;
        color: ${props => props.theme.color.anthracite};
        padding: 0 8px;
    }
`



export const EditableDamageStatus = (
    { 
        element,
        isSelected = false,
        showDetails = false
    }
) => {  
    const [editable, setEditable] = useState(false)
    const [damageState, setDamageState] = useState(element.state)

    const handleClick = (e, handle) => {
        e.preventDefault()
        e.stopPropagation()
        handle()
    }

    const enableEditable = (event) => {
        !editable && handleClick(
            event, 
            () => { setEditable(true) }
        )
    }

    return <StyledStateSelectComponent
        onClick={ !isSelected ? enableEditable : () => {} }
    >
        {
            editable && !isSelected
                ? 
                <DamageStateRowSelect 
                    damageState = {damageState}
                    setDamageState = {setDamageState} 
                    damageId = {element.id}
                    editable = {editable}
                    setEditable = {setEditable}
                    showDetails = {showDetails}
                />
                : 
                <FlexBox 
                    justifyContent='center' 
                    alignItems='center'
                >
                    <DamageStatus 
                        status={damageState}
                        isSelected={isSelected}
                    />
                    <FlexBox>
                        <i className='material-icons'>
                        edit
                        </i>
                    </FlexBox>
                </FlexBox>

        }
    </StyledStateSelectComponent>
}

EditableDamageStatus.propTypes = {
    element: PropTypes.object.isRequired,
    isSelected: PropTypes.bool
}