import { connect } from 'react-redux'
import {TableDamagesLastCreatedComponent} from './component'

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export const TableDamagesLastCreated = connect(
    mapStateToProps,
    mapDispatchToProps
)(TableDamagesLastCreatedComponent)