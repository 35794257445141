import { ROUTES } from 'application/constants/routes'
import * as storage from 'application/storage'

export function* changeCurrentBranchFromDialogSaga(action) {
    const { branch } = action.payload

    const {
        match,
        applicationLinksHook
    } = action.metaData

    const branchId = branch.id

    const {
        accountId
    } = match.params

    yield storage.currentBranch.store(accountId, branchId)
    
    const newRoute = applicationLinksHook.branchLink(
        ROUTES.BRANCH_SPECIFIC_DASHBOARD,
        branchId
    )
    yield match.navigate(newRoute)
}