import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'

const TypeChangedInfo = (
    {activityData}
) => {

    const {t} = useTranslation()

    const past = t(activityData.oldType)
    const current = t(activityData.newType)

    return <FlexBox>
        {past}  <RightArrow /> {current}
    </FlexBox>
}



TypeChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}



export {TypeChangedInfo} 