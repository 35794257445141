import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetTaskDamageCustomerDataSaga(action){
    const { damageId } = action.match.params
    const vehicleKeeper = yield resourceCall(
        api.gateway.damage.getVehicleKeeperByDamageId, 
        {
            damageId: damageId
        }
    )

    const customer = yield resourceCall(
        api.gateway.customer.getCustomerById, 
        {
            customerId: vehicleKeeper.customerId
        }
    )

    yield put(
        updateWidget(
            action.key, 
            action.dependentWidgetKeys, 
            {
                customer: {
                    ...customer,
                    customerId: vehicleKeeper.customerId
                }, damageId
            }
        )
    )
}   