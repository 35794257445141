import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMoByName } from 'application/components/building_blocks/mass_operations/helper_functions'
import { moAddSelectedItemByName, moRemoveSelectedItemByName, moClearAllItemsByName, moSetInactiveByName, moSetActiveByName } from 'application/redux/actions/mass_operations'
import { getRegisteredMassOperations } from 'application/redux/selectors'
import { isNull } from 'application/common/data_helpers'


export const useMO = (
    moName = null
) => {
    const dispatch = useDispatch(),
        moAddSelectedItem = useCallback(
            (item) => {
                dispatch(
                    moAddSelectedItemByName(
                        moName,
                        item
                    )
                )
            }, [dispatch, moName]
        ),

        moRemoveSelectedItem = useCallback(
            (id) => {
                dispatch(
                    moRemoveSelectedItemByName(
                        moName,
                        id
                    )
                )
            }, [dispatch, moName]
        ),

        moClearAllItems = useCallback(
            () => {
                dispatch(
                    moClearAllItemsByName(moName)
                )
            },
            [dispatch, moName]
        ),

        moSetActive = useCallback(
            () => {
                dispatch(
                    moSetActiveByName(moName)
                )
            },
            [dispatch, moName]
        ),

        moSetInactive = useCallback(
            () => {
                dispatch(
                    moSetInactiveByName(moName)
                )
            }, [dispatch, moName]
        ),

        registeredMassOperations = useSelector(getRegisteredMassOperations),
        mo = fetchMoByName(
            registeredMassOperations,
            moName
        ),
        moIsActive = !isNull(mo) && ( mo?.isActive || false ),
        moSelectedItems = mo?.selectedItems || []
    return {
        moAddSelectedItem,
        moRemoveSelectedItem,
        moClearAllItems,
        moSetInactive,
        moSetActive,
        moIsActive,
        moSelectedItems,
        mo
    }
}

