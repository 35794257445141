import { connect } from 'react-redux'
import { KickstartStep1Component } from './component'
import { closeDialogByName, openDialogByName, sendPayloadRequest } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'




const mapStateToProps = state => ({
    currentBranch: state.auth.currentBranch
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: (name, payload) => dispatch(
            sendPayloadRequest(name, payload)
        ),
        onOpenChangeBranchDialog : (name) => {
            dispatch(closeDialogByName(name))
            dispatch(
                openDialogByName(
                    DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH,
                    true, 
                    {}, 
                    'Change branch'
                )
            )
        }
    }
}

export const KickstartStep1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(KickstartStep1Component)