import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { getWidgetByType } from 'application/components/widgets'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'

const OuterWidget = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${props => props.successively ? '0 0' : '1 1'} ${props => props.dimension};
    width: ${props => props.cssWidth};
    height: ${props => props.cssHeight};
    padding: ${props => props.isBlank ? '0' : '8px'};
    box-sizing: border-box;
    & *{
        box-sizing: border-box;
    }
    border: ${props => props.isBlank ? '0' : '1px'} solid ${props => props.hideBorder ? 'transparent' : props.theme.color.gray10};
    border-radius: 2px;
    background: ${props => props.isSecondary ? props.theme.color.gray5 : 'transparent'};
    overflow: ${props => props.overflow};
`

const InnerWidget = styled.div`
    width: ${props => props.cssWidth};
    height: ${props => props.cssHeight};
`

const WidgetHeadline = styled.h3`
    margin: 8px 0 0;
    flex: 0 0 20px;
    display: flex;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallRegular};
    color: ${props => props.theme.color.anthracite};
    padding: 0 ${props => props.isBlank ? '0' : '8px'};
    height: 14px;
`

export const WidgetContent = styled.div`
    flex: 1 1 auto;
    padding: 0 ${props => props.isBlank ? '0' : '8px'};
    display: flex;
    flex-direction: column;
    min-height: ${props => props.$isLoading ? '52px' : '0'};
    height: calc(100% - 22px);
`

export const LoadingError = styled.div`
    flex: 1 1 auto;
    display: flex;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.primary};
    margin-bottom: 4px;
`

// const WidgetInfo = styled.div`
//     font-size: 10px;
// `

export const Widget = (
    {
        widgetKey, 
        payload, 
        title, 
        type, 
        isLoaded, 
        isLoading, 
        loadingFailed, 
        isRegistered, 
        isPermitted, 
        hideHeadline = false,
        isBlank = false,
        isSecondary = false,
        dimension='auto',
        cssWidth='100%',
        cssHeight = '100%',
        isInternal,
        successively = false,
        hideBorder = false,
        widgetType,
        overflow = 'auto'
    }
) => {
    const {t} = useTranslation()
    const WidgetBody = getWidgetByType(type, isInternal)
    return <OuterWidget 
        successively={successively}
        isBlank={isBlank} 
        dimension={dimension}
        cssWidth={cssWidth}
        cssHeight={cssHeight}
        isSecondary={isSecondary}
        hideBorder={hideBorder}
        overflow={overflow}
    >
        <InnerWidget
            cssWidth={cssWidth}
            cssHeight={cssHeight}
        >
            {/* <WidgetInfo>{widgetType}</WidgetInfo> */}
            {
                !hideHeadline && <WidgetHeadline isBlank={isBlank}>
                    {i18n.exists(title) ? t(title) : title}
                </WidgetHeadline>
            }
            <WidgetContent isBlank={isBlank} $isLoading={isLoading}>
                {
                    isLoaded && loadingFailed && !hideHeadline && <LoadingError>
                        {t('Error')}:
                    </LoadingError>
                }
                {
                    isRegistered && isLoaded && isPermitted && <WidgetBody 
                        widgetKey={widgetKey}
                        payload={payload}
                        loadingFailed={loadingFailed}
                        isLoading={isLoading}
                        isLoaded={isLoaded}
                        widgetType={widgetType}
                    />
                }
                {
                    !isPermitted && <React.Fragment>
                        <PushBottom16 />
                        <InfoBox type={INFOBOX.TYPES.SECONDARY} direction='row' cssMargin='0'>
                            <FontIcon icon='error_outline' />
                            <Spacer basis='10px' />
                            <span>{t('Permission missing for this content')}</span>
                            <Spacer />
                        </InfoBox>
                    </React.Fragment>
                }
                {
                    isLoading && <ThemedPreloader />
                }

            </WidgetContent>
        </InnerWidget>
    </OuterWidget>
}

Widget.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadingFailed: PropTypes.bool.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    isPermitted: PropTypes.bool.isRequired,
    hideHeadline: PropTypes.bool,
    isBlank: PropTypes.bool,
    cssWidth: PropTypes.string,
    cssHeight: PropTypes.string
}