import { put, all } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetPowerOfAttorneyDataSaga(action) {

    const { 
        dialogName, 
        metaData
    } = action
    
    const { 
        damageId
    } = metaData

    
    const [
        damage,
        lawfirm,
        liabilityData,
        customer
    ] = yield all([
        resourceCall(
            api.gateway.damage.getDamage, 
            {
                damageId
            }
        ),
        resourceCall(
            api.gateway.lawyerCommunication.getLawfirm, 
            {
                damageId
            }
        ),
        resourceCall(
            api.gateway.damage.getOpponentKeeper, 
            {
                damageId
            }
        ),
        resourceCall(
            api.gateway.damage.getVehicleKeeperByDamageId, 
            {
                damageId
            }
        )
    ])

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                damage,
                lawfirm,
                liabilityData,
                customer 
            }
        )
    )
}