
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'

export const ComprehensiveDamageTypeDeletedDetails = (
    {
        activity
    }
) => {
    const {
        name
    } = activity.data

    return <ActivityDetailWrapper>
        <ActivityDetail label='Name' value={name} />
    </ActivityDetailWrapper>
}

ComprehensiveDamageTypeDeletedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}