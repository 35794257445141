
import React from 'react'
import { useTranslation } from 'react-i18next'
import {ContextMenu} from 'application/components/controls/context_menu'
import {ContextMenuItem} from 'application/components/controls/context_menu_item'
import { ContextMenuWrapper } from 'application/components/tables/common/_common/fragments'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'

export const CustomersContextMenuComponent = ({
    customer,
    onEditCustomer,
    onDeleteCustomer,
    onQRCodeCreation
}) => {
    const { t } = useTranslation()
    
    const handleClick = (e, handle) => {
        e.preventDefault()
        e.stopPropagation()
        handle()
    }

    return <ForAnyBranchPermissions
        permissions={[
            USER_PERMISSONS.CUSTOMER.UPDATE,
            USER_PERMISSONS.CUSTOMER.DELETE
        ]}
        allNeeded={false}
    >
        <ContextMenuWrapper>
            <ContextMenu iconKey='edit'>
                <ForAnyBranchPermissions
                    permissions={[USER_PERMISSONS.CUSTOMER.UPDATE]}
                >
                    <ContextMenuItem
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onEditCustomer(customer)
                            })
                        }}
                        iconKey='edit'
                        title={t('Edit customer')}
                    >
                        {t('Edit customer')}
                    </ContextMenuItem>
                </ForAnyBranchPermissions>
                <ForAnyBranchPermissions
                    permissions={[USER_PERMISSONS.CUSTOMER.DELETE]}
                >
                    <ContextMenuItem
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onDeleteCustomer(customer)
                            })
                        }}
                        iconKey='delete_outline'
                        title={t('Delete customer')}
                    >
                        {t('Delete customer')}
                    </ContextMenuItem>
                </ForAnyBranchPermissions>
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.CUSTOMER.READ,
                        USER_PERMISSONS.CUSTOMER.UPDATE
                    ]}
                    allNeeded={true}
                >
                    <ContextMenuItem
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onQRCodeCreation({
                                    customerId: customer.id
                                })
                            })
                        }}
                        iconKey='delete_outline'
                        title={t('Create QR code')}
                    >
                        {t('Create QR code')}
                    </ContextMenuItem>
                </ForAnyBranchPermissions>
            </ContextMenu>
        </ContextMenuWrapper>
    </ForAnyBranchPermissions>
}