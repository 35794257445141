import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { nameHelpers } from 'application/common'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { withCurrentDamage } from 'application/components/higher_order_components'
import {DamageStatusSelectFormControl} from 'application/components/controls/damage_status_select_control'
import { Select } from 'application/components/controls/form'
import { getFullName } from 'application/common/name_helpers'
import { withStatuslistLoader } from 'application/components/higher_order_components'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { useStatefulForm } from 'application/components/hooks'



const ReopenTask = withCurrentDamage(
    (
        { 
            abort,
            dialog,
            onReopenTask, 
            currentDamage,
            onSearchUser
        }
    ) => {
        const {t} = useTranslation()
        const {usersList, task} = dialog.payload

        const [damageState, setDamageState] = useState(currentDamage.state)
        const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
        const [assigneeFirstName, setAssigneeFirstName] = useState(task.assignee?.firstName || null)
        const [assigneeLastName, setAssigneeLastName] = useState(task.assignee?.lastName || null)
    
        const defaultValues = {
            assigneeId: task.assignee.userId
        }

        const {
            register, 
            errors, 
            getValues, 
            setValue
        } = useStatefulForm(
            {defaultValues}
        )

        const usersOptions = usersList.map((u, i) => ({
            value: u.id,
            label: nameHelpers.getFullName(u),
            firstName: u.firstName,
            lastName: u.lastName
        }))

        const handleSearchUser = search => {
            if(userSearchActive && search.length > 2){
                onSearchUser(dialog.name, search)
            }  
        }

        const handleSubmit = () => {
            onReopenTask( 
                dialog.name,
                task.id,
                currentDamage.id,
                damageState.id,
                damageState.id !== currentDamage.state.id,
                getValues().assigneeId,
                assigneeFirstName,
                assigneeLastName,
                getValues().assigneeId !== defaultValues.assigneeId
            )
        }

        return <React.Fragment>
            <DialogMainContent cssHeight='400px'>
                <FlexBox flexDirection='column'>
                    <Paragraph cssPadding='16px 0 0'>
                        {t('Do you want to change the damage state?')}
                    </Paragraph>
                    <DamageStatusSelectFormControl 
                        onChange={
                            (option)=> {
                                setDamageState({
                                    id: option.value,
                                    name: option.label
                                })
                            }
                        }
                    />
                    <Paragraph cssPadding='16px 0 0'>
                        {t('Do you want to change or remove the assignee of this task?')}
                    </Paragraph>
                    <Select 
                        name='assigneeId'
                        register={register}
                        setValue={setValue}
                        options={usersOptions}
                        error={errors.assignedTo}
                        validate={null}
                        menuPlacement='bottom'
                        onInputChange={handleSearchUser}
                        onBlur={()=>{setUserSearchActive(false)}}
                        onFocus={()=>{setUserSearchActive(true)}}
                        noOptionsMessage={t('No users found')}
                        placeholder={t('Enter at least 3 characters')}
                        isLoading={dialog.payload.usersListLoading}
                        onChange={(val) => {
                            setAssigneeFirstName(val !== null ? val.firstName : null)
                            setAssigneeLastName(val !== null ? val.lastName : null)
                        }}
                        defaultValue={{
                            label: getFullName(task.assignee),
                            value: task.assignee.userId
                        }}
                    />
                </FlexBox>
            </DialogMainContent>
            <Footer
                onAbort={abort}
                onSubmit={handleSubmit}
                submitText='Reopen task'  
            />
        </React.Fragment>

    })

export const ReopenTaskComponent =  withDialogDataLoader(
    withStatuslistLoader(ReopenTask)
) 