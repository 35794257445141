
import React, {useState}   from 'react'
import { ActionButton } from 'application/components/controls/action_button'
import { useTranslation } from 'react-i18next'
import { PushBottom24 } from 'application/components/pages/_common'
import { Headline2 } from 'application/components/fragments/typography'
import { registrationUserManager } from 'application/common/user_manager'

export const LoginForm = (
    {
        sharedResourceId
    }
) => {

    const {t} = useTranslation()
    const [userManager] = useState(registrationUserManager())


    const logIn = () => {
        userManager.signinRedirect( 
            {
                state: {sharedResourceId}
            } 
        )
    }


    return <React.Fragment>
        <Headline2 cssAlign='center'>
            {t('Login')}
        </Headline2>
        <PushBottom24 />  
        <PushBottom24 /> 
        <ActionButton
            strechted={true}
            buttonText={ t('Login with new user') }
            onButtonClick={logIn}
        /> 
    </React.Fragment>
}