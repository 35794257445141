import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage, WidgetSubHeadline } from 'application/components/widgets/_common'
import { ActionButton } from 'application/components/controls/action_button'



export const DamageShareWidgetComponent = (
    {
        payload,
        loadingFailed,
        onOpenSmartConnectDialog
    }
) => {
    const { t } = useTranslation()


    if (loadingFailed) {
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    const dialogPayload = {
        damageId: payload.damageId
    }

    return <div>
        <WidgetSubHeadline>
            {t('SmartConnect')}
        </WidgetSubHeadline>
        <ActionButton
            buttonText={t('Create SmartConnect email')}
            onButtonClick={
                () => {
                    onOpenSmartConnectDialog(dialogPayload)
                }
            }
        />
    </div>
}

DamageShareWidgetComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}