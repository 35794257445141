import { call, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { createBlobFromObjectUrl } from 'application/api/helpers'

export function* dialogCreateCostCoverageSaga(action) {
    yield put(showWaitingScreen('Saving cost coverage'))

    const blob = yield call( createBlobFromObjectUrl, action.payload.pdfUrl ),
        fileName = action.payload.pdfFileName,
        file = new File(
            [blob], 
            fileName, 
            { type: 'application/pdf' }
        )
    
    yield resourceCall(
        api.gateway.attachments.postAttachment, 
        {
            damageId: action.metaData.damageId,
            categoryId: action.metaData.categoryId
        },
        { file }
    )
    yield put(hideWaitingScreen())
}