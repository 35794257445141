import { put, takeLeading, select } from 'redux-saga/effects'
import { defaultUserManager } from 'application/common/user_manager'
import { ACTIONS } from 'application/constants'
import { USER_FOUND } from 'redux-oidc'
import { requestCurrentAccountById } from 'application/redux/actions/auth'
import { getCurrentAccount } from 'application/redux/selectors'

function* ssoRedirectSignOutSaga() {
    try {
        yield defaultUserManager.removeUser()
    } catch (e) {

    }
}

function* loginSaga(action) {
    //if already logged in, get new application token after silent refresh
    const currentAccount = yield select(getCurrentAccount)
    if(currentAccount !== null){
        yield put(requestCurrentAccountById(currentAccount.id))
    }
}

export function* ssoWatcher() {
    yield takeLeading(ACTIONS.AUTH_LOGOUT_REQUEST, ssoRedirectSignOutSaga)
    yield takeLeading(ACTIONS.REGISTRATION_CONNECT_USER_CALLBACK, loginSaga)
    yield takeLeading(ACTIONS.REGISTRATION_CREATE_ACCOUNT_CALLBACK, loginSaga)
    yield takeLeading(USER_FOUND, loginSaga)
}