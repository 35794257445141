import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { SubHeadline } from 'application/components/fragments/dialog'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { SingleFile } from 'application/components/controls/single_file'
import { ToggleSwitch } from 'application/components/controls/toggle-switch'
import { withTheme } from 'styled-components'

export const FilesListWithLawyerFlag = withTheme(({
    files, //files state hook of the parent component (files needs tempId and isExportedToLawFirm flag from 'addTempIdAndLawyerFlagToFileArray' method)
    setFiles,
    theme
}) => {
    const {t} = useTranslation()
    const [checkboxesDisabled, setCheckboxesDisabled] = useState(false)
    
    const handleIsExportedToLawfirm = (fileId, isSet) => {
        setFiles(
            files.map(file => {
                return file.tempId === fileId
                    ? {
                        ...file,
                        isExportedToLawFirm: isSet
                    }
                    : file
            })
            
        )
    }

    const handleRemoveFile = (fileId) => {
        setFiles(
            files.filter(
                file => file.tempId !== fileId
            )
        )
    }

    return <React.Fragment>
        <SubHeadline isFirst={true}>{t('Documents')}</SubHeadline>
        <FlexBox 
            paddingTop='6px'
            paddingRight='8px'
            paddingLeft='8px'
            paddingBottom='6px'
            cssRules={`background: ${theme.color.primaryBackground};margin-bottom: 6px;`}
        >
            <ToggleSwitch
                label={t('Make all documents available for legal software')}
                initiallyActive={false}
                onChange={(isActive) => {
                    setFiles(
                        files.map(file => ({
                            ...file,
                            isExportedToLawFirm: isActive
                        }))
                    )
                    setCheckboxesDisabled(isActive)
                }}
            />
        </FlexBox>
        {
            files.map((f, i) => {
                return <SingleFile 
                    key={i}
                    htmlId={f.tempId}
                    fileName={f.metaData.name}
                    onCheckboxChange={handleIsExportedToLawfirm}
                    checkboxDisabled={checkboxesDisabled}
                    isExportedToLawFirm={f.isExportedToLawFirm}
                    removeFile={handleRemoveFile}
                />
            })
        }
    </React.Fragment>
})