import React, {useState, useEffect} from 'react'
import { Sidebar, Fragments } from '../_common'
import { useTranslation } from 'react-i18next'
import { Paragraph, TextNavLink } from 'application/components/fragments/typography'
import { FontIcon } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'
import { PushBottom32 } from 'application/components/pages/_common'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { GroupConnectionsInfo } from 'application/components/controls/group_connections_info'
import { exists } from 'application/common/data_helpers'
import { ActionButton } from 'application/components/controls/action_button'




const GroupSidebarContent =  withTheme(
    (
        {
            onSidebarClose, 
            groupData, 
            theme, 
            onEditGroup,
            onDeleteGroup,
            onUpdateGroup
        }
    ) => {
        const {t} = useTranslation()
        const [data, setData] = useState(groupData)
        const [connectBranches, setConnectBranches] = useState(groupData.canAccessAllBranches)

        const {
            internalAccountLink
        } = useApplicationLinks()

        useEffect(()=>{
            setData(groupData)
            setConnectBranches(groupData.canAccessAllBranches)
        }, [groupData])

        return <Sidebar 
            onSidebarClose={onSidebarClose} 
            title={t('User group')} 
            sidebarTitleColor={theme.color.groupPrimary}
        >
            <Fragments.HeadlineSidebar>
                {data.name}
            </Fragments.HeadlineSidebar>
            {
                data.isPredefined && <InfoBox
                    type={INFOBOX.TYPES.WARNING} 
                    cssMinHeight='unset'
                    direction='row'
                >
                    <FontIcon icon='info_outline' />  
                    {t('This group is defined by the system and can therefore not be altered')}
                </InfoBox>
            }
            <Fragments.ContentSidebar>
                <PushBottom32>
                    <PureCheckBox 
                        label={t('All branches are connected automatically')} 
                        checked={connectBranches} 
                        onChange={ 
                            ()=>{
                                setConnectBranches(!connectBranches)
                                onUpdateGroup(data.id, {
                                    name: data.name,
                                    canAccessAllBranches: !connectBranches
                                })
                            } 
                        }  
                        disabled={data.isPredefined}
                    />
                </PushBottom32>
                <PushBottom32>
                    <Paragraph $whiteSpace='pre-line'>
                        {
                            data.isPredefined 
                                ? t(data.description)
                                : exists(data.description) 
                                    ? data.description 
                                    : t('no description yet')
                        }
                    </Paragraph>
                </PushBottom32>
             
                <Paragraph>
                    <TextNavLink 
                        to={
                            internalAccountLink(
                                `${ROUTES.BRANCH_MANAGEMENT}/groups/${data.id}/members?${ROUTE_PARAMETERS.GROUP_MEMBERS.GROUP_NAME}=${data.name}`
                            )
                        }>
                                        &raquo; 
                        { t('Edit users in this group') }
                    </TextNavLink>
                </Paragraph>    
                <PushBottom32 />
                <GroupConnectionsInfo group={data} />
                           
            </Fragments.ContentSidebar>
            <Fragments.FooterSidebar>
                <ActionButton 
                    buttonText={ t('Edit group') } 
                    onButtonClick={
                        ()=>{ onEditGroup(data) } 
                    }
                    disabled={data.isPredefined}
                />
                <IconButtonWithLabel 
                    label={t('Delete group')} 
                    onButtonClick={
                        ()=>{ onDeleteGroup(groupData.id) } 
                    }
                    iconKey='delete'
                    disabled={data.isPredefined}
                />
            </Fragments.FooterSidebar>
        
        </Sidebar>
    }
)

export const GroupSidebarC = props => {
    if(!props.isVisible) {
        return null
    }
    return <GroupSidebarContent {...props} />
}