import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageCommissionedLawfirmSaga(action) {
    const damageId = action.match.params.damageId
    let data = {
        lawfirmFound: false,
        lawfirm: null
    }
    try {
        
        const webakteAccountsResponse = yield resourceCall(
            api.gateway.participants.getLawyers
        )

        const response = yield resourceCall(
            api.gateway.damage.getLawfirm,
            {
                damageId
            }
        )

        data = {
            lawfirmFound: true,
            lawfirm: {
                ...response,
                ...webakteAccountsResponse.webakteAccounts.find(wa => wa.webakteId === response.webakteId)
            }
        }
    } catch (error) {
    
    }
    yield put(updateWidget(action.key, action.dependentWidgetKeys, data))
}