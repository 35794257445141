import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput, DatePicker, HiddenField, TextArea, PhoneNumberInput } from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import { PushBottom16 } from 'application/components/pages/_common'


export const ExtCreateDamageForm = (
    { formHook }
) => {
    const {t} = useTranslation()

    const {
        register, 
        errors, 
        control, 
        updateFormState,
        formStateValues,
        setValue,
        trigger
    } = formHook

    const setForm = () => {
        updateFormState()
    }

    return <React.Fragment>
        <HiddenField
            name='type'
            register={register} 
        />

        <HiddenField
            name='assignee.userId'
            register={register} 
        />

        <PhoneNumberInput
            label={t('Mobile phone number')}
            name='mobilePhoneNumber'
            allowLandLine={false}
            control={control}
            register={register}
            error={errors.mobilePhoneNumber}
            onChange={setForm}
            setValue={setValue}
            defaultValue={formStateValues.mobilePhoneNumber}
        />

        <PushBottom16 />

        <DatePicker 
            label={t('Damage date')}
            name='incidentDate'
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.incidentDate}
            onDateChange={setForm}
            control={control}
            disabledDays={{after: new Date()}}
            register={register}
            defaultValue={formStateValues.incidentDate}
            setValue={setValue}
            trigger={trigger}
        />

        <TextArea
            label={t('Damage description')}
            name='description'
            register={register}
            validate={null}
            error={errors.description}
            onChange={setForm}
            placeholder='Bitte geben Sie hier eine kurze Beschreibung des Schadens an ihrem Fahrzeug ein.'
        />  

        <PushBottom16 />

        <TextInput 
            label={t('License plate')}
            name='vehicle.licensePlate'
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.vehicle?.licensePlate}
            onChange={updateFormState}
        />

        <TwoColumns 
            columnLeft={
                <TextInput 
                    label={t('Make')}
                    name='vehicle.make'
                    register={register}
                    validate={null}
                    error={errors.vehicle?.make}
                    onChange={updateFormState}
                />
            } 
            columnRight={
                <TextInput 
                    label={t('Model')}
                    name='vehicle.model'
                    register={register}
                    validate={null}
                    error={errors.vehicle?.model}
                    onChange={updateFormState}
                />
            }
        />

        <TextInput 
            label={t('Chassis number')}
            name='vehicle.chassisNumber'
            register={register}
            validate={null}
            error={errors.vehicle?.chassisNumber}
            onChange={updateFormState}
        />

    </React.Fragment>
}