import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import { TASK_STATES } from 'application/constants'
import { IndicatorWithColor } from 'application/components/controls/indicator/override_color_indicator'
import {Deadline} from 'application/components/controls/deadline'
import { HighlightContainer, HighlightContainerInner, HighlightItem, StyledTaskHighlightLinkButton } from './fragments'
import { Select } from 'application/components/controls/form'
import { StateName } from 'application/components/name_mappings/state_name'
import { LinkButton } from 'application/components/fragments/typography'
import { nameHelpers } from 'application/common'
import { exists } from 'application/common/data_helpers'
import { TaskPriorityDisplay } from 'application/components/controls/task_priority_display'
import { useStatefulForm } from 'application/components/hooks'

export const HighlightBarComponent = ({
    state,
    priority,
    deadline,
    colorIndicator = 'gray70',
    isMarginRightNeeded = true,
    canAssign = true,
    onAssignUser,
    onSearchUser,
    userList = [],
    userListLoading,
    assignee,
    currentUser
}) => {
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    const {t} = useTranslation()
    //ToDo: set current user as useForm() initial value
    const {register, errors, setValue} = useStatefulForm()
    const [defaultUserOption, setDefaultUserOption] = useState(exists(assignee) ? {
        value: assignee.userId,
        label: nameHelpers.getFullName(assignee),
        firstName: assignee.firstName,
        lastName: assignee.lastName
    } : null)

    const usersOptions = userList.length > 0 ? userList.map((u) => ({
        value: u.id,
        label: nameHelpers.getFullName(u),
        firstName: u.firstName,
        lastName: u.lastName
    })) : []

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(search)
        }  
    }

    const handleAssign = user => {
        onAssignUser(user === null ? null : user.value, user === null ? null : user.fistName, user === null ? null : user.lastName)
    }

    const showAssignMeButton = canAssign && (assignee === undefined || assignee.userId !== currentUser.id)
    const handleAssignMe = () => {
        onAssignUser(currentUser.id, currentUser.firstName, currentUser.lastName)
        setDefaultUserOption({
            value: currentUser.id,
            label: nameHelpers.getFullName(currentUser),
            firstName: currentUser.firstName,
            lastName: currentUser.lastName
        })
    }

    const defaultUserOptionValue = defaultUserOption !== null ? defaultUserOption.value : undefined
    useEffect(() => {   
        assignee !== undefined && assignee.userId !== defaultUserOptionValue && setDefaultUserOption({
            value: assignee.userId,
            label: nameHelpers.getFullName(assignee),
            firstName: assignee.firstName,
            lastName: assignee.lastName
        })
    }, [assignee, defaultUserOptionValue])

    return <HighlightContainer isMarginRightNeeded={isMarginRightNeeded} cssHeight={showAssignMeButton ? '64px' : 'auto'}>
        <HighlightContainerInner>
            <IndicatorWithColor text={<StateName state={state} />} iconKey={'move_to_inbox'} overrideColorWith={colorIndicator} />
            <HighlightItem className="highlightItem">{
                state === TASK_STATES.DONE
                    ? <IndicatorWithColor iconKey='person' text={nameHelpers.getFullName(assignee)} overrideColorWith={colorIndicator} />
                    : canAssign 
                        ? <React.Fragment>
                            <Select 
                                name='assignedTo'
                                register={register}
                                setValue={setValue}
                                options={usersOptions}
                                error={errors.assignedTo}
                                validate={null}
                                onInputChange={handleSearchUser}
                                onBlur={()=>{setUserSearchActive(false)}}
                                onFocus={()=>{setUserSearchActive(true)}}
                                onChange={handleAssign}
                                noOptionsMessage={t('No users found')}
                                placeholder={t('Search employee')}
                                isLoading={userListLoading}
                                hideValidationBox={true}
                                defaultValue={defaultUserOption}
                            />
                            {
                                showAssignMeButton && <StyledTaskHighlightLinkButton>
                                    <LinkButton 
                                        isActive={true} 
                                        onClick={handleAssignMe}
                                    >
                                        {t('assign to me')}
                                    </LinkButton>
                                </StyledTaskHighlightLinkButton>
                            }
                        </React.Fragment> 
                        : exists(assignee) && <IndicatorWithColor iconKey='person' text={defaultUserOption.label} overrideColorWith={colorIndicator} />
            }
                
            </HighlightItem>
            {
                deadline !== null && <HighlightItem cssWidth='auto'>
                    <Deadline deadlineFromTask={deadline} />
                </HighlightItem>
            }
            {
                priority !== undefined && <HighlightItem cssWidth='auto'>
                    <TaskPriorityDisplay priority={priority} />
                </HighlightItem>
            }
        </HighlightContainerInner>        
    </HighlightContainer>
}