import React from 'react'
import { useTranslation } from 'react-i18next'
import { DisabledText } from 'application/components/fragments/typography'
import { stringIsFilled } from 'application/common/data_helpers'
import PropTypes from 'prop-types'







const JobNumber = (
    { 
        number = '', 
        isSelected = false  
    }
) => {
    const {t} = useTranslation()
    const isKnown = stringIsFilled(number)

    if(!isKnown){
        return <DisabledText isSelected={isSelected}>
            {t('not known')}
        </DisabledText>
    }

    return <React.Fragment>
        {number}
    </React.Fragment>
}

JobNumber.propTypes = {
    number: PropTypes.string, 
    isSelected: PropTypes.bool  
}

export { JobNumber }