import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../_common/commission_fragments'
import { WidgetSubHeadline, WidgetErrorMessage } from 'application/components/widgets/_common'
import { DateComponent, FontIcon } from '@ec/ui-controls-react'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { INFOBOX, COMMISSION_STATE } from 'application/constants'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { FlexBox } from 'application/components/fragments/flex_box'
import { LawfirmCard } from 'application/components/building_blocks/lawfirm_card/component'
import { ActionButton } from 'application/components/controls/action_button'

const CommissionLawfirmWidgetComponent = (
    { 
        widgetKey, 
        match, 
        payload, 
        loadingFailed, 
        openDialogCreateLawyers,
        openDialogDeleteLawfirm,
        branchId,
        updateLawfirm,
        damageState,
        statusList
    }
) => {
    const {
        lawfirm, 
        lawfirmFound
    } = payload

    const { t } = useTranslation()
    const {damageId} = match.params

    const showExpirationInfo = useMemo(()=>{
        if(!statusList.loaded){
            return false
        }

        const currentState = statusList.data.find(s => s.id === damageState.id)

        if(!currentState){
            return false
        }
        
        return currentState.lifeCycleState !== 'Done'
    }, [damageState.id, statusList.data, statusList.loaded])

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    const handleOpenDialogCreateLawyer = () => {
        openDialogCreateLawyers(
            damageId, 
            lawfirmFound,
            branchId
        )
    }

    const handleOpenDialogDeleteLawyer = () => {
        openDialogDeleteLawfirm(
            lawfirm,
            damageId
        )
    }

    const warningDate = new Date().setDate(new Date().getDate() + 30)
    const expirationDate = lawfirmFound && lawfirm?.expiresAt ? new Date(lawfirm.expiresAt) : null
    const expirationDateUnknown = expirationDate?.getFullYear() === 1970
    const expirationDateIsClose = expirationDate < warningDate || expirationDateUnknown

    return <React.Fragment>
        <WidgetSubHeadline>{t('Commissioned lawfirm')}</WidgetSubHeadline>
   
        <FlexBox flexDirection='column'>
            {
                lawfirmFound 
                    ? <Fragments.ParticipantsWrapper>
                        <LawfirmCard 
                            lawfirm={lawfirm}
                            small={true}
                            stretch={true}
                        >
                            <IconButtonWithLabel
                                iconKey='autorenew'
                                label={t('Change')}
                                onButtonClick={handleOpenDialogCreateLawyer}
                            /> 
                            <IconButtonWithLabel
                                iconKey='close'
                                label={t('Undo')}
                                onButtonClick={handleOpenDialogDeleteLawyer}
                            /> 
                        </LawfirmCard>
                        {
                            showExpirationInfo && <InfoBox type={expirationDateIsClose ? INFOBOX.TYPES.WARNING : INFOBOX.TYPES.SECONDARY}
                                direction='row' 
                                cssMargin='0'
                            >
                                <FontIcon icon='info_outline' />
                                <Spacer basis='10px' />
                                <FlexBox flexDirection='column'>
                                    {
                                        expirationDateUnknown
                                            ? <span>{t('Commission expires soon!')}</span>
                                            : <span>{t('Commission expires at:')}&nbsp;<DateComponent date={lawfirm.expiresAt} /></span>
                                    }
                            
                                    {
                                        expirationDateIsClose && <FlexBox paddingTop='8px'>
                                            <ActionButton
                                                buttonText={t('Extend commission')}
                                                onButtonClick={() => updateLawfirm(damageId, lawfirm)}
                                            />
                                        </FlexBox>
                                    }
                             
                                </FlexBox>
                                <Spacer />
                            </InfoBox>
                        }
                        
                        {
                            lawfirm.state === COMMISSION_STATE.FAILED 
                            && <InfoBox type={INFOBOX.TYPES.ALERT}
                                direction='row' 
                                cssMargin='0'
                            >
                                <FontIcon icon='info_outline' />
                                <Spacer basis='10px' />
                                <span>{t('Commissioning failed. Please contact our support.')}</span>
                                <Spacer />
                            </InfoBox>
                        }
                       
                    </Fragments.ParticipantsWrapper>
                    : <React.Fragment>
                        <IconButtonWithLabel
                            iconKey='add'
                            label={t('Lawfirm')}
                            onButtonClick={handleOpenDialogCreateLawyer}
                            cssPadding='0'
                        />
                    </React.Fragment>
            }    
        </FlexBox>
    </React.Fragment>
}

CommissionLawfirmWidgetComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const CommissionLawfirmComponent = withRouter(
    CommissionLawfirmWidgetComponent
)