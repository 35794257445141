import React from 'react'
import { PushBottom32, SubPageLayout } from 'application/components/pages/_common'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS_TYPE } from 'application/constants/ui_settings'
import { ActionButton } from '@ec/ui-controls-react'
import { useTranslation } from 'react-i18next'
import {SettingsSection} from './common/section'

export const AdministrationSettingsUiSettings = () => {
    const { t } = useTranslation()

    const {
        getSetting,
        saveAccountSettings,
        saveUserSettings,
        settingsDefinitions,
        updateAccountSettings,
        updateUserSettings,
        accountSettingsLoaded,
        userSettingsLoaded,
        isPermittedToUpdateAccountSettings
    } = useUiSettings()

    return <SubPageLayout>
        {
            isPermittedToUpdateAccountSettings && accountSettingsLoaded && <React.Fragment>
                <SettingsSection
                    headline={t('Settings for this company')}
                    settingsType={UI_SETTINGS_TYPE.ACCOUNT}
                    updateSettings={updateAccountSettings}
                    getSetting={getSetting}    
                    settingsDefinitions={settingsDefinitions}
                    submitButton={ 
                        <ActionButton
                            onButtonClick={saveAccountSettings}
                            buttonText={t('Save account settings')}
                        />
                    }
                />
                <PushBottom32 />
            </React.Fragment>
        }

        {
            userSettingsLoaded && <SettingsSection
                headline={t('Settings for the current user')}
                settingsType={UI_SETTINGS_TYPE.USER}
                updateSettings={updateUserSettings}
                getSetting={getSetting}    
                settingsDefinitions={settingsDefinitions}
                submitButton={ 
                    <ActionButton
                        onButtonClick={saveUserSettings}
                        buttonText={t('Save user settings')}
                    />
                }
            />
        }
    </SubPageLayout>
}