import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, TextArea } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useStatefulForm } from 'application/components/hooks'
import { TwoColumns } from 'application/components/forms/_common'


const CreateSmartConnectEmail = (
    {
        abort,
        setAbortBlocked,
        setDataChanged,
        onCreateSmartConnectEmail,
        dialog,
        accountId
    }
) => {
    const { payload } = dialog

    const defaultValues = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        message: payload.message
    }

    const { t } = useTranslation()

    const {
        register,
        errors,
        handleSubmit,
        getValues,
        updateFormState
    } = useStatefulForm({ defaultValues })

    const { notEmpty, mustBeEmail } = validationMethods

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const onSubmitSuccess = () => {
        const metaData = {
            accountId,
            damageId: dialog.payload.damageId
        }

        const payload = getValues()

        console.log(payload)

        onCreateSmartConnectEmail(
            dialog.name,
            payload,
            metaData
        )
    }

    return <React.Fragment>
        <DialogMainContent flexDirection='column'>
            <TwoColumns
                columnLeft={<TextInput
                    label={t('Firstname')}
                    name='firstName'
                    register={register}
                    error={errors.firstName}
                    onChange={onDataChange}
                />}
                columnRight={<TextInput
                    label={t('Lastname')}
                    name='lastName'
                    register={register}
                    validate={{ notEmpty: validationMethods.notEmpty }}
                    error={errors.lastName}
                    onChange={onDataChange}
                />}
            />
            <TextInput
                label={t('Email')}
                name='email'
                register={register}
                validate={{
                    notEmpty,
                    mustBeEmail
                }}
                error={errors.email}
                onChange={onDataChange}
            />
            <TextArea
                label={t('Message')}
                name='message'
                register={register}
                validate={{ notEmpty: validationMethods.notEmpty }}
                error={errors.message}
                onChange={onDataChange}
            />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Send'
        />
    </React.Fragment>
}

export const CreateSmartConnectEmailComponent = withDialogDataLoader(CreateSmartConnectEmail)