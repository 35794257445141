import React from 'react'
import { useQuery } from 'application/components/hooks/use_query'
import { ROUTE_PARAMETERS } from './route_parameters'
import { AccountHeader } from '../../_common'
import { exists } from 'application/common/data_helpers'
import { getFullName } from 'application/common/name_helpers'


const getIsCompany = (query) => {
    const isCompanyStringValue = query.getStringParam(ROUTE_PARAMETERS.IS_COMPANY_PARAM)
    return isCompanyStringValue === 'true'
}

const getCustomerName = (query, isCompany) => {
    if (isCompany) {
        return query.getStringParam(ROUTE_PARAMETERS.COMPANY_NAME_PARAM)
    } else {
        const firstName = query.getStringParam(
            ROUTE_PARAMETERS.FIRST_NAME_PARAM
        )
        const lastName = query.getStringParam(
            ROUTE_PARAMETERS.LAST_NAME_PARAM
        )
        return `${firstName} ${lastName}`
    }
}


export const ExtCreateDamageHeader = ({
    sharedResource = null
}) => {
    const query = useQuery()
    const isCompany = getIsCompany(query)
    const customerName = exists(sharedResource?.data?.customer) ? getFullName(sharedResource.data.customer) : getCustomerName(query, isCompany)

    return <AccountHeader 
        subtitle={`Panne / Unfallmeldung von ${customerName}`}
    />
}