import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {ThumbnailWithLoader} from 'application/components/building_blocks/attachments/thumbnails'

const StyledSlider = styled.div`
    max-width: calc(100% - 100px);
    margin: auto;
    & .slick-arrow:before{
        color: ${props => props.theme.color.gray70}
    }
`

export const ThumbnailSlider = ({
    thumbnailsForSlider, //thumbnails for this slider
    onThumbnailClick,
    selectedThumbnailId
}) => {
    const sliderSettings = {
        dots: false,
        infinite: false, //weird behavior if used with effect
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true, //bit buggy?
        variableWidth: true,
        centerPadding: '0'
    }

    const slider = useRef(null)

    useEffect(() => { //scroll to selected thumbnail on init
        var currentIndex = 0
        if (thumbnailsForSlider.length > 1 ) {
            currentIndex = thumbnailsForSlider.map(x => x.id).indexOf(selectedThumbnailId)
            slider.current.slickGoTo(currentIndex) 
        } 
    }, [selectedThumbnailId, thumbnailsForSlider])

    return <StyledSlider>
        <Slider ref={slider} {...sliderSettings}>
            {
                thumbnailsForSlider.map((thumbnail, index) => {
                    return  <ThumbnailWithLoader
                        key={index}
                        attachmentId={thumbnail.id}
                        attachmentName={thumbnail.name}
                        damageId={thumbnail.damageId}
                        taskId={thumbnail.resourceId}
                        onAttachmentClick={onThumbnailClick}
                        isActive={thumbnail.id === selectedThumbnailId}
                        showCaption={false} 
                    /> 
                })
            }
        </Slider>
    </StyledSlider>
}

ThumbnailSlider.propTypes = {
    thumbnailsForSlider: PropTypes.array,
    selectedThumbnailId: PropTypes.string,
    onThumbnailClick: PropTypes.func
}