import { fetchHandler, limiter as defaultLimiter } from 'application/api/request_builders'
import { handleApiResponse, handleApiFilestreamResponse } from 'application/api/response_handlers'

export const apiCall = (
    request,
    retryStatusCodes = [],
    limiter = defaultLimiter
) => {
    return limiter.schedule(() => fetchHandler(request.url, request.data, retryStatusCodes))
        .then(handleApiResponse)
}

export const apiFilestream = (
    request,
    retryStatusCodes = [],
    limiter = defaultLimiter
) => {
    return  limiter.schedule(() => fetchHandler(request.url, request.data, retryStatusCodes))
        .then(handleApiFilestreamResponse)
}