import { connect } from 'react-redux'
import {TableMyDamagesOverviewComponent} from './component'

const mapStateToProps = state => {
    return {
        currentUserId: state.auth.user.id
    }
}

export const TableMyDamagesOverview = connect(
    mapStateToProps
)(TableMyDamagesOverviewComponent)