import React, { useMemo } from 'react'
import { CustomNodeUpperContainer, Title } from './fragments'
import TruncateString from 'react-truncate-string'
import { FontIcon } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

export const CustomNodeUpper = withTheme(({label, selected, isLocked = false, theme, data}) => {
    const {t} = useTranslation()
    
    const text = useMemo(()=>{
        return i18n.exists(`${data.nodeType}${label}`)
            ? t(`${data.nodeType}${label}`)
            : label
    }, [data.nodeType, label, t])
    
    return <CustomNodeUpperContainer selected={selected} title={text}>
        <Title>{isLocked && <FontIcon
            icon='lock'
            fontSize={theme.fontSize.standard} 
            color={theme.color.anthracite50} 
            title={t('System group')}
            isClickable={true}
        />}<TruncateString text={text} truncateAt={50}/></Title>
    </CustomNodeUpperContainer>
})