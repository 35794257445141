import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Trans } from 'react-i18next'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { DIALOG } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { TextButton } from 'application/components/fragments/typography'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { convertGermanDateStringToISODate } from 'application/common/date_time_helpers'
import { exists } from 'application/common/data_helpers'
import { FormRenderer } from 'application/modules/dynamic-form'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'

export const KickstartStep1Component = (
    {
        //from component
        abort,
        dialog,
        goToNextStep,
        setTempDamageData,
        tempDamageData,
        applyPreferredVehicleData,
        customerData,
        //from container
        onCreateDamage,
        currentBranch,
        onOpenChangeBranchDialog,
    }
) => {
    const defaultValues = {
        vehicle: (applyPreferredVehicleData || !customerData.existingCustomer) && exists(customerData.values?.preferredVehicleInfo)
            ? {...customerData.values.preferredVehicleInfo}
            : {},
        ...tempDamageData
    }

    const {t} = useTranslation()

    const [dynamicFormData, setDynamicFormData] = useState({})
    const [continueWithNextStep, setContinueWithNextStep] = useState(null)
    const formSubmitButtonRef = useRef(null)

    const formHook = useStatefulForm({ defaultValues })
    formHook.nameRequired = true

    const {
        handleSubmit,
    } = formHook

    const createDamageAndContinue = useCallback((payload) => {
        setTempDamageData(payload)
        goToNextStep()
    }, [setTempDamageData, goToNextStep])

    const createDamageAndCloseDialog = useCallback((payload) => {
        onCreateDamage(
            DIALOG.NAME.CREATE_DAMAGE_KICKSTART_AND_CLOSE_DIALOG,
            {
                damageData: {
                    customer: customerData.values,
                    damage: payload
                }
            }
        )
    }, [onCreateDamage, customerData.values])

    const onSubmitSuccess = (continueWithNextStep) => {
        setContinueWithNextStep(continueWithNextStep)
        formSubmitButtonRef.current.click()
    }

    useEffect(() => {
        if(continueWithNextStep === null || !Object.keys(dynamicFormData).length) {
            return
        }
        const newDamageData = {
            ...dynamicFormData,
            vehicle: {
                ...dynamicFormData.vehicle,
                firstRegisteredAt: convertGermanDateStringToISODate(dynamicFormData.vehicle?.firstRegisteredAt) || null
            }
        }
        continueWithNextStep
            ? createDamageAndContinue(
                newDamageData
            )
            : createDamageAndCloseDialog(
                newDamageData
            )
    }, [dynamicFormData, continueWithNextStep, createDamageAndContinue, createDamageAndCloseDialog])

    const currentBranchName = currentBranch.name

    const {
        getSetting,
    } = useUiSettings()

    const kickstartFormRendererStructure = getSetting(UI_SETTINGS.ACCOUNT.KICKSTART_FORM_RENDERER_STRUCTURE)

    return <React.Fragment>
        <DialogMainContent >
            <PushBottom16/>
            <InfoBox
                direction='row'
                cssMargin='0'
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <Trans i18nKey='This damage will be created in currentBranch'>
                    {{currentBranchName}}
                </Trans>
                <Spacer />
                <TextButton  onClick={() => onOpenChangeBranchDialog(dialog.name)}>
                    &raquo;{t('Change branch')}
                </TextButton>
            </InfoBox>
            <PushBottom16/>

            <FormRenderer 
                ref={formSubmitButtonRef} 
                formRendererDescription={kickstartFormRendererStructure}
                defaultValues={defaultValues} 
                onSubmitFormData={(data) => { setDynamicFormData(data) }}
            />

        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested'
                label={t('Cancel')}
                onButtonClick={abort}
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Complete the damage creation')}
                onButtonClick={handleSubmit(() => onSubmitSuccess(false))}
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Continue to handing over')}
                onButtonClick={handleSubmit(() => onSubmitSuccess(true))}
            />
        </StepsLayoutFooter>
    </React.Fragment>
}