import { ACTIONS } from 'application/constants'

export {
    loginRequestSuccess,
    logoutRequest,
    setReturnUrl
} from './log_in'

export { 
    requestCurrentAccountById, 
    setCurrentAccountLoadedState, 
    setCurrentAccountId,
    populateCurrentAccount 
} from './current_account'

export {
    getAccounts,
    populateAccounts,
    populateAccountsTotal,
    setAccountsFound,
    setNoAccountsFound
} from './accessible_accounts'

export {
    requestCurrentBranchChange,
    populateCurrentBranch
} from './current_branch'

export {
    populateDefaultBranchInfo
} from './accessible_branches'

export {
    writeApplicationToken
} from './tokens'

export {
    populatePermissions,
    populateCurrentBranchPermissions,
    setNoPermissionsFound,
    setPermissionsFound
} from './permissions'

export {
    getUserData,
    populateUserData
} from './current_user'

export const setSessionExpired = () => ({
    type: ACTIONS.AUTH_SESSION_EXPIRED
})


export {
    getPreferredAccount,
    populatePreferredAccount,
    preferredAccountNotFound,
    preferredAccountFound,
    setAccountAsPreferred,
    unsetAccountAsPreferred,
    refreshPreferredAccount
} from './preferred_account'