import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getComprehensiveDamageTypes } from 'application/redux/selectors'
import { getComprehensiveDamageTypesRequest } from 'application/redux/actions/common'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

export const useComprehensiveDamageTypes = () => {
    const dispatch = useDispatch()

    const {
        comprehensiveDamageTypes,
        comprehensiveDamageTypesLoaded,
        comprehensiveDamageTypesLoading
    } = useSelector(getComprehensiveDamageTypes)

    useEffect(()=>{
        if(!comprehensiveDamageTypesLoaded && !comprehensiveDamageTypesLoading){
            dispatch(getComprehensiveDamageTypesRequest())
        }
    }, [comprehensiveDamageTypesLoaded, comprehensiveDamageTypesLoading, dispatch])

    const deleteComprehensiveDamageType = useCallback((type)=>{
        dispatch(openDialogByName(DIALOG.NAME.DELETE_COMPREHENSIVE_DAMAGE_TYPE, false, type, 'Delete comprehensive damage type'))
    }, [dispatch])

    const addComprehensiveDamageType = useCallback(()=>{
        dispatch(openDialogByName(DIALOG.NAME.ADD_COMPREHENSIVE_DAMAGE_TYPE, true, {}, 'Add comprehensive damage type'))
    }, [dispatch])

    const editComprehensiveDamageType = useCallback((type)=>{
        dispatch(openDialogByName(DIALOG.NAME.EDIT_COMPREHENSIVE_DAMAGE_TYPE, true, type, 'Edit comprehensive damage type'))
    }, [dispatch])

    return {
        comprehensiveDamageTypes,
        comprehensiveDamageTypesLoaded,
        comprehensiveDamageTypesLoading,
        deleteComprehensiveDamageType,
        addComprehensiveDamageType,
        editComprehensiveDamageType
    }
}