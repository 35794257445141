import React from 'react'
import styled, { withTheme } from 'styled-components'
import { IconButton } from '@ec/ui-controls-react'

export const SidebarPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 359px;   
    border-left: 1px solid transparent;
    margin-top: -16px;
    margin-right: -16px;
`

const StyledSidebar = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 359px;   
    background: ${props => props.theme.color.white};
    border-left: 1px solid ${props => props.bordercolor};
    margin-top: -16px;
    margin-right: -16px;
`

const StyledSidebarHeader = styled.div`
    flex: 0 0 48px;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.bgcolor};
    margin-left: -1px;
`

const StyledSidebarHeaderTitle = styled.div`
    flex: 1 1 auto;
    display: flex;
    padding-left: 16px;
    align-items: center;
    color: ${props => props.theme.color.white};
    font-size: ${props => props.theme.fontSize.medium18};
`

const StyledSidebarHeaderClose = styled.div`
    flex: 0 0 48px;
    display: flex;
`

const StyledSidebarBody = styled.div`
    flex: 1 0 calc(100% - 48px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow: auto;
`


export const Sidebar = withTheme(({children, title = '', onSidebarClose = () => {}, sidebarTitleColor = null, theme}) => {
    const titleColor = sidebarTitleColor === null ? theme.color.anthracite : sidebarTitleColor
    return <StyledSidebar bordercolor={titleColor}>
        <StyledSidebarHeader bgcolor={titleColor}>
            <StyledSidebarHeaderTitle>{title}</StyledSidebarHeaderTitle>
            <StyledSidebarHeaderClose>
                <IconButton iconKey='close' onButtonClick={onSidebarClose} color={theme.color.white} />
            </StyledSidebarHeaderClose>
        </StyledSidebarHeader>
        <StyledSidebarBody className='sidebarBody'>
            {children}
        </StyledSidebarBody>
    </StyledSidebar>
})