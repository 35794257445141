import { useDispatch, useSelector } from 'react-redux'
import { settingsDefinitions, UI_SETTINGS_TYPE } from 'application/constants/ui_settings'
import { useReduxStoreLookUp } from './use_redux_store_lookup'
import { saveAccountSettingsRequest, saveUserSettingsRequest } from 'application/redux/actions/ui_settings'
import { useEffect, useState } from 'react'
import { getCurrentUserId, getAllPermissions } from 'application/redux/selectors'
import { isPermittedInAnyBranch } from '../context/permissions'
import { USER_PERMISSONS } from 'application/constants'

export const useUiSettings = () => {
    const dispatch = useDispatch()
    const {
        accountSettingsLoaded,
        accountSettingsVersion,
        userSettingsLoaded,
        userSettingsVersion,
        accountSettings: accountSettingsStore,
        userSettings: userSettingsStore
    } = useSelector(state => state.uiSettings)

    const allPermissions = useSelector(getAllPermissions)

    const [accountSettings, setAccountSettings] = useState(accountSettingsStore)
    const [userSettings, setUserSettings] = useState(userSettingsStore)

    useEffect(()=>{
        setAccountSettings(accountSettingsStore)
    }, [accountSettingsStore])
        
    useEffect(()=>{
        setUserSettings(userSettingsStore)
    }, [userSettingsStore])

    const { lookUp } = useReduxStoreLookUp()

    const getDefinition = settingKey => {
        return settingsDefinitions.find(d => d.key === settingKey)
    }

    const getSetting = (settingKey, options = {}) => {
        const definition = getDefinition(settingKey)
        const selector = definition?.selector(options)
        const settingsAreLoaded = definition.type === UI_SETTINGS_TYPE.ACCOUNT
            ? accountSettingsLoaded
            : userSettingsLoaded

        if(!settingsAreLoaded){
            return definition.defaultValue
        }
        return lookUp(selector)
    }

    const updateSettingsObject = (settings, definition, value) => {
        const path = definition.path

        var k = settings
        var steps = path.split('.')
        var last = steps.pop()
        steps.forEach(e => (k[e] = k[e] || {}) && (k = k[e]))
        k[last] = value
        return settings
    }

    const saveAccountSettings = () => {
        dispatch(saveAccountSettingsRequest(accountSettings, true))
    }

    const updateAccountSettings = (settingKey, value, saveDirectly = false) => {
        const definition = getDefinition(settingKey)
        const newAccountSettings = updateSettingsObject(accountSettings, definition, value)
        dispatch(saveAccountSettingsRequest(newAccountSettings, saveDirectly))
        setAccountSettings(newAccountSettings)
    }

    const saveUserSettings = () => {
        const userId = lookUp(getCurrentUserId)
        dispatch(saveUserSettingsRequest(userId, userSettings, true))
    }

    const updateUserSettings = (settingKey, value, saveDirectly = false) => {
        const definition = getDefinition(settingKey)
        const newUserSettings = updateSettingsObject(userSettings, definition, value)
        const userId = lookUp(getCurrentUserId)
        dispatch(saveUserSettingsRequest(userId, newUserSettings, saveDirectly))
        setUserSettings(newUserSettings)
    }

    const isPermittedToUpdateAccountSettings = isPermittedInAnyBranch(allPermissions, [USER_PERMISSONS.SETTINGS.UPDATE], true)


    return {
        getSetting,
        accountSettingsLoaded,
        accountSettingsVersion,
        userSettingsLoaded,
        userSettingsVersion,
        saveAccountSettings,
        saveUserSettings,
        accountSettings,
        userSettings,
        settingsDefinitions,
        updateAccountSettings,
        updateUserSettings,
        getDefinition,
        isPermittedToUpdateAccountSettings
    }
}