import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateUserGroups } from 'application/redux/actions/common/groups'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getUserGroupsSaga(action) {
    try {
        const groupsResponse = yield resourceCall(api.gateway.groups.getGroups)
        yield put(populateUserGroups(groupsResponse.groups))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getUserGroupsWatcher() {
    yield takeLeading(ACTIONS.COMMON_GET_USER_GROUPS_REQUEST, getUserGroupsSaga)
}