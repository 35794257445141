
import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { BaseTableRow, baseTableRowCSS } from '../base'



const StyledTableRowLink = styled(NavLink)`
    ${baseTableRowCSS}
    cursor: pointer;
    border-bottom: 2px solid ${props => props.bordercolor}; 

    &:link, 
    &:visited {
        color: ${props => props.theme.color.anthracite};
    }

    &:hover {
        background: ${props => props.theme.color.gray10};
        border-bottom-color: ${props => props.theme.color.gray10};
    }
    & *:not(.context-menu){
        cursor: inherit !important;
    }
`





export const LinkedTableRow = (
    props
) => {
    const     {
        href,
        cssHeight,
        flexibleCellContainerWidth,
        configuration,
        element,
        isSelected,
        backgroundColor,
        moIsActive = false,
        className = 'table-row-linked'
    } = props


    return <StyledTableRowLink
        backgroundcolor={backgroundColor}
        bordercolor={backgroundColor}
        to={href}
        cssheight={cssHeight}
    >
        <BaseTableRow 
            element={element}
            configuration={configuration}
            flexibleCellContainerWidth={flexibleCellContainerWidth}
            isSelected={isSelected}
            moIsActive={moIsActive}
            className={className}
        />
    </StyledTableRowLink>
}