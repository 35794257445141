import React from 'react'
import SortableTree from 'react-sortable-tree'
import { CustomNode } from './custom_node'
import { StyledCategoryTreeCssWrapper } from './tree_style'
import { withTheme } from 'styled-components'
import { arrayHelpers } from 'application/common'

const TreeViewComponent = ({
    tree,
    onChange = () => {},
    onNodeClick = () => {},
    onNodeDelete = () => {},
    onNodeMove = () => {},
    canNodeHaveChildren = () => true,
    canDragNode = () => true,
    height,
    maxDepth = 3,
    hideTreeStructure = false,
    theme
}) => {
    const handleMove = moveData => {
        //only fire event if treeIndex or path actually changed
        if(moveData.prevTreeIndex !== moveData.nextTreeIndex || !arrayHelpers.scalarArraysAreEqual(moveData.nextPath, moveData.prevPath)){
            onNodeMove(moveData)
        }
    }
    return <StyledCategoryTreeCssWrapper hideTreeStructure={hideTreeStructure} treeIsEmpty={tree.treeData.length === 0}>
        <SortableTree 
            treeData={tree.treeData}
            onChange={onChange}
            maxDepth={maxDepth}
            style={{height}}
            getNodeKey={({ node }) => node.id}
            canNodeHaveChildren={canNodeHaveChildren}
            canDrag={canDragNode}
            nodeContentRenderer={(props)=>{
                return <CustomNode 
                    onNodeClick={onNodeClick} 
                    onNodeDeleteClick={onNodeDelete}
                    {...props}
                />
            }}
            rowHeight={theme.treeView.nodeDimension}
            onMoveNode={handleMove}
        />
    </StyledCategoryTreeCssWrapper>
}

export const TreeView = withTheme(TreeViewComponent)