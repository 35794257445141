
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetailBox, ActivityDetailWrapper, BranchDetail, ActivityDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageStateChangedDetails = (
    {
        activity
    }
) => {

    const {
        oldState = null,
        newState = null,
        damage
    } = activity.data

    const oldStateName = oldState?.name || ''
    const newStateName = newState?.name || ''

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetailBox>
            {oldStateName}<RightArrow />{newStateName}   
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DamageStateChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}