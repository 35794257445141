import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { stringIsFilled } from 'application/common/data_helpers'



const CreatedInfo = (
    {
        activityData
    }
) => {
    const {t}  = useTranslation()

    const damageLicensePlate = activityData?.damage?.licensePlate || ''
    const documentName = activityData?.name || ''

    return <React.Fragment>
        {
            stringIsFilled(damageLicensePlate) && <React.Fragment>
                {t('Damage')}: {damageLicensePlate}, &nbsp;
            </React.Fragment>
        } 
        {
            stringIsFilled(documentName) && <React.Fragment>
                {t('Document')}: {documentName}
            </React.Fragment>
        }
    </React.Fragment>
}

CreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CreatedInfo}
