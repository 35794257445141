import { useDispatch, useSelector } from 'react-redux'
import { getContractors, getAccessibleBranches } from 'application/redux/selectors'
import { saveBranchContractorsRequest } from 'application/redux/actions/contractors'
import { useMemo } from 'react'

export const useContractors = () => {
    const dispatch = useDispatch()

    const {
        contractorsList,
        contractorsLoading,
        contractorsLoaded,
        branchContractorsList,
        branchContractorsLoading,
        branchContractorsLoaded,
    } = useSelector(getContractors)

    const {
        accessibleBranches,
        accessibleBranchesLoaded
    } = useSelector(getAccessibleBranches)

    const onSaveBranchContractors = (
        branchId, contractorsList
    ) => dispatch( 
        saveBranchContractorsRequest(branchId, contractorsList)
    )

    const groupedContractors = useMemo(()=>{
        
        return contractorsList.reduce((accumulator, currentContractor) => {
            const contractorTypeExisting = accumulator.find(element => element.type === currentContractor.type) !== undefined

            if(contractorTypeExisting){
                accumulator
                    .find(element => element.type === currentContractor.type)
                    .contractors
                    .push(currentContractor)
            }else{
                accumulator
                    .push({
                        type: currentContractor.type,
                        contractors: [currentContractor]
                    })
            }

            return accumulator
        }, [])

    }, [contractorsList])

    return {
        contractorsList,
        contractorsLoading,
        contractorsLoaded,
        branchContractorsList,
        branchContractorsLoading,
        branchContractorsLoaded,
        accessibleBranches,
        accessibleBranchesLoaded,
        onSaveBranchContractors,
        groupedContractors
    }
}