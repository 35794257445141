import {  StyleSheet } from '@react-pdf/renderer'
//export const dev = StyleSheet.create({
//     border: '1px dotted red'
// })
  
export const page = StyleSheet.create({
        paddingTop: '17mm',
        paddingBottom: '17mm',
        paddingRight: '8mm',
        paddingLeft: '20mm',
    }),
  
    blackBorder =  {border: '0.5px solid black'},

    fontSize = {
        extraSmall: 5,
        small: 6,
        regular: 7,
        subItem: 9,
        big: 10,
        headline: 10,
    },

    row = StyleSheet.create({
        flexDirection: 'row'
    }),
  
    column = StyleSheet.create({
        flexDirection: 'column'
    }),


    column16 = StyleSheet.create({
        ...column,
        width: '16%'
    }),
 
    headerColumnLeft = StyleSheet.create({
        ...column,
        width: '65mm'
    }),

    headerColumnRight = StyleSheet.create({
        ...column,
        width: '119mm'
    }),

    zentralVerbandInfoColumn = StyleSheet.create({
        ...column,
        width: '70mm'
    }),
  
    center = StyleSheet.create({
        justifyContent: 'center',
        alignItems: 'center'
    }),
  
    field = StyleSheet.create({
        common: {
            ...blackBorder,
            height: '7mm',
            paddingLeft: '1mm',
            paddingRight: '1mm',
            width: '100%',
            backgroundColor: 'white'
        },
        placeHolder: {
            fontSize: fontSize.small
        },
        value: {
            fontSize: fontSize.regular,
            paddingLeft: 6
        },
        label: {
            fontSize: fontSize.small,
            paddingTop: 1,
            paddingBottom: 1
        }
    }),

    fieldSmall = StyleSheet.create({
        ...field,
        common: {
            ...field.common,
            height: '5mm'
        }
    }),
  
    kSchaden = StyleSheet.create({
        fontSize: 9,
        fontWeight: 'bold',
        paddingLeft: '1mm',
        height: '4mm'
    }),
  
    headLine = StyleSheet.create({
        fontSize: fontSize.headline, 
        marginTop: '1mm', 
        marginBottom: '1mm'
    }),

    telefaxInfoField = StyleSheet.create({
        ...field.common,
        height: '26mm',
        justifyContent: 'space-between',
        paddingLeft: '1.5mm',
        paddingRight: '1mm',
        telefax: {fontSize: fontSize.big},
        value: {
            fontSize: fontSize.big,
            paddingLeft: '1mm'
        }
    }),

    noteBoxNote = StyleSheet.create({
        width: 60,
        transform: 'rotate(270deg)',
        marginTop: 30,
        marginLeft: -20,
        fontSize: fontSize.regular,
        letterSpacing: 2
    }),

    subItem = StyleSheet.create({
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#ececed',
        fontSize: fontSize.subItem,
        marginTop: '2mm',
        height: '5mm',
        paddingLeft: 4
    }),


    checkBoxWithLabelSmall = StyleSheet.create({
        wrapper: {
            ...row,
            height: 10, 
            alignItems: 'center'
        },
        checkBox: {
            ...blackBorder,
            backgroundColor: 'white',
            height: 6,
            width: 6,
            justifyContent: 'center',
            alignItems: 'center'
        },
        value: {
            fontSize: 4, 
            color: 'black'
        },
        label: {
            fontSize: fontSize.small, 
            paddingLeft: 2
        }
    }),

    checkBoxWithLabel = StyleSheet.create({
        wrapper: {
            ...row,
            height: '3mm',
            marginTop: 0,
            alignItems: 'center'
        },
        checkBox: {
            ...blackBorder,
            backgroundColor: 'white',
            height: 6,
            width: 6,
            justifyContent: 'center',
            alignItems: 'center'
        },
        value: {
            fontSize: 4, 
            color: 'black'
        },
        label: {
            fontSize: fontSize.regular, 
            paddingLeft: '1mm'
        }
    }) ,

    leftColumn = StyleSheet.create({
        ...column,
        width:'50%',
        marginRight: '2.5mm'
    }),

    rightColumn = StyleSheet.create({
        ...column,
        width:'50%',
        marginLeft: '2.5mm'
    }),

    textAreaWithLabel = StyleSheet.create({
        ...field,
        common: {
            ...field.common,
            height: '18mm'
        }
    }),

    telField =  StyleSheet.create({
        ...fieldSmall,
        common: {
            ...fieldSmall.common,
            height: '6mm'
        },
        value: {
            ...fieldSmall.value,
            fontSize: fontSize.extraSmall     
        }
    }),

    paragraphRegular = StyleSheet.create({
        fontSize: fontSize.regular, 
        marginTop: '2mm'
    }),

    signatureField = StyleSheet.create({
        common: {
            borderBottom: '0.5px solid black',
            height: '7mm',
            paddingLeft: '1mm',
            paddingRight: '1mm',
            width: '100%'
        },
        label: {
            fontSize: fontSize.small,
            paddingTop: 1,
            paddingBottom: 1
        }
    })
