export const tableConfig = {

    storeItemsPerPage: (tableName, value) => {
        const tablesSettingsAsString = localStorage?.getItem(
            'tablesSettings'
        ) || '{}'

        const tablesSettings = JSON.parse(
            tablesSettingsAsString
        )

        const newTableStorageConfig =  {
            ...tablesSettings,
            [tableName]: {
                ...tablesSettings[tableName],
                itemsPerPage: value
            }
        }

        localStorage.setItem(
            'tablesSettings',
            JSON.stringify(newTableStorageConfig)
        )
    },


    getItemsPerPage: (
        tableName, 
        defaultValue = 10
    ) => {
        const tablesSettingsAsString = localStorage?.getItem(
            'tablesSettings'
        ) || '{}'
        const tablesSettings = JSON.parse(
            tablesSettingsAsString
        )
        return parseInt(tablesSettings[tableName]?.itemsPerPage) || defaultValue
    },
    
}