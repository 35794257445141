import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogDeleteTaskWorkflowPanelSaga(action) {
    const { taskId } = action.payload

    yield put(showWaitingScreen('Deleting task'))
    yield resourceCall(
        api.gateway.tasks.deleteTask, 
        {
            taskId
        }
    )
    yield put(showToast(action.payload.successMsg))
    yield put(hideWaitingScreen())
}