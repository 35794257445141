import React, {memo} from 'react'
import styled, { withTheme } from 'styled-components'
import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd
} from 'react-flow-renderer'
import { buildElements } from '../logic'

const CustomButton = styled.button`
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid black;
    background: white;
    justify-content: center;
    text-align:center;
    align-items: end;
    cursor: pointer;
    padding: 0;
`

export const RemovableEdge = withTheme(memo(({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {
        strokeWidth: 3
    },
    arrowHeadType,
    markerEndId,
    onButtonClick, 
    source, 
    target,
    selected,
    groups,
    branches,
    roles,
    theme,
    data
}) => {
    const foreignObjectSize = 18
    const {isDisabled, isStaticEdge} = data

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })

    const computedStyle = {
        stroke: selected ? '#404040' : isDisabled ? '#fafafa' : '#ededed',
        pointerEvents: 'none',
        ...style
    }
    const elements = buildElements(roles, groups, branches, theme)

    return <React.Fragment>
        <path
            id={id}
            style={computedStyle}
            className="react-flow__edge-path"
            d={edgePath}
            markerEnd={markerEnd}
        />
        {
            selected && <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                {
                    !isStaticEdge && <CustomButton
                        className="edgebutton"
                        onClick={() => onButtonClick({source, target, elements})}
                    >
                x
                    </CustomButton>
                }
            </foreignObject>
        }
    </React.Fragment>
}))