import React from 'react'
import { EditTaskWorkflowPanelComponent } from './component'



export const EditTaskWorkflowPanelDataMapper = (props) => {
    const { dialog, onSaveTask } = props
    const { task } = dialog.payload

    //import
    const formData = {
        title: task.title,
        deadline: task.deadline,
        description: task.description,
        priority: task.priority
    }

    //export
    const handleOnSave = (dialogName, sharedResourceId, task, values) => {
        if (task.bearbeiter !== undefined) {
            onSaveTask(dialogName, sharedResourceId, {
                ...task,
                ...values,
                bearbeiterId: task.bearbeiter.id
            }, task)
        } else {
            onSaveTask(dialogName, sharedResourceId, {
                ...task,
                ...values
            }, task)
        }
    }

    return <EditTaskWorkflowPanelComponent 
        {...props} 
        formData={formData} 
        onSaveTask={handleOnSave} 
    />
}