import styled from 'styled-components'

export const AnnouncementBubbleHeadline = styled.h3`
    margin: 0 0 16px;
    font-size: ${props => props.theme.fontSize.medium};
    color: inherit;
`

export const AnnouncementBubbleContent = styled.p`
    font-size: ${props => props.theme.fontSize.small};
    color: inherit;
    margin-bottom: 16px;
    line-height: ${props => props.theme.fontSize.medium};
    white-space: pre-line;
`

export const AnnouncementBubbleAction = styled.div`
    color: inherit;
    font-size: ${props => props.theme.fontSize.medium};
    margin-bottom: 16px;
`