import React from 'react'
import styled from 'styled-components'
import { BreakLine32 } from 'application/components/pages/external/welcome/fragments'
import { NavLink } from 'react-router-dom'
import { IMAGES, ROUTES } from 'application/constants'
import {Image} from 'application/components/controls/image'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'
import { TextNavLink } from 'application/components/fragments/typography'
import { GlobalStyle } from 'application/style'
import { stringIsFilled } from 'application/common/data_helpers'
import PropTypes from 'prop-types'
import { PushBottom16 } from '../../_common'


const Wrapper = styled.div`
    padding: 16px;
`

const Information = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: ${props=>props.theme.fontSize.medium};
    font-weight: ${props=>props.theme.fontWeight.medium18};
`

const ImageLogo = styled(Image)`
    width: 80px;
    text-align: center;
`

const Icon = styled.div`
    margin-top: 100px;
    & i {
        font-size: 80px;
    }
    margin-bottom: 16px;
`




const ErrorPageBase = (
    {
        mainLogo = IMAGES.LOGO_192,
        mainLogoURL = ROUTES.PREFERRED_ACCOUNT_PRELOADER,                       
        errorIconKey = 'not_interested',
        linkURL = ROUTES.PREFERRED_ACCOUNT_PRELOADER,
        linkText = 'Back to Dashboard',
        errorMessage = 'Something went wrong'
    }
) => {
    const {t} = useTranslation()

    return <FlexBox flexDirection='column'>
        <GlobalStyle />
        <Wrapper>
            <FlexBox justifyContent='center'>
                {
                    stringIsFilled(mainLogoURL)
                        ? <NavLink to={mainLogoURL}>
                            <ImageLogo data={mainLogo} />
                        </NavLink>        
                        : <ImageLogo data={mainLogo} />
                }
            </FlexBox>
            <BreakLine32 />
            <FlexBox justifyContent='center'>
                <Icon>
                    <i className="material-icons">{errorIconKey}</i>
                </Icon>
            </FlexBox>
            <Information>
                {t(errorMessage)}
            </Information>
            <PushBottom16/>
            {
                stringIsFilled(linkURL) && <FlexBox justifyContent='center'>
                    <TextNavLink to={linkURL} >
                    &raquo; {t(linkText)}
                    </TextNavLink>
                </FlexBox>
            }
        </Wrapper>
    </FlexBox>
}

ErrorPageBase.propTypes = {
    mainLogo: PropTypes.string,
    mainLogoUrl: PropTypes.string,
    errorIconKey: PropTypes.string,
    errorMessage: PropTypes.string,
    linkURL: PropTypes.string,
    linkText: PropTypes.string
}


export {ErrorPageBase}