import { isDefined } from 'application/common/data_helpers'
import React, { useEffect } from 'react'

import {AccordionMenuComponent} from './component'

export const AccordionMenuEffects = (props) => {

    const {  
        onRegisterAccordeonMenuState,
        ...rest 
    } = props

    const name = props.menuName 
    const accordionOpen = props.accordionOpen
    const accordionMenuState = props.accordionMenuState
    const isLoading = props.isLoading
    const onOpenOnlyCurrentAccordeon = props.onOpenOnlyCurrentAccordeon

    useEffect(() => {
        !isDefined(accordionMenuState) && !isLoading && onRegisterAccordeonMenuState(
            name,
            accordionOpen
        )
    }, [accordionMenuState, name, onRegisterAccordeonMenuState, accordionOpen, isLoading])

    useEffect(()=>{
        if(accordionOpen){
            onOpenOnlyCurrentAccordeon(name)
        }
    }, [accordionOpen, name, onOpenOnlyCurrentAccordeon])

    return isDefined(accordionMenuState) 
        ?  <AccordionMenuComponent {...rest} />
        : <React.Fragment/>

}