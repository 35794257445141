import { STORAGE } from 'application/constants'

export const currentBranch = {
    store: (accountId, branchId) => {
        const oldStorageEntry = localStorage.getItem(STORAGE.CURRENT_BRANCH)
        localStorage.removeItem(STORAGE.CURRENT_BRANCH)

        const newStorageEntry = {
            currentBranches: oldStorageEntry === null
                ? [{
                    accountId,
                    branchId
                }]
                : [...(JSON.parse(oldStorageEntry)).currentBranches.filter(p => p.accountId !== accountId), {
                    accountId,
                    branchId
                }]
        } 
        
        localStorage.setItem(STORAGE.CURRENT_BRANCH, JSON.stringify(newStorageEntry))
    },
    get: (accountId) => {
        const storageEntry = localStorage.getItem(STORAGE.CURRENT_BRANCH)
        if(storageEntry === null){
            return ''
        }
        const parsedStorageEntryBranchIds = (JSON.parse(storageEntry)).currentBranches
        const currentBranchEntry = parsedStorageEntryBranchIds.find(p => p.accountId === accountId)

        if(currentBranchEntry === undefined){
            return ''
        }

        return currentBranchEntry.branchId
    },
    delete: (accountId) => {
        const oldStorageEntry = localStorage.getItem(STORAGE.CURRENT_BRANCH)
        if(oldStorageEntry !== null){
            const newStorageEntry = {
                currentBranches: (JSON.parse(oldStorageEntry)).currentBranches.filter(p => p.accountId !== accountId)
            }

            localStorage.setItem(STORAGE.CURRENT_BRANCH, JSON.stringify(newStorageEntry))   
        }
    },
    deleteStorage: () => {
        localStorage.removeItem(STORAGE.CURRENT_BRANCH)
    }
}