import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WIDGET_CONTEXTS } from 'application/constants'
import {ActionButtonBar} from 'application/components/controls/action_button_bar'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import {WidgetBoard} from 'application/components/building_blocks/widget_board'
import { UI_SETTINGS } from 'application/constants/ui_settings'



export const DashboardContentComponent = ({
    onSetStaticMode,
    onAddWidget,
    isStatic,
}) => {
    const {t} = useTranslation()
    return <React.Fragment>
        {
            !isStatic && <ActionButtonBar inline={true} buttonsAlign='flex-end'>
                <IconButtonWithLabel 
                    onButtonClick={()=>{onAddWidget(WIDGET_CONTEXTS.DASHBOARD)}}
                    iconKey='add'
                    label={t('add repair authorization widget')}
                />
                <IconButtonWithLabel 
                    onButtonClick={()=> onSetStaticMode(WIDGET_CONTEXTS.DASHBOARD, !isStatic)} 
                    iconKey={isStatic ? 'edit' : 'check'} 
                    label={isStatic ? t('Edit widgets') : t('Done')}
                />
            </ActionButtonBar>
        }
        <WidgetBoard 
            widgetContext={WIDGET_CONTEXTS.DASHBOARD} 
            hasUiSetting={true} 
            settingKey={UI_SETTINGS.USER.GENERIC_DASHBOARD_CONFIG}
        />
    </React.Fragment>
}

DashboardContentComponent.propTypes = {
    onSetStaticMode: PropTypes.func.isRequired,
    onAddWidget: PropTypes.func.isRequired,
    isStatic: PropTypes.bool.isRequired,
}