import {Image} from 'application/components/controls/image'
import { IMAGES } from 'application/constants'
import React from 'react'
import { useAccountLogoLoader } from 'application/components/hooks/account_logo/use_account_logo_loader'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'


export const TitlebarAccountLogo = (
    {
        accountId = null,
        cssMinHeight = '0',
        cssHeight = '32px',
        useEsyLogoPlaceholder = true
    }
) => {
    const {
        accountLogoNotFound,
        accountLogoFound,
        filePath
    } = useAccountLogoLoader(
        accountId
    )

    return <FlexBox cssRules='min-width: 50px;'>
        {       
            accountLogoFound && <Image 
                data={
                    {
                        src: filePath,
                        alt: 'AccountLogo',
                        height: cssHeight
                    }   
                } 
                cssMinHeight={cssMinHeight}
            />
        }
        {
            useEsyLogoPlaceholder
            &&  accountLogoNotFound
            &&  <Image 
                className='jest-account-logo-placeholder'
                data={IMAGES.LOGO_40}
                cssMinHeight={cssMinHeight}
            />
        }
    </FlexBox>
}

TitlebarAccountLogo.propTypes = {
    accountId: PropTypes.string,
    cssMinHeight: PropTypes.string,
    cssHeight: PropTypes.string,
    useEsyLogoPlaceholder: PropTypes.bool
}
