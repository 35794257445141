import { connect } from 'react-redux'

import {HighlightBarComponent} from './component'

const mapStateToProps = (state, props) => {
    return {
        currentUser: state.auth.user
    }
}

export const  HighlightBar =  connect(
    mapStateToProps
)(HighlightBarComponent)