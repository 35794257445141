export const TASK_STATES = {
    TO_DO: 'ToDo',
    TO_REVIEW: 'ToReview',
    DONE: 'Done'
}

export const TASK_PRIORITIES = {
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High'
}
