import React, { useState }from 'react'
import { useTranslation } from 'react-i18next'
import { Footer } from 'application/components/dialogs/_common/footer'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { RadioCheckBoxGroupWithFreetextField } from 'application/components/controls/form'
import { PushBottom24 } from 'application/components/pages/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'


const ChangeBranchDialog = (
    { 
        abort,  
        dialog,
        setAbortBlocked, 
        setDataChanged, 
        onSubmitBranch,
        currentBranch,
        accessibleBranches,
        accessibleBranchesLoaded,
        match
    }
) => {
    const { t } = useTranslation()
    const applicationLinksHook = useApplicationLinks()
    const currentBranchId =  currentBranch?.id || null
    const [selectedBranchId, setSelectedBranchId] = useState(currentBranchId)


    if(!accessibleBranchesLoaded){
        return <ThemedPreloader />
    }

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    
    const accessibleBranchOptions = accessibleBranches.map(
        (branch) => {
            return (branch.id === currentBranchId) 
                ? {
                    label: branch.name,
                    value: branch.id,
                    activeLabel: true
                }
                : {
                    label: branch.name,
                    value: branch.id,
                    activeLabel: false
                }  
        }
    )

    const defaultBranchOption = accessibleBranchOptions.find(
        option => option.value === currentBranchId
    )

    const newBranchIsSelected = currentBranchId !== selectedBranchId


    const handleSubmitBranch = () => {
        const branchToSubmit = accessibleBranches.find(
            branch => branch.id === selectedBranchId
        )

        const payload = {
            branch: branchToSubmit,
            successMsg: branchToSubmit.name
        }

        const metaData = {
            applicationLinksHook,
            match
        }

        if (newBranchIsSelected) {
            onSubmitBranch(
                dialog.name,
                payload,
                metaData
            )
        }
    }

    return <React.Fragment>
        <DialogMainContent
            cssHeight='500px'
        >
            <SubHeadline>
                {t('Please select one branch') + ':'}
            </SubHeadline>
            <PushBottom24/>
            <RadioCheckBoxGroupWithFreetextField
                options = {accessibleBranchOptions}
                onDataChange ={onDataChange}
                defaultSelectedItem={defaultBranchOption}
                onChange = { value => setSelectedBranchId(value) }
            />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmitBranch}
            disabled={!newBranchIsSelected}
            submitText='Confirm'
        />
    </React.Fragment>
}

export const ChangeBranchDialogComponent =  withDialogDataLoader(
    withRouter(ChangeBranchDialog)
)