import { put } from 'redux-saga/effects'

import { getSelectedTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { notNull } from 'application/common/data_helpers'

export function* refreshDamageManagerSelectedTaskSaga(action) {
    const { 
        damageId,
        taskId = null 
    } = action.metaData

    yield notNull(taskId) && put(getSelectedTask(taskId, damageId))
}