import React from 'react'
import { useTranslation } from 'react-i18next'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { GlobalStyle } from 'application/style'
import { FlexBox } from 'application/components/fragments/flex_box'


export const PagePreloader =  ({text = 'User logging in'}) => {
    const {t} = useTranslation()
    return <FlexBox
        justifyContent='center' 
        flexDirection='column'
        height='100vh'
    >
        <GlobalStyle />
        <ThemedPreloader preloaderText={t(text)} />
    </FlexBox>
}