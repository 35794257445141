import { ACTIONS } from 'application/constants'

export const populateUserData = (userData) => ({
    type: ACTIONS.AUTH_POPULATE_USER_DATA,
    userData
})

export const getUserData = () => ({
    type: ACTIONS.AUTH_GET_USER_DATA_REQUEST
})

export const resetCurrentUser = () => ({
    type: ACTIONS.AUTH_RESET_CURRENT_USER
}) 