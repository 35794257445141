import { composeFilterQuery } from 'application/common/filter_helpers'
import { getCustomersForDialogRequestDebounced } from 'application/redux/actions/common'
import { connect } from 'react-redux'

import {CustomerSearchComponent} from './component'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const getFilter = filterKey => {
    switch (filterKey) {
    case 'lastName':
        return filters.customers.findPrivateLastName
    case 'companyName':
        return filters.customers.findCompany
    case 'licensePlate':
        return filters.customers.preferredVehicleLicensePlate
    case 'chassisNumber':
        return filters.customers.preferredVehicleChassisNumber
    default:
        return filters.customers.findPrivateLastName
    }
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSearchCustomer: (dialogName, search, filterKey) => {
            const filter = getFilter(filterKey)

            dispatch(
                getCustomersForDialogRequestDebounced(
                    dialogName, 
                    0, 
                    10, 
                    'lastname',
                    'desc',
                    composeFilterQuery(
                        [new ListFilter(filter).setValue(search)]
                    )
                )
            )
        }
    }
}

export const CustomerSearch =  connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerSearchComponent)