import React from 'react'
import { Table } from 'application/components/tables/refactored/_common'
import { Table as LegacyTable } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import { withRouter } from 'application/components/higher_order_components/with_router'
import { useTableLoader } from 'application/components/hooks'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { MASS_OPERATIONS } from 'application/constants'


const TableMyDamagesOverview = (
    {
        damagesListLoading,
        damagesListLoaded,
        damagesListTotal,
        damagesList,
        onLoadDamages,
        updateConstraint = null,
        match,
        currentUserId,
        //mo
        moAddSelectedItem = () => { },
        moRemoveSelectedItem = () => { },
        moIsActive = false,
        moSelectedItems = []
    }
) => {

    useTableLoader(
        onLoadDamages,
        match,
        configuration,
        updateConstraint,
        {
            userId: currentUserId
        }
    )

    const {
        getSetting
    } = useUiSettings()

    const useLegacyDamageTable = getSetting(UI_SETTINGS.USER.USE_LEGACY_DAMAGE_TABLES)
    const TableComponent = useLegacyDamageTable ? LegacyTable : Table

    return <TableComponent
        configuration={configuration}
        elementsLoading={damagesListLoading}
        elementsTotal={damagesListTotal}
        elementsLoaded={damagesListLoaded}
        elements={damagesList}
        emptyMessage='No damages existing yet'
        //mo
        moAddSelectedItem={moAddSelectedItem}
        moRemoveSelectedItem={moRemoveSelectedItem}
        moIsActive={moIsActive}
        moSelectedItems={moSelectedItems}
        //legacy table properties
        selectItemByMOName={(name, el) => moAddSelectedItem(el)}
        deselectItemByMOName={(name, el) => moRemoveSelectedItem(el)}
        moName={MASS_OPERATIONS.NAME.DAMAGES_OVERVIEW_MY_DAMAGES}
    />
}

export const TableMyDamagesOverviewComponent = withRouter(TableMyDamagesOverview)