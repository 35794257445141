import { Headline3 } from 'application/components/fragments/typography'
import styled from 'styled-components'
import { Paragraph } from 'application/components/fragments/typography'


export const ChangelogList = styled.ul`
    padding: 0;
    list-style-type: none;
    list-style-position : outside;
    margin: 8px 0 0;
    margin-top: 20px;
    margin-bottom: 40px;
`

export const ChangelogListItem = styled.li`
    font-size: 14px;
    margin-bottom: 8px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &:before {
         content: "\\2022"; 
         color: ${
    props => props.cssColor !== undefined 
        ? props.cssColor 
        : props.theme.color.primary
};
         font-weight: bold;
         display: inline-block; 
         flex: 0 0 12px;
     }
`

export const AnnouncementListItem = styled.li`
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

export const AnnouncementListItemTitle = styled.span`
     font-weight: bold;
     margin-bottom: 8px;

     
    &:before {
        content: "\\2022"; 
        color: ${props => props.theme.color.primary};
        font-weight: bold;
        display: inline-block; 
        flex: 0 0 12px;
        margin-right: 8px;
    }
`

export const AnnouncementParagraph = styled(Paragraph)`
    white-space:pre-line;
`


export const ListItemHeadline = styled(Headline3)`
     margin-top: 0;
     justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
`

export const ChangelogItemType = styled.strong`
    flex: 0 0 110px;
`

export const ChangelogTag = styled.span`
    font-size: ${props => props.theme.fontSize.small};
    background-color: ${
    props => props.hotfix 
        ? props.theme.color.red 
        : props.version === 'true'
            ? props.theme.color.anthracite 
            : props.theme.color.primary
};
    color: ${props => props.theme.color.white};
    padding: 4px 6px;
    border-radius: 2px;
    position: relative;
    top: -4px;
    margin-right: ${
    props => props.version === 'true' 
        ? '10px' 
        : '4px'
};
`
