import { api } from 'application/api'
import { timerangeConverter } from 'application/common/date_time_helpers'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { ListFilter, common, controlling } from 'application/filters'
import { updateWidget, updateWidgetLoadedState } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { getAccessibleBranches } from 'application/redux/selectors'
import { all, call, put, select, } from 'redux-saga/effects'

export function* widgetControllingGlobalVacantPositionsSaga(action){

    const {
        accessibleBranches
    } = yield select(getAccessibleBranches)

    let additionalFilters = []

    const compareBranchesFilter = controlling.compareBranches

    const hasCompareBranchesFilter = action.urlParams.find(p => p.key === compareBranchesFilter.filterKey)

    const branchIds = !hasCompareBranchesFilter || action.urlParams.find(p => p.key === compareBranchesFilter.filterKey).value === 'All'
        ? accessibleBranches.map(ab => ab.id)
        : new ListFilter(compareBranchesFilter).setValue(action.urlParams.find(p => p.key === compareBranchesFilter.filterKey).value).getQuery()

    if(action.urlParams.find(param => param.key === controlling.globalPositionsTimeRange.filterKey)){
        const filterRawValue = action.urlParams.find(param => param.key === controlling.globalPositionsTimeRange.filterKey).value
        const timeRange = timerangeConverter.getDateTimesFromRange(filterRawValue)
        additionalFilters.push(new ListFilter(controlling.globalPositionsTimeRange).setValue(timeRange))
    }

    if(action.urlParams.find(param => param.key === controlling.damageType.filterKey)){
        const value = action.urlParams.find(param => param.key === controlling.damageType.filterKey).value
        additionalFilters.push(new ListFilter(controlling.damageType).setValue(value))
    }

    if(action.urlParams.find(param => param.key === controlling.customState.filterKey)){
        const value = action.urlParams.find(param => param.key === controlling.customState.filterKey).value
        additionalFilters.push(new ListFilter(controlling.customState).setValue(value))
    }else if(action.urlParams.find(param => param.key === controlling.state.filterKey)){
        const value = action.urlParams.find(param => param.key === controlling.state.filterKey).value
        additionalFilters.push(new ListFilter(controlling.state).setValue(value))
    }else {
        additionalFilters.push(new ListFilter(controlling.state).setValue(controlling.state.defaultValue))
    }

    if(action.urlParams.find(param => param.key === controlling.invoiceType.filterKey)){
        const value = action.urlParams.find(param => param.key === controlling.invoiceType.filterKey).value
        additionalFilters.push(new ListFilter(controlling.invoiceType).setValue(value))
    }

    let chartData = branchIds.map(branchId => {
        const branch = accessibleBranches.find(b => b.id === branchId)
        return ({
            branch,
            data: {
                paidTotal: 0,
                unpaidTotal: 0,
                grossTotal: 0
            },
            dataLoaded: false
        })
    }).sort((a,b) => a.branch.id - b.branch.id)

    let barsLoaded = 0

    yield put(updateWidget(action.key, action.dependentWidgetKeys, {chartData, totalBars: branchIds.length, barsLoaded: 0, allBarsLoaded: false}))
    yield put(updateWidgetLoadedState(action.key, true, false))


    const requests = branchIds.map(
        branchId => function* () {        
            const response = yield resourceCall(
                api.gateway.controlling.getVacantPositions,
                {
                    filterQuery: composeFilterQuery([
                        new ListFilter(common.branchId).setValue(branchId),
                        ...additionalFilters
                    ])
                }
            )

            barsLoaded += 1
             
            chartData = chartData.map(dataset => {
                if(dataset.branch.id !== branchId){
                    return dataset
                }

                return {
                    ...dataset,
                    data: response,
                    dataLoaded: true
                }
            })
            yield put(updateWidget(action.key, action.dependentWidgetKeys, {chartData, totalBars: branchIds.length, barsLoaded, allBarsLoaded: barsLoaded === branchIds.length}))


        }
    )

    yield all(requests.map(call))

    
}