import { ACTIONS } from 'application/constants'

export const updateInvoices = (invoiceId, invoices) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_UPDATE_INVOICES,
    invoiceId,
    invoices
})
export const refreshInvoices = () => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_REFRESH_INVOICES,
})

//categories
export const getCategoriesWorkflowPanelRequest = (sharedResourceId) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_CATEGORIES_REQUEST,
    sharedResourceId
})

export const populateCategoriesWorkflowPanel = (categories) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_CATEGORIES,
    categories
})

export const getDamageDataWorkflowPanelRequest = () => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_DAMAGE_DATA_REQUEST
})

export const getLawfirmWorkflowPanelRequest = () => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_LAWFIRM_REQUEST
})

export const populateDamageDataWorkflowPanel = (damage) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_DAMAGE_DATA,
    damage
})

export const populateAccountDataWorkflowPanel = (account, branch) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_ACCOUNT_DATA,
    account, branch
})


export const populateLawfirmWorkflowPanel = (lawfirm) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_LAWFIRM,
    lawfirm
})

