import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetCustomerSaga(action) {

    const { 
        dialogName, 
        metaData
    } = action
    
    const { 
        customerId
    } = metaData
    
    const customer = yield resourceCall(
        api.gateway.customer.getCustomerById, 
        { customerId }
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                customer 
            }
        )
    )
}