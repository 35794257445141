import { connect } from 'react-redux'
import { DIALOG } from 'application/constants'
import { downloadAttachment } from 'application/redux/actions/attachments'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { showGallery } from 'application/redux/actions/gallery'
import {DocumentSidebarComponent} from './component'
import { updateDocumentCenterAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { requestUpdateDocument } from 'application/redux/actions/pages/damage_manager/shared'
import { documentsByCategoriesUpdateDocument } from 'application/redux/actions/documents_by_categories'


const mapStateToProps = (state) => ({
    hasLawfirm: state.pages.damageManager.shared.hasLawfirm
})


const mapDispatchToProps = (dispatch, props) => {
    return {

        downloadAttachment: (
            attachmentId, 
            attachmentName, 
            damageId,
        ) => dispatch(
            downloadAttachment(
                attachmentId, 
                attachmentName, 
                damageId
            )
        ),

        deleteAttachment: (
            attachmentId, 
            attachmentName, 
            damageId, 
            taskId = null, 
            match,
            categoryId
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_ATTACHMENT,
                true, 
                {
                    attachmentId, 
                    attachmentName, 
                    damageId, 
                    taskId, 
                    match,
                    categoryId
                }, 
                'Delete document'
            )
        ),
        
        showGallery: (
            damageId, 
            attachmentId, 
            attachmentName, 
            taskId, 
            match
        ) => dispatch(
            showGallery(
                damageId,
                attachmentId, 
                attachmentName, 
                taskId, 
                match
            )
        ),

        onOpenExportToLawfirmConfirmationDialog: (
            name,
            initiallyLoaded, 
            payload,
            title
        ) => {
            dispatch(
                openDialogByName(
                    name,
                    initiallyLoaded, 
                    payload,
                    title
                )
            )
        },
        
        onMarkExportToLawfirm:             (
            damageId, 
            attachmentId, 
            isExportedToLawFirm,
            categoryId
        ) => {
            dispatch(
                updateDocumentCenterAttachmentDetails(
                    {isExportedToLawFirm}
                )
            )
            dispatch(
                documentsByCategoriesUpdateDocument(
                    categoryId,
                    attachmentId,
                    { isExportedToLawFirm }
                )
            )
            dispatch(
                requestUpdateDocument(
                    damageId, 
                    attachmentId, 
                    {
                        isExportedToLawFirm
                    }
                )
            )
        }
    }
}

export const DocumentSidebar =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentSidebarComponent)