import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { downLoadQRCode } from 'application/redux/actions/qr_code_download'

export const useQrCode = (
    url,
    fileName = 'qr_code.png',
    htmlId = 'qrCode',
    level = 'M',
    showClearTextUrl = true,
    showImage = true,
) => {
    const dispatch = useDispatch()

    const getQrCodeObjectUrl = useCallback(() => {
        return document.getElementById(htmlId)
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
    }, [htmlId])
    
    const onDownloadQrCode = useCallback(() => {
        dispatch(            
            downLoadQRCode(
                getQrCodeObjectUrl(),
                fileName
            )
        )
    }, [dispatch, fileName, getQrCodeObjectUrl])

    return {
        qrCodeProps: {
            url,
            htmlId,
            level,
            showClearTextUrl,
            showImage
        },
        getQrCodeObjectUrl,
        onDownloadQrCode
    }
}