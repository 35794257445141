import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'


export function* dialogFetchDamagesByCustomerSaga(action) {
    const {
        customerId 
    } = action.metaData

    const filterQuery = new ListFilter(filters.damages.customerId)
        .setValue(customerId).getQuery()
    
    const params = {
        offset: 0, 
        limit: 1, 
        filterQuery
    } 

    const damagesResponse = yield resourceCall(
        api.gateway.damages.getDamages, 
        params
    )
    
    yield put(
        updateDialogDataByName(
            action.dialogName,
            { damagesResponse }
        )
    )
}