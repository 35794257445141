import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateCategories } from 'application/redux/actions/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getCategoriesSaga(action) {
    try {
        const categoriesResponse = yield resourceCall(
            api.gateway.categories.getCategories
        )
        yield put(
            populateCategories(
                categoriesResponse.categories, 
                action.accountId
            )
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getCategoriesWatcher() {
    yield takeLeading(
        ACTIONS.COMMON_GET_CATEGORIES_REQUEST, 
        getCategoriesSaga
    )
}