import React from 'react'
import {DateComponent} from 'application/components/controls/date'
import {Deadline} from 'application/components/controls/deadline'
import TruncateString from 'react-truncate-string'
import { TaskCreator } from 'application/components/tables/common/tasks/_common/task_creator'



const tableName = 'moDialogDelete'

export const tableConfiguration = {
    name: tableName,
    columns: [
        {
            width: '22.5%',
            label: 'Title',
            CellContent: ({element}) => <TruncateString text={element.title} truncateAt={50} />
        },
        {
            width: '15%',
            label: 'Deadline',
            CellContent: ({element}) => <Deadline deadlineFromTask={element.deadline} />
        },
        {
            width: '20%',
            label: 'Creation date',
            CellContent: ({element}) => <DateComponent date={element.createdAt} />
        },
        {
            width: '22.5%',
            label: 'Creator',
            CellContent: ({element}) => <TaskCreator task={element} />
        },
        {
            width: '20%',
            label: 'License plate',
            CellContent: ({element}) => <React.Fragment>
                {element.damage.vehicle.licensePlate}
            </React.Fragment>
            
        }
    ],
}
