
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'

/**
{
    "id": "142423906358132736",
    "branchId": "137937948057272320",
    "entity": {
        "type": "Message",
        "id": "142423906123251712"
    },
    "relatedEntities": [
        {
            "type": "Damage",
            "id": "141848734303518720"
        }
    ],
    "actor": {
        "name": "Schuessler Lukas"
    },
    "occurredAt": "2022-09-09T12:54:23.31858Z",
    "type": "MessageCreatedActivity",
    "data": {
        "id": "142423906123251712",
        "damage": {
            "licensePlate": "TASK-DELETED"
        },
        "subject": "Neuer test"
    }
}
 */

export const MessageCreatedDetails = (
    {
        activity
    }
) => {

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={activity.data.damage.licensePlate} 
        />, 
        <ActivityDetail 
            label='Subject' 
            value={activity.data.subject} 
        />
    </ActivityDetailWrapper>
}

MessageCreatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}