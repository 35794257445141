import { FlexBox } from 'application/components/fragments/flex_box'
import React, { useRef, useState } from 'react'
import { withTheme } from 'styled-components'
import {SearchComboBox} from 'application/components/controls/search_combo_box/component'
import {damages as damageFilters}  from 'application/filters'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { PushBottom24, PushBottom8, Spacer } from 'application/components/pages/_common'
import { ROUTE_PARAMETERS, USER_PERMISSONS } from 'application/constants'
import { TableDamagesSearchFromTitlebar } from 'application/components/tables/refactored/damages/table_damages_search_from_titlebar'
import { ROUTES } from 'application/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from 'application/components/hooks'
import { setQuery } from 'application/components/hooks/use_query'
import { 
    StyledSearchBar, 
    StyledDamageResultsOverlay, 
    TextNavLinkButton, 
    StyledDamageSearch
} from './fragments'
import { SubHeadline } from 'application/components/fragments/dialog'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

 


 

export const DamagesSearch = ({
    damagesList,
    damagesListLoading,
    damagesListLoaded,
    damagesListTotal, 
    searchDamages,
    clearResults,
    loadInitialDamages
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const isDamageOverview = location.pathname === ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES
    const [searchTerm, setSearchTerm] = useState('')
    const [filterKey, setFilterKey] = useState('')
    const [searchActive, setSearchActive] = useState(false)

    const {
        internalAccountLink
    } = useApplicationLinks()

    const usedFilters = [
        damageFilters.vehicleLicensePlate,
        damageFilters.jobNumber,
        damageFilters.claimNumber,
        damageFilters.customerName
    ]

    const handleToggleOverlay = () => {
        clearResults()
        loadInitialDamages()
        setSearchTerm('')
        setSearchActive(false)
        setOpen(!open)
    }

    const damageSearchRef = useRef()
    useClickOutside(
        damageSearchRef, 
        open 
            ? () => handleToggleOverlay() 
            : () => {}
    )

    const handleClick = () => {
        return !open 
            ? handleToggleOverlay()
            : () => {}
    }

    const handleSearch = (param) => {
        setSearchActive(true)
        searchDamages(param)
    }


    const headline = searchActive 
        ? `${t('Results for')} "${searchTerm}":`
        : t('Suggestions') + ':'


    return <StyledDamageSearch 
        ref={damageSearchRef}
        open={open}
    >
        <StyledSearchBar>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.DAMAGE.READ
                ]}
            >
                <FlexBox>
                    <SearchComboBox
                        filters={usedFilters}
                        onSearch = {handleSearch}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        setFilterKey={setFilterKey}
                        onClick={handleClick}
                    />
                </FlexBox>
                <Spacer basis='8px' /> 
            </ForAnyBranchPermissions>
        </StyledSearchBar>
        {
            open &&   <StyledDamageResultsOverlay>
                <PushBottom24 />
                <SubHeadline isFirst={true}>
                    {headline}
                </SubHeadline>
                <TableDamagesSearchFromTitlebar
                    damagesList={damagesList}
                    damagesListLoaded={damagesListLoaded}
                    damagesListLoading={damagesListLoading}
                    damagesListTotal={damagesListTotal}
                />
                {
                    damagesList.length > 0 && <React.Fragment>
                        <PushBottom8 />
                        {
                            searchActive && <TextNavLinkButton
                                onClick={
                                    () => {
                                        setQuery(
                                            navigate, 
                                            location, 
                                            internalAccountLink(
                                                ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES
                                            ), 
                                            [
                                                {
                                                    key: filterKey,
                                                    value: encodeURIComponent(searchTerm)
                                                },
                                                {
                                                    key: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.SEARCH_BY.KEY,
                                                    value: filterKey
                                                }
                                            ], 
                                            isDamageOverview,
                                            usedFilters.map(f => f.filterKey) //remove all old search query keys
                                        )
                                        handleToggleOverlay()
                                    }
                                }
                            >
                        &raquo; {t('Show all') + ' ' + damagesListTotal + ' '  + t('Results')}
                            </TextNavLinkButton>
                        }
                    </React.Fragment>
                }
            </StyledDamageResultsOverlay>
        }       
    </StyledDamageSearch>
}


export const DamageSearchComponent = withTheme( DamagesSearch )