import React from 'react'
import { useTranslation } from 'react-i18next'
import { DisabledText } from 'application/components/fragments/typography'


export const LawFirmName =  (
    { lawFirm = null }
) => {
    const {t} = useTranslation()
    const isKnown = lawFirm !== null

    if(!isKnown){
        return <DisabledText isSelected={false}>
            {t('no commissioning')}
        </DisabledText>
    }

    return <div>{ lawFirm.name }</div>
}