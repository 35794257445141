import React from 'react'

export const AdressLine1 =  (
    {entity}
) => {
    const address = {
        line1: '',
        ...entity.address
    }

    return <React.Fragment>{address.line1}</React.Fragment>
}