import { takeLeading, select, put } from 'redux-saga/effects'

import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { getUserGroupById } from 'application/redux/selectors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* removeGroupToRolesConnectionSaga(action) {
    try {
        const userGroup = yield select(getUserGroupById, action.groupId)
        const roleIds = userGroup.roles.filter(r => r.id !== action.roleId).map(r => r.id)

        yield resourceCall(
            api.gateway.groups.updateConnectedRoles,
            {
                groupId: action.groupId
            },
            {
                roles: roleIds.map(id => ({
                    id
                }))
            }
        )

        yield put(showToast('Connection deleted'))
    } catch (e) {
        yield handleError(e)
    }
}

export function* removeGroupRolesConnectionWatcher() {
    yield takeLeading(
        ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP_ROLE_RELATIONSHIP, 
        removeGroupToRolesConnectionSaga
    )
}