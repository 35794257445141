import { sendBatchImportRequest, batchImportReset } from 'application/redux/actions/pages/customers'
import { connect } from 'react-redux'
import { CustomersImportComponent } from './component'

const mapStateToProps = state => ({
    batchImport: state.pages.customers.batchImport
})

const mapDispatchToProps = (dispatch) => {
    return {
        onSendBatchImportRequest: (customersList) => {
            dispatch(
                sendBatchImportRequest(customersList)
            )
        },
        onResetBatchImport: () => {
            dispatch(
                batchImportReset()
            )
        }
    }
}

export const CustomersImport = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersImportComponent)