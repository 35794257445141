import { ACTIONS } from 'application/constants'

export const documentsByCategoryRegisterResource = (resourceId) => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_REGISTER_RESOURCE_ID,
    resourceId
})

export const registerDocumentsByCategory = (
    categoryId 
) => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_REGISTER_CATEGORY,
    categoryId
})

export const loadDocumentsByCategory  = (
    damageId,
    categoryId,
    offset = 0,
    limit = 10,
    orderBy = 'createdAt',
    addDocumentsToLoadedDocs = false
) => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_LOAD_DOCUMENTS,
    damageId,
    categoryId,
    offset,
    limit,
    orderBy,
    addDocumentsToLoadedDocs
})

export const populateDocumentsByCategory  = (
    categoryId,
    documents,
    total
) => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_POPULATE_DOCUMENTS,
    categoryId,
    documents,
    total
})

export const addDocumentsByCategory = (
    categoryId,
    documents
) => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_ADD_DOCUMENTS,
    categoryId,
    documents
})


export const removeDocumentFromDocumentCenterByCategory = (
    documentId, 
    categoryId
) => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_REMOVE_DOCUMENT,
    documentId,
    categoryId
})

export const resetDocumentsByCategories  = () => ({
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_RESET_DOCUMENTS,
})

export const documentsByCategoriesUpdateDocument = (
    categoryId,
    documentId, 
    document
) => ({ 
    type: ACTIONS.DOCUMENTS_BY_CATEGORIES_UPDATE_DOCUMENT,
    categoryId,
    documentId,
    document
})