import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'
import { EXPERT_TYPE } from 'application/constants'


const mapInspectionInfo = (
    inspectionSite, 
    inspectionDate,
    inspectionInfoName
) => {
    return removePropertiesWithEmptyStrings({
        site: inspectionSite,
        date: inspectionDate,
        name: inspectionInfoName
    })
}

export const commissionOtherExpertDataMapper = (
    email, 
    name, 
    inspectionSite, 
    inspectionDate,
    inspectionInfoName,
    attachmentsResponses = []
) => {
    return {
        'inspectionInfo': mapInspectionInfo(
            inspectionSite, 
            inspectionDate,
            inspectionInfoName
        ),
        'type': EXPERT_TYPE.OTHER, 
        'email': email,
        'name': name,
        'documents': attachmentsResponses.length > 0 
            ? attachmentsResponses.map(
                r => r.id
            )
            : []
    }
}

export const commissionDekraExpertDataMapper = (
    email, 
    name, 
    inspectionSite, 
    inspectionDate,
    inspectionInfoName,
    attachmentsResponses = []
) => {
    return {
        'inspectionInfo': mapInspectionInfo(
            inspectionSite, 
            inspectionDate,
            inspectionInfoName
        ),
        'type': EXPERT_TYPE.OTHER, 
        'email': email,
        'name': name,
        'documents': attachmentsResponses.length > 0 
            ? attachmentsResponses.map(
                r => r.id
            )
            : []
    }
}