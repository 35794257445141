import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const createDamage = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`branches/${params.branchId}/damages`, HTTP_METHOD.POST, payload)
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(`external/qr/${params.sharedResourceId}/dynamicform/branches/${params.branchId}/damages`,
                params.pin,
                HTTP_METHOD.POST,
                payload)
            return apiCall(request)
        },
        qr: (payload) => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/branchDamages`, params.pin, HTTP_METHOD.POST, payload)
            return apiCall(request)
        }
    }
)