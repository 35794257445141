import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'

export const DamageCreatedActivity = ({activity}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Damage has been created')}</Fragments.ActivityTitle>
    </React.Fragment>
}