import { put } from 'redux-saga/effects'
import { hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { requestUpdateDocument } from 'application/redux/actions/pages/damage_manager/shared'
import { updateDocumentCenterAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { notNull } from 'application/common/data_helpers'
import { documentsByCategoriesUpdateDocument } from 'application/redux/actions/documents_by_categories'


export function* dialogExportToLawfirmFromDocumentSidebarSaga(action) {

    const {
        attachment
    } = action.payload

    const isExportedToLawFirm = attachment.isExportedToLawFirm

    const {
        damageId,
        taskId = null,
        attachmentId,
        categoryId
    } = action.metaData

    yield put(
        requestUpdateDocument(
            damageId, 
            attachmentId, 
            {
                isExportedToLawFirm
            }
        )
    )
    //UpdateAttachmentdetails
    yield put(
        updateDocumentCenterAttachmentDetails( attachment)
    )

    yield put(
        documentsByCategoriesUpdateDocument(
            categoryId,
            attachmentId,
            {isExportedToLawFirm}
        )
    )

    
    yield notNull(taskId) && put(
        showAttachmentDetails(
            attachment, 
            damageId, 
            taskId
        )
    )
    yield put(hideWaitingScreen())
}