import React from 'react'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { isDefined } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'


export const VehicleUpdatedInfo = (
    {activityData}
) => {
    const {t} = useTranslation()

    const oldVehicle = activityData.oldVehicle
    const newVehicle = activityData.newVehicle

    if (
        !( isDefined(oldVehicle) && isDefined(newVehicle) ) 
    ) return null

    return <FlexBox justifyContent='flex-start'>
        {t('Fahrzeug')}: {activityData.damage.licensePlate}
    </FlexBox>

}

VehicleUpdatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}