import React from 'react'
import { withTheme } from 'styled-components'
import * as Fragments from '../../fragments'
import {Image} from 'application/components/controls/image'
import { ICONS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'

const IconBarTopInner = ({theme}) => {
    return <React.Fragment>
    
        <Fragments.InfoMainWelcomeWrapper 
            background={theme.color.lightGray}
        >
            <FlexBox
                flexBasis={'40%'}
            >
                <Fragments.TextWrapper>
                    <FlexBox flexDirection='column'>
                        <Fragments.SuperTitle>
                            Schadenabwicklung 4.0
                        </Fragments.SuperTitle>
                        <Fragments.Title>
                        Mit dem  e.sy 360 wertvolle Zeit sparen
                        </Fragments.Title>
                        <Fragments.Description>
                            Eine Online-Plattform für die schnelle Abwicklung von Schäden, 
                            konzipiert aus der Perspektive des Autohauses. 
                            Smarte Einbindung von Dienstleistern und Kunden in den Gesamtprozess inklusive. 
                        </Fragments.Description>
                    </FlexBox>
                </Fragments.TextWrapper>
            </FlexBox>
            <FlexBox
                flexBasis={'60%'}
            >
                <Fragments.IconBarWrapper>
                    <Fragments.IconBar background={theme.color.lightGray}>
                        {/* <Fragments.Row> */}
                        <Fragments.IconWrapper>
                            <Fragments.LandingPageIcon>
                                <Image data={ICONS.DIGI_DAMAGEAKTE} />
                            </Fragments.LandingPageIcon>
                            <Fragments.ImageTitle>
                            Digitale Schadenakte
                            </Fragments.ImageTitle>
                            <Fragments.ImageDescription>
                            Komfortable Fallanlage
                            </Fragments.ImageDescription>
                        </Fragments.IconWrapper>

                        <Fragments.IconWrapper>
                            <Fragments.LandingPageIcon>
                                <Image data={ICONS.DOKUMENTENCENTER} />
                            </Fragments.LandingPageIcon>
                            <Fragments.ImageTitle>
                            Dokumentencenter
                            </Fragments.ImageTitle>
                            <Fragments.ImageDescription>
                            Schneller zentraler Zugriff auf fallbezogene Dokumente
                            </Fragments.ImageDescription>
                        </Fragments.IconWrapper>

                        <Fragments.IconWrapper>
                            <Fragments.LandingPageIcon>
                                <Image data={ICONS.SMARTEKUNDEN} />
                            </Fragments.LandingPageIcon>
                            <Fragments.ImageTitle>
                            Smarte Kunden- und Dienstleistereinbindung
                            </Fragments.ImageTitle>
                            <Fragments.ImageDescription>
                            Einfache Informationsbeschaffung und Beauftragung 
                            </Fragments.ImageDescription>
                        </Fragments.IconWrapper>

                        <Fragments.IconWrapper>
                            <Fragments.LandingPageIcon>
                                <Image data={ICONS.CONTROLLING} />
                            </Fragments.LandingPageIcon>
                            <Fragments.ImageTitle>
                            Controlling-Tool für Geschäftsführung
                            </Fragments.ImageTitle>
                            <Fragments.ImageDescription>
                            Relevante Kennzahlen und Statistiken auf einen Blick 
                            </Fragments.ImageDescription>
                        </Fragments.IconWrapper>

                        <Fragments.IconWrapper>
                            <Fragments.LandingPageIcon>
                                <Image data={ICONS.DIGITALE_PROZESS_UNTERSTUETZUNG} />
                            </Fragments.LandingPageIcon>
                            <Fragments.ImageTitle>
                            Digitale Prozessunterstützung
                            </Fragments.ImageTitle>
                            <Fragments.ImageDescription>
                            Geführte Schadenabwicklung in einer Plattform für alle beteiligten Akteure 
                            </Fragments.ImageDescription>
                        </Fragments.IconWrapper>
                    
                        <Fragments.IconWrapper>
                            <Fragments.LandingPageIcon>
                                <Image data={ICONS.TANSPARENTE_ABWICKLUNG} />
                            </Fragments.LandingPageIcon>
                            <Fragments.ImageTitle>
                            Transparente Abwicklungsregulierung
                            </Fragments.ImageTitle>
                            <Fragments.ImageDescription>
                             Aktueller und einheitlicher Fallstatus für alle involvierte Personen
                            </Fragments.ImageDescription>
                        </Fragments.IconWrapper>                        
                    </Fragments.IconBar>
                </Fragments.IconBarWrapper>

            </FlexBox>
        </Fragments.InfoMainWelcomeWrapper>
    
        
    </React.Fragment>
}

export const IconBarTop = withTheme(IconBarTopInner)