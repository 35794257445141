import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { withCurrentDamage, withCategoriesLoader } from 'application/components/higher_order_components'
import { useStatefulForm } from 'application/components/hooks'
import { ProvidedServicesCheckboxGroup } from './checkbox_groups/provided_services'
import { AccidentReportDescriptionCheckboxGroup } from './checkbox_groups/accident_report_description'
import { TwoColumns } from 'application/components/forms/_common'
import {  pdfBuilders } from 'application/components/dynamic_pdfs'
import { PdfSignature } from 'application/components/building_blocks/pdf_signature'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'
import { PushBottom24 } from 'application/components/pages/_common'
import { ActionButton } from 'application/components/controls/action_button'
import { Spacer } from 'application/components/pages/_common'
import { ACCIDENT_REPORT_DESCRIPTION } from 'application/constants'




const CreateCostCoverage = withCategoriesLoader(withCurrentDamage((
    { 
        abort, 
        dialog, 
        onSave,
        currentDamage,
        categories = [],
        categoriesLoaded,
        damage,
        accessibleBranches
    }
) => {
    const {t} = useTranslation()

    const getCostCoverageFileName = () => {
        return `RKÜ ${damage.vehicle.licensePlate}.pdf`
    }

    const {
        customer,
        victimInsuranceData,
        opponentInsuranceData,
        expertFound,
        victimVehicle
    } = dialog.payload

    const initialAccidentReportDescription = opponentInsuranceData?.accidentReport?.description?.type 
        ? [ opponentInsuranceData.accidentReport.description.type ]
        : []

    const defaultValues = {
        accidentReportDescription: initialAccidentReportDescription,
        providedServices: [],
        accidentReportDescriptionOther: opponentInsuranceData?.accidentReport?.circumstances ||  ''
    }

    const { 
        register, 
        errors, 
        handleSubmit, 
        updateFormState,
        setValue,
        trigger,
        getValues
    } = useStatefulForm({defaultValues})

    const [defaultCategory, setDefaultCategory] = useState(
        categoriesLoaded 
            ? categories.find(c => c.isDefault) 
            : null
    )

    const [files, setFiles] = useState([])
    const [url, setUrl] = useState(null)
    const hasNoFile = files.length === 0

    useEffect(
        () => {
            hasNoFile && setUrl(null)
        },
        [hasNoFile, setUrl]
    )


    const [showUploader, setShowUploader] = useState(false)

    useEffect(()=>{
        if(categoriesLoaded){
            setDefaultCategory( categories.find(c => c.isDefault))
        }
    },[categories, categoriesLoaded])

    if(!categoriesLoaded){
        return null
    }

    const [validatedPDFValues, setValidatedPdfValues] = useState(undefined)
    const [showSignablePdf, setShowSignablePdf] = useState(false)

    const onSubmitSuccess = () => {
        const {
            accidentReportDescription = [],
            providedServices = [],
            accidentReportDescriptionOther = '',
            providedServicesOther
        } = getValues()

        const pdfValues = {
            damage,
            accessibleBranches,
            customer,
            victimInsuranceData,
            opponentInsuranceData,
            expertFound,
            victimVehicle,
            accidentReportDescription,
            providedServices,
            accidentReportDescriptionOther,
            signatures: [],
            providedServicesOther
        }
        setValidatedPdfValues(pdfValues)
        setShowSignablePdf(true)
    }

    const sendPDF = () => {
        onSave(
            dialog.name,
            { 
                pdfUrl: url,
                pdfFileName: getCostCoverageFileName()
            },
            {
                damageId: currentDamage.id,
                categoryId: defaultCategory.id
            }
        )
    }

    return <React.Fragment>
        <DialogMainContent>
            <TwoColumns 
                columnLeft={
                    <React.Fragment>
                        <SubHeadline>
                            {t('Short description of the accident')}
                        </SubHeadline>
                        <AccidentReportDescriptionCheckboxGroup
                            register={register}
                            errors={errors}
                            onChange={updateFormState}
                            setValue={setValue}
                            trigger={trigger}
                            defaultValues={getValues()}
                            initialDescriptionValues={defaultValues.accidentReportDescription}
                            initialDescriptionOther={defaultValues.accidentReportDescriptionOther}
                            limit={ACCIDENT_REPORT_DESCRIPTION.OTHER_LIMIT}
                        />
                    </React.Fragment>
                }
                columnRight={
                    <React.Fragment>
                        <SubHeadline>
                            {t('Repair shop services rendered')}
                        </SubHeadline>
                        <ProvidedServicesCheckboxGroup
                            register={register}
                            errors={errors}
                            onChange={updateFormState}
                            setValue={setValue}
                            trigger={trigger}
                            limit={60}
                        />
                    </React.Fragment>
                }
            />
            {
                showUploader && <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={files}
                    setFiles={setFiles}
                    multiple={false}
                />   
            }
            <PushBottom24 />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={sendPDF}
            disabled={hasNoFile}
            submitText='Save cost coverage'
            additionalButtons={
                <React.Fragment>
                    <ActionButton
                        buttonText={t('Create cost coverage')}
                        onButtonClick = {handleSubmit(onSubmitSuccess)}
                        stretched={false}
                    />
                    <Spacer basis='8px'/>
                </React.Fragment>
            }
        />
        {
            validatedPDFValues
            && showSignablePdf 
            && <PdfSignature 
                pdfBuilder={pdfBuilders.costCoveragePdfBuilder}
                pdfValues={validatedPDFValues}
                pdfFileName={getCostCoverageFileName()}
                onSave={
                    (url, attachment)=>{
                        setFiles([attachment])
                        setUrl(url)
                        setShowUploader(true)
                        setShowSignablePdf(false)
                    }
                }
                abort={()=>{setShowSignablePdf(false)}}
                headline='Create cost coverage'
                saveButtonText='Done'
                signatureFields={
                    [
                        {
                            label: 'Cost coverage',
                            description: ''
                        }
                    ]
                }
            />
        }
    </React.Fragment>
}))

export const CreateCostCoverageComponent = withDialogDataLoader(CreateCostCoverage)