import { ACTIONS } from 'application/constants'

export const getTasksWorkflowPanel = (sharedResourceId) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_REQUEST_TASKS,
    sharedResourceId
})

export const updateTaskInListWorkflowPanel = (taskId, payload) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_UPDATE_TASK_IN_LIST,
    taskId,
    payload
})

export const populateTasksWorkflowPanel = (tasksList) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_TASKS,
    tasksList
})

export const getSelectedTaskWorkflowPanel = (taskId, sharedResourceId) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_REQUEST_SELECTED_TASK,
    taskId,
    sharedResourceId
})

export const populateSelectedTaskWorkflowPanel = (task) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK,
    task
})

export const populateSelectedTaskDocumentsWorkflowPanel = (documents, total) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS,
    documents,
    total
})

export const updateSelectedTaskWorkflowPanel = (task) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_UPDATE_SELECTED_TASK,
    task
})

export const resetSelectedTaskWorkflowPanel = () => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_RESET_SELECTED_TASK
})

export const getUsersForTaskDebouncedWorkflowPanel = (offset = 0, limit = 10, search = '') => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_LOAD_USERLIST_DEBOUNCED,
    offset,
    limit,
    search
})

export const populateUsersForTasksWorkflowPanel = (userList) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_USERLIST,
    userList
})

export const addCommentToTaskInListWorkflowPanel = (taskId) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_ADD_COMMENT_TO_TASK_IN_LIST,
    taskId
})

export const addAttachmentsToTaskInListWorkflowPanel = (taskId) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_ADD_ATTACHMENTS_TO_TASK_IN_LIST,
    taskId
})

export const minusAttachmentsToTaskInListWorkflowPanel = (taskId) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_MINUS_ATTACHMENTS_TO_TASK_IN_LIST,
    taskId
})

export const taskNotFound = () => ({
    type: ACTIONS.WORKFLOW_PANEL_TASK_NOT_FOUND
})

export const showAttachmentDetailsWorkflowPanel = (attachment, damageId, sharedResourceId, taskId) => ({
    type: ACTIONS.WORKFLOW_PANEL_SHOW_ATTACHMENT_DETAILS,
    attachment,
    damageId,
    sharedResourceId,
    taskId
})

export const hideAttachmentDetailsWorkflowPanel = () => ({
    type: ACTIONS.WORKFLOW_PANEL_HIDE_ATTACHMENT_DETAILS
})

export const removeTaskFromListWorkflowPanel = (taskId) => ({
    type: ACTIONS.WORKFLOW_PANEL_TASKS_REMOVE_TASK_FROM_LIST,
    taskId
})