import React from 'react'
import styled from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'


const StyledWidgedHeadline = styled.span`
    font-weight: bold;
    font-size: 14px;
`


const WidgedTableHeadline = (
    {
        message = ''
    }
) => {

    return <FlexBox 
        paddingLeft='8px'
        paddingBottom='8px'
        paddingTop='8px'
    >
        <StyledWidgedHeadline>
            {message}
        </StyledWidgedHeadline>
    </FlexBox>    
}


export {WidgedTableHeadline}