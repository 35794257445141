import React from 'react'

import styled from 'styled-components'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import {FlexBox}  from './flex_box'
import { isDefined } from 'application/common/data_helpers'

export const DialogFooterFixed = styled.div`
    height: auto;
    filter: blur(${props => props.blurred ? '5px' : '0'});
    padding: ${props => props.padding};
    display: block;
    flex-direction: row;
    border-top: 1px solid ${props => props.theme.color.gray15};
    margin-top: auto;
    position: absolute;
    bottom: 0;
    background: ${props => props.theme.color.white};
    width: 100%;
`

export const DialogFooterFlex = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
`




export const DialogFooter = (
    {
        children, 
        padding='16px 16px 16px'
    }
) => {
    return <DialogFooterFixed padding={padding}>
        <DialogFooterFlex>
            {children}
        </DialogFooterFlex>
    </DialogFooterFixed>
}



export const DialogFooterSpacer = styled.div`
    display: flex;
    height: 68px;
    width: 100%;
    margin-bottom: 0px;
`

export const DialogFooterLeft = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`

export const DialogFooterRight = styled.div`
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`

export const DialogAdditionalHeader = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
`

export const DialogAdditionalHeaderItem = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    &:last-child{
        padding-right: 36px;
    }
`

export const DialogMainContent = styled.div`
    flex: 3;
    padding: 0 16px;
    margin-bottom: 68px;
    overflow-y: auto;
    min-height: ${props => isDefined(props.cssHeight) ? props.cssHeight : 'auto'};
    display: flex;
    flex-direction: ${props => isDefined(props.flexDirection) ? props.flexDirection : 'column'};
`
export const DialogMainContentWithSideBar = styled.div`
    flex: 3;
    padding-right: 16px;
    padding-left: 16px;
    overflow-y: auto;
    min-height: 550px;
`

export const DialogMainContentScrollable = styled.div`
    flex: 3;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    overflow-y: auto;
    max-height: 500px;
`

export const SpaceBetween = styled.div`
    margin-bottom: 32px;
`

export const SubHeadline = styled.h3`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    margin: ${props => props.isFirst ? '0' : '8px'} 0 8px;
    display: flex;
    justify-content: space-between;
`

export const DialogSidebarScrollAble = styled.div`
    flex: ${props => props.cssFlex ? props.cssFlex : '1'};
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: stretch;
    max-height: 500px;
    overflow: auto;
    padding: 0 16px 16px;
`

export const DialogSidebar = ({ children, cssWidth = '200px', withLine = true }) => {
    return <React.Fragment>
        {
            withLine && <FlexDividerVertical />
        }
        <FlexBox
            marginLeft='24px'
            marginRight='8px'
            flexShrink='0'
            flexGrow='0'
            alignItems='flex-start'
            flexBasis={cssWidth}
        >
            {children}
        </FlexBox>
    </React.Fragment>
}

