import { put} from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetWorkflowPanelTaskCommentsSaga(action){
    const { taskId } = action.match.params
    const commentsResponse = yield resourceCall(
        api.gateway.comments.getCommentsByTask,
        {
            taskId
        }
    )
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {commentsResponse}))
}