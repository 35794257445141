import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { moClearAllItemsByName } from 'application/redux/actions/mass_operations'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogMoDamagesChangeStateSaga(action) {

    const { 
        selectedItems, 
        stateId 
    } = action.payload

    const { 
        moName
    } = action.metaData  

    yield put(
        showWaitingScreen('Status is changing...')
    )

    for(const damage of selectedItems){
        yield resourceCall(
            api.gateway.damage.updateDamageState, 
            {
                damageId: damage.id
            },
            {
                id: stateId
            }
        )
    }
    
    yield put(
        moClearAllItemsByName(moName)
    )
    yield put( 
        showToast('Status changed') 
    )
    yield put( 
        hideWaitingScreen() 
    )
}