import React from 'react'
import styled, { withTheme } from 'styled-components'
import { exists } from 'application/common/data_helpers'

const MakeModelContainer = styled.div`
    color: ${props => props.theme.color.gray70};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.small};
`

export const MakeModel = withTheme((
    {
        make = null, 
        model = null
    }
) => {
    if(!exists(make) && !exists(model)){
        return null
    }
    return <MakeModelContainer className='makeModelContainer'>
        {make} {model}
    </MakeModelContainer>
})