import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { notNull } from 'application/common/data_helpers'

export function* dialogUploadFilesSaga(action) {
    yield put(showWaitingScreen('Uploading files'))
    
    const {
        taskId = null,
        invoiceId = null
    } = action.metaData
    
    const sendAttachmentToInvoice = notNull(invoiceId)
    const sendAttachmentToTask = notNull(taskId) && !sendAttachmentToInvoice

    const files = action.payload.upload?.files || action.payload.files 
    
    for(const file of files){

        yield put(addNewSubMessage(file.metaData.name + ' ... '))

        let documentResponse        

        if (sendAttachmentToInvoice) {
            documentResponse = yield resourceCall(
                api.gateway.invoices.postAttachmentsForInvoice, 
                {
                    damageId: action.metaData.damageId,
                    invoiceId: action.metaData.invoiceId,
                    categoryId: action.metaData.categoryId
                },
                {
                    file: file.metaData
                }
            )
        } else if(sendAttachmentToTask){
            documentResponse = yield resourceCall(
                api.gateway.attachments.postAttachmentForTask, 
                {
                    damageId: action.metaData.damageId,
                    taskId: action.metaData.taskId,
                    categoryId: action.metaData.categoryId
                },
                {
                    file: file.metaData
                }
            )
        } else {
            documentResponse = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: action.metaData.damageId,
                    categoryId: action.metaData.categoryId
                },
                {
                    file: file.metaData
                }
            )
        }       

        if(file.isExportedToLawFirm){
            yield resourceCall(
                api.gateway.attachments.updateAttachment,
                {
                    damageId: action.metaData.damageId,
                    attachmentId: documentResponse.id
                },
                {
                    isExportedToLawFirm: true
                }
            )
        }
        
        yield put(addToLastSubMessage('✓'))
    }
    yield put(hideWaitingScreen())
    yield put(showToast('Upload complete'))
}