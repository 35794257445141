import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageAssigneeChangedActivity = ({activity}) => {
    const {t} = useTranslation()

    const oldAssigneeName = activity.data?.oldAssignee?.fullName || ''
    const newAssigneeName = activity.data?.newAssignee?.fullName || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Assignee changed')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>{oldAssigneeName}<RightArrow />{newAssigneeName}</Fragments.ActivityParagraph>
    </React.Fragment>
}