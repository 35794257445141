import React from 'react'
import { PushBottom16, SubPageLayout } from 'application/components/pages/_common'
import { ComprehensiveDamageTypesEditor } from 'application/components/building_blocks/comprehensive_damage_types_editor'

export const AdministrationSettingsComprehensiveDamageTypes = () => {
    return <SubPageLayout>
        <PushBottom16 />
        <ComprehensiveDamageTypesEditor />
    </SubPageLayout>
}
