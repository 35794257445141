import { ACTIONS } from 'application/constants'

export const createComment = (
    damageId, 
    taskId, 
    comment
) => ({
    type: ACTIONS.DAMAGE_MANAGER_COMMENT_CREATE,
    damageId,
    taskId,
    comment
})

export const createCommentWorkflowPanel = (
    sharedResourceId, 
    taskId, 
    comment
) => ({
    type: ACTIONS.DAMAGE_MANAGER_COMMENT_CREATE_WORKFLOW_PANEL,
    sharedResourceId,
    taskId,
    comment
})



export const loadMoreComments = (
    widgetKey,
    damageId,
    taskId,
    currentComments = [],
    offset='10',
    limit='10'
) => ({
    type: ACTIONS.DAMAGE_MANAGER_COMMENTS_LOAD_MORE,
    widgetKey,
    damageId,
    taskId,
    currentComments,
    offset,
    limit
})