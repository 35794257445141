import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { common, ListFilter } from 'application/filters'

export function* widgetBranchSpecificControllingVacantPositionsTotalSaga(action){
    const { branchId } = action.match.params

    const filterQuery = new ListFilter(common.branchId).setValue(branchId).getQuery()

    const response = yield resourceCall(
        api.gateway.controlling.getVacantPositions, 
        {
            filterQuery
        }
    )

    yield put(updateWidget(action.key, action.dependentWidgetKeys, {response}))
}