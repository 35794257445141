import React, { useState } from 'react'
import * as Fragments from './fragments'
import { useTranslation } from 'react-i18next'
import { DragAndDropUploader, SimpleUploader } from 'application/components/controls/form/uploaders'
import { Thumbnail, ThumbnailWithLoader } from 'application/components/building_blocks/attachments/thumbnails/base'
import { notNull } from 'application/common/data_helpers'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'




export const TaskUploader = ({
    onFileAdded = () => { },
    onFilesRejected,
    disabled = false,
    onAttachmentClick = () => { },
    selectedThumbnail = null,
    initialAttachments = [], //From Server
    description = 'drag your files here to upload them',
    title = 'Documents',
    label = 'Upload documents',
    labelMore = 'Upload more documents',
    disableActiveState = false,
    damageId = null,
    files = [],  //uploadQueue
    setFiles,
    onDataChange = () => {}, 
    mobileView = false,
    multiple = true,
    allowedFileExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS,
}) => {
    const [fileAddPending, setFileAddPending] = useState(false)
    const { t } = useTranslation()
    
    const handleSetFiles = (filesToPersist) => {
        setFiles(filesToPersist)
        onDataChange()
    }

    const handleAddFiles = (newFiles) => {
        const allFiles = [
            ...files,
            ...newFiles
        ]        
        handleSetFiles(allFiles)
        setFileAddPending(false)
    }
  
    const handleAdded = file => {
        onFileAdded(file)
    }

    const handleRemoveFile = (fileName, id = null) => {
        const remainingFiles = id !== null
            ? files.filter(
                file =>  file.id !== id && file.tempId !== id
            )
            : files.filter(
                file =>  file.attachment.fileName !== fileName
            )
        handleSetFiles(remainingFiles)
    }

    const [selected, setSelected] = useState([])
    return <Fragments.Wrapper>
        {
            fileAddPending && <Fragments.WaitingScreen>
                <ThemedPreloader preloaderText={t('Processing files')}/>
            </Fragments.WaitingScreen>
        }
        <Fragments.TitleBar>
            <Fragments.Title>{t(title)}</Fragments.Title>
            {
                (
                    files.length > 0 
                    || initialAttachments.length > 0 
                ) 
                && multiple
                && <SimpleUploader
                    disabled={disabled}
                    label={t(labelMore)}
                    iconKey='add'
                    onFileAdded={handleAdded}
                    onFilesRejected={onFilesRejected}
                    onFilesAdded={handleAddFiles}
                    onFilesAddStart={() => {setFileAddPending(true)}}
                    allowedFileExtensions={allowedFileExtensions}
                />
            }
        </Fragments.TitleBar>
        <DragAndDropUploader
            disabled={disabled}
            onFileAdded={(file) => { handleAdded(file) }}
            onFilesAdded={handleAddFiles}
            onFilesRejected={onFilesRejected}
            mobileView={mobileView}
            multiple={multiple}
            allowedFileExtensions={allowedFileExtensions}
            onFilesAddStart={() => {setFileAddPending(true)}}
        >
            {
                files.length === 0 && initialAttachments.length === 0
                    ? <SimpleUploader
                        disabled={disabled}
                        label={t(label)}
                        iconKey='add'
                        onFileAdded={handleAdded}
                        onFilesRejected={onFilesRejected}
                        onFilesAdded={handleAddFiles}
                        onFilesAddStart={() => {setFileAddPending(true)}}
                        multiple={multiple}
                        allowedFileExtensions={allowedFileExtensions}
                    />
                    : <React.Fragment>
                        {
                            initialAttachments.map((attachment, fileIndex) => {
                                return <ThumbnailWithLoader
                                    key={fileIndex}
                                    attachmentId={attachment.id}
                                    attachmentName={attachment.name}
                                    damageId={
                                        notNull(damageId) 
                                            ? damageId
                                            : attachment?.damageId
                                    }
                                    taskId={attachment.resourceId}
                                    onAttachmentClick={file => {
                                        setSelected(file)
                                        onAttachmentClick(file)
                                    }}
                                    extendedData={
                                        {
                                            category: attachment.category,
                                            isExportedToLawFirm: attachment.isExportedToLawFirm
                                        }
                                    }
                                    removeFile={handleRemoveFile}
                                    removableFiles={false}
                                    mobileView={mobileView}
                                    id={attachment.id}
                                />
                            })
                        }
                        {
                            files.map((file, fileIndex) => {
                                return <Thumbnail
                                    attachment={file.attachment}
                                    onAttachmentClick={file => {
                                        setSelected(file)
                                        onAttachmentClick(file)
                                    }}
                                    isActive={!disableActiveState 
                                        ? selectedThumbnail === null 
                                            ? selected.id === file.id 
                                            : selectedThumbnail.id === file.id 
                                        : false}
                                    key={fileIndex}
                                    removeFile={handleRemoveFile}
                                    removableFiles={true}
                                    mobileView={mobileView}
                                    id={file.tempId}
                                />
                            })
                        }
                    </React.Fragment>
            }
        </DragAndDropUploader>
        <Fragments.Description>{t(description)}</Fragments.Description>
    </Fragments.Wrapper>
}