import { connect } from 'react-redux'
import  { CategorySelectComponent } from './component'

const mapStateToProps = state => ({
    categories: state.common.categories,
    categoriesLoaded: state.common.categoriesLoaded
})

export const CategorySelect = connect(
    mapStateToProps
)(CategorySelectComponent)