import { connect } from 'react-redux'
import { openDialogByName } from 'application/redux/actions/dialogs'
import {CustomerCardComponent} from './component'
import { DIALOG } from 'application/constants'


const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => ({
    onQRCodeCreation: (
        payload
    ) => dispatch(
        openDialogByName(
            DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE, 
            false, 
            payload, 
            'Create QR code for vehiclekeeper'
        )
    )
})

export const CustomerCard =  connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerCardComponent)