import { connect } from 'react-redux'
import { LawfirmAktenStatusComponent } from './component'
import { updateWorkflowPanelForm } from 'application/redux/actions/common/workflow_panel'


const mapStateToProps = () => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (widgetKey, payload) => dispatch(updateWorkflowPanelForm(widgetKey, payload))
    }
}

export const LawfirmAktenStatus = connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmAktenStatusComponent)