import styled from 'styled-components'

export const _get = (obj, str) => {
    try {
        return str.split('.').reduce((o, i) => o[i], obj)
    } catch (e) {
        return undefined
    }
}

export const ELEMENT_OPTIONS = {
    DATE_PICKER : {
        AFTER_NEW_DATE: 'afterNewDate'
    }
}

export const FORM = {
    TEXT_FIELD: 'TextField',
    TEXT_AREA: 'TextArea',
    AUTO_COMPLETE: 'AutoComplete',
    DATE_PICKER: 'DatePicker',
    GERMAN_DATE_INPUT: 'GermanDateInput',
    PHONE_NUMBER: 'PhoneNumber',
    TOGGLE: 'Toggle',
    SELECT: 'Select',
    SWITCH: 'Switch',
    GROUP: 'Group',
    SUBMIT: 'Submit',
    LABEL: 'Label',
    INSURANCE_FIELDS: 'InsuranceFields',
    YES_NO_SELECT: 'YesNoSelect',
    WHO_IS_AT_FAULT: 'WhoIsAtFault',
    VEHICLE_STATE: 'VehicleState'
}

export const getAddressFormElements = (prefix) => {
    return [
        {
            type: FORM.TEXT_FIELD,
            name: `${prefix}.address.line1`,
            label: 'Street and house number',
            editorOptions: {
                isSelected: false,
                isDisabled: false,
                checkboxes: [
                    {
                        isSelected: false,
                        value: 'required',
                        label: 'Is required'
                    },

                ]
            }
        },
        {
            type: FORM.TEXT_FIELD,
            name: `${prefix}.address.postalCode`,
            label: 'Postal code',
            editorOptions: {
                isSelected: false,
                isDisabled: false,
                checkboxes: [
                    {
                        isSelected: false,
                        value: 'required',
                        label: 'Is required'
                    },

                ]
            }
        },
        {
            type: FORM.TEXT_FIELD,
            name: `${prefix}.address.locality`,
            label: 'City',
            _config: {
                css: {
                    gridColumn: 'span 2'
                }
            },
            editorOptions: {
                isSelected: false,
                isDisabled: false,
                checkboxes: [
                    {
                        isSelected: false,
                        value: 'required',
                        label: 'Is required'
                    },

                ]
            }
        }
    ]
}

export const FlexColumns = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns || 2}, 1fr);
    gap: 16px;
    width: 100%;
    margin: 0;
`