import styled from 'styled-components'

export const StyledMobileTimeline = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 800px;
`

export const StyledMobileTimelineStep = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
`

export const StyledMobileTimelineStepCounter = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 40px;
    align-items: center;
    position: relative;
    padding-top: 8px;

    & span{
        display: flex;
        color: ${props => props.theme.color.white};
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 20px;
        font-weight: bold;
        border: 4px solid ${props => props.theme.color.white};
        background-color: ${props => props.theme.color.primary};
        z-index: 2;
    }

    &:after{
        content: "";
        display: ${props => props.isLast ? 'none' : 'block'};
        height: 100%;
        position: absolute;
        background-color: ${props => props.theme.color.gray15};
        width: 2px;
        z-index: 1;
    }
`

export const StyledMobileTimelineStepContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-left: 16px;
`

export const StyledMobileTimelineStepChildren = styled.main`
    display: flex;
    flex-direction: column;
`

export const StyledMobileTimelineStepLabel = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
`

export const StyledMobileTimelineButtons = styled.nav`
    display: flex; 
    justify-content: flex-end;
    flex-direction: row;
`
