import { useMemo } from 'react'

export const useBranchCreateDamageUrl = (
    proxyShareId
) => {
    const createDamageUrl = useMemo(
        () => {
            return `${window.location.protocol}//${window.location.hostname}/external/kickstart/${proxyShareId}`

        }, [proxyShareId])

    return {
        createDamageUrl
    }
}