import { isNull } from 'application/common/data_helpers'
import { getFirstObjectByOwnPropKey } from 'application/common/list_helpers'
import { MASS_OPERATIONS } from 'application/constants'

const getMoByName = (
    moName,
    registeredMassOperations
) => {
    return getFirstObjectByOwnPropKey(
        registeredMassOperations,
        MASS_OPERATIONS.KEY.NAME,
        moName
    )
}

/**
 * 
 * @param {object} state looks at: state.massOperations.registeredMassOperations
 * @param {string} moName moName to identify MO {string} 
 * @returns {object} success: currentMassOperation as object, fail null
 */

export const fetchMoByName = (
    registeredMassOperations,
    moName = null
) => {
    return isNull(moName)
        ? null
        : getMoByName( 
            moName, 
            registeredMassOperations
        )
}