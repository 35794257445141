import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { Footer } from 'application/components/dialogs/_common/footer'
import { PushBottom32 } from 'application/components/pages/_common'
import { useStatefulForm } from 'application/components/hooks'

export const AddComprehensiveDamageTypeComponent = (
    { 
        abort, 
        dialog, 
        onAddComprehensiveDamageType 
    }
) => {
    const {t} = useTranslation()

    const defaultValues = {
        name: '',
        iconName: 'star'
    }

    const { 
        register, 
        errors, 
        handleSubmit,
        getValues 
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => {
        onAddComprehensiveDamageType(
            dialog.name,
            getValues()
        )
    }
    
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <TextInput
                    label={t('Name')}
                    name='name'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.name}
                    autoFocus={true}
                    onKeyDownEnter={handleSubmit(onSubmitSuccess)}
                />              
            </FlexBox>
            <PushBottom32 />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Add comprehensive damage type'
        />
    </React.Fragment>

}
