import React from 'react'
import { DocumentsLink, RelatedDamageLink } from 'application/components/controls/activity_links/common'
import { USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'

export const DocumentActivityLinks = (
    {
        activity
    }
) => {
    return <ForAnyBranchPermissions 
        permissions={[USER_PERMISSONS.DAMAGE.READ]}
    >
        <RelatedDamageLink activity={activity} />
        <DocumentsLink activity={activity} />
    </ForAnyBranchPermissions>
}