import { openDialogByName, sendPayloadRequest } from 'application/redux/actions/dialogs'


export const mapStateToProps = (state) => ({
    hasLawfirm: state.pages.damageManager.shared.hasLawfirm,
    sharedResourceType: state.sharedResource.resourceType
})

export const mapDispatchToProps = dispatch => ({
    onSubmit: (
        dialogName,
        payload,
        metaData
    ) => dispatch(
        sendPayloadRequest(
            dialogName,
            payload, 
            metaData
        )
    ),
    onOpenExportToLawfirmConfirmationDialog: (
        dialogName,
        name,
        initiallyLoaded, 
        payload,
        title
    ) => dispatch(
        openDialogByName(
            dialogName,
            name,
            initiallyLoaded, 
            payload,
            title
        )
    )
})
