import React from 'react'
import { useTranslation } from 'react-i18next'
import { LIABILITY_INQUIRY_STATUS } from 'application/constants'

export const LiabilityInquiryStatusName = ({ liabilityInquiryStatus }) => {
    const { t } = useTranslation()
    const mapLiabilityRequestTypeName = (type) => {
        const names = new Map([
            [LIABILITY_INQUIRY_STATUS.UNKNOWN, 'Not clear'],
            [LIABILITY_INQUIRY_STATUS.CONFIRMED, 'Approved'],
            [LIABILITY_INQUIRY_STATUS.UNCONFIRMED, 'Not approved'],
        ])
        return names.get(type)
    }

    return <React.Fragment>
        {t(mapLiabilityRequestTypeName(liabilityInquiryStatus))}
    </React.Fragment>
}