import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { getDownloadUrl, downloadFile } from 'application/api/helpers'
import { apiFilestream } from 'application/api/helpers'

export const downloadTaskDocumentsArchive = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/tasks/${params.taskId}/documents/zip`, HTTP_METHOD.GET)
            return apiFilestream(request)
                .then(getDownloadUrl)
                .then(downloadUrl => {
                    downloadFile(downloadUrl, params.damageId)
                })
        },
        sharedResource: () => null
    }
)