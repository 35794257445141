import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetSubHeadline, WidgetErrorMessage, BulletList, BulletListItem, BulletListLabel } from 'application/components/widgets/_common'
import { germanDateIfDateString } from 'application/common/date_time_helpers'



 
const DamageVehicle = ({payload, loadingFailed}) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>{t('vehicle could not be loaded')}</WidgetErrorMessage>
    }
    
    const bulletKeys = Object.keys(payload).filter( 
        key => key !== 'licensePlate' || key !== 'chassisNumber'
    )

    return <React.Fragment>
        <WidgetSubHeadline>{payload.licensePlate}</WidgetSubHeadline>
        <BulletList>
            {
                bulletKeys.map(
                    (key, i) => {
                        const value = germanDateIfDateString(payload[key])
                        return <BulletListItem key={i}>
                            <BulletListLabel cssWidth='76px'>{t(key)}:</BulletListLabel>{t(value)}
                        </BulletListItem>
                    }
                )
            }
        </BulletList>
    </React.Fragment>
}

DamageVehicle.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageVehicle }