import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

const StyledCheckBoxWithLabel = styled.div`
    display: flex;
    flex-direction: row;
    height: ${props => props.small ? '20px' : '35px'};
`

const StyledHiddenCheckBox = styled.input`
    display: none;
`
const StyledCheckbox = styled.div`
    width: ${props => props.small ? '20px' : '35px'};
    height: ${props => props.small ? '20px' : '35px'};
    background: none;
    border: 1px solid ${props => props.isDisabled ? props.theme.color.gray10 : props.theme.color.gray15};
    transition: all 150ms;
    padding: ${props => props.small ? '1px' : '5px'};
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
    background: ${props => props.theme.color.white};
`

const StyledLabel = styled.span`
    font-size: ${props=> props.small ? props.theme.fontSize.small : props.theme.fontSize.standard};
    padding-left: 8px;
    display: flex;
    align-self: center;
    color: ${props => props.theme.color.anthracite};
`

const StyledCheckmark = styled.i`
    color: ${props => props.isDisabled ? props.theme.color.gray20 : props.theme.color.anthracite};
    font-size: ${props=> props.small ? '16px' : '24px'};
`

export const PureCheckBox = React.forwardRef(
    (props, ref) => {
        const {
            checked = false, 
            name='', 
            label='', 
            onChange=()=>{},
            disabled = false,
            small = false
        } = props

        const [isChecked, setIsChecked] = useState(checked)
        useEffect(()=>{
            setIsChecked(checked)
        }, [checked])

        const [isDisabled, setIsDisabled] = useState(disabled)
        useEffect(()=>{
            setIsDisabled(disabled)
        }, [disabled])


        return <StyledCheckBoxWithLabel
            small={small}
        >
            <StyledCheckbox 
                checked={checked} 
                isDisabled={isDisabled}
                small={small}
                onClick={
                    ()=>{
                        if(!isDisabled){
                            onChange(!isChecked)
                            setIsChecked(!isChecked)
                        }
                    }
                }>
                <StyledCheckmark small={small} className="material-icons" isDisabled={isDisabled}>
                    { 
                        isChecked 
                            ? 'done' 
                            : ''
                    }
                </StyledCheckmark>
            </StyledCheckbox>
            <StyledLabel
                small={small}
            >{label}</StyledLabel>
            <StyledHiddenCheckBox 
                type='checkbox' 
                name={name} 
                id={name} 
                defaultChecked={isChecked} 
                ref={ref} 
            />
        </StyledCheckBoxWithLabel>
    })