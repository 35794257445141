import styled from 'styled-components'

export const StyledSetting = styled.div`
    background: ${props =>  props.theme.color.gray5};
    margin-bottom: 12px;
    max-width: 600px;
    padding: 8px;
    
    &:hover{
        background: ${({ collapsed, theme }) => collapsed ? theme.color.gray10 : theme.color.gray5};  
    }

    display:flex;
    flex-direction: column;
`

export const StyledHeadline = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    justify-content: space-between;
`

export const ContentWrapper = styled.div`
    background: ${props => props.theme.color.gray5};
    width: 100%;
    padding-top: 8px;
    padding-left: 0;
`

export const StyledDivider = styled.div`
    height: 8px;
    border-bottom: 1px solid ${props => props.theme.color.gray15};
`
