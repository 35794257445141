
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetailBox, ActivityDetailWrapper, BranchDetail, ActivityDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageAssigneeChangedDetails = (
    {
        activity
    }
) => {
    const {
        oldAssignee = null,
        newAssignee = null,
        damage
    } = activity.data

    const oldAssigneeName = oldAssignee?.fullName
    const newAssigneeName = newAssignee?.fullName

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail label='Licenseplate' value={damage.licensePlate} />
        <ActivityDetailBox>
            {oldAssigneeName}<RightArrow />{newAssigneeName}   
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DamageAssigneeChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}