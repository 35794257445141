import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const lawfirmsReducer =  (state = defaultStore.lawfirms, action) => {
    switch (action.type) {
    case ACTIONS.GET_LAWFIRMS_FOR_ACCOUNT_REQUEST: {
        return {
            ...state,
            loaded: false,
            loading: true,
            accountId: action.accountId
        }
    }

    case ACTIONS.POPULATE_LAWFIRMS_FOR_ACCOUNT: {
        return {
            ...state,
            loaded: true,
            loading: false,
            lawfirmsList: action.lawfirmsList
        }
    }
    
    default:
        return state
    }
}
