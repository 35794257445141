import { exists, isDefined } from 'application/common/data_helpers'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'
import { ActionButton } from 'application/components/controls/action_button'
import { CheckboxBar } from 'application/components/controls/checkbox_bar'
import { LawfirmSelect } from 'application/components/controls/form/lawfirm_select'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { Image } from 'application/components/controls/image'
import { InfoBox } from 'application/components/controls/info_box'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { CommissionOtherExpertPartialForm } from 'application/components/forms/_partial_forms'
import { PowerOfAttorneyForm } from 'application/components/forms/power_of_attorney'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { FlexBox } from 'application/components/fragments/flex_box'
import { FormHeadline } from 'application/components/fragments/typography'
import { useStatefulForm } from 'application/components/hooks'
import { useLawfirms } from 'application/components/hooks/use_lawfirms'
import { useModules } from 'application/components/hooks/use_modules'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import { IMAGES, INFOBOX, MODULE_NAME } from 'application/constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'


const getExpertData = (
    name = '', 
    email = '',
    inspectionSite = null,
    inspectionDate = '',
    inspectionInfoName = '',
    webakteId = ''
) => {
    return  {
        name: name,
        email: email,
        inspectionSite,
        inspectionDate,
        inspectionInfoName,
        webakteId,
        address: inspectionSite,
        contact: {
            email: email,
            phoneNumber: ''
        }
    }
}


export const getDeclarationOfAssignmentPDFValues = (
    expertData,
    customer, 
    damage,
    branch,
    insuranceCompanies = []
) => {
    const insuranceId = damage?.liabilityData?.liabilityInsurance?.id
    const insuranceNameGiven = damage?.liabilityData?.liabilityInsurance?.name

    const insuranceName = exists(insuranceId) &&  insuranceCompanies.length > 0 
        ? insuranceCompanies.find( ic => ic.id === insuranceId).name
        : isDefined(insuranceNameGiven)
            ? insuranceNameGiven
            : ''

    return {
        expertData,
        damage: {
            date: damage?.incidentDate || '',
            time: '',
            location: damage?.liabilityData?.accidentReport?.location?.description || '', 
            reportNumber: '', 
            claimNumber: damage?.liabilityData?.liabilityClaim?.number|| '',
            amount: '' 
        },
        client: { 
            firstName: customer?.values?.firstName || '',
            lastName: customer?.values?.lastName || '',
            street: customer?.values?.address?.line1 || '',
            postalCode: customer?.values?.address?.postalCode || '',
            locality: customer?.values?.address?.locality || '',
            eligibleForInputTaxDeduction: customer?.values?.isEntitledToTaxDeduction || '',
            vehicle: {  //damage.vehicle
                licensePlate: damage?.vehicle?.licensePlate || '',
                chassisNumber: damage?.vehicle?.chassisNumber || '',
                make: damage?.vehicle?.make || '',
                model: damage?.vehicle?.model || ''
            }
        }, 
        tortFeasor: {
            firstName: damage?.liabilityData?.vehicleKeeper?.firstName || '',     
            lastName: damage?.liabilityData?.vehicleKeeper?.lastName || '',     
            street: damage?.liabilityData?.vehicleKeeper?.address?.line1 || '',     
            postalCode: damage?.liabilityData?.vehicleKeeper?.address?.postalCode || '',     
            locality: damage?.liabilityData?.vehicleKeeper?.address?.locality || '',     
            vehicle: {
                licensePlate:  damage?.liabilityData?.vehicle?.licenseplate || '',
                chassisNumber: damage?.liabilityData?.vehicle?.chassisNumber || '',
                make: damage?.liabilityData?.vehicle?.make || '',
                model: damage?.liabilityData?.vehicle?.model || '',
                insurance: {
                    name: insuranceName,
                    claimNumber: damage?.liabilityData?.liabilityClaim?.number || '',
                    certificateNumber: damage?.liabilityData?.liabilityInsurance?.certificateNumber || ''
                }
            },
            location: damage?.liabilityData?.accidentReport?.location?.description || ''
        },
        locality:  branch?.address?.locality || '',
        signatures: []
    }
}


export const KickstartStep2Component = (
    { 
        //from container
        currentBranch,
        tempCommissionData,
        setTempCommissionData,
        //from component
        abort, 
        onDataChange,
        goToPreviousStep,
        goToNextStep,
        dialog,
        commissionExpert,
        setCommissionExpert,
        commissionLawfirm,
        setCommissionLawfirm, 
        customer,
        damage,
        setCommissionOtherFiles,
        commissionOtherFiles,
        setLawfirmFiles,
        lawfirmFiles
    }
) => {
    const {
        isModuleApplied
    } = useModules()

    const [commissionDekra, setCommissionDekra] = useState(false)

    const { t } = useTranslation()
    const branch = useSelector(state => state.auth.currentBranch)
    const {
        lawfirmsList,
        loaded
    } = useLawfirms(damage.branchId)



    const expertDefaultValues = {
        name: tempCommissionData.expert?.selectedExpert?.name || '',
        email: tempCommissionData.expert?.selectedExpert?.email || '',
        inspectionSite: {
            ...currentBranch.address,
            ...tempCommissionData.expert?.selectedExpert?.inspectionSite
        },
        inspectionDate: tempCommissionData.expert?.selectedExpert?.inspectionDate || null,
        inspectionInfoName: tempCommissionData.expert?.selectedExpert?.inspectionInfoName || currentBranch.name,
        lawfirm: tempCommissionData.lawfirm?.selectedLawfirm?.webakteId || null,
    }

    const expertFormHook = useStatefulForm(
        {defaultValues: expertDefaultValues}
    )

    const { 
        handleSubmit, 
        getValues
    } = expertFormHook


    const expertData = getExpertData(
        getValues().name,
        getValues().email,
        getValues().inspectionSite,
        getValues().inspectionDate,
        getValues().inspectionInfoName,
        getValues().lawfirm,
        getValues().inspectionSite.address
    )

    const insuranceCompanies = useSelector(state => state?.common?.insurance?.companies || [])

    const pdfValues = getDeclarationOfAssignmentPDFValues(
        expertData,
        customer,
        damage,
        branch,
        insuranceCompanies
    )
    
    const lawffirmDefaultValues = {
        lawfirm: tempCommissionData.lawfirm?.selectedLawfirm?.webakteId || null,
    }

    const lawfirmFormHook = useStatefulForm(
        {defaultValues: lawffirmDefaultValues}
    )
    const hasSelectedLawfirm = exists(lawfirmFormHook.getValues().lawfirm)


    const getSelectedLawFirmByWebakteId = (webakteId) => {
        return  lawfirmsList.find(w => w.webakteId === webakteId)
    }

    const selectedLawfirm = getSelectedLawFirmByWebakteId(lawfirmFormHook.getValues().lawfirm)

    const setData = () => {
        setTempCommissionData({
            lawfirm: {
                selectedLawfirm: selectedLawfirm,
                commissionLawfirm
            },
            expert: {
                commissionExpert: commissionExpert && !commissionDekra,
                selectedExpert: getValues(),
                commissionDekra
            }
        })
    }

    const onSubmitSuccess = () => {
        setData()
        goToNextStep() 
    }


    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                {
                    isModuleApplied(MODULE_NAME.DEKRA_COMMISSION) && <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        cssMargin='0 0 16px' 
                        cssPadding='8px 16px 8px 8px'
                        cssMinHeight='unset'
                        direction='row'
                    >
                        <Image data={IMAGES.DEKRA_LOGO} />
                        <Spacer basis='10px' />
                        <span>{t('The DEKRA commission can be sent after this damage has been created.')}</span>
                    </InfoBox>
                }
                
                {
                    isModuleApplied(MODULE_NAME.DEKRA_COMMISSION) && <CheckboxBar 
                        label={t('Hand over to DEKRA')} 
                        checked={commissionDekra} 
                        disabled={commissionExpert}
                        onChange={ 
                            ()=>{
                                setCommissionDekra(!commissionDekra)
                                setCommissionExpert(false)
                            } 
                        }  
                    />
                }
                
                <CheckboxBar 
                    label={t(isModuleApplied(MODULE_NAME.DEKRA_COMMISSION) ? 'Hand over to other expert' : 'Hand over to expert')} 
                    checked={commissionExpert} 
                    disabled={commissionDekra}
                    onChange={ 
                        ()=>{
                            setCommissionExpert(!commissionExpert)
                            setCommissionDekra(false)
                        } 
                    }  
                />
                {
                    commissionExpert && !commissionDekra && <React.Fragment>
                        <CommissionOtherExpertPartialForm 
                            dialogName={dialog.name}
                            formHook={expertFormHook}
                            onDataChange={onDataChange}
                            usedInDialog={true}
                            pdfValues={pdfValues}
                            commissionOtherFiles={commissionOtherFiles}
                            setCommissionOtherFiles={setCommissionOtherFiles}
                        />
                        <PushBottom32 />
                    </React.Fragment>
                }
                {
                    loaded && lawfirmsList.length > 0 && <React.Fragment>
                        <CheckboxBar 
                            label={t('Hand over to lawfirm')} 
                            checked={commissionLawfirm} 
                            onChange={ 
                                ()=>{
                                    setCommissionLawfirm(!commissionLawfirm)
                                } 
                            }  
                        />
                        {
                            commissionLawfirm && <React.Fragment>
                                <FormHeadline>Kanzlei auswählen </FormHeadline>
                                <LawfirmSelect
                                    formhook={lawfirmFormHook}
                                    initialLawfirmOption={null}
                                    onDataChange={lawfirmFormHook.updateFormState}
                                    branchId={branch.id}
                                />
                                <PushBottom16 />
                                {
                                    commissionLawfirm 
                                    && hasSelectedLawfirm
                                    && <React.Fragment>  
                                        <PowerOfAttorneyForm
                                            lawfirm = {selectedLawfirm}
                                            customer = {customer}
                                            branch = {currentBranch}
                                            files={lawfirmFiles}
                                            setFiles={setLawfirmFiles}
                                            damage={damage}
                                            submitButtonText='Create power of attorney'
                                        />
                                        <TaskUploader
                                            onFileAdded={() => { }}
                                            disableActiveState={true}                   
                                            files={lawfirmFiles}
                                            setFiles={setLawfirmFiles}
                                            label={t('Documents for the lawfirm')}
                                        />  
                                        <PushBottom16/>
                                    </React.Fragment>
                                } 
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </FlexBox>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={()=>{
                    setData()  
                    goToPreviousStep()
                }} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Continue')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}