import { connect } from 'react-redux'
import { FormRendererComponent } from './component'
import { submitDynamicFormData } from '../redux/actions'

const mapStateToProps = state => ({
    // formRendererDescription: selectFormRendererDescription(state)
})

const mapDispatchToProps =  {
    submitDynamicFormData
}

export const FormRenderer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(FormRendererComponent)