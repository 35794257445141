import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCustomersRequest } from 'application/redux/actions/pages/customers'
import { getUpdateConstraint } from 'application/redux/selectors'
import { UPDATE_CONSTRAINTS } from 'application/constants'

const mapStateToProps = state => {

    const updateConstraint = getUpdateConstraint(
        state, 
        UPDATE_CONSTRAINTS.CUSTOMERS_TABLES
    )

    return {
        customersListLoaded: state.pages.customers.customersListLoaded,
        customersListLoading: state.pages.customers.customersListLoading,
        customersList: state.pages.customers.customersList,
        customersListTotal: state.pages.customers.customersListTotal,
        updateConstraint
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadCustomersList: (
            offset, 
            limit, 
            orderBy,
            direction,
            filterQuery           
        ) => dispatch(
            getCustomersRequest(
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export const withCustomersLoader = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)