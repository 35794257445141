import React from 'react'
import PropTypes from 'prop-types'
import {CASEFILE} from 'application/constants'
import { useTranslation } from 'react-i18next'

export const mapStatusName = (statusString) => {
    switch (statusString) {
    case CASEFILE.STATUS.OPEN: 
        return 'Open'
    case CASEFILE.STATUS.CLOSED: 
        return 'Closed'
    default: 
        return 'Not clear'
    }
}

const CaseFileStatusName = (
    { caseFileStatusString = '' }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        {t(mapStatusName(caseFileStatusString))}
    </React.Fragment>
}

CaseFileStatusName.propTypes = {
    caseFileStatusString: PropTypes.string
}

export {CaseFileStatusName}