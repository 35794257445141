import { put, select } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { getCurrentBranchId } from 'application/redux/selectors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'
import { exists } from 'application/common/data_helpers'

export function* dialogCreateDamageSaga(action) {

    const {
        customerData,
        newDamageData
    } = action.payload

    const currentBranchId = yield select(getCurrentBranchId)

    yield put(
        showDialogStepsWaitingScreen(
            'Creating damage'
        )
    )

    let customerId = customerData.customerId
    const mappedCustomerPayload = removePropertiesWithEmptyStrings(customerData.values)

    if(!customerData.existingCustomer){
        const createCustomerResponse = yield resourceCall(
            api.gateway.customer.postCustomer, 
            null,
            mappedCustomerPayload
        )
        customerId = createCustomerResponse.id
    }

    if(customerData.existingCustomer && customerData.existingCustomerWasChanged){
        yield resourceCall(
            api.gateway.customer.putCustomer,
            {
                customerId
            }, 
            mappedCustomerPayload
        )

        const hasLegalProtection = customerData?.values?.legalProtectionInsurance
        &&  ( 
            exists(customerData?.values?.legalProtectionInsurance?.company?.name)
            ||  exists(customerData?.values?.legalProtectionInsurance?.company?.id) 
        )

        if (hasLegalProtection) {
            const mappedLegalProtectionInsurancePayload = removePropertiesWithEmptyStrings(
                customerData.values.legalProtectionInsurance 
            )
            yield resourceCall(
                api.gateway.customer.putLegalProtectionInsurance,
                { customerId }, 
                mappedLegalProtectionInsurancePayload
            )
        }
    }

    const payload = dataMappers.exportMappers.damage.createDamageDataMapper(
        newDamageData, 
        customerId
    )

    const damageCreationResponse = yield resourceCall(
        api.gateway.damage.createDamage, 
        {
            branchId: currentBranchId
        },
        payload
    )

    yield put(
        updateDialogDataByName(
            action.dialogName,
            { 
                createdDamage: {
                    ...newDamageData,
                    id: damageCreationResponse.id
                },
                customer: {
                    ...customerData.values,
                    id: customerId
                }
            }
        )
    )

    yield put(
        hideDialogStepsWaitingScreen()
    )
    
    yield put(showToast('Damage created'))
}