import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { LayoutAllBranches } from 'application/components/pages/_layout/layout_all_branches'
import {TableGroupMembers} from 'application/components/tables/common/group_members'
import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import { 
    PushBottom32
    // , Spacer 
} from 'application/components/pages/_common'
// import { ForAnyBranchPermissions } from 'application/components/context/permissions'
// import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import {withRouter} from 'application/components/higher_order_components/with_router'

export const GroupMembersC = withRouter(
    withTheme(
        (props) => {
            // const {
            //     match, 
            //     onEditUsersInGroup, 
            //     theme
            // } = props
            // const {groupId} = match.params
            const {t} = useTranslation()
            const query = useQuery()
            const groupName = query.getStringParam(ROUTE_PARAMETERS.GROUP_MEMBERS.GROUP_NAME)

            return <LayoutAllBranches
                headlineGeneric={t('Administration')}
                headlineSpecific={`${t('Group')} "${groupName}"`}
            // headlineChildren={
            //     <ForAnyBranchPermissions permissions={[]} allNeeded={true}>
            //         <Spacer />    
            //         <IconButtonWithLabel
            //             iconKey='person_add'
            //             label={t('Add user to group')}
            //             onButtonClick={()=>{onEditUsersInGroup(groupId)}}
            //             iconSize={theme.fontSize.medium}
            //             fontSize={theme.fontSize.medium}
            //         />
            //     </ForAnyBranchPermissions>
            // }
            >
                <PushBottom32 />
                <TableGroupMembers {...props} />
            </LayoutAllBranches>   
        }
    )
)