import { isDefined } from 'application/common/data_helpers'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { MessageList } from '../base'
import {withRouter} from 'application/components/higher_order_components/with_router'


const WFPMessageListC = (
    { 
        getMessageUrl, 
        baseUrlParams, 
        messages, 
        selectedMessageId, 
        messagesTotal, 
        onPaginationChange, 
        match
    }
) => {
    
    const hasMessages = messagesTotal > 0
    const firstMessage = messages[0]
    const isNewOpened = !isDefined(selectedMessageId) && isDefined(firstMessage?.id) 

    if( hasMessages &&  isNewOpened  ){
        return <Navigate 
            to={
                getMessageUrl(
                    match.params.sharedResourceId,
                    firstMessage.id
                )
            } 
        />
    }

    const getParentRessourceId = () => {
        return match.params.sharedResourceId
    }

    return <MessageList
        getMessageUrl = {getMessageUrl} 
        baseUrlParams = {baseUrlParams} 
        messages = {messages} 
        selectedMessageId = {selectedMessageId} 
        messagesTotal = {messagesTotal} 
        onPaginationChange = {onPaginationChange}
        getParentRessourceId = {getParentRessourceId}
    />
}

export const MessageListWFP =  withRouter(
    WFPMessageListC
)