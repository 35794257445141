import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { toggleGroupSidebar, updateGroup } from 'application/redux/actions/pages/branch_management'
import { connect } from 'react-redux'
import {GroupSidebarC} from './component'

const mapStateToProps = state => ({
    isVisible: state.pages.branchManagement.groupSidebarVisible,
    groupData: state.common.userGroups.find( 
        b => b.id === state.pages.branchManagement.groupSidebarDataId 
    )
})


const mapDispatchToProps = dispatch => {
    return {
        onSidebarClose: () => dispatch(
            toggleGroupSidebar(false)
        ),
        onEditGroup: (group) => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_EDIT_GROUP, 
                true, 
                { group }, 
                'Edit group'
            )
        ), 
        onUpdateGroup: (groupId, payload) => dispatch(
            updateGroup(
                groupId,
                payload
            )
        ),
        onDeleteGroup : (id) => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP, 
                false,
                { groupId:id }, 
                'Remove user group'
            )
        )
    }
}

export const GroupSidebar =  connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSidebarC)