import { isNull } from 'application/common/data_helpers'
import { useParams } from 'react-router-dom'


export const useDocumentsResourceFromUrl = () => {
    const { damageId = null } = useParams()
    const { sharedResourceId = null } = useParams()
    const hasSharedResource = !isNull(sharedResourceId) 
    const resourceIdFromUrl = hasSharedResource
        ? sharedResourceId
        : damageId
    return {
        isInternal: !hasSharedResource,
        resourceIdFromUrl
    }

}