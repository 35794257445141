import { connect } from 'react-redux'
import { DamageManagerLawyerCommunicationSidebarEffects } from './effects'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'


const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetContext: (context) => dispatch(
            refreshWidgetsByContext(context)
        )
    }
}

export const DamageManagerLawyerCommunicationSidebar = connect(
    state => state,
    mapDispatchToProps
)(DamageManagerLawyerCommunicationSidebarEffects)