import { FontIcon } from '@ec/ui-controls-react'
import { SingleMessage } from 'application/components/controls/single_message'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useQuery } from 'application/components/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import { MessageListWrapper, WrapperLink } from './fragments'





const MessageList = (
    { 
        getMessageUrl, 
        baseUrlParams, 
        messages, 
        selectedMessageId, 
        messagesTotal, 
        onPaginationChange, 
        getParentRessourceId
    }
) => {
    const { t } = useTranslation()
    const query = useQuery()

    const limit = query.getParsedParam(baseUrlParams.LIMIT) === 0 
    || query.getParsedParam(baseUrlParams.LIMIT) === undefined 
        ? 10 
        : query.getParsedParam(baseUrlParams.LIMIT) 



    if (messagesTotal === 0)  return <MessageListWrapper>
        <FlexBox 
            flexDirection='column' 
            cssRules='overflow:hidden;'
        >
            <FlexBox 
                marginRight='2%' 
                marginLeft='2%' 
                justifyContent='center' 
            >
                <FontIcon 
                    icon='attach_email' 
                    fontSize='44px'
                />
            </FlexBox>
            <FlexBox 
                marginRight='2%' 
                marginLeft='2%' 
                justifyContent='center' 
                alignItems='center'
            >
                {t('Inbox is empty')}
            </FlexBox>
        </FlexBox>
    </MessageListWrapper>

    return <MessageListWrapper>
        {
            messages.map(
                (m, i) => {
                    return <WrapperLink
                        key={i}
                        to={
                            getMessageUrl(
                                getParentRessourceId(m),
                                m.id
                            )
                        }
                    >
                        <SingleMessage
                            message={m}
                            isSelected={m.id === selectedMessageId}
                        />
                    </WrapperLink>
                }
            )
        }
        <FlexBox justifyContent='center'>
            <RoutedPagination
                totalSize={messagesTotal}
                itemsPerPage={limit}
                parameterNames={{
                    offset: baseUrlParams.OFFSET,
                    limit: baseUrlParams.LIMIT
                }}
                onPaginationChange={onPaginationChange}
            />
        </FlexBox>
    </MessageListWrapper>
}

export  {MessageList}