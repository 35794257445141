import styled from 'styled-components'

export const Wrapper = styled.div`  
    display: flex;
    flex: 100%;
    flex-direction: row;
    height: 35px;
    min-width: ${props => props.cssMinWidth};
    
    & div.css-2b097c-container  {
        max-height: 35px;
        height: 35px;
        min-height: 35px;
    }

    & div.css-2b097c-container > div.css-yk16xz-control {
        max-height: 35px;
        height: 35px;
        min-height: 35px;
    }

    & div.css-2b097c-container > div.css-1pahdxg-control {
        max-height: 35px;
        height: 35px;
        min-height: 35px;
        box-sizing: border-box;
    }

    & .bFkiPO div.css-26l3qy-menu {
        margin-top: 1px;
    }
`


export const StyledTextInputWithLabel = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.color.anthracite};
    height: 35px;
    max-height: 35px;

    & input[type=text]{
        color: ${props => props.theme.color.anthracite};
        border: 0;
        border: 1px solid ${props => props.theme.color.gray15};
        border-left: 0;
        padding: 0;
        padding-left: 8px;
        width: inherit;
        height: 35px;
        max-height: 35px;
    }

    & input[type=text]::placeholder { 
        color: ${props => props.theme.color.anthracite50};
    }

    & input[type=text]:disabled {
        border: 0;
        background-color: ${props => props.theme.color.gray5};
        color: ${props => props.theme.color.anthracite50};
    }

    & input[type=text]:focus{
        outline: none;
        height: 35px;
        max-height: 35px;
    }

    & div.css-1pahdxg-control {
        max-height: 35px;
        height: 35px;
        min-height: 35px !important;
    }
`