import React from 'react'
import { useTranslation } from 'react-i18next'
import { REPAIR_AUTHORIZATION_TYPE } from 'application/constants'

export const RepairClearanceName = ({ repairClearanceType }) => {
    const { t } = useTranslation()
    const mapRepairClearanceTypeName = (type) => {
        const names = new Map([
            [REPAIR_AUTHORIZATION_TYPE.GRANTED, 'Grant'],
            [REPAIR_AUTHORIZATION_TYPE.REFUSED, 'Do not grant'],
            [REPAIR_AUTHORIZATION_TYPE.NOT_SET, 'Not set'],
        ])

        return names.get(type)
    }

    return <React.Fragment>
        {t(mapRepairClearanceTypeName(repairClearanceType))}
    </React.Fragment>
}