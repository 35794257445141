import { exists } from 'application/common/data_helpers'
import { PdfViewer } from 'application/components/building_blocks/attachments/attachment_viewer/viewers_by_type/pdf_viewer'
import { ActionButton } from 'application/components/controls/action_button'
import { IndicatorWithColor } from 'application/components/controls/indicator/override_color_indicator'
import { PasteSignature } from 'application/components/controls/paste_signature/component'
import { useDynamicPdf } from 'application/components/dynamic_pdfs'
import { FlexBox } from 'application/components/fragments/flex_box'
import {
    Headline2,
    Paragraph,
} from 'application/components/fragments/typography'
import { useModules } from 'application/components/hooks/use_modules'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { MODULE_NAME } from 'application/constants'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import {
    StyledPdf,
    StyledPdfSignatureBackground,
    StyledPdfSignatureHeader,
    StyledPdfSignatureWrapper,
    StyledPdfWrapper,
    StyledSignatureArea,
    StyledSignatureWrapper,
} from './fragments'

export const PdfSignature = ({
    abort = () => {},
    onSave,
    pdfBuilder,
    pdfValues,
    pdfFileName = 'file.pdf',
    saveButtonText = 'Download',
    signatureAreaOpen = false,
    headline,
    signatureFields = [],
}) => {
    const { t } = useTranslation()
    const [signatureAreaVisible, setSignatureAreaVisible] = useState(
        signatureAreaOpen,
    )
    const signatureRef = useRef(null)

    const {
        isAccountModuleApplied
    } = useModules()

    const {
        url,
        addSignature,
        attachment,
        attachmentAvailable,
        download,
        currentSignatureIndex,
        setCurrentSignatureIndex,
        pdfValuesState,
        hasSignature,
    } = useDynamicPdf(pdfBuilder, pdfValues, pdfFileName)

    const prefillSignature = useCallback(
        index => {
            if (exists(signatureRef.current)) {
                signatureRef.current.clear()
                if (exists(pdfValuesState.signatures[index])) {
                    signatureRef.current.fromDataURL(pdfValuesState.signatures[index])
                }
            }
        },
        [pdfValuesState.signatures],
    )

    const handleConfirmSignatureArea = () => {
        addSignature(
            signatureRef.current.toDataURL(),
            currentSignatureIndex
        )

        if (currentSignatureIndex === signatureFields.length - 1) {
            setSignatureAreaVisible(false)
        } else {
            setCurrentSignatureIndex(currentSignatureIndex + 1)
            prefillSignature(currentSignatureIndex + 1)
        }
    }

    const handleClear = () => {
        signatureRef.current.clear()
        addSignature(null, currentSignatureIndex)
    }

    const onStepBack = () => {
        addSignature(
            signatureRef.current.toDataURL(),
            currentSignatureIndex
        )
        setCurrentSignatureIndex(currentSignatureIndex - 1)
        prefillSignature(currentSignatureIndex - 1)
    }

    useEffect(() => {
        if (signatureAreaVisible) {
            setCurrentSignatureIndex(0)
            prefillSignature(0)
        }
    }, [prefillSignature, setCurrentSignatureIndex, signatureAreaVisible])

    const handleSave = () => onSave(url, attachment)

    return (
        <React.Fragment>
            {createPortal(
                <StyledPdfSignatureBackground className="pdfSignatureBackground">
                    <StyledPdfSignatureWrapper className="pdfSignatureWrapper">
                        <StyledPdfSignatureHeader>
                            {t(headline)}
                            <IndicatorWithColor
                                onButtonClick={abort}
                                iconKey="clear"
                                iconSize="20px"
                                overrideColorWith="anthracite"
                            />
                        </StyledPdfSignatureHeader>

                        <StyledPdfWrapper className="pdfWrapper">
                            <StyledPdf>
                                {url !== null && <PdfViewer filePath={url} />}
                            </StyledPdf>
                        </StyledPdfWrapper>
                        <StyledSignatureWrapper
                            className="signatureWrapper"
                            signatureAreaVisible={signatureAreaVisible}
                        >
                            {signatureAreaVisible ? (
                                <React.Fragment>
                                    <FlexBox
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        cssRules="max-width: 900px;"
                                    >
                                        <FlexBox flexDirection="column">
                                            <Headline2 cssMargin="16px 0 0">
                                                {t('Please sign')}:{' '}
                                                <strong>
                                                    {t(signatureFields[currentSignatureIndex].label)}
                                                </strong>
                                            </Headline2>

                                            <PushBottom16 />

                                            {
                                                isAccountModuleApplied(MODULE_NAME.CLIPBOARD_SIGNATURE) && <PasteSignature
                                                    buttonText='Paste signature from clipboard'
                                                    onPaste={(dataUrl) => {
                                                        if (exists(signatureRef.current)) {
                                                            signatureRef.current.clear()
                                                            signatureRef.current.fromDataURL(dataUrl)
                                                            //paste into PDF directly:
                                                            //addSignature(dataUrl, currentSignatureIndex)
                                                        }
                                                    }}
                                                />
                                            }

                                            {
                                                exists(
                                                    signatureFields[currentSignatureIndex].description,
                                                ) && (
                                                    <Paragraph cssMargin="8px 0">
                                                        {t(
                                                            signatureFields[currentSignatureIndex].description,
                                                        )}
                                                    </Paragraph>
                                                )
                                            }
                                        </FlexBox>
                                        <Spacer />
                                        <StyledSignatureArea>
                                            <SignatureCanvas
                                                ref={signatureRef}
                                                penColor="black"
                                                canvasProps={{
                                                    width: 400,
                                                    height: 200,
                                                    className: 'sigCanvas',
                                                }}
                                            />
                                        </StyledSignatureArea>
                                    </FlexBox>

                                    <FlexBox
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        cssRules="margin-top: 16px; gap: 8px; max-width: 900px;"
                                    >
                                        <FlexBox flexDirection="row" cssRules="max-width: 420px;">
                                            <ActionButton
                                                icon="delete"
                                                onButtonClick={handleClear}
                                                buttonText={t('Delete')}
                                                secondary={true}
                                            />
                                            <Spacer />
                                            {currentSignatureIndex > 0 && (
                                                <ActionButton
                                                    icon="undo"
                                                    buttonText={t('Back')}
                                                    onButtonClick={onStepBack}
                                                    secondary={true}
                                                />
                                            )}
                                            <ActionButton
                                                icon="check"
                                                onButtonClick={handleConfirmSignatureArea}
                                                buttonText={
                                                    currentSignatureIndex === signatureFields.length - 1
                                                        ? t('Confirm')
                                                        : t('Next')
                                                }
                                            />
                                        </FlexBox>
                                    </FlexBox>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <FlexBox
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        cssRules="gap: 8px; max-width: 900px;"
                                    >
                                        <ActionButton
                                            icon="get_app"
                                            disabled={!attachmentAvailable}
                                            onButtonClick={download}
                                            buttonText={
                                                hasSignature
                                                    ? t('Download signed PDF')
                                                    : t('Download PDF')
                                            }
                                            secondary={true}
                                        />
                                        <Spacer />
                                        {
                                            signatureFields.length > 0 && <ActionButton
                                                icon="edit"
                                                onButtonClick={() => setSignatureAreaVisible(true)}
                                                buttonText={t('Sign')}
                                            />
                                        }
                                        
                                        <ActionButton
                                            icon="check"
                                            disabled={!attachmentAvailable}
                                            onButtonClick={handleSave}
                                            buttonText={t(saveButtonText)}
                                        />
                                    </FlexBox>
                                </React.Fragment>
                            )}
                        </StyledSignatureWrapper>
                    </StyledPdfSignatureWrapper>
                </StyledPdfSignatureBackground>,
                document.body,
            )}
        </React.Fragment>
    )
}

PdfSignature.propTypes = {
    abort: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    pdfBuilder: PropTypes.func.isRequired,
    pdfValues: PropTypes.object.isRequired,
    pdfFileName: PropTypes.string,
    headline: PropTypes.string,
    saveButtonText: PropTypes.string,
    signatureAreaOpen: PropTypes.bool,
}
