import { connect } from 'react-redux'
import {WaitingScreenComponent} from './component'

const mapStateToProps = (state, props) => {
    return {
        waitingScreen: state.waitingScreen,
        dialogsOpen: state.dialogs.dialogsOpen
    }
}

const mapDispatchToProps = () => ({})

export const WaitingScreen = connect(
    mapStateToProps,
    mapDispatchToProps
)(WaitingScreenComponent)