import React from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { Trans } from 'react-i18next'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { InfoBox } from 'application/components/controls/info_box'
import { useTranslation } from 'react-i18next'
import { INFOBOX, ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { Paragraph, TextNavLink } from 'application/components/fragments/typography'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { SimpleList } from 'application/components/building_blocks/simple_list'
import { Spacer, PushBottom24, PushBottom16 } from 'application/components/pages/_common'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'




const RemoveGroupComponent = (
    { 
        abort, 
        dialog,
        onRemoveGroup,
        groupData,
        members,
        onCloseDialog
    }
) => {
    const { t } = useTranslation()
    const {
        internalAccountLink
    } = useApplicationLinks()

    const handleRemoveGroup = () => {
        const payload = {}
        const metaData = { groupId: groupData.id }

        onRemoveGroup(
            dialog.name,
            payload,
            metaData
        )
    }

    const groupName = groupData.name
    const groupId = groupData.id
    const groupHasMembers = members.total > 0 


    const messages = {
        success: [],
        error: []
    }
    
    groupHasMembers && messages.error.push( t('There are still members assigned to this group') )
    !groupHasMembers && messages.success.push( t('No users are assigned to this group') )


    return <React.Fragment>
        <FlexBox 
            justifyContent='center'
            paddingBottom='100px'
            flexDirection='column'
            paddingLeft='16px'
            paddingRight ='16px'
        >
            {
                groupHasMembers
                    ? 
                    <InfoBox 
                        type={INFOBOX.TYPES.ALERT} 
                        direction='row' 
                        cssMargin='0' 
                        cssPadding='16px'
                    >   
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <FlexBox flexDirection='column'>
                            <FlexBox>
                                <FlexBox width='auto'>
                                    {t('The group') } &nbsp;<strong>"{groupName}"</strong>&nbsp; {t('Could not be deleted.')}
                                </FlexBox>            
                            </FlexBox>
                            <PushBottom16/>
                            <FlexBox>
                                <SimpleList 
                                    list={messages.error}
                                    iconKey='close'
                                    type = 'error'
                                    iconSize = '14px'
                                />   
                            </FlexBox>
                            <PushBottom16/>
                            <Paragraph>
                                <TextNavLink
                                    to={
                                        internalAccountLink(
                                            `${ROUTES.BRANCH_MANAGEMENT}/groups/${groupId}/members?${ROUTE_PARAMETERS.GROUP_MEMBERS.GROUP_NAME}=${groupName}`
                                        )
                                    }
                                    onClick={ 
                                        () => { 
                                            onCloseDialog(dialog.name) 
                                        } 
                                    }
                                >

                                
                                        &raquo; 
                                    { t('Edit users in this group') }
                                </TextNavLink>
                            </Paragraph>   
                        </FlexBox>
                    </InfoBox>
                    : 
                    <FlexBox flexDirection='column'>
                        <Trans i18nKey='do you want to delete this group?'>
                            { {groupName} }
                        </Trans>
                        <PushBottom24/>
                        <FlexBox>
                            <SimpleList 
                                list={messages.success}
                                iconKey='check'
                                type = 'success'
                            /> 
                        </FlexBox>
                    </FlexBox>     

            }
        </FlexBox>
        <Footer
            onAbort={abort}
            onSubmit={handleRemoveGroup}
            submitText='Delete group'
            disabled={groupHasMembers}
        />
    </React.Fragment>
}

export const RemoveGroup = withDialogDataLoader(RemoveGroupComponent)