import { exists } from 'application/common/data_helpers'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { useCallback } from 'react'

export const useUiSettingsAdapters = () => {
    const {
        getSetting,
        accountSettingsLoaded,
        userSettingsLoaded
    } = useUiSettings()

    const getAdaptedSetting = useCallback((settingsKey, params = {}) => {
        switch(settingsKey){
        // This is a custom ui settings adapter to get the assignee for the 
        // external kickstart damage by branch ID
        // the setting is written by useKickstartBranchAssignees() hook
        case UI_SETTINGS.ACCOUNT.EXTERNAL_DAMAGE_CREATION_BRANCH_ASSIGNEES:
            const setting = exists(getSetting(settingsKey)) ? getSetting(settingsKey) : '[]'
            const parsed = JSON.parse(setting)
            return parsed.find(s => s.branchId === params.branchId)?.assignee ?? null
        default:
            return getSetting(settingsKey)
        }
    }, [getSetting])

    return {
        getSetting: getAdaptedSetting,
        accountSettingsLoaded,
        userSettingsLoaded
    }
}