import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'



export const TaskUploaderWidgedComponent = withRouter( (
    {
        widgetKey, 
        payload, 
        loadingFailed, 
        onFilesAdded, 
        selectedTaskDocuments, 
        isExternal, 
        onShowAttachmentDetails,
        match
    }
) => {
    const {t} = useTranslation()
    const {taskId} = payload
    
    const {
        sharedResourceId
    } = match.params

    const damageId = payload?.damageId || match.params.damageId

    const initialAttachments = selectedTaskDocuments === null 
        ? [] 
        : selectedTaskDocuments.documents
    const handleAttachmentClick = (attachment) => {
        onShowAttachmentDetails(
            attachment, 
            damageId, 
            taskId, 
            isExternal, 
            sharedResourceId
        )
    }

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }
    return <TaskUploader
        onFileAdded={ () => { } }
        setFiles={
            (files) => { 
                onFilesAdded(
                    files, 
                    taskId, 
                    damageId, 
                    [taskId], 
                    isExternal
                )
            }
        }
        initialAttachments={initialAttachments}
        onAttachmentClick={handleAttachmentClick}
        damageId={damageId}
    />
})

TaskUploaderWidgedComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}