export const ACCOUNT_BASE = '/accounts'
export const BRANCH_BASE = '/branches'
export const ADMINISTRATION_BASE = '/administration'
export const ALL_BRANCHES_BASE = '/allbranches'

export const ROUTES = {
    //direct login
    LOAD: '/load',

    // idendity wide / choose account
    PREFERRED_ACCOUNT_PRELOADER: '/preferredAccountPreloader',
    CHOOSE_ACCOUNT: '/chooseaccount',
    ACCOUNT_PRELOADER: ACCOUNT_BASE + '/:accountId/load',

    // all branches
    LEGACY_DASHBOARD: '/dashboard',
    DASHBOARD: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/dashboard',
    DAMAGES_OVERVIEW: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/damages/',
    DAMAGES_OVERVIEW_MY_DAMAGES: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/damages/mine',
    DAMAGES_OVERVIEW_ALL_DAMAGES: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/damages/all',
    DAMAGES_OVERVIEW_ARCHIVED_DAMAGES: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/damages/archived',
    TASKS_OVERVIEW: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/tasks',
    TASKS_OVERVIEW_MY_TASKS: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/tasks/mine',
    TASKS_OVERVIEW_ALL_TASKS: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/tasks/all',
    ALL_BRANCHES_INBOX: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/inbox',
    INBOX_PRESELECTED: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/inbox/:damageId/:messageId',
    CONTROLLING: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/controlling',
    CONTROLLING_INVOICES: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/controlling/invoices',
    CONTROLLING_STATISTICS: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/controlling/statistics',
    CONTROLLING_DAMAGES: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/controlling/damages',
    ACTIVITY_LOG: ACCOUNT_BASE + '/:accountId' + ALL_BRANCHES_BASE + '/activities',


    // specific branches
    BRANCH_SPECIFIC_DASHBOARD: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/dashboard',
    BRANCH_SPECIFIC_DAMAGES_OVERVIEW: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/damages/',
    BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/damages/mine',
    BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/damages/all',
    BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ARCHIVED_DAMAGES: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/damages/archived',
    BRANCH_SPECIFIC_TASKS_OVERVIEW: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/tasks',
    BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/tasks/mine',
    BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/tasks/all',
    BRANCH_SPECIFIC_INBOX: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/inbox',
    BRANCH_SPECIFIC_INBOX_PRESELECTED: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/inbox/:damageId/:messageId',
    BRANCH_SPECIFIC_CONTROLLING: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/controlling',
    BRANCH_SPECIFIC_CONTROLLING_INVOICES: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/controlling/invoices',
    BRANCH_SPECIFIC_CONTROLLING_STATISTICS: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/controlling/statistics',
    BRANCH_SPECIFIC_INBOX_MESSAGE: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE +'/:branchId/inbox/:damageId/:messageId',
    BRANCH_SPECIFIC_ACTIVITY_LOG: ACCOUNT_BASE + '/:accountId' + BRANCH_BASE + '/:branchId/activities',




    // accountwide / admin    
    // ADMINISTRATION_BASE: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE,
    ADMINISTRATION_SETTINGS_ACCOUNT_LOGO: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/accountlogo',
    CUSTOMERS: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/customers',
    CUSTOMERS_PRIVATE: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/customers/private',
    CUSTOMERS_BUSINESS: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/customers/business',
    CUSTOMERS_BULK_IMPORT: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/customers/import',
    ADMINISTRATION: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE,
    ADMINISTRATION_USERS: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/users',
    ADMINISTRATION_SETTINGS: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings',
    ADMINISTRATION_SETTINGS_CATEGORIES: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/categories',
    CHANGELOG: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/changelog',
    BRANCH_MANAGEMENT: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/branchmanagement',
    BRANCH_MANAGEMENT_GROUP_MEMBERS:  ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/branchmanagement/groups/:groupId/members',
    ADMINISTRATION_SETTINGS_DAMAGE_STATES: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/states',
    ADMINISTRATION_SETTINGS_ACCOUNT_DATA: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/accountdata',
    ADMINISTRATION_SETTINGS_UI_SETTINGS: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/ui',
    ADMINISTRATION_SETTINGS_CONTRACTORS_UI: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/contractors',
    ADMINISTRATION_SETTINGS_COMPREHENSIVE_DAMAGE_TYPES: ACCOUNT_BASE + '/:accountId' + ADMINISTRATION_BASE + '/settings/comprehensivedamagetypes',
    DAMAGE_NOT_FOUND: ACCOUNT_BASE + '/:accountId/damageNotFound',


    // damage manager 
    DAMAGE_MANAGER_BASE: ACCOUNT_BASE + '/:accountId/damages/', //can be same as damageoverview, changes should affect subroutes as well
    DAMAGE_MANAGER: ACCOUNT_BASE + '/:accountId/damages/:damageId',
    DAMAGE_MANAGER_OVERVIEW: ACCOUNT_BASE + '/:accountId/damages/:damageId/overview',
    DAMAGE_MANAGER_TASKS: ACCOUNT_BASE + '/:accountId/damages/:damageId/tasks',
    DAMAGE_MANAGER_TASKS_PRESELECTED: ACCOUNT_BASE + '/:accountId/damages/:damageId/tasks/:taskId',
    DAMAGE_MANAGER_DOCUMENTS: ACCOUNT_BASE + '/:accountId/damages/:damageId/documents',
    DAMAGE_MANAGER_LAWYER_COMMUNICATION: ACCOUNT_BASE + '/:accountId/damages/:damageId/lawyercommunication',
    DAMAGE_MANAGER_INVOICES: ACCOUNT_BASE + '/:accountId/damages/:damageId/invoices',
    DAMAGE_MANAGER_INBOX: ACCOUNT_BASE + '/:accountId/damages/:damageId/messages',
    DAMAGE_MANAGER_INBOX_PRESELECTED: ACCOUNT_BASE + '/:accountId/damages/:damageId/messages/:messageId',
    


    // shared resources / workflowpanel
    EXT_SHARED_RESOURCE_BASE: '/resource/',
    EXT_SHARED_RESOURCE_SWITCH_BASE: '/getResource/',
    EXT_SHARED_RESOURCE_SWITCH: '/getResource/:sharedResourceType/:sharedResourceId',
    EXT_SHARED_RESOURCE_LOGIN: '/resource/:sharedResourceType/:sharedResourceId',

    EXT_WORKFLOW_PANEL_BASE: '/external/workflow/',
    EXT_WORKFLOW_PANEL: '/external/workflow/:sharedResourceId',
    EXT_WORKFLOW_PANEL_FORM: '/external/workflow/:sharedResourceId/form',
    EXT_WORKFLOW_PANEL_TASKS: '/external/workflow/:sharedResourceId/tasks',
    EXT_WORKFLOW_PANEL_TASKS_PRESELECTED: '/external/workflow/:sharedResourceId/tasks/:taskId',
    EXT_WORKFLOW_PANEL_MESSAGE: '/external/workflow/:sharedResourceId/message',
    EXT_WORKFLOW_PANEL_MESSAGE_PRESELECTED: '/external/workflow/:sharedResourceId/message/:messageId',
    EXT_WORKFLOW_PANEL_ACTIVITY_LOG: '/external/workflow/:sharedResourceId/activity-log',
    EXT_WORKFLOW_PANEL_DOCUMENTS: '/external/workflow/:sharedResourceId/documents',

    EXT_CREATE_DAMAGE: '/external/createdamage/:sharedResourceId',
    EXT_KICKSTART: '/external/kickstart/:sharedResourceId',

    // start page / sso
    EXT_LOGIN: '/',
    SSO_CALLBACK: '/callback',
    SSO_CALLBACK_SIGN_OUT: '/',
    SSO_SILENT_RENEW: '/silent',

    // registration
    EXT_REGISTRATION: '/registration/:sharedResourceId',
    EXT_REGISTRATION_CALLBACK: '/registration/callback',
    EXT_ACCOUNT_REGISTRATION: '/createaccount/:sharedResourceId',
    EXT_ACCOUNT_REGISTRATION_CALLBACK: '/createaccount/callback',

    // error pages
    EXT_NOT_FOUND: '/404',
    NO_PERMISSION: '/nopermission',
    NO_ACCOUNTS: '/noaccounts',
    SESSION_EXPIRED: '/sessionexpired',
    SHARED_RESOURCE_INVALID: '/resourceExpired',
    GENERIC_ERROR: '/error'
}

//keys must be unique!!!
export const ROUTE_PARAMETERS = {
    SORT_DIRECTIONS: {
        ASCENDING: 'asc',
        DESCENDING: 'desc'
    },
    DEFAULT: {
        OFFSET: 'of',
        LIMIT: 'li',
        ORDER_BY: 'or',
        DIRECTION: 'di',
        FIRSTNAME: 'fn',
        LASTNAME: 'ln',
        EMAIL: 'em',
        MOBILE: 'ph',
        COMPANY: 'cmp',
    },
    DAMAGE_MANAGER: {
        INBOX: {
            OFFSET: 'smio',
            LIMIT: 'smil'
        },
    },
    WORKFLOW_PANEL: {
        INBOX: {
            OFFSET: 'smio',
            LIMIT: 'smil'
        },
    },
    INBOX: {
        OFFSET: 'io',
        LIMIT: 'il',
    },
    BRANCH_SPECIFIC_INBOX: {
        OFFSET: 'io',
        LIMIT: 'il',
    },
    DAMAGE_MANAGER_INBOX: {
        OFFSET: 'smio',
        LIMIT: 'smil',
    },
    DAMAGES_OVERVIEW_ALL: {
        SEARCH: 'soas',
        OFFSET: 'soao',
        LIMIT: 'soal',
        SEARCH_BY: {
            KEY: 'soasb'
        },
        ORDER_BY: {
            KEY: 'soaob',
            VALUES: {
                LICENSE_PLATE: 'vehicle.licenseplate',
                CASE_NAME: 'case',
                LAST_CHANGE: 'modifiedat',
                CREATED_AT: 'createdat'
            },
            DIRECTION: 'soaobd',
        },
        USER: {
            ID: 'soauid',
            FIRST_NAME: 'soaufn',
            LAST_NAME: 'soauln',
        },
        CUSTOMER: {
            ID: 'soacid',
            FIRST_NAME: 'soacfn',
            LAST_NAME: 'soacln',
        },
        FILTERS: {
            TIME_RANGE: 'soatr',
            IDLE_TIME: 'soait',
            DAMAGE_TYPE: 'soadt'
        }
    },
    WIDGET_CONTROLLING_DAMAGES: {
        SEARCH: 'wcss',
        OFFSET: 'wcso',
        LIMIT: 'wcsl',
        SEARCH_BY: {
            KEY: 'wcssb'
        },
        ORDER_BY: {
            KEY: 'wcsob',
            VALUES: {
                LICENSE_PLATE: 'licenseplate',
                VACANT_POSITIONS: 'unpaidtotal',
                STATUS: 'state.id',
                DAMAGE_DATUM: 'createdAt'
            },
            DIRECTION: 'wcsobd',
        }
    },
    WIDGET_CONTROLLING_STATISTICS_DAMAGES_COUNT: {
        FILTERS: {
            START_DATE: 'wcsstd',
            END_DATE: 'wcsed'
        }
    },
    WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS: {
        OFFSET: 'wcgvpo',
        LIMIT: 'wcgvpl',
        FILTERS: {
            TIME_RANGE: 'wcgvptr',
            DAMAGE_TYPE: 'wcgvpdt',
            INVOICE_TYPE: 'wcgvpit',
            COMPARE_BRANCHES: 'wcgvpcb'
        }
    },
    WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART: {
        OFFSET: 'wcgvpo',
        LIMIT: 'wcgvpl',
        FILTERS: {
            TIME_RANGE: 'soatr',
            DAMAGE_TYPE: 'soadt',
            COMPARE_BRANCHES: 'wcgvpcb'
        }
    },
    MY_TASKS: {
        SEARCH: 'mts',
        OFFSET: 'mto',
        LIMIT: 'mtl',
        SEARCH_BY: {
            KEY: 'mtsb'
        },
        ORDER_BY: {
            KEY: 'mtob',
            VALUES: {
                DEADLINE: 'deadline',
                TITLE: 'title',
                LICENSE_PLATE: 'damage.vehicle.licenseplate',
                CREATION_DATE: 'createdAt'
            },
            DIRECTION: 'mtobd',
        },
        STATE: {
            KEY: 'state',
            OPEN: 'todo', 
            DONE: 'done',
            ALL: 'all'
        }
    },
    ALL_TASKS: {
        SEARCH: 'ats',
        OFFSET: 'ato',
        LIMIT: 'atl',
        SEARCH_BY: {
            KEY: 'atsb'
        },
        ORDER_BY: {
            KEY: 'atob',
            VALUES: {
                DEADLINE: 'deadline',
                TITLE: 'title',
                LICENSE_PLATE: 'damage.vehicle.licenseplate',
                CREATION_DATE: 'createdAt',
                MODIFIED_AT: 'modifiedAt'
            },
            DIRECTION: 'atobd',
        },
        USER: {
            ID: 'atuid',
            FIRST_NAME: 'atufn',
            LAST_NAME: 'atuln',
        },
        STATE: {
            KEY: 'state',
            OPEN: 'todo', 
            DONE: 'done',
            ALL: 'all'
        }
    },
    COMPANIES: {
        ORDER_BY: {
            KEY: 'cob',
            VALUES: {
                COMPANY_NAME: 'companyName',
            },
            DIRECTION: 'cobd',
        }  
    },
    CUSTOMERS: {
        SEARCH: 'cs',
        OFFSET: 'co',
        LIMIT: 'cl',
        SEARCH_BY: {
            KEY: 'csb'
        },
        ORDER_BY: {
            KEY: 'cob',
            VALUES: {
                LAST_NAME: 'lastName',
                E_MAIL: 'email',
            },
            DIRECTION: 'cobd',
        },
        FILTERS: {
            SEARCH_TERM_PRIVATE: 'pcun',
            SEARCH_TERM_COMPANY: 'ccun'
        }
    },
    NOTIFICATIONS_LIST: {
        SEARCH: 'nls',
        OFFSET: 'nlo',
        LIMIT: 'nll',
        SEARCH_BY: {
            KEY: 'nlsb'
        },
        ORDER_BY: {
            KEY: 'nlob',
            VALUES: {
                CREATED_AT: 'createdAt',
                ID: 'id'
            },
            DIRECTION: 'nlobd',
        }
    },
    USERS: {
        OFFSET: 'uo',
        LIMIT: 'ul',
        ORDER_BY: {
            KEY: 'uob',
            VALUES: {
                FIRSTNAME: 'firstName',
                LASTNAME: 'lastName',
                EMAIL: 'email',
                ROLES: 'roles',
                CREATED_AT: 'createdAt',
            },
            DIRECTION: 'uobd',
        }
    },
    ASSIGNEES: {
        OFFSET: 'ao',
        LIMIT: 'al',
        ORDER_BY: {
            KEY: 'aob',
            VALUES: {
                LASTNAME: 'lastName',
                EMAIL: 'email'
            }
        }
    },
    ACCOUNTS: {
        OFFSET: 'alo',
        LIMIT: 'all',
        ORDER_BY: {
            KEY: 'alob',
            VALUES: {
                ID: 'id'
            },
            DIRECTION: 'alobd',
        }
    },
    DAMAGES_OVERVIEW_MINE: {
        FILTER: 'somsf',
        OFFSET: 'somo',
        LIMIT: 'soml',
        ORDER_BY: {
            KEY: 'somob',
            VALUES: {
                LICENSE_PLATE: 'vehicle.licenseplate',
                CASE_NAME: 'case',
                LAST_CHANGE: 'modifiedat',
                CREATED_AT: 'createdat'
            },
            DIRECTION: 'somobd',
        }
    },
    REGISTRATION: {
        USER_CREATED: 'usercreated',
        FIRST_NAME: 'fn',
        LAST_NAME: 'ln'
    },
    GROUP_MEMBERS: {
        GROUP_NAME: 'gmgn',
        SEARCH: 'gms',
        OFFSET: 'gmo',
        LIMIT: 'gml',
        ORDER_BY: {
            KEY: 'gmob',
            VALUES: {
                FIRST_NAME: 'gmfn',
                LAST_NAME: 'gmln',
                EMAIL: 'gmem'
            },
            DIRECTION: 'gmd',
        }
    },
    ACTIVITIES: {
        ALL: {
            SEARCH: 'aas',
            OFFSET: 'aao',
            LIMIT: 'aal',
            ORDER_BY: {
                KEY: 'aab',
                VALUES: {
                    OCCURED_AT: 'occurredAt'
                },
                DIRECTION: 'aad', 
            },
            FILTERS: {
                ACTIVITY_TYPE: 'actfat',
                TIME_RANGE: 'actfatr'
            }
        }
    }
}