import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { CurrencyDisplay } from 'application/components/controls/currency_display'
import { ROUTES, ACTIVITY_RELATED_ENTITY_TYPES } from 'application/constants'
import { Link } from 'application/components/controls/hyperlink'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

export const PaymentCancelledActivity = ({activity}) => {
    const {t} = useTranslation()
    const {damageManagerLink} = useApplicationLinks()
    const damageId = activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id
    const link = damageManagerLink(
        ROUTES.DAMAGE_MANAGER_INVOICES, 
        damageId
    )

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Payment cancelled')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Bill number')}: {activity.data.invoice.number}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            {t('Amount')}: <CurrencyDisplay amount={activity.data.amount} />
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            {t('Reason')}: {activity.data.cancellationReason}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <Link to={link}>{t('show invoices')}</Link>
        </Fragments.ActivityParagraph>
    </React.Fragment>
}