import { FontIcon } from '@ec/ui-controls-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

const StyledAnnouncementBubble = styled.div`
    width: 320px;
    min-height: 144px;
    position: absolute;
    right: -340px;
    bottom: -14px;
    z-index: 20;
    border-radius: 3px;
    border: 1px solid ${props => props.$primaryColor};
    background-color: ${props => props.$secondaryColor};
    color: ${props => props.$fontColor};
    font-size: ${props => props.theme.fontSize.standard};
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    padding: 14px 14px 30px;

    &:after {
        content: "";
        position: absolute;
        bottom: 25px;
        left: -9px;
        height: 16px;
        width: 16px;
        background: ${props => props.$secondaryColor};
        transform: rotate(45deg);
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid ${props => props.$primaryColor};
        border-left: 1px solid ${props => props.$primaryColor};
        box-shadow:none;
    }
`

const StyledCloseButton = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    float: right;
    
    & i{
        color: ${props => props.$fontColor};
        font-size: ${props => props.theme.fontSize.standard};
    }
`

const StyledDontShowAnymore = styled.div`
    color: ${props => props.$fontColor};
    font-size: ${props => props.theme.fontSize.standard};
    position: absolute;
    bottom: 12px;
    left: 16px;
    cursor: pointer;

    &:before{
        content: " ";
        display: block;
        width: 16px;
        height: 16px; 
        background: white;
        border: 1px solid gray;
        position: relative;
        left: 0;
        top: 0;
        float: left;
        margin-right: 4px;
    }
`

export const AnnouncementBubble = ({
    primaryColor = null,
    secondaryColor = null,
    fontColor = null,
    onClose = () => {},
    children,
    understoodText = 'Don\'t show anymore',
    onClickDontShowAnyMore = () => {}
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const pColor = primaryColor ? primaryColor : theme.color.feedbackColor
    const sColor = secondaryColor ? secondaryColor : theme.color.feedbackColor
    const fColor = fontColor ? fontColor : theme.color.white

    return <StyledAnnouncementBubble
        $primaryColor={pColor}
        $secondaryColor={sColor}
        $fontColor={fColor}
    >
        <StyledCloseButton
            $fontColor={fColor}
            onClick={onClose}
        >
            <FontIcon isClickable={true} icon='close'/>
        </StyledCloseButton>
        {children}
        <StyledDontShowAnymore 
            $fontColor={fColor}
            onClick={onClickDontShowAnyMore}
        >
            {t(understoodText)}
        </StyledDontShowAnymore>
    </StyledAnnouncementBubble>
}