import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import {AllDamages} from './all_damages'
import {MyDamages} from './my_damages'
import { matchRoute } from 'application/common/route_helpers'
import { useMatch } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'






export const DamagesOverviewComponent = (
    { currentBranch }
) => {
    const {t} = useTranslation()
    const {branchLink} = useApplicationLinks()
    const damagesOverview = matchRoute(
        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW,
        useMatch
    )
    const myDamages = matchRoute(
        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES,
        useMatch
    )
    const allDamages = matchRoute(
        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES,
        useMatch
    )

    const currentBranchId = currentBranch !== null 
        ? currentBranch.id 
        : null
        
    if(currentBranchId === null){
        return null
    }

    if (damagesOverview) {
        return <Navigate 
            to={
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES, 
                    currentBranchId
                )
            } 
        />
    }


    return <LayoutCurrentBranch
        titleSegments={['Damages']}
        headline = {t('Damages')}
    >

        <TabsLinks
            items={[
                {
                    label: t('My damages'),
                    link: branchLink(
                        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES, 
                        currentBranchId
                    ),
                    isActive: myDamages
                },
                {
                    label: t('All damages'),
                    link: branchLink(
                        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES, 
                        currentBranchId
                    ),
                    isActive: allDamages
                }
            ]}
        />
        {
            myDamages && <MyDamages />
        }
        {
            allDamages && <AllDamages />
        }               
    </LayoutCurrentBranch>
}