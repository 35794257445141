import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { put } from 'redux-saga/effects'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { resetDamage } from 'application/redux/actions/pages/damage_manager'

export function* dialogDeleteDamageSaga(action) {
    const { payload } = action
    yield put(showWaitingScreen('Deleting damage'))
    yield resourceCall(
        api.gateway.damage.deleteDamageById, 
        {
            damageId: payload.damageId
        }
    )
    yield put(resetDamage())
    yield put(showToast(payload.successMsg))
    yield put(hideWaitingScreen())
}