import { takeLatest, put } from 'redux-saga/effects'

import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateAllDamages } from 'application/redux/actions/pages/damages_overview/all_damages'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getAllDamagesSaga(action) {
    try {
        const {
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        } = action
        
        const response = yield resourceCall(
            api.gateway.damages.getDamages,
            {
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            }
        ) 
            
        yield put(
            populateAllDamages(
                response.damages, 
                response.total
            )
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* getAllDamagesWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGES_OVERVIEW_GET_ALL_DAMAGES_REQUEST, 
        getAllDamagesSaga
    )
}