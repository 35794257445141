import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiFilestream } from 'application/api/helpers'

export const getAccountLogo = () => ({
    authenticated: () => {
        const request = fetchRequestBuilder(
            'account/logo',
            HTTP_METHOD.GET
        )
        return apiFilestream(request)
    },
    sharedResource: () => null
})
