import { WIDGET_TYPES } from 'application/constants'

//default widget saga
import { widgetDefaultLoadingSaga } from './widget_default_saga'

//dashboard widgets sagas
import { widgetLastDamagesSaga } from './widget_last_damages_saga'
import { widgetLastRepairAuthorizationsSaga } from './widget_last_repair_authorizations_saga'

//damage manager widgets sagas
import { widgetDamageActivityLogSaga } from './widget_damage_activity_log'
import { widgetDamageCommissionedExpertSaga } from './widget_damage_commissioned_expert'
import { widgetDamageCommissionedLawfirmSaga } from './widget_damage_commissioned_lawfirm'
import { widgetDamageInboxMessageList } from './widget_damage_inbox_message_list'
import { widgetDamageShareSaga } from './widget_damage_share_saga'
import { widgetDamageTaskBulletListSaga } from './widget_damage_task_bullet_list'
import { widgetDamageVehicleOwnerSaga } from './widget_damage_vehicle_owner_saga'
import { widgetDamageVehicleSaga } from './widget_damage_vehicle_saga'
import { widgetInboxMessageList } from './widget_inbox_message_list'

//task widgets sagas
import { widgetDamageInsuranceSaga } from './widget_damage_insurance_saga'
import { widgetTaskCommentsSaga } from './widget_task_comments_saga'
import { widgetTaskRepairAuthorizationSaga } from './widget_task_repair_authorization_saga'
import { widgetTaskVehicleDataSaga } from './widget_task_vehicle_data_saga'
import { widgetWorkflowPanelAktenStatusSaga } from './widget_workflow_panel_akten_status_saga'
import { widgetWorkflowInboxMessageList } from './widget_workflow_panel_inbox_message_list_saga'
import { widgetWorkflowPanelInvoicesSaga } from './widget_workflow_panel_invoices_saga'
import { widgetWorkflowPanelLiabilityRequestSaga } from './widget_workflow_panel_liability_request_saga'
import { widgetWorkflowPanelReceiptConfirmationSaga } from './widget_workflow_panel_receipt_confirmation_saga'
import { widgetWorkflowPanelRepairClearanceSaga } from './widget_workflow_panel_repair_clearance_saga'
import { widgetWorkflowPanelTaskCommentsSaga } from './widget_workflow_panel_task_comments_saga'

//anwalt kommunikation
import { widgetDamageAKInvoicesSaga } from './widget_damage_ak_invoices_saga'
import { widgetDamageAKLawfirmSaga } from './widget_damage_ak_lawfirm_saga'
import { widgetDamageAKRepairClearanceSaga } from './widget_damage_ak_repair_clearance_saga'

//controlling
import { widgetControllingGlobalVacantPositionsSaga } from './widget_controlling_global_vacant_positons_saga'
import { widgetControllingStatisticsDamagesCountSaga } from './widget_controlling_statistics_damages_count_saga'
import { widgetControllingVacantPositionsDamagesTableSaga } from './widget_controlling_vacant_positions_damages_table_saga'
import { widgetControllingVacantPositionsKaskoSaga } from './widget_controlling_vacant_positons_kasko_saga'
import { widgetControllingVacantPositionsLiabilitySaga } from './widget_controlling_vacant_positons_liability_saga'
import { widgetControllingVacantPositionsTotalSaga } from './widget_controlling_vacant_positons_total_saga'

//branch specific
import { widgetBranchSpecificControllingStatisticsDamagesCountSaga } from './widget_branch_specific_controlling_statistics_damages_count_saga'
import { widgetBranchSpecificControllingVacantPositionsDamagesTableSaga } from './widget_branch_specific_controlling_vacant_positions_damages_table_saga'
import { widgetBranchSpecificControllingVacantPositionsKaskoSaga } from './widget_branch_specific_controlling_vacant_positons_kasko_saga'
import { widgetBranchSpecificControllingVacantPositionsLiabilitySaga } from './widget_branch_specific_controlling_vacant_positons_liability_saga'
import { widgetBranchSpecificControllingVacantPositionsTotalSaga } from './widget_branch_specific_controlling_vacant_positons_total_saga'
import { widgetBranchSpecificInboxMessageList } from './widget_branch_specific_inbox_message_list'
import { widgetBranchSpecificLastDamagesSaga } from './widget_branch_specific_last_damages_saga'
import { widgetBranchSpecificLastRepairAuthorizationsSaga } from './widget_branch_specific_last_repair_authorizations_saga'


//refactored task form widgets
import { widgetControllingGlobalDamagesSaga } from 'application/redux/saga/sagas/widgets/widget_controlling_global_damages_saga'
import { widgetDamageRepairScheduleSaga } from 'application/redux/saga/sagas/widgets/widget_damage_repair_schedule_saga'
import { widgetTaskCreateInvoiceSaga } from './widget_task_create_invoice_saga'
import { widgetTaskDamageBasicDataSaga } from './widget_task_damage_basic_data_saga'
import { widgetTaskDamageComprehensiveInsuranceDataSaga } from './widget_task_damage_comprehensive_insurance_data_saga'
import { widgetTaskDamageCustomerDataSaga } from './widget_task_damage_customer_data_saga'
import { widgetTaskVehicleProprietorDataSaga } from './widget_task_damage_vehicle_proprietor_saga'
import { widgetTaskLiabilityDataSaga } from './widget_task_liability_data_saga'
import { widgetWorkflowPanelInsuranceInformationSaga } from './widget_workflow_panel_insurance_information_saga'





export const getWidgetDataSagas = (type) => {
    switch (type) {
    case WIDGET_TYPES.LAST_DAMAGES:
        return [widgetLastDamagesSaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_DAMAGES: 
        return [widgetBranchSpecificLastDamagesSaga]
    case WIDGET_TYPES.LAST_REPAIR_AUTHORIZATIONS:
        return [widgetLastRepairAuthorizationsSaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_REPAIR_AUTHORIZATIONS: 
        return [widgetBranchSpecificLastRepairAuthorizationsSaga]
    case WIDGET_TYPES.DAMAGE_VEHICLE:
        return [widgetDamageVehicleSaga]
    case WIDGET_TYPES.DAMAGE_VEHICLE_OWNER:
        return [widgetDamageVehicleOwnerSaga]
    case WIDGET_TYPES.DAMAGE_ACTIVITY_LOG:
        return [widgetDamageActivityLogSaga]
    case WIDGET_TYPES.COMMISSION_LAWFIRM:
        return [widgetDamageCommissionedLawfirmSaga]
    case WIDGET_TYPES.COMMISSION_EXPERT:
        return [widgetDamageCommissionedExpertSaga]
    case WIDGET_TYPES.DAMAGE_TASK_BULLET_LIST:
        return [widgetDamageTaskBulletListSaga]
    case WIDGET_TYPES.INBOX_MESSAGE_LIST:
        return [widgetInboxMessageList]
    case WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_LIST:
        return [widgetDamageInboxMessageList]
    case WIDGET_TYPES.TASK_COMMENTS:
        return [widgetTaskCommentsSaga]
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_AUTHORIZATION_FORM:
    case WIDGET_TYPES.DAMAGE_REPAIR_AUTHORIZATION_DISPLAY:
        return [widgetTaskRepairAuthorizationSaga]
    case WIDGET_TYPES.DAMAGE_INSURANCES:
        return [widgetDamageInsuranceSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_LIST:
        return [widgetWorkflowInboxMessageList]
    case WIDGET_TYPES.WORKFLOW_PANEL_TASK_COMMENTS:
        return [widgetWorkflowPanelTaskCommentsSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_AKTEN_STATUS:
        return [widgetWorkflowPanelAktenStatusSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_LIABILITY_REQUEST:
        return [widgetWorkflowPanelLiabilityRequestSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION:
        return [widgetWorkflowPanelReceiptConfirmationSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION:
        return [widgetWorkflowPanelInsuranceInformationSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE:
        return [widgetWorkflowPanelRepairClearanceSaga]
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES:
        return [widgetWorkflowPanelInvoicesSaga]
    case WIDGET_TYPES.DAMAGE_AK_LIABILITY_REQUEST: 
        return [widgetDamageAKLawfirmSaga]
    case WIDGET_TYPES.DAMAGE_AK_INVOICES: 
    case WIDGET_TYPES.DAMAGE_MANAGER_INVOICES_LIST: 
    case WIDGET_TYPES.TASK_DAMAGE_INVOICES: 
        return [widgetDamageAKInvoicesSaga]
    case WIDGET_TYPES.DAMAGE_AK_REPAIR_CLEARANCE: 
        return [widgetDamageAKRepairClearanceSaga]
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_GESAMT:
        return [widgetControllingVacantPositionsTotalSaga]
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
        return [widgetControllingVacantPositionsLiabilitySaga]
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_KASKO:
        return [widgetControllingVacantPositionsKaskoSaga]
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
        return [widgetControllingVacantPositionsDamagesTableSaga]
    case WIDGET_TYPES.CONTROLLING_STATISTICS_DAMAGE_COUNT:
        return [widgetControllingStatisticsDamagesCountSaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST:
        return [widgetBranchSpecificInboxMessageList]
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT:
        return [widgetBranchSpecificControllingStatisticsDamagesCountSaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT:
        return [widgetBranchSpecificControllingVacantPositionsTotalSaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
        return [widgetBranchSpecificControllingVacantPositionsLiabilitySaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO:
        return [widgetBranchSpecificControllingVacantPositionsKaskoSaga]
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
        return [widgetBranchSpecificControllingVacantPositionsDamagesTableSaga]
    case WIDGET_TYPES.CONTROLLING_GLOBAL_VACANT_POSITIONS:
        return [widgetControllingGlobalVacantPositionsSaga]
    case WIDGET_TYPES.CONTROLLING_GLOBAL_DAMAGES_BAR_CHART:
        return [widgetControllingGlobalDamagesSaga]
    case WIDGET_TYPES.TASK_DAMAGE_BASIC_DATA_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_STARTED_AT: 
        return [widgetTaskDamageBasicDataSaga]
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_DATA_FORM:
        return [widgetTaskVehicleDataSaga]
    case WIDGET_TYPES.TASK_DAMAGE_UPDATE_CUSTOMER:
        return [widgetTaskDamageCustomerDataSaga]
    case WIDGET_TYPES.TASK_DAMAGE_COMPREHENSIVE_INSURANCE_DATA:
        return [widgetTaskDamageComprehensiveInsuranceDataSaga]
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_PROPRIETOR_DATA: 
        return [widgetTaskVehicleProprietorDataSaga]
    case WIDGET_TYPES.DAMAGE_MANAGER_CREATE_INVOICE:
    case WIDGET_TYPES.TASK_DAMAGE_CREATE_INVOICE:
        return [widgetTaskCreateInvoiceSaga]
    case WIDGET_TYPES.TASK_DAMAGE_ACCIDENT_REPORT_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_WITNESS_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_DRIVER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_VEHICLE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_POLICE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_KEEPER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_LIABILITY_INSURANCE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_COMBINED_LIABILITY_DATA:
    case WIDGET_TYPES.DAMAGE_ACCIDENT_REPORT:
        return [widgetTaskLiabilityDataSaga]
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_SCHEDULE:
        return [widgetTaskDamageCustomerDataSaga, widgetDamageRepairScheduleSaga]
    case WIDGET_TYPES.DAMAGE_SHARE:
        return [widgetDamageShareSaga]
    default:
        return [widgetDefaultLoadingSaga]
    }
}

export const sendWidgetDataSagas = (type) => {
    switch (type) {
    default:
        return []
    }
}