import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WidgetErrorMessage, WidgetInformation } from 'application/components/widgets/_common'
import { PushBottom48, PushBottom16 } from 'application/components/pages/_common'
import { Headline3, Headline1 } from 'application/components/fragments/typography'
import { EditableInvoice } from 'application/components/building_blocks/invoices'

const DamageAKInvoices = (props) => {
    const {t} = useTranslation()

    const {
        payload, 
        loadingFailed, 
        isLoaded,
        match
    } = props

    const {damageId} = match.params

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    if(isLoaded && !payload.hasData){
        return <WidgetInformation>{t('No information regarding invoices yet')}</WidgetInformation>
    }

    const {data} = payload
    const invoices = data.invoices

    return <React.Fragment>
        <Headline1>{t('Invoices')}: </Headline1>
        <PushBottom16 />
        {
            invoices.length > 0 ? invoices.map((iv, i) => {
                return <React.Fragment key={i}>
                    <EditableInvoice 
                        parentId={damageId}
                        invoiceData={iv} />
                    <PushBottom48 />
                </React.Fragment>
            }) : <Headline3>
                {t('No invoices at the moment')}
            </Headline3>

        }
    </React.Fragment>
}

DamageAKInvoices.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const DamageAKInvoicesComponent = withRouter(DamageAKInvoices)