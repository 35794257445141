import { isDefined } from 'application/common/data_helpers'
import { ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledActivityLink } from '../link'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const DamageLink = (
    { activity }
) => {
    const {t} = useTranslation()
    const damage = activity.entity
    const {damageManagerLink} = useApplicationLinks()
    if(!isDefined(damage)){
        return null
    }

    const damageUrl = damageManagerLink(
        ROUTES.DAMAGE_MANAGER, 
        damage.id
    )

    return <StyledActivityLink
        to={damageUrl}
    >
        &raquo; {t('View damage')}
    </StyledActivityLink>
}