import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateInsurances } from 'application/redux/actions/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { INSURANCE_TYPE } from 'application/constants/insurances'


const getInsuranceEndpointByType = (insuranceType) => {
    switch(insuranceType){
    case INSURANCE_TYPE.LEGAL_PROTECTION:
        return api.gateway.legalProtectionInsurances.get
    case INSURANCE_TYPE.INSURANCE:
    default: 
        return api.gateway.insurances.getInsurances
    }
}



function* getInsurancesSaga(action) {
    const {insuranceType} = action
    try {
        const insurancesResponse = yield resourceCall(
            getInsuranceEndpointByType(insuranceType),
            {
                offset: 0,
                limit: 150
            }
        )
        yield put(
            populateInsurances(
                insuranceType,
                insurancesResponse.companies
            )
        )
    } catch (e) {
        yield put(
            populateInsurances(
                insuranceType,
                []
            )
        )
        yield handleError(e, action)
    }
}

export function* getInsurancesWatcher() {
    yield takeLeading(
        ACTIONS.COMMON_GET_INSURANCES_REQUEST, 
        getInsurancesSaga
    )
}