import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const myTasksReducer =  (state = defaultStore.pages.myTasks, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.myTasks
    }
    case ACTIONS.MY_TASKS_GET_ALL_TASKS_REQUEST:
        return {
            ...state,
            allTasks: {
                ...state.allTasks,
                tasksListLoading: true
            }
        }
    case ACTIONS.MY_TASKS_GET_ALL_MY_TASKS_REQUEST:
        return {
            ...state,
            userTasks: {
                ...state.userTasks,
                tasksListLoading: true
            }
        }
    case ACTIONS.MY_TASKS_POPULATE_ALL_TASKS:
        return {
            ...state,
            allTasks: {
                ...state.allTasks,
                tasksListLoading: false,
                tasksListLoaded: true,
                tasksList: action.tasksList,
                tasksListTotal: action.tasksListTotal
            }
        }
    case ACTIONS.MY_TASKS_POPULATE_ALL_MY_TASKS:
        return {
            ...state,
            userTasks: {
                ...state.userTasks,
                tasksListLoading: false,
                tasksListLoaded: true,
                tasksList: action.tasksList,
                tasksListTotal: action.tasksListTotal
            }
        }
    default:
        return state
    }
}
