import React from 'react'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { useTranslation } from 'react-i18next'
import { COMMISSION_STATE } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'
import { InfoBox } from 'application/components/controls/info_box'
import { ActionButton } from 'application/components/controls/action_button'
import { Spacer } from 'application/components/pages/_common'

export const DamageManagerInboxTabbarButtonsComponent = ({ 
    onCreateMessage, 
    damageLoaded, 
    damage, 
    hasLawfirm, 
    lawfirmState,
    onRefreshLawfirm
}) => {
    const { t } = useTranslation()
    
    return damageLoaded && hasLawfirm && lawfirmState === COMMISSION_STATE.ESTABLISHED
        ? <IconButtonWithLabel
            onButtonClick={
                () => onCreateMessage({ damage })
            }
            label={t('New message for lawfirm')}
            iconKey='chat'
        />
        : damageLoaded && hasLawfirm && lawfirmState === COMMISSION_STATE.PENDING 
            ? <InfoBox
                direction='row' 
                cssMargin='0'
            >
                <Paragraph>
                    {t('The lawfirm has not yet accepted the commission.')}
                </Paragraph>
    
                <Spacer />
                <ActionButton 
                    buttonText={t('Refresh')} 
                    onButtonClick={() => {onRefreshLawfirm(damage.id)}} 
                />
            </InfoBox>
            : null
}