import React from 'react'
import * as Fragments from './fragments'
import {ActivityDamageLink} from './_common/damage_activity_link'




export const ExpertCommunicationDekraInitiatedMetaContent = (
    {
        notification, 
        onLinkClick, 
        inline
    }
) => {
    const {payload} = notification

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <ActivityDamageLink 
            onClick={onLinkClick} 
            damageId={payload.damage.id}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}