import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { BranchFilterPill } from 'application/components/building_blocks/filters/compare_branches_filter/filter_pill'
import { PureBranchSelect } from 'application/components/controls/form/branch_select'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box'
import { Label } from 'application/components/fragments/typography'
import { setQuery, useQuery } from 'application/components/hooks/use_query'
import * as filters from 'application/filters'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'


export const CompareBranchesFilterComponent = ({
    routeParameters,
    filterDefinition = filters.controlling.compareBranches,
    menuPlacement='top',
    onChange=()=>{},
    disabled = false,
    accessibleBranches = []
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const query = useQuery()


    const { FILTERS, OFFSET, LIMIT} = routeParameters
    const { COMPARE_BRANCHES } = FILTERS

    const defaultSelectedBranchIds = query.getStringParam(COMPARE_BRANCHES, filterDefinition.defaultValue).split('|')

    const [selectedBranches, setSelectedBranches] = useState(accessibleBranches.filter(ab => defaultSelectedBranchIds.includes(ab.id)))
    const [allSelected, setAllSelected] = useState(defaultSelectedBranchIds[0] === 'All')

    const setQueryValue = value => {
        setQuery(
            navigate,
            location, 
            location.pathname,
            [
                {
                    key: filterDefinition.filterKey,
                    value
                }
            ],
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
    }

    const handleOnAdd = (option) => {
        const newSelectedBranches = [...selectedBranches, option.branch]
        const newQuery = newSelectedBranches.map(b => b.id).join('|')
        setQueryValue(newQuery)
        setSelectedBranches(newSelectedBranches)
        onChange(option.value)
    }

    const handleOnRemove = (branch) => {
        const newSelectedBranches = selectedBranches.filter(b => b.id !== branch.id)

        if(newSelectedBranches.length === 0){
            setAllSelected(true)
            setQueryValue('All')
        }else{
            const newQuery = newSelectedBranches.map(b => b.id).join('|')
            setQueryValue(newQuery)
        }

        setSelectedBranches(newSelectedBranches)
        onChange(branch.id)
    }

    const handleCheckBox = isChecked => {
        setQueryValue(isChecked ? 'All' : '')
        setSelectedBranches([])
        setAllSelected(isChecked)
    }

    return <React.Fragment>

        <FilterFragment.FilterRow>
            <FilterFragment.FilterLabel className='filterLabel'>
                <Label cssMargin='0 16px 0 0'>
                    {t(filterDefinition.label)}
                </Label>
            </FilterFragment.FilterLabel>
            <FilterFragment.FilterAction
                maxWidth='266px'
            >
                {
                    !allSelected 
                        ? <PureBranchSelect
                            onChange={handleOnAdd}
                            disabled={disabled}
                            menuPlacement={menuPlacement}
                            ignoreBranchIds={selectedBranches.map(sb => sb.id)}
                            placeholder='Add branch'
                        />
                        : <PureCheckBox
                            label={t('All branches')}
                            checked={allSelected}
                            onChange={handleCheckBox}
                            disabled={disabled}
                        />
                }
            </FilterFragment.FilterAction>
        </FilterFragment.FilterRow>

        {
            !allSelected && <FilterFragment.FilterRow>
                <FilterFragment.FilterLabel className='filterLabel'>
                    <Label cssMargin='0 16px 0 0'>
                        {t('Selected branches')}
                    </Label>
                </FilterFragment.FilterLabel>
                <FilterFragment.FilterAction flexWrap='wrap'>
                    {
                        selectedBranches.map(branch => {
                            return <BranchFilterPill
                                key={branch.id}
                                branch={branch}
                                onRemove={handleOnRemove}
                                disabled={disabled}
                            />
                        })
                    }
                </FilterFragment.FilterAction>
            </FilterFragment.FilterRow>
        }
    
    </React.Fragment>
}

CompareBranchesFilterComponent.propTypes = {
    pagination: PropTypes.object
}