import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextArea, TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { PushBottom16 } from 'application/components/pages/_common'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { FlexBox } from 'application/components/fragments/flex_box'



const CreateMessageWorkflowPanel = ({
    onCreateMessageWorkflowPanel,
    dialog,
    abort,
    setAbortBlocked,
    setDataChanged
}) => {
    const { t } = useTranslation()

    const defaultValues = {
        subject: '',
        body: ''
    }

    const { 
        register,
        errors,
        handleSubmit,
        getValues, 
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const [attachments, setAttachments] = useState([])

    const onSubmitSuccess = () => {
        const payload =             {
            ...getValues(),
            attachments
        }
        const metaData = { 
            damageId: dialog.payload?.damage?.id
        }
        onCreateMessageWorkflowPanel(
            dialog.name, 
            payload,
            metaData
        )
    }

    const setForm = () => {
        onDataChange()
        updateFormState()
    }
    return <React.Fragment>
        <DialogMainContent>
            <TextInput
                label={t('Subject')}
                name='subject'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.subject}
                onChange={setForm}
            />
            <TextArea
                label={t('Your message')}
                name='body'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.body}
            />
            <FlexBox flexDirection='column'>
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={attachments}
                    setFiles={setAttachments}
                />
                <PushBottom16 />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Send'
        />
    </React.Fragment>
    
}

export const CreateMessageWorkflowPanelComponent = withDialogDataLoader(
    CreateMessageWorkflowPanel
)