import React from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneNumberInput, TextInput } from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { validationMethods } from 'application/common'
import { AddressDataPartialForm } from '../_partial_forms'

const DamageOpponentKeeperPartialForm = (
    {
        formHook, 
        defaultValues
    }
) => {
    const {t} = useTranslation()
    
    const {
        register, 
        errors, 
        setValue,
        formStateValues,
        updateFormState,
        control,
        nameRequired = false
    } = formHook

    return <React.Fragment>
        <TwoColumns 
            columnLeft={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={defaultValues.keeper.isCompany}
                    name='keeper.isCompany'
                    label={t('Keeper is company')}
                    onChange={updateFormState}
                />
            }
        />
        {
            formStateValues.keeper?.isCompany ?
                <TextInput 
                    label={t('Company name')}
                    name='keeper.companyName'
                    register={register}
                    validate={
                        formStateValues.keeper?.isCompany 
                        && nameRequired 
                            ? {notEmpty: validationMethods.notEmpty} 
                            : null
                    }
                    error={errors.keeper?.companyName}
                    onChange={updateFormState}
                />
                : <React.Fragment>
                    <TwoColumns
                        columnLeft={
                            <TextInput 
                                label={t('First name')}
                                name='keeper.firstName'
                                register={register}
                                validate={
                                    formStateValues.keeper?.isCompany || !nameRequired 
                                        ? null : 
                                        {notEmpty: validationMethods.notEmpty}
                                }
                                error={errors.keeper?.firstName}
                                onChange={updateFormState}
                            />
                        }

                        columnRight={
                            <TextInput 
                                label={t('Last name')}
                                name='keeper.lastName'
                                register={register}
                                validate={
                                    formStateValues.keeper?.isCompany || !nameRequired 
                                        ? null 
                                        : {notEmpty: validationMethods.notEmpty}
                                }
                                error={errors.keeper?.lastName}
                                onChange={updateFormState}
                            />
                        }
                    />
                </React.Fragment>
        }

        <AddressDataPartialForm
            register={register}
            errorNameStreet={errors.keeper?.address?.line1}
            errorNamePostalCode={errors.keeper?.address?.postalCode}
            errorNameCity={errors.keeper?.address?.locality}
            onChange={updateFormState}
            nameStreet = 'keeper.address.line1'
            namePostalCode = 'keeper.address.postalCode'
            nameCity = 'keeper.address.locality'
            setValue={setValue}
        />

        <PhoneNumberInput
            defaultValue={defaultValues.keeper.contactInfo.phoneNumber}
            validate={null}
            control={control}
            label={t('Phone number')}
            name='keeper.contactInfo.phoneNumber'
            register={register}
            error={errors.keeper?.contactInfo?.phoneNumber}
            onChange={updateFormState}
            setValue={setValue}
            allowLandLine={true}
        />

  
    </React.Fragment>
}

export { DamageOpponentKeeperPartialForm }