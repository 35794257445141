import * as Fragments from '../fragments'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const ActivityDamageLink = (
    {
        onClick,
        damageId = null,
        inline = false,
        slug = ''
    }
) => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const damageLink =  internalAccountLink(
        `/damages/${damageId}${slug}`
    )

    return  <Fragments.ActivityLink 
        inline={inline}
    >
        <Link
            onClick={onClick} 
            to={damageLink}
        >
            {t('jump to damage')}
        </Link>
    </Fragments.ActivityLink>
}

ActivityDamageLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    damageId: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    slug: PropTypes.string
}