import React from 'react'
import { DamageManagerContainer } from '../container'
import { WIDGET_CONTEXTS, ROUTES } from 'application/constants'
import { SubPageLayout } from 'application/components/pages/_common'
import { WidgetArea } from 'application/components/building_blocks/widget_area'
import { DamageManagerLawyerCommunicationSidebar } from './sidebar'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Navigate } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { withRouter } from 'application/components/higher_order_components/with_router'


const DamageManagerLawyerKommunikation = (
    {
        hasLawfirm,
        damageLoaded
    }
) => {
    const shouldRedirect = damageLoaded && !hasLawfirm
    const { damageManagerLink } = useApplicationLinks()
    //redirect to overview to avoid unnessesary widget loading attempts    
    if (shouldRedirect) {
        return <Navigate to={
            damageManagerLink(
                ROUTES.DAMAGE_MANAGER
            )
        } />
    }

    return <DamageManagerContainer
        titleSegments={['Invoices']}
        headline='Lawyer communication'
        sidebar={<DamageManagerLawyerCommunicationSidebar />}
    >
        <SubPageLayout
            paddingLeft='16px'
            paddingRight='16px'
        >
            {
                damageLoaded && <FlexBox>
                    <WidgetArea
                        successively={false}
                        widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION}
                    />
                </FlexBox>
            }
        </SubPageLayout>
    </DamageManagerContainer>
}

export const LawyerCommunicationComponent = withRouter(DamageManagerLawyerKommunikation)