import { put } from 'redux-saga/effects'

import { getSelectedTaskWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'

export function* refreshWorkflowPanelSelectedTaskSaga(action) {
    const { sharedResourceId, mainId, taskId } = action.metaData
    const taskIdPayload = action.payload.id

    const srId = sharedResourceId !== undefined ? sharedResourceId : mainId
    const tId = taskId !== undefined ? taskId : taskIdPayload

    yield put(getSelectedTaskWorkflowPanel(tId, srId))
}