import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { put } from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetWorkflowPanelAktenStatusSaga(action) {
    const response = yield resourceCall(
        api.gateway.lawyerCommunication.getLawfirm
    )
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {formData: response}))
}