import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    border: 1px solid ${props => props.theme.color.gray70};
    color: ${props => props.theme.color.gray70};
    background: #fff;
    border-radius: 2px;
    height: 30px;
    display: flex;
    flex-direction: row;
    width: max-content;
`

const FirstElement = styled.div`
    width: 17px;
    background-color: #4261b5;
    height: 30px;
    margin-top: -1px;
    margin-left: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
`

const EuroImage = styled.img`

    height: 20px;
`

const Element = styled.span`
    font-family: 'FE', sans-serif !important;
    height: 28px;
    font-size: 16px;
    padding: 4px 6px 0;
    word-break: keep-all;
    white-space: nowrap;
    text-transform: uppercase;
`


export const LicensePlate = ({
    licensePlate = null,
    fontSize
}) => {
    return <Container>
        <FirstElement>
            <EuroImage src={'/images/kfz-de.png'} />
        </FirstElement>
        <Element>{licensePlate}</Element>
    </Container>
}