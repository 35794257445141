import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { IndicatorWithColor } from 'application/components/controls/indicator/override_color_indicator'
import {ContextMenu} from 'application/components/controls/context_menu'
import {ContextMenuItem} from 'application/components/controls/context_menu_item'
import {ActionButton} from 'application/components/controls/action_button'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import {LanguageSwitchHoc} from 'application/components/building_blocks/language_switch/hoc'
import {VehicleInfo} from 'application/components/controls/vehicle_info'
import { StyledTitleBar, Cell } from 'application/components/pages/internal/_title_bars/_common'
import { MetaInfo } from './meta_info'
import { useParams } from 'react-router-dom'
import { Spacer } from 'application/components/pages/_common'
import { TitlebarAccountLogo } from 'application/components/building_blocks/title_bar_account_logo'

export const TitleBarWorkflowPanelComponent = ({
    damageLoaded,
    lawfirm,
    lawfirmLoaded,
    onLogoutRequest,
    onCreateMessage,
    onLoad,
    vehicle = null,
    damage,
    account,
    branch
}) => {
    const {t} = useTranslation()
    const sharedResourceId = useParams().sharedResourceId

    const closeOptions = [
        {
            iconkey: 'widgets',
            label: t('Logout'),
            isLinked: false,
            onClick: () => {
                onLogoutRequest(sharedResourceId)
            }
        },
    ] 

    useEffect(() => {
        onLoad()
    }, [sharedResourceId, onLoad])

    return  <StyledTitleBar>
        <Cell>
            <NavLink to={ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/form'}>
                <TitlebarAccountLogo 
                    accountId={account?.id}
                />
            </NavLink>
            <Spacer basis='16px' />
            <LanguageSwitchHoc />
        </Cell>
                
        <Spacer basis='40px' />
           
        <Cell>
            <VehicleInfo 
                vehicle={vehicle} 
                damageLoaded={damageLoaded} 
            />
            {
                damageLoaded && <MetaInfo
                    damage={damage}
                    account={account}
                    branch={branch}
                />
            }
        </Cell>

        <Spacer />

        <Cell>
            <ActionButton 
                marginRight='16px' 
                onButtonClick={onCreateMessage} 
                buttonText={t('Message')} 
                icon='add' 
            />
            {
                lawfirmLoaded && <IndicatorWithColor 
                    iconKey='person' 
                    text={lawfirm.name} 
                    overrideColorWith='gray70' 
                />
            }
            <ContextMenu iconKey="close">
                {
                    closeOptions.map(
                        (option, index) => {
                            return (
                                <ContextMenuItem
                                    key={index}
                                    iconKey={option.iconkey}
                                    title={option.label}
                                    isLinked={option.isLinked}
                                    onListElementClick={option.onClick}
                                >
                                    {option.label}
                                </ContextMenuItem>
                            )
                        }
                    )
                }
            </ContextMenu>

        </Cell>
    </StyledTitleBar>
}