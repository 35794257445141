import { ACTIONS } from 'application/constants'

export const getCustomersRequest = (
    offset, 
    limit, 
    orderBy,
    direction,
    filterQuery = []
) => ({
    type: ACTIONS.CUSTOMERS_GET_CUSTOMERS_REQUEST,
    offset, 
    limit, 
    orderBy,
    direction,
    filterQuery
})


export const populateCustomersList = (customersList, customersListTotal) => ({
    type: ACTIONS.CUSTOMERS_POPULATE_CUSTOMERS_LIST,
    customersList,
    customersListTotal,
})

export const sendBatchImportRequest = (customersList) => ({
    type: ACTIONS.CUSTOMERS_SEND_BATCH_IMPORT_REQUEST,
    customersList
})

export const updateBatchImportProgress = (interator = 1) => ({
    type: ACTIONS.CUSTOMERS_SEND_UPDATE_BATCH_PROGRESS,
    interator
})

export const batchImportComplete = () => ({
    type: ACTIONS.CUSTOMERS_BATCH_IMPORT_COMPLETE,
})

export const batchImportReset = () => ({
    type: ACTIONS.CUSTOMERS_BATCH_IMPORT_RESET,
})
