import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommissionOtherExpertPartialForm } from 'application/components/forms/_partial_forms'
import { CheckboxBar } from 'application/components/controls/checkbox_bar'
import { FormHeadline } from 'application/components/fragments/typography'
import { PowerOfAttorneyForm } from 'application/components/forms/power_of_attorney'
import { PushBottom16 } from 'application/components/pages/_common'
import { exists, isDefined } from 'application/common/data_helpers'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'
import { useSelector } from 'react-redux'
import { useLawfirms } from 'application/components/hooks/use_lawfirms'
import { LawfirmSelect } from 'application/components/controls/form/lawfirm_select'
import { CommissionDekraPartialForm } from 'application/components/forms/_partial_forms/commission_dekra_partial_form'
import { CommissionDekraCustomerPartialForm } from 'application/components/forms/_partial_forms/commission_dekra_customer_partial_form'
import { useModules } from 'application/components/hooks/use_modules'
import { MODULE_NAME } from 'application/constants'


const getExpertData = (
    name = '', 
    email = '',
    inspectionSite = null,
    inspectionDate = '',
    inspectionInfoName = '',
    webakteId = ''
) => {

    return  {
        name: name,
        email: email,
        inspectionSite,
        inspectionDate,
        inspectionInfoName,
        webakteId,
        address: inspectionSite,
        contact: {
            email: inspectionSite.email,
            phoneNumber: ''
        }
    }
}



export const getDeclarationOfAssignmentPDFValues = (
    expertData,
    customer, 
    damage,
    branch,
    insuranceCompanies = []
) => {
    const insuranceId = damage?.liabilityData?.liabilityInsurance?.id
    const insuranceNameGiven = damage?.liabilityData?.liabilityInsurance?.name

    const insuranceName = exists(insuranceId) &&  insuranceCompanies.length > 0 
        ? insuranceCompanies.find( ic => ic.id === insuranceId).name
        : isDefined(insuranceNameGiven)
            ? insuranceNameGiven
            : ''

    return {
        expertData: {
            ...expertData,
            contact: {
                email: expertData?.email || ''
            }
        },
        damage: {
            date: damage?.incidentDate || '',
            time: '',
            location: damage?.liabilityData?.accidentReport?.location?.description || '', 
            reportNumber: '', 
            claimNumber: damage?.liabilityData?.liabilityClaim?.number|| '',
            amount: '' 
        },
        client: { 
            firstName: customer?.firstName || '',
            lastName: customer?.lastName || '',
            street: customer?.address?.line1 || '',
            postalCode: customer?.address?.postalCode || '',
            locality: customer?.address?.locality || '',
            eligibleForInputTaxDeduction: customer?.isEntitledToTaxDeduction || '',
            vehicle: {  //damage.vehicle
                licensePlate: damage?.vehicle?.licensePlate || '',
                chassisNumber: damage?.vehicle?.chassisNumber || '',
                make: damage?.vehicle?.make || '',
                model: damage?.vehicle?.model || ''
            }
        }, 
        tortFeasor: {
            firstName: damage?.liabilityData?.vehicleKeeper?.firstName || '',     
            lastName: damage?.liabilityData?.vehicleKeeper?.lastName || '',     
            street: damage?.liabilityData?.vehicleKeeper?.address?.line1 || '',     
            postalCode: damage?.liabilityData?.vehicleKeeper?.address?.postalCode || '',     
            locality: damage?.liabilityData?.vehicleKeeper?.address?.locality || '',     
            vehicle: {
                licensePlate:  damage?.liabilityData?.vehicle?.licenseplate || '', 
                chassisNumber: damage?.liabilityData?.vehicle?.chassisNumber || '',
                make: damage?.liabilityData?.vehicle?.make || '',
                model: damage?.liabilityData?.vehicle?.model || '',
                insurance: {
                    name: insuranceName,
                    claimNumber: damage?.liabilityData?.liabilityClaim?.number || '',
                    certificateNumber: damage?.liabilityData?.liabilityInsurance?.certificateNumber || ''
                }
            },
            location: damage?.liabilityData?.accidentReport?.location?.description || ''
        },
        locality:  branch?.address?.locality || '',
        signatures: []
    }
}


export const Step1 = ({
    formHook,
    commissionExpert,
    setCommissionExpert,
    commissionLawfirm,
    setCommissionLawfirm,
    setCommissionOtherFiles,
    commissionOtherFiles,
    
    lawfirmFormHook,
    setLawfirmFiles,
    lawfirmFiles,

    branch,
    selectedLawfirm,
    tempDamageData,
    customer,
    setCommissionDekra,
    commissionDekra,
    commissionDekraFiles,
    setCommissionDekraFiles,
    commissionDekraFormHook
}) => {
    const {t} = useTranslation()
    const hasSelectedLawfirm = exists(lawfirmFormHook.getValues().lawfirm)

    const {
        loaded,
        lawfirmsList
    } = useLawfirms()

    const {
        isModuleApplied
    } = useModules()
    
    const expertData = getExpertData(
        formHook.getValues().name,
        formHook.getValues().email,
        formHook.getValues().inspectionSite,
        formHook.getValues().inspectionDate,
        formHook.getValues().inspectionInfoName,
        formHook.getValues().lawfirm,
        formHook.getValues().inspectionSite.address
    )

    const insuranceCompanies = useSelector(state => state?.common?.insurance?.companies || [])

    const pdfValues = getDeclarationOfAssignmentPDFValues(
        expertData,
        tempDamageData?.customer,
        tempDamageData,
        branch,
        insuranceCompanies
    )


    return <React.Fragment>
        <CheckboxBar 
            label={t('Hand over to expert')} 
            checked={commissionExpert}
            disabled={commissionDekra}
            onChange={ 
                ()=>{
                    setCommissionExpert(!commissionExpert)
                    setCommissionDekra(false)
                } 
            }  
        />
        {
            commissionExpert && <CommissionOtherExpertPartialForm 
                formHook={formHook}
                usedInDialog={false}
                pdfValues={pdfValues}
                setCommissionOtherFiles={setCommissionOtherFiles}
                commissionOtherFiles={commissionOtherFiles}
            />
        }

        {
            isModuleApplied(MODULE_NAME.DEKRA_COMMISSION) && <React.Fragment>
                <CheckboxBar 
                    label={t('Hand over to DEKRA')} 
                    checked={commissionDekra} 
                    disabled={commissionExpert}
                    onChange={ 
                        ()=>{
                            setCommissionDekra(!commissionDekra)
                            setCommissionExpert(false)
                        } 
                    }  
                />

                {
                    commissionDekra && <React.Fragment>
                        <CommissionDekraPartialForm 
                            formHook={commissionDekraFormHook}
                        />
                        <CommissionDekraCustomerPartialForm 
                            customer={customer}
                            formHook={commissionDekraFormHook}
                        />
                        <TaskUploader
                            onFileAdded={() => { }}
                            disableActiveState={true}                   
                            files={commissionDekraFiles}
                            setFiles={setCommissionDekraFiles}
                            label={t('Documents for DEKRA')}
                        />  
                        <PushBottom16 />
                    </React.Fragment>
                }
            </React.Fragment>
        }

        {
            loaded && lawfirmsList.length > 0 && <React.Fragment>
                <CheckboxBar 
                    label={t('Hand over to lawfirm')} 
                    checked={commissionLawfirm} 
                    onChange={ 
                        ()=>{
                            setCommissionLawfirm(!commissionLawfirm)
                        } 
                    }  
                />

                {
                    commissionLawfirm && <React.Fragment>
                        <FormHeadline>Kanzlei auswählen </FormHeadline>
                        <LawfirmSelect 
                            formhook={lawfirmFormHook}
                            initialLawfirmOption={null}
                        />
                        <PushBottom16 />
                        {
                            commissionLawfirm 
                                    && hasSelectedLawfirm
                                    && <React.Fragment>  
                                        <PowerOfAttorneyForm
                                            lawfirm = {selectedLawfirm}
                                            customer = {customer}
                                            branch = {branch}
                                            files={lawfirmFiles}
                                            setFiles={setLawfirmFiles}
                                            damage={tempDamageData}
                                            submitButtonText='Create power of attorney'
                                        />
                                        <TaskUploader
                                            onFileAdded={() => { }}
                                            disableActiveState={true}                   
                                            files={lawfirmFiles}
                                            setFiles={setLawfirmFiles}
                                            label={t('Documents for the lawfirm')}
                                        />  
                                        <PushBottom16/>
                                    </React.Fragment>
                        } 
                    </React.Fragment>
                }
            </React.Fragment>
        }

    </React.Fragment>
}