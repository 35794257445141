import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { TASK_STATES } from 'application/constants'
import { IndicatorWithColor } from 'application/components/controls/indicator/override_color_indicator'
import { StyledTaskHeadline, StyledTaskHighlightBar, StyledAssigneeInfoColumn, StyledTaskInfo, StyledTaskInfoContent, StyledDeadlineInfo, StyledTaskInfoRow, StyledTaskHighlightLinkButton, StyledGraySpace, StyledTaskInfoLabel} from './fragments'
import { Select } from 'application/components/controls/form'
import { StateName } from 'application/components/name_mappings/state_name'
import { LinkButton } from 'application/components/fragments/typography'
import { nameHelpers } from 'application/common'
import { exists, isDefined, isNull } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { DateComponent } from 'application/components/controls/date'
import { TaskOperationsInternal } from '../task_operations_internal'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { withTheme } from 'styled-components'
import { SimpleIcon } from '../simple_icon'
import { TaskPriorityDisplay } from 'application/components/controls/task_priority_display/component'

const TaskHighlightBar = ({
    state,
    priority,
    colorIndicator = 'gray70',
    canAssign = true,
    onAssignUser,
    onSearchUser,
    userList = [],
    userListLoading,
    assignee,
    currentUser,
    openAssignUserDialog,
    taskTitle,
    branchId,
    damageId,
    task,
    onDeleteTask,
    onEditTask,
    damage,
    taskExternal = false,
    theme
}) => {
    const hasDeadline = !isNull(task.deadline)
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    const {t} = useTranslation()

    const {
        register, 
        errors, 
        setValue
    } = useStatefulForm()

    const [defaultUserOption, setDefaultUserOption] = useState(
        exists(assignee) 
            ? {
                value: assignee.userId,
                label: nameHelpers.getFullName(assignee),
                firstName: assignee.firstName,
                lastName: assignee.lastName
            } 
            : null
    )

    const usersOptions = userList.length > 0
        ? 
        userList.map((u) => ({
            value: u.id,
            label: `${nameHelpers.getFullName(u)} (${u.email})`,
            firstName: u.firstName,
            lastName: u.lastName
        })) 
        : 
        []

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(search)
        }  
    }

    const handleAssign = user => {
        onAssignUser(
            user?.value || null, 
            user?.firstName || null, 
            user?.lastName || null
        )
    }

    const showAssignMeButton = canAssign && (assignee === undefined || assignee.userId !== currentUser.id)
   
    const handleAssignMe = () => {
        onAssignUser(
            currentUser.id, 
            currentUser.firstName, 
            currentUser.lastName
        )
        setDefaultUserOption({
            value: currentUser.id,
            label: nameHelpers.getFullName(currentUser),
            firstName: currentUser.firstName,
            lastName: currentUser.lastName
        })
    }

    useEffect(
        () => {   
      
            setDefaultUserOption(
                !exists(assignee)
                    ? null
                    : {
                        value: assignee.userId,
                        label: nameHelpers.getFullName(assignee),
                        firstName: assignee.firstName,
                        lastName: assignee.lastName
                    }
            )
        }, [assignee])

    const taskIsNotDone = task.state !== TASK_STATES.DONE

    return <StyledTaskHighlightBar>
        <StyledGraySpace />
        <StyledAssigneeInfoColumn>
            <FlexBox 
                alignItems='center'
                height='50px'
            >
                {t('Task')}
            </FlexBox>
            <FlexBox height='8px'/>
            <FlexBox>
                {t('Assignee')}
            </FlexBox>
        </StyledAssigneeInfoColumn>
        <FlexBox 
            height='inherit' 
            cssRules='*border: 1px dotted green;'
            flexDirection='column'
            paddingRight='32px;'
        >
            <FlexBox 
                justifyContent='space-between'
                height='50px'
                alignItems='center'
            >
                <FlexBox
                    alignItems='center'
                    width='auto'
                >
                    <FlexBox                            
                        onClick={
                            taskIsNotDone 
                                ? () => onEditTask(
                                    task, 
                                    damageId, 
                                    damage
                                )
                                : () => {} 
                        } 
                        cssRules={taskIsNotDone ? 'cursor: pointer;' : ''}
                    >
                        <StyledTaskHeadline zIndex='1'>
                            {task.title} 
                        </StyledTaskHeadline>
                        {
                            taskIsNotDone && <SimpleIcon />
                        }
                    </FlexBox>
                </FlexBox>
                <FlexBox width='auto'>
                    <TaskOperationsInternal
                        task={task}
                        taskExternal={taskExternal}
                        damageId={damageId}
                        showAssignNote={false}
                    />
                    {
                        task.isDeletable && <IconButtonWithLabel
                            onButtonClick={
                                () => { 
                                    onDeleteTask(
                                        {
                                            ...task, 
                                            damage: { id: damageId }
                                        }
                                    ) 
                                }
                            }
                            label={t('Delete task')}
                            iconKey='delete_outline'
                        />
                    }
                </FlexBox>
            </FlexBox>
            <FlexBox>
                {
                    state === TASK_STATES.DONE
                        ? 
                        <IndicatorWithColor 
                            iconKey='person' 
                            text={defaultUserOption.label} 
                            overrideColorWith={colorIndicator} 
                        />
                        : canAssign 
                            ? 
                            <FlexBox flexDirection='column'>
                                <Select 
                                    name='assignedTo'
                                    register={register}
                                    setValue={setValue}
                                    options={usersOptions}
                                    error={errors.assignedTo}
                                    validate={null}
                                    onInputChange={handleSearchUser}
                                    onBlur={()=>{setUserSearchActive(false)}}
                                    onFocus={()=>{setUserSearchActive(true)}}
                                    onChange={handleAssign}
                                    noOptionsMessage={t('No users found')}
                                    placeholder={t('Search employee')}
                                    isLoading={userListLoading}
                                    hideValidationBox={true}
                                    defaultValue={defaultUserOption}
                                    indicatorIconKey='search'
                                    additionalCSS={
                                        `
                                            div.css-tlfecz-indicatorContainer i {
                                                color: ${theme.color.anthracite};
                                            }
                                        `
                                    }
                                />
                                <FlexBox justifyContent='space-between'>
                                    {
                                        showAssignMeButton && 
                                        <StyledTaskHighlightLinkButton>
                                            <LinkButton 
                                                isActive={true} 
                                                onClick={handleAssignMe}
                                            >
                                                {t('assign to me')}
                                            </LinkButton>
                                        </StyledTaskHighlightLinkButton>
                                    }
                                    <StyledTaskHighlightLinkButton>
                                        <LinkButton
                                            isActive={true} 
                                            onClick={
                                                ()=>{    
                                                    openAssignUserDialog(
                                                        t('Task') + ` "${taskTitle}" ` + t('assign'),
                                                        branchId,
                                                        damageId,
                                                        task,
                                                    )
                                                }
                                            }
                                        >
                                            {t('Search all users')}
                                        </LinkButton>
                                    </StyledTaskHighlightLinkButton>
                                </FlexBox>
                            </FlexBox> 
                            : 
                            state !== TASK_STATES.TO_DO 
                        && <IndicatorWithColor 
                            iconKey='person' 
                            text={defaultUserOption.label} 
                            overrideColorWith={colorIndicator} 
                        />
                }
            </FlexBox>
        </FlexBox>
        <StyledTaskInfo>
            <StyledTaskInfoContent>
                <StyledTaskInfoRow>
                    <SimpleIcon iconKey='move_to_inbox'/>
                    <StyledTaskInfoLabel hasValue={true}>
                        <StateName state={state} />
                    </StyledTaskInfoLabel>
                </StyledTaskInfoRow>                 
                <StyledTaskInfoRow>
                    <TaskPriorityDisplay
                        priority={priority}
                        iconWidth='22px'
                    />
                </StyledTaskInfoRow>
                <StyledTaskInfoRow>
                    <StyledDeadlineInfo>
                        <SimpleIcon iconKey='date_range' />
                    
                        <StyledTaskInfoLabel hasValue={hasDeadline}>
                            {
                                isDefined(task?.createdAt)
                                    ? <DateComponent 
                                        date={task.createdAt}
                                    />
                                    : 'Keine Frist gesetzt'
                            }
                        </StyledTaskInfoLabel>
                    </StyledDeadlineInfo>
                </StyledTaskInfoRow>    
            </StyledTaskInfoContent>         
        </StyledTaskInfo>
    </StyledTaskHighlightBar>
}


export  const TaskHighlightBarComponent = withTheme(TaskHighlightBar)