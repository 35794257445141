import { api } from 'application/api'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { ListFilter, common, controlling, damages } from 'application/filters'
import { updateWidget, updateWidgetLoadedState } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { getAccessibleBranches } from 'application/redux/selectors'
import { all, call, put, select, } from 'redux-saga/effects'

export function* widgetControllingGlobalDamagesSaga(action){

    const {
        accessibleBranches
    } = yield select(getAccessibleBranches)

    let additionalFilters = []

    const compareBranchesFilter = controlling.compareBranches

    const hasCompareBranchesFilter = action.urlParams.find(p => p.key === compareBranchesFilter.filterKey)

    const branchIds = !hasCompareBranchesFilter || action.urlParams.find(p => p.key === compareBranchesFilter.filterKey).value === 'All'
        ? accessibleBranches.map(ab => ab.id)
        : new ListFilter(compareBranchesFilter).setValue(action.urlParams.find(p => p.key === compareBranchesFilter.filterKey).value).getQuery()


    if(action.urlParams.find(param => param.key === damages.creationTimeRange.filterKey)){
        const value = action.urlParams.find(param => param.key === damages.creationTimeRange.filterKey).value
        additionalFilters.push(new ListFilter(damages.creationTimeRange).setValue(value))
    }

    if(action.urlParams.find(param => param.key === damages.type.filterKey)){
        const value = action.urlParams.find(param => param.key === damages.type.filterKey).value
        additionalFilters.push(new ListFilter(damages.type).setValue(value))
    }

    if(action.urlParams.find(param => param.key === damages.isFictitious.filterKey)){
        const value = action.urlParams.find(param => param.key === damages.isFictitious.filterKey).value
        additionalFilters.push(new ListFilter(damages.isFictitious).setValue(value))
    }

    if(action.urlParams.find(param => param.key === damages.assigneeId.filterKey)){
        const value = action.urlParams.find(param => param.key === damages.assigneeId.filterKey).value
        additionalFilters.push(new ListFilter(damages.assigneeId).setValue(value))
    }

    let chartData = branchIds.map(branchId => {
        const branch = accessibleBranches.find(b => b.id === branchId)
        return ({
            branch,
            data: {
                damagesTotal: 0
            },
            dataLoaded: false
        })
    }).sort((a,b) => a.branch.id - b.branch.id)

    let barsLoaded = 0

    yield put(updateWidget(action.key, action.dependentWidgetKeys, {chartData, totalBars: branchIds.length, barsLoaded: 0, allBarsLoaded: false}))
    yield put(updateWidgetLoadedState(action.key, true, false))


    const requests = branchIds.map(
        branchId => function* () {        
            const response = yield resourceCall(
                api.gateway.damages.getDamages,
                {
                    filterQuery: composeFilterQuery([
                        new ListFilter(common.branchId).setValue(branchId),
                        ...additionalFilters
                    ]),
                    limit: 1
                }
            )

            barsLoaded += 1
             
            chartData = chartData.map(dataset => {
                if(dataset.branch.id !== branchId){
                    return dataset
                }

                return {
                    ...dataset,
                    data: {
                        damagesTotal: response.total
                    },
                    dataLoaded: true
                }
            })
            yield put(updateWidget(action.key, action.dependentWidgetKeys, {chartData, totalBars: branchIds.length, barsLoaded, allBarsLoaded: barsLoaded === branchIds.length}))


        }
    )

    yield all(requests.map(call))

    
}