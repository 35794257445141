import styled from 'styled-components'

export const StyledOverlayDimmer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 6000;
    background-color: rgba(0, 0, 0, 0.55);
    display: block;
`

export const StyledOverlayContent = styled.div`
    position: relative;
    top: 16px;
    left: 16px;
    width: 100%;
    width: calc(100% - 32px);
    height: 100%;
    height: calc(100% - 32px);
    z-index: 6001;
    background-color: ${props => props.theme.color.white};
    display: flex;
    flex-flow: row wrap;
`

export const StyledHeader = styled.h2`
    margin-top: 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 20px);
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.headline2};
    font-weight: ${props => props.theme.fontWeight.standardLight};
`

export const StyledSidebarHeaderCaption = styled.div`
    flex: 1;
    overflow: hidden;
`

export const StyledSidebarHeaderCloseButton = styled.div`
    flex: 0 0 30px;
`

export const StyledSidebarHeaderDownloadButton = styled.div`
    flex: 0 0 30px;
`

export const StyledHeaderRow = styled.div`
    height: 50px;
    flex: 0 0 100%;
    max-width: 100%;
`

export const StyledFooterRow = styled.div`
    height: 100px;
    background: #eeeeee;
    flex: 0 0 100%;
    max-width: 100%;
`

export const StyledContent = styled.div`
    left: 0; 
    right: 0;
    bottom: 100px;
    width: 100%;
    height: calc(100% - 150px);
`

//tree
export const StyleButtonToggle = styled.button`
    background: transparent;
    color: ${props => props.theme.color.anthracite};
    cursor: pointer;
    padding: 4px 0px;
    display: flex;
    border: none;
    outline: none;
    font-size:${props => props.theme.fontSize.standard};
    font-weight:${props => props.theme.fontWeight.standardBold};
    width: 100%;
`

export const StyleDisplayToggle = styled.div`
    padding-left: 8px;
    display: ${props => props.active ? 'block' : 'none'}
`

export const StyledAttachmentsContainer = styled.div`
    padding-left: 12px;
    margin-bottom: 4px;
    font-size:${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite50};
`

export const StyledToggleText = styled.div`
    padding-left: 4px;
`

export const StyledToggleTextSecondary = styled.span`
    font-weight:${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite50};
`

export const StyledWrapperSubCategory = styled.div`
    padding-left: 4px;
`