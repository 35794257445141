import { BRANCH_MANAGEMENT } from 'application/constants'

export const xPosition 
    = typeIndex => BRANCH_MANAGEMENT.NODE_POSITION.X_START_OFFSET + (BRANCH_MANAGEMENT.NODE_POSITION.X_SPACING * typeIndex)



const getSpacing = nodeCount => {

    const spacingBase = (BRANCH_MANAGEMENT.NODE_POSITION.Y_MAX_SPACING + BRANCH_MANAGEMENT.NODE_POSITION.Y_MIN_SPACING) / 2
    const spacingOffset = 7 - nodeCount
    const spacing = spacingBase + (spacingOffset * 6)

    if(spacing < BRANCH_MANAGEMENT.NODE_POSITION.Y_MIN_SPACING){
        return BRANCH_MANAGEMENT.NODE_POSITION.Y_MIN_SPACING
    }

    if(spacing > BRANCH_MANAGEMENT.NODE_POSITION.Y_MAX_SPACING){
        return BRANCH_MANAGEMENT.NODE_POSITION.Y_MAX_SPACING
    }
     
    return spacing

}    

export const yPosition
    = (nodeIndex, nodeLevel, nodeCount) => BRANCH_MANAGEMENT.NODE_POSITION.Y_START_OFFSET + (BRANCH_MANAGEMENT.NODE_POSITION.Y_STEP_LEVEL * nodeLevel) + (getSpacing(nodeCount) * nodeIndex)
