
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'

export const LawfirmCommunicationAbortion = (
    {
        activity
    }
) => {
    const {
        name,
        damage
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetail 
            label='Lawfirm' 
            value={name} 
        />
    </ActivityDetailWrapper>
}

LawfirmCommunicationAbortion.propTypes = {
    activity: PropTypes.object.isRequired
}