import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* deleteGroupSaga(action) {
    yield put(
        showWaitingScreen('Deleting usergroup...')
    )
    yield resourceCall(
        api.gateway.groups.deleteGroup, 
        {
            groupId: action.metaData.groupId
        }
    )
    yield put(
        hideWaitingScreen()
    )
}