import React from 'react'
import styled from 'styled-components'
import TruncateString from 'react-truncate-string'
import { NavLink } from 'react-router-dom'
import { TaskStatusIcon } from 'application/components/controls/task_status_icon'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Spacer } from 'application/components/pages/_common'
import { ExternalTaskMarker } from 'application/components/controls/external_task_marker'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'

const Label = styled.span`
    display: flex;
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    border-bottom: ${props => props.isDone ? `1px solid ${props.theme.color.anthracite}` : 'unset' };
    width: inherit;
    overflow: hidden;
`

const Link = styled(NavLink)`
    padding: 6px 0;
    width: 100%;
    text-decoration: none;
    color: ${props => props.theme.color.anthracite};
    &:hover .label{
        text-decoration: underline;
    }
`

export const TaskListItem = ({ task }) => {
    const {
        damageManagerTaskLink
    } = useApplicationLinks()

    const isAssigned = task.assignee?.userId !== null && task.assignee !== undefined

    return <Link to={
        damageManagerTaskLink(
            ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED,
            task.id
        )
    }>
        <FlexBox alignItems='center' cssRules='cursor: pointer;'>
            <TaskStatusIcon 
                state={task.state} 
                small={true} 
                isActive={false} 
                isAssigned={isAssigned}
            />
            <Spacer basis='2px' />
            <ExternalTaskMarker 
                task={task} 
            />
            <Label 
                title={task.title} 
                className='label'
            >
                <TruncateString 
                    text={task.title} 
                    truncateAt={90}
                />
            </Label>
        </FlexBox>
    </Link>
}
