import React from 'react'
import { ActionButton } from 'application/components/controls/action_button'

export const ContactButton = ({label = 'Kontakt'}) => {
    const goToContactForm = (e) => {
        e.preventDefault()
        window.location = process.env.REACT_APP_ESY_360_CONTACT_FORM_URL
    }

    return <ActionButton
        buttonText={label}
        onButtonClick={goToContactForm}
        strechted={false}
    />

}