import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageCommissionedExpertSaga(action) {
    const damageId = action.match.params.damageId
    let data = {
        expertFound: false,
        expert: null
    }
    try {
        const response = yield resourceCall(
            api.gateway.expert.getExpert,
            { damageId }
        )
        data = {
            expertFound: true,
            expert: response
        }
    } catch (error) {}
    
    yield put(
        updateWidget(
            action.key, 
            action.dependentWidgetKeys, 
            data
        )
    )
}