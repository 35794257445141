import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PartialForms } from 'application/components/forms'
import { GeoCodingControl } from 'application/components/controls/geocoding_control'
import { PushBottom24 } from 'application/components/pages/_common'
import { useStatefulForm } from 'application/components/hooks'
import { useGeoDataSubmit} from 'application/components/hooks'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const EditBranch = (
    { 
        abort, 
        dialog, 
        onEditBranch 
    }
) => {
    const {payload} = dialog
    const defaultValues = payload
 
    const { 
        register, 
        setValue,
        errors, 
        handleSubmit, 
        getValues,
        control,
        formStateValues, 
        updateFormState
    } = useStatefulForm({defaultValues})


    const metaData = { branchId: payload.id } 

    const handleOnSubmit = () => {
        onEditBranch(
            dialog.name, 
            getValues(),
            metaData
        )
    }


    const {
        onSubmitSuccess,
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } = useGeoDataSubmit(
        formStateValues.address,
        setValue,
        handleOnSubmit
    )

    const handleOnAddressChange = () => {
        updateFormState({
            ...getValues()
        })
    }


    return <React.Fragment>
        <DialogMainContent>
            <FlexBox
                flexDirection='column'
            >
                <PartialForms.BranchDataPartialForm 
                    register={register}
                    errors={errors}
                    onChange={handleOnAddressChange}
                    control={control}
                    setValue={setValue}
                    defaultValuePhoneNumber={formStateValues.contact.phoneNumber}
                />
                <PushBottom24 />
                <GeoCodingControl
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    defaultLatitude={defaultValues.address?.coordinates?.latitude}
                    defaultLongitude={defaultValues.address?.coordinates?.latitude}
                    setUseGeoCoding={setUseGeoCoding}
                    useGeoCoding={useGeoCoding}
                /> 
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save branch'
            disabled={submitDisabled}
        />
    </React.Fragment>

}

export const EditBranchComponent = withDialogDataLoader(EditBranch)