import { BRANCH_MANAGEMENT } from 'application/constants'
import { CONNECTION_TYPE } from 'application/constants/branch_management'

const isEdgeDisabled = (branchesDisabled, connectionType, connectedNodes) => {
    if(!branchesDisabled){
        return false
    }

    if(connectionType === CONNECTION_TYPE.GROUP_TO_BRANCH){
        return true
    }

    if(connectionType === CONNECTION_TYPE.GROUP_TO_ROLE){
        return !connectedNodes.some(n => n.includesGlobalPermissions)
    }

    return false
}

export const buildEdge = (source, target, connectionType, branchesDisabled, connectedNodes = [], isStaticEdge = false) => {
    return {
        id: `${source}_${target}`,
        type: BRANCH_MANAGEMENT.ELEMENT_TYPE.EDGE_REMOVABLE,
        source,
        target,
        data: {
            connectionType, 
            branchesDisabled, 
            connectedNodes, 
            isStaticEdge,
            isDisabled: isEdgeDisabled(branchesDisabled, connectionType, connectedNodes)
        }
        // animated: true,
        // arrowHeadType: 'arrowclosed'
    }
}