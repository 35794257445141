import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { fileHelpers } from 'application/common'
import {useShouldLoadThumbnail} from 'application/components/hooks'
import { useParams } from 'react-router-dom'


export const withThumbnailLoaderComponent =
 Component => 
     (props) => {

         const params = useParams()

         const  { 
             attachments, 
             attachmentId, 
             attachmentName,
             onAttachmentClick, 
             onLoadAttachmentThumbnail,
             extendedData,
             showCaption = true, 
             isActive = false,
             damageId,
             taskId,
             match,
             ...rest
         } = props
         

         //check if thumbnail is registered in redux store              
         const thumbnailRegistered = attachments.thumbnails.filter(e => e.id === attachmentId).length > 0

         //check if thumbnail should be loaded depending on the file extension
         const shouldLoad = useShouldLoadThumbnail(attachmentName)

         //thumbnail data url
         const thumbnail = thumbnailRegistered 
             ? attachments.thumbnails.filter(t => t.id === attachmentId)[0].data 
             : ''

         //handle thumbnail loading error
         const thumbnailLaoadingFailed = thumbnailRegistered 
             ? attachments.thumbnails.filter(t => t.id === attachmentId)[0].loadingFailed 
             : false

         //preloader display depends on this value
         const thumbnailIsLoading = !thumbnailRegistered || (thumbnailRegistered 
        && !attachments.thumbnails.filter(e => e.id === attachmentId)[0].isLoaded)
    



         //request thumbnail if not registered
         useEffect(() => { 
             !thumbnailRegistered 
        && shouldLoad 
        && onLoadAttachmentThumbnail(
            attachmentId, 
            attachmentName, 
            {
                damageId,
                taskId, 
                params
            }
        )
         }, [attachmentId, attachmentName, damageId, onLoadAttachmentThumbnail, shouldLoad, taskId, thumbnailRegistered, match, params])

         const attachment = {
             id: attachmentId,
             hasThumbnail: shouldLoad,
             thumbnailIsLoading: thumbnailIsLoading,
             thumbnail,
             fileExtension: fileHelpers.getFileExtension(attachmentName),
             fileName: attachmentName,
             taskId,
             thumbnailLaoadingFailed, 
             ...extendedData,
         } 

         return <Component 
             attachment={attachment} 
             onAttachmentClick={onAttachmentClick} 
             showCaption={showCaption}
             isActive={isActive}
             damageId={damageId}
             {...rest}
         />
     }


withThumbnailLoaderComponent.propTypes = {
    thumbnails: PropTypes.shape({
        loaded: PropTypes.array.isRequired
    }),
    attachmentId: PropTypes.string.isRequired,
    attachmentName: PropTypes.string.isRequired,
    onAttachmentClick: PropTypes.func,
    onLoadAttachmentThumbnail: PropTypes.func.isRequired,
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool
}

