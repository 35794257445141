import { connect } from 'react-redux'

import { BranchSelectComponent, PureBranchSelectComponent } from './component'

const mapStateToProps = state => ({
    branches: state.common.accessibleBranches
})

export const BranchSelect = connect(
    mapStateToProps
)(BranchSelectComponent)

export const PureBranchSelect = connect(
    mapStateToProps
)(PureBranchSelectComponent)