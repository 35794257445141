import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'


export const DocumentDeletedActivity = (
    {activity}
) => {

    const {t} = useTranslation()

    const damageLicensePlate = activity.data?.damage?.licensePlate || ''
    const documentName = activity.data?.name || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Document deleted')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Damage')} : {damageLicensePlate}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            {t('Document')} :  {documentName}  
        </Fragments.ActivityParagraph>
    </React.Fragment>
}