import { connect } from 'react-redux'
import { ChangeBranchDialogComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = (state, props) => (
    {
        dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name),
        currentBranch: state.auth.currentBranch,
        accessibleBranches: state.common.accessibleBranches,
        accessibleBranchesLoaded: state.common.accessibleBranchesLoaded
    }
)

const mapDispatchToProps = dispatch => {
    return {
        onSubmitBranch: (dialogName, payload, metaData) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        }
    }
}

export const ChangeBranch = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeBranchDialogComponent)