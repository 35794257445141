import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'


export const CommentCreatedInfo = (
    {
        activityData
    }
) => {
    const {t}  = useTranslation()

    const taskTitle = activityData.task?.title || t('Unknown') 

    return <React.Fragment>
        {
            taskTitle !== '' && <React.Fragment>
                {t('Task')}: {taskTitle}
            </React.Fragment>
        }
    </React.Fragment>
}

CommentCreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}


