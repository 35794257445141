import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateRoles } from 'application/redux/actions/common/roles'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getRolesSaga(action) {
    try {
        const rolesResponse = yield resourceCall(api.gateway.roles.getRoles)
        yield put(populateRoles(rolesResponse.roles))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getRolesWatcher() {
    yield takeLeading(ACTIONS.COMMON_GET_ROLES_REQUEST, getRolesSaga)
}