

export const page = {
        paddingTop: '17mm',
        paddingBottom: '17mm',
        paddingRight: '8mm',
        paddingLeft: '20mm',
        color: 'black',
        lineHeight: '1.4px'
    },
    header = {
        flexDirection: 'row',
        paddingBottom: 8,
        borderBottom: '1px solid black',
        fontSize: 8,
        height: '50px',
      
        leftColumn: {
            flex: 4,
            alignItems: 'center',
            justifyContent: 'center'
        },
      
        rightColumn: {
            flex: 3,
            row: {
                flexDirection: 'row',
                justifyContent: 'space-between'
            }
        }
    },
    headline = {
        fontSize: 12,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop:  '20px'
    },
    form = {
        flexDirection: 'row', 
        fontSize: 8,
      
        columnSpace : {
            width: '32px'
        },
        field: {
            borderBottom: '0.5px solid black',
            flexDirection: 'row',
            paddingBottom: '1px',
            paddingTop: '3px',
            label: {
                flex: 1,
            },
            content: {
                flex: 3,
                justifyContent: 'center',
                fontStyle: 'italic'
            }
        },
        fieldBig: {
            paddingBottom: '1px',
            paddingTop: '3px',
            height: '26px',
            flexDirection: 'column',
            borderBottom: '0.5px solid black',
            label: {},
            content: {
                paddingTop: '2px',
                paddingLeft: '42px'
            }
        },
        textArea: {
            marginTop: '10px',
            label: {
          
            },
            content: {
                border: '0.5px solid black',
                paddingLeft: '24px',
                justifyContent: 'center',
                height: '60px'          
            }
        }
    },
    paragraph = {
        fontSize: 7,
        headline: {
            marginTop: '2px',
            borderBottom: '1px solid  black'
        }
    },
    signatureField = {
        borderBottom:'0.5px solid black',
        height: '20px',
        justifyContent: 'center',
        label: {
            paddingTop: '4px',
            fontSize: 7
        },
        content: {
            paddingLeft: '10px',
            fontSize: 10
        }
    },      
    paragraph2 = {
        fontSize: 9,
        space: {marginTop: '8px'}
    },
    underlinedField = {
        borderBottom: '0.5px solid black',
        height: '24px',
        marginBottom: '24px',
        alignItems: 'center',
        justifyContent: 'center',
        value: {fontSize: 10}
    }
  
  