import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

const mapStateToProps = state => ({
    currentDamage: state.pages.damageManager.shared.damage
})

const withCurrentDamageComponent = Component => ({ ...props }) => {
    return <Component {...props} />
}

//withCurrentDamage hoc is only usable in damage manager
export const withCurrentDamage = compose(
    connect(
        mapStateToProps
    ),
    withCurrentDamageComponent
)