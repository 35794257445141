
import React from 'react'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import {ButtonLink} from 'application/components/building_blocks/main_navigation_sidebar/_common/button_link'
import PropTypes from 'prop-types'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import { addWildCard } from 'application/common/route_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'



export const AllBranchesAccordion = (
    {
        primaryColor,
        primaryHoverColor
    }
) =>  {
    const dashboard = notNull(
        useMatch(ROUTES.DASHBOARD)
    )
    const damagesOverview = notNull(
        useMatch({ path:addWildCard(ROUTES.DAMAGES_OVERVIEW  ) })
    )
    const tasksOverview = notNull(
        useMatch({ path:addWildCard(ROUTES.TASKS_OVERVIEW ) })
    )
    const inbox = notNull(
        useMatch({ path:addWildCard(ROUTES.ALL_BRANCHES_INBOX)  })
    )
    const controlling = notNull(
        useMatch({ path:addWildCard(ROUTES.CONTROLLING) })
    )
    const activityLog = notNull(
        useMatch(ROUTES.ACTIVITY_LOG)
    )

    const {
        internalAccountLink
    } = useApplicationLinks()

    return <React.Fragment>
        <ButtonLink 
            label={'Overview'}
            pathDestination={internalAccountLink( ROUTES.DASHBOARD) }
            isActive={dashboard}
            iconKey={'widgets'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.DAMAGE.READ]}
        >
            <ButtonLink 
                label={'Damages'}
                pathDestination={ internalAccountLink(ROUTES.DAMAGES_OVERVIEW) }
                isActive={damagesOverview}
                iconKey={'directions_car'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.TASK.READ]} 
        >
            <ButtonLink 
                label={'Tasks'}
                pathDestination={ internalAccountLink( ROUTES.TASKS_OVERVIEW) }
                isActive={tasksOverview}
                iconKey={'description'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.MESSAGE.READ]} 
        >
            <ButtonLink 
                label={'Inbox'}
                pathDestination={ internalAccountLink(ROUTES.ALL_BRANCHES_INBOX) }
                isActive={inbox}
                iconKey={'mail_outline'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.CONTROLLING.READ]}
        >
            <ButtonLink 
                label={'Controlling'}
                pathDestination={ internalAccountLink( ROUTES.CONTROLLING) }
                isActive={controlling}
                iconKey={'bar_chart'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForAnyBranchPermissions>
        <ButtonLink 
            label={'Activities'}
            pathDestination={ internalAccountLink(ROUTES.ACTIVITY_LOG) }
            isActive={activityLog}
            iconKey={'list'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </React.Fragment>
}

AllBranchesAccordion.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired
}