import { isDefined } from 'application/common/data_helpers'

/**
 * 
 * @param {string} accordionMenuName untranslated title of this accordionMenu
 * @param {string} linkName untranslated title of this link
 * @param {object} t useTranslation 
 * @returns {string} complete translated toolTip string
 */

export const buildToolTip = (
    accordionMenuName, 
    linkName, 
    t
) => {
    const menuName = isDefined(accordionMenuName)
        ? t(accordionMenuName) + ' - '
        : ''
    return (menuName + t(linkName) )
}