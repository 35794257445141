import { connect } from 'react-redux'
import { TaskDamageCombinedLiabilityFormsComponent } from './component'
import { updateAccidentReport } from  'application/redux/actions/pages/damage_manager/tasks/forms'
import { updateWitness, updateOpponentVehicle, updatePolice, updateOpponentKeeperAndDriver, updateLiabilityInsuranceAndClaim } from 'application/redux/actions/pages/damage_manager/tasks/forms'


const mapStateToProps = state => ({
    currentDamage: state.pages.damageManager.shared.damage
})

const mapDispatchToProps = dispatch => {
    return {
        onSaveInsuranceAndClaim: (
            metaData,
            payload
        ) => dispatch(
            updateLiabilityInsuranceAndClaim(
                metaData,
                payload
            )
        ),
        onSaveWitness: (damageId, damageData) => dispatch(
            updateWitness(damageId, damageData)
        ),
        onSaveOpponentVehicle: (damageId, damageData) => dispatch(
            updateOpponentVehicle(damageId, damageData)
        ),
        onSaveOpponentKeeperAndDriver: (damageId, damageData) => dispatch(
            updateOpponentKeeperAndDriver(damageId, damageData)
        ),
        onSavePolice: (damageId, damageData) => dispatch(
            updatePolice(damageId, damageData)
        ),
        onSaveAccidentReport: (damageId, damageData) => dispatch(
            updateAccidentReport(damageId, damageData)
        )
    }
}

export const TaskDamageCombinedLiabilityForms = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageCombinedLiabilityFormsComponent)