import { BRANCH_MANAGEMENT } from 'application/constants'

export const getConnectedEdges = (elements, nodeId, edgeType = BRANCH_MANAGEMENT.ELEMENT_TYPE.EDGE_REMOVABLE, includeRoot = true) => {
    const root = elements.find(e => e.id === nodeId)

    const allEdges = elements.filter(e => e.type === edgeType)
    const connectedEdges = allEdges
        .filter(e => e.target === nodeId || e.source === nodeId)
        .filter(e => !e.data.isDisabled)

    if(!includeRoot){
        return connectedEdges
    }
    return [root, ...connectedEdges]
}