import { connect } from 'react-redux'

import { ControllingGlobalDamagesBarChartComponent } from 'application/components/widgets/controlling/global_damages_bar_chart/component'
import { refreshWidgetByKey } from 'application/redux/actions/widgets'

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (key) => dispatch(refreshWidgetByKey(key))
    }
}

export const ControllingGlobalDamagesBarChart = connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllingGlobalDamagesBarChartComponent)