import styled from 'styled-components'

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent === undefined ? '' : props.justifyContent};
    margin-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
`

export const Filler = styled.div`
    flex-grow: 1;
`

export const Description = styled.p`
    white-space: pre-line;
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    border-top: 1px solid ${props => props.theme.color.gray15};
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px 0;
`