import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_CONTEXTS } from 'application/constants'
import {DamageManagerOverviewSidebarComponent} from './component'

const DamageManagerOverviewSidebarEffectsC = (props) => {
    const { onRefreshWidgetContext, match } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW_SIDEBAR)
    }, [onRefreshWidgetContext, damageId])

    return <DamageManagerOverviewSidebarComponent {...props} />
}

DamageManagerOverviewSidebarEffectsC.propTypes = {
    onRefreshWidgetContext: PropTypes.func.isRequired
}

export const DamageManagerOverviewSidebarEffects = withRouter(DamageManagerOverviewSidebarEffectsC)