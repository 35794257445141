import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { getParamValueByKey } from 'application/common/route_helpers'
import { ROUTE_PARAMETERS } from 'application/constants'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ListFilter } from 'application/filters'
import * as filters from 'application/filters'

export function* widgetControllingVacantPositionsDamagesTableSaga(action){
    const { urlParams } = action
   
    const offset = getParamValueByKey(urlParams, ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.OFFSET, '0')
    const limit = getParamValueByKey(urlParams, ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.LIMIT, '10')
    const orderBy = getParamValueByKey(urlParams, ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.KEY, ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.VACANT_POSITIONS)
    const direction = getParamValueByKey(urlParams, ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.DIRECTION, ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING)
    const filterQuery = new ListFilter(filters.controlling.hasGrossTotal).getQuery()

    const response = yield resourceCall(
        api.gateway.controlling.getDamages, 
        {
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        }
    )
   
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {response}))
}