import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'
import { getFullName } from 'application/common/name_helpers'

export const UserDetails = (
    {
        activity
    }
) => {
    return <ActivityDetailWrapper>
        <ActivityDetail label='Name' value={getFullName(activity.data)} />
        <ActivityDetail label='Email' value={activity.data.email} />
    </ActivityDetailWrapper>
}

UserDetails.propTypes = {
    activity: PropTypes.object.isRequired
}