import i18next from 'i18next'
import { buildApiUrl } from 'application/api/helpers'
import { HTTP_METHOD } from 'application/api/request_builders'

export const fileUploadRequestBuilderPin = (apiEndpoint, pin, file, params = [], absolute = false, omitToken = false) => {
    const url = buildApiUrl(apiEndpoint, absolute)
    
    let formData = new FormData()
    if (file !== null) {
        formData.append('File', file, file.name)
        formData.append('Name', file.name)
    }
    

    for(const param of params){
        formData.append(param.key, param.value)
    }

    let data = {
        method: HTTP_METHOD.POST,
        headers: { 
            'Accept-Language': i18next.language
        },
        mode: 'cors',
        body: formData
    }

    data.headers = !omitToken ? {
        ...data.headers,
        'authorization': 'Bearer ' + pin
    } : data.headers

    return {
        data,
        url
    }
}