import styled from 'styled-components'
import React from 'react'

export const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    color: ${props => props.theme.color.primary};

    & i.material-icons {
        font-size: 50px;
    }
`

export const StyledInfoBox = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    height: inherit;
    background: ${props => props.theme.color.primaryBackground};
    font-size: ${props => props.theme.fontSize.small};
`

export const StyledMoHeadline = styled.span`
    margin-top:12px;
    color: ${props => props.theme.color.primary};
    font-weight: ${props => props.theme.fontWeight.standardBold};
`

export const VerticalSpace16 = styled.div`
    width: 16px;
`

export const Icon = () => {
    return <StyledIcon>
        <i className="material-icons">
            fact_check
        </i>
    </StyledIcon>
}