import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { TASK_STATES } from 'application/constants'
import { refreshDamageManagerDamageSaga } from '../post_processors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogReopenTaskSaga(action) {
    const {payload, metaData} = action

    yield put(
        showWaitingScreen('Updating task')
    )
    
    yield resourceCall(
        api.gateway.tasks.changeTaskState, 
        action.metaData, 
        {
            state: TASK_STATES.TO_DO
        }
    )

    //update assignee and state if assignee has changed
    if(payload.assigneeHasChanged){
        yield resourceCall(
            api.gateway.tasks.assignTask, 
            metaData, 
            {
                userId: payload.assigneeId
            }
        )
    }

    //update damage and state if assignee has changed
    if(payload.damageStateHasChanged){
        yield resourceCall(
            api.gateway.damage.updateDamageState, 
            metaData,
            {
                id: payload.damageStateId
            }
        )
        yield refreshDamageManagerDamageSaga(
            {
                damageId: metaData.damageId
            }
        )
    }

    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Task saved')
    )
}