import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import {MyTasks} from './my_tasks'
import {AllTasks} from './all_tasks'
import { isNull, notNull } from 'application/common/data_helpers'
import {  useMatch } from 'react-router-dom'
import { matchRoute } from 'application/common/route_helpers'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'



const TasksOverview = ( {currentBranch} ) => {
    const {t} = useTranslation()
    const {branchLink} = useApplicationLinks()

    const matchTasksOverview = matchRoute(
        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW,
        useMatch
    )
    const matchMyTasks = matchRoute(
        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS,
        useMatch
    )
    const matchAllTasks = matchRoute(
        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS,
        useMatch
    )

    const currentBranchId = notNull(currentBranch) 
        ? currentBranch.id 
        : null

    if( isNull(currentBranchId) ){
        return null
    }

    if (matchTasksOverview) {
        return <Navigate 
            to={
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS, 
                    currentBranchId
                )
            } 
        />
    }

    return <LayoutCurrentBranch
        titleSegments={['Tasks']}
        headline={t('Tasks')}
    >
        <TabsLinks
            items={[
                {
                    label: t('My tasks'),
                    link: branchLink(
                        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS, 
                        currentBranchId
                    )
                    ,
                    isActive: matchMyTasks
                },
                {
                    label: t('All tasks'),
                    link: branchLink(
                        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS, 
                        currentBranchId
                    )
                    ,
                    isActive: matchAllTasks
                } 
            ]}
        />
        {
            matchMyTasks && <MyTasks /> 
        }
        {
            matchAllTasks && <AllTasks />
        }
    </LayoutCurrentBranch>
}

export const TasksOverviewComponent = withRouter(TasksOverview)