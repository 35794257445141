import { useState } from 'react'

export const useTimeline = (steps, initialStepIndex = 0) => {
    const [currentTimelineStepIndex, setCurrentTimelineStepIndex] = useState(initialStepIndex)

    const gotoNextStep = () => {
        if(currentTimelineStepIndex < steps - 1){
            setCurrentTimelineStepIndex(currentTimelineStepIndex + 1)
        }
    }
    
    const gotoPreviousStep = () => {
        if(currentTimelineStepIndex > 0){
            setCurrentTimelineStepIndex(currentTimelineStepIndex - 1)
        }
    }

    return {
        gotoNextStep,
        gotoPreviousStep,
        currentTimelineStepIndex,
        setCurrentTimelineStepIndex
    }
}