import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'
import { addAttachmentsToTaskInListWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogUploadFilesWorkflowPanelSaga(action) {

    yield put(showWaitingScreen('Uploading files'))
    for(const file of action.payload.files){
        yield put( addNewSubMessage(file.metaData.name + ' ... ') )
        
        const documentResponse = yield resourceCall(
            api.gateway.attachments.postAttachmentForTask, 
            {
                taskId: action.metaData.taskId,
                categoryId: action.metaData.categoryId
            },
            {
                file: file.metaData
            }
        )
        
        if(file.isExportedToLawFirm){
            yield resourceCall(
                api.gateway.attachments.updateAttachment, 
                {
                    attachmentId: documentResponse.id
                },
                {
                    isExportedToLawFirm: true
                }
            )
        }

        yield put(addToLastSubMessage('✓'))
        yield put(addAttachmentsToTaskInListWorkflowPanel(action.metaData.taskId))
    }
    yield put(hideWaitingScreen())
    yield put(showToast('Upload complete'))
}