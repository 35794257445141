import React from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { iconMapper } from 'application/common/damage_status/icon_mapper'

const StyledDamageStatus = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.powderGray};

    background: ${ props => props.isSelected 
        ?  props.theme.color.gray5
        :  props.theme.color.black10 };
    
        display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    height: 24px;
    border-radius: 2px;
    padding: 0 8px;
    align-items: center;
    &>i{
        margin-right: 4px;
    }
`

const DamageStatusComponent = (
    { 
        status = null, 
        isSelected = false,
        theme 
    }
) => {
    if(status === null){
        return <React.Fragment />
    }

    const icon = iconMapper(status.iconName)

    return <StyledDamageStatus 
        isSelected={isSelected}
    >
        <FontIcon 
            icon={icon} 
            fontSize={theme.fontSize.standard} 
            color={theme.color.powderGray} 
        />
        <span>{status.name}</span>
    </StyledDamageStatus>
}


DamageStatusComponent.propTypes = {
    status: PropTypes.oneOfType(
        [
            PropTypes.object, 
            PropTypes.string
        ]
    ),
    isSelected: PropTypes.bool
}

export const DamageStatus =  withTheme(DamageStatusComponent)