import { WIDGET_CONTEXTS } from 'application/constants'
import React, { useEffect } from 'react'
import {DashboardContentComponent} from './component'




export const DashboardContentEffects = (props) => {
    const { onLoadDashboard, ...rest } = props

    useEffect(() => {
        onLoadDashboard(WIDGET_CONTEXTS.DASHBOARD)
    }, [onLoadDashboard])

    return <DashboardContentComponent {...rest} />
}