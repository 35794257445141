import { connect } from 'react-redux'
import { updateComprehensiveData } from  'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageComprehensiveInsuranceDataComponent } from './component'



const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return { 
        onSave: (
            metaData, 
            payload
        ) => dispatch(
            updateComprehensiveData(
                metaData, 
                payload
            )
        )
    }
}

export const TaskDamageComprehensiveInsuranceData = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageComprehensiveInsuranceDataComponent)