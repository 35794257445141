import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
    width: 100%;
    height: 80px;
    margin-top: 48px;
    background-image: url(/images/pixel_row.jpg);
    opacity: 0.5;
`

export const PixelRow = () => {
    return <Row/>
}