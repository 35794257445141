import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/modules/dynamic-form/controls/select'

export const YesNoSelect = ({
    name,
    label = '',
    register,
    setValue,
    initialValue = false,
    menuPlacement='bottom',
    yesValue = true,
    noValue = false,
    onChange = () => {},
    onYes = () => {},
    onNo = () => {},
    disabled = false
}) => {
    const {t} = useTranslation()
    const yesNoOptions = [
        {
            value: noValue,
            label: t('No')
        },
        {
            value: yesValue,
            label: t('Yes')
        }

    ]
    const initialOption = yesNoOptions.find(o => o.value === initialValue)

    const handleOnChange = (option) => {
        if(option.value === yesValue){
            onYes(option)
        }
        if(option.value === noValue){
            onNo(option)
        }
        onChange(option)
    }

    return <Select
        label={label}
        name={name}
        register={register}
        setValue={setValue}
        options={yesNoOptions}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        isSearchable={false}
        onChange={handleOnChange}
        disabled={disabled}
    />
}

YesNoSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}