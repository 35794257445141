import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Fragments from './fragments'

export const TabsButtons = ({ initialActiveTabIndex = 0, items = [], smallFont = false, children }) => {

    const [active, setActive] = useState(initialActiveTabIndex)

    return (
        <Fragments.TabsWrapper smallFont={smallFont}>
            {items.map((item, index) => {
                const currentTab = index === active
                return (
                    <Fragments.TabButton
                        key={index}
                        smallFont={smallFont}
                        isSelected={currentTab}
                        onClick={e => {
                            setActive(index)
                            item.onItemClick()
                        }}
                        className={`jestButtonTab jestButtonTab-${item.label}-${index} ${currentTab ? 'tabActive' : ''}`}
                    >
                        {item.label}

                    </Fragments.TabButton>
                )

            })}
        </Fragments.TabsWrapper>
    )
}

TabsButtons.propTypes = {
    initialActiveTabIndex: PropTypes.number,
    items: PropTypes.array,
    smallFont: PropTypes.bool
}