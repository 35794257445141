import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import styled from 'styled-components'
import { isEmptyString } from 'application/common/data_helpers'



const StyledBottomLine = styled.div`
    width: 100%;
    height: 8px;
    border-bottom: 1px solid ${props => props.theme.color.gray15};
    margin-bottom: 8px;
`
const StyledHeaderRow = styled.div`
    height: 14px;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
`

const StyledValueRow = styled.div`
    height: 14px;
    padding-right: 8px;
`


export const RowDetailsColumn = ({
    abovePrimaryLabel,
    aboveSecondaryLabel = '',
    
    belowPrimaryLabel,
    belowSecondaryLabel = '',

    abovePrimaryValue,
    aboveSecondaryValue = '',
    
    belowPrimaryValue,
    belowSecondaryValue = ''
}) => {

    const extendLabelWithDoublePoint = (label) => {
        return isEmptyString(label)  
            ? label 
            : label + ':'
    }

    return <FlexBox 
        width='auto'
        flexBasis='70%'
        marginRight='16px'
    >
        {/* header column */}
        <FlexBox flexDirection='column'  >
            <StyledHeaderRow>{ extendLabelWithDoublePoint(abovePrimaryLabel) }</StyledHeaderRow>
            <StyledHeaderRow>{ extendLabelWithDoublePoint(aboveSecondaryLabel) }</StyledHeaderRow>
            <StyledBottomLine />
            <StyledHeaderRow>{ extendLabelWithDoublePoint(belowPrimaryLabel) }</StyledHeaderRow>
            <StyledHeaderRow>{ extendLabelWithDoublePoint(belowSecondaryLabel) }</StyledHeaderRow>
        </FlexBox>

        {/* content column */}
        <FlexBox flexDirection='column'  >
            <FlexBox flexDirection='column'>
                <StyledValueRow>
                    {abovePrimaryValue}
                </StyledValueRow>
                <StyledValueRow>
                    {aboveSecondaryValue}
                </StyledValueRow>
            </FlexBox>
            <StyledBottomLine />
            <FlexBox flexDirection='column'>
                <StyledValueRow>
                    {belowPrimaryValue}
                </StyledValueRow>
                <StyledValueRow>
                    {belowSecondaryValue}
                </StyledValueRow>
            </FlexBox>
        </FlexBox>
    </FlexBox>
}