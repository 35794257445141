import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { StyledTitleBar, Cell } from 'application/components/pages/internal/_title_bars/_common'
import {LanguageSwitchHoc} from 'application/components/building_blocks/language_switch/hoc'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { Spacer } from 'application/components/pages/_common'

const TitleBar = ({
    onLogout = () => {},
    theme
}) => {
    const {t} = useTranslation()
    return (
        <StyledTitleBar>
            <Cell>
                <LanguageSwitchHoc />
            </Cell>

            <Spacer />

            <Cell>
                <IconButtonWithLabel 
                    onButtonClick={onLogout}
                    iconKey='exit_to_app' 
                    label={t('Log out')} 
                    iconSize={theme.fontSize.headline3} 
                />
            </Cell>
        </StyledTitleBar>
    )
}


export const SimpleTitleBar = withTheme(TitleBar)