import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { handleError } from '../../../errors'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* assignPreferredBranchSaga(action) {

    yield put(
        showWaitingScreen('Assigment of preferred branch')
    )
    
    try {
        const payload = action.payload
        const metaData = action.metaData


        yield resourceCall(
            api.gateway.users.putPreferredBranch,
            {
                userId: metaData.userId
            },
            payload.preferredBranch
        )
    
        yield put(
            showToast('Assigment successfull')
        )

    } catch (e) {
        yield handleError(e)
    }
    yield put(hideWaitingScreen())
}