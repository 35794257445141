import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogDeleteInvoiceLawyerCommunicationSaga(action) {
    const {parentId, invoiceId} = action.payload

    yield put(showWaitingScreen('Deleting invoice'))
    yield resourceCall(
        api.gateway.invoices.deleteInvoice, 
        {
            damageId: parentId, 
            invoiceId
        }
    )
    yield put(showToast(action.payload.successMsg))
    yield put(hideWaitingScreen())
}