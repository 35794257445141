import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'

import { WIDGET_TYPES } from 'application/constants'
import {InboxComponent} from './component'

const InboxEffectsComponent = (props) => {
    const { onRefreshWidgetType, match, onResetMessageDetails } = props
    const { branchId, damageId, messageId } = match.params

    useEffect(() => {
        onRefreshWidgetType(WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST)
    }, [onRefreshWidgetType, branchId, damageId, messageId])

    useEffect(()=>{
        onResetMessageDetails()
    }, [onResetMessageDetails])

    return <InboxComponent {...props} />
}

InboxEffectsComponent.propTypes = {
    onRefreshWidgetType: PropTypes.func.isRequired,
}

export const InboxEffects = withRouter(InboxEffectsComponent)