import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'


export const accountLogoReducer = (
    state = defaultStore.accountLogo, 
    action
) => {
    switch (action.type) {
    case ACTIONS.ACCOUNT_LOGO_REQUEST_CURRENT_ACCOUNT_LOGO_BY_ACCOUNT_ID: {
        return {
            ...state,
            loaded: false,
            loading: true
        }
    }
    case ACTIONS.ACCOUNT_LOGO_POPULATE_CURRENT_ACCOUNT_LOGO: {
        return {
            loaded: true,
            loading: false,
            blobUrl: action.blobUrl,
            accountId: action.accountId,
            accountName: action.accountName
        }
    }
    case ACTIONS.ACCOUNT_LOGO_RESET_CURRENT_ACCOUNT_LOGO: {
        return {
            blobUrl: null,
            loaded: false,
            loading: false,
            accountId: null
        }
    }
    default:
        return state
    }
}