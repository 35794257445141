import React from 'react'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import PropTypes from 'prop-types'
import {IconLink} from 'application/components/building_blocks/main_navigation_sidebar/_common/icon_link'
import { useTranslation } from 'react-i18next'
import { buildToolTip } from '../helper_functions'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import { addWildCard } from 'application/common/route_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'





export const AllBranchesAccordionClosed = (
    {
        primaryColor,
        primaryHoverColor,
        menuName=''
    }
) => {
    const {t} = useTranslation() 
    const dashboard = notNull(
        useMatch(ROUTES.DASHBOARD)
    )
    const damagesOverview = notNull(
        useMatch({ path:addWildCard(ROUTES.DAMAGES_OVERVIEW  ) })
    )
    const tasksOverview = notNull(
        useMatch({ path:addWildCard(ROUTES.TASKS_OVERVIEW ) })
    )
    const inbox = notNull(
        useMatch({ path:addWildCard(ROUTES.ALL_BRANCHES_INBOX)  })
    )
    const controlling = notNull(
        useMatch({ path:addWildCard(ROUTES.CONTROLLING) })
    )
    const activityLog = notNull(
        useMatch(ROUTES.ACTIVITY_LOG)
    )

    const {
        internalAccountLink
    } = useApplicationLinks()

    return <React.Fragment>
        <IconLink 
            toolTip={ buildToolTip(menuName,'Overview',t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={ internalAccountLink( ROUTES.DASHBOARD ) }
            iconKey={'widgets'}
            isActive={dashboard}
        />
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.DAMAGE.READ]}
        >
            <IconLink 
                toolTip={ buildToolTip(menuName,'Damages',t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ internalAccountLink(ROUTES.DAMAGES_OVERVIEW) }
                iconKey={'directions_car'}
                isActive={damagesOverview}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.TASK.READ]} 
        >
            <IconLink
                toolTip={ buildToolTip(menuName,'Tasks',t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={  internalAccountLink( ROUTES.TASKS_OVERVIEW ) }
                iconKey={'description'}
                isActive={tasksOverview}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.MESSAGE.READ]} 
        >
            <IconLink 
                toolTip={ buildToolTip(menuName,'Inbox',t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ internalAccountLink(ROUTES.ALL_BRANCHES_INBOX) }
                iconKey={'mail_outline'}
                isActive={inbox}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.CONTROLLING.READ]}
        >
            <IconLink 
                toolTip={buildToolTip(menuName,'Controlling',t)}
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ internalAccountLink(ROUTES.CONTROLLING) }
                iconKey={'bar_chart'}
                isActive={controlling}
            />
        </ForAnyBranchPermissions>
        <IconLink 
            toolTip={ buildToolTip(menuName, 'Activities', t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={ internalAccountLink(ROUTES.ACTIVITY_LOG) }         iconKey={'list'}
            isActive={activityLog}
        />
    </React.Fragment>

}
 

AllBranchesAccordionClosed.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    menuName: PropTypes.string
}