import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'
import { CombinedQuery, FilterQuery } from '@ec/filter-sort-page-query-js'

export const getDamagesStatistics = (params) => (
    {
        authenticated: () => {
            const {
                filterQuery = []
            } = params

            const queryString = new CombinedQuery(
                [
                    new FilterQuery(filterQuery)
                ]
            ).queryString

            const request = fetchRequestBuilder(`statistics/damagescount${queryString}`, HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)