import React from 'react'
import { useTranslation } from 'react-i18next'
import {DateComponent} from 'application/components/controls/date'
import * as Fragments from '../fragments'
import  { InvoiceName } from 'application/components/name_mappings/invoices'
import { FlexBox } from 'application/components/fragments/flex_box'

export const InvoiceInfo = ({ invoiceData }) => {
    const { t } = useTranslation()

    return <FlexBox flexDirection='column'>
        <Fragments.InvoiceInformation>
            <Fragments.InvoiceInformationLabel>
                {t('Invoice date')}
            </Fragments.InvoiceInformationLabel>
            <DateComponent date={invoiceData.issuedAt} />
            , {t('by')} {invoiceData.creator.name}
        </Fragments.InvoiceInformation>
        <Fragments.InvoiceInformation>
            <Fragments.InvoiceInformationLabel>
                {t('Bill number')}
            </Fragments.InvoiceInformationLabel>
            {invoiceData.number}
        </Fragments.InvoiceInformation>
        <Fragments.InvoiceInformation>
            <Fragments.InvoiceInformationLabel>
                {t('invoice type')}
            </Fragments.InvoiceInformationLabel>
            <InvoiceName invoicesType={invoiceData.type} />
        </Fragments.InvoiceInformation>
        <Fragments.InvoiceInformation>
            <Fragments.InvoiceInformationLabel>
                {t('Description')}
            </Fragments.InvoiceInformationLabel>
            <FlexBox cssRules='white-space: pre-line;'>{invoiceData.description}</FlexBox>
        </Fragments.InvoiceInformation>
    </FlexBox>
}