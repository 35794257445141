import { WebStorageStateStore } from 'oidc-client'

export const userManagerConfigDefaults = {
    authority: process.env.REACT_APP_SSO_IDENTITY_SERVICE,
    client_id: process.env.REACT_APP_SSO_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_SSO_APP_CLIENT_SECRET,
    redirect_uri: window.location.origin + '/callback',
    post_logout_redirect_uri: window.location.origin + '/',
    response_type: 'code',
    scope: `openid profile email ${process.env.REACT_APP_SSO_APP_SCOPE}`,
    silent_redirect_uri: window.location.origin + '/silent',
    automaticSilentRenew: true,
    monitorAnonymousSession: true,
    revokeAccessTokenOnSignout: true,
    filterProtocolClaims: false,
    response_mode: 'query',
    userStore: new WebStorageStateStore({ store: window.localStorage })
}