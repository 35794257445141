import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import {withRouter} from 'application/components/higher_order_components/with_router'

export const SharedResourceRedirectComponent = withRouter(({
    children,
    sharedResource,
    sharedResourceId,
    sharedResourceType,
    onSetReturnUrl,
    match
}) => {
    useEffect(()=>{
        onSetReturnUrl(match.url)
    }, [match.url, onSetReturnUrl])

    if(!sharedResource.pinIsValidated && !sharedResource.pinValidationPending && !sharedResource.pinIsValid){
        return <Navigate 
            to={`${ROUTES.EXT_SHARED_RESOURCE_BASE}${sharedResourceType}/${sharedResourceId}`} 
        />
    }

    return <React.Fragment>{children}</React.Fragment>
})