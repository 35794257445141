import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { damageNoteDataMapper } from 'application/api/data_mappers/export/damage'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreateCaseNotesSaga(action) {
    yield put(showWaitingScreen('Creating case note'))
    const payload = damageNoteDataMapper(action.payload)
    yield resourceCall(
        api.gateway.damage.updateDamageNote, 
        {
            damageId: action.metaData.damageId
        }, 
        payload
    )
    yield put(hideWaitingScreen())
}