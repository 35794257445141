import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'


export const createUserDataMapper = userFormData => {
    const payload  = {
        email: userFormData.email,
        firstName: userFormData.firstName,
        lastName: userFormData.lastName,
        company: userFormData.company,
        phoneNumber: userFormData.phoneNumber,
        mobilePhoneNumber: userFormData.mobilePhoneNumber
    }

    return removePropertiesWithEmptyStrings(payload)
}

export const updateUserGroupsDataMapper = selectedGroups => {
    return {
        groups: selectedGroups.map(g => ({id: g.id}))
    }
}