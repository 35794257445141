import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { ListFilter, users } from 'application/filters'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogAdminUsersSaga(action) {
    const { dialogName } = action

    const filterQuery = new ListFilter(
        users.getUsersInAdminGroups
    ).getQuery()

    const usersResponse = yield resourceCall(
        api.gateway.users.getUsersList, 
        {
            offset: 0, 
            limit: 1, 
            orderBy: 'lastName', 
            direction: 'desc', 
            filterQuery
        }
    )
    
    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                adminUsers: usersResponse
            }
        )
    )
}