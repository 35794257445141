import Nominatim from 'nominatim-geocoder'
import { exists } from './data_helpers'

export const isCompleteAddress = address => {
    if(!exists(address)){
        return false
    }
    return exists(address.line1) 
    && exists(address.postalCode) 
    && exists(address.locality)
}

export const getMapAddress = address => {
    if(!isCompleteAddress(address)){
        return null
    }
    return {
        street: address.line1,
        postalcode: address.postalCode,
        city: address.locality
    }
}

export const isCompleteAddressCoordinates = address => {
    return exists(address?.coordinates?.latitude) 
        && exists(address?.coordinates?.longitude)
}

export const getLatLongForMap = address => {
    if(!exists(address)){
        return null
    }
    if(!isCompleteAddressCoordinates(address)){
        return null
    }
    const lat = address.coordinates.latitude
    const lon = address.coordinates.longitude
    return [lat, lon]
}

export const geoCode = (
    addressQuery, 
    onFinished, 
    onSuccess = () => {}, 
    onError = () => {}
) => {
    const geoCoder = new Nominatim({
        secure: true
    })
    geoCoder.search(addressQuery)
        .then(
            (response) => {
                const {
                    lat, 
                    lon
                } = response[0]

                onFinished({
                    lat, 
                    lon
                })
                onSuccess({
                    lat, 
                    lon
                })
            }
        ).catch(
            (error) => {
                onFinished({
                    lat: '', 
                    lon: ''
                })
                onError(error)
            }
        )
}