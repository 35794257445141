import React, {useRef} from 'react'
import { useTranslation } from 'react-i18next'
import {RealtimeNotifications} from 'application/components/building_blocks/realtime_notifications'
import { Headline3 } from 'application/components/fragments/typography'
import { IconButton } from 'application/components/controls/icon_button/component'
import { useClickOutside } from 'application/components/hooks'
import { Link } from 'application/components/controls/hyperlink'
import { ROUTES } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import { StyledSidebar } from './fragments'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const RealtimeNotificationsSidebarComponent =  
(
    {
        sidebarVisible, 
        onHideSidebar
    }
) => {
    const sidebarRef = useRef()
    useClickOutside(sidebarRef, onHideSidebar)

    const {
        internalAccountLink
    } = useApplicationLinks()

    const {t} = useTranslation()
    if(!sidebarVisible){
        return null
    }

    return <StyledSidebar ref={sidebarRef}>
        <FlexBox 
            flexShrink='0' 
            flexBasis='48px'
        >
            <Headline3 
                cssMargin='0'>
                {t('Last notifications')}
                <IconButton 
                    hoverBackground='transparent' 
                    iconKey='close' 
                    onButtonClick={onHideSidebar} 
                />
            </Headline3>
        </FlexBox>
        <RealtimeNotifications onLinkClick={onHideSidebar} />
        <Link 
            onClick={onHideSidebar} 
            to={ internalAccountLink(ROUTES.ACTIVITY_LOG) }
        >
            {t('show all activities')}
        </Link>
    </StyledSidebar>
}