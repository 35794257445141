import React, { useState } from 'react'
import { PushBottom24, SubPageLayout } from 'application/components/pages/_common'
import { useCSVReader } from 'react-papaparse'
import { useBatchCustomerImportValidation } from 'application/components/hooks/use_batch_customer_import_validation'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ErrorTree, SuccessMessage, CsvInfo, CsvUpload } from './sub_components'

export const CustomersImportComponent = ({
    onSendBatchImportRequest,
    onResetBatchImport,
    batchImport
}) => {
    const { progress, complete } = batchImport
    const [forBusinessCustomers, setForBusinessCustomers] = useState(false)
    const [queuedCustomers, setQueuedCustomers] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [acceptedFile, setAcceptedFile] = useState(null)
    const { CSVReader } = useCSVReader()

    const {
        // validCustomers,
        // invalidCustomers,
        validRepairedCustomers,
        invalidRepairedCustomers,
        // validCleanedCustomers,
        // invalidCleanedCustomers
    } = useBatchCustomerImportValidation(queuedCustomers, forBusinessCustomers)

    return <SubPageLayout>

        <FlexBox 
            flexDirection='column' 
            cssRules='max-width: 1000px;'
        >

            <PushBottom24 />

            <CsvInfo />
                    
            <CSVReader
                onUploadAccepted={(results, file) => {
                    setAcceptedFile(file)
                    setQueuedCustomers(results.data)
                    setDataLoaded(true)
                }}
                config={{
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ';'
                }}
            >
                {({
                    getRootProps,
                    ProgressBar,
                    getRemoveFileProps,
                }) => {
                    return <React.Fragment>

                        <CsvUpload 
                            complete={complete}
                            dataLoaded={dataLoaded}
                            ProgressBar={ProgressBar}
                            onAddCsvFile={getRootProps().onClick}
                            onRemoveFile={e=>{
                                getRemoveFileProps().onClick(e)
                                setDataLoaded(false)
                                setQueuedCustomers([])
                                setAcceptedFile(null)
                            }}
                            acceptedFile={acceptedFile}
                            validCustomers={validRepairedCustomers}
                            onSubmit={()=>{
                                onSendBatchImportRequest(validRepairedCustomers)
                            }}
                            forBusinessCustomers={forBusinessCustomers}
                            setForBusinessCustomers={setForBusinessCustomers}
                        />

                        <SuccessMessage 
                            complete={complete}
                            progress={progress}
                            onReset={(e)=>{
                                onResetBatchImport()
                                getRemoveFileProps().onClick(e)
                                setDataLoaded(false)
                                setQueuedCustomers([])
                                setAcceptedFile(null)
                            }}
                        />

                    </React.Fragment>

                }}
            </CSVReader>

            <ErrorTree 
                complete={complete}
                dataLoaded={dataLoaded}
                invalidCustomers={invalidRepairedCustomers}
            />

        </FlexBox>

    </SubPageLayout>     
}