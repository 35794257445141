import React from 'react'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { SubPageLayout } from 'application/components/pages/_common'
import {CategoryTree} from 'application/components/building_blocks/category_tree'



const AdministrationSettingsCategories = ({
    categories,
    categoriesLoaded
}) => {
    if(!categoriesLoaded){
        return <ThemedPreloader />
    }

    return <SubPageLayout>
        <CategoryTree 
            categories={categories}
            categoriesLoaded={categoriesLoaded}
        />
    </SubPageLayout>
}

export const AdministrationSettingsCategoriesC = AdministrationSettingsCategories