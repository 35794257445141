import { validationMethods } from 'application/common'
import { DatePicker, TextInput } from 'application/components/controls/form'
import { DamageTypeSelect } from 'application/components/controls/form/damage_type_select'
import { TwoColumns } from 'application/components/forms/_common'
import React from 'react'
import { useTranslation } from 'react-i18next'






export const BasicDamageInformationPartialForm = ({
    register,
    errors,
    disabled = false,
    setValue,
    trigger,
    formStateValues,
    updateFormState,
    control,
    damageCreatedAt,
    damageTypeMenuPlacement='top',
    damageTypeValidate = null
}) => {
    const {t} = useTranslation()
    
    return <React.Fragment>
        <TwoColumns 
            columnLeft={
                <TextInput 
                    label={t('Job number')}
                    name='jobNumber'
                    register={register}
                    validate={null}
                    error={errors.jobNumber}
                    onChange={updateFormState}
                />
            }
        />
        <TwoColumns
            columnLeft={
                <DamageTypeSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.type}
                    onChange={updateFormState}
                    disabled={disabled}
                    menuPlacement={damageTypeMenuPlacement}
                    validate={damageTypeValidate}
                    error={errors.type}
                />
            }
            columnRight={
                <DatePicker 
                    label={t('Damage date')}
                    name='incidentDate'
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.incidentDate}
                    control={control}
                    disabledDays={{after: new Date(damageCreatedAt)}}
                    onChange={updateFormState}
                    register={register}
                    defaultValue={formStateValues.incidentDate}
                    setValue={setValue}
                    trigger={trigger}
                />  
            }
        />
        <TextInput 
            label={t('Damage description')}
            name='description'
            register={register}
            validate={null}
            error={errors.description}
            onChange={updateFormState}
        />  
    </React.Fragment>
}