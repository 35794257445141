import { connect } from 'react-redux'
import {DamageManagerTasksSidebarEffects} from './effects'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { hideAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { changeAttachmentCategoryFromTask } from 'application/redux/actions/common'

const mapStateToProps = (state) => ({
    tasksAttachmentDetails: state.pages.damageManager.tasks.attachmentDetails,
})

const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetContext: (context) => dispatch(
            refreshWidgetsByContext(context)
        ),
        
        onTaskAttachmentDetailsClose: () => dispatch(
            hideAttachmentDetails()
        ),
        
        onChangeCategory: (
            damageId, 
            attachmentId, 
            category
        ) => dispatch(
            changeAttachmentCategoryFromTask(
                damageId, 
                attachmentId, 
                category
            )
        )
    }
}

export const TasksSidebar = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerTasksSidebarEffects)