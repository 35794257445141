import { connect } from 'react-redux'
import {NoAccountsHoc} from './hoc'
import { 
    setPermissionsFound,
    setNoPermissionsFound,
    setNoAccountsFound,
    setAccountsFound
} from 'application/redux/actions/auth'

const mapStateToProps = (state) => {
    return {
        accounts: state.auth.accounts,
        accountsFound : state.auth.accountsFound,
        permissionsFound : state.auth.permissionsFound,
        accountsLoaded: state.auth.accountsLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetPermissionsFound: () => { 
            dispatch( setPermissionsFound() ) 
        },
        onSetNoPermissionsFound: () => { 
            dispatch( setNoPermissionsFound() ) 
        },
        onSetNoAccountsFound: () => {
            dispatch( setNoAccountsFound() )
        },
        onSetAccountsFound: () => {
            dispatch( setAccountsFound())
        }
    }
}

export const NoAccountsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(NoAccountsHoc)