import React, { useState } from 'react'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'

export const EditBool = ({
    defaultValue,
    onChange,
}) => {
    const {t} = useTranslation()
    const [value, setValue] = useState(defaultValue)
    return <FlexBox 
        flexDirection='row' 
        flexBasis='fit-content' 
        flexGrow='0' 
        flexShrink='1'
        width='auto' 
        cssRules='gap: 16px;'
    >
        <PureCheckBox 
            label={t('Yes')}
            checked={value} 
            onChange={()=>{
                onChange(true)
                setValue(true)
            }}  
        />
        <PureCheckBox 
            label={t('No')}
            checked={!value} 
            onChange={()=>{
                onChange(false)
                setValue(false)
            }}  
        />
    </FlexBox>
}