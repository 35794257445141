import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiFilestream, getDownloadUrl, downloadFile } from 'application/api/helpers'

export const downloadDamageDocumentsArchive = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/documents/zip`,
                HTTP_METHOD.GET
            )
            return apiFilestream(request)
                .then(getDownloadUrl)
                .then(downloadUrl => {
                    downloadFile(downloadUrl, params.damageId)
                })
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents/zip`,
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiFilestream(request)
                .then(getDownloadUrl)
                .then(downloadUrl => {
                    downloadFile(downloadUrl, payload.fileName)
                })
        },
    }
)