import { WIDGET_TYPES } from 'application/constants'
import { refreshInvoices } from 'application/redux/actions/pages/workflow_panel/shared'
import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { put } from 'redux-saga/effects'

export function* refreshWorkflowPanelInvoicesSaga() {
    yield put(
        refreshWidgetsByType(WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES)
    )
    yield put( refreshInvoices() )
}