import React, {useEffect, useMemo, useState} from 'react'
import { ActionButtonBar } from 'application/components/controls/action_button_bar'
import { MobileTimeLineStep } from 'application/components/building_blocks/mobile_time_line'
import { ActionButton } from 'application/components/controls/action_button'
import { useTranslation } from 'react-i18next'
import { PushBottom16, PushBottom32, PushBottom64, Spacer } from 'application/components/pages/_common'
import { Paragraph, TextLink } from 'application/components/fragments/typography'
import { RadioCheckboxWithLabel }  from 'application/components/controls/form/checkboxes/radio_checkbox_with_label'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { HiddenField } from 'application/components/controls/form'
import { useGeoLocationTracking } from 'application/components/hooks/use_geo_location_tracking'
import { exists } from 'application/common/data_helpers'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'

export const Step2Component = (
    {
        currentTimelineStepIndex,
        gotoPreviousStep,
        gotoNextStep,
        createDamageFormHook,
        files,
        onCreateDamage
    }
) => {
    const [coords, setCoords] = useState('')
    const {t} = useTranslation()
    const [towingNeeded, setTowingNeeded] = useState(false)
    const theme = useTheme()

    const {
        register,
        setValue,
        formStateValues
    } = createDamageFormHook

    const noteText = useMemo(()=>{

        const phoneNumberString = exists(formStateValues.mobilePhoneNumber) 
            ? `Gemeldet von: ${formStateValues.mobilePhoneNumber.split(' ').join('')}` 
            : 'Keine Telefonnummer'

        const mapsString = exists(coords) 
            ? `google.com/maps/place/${coords}` 
            : 'Keine Koordinaten'

        return `${mapsString} / ${phoneNumberString}`
        
    }, [coords, formStateValues.mobilePhoneNumber])

    useEffect(()=>{
        setValue('note', noteText)
    }, [noteText, setValue])

    const {
        trackLocation,
        geoLocationBlocked,
        trackingSuccessful,
        geolocationHelpLinks,
        getMessage,
        trackingPending
    } = useGeoLocationTracking(
        (result)=>{
            setCoords(result.message)
            if(!result.success){
                setTowingNeeded(false)
            }
        }
    )

    const {
        getSetting
    } = useUiSettings()

    const categoryId = getSetting(UI_SETTINGS.ACCOUNT.EXTERNAL_DAMAGE_CREATION_CATEGORY)

    const handleCreateExternalDamage = () => {
        const damageData = createDamageFormHook.getValues()
        const filesData = {
            files, 
            categoryId
        }
        onCreateDamage(
            damageData,
            filesData
        )
        gotoNextStep()
    }

    const handleCheckboxChange = isTowingNeeded => {
        setTowingNeeded(isTowingNeeded)

        if(isTowingNeeded){
            trackLocation()
        }else{
            setValue('note', getMessage(null))
        }
    }

    const infoBoxType = useMemo(()=>{
        if(geoLocationBlocked){
            return INFOBOX.TYPES.WARNING
        }
        if(!geoLocationBlocked && towingNeeded && trackingSuccessful){
            return INFOBOX.TYPES.SUCCESS
        }

        return INFOBOX.TYPES.STANDARD

    },[geoLocationBlocked, towingNeeded, trackingSuccessful])

    return <MobileTimeLineStep
        index={2}
        isActive={currentTimelineStepIndex === 2}
        label={t('Position')}
        buttons={
            <ActionButtonBar
                inline={true} 
                buttonsAlign='flex-end'>
                <ActionButton 
                    buttonText={t('Back')} 
                    onButtonClick={gotoPreviousStep} 
                    secondary={true} 
                />
                <ActionButton 
                    buttonText={t('Send accident report')} 
                    onButtonClick={
                        createDamageFormHook.handleSubmit(
                            handleCreateExternalDamage
                        )
                    } 
                    disabled={towingNeeded && !trackingSuccessful}
                />
            </ActionButtonBar>
        }
    >
        <HiddenField 
            register={register}
            name='note'
        />
        <Paragraph cssAlign='center'>
            {t('The customer service can use the location to be able to find you and your vehicle.')}
        </Paragraph>
        <PushBottom16 />
      
        <FlexBox
            flexDirection='row'
            justifyContent='center'
        > 

            <RadioCheckboxWithLabel
                name='towingNeeded'
                label={t('Yes')}
                isRequired={false} 
                isSelected={towingNeeded}
                onCheckboxChange={()=>{
                    handleCheckboxChange(true)
                }}
                fontSize={'12px'}
            />

            <Spacer basis='32px' />

            <RadioCheckboxWithLabel
                name='towingNeeded'
                label={t('No')}
                isRequired={false} 
                isSelected={!towingNeeded}
                onCheckboxChange={()=>{
                    handleCheckboxChange(false)
                }}
                fontSize={'12px'}
            />

        </FlexBox>

        <PushBottom32 />
        <InfoBox 
            type={infoBoxType} 
            direction='row' 
        >
            <FontIcon icon={!geoLocationBlocked && towingNeeded && trackingSuccessful  ? 'check' :'report_problem'} color={geoLocationBlocked ? theme.color.warning : !geoLocationBlocked && towingNeeded && trackingSuccessful ? theme.color.success : theme.color.anthracite} />
            <Spacer basis='10px' />
            <div>
                {
                    ((!geoLocationBlocked && !towingNeeded) || trackingPending) && <Paragraph>
                        {t('If you want to send the request without this information, continue with "Send accident report".')}
                    </Paragraph>
                }

                {
                    !geoLocationBlocked && towingNeeded && trackingSuccessful && <Paragraph>
                        {t('Location tracking was successful. You can now send the accident report.')}
                    </Paragraph>
                }

                {
                    geoLocationBlocked && <React.Fragment>
                        <Paragraph>
                            {t('To send this information to the customer service, you have to accept your browsers request to track your location.')}
                        </Paragraph>
                        <Paragraph><strong>{t('You can find out how to enable location tracking for you browser here:')}</strong></Paragraph>
                        <Paragraph>
                            {
                                geolocationHelpLinks.map((l, i)=>{
                                    return <TextLink key={i} target='_blank' href={l.link}>&raquo; {l.browser}</TextLink>
                                })
                            }
                        </Paragraph>
                    </React.Fragment>
                }
            </div>
            <Spacer />
        </InfoBox>
        <PushBottom64 />
    </MobileTimeLineStep>
}