import { connect } from 'react-redux'
import {TableMyTasksOverview} from './component'


const mapStateToProps = state => {
    return {
        currentUserId: state.auth.user.id
    }
}

export const MyTasksOverviewTable = connect(
    mapStateToProps
)(TableMyTasksOverview)