import { mapDispatchToProps, mapStateToProps } from './container'
import { compose } from 'redux'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { withExportToLawfirmConfirmationComponent } from './component'
import { connect } from 'react-redux'

export const withExportToLawfirmConfirmationDialog = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withExportToLawfirmConfirmationComponent
)