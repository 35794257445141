import React from 'react'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import { WIDGET_CONTEXTS } from 'application/constants'
import {DocumentSidebar} from 'application/components/building_blocks/document_sidebar'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { exists } from 'application/common/data_helpers'

export const DamageManagerTasksSidebarComponent = withRouter((
    {
        tasksAttachmentDetails, 
        onTaskAttachmentDetailsClose, 
        onChangeCategory, 
        match
    }
) => {
    //don't render the sidebar if no active task is given
    if(!exists(match.params.taskId)){
        return null
    }

    return tasksAttachmentDetails.visible
        ? <DocumentSidebar
            attachmentDetails={tasksAttachmentDetails}
            onClose={onTaskAttachmentDetailsClose}
            showCategorySelect={true}
            onChangeCategory={onChangeCategory}
            taskId={match.params.taskId}
        />
        : <WidgetArea 
            widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR} 
        />
})