import { connect } from 'react-redux'
import {ChangelogComponent} from './component'
import { showSidebar } from 'application/redux/actions/realtime_notifications'
import { withLoggedInUser } from 'application/components/context/logged_in'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onShowNotificationsSidebar: () => dispatch(showSidebar())
    }
}

export const Changelog = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(ChangelogComponent))