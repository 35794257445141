import { useState } from 'react'

export const useGravatar = (
    initialFirstName = '',
    initialLastName = '',
    isCompany = false,
    initialCompanyName = ''
) => {
    const [firstName, setFirstName] = useState(initialFirstName)
    const [lastName, setLastName] = useState(initialLastName)
    const [companyName, setCompanyName] = useState(initialCompanyName)

    const getInitials = () => {
        if(isCompany){
            return companyName.charAt(0).toUpperCase()
        }
        return firstName !== '' && firstName !== undefined
            ? `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`
            : lastName.charAt(0).toUpperCase()
    }

    const getFullName = () => {
        if(isCompany){
            return companyName
        }
        return firstName !== '' && firstName !== undefined 
            ? `${firstName} ${lastName}` 
            : lastName
    }

    return {
        setFirstName,
        setLastName,
        setCompanyName,
        getInitials,
        getFullName
    }
}
