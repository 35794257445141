import React from 'react'
import * as Fragments from '../fragments'
import PropTypes from 'prop-types'


const MessageDeletedMetaContent = (
    {
        notification = null, 
        inline = false
    }
) => {
    const {
        messageSubject = '',
        sender
    } = notification.payload

    const senderName = sender.email || sender.name

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Subject'
            value={messageSubject}
        />
        <Fragments.ActivityDescription 
            label='From'
            value={senderName}
        />
    </Fragments.MetaContentContainer>
}

MessageDeletedMetaContent.propTypes = {
    notification: PropTypes.object.isRequired, 
    inline: PropTypes.bool,
}

export {MessageDeletedMetaContent}