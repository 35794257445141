import styled from 'styled-components'

export const Card = styled.div`
    transition: 0.3s;
    width: 100%;
    border-radius: 0px;
    background: ${props => props.theme.color.gray10};
    padding: 8px 0;
    margin-bottom: 8px;
`

export const CardContainer = styled.div`
    padding: 2px 16px;
`

export const TextName = styled.span`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.headLine2};
`

export const HighlightSubline = styled.p`
    margin: 0;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    font-weight: ${props => props.theme.fontWeight.standardBold};
`

export const TimeStamp = styled.span`
    margin: 0;
    font-style: ${props => props.theme.fontStyle.italic};
    color: ${props => props.theme.color.anthracite50};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
`

export const TextActivity = styled.span`
    font-size: ${props => props.theme.fontSize.medium}
`

export const IsNewMarker = styled.span`
    font-size: inherit;
    font-weight: ${props => props.theme.fontWeight.standardBold};
    color: ${props => props.theme.color.success};
    margin-right: 8px;
    text-transform: uppercase;
`