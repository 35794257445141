import { HTTP_METHOD, fetchRequestBuilder } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const deleteAccountLogo = () => (
    {
        authenticated: () => {            
            const request = fetchRequestBuilder(
                'account/logo', 
                HTTP_METHOD.DELETE,
                null                
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
)