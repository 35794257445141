import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getModules = () => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder('modules', HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null
    }
}