import styled from 'styled-components'

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 ${props => props.cssWidth !== undefined ? props.cssWidth : '25%'};
    background-color: ${props => props.cssBackground !== undefined ? props.cssBackground : props.theme.color.white};
    padding-top: ${props => props.paddingTop !== undefined ? props.paddingTop : '16px'};
    padding-bottom: ${props => props.paddingBottom !== undefined ? props.paddingBottom : '0'};
    padding-left: ${props => props.paddingLeft !== undefined ? props.paddingLeft : '16px'};
    padding-right: ${props => props.paddingRight !== undefined ? props.paddingRight : '16px'};
    height: 100%;
    max-height: 100%;
    overflow: auto;
`