import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {DateComponent} from 'application/components/controls/date'

const StyledEarlyAccessNote = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1 1 auto;
    padding-right: 16px;
`

const Tag = styled.div`
    border-radius: 4px;
    padding: 4px 8px;
    background: ${props => props.theme.color.gray10};
    font-size: ${props => props.theme.fontSize.small};
`

export const EarlyAccessNote =  () => {
    const { t } = useTranslation()

    const commitSha = process.env.REACT_APP_COMMIT_SHA
    const buildDate = new Date(process.env.REACT_APP_BUILD_DATE)

    return <StyledEarlyAccessNote 
        title={`commit sha: ${commitSha}`} 
    >
        <Tag>{t('Last update')}: <strong><DateComponent date={buildDate} /></strong></Tag>
    </StyledEarlyAccessNote>
}

