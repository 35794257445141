import React, { useState }  from 'react'
import { useTranslation } from 'react-i18next'
import { 
    setQuery, useQuery, 
} from 'application/components/hooks/use_query'
import { FlexBox } from 'application/components/fragments/flex_box'
import PropTypes from 'prop-types'
import { RadioCheckboxWithLabel }  from 'application/components/controls/form/checkboxes/radio_checkbox_with_label'
import { useLocation,useNavigate } from 'react-router-dom'




const FilterRadioCheckboxGroup = (
    {
        OFFSET,
        LIMIT,
        filter,
        spaceLeft = false,
        disabled = false,
        checkboxDefinitions = [],
        onChange = () => {}
    }
) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const query = useQuery()

    const value = query.getStringParam(filter.filterKey, filter.defaultValue)

    const [selectedItem, setSelectedItem] = useState(value)
    
    const preHandleCheckBoxChange = (event) => {

        setSelectedItem(event.target.name)
        setQuery(
            navigate, 
            location, 
            location.pathname,
            [
                {
                    key: filter.filterKey,
                    value: event.target.name 
                }
            ],
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
        onChange(event)
    }

    return <FlexBox 
        flexDirection='row'
        width='auto'
        cssRules='border: 1px solid #ccc;'
        marginLeft={ spaceLeft ? '16px' : '0'}
        height='40px'
        paddingLeft='10px'
        paddingRight='10px'     
    >
        {
            checkboxDefinitions.map(
                (definition, i) => {
                    const isActive = definition.value === selectedItem
                    return <FlexBox 
                        key={definition.label}
                        width='auto'
                        marginLeft={ i > 0 ? '16px' : '0' }
                        justifyContent='center'
                        alignItems='center'    
                    >
                        <RadioCheckboxWithLabel
                            name={definition.value}
                            label={t(definition.label)}
                            isRequired={false} 
                            isSelected={isActive}
                            activeColor={isActive}
                            onCheckboxChange={preHandleCheckBoxChange}
                            isSmall={true}
                            disabled={disabled}
                            fontSize={'12px'}
                        />
                    </FlexBox>
                } 
            )
        }
    </FlexBox>
}

FilterRadioCheckboxGroup.propTypes = {
    OFFSET: PropTypes.string.isRequired,
    LIMIT: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    spaceLeft: PropTypes.bool,
    disabled: PropTypes.bool,
    checkboxDefinitions: PropTypes.array
}


export { FilterRadioCheckboxGroup }