import React from 'react'
import { ROUTE_PARAMETERS } from 'application/constants'
import { UserStatus } from 'application/components/controls/user_status'
import {ContextMenuGroupMembers} from './context_menu_container'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'

const tableName = STORAGE.TABLE_NAME.GROUP_MEMBERS



export const configuration = {
    name: tableName,
    filters: {
        match: [
            new ListFilter(filters.users.groupId)
        ],
        query: [],
        property: [],
        static: []
    },
    sorting:  {
        sortParamKey: ROUTE_PARAMETERS.GROUP_MEMBERS.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.GROUP_MEMBERS.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.GROUP_MEMBERS.ORDER_BY.VALUES.LASTNAME,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.GROUP_MEMBERS.OFFSET,
        limit: ROUTE_PARAMETERS.GROUP_MEMBERS.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName)
        }
    },
    href: () => null,
    columns: [
        {
            width: 15,
            label: 'Firstname',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.GROUP_MEMBERS.ORDER_BY.VALUES.FIRST_NAME,
            CellContent: ({element}) => <React.Fragment>{element.firstName}</React.Fragment>
        },
        {
            width: 15,
            label: 'Lastname',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.GROUP_MEMBERS.ORDER_BY.VALUES.LAST_NAME,
            CellContent: ({element}) => <React.Fragment>{element.lastName}</React.Fragment>
        },
        {
            width: 20,
            label: 'Email',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.GROUP_MEMBERS.ORDER_BY.VALUES.EMAIL,
            CellContent: ({element}) => <React.Fragment>{element.email}</React.Fragment>
        },
        {
            width: 10,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <UserStatus user={element} />
        }
    ],
    ContextMenu: ({element, moIsActive}) => moIsActive ? <React.Fragment /> : <ContextMenuGroupMembers user={element} />,
    contextMenuWidth: '34px',
    massOperations: {
        available: false //are mass operations possible for this table?
    }
}
