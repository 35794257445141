export const uiSettingsUpdateRunner = (settings, updaters, newestVersion) => {
    const appliedUpdaters = updaters.filter(updater => updater.toVersion > settings.version && updater.toVersion <= newestVersion)

    let newSettings = settings

    for (const updater of appliedUpdaters) {
        newSettings = updater.update(newSettings)
    }

    return newSettings
}