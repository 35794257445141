import { connect } from 'react-redux'
import { MoDocsIsExportedToLawfirmComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = (state, props) => {

    const dialog = state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name)

    return {
        dialog
    } 
}

const mapDispatchToProps = dispatch => {
    return {        
        onSubmit: (
            dialogName, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        }
    }            
}


export const MoDocsIsExportedToLawfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoDocsIsExportedToLawfirmComponent)
