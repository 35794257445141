import { connect } from 'react-redux'
import { LawfirmInvoicesComponent } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => ({
    isExternal: state.sharedResource.id !== null
})



const mapDispatchToProps = dispatch => {
    return {
        onCreateInvoice: (
            sharedResourceId, 
            incidentDate
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_INVOICE_WORKFLOW_PANEL, 
                true, 
                { sharedResourceId, incidentDate }, 
                'Create invoice'
            )
        ),
    }
}

export const LawfirmInvoices = connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmInvoicesComponent)