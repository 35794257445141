import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'

export const LawFirmCommunicationAbortedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Lawfirm communication aborted')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Lawyer')}: {activity.data.name}
        </Fragments.ActivityParagraph>
    </React.Fragment>
}