import { BRANCH_MANAGEMENT } from 'application/constants'


export const groupToRoleRelationship = (elements, sourceId, targetId) => {
    const group = elements.find(e => e.nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP && (e.id === sourceId || e.id === targetId))
    const role = elements.find(e => e.nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE && (e.id === sourceId || e.id === targetId))

    return {
        sourceId: group.id,
        targetId: role.id
    }
}