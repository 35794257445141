import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { populateStatusList }  from 'application/redux/actions/common'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { showToast } from 'application/redux/actions/notifications'

export function* getStatusListSaga(/*action*/) {
    try {
        const { states, unmappedStates } = yield resourceCall(
            api.gateway.damageState.getCustomStates
        )
        yield put(populateStatusList(states, unmappedStates))
    } catch (e) {
        yield handleError(e)
    }
}

export function* updateCustomoStatusListSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damageState.putMapLifecycleState,
            null,
            action.requestCustomStates
        )
        yield put(showToast('Order saved'))
    } catch (e) {
        yield handleError(e)
    }
}