import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomNodeLowerContainer, NodeType } from './fragments'
import { BRANCH_MANAGEMENT } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'

export const CustomNodeLower = withTheme(({selected, nodeData, theme}) => {
    const {t} = useTranslation()
    const {nodeType, includesBranchPermissions, includesGlobalPermissions, color} = nodeData

    const showGlobalPermissionsIcon = nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE && includesGlobalPermissions
    const showBranchPermissionsIcon = nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE && includesBranchPermissions

    return <CustomNodeLowerContainer background={color} selected={selected}>
        <NodeType>
            {t(nodeType)}
            {showGlobalPermissionsIcon && <React.Fragment>&nbsp;<FontIcon isClickable={true} title='enthält globale Rechte' icon='language' fontSize={theme.fontSize.standard} color={theme.color.white} /></React.Fragment>}
            {showBranchPermissionsIcon && <React.Fragment>&nbsp;<FontIcon isClickable={true} title='enthält filialspezifische Rechte' icon='store' fontSize={theme.fontSize.standard} color={theme.color.white} /></React.Fragment>}
        </NodeType>
    </CustomNodeLowerContainer>
})