import { apiCall } from 'application/api/helpers'
import { fetchRequestBuilderWithoutToken, HTTP_METHOD } from 'application/api/request_builders'

export const getToken = (sharedResourceId) => {
    const request = fetchRequestBuilderWithoutToken(
        `sharedresources/${sharedResourceId}/token`,
        HTTP_METHOD.GET,
        {},
        false
    )
    return apiCall(request)
}