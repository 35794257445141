import { put, select } from 'redux-saga/effects'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateCategories } from 'application/redux/actions/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { getCurrentAccountId } from 'application/redux/selectors'

export function* refreshDocumentCategoriesSaga(action) {
    try {
        const currentAccountId = yield select(getCurrentAccountId)
        const categoriesResponse = yield resourceCall(
            api.gateway.categories.getCategories
        )
        yield put(
            populateCategories(
                categoriesResponse.categories, 
                currentAccountId
            )
        )
    } catch (e) {
        yield handleError(e, action)
    }
}