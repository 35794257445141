import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStatefulForm } from 'application/components/hooks'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { BasicDamageInformationPartialForm } from 'application/components/forms/_partial_forms'
import { DAMAGE_TYPE } from 'application/constants'
import { validationMethods } from 'application/common'

export const CommissionExpertStep1 = (
    { 
        goToNextStep,
        goToPreviousStep,
        setStep1data,
        damage,
        step1data
    }
) => {
    const { t } = useTranslation()
    
    const defaultValues = {
        jobNumber: '',
        type: DAMAGE_TYPE.UNKNOWN,
        incidentDate: null,
        description: '',
        ...step1data
    }




    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue, 
        control, 
        formStateValues, 
        updateFormState,
        trigger
    } = useStatefulForm({defaultValues})
  

    const onSubmitSuccess = () => {
        setStep1data(getValues())
        goToNextStep()
    }

    const handleGoToPreviousStep = () => {
        setStep1data(getValues())
        goToPreviousStep()
    }

    return <React.Fragment>
        <DialogMainContent>
            <BasicDamageInformationPartialForm 
                register={register}
                errors={errors}
                setValue={setValue}
                trigger={trigger}
                formStateValues={formStateValues}
                updateFormState={updateFormState}
                control={control}
                damageCreatedAt={damage.createdAt}   
                damageTypeMenuPlacement='bottom'  
                damageTypeValidate={{
                    damageTypeMustNotBeUnknown: validationMethods.damageTypeMustNotBeUnknown,
                    notEmpty: validationMethods.notEmpty
                }}     
            />
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={handleGoToPreviousStep} 
            />
            <ActionButton 
                buttonText={t('Next')} 
                onButtonClick={
                    handleSubmit(
                        onSubmitSuccess
                    )
                } 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}