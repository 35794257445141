import React from 'react'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'
import { isDefined } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'



export const StateChangedInfo = (
    {activityData}
) => {
    const {t} = useTranslation()
    const oldState = activityData.oldState
    const newState = activityData.newState

    if (
        !( isDefined(oldState) && isDefined(newState) ) 
    ) return null

    const taskTitle = activityData.task?.title || t('Unknown') 

    return <FlexBox justifyContent='flex-start'>
        {t('Task')}: "{taskTitle}", &nbsp; {t(oldState)} <RightArrow /> {t(newState)} 
    </FlexBox>

}

StateChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}