import { connect } from 'react-redux'
import { EditCustomStateComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSaveCustomState: (dialogName, customStateId, payload) => dispatch(sendPayloadRequest(dialogName, payload, {customStateId}))
    }
}

export const EditCustomState = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCustomStateComponent)