import {ACTIONS} from 'application/constants'

export const getTasks = (damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_REQUEST_TASKS,
    damageId
})

export const updateTaskInList = (taskId, payload) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_UPDATE_TASK_IN_LIST,
    taskId,
    payload
})

export const incrementCommentsTotalInTask = (
    taskId
) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_INCREMENT_COMMENTS_TOTAL,
    taskId
})

export const populateTasks = (tasksList, damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_TASKS,
    tasksList,
    damageId
})

export const getSelectedTask = (taskId, damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_REQUEST_SELECTED_TASK,
    taskId,
    damageId
})

export const populateSelectedTask = (task) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK,
    task
})

export const populateSelectedTaskDocuments = (documents, total) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS,
    documents,
    total
})

export const updateSelectedTask = (task) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK,
    task
})

export const updateSelectedTaskDocumentCategory = (attachmentId, category) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK_DOCUMENT_CATEGORY,
    attachmentId, category
})

export const resetSelectedTask = () => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_RESET_SELECTED_TASK
})

export const getUsersForTaskDebounced = (
    offset = 0, 
    limit = 10, 
    filterQuery = []
) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_LOAD_USERLIST_DEBOUNCED,
    offset,
    limit,
    filterQuery,
    orderBy: 'id',
    direction: 'asc'
})

export const populateUsersForTasks = (userList) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_USERLIST,
    userList
})

export const showAttachmentDetails = (attachment, damageId, taskId) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHOW_ATTACHMENT_DETAILS,
    attachment,
    damageId,
    taskId
})

export const hideAttachmentDetails = () => ({
    type: ACTIONS.DAMAGE_MANAGER_HIDE_ATTACHMENT_DETAILS
})

export const removeTaskFromList = (taskId) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASKS_REMOVE_TASK_FROM_LIST,
    taskId
})

export const setAttachmentDetailsCategory = (categoryId) => ({
    type: ACTIONS.DAMAGE_MANAGER_ATTACHMENT_DETAILS_SET_CATEGORY,
    categoryId
})


export const updateTask = (
    damageId, 
    taskId, 
    taskData, 
    isAssign = false
) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_TASK,
    damageId,
    taskId,
    taskData,
    isAssign
})



export const assignTask = (
    damageId, 
    taskId, 
    userId, 
    userFirstName, 
    userLastName
) => ({
    type: ACTIONS.DAMAGE_MANAGER_ASSIGN_TASK,
    damageId, 
    taskId, 
    userId, 
    userFirstName, 
    userLastName
})


export const changeTaskState = (
    damageId, 
    taskId, 
    state
) => ({
    type: ACTIONS.DAMAGE_MANAGER_TASK_STATE,
    damageId, taskId, state
})

export const updateTaskWorkflowPanel = (
    damageId, 
    taskId, 
    taskData, 
    match
) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_TASK_WORKFLOW_PANEL,
    damageId,
    taskId,
    taskData,
    match,
})
