import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    INBOX_MESSAGE_DETAILS: 'INBOX_MESSAGE_DETAILS',
    INBOX_MESSAGE_LIST: 'INBOX_MESSAGE_LIST',
}

export const widgetsInbox = [
    {
        key: KEYS.INBOX_MESSAGE_LIST,
        type: WIDGET_TYPES.INBOX_MESSAGE_LIST,
        title: 'Message List',
        hideHeadline: true,
        dimension: '30%',
        routeParameters: ROUTE_PARAMETERS.INBOX
    },
    {
        key: KEYS.INBOX_MESSAGE_DETAILS,
        type: WIDGET_TYPES.INBOX_MESSAGE_DETAILS,
        title: 'Message Details',
        hideHeadline: true,
        dimension: '70%'
    } 
]