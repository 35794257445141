import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { DamageIsFictiousFilter } from 'application/components/building_blocks/filters/checkboxes/damage_isficticious_filter/component'
import { CompareBranchesFilter } from 'application/components/building_blocks/filters/compare_branches_filter'
import { DamageTypeFilter } from 'application/components/building_blocks/filters/damage_type_filter'
import { TimeRangeFilter } from 'application/components/building_blocks/filters/time_range_filter'
import { DamagesForBranches } from 'application/components/charts/damages_for_branches'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Spacer } from 'application/components/pages/_common'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { ROUTE_PARAMETERS, USER_PERMISSONS } from 'application/constants'
import { controlling, damages } from 'application/filters'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UserFilter } from 'application/components/building_blocks/filters/user_filter'

export const ControllingGlobalDamagesBarChartComponent = (
    {
        loadingFailed, 
        widgetKey, 
        onRefresh, 
        payload
    }
) => {
    const {t} = useTranslation()
    
    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    const loadingComplete = payload.barsLoaded === payload.totalBars

    return <FlexBox flexDirection='column'>
        <FilterFragment.FilterContainer>
            <TimeRangeFilter 
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART}
                onChange={() => {onRefresh(widgetKey)}}
                label='Show damages between'
                disabled={!loadingComplete}
            />
            <ForAnyBranchPermissions
                permissions={[USER_PERMISSONS.USER.READ]}
            >
                <UserFilter
                    routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.USER}
                    filterTitle='Showing damages assigned to'
                    onChange={() => {onRefresh(widgetKey)}}
                    disabled={!loadingComplete}
                />
            </ForAnyBranchPermissions>
            <DamageTypeFilter
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART}
                filterDefinition={damages.type}
                menuPlacement='bottom'
                onChange={() => {onRefresh(widgetKey)}}
                disabled={!loadingComplete}
            />
            <DamageIsFictiousFilter 
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART}
                onChange={() => {onRefresh(widgetKey)}}
                disabled={!loadingComplete}
            />
            <CompareBranchesFilter 
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART}
                filterDefinition={controlling.compareBranches}
                menuPlacement='bottom'
                disabled={!loadingComplete}
                onChange={() => {onRefresh(widgetKey)}}
            />

        </FilterFragment.FilterContainer>
        <Spacer basis='20px' />
   
        <DamagesForBranches 
            chartData={payload.chartData}
            totalBars={payload.totalBars}
            allBarsLoaded={payload.allBarsLoaded}
        />
    </FlexBox>
}

ControllingGlobalDamagesBarChartComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}