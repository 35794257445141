/**
 * Groups items by category
 * @param {array} selectedItems - array of objects with property category 
 * @returns {array} array itemgroup objects. Every group is named by category and has an subarray "items", with items grouped by category
 */
export const groupItemsByCategory  = (selectedItems) => {
    
    const itemsGroupedByCategoriesObject = selectedItems.reduce(
        (
            categoriesSoFar, 
            selectedItem
        ) => {
            const categoryName = selectedItem.category.name

            if (!categoriesSoFar[categoryName]) categoriesSoFar[categoryName] = []
        
            categoriesSoFar[categoryName].push(selectedItem)
            return categoriesSoFar
        }, {}
    )

    const itemGroupKeys = Object.keys(itemsGroupedByCategoriesObject)
    
    return itemGroupKeys.map(
        key => {
            return {
                category: key,
                items: itemsGroupedByCategoriesObject[key]
            }
        }
    )
}