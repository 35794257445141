import React, { useMemo, useState } from 'react'
import { TextInput } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { SubHeadline } from 'application/components/fragments/dialog'
import { useStatefulForm } from 'application/components/hooks'
import { ActionButton } from 'application/components/controls/action_button'
import { pdfBuilders } from 'application/components/dynamic_pdfs'
import { exists, isDefined } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PdfSignature } from 'application/components/building_blocks/pdf_signature'
import { getFullName } from 'application/common/name_helpers'
import { withInsurancesLoader } from 'application/components/higher_order_components'
import { convertISODateToGermanDateString } from 'application/common/date_time_helpers'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { PushBottom16, Spacer } from 'application/components/pages/_common'




const usePowerOfAttorneyDefaultValues = (
    customer, 
    damageData, 
    insurances
) => {
    const {t} = useTranslation()
    const customerObject = customer?.values 
        ? customer.values
        : customer
    const customerName = getFullName(customerObject)
    const insuranceId = damageData?.liabilityInsurance?.id
    || damageData?.liabilityData?.liabilityInsurance?.id
    const insuranceFromList = exists(insuranceId) 

    const insuranceCompanyInfo = insuranceFromList
        ? insurances.find(insurance => insurance.id === insuranceId)?.name
        : isDefined(damageData?.insuranceCompany?.name)  
            ? damageData?.insuranceCompany?.name
            : damageData?.liabilityData?.liabilityInsurance?.name

    const opponentInfo = getFullName(
        damageData?.liabilityData?.vehicleKeeper
    )
    const incidentDate = convertISODateToGermanDateString(damageData?.incidentDate)
    const insuranceCompanyInfoString = !exists(insuranceCompanyInfo) 
        ? '' 
        : `, versichert bei: ${insuranceCompanyInfo}`

    return {
        inMatters: `${customerName} ./. ${opponentInfo}${insuranceCompanyInfoString}`,
        becauseOf: `${t('Compensation for damages from a traffic accident')} ${incidentDate}`
    }
}

export const PowerOfAttorneyForm = withInsurancesLoader(({
    lawfirm,
    customer,
    branch,
    files,
    setFiles,
    submitButtonText = 'Download mandate',
    damage,
    insurances = []
}) => {

    const {
        getSetting
    } = useUiSettings()
    
    const [showSignablePdf, setShowSignablePdf] = useState(false)
    const [addRvgNote, setAddRvgNotes] = useState(getSetting(UI_SETTINGS.ACCOUNT.ADD_RVG_NOTE_TO_POWER_OF_ATTORNEY_PDFS))
    const {t} = useTranslation()

    const defaultValues = usePowerOfAttorneyDefaultValues(
        customer, 
        damage, 
        insurances
    )

    const signatureFields = useMemo(()=>{
        const baseFields = [
            {
                label: 'Power of attorney',
                description: 'Page 1'
            },
            {
                label: 'Instruction',
                description: 'Page 2'
            }
        ]

        if(addRvgNote){
            return [
                ...baseFields,
                {
                    label: 'RVG note',
                    description: 'Page 3'
                }
            ]
        }
        return baseFields
    },[addRvgNote])

    const { 
        handleSubmit, 
        register,
        errors,
        updateFormState,
        getValues
    } = useStatefulForm({
        defaultValues,
        values: defaultValues
    })

    
 

    const pdfValues = {
        lawfirm,
        branch,
        inMatters: getValues().inMatters,
        becauseOf: getValues().becauseOf,
        signatures: [],
        addRvgNote,
        damageLocation: damage?.liabilityData?.accidentReport?.location?.description || '',
        incidentDate: damage?.incidentDate || ''
    }
    
    const onSubmitSuccess = () => {
        setShowSignablePdf(true)
    }

    return <React.Fragment>
        <SubHeadline>{t('Power of attorney')}</SubHeadline>
        <TextInput 
            label={t('In matters')}
            name='inMatters'
            register={register}
            validate={null}
            error={errors.inMatters}
            onChange={updateFormState}
        />
        <TextInput 
            label={t('Because of')}
            name='becauseOf'
            register={register}
            validate={null}
            error={errors.becauseOf}
            onChange={updateFormState}
        />
        <FlexBox>
            <PureCheckBox 
                label={t('Add RVG note')}
                checked={addRvgNote}
                onChange={setAddRvgNotes}
            />
            <Spacer basis='16px'/>
            <ActionButton
                buttonText={t(submitButtonText)}
                onButtonClick = {handleSubmit(onSubmitSuccess)}
                stretched={false}
            />
        </FlexBox>
        <PushBottom16 />
        {
            showSignablePdf && <PdfSignature 
                pdfBuilder={pdfBuilders.powerOfAttorneyPdfBuilder}
                pdfValues={pdfValues}
                pdfFileName='anwaltliche_vollmacht.pdf'
                onSave={
                    (url, attachment)=>{
                        setFiles([...files, attachment])
                        setShowSignablePdf(false)
                    }
                }
                abort={
                    ()=>{ setShowSignablePdf(false) }
                }
                headline='Create power of attorney'
                saveButtonText='Done'
                signatureFields={signatureFields}
            
            />
        }

    </React.Fragment>
})