import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import {MyTasks} from './my_tasks'
import {AllTasks} from './all_tasks'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { useMatch } from 'react-router-dom'
import { HookRoute } from 'application/components/building_blocks/hook_route'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { matchRoute } from 'application/common/route_helpers'





const TasksOverview = () => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const matchTasks = matchRoute(
        ROUTES.TASKS_OVERVIEW_MY_TASKS,
        useMatch
    )
    const matchAllTasks = matchRoute(
        ROUTES.TASKS_OVERVIEW_ALL_TASKS,
        useMatch
    )

    if (useMatch(ROUTES.TASKS_OVERVIEW)) {
        return <Navigate 
            to={
                internalAccountLink(
                    ROUTES.TASKS_OVERVIEW_MY_TASKS
                )
            } 
        />
    }

    return <LayoutAllBranches
        titleSegments={['Tasks', 'All branches']}
        headlineSpecific={t('Tasks')}
    >
        <TabsLinks
            items={[
                {
                    label: t('My tasks'),
                    link: internalAccountLink(
                        ROUTES.TASKS_OVERVIEW_MY_TASKS
                    ),
                    isActive: matchTasks
                },
                {
                    label: t('All tasks'),
                    link: internalAccountLink(
                        ROUTES.TASKS_OVERVIEW_ALL_TASKS
                    ),
                    isActive: matchAllTasks
                } 
            ]}
        />
        <HookRoute path={ROUTES.TASKS_OVERVIEW_ALL_TASKS}>
            <AllTasks />
        </HookRoute>
        <HookRoute path={ROUTES.TASKS_OVERVIEW_MY_TASKS}>
            <MyTasks />
        </HookRoute>
    </LayoutAllBranches>
}

export const TasksOverviewComponent = withLoggedInUser(
    withRouter(TasksOverview)
)