import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'

export const InvoiceDeletedActivity = ({activity, match}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Invoice deleted')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>{t('Bill number')}: {activity.data.number}</Fragments.ActivityParagraph>
    </React.Fragment>
}