import styled from 'styled-components'

export const HighlightContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: ${props => props.justifyFlexStart ? 'flex-start' : 'space-between'};
  align-items: flex-start;
  background-color: ${props => props.theme.color.gray5};
  height: ${props => props.cssHeight ? props.cssHeight : 'auto'};
  align-content: stretch;
  margin-right: ${props => props.isMarginRightNeeded ? '8px' : 'unset'};
`

export const HighlightContainerInner = styled.div`
  flex: ${props => props.cssWidth ? '0 0 ' + props.cssWidth : '1 1 auto'};
  display: flex;
  justify-content: ${props => props.justifyContent !== undefined ? props.justifyContent : 'space-between'};
  align-items: center;
  background-color: ${props => props.theme.color.gray5};
  height: auto;
  padding: 8px;
    &:first-child:{
      padding-right: 0;
    }
    &:last-child: {
      padding-left: 0;
    }
  align-content: stretch;
  margin-right: ${props => props.isMarginRightNeeded ? '8px' : 'unset'};
`

export const HighlightItem = styled.div`
  color: ${props => props.theme.color.black};
  font-size: ${props => props.theme.fontSize.standard};
  text-align: center;
  margin: 0px 18px 0px 0px;
  &:last-child{
    margin-right: 0;
  }
  display: flex;
  flex: ${props => props.cssWidth ? '0 0 ' + props.cssWidth : '1 1 auto'};
  &>*{
    flex: 1 1 auto;
  }

  & .reactSelectWrapper{
    background: transparent;
    &:hover{
      background: ${props => props.theme.color.white}; 
    }
  }
  & .reactSelect>div {
    background: transparent;
    border: 1px solid transparent;
    &.css-26l3qy-menu{
      background: ${props => props.theme.color.white}; 
      border: 1px solid ${props => props.theme.color.gray15};      
    }
    &:hover{
      background: ${props => props.theme.color.white}; 
      border: 1px solid ${props => props.theme.color.gray15};
    }

  }
`

export const StyledTaskHighlightLinkButton = styled.div`
    position: absolute;
    padding-left: 10px;
    bottom: 4px;
`