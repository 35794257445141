import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const activityLogReducer =  (state = defaultStore.pages.activityLog, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.activityLog
    }
    case ACTIONS.ACTIVITY_LOG_GET_ACTIVITIES_REQUEST:
        return {
            ...state,
            activitiesListLoading: true,         
            activitiesListLoaded: false         
        }
    case ACTIONS.ACTIVITY_LOG_POPULATE_ACTIVITIES_LIST:
        return {
            ...state,
            activitiesListLoading: false,
            activitiesListLoaded: true,    
            activitiesList: action.activitiesList,
            activitiesListTotal: action.activitiesListTotal   
        }
    default:
        return state
    }
}
