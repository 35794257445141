import { useState, useMemo, useEffect, useCallback } from 'react'
import { downloadFile } from 'application/api/helpers'
import { createBlobFromObjectUrl } from 'application/api/helpers'
import { fileHelpers } from 'application/common'
import { exists } from 'application/common/data_helpers'

export const useDynamicPdf = (
    pdfBuilder,
    pdfValues, 
    fileName
) => {
    const [pdfValuesState, setPdfValuesState] = useState(pdfValues)
    const [url, setUrl] = useState(null)
    const [attachment, setAttachment] = useState(null)
    const [attachmentAvailable, setAttachmentAvailable] = useState(false)
    const [currentSignatureIndex, setCurrentSignatureIndex] = useState(0)

    //resolve this promise to get the object URL of the PDF
    const pdfUrlPromise = useMemo(async () => {
        setAttachmentAvailable(false)
        const blob = await pdfBuilder(pdfValuesState).toBlob()
        return window.URL.createObjectURL(blob)
    }, [pdfBuilder, pdfValuesState])

    const download = () => {
        pdfUrlPromise.then(pdfUrl => {
            downloadFile(pdfUrl, fileName)
        })
    }

    //add signature base64 data from signature pad component
    const addSignature = (base64Data, signatureIndex) => {
        setAttachmentAvailable(false)
        var newSignatures = pdfValuesState.signatures
        newSignatures[signatureIndex] = base64Data

        setPdfValuesState({
            ...pdfValuesState,
            signatures: newSignatures
        })
    }

    //write file in internal attachment data structure (can be used to inject PDF into an uploader component)
    const writeAttachment = useCallback(async () => {
        const fileBlobPromise = createBlobFromObjectUrl(url)
        await fileBlobPromise.then(fileBlob => {
            const file = new File(
                [fileBlob], 
                fileName, 
                { type: 'application/pdf' }
            )
            fileHelpers.readFiles(
                [file], 
                (a) => {
                    setAttachment(a)
                    setAttachmentAvailable(true)
                }
            )
        })

    }, [fileName, url])

    //update the state values on property change
    useEffect(() => {
        setPdfValuesState(pdfValues)
    }, [pdfValues])

    //rerender the pdf on state values change (eg a signature is added)
    useEffect(() => {
        pdfUrlPromise.then(pdfUrl => {
            setUrl(pdfUrl)
        })
    }, [pdfValuesState, pdfUrlPromise])

    //rewrite the attachment object on pdf change
    useEffect(()=>{
        writeAttachment()
    },[ writeAttachment ])

    const hasSignature = useMemo(() => {
        if(!pdfValuesState.signatures){
            return false
        }

        return pdfValuesState.signatures.length > 0 
            && pdfValuesState.signatures.some(s => exists(s))
    }, [pdfValuesState])

    return {
        download,
        url,
        addSignature,
        attachment,
        attachmentAvailable,
        currentSignatureIndex, 
        setCurrentSignatureIndex,
        pdfValuesState,
        hasSignature
    }
}