import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'

import { TitleBarDamageManagerComponent } from './component'

const mapStateToProps = (state) => {
    return {
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damageLoading: state.pages.damageManager.shared.damageLoading,
        damage: state.pages.damageManager.shared.damage,
        hasLawfirm: state.pages.damageManager.shared.hasLawfirm,
        lawfirmState: state.pages.damageManager.shared.lawfirm?.state,
        branchId: state.pages.damageManager.shared.damage.branchId,
        currentBranchId: state.auth.currentBranch?.id,
        hasMultipleBranches: state.auth.hasMultipleBranches,
        expertFound: state.pages.damageManager.shared.expertFound,
        hasAccountLogo: state.auth?.currentAccount?.hasLogo || false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDialogCreateLawyers: (
            damageId,
            changeMode,
            branchId
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMISSION_LAWFIRM,
                false,
                {
                    lawyersList: [],
                    lawyersListLoading: false,
                    changeMode,
                    damageId,
                    branchId,
                    lawfirm: null
                },
                'Commission lawfirm'
            )
        ),
        onOpenVehicleAndKeeperQRCodeCreationDialog: (
            payload
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER,
                false,
                payload,
                'Create QR code for vehiclekeeper'
            )
        ),
        onCreateMessageForLawyer: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_MESSAGE_FOR_LAWFIRM,
                false,
                payload,
                'New message for lawfirm'
            )
        ),
        onCreateCostCoverage: (payload) => {
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CREATE_COST_COVERAGE,
                    false,
                    payload,
                    'Create cost coverage'
                )
            )
        },
        openDialogCommissionDekraExpert: (
            damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER,
                false,
                { damageId },
                'Commission expert'
            )
        ),
        openDialogCommissionOtherExpert: (
            damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMMISSION_OTHER_EXPERT,
                false,
                { damageId },
                'Commission expert'
            )
        ),
        onCreatePowerOfAttorney: (payload) => {
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CREATE_POWER_OF_ATTORNEY,
                    false,
                    payload,
                    'Create power of attorney'
                )
            )
        },
        onOpenSmartConnectDialog: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_SMART_CONNECT_EMAIL,
                true,
                payload,
                'Create smart connect email'
            )
        )
    }
}

export const TitleBarDamageManager = connect(
    mapStateToProps,
    mapDispatchToProps
)(TitleBarDamageManagerComponent)