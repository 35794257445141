import { MoneyAddition } from './currency_helpers'
import {isCancelled} from 'application/common/payment'

export const getPaymentSum = (payments, property = 'amount') => {
    var total = 0
    for (var i in payments) {
        total = MoneyAddition(total, payments[i][property])
    }
    return Number(total)
}

export const getTotalInvoiceamountsSum = (invoices, property = 'grossTotal') => {
    var total = 0
    for (var i in invoices) {
        total = MoneyAddition(
            total, 
            invoices[i][property]
        )
    }
    return Number(total)
}

export const getTotalInvoicePaymentsSum = (invoices, property = 'amount') => {
    var total = 0
    for (const i of invoices) {
        total = MoneyAddition(
            total, 
            getPaymentSum(
                i.payments.filter(
                    payment => !isCancelled(payment)
                )
            )
        )
    }
    return Number(total)
}

export const getVacantPositions = invoices => {
    return {
        unpaidTotal: getTotalInvoiceamountsSum(invoices) - getTotalInvoicePaymentsSum(invoices),
        paidTotal: getTotalInvoicePaymentsSum(invoices)
    }
}

export const groupInvoicesByType = invoices => {
    const groupNames = [...new Set(invoices.map(i => i.type))]
    return groupNames.map(groupName=>({
        groupName,
        invoices: invoices.filter(i => i.type === groupName)
    }))
}