import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { LIABILITY_INQUIRY_INFO } from 'application/constants'
import {  LiabilityInquiryInfoName } from 'application/components/name_mappings/liability_inquiry_info'

export const LiabilityInquiryInfoSelect = ({
    name = 'info',
    register,
    setValue,
    initialValue,
    error,
    validate = null,
    menuPlacement = 'bottom',
    onChange
}) => {
    const { t } = useTranslation()

    const liabilityInquiryInfoOptions = [
        {
            value: LIABILITY_INQUIRY_INFO.MISSING_WRITTEN_REPORT_OF_OPPONENT,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.MISSING_WRITTEN_REPORT_OF_OPPONENT} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.PENDING_VERIFICATION_BY_OPPOSING_INSURANCE,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.PENDING_VERIFICATION_BY_OPPOSING_INSURANCE} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.INVESTIGATION_FILE_REQUESTED,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.INVESTIGATION_FILE_REQUESTED} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.LIABILITY_DENIED,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.LIABILITY_DENIED} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.LIABILITY_CONFIRMED,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.LIABILITY_CONFIRMED} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.LIABILITY_RATIO,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.LIABILITY_RATIO} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.GREEN_CARD,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.GREEN_CARD} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.MISSING_ACCIDENT_REPORT,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.MISSING_ACCIDENT_REPORT} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.INQUIRIES_DENIED,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.INQUIRIES_DENIED} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.FOREIGN_INVOLVEMENT,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.FOREIGN_INVOLVEMENT} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.GERMAN_INSURANCE,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.GERMAN_INSURANCE} />
        },
        {
            value: LIABILITY_INQUIRY_INFO.FOREIGN_INSURANCE,
            label: <LiabilityInquiryInfoName liabilityInquiryInfo={LIABILITY_INQUIRY_INFO.FOREIGN_INSURANCE} />
        },

      
    ]

    const initialOption = liabilityInquiryInfoOptions.find(o => o.value === initialValue)

    return <Select
        error={error}
        label={t('Liability request type')}
        name={name}
        register={register}
        setValue={setValue}
        options={liabilityInquiryInfoOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

LiabilityInquiryInfoSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}