import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { fileHelpers } from 'application/common'
import { shouldLoadAttachment } from './helpers'
import {AttachmentViewerComponent} from './component'





export const AttachmentViewerHoc = ({ 
    attachments,
    attachmentId,
    attachmentName,
    onLoadAttachment, 
    onAttachmentDownload = () => {console.log('download')},
    damageId,
    taskId = null,
}) => {
    //check if thumbnail is registered in redux store
    const attachmentRegistered = attachments.fullAttachments.filter(e => e.id === attachmentId).length > 0

    //check if thumbnail should be loaded depending on the file extension
    const shouldLoad = shouldLoadAttachment(fileHelpers.getFileExtension(attachmentName))

    //thumbnail data url
    const attachment = attachmentRegistered ? attachments.fullAttachments.filter(t => t.id === attachmentId)[0] : ''

    //preloader display depends on this value
    const attachmentIsLoading = shouldLoad 
    && (
        !attachmentRegistered || (attachmentRegistered && !attachments.fullAttachments.filter(
            e => e.id === attachmentId)[0].isLoaded
        )
    )
        
    //request thumbnail if not registered
    useEffect(
        () => { 
            !attachmentRegistered 
            && shouldLoad 
            && onLoadAttachment(
                attachmentId, 
                attachmentName, 
                damageId, 
                taskId
            ) 
        }, 
        [attachmentId, attachmentName, attachmentRegistered, onLoadAttachment, damageId, shouldLoad, taskId])

    return <AttachmentViewerComponent
        attachmentId={attachmentId}
        attachmentName={attachmentName}
        attachment={attachment}
        onAttachmentDownload={onAttachmentDownload}
        extension={fileHelpers.getFileExtension(attachmentName)}
        isLoading={attachmentIsLoading}
        damageId={damageId}
    /> 
}

AttachmentViewerHoc.propTypes = {
    attachmentId: PropTypes.string.isRequired,
    attachments: PropTypes.object.isRequired,
    attachmentName: PropTypes.string.isRequired,
    onLoadAttachment: PropTypes.func.isRequired,
    onAttachmentDownload: PropTypes.func.isRequired
}