export const MODULE_NAME = {
    ZYRULL_QR_CODE_STICKER: 'ZyrullQrCodeModule', //scope: 'branch' -> ToDo: Filter branch select in QR code dialog / hide button
    BATCH_CUSTOMER_IMPORT: 'CustomerBatchImportModule', //scope: 'account'
    DEKRA_COMMISSION: 'DekraModule', //scope: 'branch' -> ToDo: show notification for new branches
    SMS_MESSAGE_CHANNEL: 'SmsModule', //scope: 'account'
    CLIPBOARD_SIGNATURE: 'ClipboardSignatureModule'
}

export const MODULE_SCOPE = {
    ACCOUNT: 'Account',
    BRANCH: 'Branch'
}