import { requestStatusList } from 'application/redux/actions/common'

export const mapStateToProps = (state) => {

    const {
        statusList
    } = state.common
    
    return {statusList}
}


export const mapDispatchToProps = dispatch => {
    return {
        onLoadStatusList: () => dispatch(
            requestStatusList()
        )
    }
}

