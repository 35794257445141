import { openDialogByName } from 'application/redux/actions/dialogs'
import { showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { showAttachmentDetailsWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { connect } from 'react-redux'
import { DIALOG } from 'application/constants'
import { TaskUploaderWidgedComponent } from './component'



const mapStateToProps = state => ({
    selectedTaskDocuments: state.sharedResource.id === null
        ? state.pages.damageManager.tasks.selectedTaskDocuments
        : state.pages.workflowPanel.tasks.selectedTaskDocuments,
    isExternal: state.sharedResource.id !== null
})



const mapDispatchToProps = dispatch => { return {
    onFilesAdded: (
        files, 
        taskId, 
        damageId,
        widgetContexts, 
        isExternal
    ) => {
        const payload =                     {
            files,
            taskId,
            damageId, 
            widgetContexts, 
            useDefaultCategory: true
        }

        if(isExternal){
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CATEGORY_UPLOAD_WORKFLOW_PANEL, 
                    true, 
                    payload, 
                    'Assign category'
                )
            )
        }else{
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CATEGORY_SELECT_FROM_TASK_WIDGET,
                    true, 
                    {
                        ...payload,
                        useDefaultCategory: false
                    }, 
                    'Assign category'
                )
            )
        }
    },
    onShowAttachmentDetails: (
        attachment, 
        damageid, 
        taskId, 
        isExternal, 
        sharedResourceId
    ) => {
        if(isExternal){
            dispatch(
                showAttachmentDetailsWorkflowPanel(
                    attachment, 
                    damageid, 
                    sharedResourceId, 
                    taskId
                )
            )
        }else{
            dispatch(
                showAttachmentDetails(
                    attachment, 
                    damageid, 
                    taskId
                )
            )
        }
    }
}}


export const TaskUploaderWidged =  connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskUploaderWidgedComponent)