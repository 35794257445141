import React, { useEffect, useState } from 'react'
import ReactFlow, { ReactFlowProvider, useStoreActions } from 'react-flow-renderer'
import { withTheme } from 'styled-components'

import { BRANCH_MANAGEMENT } from 'application/constants'
import { buildElements } from './logic'
import { useMaxExtend, useNodeConnection, useElementRemove } from './hooks'
import { TopControls, RemovableEdge, CustomNode } from './components'

const BranchManagementFlowChart = withTheme(
    ({
        roles,
        groups,
        branches,
        onNodeSelect = () => {},
        onNodeConnect = () => {},
        onEdgeRemove = () => {},
        onNodeRemove = () => {},
        onNodeClick = () => {},
        branchesDisabled = false,
        currentBranch,
        onLoadInstance = () => {},
        theme
    }) => {
    
        const [elements, setElements] = useState(
            buildElements(
                roles, 
                groups, 
                branches, 
                theme, 
                branchesDisabled
            )
        )

        const { unsetUserSelection } = useStoreActions(actions => actions)
        const { maxExtend } = useMaxExtend()

        const { 
            selectionChange, 
            connectNode, 
            setSelected 
        } = useNodeConnection(
            elements, 
            setElements, 
            onNodeSelect, 
            onNodeConnect
        )

        const { 
            removeEdge, 
            removeNode, 
            removeButtonHidden 
        } = useElementRemove(
            onEdgeRemove, 
            onNodeRemove, 
            currentBranch
        )

        const edgeTypes = { 
            [BRANCH_MANAGEMENT.ELEMENT_TYPE.EDGE_REMOVABLE]:
         props => <RemovableEdge 
             {...props} 
             onButtonClick={removeEdge} 
             branches={branches} 
             groups={groups} 
             roles={roles} 
         /> 
        }

        const nodeTypes = { 
            [BRANCH_MANAGEMENT.ELEMENT_TYPE.NODE_CUSTOM]: 
        props => <CustomNode 
            {...props} 
            onRemoveButtonClick={removeNode} 
            onNodeClick={onNodeClick} 
            removeButtonHidden={removeButtonHidden} 
        /> 
        }

        useEffect(
            ()=>{
                setSelected(null)
                unsetUserSelection()
                setElements(
                    buildElements(
                        roles, 
                        groups, 
                        branches, 
                        theme, 
                        branchesDisabled
                    )
                )
            },
            [
                roles, 
                groups, 
                branches, 
                theme, 
                unsetUserSelection, 
                setSelected, 
                branchesDisabled
            ]
        )

        const onLoad = instance => {
            onLoadInstance()
            instance.fitView( {includeHiddenNodes: true} )
        }

        return <ReactFlow 
            elements={elements}
            onSelectionChange={selectionChange}
            onConnect={connectNode}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            translateExtent={maxExtend}
            onLoad={onLoad}
            {...BRANCH_MANAGEMENT.FLOW_PROPS}
        >
            <TopControls showInteractive={false} />
            {/* <Background variant="dots" /> */}
        </ReactFlow>
    }
)

export const BranchManagementFlowchartComponent =  props => <ReactFlowProvider>
    <BranchManagementFlowChart {...props} />
</ReactFlowProvider>