export const DAMAGE_TYPE = {
    UNKNOWN: 'Unknown',
    LIABILITY: 'Liability',
    COMPREHENSIVE: 'Comprehensive',
    ALL: 'All'
}

export const REPAIR_AUTHORIZATION_TYPE = {
    NOT_SET: 'NotSet',
    GRANTED: 'Granted',
    REFUSED: 'Refused'
}