import React from 'react'
// import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_CONTEXTS } from 'application/constants'
import * as Common from 'application/components/pages/_common'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import { BreakLine32 } from 'application/components/pages/external/welcome/fragments'



const WorkflowPanelInbox = ({
    match
}) => {
    return <React.Fragment>
        <BreakLine32 />
        <Common.VerticalContainer>
            <WidgetArea 
                widgetContext={WIDGET_CONTEXTS.WORKFLOW_PANEL_INBOX} 
                direction='row' 
            />
        </Common.VerticalContainer>
    </React.Fragment>
}

export const WorkflowPanelInboxComponent = withRouter(WorkflowPanelInbox)