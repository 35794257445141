import { useMemo } from 'react'

export const useComposedFormSubmit = (onSubmitSuccess, ...forms) => {

    const handleComposedSubmit =  useMemo(() => {
        const fns = [...forms.map(f => f.handleSubmit)]
        return fns.reduce((p, c) => {
            return c(p)
        }, onSubmitSuccess) 
    }, [onSubmitSuccess, forms]) 

    return { 
        handleComposedSubmit
    }
}