import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import * as Common from 'application/components/pages/_common'

export const TaskDetails = ({task}) => {
    const {t} = useTranslation()
    const totalAttachments = task.attachments?.total || 0
    const totalComments = task.comments.total || 0
    return <FlexBox flexDirection='column'>
        {totalAttachments} {t('Documents')}
        <Common.PushBottom8 />
        {totalComments} {t('Comments')}
    </FlexBox>
}