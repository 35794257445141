import { takeLeading, put } from 'redux-saga/effects'

import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateAllDocuments } from 'application/redux/actions/pages/damage_manager/documents'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* damageManagerDocumentsSaga(action) {
    try {      
        const { documents } = yield resourceCall(
            api.gateway.attachments.getAttachmentsByDamageId, 
            {
                damageId: action.damageId
            }
        )
        yield put(
            populateAllDocuments(documents, action.damageId)
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* damageManagerDocumentsWatcher() {
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST, damageManagerDocumentsSaga)
}
