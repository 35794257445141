import { useEffect } from 'react'

export const useStorageVersion = () => {
    const storageVersionKey = 'esy360storageVersion'
    const currentStorageVersion = parseInt(process.env.REACT_APP_APPLICATION_STORAGE_VERSION)
    
    const storedStorageItem =  localStorage.getItem(storageVersionKey)
    const storedStorageVersion =  storedStorageItem === null ? 0 : parseInt(storedStorageItem)

    useEffect(() => {
        if(currentStorageVersion > storedStorageVersion){
            localStorage.clear()
            localStorage.setItem(storageVersionKey, currentStorageVersion)
        }
    }, [currentStorageVersion, storedStorageVersion])
}