import React from 'react'
import {LayoutMain}  from './layout_main'
import {PageBranchHeadline} from 'application/components/building_blocks/page_branch_headline'
import { withActiveBranch } from 'application/components/context/branches'
import * as Common from 'application/components/pages/_common'
import { PageTitle } from 'application/components/controls/page_title'

const Content = withActiveBranch(
    (
        {children}
    ) => <React.Fragment>{children}</React.Fragment>
)

export const LayoutCurrentBranch = withActiveBranch((
    {
        headline,
        children,
        headlineChildren=null,
        titleSegments=[],
        currentBranchLoading,
        currentBranch
    }
) => {
    const titleSegmentsWithBranch = currentBranchLoading
        ? titleSegments
        : [...titleSegments, currentBranch.name]

    return <LayoutMain>
        <PageTitle segments={titleSegmentsWithBranch} />
        <Common.Container>
            <Common.Main 
                paddingLeft='0' 
                paddingRight='0'
            >
                <PageBranchHeadline
                    headline={headline}
                >
                    {
                        headlineChildren
                    }
                </PageBranchHeadline>
                <Content>{children}</Content>
            </Common.Main>
        </Common.Container>
    </LayoutMain>
})