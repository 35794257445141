import { takeLatest, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { showToast } from 'application/redux/actions/notifications'
import { damagesOverviewPopulateDamageState } from 'application/redux/actions/pages/damages_overview/all_damages'

function* updateStateSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateDamageState, 
            {
                damageId: action.damageId
            }, 
            {
                id: action.state.id
            }
        )
        yield put(
            damagesOverviewPopulateDamageState(
                action.damageId,
                action.state, //get statesList
                action.showDetails
            )
        )
        yield put(
            showToast('State updaded')
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* updateDamageStateWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGES_OVERVIEW_UPDATE_STATUS, 
        updateStateSaga
    )
}



