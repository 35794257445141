import styled from 'styled-components'

export const StyledFileButton = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.isActive ? props.theme.color.black : props.theme.color.anthracite};
    cursor: ${props => props.isClickable ? 'pointer': 'default'};
    margin-top: 0;
    margin-bottom: 6px;
    background: ${props => props.isActive ? props.theme.color.gray10 : props.theme.color.gray5};
    padding: 6px 8px;
    font-weight: ${props => props.isActive ? props.theme.fontWeight.standardBold : props.theme.fontWeight.standardRegular};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 240px;
`

export const StyledExtension = styled.div`
    background: ${props => props.theme.color.anthracite50};
    color: ${props => props.theme.color.white};
    font-size: ${props => props.theme.fontSize.xSmall};
    min-width: 20px;
    padding: 2px 6px;
    height: 14px;
    margin-left: 10px;
`
