import { CostCoveragePdf } from './component'
import { pdf } from '@react-pdf/renderer'
import { costCoverageValuesMapping } from './cost_coverage_values_mapping'

export const costCoveragePdfBuilder = (            
    pdfValues
) => {

    const {
        damage,
        accessibleBranches,
        customer,
        victimInsuranceData,
        opponentInsuranceData,
        expertFound,
        victimVehicle,
        accidentReportDescription = [],
        providedServices = [],
        accidentReportDescriptionOther = '',
        signatures,
        providedServicesOther
    } = pdfValues

    const mappedValues = costCoverageValuesMapping(
        damage,
        accessibleBranches,
        customer,
        victimInsuranceData,
        opponentInsuranceData,
        expertFound,
        victimVehicle,
        accidentReportDescription,
        providedServices,
        accidentReportDescriptionOther,
        signatures,
        providedServicesOther
    )

    return pdf(
        <CostCoveragePdf  values = {mappedValues} />
    )
}