import React, { useEffect, useState } from 'react'
import { CheckboxWithLabel, ValidatedHiddenField } from 'application/components/controls/form'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { validationMethods } from 'application/common'
import styled from 'styled-components'
import { isDefined } from 'application/common/data_helpers'

const Validation = styled.div`
    margin-top: -10px;
    margin-bottom: 10px;
`



const CheckBoxGroup = (
    {
        options,
        register,
        errors,
        name ='checkboxGroup',
        validationCondition = { selectAtLeastOne: validationMethods.selectAtLeastOne },
        errorMessageOverride ='Please select at least one',
        onChange = () => {},
        setValue,
        trigger
    }
) => {
    const hiddenCheckboxName = `${name}SelectedCount`

    const initialCheckedCheckBoxesCount = options.reduce(
        (accumulator, option) => {        

            if ( !isDefined(option?.isInitialChecked) ) return accumulator

            return option.isInitialChecked 
                ? accumulator = accumulator  + 1 
                : accumulator
        },
        0
    )

    const [selectedElementsCount, setSelectedElementsCount] = useState(initialCheckedCheckBoxesCount)

    useEffect(
        () => {
            setValue(
                hiddenCheckboxName,
                selectedElementsCount
            )
            trigger(hiddenCheckboxName)
        },
        [setValue, hiddenCheckboxName, selectedElementsCount, trigger]
    )

    const handleCheckboxChange = (e, isChecked) => {
        const selectedElementsCountValue = isChecked 
            ? selectedElementsCount + 1 
            : selectedElementsCount - 1
        setSelectedElementsCount(selectedElementsCountValue)
        onChange(e, isChecked)
        trigger(hiddenCheckboxName)
    }

    return <React.Fragment>
        {
            options.map(
                (option) => {

                    const isInitialChecked = isDefined(option.isInitialChecked)
                        ? option.isInitialChecked
                        : false

                    return <FlexBox
                        key={option.value} 
                        paddingBottom='8px'
                    >
                        <CheckboxWithLabel
                            register={register}
                            name={name}
                            label={option.label}
                            isRequired={false}
                            isSelected={isInitialChecked}
                            value={option.value}
                            onCheckboxChange={handleCheckboxChange}
                            activeLabel={option.activeLabel}
                        />
                    </FlexBox>
                }
            )
        }
        <Validation>
            <ValidatedHiddenField 
                register={register}
                name={hiddenCheckboxName}
                validate={validationCondition}
                error={errors[hiddenCheckboxName]}
                errorMessageOverride={errorMessageOverride}
            />
        </Validation>
    </React.Fragment>
}

CheckBoxGroup.propTypes = {
    options: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    name: PropTypes.string, 
    validationCondition: PropTypes.object, 
    errorMessageOverride: PropTypes.string,
    onChange: PropTypes.func,
    setValue: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired
}


export { CheckBoxGroup } 