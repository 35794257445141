import React from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import {Tasks} from './tasks'
import {DamageManagerOverview as Overview} from './overview'
import {DamageManagerDocuments as Documents} from './documents'
import {DamageManagerInbox as  Inbox} from './inbox'
import {DamageManagerInvoices as Invoices} from './invoices'
import {LawyerCommunication} from './lawyer_communication'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { useParams, useLocation, matchPath } from 'react-router-dom'
import { HookRoute } from 'application/components/building_blocks/hook_route'

export const DamageManagerRoutes = () => {
    const {
        damageId
    } = useParams()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const {pathname} = useLocation()

    if ( matchPath({ path: ROUTES.DAMAGE_MANAGER }, pathname)) {
        return <Navigate to= {
            internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/overview'
            )
        } />
    }

    return <React.Fragment>
        <HookRoute path={ROUTES.DAMAGE_MANAGER_OVERVIEW} >
            <Overview />
        </HookRoute>
        <HookRoute 
            path={ROUTES.DAMAGE_MANAGER_TASKS} 
            all={true}
        >
            <Tasks />
        </HookRoute>
        <HookRoute path={ROUTES.DAMAGE_MANAGER_DOCUMENTS} >
            <Documents />
        </HookRoute>
        <HookRoute 
            path={ROUTES.DAMAGE_MANAGER_INBOX} 
            all={true}
        >
            <Inbox />
        </HookRoute>
        <HookRoute path={ROUTES.DAMAGE_MANAGER_LAWYER_COMMUNICATION} >
            <LawyerCommunication />
        </HookRoute>
        <HookRoute path={ROUTES.DAMAGE_MANAGER_INVOICES} >
            <Invoices />
        </HookRoute>
    </React.Fragment>  
}