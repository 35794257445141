import React from 'react'
import { IconButton as IconButtonBase } from '@ec/ui-controls-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledOverridedIconButton = styled.div`
    span {
        :hover {
            background: ${props => props.hoverBackground ? props.hoverBackground : props.theme.color.black10};
            color: ${props => props.theme.color.black};
        }

        :active {
            background: ${props => props.activeBackground ? props.activeBackground : props.theme.color.black20};
            color: ${props => props.theme.color.black};
        }
    }   
`
export const IconButton  = ({ 
    hoverBackground = '',
    activeBackground = '',
    onButtonClick = () => {}, 
    iconKey, 
    width, 
    height, 
    iconSize, 
    color = '', 
    buttonClassName = '',
}) => {
    return <StyledOverridedIconButton 
        hoverBackground={hoverBackground} 
        activeBackground={activeBackground}
        className={buttonClassName}
    >
        <IconButtonBase 
            onButtonClick={onButtonClick} 
            iconKey={iconKey} 
            width={width} height={height} 
            iconSize={iconSize} 
            color={color}  
        />
    </StyledOverridedIconButton>
}

IconButton.propTypes = {
    hoverBackground: PropTypes.string,
    activeBackground: PropTypes.string,
    onButtonClick: PropTypes.func,
    iconKey: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    iconSize: PropTypes.string,
    color: PropTypes.string,
    buttonClassName: PropTypes.string
}