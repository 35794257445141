import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WIDGET_CONTEXTS } from 'application/constants'
import { SubPageLayout } from 'application/components/pages/_common'
import {ActionButtonBar} from 'application/components/controls/action_button_bar'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import {WidgetBoard} from 'application/components/building_blocks/widget_board'
import {DamageManagerContainer} from '../container'
import {DamageManagerOverviewSidebar} from './sidebar'


export const DamageManagerOverviewComponent = ({
    onSetStaticMode,
    onResetWidgetBoard,
    isStatic
}) => {
    const { t } = useTranslation()
    
    return <DamageManagerContainer
        titleSegments={['Overview']}
        headline='Overview'
        sidebar={ <DamageManagerOverviewSidebar /> }
    >
        <SubPageLayout paddingLeft='16px' paddingRight='16px'>
            {
                !isStatic && <ActionButtonBar inline={true} buttonsAlign='flex-end'>
                    <IconButtonWithLabel
                        onButtonClick={()=>{onResetWidgetBoard(WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW)}}
                        iconKey='settings_backup_restore'
                        label={t('Reset layout')}
                    />

                    <IconButtonWithLabel
                        onButtonClick={()=> onSetStaticMode(WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW, !isStatic)}
                        iconKey={isStatic ? 'edit' : 'check'}
                        label={isStatic ? t('Edit widgets') : t('Done')}
                    />
                </ActionButtonBar>
            }
            <WidgetBoard widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW} />
        </SubPageLayout>
    </DamageManagerContainer>
}

DamageManagerOverviewComponent.propTypes = {
    onSetStaticMode: PropTypes.func.isRequired,
    onResetWidgetBoard: PropTypes.func.isRequired,
    isStatic: PropTypes.bool.isRequired,
}