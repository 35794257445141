import { FlexBox } from 'application/components/fragments/flex_box'
import React from 'react'
import * as Fragments from '../../fragments'
import { ContactButton } from '../contact_button'

export const MainInfoWelcome = () => {
    return <Fragments.InfoMainWelcomeWrapper>
        <FlexBox flexBasis='40%'>
            <Fragments.ImageTopMain src={'images/landing_page/main_v2.jpg'} />
        </FlexBox>
        <FlexBox flexBasis='60%'>
            <Fragments.TextWrapper>
                <FlexBox flexDirection='column'>
                    <Fragments.SuperTitle>e.sy 360</Fragments.SuperTitle>
                    <Fragments.Claim>
                        BACK TO THE DRIVER SEAT
                    </Fragments.Claim>
                    <Fragments.Description>
                        Digitale Schadenabwicklung  aus der Sicht des Autohauses 
                        – starten Sie in eine neue Dimension der Schadenregulierung!
                    </Fragments.Description>
                    <Fragments.Description>
                        e.sy360 ist mehr als ein Tool: Ein geführter Abwicklungsprozess für die Regulierung von Kfz-Schäden. 
                        Alle beteiligten Akteure sind über eine einzige Online-Plattform verbunden. 
                        Hohe Abwicklungstransparenz. Schnelle Durchlaufzeiten. Unabhängigkeit von beteiligten Parteien.
                    </Fragments.Description>
                    <Fragments.ButtonContainer>
                        <ContactButton />
                    </Fragments.ButtonContainer>
                </FlexBox>
            </Fragments.TextWrapper>
        </FlexBox>
    </Fragments.InfoMainWelcomeWrapper>
}