import React from 'react'
import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import {Toaster} from 'application/components/building_blocks/toaster'
import {WaitingScreen} from 'application/components/building_blocks/waiting_screen'
import { TitleBarSimple } from 'application/components/pages/internal/_title_bars'
import {Dialogs} from 'application/components/building_blocks/dialog/xdialogs'
import {ErrorDisplay} from 'application/components/building_blocks/error_display'
import { PageTitle } from 'application/components/controls/page_title'




const LayoutNoAccount = ({ 
    waitingScreen,
    children,
    dialogsOpen,
    cssPaddingLeft = '16px',
    titleSegments = []
}) => {
    
    return <React.Fragment>
        <PageTitle segments={titleSegments} />
        <GlobalStyle />
        <Fragments.Wrapper blurred={waitingScreen.active && !dialogsOpen && !waitingScreen.inDialog}>

            <Fragments.Header>
                <TitleBarSimple />
            </Fragments.Header>
            <Fragments.Main>
   
                <Fragments.Content 
                    cssPaddingLeft={cssPaddingLeft}
                >
                    {children}
                </Fragments.Content>
                <Dialogs />
            </Fragments.Main>
        </Fragments.Wrapper>
        <ErrorDisplay />
        <Toaster />
        <WaitingScreen />
    </React.Fragment>
}

export const NoAccountLayoutComponent = LayoutNoAccount