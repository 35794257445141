import { 
    ROUTES,
    USER_PERMISSONS
} from 'application/constants'
import React from 'react'
import {IconLink} from 'application/components/building_blocks/main_navigation_sidebar/_common/icon_link'
import PropTypes from 'prop-types'
import { buildToolTip } from '../helper_functions'
import { useTranslation } from 'react-i18next'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import { addWildCard } from 'application/common/route_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

export const AdministrationAccordionClosed = (
    {
        primaryColor,
        primaryHoverColor,
        menuName=''
    }
) => {
    const {t} = useTranslation()

    const users = notNull(
        useMatch(ROUTES.ADMINISTRATION_USERS )
    )
    const customers = notNull(
        useMatch(
            {path: addWildCard(ROUTES.CUSTOMERS)}
        )
    )
    const branches = notNull(
        useMatch(ROUTES.BRANCH_MANAGEMENT)
    )
    const settings = notNull(
        useMatch(
            { path: addWildCard(ROUTES.ADMINISTRATION_SETTINGS) }
        )
    )
    const changelog = notNull(
        useMatch(ROUTES.CHANGELOG)
    )

    const {
        internalAccountLink
    } = useApplicationLinks()

    return <React.Fragment>
        <ForAnyBranchPermissions 
            permissions={[USER_PERMISSONS.USER.READ]} 
            allNeeded={false}
        >
            <IconLink 
                toolTip={ buildToolTip(menuName, 'User Administration', t) }
                to={ internalAccountLink(ROUTES.ADMINISTRATION_USERS) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'people'}
                isActive={users}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions 
            permissions={[USER_PERMISSONS.CUSTOMER.READ]} 
            allNeeded={false}
        >
            <IconLink 
                toolTip={ buildToolTip(menuName, 'Customers', t) }
                to={ internalAccountLink(ROUTES.CUSTOMERS) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'face'}
                isActive={customers}
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions 
            permissions={[
                USER_PERMISSONS.GROUP.READ,
                USER_PERMISSONS.ROLE.READ
            ]} 
            allNeeded={true}
        >
            <IconLink 
                toolTip={ buildToolTip(menuName, 'Branch management', t) }
                to={ internalAccountLink(ROUTES.BRANCH_MANAGEMENT) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'store'}
                isActive={branches}
            />
        </ForAnyBranchPermissions>
        <IconLink 
            toolTip={ buildToolTip(menuName, 'Settings', t) }
            to={ internalAccountLink(ROUTES.ADMINISTRATION_SETTINGS) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            iconKey={'tune'}
            isActive={settings}
        />
        <IconLink 
            toolTip={ buildToolTip(menuName, 'Whats`s new?', t) }
            to={ internalAccountLink(ROUTES.CHANGELOG) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            iconKey={'cached'}
            isActive={changelog}
        />
    </React.Fragment>
}




AdministrationAccordionClosed.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    menuName: PropTypes.string
}


