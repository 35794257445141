import { connect } from 'react-redux'
import { createComment, loadMoreComments } from 'application/redux/actions/pages/damage_manager/comments'
import { TaskCommentsComponent } from './component'




const mapStateToProps = state => ({
    user: state.auth.user,
    taskLoaded: state.pages.damageManager.tasks.selectedTaskLoaded
})

const mapDispatchToProps = dispatch => {
    return {
        onSaveComment: (
            damageId, 
            taskId, 
            comment
        ) => dispatch(
            createComment(
                damageId, 
                taskId, 
                comment
            )
        ),
        onLoadMore: (
            widgetKey,
            damageId,
            taskId,
            currentComments,
            offSet
        ) => dispatch(
            loadMoreComments(
                widgetKey,
                damageId,
                taskId,
                currentComments,
                offSet
            )
        )
    }
}

export const TaskComments = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskCommentsComponent)