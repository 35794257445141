
import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DescriptionField } from '../../common/setting_description'
import { PushBottom16 } from 'application/components/pages/_common'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { PureSelect } from 'application/components/controls/form'


const flattenCategories = (categories) => categories.reduce((cats, cat) => {
    cat.categories.forEach(subcat => {
        subcat.structure = cat.structure !== undefined ? `${cat.structure} → ${cat.name}` : cat.name
    })
    return cat.categories.length > 0 
        ? cats.concat(flattenCategories(cat.categories)) 
        : cats.concat(cat)
}, [])


export const SelectCategoryComponent = ({
    definition,
    updateSettings, 
    getSetting,
    categories,
    categoriesLoaded
}) => {
    const setting = getSetting(definition.key)


    const handleChange = (option) => {
        updateSettings(definition.key, option.value)
    }

    const flattenedCategoriesOptions = flattenCategories(categories).map(cat => ({
        value: cat.id,
        label: cat.structure !== undefined ? `${cat.structure} → ${cat.name}` : cat.name,
        category: cat
    }))

    const options = flattenedCategoriesOptions
    const initialOption = setting === null 
        ? options[0] 
        : options.find(o => o.value === setting)

    return <FlexBox flexDirection='column'>

        <DescriptionField
            description={definition.description}
        />
        <PushBottom16 />

        {
            !categoriesLoaded 
                ? <ThemedPreloader />
                :  <PureSelect
                    options={options}
                    value={initialOption}
                    onChange={handleChange}
                    isSearchable={true}
                    isClearable={false}
                /> 
        }
    </FlexBox>
}