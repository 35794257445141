import styled from 'styled-components'

export const InvoiceInformation = styled.span`
    color: ${props => props.theme.color.anthracite50};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.medium18};
    margin-bottom: 4px;
    display: flex;
    &:last-child{
        margin-bottom: 0;
    }
`

export const InvoiceInformationLabel = styled.span`
    display: flex;
    min-width: 132px;
    margin-right: 8px; 
`