import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_KASKO:'WIDGET_BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO',
    CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_HAFTPFLICHT: 'WIDGET_BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT',
    CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_GESAMT: 'WIDGET_BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT',
    CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_DAMAGES_TABLE: 'WIDGET_BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE'
}

export const layoutBranchSpecificControllingVacantPositions= {
    LARGE: [
        {i: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_GESAMT, x: 0, y: 0, w: 2, h: 4},
        {i: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_HAFTPFLICHT, x: 2, y: 0, w: 2, h: 4},
        {i: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_KASKO, x: 4, y: 0, w: 2, h: 4},
        {i: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_DAMAGES_TABLE, x: 0, y: 4, w: 6, h: 6},
    ]
}

export const widgetsBranchSpecificControllingVacantPositions = [
    {
        key: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_GESAMT,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT,
        title: 'Total',
        isSecondary: true,
        accountDependent: true
    },
    {
        key: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_HAFTPFLICHT,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT,
        title: 'Liability',
        hideBorder: true,
        accountDependent: true
    },
    {
        key: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_KASKO,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO,
        title: 'Kasko',
        isSecondary: true,
        accountDependent: true
    },
    {
        key: KEYS.CONTROLLING_BRANCH_SPECIFIC_VACANT_POSTIONS_DAMAGES_TABLE,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE,
        title: 'Vacant positions damages table',
        isBlank: true,
        hideHeadline: true,
        accountDependent: true
    }
]
