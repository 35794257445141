import React from 'react'
import Sticky from 'react-sticky-el'
import {HighlightBar} from 'application/components/building_blocks/highlight_bar'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import { Headline3 } from 'application/components/fragments/typography'
import * as Fragments from './fragments'
import {ContextMenu} from 'application/components/controls/context_menu'
import {ContextMenuItem} from 'application/components/controls/context_menu_item'
import {TaskOperationsWFP} from 'application/components/building_blocks/task_operations_workflow_panel'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'

export const TaskDetailsWorkflowPanelComponent =  ({
    selectedTaskLoaded,
    selectedTaskLoading,
    task,
    sharedResourceId,
    onDeletetaskWorflowPanel,
    match,
    onEditTaskWorkflowPanel,
    damage
}) => {
    const {t} = useTranslation()
    if(selectedTaskLoading){
        return <ThemedPreloader />
    }
    if(!selectedTaskLoaded || task === null){ //show 'please select task' message here?
        return <React.Fragment />
    }
    if (task.taskWasDeleted && match.params.taskId === task.id) {
        return <Navigate 
            to={ROUTES.EXT_WORKFLOW_PANEL_BASE + match.params.sharedResourceId + '/tasks'} 
        />
    }
    return <React.Fragment>
        <Sticky scrollElement=".taskScrollArea">
            <React.Fragment>
                <Fragments.Row justifyContent='space-between'>
                    <Headline3 zindex={1}>
                        {task.title}
                    </Headline3>
                    <ContextMenu iconKey='more_horiz'>
                        <ContextMenuItem
                            isLinked={false}
                            iconKey='edit'
                            onListElementClick={() => { onEditTaskWorkflowPanel(task, sharedResourceId, damage) }}
                        >{t('Edit task')}</ContextMenuItem>
                        <ContextMenuItem
                            isLinked={false}
                            iconKey='delete_outline'
                            onListElementClick={() => { onDeletetaskWorflowPanel(task) }}
                        >{t('Delete task')}</ContextMenuItem>
                    </ContextMenu>
                </Fragments.Row>
                <Fragments.Row>
                    <HighlightBar
                        state={task.state}
                        priority={task.priority}
                        deadline={null}
                        isMarginRightNeeded={false}
                        assignee={task.assignee}
                        canAssign={false}
                    />
                </Fragments.Row>
            </React.Fragment>
        </Sticky>
        
        <Fragments.Row>
            <Fragments.Description>{task.description}</Fragments.Description>
        </Fragments.Row>
        <Fragments.Row>
            <WidgetArea widgetContext={task.id} successively={true}  />
        </Fragments.Row>
        <Fragments.Filler />
        <Fragments.Footer>
            <TaskOperationsWFP
                task={task}
                match={match}
            />
        </Fragments.Footer>
    </React.Fragment>

}