import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { showToast } from 'application/redux/actions/notifications'

export function* dialogCreateSmartConnectEmailSaga(action) {
    yield put(showWaitingScreen('Sending SmartConnect email'))
    yield resourceCall(
        api.gateway.damage.postCreateSmartConnectEmail,
        {
            damageId: action.metaData.damageId
        },
        action.payload
    )

    yield put(showToast('SmartConnect email sent'))
    yield put(hideWaitingScreen())
}