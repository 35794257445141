const { Text } = require('@react-pdf/renderer')
const { exists } = require('application/common/data_helpers')
const { DateComponent } = require('application/components/controls/date')


export const PDFDate = ({date}) => {
    return exists(date)
        ? <Text>
            <DateComponent date={date} />
        </Text>
        : <Text/>
}