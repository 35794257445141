import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'


export const UserStatus = withTheme(({user, theme})=>{
    const { t } = useTranslation()
    return <React.Fragment>
        {
            user.isRegistered 
                ? <FontIcon fontSize={theme.fontSize.standard} icon='done' title={t('Registered')} />  
                : <FontIcon fontSize={theme.fontSize.standard} icon='hourglass_empty' title={t('Invited')} /> 
        }

        &nbsp;
        
        {
            user.isLocked && <FontIcon fontSize={theme.fontSize.standard} color={theme.color.error} icon='lock' title={t('User is locked')} />  
        }

    </React.Fragment>
})