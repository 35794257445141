import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'


const CommunicationInfo = (
    { activityData }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        {t('Lawyer')}: {activityData.name}
    </React.Fragment>
}




CommunicationInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CommunicationInfo} 