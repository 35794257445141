import React from 'react'
import { DamageInboxLink, RelatedDamageLink } from 'application/components/controls/activity_links/common'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'




export const DamageInboxActivityLink = (
    {
        activity
    }
) => {
    return <ForAnyBranchPermissions 
        permissions={
            [
                USER_PERMISSONS.MESSAGE.READ, 
                USER_PERMISSONS.DAMAGE.READ
            ]
        } 
        allNeeded={true}
    >
        <RelatedDamageLink activity={activity} />
        <DamageInboxLink
            activity={activity} 
        />
    </ForAnyBranchPermissions>
}