import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const postBulkCustomers = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                'customers/bulk', 
                HTTP_METHOD.POST, 
                payload
            )
            return apiCall(request)
        },
        sharedResource: () => null,
        kickstart: () => null
    }
)