import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput, DatePicker } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { useTranslation } from 'react-i18next'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { FormHeadline } from 'application/components/fragments/typography'
import { TwoColumns } from 'application/components/forms/_common'
import { SubHeadline } from 'application/components/fragments/dialog'

export const CommissionDekraPartialForm = ({
    formHook,
}) => {
    const {t} = useTranslation()

    const {
        register, 
        errors, 
        control,
        updateFormState,
        formStateValues,
        setValue,
        trigger
    } = formHook

    return <FlexBox flexDirection='column'>
        <SubHeadline>{t('Inspection date')}</SubHeadline>
        <TwoColumns 
            columnLeft={
                <DatePicker
                    label={t('Date')}
                    name='inspectionDate'
                    validate={{ notEmpty: validationMethods.notEmpty }}
                    error={errors.inspectionDate}
                    onDateChange={updateFormState}
                    control={control}
                    register={register}
                    defaultValue={formStateValues.inspectionDate}
                    setValue={setValue}
                    trigger={trigger}
                />
            }
        />
        <FormHeadline>{t('Inspection site')}</FormHeadline>
        <TextInput
            label={t('Site name')}
            name='inspectionInfoName'
            register={register}
            validate={{ notEmpty: validationMethods.notEmpty }}
            error={errors.inspectionInfoName}
            onChange={updateFormState}
        />
        <AddressDataPartialForm
            register={register}
            disabled={false}
            nameStreet = 'inspectionSite.line1'
            namePostalCode = 'inspectionSite.postalCode'
            nameCity = 'inspectionSite.locality'
            setValue={formHook.setValue}
            validate={{ notEmpty: validationMethods.notEmpty }}
            validatePostalCode={{ notEmpty: validationMethods.notEmpty }}
            errorNameStreet={errors.inspectionSite?.line1}
            errorNamePostalCode={errors.inspectionSite?.postalCode}
            errorNameCity={errors.inspectionSite?.locality}
        />
    </FlexBox>
}