import {withRouter} from 'application/components/higher_order_components/with_router'
import { compose } from 'redux'
import { ROUTES } from 'application/constants'
import { withSharedResourceValidationComponent } from './component'
import { withSharedResourceValidationContainer } from './container'

export const withSharedResourceValidation = (redirectUrl = ROUTES.SHARED_RESOURCE_INVALID) => compose(
    withRouter,
    withSharedResourceValidationContainer(redirectUrl),
    withSharedResourceValidationComponent
)