import { HttpError, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { eventChannel, buffers, END } from 'redux-saga'
import { apply, call, put, take, select, takeEvery } from 'redux-saga/effects'
import { applicationToken } from 'application/storage'
import { ACTIONS } from 'application/constants'
import { addNotification } from 'application/redux/actions/realtime_notifications'
import { showToast } from 'application/redux/actions/notifications'
import { NOTIFICATION, REALTIME_NOTIFICATION_CHANNEL } from 'application/constants'
import { getCurrentAccountId } from 'application/redux/selectors'
import { storeErrorDetails } from 'application/redux/actions/errors'
import { guidGenerator } from 'application/common/guid_helpers'
import { jsonHelpers } from 'application/common'

const connections = []

const createConnection = (currentAccountId) => {
    const url = process.env.REACT_APP_REALTIME_NOTIFICATIONS_URL
    const accessTokenFactory = () => applicationToken.get(currentAccountId)

    const hubConnection = new HubConnectionBuilder()
        .withUrl(url, {
            accessTokenFactory,
            transport: 4
        })
        .withAutomaticReconnect()
        .build()

    const connection = {
        hubConnection,
        accountId: currentAccountId
    }

    return connection
}

const createChannel = connection => eventChannel((emit) => {
    const handler = (data) => {
        emit(data)
    }

    connection.on(REALTIME_NOTIFICATION_CHANNEL, handler)
    connection.onreconnecting((e)=>{
        if(e instanceof HttpError && e.statusCode === 401){
            connection.stop()
            emit(END)
        }else{
            emit(new Error(e))
        }
    })
    
    return () => {
        connection.off(REALTIME_NOTIFICATION_CHANNEL, handler)
    }
}, buffers.expanding())
    
const stopAllExistingConnections = () => {
    connections.forEach(c => {
        if(typeof c.connection?.hubConnection?.completeClose === 'function'){
            c.connection.hubConnection.completeClose()
        }
        c.channel.close()
    })
}

export function* realtimeNotificationsSaga() {
    const currentAccountId = yield select(getCurrentAccountId)
    try{
        yield call(stopAllExistingConnections)

        const connection = yield call(createConnection, currentAccountId)
        const {hubConnection} = connection
        const channel = yield call(createChannel, hubConnection)

        connections.push({connection, channel})

        if(hubConnection.state !== HubConnectionState.Connected){
            yield apply(hubConnection, 'start')
        }
    
        try {
            while (hubConnection.state === HubConnectionState.Connected) {
                const response = yield take(channel)
                const [isJson, parsedData] = jsonHelpers.parseJson(response)
                const data = isJson ? parsedData : response
                yield put(addNotification(data))
            }
        } catch(e){
            yield apply(hubConnection, 'stop')
            yield apply(channel, 'close')
            throw(e)
        } 

    } catch(e){
        const errorId = guidGenerator()
        yield put(storeErrorDetails(e, errorId))
        yield put(showToast('Error establishing realtime connection', NOTIFICATION.LEVEL.ERROR, {errorId}))
    }
}

export function* realtimeNotificationsWatcher(){
    yield takeEvery(ACTIONS.AUTH_LOGIN_SUCCESS, realtimeNotificationsSaga)
}