import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { buildListQuery, apiCall } from 'application/api/helpers'

export const getLawyers = (params) => {
    const {
        offset = 0, 
        limit = 10, 
        orderBy = 'webakteId', 
        direction = 'desc', 
        filterQuery = []
    } = params

    const queryString = buildListQuery(offset, limit, orderBy, direction, filterQuery)

    return {
        authenticated: () => {
            const request = fetchRequestBuilder(`webakteaccounts${queryString}`, HTTP_METHOD.GET)
            return apiCall(request, [500])
        },
        sharedResource: () => null,        
        kickstart:  () => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/webakteaccounts${queryString}`, 
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request, [500])
        }
    }
}