import React from 'react'
import { DialogFooterRight } from 'application/components/fragments/dialog'
import styled from 'styled-components'


export const DialogFooterFixed = styled.div`
    height: auto;
    padding: 16px;
    display: block;
    flex-direction: row;
    border-top: 1px solid ${props => props.theme.color.gray15};
    margin-top: auto;
    position: absolute;
    left:0;
    bottom: 0;
    background: ${props => props.theme.color.white};
    width: 100%;
`

export const DialogFooterFlex = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
`

export const StepsLayoutFooter = (
    { 
        children
    }
) => {
    return <DialogFooterFixed>
        <DialogFooterFlex>
            <DialogFooterRight>
                {children}
            </DialogFooterRight>
        </DialogFooterFlex>
    </DialogFooterFixed>
}