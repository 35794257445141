import { ACTIONS } from 'application/constants'

export const commonGetUsersRequest = (
    offset, 
    limit, 
    orderBy,  
    direction,
    filterQuery
) => ({
    type: ACTIONS.COMMON_GET_USERS_REQUEST,
    offset, 
    limit, 
    orderBy,  
    direction,
    filterQuery
})

export const commonGetUsersRequestDebounced = (
    offset = 0, 
    limit = 10, 
    filterQuery = []
) => ({
    type: ACTIONS.COMMON_GET_USERS_REQUEST_DEBOUNCED,
    offset,
    limit,
    filterQuery,
    orderBy: 'id',
    direction: 'asc'
})

export const commonSetUsersLoading = () => ({
    type: ACTIONS.COMMON_SET_USERS_LOADING
})

export const commonClearUsersList = () => ({
    type: ACTIONS.COMMON_CLEAR_USERS_LIST
})

export const commonPopulateUsers = (usersList) => ({
    type: ACTIONS.COMMON_POPULATE_USERS,
    usersList
})

export const commonResetUsers = () => ({
    type: ACTIONS.COMMON_RESET_USERS
})

//for dialogs
export const getUsersForDialogRequest = (
    dialogName, 
    offset = 0, 
    limit = 10, 
    filterQuery
) => ({
    type: ACTIONS.COMMON_GET_USERS_FOR_DIALOG_REQUEST,
    dialogName,
    offset,
    limit,
    filterQuery,
    orderBy: 'id',
    direction: 'asc'
})

export const getUsersForDialogRequestDebounced = (
    dialogName, 
    offset = 0, 
    limit = 10, 
    filterQuery
) => ({
    type: ACTIONS.COMMON_GET_USERS_FOR_DIALOG_REQUEST_DEBOUNCED,
    dialogName,
    offset,
    limit,
    filterQuery,
    orderBy: 'id',
    direction: 'asc'
})

export const getCustomersForDialogRequestDebounced = (
    dialogName, 
    offset = 0, 
    limit = 10, 
    orderBy,
    direction,
    filterQuery
) => ({
    type: ACTIONS.COMMON_GET_CUSTOMERS_FOR_DIALOG_REQUEST_DEBOUNCED,
    dialogName,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})


//insurances
export const getInsurancesRequest = (insuranceType) => ({
    type: ACTIONS.COMMON_GET_INSURANCES_REQUEST,
    insuranceType
})

export const populateInsurances = (
    insuranceType, 
    companies
) => ({
    type: ACTIONS.COMMON_POPULATE_INSURANCES,
    insuranceType,
    companies
})

export const updateAccount = (accountData) => ({
    type: ACTIONS.COMMON_UPDATE_ACCOUNT,
    accountData
})

//damage typ
export const getComprehensiveDamageTypesRequest = () => ({
    type: ACTIONS.COMMON_GET_COMPREHENSIVE_DAMAGE_TYPES_REQUEST
})

export const populateComprehensiveDamageTypes = (comprehensiveDamageTypes) => ({
    type: ACTIONS.COMMON_POPULATE_COMPREHENSIVE_DAMAGE_TYPES,
    comprehensiveDamageTypes
})

export {
    changeAttachmentCategory,
    changeAttachmentCategoryFromTask,
    getCategoriesRequest,
    populateCategories,
    moveCategory
} from './document_categories'

export {
    requestStatusList,
    populateStatusList,
    updateCustomStatusList
} from './status_list'