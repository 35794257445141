import { validationMethods } from 'application/common'
import { DatePicker } from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const AdditionalDamageInformationPartialForm = ({
    register,
    errors,
    disabled = false,
    setValue,
    trigger,
    formStateValues,
    updateFormState,
    control,
}) => {
    const { t } = useTranslation()

    return <React.Fragment>
        <TwoColumns
            breakPoint='1854'
            columnLeft={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Vehicle received at')}
                            name='vehicleReceivedAt'
                            error={errors.vehicleReceivedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.vehicleReceivedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                            validate={{ notEmpty: validationMethods.notEmpty }}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Final repair started at')}
                            name='finalRepairStartedAt'
                            error={errors.finalRepairStartedAt}
                            control={control}
                            //disabledDays={{before: formStateValues.incidentDate ? new Date(formStateValues.incidentDate) : null}}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.finalRepairStartedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
            columnRight={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Repair completed at')}
                            name='repairCompletedAt'
                            error={errors.repairCompletedAt}
                            control={control}
                            //disabledDays={{before: formStateValues.finalRepairStartedAt ? new Date(formStateValues.finalRepairStartedAt) : null}}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.repairCompletedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Vehicle returned to customer at')}
                            name='vehicleReturnedAt'
                            error={errors.vehicleReturnedAt}
                            control={control}
                            //disabledDays={{before: formStateValues.finalRepairStartedAt ? new Date(formStateValues.repairCompletedAt) : null}}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.vehicleReturnedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }

        />
    </React.Fragment>
}