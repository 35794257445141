import { takeLeading, put } from 'redux-saga/effects'

import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateActivitiesList } from 'application/redux/actions/pages/activity_log'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getActivityLogSaga(action) {
    try {
        const {
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        } = action
        
        const response = yield resourceCall(
            api.gateway.activities.getActivities,
            {
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            }
        ) 
        yield put(populateActivitiesList(response.activities, response.total))
    } catch (e) {
        yield handleError(e)
    }
}

export function* getActivityLogWatcher() {
    yield takeLeading(ACTIONS.ACTIVITY_LOG_GET_ACTIVITIES_REQUEST, getActivityLogSaga)
}