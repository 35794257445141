import { put, call, all } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ListFilter } from 'application/filters/filter_class'
import * as filters from 'application/filters'

export function* widgetDamageAKInvoicesSaga(action) {
    const { damageId } = action.match.params
    const invoicesResponse = yield resourceCall(
        api.gateway.invoices.getInvoices,
        {
            damageId: damageId
        }
    )
    const getPaymentsServerCalls = invoicesResponse.invoices.map(invoice => function* () {
        const paymentResponse = yield resourceCall(
            api.gateway.invoices.getPayments,
            {
                damageId: damageId,
                invoiceId: invoice.id
            }
        )
        invoice.payments = paymentResponse.payments
    })

    yield all(getPaymentsServerCalls.map(sc => call(sc)))

    const getDocumentsServerCalls = invoicesResponse.invoices.map(invoice => function* () {
        const documentsResponse = yield resourceCall(
            api.gateway.attachments.getAttachmentsByDamageId,
            {
                damageId: damageId,
                filterQuery: new ListFilter(filters.documents.parentInvoiceId).setValue(invoice.id).getQuery()
            }
        )
        invoice.documents = documentsResponse.documents
    })

    yield all(getDocumentsServerCalls.map(sc => call(sc)))

    yield put(updateWidget(action.key, action.dependentWidgetKeys, { hasData: invoicesResponse !== '', data: invoicesResponse }))
}