//compatibility layer for react hook form 7
export const useRegister = (register, name, validate, onChange = () => {}) => {
    const isRegistered = register(name, {validate})?.onChange !== undefined

    const onRegisterChange = isRegistered
        ? e => {
            onChange(e)
            register(name, {validate}).onChange(e)
        } : e => {
            onChange(e)
        }

    let registerProps = {}

    if(isRegistered){
        const {
            onChange: removedRegisterChange, //intercept react-hook-form register onChange method to combine it with our own
            ...restProps
        } = register(name, {validate})

        registerProps = restProps
    }


    return {
        onRegisterChange,
        registerProps
    }
}