import {getThumbnailById} from 'application/redux/actions/attachments'


export const mapStateToProps = (state) => {
    return {
        attachments: state.attachments
    }
}


export const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoadAttachmentThumbnail: (
            id, 
            attachmentName, 
            metaData
        ) => dispatch(
            getThumbnailById(
                id, 
                attachmentName, 
                metaData
            )
        )
    }
}