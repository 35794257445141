import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    WORKFLOW_PANEL_TASK_COMMENTS: 'WORKFLOW_PANEL_TASK_COMMENTS'
}

export const widgetsWorkflowPanelTasksSidebar = [
    {
        key: KEYS.WORKFLOW_PANEL_TASK_COMMENTS,
        type: WIDGET_TYPES.WORKFLOW_PANEL_TASK_COMMENTS,
        title: 'Comments',
        hideHeadline: true
    }
]