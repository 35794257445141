import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon,StyledInfoBox, StyledMoHeadline } from './fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { IconButton } from '@ec/ui-controls-react'
import {MoMappedFeedBack} from './_common/mapped_feedback/component'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'

const MassOperationsInfoBoxComponent = (
    {
        onMoClose,
        mo,
        theme,
        children,
        selectedItems = [],
        headline = 'You are in multi selection mode',
        itemsDesignation,
        itemDesignation
    }
) => {
    const { t } = useTranslation()
    
    const handleCloseMo = () => { 
        onMoClose(mo.moName) 
    }


    return <StyledInfoBox>
        <FlexBox
            flexDirection='row' 
            alignItems='center'
            flexGrow='2'
        >
            <Icon/>
            <FlexBox flexDirection='column' >
                <StyledMoHeadline>
                    {t(headline)}
                </StyledMoHeadline>

                <MoMappedFeedBack 
                    itemsCount={selectedItems.length} 
                    itemsDesignation = {itemsDesignation}
                    itemDesignation = {itemDesignation}
                />
            </FlexBox>
        </FlexBox>
        <FlexBox
            flexDirection='row' 
            justifyContent='flex-end'
            alignItems='center'
            flexGrow='2'
            height='inherit'
        >
            {children}
            <FlexBox 
                height='inherit'
                width='auto'
                marginLeft='8px'
                justifyContent='flex-end'
            >
                <IconButton 
                    border={true} 
                    fontSize='inherit' 
                    iconKey='close' 
                    onButtonClick = {handleCloseMo}
                    color={theme.color.primary}
                />
            </FlexBox>
        </FlexBox>
    </StyledInfoBox>     
}

MassOperationsInfoBoxComponent.propTypes = {
    onMoClose: PropTypes.func.isRequired,
    mo: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    children:  PropTypes.object,
    selectedItems: PropTypes.array,
    headline: PropTypes.string,
    itemsDesignation: PropTypes.string,
    itemDesignation: PropTypes.string
}


export const MassOperationsInfoBox = withTheme(MassOperationsInfoBoxComponent) 