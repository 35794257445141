import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { updateWitnessDataDataMapper } from 'application/api/data_mappers/export/damage'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* updateWitnessSaga(action) {
    try {
        const payload = updateWitnessDataDataMapper(
            action.damageData
        )
        yield resourceCall(
            api.gateway.damage.updateWitness, 
            {
                damageId: action.damageId
            }, 
            payload
        )
        yield put(showToast('Witness data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateWitnessWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_UPDATE_WITNESS, 
        updateWitnessSaga
    )
}