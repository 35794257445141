import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { NOTIFICATION } from 'application/constants'
import { AccountMismatchError } from 'application/api/errors/account_mismatch_error'
import { setSessionExpired } from 'application/redux/actions/auth'
import { guidGenerator } from 'application/common/guid_helpers'
import { storeErrorDetails } from 'application/redux/actions/errors'

/**
 * specifies the error message and shows a toast 
 * @param {object} e the error object
 * @param {object} action the redux action that caused the request
 * @param {string} level the toast type according to NOTIFICATION.LEVEL
 */
export function* handleError(e, action = {}, level = NOTIFICATION.LEVEL.ERROR){
    if(e instanceof AccountMismatchError){
        yield put(setSessionExpired())
    }else{
        let message = 'Unexpected error'
    
        if(e.response !== undefined && e.response.statusText !== undefined && e.response.statusText !== ''){
            message = e.response.statusText
        }
    
        if(e.data !== undefined && e.data !== null && e.data.errorKey !== undefined){
            message = e.data.errorKey
        }
        
        const errorId = guidGenerator()
        yield put(storeErrorDetails(e.responseData || e, errorId))
        yield put(showToast(message, level, {errorId}))
        
        yield put(hideWaitingScreen())
    }
}