import styled from 'styled-components'

export const StyledTitleBar = styled.div`  
    display: flex;
    flex: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    background: ${props => props.theme.color.gray10};
    z-index: 1000;
    border-bottom: 1px solid ${props =>  props.theme.color.gray15};
`

export const Cell = styled.div`
    display: flex;
    height: inherit;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    flex-shrink: 1;
    flex-basis: auto;
`

export const TitleBarHeadline = styled.span`
    color: ${props => props.theme.color.primary};
    font-size: 12px;
    margin: 0;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
`