import React from 'react'
import * as Fragments from 'application/components/pages/_layout/fragments'
import { TitleBarWorkflowPanel } from 'application/components/pages/external/title_bar'
import {Dialogs} from 'application/components/building_blocks/dialog/xdialogs'

export const LayoutWorkflowPanel = ({ children }) => {
    return <React.Fragment>
        <Fragments.Header>
            <TitleBarWorkflowPanel />
        </Fragments.Header>
        <Fragments.Main>
            <Fragments.Content cssPaddingLeft='0' cssPaddingTop='0' cssPaddingRight='0'>
                {children}
            </Fragments.Content>
            <Dialogs />
        </Fragments.Main>
    </React.Fragment>
}