import { connect } from 'react-redux'

import { CompareBranchesFilterComponent } from './component'

const mapStateToProps = state => {
    return {
        accessibleBranches: state.common.accessibleBranches,
    }
}

export const CompareBranchesFilter = connect(
    mapStateToProps
)(CompareBranchesFilterComponent)