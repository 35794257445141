import { takeLeading, select, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { getUserGroupById } from 'application/redux/selectors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* connectGroupToRolesSaga(action) {
    try {
        const userGroup = yield select(getUserGroupById, action.groupId)
        const roleIds = [...new Set([...userGroup.roles.map(b => b.id), action.roleId])]

        yield resourceCall(
            api.gateway.groups.updateConnectedRoles,
            {
                groupId: action.groupId
            },
            {
                roles: roleIds.map(id => ({
                    id
                }))
            }
        )

        yield put(showToast('Connection added'))
    } catch (e) {
        yield handleError(e)
    }
}

export function* connectedGroupRolesWatcher() {
    yield takeLeading(
        ACTIONS.BRANCH_MANAGEMENT_ADD_GROUP_ROLE_RELATIONSHIP, 
        connectGroupToRolesSaga
    )
}