import React, {useState} from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { MoDialogFooter } from 'application/components/dialogs/mass_operations/_common/footer'
import { isDefined } from 'application/common/data_helpers'
import { MoDialogCategoryBlock } from '../_common/category_block'
import { removeItemFromListById } from 'application/common/list_helpers'
import { PushBottom16 } from 'application/components/pages/_common'
import {groupItemsByCategory} from '../_common/helper_functions'


const MoDocumentsDeleteComponent = ({
    abort, 
    onDeletion,
    dialog
}) => {
    const { t } = useTranslation()

    const { 
        damageId, 
        moName,
        selectedItems
    } = dialog.payload

    const [selectedItemsByDialog, setSelectedItemsByDialog] = useState(selectedItems)
    const documentsCount = isDefined(selectedItemsByDialog) ? selectedItemsByDialog.length : '0'
    

    const handleRemoveItem = (    
        documentId 
    ) =>  {
        const listWithoutItem = removeItemFromListById(selectedItemsByDialog,documentId)
        setSelectedItemsByDialog(listWithoutItem)
    }


    const handleDeletion = () => {
        const payload = {
            selectedItems: selectedItemsByDialog
        }

        const metaData = {
            damageId, 
            moName
        }

        onDeletion(
            dialog.name,
            payload,
            metaData
        )
    }

    const itemsGroupedByCategory = groupItemsByCategory(selectedItemsByDialog)

    return  <React.Fragment>
        <DialogMainContent>

            <Paragraph>
                <Trans  
                    i18nKey='Should_documents_be_deleted'
                >
                    {{documentsCount}}
                </Trans>
            </Paragraph>
            <PushBottom16/>
            <Paragraph />
            {
                itemsGroupedByCategory.map( 
                    itemsGroup => {            
                        return <MoDialogCategoryBlock
                            key={itemsGroup.id}
                            itemsGroup={itemsGroup}
                            removeItem={handleRemoveItem}
                            moName={moName}
                        />
                    }
                )
            }
            <Paragraph />
        </DialogMainContent>
        <MoDialogFooter 
            onCloseDialog={abort} 
            onSubmit={handleDeletion}
            submitButtonText={ t('Delete') }
        />   
    </React.Fragment>
}

MoDocumentsDeleteComponent.propTypes = {
    abort: PropTypes.func.isRequired,
    onDeletion: PropTypes.func.isRequired,
    dialog: PropTypes.object.isRequired
}

export {MoDocumentsDeleteComponent}