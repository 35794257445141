import { populateSharedResourceData } from 'application/redux/actions/shared_resource'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { put, all, call } from 'redux-saga/effects'
import { api } from 'application/api'
import { requestModulesSaga } from 'application/redux/saga/sagas/modules'

export function* sharedResourceKickstartDataSaga(){
    const [
        account, 
        branch,
        usersResponse
    ] = yield all([
        resourceCall(api.gateway.auth.getAccountById),
        resourceCall(api.gateway.auth.getBranchById),
        resourceCall(api.gateway.users.getUsersList)
    ])

    yield put(populateSharedResourceData({
        account,
        branch,
        adminUser: usersResponse.users[0]
    }))

    yield call(requestModulesSaga, {accountId: account.id})
}