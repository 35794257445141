import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextArea } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { useStatefulForm } from 'application/components/hooks'



const CreateCaseNotes = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onCreateTask, 
        dialog,
        match 
    }
) => {
    const { t } = useTranslation()
    const {register, errors, handleSubmit, getValues} = useStatefulForm()

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }
    const damageId = match.params.damageId
    const onSubmitSuccess = () => { 
        onCreateTask(dialog.name, {
            ...dialog.payload,
            ...getValues(),
        }, damageId)
    }

    const onError = (errors, e) => console.log(errors, e)

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <TextArea
                    label={t('Case notes')}
                    name='noteContent'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.noteContent}
                    onChange={onDataChange}
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Store case notes'
        />
    </React.Fragment>
}

export const CreateCaseNotesComponent = withDialogDataLoader(
    withRouter(CreateCaseNotes)
) 