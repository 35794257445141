import React from 'react'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'


export const VehicleKeeperChangedInfo = (
    {activityData}
) => {
    const {t} = useTranslation()

    const oldName = activityData?.oldVehicleKeeperFullName || ''
    const newName = activityData?.newVehicleKeeperFullName || ''

    return <FlexBox justifyContent='flex-start'>
        {t('Damage')}: {activityData.damage.licensePlate}, &nbsp; {oldName} <RightArrow /> {newName} 
    </FlexBox>

}

VehicleKeeperChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}