import { ACTIVITY_TYPE } from 'application/constants'
import { BranchActivityLinks } from './branch'
import { DamageActivityLinks } from './damage'
import { DefaultActivityLinks } from './default'
import { InvoiceActivityLinks } from './invoice'
import { PaymentActivityLinks } from './payment'
import { UserActivityLinks } from './user'
import { TaskActivityLink, TaskDamageActivityLink, TaskActivityLinkSeparateTaskData } from './task'
import { DocumentActivityLinks } from './document'
import { MessageInDamageActivityLink, DamageInboxActivityLink } from './message'

export const getActivityLinksByType = (activityType) => {
    switch (activityType) {
    case ACTIVITY_TYPE.BRANCH_CREATED:
    case ACTIVITY_TYPE.BRANCH_DELETED:
        return BranchActivityLinks
    case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_CREATED:
    case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
    case ACTIVITY_TYPE.ACKNOWLEDGEMENT_UPDATED:
    case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
    case ACTIVITY_TYPE.REPAIRAUTHORIZATION_CHANGED:
    case ACTIVITY_TYPE.VEHICLE_UPDATED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:
    case ACTIVITY_TYPE.CONTACT_INFO_UPDATED: 
    case ACTIVITY_TYPE.CASEFILE_INFO_UPDATED:
    case ACTIVITY_TYPE.LIABILITY_INQUIRY_UPDATED:
        return DamageActivityLinks
    case ACTIVITY_TYPE.INVOICE_CREATED:
    case ACTIVITY_TYPE.INVOICE_DELETED:
        return InvoiceActivityLinks
    case ACTIVITY_TYPE.PAYMENT_CANCELLED:
    case ACTIVITY_TYPE.PAYMENT_CREATED:
        return PaymentActivityLinks
    case ACTIVITY_TYPE.USER_CREATED:
    case ACTIVITY_TYPE.USER_DATA_CHANGED:
    case ACTIVITY_TYPE.USER_LINKED:
    case ACTIVITY_TYPE.USER_INVITATION_CREATED:
    case ACTIVITY_TYPE.USER_DELETED:
    case ACTIVITY_TYPE.USER_LOCKED:
    case ACTIVITY_TYPE.USER_UNLOCKED:
        return UserActivityLinks
    case ACTIVITY_TYPE.TASK_CREATED:
        return TaskActivityLink
    case ACTIVITY_TYPE.TASK_DELETED:
        return TaskDamageActivityLink
    case ACTIVITY_TYPE.TASK_STATE_CHANGED:
    case ACTIVITY_TYPE.TASK_COMMENT_CREATED:
        return TaskActivityLinkSeparateTaskData
    case ACTIVITY_TYPE.DOCUMENT_CREATED:
    case ACTIVITY_TYPE.DOCUMENT_DELETED:
        return DocumentActivityLinks
    case ACTIVITY_TYPE.MESSAGE_CREATED:
        return MessageInDamageActivityLink
    case ACTIVITY_TYPE.MESSAGE_DELETED:
        return DamageInboxActivityLink
    default:
        return DefaultActivityLinks
    }
}