import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper, StyledTextInputWithLabel } from './fragments'
import { PureSelect } from 'application/components/controls/form'
import PropTypes from 'prop-types'



export const SearchComboBox = (
    {
        filters, 
        searchTerm,
        setSearchTerm,
        onSearch = () => {},
        setFilterKey = () => {},
        disabled = false,
        cssMinWidth = 'auto',
        onClick = () => {}
    }
) => {
    const {t} = useTranslation()


    const options = filters.map(
        filter => ({
            ...t,
            label: t(filter.label),
            value: filter
        })
    )
    const [selectedOption, setSelectedOption] = useState(options[0])
    const ref = useRef()

    const changeSearchTerm = e => {
        if(!disabled){
            setSearchTerm(e.target.value)
        }
    }
    const changeFilter = o => !disabled && setSelectedOption(o)

    const search = e => {
        if(e.nativeEvent.keyCode === 13){
            onSearch( selectedOption.value.getFilterQuery(searchTerm) )
            setSearchTerm(searchTerm)
            setFilterKey(selectedOption.value.filterKey)
        }
    }


    return (
        <Wrapper 
            ref={ref} 
            cssMinWidth={cssMinWidth}
        >
            <PureSelect
                options={options}
                value={selectedOption}
                onChange={changeFilter}
                cssHeight={'35px'}
                cssWidth='180px'
            />
            <StyledTextInputWithLabel>
                <input 
                    value={searchTerm}
                    type='text'
                    onKeyDown={search} 
                    placeholder={t('Search')}
                    disabled={disabled}  
                    onChange={changeSearchTerm}
                    className='jestSearchInput'
                    onClick={onClick}
                />
            </StyledTextInputWithLabel>      
        </Wrapper>
    )
}


SearchComboBox.propTypes = {
    filters: PropTypes.array.isRequired, 
    searchTerm: PropTypes.string.isRequired, 
    setSearchTerm: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    setFilterKey: PropTypes.func,
    disabled: PropTypes.bool,
    cssMinWidth: PropTypes.string,
    onClick: PropTypes.func
}