import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteDocumentCategoryComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteCategory: (name, categoryId, categoryName) => {
            dispatch(sendPayloadRequest(name, { categoryName }, { categoryId }))
        },
    }
}

export const DeleteDocumentCategory = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteDocumentCategoryComponent)