import {
    StringEqualsFilter
} from '@ec/filter-sort-page-query-js'

export const documents = {
    parentTaskId: {
        filterName: 'documents.taskId',
        filterKey: 'dbtid',
        label: 'Task related attachments',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('parent.task.id', value, true)
        ])
    },
    parentInvoiceId: {
        filterName: 'documents.invoiceId',
        filterKey: 'dbiid',
        label: 'Invoice related attachments',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('parent.invoice.id', value, true)
        ])
    },
    documentsByCategoryId: {
        filterName: 'documents.byCategoryId',
        filterKey: 'dbcid',
        label: 'Category related attachments',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('category.id', value, true)
        ])
    }
}