/**
 * 
 * @param {array} currentBranchPermissions the permission sets for a specific branch, usually taken from redux store: state.auth.permissions 
 * @param {array} neededPermissions the permissions to be checked
 * @param {bool} allNeeded are all permissions needed or only one of them
 * @returns {bool} true, if the permission(s) are given in this branch
 */ 
export const isPermittedInSpecificBranch = (
    currentBranchPermissions, 
    neededPermissions, 
    allNeeded = false
) => neededPermissions.length < 1 
    ? true 
    : allNeeded 
        ? neededPermissions.every( r => currentBranchPermissions.includes(r) )
        : neededPermissions.some( r => currentBranchPermissions.includes(r) )
