import { connect } from 'react-redux'
import {DamagesOverviewMyDamages} from './component'
import { getAllDamagesRequest } from 'application/redux/actions/pages/damages_overview/all_damages'
import { getUpdateConstraint } from 'application/redux/selectors'
import { UPDATE_CONSTRAINTS } from 'application/constants'


const mapStateToProps = state => {
    return {
        allDamages: state.pages.damagesOverview.allDamages,
        updateConstraint: getUpdateConstraint(
            state, 
            UPDATE_CONSTRAINTS.DAMAGES_TABLES
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadDamagesList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getAllDamagesRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export const MyDamages = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamagesOverviewMyDamages)