import { DIALOG } from 'application/constants'
import { updateCustomStatusList } from 'application/redux/actions/common'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import {DamageStateEditorComponent} from './component'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onAddCustomState: (lifeCycleState, index, requestCustomStates) => dispatch(openDialogByName(DIALOG.NAME.ADD_CUSTOM_STATE, true, {lifeCycleState, index, requestCustomStates}, 'Add custom state')),
        onDeleteCustomState: (customStateId, customStateName, systemStateName) => dispatch(openDialogByName(DIALOG.NAME.DELETE_CUSTOM_STATE, false, {customStateId, customStateName, systemStateName}, 'Delete custom state')),
        onEditCustomState: (customStateId, customStateName, customStateIconName) => dispatch(openDialogByName(DIALOG.NAME.EDIT_CUSTOM_STATE, true, {customStateId, customStateName, customStateIconName}, 'Edit custom state')),
        onUpdateCustomStateIndex: (requestCustomStates) => dispatch(updateCustomStatusList(requestCustomStates))
    }
}

export const DamageStateEditor =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageStateEditorComponent)