import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { handleError } from '../../errors'
import { populateUserData } from 'application/redux/actions/auth'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export function* dialogEditUserSaga(action) {   
    yield put(showWaitingScreen('Saving user'))
    try {
        const { 
            shouldSendPreferredBranch, 
            userId 
        } = action.metaData

        const cleanedPayload = removePropertiesWithEmptyStrings(
            action.payload.user
        )

        yield resourceCall(
            api.gateway.users.putUser, 
            {
                userId
            }, 
            cleanedPayload
        )
    
        if(shouldSendPreferredBranch){
            yield resourceCall(
                api.gateway.users.putPreferredBranch,
                {
                    userId
                },
                action.payload.preferredBranch
            )

            const userDataResponse = yield resourceCall(api.gateway.auth.getUserData)
            yield put(populateUserData(userDataResponse))
        }


        yield put(showToast(action.metaData.successMsg))

    } catch (e) {
        yield handleError(e)
    }

    yield put(hideWaitingScreen())
}