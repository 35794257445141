import React from 'react'
import { connect } from 'react-redux'
import { CustomHandle } from './fragments'
import { BRANCH_MANAGEMENT } from 'application/constants'

const LeftHandleComponent = ({nodeType, groupIds, roleIds, branchesDisabled, data}) => {

    if(nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE){
        return null
    }

    const isConnectable = (nodeType === BRANCH_MANAGEMENT.NODE_TYPE.BRANCH && !branchesDisabled) 
                            || (nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP && !data.isPredefined)

    const isDisabled = (nodeType === BRANCH_MANAGEMENT.NODE_TYPE.BRANCH && branchesDisabled)
                            || !isConnectable

    return <CustomHandle
        type="target"
        position="left"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
        isValidConnection={(connection)=>{
            if(nodeType === BRANCH_MANAGEMENT.NODE_TYPE.BRANCH){
                return groupIds.includes(connection.source)
            }
            if(nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP){
                return roleIds.includes(connection.source)
            }
            return false
        }}
        $isDisabled={isDisabled}
    />
}

export const LeftHandle = connect(state => ({ 
    roleIds: state.common.roles.map(r => r.id),
    groupIds: state.common.userGroups.map(r => r.id)
}))(LeftHandleComponent)