import React from 'react'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { TableContent } from './table_content'
import { TableWrapper } from './fragments'
import { TableFooter } from './footer'
import { TableHeaderRow } from './header'
import { SelectableTableRowBase } from './rows/selectable/base'
import { DefaultRow } from './rows/default'
import { LinkedTableRow } from './rows/linked'
import { notNull } from 'application/common/data_helpers'



const TableComponent = (
    {
        elementsLoading,
        elementsTotal,
        elementsLoaded,
        elements,
        configuration,
        emptyMessage = 'No elements found',
        selectItem = () => {},
        deselectItem = () => {},
        selectedItemsFromParent = [],
        //mo
        moAddSelectedItem = () => {},
        moRemoveSelectedItem = () => {}, 
        moIsActive = false,
        moSelectedItems = []
    }
) => {
    const hasMassOperations = configuration?.massOperations?.available || false
    const isMORow = hasMassOperations && moIsActive
    const isLinkedRow = notNull(configuration.rows.linked) 
    const isSelectableRow = notNull(configuration.rows.selectable)

    const allSelectedItems = moIsActive 
        ? moSelectedItems
        : selectedItemsFromParent
    
    const  defaultRowComponents = {
        DefaultRow: (props) => <DefaultRow {...props} />,
        LinkedRow: (props) => <LinkedTableRow {...props} />,
        SelectableRow: (props) => <SelectableTableRowBase {...props} />,
        MORow: (props) => {
            return <SelectableTableRowBase 
                {...props}    
                selectItem = { 
                    (element) => {
                        moAddSelectedItem(
                            element
                        )
                    }  
                }
                deselectItem = { 
                    (elementId) => moRemoveSelectedItem(
                        elementId
                    )  
                }       
            />
        } 
    }

    const AppliedRowComponent = isMORow 
        ? defaultRowComponents.MORow
        : isLinkedRow
            ?  defaultRowComponents.LinkedRow
            :  isSelectableRow
                ? defaultRowComponents.SelectableRow
                : defaultRowComponents.DefaultRow


    return <React.Fragment>
        {
            elementsLoaded && <React.Fragment>
                <TableWrapper>
                    <TableHeaderRow 
                        sorting={configuration.sorting}
                        columns={configuration.columns}
                        configuration={configuration}
                    />
                    <TableContent
                        configuration={configuration}
                        elementsLoading={elementsLoading}
                        elementsLoaded={elementsLoaded}
                        elements={elements}
                        elementsTotal={elementsTotal}
                        emptyMessage={emptyMessage}
                        isSelectable = {isSelectableRow} 
                        selectItem = {selectItem}
                        deselectItem = {deselectItem}
                        selectedItems = {allSelectedItems}
                        AppliedRowComponent = {AppliedRowComponent}
                        hasMassOperations = {hasMassOperations}
                        moIsActive = {moIsActive}
                    />
                </TableWrapper>
                <TableFooter
                    tableName={configuration.name}
                    pagination={configuration.pagination}
                    elementsTotal={elementsTotal}
                />
            </React.Fragment>
        }
        
        {
            elementsLoading && <ThemedPreloader />
        }

    </React.Fragment>
}


export const Table = TableComponent