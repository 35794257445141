import React from 'react'
import { useTranslation } from 'react-i18next'
import { COMMISSION_STATE, MODULE_NAME, USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { ActionButtonBar } from 'application/components/controls/action_button_bar/component'
import { TitleBarActionButton } from 'application/components/controls/action_button_titlebar'
import { Dropdown } from 'application/components/pages/internal/_title_bars/_common/dropdown'
import { DropdownItem } from 'application/components/pages/internal/_title_bars/_common/dropdown/item'
import { useModules } from 'application/components/hooks/use_modules'

export const DamageManagerTitleBarButtons = (props) => {
    const { t } = useTranslation()
    const {
        isModuleApplied
    } = useModules()

    return <ActionButtonBar inline={true} gap={'0'}>

        <ForAnyBranchPermissions 
            permissions={[USER_PERMISSONS.CUSTOMER.UPDATE, USER_PERMISSONS.CUSTOMER.READ]} 
            allNeeded={true}
        >
            <TitleBarActionButton 
                icon='qr_code_scanner' 
                buttonText={t('QR-Code')} 
                onButtonClick={props.handleOpenQRCodeCreationDialog} 
                isFirst={true} 
            />
        </ForAnyBranchPermissions>

        {
            props.damageLoaded && !props.hasLawfirm && <TitleBarActionButton 
                icon='group_add' 
                buttonText={t('Lawfirm')} 
                onButtonClick={props.handleCommissionLawfirm} 
            />
        }

        {
            !props.expertFound && <Dropdown iconKey='person_add' label='Expert'>
                
                {
                    isModuleApplied(MODULE_NAME.DEKRA_COMMISSION) && <DropdownItem 
                        iconKey='fact_check' 
                        label={t('Dekra')} 
                        onItemClick={props.handleOpenDialogCommissionDekraExpert} 
                    />
                }

                <DropdownItem iconKey='assignment' label={t('Einfach')} onItemClick={props.handleOpenDialogCommissionOtherExpert} />
            
            </Dropdown>
        }

        {
            props.damageLoaded && !props.hasLawfirm && <React.Fragment>
                <TitleBarActionButton 
                    onButtonClick={() => props.onOpenSmartConnectDialog({
                        damageId: props.damage.id
                    })} 
                    buttonText={t('SmartConnect')} 
                    icon='chat' 
                />
            </React.Fragment>
        }

        {
            props.damageLoaded && props.hasLawfirm && props.lawfirmState === COMMISSION_STATE.ESTABLISHED && <Dropdown iconKey='chat' label='Message'>
                <DropdownItem iconKey='gavel' label={t('Lawyer')} onItemClick={() => props.onCreateMessageForLawyer({
                    damage: props.damage
                })} />
                <DropdownItem iconKey='share' label={t('SmartConnect')} onItemClick={() => props.onOpenSmartConnectDialog({
                    damageId: props.damage.id
                })} />
            </Dropdown>
        }
        
        <TitleBarActionButton 
            onButtonClick={() => props.onCreateCostCoverage({
                damageId: props.damage.id,
                customerId: props.damage.vehicle.keeper.customerId
            })} 
            buttonText={t('Cost coverage')} 
            icon='note_add' 
        />

        {
            props.hasLawfirm && <TitleBarActionButton 
                onButtonClick={() => props.onCreatePowerOfAttorney({
                    damageId: props.damage.id,
                    customerId: props.damage.vehicle.keeper.customerId
                })} 
                buttonText={t('Power of attorney')} 
                icon='note_add' 
            />
        }
    </ActionButtonBar>
}
