import { call, put, all } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreatePowerOfAttorneySaga(action) {
    yield put(showWaitingScreen('Saving power of attorney'))

    const {files} = action.payload

    const postAttachmentsServerCalls = files.map(
        file => function* () {        
            const response = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: action.metaData.damageId
                },
                {
                    file: file.metaData
                }
            )
            return response
        }
    )

    const documentResponses = yield all(
        postAttachmentsServerCalls.map(sc => call(sc))
    )

    const exportToLawfirmServerCalls = documentResponses.map(
        documentResponse => function* () {        
            const response = yield resourceCall(
                api.gateway.attachments.updateAttachment,
                {
                    damageId: action.metaData.damageId,
                    attachmentId: documentResponse.id
                },
                {
                    isExportedToLawFirm: true
                }
            )
            return response
        }
    )

    yield all(
        exportToLawfirmServerCalls.map(sc => call(sc))
    )

    yield put(hideWaitingScreen())
}