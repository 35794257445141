
import React from 'react'
import { useTranslation } from 'react-i18next'
import {ContextMenu} from 'application/components/controls/context_menu'
import {ContextMenuItem} from 'application/components/controls/context_menu_item'
import { ContextMenuWrapper } from 'application/components/tables/common/_common/fragments'
import { USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { getFullName } from 'application/common/name_helpers'

export const ContextMenuGroupMembersComponent = ({
    user,
    onEditUserGroups,
}) => {
    const { t } = useTranslation()

    const handleClick = (e, handle) => {
        e.preventDefault()
        e.stopPropagation()
        handle()
    }

    return <ForAnyBranchPermissions
        permissions={[USER_PERMISSONS.USER.UPDATE]}
    > 
        <ContextMenuWrapper>
            <ContextMenu iconKey='edit'>
                <ContextMenuItem
                    onListElementClick={(event) => {
                        handleClick(event, () => {
                            const dialogTitle = `${t('Edit group memberships')}: ${getFullName(user)}`
                            onEditUserGroups(user, dialogTitle)
                        })
                    }}
                    iconKey='group'
                    title={t('Edit group memberships')}
                >
                    {t('Edit group memberships')}
                </ContextMenuItem>
            </ContextMenu>
        </ContextMenuWrapper>
    </ForAnyBranchPermissions>
}