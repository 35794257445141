import React from 'react'
import { UserMenu } from 'application/components/building_blocks/user_menu'
import { RealtimeNotificationsIndicator } from 'application/components/building_blocks/realtime_notifications_indicator'
import { withTheme } from 'styled-components'



const UserSectionComponent = () => {
    return <React.Fragment>
        <RealtimeNotificationsIndicator />
        <UserMenu/>  
    </React.Fragment>
}

export const UserSection = withTheme(
    UserSectionComponent
)