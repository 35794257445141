import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const customersReducer =  (state = defaultStore.pages.customers, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.customers
    }
    case ACTIONS.CUSTOMERS_GET_CUSTOMERS_REQUEST:
        return {
            ...state,
            customersListLoading: true,
            customersListLoaded: false
        }
    case ACTIONS.CUSTOMERS_POPULATE_CUSTOMERS_LIST:
        return {
            ...state,
            customersListLoading: false,
            customersListLoaded: true,
            customersList: action.customersList,
            customersListTotal: action.customersListTotal
        }

    //customer batch import actions
    case ACTIONS.CUSTOMERS_SEND_BATCH_IMPORT_REQUEST: 
        return {
            ...state,
            batchImport: {
                running: true,
                complete: false,
                amount: action.customersList.length,
                progress: 0
            }
        }
    case ACTIONS.CUSTOMERS_SEND_UPDATE_BATCH_PROGRESS: 
        return {
            ...state,
            batchImport: {
                progress: state.batchImport.progress + action.iterator
            }
        }
    case ACTIONS.CUSTOMERS_BATCH_IMPORT_COMPLETE: 
        return {
            ...state,
            batchImport: {
                running: false,
                complete: true
            }
        }
    case ACTIONS.CUSTOMERS_BATCH_IMPORT_RESET: 
        return {
            ...state,
            batchImport: {
                running: false,
                complete: false,
                amount: 0,
                progress: 0
            }
        }

    default:
        return state
    }
}
