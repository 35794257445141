import { connect } from 'react-redux'
import {DocumentsStepComponent} from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'




const mapStateToProps = state => ({
    damage: state.pages.damageManager.shared.damage,
    currentBranch: state.auth.currentBranch
})

const mapDispatchToProps = dispatch => {
    return {
        onLawfirmCommission: (
            name, 
            payload, 
            metaData
        ) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                metaData
            )
        )
    }
}


export const DocumentsStep = connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsStepComponent)