import { call, takeEvery, put, all } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { getWidgetDataSagas} from './api_mappers'
import { updateWidgetLoadedState, clearWidget } from 'application/redux/actions/widgets'
import { handleError} from '../../errors'

function* fetchWidgetDataSaga(action) {
    try {
        const sagas = yield call(
            getWidgetDataSagas, 
            action.widgetType
        )

        if(!action.dependsOnOtherWidget){
            yield put(
                clearWidget(action.key)
            )
            yield all(
                sagas.map(
                    saga => call(saga, action)
                )
            )
            yield put(
                updateWidgetLoadedState(
                    action.key, 
                    true
                )
            )

            for (const dependentWidgetKey of action.dependentWidgetKeys) {
                yield put(
                    updateWidgetLoadedState(
                        dependentWidgetKey, 
                        true
                    )
                )
            }
        }
       
    } catch (e) {
        yield put(
            updateWidgetLoadedState(
                action.key, 
                true, 
                false, 
                true
            )
        )
        yield handleError(e, action)
    }
}

export function* fetchWidgetDataWatcher() {
    yield takeEvery(
        ACTIONS.WIDGET_REQUEST_DATA, 
        fetchWidgetDataSaga
    )
}