import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { ActivityDamageLink } from './_common/damage_activity_link'



export const DamageDescriptionChangedMetaContent = (
    {
        notification, 
        onLinkClick, 
        inline
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification
    const newDescription = payload.description ? payload.description : t('Not set')
        
    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='Damage description'
            value={newDescription}
        />
        <ActivityDamageLink
            onClick={onLinkClick} 
            damageId={payload.damage.id}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}