import styled from 'styled-components'

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
`

export const Filler = styled.div`
    flex-grow: 1;
`

export const Description = styled.p`
    white-space: pre-line;
`

export const TaskHeadline = styled.h3`
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '6px 0'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: ${props => props.zIndex !== undefined ? props.zIndex : 'unset'};
    min-height: 21px;
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px 0;
`