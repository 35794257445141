import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    DAMAGE_MANAGER_TASK_BULLET_LIST: 'DAMAGE_MANAGER_TASK_BULLET_LIST',
    DAMAGE_MANAGER_COMMISSION_LAWFIRM: 'DAMAGE_MANAGER_COMMISSION_LAWFIRM',
    DAMAGE_MANAGER_COMMISSION_EXPERT: 'DAMAGE_MANAGER_COMMISSION_EXPERT',
    DAMAGE_MANAGER_ACTIVITY_LOG: 'DAMAGE_MANAGER_ACTIVITY_LOG',
    DAMAGE_MANAGER_SHARE: 'DAMAGE_MANAGER_SHARE',
    DAMAGE_MANAGER_REPAIR_AUTHORIZATION_DISPLAY: 'DAMAGE_MANAGER_REPAIR_AUTHORIZATION_DISPLAY'
}

export const widgetsDamageManagerOverviewSidebar = [
    {
        key: KEYS.DAMAGE_MANAGER_REPAIR_AUTHORIZATION_DISPLAY,
        type: WIDGET_TYPES.DAMAGE_REPAIR_AUTHORIZATION_DISPLAY,
        title: 'Repair authorization',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_TASK_BULLET_LIST,
        type: WIDGET_TYPES.DAMAGE_TASK_BULLET_LIST,
        title: 'Tasks',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_COMMISSION_EXPERT,
        type: WIDGET_TYPES.COMMISSION_EXPERT,
        title: 'Commission expert',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_COMMISSION_LAWFIRM,
        type: WIDGET_TYPES.COMMISSION_LAWFIRM,
        title: 'Commission lawfirm',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_SHARE,
        type: WIDGET_TYPES.DAMAGE_SHARE,
        title: 'Share',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_ACTIVITY_LOG,
        type: WIDGET_TYPES.DAMAGE_ACTIVITY_LOG,
        title: 'Activity log',
        hideHeadline: true,
        accountDependent: true
    }
]