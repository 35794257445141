import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { withTheme } from 'styled-components'
import { ActivityInfo } from 'application/components/controls/activity_info'
import { FlexBox } from 'application/components/fragments/flex_box'
import { WidgetLoadMoreButton } from 'application/components/controls/widget_load_more_button'
import { ToggleButtonWithLabel } from 'application/components/controls/toggle_button_with_label'



const DamageActivityLogComponent = (
    {
        widgetKey,
        widgetType,
        payload, 
        theme,
        loadingFailed
    }
) => {
    const { t } = useTranslation()
    const initalToggleValue = payload.reloaded
    const [active, setActive] = useState(initalToggleValue)

    const {
        activities = [],
        total = 0,
        limit = 5
    } = payload
    
    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <FlexBox flexDirection='column'> 
        <ToggleButtonWithLabel 
            toggle={setActive}
            active={active} 
            label='Activities'
        />
        <Fragments.StyleDisplayToggle active={active}>
            {
                activities.sort( (a,b) => a.occurredAt < b.occurredAt 
                    ? 1 
                    : -1)
                    .map( (a, i) => {
                        return <Fragments.ActivityWrapper key={i}>
                            <ActivityInfo activity={a} />
                        </Fragments.ActivityWrapper>
                    } )
            }
            <WidgetLoadMoreButton 
                widgetKey={widgetKey}
                widgetType={widgetType}
                list={activities}
                total={total}
                limit={limit}
            />
        </Fragments.StyleDisplayToggle>
    </FlexBox>
}

DamageActivityLogComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const DamageActivityLog = withTheme(DamageActivityLogComponent)
