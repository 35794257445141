import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const StyledAccountBox = styled.div`
    display: block;
    margin-top: 16px;
    background: ${props => props.backgroundcolor};
    padding: 16px;
    font-size: ${props => props.theme.fontSize.headline1};
    color: ${props => props.fontcolor};
    
    :hover{
        background: ${props => props.isLocked ? props => props.backgroundcolor : props.hoverbackgroundcolor};
    }   
    flex-direction: row;
`

export const StyledAccountLink = styled(NavLink)`
    font-size: ${props => props.theme.fontSize.headline1};
    color: ${props => props.color};

    & > span {
        color: ${props => props.color};
    }

    :hover {
        color: ${props => props.hovercolor};
    }
    :hover span {
        color: ${props => props.hovercolor};
    }
`

export const StyledDisabledAccountLink = styled.span`
    font-size: ${props => props.theme.fontSize.headline1};
    color: ${props => props.theme.color.anthraciteLight};
`

export const StyledAccountAddressRow = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
`

export const StyledAccountAddress = styled.div`
    height: 32px;
`

export const StyledLockedMessage = styled.span`
    white-space: nowrap;
`

export const StyledSecondaryHeadline = styled.span`
    font-size: ${props => props.theme.fontSize.medium18}; 
    color: ${props => props.color};
    text-decoration: none;
`

export const ButtonStyling = styled.div`
    & i.material-icons {
        color: ${props => props.iconColor};
    }

    & > div:hover *{
        color: ${props => props.hovercolor};
    }
`