import { exists } from 'application/common/data_helpers'
import { BaseTableRow, baseTableRowCSS } from 'application/components/tables/refactored/_common/rows/base'
import { useState } from 'react'
import styled from 'styled-components'

const StyledDefaultRow = styled.div`
    ${baseTableRowCSS}
    flex-direction: column;
    
    & .table-row-base, 
    & .table-row-base * {
        cursor: pointer;
    }
`

export const DefaultRow = (
    {
        element,
        configuration,
        backgroundColor,
        cssHeight,
        flexibleCellContainerWidth,
        isSelected = false,
        moIsActive = false,
        className='table-row-base'
    }
) => {

    const hasDetails = exists(configuration?.rows?.details) 
    const [showDetails, setShowDetails] = useState(element?.showDetails || false)


    return  <StyledDefaultRow
        key={element.id}
        backgroundcolor={backgroundColor}
        cssheight={cssHeight}
    >
        <BaseTableRow 
            element={element}
            configuration={configuration}
            flexibleCellContainerWidth={flexibleCellContainerWidth}
            isSelected={isSelected}
            moIsActive={moIsActive}
            className={className}
            cssHeight={cssHeight}
            hasDetails={hasDetails}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
        />
        {
            hasDetails
            && showDetails
            && <configuration.rows.details.ContentComponent 
                element={element}
                isSelected={isSelected}
                moIsActive={moIsActive}
            />
        }
    </StyledDefaultRow>
}