import { connect } from 'react-redux'
import { MoTasksDeleteComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'



const mapStateToProps = (state, props) => {

    const dialog = state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name)

    const { 
        moName,
        selectedItems,
        states
    } = dialog.payload

    return {
        dialog,
        moName,
        selectedItems,
        states,
        allPermissions: state.auth.allPermissions
    } 
}




const mapDispatchToProps = dispatch => {
    return {
        
        onDeletion: (
            dialogName, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        }
    }            
}


export const MoTasksDelete = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoTasksDeleteComponent)
