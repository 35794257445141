import styled from 'styled-components'

export const StyledPdfSignatureBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 2000;
`

export const StyledPdfSignatureWrapper = styled.div`
    position: relative;
    width: 96vw;
    height: 96%;
    top: 2%;
    left: 2%;
    z-index: 2001;
    background: white;
    display: flex;
    flex-direction: column;
`

export const StyledPdfSignatureHeader = styled.div`
    flex: 0 0 50px;
    color: ${props => props.theme.color.anthracite};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${props => props.theme.fontSize.headline2};
    position: relative;
    padding: 0px 16px;
    background: ${props => props.theme.color.white};
`

export const StyledPdfWrapper = styled.div`
    flex: 1 1 auto;
    overflow: auto;
    box-shadow: inset 0px -11px 8px -10px #CCC;
`

export const StyledPdf = styled.div`
    padding-top: 20px;
    width: 1000px;
    max-width: 86vw;
    margin: auto;
`

export const StyledSignatureWrapper = styled.div`
    flex: 0 0 ${props => props.signatureAreaVisible ? '300px' : '92px'};
    border-top: 1px solid ${props => props.theme.color.gray20};
    background: ${props => props.theme.color.gray5};    
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
`

export const StyledSignatureArea = styled.div`
    display: flex; 
    flex-direction: column;
    box-sizing: content-box;
    width: 400px;
    height: 200px;
    border: 1px solid ${props => props.theme.color.gray20};
    background-color: white;
    background-image: url(/images/signature_pad_background.png);
    background-repeat: no-repeat;
    background-position: center center;
`

