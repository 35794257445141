import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'

const AccountDetails = (
    {
        activity
    }
) => {
    return <ActivityDetailWrapper>
        <ActivityDetail label='Name' value={activity.data.name} />
    </ActivityDetailWrapper>
}

AccountDetails.propTypes = {
    activity: PropTypes.object.isRequired
}

export {AccountDetails} 