import React from 'react'
import { isPermittedInAnyBranch } from '../is_permitted_in_any_branch'
        
export const ForAnyBranchPermissionsComponent = (
    {
        allBranchPermissions, 
        permissions,
        children , 
        allNeeded = false
    }
) => {
    return <React.Fragment>
        { 
            isPermittedInAnyBranch(
                allBranchPermissions, 
                permissions, 
                allNeeded
            ) 
            && children 
        }
    </React.Fragment>
}