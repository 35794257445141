import React from 'react'
import { ExtCreateDamageForm } from 'application/components/pages/external/create_damage/sub_components/form'
import { MobileTimeLineStep } from 'application/components/building_blocks/mobile_time_line'
import { ActionButton } from 'application/components/controls/action_button'
import { useTranslation } from 'react-i18next'
import { Headline2 } from 'application/components/fragments/typography'
import { PushBottom16 } from 'application/components/pages/_common'

export const Step0 = (
    {
        currentTimelineStepIndex,
        gotoNextStep,
        createDamageFormHook
    }
) => {
    const {t} = useTranslation()

    return <MobileTimeLineStep
        index={0}
        isActive={currentTimelineStepIndex === 0}
        label={t('Breakdown / Accident report')}
        buttons={
            <ActionButton 
                buttonText={t('Continue')} 
                onButtonClick={createDamageFormHook.handleSubmit(gotoNextStep)} 
            />
        }
    >
        <Headline2>
            <strong>Ausfüllen und in ein paar Minuten den Rückruf erhalten:</strong>
        </Headline2>
        <PushBottom16 />
        <ExtCreateDamageForm 
            formHook={createDamageFormHook}
        />
    </MobileTimeLineStep>
}