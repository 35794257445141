import React from 'react'
import styled from 'styled-components'
import Select, { components } from 'react-select'


const StyledPureSelect = styled.div`
      width: ${props => props.cssWidth};
      flex: ${props => props.cssFlexBasis === 'auto' ? '1' : '0'} 0 ${props => props.cssFlexBasis};
      height: ${props => props.cssHeight};
      font-size: ${props => props.theme.fontSize.standard};
      
      & div.css-1wa3eu0-placeholder { 
        color: ${props => props.theme.color.anthracite50};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.anthracite50}
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.anthracite50}
      }

      /* // select icon customisation */

      & i.material-icons {
        font-size: ${props => props.theme.fontSize.standart};
      }

      &  span.css-1okebmr-indicatorSeparator {
        display: none;
      }

      & div.css-1hb7zxy-IndicatorsContainer,
       div.css-tlfecz-indicatorContainer {
            display: flex;
            height: inherit;
            align-items: center;
            justify-content: center;
      }

      & div.css-1oai5dw-control {
          border-color: ${props =>  props.theme.color.gray15};
          height: auto;
          align-content:center;
      }
      & div.css-ypr86o-control,
      & div.css-zbnekv-control
      & div.css-yk16xz-control {
            align-content:center;
            height: 35px;
            max-height: 35px;
            min-height: 35px;
        }
      & * {
        border-radius: 0px !important;
      }

      & div.css-26l3qy-menu{
          margin-top:-1px;
          left: 1px;
          width: calc(100% - 2px);
      }

      & div.css-1w9j89e-menu { 
        margin-bottom: 0px; 
      }

      ${props => props.additionalCSS}
`

const DropdownIndicator = props => {
    const {
        indicatorIconKey='keyboard_arrow_down'
    } = props

    return (
        <components.DropdownIndicator {...props}>
            <i className="material-icons">{indicatorIconKey}</i>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = props => {
    return (
        <components.ClearIndicator {...props}>
            <i className="material-icons">close</i>
        </components.ClearIndicator>
    )
}

export const PureSelect = React.forwardRef(
    (props, ref) => {
        const {
            cssWidth = 'auto', 
            cssFlexBasis = 'auto', 
            cssHeight,
            additionalCSS = '',
            indicatorIconKey= 'keyboard_arrow_down',
            ...rest
        } = props
    
        return <StyledPureSelect 
            cssWidth={cssWidth} 
            cssFlexBasis={cssFlexBasis}
            cssHeight={cssHeight}
            additionalCSS={additionalCSS}
        >
            <Select 
                components={
                    { 
                        DropdownIndicator: (nativeIndicatorProps) => (
                            <DropdownIndicator 
                                indicatorIconKey={indicatorIconKey}  
                                {...nativeIndicatorProps} 
                            />
                        ), 
                        ClearIndicator 
                    }
                }
                {...rest} 
                innerRef={ref}
            />
        </StyledPureSelect>
    }
)