import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { CreatePowerOfAttorneyComponent } from './component'

const mapStateToProps = (state, props) => {
    return {
        branch: state.common.accessibleBranches.find(b => b.id === props.dialog.payload.damage?.branchId),
        damage: state.pages.damageManager.shared.damage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSave: (name, damageId, files) => {
            dispatch(sendPayloadRequest(name, { files }, { damageId }))
        },
    }
}

export const CreatePowerOfAttorney = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePowerOfAttorneyComponent)