
import React, { useEffect } from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DescriptionField } from '../../common/setting_description'
import { PushBottom16 } from 'application/components/pages/_common'
import { ROUTE_PARAMETERS } from 'application/constants'
import { ListFilter, users } from 'application/filters'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { PureSelect } from 'application/components/controls/form'
import { getFullName } from 'application/common/name_helpers'

export const SelectUserComponent = ({
    definition,
    updateSettings, 
    getSetting,
    usersListLoaded,
    usersListLoading,
    usersList,
    onLoadUsersList
}) => {
    const setting = getSetting(definition.key)

    useEffect(()=>{
        onLoadUsersList(
            0, 
            99, 
            ROUTE_PARAMETERS.USERS.ORDER_BY.VALUES.LASTNAME, 
            ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING,
            new ListFilter(users.getUsersInAdminGroups).getQuery()
        )
    },[onLoadUsersList])

    const handleChange = (option) => {
        updateSettings(definition.key, option.value)
    }

    const options = usersListLoaded
        ? usersList.users.map(user => ({
            value: user.id,
            label: getFullName(user),
            user
        }))
        : []

    const initialOption = options.some(option => option.user.id === setting)
        ? options.find(option => option.user.id === setting)
        : null

    return <FlexBox flexDirection='column'>

        <DescriptionField
            description={definition.description}
        />
        <PushBottom16 />

        {
            usersListLoading 
                ? <ThemedPreloader />
                :  <PureSelect
                    options={options}
                    value={initialOption}
                    onChange={handleChange}
                    isSearchable={true}
                    isClearable={false}
                /> 
        }
    </FlexBox>
}