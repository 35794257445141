import { ACTIONS } from 'application/constants'

export const getLawfirmsRequest = (accountId, branchId) => ({
    type: ACTIONS.GET_LAWFIRMS_FOR_ACCOUNT_REQUEST,
    accountId,
    branchId
})

export const populateLawfirms = (lawfirmsList) => ({
    type: ACTIONS.POPULATE_LAWFIRMS_FOR_ACCOUNT,
    lawfirmsList
})