import React, {useEffect, useState} from 'react'
import { PureSelect } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { nameHelpers } from 'application/common'
import { exists } from 'application/common/data_helpers'

export const DamageAssigneeSelectComponent = ({
    damage,
    damageLoaded,
    usersList = [],
    usersListLoading,
    onAssignUser,
    onSearchUser
}) => {
    const {t} = useTranslation()
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    const [userSearchTriggered, setUserSearchTriggered] = useState(false)
    const [assigneeOptions, setAssigneeOptions] = useState(
        usersList.users !== undefined 
            ? usersList.users.map(
                (u) => ({
                    value: u.id,
                    label: nameHelpers.getFullName(u),
                    firstName: u.firstName,
                    lastName: u.lastName
                })
            ) 
            : []
    )

    const [defaultAssigneeOption, setDefaultAssigneeOption] = useState(damage.assignee !== null && damage.assignee !== undefined ? {
        value: damage.assignee.userId,
        label: nameHelpers.getFullName(damage.assignee),
        firstName: damage.assignee.firstName,
        lastName: damage.assignee.lastName
    } : null)

    useEffect(()=>{
        setAssigneeOptions(usersList.users !== undefined ? usersList.users.map((u) => ({
            value: u.id,
            label: nameHelpers.getFullName(u),
            firstName: u.firstName,
            lastName: u.lastName
        })) : [])
    }, [usersList.users])

    useEffect(()=>{
        setDefaultAssigneeOption(damage.assignee !== null && damage.assignee !== undefined ? {
            value: damage.assignee.userId,
            label: nameHelpers.getFullName(damage.assignee),
            firstName: damage.assignee.firstName,
            lastName: damage.assignee.lastName
        } : null)
    }, [damage.assignee])

    useEffect(()=>{
        usersListLoading && setUserSearchTriggered(true)
    }, [usersListLoading])

    const handleSearchUser = searchTerm => {
        if (userSearchActive && searchTerm.length > 2) {
            onSearchUser(searchTerm, damage.branchId)
        }
    }

    const handleAssign = user => {
        onAssignUser(damage.id, user === null ? null : user.value, exists(user?.firstName) ? user.firstName : '', exists(user?.lastName) ? user.lastName : '')
        setDefaultAssigneeOption(user)
        setAssigneeOptions([])
        setUserSearchTriggered(false)
    }

    if(!damageLoaded){
        return <PureSelect 
            options={[]}
            value={null}
            isDisabled={true}
            placeholder={t('loading...')}
        />
    }

    return <PureSelect
        options={assigneeOptions}
        value={defaultAssigneeOption}
        onChange={handleAssign}
        onBlur={() => { setUserSearchActive(false) }}
        onFocus={() => { setUserSearchActive(true) }}
        isLoading={usersListLoading}
        noOptionsMessage={() => userSearchTriggered ? t('No users found') : t('Start typing to search')}
        placeholder={t('Search employee')}
        onInputChange={handleSearchUser}
        isClearable={false}
    />
}