import React, {useState, useRef, useEffect} from 'react'
import { Document, Page, pdfjs  } from 'react-pdf'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { PDF_JS_WORKER_URL } from 'application/constants'

pdfjs.GlobalWorkerOptions.workerSrc = PDF_JS_WORKER_URL

const StyledDocument = styled.div`
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    & .react-pdf__Document, & .react-pdf__Page{
        max-width: 100%;
        margin: auto;
    }

    & .react-pdf__Page__canvas{
        margin: 20px auto;
        border: 1px solid ${props => props.theme.color.gray15};
    }
`


export const PdfViewer = ({fileType, filePath, errorComponent = null}) => {
    const { t } = useTranslation()
    const [pageCount, setPageCount] = useState(1)
    const [pageWidth, setPageWidth] = useState(0)

    const documentRef = useRef(null)
    useEffect(() => {
        if (!documentRef.current || !documentRef.current.getBoundingClientRect().width){
            return
        }
        setPageWidth(documentRef.current.getBoundingClientRect().width - Math.round(documentRef.current.getBoundingClientRect().width / 10))
    }, [documentRef])

    return(
        
        <StyledDocument ref={documentRef} >
            <Document 
                file={filePath}
                loading={<ThemedPreloader preloaderText={t('GeneratingPDF') + '...'} />}
                onLoadSuccess={(pdf)=> {setPageCount(pdf._pdfInfo.numPages)}}
                onLoadError={console.error}
            >
                {
                    Array(pageCount).fill().map((index, i)=> {
                        return <Page key={i} pageNumber={i + 1} width={pageWidth}/>
                    })
                }
            </Document>
        </StyledDocument>
    )
}