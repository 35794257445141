import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { ActionButton } from 'application/components/controls/action_button'
import { PushBottom8, Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { Headline3 } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ActionButtonBar } from 'application/components/controls/action_button_bar'
import { TabsButtons } from 'application/components/building_blocks/tabs'

const StyledProgressBar = styled.div`
    background-color: ${props => props.theme.color.gray15};
    height: 10px;
    margin-bottom: 6px;
    border-radius: 3px;
`

export const CsvUpload = ({
    complete,
    dataLoaded,
    ProgressBar,
    onAddCsvFile,
    onRemoveFile,
    acceptedFile,
    validCustomers,
    onSubmit,
    setForBusinessCustomers
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return <React.Fragment>
        <TabsButtons
            smallFont={true}
            items={[
                {
                    label: t('Private customer'),
                    onItemClick: () => { 
                        setForBusinessCustomers(false)
                    }
                },
                {
                    label: t('Business customer'),
                    onItemClick: () => { 
                        setForBusinessCustomers(true)
                    }
                }
            ]}
        />
        <PushBottom8 />

        {
            !complete && <InfoBox 
                type={INFOBOX.TYPES.SECONDARY} 
                cssMinHeight='70px'
            >
                <FlexBox flexDirection='column'>
                    <StyledProgressBar>
                        <ProgressBar />
                    </StyledProgressBar>
                    <FlexBox alignItems='center'>

                        

                        {
                            !dataLoaded && <ActionButton
                                buttonText={t('Select CSV file')}
                                onButtonClick={onAddCsvFile}
                            />
                        }
                
                        {
                            dataLoaded && <React.Fragment>
                                <FontIcon icon='check' color={theme.color.success} />
                                <Spacer basis='14px' />
                                <Headline3 cssMargin='0' cssWidth='auto'>
                                    {acceptedFile && acceptedFile.name}
                                </Headline3>
                                <Spacer />
                                <ActionButtonBar inline={true}>
                                    <ActionButton 
                                        buttonText={t('Reset Data')}
                                        onButtonClick={onRemoveFile} 
                                        secondary={true}
                                    />
                                    <ActionButton 
                                        buttonBackground={ validCustomers.length === 0 ? theme.color.anthracite : theme.color.green }
                                        hoverBackground={ validCustomers.length === 0 ? theme.color.anthracite : theme.color.darkGreen }
                                        disabled={validCustomers.length === 0}
                                        buttonText={t('Start import:') + ' ' + validCustomers.length + ' ' + t('customers')}
                                        onButtonClick={onSubmit} 
                                    />
                                </ActionButtonBar>
                            </React.Fragment>
                        }
          
                    </FlexBox>
                </FlexBox>

            </InfoBox>
        }
    </React.Fragment>
}