import React, { useState, useEffect } from 'react'
import { ActionButton } from 'application/components/controls/action_button'
import * as Fragment from './fragments'
import {LanguageSwitchHoc} from 'application/components/building_blocks/language_switch/hoc'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput } from 'application/components/controls/form'
import { ErrorLabel } from 'application/components/fragments/error_information'
import { IMAGES, ROUTES } from 'application/constants'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { CheckboxWithLabel } from 'application/components/controls/form'
import {Image} from 'application/components/controls/image'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { PushBottom24 } from 'application/components/pages/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { sharedResourcePin } from 'application/storage'
import { exists } from 'application/common/data_helpers'
import { Navigate } from 'react-router-dom'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation/with_shared_resource_validation'
import { useStatefulForm } from 'application/components/hooks'

const SharedResourceLogin = withRouter(({ sharedResource, onUpdatePin, onValidatePin, match}) => {
    const {t} = useTranslation()
    const [storePin, setStorePin] = useState(false)
    const { register, errors, handleSubmit, getValues } = useStatefulForm()
    const { sharedResourceType, sharedResourceId } = match.params

    const onSubmitSuccess = () => { onUpdatePin(getValues().pin, sharedResourceId) }

    const loginOnEnter = (event) => {
        event.preventDefault()
        onUpdatePin(getValues().pin, sharedResourceId)
    }

    const storedPin = sharedResourcePin.get(sharedResourceId)
    const hasPin = exists(sharedResource.pin) || exists(storedPin)
    const pin = storedPin || sharedResource.pin || ''
    const shouldValidatePin = !sharedResource.pinValidationPending && !sharedResource.pinIsValidated

    useEffect(() => {
        hasPin && shouldValidatePin && onValidatePin(pin, sharedResourceId, storePin)
    }, [hasPin, pin, onValidatePin, sharedResourceId, storePin, shouldValidatePin])

    if(sharedResource.pinIsValidated && sharedResource.pinIsValid){
        const url = exists(sharedResource.returnUrl)
            ? sharedResource.returnUrl : 
            `${ROUTES.EXT_SHARED_RESOURCE_SWITCH_BASE}${sharedResourceType}/${sharedResourceId}`

        return <Navigate to={url} />
    }

    return <LayoutSharedResource 
        sharedResourceType={sharedResourceType}
    >
        <Fragment.LayoutWrapper>
            <Fragment.LayoutInputWrapper>
                <Fragment.LayoutInput>
                    <LanguageSwitchHoc />
                    <Fragment.FormLoginWrapper>
                        {
                            !sharedResource.pinValidationPending && <TextInput
                                label={t('Enter the PIN code')}
                                name='pin'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.pin}
                                onKeyDownEnter={loginOnEnter}
                            />
                        }
                    
                        {
                            sharedResource.pinIsValidated && !sharedResource.pinIsValid && <React.Fragment>
                                <ErrorLabel>
                                    {t('Wrong PIN')}
                                </ErrorLabel>
                                <PushBottom24 />
                            </React.Fragment>
                        }
                        {
                            sharedResource.pinValidationPending ? <ThemedPreloader /> :
                                <ActionButton
                                    buttonText={t('Start')}
                                    onButtonClick={handleSubmit(onSubmitSuccess)}
                                    strechted={true}
                                />
                        }
                        {
                            !sharedResource.pinValidationPending && <React.Fragment>
                                <PushBottom24 />
                                <CheckboxWithLabel 
                                    label={t('Store PIN?')}
                                    name='storePin'
                                    isRequired={false}
                                    isSelected={storePin}
                                    onCheckboxChange={(e)=>{setStorePin(e.target.checked)}}
                                />
                            </React.Fragment>
                        }
                    </Fragment.FormLoginWrapper>
                    <Fragment.LogoWrapper>
                        <Fragment.StyledBigLogo>
                            <Image data={IMAGES.LOGO_72} />
                        </Fragment.StyledBigLogo>
                    </Fragment.LogoWrapper>
                    <Fragment.PolicyWrapper>
                        <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_IMPRINT_LINK}>
                            {t('legal notice')}
                        </a>
                        <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_PRIVACY_LINK}>
                            {t('privacy')}
                        </a>
                        <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}>
                            {t('conditions')}
                        </a>
                    </Fragment.PolicyWrapper>
                </Fragment.LayoutInput>
            </Fragment.LayoutInputWrapper>
            <Fragment.LayoutInfoWrapper src='/images/sample-login-anwalt_2.jpg' />
        </Fragment.LayoutWrapper>
    </LayoutSharedResource>
})

export const SharedResourceLoginComponent = withSharedResourceValidation()(SharedResourceLogin)