import { ACTIONS } from 'application/constants'

export const createExtDamageRequest = (damageData, filesData = null) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_REQUEST,
    damageData,
    filesData
})

export const createDamageSuccess = (damageId) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_SUCCESS,
    damageId
})


export const setDocumentsSendingState = () => ({
    type: ACTIONS.EXT_SEND_DOCUMENTS_REQUEST
})

export const sendDocumentsSuccess = () => ({
    type: ACTIONS.EXT_SEND_DOCUMENTS_SUCCESS
})


export const createDamageKickstartRequest = (payload) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_REQUEST,
    payload
})

export const createDamageKickstartSuccess = (damageId) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_SUCCESS,
    damageId
})
