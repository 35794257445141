import { call, put, takeEvery, all } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { succeedLoaded } from 'application/redux/actions/dialogs'
import { handleError } from 'application/redux/saga/errors'
import { getDialogDataSagas, sendDialogDataSagas } from './api_mappers'
import { switchDialogSaga } from './_common/switch_dialog'


function* fetchDialogDataSaga(action) {
    try {
        const sagas = yield call(
            getDialogDataSagas, 
            action.dialogName
        )
        yield all(
            sagas.map(
                saga => call(saga, action)
            )
        )
        yield put(
            succeedLoaded(action.dialogName)
        )
    } catch (e) {
        yield handleError(e)
    }
}

function* sendDialogDataSaga(action) {
    try {
        const sagas = yield call(
            sendDialogDataSagas, 
            action.dialogName
        )
        for(const saga of sagas){
            yield saga(action)
        }
    } catch (e) {
        yield handleError(e)
    }
}

export function* fetchDataDialogWatcher() {
    yield takeEvery(
        ACTIONS.DIALOG_REQUEST_DIALOG_DATA, 
        fetchDialogDataSaga
    )
}

export function* sendPayloadWatcher() {
    yield takeEvery(
        ACTIONS.DIALOG_SEND_DIALOG_DATA_BY_NAME, 
        sendDialogDataSaga
    )
}

export function* switchDialogWatcher() {
    yield takeEvery(
        ACTIONS.DIALOG_SWITCH_DIALOG, 
        switchDialogSaga
    )
}


export {getExpertsFromSearchWatcher} from './dialog_get_experts_from_search_saga'