import React, { useEffect } from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import {ActivityLogWorkflowPanelComponent} from './component'



const ActivityLogWorkflowPanelEffectsC = (props) => {
    const { loggedIn, onLoadActivityLog, activityLoaded } = props

    useEffect(() => {
        loggedIn && !activityLoaded && onLoadActivityLog()
    }, [loggedIn, onLoadActivityLog, activityLoaded])

    if (activityLoaded) {
        return <ActivityLogWorkflowPanelComponent {...props} />
    }

    return <React.Fragment />
}

export const ActivityLogWorkflowPanelEffects =  withRouter(ActivityLogWorkflowPanelEffectsC)