import React, { useState } from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCreateDamageSteps } from './with_create_damage_steps'
import { compose } from 'redux'
import {CreateDamageStep} from './step_1'
import {SelectCustomerStep} from './step_0'
import { DialogStepsLayout } from 'application/components/dialogs/_common/steps_layout'
import { EditInsuranceStep } from './step_2'
import { useDamageCreationCustomer } from '../_common/use_damage_creation_customer'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'

const CreateDamageComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        dialog,
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
        changeSubLabel
    }
) => {
    const { 
        createdDamage = null,
        customer = null
    } = dialog.payload

    const {
        getSetting
    } = useUiSettings()

    const {
        customerData,
        setCustomerData,
        applyPreferredVehicleData,
        setApplyPreferredVehicleData,
        resetCustomerData,
        existingCustomerWasChanged,
        setExistingCustomerWasChanged,
        setExistingCustomer
    } = useDamageCreationCustomer(customer)

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const [existingCustomerState, setExistingCustomerState] = useState(getSetting(UI_SETTINGS.ACCOUNT.PREFER_EXISTING_CUSTOMER_ON_DAMAGE_CREATION))

    return <DialogStepsLayout getStepsState={getStepsState}>
        {
            getCurrentStep().id === 0 && <SelectCustomerStep
                abort={abort}
                onDataChange={onDataChange}
                goToNextStep={goToNextStep}
                dialog={dialog}
                setCustomerData={setCustomerData}
                resetCustomerData={resetCustomerData}
                customerData={customerData}
                changeSubLabel={changeSubLabel}
                currentStepIndex = {getCurrentStep().id}
                applyPreferredVehicleData={applyPreferredVehicleData}
                setApplyPreferredVehicleData={setApplyPreferredVehicleData}
                existingCustomerWasChanged={existingCustomerWasChanged}
                setExistingCustomerWasChanged={setExistingCustomerWasChanged}
                existingCustomer={existingCustomerState}
                setExistingCustomer={setExistingCustomerState}
            />
        }
        {
            getCurrentStep().id === 1  && <CreateDamageStep
                abort={abort}
                dialog={dialog}
                onDataChange={onDataChange}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                customerData={customerData}
                changeSubLabel={changeSubLabel}
                currentStepIndex = {getCurrentStep().id}
                applyPreferredVehicleData={applyPreferredVehicleData}
                setExistingCustomer={setExistingCustomer}
            />
        }
        {
            getCurrentStep().id === 2 && <EditInsuranceStep
                abort={abort}
                onDataChange={onDataChange}
                damageId={createdDamage?.id}
                damageType={createdDamage?.type}
            />
        }
    </DialogStepsLayout>

}


export const CreateDamage = compose(
    withCreateDamageSteps(),
    withDialogDataLoader
) (CreateDamageComponent)