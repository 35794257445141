import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    WORKFLOW_PANEL_INBOX_MESSAGE_DETAILS: 'WORKFLOW_PANEL_INBOX_MESSAGE_DETAILS',
    WORKFLOW_PANEL_INBOX_MESSAGE_LIST: 'WORKFLOW_PANEL_INBOX_MESSAGE_LIST',
}

export const widgetsWorkflowPanelInbox = [
    {
        key: KEYS.WORKFLOW_PANEL_INBOX_MESSAGE_LIST,
        type: WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_LIST,
        title: 'Message List',
        hideHeadline: true,
        dimension: '30%',
        routeParameters: ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX
    },
    {
        key: KEYS.WORKFLOW_PANEL_INBOX_MESSAGE_DETAILS,
        type: WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_DETAILS,
        title: 'Message Details',
        hideHeadline: true,
        dimension: '70%'
    } 
]