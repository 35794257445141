
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { showToast } from 'application/redux/actions/notifications'
import { put } from 'redux-saga/effects'
import { NOTIFICATION } from 'application/constants'
import { resetCurrentAccountLogo} from 'application/redux/actions/account_logo'




export function* dialogDeleteAccountlogoSaga(action) {   
    try {
        yield resourceCall(
            api.gateway.account.deleteAccountLogo
        )
        yield put(
            resetCurrentAccountLogo()
        )
    } catch (e) {
        yield put(
            showToast( 
                'Accountlogo could not be removed',
                NOTIFICATION.LEVEL.ERROR
            )
        )    
    }
    yield put(
        showToast( 'Accountlogo successfully removed')
    )    
}