import React from 'react'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'


export const CreatedInfo = (
    {activityData}
) => {
    const {t} = useTranslation()

    return <FlexBox justifyContent='flex-start'>
        {t('Damage')}: {activityData.damage.licensePlate}, &nbsp; {t('Subject')}: {activityData.subject}
    </FlexBox>
}

CreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}