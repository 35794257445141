import React, {useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useEscapeKey, useClickOutside } from 'application/components/hooks'



const StyledDropdown = styled.div`
    position: relative;
    height: ${props => props.height};
    width: ${props => props.width};

    & span {
        padding-left: 8px;
        font-size: 12px;
        font-weight: bold;
    }

    & span, i {
        color: ${props => props.isOpen 
        ? props.theme.color.white 
        : props.theme.color.gray70};
    }  

`

const StyledDropdownList = styled.ul`
    position: absolute;
    left: 0;
    display: inline;
    list-style-type: none;
    z-index: 2002;
    margin: 0;
    box-sizing: border-box;
    padding-inline-start: 0;
    min-width: ${props => props.width};
    width: auto;
`

const StyledTrigger = styled.div`
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        height: inherit;
        padding: 0 16px;
        border-right: 1px solid white;

        background: ${props => props.isOpen 
        ? props.theme.color.gray70 
        : props.theme.color.gray10};

        &:hover{
            background: ${props => props.isOpen ? props.theme.color.gray70 : props.theme.color.black10};
        }
`



export const Dropdown = (
    {
        label = '',
        iconKey = '',
        height = '48px',
        width = '120px',
        children
    }
) => {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    
    const ref = useRef()
    
    useClickOutside(
        ref, 
        () => {setIsOpen(false)}
    )
    
    useEscapeKey(
        () => {setIsOpen(false)}
    )

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }



    return  <StyledDropdown
        ref={ref}
        height={height}
        width={width}
        isOpen={isOpen}
    >
        <StyledTrigger 
            onClick={toggleOpen}
            isOpen={isOpen}
        >
            <i className='material-icons'>{iconKey}</i>
            <span>{t(label)}</span>
        </StyledTrigger>
        {
            isOpen && <StyledDropdownList 
                width={width}
                isOpen={isOpen}
            >
                {children}
            </StyledDropdownList>
        }
    </StyledDropdown>
}