import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ToggleSwitch } from 'application/components/controls/toggle-switch'

export const StyledSwitchWrapper = styled.div`
    height: 35px;

    &>div {
        margin-bottom: 0;
        & label {
               & span {
                padding-left: 0px;
            }
        }
    }
`

export const MassOperationsSwitchWithLabel = (
    {
        moClearAllItems,
        moSetInactive,
        moSetActive,
        isActive = false,
        label='',
        moOpenAdditionalAction=()=>{}
    }
) => {

    const handleMoOpen = () => {
        moOpenAdditionalAction()
        moSetActive() 
    }

    const handleChange = (isActive) => {
        moClearAllItems()
        isActive 
            ? handleMoOpen() 
            : moSetInactive()
    }

    return <StyledSwitchWrapper>
        <ToggleSwitch
            label={label}
            initiallyActive={isActive}
            onChange={handleChange}
        />
    </StyledSwitchWrapper>
}

MassOperationsSwitchWithLabel.propTypes = {
    moSetActive: PropTypes.func.isRequired,
    moSetInactive: PropTypes.func.isRequired,
    moClearAllItems: PropTypes.func.isRequired,
    isActive: PropTypes.bool, 
    label: PropTypes.string,
    moOpenAdditionalAction: PropTypes.func
}