import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import { DateComponent } from '@ec/ui-controls-react'

export const DamageIncidentDateChangedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const {
        oldDate = null,
        newDate = null
    } = activity.data

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Damageincidentdate changed')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            <DateComponent date={oldDate} />
            <RightArrow />
            <DateComponent date={newDate} />
        </Fragments.ActivityParagraph>
    </React.Fragment>
}