import { connect } from 'react-redux'
import {DamagesOverviewMyDamagesComponent} from './component'
import { getAllDamagesRequest } from 'application/redux/actions/pages/damages_overview/all_damages'
import { UPDATE_CONSTRAINTS } from 'application/constants'
import { getUpdateConstraint } from 'application/redux/selectors'

const mapStateToProps = state => {
    return {
        auth: state.auth,
        allDamages: state.pages.damagesOverview.allDamages,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.DAMAGES_TABLES)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadDamagesList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getAllDamagesRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export const DamagesOverviewMyDamages = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamagesOverviewMyDamagesComponent)