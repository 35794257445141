import React from 'react'
import { getActivityInfoByType } from './sub_components'
import * as Fragments from './fragments'
import {DateComponent} from 'application/components/controls/date'
import { LocalTime } from 'application/components/controls/local_time'

export const ActivityInfo = ({activity}) => {

    const MappedActivityComponent = getActivityInfoByType(activity.type)
    const actor = activity?.actor?.name || 'System'

    return <React.Fragment>
        <Fragments.ActivityHeadline>
            <span>{actor}</span>
            <span>
                <DateComponent 
                    date={activity.occurredAt} 
                />, 
                <LocalTime 
                    date={activity.occurredAt} 
                />
            </span>
        </Fragments.ActivityHeadline>
        <MappedActivityComponent activity={activity} />
    </React.Fragment>
}