import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, setQuery, deleteQuery } from 'application/components/hooks/use_query'
import { nameHelpers } from 'application/common'
import { IconButton } from 'application/components/controls/icon_button'
import { PureSelect } from 'application/components/controls/form'
import { exists } from 'application/common/data_helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { Label } from 'application/components/fragments/typography'
import { InfoBox } from 'application/components/controls/info_box'
import { Spacer } from 'application/components/pages/_common'

export const UserFilterComponent = (
    { 
        onSearchUser, 
        usersList, 
        usersListLoading, 
        routeParameters, 
        filterTitle = 'Showing tasks for', 
        onRemoveFilter = () => { },
        onChange = () => {},
        disabled = false
    }
) => {
        
    const query = useQuery()
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { ID, FIRST_NAME, LAST_NAME } = routeParameters
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    const [selectedUserId, setSelectedUserId] = useState(query.getStringParam(ID))
    const [selectedUserFirstName, setSelectedUserFirstName] = useState(query.getStringParam(FIRST_NAME))
    const [selectedUserLastName, setSelectedUserLastName] = useState(query.getStringParam(LAST_NAME))

    //predefine the selected option if the information is present in the URL
    const [selectedUserOption, setSelectedUserOption] = useState( 
        query.getStringParam(ID) === '' 
            ? null 
            : {
                value: query.getStringParam(ID),
                label: nameHelpers.getFullName(
                    { 
                        firstName: query.getStringParam(FIRST_NAME), 
                        lastName: query.getStringParam(LAST_NAME) 
                    }
                ),
                firstName: query.getStringParam(FIRST_NAME),
                lastName: query.getStringParam(LAST_NAME)
            }
    )

    const handleSearchUser = search => {
        if (userSearchActive && search.length > 2) {
            onSearchUser(search)
        }
    }

    const handleSelectUser = option => {
        setSelectedUserId(option.value)
        setSelectedUserLastName(option.lastName)
        setSelectedUserFirstName(option.firstName)
        setSelectedUserOption(option)

        setQuery(
            navigate, 
            location, 
            location.pathname, 
            [
                {
                    key: ID,
                    value: option.value
                }, {
                    key: FIRST_NAME,
                    value: option.firstName
                }, {
                    key: LAST_NAME,
                    value: option.lastName
                }
            ], 
            true
        )
        onChange()
    }

    const usersOptions = usersList.map((u, i) => ({
        value: u.id,
        label: nameHelpers.getFullName(u),
        firstName: u.firstName,
        lastName: u.lastName
    }))

    const handleResetUserSearch = () => {
        setSelectedUserId('')
        setSelectedUserLastName('')
        setSelectedUserFirstName('')
        setSelectedUserOption(null)

        deleteQuery(
            navigate, 
            location, 
            location.pathname, 
            [ID, FIRST_NAME, LAST_NAME], 
            true
        )
        onChange()
    }

    return <FilterFragment.FilterRow className='userfilterRow'>
        <FilterFragment.FilterLabel className='filterLabel'>
            <Label cssMargin='0 16px 0 0'>
                {t('Assignee')}
            </Label>
        </FilterFragment.FilterLabel>

        <FilterFragment.FilterAction className='filterAction'>
            <PureSelect
                options={usersOptions}
                value={selectedUserOption}
                onChange={handleSelectUser}
                onInputChange={handleSearchUser}
                cssFlexBasis='267px'
                isSearchable={true}
                onBlur={ () => { setUserSearchActive(false) }}
                onFocus={ () => { setUserSearchActive(true) }}
                placeholder={ t('Filter for assignee')}
                loadingMessage={ () => (t('Searching for user'))}
                noOptionsMessage={ () => (t('No user found'))}
                isLoading={usersListLoading}
                isDisabled={disabled}
            />  
        </FilterFragment.FilterAction>

        {
            exists(selectedUserId) && <FilterFragment.FilterMeta>
                <InfoBox direction='row' cssMargin='0'>
                    <span>{t(filterTitle)}:</span>
                    <strong>
                                "{
                            nameHelpers.getFullName(
                                { 
                                    firstName: selectedUserFirstName, 
                                    lastName: selectedUserLastName 
                                }
                            )
                        }"
                    </strong>
                    <Spacer />
                    <IconButton 
                        iconKey='close' 
                        width='12px' 
                        height='16px' 
                        onButtonClick={handleResetUserSearch} 
                    />
                </InfoBox>
            </FilterFragment.FilterMeta>
        }

    </FilterFragment.FilterRow>

}