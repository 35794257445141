import { showErrorDetails } from 'application/redux/actions/errors'
import { connect } from 'react-redux'
import {ErrorToastComponent} from './component'

const mapDispatchToProps = (dispatch) => {
    return {
        onDetailsClick: (errorId) => dispatch(showErrorDetails(errorId))
    }
}

export const ErrorToast = connect(
    () => ({}),
    mapDispatchToProps
)(ErrorToastComponent)