import styled from 'styled-components'


export const StyledTaskHighlightBar = styled.div`
  display: flex;
  height: 120px;
  width: 100%;
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.gray5};
`

export const StyledGraySpace = styled.div`
  min-width: 20px;
  width: 20px;
  height: 118px;
  background-color: ${props => props.theme.color.gray5};
`

export const StyledAssigneeInfoColumn = styled.div`
  min-width: 100px;
  width: 100px;
  height: inherit;
  padding-left: 16px;
  padding-right: 16px;
  font-size: ${props => props.theme.fontSize.standard};
`

export const StyledTaskInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  width: 180px;
  height: inherit;
  padding: 8px;
  padding-left: 0px;
`

export const StyledTaskInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-left: 1px solid ${props => props.theme.color.gray15};
` 

export const StyledTaskInfoRow = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: ${props => props.theme.fontSize.standard};
`

export const StyledTaskInfoLabel = styled.span`
  font-size:${props => props.theme.fontSize.standard};
  font-style: ${props => props.hasValue ? 'normal' : 'italic'};
  margin-left: 4px;
`


export const StyledDeadlineInfo = styled.div`
  display: flex;
  align-items: center;

  color: ${props => props.theme.color.anthracite};

  & i {
    font-size: 18px;
  }
`

export const StyledTaskHeadline = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    margin: '6px 0';
    display: flex;
    align-items: center;
    justify-content: space-between;
`








export const SelectStyling = styled.div`
    max-height: 35px;

    & div.reactSelect > div {
      background-color: ${props => props.theme.color.gray5};
      border: 1px solid transparent;
    
      &:hover{
        border: 1px solid ${props => props.theme.color.gray15}; 
        background: ${props => props.theme.color.white};
      }
    }
`

export const StyledTaskHighlightLinkButton = styled.div`
  font-size: ${props => props.theme.fontSize.standard};
  margin-top: 4px;
  padding-left: 8px;
`