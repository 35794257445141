import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { DamageIdleTimeFilter } from 'application/components/building_blocks/filters/damage_idle_time_filter'
import { DamageStateFilterCombo } from 'application/components/building_blocks/filters/damage_state_filter_combo'
import { DamageTypeFilter } from 'application/components/building_blocks/filters/damage_type_filter'
import { TimeRangeFilter } from 'application/components/building_blocks/filters/time_range_filter'
import { SubPageLayout } from 'application/components/pages/_common'
import { TableMyDamagesOverview } from 'application/components/tables/refactored/damages/table_my_damages'
import { ROUTE_PARAMETERS } from 'application/constants'

export const DamagesOverviewMyDamages = ({
    allDamages,
    updateConstraint,
    onLoadDamagesList
}) => {
    const { 
        damagesList, 
        damagesListLoading, 
        damagesListLoaded, 
        damagesListTotal 
    } = allDamages  

    return <SubPageLayout>
        <FilterFragment.FilterContainer>
            <TimeRangeFilter 
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
                label='Damages created between'
                maxPeriod={null}
            />
            <DamageStateFilterCombo
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
                disabled = {damagesListLoading}
            />
            <DamageIdleTimeFilter 
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
            />
            <DamageTypeFilter
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
            />
        </FilterFragment.FilterContainer>
        
        <TableMyDamagesOverview
            damagesList={damagesList}
            damagesListLoaded={damagesListLoaded}
            damagesListLoading={damagesListLoading}
            damagesListTotal={damagesListTotal}
            updateConstraint={updateConstraint}
            onLoadDamages={onLoadDamagesList}
        />
    </SubPageLayout>
}