import { put } from 'redux-saga/effects'
import { openDialogByName } from 'application/redux/actions/dialogs'

export function* dialogOpenDialogSaga(action) {
    const {
        dialogToOpenData,
        shouldOpenDialog = false,
    } = action.metaData

    yield shouldOpenDialog && put(
        openDialogByName(
            dialogToOpenData.name, 
            dialogToOpenData.initiallyLoaded, 
            dialogToOpenData.payload, 
            dialogToOpenData.title
        )
    )  
}