import { isDefined } from 'application/common/data_helpers'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledActivityLink } from '../link'
import PropTypes  from 'prop-types'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const TaskLink = (
    {
        activity,
        taskDataIsSeparate = false  //If activity has  data.task.id  instead of data.id
    }
) => {
    const {t} = useTranslation()

    const {damageManagerTaskLink} = useApplicationLinks()

    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    const taskId = taskDataIsSeparate 
        ? activity.data.task.id
        : activity.data.id

    if(
        !( isDefined(damageId) && isDefined(taskId) ) 
    ){
        return null
    }    

    const linkToTaskInDamageManager = damageManagerTaskLink(
        ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED, 
        taskId,
        damageId
    )

    return <StyledActivityLink
        to={linkToTaskInDamageManager}
    >
        &raquo; {t('View task')}
    </StyledActivityLink>
}



TaskLink.propTypes = {
    activity: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}