import React from 'react'
import {WidgetBoardComponent} from './component'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { useSelector } from 'react-redux'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { getWidgetBoardConfigurationFromSetting } from 'application/widget_configuration/get_dynamic_dashboard_configuration'

const useWidgetContextSettings = (
    widgetContext,
    hasUiSetting,
    settingKey
) => {
    const {
        getSetting,
        userSettingsLoaded,
    } = useUiSettings()

    const context = useSelector(state => state.widgetContexts.contexts.find(c => c.key === widgetContext))
    const hasStoreContext = context !== undefined
    let widgetLayout = hasStoreContext ? context.widgetLayout : []
    let widgetList = hasStoreContext ? context.widgetList: []
    let boardConfiguration = hasStoreContext ? context.boardConfiguration : {}

    if(hasUiSetting){
        const settingValue = getSetting(settingKey)

        const {
            widgetLayout: widgetLayoutSetting,
            widgetList: widgetListSetting,
            boardConfiguration: widgetBoardConfiguration
        } = getWidgetBoardConfigurationFromSetting(settingKey, settingValue)

        widgetLayout = widgetLayoutSetting
        widgetList = widgetListSetting
        boardConfiguration = widgetBoardConfiguration
    }
    return {
        hasContext: !hasUiSetting ? hasStoreContext : userSettingsLoaded,
        widgetLayout,
        widgetList,
        boardConfiguration
    }
}

const WidgetBoardEffectsComponent = (props) => {
    const { registeredWidgets, widgetContext, onRegisterWidgets, onWidgetLayoutChange, hasUiSetting = false, settingKey = '' } = props

    const {
        hasContext,
        widgetLayout,
        widgetList,
        boardConfiguration
    } = useWidgetContextSettings(widgetContext, hasUiSetting, settingKey)

    const handleLayoutChange = (layout) => {
        const registeredWidgetKeys = registeredWidgets.map(w => w.key)
        const widgetsToRegister = widgetList.filter(w => !registeredWidgetKeys.includes(w.key)).map(w => ({
            ...w,
            initialPayload: w.routeParameters !== undefined ? {
                ...w.initialPayload,
                routeParameters: w.routeParameters
            } : {
                ...w.initialPayload
            }
        }))
        
        onRegisterWidgets(widgetContext, widgetsToRegister)
        onWidgetLayoutChange(widgetContext, layout, widgetList)
    }

    return <WidgetBoardComponent 
        {...props} 
        onWidgetLayoutChange={handleLayoutChange} 
        hasContext={hasContext}
        widgetLayout={widgetLayout}
        boardConfiguration={boardConfiguration}
        widgetList={widgetList}
    />
}

export const WidgetBoardEffects =  withRouter(WidgetBoardEffectsComponent)