import React from 'react'
import { FilterRadioCheckboxGroup }  from '../radio_checkbox_group'
import PropTypes from 'prop-types'
import * as filters from 'application/filters'
import { FILTERS } from 'application/constants/filters'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { Label } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'

const DamageIsFictiousFilter = (
    {
        routeParameters,
        disabled = false,
        spaceLeft = false,
        onChange = () => {}
    }
) => {
    const {t} = useTranslation()
    const { OFFSET, LIMIT} = routeParameters
    const filter = filters.damages.isFictitious

    const checkboxDefinitions = [
        {
            label: 'All damages',
            value: FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.ALL
        },
        {
            label: 'Damages with fictitious invoices',
            value:  FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.TRUE
        },
        {
            label: 'Damages without fictitious invoices',
            value: FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.FALSE
        }
    ]

    return <FilterFragment.FilterRow className='stateFilterRow'>
        <FilterFragment.FilterLabel>
            <Label>
                {t('Ficticious Invoices')}
            </Label>
        </FilterFragment.FilterLabel>
        <FilterFragment.FilterAction>
            <FilterRadioCheckboxGroup
                OFFSET={OFFSET}
                LIMIT={LIMIT}
                filter={filter}
                disabled={disabled}
                checkboxDefinitions={checkboxDefinitions}
                spaceLeft={spaceLeft}
                onChange={onChange}
            />               
        </FilterFragment.FilterAction>
    </FilterFragment.FilterRow> 
}

DamageIsFictiousFilter.propTypes = {
    routeParameters: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    spaceLeft: PropTypes.bool,
    onChange: PropTypes.func
}

export {DamageIsFictiousFilter}