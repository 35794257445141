import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    CONTROLLING_GLOBAL_VACANT_POSITIONS: 'WIDGET_CONTROLLING_GLOBAL_VACANT_POSITIONS',
}

export const layoutGlobalControllingStatistics= {
    LARGE: [
        {i: KEYS.CONTROLLING_GLOBAL_VACANT_POSITIONS, x: 0, y: 0, w: 6, h: 3}
    ]
}

export const widgetsGlobalControllingStatistics = [
    {
        key: KEYS.CONTROLLING_GLOBAL_VACANT_POSITIONS,
        type: WIDGET_TYPES.CONTROLLING_GLOBAL_VACANT_POSITIONS,
        title: 'Statistics',
        isSecondary: false,
        hideBorder: true,
        isBlank: true,
        hideHeadline: true,
        routeParameters: ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS.FILTERS,
        accountDependent: true
    }
]
