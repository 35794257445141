import React from 'react'
import {MoTableRow} from './table_row'
import styled from 'styled-components'
import {FlexBox} from 'application/components/fragments/flex_box.js'
import { PushBottom8 } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { dataHelpers } from 'application/common'


const StyledTable = styled.div`
    margin-bottom: 16px;
    ${props => props.alert ? 'color: #721c24;' : ''}
`

const StyledDialogTableHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 4px 8px;
    height: 24px;
    margin-top: 16px;
    align-items: center;

    color: ${props => props.alert ? '#721c24' : props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.small};
    font-weight:  ${props => props.theme.fontWeight.smallBold};
`




const MoDialogTable = (
    {
        removeElement,
        tableConfig,
        elements = [],
        alert = false
    }
) => {
    const {t} = useTranslation()

    const hasLabel = dataHelpers.objectHasProperty( tableConfig, 'label' )

    return <StyledTable alert={alert}>
        {
            hasLabel && <FlexBox cssRules='font-size: 14px;'>
                {tableConfig.label}
            </FlexBox>
        }
        <PushBottom8/>
        <StyledDialogTableHeader alert={alert}>
            {
                tableConfig.columns.map(
                    ( column, i ) => <FlexBox
                        key={i}
                        width={column.width}
                        paddingRight='8px'
                    >
                        {t(column.label)}
                    </FlexBox>
                )
            }
            <FlexBox 
                width={'10%'}
            ></FlexBox>
        </StyledDialogTableHeader>
        {
            elements.map(
                element => {
                    return <MoTableRow
                        key={element.id}
                        element={element}
                        removeElement={removeElement}
                        tableConfig={tableConfig}
                    />
                }
            )           
        }
    </StyledTable>  
}


MoDialogTable.propTypes = {
    removeElement: PropTypes.func.isRequired,
    tableConfig: PropTypes.object.isRequired,
    elements: PropTypes.array,
    alert: PropTypes.bool
}

export {MoDialogTable}