import * as local from './_local'
import * as gateway from './_gateway'
import * as dataMappers from './data_mappers'

const api = {
    local, 
    gateway
}

export { 
    api,
    dataMappers
}