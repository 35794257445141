import { PERMISSION_SCOPE } from 'application/constants'
import { isPermittedInAnyBranch } from './is_permitted_in_any_branch'
import { isPermittedInSpecificBranch } from './is_permitted_in_specific_branch'

/**
 * 
 * @param {array} allBranchesPermissions the permission sets for all branches, usually taken from redux store: state.auth.allPermissions 
 * @param {array} currentBranchPermissions the permission sets for a specific branch, usually taken from redux store: state.auth.permissions 
 * @param {array} neededPermissions the permissions to be checked
 * @param {bool} allNeeded are all permissions needed or only one of them
 * @param {string} branchId the branchId of a specic branch; needed, if the permission scope is SPECIFIC_BRANCH
 * @returns {bool} true, if the permission(s) are given in this branch
 */ 
export const isPermitted = (
    allBranchesPermissions,
    currentBranchPermissions,
    neededPermissions,
    permissionScope = PERMISSION_SCOPE.CURRENT_BRANCH,
    allNeeded = true,
    branchId = null,
) => {
    switch (permissionScope) {
    case PERMISSION_SCOPE.ALL_BRANCHES:
        return isPermittedInAnyBranch(allBranchesPermissions, neededPermissions, allNeeded)
    case PERMISSION_SCOPE.CURRENT_BRANCH:
        return isPermittedInSpecificBranch(currentBranchPermissions, neededPermissions, allNeeded)
    case PERMISSION_SCOPE.SPECIFIC_BRANCH:
        const branchPermissions = allBranchesPermissions.find(p => p.branchId === branchId).permissions
        return isPermittedInSpecificBranch(branchPermissions, neededPermissions, allNeeded)
    case PERMISSION_SCOPE.SHARED_RESOURCE:
    case PERMISSION_SCOPE.NONE:
        return true
    default:
        return true
    }
}
