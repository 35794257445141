import styled from 'styled-components'

export const HeadlineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${props => props.background !== '' ? props.background : props.theme.color.gray5};
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    cursor: pointer;
    padding: 8px;
    margin-bottom: ${({collapsed}) => collapsed ? '12px' : '0'};
    width: 100%;
    &:hover{
        background: ${({collapsed, theme}) => collapsed ? theme.color.gray10 : theme.color.gray5};   
    }
`

export const ContentWrapper = styled.div`
    ${({ collapsed }) => collapsed && `
        display: none;
    `}
    background: ${props => props.background !== '' ? props.background : props.theme.color.gray5};
    margin-bottom: 12px;
    padding: ${props => props.padding};
    width: 100%;
`