import { groupToBranchRelationship } from './group_branch_relationship'
import { groupToRoleRelationship } from './group_role_relationship'
import { BRANCH_MANAGEMENT } from 'application/constants'

export const mapIds = (connectionType, elements, connection) => {
    if(connectionType === BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_ROLE){
        return groupToRoleRelationship(elements, connection.source, connection.target)
    }else{
        return groupToBranchRelationship(elements, connection.source, connection.target)
    }
}