import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { UI_SETTINGS_DATA_TYPE } from 'application/constants/ui_settings'
import { EditBool } from '../setting_editor_components/edit_bool'
import { PureInputWithLabel } from 'application/components/controls/form/pure_input_with_label'
import { StyledHeadline } from './fragments'
import { useTranslation } from 'react-i18next'

const SettingHeaderControl = ({
    dataType,
    settingKey,
    updateSettings,
    getSetting
}) => {
    switch (dataType) {
    case UI_SETTINGS_DATA_TYPE.BOOL:
        return <EditBool
            defaultValue={getSetting(settingKey)}
            onChange={
                checked => updateSettings(
                    settingKey,
                    checked
                )
            }
        />
    case UI_SETTINGS_DATA_TYPE.STRING:
        return <PureInputWithLabel
            type='text'
            defaultValue={getSetting(settingKey)}
            onChange={
                e => {
                    updateSettings(
                        settingKey,
                        e.target.value
                    )
                }
            }
        />
    case UI_SETTINGS_DATA_TYPE.INTEGER:
        return <PureInputWithLabel
            type='number'
            defaultValue={getSetting(settingKey)}
            onChange={
                e => updateSettings(
                    settingKey,
                    e.target.value
                )
            }
        />
    default:
        return null
    }
}

export const SettingHeader = ({
    definition,
    updateSettings,
    getSetting
}) => {
    const {t} = useTranslation()
    const {
        label,
        dataType,
        key
    } = definition

    return <StyledHeadline>
        <FlexBox flexShrink='1' width='auto'>{t(label)}</FlexBox>
        <SettingHeaderControl 
            dataType={dataType}
            getSetting={getSetting}
            settingKey={key}
            updateSettings={updateSettings}
        />
    </StyledHeadline>
}