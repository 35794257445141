import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const allDamagesReducer =  (
    state = defaultStore.pages.damagesOverview.allDamages, 
    action
) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damagesOverview.allDamages
    }
    case ACTIONS.DAMAGES_OVERVIEW_GET_ALL_DAMAGES_REQUEST:
        return {
            ...state,
            damagesListLoading: true,         
            damagesListLoaded: false         
        }
    case ACTIONS.DAMAGES_OVERVIEW_POPULATE_ALL_DAMAGES:
        return {
            ...state,
            damagesListLoading: false,
            damagesListLoaded: true,    
            damagesList: action.damagesList,
            damagesListTotal: action.damagesListTotal   
        }
    case ACTIONS.DAMAGES_OVERVIEW_POPULATE_DAMAGE_STATE: 
        const damagesList = state.damagesList
        const damagesListWithUpdatedDamage = damagesList.map(
            damage => damage.id === action.damageId
                ? {
                    ...damage,
                    state: action.state,
                    showDetails: action.showDetails
                }
                : damage
        )
        return {
            ...state,
            damagesList: damagesListWithUpdatedDamage
        }
    default:
        return state
    }
}
