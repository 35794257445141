import { commonClearUsersList, commonGetUsersRequestDebounced } from 'application/redux/actions/common'
import { updateDamageAssignee } from 'application/redux/actions/pages/damage_manager/shared'
import { connect } from 'react-redux'
import {DamageAssigneeSelectComponent} from './component'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { composeFilterQuery } from 'application/common/filter_helpers'

const mapStateToProps = (state) => {
    const { shared } = state.pages.damageManager
    return {
        damage: shared.damage,
        damageLoaded: shared.damageLoaded,
        usersList: state.common.usersList,
        usersListLoading: state.common.usersListLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSearchUser: (searchTerm, branchId) => dispatch(
            commonGetUsersRequestDebounced(
                0, 
                10, 
                composeFilterQuery([
                    new ListFilter(filters.users.name)
                        .setValue(searchTerm),
                    new ListFilter(filters.users.getUsersForSpecificBranch)
                        .setValue(branchId)
                ])
            )
        ),
        onAssignUser: (
            damageId, 
            userId,
            firstName,
            lastName
        ) => { 
            dispatch(commonClearUsersList())
            dispatch(
                updateDamageAssignee(
                    damageId, 
                    userId,
                    firstName,
                    lastName
                )
            )
        } 
    }
}

export const DamageAssigneeSelect =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageAssigneeSelectComponent)