import React, { useEffect } from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import {DocumentsContentComponent} from './component'


const DocumentsEffectsComponent = (props) => {
    const { onLoadDocumentsWorkflowPanel, categoriesLoaded, loggedIn, match, onHideAttachmentDetails, categoriesLoading, onGetCategories } = props
    const { sharedResourceId } = match.params
    // const shouldLoad = !documentsLoading && sharedResourceId !== undefined

    useEffect(() => {
        // loggedIn && onLoadDocumentsWorkflowPanel(sharedResourceId)
        loggedIn && onHideAttachmentDetails() //hide sidebar when new document center data is loaded
    }, [loggedIn, onHideAttachmentDetails, onLoadDocumentsWorkflowPanel, sharedResourceId])

    useEffect(() => {
        loggedIn && !categoriesLoaded && !categoriesLoading && onGetCategories(sharedResourceId)
    }, [loggedIn, categoriesLoaded, categoriesLoading, onGetCategories, sharedResourceId])

    if (categoriesLoaded ) {
        return <DocumentsContentComponent 
            {...props}
        />
    }

    return <ThemedPreloader />
}

export const DocumentsEffects =  withRouter(DocumentsEffectsComponent)