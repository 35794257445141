import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { buildListQuery } from 'application/api/helpers'
import { apiCall } from 'application/api/helpers'

export const getUsersList = (params) => (
    {
        authenticated: () => {

            const {
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery = []
            } = params

            const queryString = buildListQuery(
                offset, 
                limit, 
                orderBy, 
                direction, 
                filterQuery
            )
            
            const request = fetchRequestBuilder(`users${queryString}`, HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null,
        qr: () => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/users`, params.pin, HTTP_METHOD.GET)
            return apiCall(request)
        },
        kickstart: () => {
            const request = fetchRequestBuilderPin(`external/qr/${params.sharedResourceId}/users`, params.pin, HTTP_METHOD.GET)
            return apiCall(request)
        }
    }
)