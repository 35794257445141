import { connect } from 'react-redux'
import {DocumentsByCategoryComponent} from './component'
import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state


const mapDispatchToProps = dispatch => {
    return {
        onAddDocument: (categoryId, damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.CATEGORY_UPLOAD_FROM_DOCUMENT_CENTER, 
                true, 
                { 
                    categoryId, 
                    damageId 
                }, 
                'Upload documents'
            )
        ),
    }
}

export const DocumentsByCategory =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsByCategoryComponent)