import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { CreateSmartConnectEmailComponent } from './component'

const mapStateToProps = state => ({
    accountId: state.auth.currentAccount.id
})


const mapDispatchToProps = dispatch => {
    return {
        onCreateSmartConnectEmail: (
            dialogName,
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        },
    }
}

export const CreateSmartConnectEmail = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateSmartConnectEmailComponent)