import { createGlobalStyle } from 'styled-components'
import 'application/style/normalize.css'
import { theme } from 'application/style/theme'

export const GlobalStyle = createGlobalStyle`
    html, body, #root {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }
    #root {
        display: flex;
    }
    div {
        box-sizing: border-box;
    }
    * {
        font-family: 'Lato', sans-serif;
    }
    .react-grid-item.react-grid-placeholder{
        background: ${theme.color.gray20};
        border: 2px dashed ${theme.color.anthracite};
    }
    .react-grid-item {
        transition: none;
    }
    strong .material-icons{
        font-weight: bold !important;
    }
`