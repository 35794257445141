import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { buildListQuery, apiCall } from 'application/api/helpers'

export const getCommentsByTask = (params) => {
    const {
        offset, 
        limit, 
        orderBy='createdat',
        direction='desc',
        filterQuery = []
    } = params

    const queryString = buildListQuery(
        offset, 
        limit, 
        orderBy, 
        direction, 
        filterQuery
    )

    return {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/tasks/${params.taskId}/comments${queryString}`,
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/tasks/${params.taskId}/comments${queryString}`,
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
}