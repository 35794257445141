/**
 * Fetch Error Object that contains the http response 
 * 
 * @param {string} message the error message, inherited from Error 
 * @param {object} response the http response object
 */
export class FetchError extends Error {
    constructor(message, response) {
        super(message)
        this.response = response
    }
}
