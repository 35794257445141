import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {DamagesLastRepairAuthorizationsTable} from 'application/components/tables/refactored/damages/table_damages_last_repair_authorizations'
import { WidgedTableHeadline } from 'application/components/widgets/_common/table-headline'


export const LastRepairAuthorizationsComponent = (
    {
        widgetKey, 
        payload, 
        loadingFailed, 
        isLoaded, 
        isLoading
    }
) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <React.Fragment>
        <WidgedTableHeadline
            message={t('last repair authorization')}
        />
        <DamagesLastRepairAuthorizationsTable 
            damagesListLoading={isLoading}
            damagesListLoaded={isLoaded}
            damagesListTotal={payload.total}
            damagesList={ payload.damages}
        />
    </React.Fragment>
}

LastRepairAuthorizationsComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}