import { exists } from 'application/common/data_helpers'
import { SETTINGS } from 'application/constants'
import { useCallback, useMemo, useState } from 'react'

export const useNotificationSettings = (
    defaultSettings,
    userSettings,
    channelTypes = []
) => {
    const [notificationSettings, setNotificationSettings] = useState(defaultSettings.map(setting => ({
        ...setting,
        valueDict: channelTypes.map(channelType => ({
            key: channelType,
            value: !exists(userSettings[setting.type]) || userSettings[setting.type].some(s => s[SETTINGS.KEYS.NOTIFICATIONS_CHANNEL] === channelType)
        }))
    })))

    const setNotificationSettingValue = useCallback((type, key, value) => {
        setNotificationSettings(notificationSettings.map(
            setting => setting.type === type 
                ? {
                    ...setting,
                    valueDict: setting.valueDict.map(
                        settingKeyValuePair => settingKeyValuePair.key === key 
                            ? {
                                ...settingKeyValuePair,
                                value
                            } 
                            : settingKeyValuePair
                    )
                } 
                : setting
        ))
    }, [notificationSettings])

    const notificationSettingsRequestValues = useMemo(()=>{
        return notificationSettings.reduce(
            (o, setting) => Object.assign(o, {
                [setting.type]: setting.valueDict
                    .filter(kvp => kvp.value)
                    .map(kvp => ({
                        [SETTINGS.KEYS.NOTIFICATIONS_CHANNEL]: kvp.key
                    }))
            }), {}
        )
    }, [notificationSettings])

    return {
        notificationSettings,
        setNotificationSettingValue,
        notificationSettingsRequestValues
    }
}