import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LayoutAllBranches } from 'application/components/pages/_layout/layout_all_branches'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { MODULE_NAME, ROUTES, USER_PERMISSONS } from 'application/constants'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { Navigate } from 'react-router-dom'
import { CustomersContent } from './customers'
import { CompaniesContent } from './companies'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import { HookRoute } from 'application/components/building_blocks/hook_route'
import { withRouter } from 'application/components/higher_order_components/with_router'
import { useModules } from 'application/components/hooks/use_modules'
import { CustomersImport } from './batch_import'



const CustomersOverviewComponent = (
    { onOpenCreateCustomerDialog }
) => {
    const { t } = useTranslation()
    const customersURL = ROUTES.CUSTOMERS_PRIVATE
    const companiesURL = ROUTES.CUSTOMERS_BUSINESS
    const bulkImportURL = ROUTES.CUSTOMERS_BULK_IMPORT

    const {
        internalAccountLink
    } = useApplicationLinks()

    const matchCustomersOverview = notNull(
        useMatch(ROUTES.CUSTOMERS)
    )
    const matchPrivateCustomers = notNull(
        useMatch(customersURL)
    )
    const matchCompanies = notNull(
        useMatch(companiesURL)
    )
    const matchBulkImport = notNull(
        useMatch(bulkImportURL)
    )

    const {
        isModuleApplied
    } = useModules()

    if (matchCustomersOverview) {
        return <Navigate 
            to={
                internalAccountLink(
                    customersURL
                )
            }
        />
    } 


    return <LayoutAllBranches
        headlineGeneric={t('Administration')} 
        headlineSpecific={t('Customers')}
        titleSegments={['Customers']}
    >
        <TabsLinks
            items={
                [
                    {
                        label: t('PrivateCustomers'),
                        link: internalAccountLink(             
                            customersURL
                        ),
                        isActive: matchPrivateCustomers
                    },
                    {
                        label: t('BusinessCustomers'),
                        link: internalAccountLink(
                            companiesURL
                        ),
                        isActive: matchCompanies
                    },
                    {
                        label: t('CSV Import'),
                        link: internalAccountLink(
                            bulkImportURL
                        ),
                        isActive: matchBulkImport,
                        skip: !isModuleApplied(MODULE_NAME.BATCH_CUSTOMER_IMPORT)
                    }
                ]
            }
        >
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.CUSTOMER.CREATE
                ]} 
                allNeeded={true}
            >
                <IconButtonWithLabel
                    iconKey='add' 
                    onButtonClick={onOpenCreateCustomerDialog}
                    label={t('Create customer')}
                />
            </ForAnyBranchPermissions>
        </TabsLinks>
        <HookRoute path={customersURL}>
            <CustomersContent />
        </HookRoute>
        <HookRoute path={companiesURL}>
            <CompaniesContent />
        </HookRoute>  
        <HookRoute path={bulkImportURL}>
            <CustomersImport />
        </HookRoute>               
    </LayoutAllBranches>
}

CustomersOverviewComponent.propTypes = {
    match: PropTypes.object.isRequired
}

export const CustomersOverview = withLoggedInUser(
    withRouter(CustomersOverviewComponent)
) 