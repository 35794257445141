import styled from 'styled-components'

export const PaymentInformation = styled.span`
    flex: 0 0 100%;
    overflow: hidden;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 8px;
`

export const PaymentDate = styled.span`
    font-style: ${props => props.theme.fontStyle.italic};
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite50};
`

export const PaymentName = styled.span`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    height: 20px;
    margin-bottom: 4px;
`

export const PaymentInfoButton = styled.span`
    position: relative;
    top: 3px;
    left: 3px;
`

export const PaymentInfo = styled.div`
    background: ${props => props.theme.color.gray5};
    padding: 8px;
    margin: 0 0 4px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    display: ${props => props.isVisible ? 'block' : 'none'};
`

export const PaymentCanceled = styled.span`
    color: ${props => props.theme.color.red};
    padding-left: 8px;
    overflow: hidden;
`

export const PaymentAmount = styled.span`
    flex: 0 0 auto;
    text-decoration: ${props => props.canceled ? 'line-through' : 'none'};
    display: flex;
    justify-content: flex-end;
`

export const TextTM = styled.span`
    color: ${props => props.theme.color.warning};
`

export const TextOA = styled.span`
    color: ${props => props.theme.color.red};
`

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${props => props.theme.color.anthracite50};
    margin: 16px 0;
`

export const Spacer = styled.div`
    flex: 1 1 auto;
`