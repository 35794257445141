import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import { InsuranceSelect } from 'application/components/controls/form/insurance_select'
import { validationMethods } from 'application/common'
import { TwoColumns } from 'application/components/forms/_common'
import { PureSelectWithLabel } from 'application/components/controls/form/select/pure_select_with_label'
import PropTypes from 'prop-types'
import { PushBottom8 } from 'application/components/pages/_common'
import { SubHeadline } from 'application/components/fragments/dialog'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists, isNull } from 'application/common/data_helpers'
import { withTheme } from 'styled-components'
import { INSURANCE_TYPE } from 'application/constants/insurances'
import { getObjectsFieldByPath, removeObjectProperty } from 'application/common/object_helpers'






const InsuranceInputGroupComponent = withTheme(({
    register,
    setValue,
    formStateValues,
    updateFormState,
    errors,
    setInsuranceSelectionFromList,
    insuranceSelectionFromList = true,
    insuranceSelectInputName,
    insuranceTextName,
    formTitle = 'Insurance',
    menuPlacement='bottom',
    validate={notEmpty: validationMethods.notEmpty},
    theme,
    insuranceType = INSURANCE_TYPE.INSURANCE, 
    reset
}) => {
    const {t} = useTranslation()
    const value = getObjectsFieldByPath(formStateValues, insuranceSelectInputName)

    const insuranceTypeOptions = [
        {
            label: t('Select from list'),
            value: true
        },
        {
            label: t('Other insurance'),
            value: false
        }
    ]
   
    const removeCompanyIdFromValidationForm = () => {
        let formStateValuesWithoutCompanyId = formStateValues
        removeObjectProperty(formStateValuesWithoutCompanyId, insuranceSelectInputName)
        updateFormState()
        setValue(insuranceSelectInputName, 'NOT_PROVIDED')
    }
      



    const handleSetInsuranceSelectionType = (value) => {
        setInsuranceSelectionFromList(value)
        if (isNull(validate)) {
            if (!value ) {
                setValue(insuranceSelectInputName, '')
            }  else {
                setValue(insuranceTextName, '')
            }
        } else {
            !value && removeCompanyIdFromValidationForm()
        }
    }

    return <FlexBox flexDirection='column'>
        {
            exists(formTitle) && <React.Fragment>
                <SubHeadline >{t(formTitle)}</SubHeadline>
                <PushBottom8/>
            </React.Fragment>
        }
        <TwoColumns
            breakPoint={theme.breakpoint.medium}
            columnLeft={
                <PureSelectWithLabel
                    options = {insuranceTypeOptions}
                    defaultValue = {insuranceSelectionFromList}
                    setValue = {handleSetInsuranceSelectionType}
                    untranslatedLabel = 'Insurance name'
                    menuPlacement={menuPlacement}
                />
            }
            columnRight={
                insuranceSelectionFromList
                    ? 
                    <InsuranceSelect
                        register={register}
                        setValue={setValue}
                        name={insuranceSelectInputName}
                        initialValue={value}
                        onChange={updateFormState}
                        validate={validate}
                        error={
                            validate !== null && getObjectsFieldByPath(errors, insuranceSelectInputName)
                        }
                        menuPlacement={menuPlacement}
                        maxMenuHeight={204}
                        insuranceType={insuranceType}
                        
                    /> 
                    : 
                    <TextInput
                        label={t('Name of other insurance')}
                        name={insuranceTextName}
                        register={register}
                        validate={validate}
                        error={
                            validate !== null && getObjectsFieldByPath(errors, insuranceTextName)
                        }
                        onChange={updateFormState}
                    />  
            }
        />
    </FlexBox> 
})


InsuranceInputGroupComponent.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    formStateValues: PropTypes.object.isRequired,
    insuranceSelectInputName: PropTypes.string.isRequired,
    insuranceTextName: PropTypes.string.isRequired,
    setFormStateValues: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    setInsuranceSelectionFromList: PropTypes.func.isRequired,
    insuranceSelectionFromList: PropTypes.bool
}

export {InsuranceInputGroupComponent}