import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'

export function* dialogWriteBackToLastDialogSaga(action) {
    yield put(showWaitingScreen('Saving data...'))
    yield put(
        updateDialogDataByName(
            action.payload.fromDialogName,
            { exportToLawfirmIsConfirmed: true }
        )
    )
    yield put(showToast(action.metaData.successMsg))
    yield put(hideWaitingScreen())
}