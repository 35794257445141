import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'



export const VehicleUpdatedActivity = (
    {activity}
) => {
    const {t} = useTranslation()
    const newVehicle = activity.data?.newVehicle

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Vehicle updated')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Vehicle')}: &nbsp; {newVehicle.licensePlate}          
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

VehicleUpdatedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}