import React, { useState } from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { PowerOfAttorneyForm } from 'application/components/forms/power_of_attorney'
import { PushBottom16, PushBottom24 } from 'application/components/pages/_common'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'

export const CreatePowerOfAttorney = (
    { 
        abort, 
        dialog, 
        branch,
        onSave,
        damage
    }
) => {
    const payload = dialog.payload
    const [files, setFiles] = useState([])
    const tempDamage = {
        ...damage,
        liabilityData: dialog.payload.liabilityData
    }

    return <React.Fragment>
        <DialogMainContent>
            <PowerOfAttorneyForm
                lawfirm = {payload.lawfirm}
                customer = {payload.customer}
                branch = {branch}
                files={files}
                setFiles={setFiles}
                submitButtonText='Create power of attorney'
                damage={tempDamage}
            />
            <PushBottom16 />
            <TaskUploader
                onFileAdded={() => { }}
                disableActiveState={true}                   
                files={files}
                setFiles={setFiles}
            />   
            <PushBottom24 />
        </DialogMainContent>
        
        <Footer
            onAbort={abort}
            onSubmit={
                () => onSave(
                    dialog.name, 
                    dialog.payload.damage.id,
                    files
                )
            }
            disabled={files.length === 0}
            submitText='Save and send to lawfirm'
        />
    </React.Fragment>

}

export const CreatePowerOfAttorneyComponent = withDialogDataLoader(CreatePowerOfAttorney)