import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { showToast } from 'application/redux/actions/notifications'
import { assignTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export function* dialogCreateTaskSaga(action) {

    const {
        damageId,
        hasAssignee,
        assignee
    } = action.metaData

    const {
        payload
    } = action

    yield put(
        showWaitingScreen('Creating task')
    )
    yield put(
        addNewSubMessage(payload.title)
    )
    const response = yield resourceCall(
        api.gateway.tasks.createTask, 
        {
            damageId
        }, 
        removePropertiesWithEmptyStrings(payload)
    )

    yield hasAssignee && put(
        assignTask(
            damageId, 
            response.id, 
            assignee.id,
            assignee.firstName,
            assignee.lastName
        )
    )
    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Task created')
    )
}