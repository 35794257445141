import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export function* dialogCreateTaskWorkflowPanelSaga(action) {
    yield put(showWaitingScreen('Creating task'))
    yield resourceCall(
        api.gateway.tasks.createTask, 
        null, 
        removePropertiesWithEmptyStrings(action.payload)
    )
    yield put(hideWaitingScreen())
}