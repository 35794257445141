import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ACCIDENT_REPORT_DESCRIPTION } from 'application/constants'
import { Select } from 'application/components/controls/form'




export const AccidentReportDescriptionSelect = ({
    register,
    setValue,
    name,
    initialValue,
    menuPlacement='bottom',
    onChange = () => {}
}) => {
    const {t} = useTranslation()

    const options = [
        {
            value: ACCIDENT_REPORT_DESCRIPTION.REAR_END_COLLISION,
            label: t('Rear-end collision')
        },
        {
            value: ACCIDENT_REPORT_DESCRIPTION.RIGHT_OF_WAY_VIOLATION,
            label: t('Right-of-way infringement')
        },
        {
            value: ACCIDENT_REPORT_DESCRIPTION.LANE_CHANGE,
            label: t('Lane change')
        },
        {
            value: ACCIDENT_REPORT_DESCRIPTION.OVERTAKING,
            label: t('Overtaking')
        },
        {
            value: ACCIDENT_REPORT_DESCRIPTION.DAMAGE_TO_PARKED_VEHICLE,
            label: t('Parked vehicle damaged')
        },
        {
            value: ACCIDENT_REPORT_DESCRIPTION.LEAVING_THE_LANE,
            label: t('Off the roadway')
        },
        {
            value: ACCIDENT_REPORT_DESCRIPTION.OTHER,
            label: t('Other')
        }
    ]

    const initialOption =  options.find(
        option => option.value === initialValue
    )

    return <Select 
        label={t('Short description of the accident (for RKÜ)')}
        name={name}
        register={register}
        setValue={setValue}
        options={options}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

AccidentReportDescriptionSelect.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    name: PropTypes.string,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
    initialValue: PropTypes.string
}