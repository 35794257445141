import { exists } from 'application/common/data_helpers'

export class ListFilter {

    constructor(filter) {
        const props = {
            filterName: '',
            filterKey: '',
            label: '',
            defaultValue: null,
            getFilterQuery: () => ([]),
            isStatic: false,
            ...filter
        }
        this.filterName = props.filterName
        this.filterKey = props.filterKey
        this.label = props.label
        this.defaultValue = props.defaultValue
        this.getFilterQuery = props.getFilterQuery
        this.isStatic = props.isStatic
        this.value = null
    }

    setValue (value) {
        this.value = value
        return this
    }

    getValue () {
        if(typeof this.value === 'object'){
            return this.value
        }
        try {
            return this.value.toString()
        } catch {
            return this.value
        }
    }

    getDefaultValue () {
        if(typeof this.defaultValue === 'object'){
            return this.defaultValue
        }
        try {
            return this.defaultValue.toString()
        } catch {
            return this.defaultValue
        }
    }

    getQuery () {
        if(this.isStatic){
            return this.getFilterQuery()
        }
        if(!exists(this.value) && !exists(this.defaultValue)){
            return []
        }
        return this.getFilterQuery(this.getValue() || this.getDefaultValue())
    }
}