import React, { useEffect } from 'react'
import {WidgetAreaComponent} from './component'
import {withRouter} from 'application/components/higher_order_components/with_router'

const WidgetAreaEffectsComponent = (props) => {
    const { registeredWidgets, widgetList, widgetContext, onRegisterWidgets } = props
    const registeredWidgetKeys = registeredWidgets.map(w => w.key)
    const widgetsToRegister = widgetList.filter(w => !registeredWidgetKeys.includes(w.key)).map(w => ({
        ...w,
        initialPayload: w.routeParameters !== undefined ? {
            ...w.initialPayload,
            routeParameters: w.routeParameters
        } : {
            ...w.initialPayload
        }
    }))
    useEffect(() => {
        onRegisterWidgets(widgetContext, widgetsToRegister)
    }, [onRegisterWidgets, widgetContext, widgetsToRegister])

    return <WidgetAreaComponent {...props} />
}

export const WidgetAreaEffects =  withRouter(WidgetAreaEffectsComponent)