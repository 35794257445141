import { HTTP_METHOD, fileUploadRequestBuilder } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateAccountLogo = () => (
    {
        authenticated: (payload) => {
            const {
                file
            } = payload
            
            const request = fileUploadRequestBuilder(
                'account/logo', 
                file.metaData,
                [], 
                false, 
                false,
                true,
                HTTP_METHOD.PUT
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
)