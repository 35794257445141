import { removeDuplicateIdsFromArrayOfObjects } from './object_helpers'

export const getAvailableBranchesFromGroups = (groups, allBranches) => {
    //at least one group has access to all branches
    const accessAllBranches = groups.some(group => group.canAccessAllBranches)

    const accessibleBranchesWithDoubles = accessAllBranches 
        ? allBranches
        : groups.reduce(
            (sum, current) => {
                return [
                    ...sum,
                    ...current.branches
                ]
            },
            []
        )

    return removeDuplicateIdsFromArrayOfObjects(accessibleBranchesWithDoubles)
}