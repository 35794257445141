export const LIABILITY_INQUIRY_STATUS = {
    UNKNOWN: 'Unknown',
    CONFIRMED: 'Confirmed',
    UNCONFIRMED: 'Unconfirmed',
}

export const COMMISSION_STATUS = {
    NOT_SET: 'NotSet',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected'
}

export const CASE_STATUS = {
    NOT_SET: 'NotSet',
    OPEN: 'Open',
    CLOSED: 'Closed'
}

export const LIABILITY_INQUIRY_INFO = {
    MISSING_WRITTEN_REPORT_OF_OPPONENT: 'MissingWrittenReportOfOpponent',
    PENDING_VERIFICATION_BY_OPPOSING_INSURANCE: 'PendingVerificationByOpposingInsurance',
    INVESTIGATION_FILE_REQUESTED: 'InvestigationFileRequested',
    LIABILITY_DENIED: 'LiabilityDenied',
    LIABILITY_CONFIRMED: 'LiabilityConfirmed',
    LIABILITY_RATIO: 'LiabilityRatio',
    GREEN_CARD: 'GreenCard',
    MISSING_ACCIDENT_REPORT: 'MissingAccidentReport',
    INQUIRIES_DENIED: 'InquiriesDenied',
    FOREIGN_INVOLVEMENT: 'ForeignInvolvement',
    GERMAN_INSURANCE: 'GermanInsurance',
    FOREIGN_INSURANCE: 'ForeignInsurance'
}