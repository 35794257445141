import { connect } from 'react-redux'
import { updateRepairStartedAt } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageRepairStartedAtFormComponent } from './component'



const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (
            metaData, 
            payload
        ) => dispatch(
            updateRepairStartedAt(
                metaData,
                payload
            )
        )
    }
}

export const DamageRepairStartedAtForm =  connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageRepairStartedAtFormComponent)