import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { fileHelpers } from 'application/common'
import * as Fragments from './fragments'
import { useAccept } from 'application/components/hooks'





const IconButtonUploadComponent = ({
    iconKey = 'attach_file', 
    onFileAdded, 
    onFilesAdded,
    onFilesRejected, 
    onFilesAddStart = () => {},
    title = '',
    disabled = false, 
    width = '48px', 
    height = '48px', 
    name = '', 
    capture = false, 
    label = '', 
    theme, //from theme context,
    multiple = true,
    allowedFileExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS
}) => {
    const { accept } = useAccept(allowedFileExtensions)

    const handleChange = async (e) => {
        const filesArray = Array.from(e.target.files)
        const rejectedFiles = filesArray.filter(
            f=>(
                !fileHelpers.isAcceptedFileExtension(
                    fileHelpers.getFileExtension(f.name.toLowerCase())
                ) 
                || fileHelpers.isEmpty(f) 
            )
        )
        const acceptedFiles = filesArray.filter(
            f=>(
                fileHelpers.isAcceptedFileExtension(
                    fileHelpers.getFileExtension(f.name.toLowerCase())
                ) 
                && !fileHelpers.isEmpty(f)
            )
        )
        rejectedFiles.length > 0 && onFilesRejected(rejectedFiles)
        if(acceptedFiles.length > 0){
            onFilesAddStart()
            await fileHelpers.readFiles(
                acceptedFiles, 
                onFileAdded, 
                onFilesAdded
            )
        }
    }
    return <Fragments.StyledContainer 
        disabled={disabled} 
        widthProp={width} 
        heightProp={height}
    >
        <FontIcon 
            icon={iconKey} 
            isClickable={!disabled} 
            title={title} 
            color={
                disabled 
                    ? theme.color.anthracite50 
                    : theme.color.anthracite
            } />
        {
            capture 
                ? <Fragments.StyledInput 
                    onChange={(e)=>{handleChange(e)}} 
                    disabled={disabled} 
                    type='file' 
                    name={name} 
                    accept={accept} 
                    multiple={multiple}
                    capture
                />
                : <Fragments.StyledInput 
                    onChange={(e)=>{handleChange(e)}} 
                    disabled={disabled} 
                    type='file' 
                    name={name} 
                    accept={accept}
                    multiple={multiple}
                />
        }
        {
            label !== '' && <Fragments.StyledLabel className='buttonLabel'>
                {label}
            </Fragments.StyledLabel>
        }
    </Fragments.StyledContainer>
}

IconButtonUploadComponent.propTypes = {
    iconKey: PropTypes.string,
    onFileAdded: PropTypes.func.isRequired,
    onFilesRejected: PropTypes.func.isRequired,
    onFilesAddStart: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string, 
    name: PropTypes.string,
    capture: PropTypes.bool,
    label: PropTypes.string,
    allowedFileExtensions: PropTypes.string
}

export const SimpleUploaderComponent = withTheme(IconButtonUploadComponent)