import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'
import { exists } from 'application/common/data_helpers'

export const AcknowledgementUpdatedInfo = (
    { activityData }
) => {
    const {t} = useTranslation()
    const oldStatus = activityData.oldAcknowledgement?.commissionStatus ?? ''
    const newStatus = activityData.newAcknowledgement?.commissionStatus ?? ''

    return <React.Fragment>
        <FlexBox>
            {t('Damage')} : {activityData.damage?.licensePlate}, &nbsp; {t('Lawyer')}: {activityData.lawFirm?.name}
        </FlexBox>
        <FlexBox>
            {t('Status')} : {exists(oldStatus) ?? <React.Fragment>{t(oldStatus)} <RightArrow /> </React.Fragment>} {t(newStatus)}
        </FlexBox>
    </React.Fragment>
}

AcknowledgementUpdatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}
