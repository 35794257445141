import React from 'react'
import { StyledActivityLink } from 'application/components/controls/activity_links/common'
import { useTranslation } from 'react-i18next'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const BranchActivityLinks = () => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const link = internalAccountLink(
        ROUTES.BRANCH_MANAGEMENT
    )

    return <ForAnyBranchPermissions permissions={[USER_PERMISSONS.BRANCH.CREATE]}>
        <StyledActivityLink to={link}>
            &raquo; {t('View branches')}
        </StyledActivityLink>
    </ForAnyBranchPermissions>
}