import { connect } from 'react-redux'
import {FormWorkflowPanelEffects} from './effects'

const mapStateToProps = state => {
    return {
        damageLoaded: state.pages.workflowPanel.shared.damageLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export const FormWorkflowPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormWorkflowPanelEffects)