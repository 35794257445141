import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const deleteCustomer = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(`customers/${params.customerId}`, HTTP_METHOD.DELETE)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)