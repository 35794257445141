import styled from 'styled-components'

export const Task = styled.div`
    & > a {
        display: block;
        padding: 12px 12px 6px;
        cursor: pointer;
        border: 1px solid ${props => props.theme.color.gray15};
        border-radius: 4px;
        border-radius: 4px;
        margin-bottom: 8px;
        text-decoration: none;
        background-color:${props => props.cssActive ? props.theme.color.gray10 : props.theme.color.white};
        &:hover{
            background-color:${props => props.theme.color.gray10};
        }
        & * {
            text-decoration: none;
        }
    }
    &:last-child > a { 
        border-bottom: 1px solid ${props => props.theme.color.gray15};
    }
`

export const TaskTitle = styled.h3`
    max-width: 100%;
    margin: 4px 0 0;
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite};
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${props => props.marginBottom !== undefined ? props.marginBottom : '6px'};
    flex: 0 0 100%;
    align-items: center;
`

export const Left = styled.div`
    flex: 0 0 32px;
`

export const Filler = styled.div`
    flex-grow: 1;
    flex-basis: auto;    
    overflow: hidden;
`

export const Spacer = styled.div`
    width: ${props => props.cssWidth !== undefined ? props.cssWidth : '2px'};
`