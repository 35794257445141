import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import {DamagesOverviewAllDamages as AllDamages} from './all_damages'
import  {DamagesOverviewMyDamages as MyDamages} from './my_damages'
import { matchRoute } from 'application/common/route_helpers'
import { useMatch } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const DamagesOverviewComponent = () => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const damagesOverview = matchRoute(
        ROUTES.DAMAGES_OVERVIEW,
        useMatch
    )
    const myDamages = matchRoute(
        ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES,
        useMatch
    )
    const allDamages = matchRoute(
        ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES,
        useMatch
    )

    if (damagesOverview) {
        return <Navigate 
            to={
                internalAccountLink(
                    ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES
                )
            } 
        />
    } 


    return <LayoutAllBranches
        titleSegments={['Damages', 'All branches']}
        headlineSpecific={t('Damages')}
    >

        <TabsLinks
            items={[
                {
                    label: t('My damages'),
                    link: internalAccountLink(
                        ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES
                    ),
                    isActive: myDamages
                },
                {
                    label: t('All damages'),
                    link: internalAccountLink(
                        ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES
                    ),
                    isActive: allDamages
                }
                //,{
                //    label: t('Archived damages'),
                //    link: internalAccountLink( ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES),
                //    isActive: match.path === ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES
                //}
            ]}
        />
        {
            myDamages && <MyDamages />
        }
        {
            allDamages && <AllDamages />
        }
    </LayoutAllBranches>
}