import {
    Image, 
    Document,
    Page,
    Text,
    View
} from '@react-pdf/renderer'
import {
    styles
} from './styles'
import { DateComponent } from 'application/components/controls/date'
import { exists } from 'application/common/data_helpers'
import React from 'react'
import { PDFDate} from 'application/components/dynamic_pdfs/builders/_common/pdf_date'





export const RepairSchedulePdf = ({
    values = null
}) => {
    const {
        damage,
        repairSchedule,
        customer,
        vehicle,
        assignee,
        currentBranch,
        accountLogo,
        currentAccount,
        signatures
    } = values

    const  {
        appraisalReceivedAt,
        inspectedAt,
    } = repairSchedule


    return <Document>
        <Page size="A4" style={styles.page}>
            {
                currentAccount.hasLogo 
                && accountLogo.accountLogoFound
                && <View 
                    style={{
                        alignItems:'center', 
                        marginTop: 20
                    }}
                >
                    <View style={{
                        width:50
                    }}>
                        <Image
                            src={{ uri: accountLogo.filePath}}
                        />
                    </View>
                </View>
            }
        
            <View style={styles.headline}>
                <Text>
                    Reparaturablaufplan
                </Text>
            </View>
        
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Kunden-Nr.:</Text>
                </View>
                <View style={styles.valueField}>
                    <Text>
                        {customer.externalReferenceId}
                    </Text>
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Name des Kunden:</Text>
                </View>
                <View style={styles.valueField}>
                    <Text>
                        {customer.name}
                    </Text>
                </View>
            </View>
        
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Anschrift :</Text>
                </View>
                <View style={styles.valueField}>
                    <Text>
                        {customer.address}
                    </Text>
                </View>
            </View>
        
              
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Fahrzeugkennzeichen :</Text>
                </View>
                <View style={styles.valueField}>
                    <Text>
                        {vehicle.licensePlate}
                    </Text>
                </View>
            </View>
        
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Fahrzeugtyp :</Text>
                </View>
                <View style={styles.valueField}>
                    <Text>
                        { vehicle.model }
                    </Text>
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Annahmetag :</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={damage.vehicleReceivedAt} />
                </View>
            </View>   
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Besichtigung durch Sachverständigen am:</Text>
                </View>
                <View style={styles.valueField}>                
                    <PDFDate date={inspectedAt} />
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Eingang Sachverständigengutachten am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={appraisalReceivedAt} />
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Reparaturbeginn am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={damage.repairStartedAt} />
                </View>
            </View>

            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Ersatzteilbestellung am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={repairSchedule.sparePartsOrderedAt} />
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Ersatzteillieferung am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={repairSchedule.sparePartsReceivedAt} />
                </View>
            </View>
  
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Fahrzeugverbringung (falls zutreffend):</Text>
                </View>
                <View style={styles.valueField}/>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>a) an Karosseriebetrieb am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={repairSchedule.shippedToBodyShopAt}/>
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>b) an Lackiererei am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={repairSchedule.shippedToPaintShopAt}/>
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Rückgabe am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={damage.vehicleReturnedAt}/>
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Reparaturabschlussarbeiten begonnen am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={damage.finalRepairStartedAt} /> 
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Fertigstellung am:</Text>
                </View>
                <View style={styles.valueField}>
                    <PDFDate date={damage.repairCompletedAt} /> 
                </View>
            </View>
        
            <View style={styles.row}>
                <View style={styles.labelField}>
                    <Text>Bemerkungen (z.B. Verzögerungen):</Text>
                </View>
                <View style={styles.valueTextArea}>
                    <Text>
                        {repairSchedule.notes}
                    </Text>
                </View>
            </View>



            <View style={{height: '32px'}} />
        
            <View style={{ 
                fontSize: 8, 
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10
            }}>
                <View style={{flex:1}}>
                </View>
                <View style={{
                    flex:1, 
                    flexDirection: 'row', 
                    justifyContent: 'space-between',
                    paddingLeft: '32px'
                    
                }}>
                    <Text>Sachbearbeiter Autohaus:</Text>
                    <Text>{assignee.name}</Text>                  
                </View>
                
            </View>
           

            <View style={{flexDirection: 'row'}}>        
                <View style={{flex:1}}>
                    <View style={{ ...styles.signatureField, paddingTop: '44px' }} >
                        <Text style={
                            {
                                flex: 3,
                                justifyContent: 'flex-end',
                                fontStyle: 'italic',
                                fontSize: 7,
                            }
                        }>
                            {values?.currentBranch?.address?.locality ?? ''}, den <DateComponent date={new Date()} />
                        </Text>
                    </View>
                    <View style={styles.signatureField.label}>
                        <Text >Ort, Datum</Text>
                    </View>
                </View>{/*signatureField*/}      

                <View style={{width: '32px'}}/>          
                <View style={{flex:1}}>
                    <View style={[styles.signatureField, {paddingLeft: '16px', paddingTop: '10px'}]}>
                        {
                            exists(signatures[0]) && <Image
                                style={ {height: '50px', width: '100px'} }
                                source={ {uri: signatures[0]} }
                            />
                        } 
                    </View>
                    <View style={styles.signatureField.label}>
                        <Text >Unterschrift</Text>
                    </View>
                </View>{/*signatureField*/}       
            </View>{/*signatureRow*/}
        
            <View style={{marginTop: 40}}>
                <View style={{fontSize: 6}}>
                    {
                        currentBranch?.name 
                        && <Text>{currentBranch?.name }</Text>
                    }
                    {
                        currentBranch?.address?.line1 
                        && <Text>{currentBranch?.address?.line1}</Text>
                    }
                    {
                        currentBranch?.address?.line2 
                        && <Text>{currentBranch?.address?.line2}</Text>
                    }
                    <Text>{currentBranch?.address?.postalCode  ?? ''} {currentBranch?.address?.locality  ?? ''}</Text>
                </View>
                <View style={{fontSize: 6,flexDirection:'row'}}>
                    {
                        currentBranch?.contact?.phoneNumber 
                        && <Text style={{marginRight:10}}>
                            {currentBranch?.contact?.phoneNumber}
                        </Text> 
                    }
                    {
                        currentBranch?.contact?.email 
                        &&  <Text>{currentBranch?.contact?.email} </Text>
                    }               
                </View>
            </View>
        
        </Page>
    </Document>
}