import React, { useCallback, useEffect, useState } from 'react'
import { PushBottom16, PushBottom8, Spacer, SubPageLayout } from 'application/components/pages/_common'
import { ContractorListElement } from './contractor_list_element'
import { FlexBox } from 'application/components/fragments/flex_box'
import { BranchSelect } from 'application/components/controls/form/branch_select'
import { useStatefulForm } from 'application/components/hooks'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { ActionButton } from 'application/components/controls/action_button'
import { useContractors } from 'application/components/hooks/use_contractors'
import { useReduxStoreLookUp } from 'application/components/hooks/use_redux_store_lookup'
import { getBranchContractorIds } from 'application/redux/selectors'
import { Headline3, Paragraph } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'

export const AdministrationSettingsContractors = () => {
    const {t} = useTranslation()
    const [selectedContractorIds, setSelectedContractorIds] = useState([])
    
    const {
        lookUp
    } = useReduxStoreLookUp()

    const {
        contractorsLoaded,
        branchContractorsLoaded,
        accessibleBranches,
        accessibleBranchesLoaded,
        onSaveBranchContractors,
        groupedContractors,
        contractorsList
    } = useContractors()

    const {
        updateFormState,
        register,
        setValue,
        getValues
    } = useStatefulForm()

    const handleOnContractorChange = (contractorId) => {
        const isChecked = selectedContractorIds.includes(contractorId)
        setSelectedContractorIds(
            !isChecked 
                ? [...selectedContractorIds, contractorId]
                : selectedContractorIds.filter(cid => cid !== contractorId)
        )
    }

    const handleOnBranchChange = useCallback((branchId) => {
        const contractorIds = lookUp(getBranchContractorIds(branchId))
        setSelectedContractorIds(contractorIds)   
    }, [lookUp])

    useEffect(() => {
        if(accessibleBranchesLoaded){
            handleOnBranchChange(accessibleBranches[0].id)
        }
    }, [accessibleBranchesLoaded, accessibleBranches, handleOnBranchChange])

    const handleOnSaveBranchContractors = () => {
        onSaveBranchContractors(getValues().branchId, selectedContractorIds)
    }

    if(!accessibleBranchesLoaded || !branchContractorsLoaded || !contractorsLoaded){
        return <ThemedPreloader />
    }

    return <SubPageLayout>
        <FlexBox
            flexDirection='column'
            cssRules='max-width: 600px;'
        >
            {
                contractorsLoaded && contractorsList.length === 0 
                    ? <React.Fragment>
                        <PushBottom16 />
                        <InfoBox
                            type={INFOBOX.TYPES.WARNING} 
                            cssMargin='0 0 16px' 
                            cssPadding='8px 16px 8px 8px'
                            cssMinHeight='unset'
                            direction='row'
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <Paragraph>{t('No contractors found')}</Paragraph>
                        </InfoBox>
                        
                    </React.Fragment>
                    : <React.Fragment>
                        <BranchSelect
                            onChange={(option) => {
                                updateFormState()
                                handleOnBranchChange(option.value)
                            }} 
                            register={register} 
                            setValue={setValue} 
                        />

                        <FlexBox
                            flexDirection='column'
                            cssRules='max-width: 600px;'
                        >
                            {
                                groupedContractors.map((group, groupIndex) => {
                                    return <React.Fragment key={groupIndex}>
                                        <PushBottom16 />
                                        <Headline3>{t(`contractorHeadline${group.type}`)}</Headline3>
                                        <PushBottom8 />
                                        {
                                            group.contractors.map(contractor => <ContractorListElement 
                                                key={contractor.id}
                                                contractor={contractor}
                                                isSelected={selectedContractorIds.includes(contractor.id)}
                                                onChange={handleOnContractorChange}
                                            />)
                                        }
                                    </React.Fragment>
                                })
                            }
                        </FlexBox>
                        <ActionButton 
                            buttonText='Speichern'
                            onButtonClick={handleOnSaveBranchContractors}
                        />
                    </React.Fragment>
            }
            
        </FlexBox>
        
    </SubPageLayout>
}