import { connect } from 'react-redux'
import {TaskOperationsWFPComponent} from './component'
import { changeTaskState } from 'application/redux/actions/pages/damage_manager/tasks'

const mapStateToProps = (state, props) => {
    return {
        currentUser: state.auth.user
    }
}

const mapDispatchToProps = dispatch => ({
    onTaskStateChange: (taskId, state) => dispatch(changeTaskState(null, taskId, state))
})

export const TaskOperationsWFP =  connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskOperationsWFPComponent)