import React, {useEffect} from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { requestCurrentAccountById } from 'application/redux/actions/auth'
import { ForLoggedInUser } from './container'
import { ROUTES } from 'application/constants'
import { useParams } from 'react-router-dom'

const mapStateToProps = state => ({
    currentAccount: state.auth.currentAccount,
    loggedIn: state.auth.loggedIn,
    sessionExpired: state.auth.sessionExpired
})
const mapDispatchToProps = dispatch => (
    { 
        onLoadAccount: (id) => {
            dispatch(
                requestCurrentAccountById(id)
            )
        } ,
    }
)


const withLoggedInUserComponent = Component => ({ ...props }) => {
    const {accountId} = useParams()
    const {onLoadAccount, sessionExpired} = props
    const shouldLoadAccount = accountId !== undefined 
    && (
        props.currentAccount === null 
        || props.currentAccount.id !== accountId
    )

    useEffect(()=>{
        !sessionExpired && shouldLoadAccount && onLoadAccount(accountId)
    },[accountId, onLoadAccount, sessionExpired, shouldLoadAccount])

    if(sessionExpired){
        return <Navigate to={ROUTES.SESSION_EXPIRED} />
    }

    return <ForLoggedInUser>
        <Component {...props} />
    </ForLoggedInUser>

}

export const withLoggedInUser = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    compose(
        withLoggedInUserComponent
    )
)