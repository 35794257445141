import { put, takeLeading } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { resetDocumentCenter, updateDocumentCenterAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { resetDocumentsByCategories } from  'application/redux/actions/documents_by_categories'
import { showToast } from 'application/redux/actions/notifications'
import { updateSelectedTaskDocumentCategory } from 'application/redux/actions/pages/damage_manager/tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'




function* setCategorySaga(action) {
    try {
        yield resourceCall(
            api.gateway.attachments.changeCategory, 
            {
                damageId: action.damageId,
                attachmentId: action.attachmentId
            },
            {
                id: action.category.id
            }
        )
        yield put( resetDocumentCenter() )
        yield put( resetDocumentsByCategories() )
        yield put (
            updateDocumentCenterAttachmentDetails(
                {
                    category: action.category
                }
            )
        )
        yield put(showToast('Category saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* setCategoryAndTaskSaga(action) {
    try {
        yield resourceCall(
            api.gateway.attachments.changeCategory, 
            {
                damageId: action.damageId,
                attachmentId: action.attachmentId
            },
            {
                id: action.category.id
            }
        )
        yield put(updateSelectedTaskDocumentCategory(action.attachmentId, action.category))
        yield put(resetDocumentCenter())
        yield put( resetDocumentsByCategories())
        yield put(showToast('Category saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* changeCategoryOfDocumentWatcher() {
    yield takeLeading(
        ACTIONS.COMMON_CHANGE_ATTACHMENT_CATEGORY, 
        setCategorySaga
    )
    yield takeLeading(
        ACTIONS.COMMON_CHANGE_ATTACHMENT_CATEGORY_FROM_TASK, 
        setCategoryAndTaskSaga
    )
}