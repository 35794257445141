import { connect } from 'react-redux'
import { DamageReportComponent } from './component'

const mapStateToProps = state => ({
    damage: state.pages.damageManager.shared.damage,
    damageLoaded: state.pages.damageManager.shared.damageLoaded
})

const mapDispatchToProps = dispatch => {
    return {

    }
}

export const DamageReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DamageReportComponent)