import { WIDGET_TYPES } from 'application/constants'
import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { put } from 'redux-saga/effects'




export function* refreshWorkflowPanelMessagesSaga() {
    yield put(
        refreshWidgetsByType(WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_LIST)
    )
}