import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    DASHBOARD_LAST_DAMAGES:'WIDGET_DASHBOARD_LAST_DAMAGES',
    DASHBOARD_LAST_REPAIR_AUTHORIZATIONS: 'WIDGET_DASHBOARD_LAST_REPAIR_AUTHORIZATIONS'
}

export const layoutDashboard= {
    LARGE: [
        {i: KEYS.DASHBOARD_LAST_DAMAGES, x: 0, y: 0, w: 4, h: 2},
        {i: KEYS.DASHBOARD_LAST_REPAIR_AUTHORIZATIONS, x: 0, y: 2, w: 4, h: 2}
    ]
}

export const widgetsDashboard = [
    {
        key: KEYS.DASHBOARD_LAST_DAMAGES,
        type: WIDGET_TYPES.LAST_DAMAGES,
        title: 'last damages',
        hideHeadline: true,
        accountDependent: true,
        overflow: 'visible'
    },
    {
        key: KEYS.DASHBOARD_LAST_REPAIR_AUTHORIZATIONS,
        type: WIDGET_TYPES.LAST_REPAIR_AUTHORIZATIONS,
        title: 'last repair authorization',
        hideHeadline: true,
        accountDependent: true,
        overflow: 'visible'
    }
]
