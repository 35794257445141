import { exists } from 'application/common/data_helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { DisabledText } from 'application/components/fragments/typography'
import { RightArrow } from 'application/components/fragments/typography'

const JobNumber = ({jobNumber}) => {
    const {t} = useTranslation()

    return !exists(jobNumber)
        ? <DisabledText 
            isSelected={true}
        >
            {t('noneSingular')}
        </DisabledText>
        : <React.Fragment>{jobNumber}</React.Fragment>
}

export const DamageJobNumberChangedActivity = ({activity}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Job number changed')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            <JobNumber jobNumber={activity.data.oldNumber} />
            <RightArrow />
            <JobNumber jobNumber={activity.data.newNumber} />
        </Fragments.ActivityParagraph>
    </React.Fragment>
}