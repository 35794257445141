import { DialogStepsLayoutComponent } from './component'
import { connect } from 'react-redux'


const mapStateToProps = (state, props) => {
    return {
        dialogStepsWaitingScreen: state?.dialogStepsWaitingScreen
    }
}

export const DialogStepsLayout = connect(
    mapStateToProps
)(DialogStepsLayoutComponent)





