import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteInvoiceComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteInvoice: (
            name, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    name, 
                    payload,
                    metaData
                )
            )
        },
    }
}

export const DeleteInvoice = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteInvoiceComponent)