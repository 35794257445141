import React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'application/components/fragments/typography'
import { CaseFileStatusName } from 'application/components/controls/casefile_status_name'
import { useTranslation } from 'react-i18next'


const CasefileUpdatedInfo = (
    {activityData}
) => {

    const {
        oldCaseFileInfo,
        newCaseFileInfo
    } = activityData

    const {t} = useTranslation()

    return <React.Fragment>
        {t('File number lawyer')}: {oldCaseFileInfo?.referenceId}<RightArrow />{newCaseFileInfo?.referenceId}
        <br/>

        {/* 
        {oldCaseFileInfo?.note}<RightArrow />{newCaseFileInfo?.note}
        <br/> */}
        {t('Casefile status')}: <CaseFileStatusName 
            caseFileStatusString = {oldCaseFileInfo?.caseFileStatus}
        />
        <RightArrow />
        <CaseFileStatusName 
            caseFileStatusString = {newCaseFileInfo?.caseFileStatus}
        />        
    </React.Fragment>
}

CasefileUpdatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CasefileUpdatedInfo} 