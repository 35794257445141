import { connect } from 'react-redux'
import {DamageManagerDocumentsSidebarComponent} from './component'
import { hideAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { changeAttachmentCategory } from 'application/redux/actions/common'



const mapStateToProps = (state) => {
    const damageId = state.pages.damageManager.documentCenter.damageId
    return {
        documentCenterAttachmentDetails: state.pages.damageManager.documentCenter.attachmentDetails,
        damageId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onHideAttachmentDetails: () => dispatch(
            hideAttachmentDetails()
        ),
        onChangeCategory: (
            damageId, 
            attachmentId, 
            category
        ) => dispatch(
            changeAttachmentCategory(
                damageId, 
                attachmentId, 
                category
            )
        )
    }
}

export const DamageManagerDocumentsSidebar =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDocumentsSidebarComponent)