import { iconMapper } from 'application/common/damage_status/icon_mapper'
import { useEffect, useState, useCallback } from 'react'
import { getVisibleNodeCount } from 'react-sortable-tree'

const mapCustomStateToNode = (customState, canBeDeleted = true) => {
    return {
        title: customState.name,
        iconName: iconMapper(customState.iconName),
        id: customState.id,
        expanded: true,
        canBeDeleted,
        canHaveChildren: true,
        canBeChild: true,
        children: null
    }
}

const customStateCanBeDeleted = (customStates = []) => {
    if(customStates.length === 0){
        return false
    }

    const lifeCycleState = customStates[0].lifeCycleState

    if(lifeCycleState !== 'New' && lifeCycleState !== 'Done'){
        return true
    }

    if(customStates.length > 1){
        return true
    }

    return false
}

const treeStructureMapper = customStates => {
    return {
        treeData: customStates.map(c => {
            const canBeDeleted = customStateCanBeDeleted(customStates)
            return mapCustomStateToNode(c, canBeDeleted)
        })
    }
}



export const useCustomStateTree = (
    customStates = [],
    nodeHeight = 52
) => {
    const [tree, setTree] = useState(treeStructureMapper([]))
    const [treeLoaded, setTreeLoaded] = useState(false)
    const [height, setHeight] = useState(0)

    const setCustomStateTree = useCallback(treeData => {
        setTree(treeData)
        setHeight(getVisibleNodeCount(treeData) * nodeHeight)
    }, [nodeHeight])

    const onChange = treeData => {
        setCustomStateTree({treeData})
    }

    useEffect(()=>{
        if(!treeLoaded){
            setCustomStateTree(treeStructureMapper(customStates))
            setTreeLoaded(true)
        }
    }, [customStates, setCustomStateTree, treeLoaded])

    return {
        tree,
        height,
        treeLoaded,
        onChange
    }
}