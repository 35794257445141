import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { buildListQuery } from 'application/api/helpers'
import { apiCall } from 'application/api/helpers'


export const getAttachmentsByDamageId = (params) => {
    const {
        offset = 0, 
        limit = 10, 
        orderBy= 'createdAt',
        direction = 'desc',
        filterQuery = []
    } = params

    const queryString = buildListQuery(
        offset, 
        limit,  
        orderBy,
        direction,
        filterQuery
    )

    return  {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/documents${queryString}`, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents${queryString}`, 
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
}