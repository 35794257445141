import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { exists } from 'application/common/data_helpers'

export const Headline1 = styled.span`
  color: ${props => props.theme.color.anthracite};
  font-size: ${props => props.theme.fontSize.headline1};
  font-weight: ${props => props.theme.fontWeight.headline1};
`

export const Headline2 = styled.span`
  color: ${props => props.theme.color.anthracite};
  font-size: ${props => props.theme.fontSize.headline2};
  font-weight: ${props => props.theme.fontWeight.headline2};
  margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '0'};
`

export const Headline3 = styled.h3`
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.standardBold};
  margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '6px 0'};
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent !== undefined ? props.justifyContent : 'space-between'};
  width: ${props => props.cssWidth !== undefined ? props.cssWidth : '100%'};;
  z-index: ${props => props.zIndex !== undefined ? props.zIndex : 'unset'};
`

export const FormHeadline = styled.h3`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    margin: 12px 0;
`

export const Paragraph = styled.div`
  padding: ${props => props.cssPadding !== undefined ? props.cssPadding : '0'};
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standard};
  line-height: ${props => props.theme.fontSize.headline3};
  color: ${props => props.fontColor !== undefined ? props.fontColor : props.theme.color.anthracite};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
  margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '0 0 8px'};
  width: 100%;
  white-space: ${props => exists(props.$whiteSpace) ? props.$whiteSpace : 'normal'} ;
`

export const Label = styled.div`
  padding: ${props => props.cssPadding !== undefined ? props.cssPadding : '0'};
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standard};
  line-height: ${props => props.theme.fontSize.headline3};
  color: ${props => props.fontColor !== undefined ? props.fontColor : props.theme.color.anthracite};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
  margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '0 0 8px'};
  flex: 1 0 auto;
`

export const ParagraphSmall = styled.div`
  padding: ${props => props.cssPadding !== undefined ? props.cssPadding : '0'};
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.standard};
  line-height: ${props => props.theme.fontSize.headline3};
  color: ${props => props.fontColor !== undefined ? props.fontColor : props.theme.color.anthracite};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
  margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '0 0 8px'};
  width: 100%;
`

export const ParagraphTitle = styled.p`
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standardBold};
  margin: 0 0 16px;
`

export const SubParagraph = styled.div`
  padding: ${props => props.cssPadding !== undefined ? props.cssPadding : '0'};
  font-size: ${props => props.theme.fontSize.xSmall};
  font-weight: ${props => props.theme.fontWeight.standard};
  line-height: ${props => props.theme.fontSize.medium};
  color: ${props => props.fontColor !== undefined ? props.fontColor : props.theme.color.anthracite};
  text-align: ${props => props.cssAlign !== undefined ? props.cssAlign : 'left'};
  margin: ${props => props.cssMargin !== undefined ? props.cssMargin : '0 0 8px'};
  width: 100%;
`

export const ButtonDescription = styled.p`
  font-size: ${props => props.theme.fontSize.standard};
  font-weight: ${props => props.theme.fontWeight.standard};
  color: ${props => props.theme.color.anthracite50};
  margin: 0 4px;
`

export const LinkButton = styled.span`
  margin: 0;
  color: ${props => props.isActive ? props.theme.color.primary : props.theme.color.anthracite};
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  text-decoration: underline;
  font-size: inherit;
  &:before {
    content: "» ";
    display: inline;
  }
`

export const TextButton = styled.span`
  font-size: ${props => props.$cssFontSize ? props.$cssFontSize : 'inherit'};
  font-weight: ${props => props.theme.fontWeight.standardRegular};
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
`

export const TextLink = styled.a`
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.$color ? props.$color : props.theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & > i {
    color: ${props => props.$color ? props.$color : props.theme.color.primary};
  }
  &:visited, &:hover {
    color: ${props => props.$color ? props.$color : props.theme.color.primary};
  }
`

export const TextNavLink = styled(NavLink)`
  font-size: ${props => props.$cssFontSize !== undefined ? props.$cssFontSize : 'inherit'};
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & > i {
    color: ${props => props.theme.color.primary};
  }
  &:visited, &:hover {
    color: ${props => props.theme.color.primary};
  }
`

export const TextIcon = styled.span`
  position: relative;
  top: 3px;
`

export const DisabledText = styled.span`
  color: ${ props => 
        props.isSelected 
            ? props.theme.color.powderGray 
            : props.theme.color.gray20 };

  font-size: inherit;
`

export const FooterLabel = styled.span`
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.standard};
  line-height: ${props => props.theme.fontSize.headline3};
  color: ${props => props.fontColor !== undefined ? props.fontColor : props.theme.color.anthracite};
  margin-right: 4px;
`

export const RightArrow = () => {    
    return <React.Fragment>&nbsp;&rarr;&nbsp;</React.Fragment> 
}