import { isDefined } from 'application/common/data_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledActivityLink } from '../link'
import PropTypes  from 'prop-types'





export const TaskDamageLink = (
    {activity}
) => {
    const {t} = useTranslation()
    const {damageManagerLink} = useApplicationLinks()
    const damageId = activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    if( !isDefined(damageId) ){
        return null
    }

    const damageUrl = damageManagerLink(
        ROUTES.DAMAGE_MANAGER, 
        damageId
    ) 

    const damageUrlToTaskSection = damageUrl + '/tasks'

    return <StyledActivityLink
        to={damageUrlToTaskSection}
    >
        &raquo; {t('View damage')}
    </StyledActivityLink>
}


TaskDamageLink.propTypes = {
    activity: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}