import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { validationMethods } from 'application/common'
import styled from 'styled-components'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'
import { FormElementWrapper } from '../_common'
import { isDefined } from 'application/common/data_helpers'




const StyledCurrencyValueWrapper = styled.div`
    position: relative;
`

const StyledCurrencyValue = styled.div`
        position: absolute;
        top: -27px;
        font-size: ${props => props.theme.fontSize.standard};
        right: 16px;
        color:${props => props.theme.color.anthracite};
`

const StyledCurrencyInput = styled.input`

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;

    color: ${props => props.theme.color.anthracite};
    border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
    min-height: 38px;
    padding-left: 8px;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    padding-right: 28px;

    &:hover {
        border: 1px solid ${props => props.disabled ?  props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red   : props.theme.color.lightPowderGray};
    }

    &:active {
        border: 1px solid ${props => props.disabled ? props.theme.color.gray15   : props => props.theme.color.anthracite};
    }

    &:disabled, &:read-only {
        background-color: ${props => props.theme.color.gray5};
        color: ${props => props.theme.color.anthracite50};
        &:hover{
            border: 1px solid ${props => props.theme.color.gray15};
        }
    }

    &:focus{
        outline: none;
    }

    &:invalid{
        border: 1px solid ${props => props.hasPattern ? props.theme.color.red : props.theme.color.gray15};
    }
`



export const CurrencyInput = (
    { 
        name, 
        register, 
        error, 
        label = '',
        couldBeZero = false,
        hideValidationMessage = false,
        errorMessageOverride = ''
    }
) => {
    const [currentValue, setCurrentValue] = useState(undefined)


    const baseValidation = {
        notEmpty: validationMethods.notEmpty, 
    }

    const validationRules = couldBeZero 
        ? baseValidation
        : {
            ...baseValidation,
            currencyMustBeNotZero: validationMethods.currencyMustBeNotZero,
        }

    function checkValue(value) {
        setCurrentValue(
            handleDecimalsOnValue(value)
        )
        return currentValue
    }
        
    function handleDecimalsOnValue(value) {
        const regex = /([0-9]*[.|,]{0,1}[0-9]{0,2})/s
        return value.match(regex)[0]
    }

    return <FormElementWrapper>
        <label className='label'>
            {label}
            <RequiredFieldMarker 
                validate={validationRules}
            />
        </label>
        <StyledCurrencyInput 
            type="number"
            value={currentValue}
            {
                ...register(
                    name,
                    { 
                        validate: validationRules,
                        setValueAs: value => isDefined(value) 
                            ? checkValue(value) 
                            : '0.00'
                    }
                )
            } 
        />  
        <StyledCurrencyValueWrapper>
            <StyledCurrencyValue>€</StyledCurrencyValue>
        </StyledCurrencyValueWrapper>
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </FormElementWrapper>
}

CurrencyInput.defaultProps = {
    label: '',
    couldBeZero: false,
    hideValidationMessage: false,
    errorMessageOverride: ''
}

CurrencyInput.propTypes = {
    name: PropTypes.string.isRequired, 
    register: PropTypes.func.isRequired, 
    error: PropTypes.object, 
    label: PropTypes.string,
    couldBeZero: PropTypes.bool,
    hideValidationMessage: PropTypes.bool,
    errorMessageOverride: PropTypes.string
}
