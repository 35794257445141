import React from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCreateDamageKickstartSteps } from './with_create_damage_kickstart_steps'
import { compose } from 'redux'
import { KickstartStep0 } from './step_0'
import { KickstartStep1 } from './step_1'
import { KickstartStep2 } from './step_2'
import { KickstartStep3 } from './step_3'
import { notNull } from 'application/common/data_helpers'
import { DialogStepsLayout } from '../../_common/steps_layout'
import { useState } from 'react'
import { useDamageCreationCustomer } from '../_common/use_damage_creation_customer'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'



const CreateDamageKickstartComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        dialog,
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
        changeSubLabel
    }
) => {
    const { 
        customer = null
    } = dialog.payload

    const {
        getSetting
    } = useUiSettings()

    const {
        customerData,
        setCustomerData,
        applyPreferredVehicleData,
        setApplyPreferredVehicleData,
        resetCustomerData,
        existingCustomerWasChanged,
        setExistingCustomerWasChanged
    } = useDamageCreationCustomer(customer)

    const [insuranceSelectionFromList, setInsuranceSelectionFromList] = useState(true)
    const [tempDamageData, setTempDamageData] = useState({})
    const [tempCommissionData, setTempCommissionData] = useState({})
    const [commissionExpert, setCommissionExpert] = useState(false)
    const [commissionLawfirm, setCommissionLawfirm] = useState(false)
    const [existingCustomer, setExistingCustomer] = useState(getSetting(UI_SETTINGS.ACCOUNT.PREFER_EXISTING_CUSTOMER_ON_DAMAGE_CREATION))
    const [damageFiles,setDamageFiles] = useState([])
    const [commissionOtherFiles, setCommissionOtherFiles] = useState([])
    const [lawfirmFiles, setLawfirmFiles] = useState([])

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    return <React.Fragment>
        <DialogStepsLayout cssMaxWidth='400px'  getStepsState={getStepsState}>
            {
                getCurrentStep().id === 0 && <KickstartStep0
                    abort={abort}
                    onDataChange={onDataChange}
                    goToNextStep={goToNextStep}
                    dialog={dialog}
                    setCustomerData={setCustomerData}
                    resetCustomerData={resetCustomerData}
                    customerData={customerData}
                    changeSubLabel={changeSubLabel}
                    currentStepIndex = {getCurrentStep().id}
                    applyPreferredVehicleData={applyPreferredVehicleData}
                    setApplyPreferredVehicleData={setApplyPreferredVehicleData}
                    existingCustomerWasChanged={existingCustomerWasChanged}
                    setExistingCustomerWasChanged={setExistingCustomerWasChanged}
                    existingCustomer={existingCustomer}
                    setExistingCustomer={setExistingCustomer}
                />
            }
            {
                getCurrentStep().id === 1 && <KickstartStep1
                    abort={abort}
                    getStepsState={getStepsState} 
                    goToNextStep={goToNextStep}
                    dialog={dialog}
                    insuranceSelectionFromList={insuranceSelectionFromList}
                    setInsuranceSelectionFromList={setInsuranceSelectionFromList}
                    setTempDamageData={setTempDamageData}
                    tempDamageData={tempDamageData}
                    customerData={customerData}
                    applyPreferredVehicleData={applyPreferredVehicleData}
                />
            }
            {
                notNull(tempDamageData) && <React.Fragment>
                    {
                        getCurrentStep().id === 2 && <KickstartStep2
                            abort={abort}
                            goToPreviousStep={goToPreviousStep}
                            onDataChange={onDataChange}
                            customer={customerData}
                            dialogName={dialog.name}
                            dialog={dialog}
                            commissionExpert={commissionExpert}
                            setCommissionExpert={setCommissionExpert}
                            commissionLawfirm={commissionLawfirm}
                            setCommissionLawfirm={setCommissionLawfirm}
                            tempCommissionData={tempCommissionData}
                            setTempCommissionData={setTempCommissionData}
                            goToNextStep={goToNextStep}

                            damage={tempDamageData}
                            setCommissionOtherFiles={setCommissionOtherFiles}
                            commissionOtherFiles={commissionOtherFiles}
                            setLawfirmFiles={setLawfirmFiles}
                            lawfirmFiles={lawfirmFiles}
                        />
                    }
                </React.Fragment>
            }
            {
                notNull(tempDamageData) && <React.Fragment>
                    {
                        getCurrentStep().id === 3 && <KickstartStep3
                            abort={abort}
                            goToPreviousStep={goToPreviousStep}
                            onDataChange={onDataChange}
                            customer={customer}
                            dialogName={dialog.name}
                            dialog={dialog}
                            commissionExpert={commissionExpert}
                            setCommissionExpert={setCommissionExpert}
                            commissionLawfirm={commissionLawfirm}
                            setCommissionLawfirm={setCommissionLawfirm}
                            tempDamageData={tempDamageData}
                            tempCommissionData={tempCommissionData}
                            customerData={customerData}
                            commissionOtherFiles={commissionOtherFiles}
                            lawfirmFiles={lawfirmFiles}
                            damageFiles={damageFiles}
                            setDamageFiles={setDamageFiles}
                        />
                    }                   
                </React.Fragment>
            }
        </DialogStepsLayout>
    </React.Fragment>
}


export const CreateDamageKickstart = compose(
    withCreateDamageKickstartSteps(),
    withDialogDataLoader
) (CreateDamageKickstartComponent)