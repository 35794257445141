import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { call, takeLatest, put } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { populateSharedResource, populateTokenList } from 'application/redux/actions/shared_resource'


function* fetchSharedResourceSaga(action) {
    try {

        const tokensResponse = yield call(
            api.gateway.sharedResource.getToken,
            action.sharedResourceId
        )
        yield put(
            populateTokenList(tokensResponse.proxyResources)
        )
        const result = yield call(
            api.gateway.sharedResource.getSharedResourceById,
            action.sharedResourceId,
        )
        yield put(
            populateSharedResource(result)
        )
    } catch (e) {
        yield put( 
            populateSharedResource(null) 
        )
        yield handleError(e, action)
    }
}

export function* fetchSharedResourceWatcher() {
    yield takeLatest(
        ACTIONS.SHARED_RESOURCE_GET_SHARED_RESOURCE_BY_ID, 
        fetchSharedResourceSaga
    )
}