import React, {useState} from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import PropTypes from 'prop-types'
import { MoDialogFooter } from 'application/components/dialogs/mass_operations/_common/footer'
import { removeItemFromListById } from 'application/common/list_helpers'
import { PushBottom16 } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'
import { MoDocumentListElement } from '../_common/list_element'

const MoDocsIsExportedToLawfirmComponent = ({
    abort, 
    onSubmit,
    dialog
}) => {
    const { t } = useTranslation()

    const { 
        damageId, 
        moName,
        selectedItems,
    } = dialog.payload

    const [selectedItemsByDialog, setSelectedItemsByDialog] = useState(selectedItems.filter(f => !f.isExportedToLawFirm))
    const [alreadyExportedItems] = useState(selectedItems.filter(f => f.isExportedToLawFirm))

    const handleRemoveItem = (    
        documentId 
    ) =>  {
        const listWithoutItem = removeItemFromListById(selectedItemsByDialog,documentId)
        setSelectedItemsByDialog(listWithoutItem)
    }


    const handleSubmit = () => {
        const payload = {
            selectedDocuments: selectedItemsByDialog
        }

        const metaData = {
            damageId,
            moName
        }

        onSubmit(
            dialog.name,
            payload,
            metaData
        )
    }

    return  <React.Fragment>
        <DialogMainContent cssHeight='400px'>
            <Paragraph>
                {t('These documents will be made available for legal software')}
            </Paragraph>
            <Paragraph>
                {t('If the lawyer communication has already been established, they will be sent immediately.')}
            </Paragraph>
            <PushBottom16/>
            <FlexBox 
                flexDirection='column'
                paddingLeft='16px'
                paddingRight='16px'
            >
                
                <Paragraph />
                {
                    selectedItemsByDialog.map(
                        item => {
                            return <MoDocumentListElement
                                key={item.id}
                                document={item}
                                removeItem={handleRemoveItem}
                                moName={moName}
                            />
                        }
                    )           
                }
                <Paragraph />
            </FlexBox>  

            {
                alreadyExportedItems.length > 0 && <React.Fragment>
                    <PushBottom16/>
                    <Paragraph>
                        {t('These documents are already made available and will be ignored')}:
                    </Paragraph>
                    <PushBottom16/>
                    <FlexBox 
                        flexDirection='column'
                        paddingLeft='16px'
                        paddingRight='16px'
                    >
                
                        <Paragraph />
                        {
                            alreadyExportedItems.map(
                                item => {
                                    return <MoDocumentListElement
                                        key={item.id}
                                        document={item}
                                        moName={moName}
                                        hideRemoveButton={true}
                                    />
                                }
                            )           
                        }
                        <Paragraph />
                    </FlexBox>  
                </React.Fragment>
            }

        </DialogMainContent>
        <MoDialogFooter 
            onCloseDialog={abort} 
            onSubmit={handleSubmit}
            submitButtonText={ t('Make available') }
            submitDisabled={selectedItemsByDialog.length === 0}
        />   
    </React.Fragment>
}

MoDocsIsExportedToLawfirmComponent.propTypes = {
    abort: PropTypes.func.isRequired,
    dialog: PropTypes.object.isRequired
}

export {MoDocsIsExportedToLawfirmComponent}