import React, { useState } from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DASHBOARD_CONFIGURATION, DIALOG } from 'application/constants'
import { useTranslation } from 'react-i18next'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'

export const StyledDashboardConfigurationOption = styled.div`
    flex: 0 0 26px;
    height: 26px;
    margin-right: 4px;
    background-color: ${props => props.isActive ? props.theme.color.primaryBackground : props.theme.color.gray5};
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        background-color: ${props => props.theme.color.gray15};   
    }

    & img{
        width: 20px !important;
        height: 20px !important;
    }
`


export const ConfigureDashboardComponent = ({settingKey}) => {
    const {t} = useTranslation()
    const {
        getSetting,
        updateUserSettings
    } = useUiSettings()
    const [dashboardConfiguration, setDashboardConfiguration] = useState(getSetting(settingKey))


    const handleChange = (value) => {
        setDashboardConfiguration(value)
        updateUserSettings(settingKey, value, true)
    }

    return <FlexBox flexDirection='row' flexShrink='1' width='auto' cssRules='margin-left: 16px; gap: 4px;'>
        <StyledDashboardConfigurationOption 
            isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES} 
            onClick={()=>{handleChange(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES)}}
            title={t('Damages & Releases')}
        >
            <FontIcon 
                icon='local_car_wash' 
                isClickable={true}
                fontSize='16px'
            />
        </StyledDashboardConfigurationOption>
        <StyledDashboardConfigurationOption 
            isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING} 
            onClick={()=>{handleChange(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING)}}
            title={t('Controlling & Statistics')}
        >
            <FontIcon 
                icon='bar_chart' 
                isClickable={true}
                fontSize='16px'
            />
        </StyledDashboardConfigurationOption>
    </FlexBox>
} 




const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => {
    return {
        onChangeConfiguration: (dashboardConfiguration) => {
            dispatch(sendPayloadRequest(DIALOG.NAME.CONFIGURE_DASHBOARD, { dashboardConfiguration }))
        },
    }
}


export const DashboardConfigurator = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureDashboardComponent)