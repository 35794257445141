export const toDecimal = currencyString => {
    if(currencyString === undefined || currencyString === null || typeof currencyString === 'number' ){
        return currencyString
    }

    if(typeof currencyString !== 'string'){
        return null
    }

    const numericCurrencyString = currencyString.endsWith(' €') //remove € sign if present
        ? currencyString.slice(0, -2) 
        : currencyString

    return Number(numericCurrencyString
        .split('.') //remove 1000 separator
        .join('')
        .replace(',', '.') //english decimal
    )
}

export const FormatCurrencyDE = (number) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number)
}

export const currencyToDecimalString = currencyString => {
    if(
        currencyString === undefined 
        || currencyString === null 
        || typeof currencyString === 'number'
    ){
        return currencyString
    }

    if(typeof currencyString === 'string' && !isNaN(currencyString)){
        return currencyString
    }
    return Number(
        (currencyString).substring(
            0, 
            currencyString.length - 2).split('.').join('').replace(/,/g, '.')).toString()
}

export const toCurrencyString = currencyValue => {
    if(typeof currencyValue === 'string' && !isNaN(currencyValue)) {
        const toNumber = parseFloat(currencyValue)
        return toNumber.toLocaleString('de-DE', {'minimumFractionDigits':2,'maximumFractionDigits':2})
    }

    if(currencyValue === undefined || currencyValue === null){
        return currencyValue
    }
    return FormatCurrencyDE(currencyValue)
}

export const MoneySubstraction = (amount1, amount2) => {
    amount1 = Number(amount1)
    amount2 = Number(amount2)
    const result = (amount1 - amount2).toFixed(2)
    return result
}
export const MoneyAddition = (amount1, amount2) => {
    amount1 = Number(amount1)
    amount2 = Number(amount2)
    const result = (amount1 + amount2).toFixed(2)
    return result
}



export const SubstractAndFormat = (amount1, amount2) => {
    const result = MoneySubstraction(amount1, amount2)
    const convertedCurrencyResult = FormatCurrencyDE(result)

    return convertedCurrencyResult
}