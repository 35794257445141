import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT: 'WIDGET_BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT',
}

export const layoutBranchSpecificControllingStatistics= {
    LARGE: [
        {i: KEYS.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT, x: 0, y: 0, w: 6, h: 3}
    ]
}

export const widgetsBranchSpecificControllingStatistics = [
    {
        key: KEYS.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT,
        title: 'Statistics',
        isSecondary: false,
        hideBorder: true,
        isBlank: true,
        hideHeadline: true,
        routeParameters: ROUTE_PARAMETERS.WIDGET_CONTROLLING_STATISTICS_DAMAGES_COUNT.FILTERS,
        accountDependent: true
    }
]
