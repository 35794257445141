import React from 'react'
import PropTypes from 'prop-types'
import {
    StyledCheckbox,
    StyledIcon
} from './fragments'

export const PureCheckbox = ({
    checked,
    isSmall,
    activeColor,
}) => (
    <StyledCheckbox
        checked={checked}
        isSmall={isSmall}
        cursor={'pointer'}
    >
        <StyledIcon 
            checked={checked} 
            className="material-icons"
            isSmall={isSmall}
            activeColor={activeColor}
        >
            done
        </StyledIcon>
    </StyledCheckbox>
)

PureCheckbox.propTypes = {
    checked: PropTypes.bool,
    isSmall: PropTypes.bool,
    activeColor: PropTypes.bool
}