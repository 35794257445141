import { connect } from 'react-redux'
import { SelectCategoryComponent } from './component'

const mapStateToProps = state => ({
    categories: state.common.categories,
    categoriesLoaded: state.common.categoriesLoaded
})

export const SelectCategory = connect(
    mapStateToProps
)(SelectCategoryComponent)
