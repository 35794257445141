import React, {useState} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { TextInput, Select, DatePicker, HiddenField } from 'application/components/controls/form'
import { DamageTypeSelect } from 'application/components/controls/form/damage_type_select'
import { nameHelpers } from 'application/common'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { TwoColumns } from 'application/components/forms/_common'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { DIALOG, DAMAGE_TYPE } from 'application/constants'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { TextButton } from 'application/components/fragments/typography'
import { exists } from 'application/common/data_helpers'

export const CreateDamageStepComponent = (
    { 
        //from component
        dialog,
        onDataChange,
        goToNextStep,
        goToPreviousStep,
        customerData,
        changeSubLabel,
        currentStepIndex,
        setExistingCustomer,
        //from container
        onCreateDamage, 
        onSearchUser,
        currentBranch,
        onOpenChangeBranchDialog,
        applyPreferredVehicleData
    }
) => {
    const {
        usersList = [], 
        usersListLoading = false
    } = dialog.payload

    const defaultValues = {
        type: DAMAGE_TYPE.UNKNOWN,
        vehicle: (applyPreferredVehicleData || !customerData.existingCustomer) && exists(customerData.values?.preferredVehicleInfo) 
            ? {...customerData.values.preferredVehicleInfo}
            : {},
    }

    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls    
    const {t} = useTranslation()

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        formStateValues, 
        updateFormState,
        trigger
    } = useStatefulForm({ defaultValues })  

    const setForm = () => {
        onDataChange()
        updateFormState()
    }


    const createDamageAndContinue = (dialogName, payload) => {
        onCreateDamage(
            dialogName,
            payload
        )
        goToNextStep()
    }
    
    const createDamageAndCloseDialog = (payload) => {
        onCreateDamage(
            DIALOG.NAME.CREATE_DAMAGE_AND_CLOSE_DIALOG,
            payload
        )
    }

    const onSubmitSuccess = (continueWithNextStep) => {
        changeSubLabel(getValues().vehicle?.licensePlate, currentStepIndex)
        const newDamageData = {
            ...formStateValues, //include all values
            ...getValues()
        }
        continueWithNextStep 
            ? createDamageAndContinue(
                dialog.name,
                {
                    newDamageData,
                    customerData
                }
            )
            : createDamageAndCloseDialog(
                {
                    newDamageData,
                    customerData
                }
            )
    }

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(dialog.name, search)
        }  
    }

    const usersOptions = usersList.map((u, i) => ({
        value: u.id,
        label: nameHelpers.getFullName(u),
        firstName: u.firstName,
        lastName: u.lastName
    }))


    const currentBranchName = currentBranch.name

    return <React.Fragment>
        <DialogMainContent >
            <PushBottom16/>
            <InfoBox 
                direction='row' 
                cssMargin='0' 
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <Trans i18nKey='This damage will be created in currentBranch'>
                    {{currentBranchName}} 
                </Trans>
                <Spacer />
                <TextButton  onClick={() => onOpenChangeBranchDialog(dialog.name)}>
                    &raquo;{t('Change branch')}
                </TextButton>
            </InfoBox>
            <PushBottom16/>

            <TwoColumns 
                columnLeft={ 
                    <React.Fragment>
                        <TextInput 
                            label={t('License plate')}
                            name='vehicle.licensePlate'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.vehicle?.licensePlate}
                            onChange={setForm}
                        />
                        <HiddenField 
                            name={'vehicle.make'}
                            register={register} 
                        />
                        <HiddenField 
                            name={'vehicle.model'}
                            register={register} 
                        />
                        <HiddenField 
                            name={'vehicle.chassisNumber'}
                            register={register} 
                        />
                    </React.Fragment>
                }
            />


            <SubHeadline>{t('Damage information')}</SubHeadline>
            <TwoColumns 
                columnLeft={
                    <TextInput 
                        label={t('Job number')}
                        name='jobNumber'
                        register={register}
                        validate={null}
                        error={errors.jobNumber}
                        onChange={setForm}
                    />
                }
            />

            <TwoColumns 
                columnLeft={  
                    <DamageTypeSelect 
                        register={register}
                        setValue={setValue}
                        error={errors.type}
                        onChange={setForm}
                        initialValue={formStateValues.type}
                    />
                }
                columnRight={
                    <DatePicker 
                        label={t('Damage date')}
                        name='incidentDate'
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.incidentDate}
                        onDateChange={setForm}
                        control={control}
                        disabledDays={{after: new Date()}}
                        register={register}
                        defaultValue={formStateValues.incidentDate}
                        setValue={setValue}
                        trigger={trigger}
                    />
                }
            />
            <TextInput 
                label={t('Damage description')}
                name='description'
                register={register}
                validate={null}
                error={errors.description}
                onChange={setForm}
            />


            <SubHeadline>{t('Assign damage')}</SubHeadline>
            <TwoColumns 
                columnLeft={
                    <Select 
                        label={t('Assign employee')}
                        name='assignee.userId'
                        register={register}
                        setValue={setValue}
                        options={usersOptions}
                        error={errors.assignee?.userId}
                        validate={null}
                        menuPlacement='top'
                        onInputChange={handleSearchUser}
                        onBlur={()=>{setUserSearchActive(false)}}
                        onFocus={()=>{setUserSearchActive(true)}}
                        noOptionsMessage={t('No users found')}
                        placeholder={t('Enter at least 3 characters')}
                        isLoading={usersListLoading}
                        onChange={setForm}
                    />
                }
            />
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={() => {
                    goToPreviousStep()
                    setExistingCustomer(false)
                    changeSubLabel('', 0)
                }} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Complete the damage creation')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(false))} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Create damage and continue')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(true))} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}