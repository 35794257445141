import React from 'react'
import {ActionButton} from 'application/components/controls/action_button'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'


const StyledTitlebarActionButton = styled.div`
    height: ${props => props.cssHeight};

    & .action-button-titlebar {
        ${ props => props.isFirst ? 'border-left:1px solid #ffffff;' : '' }
        border-right:1px solid #ffffff;
        height: ${props => props.cssHeight};
        max-height: none;
    }

    & i {
        margin-right: 8px;
    }

    & span {
        font-size: 12px;
        font-weight: bold;
    }
`

export const TitleBarActionButton = withTheme(
    (
        {
            onButtonClick,
            activeBackground = '',
            buttonText = '',
            strechted = false,
            disabled = false,
            secondary = true,
            icon = '',
            marginRight = '0',
            isFirst = false,
            theme,
            cssHeight='48px'
        }
    ) => {
        return <StyledTitlebarActionButton
            isFirst={isFirst}
            cssHeight={cssHeight}
        >
            <ActionButton
                onButtonClick={onButtonClick}
                hoverBackground={theme.color.black10} 
                activeBackground={activeBackground}
                buttonClassName={'action-button-titlebar'}
                buttonText={buttonText}
                strechted={strechted}
                disabled={disabled}
                secondary={secondary} 
                icon={icon}
                marginRight={marginRight} 
            />
        </StyledTitlebarActionButton>
    }
)

TitleBarActionButton.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    activeBackground: PropTypes.string,
    buttonText: PropTypes.string,
    strechted: PropTypes.bool,
    disabled: PropTypes.bool,
    secondary: PropTypes.bool,
    icon: PropTypes.string,
    marginRight: PropTypes.string
}