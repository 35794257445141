import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const extCreateDamageKickstartReducer =  (state = defaultStore.pages.extCreateDamageKickstart, action) => {
    switch (action.type) {
    case ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_REQUEST:
        return {
            ...state,
            damageDataSending: true,
            damageDataSent: false
        }
    case ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_SUCCESS:
        return {
            ...state,
            damageDataSending: false,
            damageDataSent: true
        }
    default:
        return state
    }
}
