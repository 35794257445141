import i18next from 'i18next'
import { buildApiUrl } from 'application/api/helpers'
import { HTTP_METHOD } from 'application/api/request_builders'

export const fetchRequestBuilderWithoutToken = (
    apiEndpoint,
    method, 
    payload = {}, 
    absolute = false
) => {

    const url = buildApiUrl(apiEndpoint, absolute)

    let data = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18next.language
        },
        mode: 'cors'
    }

    data = method !== HTTP_METHOD.GET ? {
        ...data,
        body: JSON.stringify(payload)
    } : data

    return {
        data,
        url
    }
}