import React from 'react'
import { PushBottom24, Spacer, SubPageLayout } from 'application/components/pages/_common'
import { AccountForms } from 'application/components/forms'
import { FlexBox } from 'application/components/fragments/flex_box'



export const AdministrationSettingsAccountLogo = () => {
    return <SubPageLayout>
        <PushBottom24 />
        <FlexBox flexDirection='row'>
            <FlexBox 
                flexBasis='1000px' 
                flexGrow='0' 
                flexShrink='1'
            >
                <FlexBox 
                    flexShrink='1' 
                    flexDirection='column'
                >
                    <AccountForms.AccountLogoForm />
                </FlexBox>
                <Spacer basis='32px' />
                <FlexBox 
                    flexDirection='column' 
                    flexBasis='308px' 
                    flexShrink='0' 
                    flexGrow='1'
                > 
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </SubPageLayout>
}