import styled from 'styled-components'

export const SyledNodeContainer = styled.div`
    height: ${props => props.theme.treeView.nodeDimension}px;
`

export const StyledExpandButton = styled.button`
    appearance: none;
    border: none;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0 1px ${props => props.theme.color.anthracite};
    width: 20px;
    height: 20px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.color.gray5};
    left: ${props => props.scaffoldBlockPxWidth * - 0.5}px;

    &:hover{
        background: ${props => props.theme.color.gray10};
    }
`

export const StyledVerticalLine = styled.div`
    height: 100%;
    display: inline-block;
    position: absolute;
    width: ${props => props.scaffoldBlockPxWidth}px;

    &:after{
        content: '';
        position: absolute;
        background-color: ${props => props.theme.treeView.lineColor};
        width: ${props => props.theme.treeView.lineWeight};
        left: 50%;
        bottom: 0;
        height: 20px;
    }
`

export const StyledTreeRow = styled.div`
    height: 100%;
    white-space: nowrap;
    display: flex;
`

export const StyledButton = styled.button`
    cursor: pointer;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: transparent;
    border: 0;
    width: 30px;
    &:hover{
        background-color: ${props => props.theme.color.gray5};
    }
`

export const StyledDisabledButton = styled.div`
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: transparent;
    border: 0;
    width: 30px;
`