import React from 'react'
import styled, { withTheme } from 'styled-components'

const StyledDropdownItem = styled.li`
    display: flex;
    align-items: center;
    height: 48px;
    padding-right: 16px;
    padding-left: 16px;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    cursor: pointer;
    background: ${props => props.theme.color.gray70};

    :hover {
        background: ${props => props.theme.color.anthracite};
    }

    `

const DropdownItemComponent = (
    {
        iconKey = '',
        onItemClick = () => {},
        label = '',
        isOpen = false
    }
) => {
    return <StyledDropdownItem
        isOpen={isOpen}
        onClick={onItemClick}
        className='dropdown-list-item'
    >
        <i className='material-icons'>
            {iconKey}
        </i>
        <span>{label}</span>
    </StyledDropdownItem>
}

export const DropdownItem = withTheme(
    DropdownItemComponent
)