import React from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'

import * as Fragments from './fragments'
import {LayoutBaseSharedResource} from './layout_base_workflow_panel_container'
import { TitleBarWorkflowPanel } from 'application/components/pages/external/title_bar'
import {Dialogs} from 'application/components/building_blocks/dialog/xdialogs'

const LayoutWorkflowPanelC = ({ children }) => {
    return <LayoutBaseSharedResource>
        <Fragments.Header>
            <TitleBarWorkflowPanel />
        </Fragments.Header>
        <Fragments.Main>
            <Fragments.Content cssPaddingLeft='0' cssPaddingTop='0' cssPaddingRight='0'>
                {children}
            </Fragments.Content>
            <Dialogs />
        </Fragments.Main>
    </LayoutBaseSharedResource>
}

export const  LayoutWorkflowPanel = withRouter(LayoutWorkflowPanelC)