import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { 
    // WidgetSubHeadline, 
    WidgetErrorMessage, 
    WidgetInformation, 
    WidgetSubHeadline, 
    WidgetText 
} from 'application/components/widgets/_common'
import {Collapsable} from 'application/components/controls/collapsable'

const DamageAKReceiptConfirmation = (props) => {
    const {payload, loadingFailed, isLoaded} = props
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }
    
    if(isLoaded && !payload.hasData){
        return <WidgetInformation>{t('No information regarding receipt confirmation yet')}</WidgetInformation>
    }

    const {data} = payload

    return <React.Fragment>
        <WidgetSubHeadline>
            {t('Receipt confirmation')}
        </WidgetSubHeadline>
        <Collapsable 
            initiallyCollapsed={false} 
            headline={t('Details of the firm')}
        >
            {
                data?.caseFileInfo?.referenceId && 
                <WidgetText>
                    {t('File number lawyer')}: <strong> {data.caseFileInfo.referenceId} </strong>
                </WidgetText>
            }
            {
                data?.contactInfo?.name &&
                <WidgetText>
                    {t('Contact person')}: <strong>{data?.contactInfo?.name}</strong>
                </WidgetText>
            }
            {
                data?.contactInfo?.phoneNumber && 
                <WidgetText>
                    {t('Phone number')}: <strong>{data?.contactInfo?.phoneNumber}</strong>
                </WidgetText>
            }
            {
                data?.caseFileInfo?.note  && 
                <WidgetText>
                    {t('Comment case file')}: <strong>{data?.caseFileInfo?.note}</strong>
                </WidgetText>
            }   
            {
                data?.acknowledgement?.note  && 
                <WidgetText>
                    {t('Comment acknowledgement')}: <strong>{data?.acknowledgement?.note}</strong>
                </WidgetText>
            }   


                     
        </Collapsable>
    </React.Fragment>
}

DamageAKReceiptConfirmation.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageAKReceiptConfirmation }