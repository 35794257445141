import { connect } from 'react-redux'
import {DamagesOverviewComponent} from './component'
import { withLoggedInUser } from 'application/components/context/logged_in'


const mapStateToProps = (state) => {

    return {}
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export const DamagesOverview = connect(
    mapStateToProps,
    mapDispatchToProps
)( withLoggedInUser(DamagesOverviewComponent) )