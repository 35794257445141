import { put } from 'redux-saga/effects'

// import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'

export function* removeUserFromGroupSaga(action) {
    // const {groupId, userId} = action.metaData
    yield put(showWaitingScreen('Saving group'))
    //server call goes here
    yield put(hideWaitingScreen())
    yield put(showToast('Group saved'))
}