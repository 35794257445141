import styled from 'styled-components'

export const StyledLifeCycleStateItem = styled.div`
    display: flex;
    flex-basis: 240px;
`

export const StyledLifeCycleStateLabel = styled.div`
    display: flex;
    margin-bottom: 62px;
    width: 100%;
`

export const StyledDamageStateItem = styled.div`
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    min-height: 72px;
    margin-bottom: 32px;
`

export const StyledTreeMarker = styled.div`
    width: 18px;
    margin-left: 18px;
    position: relative;
    flex: 1;

    &:before{
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        border-left: 2px solid ${props => props.theme.color.primary};
        border-bottom: 2px solid ${props => props.theme.color.primary};
        width: 20px;
        height: 15px;
    }

    &:after{
        position: absolute;
        content: "";
        top: 21px;
        left: 0;
        border-left: 2px solid ${props => props.theme.color.primary};
        border-top: 2px solid ${props => props.theme.color.primary};
        width: 20px;
        height: 100%;
    }

    &:last-child:after{
        display: ${props => props.isSingle ? 'block' : 'none'};
        border-left: 0;
    }

    &:first-child:before{
        position: absolute;
        content: "";
        top: 21px;
        left: -20px;
        border-left: 0;
        border-bottom: 0;
        border-top: 2px solid ${props => props.theme.color.primary};
        width: 20px;
        height: 100%;
    }
`
