import React from 'react'
import { useTranslation } from 'react-i18next'
import { CASE_STATUS } from 'application/constants'

export const StateName = ({ state }) => {
    const {t} = useTranslation()

    const mapState = (state) => {
        switch(state) {
        case CASE_STATUS.OPEN: 
            return 'Opened'
        default : 
            return state
        }
    }

    return <React.Fragment>
        { t( mapState(state) ) }
    </React.Fragment>
}