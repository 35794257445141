import { getWidgetContextMeta } from './get_widget_context_meta'

export const widgetLayoutStore = widgetContextName => {
    const { storage, defaults } = getWidgetContextMeta(widgetContextName)

    return {
        store: (layout) => {
            localStorage.removeItem(storage.layout)
            localStorage.setItem(storage.layout, JSON.stringify({layout}))
        },
        get: () => {
            if(storage === null){
                return defaults.layout
            }

            const storageEntry = localStorage.getItem(storage.layout)
            return storageEntry === null ? defaults.layout : (JSON.parse(storageEntry)).layout
        },
        delete: () => {
            localStorage.removeItem(storage.layout)
        }
    }
}