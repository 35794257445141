import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const StyledPagination = styled.ul`
    display: flex;
    justify-content: flex-start;
    padding: 16px 0;
    margin: 0;
    list-style: none;
    display: flex;
    font-size: ${props => props.theme.fontSize.small};
    
    & a.link-icon-arrow-right-2345 {
        margin-left: 8px;
    }
`

export const StyledPlaceholder = styled.div`
    width: 32px;
    height: 32px; 
`


export const StyledLink = styled(NavLink)`
    background: ${props => props.active ? props.theme.color.primary : 'transparent'};
    box-sizing: border-box;
    border-radius: 30px;
    width: 32px;
    height: 32px;
    padding: 8px;
    margin-left: 8px; 
    text-decoration: none; 
    text-align: center;
    color:  ${props => props.active ? props.theme.color.white : props.theme.color.anthracite};

    font-weight: ${
    props => props.active 
        ? props.theme.fontWeight.standardBold 
        : props.theme.fontWeight.standardRegular
};
`

export const StyledDivider = styled.div`
    width: 64px;
    text-align: center;
    margin-left: 8px;
    font-weight: ${props => props.theme.fontWeight.regularBold};
    font-size: ${props => props.theme.fontSize.medium18};
`

export const Divider = () => {
    return <StyledDivider>...</StyledDivider>
}