import { ACTIONS } from 'application/constants'

export const getUserGroupsRequest = () => ({
    type: ACTIONS.COMMON_GET_USER_GROUPS_REQUEST
})

export const populateUserGroups = (groups) => ({
    type: ACTIONS.COMMON_POPULATE_USER_GROUPS,
    groups
})

export const resetUserGroups = () => ({
    type: ACTIONS.COMMON_RESET_USER_GROUPS
})
