import React from 'react'
import * as Fragments from './fragments'
import { withStatuslistLoader } from 'application/components/higher_order_components'
import { ActivityDamageLink } from './_common/damage_activity_link'



export const DamageStateChangedMetaContent = withStatuslistLoader(
    (
        {
            notification, 
            onLinkClick, 
            inline, 
            statusList
        }
    ) => {
        const {payload} = notification

        const oldStateName = statusList.data.find(
            state => state.id === payload.oldStateId
        ).name
        
        const newStateName = statusList.data.find(
            state => state.id === payload.newStateId
        ).name
        
        return <Fragments.MetaContentContainer inline={inline}>
            <Fragments.ActivityDescription 
                label='Damage'
                value={payload.damage.vehicle.licensePlate}
            />
            <Fragments.ActivityDescription 
                label='State'
                value={`${oldStateName} → ${newStateName}`}
            />
            <ActivityDamageLink
                onClick={onLinkClick} 
                damageId={payload.damage.id}
                inline={inline}
            />
        </Fragments.MetaContentContainer>
    })