import styled from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { exists } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'


export const StyledDescriptionField = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 35px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
`

export const DescriptionField = (
    {
        description,
        icon = 'info_outline'
    }
) => {
    const {t} = useTranslation()
    if(!exists(description)){
        return null
    }

    return <StyledDescriptionField>
        <FontIcon icon={icon} />
        <Spacer basis='8px' />
        <span>{t(description)}</span>
    </StyledDescriptionField>
}