import { connect } from 'react-redux'

import { getFullAttachmentById, downloadAttachment } from 'application/redux/actions/attachments'
import {AttachmentViewerHoc} from './load_attachment_hoc'


const mapStateToProps = (state) => {
    return {
        attachments: state.attachments
    }
}

const mapDispatchToProps = dispatch => ({
    onLoadAttachment: (
        id, 
        name, 
        damageId, 
        taskId
    ) => dispatch(
        getFullAttachmentById(
            id, 
            name, 
            { 
                damageId, 
                taskId 
            }
        )
    ),
    onAttachmentDownload: (
        id, 
        damageId,
        name
    ) => dispatch(
        downloadAttachment(
            id, 
            name, 
            damageId
        )
    )
})

export const AttachmentViewer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentViewerHoc)