import React, { useState, useEffect } from 'react'
import { useStatefulForm } from 'application/components/hooks'
import { PageContainer, ResponsiveMobileContainer, AccountHeader } from '../_common'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { convertGermanDateStringToISODate } from 'application/common/date_time_helpers'
import { useLawfirms } from 'application/components/hooks/use_lawfirms'
import { useUiSettingsAdapters } from 'application/components/hooks/use_ui_settings_adapters'
import { KickstartExternalExtendedContent } from 'application/components/pages/external/kickstart/extended_content'
import { KickstartExternalSimpleContent } from 'application/components/pages/external/kickstart/simple_content'
import { todayToUtcString } from 'application/common/date_time_helpers'
import { CONTRACTOR_TYPE } from 'application/constants/contractors'






export const KickstartExternalContent = ({
    onCreateDamageKickstart,
    sharedResource,
    damageDataSent
}) => {
    const [commissionExpert, setCommissionExpert] = useState(false)
    const [commissionDekra, setCommissionDekra] = useState(false)
    const [commissionLawfirm, setCommissionLawfirm] = useState(false)
    const [tempDamageData, setTempDamageData] = useState(null)
    const account = sharedResource.data.account
    const branch = sharedResource.data.branch

    const {
        getSetting,
        accountSettingsLoaded
    } = useUiSettingsAdapters()

    const kickstartFormRendererStructure = getSetting(UI_SETTINGS.ACCOUNT.KICKSTART_FORM_RENDERER_STRUCTURE)
    const assignee = getSetting(UI_SETTINGS.ACCOUNT.EXTERNAL_DAMAGE_CREATION_BRANCH_ASSIGNEES, {branchId: branch.id})

    const adminUser = sharedResource.data.adminUser

    const {
        lawfirmsList
    } = useLawfirms()

    const [damageFiles,setDamageFiles] = useState([])
    const [commissionOtherFiles, setCommissionOtherFiles] = useState([])
    const [commissionDekraFiles, setCommissionDekraFiles] = useState([])
    const [lawfirmFiles, setLawfirmFiles] = useState([])


    const defaultValues = {
        incidentDate: todayToUtcString(),
        description: '',
        vehicle: {
            licensePlate: '',
        },
        assignee: { 
            userId: '' 
        },
        customer: {
            isCompany: false,
            companyName: '',
            firstName: '',
            lastName: ''
        },
        keeper: {
            firstName: null,
            lastName: null,
            isCompany: false,
            companyName: null,
            contactInfo: {
                phoneNumber: ''
            },
            address: {
                line1: null,
                postalCode: null,
                locality: null,
            }
        }
    }
    
    const accidentReportFormHook = useStatefulForm({defaultValues})

    const customerFormHook = useStatefulForm({
        defaultValues:{
            isCompany: false,
            companyName: '',
            firstName: '',
            lastName: ''
        }
    })

    const simpleCustomerFormHook = useStatefulForm({
        defaultValues:{
            isCompany: false,
            companyName: '',
            firstName: 'unbekannt',
            lastName: '',
            mobilePhoneNumber: ''
        }
    })
    
    const customer =  customerFormHook.getValues()

    const lawfirmFormHook = useStatefulForm(
        {
            defaultValues: {
                lawfirm: ''
            }
        }
    )

    const getSelectedLawFirmByWebakteId = (webakteId) => {
        return lawfirmsList.find(w => w.webakteId === webakteId)
    }

    const selectedLawfirm = getSelectedLawFirmByWebakteId(lawfirmFormHook.getValues().lawfirm)
    
    accidentReportFormHook.nameRequired = true

    const commissionFormHook = useStatefulForm({
        defaultValues: {
            inspectionInfoName: branch.name,
            inspectionSite: {
                ...branch.address
            }
        }
    })

    const commissionDekraFormHook = useStatefulForm({
        defaultValues: {
            inspectionInfoName: branch.name,
            inspectionSite: {
                ...branch.address
            },
            customer: {
                isCompany: false,
                companyName: '',
                firstName: '',
                lastName: ''
            },
        }
    })

    const [customerData, setCustomerData] = useState({})


    const handleCreateDamageKickstart = () => {
        const payload = {
            damageData: {
                damage: tempDamageData,         
                files: damageFiles,
                customer: customerData
            },
            commissionOtherExpert: {
                expert: commissionFormHook.getValues(),
                files: commissionOtherFiles,
                commissionExpert,
            },
            commissionDekra: {
                commissionDekra,
                commissionDekraData: {
                    customer: {
                        ...commissionDekraFormHook.getValues().customer,
                        ...customerData,
                    },
                    commissionExpertPayload: {
                        branchInfo: branch,
                        inspectionInfo: {
                            date: commissionDekraFormHook.getValues().inspectionDate,   
                            name: commissionDekraFormHook.getValues().inspectionInfoName,
                            site: commissionDekraFormHook.getValues().inspectionSite,
                            contact: branch.contact
                        },
                        note: '',
                        type: CONTRACTOR_TYPE.DEKRA
                    }
                },
                files: commissionDekraFiles
            },
            lawfirm: {
                commissionLawfirm,
                selectedLawfirm,
                files:lawfirmFiles
            },
            assigneeId: assignee?.id ?? adminUser?.id ?? null
        }
        onCreateDamageKickstart(payload)
    }

    const handleCreateDamageKickstartSimple = (customerData, damageData) => {
        const payload = {
            damageData: {
                damage: damageData,         
                files: [],
                customer: customerData
            },
            commissionOtherExpert: {
                expert: null,
                files: [],
                commissionExpert: false,
            },
            lawfirm: {
                commissionLawfirm: false,
                selectedLawfirm: null,
                files: []
            },
            assigneeId: assignee?.id ?? adminUser?.id ?? null
        }
        onCreateDamageKickstart(payload)
    }

    const [dynamicFormData, setDynamicFormData] = useState({})

    useEffect(() => {
        const newDamageData = {
            ...dynamicFormData,
            vehicle: {
                ...dynamicFormData.vehicle,
                firstRegisteredAt: convertGermanDateStringToISODate(dynamicFormData.vehicle?.firstRegisteredAt) || null
            }
        }
        setTempDamageData(newDamageData)
    }, [dynamicFormData])

    const simpleSteps = getSetting(UI_SETTINGS.ACCOUNT.EXTERNAL_KICKSTART_SIMPLE)

    const handleCommission = commissionDekra ? commissionDekraFormHook.handleSubmit : commissionFormHook.handleSubmit

    return <React.Fragment>
        <AccountHeader
            accountName={account.name}
            subtitle={branch.name}
            accountId={account.id}
        />
        <PageContainer>
            <ResponsiveMobileContainer>
                {
                    accountSettingsLoaded && <React.Fragment>
                        {
                            simpleSteps 
                                ? <KickstartExternalSimpleContent 
                                    customerFormHook={simpleCustomerFormHook}
                                    setCustomerData={setCustomerData}
                                    kickstartFormRendererStructure={kickstartFormRendererStructure}
                                    setDynamicFormData={setDynamicFormData}
                                    tempDamageData={tempDamageData}
                                    account={account}
                                    damageDataSent={damageDataSent}
                                    handleCreateDamageKickstart={handleCreateDamageKickstartSimple}
                                    damageDefaultValues={defaultValues}
                                />
                                : <KickstartExternalExtendedContent 
                                    customerFormHook={customerFormHook}
                                    setCustomerData={setCustomerData}
                                    kickstartFormRendererStructure={kickstartFormRendererStructure}
                                    setDynamicFormData={setDynamicFormData}
                                    commissionFormHook={commissionFormHook}
                                    commissionExpert={commissionExpert}
                                    setCommissionExpert={setCommissionExpert}
                                    commissionLawfirm={commissionLawfirm}
                                    setCommissionLawfirm={setCommissionLawfirm}
                                    commissionOtherFiles={commissionOtherFiles}
                                    setCommissionOtherFiles={setCommissionOtherFiles}
                                    lawfirmFormHook={lawfirmFormHook}
                                    lawfirmFiles={lawfirmFiles}
                                    setLawfirmFiles={setLawfirmFiles}
                                    branch={branch}
                                    selectedLawfirm={selectedLawfirm}
                                    tempDamageData={tempDamageData}
                                    customer={customer}
                                    handleCreateDamageKickstart={handleCreateDamageKickstart}
                                    damageFiles={damageFiles}
                                    setDamageFiles={setDamageFiles}
                                    account={account}
                                    damageDataSent={damageDataSent}
                                    handleCommission={handleCommission}
                                    commissionDekraFormHook={commissionDekraFormHook}
                                    commissionDekra={commissionDekra}
                                    setCommissionDekra={setCommissionDekra}
                                    commissionDekraFiles={commissionDekraFiles}
                                    setCommissionDekraFiles={setCommissionDekraFiles}
                                />
                        }
                    </React.Fragment>
                }
            </ResponsiveMobileContainer>
        </PageContainer>
    </React.Fragment>
}