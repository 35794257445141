import { put } from 'redux-saga/effects'
import { removeTaskFromList } from 'application/redux/actions/pages/damage_manager/tasks'

export function* removeTaskFromTaskList(action) {
    const taskId = action.payload.taskId !== undefined 
        ? action.payload.taskId 
        : action.metaData.id 

    if(taskId !== undefined){
        yield put(removeTaskFromList(taskId))
    }else{
        yield
    }
}