import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16 } from 'application/components/pages/_common'
import { validationMethods } from 'application/common'
import { WidgetSubHeadline, WidgetErrorMessage } from 'application/components/widgets/_common'
import { TextArea } from 'application/components/controls/form'
import {CommentBox} from 'application/components/controls/comment'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { getFullName } from 'application/common/name_helpers'
import { WidgetLoadMoreButton } from 'application/components/controls/widget_load_more_button'
import { useStatefulForm } from 'application/components/hooks'
import { ActionButton } from 'application/components/controls/action_button'



const TaskComments = (
    {
        widgetKey,
        widgetType,
        payload, 
        loadingFailed, 
        onSaveComment, 
        match, 
        user, 
        taskLoaded
    }
) => {
    const {t} = useTranslation()

    const {
        damageId, 
        taskId,
    } = match.params

    const {
        limit = 6,
        list = [],
        total = 0
    } = payload


    const [componentComments, setComponentComments] = useState(list)
    const [componentTotal, setComponentTotal] = useState(total)

    useEffect(
        () => {
            setComponentComments(list)
        },
        [list]
    ) 

    useEffect(
        () => {
            setComponentTotal(total )
        },
        [total ]
    ) 

    const {
        register, 
        errors, 
        handleSubmit,
        getValues,
        setValue
    } = useStatefulForm()

    const onSubmitSuccess = () => { 
        onSaveComment(
            damageId, 
            taskId, 
            getValues().comment
        )
        setComponentComments(
            [
                {
                    content: getValues().comment,
                    createdAt: new Date(Date.now()),
                    creator: {
                        name: getFullName(user)
                    }
                }, 
                ...componentComments
            ]
        )
        setComponentTotal(componentTotal + 1)
        setValue('comment', '')
    }

    if(!taskLoaded){
        return <React.Fragment />
    }

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <React.Fragment>
        <WidgetSubHeadline>
            <FontIcon 
                icon='chat_bubble_outline' 
                fontSize='16px' /> 
            &nbsp; {t('Do you have a comment?')}
        </WidgetSubHeadline>
        <PushBottom16 />
        <TextArea 
            label=''
            placeholder={t('Write an information for this task')}
            name='comment'
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.comment}
            hideValidationBox={true}
        />
        <ActionButton 
            buttonText={t('Save comment')} 
            onButtonClick={handleSubmit(onSubmitSuccess)} 
        />
        <PushBottom16 />
        {
            componentComments.sort(
                function compare(a, b) {
                    var dateA = new Date(a.createdAt)
                    var dateB = new Date(b.createdAt)
                    return dateB - dateA
                }
            ).map(
                (c, i) => {
                    return <CommentBox 
                        key={i}
                        creatorName={c.creator.name}
                        creationDate={c.createdAt}
                        text={c.content}

                    />
                }
            )
        }
        <WidgetLoadMoreButton 
            widgetKey={widgetKey}
            widgetType={widgetType}
            list={componentComments}
            total={componentTotal}
            limit={limit}
        />
        <PushBottom16 />
    </React.Fragment>
}

TaskComments.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired,
    onSaveComment: PropTypes.func.isRequired, 
    match: PropTypes.object.isRequired, 
    user: PropTypes.object.isRequired,  
    taskLoaded: PropTypes.bool.isRequired
}

export const TaskCommentsComponent = withRouter(TaskComments)