import React from 'react'

import { DatePicker as DP } from 'application/modules/dynamic-form/controls/date_picker'
import { _get } from './util'
import { useTranslation } from 'react-i18next'

export const DatePicker = ({ description, formHook }) => {
    const {
        register,
        errors,
        updateFormState,
        control,
        formStateValues,
        setValue,
        trigger
    } = formHook

    const { t } = useTranslation()

    return <DP
        label={t(description.label)}
        name={description.name}
        validate={description.validate}
        error={_get(errors, description.name)}
        onDateChange={() => updateFormState()}
        control={control}
        disabledDays={description.elementOptions?.disableFutureDates}
        register={register}
        defaultValue={_get(formStateValues, description.name)}
        setValue={setValue}
        trigger={trigger}
    />
}
