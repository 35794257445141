import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const dialogsReducer = (
    state = defaultStore.dialogs,
    action
) => {
    switch (action.type) {
    case ACTIONS.DIALOG_OPEN_DIALOG_BY_NAME:
        return ({
            ...state,
            dialogsOpen: true,
            registeredDialogs: [
                ...state.registeredDialogs, //check if dialog with same name is open first?
                {
                    name: action.name,
                    title: action.title,
                    isLoaded: action.initiallyLoaded,
                    isLoading: false,
                    payload: action.payload
                }
            ]
        })
    case ACTIONS.DIALOG_REQUEST_DIALOG_DATA:
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.dialogName ? {
                    ...d,
                    isLoading: true
                } : d
            }) 
        }
    case ACTIONS.DIALOG_CLOSE_DIALOG_BY_NAME: {
        const newRegisteredDialogs = state.registeredDialogs.filter(d => d.name !== action.name)
        return {
            ...state,
            dialogsOpen: newRegisteredDialogs.length > 0,
            registeredDialogs: newRegisteredDialogs
        }
    }
    case ACTIONS.DIALOG_CLOSE_ALL: {
        return {
            ...state,
            dialogsOpen: false,
            registeredDialogs: []
        }
    } 
    case ACTIONS.DIALOG_UPDATE_DIALOG_DATA_BY_NAME: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(
                d => {
                    return d.name === action.name 
                        ? {
                            ...d,
                            payload: {
                                ...d.payload,
                                ...action.payload
                            } 
                        } 
                        : d
                }) 
                
        }
    }
    case ACTIONS.DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(
                d => {
                    return d.name === action.name 
                        ? {
                            ...d,
                            payload: {
                                ...d.payload, //keep the previous data, eg an id
                                ...action.payload
                            },
                    
                        } 
                        : d
                }) 
                
        }
    }
    case ACTIONS.DIALOG_SUCCEED_LOADED: {
        return {
            ...state,
            registeredDialogs: state.registeredDialogs.map(d => {
                return d.name === action.name ? {
                    ...d,
                    isLoaded: true,
                    isLoading: false
                } : d
            }) 
                
        }
    }
    default:
        return state
    }
}