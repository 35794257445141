import React, {useEffect} from 'react'

import {TaskSelectComponent} from './component'

export const TaskSelectEffects = (props) => {
    const { damageId, tasksListDamageId, tasksLoaded, tasksLoading, onLoadTasks } = props

    const shouldLoad = (!tasksLoaded || damageId !== tasksListDamageId) && !tasksLoading 

    useEffect(() => {
        shouldLoad && onLoadTasks(damageId)
    }, [onLoadTasks, damageId, shouldLoad])

    return <TaskSelectComponent {...props} />
}