import { ACTIONS } from 'application/constants'

export const getAllTasksRequest = (
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
) => ({
    type: ACTIONS.MY_TASKS_GET_ALL_TASKS_REQUEST,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})

export const getAllMyTasksRequest = (
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
) => ({
    type: ACTIONS.MY_TASKS_GET_ALL_MY_TASKS_REQUEST,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})

export const populateAllTasks = (tasksList, tasksListTotal) => ({
    type: ACTIONS.MY_TASKS_POPULATE_ALL_TASKS,
    tasksList,
    tasksListTotal,
})

export const populateAllMyTasks = (tasksList, tasksListTotal) => ({
    type: ACTIONS.MY_TASKS_POPULATE_ALL_MY_TASKS,
    tasksList,
    tasksListTotal,
})