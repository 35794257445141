import { takeLeading, put, takeLatest } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateAllDocumentsWorkflowPanel, setArchiveDownloadingWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/documents'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* workflowPanelrDocumentsSaga(action) {
    try {
        const { documents } = yield resourceCall(
            api.gateway.attachments.getAttachmentsByDamageId
        )
        yield put(
            populateAllDocumentsWorkflowPanel(
                documents, 
                action.sharedResourceId
            )
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* downloadAttachmentsWFPZipSaga(action) {
    try {
        yield put(setArchiveDownloadingWorkflowPanel(true))
        yield resourceCall(
            api.gateway.attachments.downloadDamageDocumentsArchive,
            {},
            {
                fileName: action.attachmentName
            }
        )
        yield put(setArchiveDownloadingWorkflowPanel(false))
    } catch (e) {
        yield handleError(e, action)
    }

    // yield put(hidePreloader())
}

export function* workflowPanelDocumentsWatcher() {
    yield takeLeading(
        ACTIONS.WORKFLOW_PANEL_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST, 
        workflowPanelrDocumentsSaga
    )
    yield takeLatest(
        ACTIONS.WORKFLOW_PANEL_DOWNLOAD_ATTACHMENTS_ZIP, 
        downloadAttachmentsWFPZipSaga
    )
}
