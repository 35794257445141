import { connect } from 'react-redux'
import { updateAccidentReport } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageAccidentReportFormComp } from './component'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (damageId, damageData) => dispatch(
            updateAccidentReport(damageId, damageData)
        )
    }
}

export const TaskDamageAccidentReportForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageAccidentReportFormComp)