import { getFeatureFlags } from 'application/redux/selectors'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useFeatureFlag = () => {
    const {
        activeFeatures,
        loaded,
        loading
    } = useSelector(getFeatureFlags)

    const isFeatureActive = useCallback((featureFlag) => {
        if(!loaded || loading){
            return false
        }
        return activeFeatures.find(f => f === featureFlag)
    }, [activeFeatures, loaded, loading])

    return {
        featureFlagsLoaded: loaded,
        featureFlagsLoading: loading,
        isFeatureActive
    }
}