import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const contractorsReducer = (
    state = defaultStore.contractors, 
    action
) => {
    switch (action.type) {
    case ACTIONS.CONTRACTORS_REQUEST_ACCOUNT_CONTRACTORS_LIST: {
        return {
            ...state,
            contractorsList: [],
            contractorsLoading: true,
            contractorsLoaded: false,
        }
    }
    case ACTIONS.CONTRACTORS_POPULATE_ACCOUNT_CONTRACTORS_LIST: {
        return {
            ...state,
            contractorsList: action.contractorsList,
            contractorsLoading: false,
            contractorsLoaded: true,
        }
    }
    case ACTIONS.CONTRACTORS_REQUEST_BRANCH_CONTRACTORS_LIST: {
        return {
            ...state,
            branchContractorsList: [],
            branchContractorsLoading: true,
            branchContractorsLoaded: false,
        }
    }
    case ACTIONS.CONTRACTORS_POPULATE_BRANCH_CONTRACTORS_LIST: {
        return {
            ...state,
            branchContractorsList: action.contractorsList,
            branchContractorsLoading: false,
            branchContractorsLoaded: true,
        }
    }
    case ACTIONS.CONTRACTORS_SYNC_BRANCH_CONTRACTORS: {
        return {
            ...state,
            branchContractorsList: state.branchContractorsList.map(branch => {
                return branch.id === action.branchId 
                    ? {
                        ...branch,
                        allowedContractors: action.contractorsList.map(c => ({
                            id: c
                        }))
                    } 
                    : branch  
            })
        }
    }
    default:
        return state
    }
}