
//image sources for static images
const ICONS_BASE_FOLDER = '/images'

export const IMAGES = {
    LOGO_36: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '36px'
    },
    LOGO_36_SECONDARY: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360_secondary.svg',
        alt: 'e.sy 360',
        height: '36px'
    },
    LOGO_72: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '72px'
    },
    LOGO_192: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '192px'
    },
    LOGO_512: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '512px'
    },
    LOGO_40: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360_40.png',
        alt: 'e.sy 360',
        height: '32px'
    },
    DEKRA: {
        LOGO:  '/images/logos/dekra.svg',
        SIGN: '/images/DEKRA.svg'
    },
    DEKRA_LOGO: {
        src:  ICONS_BASE_FOLDER + '/DEKRA.svg',
        alt: 'DEKRA',
        height: '26px' //32px for /logo/dekra.svg
    }
}