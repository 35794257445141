import React from 'react'
import { Table } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import { useMoCloseWatcher, useTableLoader } from 'application/components/hooks'
import {withRouter} from 'application/components/higher_order_components/with_router'



const TableCompanies =  withRouter((
    {
        companiesListLoading,
        companiesListLoaded,
        companiesListTotal,
        companiesList,
        moName,
        emptyMessage='No companies found',
        match,
        onLoadCustomersList,
        updateConstraint
    }
) => {
    useTableLoader(
        onLoadCustomersList,
        match, 
        configuration, 
        updateConstraint
    )

    useMoCloseWatcher(
        moName, 
        companiesList
    )
    
    return <Table 
        configuration={configuration}
        elementsLoading={companiesListLoading}
        elementsLoaded={companiesListLoaded}
        elementsTotal={companiesListTotal}
        elements={companiesList}
        emptyMessage={emptyMessage}
        moName={moName}
    />
})

export {TableCompanies}