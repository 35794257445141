
import React from 'react'
import PropTypes from 'prop-types'
import {  ActivityDetailWrapper, BranchDetail, ActivityDetail, ActivityDetailBox } from 'application/components/controls/activity_details/common'
import {ShortenedString} from 'application/components/controls/shortened_string'
import { RightArrow } from 'application/components/fragments/typography'




export const DamageDescriptionChangedDetails = (
    {
        activity
    }
) => {

    const {
        damage
    } = activity.data

    const oldDescription = activity.data?.oldDescription || ''
    const newDescription = activity.data?.newDescription || ''

    return <ActivityDetailWrapper>
        <BranchDetail activity={activity} />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetailBox>
            <ShortenedString
                text={oldDescription} 
                shortenAt={20}
            />
            <RightArrow />
            <ShortenedString 
                text={newDescription}  
                shortenAt={20}
            />
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DamageDescriptionChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}