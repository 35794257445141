import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { put } from 'redux-saga/effects'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogDeleteLawfirmSaga(action) {
    const {
        damageId
    } = action.metaData
    
    yield put(
        showWaitingScreen('Canceling commission')
    )
    yield resourceCall(
        api.gateway.participants.deleteLawfirmById,
        {
            damageId
        }
    )
    yield put(
        showToast('Commission has been canceled')
    )
    yield put(
        hideWaitingScreen()
    )
}