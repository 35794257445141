import { connect } from 'react-redux'

import { WIDGET_CONTEXTS } from 'application/constants'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { ControllingDamagesComponent } from './component'

const mapStateToProps = state => {
    return {
        auth: state.auth,
        accessibleBranchesLoaded: state.common.accessibleBranchesLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(refreshWidgetsByContext(WIDGET_CONTEXTS.CONTROLLING_GLOBAL_DAMAGES))
        },
    }
}

export const ControllingDamages = connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllingDamagesComponent)