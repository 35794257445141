import { useWindowSize } from 'application/components/hooks'
import { PushBottom16 } from 'application/components/pages/_common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import {ContextMenu} from 'application/components/controls/context_menu'
import {ContextMenuItem} from 'application/components/controls/context_menu_item'
import { getPaymentSum} from 'application/common/invoice_helpers'
import { Invoice } from '../invoice'
import { FlexBox } from 'application/components/fragments/flex_box'
import { isCancelled } from 'application/common/payment'


const EditableInvoice = ({
    invoiceData,
    parentId,
    addPayment,
    theme,
    cancelPayment,
    deleteInvoice,
    addDocuments = () => {},
    dialogToOpenData = null,
    shouldReopenDialog = false
}) => {
    const { t } = useTranslation()
    const activePayments = invoiceData.payments.filter( 
        payment => !isCancelled(payment) 
    )

    // total auszahlungen
    const paymentTotal = invoiceData.payments.length > 0 
        ? getPaymentSum(activePayments) 
        : 0

    //status invoices
    const isOpen = Number(invoiceData.grossTotal) > paymentTotal
    const screenWidth = useWindowSize().width




    const handleCancelPayment = (parentId, invoiceData) => {
        cancelPayment(
            parentId, 
            {
                ...invoiceData,
                payments: invoiceData.payments
            },
            dialogToOpenData,
            shouldReopenDialog
        )
    }

    return <Invoice
        invoiceData={invoiceData}
        parentId={parentId}
    >
        <PushBottom16 />
        <FlexBox>
            {
                screenWidth < theme.breakpoint.desktop 
                    ? 
                    <FlexBox justifyContent='flex-end'>
                        <ContextMenu iconKey='more_horiz' label={t('More')} isTop={true}>
                            <ContextMenuItem
                                isLinked={false}
                                iconKey='file_upload'
                                onListElementClick={
                                    () => addDocuments(
                                        parentId, 
                                        invoiceData.id, 
                                        invoiceData,
                                        dialogToOpenData,
                                        shouldReopenDialog
                                    )
                                }
                            >
                                {t('Add documents')}
                            </ContextMenuItem>
                            {
                                isOpen && <ContextMenuItem
                                    isLinked={false}
                                    iconKey='add'
                                    onListElementClick={
                                        () => addPayment(
                                            parentId, 
                                            invoiceData,
                                            dialogToOpenData,
                                            shouldReopenDialog
                                        )
                                    }
                                >
                                    {t('Add payment')}
                                </ContextMenuItem>
                            }
                            {
                                paymentTotal > 0 && <ContextMenuItem
                                    isLinked={false}
                                    iconKey='replay'
                                    onListElementClick={
                                        () => handleCancelPayment(
                                            parentId, 
                                            invoiceData,
                                            dialogToOpenData,
                                            shouldReopenDialog
                                        )
                                    }
                                >
                                    {t('Cancel payment')}
                                </ContextMenuItem>
                            }
                            <ContextMenuItem
                                isLinked={false}
                                iconKey='clear'
                                onListElementClick={
                                    () => deleteInvoice(
                                        parentId, 
                                        invoiceData,
                                        dialogToOpenData,
                                        shouldReopenDialog
                                    )
                                }
                            >
                                {t('Delete invoice')}
                            </ContextMenuItem>
                        </ContextMenu>
                    </FlexBox> 
                    :
                    <FlexBox justifyContent='space-between'>
                        {
                            (isOpen) && <IconButtonWithLabel
                                label={t('Add payment')}
                                iconKey='add'
                                onButtonClick={
                                    () => addPayment(
                                        parentId, 
                                        invoiceData,
                                        dialogToOpenData,
                                        shouldReopenDialog
                                    )
                                }
                            />
                        }
                        {
                            paymentTotal > 0 && <IconButtonWithLabel
                                label={t('Cancel payment')}
                                iconKey='replay'
                                onButtonClick={
                                    () => cancelPayment(parentId, invoiceData)
                                }
                            />
                        }
                        <FlexBox>
                            <IconButtonWithLabel
                                label={t('Delete invoice')}
                                iconKey='clear'
                                onButtonClick={() => deleteInvoice(parentId, invoiceData)}
                            />
                        </FlexBox>
                    </FlexBox>
            }

        </FlexBox>
    </Invoice>
}

export const EditableInvoiceComponent =  withTheme(EditableInvoice)