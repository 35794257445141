import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { COMMISSION_STATE, INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import { FontIcon } from '@ec/ui-controls-react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { LawfirmCard } from 'application/components/building_blocks/lawfirm_card/component'

export const DeleteLawfirmComponent = withDialogDataLoader((
    { 
        abort, 
        dialog, 
        onDeleteLawfirm
    }
) => {
    const {t} = useTranslation()
    const {
        lawfirm,
        damageId,
        commissionState
    } = dialog.payload

    return <React.Fragment>
        <DialogMainContent>
            <LawfirmCard 
                lawfirm={lawfirm} 
                stretch={true}
            />
            <PushBottom16/>
            {
                commissionState === COMMISSION_STATE.PENDING 
                    ? <InfoBox type={INFOBOX.TYPES.ALERT}
                        direction='row' 
                        cssMargin='0'
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <span>{t('The connection to the lawfirm has not been established yet. Please wait a couple of minutes and try again.')}</span>
                        <Spacer />
                    </InfoBox>
                    : <InfoBox 
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0' 
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <span>
                            {t('Documents uploaded by the lawfirm are not deleted. These can still be accessed via the damage manager')}
                        </span>
                        <Spacer />
                    </InfoBox>
            }

            
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={
                () => onDeleteLawfirm(
                    dialog.name, 
                    damageId
                )
            }
            submitText='Cancel commission'
            disabled={commissionState === COMMISSION_STATE.PENDING}
        />
    </React.Fragment>
})