import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-left: ${props => props.cssPaddingLeft !== undefined ? props.cssPaddingLeft : '16px'};
    padding-right: ${props => props.cssPaddingRight !== undefined ? props.cssPaddingRight : '16px'};
    padding-top: ${props => props.cssPaddingTop !== undefined ? props.cssPaddingTop : '12px'};
    padding-bottom: ${props => props.cssPaddingBottom !== undefined ? props.cssPaddingBottom : '0'};
    overflow: auto;
`

export const DamageManagerContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-left: ${props => props.cssPaddingLeft !== undefined ? props.cssPaddingLeft : '16px'};
    padding-right: ${props => props.cssPaddingRight !== undefined ? props.cssPaddingRight : '16px'};
    padding-top: ${props => props.cssPaddingTop !== undefined ? props.cssPaddingTop : '12px'};
    padding-bottom: ${props => props.cssPaddingBottom !== undefined ? props.cssPaddingBottom : '0'};
    overflow: auto;
    max-height: calc(100vh - 50px);
    
    @media (max-width: 1440px)
        max-height: calc(100vh - 100px);
    }
`

export const Main = styled.div`
    display: flex;
    flex: 1 1 100%;
    max-height:100%;
    overflow-y: hidden;
`

export const Spacer = styled.div`
    flex: 1 1 auto;
`