import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { Link } from 'application/components/controls/hyperlink'
import { ROUTES, ACTIVITY_RELATED_ENTITY_TYPES } from 'application/constants'
import PropTypes from 'prop-types'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'


export const TaskCreatedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const {
        damageManagerTaskLink
    } = useApplicationLinks()

    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    const taskId = activity.data.id

    const link = damageManagerTaskLink(
        ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED, 
        taskId,
        damageId
    )
            
    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t(`${activity.type}Title`)}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Title')}: {activity.data.title}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <Link to={link}>
                {t('Switch to task')}
            </Link>
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

TaskCreatedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}