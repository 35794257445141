import React from 'react'
import { Table } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { useTableLoader } from 'application/components/hooks'

const TableActivitiesComponent = (
    {
        activitiesList,
        activitiesListLoading,
        activitiesListLoaded,
        activitiesListTotal,
        onLoadActivitiesList,
        match,
        updateConstraint = null,
    }
) => {

    useTableLoader(
        onLoadActivitiesList, 
        match, 
        configuration, 
        updateConstraint
    )

    return <Table 
        configuration={configuration}
        elementsLoading={activitiesListLoading}
        elementsTotal={activitiesListTotal}
        elementsLoaded={activitiesListLoaded}
        elements={activitiesList}
        emptyMessage='No activities found'
    />
}

export const TableActivities = withRouter(
    TableActivitiesComponent
)