import { validationMethods } from 'application/common'

const elementOptions = {
    disableFutureDates: {
        after: new Date()
    },
    allowLandLine: true
}

export const dataMapper = obj => {
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => dataMapper(item))
    }

    const copiedObj = {}
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (key === 'validate' && obj[key] !== null) {
                const updatedValidate = {}
                for (const validateKey in obj[key]) {
                    if (obj[key].hasOwnProperty(validateKey)) {
                        // Map the validate property using the mapperObj
                        updatedValidate[validateKey] = validationMethods[obj[key][validateKey]]
                    }
                }
                copiedObj[key] = updatedValidate
            } else {
                copiedObj[key] = dataMapper(obj[key])
            }
            if (key === 'elementOptions' && obj[key] !== null) {
                const updatedElementOptions = {}
                for (const elOpKey in obj[key]) {
                    if (obj[key].hasOwnProperty(elOpKey)) {
                        updatedElementOptions[elOpKey] = elementOptions[elOpKey]
                    }
                }
                copiedObj[key] = updatedElementOptions
            }
        }
    }

    return copiedObj
}