import React from 'react'
import { FilterRadioCheckboxGroup }  from '../radio_checkbox_group'
import PropTypes from 'prop-types'
import * as filters from 'application/filters'
import { FILTERS } from 'application/constants'


const DamageStateFilterCheckboxGroup = (
    {
        OFFSET,
        LIMIT,
        disabled = false,
        spaceLeft = false,
        onChange = () => {}
    }
) => {
    const filter = filters.damages.state

    const checkboxDefinitions = [
        {
            label: 'In Progress',
            value:  FILTERS.URL.VALUES.DAMAGES.STATE.IN_PROGRESS,
        },
        {
            label: 'Done',
            value:  FILTERS.URL.VALUES.DAMAGES.STATE.FINISHED,
        },
        {
            label: 'All',
            value:  FILTERS.URL.VALUES.DAMAGES.STATE.ALL,
        }
    ]


    return <FilterRadioCheckboxGroup
        OFFSET={OFFSET}
        LIMIT={LIMIT}
        filter={filter}
        disabled={disabled}
        checkboxDefinitions={checkboxDefinitions}
        spaceLeft={spaceLeft}
        onChange={onChange}
    />                
}

DamageStateFilterCheckboxGroup.propTypes = {
    OFFSET: PropTypes.string.isRequired,
    LIMIT: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    spaceLeft: PropTypes.bool,
    onChange: PropTypes.func
}

export {DamageStateFilterCheckboxGroup}