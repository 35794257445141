import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteComprehensiveDamageTypeComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteComprehensiveDamageType: (name, comprehensiveDamageTypeId, comprehensiveDamageTypeName) => {
            dispatch(sendPayloadRequest(name, { comprehensiveDamageTypeName }, { comprehensiveDamageTypeId }))
        },
    }
}

export const DeleteComprehensiveDamageType = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteComprehensiveDamageTypeComponent)