import React from 'react'
import {DamageStateSelectDamageManager} from 'application/components/controls/damage_status_select'
import {DamageAssigneeSelect} from 'application/components/controls/damage_assignee_select'
import { DamageManagerVehicle } from 'application/components/controls/damage_manager_vehicle'
import {
    StyledDamageManagerSidebar,
    StyledDamageManagerSidebarStatus,
    StyledDamageManagerSidebarDropdownContainer
} from './fragments'
import { DamageManagerSidebarNavigation } from './navigation'
import { PushBottom16 } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'
import { AnnouncementAndFeedbackButtons } from 'application/components/building_blocks/main_navigation_sidebar/_common/feedback_buttons'
import { LanguageSwitchHoc } from 'application/components/building_blocks/language_switch/hoc'

export const SidebarDamageManagerComponent = ({
    vehicle,
    damageLoaded,
    hasLawfirm,
}) => {
    const { t } = useTranslation()
    const showLawfirmTab = damageLoaded && hasLawfirm

    return <StyledDamageManagerSidebar>
        <DamageManagerVehicle
            damageLoaded={damageLoaded}
            vehicle={vehicle}
        />
        <StyledDamageManagerSidebarStatus>
            <PushBottom16 />
            <StyledDamageManagerSidebarDropdownContainer>
                <label>{t('Assignee')}</label>
                <DamageAssigneeSelect />
            </StyledDamageManagerSidebarDropdownContainer>
            <StyledDamageManagerSidebarDropdownContainer>
                <label>{t('State')}</label>
                <DamageStateSelectDamageManager />
            </StyledDamageManagerSidebarDropdownContainer>
        </StyledDamageManagerSidebarStatus>

        <DamageManagerSidebarNavigation
            showLawfirmTab={showLawfirmTab}
        />
        <AnnouncementAndFeedbackButtons />
        <LanguageSwitchHoc cssMargin='0 0 12px 13px' />
    </StyledDamageManagerSidebar>
}