import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { validationMethods } from 'application/common'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, CheckboxWithLabel } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
import { PhoneNumberInput } from 'application/components/controls/form/phone_number_input'
import { Select } from 'application/components/controls/form'
import { InfoBox } from 'application/components/controls/info_box'
import { PushBottom16, PushBottom8 } from 'application/components/pages/_common'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists, isDefined } from 'application/common/data_helpers'
import { useStatefulForm } from 'application/components/hooks'
import {GravatarComponent} from 'application/components/building_blocks/gravatar'
import {useGravatar} from 'application/components/hooks/use_gravatar'
import { TwoColumns } from 'application/components/forms/_common'




const EditUser = ( 
    {
        abort,
        setAbortBlocked,
        setDataChanged,
        onEditUser,
        onSendInvitation,
        dialog,
        authentificatedUserId,
        accessibleBranches
    }
) => {
    const { payload } = dialog

    const initialPreferredBranchId = isDefined(payload.preferredBranch) 
        ? payload.preferredBranch.id
        : accessibleBranches[0].id

    const preferredBranchSelectOptions = accessibleBranches.map(ab => ({
        value: ab.id,
        label: ab.name
    }))

    const isLocked = exists(payload.isLocked) ? payload.isLocked : false

    const defaultValues = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        company: payload.company,
        email: payload.email,
        phoneNumber: payload.phoneNumber,
        mobilePhoneNumber: payload.mobilePhoneNumber,
        preferredBranchId: initialPreferredBranchId,
        isLocked
    }

    const { t } = useTranslation()

    const [selectedOption, setSelectedOption] = useState(preferredBranchSelectOptions.find(o => o.value === defaultValues.preferredBranchId))
 
    const {
        setFirstName,
        setLastName,
        getInitials,
        getFullName 
    } = useGravatar(
        defaultValues.firstName,
        defaultValues.lastName
    )

    const { 
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        setValue,
        updateFormState
    } = useStatefulForm({defaultValues})

    const isRegistered = payload.isRegistered
    const userId = payload.id
    const isCurrentUser = userId === authentificatedUserId
    const { notEmpty, mustBeEmail } = validationMethods





    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }



    const successMsg = getFullName() + ' ' + t('has been saved')


    const onSubmitSuccess = () => {
        const shouldSendPreferredBranch = isCurrentUser && initialPreferredBranchId !== getValues().preferredBranchId

        const metaData = {
            userId: userId,
            successMsg,
            shouldSendPreferredBranch,
            previousUserData: dialog.payload
        }

        const payload =  { 
            user: {
                email: getValues().email,
                firstName: getValues().firstName,
                lastName: getValues().lastName,
                company: getValues().company,
                isLocked: getValues().isLocked,
                phoneNumber: getValues().phoneNumber,
                mobilePhoneNumber: getValues().mobilePhoneNumber
            },
            preferredBranch: {
                id: getValues().preferredBranchId
            }
        }
        onEditUser(
            dialog.name,
            payload,
            metaData
        )
    }

    const handleSendInvitation = () => {
        onSendInvitation(payload.id)
    }

    return <React.Fragment>
        <DialogMainContent flexDirection='row'>
            <GravatarComponent
                getInitials = {getInitials}
                getFullName = {getFullName}
                cssWidth = '45%'
            >
                {
                    !isRegistered && <React.Fragment>
                        <PushBottom16 />
                        <InfoBox cssPadding='16px'>
                            <Paragraph cssAlign='center'>
                                <strong>{t('Note')}: </strong> {t('This user has not accepted the invitation yet.')}
                            </Paragraph>
                            <PushBottom8 />
                            <Paragraph cssAlign='center'>
                                <ActionButton buttonText={t('Send invitation again')} onButtonClick={handleSendInvitation} />
                            </Paragraph>
                        </InfoBox>
                    </React.Fragment>
                }

                {
                    !isCurrentUser && <FlexBox justifyContent='center'>
                        <CheckboxWithLabel
                            name='isLocked'
                            register={register}
                            label={t('User is locked')}
                            isRequired={false}
                            onCheckboxChange={
                                (e, isChecked) => {
                                    setValue('isLocked', isChecked)
                                }
                            }
                            isSelected={isLocked}
                        />
                    </FlexBox>
                }
            </GravatarComponent>

            <FlexBox 
                flexBasis='55%' 
                paddingLeft='16px'
                flexDirection='column'
            >
                <TextInput
                    label={t('Firstname')}
                    name='firstName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.firstName}
                    onChange={(e) => {
                        onDataChange()
                        setFirstName(e.target.value)
                    }}
                />
                <TextInput
                    label={t('Lastname')}
                    name='lastName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.lastName}
                    onChange={(e) => {
                        onDataChange()
                        setLastName(e.target.value)
                    }}
                />
                <TextInput
                    label={t('Company')}
                    name='company'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.company}
                    onChange={onDataChange}
                />
                <TextInput
                    label={t('Email')}
                    name='email'
                    register={register}
                    validate={{
                        notEmpty,
                        mustBeEmail
                    }}
                    error={errors.email}
                    onChange={onDataChange}
                /> 
                <TwoColumns 
                    columnLeft={
                        <PhoneNumberInput
                            defaultValue={defaultValues.phoneNumber}
                            validate={null}
                            control={control}
                            label={t('Phone number')}
                            name='phoneNumber'
                            register={register}
                            error={errors.phoneNumber}
                            onChange={onDataChange}
                            setValue={setValue}
                            allowLandLine={true}
                        />
                    }
                    columnRight={
                        <PhoneNumberInput
                            defaultValue={defaultValues.mobilePhoneNumber}
                            validate={null}
                            control={control}
                            label={t('Mobile phone number')}
                            name='mobilePhoneNumber'
                            register={register}
                            error={errors.mobilePhoneNumber}
                            onChange={onDataChange}
                            setValue={setValue}
                            allowLandLine={false}
                        />
                    }
                />
                {
                    isCurrentUser && <Select 
                        label={t('Select preferred branch')}
                        name='preferredBranchId'
                        register={register}
                        setValue={setValue}
                        options={preferredBranchSelectOptions}
                        defaultValue={selectedOption}
                        isClearable={false}
                        validate={{notEmpty}}
                        menuPlacement='top'
                        onChange={(option)=>{
                            setSelectedOption(option)
                            onDataChange()
                        }}
                    />
                }
                
                
            </FlexBox> 

        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save'
        />
    </React.Fragment>
}

export const EditUserComponent = withDialogDataLoader(EditUser)