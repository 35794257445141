import React from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { GeocodedMap } from 'application/components/building_blocks/open_street_map'
import { Spacer } from 'application/components/pages/_common'
import { getLatLongForMap } from 'application/common/address_helper'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { 
    StyledAccountBox, 
    StyledAccountLink,
    StyledDisabledAccountLink,
    StyledAccountAddressRow,
    StyledAccountAddress,
    StyledSecondaryHeadline,
    ButtonStyling,
    StyledLockedMessage
} from './fragments'

export const AccountBoxComponent = (
    {
        account,
        theme,
        to,
        onSetAsPreferredAccount,
        onUnsetAsPreferredAccount,
        isPreferred = false,
        address= null
    }
) => {
    const {t} = useTranslation()
    const isLocked = account.isLocked || account.user.isLocked

    const fontColor = theme.color.anthracite
    const linkFontColor = theme.color.anthraciteLight
    const linkHoverColor = theme.color.anthracite
    const preferedButtonHoverColor = theme.color.black
    const backgroundColor = isLocked ? theme.color.gray5 : theme.color.gray10
    const hoverBackgroundColor = theme.color.gray15

    const iconColor = isPreferred 
        ? theme.color.primary
        : theme.color.anthracite

    const line1 = address?.line1 || ''
    const postalCode = address?.postalCode || ''
    const locality = address?.locality || ''
    const latLong = getLatLongForMap(address)

    const iconKeyPreferredButton = isPreferred
        ? 'star' 
        : 'star_outline'

    const titlePreferredButton = isPreferred
        ? 'Unset as preferred'
        : 'Set as preferred'


    const handleSetAsPreferredAccount = () => {
        isPreferred
            ?  onUnsetAsPreferredAccount()   
            :  onSetAsPreferredAccount(account)
    }

    return <StyledAccountBox  
        backgroundcolor={backgroundColor}
        hoverbackgroundcolor={hoverBackgroundColor}
        fontcolor={fontColor}
        isLocked={isLocked}
    >
        <FlexBox flexDirection='row'>
            <FlexBox flexShrink='1' flexGrow='0' flexBasis='176px' width='176px'>
                <GeocodedMap 
                    addressQuery={address} 
                    latLong={latLong}
                    mapWidth='160px'
                    mapHeight='160px'
                    mapLinkText='Show map'
                />
            </FlexBox>
            
            <FlexBox flexDirection='column' flexShrink='0' flexGrow='1' flexBasis='auto' width='auto'>
                <FlexBox 
                    justifyContent='space-between'
                    cssRules={'padding: 0 0 16px;'}
                    alignItems='flex-end'
                >
                    {
                        isLocked 
                            ? <StyledDisabledAccountLink>
                                {account.name} 
                            </StyledDisabledAccountLink>
                            : <StyledAccountLink 
                                color={linkFontColor}
                                hovercolor={linkHoverColor}
                                to={to} 
                            >
                                {account.name} 
                            </StyledAccountLink>
                    }
                    
                    <Spacer basis='10px' />
                    { 
                        isPreferred && <StyledSecondaryHeadline
                            color={linkFontColor}
                        >
                            {'(' + t('Preferred account') +')'}
                        </StyledSecondaryHeadline> 
                    }
                    <Spacer />
                    {
                        !isLocked && <ButtonStyling 
                            hovercolor={preferedButtonHoverColor}
                            iconColor={iconColor}
                        >
                            <IconButtonWithLabel
                                iconKey={iconKeyPreferredButton} 
                                title={t(titlePreferredButton)}
                                iconSize='22px'
                                fontSize='14px'
                                onButtonClick={handleSetAsPreferredAccount} 
                            />
                        </ButtonStyling>
                    }
                </FlexBox>

                <FlexBox 
                    justifyContent='space-between'
                    
                >
                    <FlexBox
                        width='auto'
                        flexDirection='column'
                        justifyContent='space-between'
                        background='white'
                    >         
                        {
                            isLocked && <InfoBox
                                type={INFOBOX.TYPES.ALERT} 
                                cssMargin='0 0 16px' 
                                cssPadding='8px 16px 8px 8px'
                                cssMinHeight='unset'
                                direction='row'
                            >
                                <FontIcon icon='error_outline' />
                                <Spacer basis='10px' />
                                <StyledLockedMessage>{t('You currently don\'t have access to this account')}</StyledLockedMessage>
                            </InfoBox>
                        }         
                        <StyledAccountAddress>
                            <StyledAccountAddressRow>
                                {line1}
                            </StyledAccountAddressRow>
                            <StyledAccountAddressRow>
                                {`${postalCode} ${locality}`}
                            </StyledAccountAddressRow>
                            
                        
                        </StyledAccountAddress>
                        
                    </FlexBox>
                </FlexBox>


            </FlexBox>
        </FlexBox>
        

        
    </StyledAccountBox>
}


AccountBoxComponent.propTypes = {
    account: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    to: PropTypes.string.isRequired,
    onSetAsPreferredAccount: PropTypes.func,
    onUnsetAsPreferredAccount: PropTypes.func,
    street: PropTypes.string,
    postalCode: PropTypes.string, 
    place: PropTypes.string,
    isPreferred: PropTypes.bool,
    address: PropTypes.object
}

export const AccountBox = withTheme(AccountBoxComponent)