import { connect } from 'react-redux'

import { LawfirmReceiptConfirmationComponent } from './component'
import { updateWorkflowPanelForm } from 'application/redux/actions/common/workflow_panel'

const mapStateToProps = state => (
    {
        damageType: state.pages.workflowPanel.shared.damage.type
    }
)

const mapDispatchToProps = dispatch => {
    return {
        onSave: (
            widgetKey,
            payload
        ) => dispatch(
            updateWorkflowPanelForm(
                widgetKey, 
                payload
            )
        )
    }
}

export const LawfirmReceiptConfirmation = connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmReceiptConfirmationComponent)