import { connect } from 'react-redux'
import { LastRepairAuthorizationsComponent } from './component'

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export const LastRepairAuthorizations = connect(
    mapStateToProps,
    mapDispatchToProps
)(LastRepairAuthorizationsComponent)