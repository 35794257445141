import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogEditUserNotificationSettingsSaga(action) {   
    yield put(showWaitingScreen('Saving notification settings'))

    const { 
        userId 
    } = action.metaData

    const {
        notificationSettingsRequestValues
    } = action.payload

    yield resourceCall(
        api.gateway.users.putNotificationSettings, 
        {
            userId
        },
        notificationSettingsRequestValues
    )

    yield put(showToast('Notification settings saved'))

    yield put(hideWaitingScreen())
}