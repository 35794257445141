import {WithMessagesContainer} from './container'
import { compose } from 'redux'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { ROUTES } from 'application/constants'
import {withMessagesComponent} from './component'

/**
 * fetches messages 
 * @param {string} widgedContext - context name of current passed widged
 * @param {string} redirectUrl url to redirect to current inbox location
 * @returns getMessageByIdRequest, refreshWidgetsByContext
 */
export const withMessageDetailsLoader = (
    redirectUrl = ROUTES.ALL_BRANCHES_INBOX
) => compose(
    withRouter,
    WithMessagesContainer( 
        redirectUrl
    ),
    withMessagesComponent
)