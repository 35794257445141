import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'

export const CardWrapper = styled.div`
    border: 1px solid ${props => props.theme.color.gray10};
    background: ${props => props.theme.color.white};
    padding: ${props => props.small ? '8px 0 8px 12px' : '16px 0 16px 24px'};
    max-width: ${props => props.stretch ? 'unset' : '330px'};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: fit-content;
    margin-bottom: 16px;
`

export const CardMeta = styled.div`
    flex: 0 0 110px;
    padding-right: 8px;
    border-left: 1px solid ${props => props.theme.color.gray10};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

export const CardContent = styled.div`
    flex: 1 1 auto;
    height: 100%;
    padding-right: 16px;
`

export const CardPreHeadline = styled.p`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    padding: 0;
    margin: 0;
`

export const CardHeadline = styled.p`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.primary};
    padding: 0;
    margin: 0 0 8px 0;
`

export const CardInfoRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
`


export const CardInfoRowIcon = styled.div`
    flex: 0 0 14px;
    padding-top: 2px;
`

export const CardInfoRowText = styled.div`
    padding-left: 10px;
    flex: 1 1 auto;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.hasContent
        ? props.theme.color.anthracite
        : props.theme.color.anthracite50};
    font-style: ${props => props.hasContent
        ? 'normal'
        : 'italic'};
    line-height: ${props => props.theme.fontSize.headline3};
`

export const CardInfoRow = ({
    icon, 
    children,
    hasContent
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return <CardInfoRowWrapper>
        <CardInfoRowIcon>
            <FontIcon 
                icon={icon} 
                fontSize={theme.fontSize.standard} 
                color={theme.color.anthracite} 
            />
        </CardInfoRowIcon>
        <CardInfoRowText hasContent={hasContent}>
            {
                hasContent
                    ? <React.Fragment>{children}</React.Fragment>
                    : <React.Fragment>{t('not specified')}</React.Fragment>
            }
        </CardInfoRowText>
    </CardInfoRowWrapper>
}