import React, { useEffect, useMemo } from 'react'
import {Widget} from './component'
import { useQuery } from 'application/components/hooks/use_query'
import { useParams } from 'react-router-dom'



export const WidgetEffects = (props) => {
    const query = useQuery()

    const { 
        onLoadWidgetData, 
        isLoaded, 
        isLoading, 
        isRegistered, 
        widgetKey, 
        type, 
        loggedIn, 
        isPermitted, 
        payload, 
        accountDependent, 
        currentAccountLoaded, 
        currentAccountId, 
        dependentWidgetKeys, 
        dependsOnOtherWidget
    } = props

    const params = useParams()

    const match = useMemo(
        () => {return {params}}, [params]
    ) 

    useEffect(
        () => {
            loggedIn  //token is needed for widget content
        && isPermitted //user is permitted to load widget data
        && isRegistered //widget must be registered in redux store
        && !isLoaded //only use effect when widget content is not loaded or should reload
        && !isLoading //only use effect when widget content is not currently loading
        && ((accountDependent && currentAccountLoaded) || !accountDependent) //if widget is account id dependent, wait for the account data to be written and the application token is set
        && onLoadWidgetData(
            widgetKey, 
            type, 
            payload, 
            match, 
            query.all, 
            dependentWidgetKeys, 
            dependsOnOtherWidget,
            
        )
        }, [accountDependent, currentAccountLoaded, isLoaded, isLoading, isPermitted, isRegistered, loggedIn, match, onLoadWidgetData, payload, query.all, type, widgetKey, currentAccountId, dependentWidgetKeys, dependsOnOtherWidget]
    )

    return <Widget 
        {...props} 
        widgetType={type}
    />
}