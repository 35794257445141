import { connect } from 'react-redux'
import {BranchSpecificAccordionMenuComponent} from './component'

const mapStateToProps = (state) => {
    return {
        currentBranch: state.auth.currentBranch,
        hasMultipleBranches: state.auth.hasMultipleBranches
    }
}

export const BranchSpecificAccordionMenu = connect(
    mapStateToProps
)(BranchSpecificAccordionMenuComponent)