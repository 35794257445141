import { put, debounce } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getCustomersForDialogsSaga(action) {
    try {
        yield put(
            updateDialogDataByName(
                action.dialogName, 
                {
                    customerList: [], 
                    customerListLoading: true
                }
            )
        )

        const customersResponse = yield resourceCall(
            api.gateway.customer.getAllCustomers,
            {
                offset: action.offset, 
                limit: action.limit, 
                orderBy: action.orderBy, 
                direction: action.direction, 
                filterQuery: action.filterQuery
            }
        )

        yield put(
            updateDialogDataByName(
                action.dialogName, 
                { 
                    customerList: customersResponse.customers, 
                    customerListLoading: false
                }
            )
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getCustomersWatcher() {
    yield debounce(
        1000, 
        ACTIONS.COMMON_GET_CUSTOMERS_FOR_DIALOG_REQUEST_DEBOUNCED, 
        getCustomersForDialogsSaga
    )
}