import { DeclarationOfAssignmentPdf } from './component'
import { pdf } from '@react-pdf/renderer'
// import { costCoverageValuesMapping } from './cost_coverage_values_mapping'

export const declarationOfAssignmentPdfBuilder = (            
    pdfValues
) => {
    return pdf(
        <DeclarationOfAssignmentPdf        
            values={pdfValues} 
        />
    )
}