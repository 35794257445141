import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {DamageManagerTasks} from './component'
import {  useParams } from 'react-router-dom'
import { DamageManagerDamageExists } from 'application/components/pages/internal/damage_manager/common/damage_exists'



export const DamageManagerTasksEffects = (props) => {
    const params = useParams()
    const {
        onLoadTasks,
        loggedIn
    } = props

    const { damageId} = params

    useEffect(() => {
        loggedIn && onLoadTasks(damageId)
    }, [loggedIn, onLoadTasks, damageId])

    return <DamageManagerDamageExists>
        <DamageManagerTasks {...props} />
    </DamageManagerDamageExists>
}

DamageManagerTasksEffects.propTypes = {
    onLoadTasks: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
}