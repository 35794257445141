import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import {LanguageSwitch} from '..'
import { I18N } from 'application/constants'

export const LanguageSwitchHoc = ({cssMargin='0'}) => {
    const {i18n} = useTranslation()
    const currentLanguage = i18next.language

    const getLanguage = (l) => {
        if(l.length === 2) {
            return l
        }
        if(l.length > 2) {
            return l.split('-')[0]
        }
        if(!l){
            return 'de'
        }
        return l
    }

    return <LanguageSwitch 
        cssMargin={cssMargin}
        languages={I18N.LANGUAGES}
        currentLanguage={getLanguage(currentLanguage)}
        onSwitchLanguages={(lang)=>{i18n.changeLanguage(lang)}}
    /> 
}