import { BRANCH_MANAGEMENT } from 'application/constants'
import { setSelectedEdgesStackingOrder, setSingleEdgeStackingOrder } from './set_stacking_order'
import { getConnectedEdges } from './get_connected_edges'

const selectConnectedEdges = (nodeId, elements, setSelectedElements) => setSelectedElements(getConnectedEdges(elements, nodeId))

export const edgeSelectionHandler = (allElements, setElements, selectedElements, setSelectedElements, onNodeSelect) => {

    if(selectedElements !== null){
        const el = selectedElements[0]
        //selected element is node => also select all connected edges & bring them on top of stacking order
        if(el !== undefined && el.type === BRANCH_MANAGEMENT.ELEMENT_TYPE.NODE_CUSTOM){
            selectConnectedEdges(el.id, allElements, setSelectedElements)
            onNodeSelect(el.id, el.nodeType)
            setElements(els => setSelectedEdgesStackingOrder(els, el.id))
        }
        //selected element is edge => bring it on top of stacking order
        if(el !== undefined && el.type === BRANCH_MANAGEMENT.ELEMENT_TYPE.EDGE_REMOVABLE){
            setElements(els => setSingleEdgeStackingOrder(els, el.id))
        }
    }
}