import React from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withExportToLawfirmConfirmationDialog, withUploadSteps } from 'application/components/higher_order_components'
import { useState } from 'react'
import { CreateInvoiceStep0 } from './component_step_0'
import { CreateInvoiceStep1 } from './component_step_1'
import { CreateInvoiceStep2 } from './component_step_2'
import { compose } from 'redux'
import { useEffect } from 'react'

 

const CreateInvoice = (
    {
        //withExport... hoc
        flaggedFiles,  
        setFlaggedFiles, 
        onSubmitWithExportToLawfirmConfirmation,
        //witSteps
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
        //other
        abort,
        setAbortBlocked,
        setDataChanged, 
        dialog,
        defaultCategoryId
    }
) => {
    //Step0
    const [invoicePayload, setInvoicePayload] = useState(null)
    const [invoiceMetaData, setInvoiceMetaData] = useState(null)

    //Step2
    const [taskId, setTaskId] = useState(null)
    const initialCategoryId = dialog.payload.categoryId !== undefined 
        ? dialog.payload.categoryId 
        : defaultCategoryId

    const [categoryId, setCategoryId] = useState(initialCategoryId) 


    useEffect(
        () => {
            setCategoryId(initialCategoryId)
        },
        [initialCategoryId]
    )

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const createInvoiceAndUploadFiles = () => {  
        const uploadPayload = {files:flaggedFiles}
        const uploadMetaData = {
            damageId: dialog.payload.damageId, 
            mainId: dialog.payload.damageId,
            categoryId: categoryId, 
            taskId
        }
        
        const payload = {
            invoice: invoicePayload,
            upload: uploadPayload
        }

        const metaData = {
            ...invoiceMetaData,
            ...uploadMetaData
        }

        onSubmitWithExportToLawfirmConfirmation(
            payload,
            metaData
        )
    }

    return <React.Fragment>
        {
            getCurrentStep().id === 0 && <CreateInvoiceStep0
                goToNextStep={goToNextStep}
                getStepsState={getStepsState}
                setInvoicePayload={setInvoicePayload}
                setInvoiceMetaData={setInvoiceMetaData}
                onDataChange={onDataChange}
                dialog={dialog}
                abort={abort}
            />
        }
        {
            getCurrentStep().id === 1 && <CreateInvoiceStep1
                getStepsState={getStepsState}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                files={flaggedFiles}
                setFlaggedFiles={setFlaggedFiles}
                onDataChange={onDataChange}
            />
        }
        {
            getCurrentStep().id === 2 && <CreateInvoiceStep2
                getStepsState={getStepsState}
                goToPreviousStep={goToPreviousStep}
                files={flaggedFiles}
                setFiles={setFlaggedFiles}
                setTaskId={setTaskId}
                setCategoryId={setCategoryId}
                initialCategoryId={initialCategoryId}
                damageId={dialog.payload.damageId}
                onSubmit={createInvoiceAndUploadFiles}
            />
        }
    </React.Fragment>

}

export const CreateInvoiceDialog = compose(
    withUploadSteps([
        {
            id: 0,
            label: 'Create invoice'
        },
        {
            id: 1,
            label: 'Upload files'
        },
        {
            id: 2,
            label: 'Select Category & Lawyer'
        }
    ]),
    withDialogDataLoader,
    withExportToLawfirmConfirmationDialog
)(CreateInvoice)