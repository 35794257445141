import { ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import * as Fragments from '../fragments'
import PropTypes from 'prop-types'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const MessageCreatedMetaContent = (
    {
        notification, 
        onLinkClick, 
        inline = false, 
    }
) => {
    const {t} = useTranslation()
    const location = useLocation()

    const {
        branchLink
    } = useApplicationLinks()

    const {
        messageId = '',
        damage = null,
        messageSubject = '',
        branchId = '',
        sender = null
    } = notification.payload

    const damageId = damage.id
    const senderName = sender.email || sender.name

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Subject'
            value={messageSubject}
        />
        <Fragments.ActivityDescription 
            label='From'
            value={senderName}
        />
        <Fragments.ActivityLink inline={inline}>
            <Link
                onClick={onLinkClick} 
                to={
                    branchLink(
                        ROUTES.ALL_BRANCHES_INBOX + '/' + damageId + '/' + messageId, 
                        branchId
                    ) 
                    + location.search
                }
            >
                {t('Show message')}
            </Link>
        </Fragments.ActivityLink>
    </Fragments.MetaContentContainer>
}


MessageCreatedMetaContent.propTypes = {
    match: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired, 
    onLinkClick: PropTypes.func.isRequired, 
    inline: PropTypes.bool,

}