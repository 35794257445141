import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_CONTEXTS } from 'application/constants'
import {DamageManagerTasksSidebarComponent} from './component'



const DamageManagerTasksSidebarEffectsComponent = (props) => {
    const { onRefreshWidgetContext, match } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR)
    }, [onRefreshWidgetContext, damageId])

    return <DamageManagerTasksSidebarComponent {...props} />
}

DamageManagerTasksSidebarEffectsComponent.propTypes = {
    onRefreshWidgetContext: PropTypes.func.isRequired
}

export const DamageManagerTasksSidebarEffects = withRouter(DamageManagerTasksSidebarEffectsComponent)