import React from 'react'
import PropTypes from 'prop-types'


const AssigneeNameActivity = (
    { assignee = null }
) => {

    if(assignee === null){
        return null
    }

    const {
        fullName = ''
    } = assignee

    return <React.Fragment>
        {fullName}
    </React.Fragment>
}

AssigneeNameActivity.propTypes = {
    assignee: PropTypes.object
}

export {AssigneeNameActivity}