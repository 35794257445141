import { currencyToDecimalString } from 'application/common/currency_helpers'

export const createInvoice = (payload) => ({
    number: payload.rechnungsnr,
    type: payload.type,
    grossTotal: currencyToDecimalString(payload.summeBrutto),
    netTotal: currencyToDecimalString(payload.summeNetto),
    description: payload.kommentar,
    issuedAt: payload.issuedAt
})

