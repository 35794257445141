import { api, dataMappers } from 'application/api'
import { ACTIONS, WIDGET_TYPES } from 'application/constants'
import { showToast } from 'application/redux/actions/notifications'
import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { handleError } from 'application/redux/saga/errors'
import { refreshDamageManagerDamageSaga } from 'application/redux/saga/sagas/post_processors'
import { put, takeLatest, takeLeading } from 'redux-saga/effects'
// import { liabilityClaimDataMapper, liabilityInsuranceDataMapper } from 'application/api/data_mappers/export/damage'
import { objectHelpers } from 'application/common'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'
import { FILTERS } from 'application/constants'
import { syncDamageAssignee } from 'application/redux/actions/pages/damage_manager/shared'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* updateDamageSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateDamage, 
            {
                damageId: action.damageId
            }, 
            action.damageData
        )
        yield refreshDamageManagerDamageSaga(action)
        yield put(showToast('Damage saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateRepairAuthorizationSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateRepairAuthorization, 
            {
                damageId: action.damageId
            }, 
            {
                repairAuthorization: action.repairAuthorizationType
            }
        )
        yield put(showToast('Repair approval state changed'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateDamageAssigneeSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateDamageAssignee, 
            {
                damageId: action.damageId
            }, 
            {
                id: action.userId
            }
        )
        yield put(syncDamageAssignee(action.userId, action.firstName, action.lastName))
        yield put(showToast('Editor for this damage is changed'))
        yield put(refreshWidgetsByType(WIDGET_TYPES.COMMISSION_LAWFIRM))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateComprehensiveDataSaga(action) {
    try {

        const {
            insurancePayload,
            claimPayload,
            typePayload
        } = action.payload.comprehensive

        const {damageId} =  action.metaData

        yield resourceCall(
            api.gateway.damage.updateComprehensiveDataByDamageId, 
            { damageId }, 
            objectHelpers.removePropertiesWithEmptyStrings(insurancePayload)
        )

        //if coverage == none, claimnumber + type are reset to NULL serverside
        if(insurancePayload.coverage !== FILTERS.VALUES.DAMAGES.COVERAGE.NONE){

            yield resourceCall(
                api.gateway.damage.updateComprehensiveDataClaimByDamageId, 
                { damageId }, 
                claimPayload
            )

            yield resourceCall(
                api.gateway.damage.updateComprehensiveDataTypeByDamageId, 
                { damageId }, 
                typePayload
            )
        }

        yield put(showToast('Comprehensive data changed'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateLiabilityInsuranceAndClaimSaga(action) {
    try {
        const {
            liability = null
        } = action.payload

        if (liability === null) return

        const {
            insurancePayload = null,
            claimPayload
        } = liability

        const {damageId} =  action.metaData

        yield resourceCall(
            api.gateway.damage.updateLiabilityInsurance, 
            {damageId}, 
            insurancePayload
        )
        yield resourceCall(
            api.gateway.damage.updateLiabilityClaim, 
            {damageId}, 
            claimPayload
        )
        yield put(showToast('Liability insurance data changed'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateVehicleProprietorSaga(action) {
    try {

        const payload = dataMappers.exportMappers.damage.vehicleProprietorDataMapper(action.formData)

        yield resourceCall(
            api.gateway.damage.updateVehicleProprietor, 
            {
                damageId: action.damageId
            }, 
            payload
        )
        yield put(showToast('Vehicle proprietor data changed'))
    } catch (e) {
        yield handleError(e, action)
    }
}


function* updateRepairStartedAtSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateRepairStartedAt, 
            action.metaData, 
            action.payload
        )
        yield put(showToast('RepairStartedAt data changed'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateAdditionalDamageDataSaga(action) {
    try {

        const payload = removePropertiesWithEmptyStrings(action.payload)

        const vehicleReceivedAt = payload.vehicleReceivedAt ?? null
        yield resourceCall(
            api.gateway.damage.additionalProperties.updateVehicleReceivedAt, 
            action.metaData, 
            {vehicleReceivedAt}
        )

        const finalRepairStartedAt = payload.finalRepairStartedAt ?? null
        yield resourceCall(
            api.gateway.damage.additionalProperties.updateFinalRepairStartedAt, 
            action.metaData, 
            {finalRepairStartedAt}
        )

        const repairCompletedAt = payload.repairCompletedAt ?? null
        yield resourceCall(
            api.gateway.damage.additionalProperties.updateRepairCompletedAt, 
            action.metaData, 
            {repairCompletedAt}
        )

        const vehicleReturnedAt = payload.vehicleReturnedAt ?? null
        yield resourceCall(
            api.gateway.damage.additionalProperties.updateVehicleReturnedAt, 
            action.metaData, 
            {vehicleReturnedAt}
        )

        yield put(showToast('Damage data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateRepairScheduleDataSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateDamageRepairSchedule, 
            action.metaData, 
            action.payload
        )
        yield put(showToast('Repair schedule saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateDamageWatcher() {
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE, updateDamageSaga)
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE_ASSIGNEE, updateDamageAssigneeSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_REPAIR_AUTHORIZATION, updateRepairAuthorizationSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_COMPREHENSIVE_DATA, updateComprehensiveDataSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_LIABILITY_INSURANCE_AND_CLAIM, updateLiabilityInsuranceAndClaimSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_VEHICLE_PROPRIETOR, updateVehicleProprietorSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_REPAIR_STARTED_AT, updateRepairStartedAtSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_ADDITIONAL_DAMAGE_DATA, updateAdditionalDamageDataSaga)
    yield takeLatest(ACTIONS.DAMAGE_MANAGER_UPDATE_REPAIR_SCHEDULE, updateRepairScheduleDataSaga)
}