import { BREAKPOINTS } from 'application/constants'

export const theme = {
    color: {
        primary: '#284899', //blue
        primaryHover: '#6583d0',
        primaryHoverDark: '#1c3268',
        primaryBackground: '#c0ceee', //very light blue 
        transparent: 'rgba(0,0,0,0)',
        white: '#ffffff',
        smokewhite: '#f5f5f5',
        lightRed: '#ffd3d6',
        red: '#bc3640',
        black: '#000000',
        black10: '#E6E6E6',
        black20: '#C6C6C6',
        green: '#28a35b',
        darkGreen: '#14733c',
        yellow: '#ffba00',
        lightGray: '#fafafa',
        gray5: '#f5f5f5',
        gray10: '#ededed',
        gray15: '#d9d9d9',
        gray20: '#cccccc',
        gray70: '#4d4d4d',
        anthracite: '#404040',
        anthracite50: '#A0A0A0',
        anthraciteLight: '#807f7f',
        iceBlue: '#c4e7ff',
        iceBlue50: '#f0f9ff',
        lightTurqouise: '#f4fcff',
        lightPurple: '#ebe6ff',
        purple: '#5136b9',
        powderGray: '#616b78',
        lightPowderGray: '#9a9eab',
        notificationSuccess: '#55d17b',
        error: '#ce1d1d',
        errorBackground: '#ffd6d6',
        warning: '#ffa500',
        warningBackground: '#fff1d9',
        success: '#4bb543',
        successBackground: '#dbf1d9',
        salmon: '#f7ebeb',
        //charts
        orange: '#d07626',
        lime: '#8bb705',
        //branchmanagement:
        rolePrimary: '#ff9000',
        groupPrimary: '#52a41c',
        branchPrimary: '#284899',
        feedbackColor: '#ed770c',
        announcementColor: '#f8b608'
    },
    iconSize: {
        extraLarge: '110px'
    },
    fontSize: {
        headline1: '24px',
        headline2: '22px',
        headline3: '20px',
        medium18: '18px',
        medium: '16px',
        standard: '14px',
        small: '12px',
        xSmall: '10px',
        // custom
        medium32: '32px',
        xLarge: '48px',
        large: '40px',
        xxxLarge: '80px',
        xxLarge: '64px',
        
    },
    fontWeight: {
        headLine1: '700',
        headLine2: '300',
        headLine3: '700',
        medium: '300',
        medium18: '400',
        standardBold: '700',
        standardRegular: '400',
        standardLight: '300',
        smallBold: '700',
        smallRegular: '400',
        smallLight: '300',
        xBold: '900',
        normal: 'normal'
    },
    fontStyle: {
        default: 'normal',
        italic: 'italic'
    },
    breakpoint: {
        xSmall: BREAKPOINTS.X_SMALL,
        small: BREAKPOINTS.SMALL,
        medium: BREAKPOINTS.MEDIUM,
        large: BREAKPOINTS.LARGE,
        xLarge: BREAKPOINTS.X_LARGE,
        vSmall: BREAKPOINTS.V_SMALL,
        //custom
        mobileS: BREAKPOINTS.MOBILE_S,
        mobileM: BREAKPOINTS.MOBILE_M,
        mobileL: BREAKPOINTS.MOBILE_L,
        tablet: BREAKPOINTS.TABLET,
        laptop: BREAKPOINTS.LAPTOP,
        laptopL: BREAKPOINTS.LAPTOP_L,
        desktop: BREAKPOINTS.DESKTOP,
    },
    thumbnail: {
        width: '64',
        height: '80'
    },
    transition: {
        fast: '0.1s'
    },
    dialog: {
        size: {
            small: 480,
            medium: 740,
            medium50: 905,
            large: 1110
        }
    },
    disabledOpacity: '0.5',
    treeView: {
        lineWeight: '2px',
        lineColor: '#284899',
        nodeDimension: 52, //height of the node
        nodePadding: '10px'
    }
}
