import { ACTIONS } from 'application/constants'

export const setSharedResourceData = (sharedResourceId, sharedResourceType) => ({
    type: ACTIONS.SHARED_RESOURCE_SET_SHARED_RESOURCE_DATA,
    sharedResourceId, 
    sharedResourceType
})

export const getSharedResourceById = (
    sharedResourceId=''
) => ({
    type: ACTIONS.SHARED_RESOURCE_GET_SHARED_RESOURCE_BY_ID,
    sharedResourceId
})

export const populateSharedResource = (
    sharedResource = null
) => ({
    type: ACTIONS.SHARED_RESOURCE_POPULATE_SHARED_RESOURCE,
    sharedResource
})

export const getSharedResourceData = (
) => ({
    type: ACTIONS.SHARED_RESOURCE_GET_SHARED_RESOURCE_DATA
})

export const populateSharedResourceData = (
    data
) => ({
    type: ACTIONS.SHARED_RESOURCE_POPULATE_SHARED_RESOURCE_DATA,
    data
})

export const setReturnUrl = (returnUrl) => ({
    type: ACTIONS.SHARED_RESOURCE_SET_RETURN_URL,
    returnUrl
})

export const logout = (sharedResourceId) => ({
    type: ACTIONS.SHARED_RESOURCE_LOGOUT,
    sharedResourceId
})

export const updatePin = (pin) => ({
    type: ACTIONS.SHARED_RESOURCE_UPDATE_PIN,
    pin
})

export const validatePinRequest = (pin, sharedResourceId, storePin = false) => ({
    type: ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_REQUEST,
    pin,
    sharedResourceId,
    storePin
})

export const validatePinSuccess = (pin, sharedResourceId) => ({
    type: ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_SUCCESS,
    pin,
    sharedResourceId
})

export const validatePinFailed = () => ({
    type: ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_FAILED,
})

export const populateTokenList = (tokenList = []) => ({
    type: ACTIONS.SHARED_RESOURCE_POPULATE_TOKEN_LIST,
    tokenList
})
