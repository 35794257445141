import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'


const StyledImage = styled.img`
    max-height: 200px;
`

export const AccountLogoViewer = (
    {
        filePath
    }
) => {
    return <StyledImage 
        src={filePath} 
        className='jestAccountAccountLogoViewer' 
    />
}

AccountLogoViewer.propTypes = {
    filePath: PropTypes.string.isRequired
}
