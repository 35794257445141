import styled from 'styled-components'
import { NavLink } from 'react-router-dom'


export const StyledTableRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: ${props => props.width ? props.width : '100%'};
    align-items: center;
    padding:0 8px;
    min-height: ${props => props.cssHeight};   
    text-decoration: none;
    color: ${props => props.theme.color.anthracite};
    cursor: pointer;
    background:${props => props.backgroundColor}; 
    border-top: 2px solid ${props => props.borderColor}; 
    border-bottom: 2px solid ${props => props.borderColor}; 
    box-sizing: border-box;
    & *:not(.context-menu){
        cursor: inherit !important;
    }
`

export const StyledTableRowLink = styled(NavLink)`
    display: flex;
    justify-content: space-between;
    width: ${props => props.width ? props.width : '100%'};
    align-items: center;
    padding:0 8px;
    min-height: ${props => props.cssheight};   
    text-decoration: none;
    color: ${props => props.theme.color.anthracite};
    cursor: pointer;
    box-sizing: border-box;

    background:${props => props.rowcolor}; 
    border-bottom: 2px solid ${props => props.bordercolor}; 


    &:link, 
    &:visited {
        color: ${props => props.theme.color.anthracite};
    }

    &:hover {
        background: ${props => props.theme.color.gray10};
        border-bottom-color: ${props => props.theme.color.gray10};
    }
    & *:not(.context-menu){
        cursor: inherit !important;
    }
`

export const StyledTableHeader = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.color.gray15};
    padding: 4px 8px;
    height: 28px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    justify-content: space-between;
    width: ${props => props.width ? props.width : '100%'};
`


export const StyledTableCell = styled.div`
    text-decoration: none !important;
    flex: 0 0 ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    min-width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    max-width: ${props => props.cssMaxWidth !== undefined ? props.cssMaxWidth : '100%'};
    font-size: ${props => props.isRow ? props.theme.fontSize.standard : props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    display: flex;
    flex-direction: ${props => props.column ? 'column' : 'row'};
    flex-grow: ${props => props.flexGrow ? props.flexGrow : '1'};
    flex-shrink: ${props => props.flexShrink ? props.flexShrink : '1'};
    align-items: center;
    word-break: break-all;
`

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: ${props => props.width ? props.width : '100%'};
    align-items: center;
    padding:0 8px;
    min-height: ${props => props.cssHeight};   
    text-decoration: none;
    color: ${props => props.theme.color.anthracite};
    box-sizing: border-box;
    background:${props => props.isColoured 
        ? props.theme.color.gray5 
        : props.theme.color.white }; 

    &:link, 
    &:visited {
        color: ${props => props.theme.color.anthracite};
    }
`

export const ContextMenuWrapper = styled.div`
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
`