import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { showToast } from 'application/redux/actions/notifications'

function* updateLawfirmSaga(action) {
    try {
        const lawfirm = action.payload
        const damageId = action.damageId
    
        yield resourceCall(
            api.gateway.damage.updateDamageLawfirm, 
            {
                damageId:  damageId
            },
            {
                name: lawfirm.name,
                webakteId: lawfirm.webakteId
            }
        )

        yield put(showToast('Commission updated'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateLawfirmWatcher() {
    yield takeLeading(ACTIONS.COMMON_UPDATE_LAWFIRM, updateLawfirmSaga)
}