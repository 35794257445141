import styled from 'styled-components'




export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`



export const ContextMenuWrapper = styled.div`
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
`