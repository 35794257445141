import { WidgetArea } from 'application/components/building_blocks/widget_area'
import { SubPageLayout } from 'application/components/pages/_common'
import { WIDGET_CONTEXTS } from 'application/constants'
import { useEffect } from 'react'

export const ControllingStatisticsComponent = ({
    accessibleBranchesLoaded,
    onLoad
}) => {

    useEffect(()=>{
        onLoad()
    }, [onLoad])

    return <SubPageLayout>
        {
            accessibleBranchesLoaded && <WidgetArea 
                widgetContext={WIDGET_CONTEXTS.CONTROLLING_GLOBAL_STATISTICS} 
                successively={false}
            />
        }
    </SubPageLayout>
}