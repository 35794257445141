import { BRANCH_MANAGEMENT } from 'application/constants'
import { buildNode } from './build_node'
// import { sortAlphabetically } from './sort_nodes'

export const getNodes = (roles, groups, branches, theme, branchesDisabled = true) => {
    const roleNodes = roles.map((role, i) => buildNode(role, BRANCH_MANAGEMENT.NODE_TYPE.ROLE, i, theme, roles.length, false, branchesDisabled))
    const groupNodes = groups.map((group, i) => buildNode(group, BRANCH_MANAGEMENT.NODE_TYPE.GROUP, i, theme, groups.length, false, branchesDisabled))
    const branchNodes = branches.map((branch, i) => buildNode(branch, BRANCH_MANAGEMENT.NODE_TYPE.BRANCH, i, theme, branches.length, branchesDisabled, branchesDisabled))

    return [...roleNodes, ...groupNodes, ...branchNodes]
}