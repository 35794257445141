import { ACTIONS } from 'application/constants'

export const getAccountContractorsRequest = () => ({
    type: ACTIONS.CONTRACTORS_REQUEST_ACCOUNT_CONTRACTORS_LIST
})

export const populateAccountContractors = (contractorsList) => ({
    type: ACTIONS.CONTRACTORS_POPULATE_ACCOUNT_CONTRACTORS_LIST,
    contractorsList
})

export const getBranchContractorsRequest = () => ({
    type: ACTIONS.CONTRACTORS_REQUEST_BRANCH_CONTRACTORS_LIST
})

export const populateBranchContractors = (contractorsList) => ({
    type: ACTIONS.CONTRACTORS_POPULATE_BRANCH_CONTRACTORS_LIST,
    contractorsList
})

export const syncBranchContractors = (branchId, contractorsList) => ({
    type: ACTIONS.CONTRACTORS_SYNC_BRANCH_CONTRACTORS,
    branchId, contractorsList
})

export const saveBranchContractorsRequest = (branchId, contractorsList) => ({
    type: ACTIONS.CONTRACTORS_SAVE_BRANCH_CONTRACTORS,
    branchId, contractorsList
})