import React from 'react'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'
import { exists } from 'application/common/data_helpers'

export const ExternalTaskMarker = withTheme(({ task, theme }) => {
    const {t} = useTranslation()

    if(!exists(task.creator) || !task.creator.isExternal){
        return null
    }

    return <React.Fragment>
        <FontIcon
            title={t('External task')}
            icon='directions' 
            color={theme.color.warning}
            fontSize={theme.fontSize.medium18}
        />
        <Spacer basis='2px' />
    </React.Fragment>
})
