import React from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneNumberInput, TextInput } from 'application/components/controls/form'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { validationMethods } from 'application/common'
import { TwoColumns } from 'application/components/forms/_common'

export const BranchDataPartialForm = ({
    nameBranchName = 'name',
    nameStreet = 'address.line1',
    namePostalCode = 'address.postalCode',
    nameCity = 'address.locality',
    defaultValuePhoneNumber = undefined,
    register,
    errors,
    disabled = false,
    onChange = () => {},
    setValue,
    control
}) => {
    const {t} = useTranslation()
    const { mustBeEmail } = validationMethods
    const namePhoneNumber = 'contact.phoneNumber'
    const nameEmail='contact.email'

    return <React.Fragment>
        <TextInput
            label={t('Branch name')}
            name={nameBranchName}
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors[nameBranchName]}
            autoFocus={true}
            onChange={onChange}
        />
        <AddressDataPartialForm 
            register={register}
            errors={errors}
            nameStreet={nameStreet}
            namePostalCode={namePostalCode}
            nameCity={nameCity}
            disabled={disabled}
            onChange={onChange}
            setValue={setValue}
        />
        <TwoColumns 
            columnLeft={ 
                <TextInput
                    label={t('Email')}
                    name={nameEmail}
                    register={register}
                    validate={{
                        mustBeEmail
                    }}
                    error={errors?.contact?.email || null}
                    onChange={onChange}
                />
            }
            columnRight={
                <PhoneNumberInput
                    defaultValue={defaultValuePhoneNumber}
                    validate={null}
                    control={control}
                    label={t('Phone number (mobile or landline)')}
                    name={namePhoneNumber}
                    register={register}
                    error={errors?.contact?.phoneNumber || null}
                    onChange={onChange}
                    setValue={setValue}
                    allowLandLine={true}
                />
            }
        />
    </React.Fragment>
}