import {
    StringEqualsFilter, StringContainsFilter,
    OrOperator,
} from '@ec/filter-sort-page-query-js'

export const customers = {
    isCompany: {
        filterName: 'customers_isCompany',
        filterKey: 'cuic',
        label: 'Customer is business customer',
        defaultValue: null,
        getFilterQuery: (value) => ([
            new StringEqualsFilter('isCompany', value, true)
        ])
    },
    name: {
        filterName: 'customers_name',
        filterKey: 'cun',
        label: 'Name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('lastName', value, false),
            new OrOperator(),
            new StringContainsFilter('companyName', value, false)
        ])
    },
    preferredVehicleLicensePlate: {
        filterName: 'customers_preferred_vehicle_licensePlate',
        filterKey: 'cupvl',
        label: 'License Plate',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('preferredVehicleInfo.LicensePlate', value, false)
        ])
    },
    findPrivate: {
        filterName: 'private_customers_name',
        filterKey: 'pcun',
        label: 'Private customer name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('lastName', value, false)
        ])
    },
    findPrivateLastName: {
        filterName: 'private_customers_last_name',
        filterKey: 'pculn',
        label: 'Private customer last name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('lastName', value, false)
        ])
    },
    preferredVehicleChassisNumber: {
        filterName: 'customers_preferred_vehicle_chassis_number',
        filterKey: 'cupvc',
        label: 'Private customer last name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('preferredVehicleInfo.ChassisNumber', value, false)
        ])
    },
    findCompany: {
        filterName: 'company_customers_name',
        filterKey: 'ccun',
        label: 'Chassis number',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('companyName', value, false)
        ])
    },
    businessCustomers: {
        filterName: 'customers_businessCustomers',
        filterKey: null,
        label: 'Business customers',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StringEqualsFilter('isCompany', 'true', true)
        ])
    },
    privateCustomers: {
        filterName: 'customers_privateCustomers',
        filterKey: null,
        label: 'Private customers',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StringEqualsFilter('isCompany', 'false', true)
        ])
    }
}