import {ACTIONS} from 'application/constants'


export const usersGetUsersRequest = (
    offset, 
    limit, 
    orderBy,  
    direction,
    filterQuery
) => ({
    type: ACTIONS.USERS_GET_USERS_REQUEST,
    offset, 
    limit, 
    orderBy,  
    direction,
    filterQuery
})

export const usersSetUsersLoading = () => ({
    type: ACTIONS.USERS_SET_USERS_LOADING
})

export const usersPopulateUsers = (usersList) => ({
    type: ACTIONS.USERS_POPULATE_USERS,
    usersList
})