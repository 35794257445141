import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import { ShortenedString } from 'application/components/controls/shortened_string'

export const RepairAuthorizationChangedActivity = (
    { activity }
) => {
    const { t } = useTranslation()

    const oldStatus = t(activity.data?.oldRepairAuthorization) || ''
    const newStatus = t(activity.data?.newRepairAuthorization) || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Repair authorization has been changed')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            <ShortenedString
                text={oldStatus}
                shortenAt={25}
            />
            <RightArrow />
            <ShortenedString
                text={newStatus}
                shortenAt={25}
            />
        </Fragments.ActivityParagraph>
    </React.Fragment>
}