import React, { useRef } from 'react'
import { useTimeline } from 'application/components/hooks'
import { MobileTimeLine, MobileTimeLineStep } from 'application/components/building_blocks/mobile_time_line'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { ActionButtonBar } from 'application/components/controls/action_button_bar'
import { Step0, Step1, Step2, Step3 } from './steps'
import { FormRenderer } from 'application/modules/dynamic-form'

export const KickstartExternalExtendedContent = ({
    customerFormHook,
    setCustomerData,
    kickstartFormRendererStructure,
    setDynamicFormData,
    commissionFormHook,
    commissionExpert,
    setCommissionExpert,
    commissionLawfirm,
    setCommissionLawfirm,
    commissionOtherFiles,
    setCommissionOtherFiles,
    lawfirmFormHook,
    lawfirmFiles,
    setLawfirmFiles,
    branch,
    selectedLawfirm,
    tempDamageData,
    customer,
    handleCreateDamageKickstart,
    damageFiles,
    setDamageFiles,
    account,
    damageDataSent,
    handleCommission,
    commissionDekraFormHook,
    commissionDekra,
    setCommissionDekra,
    commissionDekraFiles,
    setCommissionDekraFiles
}) => {
    const {t} = useTranslation()

    const {
        currentTimelineStepIndex,
        gotoNextStep,
        gotoPreviousStep,
    } = useTimeline(5)

    const formSubmitButtonRef = useRef(null)


    return <MobileTimeLine>

        <MobileTimeLineStep
            index={0}
            isActive={currentTimelineStepIndex === 0}
            label={t('Customer data')}
            buttons={
                <ActionButton
                    buttonText={t('Continue')}
                    onButtonClick={customerFormHook.handleSubmit(() => {
                        setCustomerData(customerFormHook.getValues())
                        gotoNextStep()
                    })}
                />
            }
        >
            <Step0
                formHook={customerFormHook}
            />
        </MobileTimeLineStep>

        <MobileTimeLineStep
            index={1}
            isActive={currentTimelineStepIndex === 1}
            label={t('Accident report')}
            buttons={
                <ActionButton
                    buttonText={t('Continue')}
                    onButtonClick={() => {
                        formSubmitButtonRef.current.click()
                    }}
                />
            }
        >
            <FormRenderer
                ref={formSubmitButtonRef}
                formRendererDescription={kickstartFormRendererStructure}
                onSubmitFormData={(data) => {
                    setDynamicFormData(data)
                    gotoNextStep()
                }}/>
        </MobileTimeLineStep>

        <MobileTimeLineStep
            index={2}
            isActive={currentTimelineStepIndex === 2}
            label={t('Hand over')}
            buttons={
                <ActionButtonBar inline={true} buttonsAlign='flex-end'>
                    <ActionButton
                        buttonText={t('Back')}
                        onButtonClick={gotoPreviousStep}
                        secondary={true}
                    />
                    <ActionButton
                        buttonText={t('Continue')}
                        onButtonClick={handleCommission(gotoNextStep)}
                    />
                </ActionButtonBar>
            }
        >
            <Step1
                commissionExpert={commissionExpert}
                setCommissionExpert={setCommissionExpert}
                commissionLawfirm={commissionLawfirm}
                setCommissionLawfirm={setCommissionLawfirm}
                formHook={commissionFormHook}
                setCommissionOtherFiles={setCommissionOtherFiles}
                commissionOtherFiles={commissionOtherFiles}

                lawfirmFormHook={lawfirmFormHook}
                setLawfirmFiles={setLawfirmFiles}
                lawfirmFiles={lawfirmFiles}  
                selectedLawfirm={selectedLawfirm}

                branch={branch}
                tempDamageData={tempDamageData}
                customer={customer}
                setCommissionDekra={setCommissionDekra}
                commissionDekra={commissionDekra}
                setCommissionDekraFiles={setCommissionDekraFiles}
                commissionDekraFiles={commissionDekraFiles}
                commissionDekraFormHook={commissionDekraFormHook}
            />

        </MobileTimeLineStep>

        <MobileTimeLineStep
            index={3}
            isActive={currentTimelineStepIndex === 3}
            label={t('Images')}
            buttons={
                <ActionButtonBar inline={true} buttonsAlign='flex-end'>
                    <ActionButton
                        buttonText={t('Back')}
                        onButtonClick={gotoPreviousStep}
                        secondary={true}
                    />
                    <ActionButton
                        buttonText={t('Send accident report')}
                        onButtonClick={() => {
                            handleCreateDamageKickstart()
                            gotoNextStep()
                        }}
                    />
                </ActionButtonBar>
            }
        >
            <Step2
                branch={branch}
                commissionLawfirm={commissionLawfirm}
                selectedLawfirm={selectedLawfirm}
                tempDamageData={tempDamageData}
                currentTimelineStepIndex={currentTimelineStepIndex}
                files={damageFiles}
                setFiles={setDamageFiles}
            />

        </MobileTimeLineStep>

        <MobileTimeLineStep
            index={4}
            isActive={currentTimelineStepIndex === 4}
            label={t('Summary')}
            isLast={true}
        >
            <Step3
                damageData={tempDamageData}
                commission={{
                    commissionExpert,
                    commissionLawfirm,
                    data: {
                        ...commissionFormHook.getValues(),
                        selectedLawfirm
                    }
                }}
                accountName={account.name}
                damageDataSent={damageDataSent}
            />
        </MobileTimeLineStep>

    </MobileTimeLine>
}