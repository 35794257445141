import { put, debounce } from 'redux-saga/effects'
import { ACTIONS} from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'


function* getExpertsFromSearchSaga(action) {
    try {
        yield put(
            updateDialogDataByName(
                action.dialogName, 
                {
                    foundExperts: [], 
                    foundExpertsLoading: true
                }
            )
        )
        const usersResponse = yield resourceCall(
            api.gateway.users.getUsersList, 
            {
                offset: action.offset, 
                limit: action.limit,
                orderBy: 'id',
                direction: 'desc',
                filterQuery:  new ListFilter(
                    filters.users.experts
                ).setValue(action.searchTerm).getQuery()
            }
        )
        yield put(
            updateDialogDataByName(
                action.dialogName,
                { 
                    foundExperts:  usersResponse.users,
                    foundExpertsLoading: false
                }
            )
        )
    } catch (e) {
        yield handleError(e, action)
    }
}


export function* getExpertsFromSearchWatcher() {
    yield debounce(
        1000, 
        ACTIONS.DIALOG_GET_SEARCHED_EXPERTS_REQUEST_DEBOUNCED, 
        getExpertsFromSearchSaga
    )
}