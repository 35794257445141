import React, { 
    useState 
} from 'react'
import { useTranslation } from 'react-i18next'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { CreateUserDialogLayout } from '../_common/layout'
import { Select } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { withCreateUserSteps } from '../_common/with_create_user_steps'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { useStatefulForm } from 'application/components/hooks'




const AssignPreferredBranch = withCreateUserSteps(2)(
    (
        { 
            abort, 
            onCreateUser, 
            dialog,
            getCreateUserStepsState
        }
    ) => {
        const { t } = useTranslation()

        const {
            accessibleBranches
        } = dialog.payload

        const initialBranchOptions = accessibleBranches.length > 0 
            ? accessibleBranches.map(
                (branch) => {
                    return {
                        label: branch.name,
                        value: branch.id
                    }
                }
            )
            : []

        const {
            register, 
            errors, 
            setValue,
            getValues,
            handleSubmit
        } = useStatefulForm()

        const [selectedOption, setSelectedOption] = useState(null)

        const handleGoingBack = () => {
            abort()
        }
  

        const onSubmitSuccess = () => {
            const payload = {
                preferredBranch: {
                    Id: getValues().preferredBranchId
                }
            }
            const metaData = {
                userId: dialog.payload.user.id
            }
            onCreateUser(
                dialog.name,
                payload, 
                metaData
            )
        }

        return <CreateUserDialogLayout
            getStepsState={getCreateUserStepsState}
            firstName={dialog.payload.user.firstName}
            lastName={dialog.payload.user.lastName}
            form = {
                <React.Fragment>
                    <InfoBox type={INFOBOX.TYPES.SUCCESS} direction='row' cssMargin='0'>
                        <FontIcon icon='person' />
                        <FontIcon icon='done' />
                        <Spacer basis='10px' />
                        <span>{t('User has been successfully created.')}</span>
                        <Spacer />
                    </InfoBox>
                    <Paragraph cssMargin='8px 0'>{t('You can now assign a preferred branch for this user or skip this step.')}</Paragraph>
                    <Spacer basis='20px' />
                    <Select
                        label={t('Assign branch')}
                        name='preferredBranchId'
                        register={register}
                        setValue={setValue}
                        options={initialBranchOptions}
                        error={errors.assignedTo}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        onChange={setSelectedOption}
                        noOptionsMessage={t('No branches available')}
                        placeholder={t('Select branch')}
                        defaultValue={selectedOption}
                    />
                </React.Fragment>
            }
            footerElements={
                <React.Fragment>
                    <IconButtonWithLabel
                        iconKey='not_interested' 
                        label={t('Cancel branch assigment')} 
                        onButtonClick={handleGoingBack} 
                    />
                    <ActionButton
                        buttonText={t('Assign branch')} 
                        onButtonClick={handleSubmit(onSubmitSuccess)}
                        disabled={getValues().assignedBranchId === null || null}
                    />
                </React.Fragment>
            }
        />
    }
)

export const AssignPreferredBranchComponent = withDialogDataLoader(AssignPreferredBranch)