import {ACTIONS} from 'application/constants'


export const updateVehicle = (
    damageId, 
    vehicleData
) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_UPDATE_VEHICLE,
    damageId, 
    vehicleData
})

//isn't sent to api, life update redux store after customer change
export const syncVehicleKeeper = (
    vehicleKeeper
) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_SYNC_VEHICLE_KEEPER,
    vehicleKeeper
})


export const populateVehicle = (
    vehicleData
) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_POPULATE_VEHICLE,
    vehicleData
})

export const updateDamageAssignee = (damageId, userId, firstName = '', lastName = '') => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE_ASSIGNEE,
    damageId,
    userId,
    firstName,
    lastName
})

export const syncDamageAssignee = (userId, firstName = '', lastName = '') => ({
    type: ACTIONS.DAMAGE_MANAGER_SYNC_DAMAGE_ASSIGNEE,
    userId,
    firstName,
    lastName
})

export const requestUpdateDocument = (
    damageId,
    attachmentId,
    payload
) => ({
    type: ACTIONS.DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT,
    damageId,
    attachmentId,
    payload
})

export const refreshSharedLawfirm = (damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_REFRESH_LAWFIRM,
    damageId
})