import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { ActionButton } from 'application/components/controls/action_button'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { BreakLine32 } from 'application/components/pages/external/welcome/fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import { useGravatar } from 'application/components/hooks/use_gravatar'
import { useCustomerDataPlausibility, useStatefulForm } from 'application/components/hooks'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { useEffect } from 'react'
import { CustomerDataPartialForm } from 'application/components/forms/_partial_forms/customer_data_partial_form'
import { emptyCustomer } from 'application/common/empty_customer'




export const CustomerDataStep = (
    {
        goToPreviousStep,
        goToNextStep,
        customer,
        setCustomer,
        onDataChange
    }
) => {
    const { t } = useTranslation()

    const defaultValues = {
        ...emptyCustomer,
        ...customer
    }

    const formHook = useStatefulForm({defaultValues})

    const {
        handleSubmit,
        getValues,
    } = formHook



    const {
        firstName,
        lastName,
        isCompany,
        companyName
    } = customer

    const {
        getInitials,
        getFullName,
        setFirstName,
        setLastName,
        setCompanyName
    } = useGravatar(
        firstName,
        lastName,
        isCompany,
        companyName
    )

    useEffect(
        () => {
            setFirstName(firstName)
        },
        [firstName, setFirstName]
    )

    useEffect(
        () => {
            setLastName(lastName)
        },
        [lastName, setLastName]
    )

    useEffect(
        () => {
            setCompanyName(companyName)
        },
        [companyName, setCompanyName]
    )



    const handleStepBack = () => {
        setCustomer({
            ...customer,
            ...getValues()
        })
        goToPreviousStep()
    }

    const {
        customerDataIsPlausible
    } = useCustomerDataPlausibility(customer)

    const customerInfoText = customerDataIsPlausible
        ? 'It is recommended to check the customer data before hiring a lawyer.'
        : 'Please provide at least email or phone number.'

    const customerInfoBoxType = customerDataIsPlausible
        ? INFOBOX.TYPES.STANDARD
        : INFOBOX.TYPES.WARNING

    const onSubmitSuccess = () => {
        setCustomer({
            ...customer,
            ...getValues()
        })
        goToNextStep()
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='row'>
                <FlexBox flexDirection='column' width='360px'>
                    <SubHeadline>{t('Customer')}</SubHeadline>
                    <BreakLine32 />
                    <FlexBox justifyContent='center'>
                        <GravatarComponent
                            getInitials = {getInitials}
                            getFullName = {getFullName}
                            cssWidth='100%'
                        >
                            <InfoBox
                                type={customerInfoBoxType}
                                direction='row'
                                cssMargin='0'
                            >
                                <FontIcon icon='info_outline' />
                                <Spacer basis='10px' />
                                <FlexBox flexDirection='column'>
                                    <span>
                                        {t(customerInfoText)}
                                    </span>
                                    <PushBottom16 />
                                </FlexBox>
                            </InfoBox>
                        </GravatarComponent>
                    </FlexBox>
                </FlexBox>

                <Spacer basis="32px" />

                <FlexBox
                    flexBasis='60%'
                    flexDirection='column'
                >
                    <CustomerDataPartialForm
                        formHook={formHook}
                        onDataChange={onDataChange}
                        onFirstNameChange={setFirstName}
                        onLastNameChange={setLastName}
                        onCompanyNameChange={setCompanyName}
                    />

                </FlexBox>



            </FlexBox>




            <PushBottom32/>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back'
                label={t('Back')}
                onButtonClick={handleStepBack}
            />
            <ActionButton
                buttonText={t('Next')}
                onButtonClick={handleSubmit(onSubmitSuccess)}
            />
        </StepsLayoutFooter>


    </React.Fragment>
}
