import React from 'react'
import { ContentWrapper, HeadlineWrapper } from './fragments'

export const StaticCollapsable = ({headline = '', children, background = ''}) => {

    return <React.Fragment>
        {
            headline !== '' && <HeadlineWrapper background={background} collapsed={false}>
                <span>{headline}</span>
            </HeadlineWrapper>
        }
        <ContentWrapper background={background} collapsed={false}>
            {children}
        </ContentWrapper>
    </React.Fragment>
}