import { DIALOG } from 'application/constants'
import { sendPayloadRequest, switchDialog } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteUserComponent } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteUser: (
            userId, 
            payload, 
            successMsg
        ) => {
            dispatch(
                sendPayloadRequest(
                    DIALOG.NAME.DELETE_USER, 
                    { 
                        userId, 
                        successMsg 
                    }, 
                    payload
                )
            )
        },
        onEditUser: (
            nameToClose,
            nameToOpen,
            initiallyLoaded, 
            payload,
            title
        ) => {
            dispatch(
                switchDialog(
                    nameToClose,
                    nameToOpen,
                    initiallyLoaded, 
                    payload,
                    title
                )
            )
        }
    }
}

export const DeleteUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteUserComponent)