import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const activityLogReducer =  (state = defaultStore.pages.workflowPanel.activityLog, action) => {
    switch (action.type) {
    case ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_REQUEST:
        return {
            ...state,
            isLoaded: false,
            isLoading: true,
        }
    case ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_POPULATE_DATA:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
            activities: [...state.activities, ...action.activities],
            total: action.total
        }
    case ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_MORE_REQUEST:
        return {
            ...state,
            isLoading: true,
        }
        
    default:
        return state
    }
}
