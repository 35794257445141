import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageShareSaga(action) {

    const { damageId } = action.match.params

    yield resourceCall(
        api.gateway.damage.getDamage,
        { damageId }
    )

    yield put(
        updateWidget(
            action.key,
            action.dependentWidgetKeys,
            {
                damageId
            }
        )
    )
}