import { apiCall } from 'application/api/helpers'
import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'

export const updateFinalRepairStartedAt = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/FinalRepairStartedAt`, 
                HTTP_METHOD.PUT, 
                payload
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
)