import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import {DeleteAccountlogoComponent} from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
    onDeleteAccountlogo: (dialogName) => {
        dispatch(  
            sendPayloadRequest(
                dialogName
            )
        )
    },
})

export const DeleteAccountlogo = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteAccountlogoComponent)