import { useCallback, useMemo } from 'react'

export const useLifeCycleStatesMappings = (customStates, unmappedStates = [], maxCustomStates = 20) => {
    
    //data structure for tree view in browser
    const treeCustomStates = useMemo(() => {
        return customStates.reduce(function (r, a) {
            r[a.lifeCycleState] = r[a.lifeCycleState] || []
            r[a.lifeCycleState].push(a)
            return r
        }, Object.create(null))
    }, [customStates])

    //data structure for server calls to update life cycle mappings
    const getRequestCustomStates = useCallback((cs) => {
        return cs.reduce(
            (obj, item) => Object.assign(obj, {
                [item.id]: {
                    lifeCycleState: item.lifeCycleState,
                    index: item.index 
                }
            }), {})
    }, [])

    const requestCustomStates = useMemo(() => {
        return getRequestCustomStates(customStates)
    }, [customStates, getRequestCustomStates])

    //update index of custom state
    const updateIndex = useCallback((lifeCycleState, treeData)=>{
        const updatedCustomStates = customStates.map((c, i) => {
            return treeData.some(td => td.id === c.id)
                ? {
                    ...c,
                    index: treeData.findIndex(td => td.id === c.id)
                }
                : c
        })
        return getRequestCustomStates(updatedCustomStates)
    }, [customStates, getRequestCustomStates])

    const maxCustomStatesReached = customStates.length + unmappedStates.length >= maxCustomStates

    return {
        treeCustomStates,
        requestCustomStates,
        updateIndex,
        maxCustomStatesReached
    }

}