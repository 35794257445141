import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'
import { ShortenedString } from 'application/components/controls/shortened_string'

const RepairAuthorizationChangedInfo = (
    { activityData }
) => {

    const { t } = useTranslation()

    const {
        damage,
    } = activityData

    const oldStatus = t(activityData?.oldRepairAuthorization) || ''
    const newStatus = t(activityData?.newRepairAuthorization) || ''

    return <FlexBox>
        <React.Fragment>
            {t('Damage')} : {damage.licensePlate}, &nbsp;
        </React.Fragment>
        <ShortenedString
            text={oldStatus}
            shortenAt={20}
        />
        <RightArrow />
        <ShortenedString
            text={newStatus}
            shortenAt={20}
        />
    </FlexBox>
}



RepairAuthorizationChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}



export { RepairAuthorizationChangedInfo } 