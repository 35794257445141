import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiFilestream, getDownloadUrl, downloadFile } from 'application/api/helpers'

export const downloadDocumentById = (params) => (
    {
        authenticated: payload => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/documents/${params.documentId}/file`, 
                HTTP_METHOD.GET
            )
            return apiFilestream(request)
                .then(getDownloadUrl)
                .then(downloadUrl => {
                    downloadFile(downloadUrl, payload.documentName)
                })
        },
        sharedResource: payload => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/documents/${params.documentId}/file`,
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiFilestream(request)
                .then(getDownloadUrl)
                .then(downloadUrl => {
                    downloadFile(downloadUrl, payload.documentName)
                })
        },
    }
)