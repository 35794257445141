import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { CommissionLawfirmComponent } from './component'
import { updateLawfirm } from 'application/redux/actions/common/lawfirm'

const mapStateToProps = (state) => ({
    branchId: state.pages.damageManager.shared.damage.branchId,
    damageState: state.pages.damageManager.shared.damage.state,
    statusList: state.common.statusList
})

const mapDispatchToProps = dispatch => {
    return {
        openDialogCreateLawyers: ( 
            damageId, 
            changeMode,
            branchId
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMISSION_LAWFIRM, 
                false, 
                { 
                    lawyersList: [], 
                    lawyersListLoading: false, 
                    changeMode, 
                    damageId, 
                    branchId,
                    lawfirm: null
                }, 
                'Commission lawfirm'
            )
        ),
        openDialogDeleteLawfirm: ( 
            lawfirm,
            damageId
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_LAWFIRM, 
                false, 
                { 
                    lawfirm,
                    damageId
                }, 
                'Cancel lawfirm commission'
            )
        ),
        updateLawfirm: (damageId, lawfirm) => {
            dispatch(updateLawfirm(damageId, lawfirm))
        }
    }
}

export const CommissionLawfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionLawfirmComponent)