import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { Link } from 'application/components/controls/hyperlink'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const TaskCommentCreatedMetaContent = (
    {
        notification, 
        inline,
        onLinkClick
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification

    const {
        internalAccountLink
    } = useApplicationLinks()

    const damageLink =  internalAccountLink(
        `/damages/${payload.damage.id}/tasks/${payload.taskId}`
    )
        
    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='Task name'
            value={payload.taskTitle}
        />
        <Fragments.ActivityDescription 
            label='Comment'
            value={payload.content}
        />
        <Fragments.ActivityLink inline={inline}>
            <Link 
                onClick={onLinkClick} 
                to={damageLink}
            >
                {t('jump to task')}
            </Link>
        </Fragments.ActivityLink>
    </Fragments.MetaContentContainer>
}