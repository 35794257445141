import React from 'react'
import { connect } from 'react-redux'
import { CustomHandle } from './fragments'
import { BRANCH_MANAGEMENT } from 'application/constants'

const RightHandleComponent = ({nodeType, groupIds, branchIds, branchesDisabled, data}) => {

    if(nodeType === BRANCH_MANAGEMENT.NODE_TYPE.BRANCH){
        return null
    }

    const isConnectable = (nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP && !branchesDisabled && !data.isPredefined && !data.canAccessAllBranches)
                        || (nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE && (!branchesDisabled || data.includesGlobalPermissions))

    const isDisabled = (nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP && branchesDisabled) 
                        || (branchesDisabled && nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE && !data.includesGlobalPermissions)
                        || !isConnectable

    return <CustomHandle
        type="source"
        position="right"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
        isValidConnection={(connection)=>{
            if(nodeType === BRANCH_MANAGEMENT.NODE_TYPE.ROLE){
                return groupIds.includes(connection.target)
            }
            if(nodeType === BRANCH_MANAGEMENT.NODE_TYPE.GROUP){
                return branchIds.includes(connection.target)
            }
            return false
        }}
        $isDisabled={isDisabled}
    />
}

export const RightHandle = connect(state => ({ 
    branchIds: state.common.allBranches.map(r => r.id),
    groupIds: state.common.userGroups.map(r => r.id)
}))(RightHandleComponent)