import { connect } from 'react-redux'

import { updateRepairAuthorization } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageRepairAuthorizationFormComp } from './component'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onUpdateRepairAuthorization: (damageId, approvalType) => dispatch(updateRepairAuthorization(damageId, approvalType))
    }
}

export const TaskDamageRepairAuthorizationForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageRepairAuthorizationFormComp)