import { ACTIONS } from 'application/constants'

export const openDialogByName = (
    name, 
    initiallyLoaded = true, 
    payload = {}, 
    title = ''
) => ({
    type: ACTIONS.DIALOG_OPEN_DIALOG_BY_NAME,
    name,
    initiallyLoaded, 
    payload,
    title
})

export const closeDialogByName = (name) => ({
    type: ACTIONS.DIALOG_CLOSE_DIALOG_BY_NAME,
    name
})

export const closeAllDialogs = () => ({
    type: ACTIONS.DIALOG_CLOSE_ALL
})

export const populateDialogContentByName = (
    name, 
    payload
) => ({
    type: ACTIONS.DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME,
    name,
    payload
})

export const updateDialogDataByName = (
    name, 
    payload
) => ({
    type: ACTIONS.DIALOG_UPDATE_DIALOG_DATA_BY_NAME,
    name,
    payload
})

export const getDialogDataRequest = (
    dialogName, 
    metaData
) => ({
    type: ACTIONS.DIALOG_REQUEST_DIALOG_DATA,
    dialogName,
    metaData
})

// sending payload - save, create, edit
export const sendPayloadRequest = (
    dialogName, 
    payload={}, 
    metaData={}
) => ({
    type: ACTIONS.DIALOG_SEND_DIALOG_DATA_BY_NAME,
    dialogName,
    payload,
    metaData
})

export const succeedLoaded = (name) => ({
    type: ACTIONS.DIALOG_SUCCEED_LOADED,
    name
})

/**
 * 
 * @param {string} nameToClose name of dialog to close
 * @param {string} nameToOpen  name of dialog to open
 * @param {boolean} initiallyLoaded  is new opened dialog initially loaded?
 * @param {object} payload payload for new opened dialog
 * @param {string} title translatable title from new opened dialog
 * @returns new list of dialogs in reducer
 */
export const switchDialog = (
    nameToClose,
    nameToOpen,
    initiallyLoaded = true, 
    payload = {}, 
    title = ''
) => ({
    type: ACTIONS.DIALOG_SWITCH_DIALOG,
    nameToClose,
    nameToOpen,
    initiallyLoaded, 
    payload,
    title
})