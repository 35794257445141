import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import {ShortenedString} from 'application/components/controls/shortened_string'

export const DamageDescriptionChangedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const oldDescription = activity.data?.oldDescription || t('Unkown')
    const newDescription = activity.data?.newDescription || t('Unkown')

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Damagedescription changed')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            <ShortenedString 
                text={oldDescription} 
                shortenAt={10}
            /> 
            <RightArrow /> 
            <ShortenedString 
                text={newDescription} 
                shortenAt={10}
            />
        </Fragments.ActivityParagraph>
    </React.Fragment>
}