import { useCallback } from 'react'
import { useStore } from 'react-redux'

export const useReduxStoreLookUp = () => {
    const store = useStore()

    const lookUp = useCallback((selectorFunction) => {
        return selectorFunction(store.getState())
    }, [store])

    return {
        lookUp
    }
}