import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput, TextInputGermanDate } from 'application/components/controls/form'
import { useStatefulForm } from 'application/components/hooks'
import { VehicleDataPartialForm } from '../_partial_forms'
import { validationMethods } from 'application/common'
import { TwoColumns } from 'application/components/forms/_common'
import { convertGermanDateStringToISODate, convertISODateToGermanDateString } from 'application/common/date_time_helpers'
import { VehicleStateSelect } from 'application/components/controls/form/vehicle_state_select'





const DamageVehicleForm = ({
    formData = {}, 
    damageId, 
    onSave
}) => {
    const {t} = useTranslation(),
        firstRegisteredAtInitialValue = convertISODateToGermanDateString(formData?.firstRegisteredAt || null),

        defaultValues = {
            licensePlate: '',
            chassisNumber: '', 
            make: '',
            model: '',
            ...formData,
            firstRegisteredAt: firstRegisteredAtInitialValue,
        },

        {
            register, 
            errors, 
            control,
            handleSubmit, 
            getValues, 
            formStateValues, 
            updateFormState,
            setValue
        } = useStatefulForm({ defaultValues }),

       
        
        onSubmitSuccess = () => { 

            const firstRegisteredAt = convertGermanDateStringToISODate(formStateValues?.firstRegisteredAt),

                payload = {
                    ...formStateValues, //include all values
                    ...getValues(),
                    firstRegisteredAt
                }

            onSave(
                damageId, 
                payload
            )
        }

    return <React.Fragment>
        <VehicleDataPartialForm 
            register={register}
            errorLicensePlate={errors.licensePlate}
            onChange={updateFormState}
            control={control}
        />
        <TwoColumns
            columnLeft={
                <TextInputGermanDate 
                    label={t('Date of first registration')}
                    name='firstRegisteredAt'
                    register={register}
                    validate={{ 
                        germanDateMustBeInThePast: validationMethods.germanDateMustBeInThePast 
                    }}
                    error={errors.firstRegisteredAt}
                    placeholder={'TT.MM.JJJJ'}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <TextInput 
                    label={t('Milage')}
                    name='milage'
                    register={register}
                    validate={{mustBeInteger: validationMethods.mustBeInteger}}
                    error={errors.milage}
                    onChange={updateFormState}
                />
            }
        />
        <TwoColumns 
            columnLeft={  
                <TextInput 
                    label={t('Chassis number')}
                    name='chassisNumber'
                    register={register}
                    validate={null}
                    error={errors.chassisNumber}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <VehicleStateSelect 
                    error={errors.repairAuthorization}
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.state}
                    onChange={updateFormState}
                />
            }
        />
        
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save vehicle data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </React.Fragment>
}

export { DamageVehicleForm }