import Moment from 'moment'
import { exists } from './data_helpers'
import moment from 'moment'

export const getOffset = (d) => {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
}

export const dateIsBefore = (d1, d2) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD')).toDate()
    const date2 = new Moment(new Moment(d2).format('YYYY-MM-DD')).toDate()
    return new Date(date1) < new Date(date2)
}

export const isBeforeToday = (isoDateString) => {
    const date1 = new Moment(new Moment(isoDateString).format('YYYY-MM-DD')).toDate()
    const date2 = new Moment(new Moment(new Date()).format('YYYY-MM-DD')).toDate()
    return new Date(date1) < new Date(date2)
}

export const isBeforeOtherDate = (isoDateString1, otherIsoDateString) => {
    const date1 = new Moment(new Moment(isoDateString1).format('YYYY-MM-DD')).toDate()
    const date2 = new Moment(new Moment(otherIsoDateString).format('YYYY-MM-DD')).toDate()
    return new Date(date1) < new Date(date2)
}

export const datesRange = (d1, d2) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD'))
    const date2 = new Moment(new Moment(d2).format('YYYY-MM-DD'))
    return date2.diff(date1, 'days')
}

export const timerangeConverter = {
    getDateTimesFromRange: (timeRangeString, separator = '|') => {
        if(!exists(timeRangeString)){
            return {
                startDate: null,
                endDate: null
            }
        }
        const [startDate, endDate] = timeRangeString.split(separator)
        return {
            startDate,
            endDate
        }
    },
    getRangeFromDateTimes: (startDate, endDate, format = 'YYYY-MM-DD', separator = '|') => {
        const date1string = new Moment(startDate).format(format)
        const date2string = new Moment(endDate).format(format)
    
        return `${date1string}${separator}${date2string}`
    }
}


export const convertISODateToGermanDateString = (isoDateString) => {
    return exists(isoDateString)
        ? new Moment(isoDateString).format('DD.MM.YYYY')
        : ''
}

export const convertGermanDateStringToISODate = (germanDateString) => {
    return exists(germanDateString) 
        ? new Date(
            germanDateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1')
        )
        : null
}

export const germanDateIfDateString = (dateString) => {
    const isValid = Moment(dateString, Moment.ISO_8601, true).isValid()

    if(!isValid){
        return dateString
    }

    return convertISODateToGermanDateString(dateString)
}

export const todayToUtcString = () => {
    return moment(new Date().setHours(0,0,0,0)).utcOffset(0, true).format()
}