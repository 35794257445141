import React from 'react'
import { TextInput } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { useStatefulForm } from 'application/components/hooks'
import { validationMethods } from 'application/common'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { BranchSelect } from 'application/components/controls/form/branch_select'
import { TwoColumns } from 'application/components/forms/_common'
import { PushBottom64 } from 'application/components/pages/_common'
import { DIALOG } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'

export const  Step0Component = ({
    abort,
    onDataChange,
    goToNextStep,
    dialog,
    onQRCodeCreation
}) => {


    const {t} = useTranslation()

    const {
        licensePlate,
        make,
        model,
        chassisNumber,
        branchId,
        customer,
    } = dialog.payload

    const applyDamageManagerVehicle = dialog.name === DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER

    const vehicle = applyDamageManagerVehicle
        ? {        
            licensePlate,
            make,
            model,
            chassisNumber
        }
        : customer.preferredVehicleInfo

    const defaultValues = {
        licensePlate: vehicle?.licensePlate || '',
        branchId,
        make: vehicle?.make || '',
        model: vehicle?.model || '',
        chassisNumber: vehicle?.chassisNumber || ''
    }

    const info = applyDamageManagerVehicle
        ? 'The vehicle data of the current damage case has been applied.'
        : 'The vehicle data of the customers preferred vehicle has been applied.'

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue, 
        updateFormState
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => {
        const payload = {
            customer,
            ...getValues()
        }
        onQRCodeCreation(
            dialog.name,
            payload
        )
        goToNextStep()
    }


    return <React.Fragment>
        <InfoBox
            type={INFOBOX.TYPES.STANDARD} 
            cssMargin='0 0 24px' 
            cssMinHeight='unset'
            direction='row'
        >
            <FontIcon icon='info_outline' />
            <Spacer basis='10px' />
            <Paragraph>
                {t(info)}
            </Paragraph>
        </InfoBox>
        <TextInput
            label = {t('License plate')}
            name = 'licensePlate'
            register = {register}
            validate = {{
                notEmpty: validationMethods.notEmpty, 
                max50Characters: validationMethods.limitedChars(50)
            }}
            error = {errors.licensePlate}
            onChange = {
                () => {
                    onDataChange()
                    updateFormState()
                }
            } 
        />

        <TwoColumns 
            columnLeft={
                <TextInput
                    label = {t('Model')}
                    name = 'model'
                    register = {register}
                    validate = {{
                        max50Characters: validationMethods.limitedChars(50)
                    }}
                    error = {errors.model}
                    onChange = {
                        () => {
                            onDataChange()
                            updateFormState()
                        }
                    } 
                />
            }

            columnRight={
                <TextInput
                    label = {t('Make')}
                    name = 'make'
                    register = {register}
                    validate = {{
                        max50Characters: validationMethods.limitedChars(50)
                    }}
                    error = {errors.make}
                    onChange = {
                        () => {
                            onDataChange()
                            updateFormState()
                        }
                    } 
                />
            }
        />

        <TextInput
            label = {t('Chassis number')}
            name = 'chassisNumber'
            register = {register}
            validate = {null}
            error = {errors.chassisNumber}
            onChange = {
                () => {
                    onDataChange()
                    updateFormState()
                }
            } 
        />
        <BranchSelect
            onChange={updateFormState} 
            register={register} 
            setValue={setValue} 
            initialBranchId={defaultValues.branchId}
            menuPlacement='top'
        />
        <PushBottom64 />
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <ActionButton
                buttonText={t('Generate QR code')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}