import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'


export const ContactInfoUpdatedDetails = (
    {
        activity
    }
) => {

    const {
        name,
        phoneNumber
    } = activity.data.newContactInfo


    return <ActivityDetailWrapper>
        <ActivityDetail 
            label='Name' 
            value={name} 
        />
        <ActivityDetail 
            label='Phone number (mobile or landline)' 
            value={phoneNumber} />
    </ActivityDetailWrapper>
}

ContactInfoUpdatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}