import { put, all } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetUserNotificationSettingsSaga(action) {   
    const { 
        dialogName, 
        metaData
    } = action

    const {
        userId
    } = metaData

    const [
        defaultSettings, 
        userNotificationSettings,
        userDefaultSettings
    ] = yield all([
        resourceCall(
            api.gateway.notifications.getNotificationSettingsOptions
        ),
        resourceCall(
            api.gateway.users.getNotificationSettings, 
            {
                userId
            }
        ),
        resourceCall(
            api.gateway.users.getDefaultSettings, 
            {
                userId
            }
        )
    ])

    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                defaultSettings,
                userNotificationSettings,
                userDefaultSettings
            }
        )
    )
}