import { BRANCH_MANAGEMENT } from 'application/constants'
import { useStoreState } from 'react-flow-renderer'

export const useMaxExtend = (bonusSpace = 10) => {
    const nodes = useStoreState(state => state.nodes).filter(n => n.type === BRANCH_MANAGEMENT.ELEMENT_TYPE.NODE_CUSTOM)
    
    if(nodes.length === 0) {
        return [[0, 0], [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]]
    }

    const nodeMaxX = nodes
        .sort((a, b) => a.position.x > b.position.x ? -1 : a.position.x < b.position.x ? 1 : 0)[0].position.x

    const nodeMaxY = nodes
        .sort((a, b) => a.position.y > b.position.y ? -1 : a.position.y < b.position.y ? 1 : 0)[0].position.y

    const maxX = nodeMaxX + BRANCH_MANAGEMENT.NODE_POSITION.X_SPACING + bonusSpace

    const maxY = nodeMaxY + BRANCH_MANAGEMENT.NODE_POSITION.Y_MIN_SPACING + bonusSpace

    return {
        maxExtend: [[0, 0], [maxX, maxY]],
        maxX,
        maxY
    }

}