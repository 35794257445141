import { hideErrorDetails } from 'application/redux/actions/errors'
import { connect } from 'react-redux'
import {ErrorDisplayComponent} from './component'

const mapStateToProps = (state, props) => {
    return {
        isVisible: state.errors.currentErrorId !== null,
        error: state.errors.storedErrors.find(e => e.id === state.errors.currentErrorId)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeErrorDetails: () => dispatch(hideErrorDetails())
    }
}

export const ErrorDisplay =  connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorDisplayComponent)