import React from 'react'
import { WIDGET_CONTEXTS } from 'application/constants'
import { SubPageLayout } from 'application/components/pages/_common'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import {DamageManagerContainer} from '../container'
import {withRouter} from 'application/components/higher_order_components/with_router'

const DamageManagerInvoices = ({
    damageLoaded, 
    damage, 
    match
}) => {
    return <DamageManagerContainer
        titleSegments={['Invoices']}
        headline='Invoices'
    >        
        <SubPageLayout paddingLeft='16px' >
            {
                damageLoaded 
                && damage.id === match.params.damageId 
                && <WidgetArea 
                    widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_INVOICES} 
                    successively={true} 
                />
            }
        </SubPageLayout>
    </DamageManagerContainer>
}

export const DamageManagerInvoicesComponent = withRouter(DamageManagerInvoices)