import React from 'react'
import { Table } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { useMoCloseWatcher, useTableLoader } from 'application/components/hooks'


export const TableMyTasksOverview = withRouter((
    {
        tasksListLoading,
        tasksListLoaded,
        tasksListTotal,
        tasksList,
        moName,
        onLoadTasks,
        updateConstraint = null,
        match,
        currentUserId
    }
) => {
    useTableLoader(
        onLoadTasks, 
        match, 
        configuration, 
        updateConstraint, 
        {userId: currentUserId}
    )

    useMoCloseWatcher(
        moName, 
        tasksList
    )
    
    return <Table 
        configuration={configuration}
        elementsLoading={tasksListLoading}
        elementsTotal={tasksListTotal}
        elementsLoaded={tasksListLoaded}
        elements={tasksList}
        emptyMessage='No tasks found'
        moName={moName}
    />
})
