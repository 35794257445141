import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    max-width: 640px;
    &:hover{
        &>.styled-shadow:before, &>.styled-shadow:after{
            box-shadow: 0 2px 14px rgba(0,0,0,.4);
        } 
        &>.styled-shadow:before{
            left: 8px;
        } 
        &>.styled-shadow:after{
            right: 8px;
        } 
    }
`

const StyledLetter = styled.div`
    position: relative;
    background: ${props => props.theme.color.white};
    border-radius: 2px;
    max-width: 640px;
    border: 1px solid ${props => props.theme.color.gray15};
    padding: 16px;
    z-index: ${props => props.letterIndex};
`

const StyledShadow = styled.div`
    position: relative;
    z-index: ${props => props.shadowIndex};
    max-width: 640px;

    &:before, &:after{
        content: '';
        position: absolute;
        bottom: 12px;
        width: 40%;
        height: 10px;
        box-shadow: 0 5px 14px rgba(0,0,0,.55);
        transition: all .3s ease-in-out;
    } 
    &:before{
        left: 15px;
        transform: skew(-5deg) rotate(-5deg);
    } 
    &:after{
        right: 15px;
        transform: skew(5deg) rotate(5deg);
    } 
`


export const Letter = ({
    zIndexBase = 1,
    children
}) => {
    const letterIndex = zIndexBase
    const shadowIndex = letterIndex - 1
    return <Container>
        <StyledLetter letterIndex={letterIndex} shadowIndex={shadowIndex}>{children}</StyledLetter>
        <StyledShadow className='styled-shadow' />
    </Container>
}