import { connect } from 'react-redux'
import {RemoveBranchComponent} from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = ( state, props ) => {

    const branchId = props.dialog.payload.branchId 

    const branchData = state.common.allBranches.find(
        branch => branch.id === branchId
    )
    
    return {
        branchData,
        userGroups: state.common.userGroups
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveBranch: (
            dialogName, 
            payload, 
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload, 
                    metaData
                )
            )
        }
    }
}

export const RemoveBranch =  connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveBranchComponent)