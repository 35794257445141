import React from 'react'
import * as Fragments from './fragments'

export const DamageDeletedMetaContent =
    (
        {
            notification, 
            inline
        }
    ) => {
        const {payload} = notification
        
        return <Fragments.MetaContentContainer inline={inline}>
            <Fragments.ActivityDescription 
                label='Damage'
                value={payload.damage.vehicle.licensePlate}
            />
        </Fragments.MetaContentContainer>
    }