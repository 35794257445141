import React, {useEffect} from 'react'
import {ROUTES} from 'application/constants'
import {NoPermissionsPage} from './component'
import { Navigate } from 'react-router-dom'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'



const NoAccountsHocComponent = (
    {
        accounts,
        accountsFound,
        permissionsFound,
        onSetPermissionsFound,
        onSetAccountsFound,
        onSetNoPermissionsFound,
        onSetNoAccountsFound,
        match
    }
) => {
    //check if accounts submitted later and changed in the meantime if there are...
    //      ... accounts 
    useEffect(
        () => { 
            if(accounts.length > 0 ){
                onSetPermissionsFound()
                onSetAccountsFound()
            }
        }, [accounts.length, onSetAccountsFound, onSetNoAccountsFound, onSetPermissionsFound]
    )
    //      ...  no accounts
    useEffect(
        () => { 
            if (accounts.length === 0){
                onSetNoAccountsFound()
            }
        }, [accounts.length, onSetNoAccountsFound, onSetNoPermissionsFound]
    )
 
    if (accountsFound) {
        return <Navigate to={ROUTES.CHOOSE_ACCOUNT} />
    }

    if (!permissionsFound || !accountsFound) {
        //maybe solve flashing with an counter (for retries) attribute in store ?
        // - for retrying fetching accounts etc.... and after x retries it displays this page 
        return <NoPermissionsPage/> 
    }

    return <PagePreloader />
}

export const NoAccountsHoc = withRouter(NoAccountsHocComponent)