import { connect } from 'react-redux'
import { LawfirmRepairClearanceComponent } from './component'
import { updateWorkflowPanelForm } from 'application/redux/actions/common/workflow_panel'



const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (widgetKey, payload) => dispatch(updateWorkflowPanelForm(widgetKey, payload))
    }
}

export const LawfirmRepairClearance = connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmRepairClearanceComponent)