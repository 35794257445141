
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'



export const VehicleUpdatedDetails = (
    {
        activity
    }
) => {
    const newVehicle = activity.data?.newVehicle

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={activity.data.damage.licensePlate} 
        />
        <ActivityDetail
            label='Vehicle'
            value={newVehicle.licensePlate}
        />
    </ActivityDetailWrapper>
}

VehicleUpdatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}