import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'

import {CustomersContextMenuComponent} from './context_menu'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteCustomer: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_CUSTOMER, 
                false, 
                {
                    ...payload, 
                    customerId: payload.id
                },
                'Delete customer'
            )
        ),
        onEditCustomer: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_CUSTOMER, 
                true,
                {
                    customer: {
                        ...payload, 
                        customerId: payload.id
                    }
                }, 
                'Edit customer'
            )
        ),
        onQRCodeCreation: (
            payload
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE, 
                false, 
                payload, 
                'Create QR code for vehiclekeeper'
            )
        )
    }
}

export const CustomersContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)( CustomersContextMenuComponent )