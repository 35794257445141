import { connect } from 'react-redux'
import { updateOpponentKeeper } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageOpponentKeeperFormComp } from './component'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (damageId, damageData) => dispatch(updateOpponentKeeper(damageId, damageData))
    }
}

export const TaskDamageOpponentKeeperForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageOpponentKeeperFormComp)