import { ACTIONS } from 'application/constants'

export const setCurrentAccountLoadedState = (isLoading = true, isLoaded = false) => ({
    type: ACTIONS.AUTH_SET_CURRENT_ACCOUNT_LOADED_STATE,
    isLoading, isLoaded
})

export const requestCurrentAccountById = (accountId) => ({
    type: ACTIONS.AUTH_REQUEST_CURRENT_ACCOUNT_BY_ID,
    accountId
})

export const populateCurrentAccount = (account) => ({
    type: ACTIONS.AUTH_POPULATE_CURRENT_ACCOUNT,
    account
})

export const setCurrentAccountId = (accountId) => ({
    type: ACTIONS.AUTH_SET_CURRENT_ACCOUNT_ID,
    accountId
})

export const syncCurrentAccount = (accountData) => ({
    type: ACTIONS.AUTH_SYNC_CURRENT_ACCOUNT,
    accountData
})