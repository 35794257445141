import { FontIcon } from '@ec/ui-controls-react'
import { InfoBox } from 'application/components/controls/info_box'
import { Spacer } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import { useTranslation } from 'react-i18next'

export const CsvInfo = () => {
    const {t} = useTranslation()

    return <InfoBox 
        type={INFOBOX.TYPES.STANDARD} 
        direction='row' 
        cssMinHeight='84px'
    >
        <FontIcon icon='info_outline' />
        <Spacer basis='10px' />
        <div>
            <span>{t('All values must be separated by a semicolon: ')}<strong>";"</strong></span>
            <br />
            <br />
            <span>{t('The import only works if the csv headers have this structure')}:</span>
            <br />
            <br />
            <span>firstName;lastName;street;postalCode;city;phoneNumber;mobilePhoneNumber;email;licensePlate;make;model;chassisNumber</span>
        </div>
        <Spacer />
    </InfoBox>
}