import { useState } from 'react'

export const useDamageCreationCustomer = (defaultValues = null) => {
    const [customerData, setCustomerData] = useState({
        existingCustomer: true, 
        values: defaultValues, 
        customerId: defaultValues?.id, 
        existingCustomerWasChanged: false
    })
    
    const [applyPreferredVehicleData, setApplyPreferredVehicleData] = useState(true)
    const [existingCustomerWasChanged, setExistingCustomerWasChanged] = useState(false)

    const setExistingCustomer = (isExistingCustomer) => {
        setCustomerData({
            ...customerData,
            existingCustomer: isExistingCustomer
        })
    }

    const resetCustomerData = () => {
        setCustomerData({
            values: null,
            customerId: null,
            existingCustomer: false,
            existingCustomerWasChanged: false
        })
    }

    return {
        customerData,
        setCustomerData,
        setExistingCustomer,
        resetCustomerData,
        applyPreferredVehicleData,
        setApplyPreferredVehicleData,
        existingCustomerWasChanged,
        setExistingCustomerWasChanged
    }
}