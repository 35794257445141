import styled, { withTheme } from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { exists } from 'application/common/data_helpers'


const StyledFlexBox = styled.div`
    display: ${props => props.display};
    flex-direction: ${props => props.flexDirection};
    flex-wrap: ${props => props.flexWrap};
    justify-content:  ${props => props.justifyContent };
    align-items:  ${props =>  props.alignItems};
    align-content:  ${props => props.alignContent};
    /* gap, row-gap, column-gap not yet included */

    order: ${props => props.order};
    flex-grow: ${props => props.flexGrow};
    flex-shrink: ${props => props.flexShrink};
    flex-basis: ${props => props.flexBasis };
    align-self: ${props => props.alignSelf };

    height: ${props => props.height};
    width: ${props => props.width };
    margin-left: ${props =>  props.marginLeft };
    margin-right: ${props =>  props.marginRight };
    padding-top: ${props =>  props.paddingTop };
    padding-bottom: ${props =>  props.paddingBottom };
    padding-left: ${props =>  props.paddingLeft };
    padding-right: ${props =>  props.paddingRight };
    cursor: ${props => props.cursorDisplay};

    ${props => props.cssRules }

    @media (max-width: ${props => props.breakPoint}px) {
        ${ props => props.breakPointCssRules }
    }
`





const FlexBoxComponent = (
    props
) => {

    const { 
        flexDirection = 'row',
        flexWrap ='nowrap',
        justifyContent ='flex-start',
        alignItems ='stretch ',
        alignContent ='normal',
        order = '0',
        flexGrow = '0',
        flexShrink = '1',
        flexBasis = 'auto',
        alignSelf = 'auto',

        display = true,
        height = 'auto',
        width = '100%',
        marginLeft = '0',
        marginRight = '0',
        paddingTop = '0',
        paddingBottom = '0',
        paddingLeft = '0',
        paddingRight = '0',

        cssRules = '',
        breakPoint = null,
        breakPointCssRules = '',

        isDisabled = true, 
        onClick = () => {},
        theme
    } = props

    const displayAs = display ? 'flex' : 'none' 
    const cursorDisplay = isDisabled ? 'auto' : 'pointer'

    const handleOnClick = (args) => {
        return isDisabled ?  () => {} : onClick(args)
    }

    const defaultBreakPoint = exists(breakPoint) ? breakPoint : theme.breakpoint.tablet 

    return <StyledFlexBox
        flexDirection = {flexDirection}
        flexWrap = {flexWrap}
        justifyContent = {justifyContent}
        alignItems = {alignItems}
        alignContent = {alignContent}
        order = {order}
        flexGrow = {flexGrow}
        flexShrink = {flexShrink}
        flexBasis = {flexBasis}
        alignSelf = {alignSelf}

        display = {displayAs}
        height = {height}
        width = {width}
        marginLeft = {marginLeft}
        marginRight = {marginRight}
        paddingTop = {paddingTop}
        paddingBottom = {paddingBottom}
        paddingLeft = {paddingLeft}
        paddingRight = {paddingRight}

        cssRules = {cssRules}
        breakPoint = {defaultBreakPoint}
        breakPointCssRules = {breakPointCssRules}

        cursorDisplay={cursorDisplay}
        onClick={handleOnClick}
        {...props}
    />
}





FlexBoxComponent.propTypes = {
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    alignConten: PropTypes.string,
    order: PropTypes.string,
    flexGrow: PropTypes.string,
    flexShrink: PropTypes.string,
    flexBasis: PropTypes.string,
    alignSelf: PropTypes.string,

    display: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string,

    cssRules: PropTypes.string,
    breakPoint: PropTypes.number,
    breakPointCssRules: PropTypes.string,

    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
}


/**
 * representing Flexbox (Parent & Children - depends on the setted rules)  https://css-tricks.com/snippets/css/a-guide-to-flexbox/
 * 
 * 
 * 
 * Parent rules:
 * @param {string} flexDirection Parent: (default: row)  establishes the main-axis 
 * @param {string} flexWrap Parent: (default: nowrap) By default, flex items will all try to fit onto one line. You can change that and allow the items to wrap as needed with this property.
 * @param {string} justifyContent  Parent: (default: flex-start) This defines the alignment along the main axis
 * @param {string} alignItems  Parent: (default: stretch) This defines the default behavior for how flex items are laid out along the cross axis on the current line
 * @param {string} alignContent  Parent: (default: normal) This aligns a flex container’s lines within when there is extra space in the cross-axis, similar to how justify-content aligns individual items within the main-axis.
 * 
 * Child rules:
 * @param {string} order Children: (default: '0') By default, flex items are laid out in the source order. However, the order property controls the order in which they appear in the flex container. 
 * @param {string} flexGrow Children:  (default: '0') This defines the ability for a flex item to grow if necessary. It accepts a unitless value that serves as a proportion.
 * @param {string} flexShrink Children: (default: '1') This defines the ability for a flex item to shrink if necessary.
 * @param {string} flexBasis Children: (default: 'auto') This defines the default size of an element before the remaining space is distributed.
 * @param {string} alignSelf Children: (default: 'auto') This allows the default alignment (or the one specified by align-items) to be overridden for individual flex items.
 * 
 * Standart css rules:
 * @param {bool}   display - (default: flex) boolean, false = none, true = flex
 * @param {string} height (default: 'auto') standart css rule to define the height of the container
 * @param {string} width (default: 'auto') standart css rule to define the width of the container via css width property
 * @param {string} marginLeft (default: '0') standart css rule to define margin to the left 
 * @param {string} marginRight (default: '0') standart css rule to define margin to the right
 * @param {string} paddingTop (default: '0') standart css rule to define padding to the top
 * @param {string} paddingLeft (default: '0') standart css rule to define padding to the left 
 * @param {string} paddingRight (default: '0') standart css rule to define padding to the right
 * @param {string} cssRules Here can be injected self defined rules as string. For example: 'color: #232323; background: #ababab;'
 * 
 * @param {string} breakPointCssRules Here can be passed custom css rules which are triggered wenn breakpoint is reached
 * 
 * @param {boolean} isDisabled (default: true) if its value is false, a onclick function could be passed and clicked
 * @param {function} onClick (default: empty function) onclick function could be passed, to make element clickable
 * 
 */
export const FlexBox = withTheme(FlexBoxComponent)