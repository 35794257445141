import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'
import { getFullName } from 'application/common/name_helpers'
import { exists } from 'application/common/data_helpers'

export const UserInvitationDetails = (
    {
        activity
    }
) => {
    
    const name = exists(activity.data.invitee) ? getFullName(activity.data.invitee) : ''
    const email = activity.data.invitee?.email || ''
    return <ActivityDetailWrapper>
        <ActivityDetail label='Name' value={name} />
        <ActivityDetail label='Email' value={email} />
    </ActivityDetailWrapper>
}

UserInvitationDetails.propTypes = {
    activity: PropTypes.object.isRequired
}