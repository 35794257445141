import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCancelPaymentLawyerCommunicationSaga(action) {
    const {parentId, invoiceId, paymentId} = action.metaData

    yield put(showWaitingScreen('Payment is being cancelled'))
    yield resourceCall(
        api.gateway.invoices.cancelPayment, 
        {
            damageId: parentId, 
            invoiceId, 
            paymentId
        }, 
        action.payload
    )
    yield put(showToast(action.metaData.successMsg))
    yield put(hideWaitingScreen())
}