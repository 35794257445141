import { useMemo } from 'react'
import mime from 'mime'

export const useAccept = (
    allowedExtensions
) => {
    const accept = useMemo(() => {
        const extensionsArray = allowedExtensions.split(',')
        const cleanedExtensionsArray = extensionsArray.map(
            allowedExtensionString =>  allowedExtensionString.replace('"', '')
        ).filter(
            allowedExtension => allowedExtension !== ''
        ) 
        
        //map allowed extensions to their mime type
        const mappedMimeTypes = cleanedExtensionsArray.map(extension => ({
            extension,
            mimeType: mime.getType(extension) || 'application/octet-stream'
        }))

        //create array of disinct mime types
        const acceptedMimeTypes = Array.from(new Set(mappedMimeTypes.map(mt => mt.mimeType)))

        //reduce extensions & mime types into accept format
        //https://react-dropzone.js.org/#section-accepting-specific-file-types
        const accept = acceptedMimeTypes
            .reduce((accumulator, mimeType) => ({
                ...accumulator,
                [mimeType]: mappedMimeTypes
                    .filter(m => m.mimeType === mimeType)
                    .map(m => `.${m.extension}`)
            }),{})

        return accept
    }, [allowedExtensions])

    return accept
}