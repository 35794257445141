// /damages/:damage_id/invoices/:invoice_id/documents

import { fileUploadRequestBuilder, fileUploadRequestBuilderPin } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const postAttachmentsForInvoice = (params) => (
    {
        authenticated: (payload) => {
            const fileParams = [{
                key: 'Category.Id',
                value: params.categoryId === null 
                    ? '0' 
                    : params.categoryId
            }]
            const request = fileUploadRequestBuilder(
                `damages/${params.damageId}/invoices/${params.invoiceId}/documents`, 
                payload.file, 
                fileParams
            )
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const fileParams = [{
                key: 'Category.Id',
                value: params.categoryId === null ? '0' : params.categoryId
            }] 
            const request = fileUploadRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/invoices/${params.invoiceId}/documents`, 
                params.pin, 
                payload.file,
                fileParams
            )
            return apiCall(request)
        }
    }
)