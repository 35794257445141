import { connect } from 'react-redux'
import { DamageInsurancesComponent } from './component'

const mapStateToProps = state => ({
    damage: state.pages.damageManager.shared.damage,
    damageLoaded: state.pages.damageManager.shared.damageLoaded
})

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export const DamageInsurances = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DamageInsurancesComponent)