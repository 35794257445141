import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageStateChangedActivity = ({activity}) => {
    const {t} = useTranslation()

    const oldStateName = activity.data?.oldState?.name || ''
    const newStateName = activity.data?.newState?.name || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Damage state changed')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>{oldStateName}<RightArrow />{newStateName}</Fragments.ActivityParagraph>
    </React.Fragment>
}