import React from 'react'
import { RoleAdminDescription } from './role_admin_description'
import { RoleUserDescription } from './role_user_description'
import { RoleDamageManagementDescription } from './role_damage_management_description'
import { RoleControllingDescription } from './role_controlling_description'
import { RoleCommisioningDescription } from './role_comissioning_description'
import { RoleDeletionDescription } from './role_deletion_description'

export const RoleDescription = ({roleData}) => {

    switch (roleData.description) {
    case 'roleAdminDescription':
        return <RoleAdminDescription />
    case 'roleUserDescription':
        return <RoleUserDescription />
    case 'roleDamageManagementPermissionsDescription':
        return <RoleDamageManagementDescription />
    case 'roleControllingPermissionsDescription':
        return <RoleControllingDescription />
    case 'roleCommissioningPermissionsDescription':
        return <RoleCommisioningDescription />
    case 'roleDeletionPermissionsDescription':
        return <RoleDeletionDescription />
    default:
        return <React.Fragment> default </React.Fragment>
    }
}