import React, {useState} from 'react'
import { 
    StyledMobileTimeline, 
    StyledMobileTimelineStep,
    StyledMobileTimelineStepCounter,
    StyledMobileTimelineStepContent,
    StyledMobileTimelineStepLabel,
    StyledMobileTimelineButtons,
    StyledMobileTimelineStepChildren
} from './fragments'
import AnimateHeight from 'react-animate-height'
import { useEffect } from 'react'

export const MobileTimeLineStep = ({
    index,
    isLast = false,
    isActive,
    label,
    children,
    buttons = null
}) => {
    const [height, setHeight] = useState(isActive ? 'auto' : 0)

    useEffect(()=>{
        setHeight(isActive ? 'auto' : 0)
    },[isActive])

    return <StyledMobileTimelineStep isActive={isActive}>
        <StyledMobileTimelineStepCounter isLast={isLast}>
            <span>{index + 1}</span>
        </StyledMobileTimelineStepCounter>
        <StyledMobileTimelineStepContent>
            <StyledMobileTimelineStepLabel>
                {
                    label
                }
            </StyledMobileTimelineStepLabel>
            <AnimateHeight
                id={`step${index}`}
                duration={ 500 }
                height={height} 
                animateOpacity={true}
                aria-hidden={false}
            >
                <StyledMobileTimelineStepChildren>
                    {
                        children
                    }
                </StyledMobileTimelineStepChildren>
                <StyledMobileTimelineButtons>
                    {
                        buttons
                    }
                </StyledMobileTimelineButtons>
            </AnimateHeight>
        </StyledMobileTimelineStepContent>
    </StyledMobileTimelineStep>
}

export const MobileTimeLine = ({
    children
}) => {
    return <StyledMobileTimeline>
        {
            children
        }
    </StyledMobileTimeline>
}