import styled from 'styled-components'

export const StyledComprehensiveDamageTypeItem = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    margin-bottom: 8px;
    position: relative;
    border: 1px solid ${props => props.theme.color.gray15};
    padding-left: 12px;
    height: 42px;
    min-width: 360px;
    max-width: 480px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
`

export const StyledButton = styled.button`
    cursor: pointer;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: transparent;
    border: 0;
    width: 40px;
    height: 40px;

    &:hover{
        background-color: ${props => props.theme.color.gray5};
    }
`