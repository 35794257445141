import React from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { Footer } from 'application/components/dialogs/_common/footer'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { QrCode } from 'application/components/building_blocks/qr_code'
import { useBranchCreateDamageUrl } from './use_branch_create_damage_url'
import { useQrCode } from 'application/components/hooks'

export const CreateBranchQRCodeComponent = withDialogDataLoader((
    { 
        abort,
        dialog,
        currentAccountName
    }
) => {
    const {
        sharedResourceId,
        branch
    } = dialog.payload


    const {
        createDamageUrl
    } = useBranchCreateDamageUrl(
        sharedResourceId,
    )

    const fileName = branch.name === currentAccountName
        ? `QR-Code-Schadenabwicklung-${branch.name}.png`
        : `QR-Code-Schadenabwicklung-${currentAccountName}-${branch.name}.png`

    const {
        qrCodeProps,
        onDownloadQrCode
    } = useQrCode(
        createDamageUrl,
        fileName
    )

    return <React.Fragment>
        <DialogMainContent>
            <QrCode
                {
                    ...qrCodeProps
                }
            />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={onDownloadQrCode}
            submitText='Download'
        />
    </React.Fragment>
})