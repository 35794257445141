import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { CompareBranchesFilter } from 'application/components/building_blocks/filters/compare_branches_filter'
import { DamageStateFilterCombo } from 'application/components/building_blocks/filters/damage_state_filter_combo'
import { DamageTypeFilter } from 'application/components/building_blocks/filters/damage_type_filter'
import { InvoiceTypeFilter } from 'application/components/building_blocks/filters/invoice_type_filter'
import { TimeRangeFilter } from 'application/components/building_blocks/filters/time_range_filter'
import { VacantPositionsForBranches } from 'application/components/charts/vacant_positions_for_branches'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Spacer } from 'application/components/pages/_common'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { ROUTE_PARAMETERS } from 'application/constants'
import { controlling } from 'application/filters'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const ControllingGlobalVacantPositionsComponent = (
    {
        loadingFailed, 
        widgetKey, 
        onRefresh, 
        payload
    }
) => {
    const {t} = useTranslation()
    
    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    const loadingComplete = payload.barsLoaded === payload.totalBars

    return <FlexBox flexDirection='column'>
        <FilterFragment.FilterContainer>
            <TimeRangeFilter 
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS}
                onChange={() => {onRefresh(widgetKey)}}
                label='Show damages between'
                disabled={!loadingComplete}
            />
            <DamageStateFilterCombo
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS}
                disabled={!loadingComplete}
                onChange={() => {onRefresh(widgetKey)}}
            />
            <DamageTypeFilter
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS}
                filterDefinition={controlling.damageType}
                menuPlacement='bottom'
                onChange={() => {onRefresh(widgetKey)}}
                disabled={!loadingComplete}
            />
            <InvoiceTypeFilter 
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS}
                disabled={!loadingComplete}
                onChange={() => {onRefresh(widgetKey)}}
            />
            <CompareBranchesFilter 
                routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_VACANT_POSTITIONS}
                filterDefinition={controlling.compareBranches}
                menuPlacement='bottom'
                disabled={!loadingComplete}
                onChange={() => {onRefresh(widgetKey)}}
            />
        </FilterFragment.FilterContainer>
        <Spacer basis='20px' />
   
        <VacantPositionsForBranches 
            chartData={payload.chartData}
            totalBars={payload.totalBars}
            allBarsLoaded={payload.allBarsLoaded}
        />
    </FlexBox>
}

ControllingGlobalVacantPositionsComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}