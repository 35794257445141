import { put, all } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetDataForDekraExpertCommissionSaga(action) {
    const { dialogName, metaData } = action
    const { damageId } = metaData

    const damage = yield resourceCall(
        api.gateway.damage.getDamage, 
        { damageId: damageId }
    )

    const [branch, customer, comprehensiveData] = yield all([
        resourceCall(
            api.gateway.auth.getBranchById, 
            { branchId: damage.branchId }
        ),
        resourceCall(
            api.gateway.damage.getVehicleKeeperByDamageId, 
            { damageId: damageId }
        ),
        resourceCall(
            api.gateway.damage.getComprehensiveDataByDamageId,
            { damageId: damageId }
        )
    ])

    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                damage, 
                branch, 
                customer,
                comprehensiveData
            })
    )
}