import React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'

const ContactUpdatedInfo = (
    {activityData}
) => {
    const{t} = useTranslation()
    const {
        oldContactInfo,
        newContactInfo
    } = activityData

    return <React.Fragment>
        {t('Contact person')}: {oldContactInfo?.name}<RightArrow />{newContactInfo?.name} <br/>
        {t('Phone number')}: {oldContactInfo?.phoneNumber}<RightArrow />{newContactInfo?.phoneNumber}
    </React.Fragment>
}

ContactUpdatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {ContactUpdatedInfo} 