import React, { useRef } from 'react'
import { useTimeline } from 'application/components/hooks'
import { MobileTimeLine, MobileTimeLineStep } from 'application/components/building_blocks/mobile_time_line'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { Step3 } from './steps'
import { Step0Simple } from 'application/components/pages/external/kickstart/steps/step_0_simple'

export const KickstartExternalSimpleContent = ({
    customerFormHook,
    setCustomerData,
    kickstartFormRendererStructure,
    setDynamicFormData,
    tempDamageData,
    account,
    damageDataSent,
    handleCreateDamageKickstart,
    damageDefaultValues
}) => {
    const {t} = useTranslation()

    const {
        currentTimelineStepIndex,
        gotoNextStep
    } = useTimeline(2)

    const formSubmitButtonRef = useRef(null)


    return <MobileTimeLine>

        <MobileTimeLineStep
            index={0}
            isActive={currentTimelineStepIndex === 0}
            label={t('Damage report')}
            buttons={
                <ActionButton
                    buttonText={t('Continue')}
                    onButtonClick={() => {
                        formSubmitButtonRef.current.click()
                    }}
                />
            }
        >
            <Step0Simple
                damageDefaultValues={damageDefaultValues}
                formHook={customerFormHook}
                kickstartFormRendererStructure={kickstartFormRendererStructure}
                onSubmitFormData={(data) => {
                    const trySubmit = customerFormHook.handleSubmit(() => {
                        setDynamicFormData(data)
                        setCustomerData(customerFormHook.getValues())
                        gotoNextStep()
                        handleCreateDamageKickstart(customerFormHook.getValues(), data)
                    })
                    trySubmit()
                }}
                ref={formSubmitButtonRef}
            />
        </MobileTimeLineStep>

        <MobileTimeLineStep
            index={1}
            isActive={currentTimelineStepIndex === 1}
            label={t('Summary')}
            isLast={true}
        >
            <Step3
                damageData={tempDamageData}
                commission={{
                    commissionExpert: false,
                    commissionLawfirm: false,
                    data: null
                }}
                accountName={account.name}
                damageDataSent={damageDataSent}
            />
        </MobileTimeLineStep>

    </MobileTimeLine>
}