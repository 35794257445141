import React from 'react'

import { TextArea as TA} from 'application/modules/dynamic-form/controls/text_area'
import { _get } from './util'
import { useTranslation } from 'react-i18next'

export const TextArea = ({ description, formHook }) => {
    const {
        register,
        errors,
        setForm,
    } = formHook

    const { t } = useTranslation()

    return <div style={{ gridColumn: 'span 2' }}>
        <TA
            label={t(description.label)}
            name={description.name}
            register={register}
            validate={description.validate}
            error={_get(errors, description.name)}
            onChange={setForm}
            placeholder={description.elementOptions?.placeholder}
        />
    </div>
}