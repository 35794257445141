import { connect } from 'react-redux'
import * as container from './container'
import { compose } from 'redux'
import { withStatuslistLoaderComponent } from './component'


export const withStatuslistLoader = compose(
    connect(
        container.mapStateToProps,
        container.mapDispatchToProps
    ),
    withStatuslistLoaderComponent
)