import { DatePicker, TextArea } from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import { validationMethods } from 'application/common'
import React from 'react'
import { useTranslation } from 'react-i18next'


export const RepairSchedulePartialForm = ({
    register,
    errors,
    disabled = false,
    setValue,
    trigger,
    formStateValues,
    updateFormState,
    control
}) => {
    const { t } = useTranslation()

    return <React.Fragment>
        <TwoColumns
            breakPoint='1854'
            columnLeft={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Spare parts ordered at')}
                            name='sparePartsOrderedAt'
                            error={errors.sparePartsOrderedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.sparePartsOrderedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Spare parts received at')}
                            name='sparePartsReceivedAt'
                            error={errors.sparePartsReceivedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.sparePartsReceivedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
            columnRight={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Shipped to bodyshop at')}
                            name='shippedToBodyShopAt'
                            error={errors.shippedToBodyShopAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.shippedToBodyShopAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Shipped to paintshop at')}
                            name='shippedToPaintShopAt'
                            error={errors.shippedToPaintShopAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.shippedToPaintShopAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
        />

        <TwoColumns
            columnLeft={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Inspected at')}
                            name='inspectedAt'
                            error={errors.inspectedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.inspectedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Appraisal received at')}
                            name='appraisalReceivedAt'
                            error={errors.appraisalReceivedAt}
                            control={control}
                            //disabledDays={{before: formStateValues.inspectedAt ? new Date(formStateValues.inspectedAt) : null}}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.appraisalReceivedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
            columnRight={
                <TextArea
                    label={t('Notes (e.g. delays)')}
                    name='notes'
                    register={register}
                    error={errors.notes}
                    onChange={updateFormState}
                    disabled={disabled}
                    validate={
                        { limitedChars: validationMethods.limitedChars(1400) }
                    }
                />
            }
        />


    </React.Fragment>
}