import { put, call } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { 
    kickstartCreateDamageSubSaga, 
    kickstartCommissionLawfirmSubSaga,
    kickstartUploadFilesSubSaga
} from 'application/redux/saga/sagas/sub_sagas/kickstart_sub_sagas'
import { kickstartCommissionOtherExpertSubSaga } from '../sub_sagas/kickstart_sub_sagas/kickstart_commission_other_subsaga'

export function* dialogCreateDamageKickstartCommissionSaga(action) {


    yield put(
        showDialogStepsWaitingScreen(
            'Creating damage'
        )
    )

    try {

        const {
            damageData,
            commissionOtherExpert,
            lawfirm
        } = action.payload
    
        const {createDamageResponse} = yield call(
            kickstartCreateDamageSubSaga, 
            action
        )
    
        //Gutachterbeauftragung
        yield commissionOtherExpert.commissionExpert && call(
            kickstartCommissionOtherExpertSubSaga,
            commissionOtherExpert.expert,
            createDamageResponse,
            commissionOtherExpert.files
        )
    
        //Anwalt beauftragung
        yield lawfirm.commissionLawfirm && call(
            kickstartCommissionLawfirmSubSaga,
            lawfirm,
            createDamageResponse
        )
    
        //upload files
        const allFiles = [
            ...damageData.files,
            ...lawfirm.files
        ]
        
        yield allFiles.length > 0 && call(
            kickstartUploadFilesSubSaga,
            allFiles,
            createDamageResponse,
            lawfirm
        )
    
    } catch(e){
        console.error('chb::dialogCreateDamageKickstartCommissionSaga e',e)
    }

   
    yield put(
        hideDialogStepsWaitingScreen()
    )
    yield put(showToast('Damage created'))
}