import { connect } from 'react-redux'

import { WIDGET_CONTEXTS } from 'application/constants'
import {ControllingInvoicesEffects} from './effects'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { resetWidgetLayout, setStaticMode } from 'application/redux/actions/widget_contexts'

const mapStateToProps = state => {
    return {
        auth: state.auth,
        isStatic: state.widgetContexts.contexts.filter(c => c.key === WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS).length > 0 
            ? state.widgetContexts.contexts.find(c => c.key === WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS).boardConfiguration.isStatic 
            : false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetStaticMode: (contextKey, isStatic) => dispatch(setStaticMode(contextKey, isStatic)),
        onResetWidgetBoard: (contextKey) => {
            dispatch(resetWidgetLayout(contextKey))
        },
        onRefreshWidgetContexts: (contexts) => {
            for (const context of contexts){
                dispatch(refreshWidgetsByContext(context))
            }
        },
    }
}

export const ControllingInvoices = connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllingInvoicesEffects)