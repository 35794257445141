import { exists } from 'application/common/data_helpers'
import { useState } from 'react'

/**
 * hook wich takes care of Checkbox selection. Only one Checkbox could be selected!
 * it handles its selection by the labels as ids of the objects. Labels are required as object propertys!
 *  
 * @param {[]} list array of objects. Objects need to have a property label and value!
 * @param {Object} defaultSelectedItem  Object with property label and value. This represents the default selection 
 * 
 * @function isChecked(value) - returns true or false if the given value is equal to selected value. 
 * @function handleCheckboxChange(event) - the given event.target by the Checkbox name property value is set as selected value
 * @function   getSelectedValue() - returns the selected value
 * @function getSelectedObject() - returns the whole selected object
 * 
 * 
 * 
 * ---------------------------------------------
 * Implementation Example
 * ---------------------------------------------
 * 
 * required imports: 
 *  
 *  import CheckboxWithLabel from 'application/components/controls/form/checkbox_with_label'
 *  import { useRadioCheckBoxesByValue } from 'application/components/hooks'
 * 
 *     const reasonsForReductionKeywords = [
 *      {
 *          label: 'Stichwort 1',
 *          value: '1'
 *      },
 *      {
            label: 'Stichwort 2',
            value: '2'
        },
        {
            label: 'Stichwort 3',
            value: '3'
        },
        {
            label: 'sonstiges',
            value: '4'
        },
    ]


    const {
        isChecked, 
        handleCheckboxChange, 
        getSelectedValue
    } = useRadioCheckBoxesByValue(reasonsForReductionKeywords)



    //JSX part:
                    reasonsForReductionKeywords.map(
                    (keyword, i) => {
                        return <CheckboxWithLabel
                            key={keyword.label}
                            name={keyword.value}
                            label={keyword.label}
                            isRequired={false}
                            isSelected={isChecked(keyword.value)}
                            onCheckboxChange={handleCheckboxChange} 
                        />
                    } 
                )
 * 
 * 
 * 
 */


const useRadioCheckBoxesByValue = (list, defaultSelectedItem) => {

    const getInitialSelectedItemLabel = () => {
        return exists(defaultSelectedItem) 
            ? defaultSelectedItem.value
            : list[0].value    
    }

    const [selectedValue, setSelectedValue] = useState( getInitialSelectedItemLabel() )

    const handleCheckboxChange =  (event) =>  {
        const { name } = event.target 
        const value = name
        setSelectedValue(value)      
    }

    const getSelectedValue = () => {
        return selectedValue
    }

    const isChecked = (label) => {
        return selectedValue === label
    }

    const getSelectedObject = () => {
        return list.find(
            item => item.value === selectedValue
        )
    }

    return {
        isChecked, 
        handleCheckboxChange, 
        getSelectedValue,
        getSelectedObject
    }

}

export  { useRadioCheckBoxesByValue }