import React from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'
import { useLocation, useParams, matchPath } from 'react-router-dom'
import { FontIcon } from '@ec/ui-controls-react'
import {
    StyledDamageManagerSidebarNavigation,
    StyledDamageManagerSidebarNavigationItem
} from './fragments'
import { Spacer } from 'application/components/pages/_common'

const NavigationItem = withTheme(({ item, theme }) => {

    if (item.skip) {
        return <React.Fragment></React.Fragment>
    }

    return <StyledDamageManagerSidebarNavigationItem to={item.link} active={item.isActive}>
        <FontIcon
            icon={item.icon}
            clickable={true}
            fontSize={theme.fontSize.medium18}
            color={theme.color.gray70}
        />
        <Spacer basis='8px' />
        {item.label}
    </StyledDamageManagerSidebarNavigationItem>
})

export const DamageManagerSidebarNavigation = ({
    showLawfirmTab = false
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { damageId } = useParams()
    const { pathname } = location
    const {
        internalAccountLink
    } = useApplicationLinks()
    const items = [
        {
            label: t('Overview'),
            link: internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/overview'
            ),
            isActive: matchPath({
                path: ROUTES.DAMAGE_MANAGER_OVERVIEW
            }, pathname),
            icon: 'grid_on'
        },
        {
            label: t('Damage processing'),
            link: internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/tasks'
            ),
            isActive: matchPath({
                path: ROUTES.DAMAGE_MANAGER_TASKS
            }, pathname) || matchPath({
                path: ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED
            }, pathname),
            icon: 'local_car_wash'
        },
        {
            label: t('Documents & Pictures'),
            link: internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/documents'
            ),
            isActive: matchPath({
                path: ROUTES.DAMAGE_MANAGER_DOCUMENTS
            }, pathname),
            icon: 'photo_camera'
        },
        {
            label: t('Messages'),
            link: internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/messages'
            ),
            isActive: matchPath({
                path: ROUTES.DAMAGE_MANAGER_INBOX
            }, pathname) || matchPath({
                path: ROUTES.DAMAGE_MANAGER_INBOX_PRESELECTED
            }, pathname),
            icon: 'email'
        },
        {
            label: t('Invoices'),
            link: internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/invoices'
            ),
            isActive: matchPath({
                path: ROUTES.DAMAGE_MANAGER_INVOICES
            }, pathname),
            icon: 'description'
        },
        {
            label: t('Lawyer communication'),
            link: internalAccountLink(
                ROUTES.DAMAGE_MANAGER_BASE + damageId + '/lawyercommunication'
            ),
            isActive: matchPath({
                path: ROUTES.DAMAGE_MANAGER_LAWYER_COMMUNICATION,
                exact: false
            }, pathname),
            icon: 'gavel',
            skip: !showLawfirmTab
        }
    ]

    return <StyledDamageManagerSidebarNavigation>
        {
            items.map((item, index) => <NavigationItem item={item} key={index} />)
        }
    </StyledDamageManagerSidebarNavigation>
}