import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const StyledMessage = styled.div`
    *color: #383d41;
    *background-color: #e2e3e5;
    *border-color: #d6d8db;

    color: #69510a;
    background-color: #fff3cd;
    border-color: #ffeeba;

    & > div {
        color: #69510a;
    }


    font-size: ${props => props.theme.fontSize.standard};
    padding: 16px;

    border-radius: 2px;
`

export const StyledSuccessMessage = styled.div`


    color: ${props => props.theme.color.primary};
    background: ${props => props.theme.color.primaryBackground};

    font-size: ${props => props.theme.fontSize.standard};
    padding: 16px;
`

export const StyledSuccessHeadline = styled.span`
    margin-left: 8px;
    color: ${props => props.theme.color.primary};
`



export const StyledPreferredAccountLink = styled(NavLink)`
    font-size: 24px;
    color: ${props => props.theme.color.primary};
    padding: 8px;

    :hover{
        color: ${props => props.theme.color.primaryHoverDark};
    }
`
export const StyledInfoHeadline = styled.span`
    margin-left: 8px;
    color: #69510a;
`

export const StyledInfoContent = styled.div`
    padding-left: 32px;
    color: #69510a;

    & > div {
        color: #69510a;
    }
`

export const StyledSuccessContent = styled.div`
    padding-left: 32px;
    color: ${props => props.theme.color.primary};

    & > div {
        color: ${props => props.theme.color.primary};
    }
`

export const StyledPreferredInfoButton = styled.div`
    display: flex;
    margin-left: -8px;
    padding-top: 4px;

    & div * {
        color: ${props => props.theme.color.primary};
    }
`