import { call, debounce, put } from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { validatePinFailed, validatePinSuccess, updatePin } from 'application/redux/actions/shared_resource'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { sharedResourcePin } from 'application/storage'

function* validatePinSaga(action) {
    try {

        yield resourceCall(api.gateway.sharedResource.validatePin, {
            pinToValidate: action.pin
        })
        yield put(updatePin(action.pin))
        yield put(validatePinSuccess(action.pin, action.sharedResourceId))
        
        if(action.storePin){
            yield call(sharedResourcePin.store, action.sharedResourceId, action.pin)
        }

    } catch (e) {
        
        yield call(sharedResourcePin.delete, action.sharedResourceId)
        
        if (e.response.status === 401) {
            yield put(validatePinFailed())
        }else{
            yield handleError(e, action)
        }

    }
}

export function* validatePinWatcher() {
    yield debounce(1000, ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_REQUEST, validatePinSaga)
}