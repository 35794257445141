import React, {useState} from 'react'
import styled, {withTheme} from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { LinkButton } from 'application/components/fragments/typography'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'

const StyledPermissionSet = styled.div`
    margin-bottom: 16px;
    padding: 8px;
    background-color: ${props => props.theme.color.gray5};
`

const StyledPermissionSetTitle = styled.h3`
    margin: 0 0 8px;
    color: ${props => props.theme.color.anthractite};
    display: flex;
    align-items: center;
`

const StyledPermissionSetDescription = styled.p`
    margin: 0 0 8px;
    color: ${props => props.theme.color.anthractite};
`

const StyledPermissionDetailsDescription = styled.p`
    margin: 16px 0 4px;
    color: ${props => props.theme.color.anthractite};
    font-weight: bold;
`

const StyledPermissionsList = styled.ul`
    margin: 0 0 8px;
`

export const PermissionSet = withTheme(
    (
        {
            title, 
            description, 
            permissions,
            includesGlobalPermissions, 
            includesBranchPermissions,
            theme,
            isSelected
        }
    ) => {
        const [showPermissions, setShowPermissions] = useState(false)
        const [selected, setSelected] = useState(false)

        const {t} = useTranslation()

        return <StyledPermissionSet>
            <FlexBox directionRow>
                <FlexBox flexBasis='60px'>
                    <PureCheckBox 
                        label=''
                        isRequired={false}
                        isSelected={selected}
                        onCheckboxChange={(e)=>{setSelected(!selected)}}
                    />
                </FlexBox>
                <FlexBox 
                    flexDirection='column'
                    flexBasis='calc(100% - 60px)' 
                >
                    <StyledPermissionSetTitle>{title}
                        {
                            includesGlobalPermissions 
                            && <React.Fragment>
                                &nbsp;
                                <FontIcon 
                                    title='enthält globale Rechte' 
                                    icon='language' 
                                    fontSize={theme.fontSize.medium} 
                                    color='inherit' 
                                />
                            </React.Fragment>
                        }
                        {
                            includesBranchPermissions 
                            && <React.Fragment>
                                &nbsp;
                                <FontIcon 
                                    title='enthält filialspezifische Rechte' 
                                    icon='store'
                                    fontSize={theme.fontSize.medium} 
                                    color='inherit' 
                                />
                            </React.Fragment>
                        }
                    </StyledPermissionSetTitle>
                    <StyledPermissionSetDescription>
                        {description}
                    </StyledPermissionSetDescription>
                    {
                        !showPermissions 
                        && <LinkButton
                            isActive={true} 
                            onClick={()=>{setShowPermissions(true)}}
                        >
                            {t('Show Details')}
                        </LinkButton>
                    }
                    {
                        showPermissions && <React.Fragment>
                            <StyledPermissionDetailsDescription>
                                Assoziierte Einzelrechte
                            </StyledPermissionDetailsDescription>
                            <StyledPermissionsList>
                                {
                                    permissions.map(
                                        (p, i) => {
                                            return <li key={i}>{p}</li>
                                        }
                                    )
                                }
                            </StyledPermissionsList>
                            <LinkButton 
                                isActive={true} 
                                onClick={()=>{setShowPermissions(false)}}
                            >
                                {t('Hide Details')}
                            </LinkButton>
                        </React.Fragment>
                    }
                </FlexBox>
            </FlexBox>
        </StyledPermissionSet>
    }
)