import React, {useEffect} from 'react'
import { Navigate } from 'react-router-dom'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { useTranslation } from 'react-i18next'

export const withSharedResourceValidationComponent = 
Component => 
    props => {
        const {t} = useTranslation()

        const {
            sharedResource, 
            onGetSharedResourceById, 
            match, 
            redirectUrl
        } = props

        const sharedResourceIdFromUrl = match.params.sharedResourceId

        useEffect(
            () => {
                onGetSharedResourceById(sharedResourceIdFromUrl)
            },
            [onGetSharedResourceById, sharedResourceIdFromUrl]
        )

        const {
            resourceIsLoaded,
            resourceIsValid,
            resourceData
        } = sharedResource

        if (!resourceIsLoaded) {
            return <PagePreloader 
                text={t('Checking data...')} 
            />
        }

        const sharedResourceIsNotExpired = resourceData !== null 
            && new Date( resourceData.expiresAt ) > new Date() 

        if(!resourceIsValid || !sharedResourceIsNotExpired){
            return <Navigate to={redirectUrl} />
        }

        return <Component {...props} />
    }
