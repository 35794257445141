import React, {useState} from 'react'
import { ActionButton } from 'application/components/controls/action_button'
import { useTranslation } from 'react-i18next'
import { accountRegistrationUserManager } from 'application/common/user_manager'
import {RegistrationLayout} from 'application/components/pages/_registration_layout'
import {ActionLink} from 'application/components/controls/action_link'
import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import { PushBottom24 } from 'application/components/pages/_common'
import { Paragraph, Headline2 } from 'application/components/fragments/typography'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation/with_shared_resource_validation'


const AccountRegistrationC = (
    {match}
) => {
    const {t} = useTranslation()
    const sharedResourceId = match.params.sharedResourceId
    const [userManager] = useState(accountRegistrationUserManager())
    const query = useQuery()
    const userCreated = query.getStringParam(ROUTE_PARAMETERS.REGISTRATION.USER_CREATED, '') === 'true' 
    const registrationUrl = `${process.env.REACT_APP_SSO_SIGNUP}?returnurl=${window.location.origin}/createaccount/${sharedResourceId}?${ROUTE_PARAMETERS.REGISTRATION.USER_CREATED}=true`
    const firstName = query.getStringParam(ROUTE_PARAMETERS.REGISTRATION.FIRST_NAME)
    const lastName = query.getStringParam(ROUTE_PARAMETERS.REGISTRATION.LAST_NAME)
    const salutation = firstName === '' ? ',' : `${firstName} ${lastName},`


    const signIn = () => {
        userManager.signinRedirect(
            {
                state: { sharedResourceId }
            }
        )
    }

    return <RegistrationLayout 
        leftAreaWidth='50%' 
        rightAreaWidth='50%'
    >
        <Headline2 cssAlign='center'>
            {t('Welcome')} {salutation}
        </Headline2>
        <PushBottom24 />  
        <Paragraph cssAlign='center'>
            {t('to finish the registration, please create a new e.Consult account.')}
        </Paragraph>
        <PushBottom24 />  
        {
            !userCreated && <React.Fragment>
                <ActionLink
                    strechted={true}
                    linkText={t('Create user account')}
                    to={registrationUrl}
                />
                <br />
                <ActionButton
                    strechted={true}
                    buttonText={t('Login with existing e.Consult account')}
                    onButtonClick={signIn}
                />
            </React.Fragment>
        }
        {
            userCreated && <ActionButton
                strechted={true}
                buttonText={t('Activate account')}
                onButtonClick={signIn}
            /> 
        }
    </RegistrationLayout>
}

export const AccountRegistration = withSharedResourceValidation()(AccountRegistrationC)