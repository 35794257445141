import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { DialogFooter, DialogFooterRight, DialogFooterLeft} from 'application/components/fragments/dialog'
import PropTypes from 'prop-types'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { StyledLockedMessage } from 'application/components/building_blocks/account_box/fragments'


const Footer = (
    { 
        onAbort, 
        onSubmit = () => {},
        submitText = 'Confirm',
        disabled = false,
        showDeadlineInfo = false,
        hasSubmitButton = true,
        additionalButtons,
        showWarning = false,
        untranslatedWarningText = 'The viewing date is in the past.'
    }
) => {
    const {t} = useTranslation()

    return <DialogFooter>
        {
            showDeadlineInfo && <DialogFooterLeft>
                <InfoBox type={INFOBOX.TYPES.WARNING} direction='row' cssMargin='0'>
                    <FontIcon icon='error_outline' />
                    <Spacer basis='10px' />
                    <span>{t('Deadline is in past')}</span>
                    <Spacer />
                </InfoBox>
            </DialogFooterLeft>
        }
        {
            showWarning && <DialogFooterLeft>
                <InfoBox
                    type={INFOBOX.TYPES.WARNING} 
                    cssMargin='0 0 16px' 
                    cssPadding='8px 16px 8px 8px'
                    cssMinHeight='unset'
                    direction='row'
                >
                    <FontIcon icon='error_outline' />
                    <Spacer basis='10px' />
                    <StyledLockedMessage>{t(untranslatedWarningText)}</StyledLockedMessage>
                </InfoBox>
            </DialogFooterLeft>

        }
        <DialogFooterRight>
            <IconButtonWithLabel 
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={onAbort} 
            />
            {additionalButtons}
            {
                hasSubmitButton && <ActionButton 
                    buttonText={t(submitText)} 
                    onButtonClick={onSubmit}
                    disabled={disabled}
                />
            }
        </DialogFooterRight>
    </DialogFooter>
}

Footer.propTypes = {
    onAbort: PropTypes.func.isRequired, 
    onSubmit: PropTypes.func,
    submitText: PropTypes.string, 
    disabled: PropTypes.bool,
    showDeadlineInfo: PropTypes.bool,
    hasSubmitButton: PropTypes.bool,
    additionalButtons: PropTypes.object
}

export {Footer}