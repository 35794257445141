import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { DamageOpponentKeeperPartialForm } from '../_partial_forms/opponent_keeper_partial_form'

const DamageOpponentKeeperForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()

    const defaultValues = {
        keeper: {
            firstName: formData?.vehicle?.keeper?.firstName || null,
            lastName: formData?.vehicle?.keeper?.lastName || null,
            isCompany: formData?.vehicle?.keeper?.isCompany,
            companyName: formData?.vehicle?.keeper?.companyName || null,
            contactInfo: {
                phoneNumber: formData?.vehicle?.keeper?.contactInfo?.phoneNumber || ''
            },
            address: {
                line1: formData?.vehicle?.keeper?.address?.line1 || null,
                postalCode: formData?.vehicle?.keeper?.address?.postalCode || null,
                locality: formData?.vehicle?.keeper?.address?.locality || null,
            }
        }
    }
    
    const formHook = useStatefulForm({ defaultValues })

    const {
        handleSubmit, 
        getValues, 
        formStateValues,
    } = formHook

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <FlexBox flexDirection='column'>
        <DamageOpponentKeeperPartialForm 
            formHook={formHook}
            defaultValues={defaultValues}
        />

        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save opponent keeper data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { DamageOpponentKeeperForm }