import React from 'react'
import { fileHelpers } from 'application/common'
import { StyledFileButton, StyledExtension } from './fragments'
import TruncateString from 'react-truncate-string'
import { FlexBox } from 'application/components/fragments/flex_box'

export const FileButton = ({
    isClickable = false,
    isActive = false,
    fileName,
    onClick = () => {}
}) => {
    const extension = fileHelpers.getFileExtension(fileName).toLowerCase()

    return <StyledFileButton isClickable={isClickable} isActive={isActive} onClick={onClick} title={fileName}>
        <FlexBox flexBasis='auto' flexGrow='0' flexShrink='1' cssRules={'overflow: hidden;'}>
            <TruncateString text={fileName}truncateAt={40}/>7
        </FlexBox>
        <FlexBox flexBasis='30px'>
            <StyledExtension>.{extension}</StyledExtension>
        </FlexBox>
    </StyledFileButton>
}