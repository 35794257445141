import {ACTIONS} from 'application/constants'

export const getExpertsForDialogRequest = (
    dialogName,
    searchTerm = '',
    offset = 0,
    limit = 25
) => ({
    type: ACTIONS.DIALOG_GET_SEARCHED_EXPERTS_REQUEST_DEBOUNCED,
    dialogName,
    searchTerm,
    offset,
    limit
})