import { ACTIONS } from 'application/constants'


/**
 * 
 * @param {object} params object with properties:       
 *              offset, 
                limit, 
                orderBy='id',
                direction = 'desc',
                filterQuery = []
 * @returns 
 */
export const getDamagesByParams = (
    params
) => ({
    type: ACTIONS.DAMAGES_SEARCH_GET_DAMAGES_BY_PARAMS,
    params
})

export const populateDamageResults = (
    results = [],
    total = 0
) => ({
    type: ACTIONS.DAMAGES_SEARCH_POPULATE_DAMAGES,
    results,
    total
})

export const clearResults = () => ({
    type: ACTIONS.DAMAGES_SEARCH_CLEAR_RESULTS
})