import React from 'react'
import { isDefined } from 'application/common/data_helpers'
import { fileHelpers } from 'application/common'
import { DIALOG, SHARED_RESOURCE_TYPE } from 'application/constants'
import { useState } from 'react'




export const withExportToLawfirmConfirmationComponent = Component => props =>  {

    const {        
        onOpenExportToLawfirmConfirmationDialog,
        onSubmit,
        sharedResourceType = null,
        hasLawfirm = false
    } = props

    const { 
        name,
        payload = null
    } = props.dialog

    const dialogName = name
    const initialFiles = payload?.files || []

    const [ flaggedFiles, setFlaggedFiles ] = useState(initialFiles)

    const hasAtLeastOneExportedToLawFirmFile =  isDefined(
        flaggedFiles.find(
            element => fileHelpers.isExportedToLawfirm(element)
        )
    )
    
    const isLawfirmSharedRessource = sharedResourceType === SHARED_RESOURCE_TYPE.LAWFIRM
    const displayConfirmationDialog = hasAtLeastOneExportedToLawFirmFile 
    && ( hasLawfirm || isLawfirmSharedRessource)

    const onSubmitWithExportToLawfirmConfirmation = (
        payload,
        metaData
    ) => {
        return displayConfirmationDialog
            ?
            onOpenExportToLawfirmConfirmationDialog(
                DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION,
                true, 
                { 
                    dialogName,
                    files: flaggedFiles.filter(
                        file =>  fileHelpers.isExportedToLawfirm(file)
                    ),
                    sendPayloadRequestData: {
                        dialogName,
                        payload,
                        metaData
                    }
                },
                'Confirmation needed'             
            )
            : 
            onSubmit(
                dialogName,
                payload,
                metaData
            )
    }

    return <Component
        onSubmitWithExportToLawfirmConfirmation={onSubmitWithExportToLawfirmConfirmation}
        setFlaggedFiles={setFlaggedFiles}
        flaggedFiles={flaggedFiles}
        {...props} 
    />
}