import React from 'react'
import { withCreateVehicleAndKeeperQRCodeSteps } from './_common/with_create_vehicle_and_keeper_qr_code_steps'
import { compose } from 'redux'
import { Step0 } from './step_0'
import { Step1 } from './step_1'
import { DialogStepsLayout } from '../../_common/steps_layout'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'






const CreateVehicleAndKeeperQRCodeComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        dialog,
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
    }
) => {
    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    return <DialogStepsLayout  getStepsState={getStepsState}>
        {
            getCurrentStep().id === 0 && <Step0
                abort={abort}
                onDataChange={onDataChange}
                goToNextStep={goToNextStep}
                dialog={dialog}
            />
        }
        {
            getCurrentStep().id === 1 && <Step1
                abort={abort}
                onDataChange={onDataChange}
                goToPreviousStep={goToPreviousStep}
                dialog={dialog}
            />
        }
    </DialogStepsLayout>
}


export const CreateVehicleAndKeeperQRCode = compose(
    withDialogDataLoader,
    withCreateVehicleAndKeeperQRCodeSteps()
) (CreateVehicleAndKeeperQRCodeComponent)