import React from 'react'
import PropTypes from 'prop-types'

export const DamageLicensePlate = ({ damage }) => {
    const licensePlate = damage.vehicle !== undefined && damage.vehicle.licensePlate !== undefined ? damage.vehicle.licensePlate : ''
    return <React.Fragment>{licensePlate}</React.Fragment>
}

DamageLicensePlate.propTypes = {
    damage: PropTypes.object.isRequired
}