import * as Fragments from '../fragments'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'




export const ActivityDamageTaskLink = (
    {
        onClick,
        damageId,
        taskId,
        inline = false
    }
) => {
    const {t} = useTranslation()

    const {
        damageManagerTaskLink
    } = useApplicationLinks()

    const damageLink =  damageManagerTaskLink(
        ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED,
        taskId,
        damageId
    )

    return  <Fragments.ActivityLink 
        inline={inline}
    >
        <Link
            onClick={onClick} 
            to={damageLink}
        >
            {t('Jump to task')}
        </Link>
    </Fragments.ActivityLink>
}

ActivityDamageTaskLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    damageId: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    inline: PropTypes.bool
}