import { ACTIONS } from 'application/constants'

export const resetCurrentBranch = () => ({
    type: ACTIONS.AUTH_RESET_CURRENT_BRANCH
})

export const requestCurrentBranchChange = (branchId) => ({
    type: ACTIONS.AUTH_REQUEST_CURRENT_BRANCH_CHANGE,
    branchId
})

export const populateCurrentBranch = (branch) => ({
    type: ACTIONS.AUTH_POPULATE_CURRENT_BRANCH,
    branch
})