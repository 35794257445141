import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'


export const putCustomerQRCodeResource = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `customers/${params.customerId}/${params.branchId}/qrcode`, 
                HTTP_METHOD.PUT
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
)