import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'
  
//NOTES:

// original match object:
// import { withRouter } from react-router
/**
 * 
 * {
    "path": "/accounts/:accountId/branches/:branchId/inbox/:damageId/:messageId",
    "url": "/accounts/201/branches/137937948040495105/inbox/166502271884984320/166759798828695552",
    "isExact": true,
    "params": {
        "accountId": "201",
        "branchId": "137937948040495105",
        "damageId": "166502271884984320",
        "messageId": "166759798828695552"
    }
}
 * 
 */


// useLocation():
// {
//     "location": {
//         "pathname": "/accounts/201/branches/137937948040495105/inbox/166502271884984320/166759798828695552",
//         "search": "",
//         "hash": "",
//         "key": "f782hm"
//     },

// useParams()
//     "params": {
//         "*": "accounts/201/branches/137937948040495105/inbox/166502271884984320/166759798828695552",
//         "accountId": "201",
//         "branchId": "137937948040495105",
//         "damageId": "166502271884984320",
//         "messageId": "166759798828695552"
//     }
// }



const withRouter = (Component) => (props) => {
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const match = {
        url: location.pathname,
        params,
        navigate
    }

    return (
        <Component
            {...props}
            match={match}
        />
    )
}

export { withRouter }