import React from 'react'

import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import {Toaster} from 'application/components/building_blocks/toaster'
import {Gallery} from 'application/components/building_blocks/attachments/gallery'
import {WaitingScreen} from 'application/components/building_blocks/waiting_screen'
import {Dialogs} from 'application/components/building_blocks/dialog/xdialogs'
import { withCategoriesLoader, withInsurancesLoader, withAccessibleBranchesLoader, withAccountsLoader } from 'application/components/higher_order_components'
import { compose } from 'redux'
import {ErrorDisplay} from 'application/components/building_blocks/error_display'

const LayoutBase = ({ 
    waitingScreen,
    children,
    dialogsOpen,
    className = ''
}) => {
    return <React.Fragment>
        <GlobalStyle />
        <Fragments.Wrapper 
            blurred={ dialogsOpen || (waitingScreen.active && !waitingScreen.inDialog)}
            className={className}
        >
            {children}
        </Fragments.Wrapper>
        <Dialogs />
        <ErrorDisplay />
        <Toaster />
        <WaitingScreen />
        <Gallery />
    </React.Fragment>
}

export const LayoutBaseComponent = compose(
    withAccountsLoader(),
    withAccessibleBranchesLoader,
    withInsurancesLoader,
    withCategoriesLoader
)(LayoutBase)