import React from 'react'
import { PureSelect } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'


const DamageStateSelect = ({
    statusList,
    statusListLoaded,
    onUpdateState,
    damage = null,
    damageLoaded = false,
    onChange = () => {},
    menuIsOpen = undefined
}) => {
    const {t} = useTranslation()

    if(!statusListLoaded || !damageLoaded){
        return <PureSelect 
            options={[]}
            value={null}
            isDisabled={true}
            placeholder={t('loading...')}
        />
    }

    const stateOptions = statusList.data.map(s => ({
        value: s.id,
        label: s.name
    }))

    const stateInitialValue = damage.state !== undefined 
        ? stateOptions.find(s => s.value === damage.state.id) 
        : stateOptions[0]

    const statusChange = (option) => {
        onUpdateState(
            option.value,
            damage.id
        )
        onChange(option)
    }

    return <PureSelect
        options={stateOptions}
        value={stateInitialValue}
        onChange={statusChange}
        placeholder={t('Choose damage state')}
        menuIsOpen={menuIsOpen}
    />
}


export const DamageStateSelectComponent = DamageStateSelect