import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { getUrlParamValueNumber } from 'application/common/route_helpers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageInboxMessageList(action) {
    const damageId = action.match.params.damageId
    const offset = getUrlParamValueNumber(action.payload.routeParameters.OFFSET, 0)
    const limit = getUrlParamValueNumber(action.payload.routeParameters.LIMIT, 0) === 0 ? 10 : getUrlParamValueNumber(action.payload.routeParameters.LIMIT, 0)

    const response = yield resourceCall(
        api.gateway.messages.getDamageManagerAllMessageList, 
        {
            damageId, 
            offset, 
            limit
        }
    )
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {...response}))
}