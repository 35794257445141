import { ACTIONS } from 'application/constants'


export const getAnnouncementsByType = (
    announcementType = null,
    offset, 
    limit, 
    orderBy,
    direction,
    filterQuery = []
) => ({
    type: ACTIONS.CHANGELOG_GET_ANNOUNCEMENTS,
    announcementType,
    offset, 
    limit, 
    orderBy,
    direction,
    filterQuery
})

export const populateAnnouncements = (announcementsData) => ({
    type: ACTIONS.CHANGELOG_POPULATE_ANNOUNCEMENTS,
    announcementsData
})