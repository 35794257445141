import React from 'react'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'






/**
* Displays name and optional icon of status 
 * 
 * estimated structure
status = {
    iconName(pin):"Neu",
    id(pin):"134276686408581121",
    index(pin):0,
    lifeCycleState(pin):"New",
    name(pin):"Neu"
}
 * 
 * @param {object} status   (default: null)
 * @param {bool} displayIcon (default: false)
 * @param {string} paddingLeft (default: '8px')
 * @returns 
 */
const DamageStatusName = (
    { 
        status = null,
        displayIcon = false,
        paddingLeft = '0',
        paddingRight = '0'
    }
) => {

    const {t} = useTranslation()

    if(status === null){
        return null
    }

    const {
        name = '',
        iconName = ''
    } = status

    return <FlexBox width='auto'>
        { 
            displayIcon && <i className='material-icons'>{iconName}</i>
        }  
        <FlexBox 
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            width='auto'
        >
            { t(name)}
        </FlexBox>
    </FlexBox>
}

DamageStatusName.propTypes = {
    status: PropTypes.object,
    displayIcon: PropTypes.bool,
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string
}


export { DamageStatusName }