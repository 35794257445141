import React from 'react'
import { TaskDamageLink, RelatedDamageLink } from 'application/components/controls/activity_links/common'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'

export const TaskDamageActivityLink = (
    {
        activity
    }
) => {
    return <ForAnyBranchPermissions 
        permissions={ [USER_PERMISSONS.DAMAGE.READ] } 
        allNeeded={true}
    >
        <RelatedDamageLink activity={activity} />
        <TaskDamageLink activity={activity} />
    </ForAnyBranchPermissions>
}