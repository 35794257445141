import { STORAGE } from 'application/constants'

export const navbarOpenState = {
    store: (isOpen) => {
        localStorage.removeItem(STORAGE.NAVBAR_OPEN_STATE)
        localStorage.setItem(STORAGE.NAVBAR_OPEN_STATE, isOpen)
    },
    get: () => {
        const storageEntry = localStorage.getItem(STORAGE.NAVBAR_OPEN_STATE)
        return storageEntry === null ? true : storageEntry === 'true'
    },
    delete: () => {
        localStorage.removeItem(STORAGE.NAVBAR_OPEN_STATE)
    }
}