import { connect } from 'react-redux'

import { updatePolice } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamagePoliceDataFormComp } from './component'

const mapStateToProps = state => ({
    currentDamage: state.pages.damageManager.shared.damage
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (damageId, damageData) => dispatch(updatePolice(damageId, damageData))
    }
}

export const TaskDamagePoliceDataForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamagePoliceDataFormComp)