import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import { isDefined } from 'application/common/data_helpers'

export const LiabilityInquiryUpdateddActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const {
        oldLiabilityInquiry,
        newLiabilityInquiry
    } = activity.data

    const hasStatus = isDefined(newLiabilityInquiry.status) 
                   || isDefined(oldLiabilityInquiry.status)

    const hasInfo = isDefined(newLiabilityInquiry.info) 
                 || isDefined(oldLiabilityInquiry.info)

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Liability inquiry information has been updated')}
        </Fragments.ActivityTitle>
        {
            hasStatus && <Fragments.ActivityParagraph>
                {t(oldLiabilityInquiry?.status || '')} <RightArrow /> {t(newLiabilityInquiry.status || '')}
            </Fragments.ActivityParagraph>
        }
        {
            hasInfo && <Fragments.ActivityParagraph>
                {t(oldLiabilityInquiry?.info || '')} <RightArrow /> {t(newLiabilityInquiry?.info || '')}
            </Fragments.ActivityParagraph>
        }
    </React.Fragment>
}