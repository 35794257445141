import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getVehicleKeeperByDamageId = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/vehicle/keeper`,
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/vehicle/keeper`, 
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
)