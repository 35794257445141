import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { StyledTableCell, TableCell } from '../../table_cell'
import { exists } from 'application/common/data_helpers'
import {css} from 'styled-components'
import { IconButton } from 'application/components/controls/icon_button'


export const baseTableRowCSS = css`
    display: flex;
    justify-content: space-between;
    width: ${ ({width}) => width ? width : '100%' };
    align-items: center;
    padding:0 8px;
    min-height: ${ ({cssheight}) => `${cssheight}` };   
    text-decoration: none;
    color: ${ ({theme}) => theme?.color?.anthracite };
    box-sizing: border-box;
    background: ${ ({backgroundcolor}) => backgroundcolor };

    & div.row-button > span:hover,
    div.row-button > span:active {
        background: ${ ({backgroundcolor}) => backgroundcolor };
    }
`


export const BaseTableRow = (
    {
        element,
        configuration,
        isSelected = false,
        moIsActive = false,
        className='table-row-base',
        hasDetails = false,
        showDetails = false,
        setShowDetails = () => {},
        cssHeight = '32px'
    }
) => {
    const contextMenuWidth = configuration.contextMenuWidth || 0
    const placeHolderWidth = 48
    const placeHolderMenuWidth = hasDetails ? placeHolderWidth : 0
    const fixedSpace = contextMenuWidth + placeHolderMenuWidth 
    const flexibleCellContainerWidth = `calc(100% - ${fixedSpace}px)`


    const handleSetOpen = () => {
        return hasDetails    
            ? setShowDetails(!showDetails) 
            : () => {}
    } 


    return  <FlexBox 
        cssHeight={cssHeight} 
        onClick={handleSetOpen}
    >
        {
            hasDetails  && <StyledTableCell
                flexGrow='0' 
                cssWidth={placeHolderWidth + 'px'} 
                cssMaxWidth={placeHolderWidth +'px'}
            >
                {
                    moIsActive 
                        ? <FlexBox 
                            width={placeHolderWidth}
                        />
                        : showDetails 
                            ? <IconButton 
                                iconKey='expand_more' 
                                onButtonClick={handleSetOpen}
                                iconSize='18px'
                                buttonClassName='row-button'
                            />
                            : 
                            <IconButton 
                                iconKey='chevron_right' 
                                onButtonClick={handleSetOpen}
                                iconSize='18px'
                                buttonClassName='row-button'
                            />
                        
                }
            </StyledTableCell>
        }
        <FlexBox
            width='auto' 
            flexBasis={flexibleCellContainerWidth} 
            flexGrow='0' 
            flexShrink='0' 
            className={className}
        > 
            {
                configuration.columns.map(
                    (column, columnIndex) => {
                        return <TableCell
                            key={`${element.id}_${columnIndex}`}
                            configuration={configuration} 
                            column={column}
                        >
                            <column.CellContent
                                element={element} 
                                isSelected={isSelected}
                                moIsActive={moIsActive}
                                showDetails={showDetails}
                            />
                        </TableCell>
                    }
                )
            }
        </FlexBox>
        {
            exists(configuration.ContextMenu) 
            && <StyledTableCell
                flexGrow='0' 
                cssWidth={`${configuration.contextMenuWidth}px`} 
                cssMaxWidth={`${configuration.contextMenuWidth}px`}
            >
                <configuration.ContextMenu 
                    element={element} 
                    moIsActive={moIsActive} 
                />
            </StyledTableCell>
        }
    </FlexBox> 
}