import React from 'react'
import { takeEvery } from 'redux-saga/effects'
import i18next from 'i18next'
import { toast } from 'react-toastify'
import { NOTIFICATION, ACTIONS } from 'application/constants'

import {ErrorToast} from 'application/components/building_blocks/toaster/error_toast'

function showNotificationSaga(action) {
    switch (action.level) {     
    case NOTIFICATION.LEVEL.ERROR:
        toast.error((props) => <ErrorToast {...props} {...action.payload} message={i18next.t(action.message)} />, {toastId: action.message, autoClose: 10000})
        break
    case NOTIFICATION.LEVEL.WARNING:
        toast.warn(i18next.t(action.message), {toastId: action.message})
        break
    default: //NOTIFICATION.LEVEL.SUCCESS
        toast.success(i18next.t(action.message), {toastId: action.message})
    }
}

export function* showNotificationWatcher() {
    yield takeEvery(ACTIONS.NOTIFICATIONS_SHOW_TOAST, showNotificationSaga)
}