import { exists } from 'application/common/data_helpers'
import { FILTERS } from 'application/constants'

export const 
    coverageName = 'comprehensiveCoverage',
    deductableName = 'comprehensiveDeductable',
    insuranceSelectInputName = 'comprehensiveCompanyId',
    insuranceTextName = 'comprehensiveCompanyName',
    certificateNumberName = 'comprehensiveCertificateNumber',
    typeName = 'comprehensiveTypeId',
    claimName = 'comprehensiveClaimNumber',
    
    emptyValues = {
        comprehensiveClaimNumber: 'unbekannt',
        comprehensiveCoverage: FILTERS.VALUES.DAMAGES.COVERAGE.NONE,
        comprehensiveCertificateNumber: null,
        comprehensiveCompanyId: null,
        comprehensiveCompanyName: null,
        comprehensiveDeductable: null,
        comprehensiveTypeId: null
    },
    mapDefaultValues = (formData) => ({
        comprehensiveClaimNumber: formData?.claim?.number || emptyValues.comprehensiveClaimNumber,
        comprehensiveCoverage: formData?.insurance?.coverage || FILTERS.VALUES.DAMAGES.COVERAGE.NONE,
        comprehensiveCertificateNumber: formData?.insurance?.certificateNumber || emptyValues.comprehensiveCertificateNumber,
        comprehensiveCompanyId: formData?.insurance?.company?.id || emptyValues.comprehensiveCompanyId ,
        comprehensiveCompanyName: formData?.insurance?.company?.name || emptyValues.comprehensiveCompanyId,
        comprehensiveDeductable: formData?.insurance?.deductable || emptyValues.deductable,
        comprehensiveTypeId: formData?.type?.id || emptyValues.comprehensiveTypeId
    }),
    mapFormValuesToComprehensiveCompany = (formValues) => {
        return exists(formValues?.comprehensiveCompanyId) && formValues.comprehensiveCompanyId !== 'NOT_PROVIDED'
            ? { id: formValues.comprehensiveCompanyId }
            : { name: formValues.comprehensiveCompanyName }
    },
    mapFormValuesToComprehensivePayload = (formValues) => {
        return {
            comprehensive: {
                insurancePayload: {
                    certificateNumber: formValues.comprehensiveCertificateNumber,
                    company: mapFormValuesToComprehensiveCompany(formValues),
                    coverage: formValues.comprehensiveCoverage,
                    deductable: formValues.comprehensiveDeductable
                },
                claimPayload: {
                    number: formValues.comprehensiveClaimNumber
                },
                typePayload: {
                    id: formValues.comprehensiveTypeId
                }
            }        
        }
    }