import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'


//ACTIONS.DAMAGE_SEARCH_SEARCH_DAMAGES_BY_PARAMS

// damagesSearch: {
//     results: {
//         list: [],
//         total: 0
//     },
//     loading: false,
//     loaded: false,
// }


export const damagesSearchReducer = (
    state = defaultStore.damagesSearch , 
    action
) => {
    switch (action.type) {
    case ACTIONS.DAMAGES_SEARCH_GET_DAMAGES_BY_PARAMS: 
        return {
            ...state,
            loading: true,
            loaded: false
        }
    case ACTIONS.DAMAGES_SEARCH_POPULATE_DAMAGES: 
        return {
            results: {
                list: action.results,
                total: action.total
            },
            loading: false,
            loaded: true,
        }
    case ACTIONS.DAMAGES_SEARCH_CLEAR_RESULTS:
        return {
            results: {
                list: [],
                total: 0
            },
            loading: false,
            loaded: false
        }
    default:  
        return state
    }

}