import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const postMessage = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                `damages/${params.damageId}/messages`,
                HTTP_METHOD.POST, 
                payload
            )
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/messages`, 
                params.pin, 
                HTTP_METHOD.POST, 
                payload
            )
            return apiCall(request)
        }
    }
)