import { put, takeEvery } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { populateActivityLogWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/activity_log'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* requestActivityLogWorkflowPanelSaga(action) {
    try {
        const {activities, total} = yield resourceCall(
            api.gateway.activities.getActivities,
            {
                offset: action.offset,
                limit: action.limit
            }
        )

        yield put(
            populateActivityLogWorkflowPanel(activities, total)
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* workflowPanleActivityLogWatcher() {
    yield takeEvery(
        ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_REQUEST, 
        requestActivityLogWorkflowPanelSaga
    )
    yield takeEvery(
        ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_MORE_REQUEST, 
        requestActivityLogWorkflowPanelSaga
    )
}