import React from 'react'
import { useSteps } from 'application/components/hooks'
import { useTranslation } from 'react-i18next'

export const withCommissionExpertSteps = (initialStepIndex = 0) => (Component) => ({ ...props }) => {

    const { t } = useTranslation()

    const initialSteps = [
        {
            id: 0,
            label: t('Upload files')
        },
        {
            id: 1,
            label: t('Complete damage data')
        },
        {
            id: 2,
            label: t('Complete customer data')
        },
        {
            id:3,
            label: t('Inspection site')  
        }
    ]

    const {
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep
    } = useSteps(initialSteps, initialStepIndex)


    return <Component 
        {...props} 
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        getCurrentStep={getCurrentStep}
        getStepsState={getStepsState}
    />
}