import React from 'react'
import { Table } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import { useMoCloseWatcher, useTableLoader } from 'application/components/hooks'
import {withRouter} from 'application/components/higher_order_components/with_router'


const TableGroupMembersComponent = (
    {
        groupMembersList,
        groupMembersListLoading,
        groupMembersListLoaded,
        groupMembersTotal,
        onLoadGroupMembers,
        updateConstraint,
        match,
        moName=null
    }
) => {
    useTableLoader(
        onLoadGroupMembers,
        match, 
        configuration, 
        updateConstraint
    )
    useMoCloseWatcher(
        moName, 
        groupMembersList
    )

    return <Table 
        configuration={configuration}
        elementsLoading={groupMembersListLoading}
        elementsLoaded={groupMembersListLoaded}
        elementsTotal={groupMembersTotal}
        elements={groupMembersList}
        emptyMessage='No users found'
    />
}

export const TableGroupMembers = withRouter(TableGroupMembersComponent)