import React from 'react'
import { ErrorPageBase } from 'application/components/pages/errors/_common'


const SessionExpiredPage = () => {
    return <ErrorPageBase 
        errorMessage='Session for this company has expired' 
    />
}

export {SessionExpiredPage}
