import {
    StringEqualsFilter, 
    StringContainsFilter,
    OrOperator,
    AndOperator,
    StringNotEqualsFilter,
    GreaterOrEqualThanFilter,
    LesserOrEqualThanFilter,
    StaticFilter
} from '@ec/filter-sort-page-query-js'
import { exists } from 'application/common/data_helpers'
import { timerangeConverter } from 'application/common/date_time_helpers'
import { ROUTE_PARAMETERS, FILTERS, DAMAGE_TYPE } from 'application/constants'


export const damages = {
    vehicleLicensePlate: {
        filterName: 'damages_vehicle.licensePlate',
        filterKey: 'soalp',
        label: 'License plate',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('vehicle.licensePlate', value, false)
        ])
    },
    jobNumber: {
        filterName: 'damages_jobNumber',
        filterKey: 'soajn',
        label: 'Job number',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('jobNumber', value, false)
        ])
    },
    claimNumber: {
        filterName: 'damages_LiabilityData.Claim.Number',
        filterKey: 'soacn',
        label: 'Claim number',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('LiabilityData.Claim.Number', value, false)
        ])
    },
    customerId: {
        filterName: 'damages_CustomerId',
        filterKey: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.CUSTOMER.ID,
        label: 'Customer',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('Vehicle.Keeper.CustomerId', value, true)
        ])
    },
    customerName: {
        filterName: 'damages_CustomerName',
        filterKey: 'soacname',
        label: 'Customer',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('vehicle.keeper.firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('vehicle.keeper.lastName', value, false),
            new OrOperator(),
            new StringContainsFilter('vehicle.keeper.companyName', value, false),
        ])
    },
    assigneeId: {
        filterName: 'damages_assignee.userId',
        filterKey: 'soauid',
        label: 'Assignee',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('assignee.userId', value, true)
        ])
    },
    assigneeCurrentUserId: {
        filterName: 'damages_assignee.currentUserId',
        filterKey: 'userId',
        label: 'Assignee',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('assignee.userId', value, true)
        ])
    },
    repairAuthorization: {
        filterName: 'damages_RepairAuthorization',
        filterKey: 'repairAuthorization',
        label: 'Repair authorization',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('RepairAuthorization', value, true)
        ])
    },
    repairAuthorizationGranted: {
        filterName: 'damages_RepairAuthorization_granted',
        filterKey: null,
        label: 'Repair authorization: granted',
        defaultValue: null, //not necessary for static filters
        isStatic: true,
        getFilterQuery: () => ([
            new StringEqualsFilter(
                'RepairAuthorization', 
                FILTERS.VALUES.DAMAGES.REPAIR_AUTHORIZATION.GRANTED, 
                true
            )
        ])
    },
    state: {
        filterName: 'damages_state',
        filterKey: 'sods',
        label: 'Damage state',
        defaultValue: FILTERS.VALUES.DAMAGES.STATE.IN_PROGRESS,
        isStatic: false,
        getFilterQuery: value => {
            switch(value){
            case FILTERS.URL.VALUES.DAMAGES.STATE.IN_PROGRESS:
                return  [
                    new StringNotEqualsFilter(
                        FILTERS.QUERY_KEYS.DAMAGE.STATE, 
                        FILTERS.VALUES.DAMAGES.STATE.DONE, 
                        true
                    )
                ]
            case FILTERS.URL.VALUES.DAMAGES.STATE.FINISHED: 
                return [
                    new StringEqualsFilter(
                        FILTERS.QUERY_KEYS.DAMAGE.STATE, 
                        FILTERS.VALUES.DAMAGES.STATE.DONE, 
                        true
                    )
                ]
            case FILTERS.URL.VALUES.DAMAGES.STATE.ALL: 
                return []
            default: 
                return [
                    new StringNotEqualsFilter(
                        FILTERS.QUERY_KEYS.DAMAGE.STATE, 
                        FILTERS.VALUES.DAMAGES.STATE.DONE, 
                        true
                    )
                ]
            }  
        }
    },
    customState: {
        filterName: 'damages_customstate',
        filterKey: 'sodcs',
        label: 'Custom state',
        defaultValue: null,
        isStatic: false,
        getFilterQuery: value => {
            if(!exists(value)){
                return []
            }

            return [
                new StringEqualsFilter(
                    'state.id', 
                    value, 
                    true
                )
            ]
        }
    },
    creationTimeRange: {
        filterName: 'damages_timerange',
        filterKey: 'soatr',
        label: 'Damages time range',
        defaultValue: null,
        getFilterQuery: (value) => {
            const { startDate, endDate } = timerangeConverter.getDateTimesFromRange(value)
            return [
                new GreaterOrEqualThanFilter('createdAt',  `${startDate}T00:00:00`, true),
                new AndOperator(),
                new LesserOrEqualThanFilter('createdAt', `${endDate}T23:59:59`, true)
            ]
        }
    },
    idleTime: {
        filterName: 'damages_idleTime',
        filterKey: 'soait',
        label: 'Damages idle time',
        defaultValue: null,
        getFilterQuery: (value) => {
            const targetDate = new Date()
            targetDate.setDate(targetDate.getDate() - value)
            const formattedDate = targetDate.toISOString().slice(0, 10)

            return [
                new LesserOrEqualThanFilter('modifiedAt', `${formattedDate}T23:59:59`, true)
            ]
        }
    },
    type: {
        filterName: 'damages_type',
        filterKey: 'soadt',
        label: 'Damagetype',
        defaultValue: DAMAGE_TYPE.ALL,
        getFilterQuery: (value) => {
            switch(value){
            case DAMAGE_TYPE.ALL: 
                return []
            default: 
                return [
                    new StringEqualsFilter(
                        'type', 
                        value,
                        true
                    )
                ]
            }
        }
    },
    comprehensiveDamageType: {
        filterName: 'damages_comprehensivedamagetype',
        filterKey: 'sodcdt',
        label: 'Comprehensive damage type',
        defaultValue: null,
        isStatic: false,
        getFilterQuery: value => {
            if(!exists(value)){
                return []
            }

            return [
                new StringEqualsFilter(
                    'comprehensiveData.type.id', 
                    value, 
                    true
                )
            ]
        }
    },
    isFictitious: {
        filterName: 'damages_isfictitious',
        filterKey: 'sodif',
        label: 'Is fictitious',
        defaultValue: FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.ALL,
        isStatic: false,
        getFilterQuery: value => {
            switch(value){
            case FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.TRUE:
                return  [
                    new StaticFilter('invoices[type == "FictitiousRepair"]')
                ]
            case FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.FALSE: 
                return  [
                    new StaticFilter('invoices[[type != "FictitiousRepair"]]')
                ]
            case FILTERS.URL.VALUES.DAMAGES.IS_FICTITIOUS.ALL: 
                return []
            default: 
                return []
            }  
        }
    },
}