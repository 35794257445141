import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput, TextArea } from 'application/components/controls/form'
import { PhoneNumberInput } from 'application/components/controls/form/phone_number_input'
import { PartialForms } from 'application/components/forms'
import { TwoColumns } from 'application/components/forms/_common'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { VehicleDataPartialForm } from 'application/components/forms/_partial_forms'
import { FormHeadline } from 'application/components/fragments/typography'
import { InsuranceInputGroup } from 'application/components/building_blocks/insurance_input_group'
import { INSURANCE_TYPE } from 'application/constants/insurances'
import { exists, isDefined, isNull } from 'application/common/data_helpers'


export const CustomerDataPartialForm = ({
    formHook,
    onDataChange = () => {},
    onFirstNameChange = () => {},
    onLastNameChange = () => {},
    onCompanyNameChange = () => {}
}) => {
    const {t} = useTranslation()
    
    const {
        register,
        errors,
        formStateValues,
        setValue,
        control,
        updateFormState,
        setFormStateValues,
        reset
    } = formHook

    const companyId = formStateValues?.legalProtectionInsurance?.company?.id 
    const companyName = formStateValues?.legalProtectionInsurance?.company?.name

    const customerHasLegalInsurance = isDefined(formStateValues?.legalProtectionInsurance) 
    && (
        exists(companyId) 
        || exists(companyName)
    ) 

    const initialInsuranceIsFromList = exists(
        companyId
    )
    const showPredefinedList = !customerHasLegalInsurance || initialInsuranceIsFromList
    const [legalInsuranceSelectionFromList, setLegalInsuranceSelectionFromList] = useState(showPredefinedList)


    useEffect(
        () => {
            if(!customerHasLegalInsurance) {
                setValue(
                    'legalProtectionInsurance.certificateNumber',
                    ''
                ) 
                formStateValues.legalProtectionInsurance.certificateNumber = ''
            }
        },
        [customerHasLegalInsurance, formStateValues.legalProtectionInsurance, setValue]
    )



    useEffect(
        () =>  {
            if (isNull(companyId)) {
                formStateValues.legalProtectionInsurance.company.name = ''
            }
        },
        [companyId, formStateValues.legalProtectionInsurance.company]
    )

    return <React.Fragment>
        <TwoColumns
            columnLeft={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.isCompany}
                    name='isCompany'
                    label={t('Is vehicle owned by company?')}
                    onChange={()=>{
                        onDataChange()
                        updateFormState()
                    }}
                />
            }
        />
        {
            !formStateValues.isCompany 
                ? <React.Fragment>
                    <TwoColumns 
                        columnLeft={
                            <TextInput
                                label={t('Firstname')}
                                name='firstName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.firstName}
                                onChange={(e) => {
                                    onDataChange()
                                    onFirstNameChange(e.target.value)
                                }}
                            />
                        }
                        columnRight={ 
                            <TextInput
                                label={t('Lastname')}
                                name='lastName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.lastName}
                                onChange={(e) => {
                                    onDataChange()
                                    onLastNameChange(e.target.value)
                                }}
                            />
                        }
                    />
                </React.Fragment>
                : <TextInput
                    label={ t('Company name')}
                    name='companyName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.companyName}
                    onChange={(e) => {
                        onDataChange()
                        onCompanyNameChange(e.target.value)
                    }}
                />
        }

        <PartialForms.AddressDataPartialForm
            register={register}
            errors={errors}
            onChange={onDataChange}
            setValue={setValue}
        />

        <TextInput
            label={t('Email')}
            name='email'
            register={register}
            validate={{mustBeEmail: validationMethods.mustBeEmail}}
            error={errors.email}
            onChange={(e) => {
                onDataChange()
            }}
        />
        <TwoColumns 
            columnLeft={
                <PhoneNumberInput
                    defaultValue={formStateValues.phoneNumber}
                    validate={null}
                    control={control}
                    label={t('Phone number')}
                    name='phoneNumber'
                    register={register}
                    error={errors.phoneNumber}
                    onChange={onDataChange}
                    setValue={setValue}
                    allowLandLine={true}
                />
            }
            columnRight={
                <PhoneNumberInput
                    defaultValue={formStateValues.mobilePhoneNumber}
                    validate={null}
                    control={control}
                    label={t('Mobile phone number')}
                    name='mobilePhoneNumber'
                    register={register}
                    error={errors.mobilePhoneNumber}
                    onChange={onDataChange}
                    setValue={setValue}
                    allowLandLine={false}
                />
            }
        />

        <TextInput
            label={t('IBAN')}
            name='iban'
            register={register}
            validate={ {mustBeIBAN: validationMethods.mustBeIBAN} }
            error={errors.iban}
            onChange={onDataChange}
        />
        <TwoColumns
            columnLeft={
                <TextInput
                    label={t('Customer number')}
                    name='externalReferenceId'
                    register={register}
                    validate={null}
                    error={errors.externalReferenceId}
                    onChange={onDataChange}
                />
            }
            columnRight={
                <YesNoSelect
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.isEntitledToTaxDeduction}
                    name='isEntitledToTaxDeduction'
                    label={t('Is entitled to tax deduction')}
                    onChange={onDataChange}
                    menuPlacement='top'
                />
            }
        />
        <TextArea
            label={t('Annotations')}
            name='annotation'
            register={register}
            validate={null}
            error={errors.annotation}
            onChange={onDataChange}
        />
        <InsuranceInputGroup
            register={register}
            setValue={setValue}
            formStateValues={formStateValues}
            setFormStateValues={setFormStateValues}
            updateFormState={updateFormState}
            errors={errors}
            insuranceSelectInputName = {'legalProtectionInsurance.company.id'}
            insuranceTextName = {'legalProtectionInsurance.company.name'}
            setInsuranceSelectionFromList = {setLegalInsuranceSelectionFromList}
            insuranceSelectionFromList={legalInsuranceSelectionFromList}
            formTitle={'Legal insurance'}
            reset={reset}
            insuranceType={INSURANCE_TYPE.LEGAL_PROTECTION}
            validate={null}
        />
        {
            customerHasLegalInsurance && <TextInput
                label={t('Insurance certificate number')}
                name='legalProtectionInsurance.certificateNumber'
                register={register}
                error={errors?.legalProtectionInsurance?.certificateNumber}
                onChange={onDataChange}
                validate={ {notEmpty: validationMethods.notEmpty} }
            />
        }

        <FormHeadline>
            {t('Preferred vehicle')}
        </FormHeadline>
        <VehicleDataPartialForm
            register={register}
            onChange={onDataChange}
            nameLicensePlate='preferredVehicleInfo.licensePlate'
            nameMake='preferredVehicleInfo.make'
            nameModel='preferredVehicleInfo.model'
            hasChassisNumber={true}
            nameChassisNumber='preferredVehicleInfo.chassisNumber'
            validateLicensePlate={null}
        />
    </React.Fragment>
}