import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledDamageManagerSidebar = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 210px;
    border-right: 1px solid ${props => props.theme.color.gray15};
`

export const StyledDamageManagerSidebarStatus = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledDamageManagerSidebarDropdownContainer = styled.div`
    padding: 0 8px;
    margin-top: 8px;

    & label{
        margin-bottom: 4px;
        color: ${props => props.theme.color.anthracite};
        font-size: ${props => props.theme.fontSize.small};
    }
`

export const StyledDamageManagerSidebarNavigation = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`

export const StyledDamageManagerSidebarNavigationItem = styled(NavLink)`
    padding: 0 8px 0 32px;
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.color.anthracite};
    text-decoration: none;
    height: 48px;
    align-items: center;
    font-size: ${props => props.theme.fontSize.small};
    background-color: ${props => props.active ? props.theme.color.gray5 : 'transparent'};

    &:hover {
        background-color: ${props => props.theme.color.gray5};
    }
`
