import React from 'react'
import { StyledTableCell } from './fragments'

const round = (number) => Math.round((( number ) + Number.EPSILON) * 100) / 100

const calculateWidth = (column, columns) => {
    if(isNaN(column.width)){
        return column.width
    }

    const overallWidth = columns.map(c => c.width).reduce((partialSum, a) => partialSum + a, 0)
    const widthMod = round(100 / overallWidth)
    const newWidth = round(column.width * widthMod)
    return `${newWidth}%`
}

export const TableCell = ({
    column, //current column
    children,
    configuration
}) => {
    const width = calculateWidth(column, configuration.columns)
    return <StyledTableCell justify={column.justify} cssWidth={width}>
        {
            children
        }
    </StyledTableCell>
}