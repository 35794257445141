import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'
import { DateComponent } from '@ec/ui-controls-react'

const IncidentDateChangedInfo = (
    {activityData}
) => {

    const {t} = useTranslation()

    const {
        oldDate = null,
        newDate = null,
        damage = null
    } = activityData

    return <FlexBox>
        {t('Damage')} : {damage.licensePlate}, &nbsp;
        <DateComponent date={oldDate} />  
        <RightArrow />
        <DateComponent date={newDate} />
    </FlexBox>
}



IncidentDateChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}



export {IncidentDateChangedInfo} 