import styled from 'styled-components'

export const PriorityIcon = styled.div`
    width: ${props => props.$width ? props.$width : 'auto'};
    display: flex;
    justify-content: center;
    & i{
        font-weight: ${props => props.theme.fontWeight.standardBold};
    }
`

export const PriorityLabel = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.fontColor};
    margin-left: 4px;
`