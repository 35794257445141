import { connect } from 'react-redux'
import {RealtimeNotificationsIndicatorComponent} from './component'
import { showSidebar } from 'application/redux/actions/realtime_notifications'

const mapStateToProps = (state) => {
    return {
        hasNewNotifications: state.realtimeNotifications.hasNewNotifications,
        notifications: state.realtimeNotifications.notifications
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowSidebar: () => dispatch(showSidebar())
    }
}

export const RealtimeNotificationsIndicator = connect(
    mapStateToProps,
    mapDispatchToProps
)(RealtimeNotificationsIndicatorComponent)