import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const createDamageKickstart = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`branches/${params.branchId}/damages/kickstart`, HTTP_METHOD.POST, payload)
            return apiCall(request)
        },
        qr: (payload) => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/branchDamages`, params.pin, HTTP_METHOD.POST, payload)
            return apiCall(request)
        },
        kickstart: (payload) => {
            const request = fetchRequestBuilderPin(`external/qr/${params.sharedResourceId}/branchDamages`, params.pin, HTTP_METHOD.POST, payload)
            return apiCall(request)
        }
    }
)