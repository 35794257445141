import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledImage = styled.img`
    width: auto;
    max-width: 90%;
`

const ImageViewer = (
    {
        filePath
    }
) => {
    return <StyledImage 
        src={filePath} 
        className='jestImageViewer' 
    />
}

ImageViewer.propTypes = {
    filePath: PropTypes.string.isRequired
}

export {ImageViewer}