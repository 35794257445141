import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const deleteLawfirmById = (params) => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/communications/lawfirm`, HTTP_METHOD.DELETE)
            return apiCall(request)
        },
        sharedResource: () => {}
    }
}