import { connect } from 'react-redux'
import {Step0Component} from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => ({
    categories: state.common.categories,
})

const mapDispatchToProps = dispatch => {
    return {
        onQRCodeCreation: (name, payload) => dispatch(
            sendPayloadRequest(name, payload)
        ),
    }
}

export const Step0 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step0Component)