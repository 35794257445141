import { 
    put, 
    // select 
} from 'redux-saga/effects'
import { loadDocumentsByCategory } from 'application/redux/actions/documents_by_categories'


export function* refreshDamageManagerDocumentCenterCategorySaga(action) {
    const { 
        damageId,
        categoryId
    } = action.metaData

    yield put(
        loadDocumentsByCategory(
            damageId,
            categoryId,
            0,
            10
        )
    )
}