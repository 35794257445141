import React from 'react'
import {RealtimeNotificationsComponent} from './component'

export const RealtimeNotificationsEffects = (props) => {
    // const {         
    //     previousNotificationsLoaded,
    //     previousNotificationsLoading,
    //     onGetPreviousNotifications
    // } = props

    // useEffect(() => {
    //     !previousNotificationsLoaded && !previousNotificationsLoading && onGetPreviousNotifications()
    // }, [onGetPreviousNotifications, previousNotificationsLoaded, previousNotificationsLoading])

    return <RealtimeNotificationsComponent {...props} />
}