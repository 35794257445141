import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { IconRadioButtons } from '../_common/icon_radio_buttons'
import { iconMapper } from 'application/common/damage_status/icon_mapper'
import { PushBottom32 } from 'application/components/pages/_common'
import { useStatefulForm } from 'application/components/hooks'

const EditCustomState = (
    { 
        abort, 
        dialog, 
        onSaveCustomState 
    }
) => {
    const { customStateName, customStateId, customStateIconName } = dialog.payload
    const {t} = useTranslation()

    const defaultValues = {
        name: customStateName,
        iconName: iconMapper(customStateIconName)
    }

    const { 
        register,
        errors, 
        handleSubmit,
        getValues 
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => {
        onSaveCustomState(
            dialog.name, 
            customStateId, 
            getValues()
        )
    }
    
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <TextInput
                    label={t('Custom state name')}
                    name='name'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.name}
                    autoFocus={true}
                    onKeyDownEnter={handleSubmit(onSubmitSuccess)}
                />         
                <IconRadioButtons 
                    register={register}
                />     
            </FlexBox>
            <PushBottom32 />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save custom status'
        />
    </React.Fragment>
}

export const EditCustomStateComponent = withDialogDataLoader(EditCustomState) 