import React from 'react'
import { TextInput } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { TwoColumns } from 'application/components/forms/_common'
import { InsuranceInputGroup } from 'application/components/building_blocks/insurance_input_group'
import {liabilityDataStructure} from 'application/components/forms/_configurations'
import { validationMethods } from 'application/common'


export const LiabilitaInsurancePartialForm = ({
    setInsuranceSelectionFromList,
    insuranceSelectionFromList,
    stateFulFormHook,
    disabled = false,
    formTitle = 'Liability insurance'
}) => {
    const {t} = useTranslation() 

    const {
        insuranceSelectInputName,
        insuranceTextName,
        certificateNumberName,
        claimNumberName,
    } = liabilityDataStructure

    const {
        register,
        setValue,
        formStateValues,
        setFormStateValues,
        updateFormState,
        errors,
        reset
    } = stateFulFormHook


    return <React.Fragment>
        <InsuranceInputGroup
            register={register}
            setValue={setValue}
            formStateValues={formStateValues}
            setFormStateValues={setFormStateValues}
            updateFormState={updateFormState}
            errors={errors}
            insuranceSelectInputName = {insuranceSelectInputName}
            insuranceTextName = {insuranceTextName}
            setInsuranceSelectionFromList = {setInsuranceSelectionFromList}
            insuranceSelectionFromList={insuranceSelectionFromList}
            formTitle={formTitle}
            reset={reset}
        />
        <TwoColumns
            columnLeft={
                <TextInput 
                    label={t('Insurance certificate number')}
                    name={certificateNumberName}
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors[certificateNumberName]}
                    onChange={updateFormState}
                    disabled={disabled}
                />            
            }
            columnRight={
                <TextInput 
                    label={t('Claim number')}
                    name={claimNumberName}
                    register={register}
                    validate={null}
                    error={errors[claimNumberName]}
                    onChange={updateFormState}
                    disabled={disabled}
                />  
            }
        />
    </React.Fragment>
   
}