import React from 'react'
import { TableCompanies } from 'application/components/tables/common/companies'
import { SubPageLayout, PushBottom32 } from 'application/components/pages/_common'
import { withCustomersLoader } from 'application/components/higher_order_components/with_customers_loader'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { TableBusinessCustomersSearchFilter } from 'application/components/building_blocks/filters/table_business_customers_search_filter/component'

export const CompaniesContentComponent = (
    {
        customersList,
        customersListLoaded,
        customersListLoading,
        customersListTotal,
        updateConstraint,
        onLoadCustomersList
    }
) => {
    return <SubPageLayout>
        <FilterFragment.FilterContainer>
            <TableBusinessCustomersSearchFilter />
        </FilterFragment.FilterContainer>
        <PushBottom32 />
        <TableCompanies 
            companiesList={customersList}
            companiesListLoaded={customersListLoaded}
            companiesListLoading={customersListLoading}
            companiesListTotal={customersListTotal}
            onLoadCustomersList={onLoadCustomersList}
            updateConstraint={updateConstraint}
        />
        <PushBottom32 />
    </SubPageLayout>
}

export const CompaniesContent = withCustomersLoader(
    CompaniesContentComponent
)