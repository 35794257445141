import { NavLink } from 'react-router-dom'
import styled from 'styled-components'


export const StyledActivityLink = styled(NavLink)`
    color: ${props => props.theme.color.primary};
    text-decoration: none;
    cursor: pointer;
    margin-right: 8px;

    &:after{
        content: "/";
        margin-left: 8px;
        color: ${props => props.theme.color.anthracite};
    }

    &:last-child:after{
        content: "";
        margin-left: 0%;
    }
`
