import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const modulesReducer = (
    state = defaultStore.modules, 
    action
) => {
    switch (action.type) {
    case ACTIONS.REQUEST_MODULES: {
        return {
            ...state,
            loaded: false,
            loading: true
        }
    }
    case ACTIONS.POPULATE_MODULES: {
        return {
            ...state,
            loaded: true,
            loading: false,
            accoundId: action.accoundId,
            appliedModules: action.appliedModules,
            accountModules: action.accountModules,
            branchModules: action.branchModules
        }
    }
    default:
        return state
    }
}