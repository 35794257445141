import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { 
    WidgetHeadline, 
    WidgetErrorMessage, 
    WidgetCardLayout, 
    WidgetCardLayoutLeft, 
    WidgetCardLayoutRight,
    WidgetCardLayoutIcon,
    WidgetText
} from 'application/components/widgets/_common'

const DamageAccidentReport = ({widgetKey, payload, loadingFailed}) => {
    const {t} = useTranslation()
    const {formData} =  payload

    const description = formData?.accidentReport?.location?.description || ''
    const circumstances = formData?.accidentReport?.circumstances || ''

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    return <WidgetCardLayout>
        <WidgetCardLayoutLeft>
            <WidgetCardLayoutIcon>
                <FontIcon icon='assignment_ind'/>
            </WidgetCardLayoutIcon>
        </WidgetCardLayoutLeft>
        <WidgetCardLayoutRight>
            <WidgetHeadline>{t('Accident report')}</WidgetHeadline>
            <br/>
            <WidgetText>
                <strong>{t('Location')}</strong>: {description}
            </WidgetText>
            <br/>
            <WidgetText><strong>{t('Description')}</strong>: {circumstances}</WidgetText>
        </WidgetCardLayoutRight>
    </WidgetCardLayout>
}

DamageAccidentReport.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageAccidentReport }