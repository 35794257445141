import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom24, Spacer } from 'application/components/pages/_common'
import { StyledLockedMessage } from 'application/components/building_blocks/account_box/fragments'




export const DeleteAccountlogoComponent = (
    { 
        dialog,
        abort, 
        onDeleteAccountlogo 
    }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox>
                {t('Do you want to delete your current Accountlogo?')}
            </FlexBox>
            <PushBottom24/>
            <InfoBox
                type={INFOBOX.TYPES.WARNING} 
                cssMargin='0 0 16px' 
                cssPadding='8px 16px 8px 8px'
                cssMinHeight='unset'
                direction='row'
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <StyledLockedMessage>
                    {t('This action can not be undone!')}
                </StyledLockedMessage>
            </InfoBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={() => onDeleteAccountlogo(dialog.name)}
            submitText='Delete'
        />
    </React.Fragment>
}