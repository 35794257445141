import { ACTIONS } from 'application/constants'


export const getPreferredAccount = () => ({
    type: ACTIONS.AUTH_REQUEST_PREFERRED_ACOUNT
})

export const populatePreferredAccount = (
    preferredAccount= null
) => ({
    type: ACTIONS.AUTH_POPULATE_PREFERRED_ACOUNT,
    preferredAccount
})

export const preferredAccountNotFound = () => ({
    type: ACTIONS.AUTH_PREFERRED_ACOUNT_NOT_FOUND
})

export const preferredAccountFound = () => ({
    type: ACTIONS.AUTH_PREFERRED_ACOUNT_FOUND
})

export const setAccountAsPreferred = (account) =>({
    type: ACTIONS.AUTH_PREFERRED_ACOUNT_SET_ACCOUNT_AS_PREFERRED,
    account
})

export const unsetAccountAsPreferred = () =>({
    type: ACTIONS.AUTH_PREFERRED_ACOUNT_UNSET_ACCOUNT_AS_PREFERRED
})

export const refreshPreferredAccount = () => ({
    type: ACTIONS.AUTH_PREFERRED_ACOUNT_REFRESH_PREFERRED_ACCOUNT
})