
import uniqid from 'uniqid'
import { FormElementWrapper } from 'application/components/controls/form/_common/form_element_wrapper'
import { TextInputPureComponent } from 'application/components/controls/form/text_input'



export const PureInputWithLabel = ({ 
    label, 
    onKeyDown = () => {}, 
    onKeyDownEnter = () => {}, 
    defaultValue='', 
    type='text', 
    onChange = ()=>{}, 
    cssWidth = '100%', 
    cssMargin='0', 
    autoFocus= false, 
    disabled = false,
    readOnly = false,
    placeholder = ''
} ) => {
    const htmlId = uniqid()
    
    
    return <FormElementWrapper 
        cssWidth={cssWidth}
        cssMargin={cssMargin} 
        disabled={disabled}
    >
        <label 
            className='label' 
            htmlFor={htmlId}
        >
            {label}
        </label>
        <TextInputPureComponent 
            autoFocus={autoFocus} 
            onKeyDown={onKeyDown}
            onKeyDownEnter={onKeyDownEnter}
            htmlId={htmlId} 
            defaultValue={defaultValue} 
            inputType={type} 
            onChange={onChange} 
            disabled={disabled} 
            readOnly={readOnly}
            placeholder={placeholder}
        />
    </FormElementWrapper>
}