import { Select } from 'application/components/controls/form'
import { DamageTypeName } from 'application/components/name_mappings/damage_type_name'
import { DAMAGE_TYPE } from 'application/constants'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const DamageTypeSelectAll = ({
    name='type',
    register = () => {},
    setValue = () => {},
    initialValue,
    menuPlacement='top',
    onChange = () => {},
    validate = null, //usually no validation needed, no empty value possible
    error = null,
    label = 'Damage type',
    placeholder = undefined,
    disabled = false,
    hideValidationBox = false
}) => {
    const {t} = useTranslation()

    const damagetypOptions = [
        {
            value: DAMAGE_TYPE.ALL,
            label: <DamageTypeName damageType={DAMAGE_TYPE.ALL} />
        },
        {
            value: DAMAGE_TYPE.UNKNOWN,
            label: <DamageTypeName damageType={DAMAGE_TYPE.UNKNOWN} />
        },
        {
            value: DAMAGE_TYPE.LIABILITY,
            label: <DamageTypeName damageType={DAMAGE_TYPE.LIABILITY} />
        },
        {
            value: DAMAGE_TYPE.COMPREHENSIVE,
            label: <DamageTypeName damageType={DAMAGE_TYPE.COMPREHENSIVE} />
        }        
    ]

    const defaultValue = damagetypOptions.find(o => o.value === initialValue)

    return <Select 
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={damagetypOptions}
        defaultValue={defaultValue}
        validate={validate} 
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        disabled={disabled}
        hideValidationBox={hideValidationBox}
    />
}

DamageTypeSelectAll.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func,
    setValue: PropTypes.func,
    initialValue: PropTypes.string,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
    validate: PropTypes.object,
    error: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string || PropTypes.undefined
}