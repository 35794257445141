import { exists } from 'application/common/data_helpers'
import { useEffect, useMemo, useState } from 'react'

export const useKickstartBranchAssignees = (definition, getSetting, branches, updateSettings) => {
    const parsedSetting = useMemo(() => {
        const setting = exists(getSetting(definition.key)) ? getSetting(definition.key) : '[]'
        return JSON.parse(setting)
    }, [definition.key, getSetting])

    const [mappedBranches, setMappedBranches] = useState(branches.map(branch => {
        return {
            ...branch,
            assignee: parsedSetting.find(s => s.branchId === branch.id)?.assignee ?? null
        }
    }))

    useEffect(() => {
        setMappedBranches(branches.map(branch => {
            return {
                ...branch,
                assignee: parsedSetting.find(s => s.branchId === branch.id)?.assignee ?? null
            }
        }))
    }, [branches, parsedSetting])

    const onUpdateBranchAssignee = (branchId, assignee) => {
        const newMappedBranches = mappedBranches.map(branch => {
            if(branch.id === branchId){
                return {
                    ...branch,
                    assignee: {
                        firstName: assignee.firstName,
                        lastName: assignee.lastName,
                        id: assignee.id
                    }
                }
            }
            return branch
        })

        setMappedBranches(newMappedBranches)

        updateSettings(definition.key, JSON.stringify(newMappedBranches.map(branch => {
            return {
                branchId: branch.id,
                assignee: branch.assignee
            }
        })))
    }

    return {
        mappedBranches,
        onUpdateBranchAssignee
    }
}
