import { getModules } from 'application/redux/selectors'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useModules = () => {
    const {
        appliedModules,
        accountModules,
        branchModules,
        loaded,
        loading
    } = useSelector(getModules)

    const isModuleApplied = useCallback((moduleName) => {
        if(!loaded || loading){
            return false
        }
        return appliedModules.find(m => m.name === moduleName)
    }, [appliedModules, loaded, loading])

    const isAccountModuleApplied = useCallback((moduleName) => {
        if(!loaded || loading){
            return false
        }
        return accountModules.find(m => m.name === moduleName)
    }, [accountModules, loaded, loading])

    const isBranchModuleApplied = useCallback((moduleName, branchId) => {
        if(!loaded || loading){
            return false
        }

        if(!branchModules.find(branch => branch.branchId === branchId)){
            return false
        }

        return branchModules.find(branch => branch.branchId === branchId).modules.find(m => m.name === moduleName)
    }, [branchModules, loaded, loading])

    const getModuleSettings = useCallback((moduleName, branchId) => {
        if(!loaded || loading){
            return null
        }

        if(!branchModules.find(branch => branch.branchId === branchId)){
            return null
        }
        return branchModules.find(branch => branch.branchId === branchId).modules.find(m => m.name === moduleName)?.settings || null

    }, [branchModules, loaded, loading])

    return {
        appliedModules,
        isModuleApplied,
        modulesLoaded: loaded,
        modulesLoading: loading,
        isAccountModuleApplied,
        isBranchModuleApplied,
        getModuleSettings
    }
}