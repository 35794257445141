import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { all, call } from 'redux-saga/effects'

export function* kickstartCommissionDekraSubSaga (dekraData, createDamageResponse, files = []) {

    const postAttachmentsServerCalls = files.map(
        file => function* () {        

            const response = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: createDamageResponse.id
                },
                {
                    file: file.metaData
                }
            )
            return response
        }
    )
    const attachmentsResponses = yield all(
        postAttachmentsServerCalls.map(
            sc => call(sc)
        )
    )

    const commissionExpertPayload = {
        ...dekraData.commissionExpertPayload,
        documents: attachmentsResponses.map(r => r.id)
    }

    yield resourceCall(
        api.gateway.expert.updateCommissionExpert,
        {
            damageId: createDamageResponse.id
        },
        commissionExpertPayload
    )
}