

export const exists = (item) =>  {
    return item !== null
           && item !== undefined
           && item !== '' 
}

/**
 * 
 * @param {string} string 
 * @returns true if string is empty
 */
export const isEmptyString = (string = '') => {
    return string === ''
}

export const isNull = (object) => {
    return object === null
}

export const notNull = (object) => {
    return object !== null
}


/**
 * stringIsFilled - returns true if param is type of string and not empty
 * @param {string} string 
 * @returns {bool} true if string is not empty
 */
export const stringIsFilled = (string) => {
    return typeof string === 'string' && string !== ''
}

export const isString = (string) => {
    return exists(string) && typeof string === 'string'
}

export const isNumber = (number) => {
    return exists(number) && typeof number === 'number'
}

export const isDate = (date) => {
    return Object.prototype.toString.call(date) === '[object Date]'
}

export const getSmallestNumberOfList = (listOfNumbers=[]) => {
    return Math.min(...listOfNumbers)
}


export const removeWhiteSpaces = (string) => {
    return string.replace(/\s+/g, '')
}


export const incrementNumberFromString = (numberAsString, numberToIncrement) => {
    let number = parseInt(numberAsString)
    return number + numberToIncrement
}

export const decrementNumberFromString = (numberAsString, numberToDecrement) => {
    let number = parseInt(numberAsString)
    return number > numberToDecrement ? number-numberToDecrement : 0
}

export const addItemToList = (list, item) => {
    return [...list, item]
} 

/**
 * checks if array contains object with id
 * 
 * @param {array} list list of objects with id {string}
 * @param {object} obj  must have id {string}
 * @returns succes: true, fail: false
 */
export const contains = (list, obj) => {
    return list.some(listItem => obj.id === listItem.id)
}


/**
 * checks if array contains object with id
 * 
 * @param {array} list list of objects with id {string}
 * @param {string} id  of object 
 * @returns succes: true, fail: false
 */
export const containsId = (list, id) => {
    return list.some(listItem => id === listItem.id)
}


/**
 * 
 * @param {*} item 
 * @returns boolean true/false if its defined
 */
export const isDefined = (item) => {
    return item !== undefined
}



/**
 * Checks if given Object has Property
 * @param {object} object object to check if it has given property
 * @param {string} propertyName name of property as string
 * @returns 
 */
export const objectHasProperty = (
    object,
    propertyName
) => {
    return isDefined(object?.[propertyName])
}