import { connect } from 'react-redux'
import { updateDamage } from 'application/redux/actions/pages/damage_manager'
import { TaskDamageBasicDataFormComponent } from './component'



const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (damageId, damageData) => dispatch(
            updateDamage(damageId, damageData)
        )
    }
}

export const TaskDamageBasicDataForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageBasicDataFormComponent)