import { useCallback, useEffect, useState } from 'react'
import { zipCodes } from './zip_codes'

export const useZipCodeSearch = (debounce = 500) => {

    const [zipCodeSearchTerm, setZipCodeSearchTerm] = useState('')
    const [searchCityResult, setSearchCityResult] = useState('')

    const searchCity = useCallback(()=>{
        if(zipCodeSearchTerm.length === 5){
            const result = zipCodes.find(zipCode => zipCode.z === zipCodeSearchTerm)
            setSearchCityResult(result ? result.p : '')
        }
    }, [zipCodeSearchTerm])

    useEffect(()=>{
        const getData = setTimeout(() => {
            searchCity()
        }, debounce)
        return () => {
            clearTimeout(getData)
        }
    }, [debounce, searchCity, zipCodeSearchTerm])

    return {
        setZipCodeSearchTerm,
        searchCityResult
    }

}