import React, {useState, useEffect} from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { ContentWrapper, HeadlineWrapper } from './fragments'
import { Spacer } from 'application/components/pages/_common'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'

export const Collapsable = (
    {
        headline, 
        children, 
        initiallyCollapsed = false, 
        background = '', 
        additionalHeaderItem = React.Fragment,
        padding = '8px',
        cssOnly = true,
        onCollapseChange = () => {},
        checkBoxStyle = false
    }
) => {
    const [collapsed, setCollapsed] = useState(initiallyCollapsed)

    useEffect(()=>{
        setCollapsed(initiallyCollapsed)
    }, [initiallyCollapsed])

    useEffect(()=>{
        onCollapseChange(collapsed)
    },[collapsed, onCollapseChange])

    return <React.Fragment>
        <HeadlineWrapper 
            background={background} 
            onClick={()=>{setCollapsed(!collapsed)}} 
            collapsed={collapsed}
        >
            <span>{headline}</span>
            <Spacer />
            {
                additionalHeaderItem
            }
            <Spacer basis='12px' />
            {
                checkBoxStyle && <PureCheckBox 
                    checked={!collapsed}
                    onChange={()=>{
                        // setCollapsed(!collapsed)
                    }}
                />
            }
            {
                !checkBoxStyle && <React.Fragment>
                    {
                        collapsed 
                            ? <FontIcon 
                                icon='chevron_left' 
                                isClickable={true} /> 
                            : <FontIcon 
                                icon='expand_more' 
                                isClickable={true}
                            />
                    }
                </React.Fragment>
            }
        </HeadlineWrapper>
        {
            cssOnly && <ContentWrapper 
                background={background} 
                collapsed={collapsed}
                padding={padding}
            >
                {children}
            </ContentWrapper>
        }
       
        {
            !cssOnly && !collapsed && <ContentWrapper 
                background={background} 
                collapsed={false}
                padding={padding}
            >
                {children}
            </ContentWrapper>
        }
    </React.Fragment>
}