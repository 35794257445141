import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { AktenStatusName } from 'application/components/name_mappings/akten_status'
import { CASE_STATUS } from 'application/constants'

export const AktenStatusTypeSelect = ({
    name = 'status',
    register,
    setValue,
    initialValue,
    error,
    validate = null,
    menuPlacement = 'bottom',
    onChange = () => {}
}) => {
    const { t } = useTranslation()
    const aktenStatusTypeOptions = [
        {
            value: CASE_STATUS.NOT_SET,
            label: <AktenStatusName aktenStatusType={CASE_STATUS.NOT_SET} />
        },
        {
            value: CASE_STATUS.OPEN,
            label: <AktenStatusName aktenStatusType={CASE_STATUS.OPEN} />
        },
        {
            value: CASE_STATUS.CLOSED,
            label: <AktenStatusName aktenStatusType={CASE_STATUS.CLOSED} />
        },
    ]

    const initialOption = aktenStatusTypeOptions.find(o => o.value === initialValue)

    return <Select
        error={error}
        label={t('Akten status type')}
        name={name}
        register={register}
        setValue={setValue}
        options={aktenStatusTypeOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

AktenStatusTypeSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}