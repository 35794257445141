import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { syncVehicleKeeper } from 'application/redux/actions/pages/damage_manager/shared'

export function* damageSwitchCustomerSaga(action) {
    try {

        const {customerId} = action.payload

        yield resourceCall(
            api.gateway.damage.switchDamageCustomer, 
            action.metaData, 
            {
                customerId
            }
        )

        yield put(
            syncVehicleKeeper({
                ...action.payload.customer,
                customerId
            })
        )

        yield put(showToast('New customer assigned'))
    } catch (e) {
        yield handleError(e, action)
    }
}