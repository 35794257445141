import { useState } from 'react'
import { useForm } from 'react-hook-form'

//react hook form 7 compatibility layer
export const useStatefulForm = (options) => {
    const defaultValues = options?.defaultValues || {}
    const [formStateValues, setFormStateValues] = useState(defaultValues)
    const form = useForm(options)

    const updateFormState = () => {
        setFormStateValues(
            {
                ...formStateValues,
                ...form.getValues()
            }
        )
    }

    return {
        formStateValues,
        setFormStateValues,
        updateFormState,
        errors: form.formState.errors, //inject errors manually to be able to use them in the react-hook-form v6 way
        ...form
    }
}