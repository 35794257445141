import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'application/components/controls/hyperlink'
import * as Fragments from './fragments'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const RepairAuthorizationChangedMetaContent = (
    {
        notification,
        onLinkClick,
        inline
    }
) => {
    const { t } = useTranslation()
    const { payload } = notification

    const {
        internalAccountLink
    } = useApplicationLinks()

    const damageLink = internalAccountLink(
        `/damages/${payload.damage.id}`
    )

    const newStatus = payload.newRepairAuthorization ? payload.newRepairAuthorization : t('Not set')


    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription
            label='Repair Authorization'
            value={newStatus}
        />
        <Fragments.ActivityLink inline={inline}>
            <Link
                onClick={onLinkClick}
                to={damageLink}
            >
                {t('jump to damage')}
            </Link>
        </Fragments.ActivityLink>
    </Fragments.MetaContentContainer>
}