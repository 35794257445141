import React from 'react'
import { Headline3 } from 'application/components/fragments/typography'
import { StyledDescription } from './_fragments'
import { useTranslation } from 'react-i18next'
import { BulletList, BulletListItem } from 'application/components/widgets/_common'
import { PushBottom16 } from 'application/components/pages/_common'

export const RoleAdminDescription = () => {
    const {t} = useTranslation()

    return <StyledDescription>
        <Headline3>
            {t('Administrators have these special permissions')}
        </Headline3>
        <PushBottom16 />
        <BulletList>
            <BulletListItem>{t('Create, update and delete users')}</BulletListItem>
            <BulletListItem>{t('Invite users')}</BulletListItem>
            <BulletListItem>{t('Create, update and delete user groups')}</BulletListItem>
            <BulletListItem>{t('Assign users to groups')}</BulletListItem>
            <BulletListItem>{t('Create, update and delete branches')}</BulletListItem>
            <BulletListItem>{t('Assign user groups to branches')}</BulletListItem>
            <BulletListItem>{t('Assign user groups to permission sets')}</BulletListItem>
            <BulletListItem>{t('Edit company address data')}</BulletListItem>
        </BulletList>

        <Headline3 cssMargin='32px 0 6px'>
            {t('Administrators have these permissions for damage management')}
        </Headline3>
        <PushBottom16 />
        <BulletList>
            <BulletListItem>{t('Create, update and delete damage cases')}</BulletListItem>
            <BulletListItem>{t('Create, update, assign and delete tasks')}</BulletListItem>
            <BulletListItem>{t('Commission service providers')}</BulletListItem>
            <BulletListItem>{t('Access to controlling data')}</BulletListItem>
            <BulletListItem>{t('Create and delete invoices')}</BulletListItem>
            <BulletListItem>{t('Create and cancel payments')}</BulletListItem>
            <BulletListItem>{t('Create, update and delete customer data')}</BulletListItem>
        </BulletList>
    </StyledDescription>
}