import { connect } from 'react-redux'
import { ForSpecificBranchPermissionsComponent } from './component'

const mapStateToProps = (state) => {
    return {
        allBranchPermissions: state.auth.allPermissions
    }
}

export const ForSpecificBranchPermissions = connect(
    mapStateToProps
)(ForSpecificBranchPermissionsComponent)