import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'
import { buildListQuery } from 'application/api/helpers'

export const getInsurances = params => {

    const {
        offset, 
        limit, 
    } = params

    const queryString = buildListQuery(offset, limit)

    return {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `insuranceCompanies${queryString}`, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `insuranceCompanies${queryString}`, 
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        kickstart: () => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/insuranceCompanies${queryString}`,
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
}





// https://webgateway.esy360.dev.e-consult.eco/insurancecompanies?offset=0&limit=150