import { PERMISSION_SCOPE, USER_PERMISSONS, WIDGET_TYPES } from 'application/constants'

//the permissions in this mapper only decide if a widget is rendered in the first place
//sometimes additional permission checks happen in the widget itself, eg to check if the 'delete message' button should be displayed
const mapWidgetPermissions = (widgetType) => {
    switch (widgetType) {
    case WIDGET_TYPES.LAST_DAMAGES:
    case WIDGET_TYPES.LAST_REPAIR_AUTHORIZATIONS:
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_DAMAGES:
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_REPAIR_AUTHORIZATIONS:
    case WIDGET_TYPES.DAMAGE_AK_RECEIPT_CONFIRMATION:
    case WIDGET_TYPES.DAMAGE_AK_LIABILITY_REQUEST:
    case WIDGET_TYPES.DAMAGE_AK_AKTEN_STATUS:
    case WIDGET_TYPES.DAMAGE_AK_REPAIR_CLEARANCE:
    case WIDGET_TYPES.DAMAGE_SHARE:
    case WIDGET_TYPES.DAMAGE_VEHICLE:
    case WIDGET_TYPES.DAMAGE_VEHICLE_OWNER:
    case WIDGET_TYPES.DAMAGE_INSURANCES:
    case WIDGET_TYPES.DAMAGE_REPORT:
    case WIDGET_TYPES.DAMAGE_ACCIDENT_REPORT:
    case WIDGET_TYPES.DAMAGE_ACTIVITY_LOG:
    case WIDGET_TYPES.DAMAGE_CASE_NOTES:
        return {
            permissions: [
                USER_PERMISSONS.DAMAGE.READ
            ]
        }
    case WIDGET_TYPES.COMMISSION_LAWFIRM: 
        return {
            permissions: [
                USER_PERMISSONS.DAMAGE.READ,
                USER_PERMISSONS.DAMAGE.COMMISSION
            ]
        }
    case WIDGET_TYPES.LAST_TASKS:
        return {
            permissions: [
                USER_PERMISSONS.TASK.READ
            ]
        }
    case WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_DETAILS:
    case WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_LIST:
        return {
            permissions: [
                USER_PERMISSONS.MESSAGE.READ
            ]
        }
    case WIDGET_TYPES.DAMAGE_MANAGER_INVOICES_LIST:
    case WIDGET_TYPES.DAMAGE_AK_INVOICES:
    case WIDGET_TYPES.TASK_DAMAGE_CREATE_INVOICE:
    case WIDGET_TYPES.TASK_DAMAGE_INVOICES:
        return {
            permissions: [
                USER_PERMISSONS.INVOICE.READ
            ]
        }
    case WIDGET_TYPES.DAMAGE_MANAGER_CREATE_INVOICE:
        return {
            permissions: [
                USER_PERMISSONS.INVOICE.CREATE
            ]
        }
    case WIDGET_TYPES.DAMAGE_TASK_BULLET_LIST:
    case WIDGET_TYPES.TASK_COMMENTS:
        return {
            permissions: [
                USER_PERMISSONS.TASK.READ
            ]
        }
    case WIDGET_TYPES.TASK_DAMAGE_BASIC_DATA_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_DATA_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_UPDATE_CUSTOMER:
    case WIDGET_TYPES.TASK_DAMAGE_LIABILITY_INSURANCE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_LIABILITY_OTHER_VEHICLE_DRIVER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_COMPREHENSIVE_INSURANCE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_POLICE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_WITNESS_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_ACCIDENT_REPORT_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_DRIVER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_KEEPER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_VEHICLE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_PROPRIETOR_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_COMBINED_LIABILITY_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_SCHEDULE:
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_AUTHORIZATION_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_STARTED_AT:
        return {
            permissions: [
                USER_PERMISSONS.DAMAGE.UPDATE
            ]
        }
    case WIDGET_TYPES.TASK_DAMAGE_UPLOADER:
    case WIDGET_TYPES.TASK_UPLOADER:
        return {
            permissions: [
                USER_PERMISSONS.TASK.UPDATE,
                USER_PERMISSONS.DOCUMENT.CREATE
            ]
        }
    case WIDGET_TYPES.INBOX_MESSAGE_DETAILS:
    case WIDGET_TYPES.INBOX_MESSAGE_LIST:
    case WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_DETAILS:
    case WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST:
        return {
            permissions: [
                USER_PERMISSONS.MESSAGE.READ
            ]
        }
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_KASKO:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_GESAMT:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
    case WIDGET_TYPES.CONTROLLING_STATISTICS_DAMAGE_COUNT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT:
        return {
            permissions: [ USER_PERMISSONS.CONTROLLING.READ ]
        }
    case WIDGET_TYPES.UNKNOWN:
    default:
        return {
            permissions: [],
            allNeeded: false
        }
    }
}

const mapPermissionScope = (widgetType) => {

    switch (widgetType) {
    case WIDGET_TYPES.LAST_DAMAGES:
    case WIDGET_TYPES.LAST_REPAIR_AUTHORIZATIONS:
    case WIDGET_TYPES.LAST_TASKS: //not in use
    case WIDGET_TYPES.INBOX_MESSAGE_DETAILS:
    case WIDGET_TYPES.INBOX_MESSAGE_LIST:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_KASKO:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_GESAMT:
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
    case WIDGET_TYPES.CONTROLLING_STATISTICS_DAMAGE_COUNT:

        return PERMISSION_SCOPE.ALL_BRANCHES

    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_DAMAGES:
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_REPAIR_AUTHORIZATIONS:
    case WIDGET_TYPES.DAMAGE_AK_RECEIPT_CONFIRMATION:
    case WIDGET_TYPES.DAMAGE_AK_LIABILITY_REQUEST:
    case WIDGET_TYPES.DAMAGE_AK_AKTEN_STATUS:
    case WIDGET_TYPES.DAMAGE_AK_REPAIR_CLEARANCE:
    case WIDGET_TYPES.DAMAGE_SHARE:
    case WIDGET_TYPES.DAMAGE_VEHICLE:
    case WIDGET_TYPES.DAMAGE_VEHICLE_OWNER:
    case WIDGET_TYPES.DAMAGE_INSURANCES:
    case WIDGET_TYPES.DAMAGE_REPORT:
    case WIDGET_TYPES.DAMAGE_ACCIDENT_REPORT:
    case WIDGET_TYPES.COMMISSION_LAWFIRM: 
    case WIDGET_TYPES.DAMAGE_ACTIVITY_LOG:
    case WIDGET_TYPES.DAMAGE_CASE_NOTES:
    case WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_DETAILS:
    case WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_LIST:
    case WIDGET_TYPES.DAMAGE_MANAGER_INVOICES_LIST:
    case WIDGET_TYPES.DAMAGE_AK_INVOICES:
    case WIDGET_TYPES.TASK_DAMAGE_CREATE_INVOICE:
    case WIDGET_TYPES.TASK_DAMAGE_INVOICES:
    case WIDGET_TYPES.DAMAGE_MANAGER_CREATE_INVOICE:
    case WIDGET_TYPES.DAMAGE_TASK_BULLET_LIST:
    case WIDGET_TYPES.TASK_COMMENTS:
    case WIDGET_TYPES.TASK_DAMAGE_BASIC_DATA_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_DATA_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_UPDATE_CUSTOMER:
    case WIDGET_TYPES.TASK_DAMAGE_LIABILITY_INSURANCE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_LIABILITY_OTHER_VEHICLE_DRIVER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_COMPREHENSIVE_INSURANCE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_POLICE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_WITNESS_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_ACCIDENT_REPORT_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_DRIVER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_KEEPER_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_STARTED_AT:
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_VEHICLE_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_PROPRIETOR_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_COMBINED_LIABILITY_DATA:
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_AUTHORIZATION_FORM:
    case WIDGET_TYPES.TASK_DAMAGE_UPLOADER:
    case WIDGET_TYPES.TASK_UPLOADER:
    case WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_DETAILS:
    case WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT:
    
        return PERMISSION_SCOPE.CURRENT_BRANCH

    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_AKTEN_STATUS:
    case WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_DETAILS: 
    case WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_LIST: 
    case WIDGET_TYPES.WORKFLOW_PANEL_TASK_COMMENTS: 
    case WIDGET_TYPES.WORKFLOW_PANEL_TASK_UPLOADER:
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_LIABILITY_REQUEST:
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION:
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES:
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE:
        
        return PERMISSION_SCOPE.SHARED_RESOURCE

    default:
        return PERMISSION_SCOPE.NONE
    }


}

export const getWidgetPermissions = (widgetType) => {
    return {
        allNeeded: true,
        permissionScope: mapPermissionScope(widgetType),
        ...mapWidgetPermissions(widgetType),
    }
}
