import { connect } from 'react-redux'
import {DamageTableContextMenuComponent} from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'
import { downloadDamageDocuments } from 'application/redux/actions/attachments'

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteDamage: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_DAMAGE, 
                true, payload, 
                'Delete damage'
            )
        ),
        onDownloadDamageDocuments: (damageId) => dispatch(
            downloadDamageDocuments(
                damageId, 
                `damage-${damageId}.zip`
            )
        ),
    }
}

export const DamageTableContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageTableContextMenuComponent)