import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { InvoicesTypeSelectAll } from 'application/components/controls/form/invoice_type_select_all/component'
import { Label } from 'application/components/fragments/typography'
import { setQuery, useQuery } from 'application/components/hooks/use_query'
import * as filters from 'application/filters'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'



export const InvoiceTypeFilter = ({
    routeParameters,
    filterDefinition = filters.controlling.invoiceType,
    menuPlacement='bottom',
    onChange=()=>{},
    disabled = false
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const query = useQuery()

    const { FILTERS, OFFSET, LIMIT} = routeParameters
    const { INVOICE_TYPE } = FILTERS

    const defaultInvoiceType = query.getStringParam(INVOICE_TYPE, filterDefinition.defaultValue)

    const [currentInvoiceType, setCurrentInvoiceType] = useState(defaultInvoiceType)

    const handleOnChange = (option) => {
        setQuery(
            navigate,
            location, 
            location.pathname,
            [
                {
                    key: filterDefinition.filterKey,
                    value: option.value
                }
            ],
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
        setCurrentInvoiceType(option.value)
        onChange(option.value)
    }

    return <FilterFragment.FilterRow>
        <FilterFragment.FilterLabel className='filterLabel'>
            <Label cssMargin='0 16px 0 0'>
                {t(filterDefinition.label)}
            </Label>
        </FilterFragment.FilterLabel>
        <FilterFragment.FilterAction
            maxWidth='266px'
        >
            <InvoicesTypeSelectAll
                initialValue={currentInvoiceType}
                onChange={handleOnChange}
                disabled={disabled}
                placeholder='Select invoice type'
                menuPlacement={menuPlacement}
                label=''
                hideValidationBox={true}
            />
        </FilterFragment.FilterAction>
    </FilterFragment.FilterRow>
}