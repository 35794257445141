import { isDefined } from './data_helpers'

/**
 * 
 * @param {array} list list to remove item from. Need Object items with property id
 * @param {object} item to remove -  must have property id
 * @returns list without item to remove
 */
export const removeItemFromList = (list, item) => {
    return list.filter( x => x.id !== item.id)
}


/**
 * 
 * @param {array} list list to remove item from. Need Object items with property id
 * @param {string} id - object to remove by id
 * @returns list without item to remove
 */
export const removeItemFromListById = (list, id) => {
    return list.filter( x => x.id !== id)
}

/**
 * 
 * @param {array} list list to remove item from. Need Object items with identifier property name
 * @param {string} key name of key from list objects - this value is compared to value param
 * @param {string} value name of value of searched object key 
 * @returns list without item with key identifier value
 */
export const removeObjectFromListByKeyValue = (
    list, 
    key, 
    value
) => {
    return list.filter( x => x[key] !== value)
}

/**
 * 
 * @param {array} list list to search object by name. Need Object items with identifier name
 * @param {string} name name identifier of object to pick 
 * @returns success: found object, fail: null 
 */
export const getObjectByName = (list, name) => {
    return list.length > 0 
        ? list.find( listItem => listItem.name === name)
        : null
}


/**
 * selects first  object from list if possible
 * @param {array} list list to search object by ownPropKey. Need Object items with identifier name
 * @param {string} ownPropKey key identifier of object to pick 
 * @param {string} value  ownPropKey value must match this value to select object 
 * @returns success: first found object, fail: null 
 */
export const getFirstObjectByOwnPropKey = (
    list = [], 
    ownPropKey, 
    value
) => {
    if (list.length === 0 ) return null

    const result =   list.find( 
        listItem => listItem[ownPropKey] === value
    )
    return isDefined(result) ? result : null
}

/**
 * 
 * @param {array} list list to search object by id. Need Object items with identifier id
 * @param {string} id id identifier of object to pick 
 * @returns success: found object, fail: null 
 */
export const getObjectById = (
    list = [], 
    id
) => {
    if (list.length === 0 ) return null

    const result = list.find( object => object.id === id )

    return isDefined(result) ? result : null
}

/**
 * checks if list contains object with given id
 * @param {array} list - list of objects with id
 * @param {string} id - id identifier of object to search for in list
 * @returns success: true, fail: false
 */
export const contains = (
    list = [], 
    id
) => {
    const result = list.find( object => object.id === id )
    return isDefined(result)
}


/**
 * checks if list contains object with given id
 * @param {array} list - list of objects with id
 * @param {string} searchKey - identifier of objects property value to search for in list
 * @param {string} searchValue - value to equal with listitems value by searchKey
 * @returns success: true, fail: false
 */
export const containsByProperty = (
    list = [], 
    searchKey,
    searchValue
) => {
    const result = list.find( object => object[searchKey] === searchValue )
    return isDefined(result)
}



/**
 * @param {array} list to map
 * @param {string} selectedObjectsName of selected object - needet to identify the object in list 
 * @param {string} selectedPropertyKey key of property of selected object in list 
 * @param {string} selectedPropertyValue value to set of property of selected object in list 
 * @param {string} otherPropertysValue property value to set of others objects in list 
 * @returns list with selectedObjects property true and other objects propertys set to false
 */

export const setOnlySelectedObjectPropertysValueByName = (
    list, 
    selectedObjectsName,
    selectedPropertyKey, 
    selectedPropertyValue,
    otherPropertysValue
) => {
    return list.length > 0 
        ?  list.map(
            listItem => {
                return listItem.name === selectedObjectsName
                    ? {
                        ...listItem,
                        [selectedPropertyKey]: selectedPropertyValue
                    }
                    : {
                        ...listItem,
                        [selectedPropertyKey]: otherPropertysValue
                    }
            }
        )
        : []
}


/**
 * Groups elements by attributes
 * @param {array} selectedItems - array of objects with property to group
 * @param {string} attribute - name of object property to group of
 * @param {string} attribute2 - (optional) name of object propertys property to group of
 * @returns {array} array grouped objects. Every group is named by attribute and has an subarray "objects", with objects grouped by attribute
 */
export const groupObjectsByAttributes  = (
    objects, 
    attribute, 
    attribute2 = null
) => {

    const objectsGroupedByObjectProperty = objects.reduce(
        (
            objectsSoFar, 
            selectedObject
        ) => {

            const groupName = attribute2 !== null 
                ? selectedObject[attribute][attribute2]
                : selectedObject[attribute]

            if (!objectsSoFar[groupName]) objectsSoFar[groupName] = []
        
            objectsSoFar[groupName].push(selectedObject)    
            return objectsSoFar
        }, {}
    )

    const objectGroupKeys = Object.keys(objectsGroupedByObjectProperty)
    
    return objectGroupKeys.map(
        objectGroupKey => {
            return {
                [attribute]: objectGroupKey,
                objects: objectsGroupedByObjectProperty[objectGroupKey]
            }

        }
    )
}



/**
 * 
 * @param {array<object>} objectList 
 * @param {array<string>} ids 
 * @returns {array<object>} with objects which have the ids 
 */
export const filterObjectListByIds = (
    objectList = [],
    ids = []
) => {
    return objectList.filter(
        object => ids.indexOf(object.id) !== -1
    )
}