import { fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const putCasefile = (params) => (
    {
        authenticated: () => null,
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/damage/communications/lawfirm/casefile`,
                params.pin, 
                HTTP_METHOD.PUT,
                payload
            )
            return apiCall(request)
        }
    }
)