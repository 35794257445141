import { exists } from 'application/common/data_helpers'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export const editAccountDataMapper = (accountData) => {
    return {
        name: accountData.name
    }
}

export const editAccountAddressDataMapper = (address) => {
    if(
        exists(address?.coordinates?.latitude) 
        && exists(address?.coordinates?.longitude)
    ){
        return { ...address }
    }
    return {
        ...address,
        coordinates: null
    }
}


export const editAccountContactDataMapper = (contactData) => {
    return removePropertiesWithEmptyStrings(contactData)
}
