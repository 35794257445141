import { takeLatest, put } from 'redux-saga/effects'

import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateSelectedTaskWorkflowPanel, populateTasksWorkflowPanel, taskNotFound, populateSelectedTaskDocumentsWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ListFilter, documents } from 'application/filters'

function* workflowPanelTasksSaga(action) {
    try {
        const { tasks } = yield resourceCall(api.gateway.tasks.getTasksByDamageId)
        yield put(populateTasksWorkflowPanel(tasks))
    } catch (e) {
        if (e.response.status === 404) {
            yield put(taskNotFound())
        } else {
            yield handleError(e, action)
        }
    }
}

export function* workflowPanelTasksWatcher() {
    yield takeLatest(ACTIONS.WORKFLOW_PANEL_TASKS_REQUEST_TASKS, workflowPanelTasksSaga)
}

function* workflowPanelSelectedTaskSaga(action) {
    try {
        const taskResponse = yield resourceCall(
            api.gateway.tasks.getTaskById, 
            {
                taskId: action.taskId
            }
        )
        yield put(populateSelectedTaskWorkflowPanel(taskResponse))

        if(taskResponse.documents.total > 0){
            const documentsResponse = yield resourceCall(
                api.gateway.attachments.getAttachmentsByDamageId, 
                {
                    damageId: action.damageId,
                    filterQuery: new ListFilter(documents.parentTaskId).setValue(action.taskId).getQuery()
                }
            )

            yield put(
                populateSelectedTaskDocumentsWorkflowPanel(
                    documentsResponse.documents, 
                    documentsResponse.total
                )
            )
        }else{
            yield put(populateSelectedTaskDocumentsWorkflowPanel([], 0))
        }
    } catch (e) {
        if (e.response.status === 404) {
            yield put(taskNotFound())
        } else {
            yield handleError(e, action)
        }
    }
}

export function* workflowPanelSelectedTaskWatcher() {
    yield takeLatest(ACTIONS.WORKFLOW_PANEL_TASKS_REQUEST_SELECTED_TASK, workflowPanelSelectedTaskSaga)
}