export const handleApiFilestreamResponse = (response) => {
    return response.clone().text().then(text => {
        if (!response.ok) {
            return Promise.reject({
                response,
                data: null,
                responseData: {
                    isServerError: true,
                    url: response.url,
                    status: response.status,
                    statusText: response.statusText,
                    response: text
                }
            })
        }

        return response.blob()
    })
}