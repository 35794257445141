import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogGetCostCoverageData(action) {

    const { 
        dialogName, 
        metaData
    } = action
    
    const { 
        customerId,
        damageId
    } = metaData
    
    const customer = yield resourceCall(
        api.gateway.customer.getCustomerById, 
        { customerId }
    )

    const victimInsuranceData = yield resourceCall(
        api.gateway.damage.getComprehensiveDataByDamageId, 
        { damageId }
    )
    const opponentInsuranceData = yield resourceCall(
        api.gateway.damage.getLiabilityData, 
        { damageId }
    )

    const victimVehicle = yield resourceCall(
        api.gateway.damage.getVehicleByDamageId, 
        { damageId }
    )

    let expertFound = false
    
    try {
        yield resourceCall(
            api.gateway.expert.getExpert,
            { damageId }
        )
        expertFound = true
    } catch (error) { 
        expertFound = false
    }

    yield put(
        updateDialogDataByName(
            dialogName, 
            { 
                customer,
                victimInsuranceData,
                opponentInsuranceData,
                expertFound,
                victimVehicle
            }
        )
    )
}