import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/modules/dynamic-form/controls/select'

export const WhosAtFaultSelect = ({
    name='liabilityData.accidentReport.whoIsAtFault',
    register,
    setValue,
    initialValue = 'Unbekannt',
    menuPlacement='bottom',
    onChange = () => {},
    validate = null, //usually no validation needed, no empty value possible
    error = null
}) => {
    const {t} = useTranslation()

    const selectOptions = [
        {
            value: 'Unbekannt',
            label: t('Unknown')
        },
        {
            value: 'Fahrzeughalter',
            label: t('Vehicle keeper')
        },
        {
            value: 'Unfallgegner',
            label: t('Accident opponent')
        },
        {
            value: 'Beide',
            label: t('Both')
        },
    ]

    const defaultValue = selectOptions.find(o => o.value === initialValue)

    return <Select
        label={t('Who\'s at fault?')}
        name={name}
        register={register}
        setValue={setValue}
        options={selectOptions}
        defaultValue={defaultValue}
        validate={validate}
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        error={error}
    />
}

WhosAtFaultSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}