import { useMemo } from 'react'
import { exists } from 'application/common/data_helpers'

export const useCustomerDataPlausibility = (formStateValues) => {
    const customerDataIsPlausible = useMemo(
        ()=>{
            return exists(formStateValues.mobilePhoneNumber) 
            || exists( formStateValues.email)
        }, [formStateValues.mobilePhoneNumber, formStateValues.email]
    )

    return {
        customerDataIsPlausible
    }
}