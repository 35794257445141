import { connect } from 'react-redux'
import {AccountLogoFormComponent} from './component'

const mapStateToProps = state => ({
    accountId: state.auth?.currentAccount?.id || null
})

export const AccountLogoForm =  connect(
    mapStateToProps,
    {}
)(AccountLogoFormComponent)