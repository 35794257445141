import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FILTERS } from 'application/constants'

export const CoverageSelect = ({
    name = 'coverage',
    register,
    setValue,
    menuPlacement = 'bottom',
    onChange = () => {},
    defaultValue = FILTERS.VALUES.DAMAGES.COVERAGE.NONE
}) => {
    const { t } = useTranslation()

    const coverageOptions = [
        {
            value: FILTERS.VALUES.DAMAGES.COVERAGE.NONE,
            label: t('none')
        },
        {
            value: FILTERS.VALUES.DAMAGES.COVERAGE.PARTIAL,
            label: t('Partial')
        },
        {
            value: FILTERS.VALUES.DAMAGES.COVERAGE.FULL,
            label: t('Full')
        }
    ]

    const initialOption = coverageOptions.find( option => option.value === defaultValue)

    return <Select
        label={t('Partial Casco')}
        name={name}
        register={register}
        setValue={setValue}
        options={coverageOptions}
        defaultValue={initialOption}
        validate={{notEmpty: validationMethods.notEmpty}} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

CoverageSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
}