import React from 'react'
import { DialogFooter, DialogFooterRight } from 'application/components/fragments/dialog'

export const Footer = (
    { 
        children
    }
) => {

    return <DialogFooter>
        <DialogFooterRight>
            {children}
        </DialogFooterRight>
    </DialogFooter>
}