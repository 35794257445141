import { connect } from 'react-redux'
import {PageBranchHeadlineComponent} from './component'

const mapStateToProps = state => {
    return {
        currentBranch: state.auth.currentBranch,
        currentBranchLoaded: state.auth.currentBranchLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export const PageBranchHeadline =  connect(
    mapStateToProps,
    mapDispatchToProps
)(PageBranchHeadlineComponent)