import { ACTIONS } from 'application/constants'

 

export const getActivitiesRequest = (
    offset, 
    limit, 
    orderBy = 'name', 
    direction = 'desc', 
    filterQuery = []
) => ({
    type: ACTIONS.ACTIVITY_LOG_GET_ACTIVITIES_REQUEST,
    offset,
    limit,
    orderBy,
    direction,
    filterQuery
})


export const populateActivitiesList = (
    activitiesList, 
    activitiesListTotal
) => ({
    type: ACTIONS.ACTIVITY_LOG_POPULATE_ACTIVITIES_LIST,
    activitiesList,
    activitiesListTotal
})