import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateCategoryParentOrder = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`documentcategories/${params.categoryId}/categories/order`, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)