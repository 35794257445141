import React from 'react'
import { validationMethods } from 'application/common'
import { TwoColumns } from 'application/components/forms/_common'
import { TextInput, YesNoSelect } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { PushBottom16 } from 'application/components/pages/_common'

export const Step0 = ({
    formHook
}) => {
    const {t} = useTranslation()

    const {
        updateFormState,
        errors,
        register,
        setValue,
        formStateValues
    } = formHook

    return <React.Fragment>
        <TwoColumns
            columnLeft={
                <YesNoSelect
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.isCompany}
                    name='isCompany'
                    label={t('Is vehicle owned by company?')}
                    onChange={updateFormState}
                />
            }
        />

        {
            formStateValues.isCompany
                ? <TwoColumns
                    columnLeft={
                        <TextInput
                            label={t('Company name')}
                            name='companyName'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.companyName}
                            onChange={updateFormState}
                        />
                    }
                />

                : <TwoColumns
                    columnLeft={
                        <TextInput
                            label={t('Firstname')}
                            name='firstName'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.firstName}
                            onChange={updateFormState}
                        />
                    }
                    columnRight={
                        <TextInput
                            label={t('Lastname')}
                            name='lastName'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.lastName}
                            onChange={updateFormState}
                        />
                    }
                />
        }
        <PushBottom16 />
    </React.Fragment>
}