import React, { useState } from 'react'
import { PureSelect } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'

export const DamageStatusSelectComponent =  ({
    statusList,
    statusListLoaded,
    damage,
    damageLoaded,
    onChange = () => {}
}) => {
    const {t} = useTranslation()

    const stateOptions = statusList.map(s => ({
        value: s.id,
        label: s.name
    }))

    const [initialValue, setInitialValue] = useState(damage.state !== undefined ? {
        value: damage.state.id,
        label: damage.state.name
    } : stateOptions[0])

    if(!statusListLoaded || !damageLoaded){
        return <PureSelect 
            options={[]}
            value={null}
            isDisabled={true}
            placeholder={t('loading...')}
        />
    }

    const statusChange = (s) => {
        onChange(s)
        setInitialValue(stateOptions.find(option => option.value === s.value))
    }

    return <PureSelect
        options={stateOptions}
        value={initialValue}
        onChange={statusChange}
    />
}