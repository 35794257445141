import { ACTIONS } from 'application/constants'

export const registerWidget = (
    key, 
    widgetType, 
    context, 
    title, 
    payload, 
    hideHeadline = false,
    accountDependent = false,
    dependentWidgetKeys = [],
    dependsOnOtherWidget = false
) => ({
    type: ACTIONS.WIDGET_REGISTER_WIDGET,
    key,
    widgetType,
    context,
    title,
    payload,
    hideHeadline,
    accountDependent,
    dependentWidgetKeys,
    dependsOnOtherWidget
})

export const unregisterWidget = (key) => ({
    type: ACTIONS.WIDGET_UNREGISTER_WIDGET,
    key
})

export const requestWidgetData = (
    key, 
    widgetType, 
    payload, 
    match, 
    urlParams = [],
    dependentWidgetKeys = [],
    dependsOnOtherWidget = false
) => ({
    type: ACTIONS.WIDGET_REQUEST_DATA,
    key,
    widgetType,
    payload,
    match,
    urlParams,
    dependentWidgetKeys,
    dependsOnOtherWidget
})

export const refreshWidgetByKey = (key) => ({
    type: ACTIONS.WIDGET_REFRESH_WIDGET_BY_KEY,
    key
})

export const refreshWidgetsByType = (widgetType) => ({
    type: ACTIONS.WIDGET_REFRESH_WIDGETS_BY_TYPE,
    widgetType
})

export const refreshWidgetsByContext = (context) => ({
    type: ACTIONS.WIDGET_REFRESH_WIDGETS_BY_CONTEXT,
    context
})

export const updateWidget = (key, dependentWidgetKeys, payload) => ({
    type: ACTIONS.WIDGET_UPDATE_CONTENT_BY_KEY,
    key,
    dependentWidgetKeys,
    payload
})

export const clearWidget = (key) => ({
    type: ACTIONS.WIDGET_CLEAR_CONTENT_BY_KEY,
    key
})

export const updateWidgetLoadedState = (key, isLoaded, isLoading = false, loadingFailed = false) => ({
    type: ACTIONS.WIDGET_UPDATE_LOADED_STATE,
    key,
    isLoaded,
    isLoading,
    loadingFailed
})

export const unregisterAllWidgets = () => ({
    type: ACTIONS.WIDGET_UNREGISTER_ALL_WIDGETS
})