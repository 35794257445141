import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import * as Common from 'application/components/pages/_common'
import { SubPageLayout } from 'application/components/pages/_common'
import {TaskBar} from 'application/components/building_blocks/task_bar'
import {TaskDetails} from 'application/components/building_blocks/task_details'
import {DamageManagerContainer} from '../container'
import {TasksSidebar} from './sidebar'
import { useMatch, useParams } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import {useLocation} from 'react-router-dom'



export const DamageManagerTasks = ({
    tasksList, 
    tasksLoaded, 
    tasksLoading, 
    tasksListDamageId, 
    onAddTask, 
    onDeleteTasks, 
    onForwardTasks, 
    onDownloadTasks, 
    onResetSelectedTask, 
    damage,
    damageLoaded
}) => {
    const path = useLocation().pathname
    const matchTasksRoute = notNull(
        useMatch(ROUTES.DAMAGE_MANAGER_TASKS)
    )
    const matchPreselectedTaskRoute = notNull(
        useMatch(ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED )
    )
    const params = useParams()
    const hasTasks = tasksList.length > 0 

    // redirect to the first task in the list if no task is selected
    if(
        hasTasks
        && tasksLoaded 
        && matchTasksRoute
        && tasksListDamageId === params.damageId
    ){
        const redirect = `${path}/${tasksList[0].id}`
        return <Navigate to={redirect} />
    }

    return <DamageManagerContainer
        titleSegments={['Damage processing']}
        headline='Damage processing'
        sidebar={ <TasksSidebar /> }
        paddingRight='0'
        className='damageManagerContainer'
    >
        <SubPageLayout paddingLeft='0' className='subPageLayout'>
            <Common.Container className='commonContainer'>
                <Common.Sidebar 
                    cssWidth='300px' 
                    paddingLeft='0' 
                    paddingRight='0' 
                    paddingTop='0' 
                    paddingBottom='50px'
                >
                    <TaskBar 
                        tasksList={tasksList} 
                        tasksLoaded={tasksLoaded} 
                        tasksLoading={tasksLoading}
                        onAddTask={onAddTask}
                        onDeleteTasks={onDeleteTasks}
                        onForwardTasks={onForwardTasks}
                        onDownloadTasks={onDownloadTasks}
                        onTaskClick={onResetSelectedTask}
                        damage={damage}
                    />
                </Common.Sidebar>
                <Common.Main 
                    paddingLeft='0' 
                    paddingRight='0' 
                    className='taskScrollArea'
                >
                    {
                        matchPreselectedTaskRoute
                        && damageLoaded 
                        && damage.id === params.damageId 
                        && <TaskDetails /> 
                    }
                </Common.Main>
            </Common.Container>
        </SubPageLayout>
    </DamageManagerContainer>
}

DamageManagerTasks.propTypes = {
    tasksList: PropTypes.array.isRequired,
    tasksLoaded: PropTypes.bool.isRequired,
    onAddTask: PropTypes.func.isRequired,
    onDeleteTasks: PropTypes.func.isRequired,
    onForwardTasks: PropTypes.func.isRequired,
    onDownloadTasks: PropTypes.func.isRequired,
    onResetSelectedTask: PropTypes.func.isRequired,
}