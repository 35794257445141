import { Fullname, FullnameWrapper, Gravatar, GravatarCircle, GravatarInitials } from 'application/components/building_blocks/gravatar/fragments'
import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import PropTypes from 'prop-types'




const GravatarComponent = (
    {
        getInitials,
        getFullName,
        cssFlexBasis = 'auto',
        cssWidth = 'auto',
        cssHeight = 'auto',
        children
    }
) => {

    return  <FlexBox 
        flexDirection='column'
        justifyContent='center'
        height={cssHeight}
        flexBasis={cssFlexBasis}
        width={cssWidth}
        alignItems='center'
    >
        <Gravatar>
            <GravatarCircle>
                <GravatarInitials>
                    {
                        getInitials()
                    }
                </GravatarInitials>
            </GravatarCircle>
        </Gravatar>

        <FlexBox justifyContent='center'>
            <FullnameWrapper>
                <Fullname>
                    {
                        getFullName()
                    }
                </Fullname>
            </FullnameWrapper>
        </FlexBox>
        {children}
    </FlexBox>
}

GravatarComponent.propTypes = {
    getInitials: PropTypes.func.isRequired,
    getFullName: PropTypes.func.isRequired,
    cssWidth: PropTypes.string,
    cssHeight: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
}


export  {GravatarComponent}
