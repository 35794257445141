import { connect } from 'react-redux'
import { MoDamagesChangeStateC } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'



const mapStateToProps = (state, props) => {

    const dialog = state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name)

    const { 
        moName,
        selectedItems
    } = dialog.payload

    const statusList = state.common.statusList

    return {
        selectedDamages: selectedItems,
        dialog,
        moName,
        statusList,
        allPermissions: state.auth.allPermissions
    } 
}




const mapDispatchToProps = dispatch => {
    return {
        
        onSubmit: (
            dialogName, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        }
    }
}


export const MoDamagesChangeState = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoDamagesChangeStateC)
