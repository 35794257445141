import { REPAIR_AUTHORIZATION_TYPE } from 'application/constants'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'

const StyledDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: ${props => props.$bgColor};
`
const StyledDotRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
`

const StyledLabel = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.$labelColor};
    margin-left: 8px;
`

export const RepairAuthorizationMiniDisplay = ({
    showLabel = true,
    repairAuthorizationType
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const disabledColor = theme.color.gray20
    const grantedColor = theme.color.success
    const notSetColor = theme.color.warning
    const refusedColor = theme.color.error

    const label = repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.REFUSED
        ? 'Repair Authorization refused'
        : repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.GRANTED
            ? 'Repair Authorization granted'
            : 'Repair Authorization pending'

    const labelColor = repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.REFUSED
        ? refusedColor
        : repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.GRANTED
            ? grantedColor
            : notSetColor

    return <FlexBox 
        flexDirection='row'
    >
        <StyledDotRow>
            <StyledDot $bgColor={repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.REFUSED ? refusedColor : disabledColor} />
            <StyledDot $bgColor={repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.NOT_SET ? notSetColor : disabledColor} />
            <StyledDot $bgColor={repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.GRANTED ? grantedColor : disabledColor} />
        </StyledDotRow>
        {
            showLabel && <StyledLabel $labelColor={labelColor}>{t(label)}</StyledLabel>
        }
    </FlexBox>
}