import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { RightArrow } from 'application/components/fragments/typography'
import {ShortenedString} from 'application/components/controls/shortened_string'

export const DamageNoteChangedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const oldNote = activity.data?.oldNoteContent || ''
    const newNote = activity.data?.newNoteContent || ''

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Damagenote changed')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            <ShortenedString 
                text={oldNote} 
                shortenAt={25}
            /> 
            <RightArrow /> 
            <ShortenedString 
                text={newNote} 
                shortenAt={25}
            />
        </Fragments.ActivityParagraph>
    </React.Fragment>
}