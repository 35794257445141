import { put, select, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { populateAccountContractors, populateBranchContractors, syncBranchContractors } from 'application/redux/actions/contractors'
import { resourceCall } from '../../helper/resource_call_decorator'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { handleError } from '../../errors'
import { getFeatureFlags } from 'application/redux/selectors'
import { FEATURE_FLAGS } from 'application/constants/feature_flags'

function* requestAccountContractorsSaga(action) {
    const featureFlags = yield select(getFeatureFlags)
    const isContractorsFeatureActive = featureFlags.activeFeatures.some(f => f === FEATURE_FLAGS.CONTRACTORS_UI)
    
    try {

        if(isContractorsFeatureActive){

            const accountContractorsResponse = yield resourceCall(
                api.gateway.contractors.getAccountContractors
            )    

            yield put(populateAccountContractors(accountContractorsResponse.items))

        }
        
    } catch (e) {
        yield handleError(e, action)
    }
}

function* requestBranchContractorsSaga(action) {
    const featureFlags = yield select(getFeatureFlags)
    const isContractorsFeatureActive = featureFlags.activeFeatures.some(f => f === FEATURE_FLAGS.CONTRACTORS_UI)

    try {
        
        if(isContractorsFeatureActive){

            const branchContractorsResponse = yield resourceCall(
                api.gateway.contractors.getBranchContractorIds
            )    

            yield put(populateBranchContractors(branchContractorsResponse.items))

        }

    } catch (e) {
        yield handleError(e, action)
    }
}

function* saveBranchContractorsSaga(action) {
    const featureFlags = yield select(getFeatureFlags)
    const isContractorsFeatureActive = featureFlags.activeFeatures.some(f => f === FEATURE_FLAGS.CONTRACTORS_UI)

    try {

        if(isContractorsFeatureActive){
    
            yield resourceCall(
                api.gateway.contractors.saveBranchContractors,
                {
                    branchId: action.branchId
                },
                {
                    contractors: action.contractorsList
                }
            )

        }

        yield put(syncBranchContractors(action.branchId, action.contractorsList))
        yield put(showToast('Contractors selection saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* contractorsWatcher() {
    yield takeLeading(ACTIONS.CONTRACTORS_REQUEST_ACCOUNT_CONTRACTORS_LIST, requestAccountContractorsSaga)
    yield takeLeading(ACTIONS.CONTRACTORS_REQUEST_BRANCH_CONTRACTORS_LIST, requestBranchContractorsSaga)
    yield takeLeading(ACTIONS.CONTRACTORS_SAVE_BRANCH_CONTRACTORS, saveBranchContractorsSaga)
}
