import { useMemo } from 'react'
import { isBeforeOtherDate } from  'application/common/date_time_helpers'
import { notNull } from 'application/common/data_helpers'


export const useInspectionDatePlausibility = (
    inspectionDate = null, 
    incidentDate = null
) => {
    
    const inspectionDateIsBeforeIncidentDate =  useMemo(
        ()=>(
            notNull(inspectionDate) 
            && notNull(incidentDate)
            && isBeforeOtherDate( 
                inspectionDate,
                incidentDate
            )
        ),[incidentDate, inspectionDate]
    ) 
    
    return { inspectionDateIsBeforeIncidentDate }
   
}