import { connect } from 'react-redux'
import { CreateBranchComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddBranch: (dialogName, payload) => {
            dispatch(sendPayloadRequest(dialogName, payload))
        }
    }
}

export const CreateBranch = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBranchComponent)