import React from 'react'
import styled from 'styled-components'

const StyledTag = styled.div`
  height: 24px;
  padding: 0px 6px;
  background-color: ${props => props.theme.color.gray70};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.white};
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.medium18};
  border-radius: 2px;
`

export const Tag = ({ children }) => {
    return <StyledTag>
        {children}
    </StyledTag>
}