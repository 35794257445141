import { put, takeLatest } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateDamageResults } from 'application/redux/actions/damages_search'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'



function* getDamageResultsSaga(action) {
    try {        
        const response = yield resourceCall(
            api.gateway.damages.getDamages,
            action.params
        )    
        yield put(
            populateDamageResults(
                response.damages, 
                response.total
            )
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* getDamageResultsWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGES_SEARCH_GET_DAMAGES_BY_PARAMS, 
        getDamageResultsSaga
    )
}