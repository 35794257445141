import React from 'react'
import { ErrorPageBase } from 'application/components/pages/errors/_common'


const DamageNotFoundPage = (
    {
        linkURL = ''
    }
) => {
    return <ErrorPageBase
        linkURL={linkURL}
        mainLogoURL={linkURL}
        errorMessage = 'Damage could not be found'
    />
}

export {DamageNotFoundPage}