export const emptyCustomer = {
    isEntitledToTaxDeduction: false,
    firstName: '',
    lastName: '',
    address: {
        line1: '',
        postalCode: '',
        locality: ''
    },
    preferredVehicleInfo: {
        licensePlate: '',
        make: '',
        model: '',
        chassisNumber: ''
    },
    email: '',
    iban: '',
    externalReferenceId: '',
    mobilePhoneNumber: '',
    isCompany: false,
    annotation: '',
    legalProtectionInsurance: {
        certificateNumber: '',
        company: {
            name: '',
            id: ''
        }
    }
}