import React from 'react'
import { ActionButtonBar } from 'application/components/controls/action_button_bar'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'
import { MobileTimeLineStep } from 'application/components/building_blocks/mobile_time_line'
import { ActionButton } from 'application/components/controls/action_button'
import { useTranslation } from 'react-i18next'
import { PushBottom16, PushBottom32 } from 'application/components/pages/_common'
import { Paragraph } from 'application/components/fragments/typography'



export const Step1 = (
    {
        currentTimelineStepIndex,
        gotoPreviousStep,
        gotoNextStep,
        createDamageFormHook,
        files,
        setFiles,
    }
) => {
    const {t} = useTranslation()

    return <MobileTimeLineStep
        index={1}
        isActive={currentTimelineStepIndex === 1}
        label={t('Upload files')}
        buttons={
            <ActionButtonBar
                inline={true} 
                buttonsAlign='flex-end'>
                <ActionButton 
                    buttonText={t('Back')} 
                    onButtonClick={gotoPreviousStep} 
                    secondary={true} 
                />
                <ActionButton 
                    buttonText={t('Continue')} 
                    onButtonClick={
                        createDamageFormHook.handleSubmit(
                            gotoNextStep
                        )
                    } 
                />
            </ActionButtonBar>
        }
    >
        <Paragraph cssAlign='center'>
            {t('To speed up claims processing, you can also send photos of the damage to the dealership.')}
        </Paragraph>
        <PushBottom16 />
        <TaskUploader
            title='Fotos'
            description=''
            label='Foto machen'
            labelMore='Weitere Fotos hinzufügen'
            files={files}
            setFiles={setFiles}
            mobileView={true}
        />
        <PushBottom32/>
    </MobileTimeLineStep>
}