import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { loadDocumentsByCategory } from 'application/redux/actions/documents_by_categories'
import { isNull, notNull } from 'application/common/data_helpers'
import { useDispatch } from 'react-redux'
import { getDocumentsByCategory, getDocsByCatsResourceId } from 'application/redux/selectors'
import { useDocumentsResourceFromState } from './use_resource_from_state'
import { useDocumentsResourceFromUrl } from './use_resource_from_url'


export const useDocsByCategoriesLoadDocumentsByCategory = (
    categoryId = null,
    shouldLoad = true
) => {
    const {resourceId = null} = useDocumentsResourceFromState()
    const {resourceIdFromUrl} = useDocumentsResourceFromUrl()
    const dispatch = useDispatch()
    const category = useSelector(getDocumentsByCategory(categoryId))
    const documentsByCategoryExist = notNull(category)

    const documentsByCategoryLoaded = documentsByCategoryExist 
        ? category?.loaded
        : false

    const documentsByCategoryLoading = documentsByCategoryExist 
        ? category?.loading
        : false

    const registeredRessourceId = useSelector(getDocsByCatsResourceId)
    const currentResourceIdIsRegistered = resourceId === registeredRessourceId
    const urlResourceIdMatchesStoredResourceId = resourceIdFromUrl === resourceId
   

    const fetchDocumentsByCategory =  (
        resourceId,
        categoryId,
        offset = 0,
        limit = 10,
        orderBy = 'createdAt',
        addDocumentsToLoadedDocs = false
    ) => {
        dispatch(
            loadDocumentsByCategory(
                resourceId,
                categoryId,
                offset,
                limit ,
                orderBy,
                addDocumentsToLoadedDocs
            )
        )
    }

    useEffect(
        () =>{
            shouldLoad 
            && !documentsByCategoryLoaded 
            && !isNull(resourceId)
            && !isNull(categoryId)
            && urlResourceIdMatchesStoredResourceId
            && currentResourceIdIsRegistered
            &&  dispatch( 
                loadDocumentsByCategory(
                    resourceId,
                    categoryId
                )
            )
        },
        [
            categoryId, 
            dispatch,
            resourceId,
            urlResourceIdMatchesStoredResourceId,
            shouldLoad,
            currentResourceIdIsRegistered,
            resourceIdFromUrl,
            documentsByCategoryLoaded
        ]
    )

    return {
        total: category?.total || 0,
        documentsByCategory: category?.documents || null,
        parentId: resourceId ,
        documentsByCategoryLoading,
        documentsByCategoryLoaded,
        fetchDocumentsByCategory
    }

}