import React from 'react'
import styled, { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { FormElementWrapper } from 'application/components/controls/form/_common'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useRegister } from 'application/components/hooks/use_register'

export const StyledHiddenRadioButton = styled.input`
    display: none;

    /* CHECKED STYLES */
    :checked + label {
        background-color: ${props => props.theme.color.primaryBackground};
        border: 1px solid ${props => props.theme.color.primary};
    }
`

const StyledLabel = styled.label`
    cursor: pointer;
    background-color: ${props => props.theme.color.gray5};
    border: 1px solid ${props => props.theme.color.gray5};
    position: relative;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 8px;
`

export const IconRadioButtons = withTheme(({
    name = 'iconName',
    register,
    validate = null,
    onChange = () => {},
    idModificator = 'select',
    label = 'Please select an icon for this state',
    theme
}) => {
    const {t} = useTranslation()

    const options = [
        {
            label: 'Star',
            value: 'star'
        },
        {
            label: 'Document',
            value: 'description'
        },
        {
            label: 'Attachment',
            value: 'attach_file'
        },
        {
            label: 'Assignment',
            value: 'assignment'
        },
        {
            label: 'Assignment checked',
            value: 'assignment_turned_in'
        },
        {
            label: 'Edit',
            value: 'edit'
        },
        {
            label: 'Build',
            value: 'build'
        },
        {
            label: 'Euro symbol',
            value: 'euro_symbol'
        },
        {
            label: 'Key',
            value: 'vpn_key'
        },
        {
            label: 'List',
            value: 'list'
        },
        {
            label: 'Lock',
            value: 'lock'
        },
        {
            label: 'Today',
            value: 'today'
        },
        {
            label: 'Gavel',
            value: 'gavel'
        },
        {
            label: 'Add',
            value: 'add'
        },
        {
            label: 'Check',
            value: 'check'
        },
        {
            label: 'Clear',
            value: 'clear'
        }
    ]

    const handleChange = (e) => {
        onChange(e.target.value)
    }

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate, handleChange)

    return <FormElementWrapper>
        <label>{t(label)}</label>
        <FlexBox flexWrap='wrap'>
            {
                options.map((option, i) => {
                    const {
                        value = ''
                    } = option

                    const radioId = `icon-radio-${idModificator}-${value}`

                    return <React.Fragment key={radioId}>
                        <StyledHiddenRadioButton
                            type="radio"
                            {...registerProps}
                            onChange={onRegisterChange}
                            value={value}
                            id={radioId}
                        />
                        <StyledLabel htmlFor={radioId}>
                            <FontIcon 
                                icon={option.value} 
                                fontSize={theme.fontSize.medium} 
                                color={theme.color.gray70} 
                                isClickable={true}
                            />
                        </StyledLabel>

                    </React.Fragment>
                })
            }
        </FlexBox>
    </FormElementWrapper>
})