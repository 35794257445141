import { connect } from 'react-redux'
import {RealtimeNotificationsEffects} from './effects'

const mapStateToProps = (state) => {
    const {
        notifications,
        previousNotifications,
        previousNotificationsLoaded,
        previousNotificationsLoading
    } = state.realtimeNotifications

    return {
        notifications,
        previousNotifications,
        previousNotificationsLoaded,
        previousNotificationsLoading
    }
}


export const RealtimeNotifications =  connect(
    mapStateToProps
)(RealtimeNotificationsEffects)