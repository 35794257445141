import { ACTIONS } from 'application/constants'

export const populatePermissions = (permissions) => ({
    type: ACTIONS.AUTH_POPULATE_PERMISSIONS,
    permissions
})

export const populateCurrentBranchPermissions = (permissions, currentBranchId) => ({
    type: ACTIONS.AUTH_POPULATE_CURRENT_BRANCH_PERMISSIONS,
    permissions,
    currentBranchId
})


export const setNoPermissionsFound = () => ({
    type: ACTIONS.AUTH_NO_PERMISSIONS_FOUND
})

export const setPermissionsFound = () => ({
    type: ACTIONS.AUTH_PERMISSIONS_FOUND
})
