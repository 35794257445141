import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ANNOUNCEMENTS } from 'application/constants/announcements'
import { getAnnouncementsByType } from 'application/redux/actions/pages/changelog/announcements'
import { useUiSettings } from '../use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'


export const useAnnouncements = (
    type= ANNOUNCEMENTS.TYPE.FEATURE
) => {
    const {
        getSetting
    } = useUiSettings()

    const dispatch = useDispatch()
    const announcements = useSelector( 
        state => state.pages.changelog?.announcements || null
    )
    const announcementsLoaded = announcements.loaded
    const announcementsLoading = announcements.loading

    useEffect(
        ()=>{
            if(!announcementsLoaded && !announcementsLoading){
                dispatch(
                    getAnnouncementsByType(type)
                )
            }
        },
        [announcementsLoaded, announcementsLoading, dispatch, type]
    )

    const allAnnouncements = useMemo(()=>{
        return announcementsLoaded
            ? announcements.data.announcements
            : []
    }, [announcements, announcementsLoaded])

    const features = useMemo(()=>{
        return announcementsLoaded
            ? allAnnouncements.filter(a => a.type === ANNOUNCEMENTS.TYPE.FEATURE)
            : []
    }, [allAnnouncements, announcementsLoaded])

    const newestAnnouncement = useMemo(()=>{
        return announcementsLoaded && allAnnouncements.length > 0
            ? allAnnouncements.reduce(function(prev, current) {
                return (prev.iterator > current.iterator) ? prev : current
            })
            : null
    }, [allAnnouncements, announcementsLoaded])

    const showNewestAnnouncement = useMemo(()=>{
        if(newestAnnouncement === null ){
            return false
        }
        
        return parseInt(newestAnnouncement.iterator) > parseInt(getSetting(UI_SETTINGS.USER.LAST_SEEN_ANNOUNCEMENT))
    },[getSetting, newestAnnouncement])

    return {
        announcementsLoaded,
        announcementsLoading,
        allAnnouncements,
        features,
        showNewestAnnouncement,
        newestAnnouncement
    }
}