import React from 'react'

import { WhosAtFaultSelect as WAFS } from 'application/modules/dynamic-form/controls/whos_at_fault_select'

export const WhosAtFaultSelect = ({ description, formHook }) => {
    const {
        register,
        setValue,
    } = formHook

    return <div style={description._config?.css}>
        <WAFS
            label={description.label}
            name={description.name}
            register={register}
            setValue={setValue}
        />
    </div>
}