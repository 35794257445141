import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateComprehensiveDataClaimByDamageId = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/comprehensiveData/claim`, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)