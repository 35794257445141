import { connect } from 'react-redux'
import {CommissionLawfirmStepComponent} from './component'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        updateDialog: (name) => dispatch(
            updateDialogDataByName(
                name, 
                { 
                    usersList: [], 
                    usersListLoading: false, 
                }
            )
        )
    }
}


export const CommissionLawfirmStep = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionLawfirmStepComponent)