import React from 'react'
import PropTypes from 'prop-types'
import { ValidationErrorMessage} from 'application/components/controls/form/_common'
import { useRegister } from 'application/components/hooks/use_register'



const ValidatedHiddenField = (
    {
        name,
        register,
        error,
        validate,
        errorMessageOverride = ''
    }
) => {
    const {
        registerProps
    } = useRegister(register, name, validate)

    return <React.Fragment> 
        <input 
            type="hidden" 
            {...registerProps}
        />
        <ValidationErrorMessage
            hideValidationMessage={false} 
            error={error}
            errorMessageOverride={errorMessageOverride}
        />
    </React.Fragment>
}

ValidatedHiddenField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType(
        [
            PropTypes.string, 
            PropTypes.bool, 
            PropTypes.number
        ]
    ),
    register: PropTypes.func.isRequired
}

export { ValidatedHiddenField }