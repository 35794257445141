import styled from 'styled-components'

export const Wrapper = styled.div`
    height: auto;
    max-height: 100%;
`

export const StyledTitleOnReviewThumbNail = styled.span`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.standardLight};
    color: ${props => props.theme.color.gray70};
    max-width: 100%;
    overflow: hidden;
`

export const StyledDisplayThumbnailImage = styled.img`
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
`

export const BreakLine16 = styled.div`
    margin-bottom: 16px;
`

export const StyledHideDisplayOnReviewThumbNail = styled.a`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.gray70};
    cursor: pointer;
    text-decoration: underline;
`