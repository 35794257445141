import React, {useState, useEffect, useMemo, useCallback} from 'react'
import uniqid from 'uniqid'
import styled from 'styled-components'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/modules/dynamic-form/controls/_common'
import { useTranslation } from 'react-i18next'
import { PureSelect } from './pure_component'
import { useRegister } from 'application/modules/dynamic-form/hooks/use_register'

const StyledSelectInputWithLabel = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    height: ${props => props.cssHeight};

    &>div{
        height: ${props => props.cssHeight !== undefined ? props.cssHeight : 'auto'};
    }

    margin-bottom: ${props => props.cssMarginBottom !== undefined ? props.cssMarginBottom : '0'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite};

    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }
`

export const Select = ({
    label = '',
    name,
    register,
    options,
    error,
    setValue,
    validate,
    hideValidationMessage = false,
    hideValidationBox = false,
    defaultValue = null,
    isClearable = true,
    errorMessageOverride='',
    menuPlacement='auto',
    noOptionsMessage='no options',
    placeholder='start typing or select',
    isLoading=false,
    isSearchable=true,
    onChange=()=>{},
    onInputChange=()=>{},
    onBlur=()=>{},
    onFocus=()=>{},
    maxMenuHeight = 300,
    cssHeight= 'auto',
    disabled = false,
    indicatorIconKey='keyboard_arrow_down',
    additionalCSS = ''
}) => {
    const { t } = useTranslation()
    const htmlId = uniqid()
    const [selectedOption, setSelectedOption] = useState(defaultValue)
    const hasLabel = label !== ''
    const handleChange = (selectedOption) => {
        if( setValue !== undefined){
            setValue(
                name,
                selectedOption !== null
                    ? selectedOption.value
                    : null,
                {
                    shouldValidate: true
                }
            )
        }
        setSelectedOption(selectedOption)
        onChange(selectedOption)
    }

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate, onChange)

    const memoizedDefaultValue = useMemo(
        ()=> defaultValue !== null && defaultValue.value,
        [defaultValue]
    )

    const memoizedDefaultLabel = useMemo(
        ()=> defaultValue !== null && defaultValue.label,
        [defaultValue]
    )

    const defaultValueIsNull = defaultValue === null

    const handleSetValue = useCallback(
        (option)=>{
            if(option !== null){
                setValue(name, option.value)
                setSelectedOption(option)
            }else{
                setValue(name, null)
                setSelectedOption(null)
            }
        },
        [name, setValue]
    )

    useEffect(
        () => {
            handleSetValue(
                defaultValueIsNull
                    ? null
                    :  {
                        value: memoizedDefaultValue,
                        label: memoizedDefaultLabel
                    }
            )
        },
        [
            defaultValueIsNull,
            handleSetValue,
            memoizedDefaultLabel,
            memoizedDefaultValue
        ]
    )

    return <StyledSelectInputWithLabel
        cssHeight={cssHeight}
        className="reactSelectWrapper"
    >
        {
            hasLabel && <label
                className='label'
                htmlFor={htmlId}
            >
                {label}
                <RequiredFieldMarker
                    validate={validate}
                />
            </label>
        }
        <PureSelect
            className="reactSelect"
            isClearable={isClearable}
            value={selectedOption}
            options={options}
            onInputChange={onInputChange}
            onBlur={onBlur}
            onFocus={onFocus}
            menuPlacement={menuPlacement}
            noOptionsMessage={()=>{return t(noOptionsMessage)}}
            placeholder={t(placeholder)}
            isLoading={isLoading}
            isSearchable={isSearchable}
            maxMenuHeight={maxMenuHeight}
            cssHeight={cssHeight}
            isDisabled={disabled}
            {...registerProps}
            onChange={(e)=>{
                handleChange(e)
                //mock a pseudo event for react hook form
                // onRegisterChange({ target: {name, value: e.hasOwnProperty('value') ? e.value : false}})
                onRegisterChange({ target: { name, value: e?.value || null }})
            }}
            setValue={setValue}
            indicatorIconKey={indicatorIconKey}
            additionalCSS={additionalCSS}
        />
        {
            !hideValidationBox && <ValidationErrorMessage
                hideValidationMessage={hideValidationMessage}
                error={error}
                errorMessageOverride={errorMessageOverride}
            />
        }
    </StyledSelectInputWithLabel>
}
