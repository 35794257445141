import React from 'react'
import PropTypes from 'prop-types'
import { RightArrow } from 'application/components/fragments/typography'
import { isDefined } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { getActivityLinksByType } from 'application/components/controls/activity_links'




export const StateChangedActivity = (
    {activity}
) => {
    const {t} = useTranslation()

    const oldState = activity.data?.oldState
    const newState = activity.data?.newState

    if (
        !( isDefined(oldState) && isDefined(newState) ) 
    ) return null

    const ActivityLinks = getActivityLinksByType(activity.type)

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Taskstate changed')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t(oldState)} <RightArrow /> {t(newState)}          
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <ActivityLinks activity={activity}/>   
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

StateChangedActivity.propTypes = {
    activity: PropTypes.object.isRequired
}