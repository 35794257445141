import React, {useEffect} from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCategoriesRequest } from 'application/redux/actions/common'
import { USER_PERMISSONS } from 'application/constants'

const mapStateToProps = state => ({
    categoriesAccountId: state.common.categoriesAccountId,
    categoriesLoaded: state.common.categoriesLoaded,
    categoriesLoading: state.common.categoriesLoading,
    categories: state.common.categories,
    auth: state.auth,
    isSharedResource: state.sharedResource.loggedIn
})

const mapDispatchToProps = dispatch => ({ 
    onGetCategories: (accountId) => dispatch(getCategoriesRequest(accountId))
})

const withCategoriesLoaderComponent = Component => ({ ...props }) => {
    const {match, categoriesAccountId, auth, categoriesLoaded, categoriesLoading, onGetCategories, isSharedResource} = props
    const {accountId} = match.params
    const categoriesMatchAccount = accountId === categoriesAccountId

    //only load categories if permission to read documents is given
    const skip = !isSharedResource && auth.permissions.find(p => p === USER_PERMISSONS.DOCUMENT.READ) === undefined

    useEffect(() => { 
        if(
            auth.loggedIn //login process must be complete
            && (
                (!categoriesLoaded && !categoriesLoading) //fire effect if categories aren't loaded at all
                || (!categoriesMatchAccount && categoriesAccountId !== null) //fire effect if current categories don't match the current account
            )
            && !skip
        ){
            onGetCategories(accountId) 
        }
    }, [accountId, auth.loggedIn, categoriesAccountId, categoriesLoaded, categoriesLoading, categoriesMatchAccount, onGetCategories, skip])

    return <Component {...props} />
}

export const withCategoriesLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    compose(
        withRouter,
        withCategoriesLoaderComponent
    )
)