import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import {AccountLogoViewer} from './image_viewer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TaskUploader } from '../../../building_blocks/task_uploader'
import { ActionButton } from 'application/components/controls/action_button'
import { notNull } from 'application/common/data_helpers'
import { PushBottom24, Spacer } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { SubHeadline } from 'application/components/fragments/dialog'
import { useAccountLogoHandling } from 'application/components/hooks/account_logo/use_account_logo_handling'
import { useAccountLogoLoader } from 'application/components/hooks/account_logo/use_account_logo_loader'




const StyledFileViewer = styled.div`
    overflow: auto;
    height: 100%;
    width: 100%;
    text-align: center;
    & .pg-viewer-wrapper{
        overflow-y: auto;
    }
    & .pdf-canvas canvas{
        border: 1px solid #aaa;        
        margin-bottom: 20px;
        max-width: 100%;
    }
    & .pdf-viewer{
        text-align: center;
        max-height: calc(100% - 150px);
    }
`


export const AccountLogoFormComponent = ({
    accountId
}) => {

    const {
        accountLogoLoading,
        accountLogoFound = false,
        accountLogoNotFound = true,
        filePath = null,
    } = useAccountLogoLoader(
        accountId
    )

    const {
        onUploadAccountlogo,
        onRemoveAccountlogo
    } = useAccountLogoHandling()

    const { t } = useTranslation()
    const [files, setFiles] = useState([])
    const hasFile = files.length > 0
    const [uploaderVisible, setUploaderVisible]  = useState(accountLogoNotFound)


    //force to regognize and empty files
    useEffect(
        () => {setFiles([])},
        [setFiles, accountLogoFound]
    )
    //force to regognize and refresh bool
    useEffect(
        () => {setUploaderVisible(accountLogoNotFound)},
        [setUploaderVisible, accountLogoNotFound]
    )
    
    return <StyledFileViewer>
        <SubHeadline>{t('Accountlogo')}</SubHeadline>
        {
            accountLogoLoading
                ? 
                <ThemedPreloader preloaderText={t('Loading') + '...'} />
                : 
                <FlexBox            
                    flexDirection='column'
                >
                    <PushBottom24 />
                    {
                        uploaderVisible
                            ? <React.Fragment>
                                <InfoBox
                                    type={INFOBOX.TYPES.WARNING} 
                                    direction='row'
                                    cssMargin='0' 
                                    cssPadding='16px;'
                                    cssMinHeight='auto'
                                >
                                    <FontIcon icon='info_outline' />
                                    <Spacer basis='10px' />
                                    <FlexBox flexDirection='column'>
                                        <span>
                                            <strong>{t('Only one file could be selected')}</strong> 
                                        </span>
                                        <span>
                                            <strong>{t('Allowed formats')+':'}</strong> 
                                            {'png,jpe,jpeg,jpg,webp,gif,tiff,tif,bmp,svg'}
                                        </span>
                                    </FlexBox>
                                    <Spacer />
                                </InfoBox>
                                <PushBottom24 />

                                <TaskUploader 
                                    label='Upload accountlogo'
                                    iconKey='add'
                                    setFiles={setFiles}
                                    files={files}
                                    multiple={false}
                                    allowedFileExtensions={process.env.REACT_APP_SUPPORTED_ACCOUNT_IMAGE_EXTENSIONS}
                                />
                                <PushBottom24 />
                                <FlexBox
                                    justifyContent='flex-end'
                                    alignItems='center'
                                >
                                    {
                                        accountLogoFound && <ActionButton
                                            buttonText={t('Display current accountlogo')}
                                            onButtonClick={
                                                () => {setUploaderVisible(false)}
                                            }
                                            secondary={true}
                                        />
                                    }
                                    <ActionButton
                                        buttonText={t('Upload accountlogo')}
                                        onButtonClick={
                                            () => {
                                                onUploadAccountlogo( 
                                                    files[0] 
                                                )
                                            }
                                        }
                                        disabled={!hasFile}
                                    />
                                </FlexBox>
                            </React.Fragment>
                            : 
                            <FlexBox flexDirection='column'>
                                <FlexBox justifyContent='center'>
                                    {
                                        notNull(filePath) && <AccountLogoViewer filePath={filePath}/>
                                    }
                                </FlexBox>                     
                                <PushBottom24 />
                                <FlexBox
                                    justifyContent='flex-end'
                                    alignItems='center'
                                >
                                    <React.Fragment>
                                        <ActionButton
                                            buttonText={t('Remove accountlogo')}
                                            onButtonClick={onRemoveAccountlogo}
                                            secondary={true}
                                            disabled={accountLogoNotFound}
                                        />
                                        <Spacer basis='10px' />
                                    </React.Fragment>
                                    {/* } */}
                                    <ActionButton
                                        buttonText={t('Add another accountlogo')}
                                        onButtonClick={
                                            () => {
                                                setFiles([])
                                                setUploaderVisible(true)
                                            }
                                        }
                                    />
                                </FlexBox>
                            </FlexBox>
                    }
                </FlexBox>//!isLoading 
        } 
        <FlexBox height='48px'/>
    </StyledFileViewer> 
}