import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { deleteAttachment } from 'application/redux/actions/attachments'
import { removeDocumentFromDocumentCenterByCategory } from 'application/redux/actions/documents_by_categories'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'
import { moClearAllItemsByName } from 'application/redux/actions/mass_operations'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { hideAttachmentDetails as hideTasksAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'




export function* dialogMoDocumentsDeleteSaga(action) {
    const { selectedItems } = action.payload
    const { damageId, moName } = action.metaData  

    yield put(
        showWaitingScreen('Deleting documents')
    )

    yield put(hideTasksAttachmentDetails())

    for(const file of selectedItems){
        yield put(
            addNewSubMessage(file.fileName + ' ... ')
        )
        yield put(
            removeDocumentFromDocumentCenterByCategory(
                file.id,
                file.category.id
            ) 
        )
        yield resourceCall(
            api.gateway.attachments.deleteAttachment, 
            {
                damageId: damageId, 
                attachmentId: file.id
            }
        )
        yield put(
            deleteAttachment(file.id)
        )
        yield put(
            addToLastSubMessage('✓')
        )
    }

    yield put(
        moClearAllItemsByName(moName)
    )
    yield put( 
        showToast('Documents deleted') 
    )
    yield put( 
        hideWaitingScreen() 
    )
}