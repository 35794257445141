import { ROUTE_PARAMETERS, WIDGET_TYPES } from 'application/constants'

const KEYS = {
    CONTROLLING_GLOBAL_DAMAGES_BAR_CHART: 'WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART',
}

export const layoutGlobalControllingDamagesBarChart = {
    LARGE: [
        {i: KEYS.CONTROLLING_GLOBAL_DAMAGES_BAR_CHART, x: 0, y: 0, w: 6, h: 3}
    ]
}

export const widgetsGlobalControllingDamages = [
    {
        key: KEYS.CONTROLLING_GLOBAL_DAMAGES_BAR_CHART,
        type: WIDGET_TYPES.CONTROLLING_GLOBAL_DAMAGES_BAR_CHART,
        title: 'Damages',
        isSecondary: false,
        hideBorder: true,
        isBlank: true,
        hideHeadline: true,
        routeParameters: ROUTE_PARAMETERS.WIDGET_CONTROLLING_GLOBAL_DAMAGES_BAR_CHART.FILTERS,
        accountDependent: true
    }
]
