import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'



export const get = params => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder(
                'insuranceCompanies/legalprotection', 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => {},
        kickstart: () => {}
    }
}