import React, {useEffect} from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { exists, isDefined } from 'application/common/data_helpers'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { useParams } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const withMessagesComponent = Component => props =>  {
    const { t } = useTranslation()
    const isExternal = isDefined(props.match.params?.sharedResourceId)
    const params = useParams()
    const {branchLink, internalAccountLink, } = useApplicationLinks()

    const { 
        loadMessage, 
        detailsLoaded, 
        deletedMessages,
        redirectUrl
    } = props

    const branchId =  isExternal 
        ? null 
        : params?.branchId || null

    const accountId = isExternal 
        ? null 
        : params?.accountId || null

    const messageId = isExternal 
        ? params?.messageId || null
        : params?.messageId || null
    
    const parentRessourceId = isExternal 
        ? params.sharedResourceId
        : params.damageId

    useEffect(
        () => {
            parentRessourceId !== null
            && messageId !== null
            && loadMessage(parentRessourceId, messageId)
        }, [loadMessage, messageId, parentRessourceId]
    )

    const redirect = exists(branchId) 
        ? branchLink(
            redirectUrl, 
            branchId
        )
        : exists(accountId)
            ? internalAccountLink(redirectUrl)
            : isExternal
                ? `/external/workflow/${parentRessourceId}/message`
                : ''

    if (
        deletedMessages.includes(messageId)
    ) {
        return <Navigate 
            to={redirect} />
    } 
    
    if(exists(messageId) && !detailsLoaded){
        return <ThemedPreloader preloaderText={t('Loading')} />
    }

    return <Component {...props} />
}