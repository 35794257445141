import React from 'react'
import { Thumbnail } from 'application/components/building_blocks/attachments/thumbnails'
import { useMO } from 'application/components/hooks'
import { contains } from 'application/common/data_helpers'
import { withThumbnailLoader } from 'application/components/higher_order_components'
import PropTypes from 'prop-types'


const MoThumbnailComponent = (props) => {

    const {    
        onAttachmentClick,
        attachment,
        isActive = false,
        moName = null,
        ...rest
    } = props

    const {
        moAddSelectedItem = () => {},
        moRemoveSelectedItem = () => {},
        moSelectedItems,
        moIsActive
    } = useMO(moName)

    const isSelectedByMo =  moIsActive
        ? contains(
            moSelectedItems,
            attachment
        )
        : false

    const isHighlighted = isActive || isSelectedByMo
            
    const chooseAndFireMoActionBySelection = (isSelected) => {
        isSelected
            ? moRemoveSelectedItem(
                props.attachment.id
            )
            : moAddSelectedItem(
                props.attachment
            )
    }

    const handleOnClick = () => {
        moIsActive
            ?
            chooseAndFireMoActionBySelection(isSelectedByMo)
            :
            onAttachmentClick( attachment )
    }

    return <Thumbnail
        onAttachmentClick={handleOnClick}
        isActive={isHighlighted}
        attachment={attachment}
        {
            ...rest
        }
    />
}

MoThumbnailComponent.propTypes = {
    attachment: PropTypes.object.isRequired,
    onAttachmentClick: PropTypes.func.isRequired, 
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool,
    damageId: PropTypes.string,
    moName: PropTypes.string,
    categoryId: PropTypes.string
}

export const MoThumbnail =  withThumbnailLoader(MoThumbnailComponent)