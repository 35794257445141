import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './reducers'
import {rootSaga} from './saga'
import { loadUser } from 'redux-oidc'
import { defaultUserManager } from 'application/common/user_manager'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

const compose = (middleware) => {
    return typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(middleware)
        : middleware
}

export const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middlewares))
)

loadUser(store, defaultUserManager)

sagaMiddleware.run(rootSaga)