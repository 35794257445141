import React from 'react'
import { Navigate, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    ROUTES, SHARED_RESOURCE_TYPE, WIDGET_CONTEXTS,
} from 'application/constants'
import * as Common from 'application/components/pages/_common'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import {DocumentSidebar} from 'application/components/building_blocks/document_sidebar'
// import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import {WorkflowPanelTasks} from './tasks'
import {FormWorkflowPanel} from './form'
import {ActivityLogWorkflowPanel} from './activity_log'
import {DocumentsContent} from './documents'
import {WidgetArea} from 'application/components/building_blocks/widget_area'
import {LayoutWorkflowPanel} from './layout'
import { withSharedResourcePinValidation } from 'application/components/higher_order_components'
import {WorkflowPanelInbox} from './inbox'
import { HookRoute } from 'application/components/building_blocks/hook_route'
import { withRouter } from 'application/components/higher_order_components/with_router'
import { notNull } from 'application/common/data_helpers'
import { addWildCard } from 'application/common/route_helpers'



const Lawfirm = (
    { 
        match, 
        damageLoaded, 
        selectedTaskLoaded, 
        // documentCenterArchiveDownloading, 
        documentCenterAttachmentDetails, 
        tasksAttachmentDetails, 
        onDocumentCenterAttachmentDetailsClose, 
        onTaskAttachmentDetailsClose, 
    }
) => {
    const { t } = useTranslation()
    const {sharedResourceId} = match.params

    const matchesWFPBase = notNull(
        useMatch(ROUTES.EXT_WORKFLOW_PANEL)
    )
    const matchesForm = notNull(
        useMatch(ROUTES.EXT_WORKFLOW_PANEL_FORM)
    )
    const matchesTasks = notNull(
        useMatch(
            { path: addWildCard(ROUTES.EXT_WORKFLOW_PANEL_TASKS) }
        )
    )
    const matchesDocuments = notNull(
        useMatch(ROUTES.EXT_WORKFLOW_PANEL_DOCUMENTS)
    )
    const matchesMessages = notNull(
        useMatch(
            { path: addWildCard(ROUTES.EXT_WORKFLOW_PANEL_MESSAGE) }
        )
    )
    const matchesActivityLog = notNull(
        useMatch(ROUTES.EXT_WORKFLOW_PANEL_ACTIVITY_LOG)
    )

    if (matchesWFPBase) {
        return <Navigate to={ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/form'} />
    }

    return <LayoutWorkflowPanel>
        <Common.Container>
            <Common.Main paddingLeft='0' paddingRight='0'>
                <Common.VerticalContainer>
                    <TabsLinks
                        items={[
                            {
                                label: t('Form'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/form',
                                isActive: matchesForm
                            },
                            {
                                label: t('Tasks'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/tasks',
                                isActive: matchesTasks
                            },
                            {
                                label: t('Documents'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/documents',
                                isActive: matchesDocuments
                            },
                            {
                                label: t('Messages'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/message',
                                isActive: matchesMessages
                            },                            
                            {
                                label: t('Activity Log'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/activity-log',
                                isActive: matchesActivityLog
                            }
                        ]}
                    >
                        {/* chb:: needed? */}
                        {/* {
                            match.params.subRoute === 'documents' && <React.Fragment>
                                {
                                    documentCenterArchiveDownloading && <Preloader small={true} paddingTop='0' />
                                }
                            </React.Fragment>
                        } */}
                    </TabsLinks>

                    <HookRoute path={ROUTES.EXT_WORKFLOW_PANEL_FORM}>
                        <FormWorkflowPanel />
                    </HookRoute>
                    <HookRoute 
                        path={ROUTES.EXT_WORKFLOW_PANEL_TASKS} 
                        all={true}
                    >
                        <WorkflowPanelTasks />
                    </HookRoute>
                    <HookRoute path={ROUTES.EXT_WORKFLOW_PANEL_DOCUMENTS}>
                        <DocumentsContent />
                    </HookRoute>
                    <HookRoute 
                        path={ROUTES.EXT_WORKFLOW_PANEL_MESSAGE}
                        all={true}
                    >
                        <WorkflowPanelInbox />
                    </HookRoute>
                    <HookRoute path={ROUTES.EXT_WORKFLOW_PANEL_ACTIVITY_LOG}>
                        <ActivityLogWorkflowPanel />
                    </HookRoute>
                </Common.VerticalContainer>
            </Common.Main>
            {
                !matchesActivityLog 
                && <Common.Sidebar>
                    <HookRoute path={ROUTES.EXT_WORKFLOW_PANEL_TASKS_PRESELECTED}>
                        {
                            selectedTaskLoaded && <React.Fragment>
                                {
                                    tasksAttachmentDetails.visible 
                                        ? <DocumentSidebar
                                            attachmentDetails={tasksAttachmentDetails}
                                            onClose={onTaskAttachmentDetailsClose}
                                            match={match}
                                        />
                                        : 
                                        <WidgetArea
                                            widgetContext={WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR} 
                                        />
                                }
                            </React.Fragment> 
                        }
                    </HookRoute>
                    <HookRoute path={ROUTES.EXT_WORKFLOW_PANEL_FORM}>
                        {
                            damageLoaded && <WidgetArea successively={true} widgetContext={WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM_SIDEBAR} />
                        }
                    </HookRoute>
                    <HookRoute path={ROUTES.EXT_WORKFLOW_PANEL_DOCUMENTS}>
                        {
                            documentCenterAttachmentDetails.visible 
                            && <DocumentSidebar
                                attachmentDetails={documentCenterAttachmentDetails}
                                onClose={onDocumentCenterAttachmentDetailsClose}
                                match={match}
                            />
                        }
                    </HookRoute>
                </Common.Sidebar>}
        </Common.Container>
    </LayoutWorkflowPanel>


}

export const LawfirmComponent = withSharedResourcePinValidation(SHARED_RESOURCE_TYPE.LAWFIRM)
(withRouter(Lawfirm))