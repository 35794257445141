import React, {useEffect} from 'react'
import * as Fragments from '../../fragments'
import { ActionButton } from 'application/components/controls/action_button'
import { useTranslation } from 'react-i18next'
import { defaultUserManager } from 'application/common/user_manager'
import { IMAGES } from 'application/constants'
import {Image} from 'application/components/controls/image'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'


export const HeaderWelcomePage = () => {
    const { t } = useTranslation()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const returnUrl = urlParams.has('returnUrl') ?  urlParams.get('returnUrl') : ''

    useEffect(()=>{
        returnUrl !== '' && defaultUserManager.signinRedirect({state: {returnUrl}})
    }, [returnUrl])

    return returnUrl === ''
        ? <Fragments.Header>
            <Image data={IMAGES.LOGO_72} width='60' />
            <ActionButton
                buttonText={t('Login')}
                onButtonClick={() => defaultUserManager.signinRedirect()}
                strechted={false}
            />
        </Fragments.Header>
        : <PagePreloader />
}