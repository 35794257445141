import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateDamageAssignee = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/assignee`, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        },
        sharedResource: () => null,
        qr: (payload) => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/damages/${params.damageId}/assignee`, params.pin, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        }
    }
)