import { FormatCurrencyDE } from 'application/common/currency_helpers'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useRef } from 'react'
import { Bar, getElementAtEvent } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

export const VacantPositionsForBranchesComponent = ({chartData, totalBars, allBarsLoaded, theme}) => {

    const barSize = 20
    const branchSize = 30

    ChartJS.register(
        ChartDataLabels,
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    )
    const chartRef = useRef()

    const dataSetDefaults = {
    }

    const navigate = useNavigate()

    const {
        branchLink
    } = useApplicationLinks()

    const vacantData = chartData.map(dataSet => dataSet.data.unpaidTotal)
    const payedData = chartData.map(dataSet => dataSet.data.paidTotal)
    const totalData = chartData.map(dataSet => dataSet.data.grossTotal)

    const labels = chartData.map(dataSet => dataSet.branch.name)
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Offen',
                data: vacantData,
                borderColor: theme.color.error,
                backgroundColor: theme.color.error,
                ...dataSetDefaults

            },
            {
                label: 'Bezahlt',
                data: payedData,
                borderColor: theme.color.success,
                backgroundColor: theme.color.success,
                ...dataSetDefaults
            },
            {
                label: 'Gesamt',
                data: totalData,
                borderColor: theme.color.primary,
                backgroundColor: theme.color.primary,
                ...dataSetDefaults
            }
        ]
    }

    const getTooltipBeforeLabel = (context) => {
        if(context.datasetIndex === 2){
            return null
        }
        const i = context.dataIndex
        const total = totalData[i]
        return  `Gesamt: ${FormatCurrencyDE(total)} €`
    }

    const getTooltipLabel = (context) => {
        return  `${context.dataset.label}: ${FormatCurrencyDE(context.raw)} €`
    }

    const onClick = (event) => {
        const elements = getElementAtEvent(chartRef.current, event)
        if(elements.length){
            const dataSet = chartData[elements[0].index]
            navigate(branchLink( 
                ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES,
                dataSet.branch.id
            ))
        }
    }

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                // align: 'start',
                events: []
            },
            tooltip: {
                callbacks: {
                    beforeLabel: getTooltipBeforeLabel,
                    label: getTooltipLabel
                }
            },
            datalabels: {
                color: 'black',
                anchor: 'end',
                align: 'end',
                clamp: true,
                formatter: val => FormatCurrencyDE(val) + ' €',
                listeners: {
                    click: (context) => {
                        const dataSet = chartData[context.dataIndex]
                        navigate(branchLink( 
                            ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES,
                            dataSet.branch.id
                        ))
                    }
                }
            }
        },
        scales: {
            x: {
                beginAtZero: true, 
                stacked: false,
                ticks: {
                    callback: value => value + ' €'
                }
            },
            y: {
                beginAtZero: true,
                stacked: false
            }
        },
        barThickness: barSize,
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
        },
        animation: {
            duration: 300
        },
        layout: {
            padding: {
                left: 0,
                right: 120,
                top: 0,
                bottom: 0
            }
        }
    }

    const height = (totalBars * branchSize) + 14

    return <FlexBox flexDirection='column' cssRules='max-width: 1000px;position:relative;'>
        {
            !allBarsLoaded && <FlexBox cssRules='max-width: 1000px;position:absolute;top:0;height:400px;' alignItems='center' justifyContent='center'>
                <ThemedPreloader />
            </FlexBox>
        }
       
        <FlexBox cssRules={`min-height:400px;max-width: 1000px;position:absolute;top:0;opacity:${allBarsLoaded ? '1' : '0.3'};`}>
            <Bar 
                options={options} 
                data={data} 
                height={height}
                ref={chartRef}
                onClick={onClick}
            />
        </FlexBox>

    </FlexBox>
}

export const VacantPositionsForBranches = withTheme(VacantPositionsForBranchesComponent)