import {sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { KickstartStep3Component } from './component'

const mapStateToProps = state => ({
    currentBranch: state.auth.currentBranch
})

const mapDispatchToProps = dispatch => ({
    onSendCommission: (
        dialogName, 
        payload,
        metaData
    ) => {
        dispatch(
            sendPayloadRequest(
                dialogName, 
                payload, 
                metaData
            )
        )
    },
})

export const KickstartStep3 = connect(
    mapStateToProps,
    mapDispatchToProps
)(KickstartStep3Component)