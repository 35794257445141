import React   from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Footer } from 'application/components/dialogs/users/create/_common/footer'
import { PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import { StepsBar } from 'application/components/building_blocks/steps_bar'





export const DialogSelectWithUploaderDialogLayout = (
    {
        getStepsState,
        children,
        footerElements
    }
) => {
    return <React.Fragment>
        <FlexBox
            flexDirection='column'
            cssRules='
                padding: 0 16px;
                margin-bottom: 68px;
                overflow-y: auto;
                min-height: 480px;
            '
        >
            <PushBottom16 />
            <FlexBox
                cssRules='padding: 0 32px;'
            >
                <StepsBar
                    getStepsState={getStepsState}
                />
            </FlexBox>
            <PushBottom32/>
            {
                children
            }
        </FlexBox>
        <Footer>
            {footerElements}
        </Footer>
    </React.Fragment>
}