import styled from 'styled-components'

export const CustomNodeCard = styled.div`
    border: ${props => props.selected ? '3px' : '1px'} solid ${props => props.borderColor};
    padding: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 180px;
    flex: 0 0 180px;
    cursor: default;
    position: relative;
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
    background: ${props => props.selected ? props.theme.color.gray10 : props.theme.color.white};
    opacity: ${props => props.isDisabled ? '0.3' : '1'};

    &:hover>button{
        display: flex;
    }
`

export const CustomNodeUpperContainer = styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    padding: ${props => props.selected ? '6px' : '8px'} 0 ${props => props.selected ? '10px' : '8px'} 0;
    align-items: center;
    justify-content: center;
    width: inherit;
    margin: 0 ${props => props.selected ? '-3px' : '-1px'};
    flex: 0 0 100%;
    overflow: hidden;
`

export const CustomNodeLowerContainer = styled.div`
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: ${props => props.background};
    display: flex;
    padding: 4px 0;
    align-items: center;
    justify-content: center;
    width: inherit;
    margin: ${props => props.selected ? '-3px' : '-1px'};
`

export const Title = styled.span`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.standard};
    display: flex;
    flex: 0 0 100%;
    width: inherit;
    justify-content: center;
    align-items: center;
`

export const NodeType = styled.span`
    color: ${props => props.theme.color.white};
    font-size: ${props => props.theme.fontSize.small};
    display: flex;
    align-items: center;
`

export const RemoveNodeButton = styled.button`
    display: ${props => props.selected ? 'flex' : 'none'};
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid black;
    background: white;
    justify-content: center;
    text-align:center;
    align-items: end;
    cursor: pointer;
    padding: 0;
    right: ${props => props.selected ? '-9px' : '-7px'};
    top: ${props => props.selected ? '-9px' : '-7px'};
`