import { connect } from 'react-redux'

import  {TaskHighlightBarComponent} from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = (state, props) => {
    return {
        currentUser: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    openAssignUserDialog: (
        title, 
        branchId,
        damageId,
        task
    ) => dispatch(
        openDialogByName(
            DIALOG.NAME.TASK_ASSIGN_USER,
            false,
            { 
                branchId,
                damageId,
                task
            },
            title,
            mapDispatchToProps
        )
    ),
    onDeleteTask: (payload) => dispatch(
        openDialogByName(
            DIALOG.NAME.DELETE_TASK_FROM_DAMAGE_MANAGER, 
            true, 
            payload, 
            'Delete task'
        )
    ),
    onEditTask: (
        task, 
        damageId, 
        damage
    ) => dispatch(
        openDialogByName(
            DIALOG.NAME.EDIT_TASK, 
            true, 
            {
                task,
                damageId,
                damage
            }, 
            task.title
        )
    ),
})

export const TaskHighlightBar =  connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskHighlightBarComponent)