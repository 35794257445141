import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WIDGET_TYPES } from 'application/constants'
import {DamageManagerInboxComponent} from './component'
import { DamageManagerDamageExists } from 'application/components/pages/internal/damage_manager/common/damage_exists'

const DamageManagerInboxEffectsComponent = (props) => {
    const { onRefreshWidgetType, match, onResetMessageDetails } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetType(WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_LIST)
    }, [onRefreshWidgetType, damageId])

    useEffect(()=>{
        onResetMessageDetails()
    },[onResetMessageDetails])

    return <DamageManagerDamageExists>
        <DamageManagerInboxComponent {...props} />
    </DamageManagerDamageExists>
}

DamageManagerInboxEffectsComponent.propTypes = {
    onRefreshWidgetType: PropTypes.func.isRequired,
}

export const DamageManagerInboxEffects = withRouter(DamageManagerInboxEffectsComponent)