import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { TextInput } from 'application/components/controls/form'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { TwoColumns } from 'application/components/forms/_common'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { ProprietaryReasonSelect } from 'application/components/controls/form/proprietary_reason_select'
import { validationMethods } from 'application/common'
import { FILTERS } from 'application/constants'
import { useStatefulForm } from 'application/components/hooks'
import { exists } from 'application/common/data_helpers'

const DamageOpponentProprietorForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()

    const emptyValues = {
        isCompany: true,
        firstName: null,
        lastName: null,
        companyName: null,
        proprietaryReason: FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET,
        contractNumber: null
    }

    const defaultValues = {
        isCompany: !exists(formData.isCompany) || formData?.proprietaryReason ===  FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET ? true : formData.isCompany,
        firstName: formData?.firstName || null,
        lastName: formData?.lastName || null,
        companyName: formData?.companyName || null,
        proprietaryReason: formData?.proprietaryReason || FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET,
        contractNumber: formData?.contractNumber || null
    }
    
    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue,
        formStateValues,
        updateFormState,
        reset,
        setFormStateValues,
    } = useStatefulForm({ defaultValues })

    const resetForm = () => {
        reset(emptyValues)
        setFormStateValues(emptyValues)
    }

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })

        if(formStateValues.proprietaryReason ===  FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET){
            resetForm()
        }
    }

  
    
    return <FlexBox flexDirection='column' cssRules='min-height: 160px;' >
        <ProprietaryReasonSelect 
            register={register}
            setValue={setValue}
            initialValue={formStateValues.proprietaryReason}
            onChange={updateFormState}
        />
        
        {
            formStateValues.proprietaryReason !== FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET && <React.Fragment>
                <TwoColumns 
                    columnLeft={
                        <YesNoSelect 
                            register={register}
                            setValue={setValue}
                            initialValue={formStateValues.isCompany}
                            name='isCompany'
                            label={t('Proprietor is company')}
                            onChange={updateFormState}
                        />
                    }
                />
                {
                    formStateValues.isCompany ?
                        <TextInput 
                            label={t('Company name')}
                            name='companyName'
                            register={register}
                            validate={formStateValues.isCompany ? {notEmpty: validationMethods.notEmpty} : null}
                            error={errors.companyName}
                            onChange={updateFormState}
                        />
                        : <React.Fragment>
                            <TwoColumns
                                columnLeft={
                                    <TextInput 
                                        label={t('First name')}
                                        name='firstName'
                                        register={register}
                                        validate={formStateValues.isCompany ? null : {notEmpty: validationMethods.notEmpty}}
                                        error={errors.firstName}
                                        onChange={updateFormState}
                                    />
                                }

                                columnRight={
                                    <TextInput 
                                        label={t('Last name')}
                                        name='lastName'
                                        register={register}
                                        validate={formStateValues.isCompany ? null : {notEmpty: validationMethods.notEmpty}}
                                        error={errors.lastName}
                                        onChange={updateFormState}
                                    />
                                }
                            />
                        </React.Fragment>
                }


                <TextInput 
                    label={t('Contract number')}
                    name='contractNumber'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty} }
                    error={errors.contractNumber}
                    onChange={updateFormState}
                />
            
            </React.Fragment>
        }

        

        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save vehicle proprietor data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { DamageOpponentProprietorForm }