import { REALTIME_NOTIFICATION_TYPE } from 'application/constants'

export const useNotificationFilter = (defaultSettings, canCommissionDekra) => {

    const ignoreList = [
        REALTIME_NOTIFICATION_TYPE.ACKNOWLEDGEMENT_UPDATED //goes only to webakte
    ]

    const filteredNotifications = defaultSettings.notifications
        .filter(notification => !ignoreList.includes(notification.type))

    if(canCommissionDekra){
        return {
            filteredNotifications
        }
    }

    return {
        filteredNotifications: filteredNotifications
            .filter(notification => !notification.type.toLowerCase().includes('dekra'))
    }
}