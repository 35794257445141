import { STORAGE } from 'application/constants'

export const applicationToken = {
    store: (accountId, token) => {
        const oldStorageEntry = localStorage.getItem(STORAGE.APPLICATION_TOKEN)
        localStorage.removeItem(STORAGE.APPLICATION_TOKEN)

        const newStorageEntry = {
            tokens: oldStorageEntry === null
                ? [{
                    accountId,
                    token
                }]
                : [...(JSON.parse(oldStorageEntry)).tokens.filter(p => p.accountId !== accountId), {
                    accountId,
                    token
                }]
        } 
        
        localStorage.setItem(STORAGE.APPLICATION_TOKEN, JSON.stringify(newStorageEntry))
    },
    get: (accountId) => {
        const storageEntry = localStorage.getItem(STORAGE.APPLICATION_TOKEN)
        if(storageEntry === null){
            return ''
        }
        const parsedStorageEntryTokens = (JSON.parse(storageEntry)).tokens
        const tokenEntry = parsedStorageEntryTokens.find(p => p.accountId === accountId)

        if(tokenEntry === undefined){
            return ''
        }

        return tokenEntry.token
    },
    delete: (accountId) => {
        const oldStorageEntry = localStorage.getItem(STORAGE.APPLICATION_TOKEN)
        if(oldStorageEntry !== null){
            const newStorageEntry = {
                tokens: (JSON.parse(oldStorageEntry)).tokens.filter(p => p.accountId !== accountId)
            }

            localStorage.setItem(STORAGE.APPLICATION_TOKEN, JSON.stringify(newStorageEntry))   
        }
    },
    deleteStorage: () => {
        localStorage.removeItem(STORAGE.APPLICATION_TOKEN)
    }
}