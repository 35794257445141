import React from 'react'
import { nameHelpers } from 'application/common'
import { isNull } from 'application/common/data_helpers'

export const CustomerNameDamage = (
    { damage }
) => {
    const customer = damage?.vehicle?.keeper || null

    if(isNull(customer)){
        return null
    }

    const customerName = customer.isCompany
        ? customer.companyName
        : nameHelpers.getFullName(customer)

    return <React.Fragment>
        {customerName}
    </React.Fragment>
}
