import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { ParagraphSmall } from 'application/components/fragments/typography'
import {TableDamagesLastCreated} from 'application/components/tables/refactored/damages/table_damages_last_created'
import { WidgedTableHeadline } from 'application/components/widgets/_common/table-headline'
import { AllDamagesPageLink } from 'application/components/controls/all_damages_page_link'

export const TableDamagesLastCreatedComponent = (
    {
        payload, 
        loadingFailed,
        isLoaded,
        isLoading
    }
) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage> 
    }

    return <React.Fragment>
        <WidgedTableHeadline
            message={t('last damages')}
        />
        <TableDamagesLastCreated 
            damagesListLoading={isLoading}
            damagesListLoaded={isLoaded}
            damagesListTotal={payload.total}
            damagesList={ payload.damages}
        />
        <ParagraphSmall>
            <AllDamagesPageLink />
        </ParagraphSmall>
    </React.Fragment>
}

TableDamagesLastCreatedComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}