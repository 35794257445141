

export const BREAKPOINTS = {
    X_SMALL: 480,
    SMALL: 640,
    MEDIUM: 800,
    LARGE: 1024,
    X_LARGE: 1280,
    V_SMALL: 800,
    //custom
    MOBILE_S: 320,
    MOBILE_M: 375,
    MOBILE_L: 425,
    TABLET: 768,
    LAPTOP: 1024,
    LAPTOP_L: 1440,
    DESKTOP: 2560,
}