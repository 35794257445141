import React, { useState } from 'react'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { compose } from 'redux'
import { withCommissionLawfirmSteps } from './with_commission_lawfirm_steps'
import { DialogStepsLayout } from 'application/components/dialogs/_common/steps_layout'
import { CommissionLawfirmStep } from './step0'
import { CustomerDataStep } from './step1'
import { DocumentsStep } from './step2'



const CommissionLawfirm = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        dialog,
        //StepsHoc
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep
    }
) => {
    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }
    
    const [lawfirm, setLawfirm] = useState(dialog.payload.lawfirm)
    const [customer, setCustomer] = useState(dialog.payload.customer)
    const [files, setFiles] = useState([])

    return <DialogStepsLayout  getStepsState={getStepsState}>
        {
            getCurrentStep().id === 0 && <CommissionLawfirmStep
                abort={abort}
                setAbortBlocked={setAbortBlocked}
                setDataChanged ={setDataChanged }
                onDataChange={onDataChange}
                getStepsState={getStepsState} 
                goToNextStep={goToNextStep}
                dialog={dialog}
                customer={customer}
                lawfirm={lawfirm}
                setLawfirm={setLawfirm}
            />
        }
        {
            getCurrentStep().id === 1 && <CustomerDataStep
                onDataChange={onDataChange}
                goToPreviousStep={goToPreviousStep} 
                goToNextStep={goToNextStep}
                dialogName={dialog.name}
                lawfirm={lawfirm}
                changeMode={dialog.payload.changeMode}
                customer={customer}
                setCustomer={setCustomer}
            />
        }
        {
            getCurrentStep().id === 2 && <DocumentsStep
                onDataChange={onDataChange}
                goToPreviousStep={goToPreviousStep} 
                dialogName={dialog.name}
                lawfirm={lawfirm}
                changeMode={dialog.payload.changeMode}
                customer={customer}
                files={files}
                setFiles={setFiles}
                liabilityData={dialog.payload.liabilityData}
            />
        }
    </DialogStepsLayout>


}

export const CommissionLawfirmComponent = compose(
    withRouter,
    withDialogDataLoader,
    withCommissionLawfirmSteps(),
) (CommissionLawfirm)