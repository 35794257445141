import { connect } from 'react-redux'
import { usersGetUsersRequest } from 'application/redux/actions/pages/users'
import { SelectUserComponent } from './component'

const mapStateToProps = state => {
    const usersPage = state.pages.users

    return {
        usersListLoaded: usersPage.loaded,
        usersListLoading: usersPage.loading,
        usersList: usersPage.usersList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadUsersList: ( 
            offset, 
            limit, 
            orderBy, 
            direction,
            filterQuery
        ) => dispatch(
            usersGetUsersRequest(
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export const SelectUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectUserComponent)
