import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'
import { getFullName } from 'application/common/name_helpers'

export const UserDeletedDetails = (
    {
        activity
    }
) => {
    return <ActivityDetailWrapper>
        <ActivityDetail label='Name' value={getFullName(activity.data)} />
    </ActivityDetailWrapper>
}

UserDeletedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}