import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import {AssignPreferredBranchComponent} from './component'

const mapStateToProps = () => ({})


const mapDispatchToProps = dispatch => {
    return {
        onCreateUser: 
        (
            dialogName, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload,
                    metaData
                )
            )
        },
    }
}

export const AssignPreferredBranch = connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignPreferredBranchComponent)