import React, {useEffect, useState} from 'react'
import { PureSelect } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { nameHelpers } from 'application/common'
import styled from 'styled-components'
import { PushBottom16 } from 'application/components/pages/_common'




const StyledDialogExpertSelect = styled.div`
    display: flex;
    flex-direction: column;

    &>label {
        margin-bottom: 4px;
        font-size: 12px;
    }
`









export const DialogExpertSearchSelectComponent = (
    {
        //parent (dialog)
        dialogName,
        initialExperts = [],
        foundExperts = [],
        foundExpertsLoading = false,
        //container
        onSearchExpert,
        callBack = () => {},
        label=''
    }
) => {
    const {t} = useTranslation()

    const mapExpertsToOptions = (experts) => {
        return experts.map(
            (e) => ({
                value: e.id,
                label: `${nameHelpers.getFullName(e)} <${e.email}>`,
                firstName: e.firstName,
                lastName: e.lastName,
                email: e.email
            })
        ) 
    }

    const [expertSearchActive, setExpertSearchActive] = useState(false) //to prevent unwanted server calls
    const [expertSearchTriggered, setExpertSearchTriggered] = useState(false)
    const initialExpertsOptions = mapExpertsToOptions(initialExperts)
    const [expertOptions, setExpertOptions] = useState(initialExpertsOptions)
    const [defaultExpertOption, setDefaultExpertOption] = useState(null)

    useEffect(
        ()=>{
            foundExperts.length > 0 &&
            setExpertOptions(
                mapExpertsToOptions(foundExperts)
            )
        }, [foundExperts]
    )



    useEffect(()=>{
        foundExpertsLoading && setExpertSearchTriggered(true)
    }, [foundExpertsLoading])

    const handleSearchExpert = searchTerm => {
        if (expertSearchActive && searchTerm.length > 2) {
            onSearchExpert(
                dialogName, 
                searchTerm
            )
        }
    }

    const handleSelectExpert = expertOption => {
        setDefaultExpertOption(null)
        setExpertOptions(initialExpertsOptions)
        callBack(expertOption)
        setExpertSearchTriggered(false)
    }

    return <StyledDialogExpertSelect>
        <label 
            className='label' 
        >
            {label}
        </label>
        <PureSelect
            options={expertOptions}
            value={defaultExpertOption}
            onChange={handleSelectExpert}
            onBlur={() => { setExpertSearchActive(false) }}
            onFocus={() => { setExpertSearchActive(true) }}
            isLoading={foundExpertsLoading}
            noOptionsMessage={
                () => expertSearchTriggered 
                    ? t('No experts found') 
                    : t('Start typing to search')
            }
            placeholder={t('Search expert')}
            onInputChange={handleSearchExpert}
            isClearable={false}
        />
        <PushBottom16/>
    </StyledDialogExpertSelect>
}