import styled from 'styled-components'
export { AccountHeader } from './account_header'

export const PageContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    flex-direction: column;
    padding-top: 100px;
`

export const ResponsiveMobileContainer = styled.div`
    display: flex;
    width: 500px;
    padding: 20px;
    flex-direction: column;
    margin: 0 auto;
    overflow: auto;

    @media (max-width: 500px) {
        width: 100%;
    }
`

export const Bar = styled.div`  
    display: flex;
    flex: 100%;
    flex-direction: row;
    height: 48px;
    background: ${props => props.theme.color.gray10};
    &>div:last-child {
        margin-left: auto;
        justify-content: flex-end;
    }
`

export const Cell = styled.div`
    display: flex;   
    flex: ${props => props.cssWidth !== undefined ? '0 0 ' + props.cssWidth : '1 1 auto'};
    align-items: center;
    height: inherit;
    padding: 0 8px;
    ${ props => props.justifyContentCenter !== undefined 
        ? 'justify-content: center;'
        : ''
}
    padding-left:  ${ props => props.paddingLeft !== undefined 
        ?  props.paddingLeft
        : '8px' };

    &:first-child {
        padding-left: ${ props => props.paddingLeft !== undefined 
        ?  props.paddingLeft
        : '0' };
    }
`

export const AccountName = styled.span`
    font-size: 12px;
`