import { connect } from 'react-redux'
import { WIDGET_CONTEXTS } from 'application/constants'
import { TaskDetailsEffects } from './effects'
import { getSelectedTask, getUsersForTaskDebounced, hideAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { registerContext } from 'application/redux/actions/widget_contexts'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { assignTask } from 'application/redux/actions/pages/damage_manager/tasks'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { composeFilterQuery } from 'application/common/filter_helpers'

const mapStateToProps = (state, props) => {
    const selectedTaskLoaded = state.pages.damageManager.tasks.selectedTaskLoaded
    const contextLoaded = selectedTaskLoaded 
        ? state.widgetContexts.contexts.some(
            c => c.key === state.pages.damageManager.tasks.selectedTask.id
        )
        : false

    return {
        auth: state.auth,
        task: state.pages.damageManager.tasks.selectedTask,
        selectedTaskLoading: state.pages.damageManager.tasks.selectedTaskLoading,
        selectedTaskLoaded,
        attachments:[],// state.attachments.fullAttachments,
        contextLoaded,
        userList: state.pages.damageManager.tasks.selectedTaskUserList.users,
        userListLoading: state.pages.damageManager.tasks.selectedTaskUserListLoading,
        hasTaskList: state.pages.damageManager.tasks.tasksList.length > 0,
        damage: state.pages.damageManager.shared.damage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadTask: (taskId, damageId) => {
            dispatch(hideAttachmentDetails())
            dispatch(getSelectedTask(taskId, damageId))
            dispatch(refreshWidgetsByContext(taskId))
            dispatch(refreshWidgetsByContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR))
        },

        onAssignTask: (
            damageId, 
            taskId, 
            userId, 
            userFirstName, 
            userLastname
        ) => dispatch(
            assignTask(
                damageId, 
                taskId, 
                userId, 
                userFirstName, 
                userLastname
            )
        ),
        onSearchUser: (search, branchId) => {
            const filterQuery = composeFilterQuery([
                new ListFilter(filters.users.name).setValue(search),
                new ListFilter(filters.users.getUsersForSpecificBranch).setValue(branchId)
            ])
            dispatch(
                getUsersForTaskDebounced(
                    0, 
                    10, 
                    filterQuery
                )
            )
        },
        onRegisterWidgetContext: (widgetContext, widgetList) => dispatch(
            registerContext(widgetContext, widgetList)
        )
    }
}

export const TaskDetails =  connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDetailsEffects)