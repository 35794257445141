import React from 'react'
import { AccountBox } from 'application/components/building_blocks/account_box'
import { useTableLoader } from 'application/components/hooks'
import { configuration } from './configuration'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import { useParams } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'


export const AccountsTable = ({
    accounts,
    preferredAccountId,
    onSetAsPreferredAccount,
    onUnsetAsPreferredAccount,
    onLoadAccounts,
    updateConstraint = null,
    accountsTotal
}) => {

    useTableLoader(
        onLoadAccounts,
        useParams(),
        configuration,
        updateConstraint
    )

    const applicationLinksHook = useApplicationLinks()

    return <React.Fragment>
        {
            accounts.map(
                (account, i) => {
                    return <AccountBox
                        to={
                            configuration.href(
                                account,
                                applicationLinksHook
                            )
                        }
                        key={i}
                        account={account}
                        address={account.address}
                        isPreferred={account.id === preferredAccountId}
                        onSetAsPreferredAccount={onSetAsPreferredAccount}
                        onUnsetAsPreferredAccount={onUnsetAsPreferredAccount}
                    />
                }
            )
        }
        <br />
        {
            accounts.length > 0
            && <FlexBox justifyContent='center'>
                <RoutedPagination
                    totalSize={parseInt(accountsTotal)}
                    itemsPerPage={configuration.pagination.getItemsPerPage()}
                    parameterNames={configuration.pagination}
                />
            </FlexBox>
        }
    </React.Fragment>
}