import React from 'react'
import PropTypes from 'prop-types'
import { exists } from 'application/common/data_helpers'
import { TASK_PRIORITIES } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import { FontIcon } from '@ec/ui-controls-react'
import { PriorityLabel, PriorityIcon } from './fragments'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

export const mapPriorityDisplayValues = (priority, theme) => {
    switch (priority) {
    case TASK_PRIORITIES.LOW:
        return {
            icon: 'south',
            label: 'Low priority',
            color: theme.color.anthracite
        }
    case TASK_PRIORITIES.MEDIUM:
        return {
            icon: 'east',
            label: 'Medium priority',
            color: theme.color.warning
        }
    case TASK_PRIORITIES.HIGH:
        return {
            icon: 'north',
            label: 'High priority',
            color: theme.color.error
        }
    default : 
        return {
            icon: 'south',
            label: 'Low priority',
            color: theme.color.anthracite
        }
    }  
}

const TaskPriorityDisplayComponent = ({
    priority,
    showLabel = true,
    theme,
    iconWidth = null
}) => {
    const {t} = useTranslation()

    if(!exists(priority)){
        return null
    }

    const displayValues = mapPriorityDisplayValues(priority, theme)

    return <FlexBox
        flexDirection='row'
        width='auto'
        alignConten='center'
    >
        <PriorityIcon
            $width={iconWidth}
        >
            <FontIcon 
                title={t(displayValues.label)} 
                icon={displayValues.icon} 
                fontSize={theme.fontSize.standard} 
                color={displayValues.color} 
            />
        </PriorityIcon>
        {
            showLabel && <PriorityLabel className='jestTaskPriorityDisplayLabel' fontColor={displayValues.color}>{t(displayValues.label)}</PriorityLabel>
        }
    </FlexBox>
}

TaskPriorityDisplayComponent.propTypes = {
    priority: PropTypes.string
}

export const TaskPriorityDisplay = withTheme(TaskPriorityDisplayComponent)