import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getUserGroupsRequest } from 'application/redux/actions/common/groups'

const mapStateToProps = state => ({
    userGroupsLoaded: state.common.userGroupsLoaded,
    userGroupsLoading: state.common.userGroupsLoading,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({ 
    onGetGroups: () => dispatch(getUserGroupsRequest()),
})

const withGroupsLoaderComponent = Component => ({ ...props }) => {
    const {auth, userGroupsLoaded, userGroupsLoading, onGetGroups} = props

    useEffect(() => { 
        auth.loggedIn 
        && !userGroupsLoaded 
        && !userGroupsLoading 
        && onGetGroups() 
    }, [auth.loggedIn, onGetGroups, userGroupsLoaded, userGroupsLoading])

    return <Component {...props} />
}

export const withGroupsLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withGroupsLoaderComponent
)