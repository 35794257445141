import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, TextArea, DatePicker } from 'application/components/controls/form'
import { InvoicesTypeSelect } from 'application/components/controls/form/invoice_type_select'
import { CurrencyInput } from 'application/components/controls/form/currency_input'
import { INVOICE_TYPE } from 'application/constants'
import { TwoColumns } from 'application/components/forms/_common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { DialogSelectWithUploaderDialogLayout } from '../../_common/select_with_uploader_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { CURRENCY_INPUT } from 'application/constants/currency'






const CreateInvoiceStep0 = (
    {
        abort,
        onDataChange,
        dialog,
        setInvoicePayload,
        setInvoiceMetaData,
        goToNextStep,
        getStepsState
    }
) => {
    const { t } = useTranslation()
    const { incidentDate } = dialog.payload

    const defaultValues =  {
        summeBrutto: CURRENCY_INPUT.ZERO_VALUE_STRING.FLOAT,
        summeNetto: CURRENCY_INPUT.ZERO_VALUE_STRING.FLOAT,
        type: INVOICE_TYPE.UNKNOWN
    }

    const {
        register, 
        errors, 
        handleSubmit,
        getValues, 
        setValue, 
        control,
        formStateValues, 
        updateFormState,
        trigger
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => { 
        const parentId = dialog.payload.damageId 
            ? dialog.payload.damageId 
            : dialog.payload.sharedResourceId
        const successMsg = t('Invoice is created')
        const invoicePayload = {
            ...formStateValues, //include all values
            ...getValues()
        }



        const invoiceMetaData = { parentId, successMsg }
        setInvoicePayload(invoicePayload)
        setInvoiceMetaData(invoiceMetaData)
        goToNextStep()
    }


    const setForm = () => {
        onDataChange()
        updateFormState()
    }
    const onError = (errors, e) => console.log(errors, e)

    const today = new Date()


    return <DialogSelectWithUploaderDialogLayout
        getStepsState={getStepsState}
        footerElements={ 
            <React.Fragment>
                <IconButtonWithLabel 
                    iconKey='not_interested' 
                    label={t('Cancel')} 
                    onButtonClick={abort} 
                />
                <ActionButton
                    buttonText={t('Next')} 
                    onButtonClick={handleSubmit(onSubmitSuccess, onError)} 
                />
            </React.Fragment>
        }
    >
        <DialogMainContent>
            <FlexBox 
                flexDirection='column' 
                flexWrap='nowrap'
            > 
                <TwoColumns 
                    columnLeft={
                        <TextInput
                            label={t('Bill number')}
                            name='rechnungsnr'
                            register={register}
                            validate={null}
                            error={errors.rechnungsnr}
                            onChange={setForm}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Invoice date')}
                            name='issuedAt'
                            validate={
                                {
                                    notEmpty: validationMethods.notEmpty
                                }
                            }
                            error={errors.issuedAt}
                            onDateChange={setForm}
                            control={control}
                            disabledDays={{
                                before: new Date(incidentDate),
                                after: today
                            }}
                            register={register}
                            defaultValue={formStateValues.issuedAt}
                            setValue={setValue}
                            trigger={trigger}
                        />
                    }
                />

                <InvoicesTypeSelect
                    error={errors.type}
                    name='type'
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.type}
                    onChange={setForm}
                />

                <TwoColumns 
                    columnLeft={
                        <CurrencyInput 
                            name='summeBrutto'
                            register={register}
                            error={errors.summeBrutto}
                            label={t('Total (Gross) in €')}
                            couldBeZero={true}
                        />
                    }
                    columnRight={
                        <CurrencyInput
                            name='summeNetto'
                            register={register}
                            error={errors.summeNetto}
                            label={t('Total (Nett) in €')}
                            couldBeZero={true}
                        />
                    }
                />
                <TextArea 
                    label={t('Description')}
                    name='kommentar'
                    register={register}
                    validate={null}
                    error={errors.kommentar}
                    onChange={setForm}
                />
            </FlexBox>
        </DialogMainContent>
    </DialogSelectWithUploaderDialogLayout>

}

export {CreateInvoiceStep0}
