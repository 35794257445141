export const ELEMENT_TYPE = {
    EDGE_REMOVABLE: 'edgeremovable',
    NODE_CUSTOM: 'nodecustom'
}

export const NODE_TYPE = {
    ROLE: 'role',
    GROUP: 'group',
    BRANCH: 'branch'
}

export const CONNECTION_TYPE = {
    GROUP_TO_ROLE: 'GROUP_TO_ROLE',
    GROUP_TO_BRANCH: 'GROUP_TO_BRANCH'
}

export const NODE_POSITION = {
    X_START_OFFSET: 60, //distance from left boundary
    X_SPACING: 300, //horizontal distance between nodes
    Y_START_OFFSET: 20, //distance from top boundary
    Y_MIN_SPACING: 60,  //min vertical distance between nodes
    Y_MAX_SPACING: 100,  //max vertical distance between nodes
    Y_STEP_LEVEL: 30 //step offset between node types
}

export const FLOW_PROPS = {
    selectNodesOnDrag: false,
    nodesDraggable: false,
    minZoom: 0.5,
    maxZoom: 2,
    defaultZoom: 1, //not used if fitToView is called...
    preventScrolling: false,
    panOnScroll: true,
    panOnScrollMode: 'vertical',
    paneMoveable: false,
    multiSelectionKeyCode: null
}