import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PartialForms } from 'application/components/forms'
import { useStatefulForm } from 'application/components/hooks'

export const EditGroupComponent = (
    { 
        abort, 
        dialog, 
        setAbortBlocked, 
        setDataChanged, 
        onSaveGroup 
    }
) => {
    const {group} = dialog.payload

    const defaultValues = {
        name: group.name,
        description: group.description
    }

    const { register, errors, handleSubmit, getValues } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => {
        onSaveGroup(dialog.name, group.id, getValues())
    }
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <PartialForms.GroupDataPartialForm 
                    register={register}
                    errors={errors}
                    onChange={()=>{
                        setAbortBlocked(true)
                        setDataChanged(true)
                    }}
                />            
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save user group'
        />
    </React.Fragment>

}