import { takeLeading } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { refreshBranchManagementSaga } from '../../post_processors/refresh_branch_management_saga'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* updateGroupSaga(action) {
    try {
        yield resourceCall(
            api.gateway.groups.updateGroup,
            {
                groupId: action.groupId
            },
            action.payload
        )

        yield refreshBranchManagementSaga()
    } catch (e) {
        yield handleError(e)
    }
}

export function* updateGroupWatcher() {
    yield takeLeading(
        ACTIONS.BRANCH_MANAGEMENT_UPDATE_GROUP, 
        updateGroupSaga
    )
}