import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const waitingScreenReducer =  (state = defaultStore.waitingScreen, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.waitingScreen
    }
    case ACTIONS.WAITING_SCREEN_SHOW:
        return {
            ...state,
            active: true,
            message: action.message,
            inDialog: action.inDialog,
            subMessages: action.subMessages
        }
    case ACTIONS.WAITING_SCREEN_HIDE:
        return {
            ...state,
            active: false,
            inDialog: true,
            message: '',
            subMessages: []
        }
    case ACTIONS.WAITING_SCREEN_ADD_NEW_SUB_MESSAGE:
        return {
            ...state,
            subMessages: [...state.subMessages, action.message]
        }   
    case ACTIONS.WAITING_SCREEN_ADD_TO_LAST_SUB_MESSAGE:
        return {
            ...state,
            subMessages: state.subMessages.length === 0
                ? [action.message]
                : state.subMessages.map((m, i) => {
                    return i < state.subMessages.length - 1
                        ? state.subMessages[i]
                        : state.subMessages[i] + action.message
                })
        }      
    default:
        return state
    }
}
