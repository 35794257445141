import React from 'react'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { exists } from 'application/common/data_helpers'
import { TextNavLink } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const AllDamagesPageLink = ({
    orderBy = ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LAST_CHANGE
}) => {
    const { t } = useTranslation()
    const params = useParams()

    const linkToCurrentBranch = exists(params?.branchId)
    const linkText = linkToCurrentBranch
        ? 'show all damage cases in this branch'
        : 'show all damage cases'

    const urlParameters = `${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.KEY}=${orderBy}&${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.DIRECTION}=${ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING}`

    const {
        internalAccountLink,
        branchLink
    } = useApplicationLinks()

    const url = linkToCurrentBranch
        ? branchLink(
            `${ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES}?${urlParameters}`,
            params?.branchId
        )
        : internalAccountLink(
            `${ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES}?${urlParameters}`
        )

    return <TextNavLink to={url}>
        &raquo; {t(linkText)}
    </TextNavLink>
}