import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    &>*:nth-child(even){
        background: ${props => props.theme.color.gray5};
    }
`

export const TableHeader = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.color.gray15};
    padding: 4px 8px;
    height: 28px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    width: ${props => props.width ? props.width : '100%'};
    justify-content: space-between;
`

export const TableRow = styled.div`
    display: flex;
    align-items: center;
    padding:4px 8px;
    min-height: 20px;
    cursor: ${props => props.static ? 'initial' : 'pointer'};
    &:hover {
        background: ${props => props.theme.color.gray5};
    }

    background: ${props => props.checked  ? props.theme.color.gray10  : 'none' };
    justify-content: space-between;
    & > div{
        height: ${props => props.cssHeight ? props.cssHeight : 'auto'};
    }
`

export const TableRowLink = styled(NavLink)`
    display: flex;
    justify-content: ${props => props.justify !== undefined ? props.justify : 'flex-start'};
    width: ${props => props.width ? props.width : '100%'};
    align-items: center;
    padding:4px 8px;
    min-height: 32px; 
    height: auto;  
    text-decoration: none;
    color: ${props => props.theme.color.anthracite};
    cursor: pointer;
    &:link, &:visited {
        color: ${props => props.theme.color.anthracite};
    }
    &:hover {
        background: ${props => props.theme.color.gray10};
    }
    justify-content: space-between;
    box-sizing: border-box;
`

export const TableCell = styled.div`
    text-decoration: none !important;
    flex: 0 0 ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    min-width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    max-width: ${props => props.cssMaxWidth !== undefined ? props.cssMaxWidth : '100%'};
    max-width: 100%;
    padding-right: ${props => props.paddingRight !== undefined ? props.paddingRight : '16px'};
    font-size: ${props => props.isRow ? props.theme.fontSize.standard : props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    overflow: hidden;
    justify-content: ${props => props.justifyContent !== undefined ? props.justifyContent : 'unset'};
    display: flex;
    flex-direction: ${props => props.column ? 'column' : 'row'};
    align-items: ${props => props.column ? 'flex-start' : 'center'};
`

export const TableCellLink = styled(NavLink)`
    text-decoration: none !important;
    width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    min-width: ${props => props.cssWidth !== undefined ? props.cssWidth : 'auto'};
    max-width: ${props => props.cssMaxWidth !== undefined ? props.cssMaxWidth : '100%'};
    max-width: 100%;
    order: 1;
    padding-right: 16px;
    font-size: ${props => props.isRow ? props.theme.fontSize.standard : props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
    overflow: hidden;
`

export const ContextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 10%;
    min-height: 1px;
`