import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { 
    WidgetHeadline, 
    WidgetErrorMessage, 
    WidgetCardLayout, 
    WidgetCardLayoutRight,
    WidgetText
} from 'application/components/widgets/_common'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import styled, { withTheme } from 'styled-components'
import { exists } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'

const Par = styled.span`
    font-size: 14px;
    display: flex;
    margin: unset;
    align-items: center;
    white-space: break-word;
`

const DamageCaseNotes = (
    {
        widgetKey, 
        payload, 
        loadingFailed, 
        damage,
        onCreateCaseNotes, 
        damageLoaded, 
        onEditCaseNotes, 
        theme
    }
) => {
    const {t} = useTranslation()

    const hasCaseNotes = exists(damage.note?.content)
    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    if (damageLoaded) {
        return <WidgetCardLayout isBlank={true} backgroundColor='#fff7f7'>
            
            <WidgetCardLayoutRight>
                {
                    hasCaseNotes && <WidgetHeadline>
                        <FlexBox justifyContent='space-between'>
                            <IconButtonWithLabel
                                iconKey='chat'
                                label={t('Case notes')}
                                fontSize={theme.fontSize.medium}
                                iconSize={theme.fontSize.medium}
                            />
                            <IconButtonWithLabel 
                                iconKey='edit' 
                                onButtonClick={
                                    () => onEditCaseNotes(damage)
                                } 
                            />
                        </FlexBox>
                    </WidgetHeadline>
                }
                <WidgetText isBlank={true}>
                    {
                        hasCaseNotes 
                            ? <Par>
                                { damage.note.content}
                            </Par>
                            : <FlexBox justifyContent='center'>
                                <IconButtonWithLabel 
                                    onButtonClick={
                                        () => onCreateCaseNotes(damage)
                                    } 
                                    label={t('Store case notes')} 
                                    iconKey='add' 
                                />
                            </FlexBox>
                    }
                </WidgetText>
            </WidgetCardLayoutRight>
        </WidgetCardLayout>
    }

    return <React.Fragment />
    
}

DamageCaseNotes.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const DamageCaseNotesComponent = withTheme(DamageCaseNotes)