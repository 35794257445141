import React from 'react'

import { TextInput } from 'application/modules/dynamic-form/controls/text_input'
import { _get } from './util'
import { useTranslation } from 'react-i18next'

export const TextField = ({ description, formHook }) => {
    const {
        register,
        errors,
        updateFormState,
    } = formHook

    const { t } = useTranslation()

    return <div style={description._config?.css}>
        <TextInput
            label={t(description.label)}
            name={description.name}
            register={register}
            validate={description.validate}
            error={_get(errors, description.name)}
            onChange={updateFormState}
        />
    </div>
}
