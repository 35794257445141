import { put, call } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { kickstartCreateDamageSubSaga } from 'application/redux/saga/sagas/sub_sagas/kickstart_sub_sagas'

export function* dialogCreateDamageKickstartSaga(action) {

    yield put(
        showDialogStepsWaitingScreen(
            'Creating damage'
        )
    )

    yield call(
        kickstartCreateDamageSubSaga, 
        action
    )

    yield put(
        hideDialogStepsWaitingScreen()
    )
    yield put(showToast('Damage created'))
}