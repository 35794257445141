import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/modules/dynamic-form/controls/select'
import { VEHICLE_STATE } from 'application/modules/dynamic-form/constants/vehicle_state'

const mapVehicleStateName = (type) => {
    const names = new Map([
        [VEHICLE_STATE.NOT_READY_TO_DRIVE, 'Not ready to drive'],
        [VEHICLE_STATE.READY_TO_DRIVE_NOT_SAFE_FOR_TRAFFIC, 'Ready to drive, not safe for traffic'],
        [VEHICLE_STATE.READY_TO_DRIVE_SAFE_FOR_TRAFFIC, 'Ready to drive, safe for traffic'],
        [VEHICLE_STATE.NOT_SET, 'Not set'],
    ])

    return names.get(type)
}

export const VehicleStateSelect = ({
    name = 'vehicle.state',
    register,
    setValue,
    validate = null,
    initialValue = VEHICLE_STATE.NOT_SET,
    error = null,
    menuPlacement = 'bottom',
    onChange = () => {}
}) => {
    const { t } = useTranslation()

    const vehicleStateOptions = [
        {
            value: VEHICLE_STATE.NOT_SET,
            label: t(mapVehicleStateName(VEHICLE_STATE.NOT_SET))
        },
        {
            value: VEHICLE_STATE.NOT_READY_TO_DRIVE,
            label: t(mapVehicleStateName(VEHICLE_STATE.NOT_READY_TO_DRIVE))
        },
        {
            value: VEHICLE_STATE.READY_TO_DRIVE_NOT_SAFE_FOR_TRAFFIC,
            label: t(mapVehicleStateName(VEHICLE_STATE.READY_TO_DRIVE_NOT_SAFE_FOR_TRAFFIC))
        },
        {
            value: VEHICLE_STATE.READY_TO_DRIVE_SAFE_FOR_TRAFFIC,
            label: t(mapVehicleStateName(VEHICLE_STATE.READY_TO_DRIVE_SAFE_FOR_TRAFFIC))
        }
    ]

    const initialOption = vehicleStateOptions.find(o => o.value === initialValue) ?? vehicleStateOptions[0]

    return <Select
        label={t('Vehicle state')}
        error={error}
        name={name}
        register={register}
        setValue={setValue}
        options={vehicleStateOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        cssHeight={'38px'}
    />
}

VehicleStateSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}