import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {MessageListInternal} from 'application/components/building_blocks/message/list'
import { useLocation } from 'react-router-dom'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useParams } from 'react-router-dom'



export const MessageListWidgetComponent = (
    {
        widgetKey,
        payload, 
        loadingFailed,
        onPaginationChange 
    }
) => {
    const { t } = useTranslation()
    const location = useLocation()
    const {
        messageId
    } = useParams()

    const {
        internalAccountLink
    } = useApplicationLinks()

    if (loadingFailed) {
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    return <React.Fragment>
        <FlexBox>
            <MessageListInternal
                getMessageUrl={
                    (damageId, messageId) => {
                        return internalAccountLink(
                            ROUTES.ALL_BRANCHES_INBOX + '/' + damageId + '/' + messageId
                        ) + location.search
                    }
                }
                baseUrlParams={ROUTE_PARAMETERS.INBOX}
                messages={payload.messages}
                messagesTotal={payload.total}
                selectedMessageId={messageId}
                onPaginationChange={
                    (offset, limit) => { 
                        onPaginationChange(widgetKey, offset, limit)
                    }
                }
            />
        </FlexBox>
    </React.Fragment>
}

MessageListWidgetComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}