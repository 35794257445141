import { put, }from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetControllingVacantPositionsTotalSaga(action){

    const response = yield resourceCall(
        api.gateway.controlling.getVacantPositions
    )

    yield put(updateWidget(action.key, action.dependentWidgetKeys, {response}))
}