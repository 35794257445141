import React from 'react'
import styled from 'styled-components'
import { NotificationCard } from 'application/components/controls/notification_card'

const StyledRealtimeNotifications = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    width: 100%;
    overflow: auto;
    height: inherit;
`



export const RealtimeNotificationsComponent = (
    {
        notifications, 
        previousNotifications, 
        onLinkClick
    }
) => {
    return <StyledRealtimeNotifications>
        {
            notifications.map(
                (notification, i) => <NotificationCard 
                    key={i} 
                    notification={notification}
                    isNew={true}
                    onLinkClick={onLinkClick}
                />
            )
        }
        {
            previousNotifications.map(
                (notification, i) => <NotificationCard 
                    key={i} 
                    notification={notification}
                    onLinkClick={onLinkClick}
                />
            )
        }
    </StyledRealtimeNotifications>
}