import React from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import {DashboardContent} from './content'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { DashboardConfigurator } from 'application/components/controls/dashboard_configurator'
import { UI_SETTINGS } from 'application/constants/ui_settings'



const DashboardComponent = () => {
    const {t} = useTranslation()

    return <LayoutAllBranches
        titleSegments={['Overview', 'All branches']}
        headlineSpecific={t('Overview')}
        headlineChildren={<DashboardConfigurator settingKey={UI_SETTINGS.USER.GENERIC_DASHBOARD_CONFIG} />}
    >
        <DashboardContent />
    </LayoutAllBranches>
}

export const Dashboard =  withLoggedInUser(DashboardComponent)