import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { useComprehensiveDamageTypes } from 'application/components/hooks/use_comprehensive_damage_types'

export const ComprehensiveDamageTypeSelect = ({
    name='type',
    label='Damage type',
    register,
    setValue,
    initialValue = null,
    menuPlacement='bottom',
    onChange = () => {},
    validate=null,
    error=null
}) => {
    const {t} = useTranslation()

    const {
        comprehensiveDamageTypes,
        comprehensiveDamageTypesLoading,
    } = useComprehensiveDamageTypes()

    if(comprehensiveDamageTypesLoading){
        return <ThemedPreloader />
    }

    const comprehensiveDamageTypeOptions = comprehensiveDamageTypes.map((damageType) => ({
        value: damageType.id,
        label: damageType.name
    }))
    const initialOption = comprehensiveDamageTypeOptions.find(o => o.value === initialValue)

    return <Select 
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={comprehensiveDamageTypeOptions}
        defaultValue={initialOption}
        validate={validate}
        isClearable={true}
        menuPlacement={menuPlacement}
        onChange={onChange}
        error={error}
    />
}

ComprehensiveDamageTypeSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}