export const ACTIONS = {
    UPDATE_FORM_EDITOR_DESCRIPTION: 'UPDATE_FORM_EDITOR_DESCRIPTION',
    UPDATE_FORM_RENDERER_DESCRIPTION: 'UPDATE_FORM_RENDERER_DESCRIPTION',
    SUBMIT_DYNAMIC_FORM_EDITOR_DESCRIPTION: 'SUBMIT_DYNAMIC_FORM_EDITOR_DESCRIPTION',
    SUBMIT_DYNAMIC_FORM_DATA: 'SUBMIT_DYNAMIC_FORM_DATA',
}

export const DATE_INPUT_TYPES = {
    DATE: 'date',
    TIME: 'time',
    DATE_TIME: 'datetime-local'
}