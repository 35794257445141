import React from 'react'
import { FlexBox } from 'application/modules/dynamic-form/controls/fragments/flex_box'
import { SubHeadline } from 'application/modules/dynamic-form/controls/fragments/dialog'
import { FlexColumns } from './util'
import { useTranslation } from 'react-i18next'

export const Group = ({ description, formHook, buildElement, showSubmitButton }) => {
    const { t } = useTranslation()
    return <FlexBox flexDirection='column'>
        {description.label && <SubHeadline>{t(description.label)}</SubHeadline>}
        <FlexColumns columns={description.descriptions.length > 1 ? 2 : 1}>
            {description.descriptions.map((groupItem) => buildElement(groupItem, formHook, showSubmitButton))}
        </FlexColumns>
    </FlexBox>
}