import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { DialogMainContent } from 'application/components/fragments/dialog' 
import { Paragraph } from 'application/components/fragments/typography'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Spacer, PushBottom32 } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const DeleteDocumentCategory = 
(
    { 
        abort, 
        dialog, 
        onDeleteCategory
    }
) => {
    const { categoryName, categoryId } = dialog.payload
    const {t} = useTranslation()

    return <React.Fragment>
        <DialogMainContent>
            <Paragraph cssPadding='16px 0'>
                <Trans i18nKey='Are you sure you want to delete this category?'>
                    Do you want to delete <strong>{{categoryName}}</strong> ?
                </Trans>
            </Paragraph>
            <InfoBox 
                type={INFOBOX.TYPES.WARNING} 
                direction='row' 
                cssMargin='0' 
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <span>
                    {t('This process is not reversible.')}
                    <br />
                    <br />
                    <Trans i18nKey='If you delete this category, all associated documents will be moved to the default category.'>
                                If you delete this category, all associated documents <strong>will be moved to the default category</strong>.
                    </Trans>
                    <br />
                    <br />
                    {t('Also, documents that will be uploaded to that category in the future, like through a QR code already in circulation, will also be moved to the default category.')}

                </span>
            </InfoBox>
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={() => onDeleteCategory(dialog.name, categoryId, categoryName)}
            submitText='Delete category'
        />
    </React.Fragment>

}

export const DeleteDocumentCategoryComponent = withDialogDataLoader(DeleteDocumentCategory) 