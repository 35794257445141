import { matchRoute } from 'application/common/route_helpers'
import { HookRoute } from 'application/components/building_blocks/hook_route'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import { PERMISSION_SCOPE } from 'application/constants/permissions'
import { useTranslation } from 'react-i18next'
import { Navigate, useMatch } from 'react-router-dom'
import { ControllingDamages } from './damages'
import { ControllingInvoices } from './invoices'
import { ControllingStatistics } from './statistics'

const ControllingComponent = () => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()


    const controllingBase = matchRoute(
        ROUTES.CONTROLLING,
        useMatch
    )

    const controllingInvoices = matchRoute(
        ROUTES.CONTROLLING_INVOICES,
        useMatch
    )

    const controllingStatistics = matchRoute(
        ROUTES.CONTROLLING_STATISTICS,
        useMatch
    )

    const controllingDamages = matchRoute(
        ROUTES.CONTROLLING_DAMAGES,
        useMatch
    )

    if (controllingBase) {
        return <Navigate 
            to={
                internalAccountLink(
                    ROUTES.CONTROLLING_INVOICES
                )
            } 
        />
    }

    return <LayoutAllBranches
        titleSegments={['Controlling', 'All branches']}
        headlineSpecific={t('Controlling')}
    >


        <TabsLinks
            items={[
                {
                    label: t('Invoices'),
                    link: internalAccountLink(ROUTES.CONTROLLING_INVOICES),
                    isActive: controllingInvoices,
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ ],
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    allNeeded: true
                },
                {
                    label: t('Vacant positions in branches'),
                    link: internalAccountLink(ROUTES.CONTROLLING_STATISTICS),
                    isActive: controllingStatistics,
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ ],
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    allNeeded: true
                },
                {
                    label: t('Damages in branches'),
                    link: internalAccountLink(ROUTES.CONTROLLING_DAMAGES),
                    isActive: controllingDamages,
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ ],
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    allNeeded: true
                }
            ]}
        />
        <HookRoute path={ROUTES.CONTROLLING_INVOICES}>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.CONTROLLING.READ
                ]}
            >
                <ControllingInvoices />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.CONTROLLING_STATISTICS}>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.CONTROLLING.READ
                ]}
            >
                <ControllingStatistics />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.CONTROLLING_DAMAGES}>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.CONTROLLING.READ
                ]}
            >
                <ControllingDamages />
            </ForAnyBranchPermissions>
        </HookRoute>
    </LayoutAllBranches>
}

export const Controlling =  withLoggedInUser(ControllingComponent)