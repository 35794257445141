import {
    StringEqualsFilter
} from '@ec/filter-sort-page-query-js'

export const common = {
    branchId: {
        filterName: 'branchId', //a unique filter name, not in use right now (could be used to render filter components programmatically)
        filterKey: 'branchId', //the key that is looked up to get the value (could be a route parameter, a property name or a query parameter)
        label: 'branch Id', //clear text label for the filter; can be used for dropdowns or other controls
        defaultValue: null, //if no value is given, use this default for the query; if both is not set, the filter is ignored
        getFilterQuery: value => ([ //the filterquery that is processed after getting the value
            new StringEqualsFilter('branch.id', value, true)
        ])
    },
}