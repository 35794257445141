import React, {useState, useRef } from 'react'
import styled, {withTheme} from 'styled-components'
import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import { IconButton } from 'application/components/controls/icon_button/component'
import { exists } from 'application/common/data_helpers'

const DisplayContext = styled.div`
    display: flex;
    flex-direction: column;
`

const DisplayTrigger = styled.div`
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-right: ${props => props.isLabel ? '8px' : '0'};
    align-items: center;
    height: ${props => exists(props.height) ? props.height: 'auto'};
    background: ${props => props.menuOpen ? props.theme.color.gray70 : 'transparent'};

    i {
        color: ${props => props.menuOpen ? props.theme.color.white : props.theme.color.gray70};
    }

    span {
        color: ${props => props.menuOpen ? props.theme.color.white : props.theme.color.gray70};
    }

    :hover i, 
    :hover span {
        color: ${props => props.shouldHoverAsUserMenu ?  props.theme.color.gray70 : props.theme.color.white };
    }

    :hover {
        background: ${props => props.shouldHoverAsUserMenu ?  props.theme.color.black10 : props.theme.color.gray70 };
        color: ${props => props.shouldHoverAsUserMenu ? props.theme.color.gray70 : props.theme.color.white};
    }

    :active {
            background: ${props => props.shouldHoverAsUserMenu ? props.theme.color.black20 : props.theme.color.gray70};
            color: ${props => props.theme.color.black};
    }

`

const StyledContextMenu = styled.div`
    position: relative;
`

const StyledContextMenuList = styled.ul`
    position: absolute;
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    right: 0;
    display: inline;
    list-style-type: none;
    z-index: 2002;
    margin: 0;
`

export const StyledNameLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.menuOpen ? props.theme.color.white : props.theme.color.gray70};
    padding-left: 8px;
    cursor: pointer;
`






const ContextMenuComponent = (
    {
        children, 
        isInDialog, 
        isTop = false,
        onMenuOpen = () => {}, 
        onMenuClose = () => {}, 
        label = '', 
        theme, 
        iconKey,
        userName = '',
        width = '32px',
        height = '32px',
        iconSize='20px',
        greyHover = false,
        top = '34px'
    }
) => {


    const topCssValue = isTop 
        ? 'unset' 
        : top 

    const bottomCssValue = isTop
        ? '16px'
        : 'unset'

    const [menuOpen, setMenuOpen] = useState(false)

    const closeMenu = () => {
        setMenuOpen(false)
        onMenuClose()
    }
    
    const handleMenu = () => {
        if(menuOpen){
            closeMenu()
        } else{
            setMenuOpen(true)
            onMenuOpen()
        }
    }
    
    const handleParent = () => {
        if(menuOpen){
            closeMenu()
        }
    }

    const ref = useRef()
    useClickOutside(ref, closeMenu)
    useEscapeKey(closeMenu)


    const shouldHoverAsUserMenu = () => {
        return ( !menuOpen ) || ( greyHover && !menuOpen )
    }

    const hasLabel = label !== ''

    return <StyledContextMenu
        isInDialog={isInDialog}  
        ref={ref} 
        onClick={handleParent}
    >
        <DisplayContext>
            <DisplayTrigger 
                className='context-menu'
                onClick={handleMenu} 
                menuOpen={menuOpen}
                strechted={hasLabel} 
                isLabel={hasLabel}
                height={height}
                shouldHoverAsUserMenu={shouldHoverAsUserMenu()}
            >               
                <IconButton
                    onButtonClick={handleMenu}
                    iconKey={iconKey ? iconKey : 'more_horiz'}
                    width={width}
                    height={height}
                    iconSize={iconSize}
                    color={theme.color.anthracite}
                    hoverBackground='none'
                    activeBackground='none'
                /> {
                    hasLabel && <span>
                        {label}
                    </span>
                }
            </DisplayTrigger>
            {
                menuOpen && <StyledContextMenuList                    
                    top={topCssValue}
                    bottom={bottomCssValue}
                    isInDialog={isInDialog}
                >
                    {children}
                </StyledContextMenuList>
            }
        </DisplayContext>
        
    </StyledContextMenu>
}

export const ContextMenu = withTheme(ContextMenuComponent)