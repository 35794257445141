import React from 'react'
import styled  from 'styled-components'
import PropTypes from 'prop-types'
import {Step} from 'application/components/building_blocks/steps_bar/step'


const StyledStepsBar = styled.div`
    display: flex;
    width: 100%;
    height: 38px;
`


const StepsBarComponent = (
    {
        getStepsState 
    }
) => {
    const stepsState = getStepsState()  
    const lastStepElementIndex = stepsState.length - 1

    return <StyledStepsBar>
        {
            stepsState.map(
                (step, i) => <Step
                    key={i}
                    isLast = {i === lastStepElementIndex}
                    state={step.state}
                    label = {step.label}
                    subLabel = {step.subLabel}
                    i = {i}
                />
            )
        }
    </StyledStepsBar>
}


StepsBarComponent.propTypes = {
    getStepsState: PropTypes.func.isRequired
}


export {StepsBarComponent}

