
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'

export const ComprehensiveDamageTypeDetails = (
    {
        activity
    }
) => {
    const {
        name = ''
    } = activity.data

    return <ActivityDetailWrapper>
        <ActivityDetail label='Name' value={name} />
    </ActivityDetailWrapper>
}

ComprehensiveDamageTypeDetails.propTypes = {
    activity: PropTypes.object.isRequired
}