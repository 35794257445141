import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { DAMAGE_TYPE, ROUTES } from 'application/constants'
import { JobNumber } from 'application/components/controls/job_number'
import { ClaimNumber } from 'application/components/controls/claim_number'
import { DamageTypeName } from 'application/components/name_mappings/damage_type_name'
import { RowDetailsColumn } from './row-details-column'
import { FullName } from 'application/components/controls/full_name'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'
import {DateComponent} from 'application/components/controls/date'
import { RepairAuthorizationIndicator } from 'application/components/controls/repair_authorization_indicator'
import { DamageDisplay } from 'application/components/building_blocks/damage_display'



const StyledRowContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 8px 16px;   
    font-size: 12px;
    color: ${props =>props.theme.color.anthracite};
`

const StyledRowDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 32px 16px;   
    background-color: ${props =>props.theme.color.gray10};
    margin-left: 24px;
    border-radius: 5px;
`


export const RowDetails = ({
    element,
    isSelected
}) => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const claimNumber = element.type === DAMAGE_TYPE.COMPREHENSIVE 
        ? element?.comprehensiveData?.claim?.number
        : element?.liabilityData?.claim?.number

    const to = internalAccountLink(
        ROUTES.DAMAGE_MANAGER_BASE + element.id + '/overview'
    )
    return <StyledRowContent>
        <FlexBox cssRules='margin-bottom: 20px;'>
            <DamageDisplay 
                damage={element} 
                to={to}
            />

            <StyledRowDetailsBox>
                <RowDetailsColumn 
                    abovePrimaryLabel = { t('Claim number') }
                    aboveSecondaryLabel = { t('Damagetype') }                  
                    belowPrimaryLabel = { t('Job number') }
                    abovePrimaryValue = {
                        <ClaimNumber 
                            number={claimNumber} 
                            isSelected={isSelected}
                        />
                    }
                    aboveSecondaryValue = {
                        <DamageTypeName 
                            damageType={element.type} 
                            isSelected={isSelected}
                        />
                    }
                    belowPrimaryValue = {
                        <JobNumber
                            number={element.jobNumber} 
                            isSelected={isSelected}
                        />
                    }
                />
                <RowDetailsColumn 
                    abovePrimaryLabel = { t('Lawfirm') }          
                    belowPrimaryLabel = { t('Assignee') }
                    abovePrimaryValue = {
                        <LawFirmName lawFirm={element.lawFirm} />  
                    }
                    belowPrimaryValue = {
                        <FullName person={element.assignee} />
                    }
                />
                <RowDetailsColumn 
                    abovePrimaryLabel = { t('Authorization') }          
                    belowPrimaryLabel = { t('last change') }
                    abovePrimaryValue = {
                        <RepairAuthorizationIndicator 
                            repairAuthorization={element.repairAuthorization} 
                        />
                    }
                    belowPrimaryValue = {
                        <DateComponent 
                            date={element.modifiedAt}
                        />
                    }
                />
            </StyledRowDetailsBox>
        </FlexBox>
    </StyledRowContent>    
}