import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { AnnouncementButton } from 'application/components/building_blocks/announcement_button'
import { PushBottom16, PushBottom8, Spacer } from 'application/components/pages/_common'
import { AnnouncementBubble, AnnouncementBubbleHeadline, AnnouncementBubbleContent, AnnouncementBubbleAction } from 'application/components/building_blocks/announcement_bubble'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { useTranslation } from 'react-i18next'
import { TextLink } from 'application/components/fragments/typography'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES } from 'application/constants'
import { useAnnouncements } from 'application/components/hooks/announcements/use_announcements'
import { ANNOUNCEMENTS } from 'application/constants/announcements'

const StyledAnnouncementAndFeedbackButtons = styled.div`
    padding: 0 ${props => props.$collapsed ? '6px' : '12px'};
`

export const AnnouncementAndFeedbackButtons = ({
    collapsed = false
}) => {
    const {
        getSetting,
        updateUserSettings
    } = useUiSettings()

    const {
        showNewestAnnouncement,
        newestAnnouncement
    } = useAnnouncements(ANNOUNCEMENTS.TYPE.ALL)

    const {
        internalAccountLink
    } = useApplicationLinks()

    const {t} = useTranslation()
    const theme = useTheme()

    const [feedbackBubbleHidden, setFeedbackBubbleHidden] = useState(getSetting(UI_SETTINGS.USER.HIDE_FEEDBACK_NOTICE))
    const [updateBubbleVisible, setUpdateBubbleVisible] = useState(false)
    
    const dontShowFeedbackBubbleAnymore = (save = false) => {
        updateUserSettings(UI_SETTINGS.USER.HIDE_FEEDBACK_NOTICE, true, save)
        setFeedbackBubbleHidden(true)
    }

    const dontShowUpdateBubbleAnymore = (save = false) => {
        updateUserSettings(UI_SETTINGS.USER.LAST_SEEN_ANNOUNCEMENT, newestAnnouncement?.iterator, save)
        setUpdateBubbleVisible(false)
    }

    useEffect(()=>{
        if(showNewestAnnouncement){
            setUpdateBubbleVisible(showNewestAnnouncement)
        }
    }, [showNewestAnnouncement])

    const announcementColors = useMemo(() => {
        return newestAnnouncement?.type === ANNOUNCEMENTS.TYPE.EMERGENCY
            ? {
                primaryColor: theme.color.error,
                secondaryColor: theme.color.error,
                fontColor: theme.color.white
            }
            : {
                primaryColor: theme.color.announcementColor,
                secondaryColor: theme.color.white,
                fontColor: theme.color.announcementColor
            }
    }, [newestAnnouncement?.type, theme.color])

    return <React.Fragment>

        <Spacer />

        <StyledAnnouncementAndFeedbackButtons 
            $collapsed={collapsed}
        >
            <AnnouncementButton
                collapsed={collapsed}
                to={process.env.REACT_APP_FEEDBACK_LINK}
            >
                {
                    !feedbackBubbleHidden && <AnnouncementBubble
                        onClose={()=>{dontShowFeedbackBubbleAnymore(false)}}
                        onClickDontShowAnyMore={()=>{dontShowFeedbackBubbleAnymore(true)}}
                    >
                        <AnnouncementBubbleHeadline>
                            {t('Give us feedback')}
                        </AnnouncementBubbleHeadline>
                        <AnnouncementBubbleContent>
                            {t('If you want to give us some feedback about the application, like feature requests or bug reports, you can do that here:')}
                        </AnnouncementBubbleContent>
                        <AnnouncementBubbleAction>
                            <TextLink $color='inherit' target='_blank' href={process.env.REACT_APP_FEEDBACK_LINK}>&raquo; {t('Go to feedback form')}</TextLink>
                        </AnnouncementBubbleAction>
                    </AnnouncementBubble>
                }
            </AnnouncementButton>

            <PushBottom8 />
            
            <AnnouncementButton 
                collapsed={collapsed}
                buttonText='Updates'
                primaryColor={theme.color.announcementColor}
                secondaryColor={theme.color.white}
                fontColor={theme.color.announcementColor}
                icon='campaign'
                variant='navlink'
                to={internalAccountLink(ROUTES.CHANGELOG)}
            >
                {
                    updateBubbleVisible && feedbackBubbleHidden && <AnnouncementBubble
                        onClose={()=>{dontShowUpdateBubbleAnymore(false)}}
                        onClickDontShowAnyMore={() => {dontShowUpdateBubbleAnymore(true)}}
                        {...announcementColors}
                    >
                        <AnnouncementBubbleHeadline>
                            {newestAnnouncement?.title}
                        </AnnouncementBubbleHeadline>
                        <AnnouncementBubbleContent>
                            {newestAnnouncement?.body}
                        </AnnouncementBubbleContent>
                    </AnnouncementBubble>
                }

            </AnnouncementButton>
        </StyledAnnouncementAndFeedbackButtons>

        <PushBottom16 />
        
    </React.Fragment>
}