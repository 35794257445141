import React, { useState } from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { DamageStateFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { DamageCustomStateFilter } from '../damage_custom_state_filter'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { useQuery, setQuery } from 'application/components/hooks/use_query'
import * as filters from 'application/filters'
import { exists } from 'application/common/data_helpers'
import { FILTERS } from 'application/constants'
import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { Label } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'

export const DamageStateFilterCombo = ({
    routeParameters,
    disabled,
    onChange = () => {}
}) => {
    const {t} = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const query = useQuery()
    const { OFFSET, LIMIT} = routeParameters
    const specificFilter = filters.damages.customState
    const genericFilter = filters.damages.state
    const [specificFilterActive, setSpecificFilterActive] = useState(
        exists(
            query.getStringParam(specificFilter.filterKey)
        )
    )

    const handleChange = () => {

        const newGenericValue = specificFilterActive 
            ? FILTERS.URL.VALUES.DAMAGES.STATE.IN_PROGRESS 
            : FILTERS.URL.VALUES.DAMAGES.STATE.ALL

        setSpecificFilterActive(!specificFilterActive)

        setQuery(
            navigate, 
            location, 
            location.pathname,
            [
                {
                    key: genericFilter.filterKey,
                    value: newGenericValue
                }
            ],
            true,
            [
                OFFSET,
                LIMIT,
                specificFilter.filterKey
            ]
        )
    }

    return <FilterFragment.FilterRow>

        <FilterFragment.FilterLabel className='filterLabel'>
            <Label cssMargin='0 16px 0 0'>
                {t('Damage state')}
            </Label>
        </FilterFragment.FilterLabel>

        <FilterFragment.FilterAction>
            {
                specificFilterActive 
                    ? <FlexBox flexGrow='0' flexBasis='268px'>
                        <DamageCustomStateFilter 
                            OFFSET={OFFSET}
                            LIMIT={LIMIT}
                            disabled={disabled}
                            onChange={onChange}
                        />
                    </FlexBox>
                    : <DamageStateFilterCheckboxGroup
                        OFFSET={OFFSET}
                        LIMIT={LIMIT}
                        disabled={disabled}
                        onChange={onChange}
                    />
            }
            <IconButtonWithLabel
                iconKey='autorenew'
                label={specificFilterActive ? 'einfacher Statusfilter' : 'erweiterter Statusfilter'}
                iconSize='22px'
                fontSize='14px'
                onButtonClick={handleChange} 
            />
        </FilterFragment.FilterAction>


    </FilterFragment.FilterRow>
}