import { PdfSignature } from 'application/components/building_blocks/pdf_signature'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'
import { ActionButton } from 'application/components/controls/action_button'
import { Collapsable } from 'application/components/controls/collapsable'
import { pdfBuilders } from 'application/components/dynamic_pdfs'
import { DamageForms } from 'application/components/forms'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useAccountLogoLoader } from 'application/components/hooks/account_logo/use_account_logo_loader'
import { PushBottom16 } from 'application/components/pages/_common'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'



export const TaskDamageRepairScheduleComponent = ({
    payload, 
    loadingFailed, 
    onSaveAdditionalDamageData,
    onSaveRepairScheduleData,
    selectedTaskDocuments,
    onFilesAdded,
    isExternal,
    sharedResourceId,
    onShowAttachmentDetails
}) => {
    const {t} = useTranslation()
    const currentBranch = useSelector(state => state.auth.currentBranch)
    const currentAccount = useSelector(state => state.auth.currentAccount)
    const params = useParams()
    const accountId = params.accountId

    const {
        accountLogoFound,
        filePath
    } = useAccountLogoLoader(
        accountId
    )

    const accountLogo = {
        accountLogoFound,
        filePath
    }



    const {
        damage,
        repairSchedule,
        damageId,
        taskId,
        customer
    } = payload

    const [damageData, setDamageData] = useState(damage)
    const [repairScheduleData, setRepairScheduleData] = useState(repairSchedule)
    const [files, setFiles] = useState([])
    const [showPdf, setShowPdf] = useState(false)

    const initialAttachments = selectedTaskDocuments === null 
        ? [] 
        : selectedTaskDocuments.documents

    const handleUpdateDamage = (damageId, formData) => {
        setDamageData(formData)
        onSaveAdditionalDamageData(damageId, formData)
    }

    const handleUpdateRepairSchedule = (damageId, formData) => {
        setRepairScheduleData(formData)
        onSaveRepairScheduleData(damageId, formData)
    }

    const handleAttachmentClick = (attachment) => {
        onShowAttachmentDetails(
            attachment, 
            damageId, 
            taskId, 
            isExternal, 
            sharedResourceId
        )
    }

    useEffect(()=>{
        if(files.length > 0){
            onFilesAdded(
                files, 
                taskId, 
                damageId, 
                [taskId], 
                isExternal
            )
        }
    }, [damageId, files, isExternal, onFilesAdded, taskId])

    const signatures = useMemo(
        ()=>{
            return [
                {
                    label: 'Repair schedule',
                    description: ''
                }
            ]
        },[]
    )


    if(!damageId){
        return <WidgetErrorMessage>
            {t('Damage ID not present. Render this widget only in damage manager.')}
        </WidgetErrorMessage>
    }

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('Data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <FlexBox flexDirection='column'>

        <Collapsable headline={t('Additional damage data')} initiallyCollapsed={false}>
            <DamageForms.DamageAdditionalDamageInformationForm 
                onSave={handleUpdateDamage}  
                damageId={damageId}
                formData={damage}
            />
        </Collapsable>

        <Collapsable headline={t('Repair schedule')} initiallyCollapsed={false}>
            <DamageForms.DamageRepairScheduleForm 
                onSave={handleUpdateRepairSchedule}  
                damageId={damageId}
                formData={repairSchedule}
            />
        </Collapsable>

        <FlexBox>
            <ActionButton 
                buttonText={t('Create repair schedule PDF')}
                onButtonClick={() => {
                    setShowPdf(true)
                }}
            />
        </FlexBox>
        {
            showPdf && <PdfSignature
                pdfBuilder={pdfBuilders.repairSchedulePdfBuilder} 
                pdfValues={{
                    damageData,
                    repairScheduleData,
                    customer,
                    currentBranch,
                    currentAccount,
                    accountLogo,
                    signatures: []
                }} 
                pdfFileName={`Reparaturablaufplan-${damage.vehicle.licensePlate}.pdf`}
                onSave={
                    (_url, attachment)=>{
                        setFiles( [attachment] )
                        setShowPdf(false)
                    }
                }
                abort={
                    ()=>{ setShowPdf(false) }
                }
                headline='Planned repair schedule'
                saveButtonText='Done'
                signatureFields={signatures}
            />
        }

        <PushBottom16 />

        <TaskUploader
            onFileAdded={ () => { } }
            files={files}
            initialAttachments={initialAttachments}
            onAttachmentClick={handleAttachmentClick}
            damageId={damageId}
        />

    </FlexBox>
}

TaskDamageRepairScheduleComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}