import { exists } from 'application/common/data_helpers'

export const putContactInformation = payload => {
    return!exists(payload.phoneNumber) 
        ? {
            name: payload.name
        }
        : {
            name: payload.name,
            phoneNumber: payload.phoneNumber
        }
}