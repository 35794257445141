import React from 'react'
import PropTypes from 'prop-types'
import { ValidationErrorMessage } from 'application/components/controls/form/_common'

export const HiddenField = ({
    name,
    register=()=>{},
    validate=null,
    error,
    errorMessageOverride=''
}) => {
    return <React.Fragment>
        <input type="hidden" {...register(name, {validate})} />
        {
            validate !== null && <ValidationErrorMessage 
                error={error} 
                errorMessageOverride={errorMessageOverride} 
            />
        }
    </React.Fragment>
}

HiddenField.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func
}