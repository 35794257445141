import { put, takeLeading } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* sendInvitationSaga(action) {
    try{
        yield resourceCall(
            api.gateway.users.postInvitation, 
            {
                userId: action.userId
            }
        )
        yield put(showToast('Invitation sent'))
    } catch (e) {
        yield handleError(e)
    }
}

export function* sendInvitationWatcher() {
    yield takeLeading(ACTIONS.ADMINISTRATION_SEND_INVITATION, sendInvitationSaga)
}