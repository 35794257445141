import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { useStatefulForm } from 'application/components/hooks'
import { PartialForms } from 'application/components/forms'
import { FlexBox } from 'application/components/fragments/flex_box'
import {DAMAGE_TYPE} from 'application/constants'
import { isDefined, notNull } from 'application/common/data_helpers'
import { liabilityDataStructure } from 'application/components/forms/_configurations'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'

const LawfirmInsuranceInformationForm = (
    { 
        formData = {},
        widgetKey, 
        onSave,
        damageType
    }
) => {
    const {t} = useTranslation()

    const noInsuranceGiven = !isDefined(formData.insurance)
    const initialInsuranceIsFromList = isDefined(formData.liabilityData?.insurance?.company?.id) 
        && notNull(formData.liabilityData?.insurance?.company?.id)
    const [insuranceSelectionFromList, setInsuranceSelectionFromList] = useState(initialInsuranceIsFromList || noInsuranceGiven)
    const damageIsLiability =  damageType === DAMAGE_TYPE.LIABILITY

    const defaultLiabilityValues = liabilityDataStructure.mapDefaultValues(formData?.liabilityData || null)
    const liabilityForm = useStatefulForm(
        { defaultValues: defaultLiabilityValues }
    )

    const onSubmitSuccess = () => { 
        const payload = liabilityDataStructure.mapFormValuesToLiabilityPayload( liabilityForm.getValues() )
        onSave(payload)
    }

    return <React.Fragment>
        {
            damageIsLiability 
                ? <React.Fragment>
                    <PartialForms.LiabilitaInsurancePartialForm
                        insuranceSelectionFromList={insuranceSelectionFromList}
                        setInsuranceSelectionFromList={setInsuranceSelectionFromList}
                        stateFulFormHook={liabilityForm}
                        formTitle=''
                    />
                    <FlexBox justifyContent='flex-end'>
                        <ActionButton 
                            buttonText={t('Save insurance information')}
                            onButtonClick={liabilityForm.handleSubmit(onSubmitSuccess)} 
                        />
                    </FlexBox>
                </React.Fragment>
                : <InfoBox type={INFOBOX.TYPES.SECONDARY} direction='row' cssMargin='0'>
                    <FontIcon icon='error_outline' />
                    <Spacer basis='10px' />
                    <span>{t('Liability forms are only displayed for liability cases.')}</span>
                    <Spacer />
                </InfoBox>
        }
        
    </React.Fragment>
}

export { LawfirmInsuranceInformationForm }