import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageInsuranceSaga(action) {
    const { damageId } = action.match.params
    const victimInsuranceData = yield resourceCall(
        api.gateway.damage.getComprehensiveDataByDamageId, 
        {
            damageId: damageId
        }
    )
    const opponentInsuranceData = yield resourceCall(
        api.gateway.damage.getLiabilityData, 
        {
            damageId: damageId
        }
    )
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {
        victimInsurance: victimInsuranceData.insurance,
        opponentInsurance: opponentInsuranceData.insurance
    }))
}