import React from 'react'
import { Page, Text, View, Document, Image} from '@react-pdf/renderer'
import { page, header, headline, form, paragraph, signatureField, paragraph2, underlinedField} from './styles'
import { DateComponent } from 'application/components/controls/date'
import { exists } from 'application/common/data_helpers'




const today = new Date()

export const DeclarationOfAssignmentPdf = ({
    values
}) => {
    const {signatures = []} = values

    const setX = (bool) => {
        return bool ? 'x' : ''
    }

    return <Document>
        <Page size="A4" style={page}>
            <View style={header}>
                <View style={header.leftColumn}
                >
                    <Text>{values.expertData.name}</Text>
                    <Text>{values.expertData?.address?.line1}</Text>
                    {
                        values.expertData?.address?.line2 !== '' && <Text>
                            { values.expertData.inspectionSite.line2 }
                        </Text>
                    }
                    <Text>{ values.expertData?.address?.postalCode }{ values.expertData?.address?.locality }</Text>
                </View>
                <View style={header.rightColumn} >
                    <View style={header.rightColumn.row}>
                        <Text> Tel:</Text>
                        <Text>{values.expertData?.contact?.phoneNumber || ''}</Text>
                    </View>
                    <View style={header.rightColumn.row}>
                        <Text> Fax:</Text>
                        <Text>{''}</Text>
                    </View>
                    <View style={header.rightColumn.row}>
                        <Text> Mail:</Text>
                        <Text>{values.expertData?.contact?.email || ''}</Text>
                    </View>
                    <View style={header.rightColumn.row}>
                        <Text> Web:</Text>
                        <Text>
                            {''}
                        </Text>
                    </View>
                </View>			
            </View>{/*header*/}
            <View style={headline}>
                <Text>
                    Auftragserteilung / Abtretung (erfüllungshalber) / Zahlungsanweisung 
                </Text>
                <Text>
                    Widerruf des Gutachtenauftrags mit Widerrufsbelehrung 
                </Text>
            </View>{/*headline*/}
            <View style={form}>
                <View style={{flex:1}}>
                    <Text>{values.expertData?.name}</Text>
                    <View style={{height: '24px'}} />
                    <Text>{values.expertData?.address?.line1}</Text>
                    <Text>{ `${values.expertData?.address?.postalCode} ${values.expertData?.address?.locality}`}</Text>
                    <View style={{height: '5px'}} />
                    <View style={form.textArea}>
                        <View style={form.textArea.label} >
                            <Text>Auftraggeber / Geschädigter</Text>
                        </View>
                        <View style={form.textArea.content} >
                            <Text> 
                                { `${values.client.firstName} ${values.client.lastName}`}
                            </Text>
                            <Text>{values.client.street}</Text>
                            <Text>{`${values.client.postalCode} ${values.client.locality}`}</Text>
                        </View>              	
                    </View>{/*textArea*/} 
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>Fahrzeug</Text>
                        </View>
                        <View style={form.fieldBig.content}>
                            <Text>{values.client.vehicle.make} {values.client.vehicle.model}</Text>
                        </View>          		
                    </View>{/*field*/}   
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>amtl. Kennzeichen</Text>
                        </View>
                        <View style={form.fieldBig.content}>
                            <Text>{values.client.vehicle.licensePlate}</Text>
                        </View>          		
                    </View>{/*field*/}
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>Fahrzeugident-Nr</Text>
                        </View>
                        <View style={form.fieldBig.content}>
                            <Text>{values.client.vehicle.chassisNumber}</Text>
                        </View>          		
                    </View>{/*field*/}  
                    <View 
                        style={{
                            flexDirection: 'row', 
                            justifyContent: 'space-between',
                            height: '30px', 
                            alignItems: 'center'
                        }}
                    >
                        <View>
                            <Text>Vorsteuerabzugsberechtigt</Text>
                        </View>
                        <View style={{flexDirection: 'row', paddingRight: '12px' }}>
                            <Text>[ { setX(values.client.eligibleForInputTaxDeduction ) } ] ja  </Text>
                            <Text>[ { setX(!values.client.eligibleForInputTaxDeduction ) } ] nein</Text>
                        </View>
                    </View>
                </View>{/*leftColumn*/}
        
                <View style={form.columnSpace}/>        
        
                <View style={{flex:1}}>
                    <View style={form.field}>
                        <View style={form.field.label}>
                            <Text>Schadentag</Text>
                        </View>
                        <View style={form.field.content}>
                            <Text>
                                <DateComponent date={values.damage.date} />
                            </Text>
                        </View>          		
                    </View>{/*field*/}
                    <View style={form.field}>
                        <View style={form.field.label}>
                            <Text>Schadenzeit</Text>
                        </View>
                        <View style={form.field.content}>
                            <Text style={{minheight:7}} ></Text>
                        </View>          		
                    </View>{/*field*/}
                    <View style={form.field}>
                        <View style={form.field.label}>
                            <Text>Schadenort</Text>
                        </View>
                        <View style={form.field.content}>
                            <Text style={{minHeight:7}}  >{values.damage.location}</Text>
                        </View>          		
                    </View>{/*field*/}
                    <View style={form.field}>
                        <View style={form.field.label}>
                            <Text>Gutachten-Nr.</Text>
                        </View>
                        <View style={form.field.content}>
                            <Text style={{minheight:7}}  ></Text>
                        </View>          		
                    </View>{/*field*/}     
                    <View style={form.textArea}>
                        <View style={form.textArea.label} >
                            <Text>Versicherungsnehmer / Schädiger</Text>
                        </View>
                        <View style={form.textArea.content} >
                            <Text> 
                                { `${values.tortFeasor.firstName} ${values.tortFeasor.lastName}`}
                            </Text>
                            <Text>{values.tortFeasor.street}</Text>
                            <Text>{`${values.tortFeasor.postalCode} ${values.tortFeasor.locality}`}</Text>
                        </View>              	
                    </View>{/*textArea*/} 
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>Fahrzeug</Text>
                        </View>
                        <View style={form.fieldBig.content}>
                            <Text>{values.tortFeasor.vehicle.make} {values.tortFeasor.vehicle.model}</Text>
                        </View>          		
                    </View>{/*field*/}   
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>amtl. Kennzeichen</Text>
                        </View>
                        <View style={form.fieldBig.content}>
                            <Text>{values.tortFeasor.vehicle.licensePlate}</Text>
                        </View>          		
                    </View>{/*field*/}   
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>Versicherung</Text>
                        </View>
                        <View style={form.fieldBig.content}>
                            <Text>{values.tortFeasor.vehicle.insurance.name}</Text>
                        </View>          		
                    </View>{/*field*/}
                    <View style={form.fieldBig}>
                        <View style={form.fieldBig.label}>
                            <Text>Schaden- / Versicherungsscheinnummer</Text>
                        </View>
                        <View style={[form.fieldBig.content, {paddingLeft: '8px'}]}>
                            <Text>{values?.tortFeasor?.vehicle?.insurance?.claimNumber || ''} / {values.tortFeasor.vehicle.insurance.certificateNumber}</Text>
                        </View>          		
                    </View>{/*field*/}           
                </View>{/*rightColumn*/}
            </View>{/*form*/}
      
            <View>
                <Text style={paragraph}>
          Aus  Anlass  des  oben  beschriebenen  Schadenfalles  beauftrage  ich  das  oben  genannte  Kfz-Sachverständigenbüro,  ein  Gutachten  zwecks  Ermittlung  der Schadenhöhe zu erstellen. Hierfür wird ein Werklohn bzw. Honorar in Anlehnung an die tatsächliche Schadenhöhe gemäß beigefügter Honorartabelle des Sachverständigenbüros zzgl. erforderlicher Nebenkosten vereinbart. Das Grundhonorar berechnet sich nach der Honorartabelle HBIII. Die Höhe der Vergütung der Nebenkosten richtet sich nach den einfachen Sätzen des JVEG (Gesetz über die Vergütung von Sachverständigen, Dolmetscherinnen, Dolmetschern, Übersetzerinnen und Übersetzern sowie die Entschädigung von ehrenamtlichen Richterinnen, ehrenamtlichen Richtern, Zeuginnen, Zeugen und Dritten).
                </Text>
                <Text style={paragraph}>
          Ich    trete    hiermit    meinen    Schadensersatzanspruch    aus    dem    oben    bezeichneten    Unfallereignis    auf    Ersatz    der    Kosten    des    eingeholten Sachverständigengutachtens gegen den Fahrer, den Halter und den Versicherer des gegnerischen Fahrzeuges erfüllungshalber an das oben bezeichnete Kfz-Sachverständigenbüro ab. Das Sachverständigenbüro kann die Ansprüche gegen mich geltend machen, nachdem der regulierungspflichtige Versicherer trotz Aufforderung keine Zahlung oder lediglich eine Teilzahlung geleistet hat, mithin die Zahlung (teilweise) verweigert hat. Die Inanspruchnahme meinerseits erfolgt nur Zug um Zug gegen Rückabtretung der noch offenen Schadenersatzforderung.
                </Text>
                <Text style={paragraph}>
         Hiermit weise ich den regulierungspflichtigen Versicherer an, die in Rechnung gestellten Sachverständigenkosten unmittelbar auf das Bankkonto des von mir beauftragten Sachverständigenbüros zu zahlen.
                </Text>         
            </View>
      
            <View style={{flexDirection: 'row'}}>        
                <View style={{flex:1}}>
                    <View style={signatureField}>
                        <Text style={signatureField.content}>
                            {values.locality}, den <DateComponent date={today} />
                        </Text>
                    </View>
                    <View style={signatureField.label}>
                        <Text >Ort</Text>
                    </View>
                </View>{/*signatureField*/}         
                <View style={form.columnSpace}/>          
                <View style={{flex:1}}>
                    <View style={[signatureField, {paddingLeft: '16px'}]}>
                        {
                            exists(signatures[0]) && <Image
                                style={ {width: '40px'} }
                                source={ {uri: signatures[0]} }
                            />
                        } 
                    </View>
                    <View style={signatureField.label}>
                        <Text >Unterschrift</Text>
                    </View>
                </View>{/*signatureField*/}       
            </View>{/*signatureRow*/}
      
            <View>
                <Text style={[paragraph,paragraph.headline,{ width:'85px'}]}>
                Einwilligung Datenschutz
                </Text>
                <Text style={paragraph}>
          Ich erkläre hiermit meine Einwilligung, dass meine personenbezogenen Daten im Rahmen der Erstellung des von mir beauftragten Schadengutachtens an die  von  mir  beauftragte  Reparaturwerkstatt  und  die  von  mir  beauftragte  Anwaltskanzlei  sowie  an  die  regulierungspflichtige  Versicherung  zum  Zwecke  der Schadenregulierung weitergeleitet werden. Ich kann meine Einwilligung jederzeit mit Wirkung für die Zukunft gegenüber dem beauftragten Sachverständigen widerrufen.
                </Text>
            </View>{/*headlineParagraph*/}
      
            <View style={{flexDirection: 'row'}}>        
                <View style={{flex:1}}>
                    <View style={signatureField}>
                        <Text style={signatureField.content}>
                            {values.locality},  den <DateComponent date={today} />
                        </Text>
                    </View>
                    <View style={signatureField.label}>
                        <Text >Ort</Text>
                    </View>
                </View>{/*signatureField*/}         
                <View style={form.columnSpace}/>          
                <View style={{flex:1}}>
                    <View style={[signatureField, {paddingLeft: '16px'}]}>
                        {
                            exists(signatures[1]) && <Image
                                style={ {width: '40px'} }
                                source={ {uri: signatures[1]} }
                            />
                        } 
                    </View>
                    <View style={signatureField.label}>
                        <Text >Unterschrift des/der Verbraucher(s)</Text>
                    </View>
                </View>{/*signatureField*/}       
            </View>{/*signatureRow*/}
      
            <View>
                <Text style={[paragraph,paragraph.headline, { width:'185px'}]}>
          Nur bei Auftragsannahme außerhalb der Geschäftsräume
                </Text>
                <Text style={paragraph}>
          Wird der Vertrag außerhalb der Geschäftsräume des oben genannten Sachverständigenbüros geschlossen, 
          haben Kunden, die Verbraucher sind, ein 14-tägiges Widerrufsrecht.
                </Text>
                <Text style={paragraph}>
              Über  die  Bedingungen,  die  Fristen  und  das  Verfahren  für  die  Ausübung  des  Widerrufsrechts  ist  der  Kunde  vor  Erteilung  des  Auftrages  separat  informiert worden.
                </Text>
            </View>{/*headlineParagraph*/}
      
      
            <View style={{flexDirection: 'row'}}>
                <View style={{flex:1}}>
                    <Text style={paragraph}>[ x ]</Text>
                </View>
                <View style={{flex:20}}>
                    <Text style={paragraph}>
            Ich bestätige, dass ich die Widerrufsbelehrung und das Muster-Widerrufsformular erhalten habe.
                    </Text>
                </View>
            </View>
      
            <View style={{flexDirection:'row', marginTop:'2px'}}>
                <View style={{flex:1}}>
                    <Text style={paragraph}>[ x ]</Text>
                </View>
                <View style={{flex:20}}>
                    <Text style={paragraph}>
            Erklärung des Kunden zur Ausführung der beauftragten Arbeiten vor Ablauf der WiderrufsfristIn  Kenntnis  der  Widerrufsbelehrung  fordere  ich  das  beauftragte  Sachverständigenbüro  auf,  mit  der  Gutachtenerstellung  bereits  vor  Ablauf  der Widerrufsfrist zu beginnen. Mir ist bewusst, dass ich bei vollständiger Vertragserfüllung durch den Unternehmer mein Widerrufsrecht verliere. Mir ist weiter bewusst, dass ich für den Fall, dass ich vor vollständiger Vertragserfüllung den Vertrag widerrufe, für die bis zum Widerruf bereits erbrachten Leistungen einen Wertersatz zu leisten habe.
                    </Text>
                </View>
            </View>{/*checkedInfo*/}
      
            <View style={{flexDirection: 'row'}}>        
                <View style={{flex:1}}>
                    <View style={signatureField}>
                        <Text style={signatureField.content}>
                            {values.locality},  den <DateComponent date={today} />
                        </Text>
                    </View>
                    <View style={signatureField.label}>
                        <Text >Ort</Text>
                    </View>
                </View>{/*signatureField*/}         
                <View style={form.columnSpace}/>          
                <View style={{flex:1}}>
                    <View style={[signatureField, {paddingLeft: '16px'}]}>
                        {
                            exists(signatures[2]) && <Image
                                style={ {width: '40px'} }
                                source={ {uri: signatures[2]} }
                            />
                        } 
                    </View>
                    <View style={signatureField.label}>
                        <Text >Unterschrift des/der Verbraucher(s)</Text>
                    </View>
                </View>{/*signatureField*/}       
            </View>{/*signatureRow*/}
      
            <View style={{marginTop: '17px'}}>
                <Text style={{fontSize: 6}}>
        Diese Abtretungserklärung wird vom Bundesverband der freiberuflichen und unabhängigen Sachverständigen für das Kraftfahrzeugwesen e.V.
          – BVSK – empfohlen.Stand: Januar 2019
                </Text>
            </View>{/*note*/}
        </Page>
  
    
        <Page size="A4" style={page}>
            <View style={headline}>
                <Text style = {{
                    borderBottom: '1px solid black',
                    width: '150px'
                }}>
           WIDERRUFSBELEHRUNG
                </Text>
            </View>{/*headline*/}
    
            <View>
                <Text style={paragraph2}>
         Widerrufsrecht
                </Text>
                <Text style={paragraph2}>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. 
            Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.
                </Text>
                <View style={paragraph2.space} />
                <Text style={paragraph2}>
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
                </Text>
                <View style={paragraph2.space} />
                <Text style={[
                    paragraph2,
                    {
                        borderBottom: '0.5px solid black',
                        width: '465px'
                    }
                ]}>
                    {/* Kfz-Prüfstelle Monheim IFB Ing. GmbH, Benzstraße 8, 40789 Monheim am Rhein, mail@kfz-pruefstelle-monheim.de */}
                </Text>
                <View style={paragraph2.space} />
                <Text style={paragraph2}>
          mittels einer eindeutigen Erklärung (zum Beispiel einem mit der Post versandten Brief, einem Telefax oder einer E-Mail)  über  Ihren  Entschluss,  
          diesen  Vertrag  zu  widerrufen,  informieren.  Sie  können  dafür  das  beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
          Zur Wahrung der Widerrufsfrist reicht es aus,
          dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                </Text>
                <View style={paragraph2.space} />
                <Text style={paragraph2}>
          Folgen des Widerrufs
                </Text>
                <Text style={paragraph2}>
          Wenn  Sie  diesen  Vertrag  widerrufen,  haben  wir  Ihnen  alle  Zahlungen,  
          die  wir  von  Ihnen  erhalten  haben, einschließlich  der  Lieferkosten  (mit  Ausnahme  der  zusätzlichen  Kosten, 
          die  sich  daraus  ergeben,  
          dass  Sie eine  andere  Art  der  Lieferung  als  die  von  uns  angebotene,  
          günstigste  Standardlieferung  gewählt  haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, 
          an dem die Mitteilung über Ihren  Widerruf  dieses  Vertrags  bei  uns  eingegangen  ist.  
          Für  diese  Rückzahlung  verwenden  wir  dasselbe Zahlungsmittel, 
          das  Sie  bei  der  ursprünglichen  Transaktion  eingesetzt  haben,  
          es  sei  denn,  mit  Ihnen  wurde ausdrücklich  etwas  anderes  vereinbart; 
          in  keinem  Fall  werden  Ihnen  wegen  dieser  Rückzahlung  Entgelte berechnet.
                </Text>
                <View style={paragraph2.space} />
                <Text style={paragraph2}>
        Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, 
          so haben Sie uns einen  angemessenen  Betrag  zu  zahlen,  
          der  dem  Anteil,  der  bis  zu  dem  Zeitpunkt,  zu  dem  Sie  uns  von  der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, 
          bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                </Text>
            </View>
            <View style={{borderBottom: '1px dashed black', height: '24px'}} />
            <View style={headline}>
                <Text style = {{
                    borderBottom: '1px solid black',
                    width: '148px',
                    fontSize: 9,
                    marginBottom: '4px'
                }}>
           MUSTER-WIDERRUFSFORMULAR
                </Text>
            </View>{/*headline*/}
            <Text style={paragraph2}>
        Wenn  Sie  den  Vertrag  widerrufen  wollen,  
        dann  füllen  Sie  bitte  dieses  Formular  aus  und  senden  Sie  es  an nachfolgende Adresse zurück:
            </Text>
            <View style={underlinedField}>
                <Text style={underlinedField.value}> 
                    {/* Name, Vorname, Adressestr. 2, 32432 Ort von der Adresse */}
                </Text>
            </View>{/*underlinedField*/}
            <Text style={paragraph2}>
        Hiermit  widerrufe(n)  ich/wir (*)  den  von  mir/uns (*)  abgeschlossenen  Vertrag  
        über  die  Erstellung  eines Gutachtens zur Schadenhöhe
            </Text>
            <View style={underlinedField}>
                <Text style={underlinedField.value}></Text>
            </View>{/*underlinedField*/}
      
            <View style={{flexDirection: 'row'}}>
                <View style={{flex:1}}>
                    <Text style={paragraph}>Bestellt am (*) / erhalten am (*):</Text>
                </View>
                <View style={{flex:2}}>
                    <View style={underlinedField}>
                        <Text style={underlinedField.value}> 
                            {/* 23.2.2001 / 3.3.2001  */}
                        </Text>
                    </View>{/*underlinedField*/}
                </View>
            </View>{/*row*/}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex:1}}>
                    <Text style={paragraph}>Name des/der Verbraucher(s):</Text>
                </View>
                <View style={{flex:2}}>
                    <View style={underlinedField}>
                        <Text style={underlinedField.value}> 
                            {/* Verbrauchervorname, Verbrauchernachname */}
                        </Text>
                    </View>{/*underlinedField*/}
                </View>
            </View>{/*row*/}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex:1}}>
                    <Text style={paragraph}>Anschrift des/der Verbraucher(s):</Text>
                </View>
                <View style={{flex:2}}>
                    <View style={underlinedField}>
                        <Text style={underlinedField.value}> 
                            {/* Verbraucherstr. 1, 32432 Verbraucherort */}
                        </Text>
                    </View>{/*underlinedField*/}
                </View>
            </View>{/*row*/}
  
      
      
            <View style={{flexDirection: 'row'}}>        
                <View style={{flex:1}}>
                    <View style={signatureField}>
                        <Text style={signatureField.content}></Text>
                    </View>
                    <View style={signatureField.label}>
                        <Text >Datum</Text>
                        <View style={{height: '7px'}} />
                        <Text> (*) Unzutreffendes streichen. </Text>
                    </View>
                </View>{/*signatureField*/}         
                <View style={form.columnSpace}/>          
                <View style={{flex:1}}>
                    <View style={signatureField}>
                        <Text style={signatureField.content}></Text>
                    </View>
                    <View style={signatureField.label}>
                        <Text >Unterschrift des/der Verbraucher(s)</Text>
                        <Text> (nur bei Mitteilung auf Papier)</Text>
                    </View>
                </View>{/*signatureField*/}       
            </View>{/*signatureRow*/}
      
        </Page>       
    </Document>
}