import React, {useCallback, useState} from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { AssigneesTable } from 'application/components/tables/refactored/users/assignees'
import { useDispatch } from 'react-redux'
import { loadAssignees } from 'application/redux/actions/assignees'
import { isNull } from 'application/common/data_helpers'
import { setQuery } from 'application/components/hooks/use_query'
import { useLocation, useNavigate } from 'react-router-dom'
import { Paragraph } from 'application/components/fragments/typography'
import { PushBottom16 } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'




export const TaskAssignUserComponent = (
    { 
        abort, 
        dialog,
        onAssignUser
    }
) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    
    const {
        usersList, 
        branchId ='',
        damageId,
        task,
    } = dialog.payload

    const [selectedUser, setSelectedUser] = useState(null)
    const hasNoSelectedUser = isNull(selectedUser)

    const onLoadAssignees = useCallback((
        offset,
        limit,
        orderBy,
        direction,
        filterQuery
    ) => {
        dispatch(loadAssignees(
            dialog.name,
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ))
    },[dispatch, dialog.name])
  
    const handleSubmit = () => { 
        const userId = selectedUser.id
        const payload = { 
            assignTask: { userId },
            task: {
                ...task,
                assignee: {
                    id: userId,
                    firstName: selectedUser?.firstName || '',
                    lastName: selectedUser?.lastName || ''
                }
            }
        }
        const metaData = {
            damageId,
            taskId: task.id,
            navigate,
            location
        } 
        onAssignUser(
            dialog.name, 
            payload,
            metaData
        )
    }

    const handleAbort = () => {
        abort()
        setQuery(
            navigate, 
            location, 
            location.pathname, 
            [], 
            false
        )
    }

    return <React.Fragment>
        <DialogMainContent 
            flexDirection={'row'} 
            cssHeight='330px'
        >
            <FlexBox 
                flexDirection='column'
                width='100%'
            >
                <Paragraph>
                    {t('To assign this task to a user, select them from a list.')}
                </Paragraph>
                <PushBottom16 />
                <AssigneesTable
                    usersListLoading = {usersList?.loading || false}
                    usersListLoaded = {usersList?.loaded || false}
                    usersListTotal = {usersList?.total || 0}
                    usersList = {usersList?.users || []}
                    onLoadUsersList = {onLoadAssignees}
                    branchId = {branchId}
                    selectedElement = {selectedUser}
                    setSelectedElement = {setSelectedUser}
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={handleAbort}
            onSubmit={handleSubmit}
            submitText='Assign task to this user'
            disabled={hasNoSelectedUser}
        />
    </React.Fragment>
}