import React from 'react'
import { ROUTES } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import {DamageLicensePlate} from 'application/components/controls/damage_licenseplate'
import { FullName } from 'application/components/controls/full_name'
import {DateComponent} from 'application/components/controls/date'
import {CustomerNameDamage} from 'application/components/controls/customer_name_damage'
import { RepairAuthorizationIndicator } from 'application/components/controls/repair_authorization_indicator'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'
import { EditableDamageStatus } from 'application/components/controls/editable_damage_state'
import {theme} from 'application/style/theme'
import {DamageStatus} from 'application/components/controls/damage_status'



export const configuration = {
    //no pagination (hide=true) -> no tableName needed
    sorting: {
        sortParamKey: '',
        directionParamKey: '',
        defaultSortParamValue: '',
        defaultDirectionParamValue: ''
    },
    pagination: {

        hidePagination: true
    },
    columns: [
        {
            width: 15,
            label: 'License plate',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DamageLicensePlate damage={element}/>
        },
        {
            width: 20,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: (
                {
                    element, 
                    isSelected,
                    moIsActive,
                    showDetails
                }
            ) => moIsActive 
                ? <DamageStatus
                    status={element.state} 
                    isSelected={isSelected} 
                /> 
                :<EditableDamageStatus
                    element={element}
                    isSelected={isSelected}
                    showDetails={showDetails}
                />
        },
        {
            width: 20,
            label: 'Customer / owner',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <CustomerNameDamage damage={element} />
        },
        {
            width: 20,
            label: 'Assignee',
            secondLabel: 'Lawfirm',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FlexBox flexDirection='column'>
                <FullName person={element.assignee} />
                <LawFirmName lawFirm={element.lawFirm} />
            </FlexBox>
        },
        {
            width: 15,
            label: 'Creation date',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DateComponent 
                date={element.createdAt} 
            />,
            breakpoint: theme.breakpoint.xLarge
        },
        {
            width: 10,
            label: 'Authorization',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <RepairAuthorizationIndicator 
                repairAuthorization={element.repairAuthorization} 
            />,
            breakpoint: theme.breakpoint.large
        }
    ],
    ContextMenu: () => null,
    contextMenuWidth: 0,
    rows: {
        linked: {
            Component: null,
            additionalProps: {
                href: (element, applicationLinksHook) => applicationLinksHook.internalAccountLink(
                    ROUTES.DAMAGE_MANAGER_BASE + element.id + '/overview'
                )
            }
        },
        selectable: null,
        details: null
    },
    massOperations: {
        available: false
    },
    rowHeight: '36px'
}
