import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DamageShareWidgetComponent } from './component'

const mapStateToProps = state => ({
    accountId: state.auth.currentAccount.id
})

const mapDispatchToProps = dispatch => {
    return {
        onOpenSmartConnectDialog: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_SMART_CONNECT_EMAIL,
                true,
                payload,
                'Create smart connect email'
            )
        )
    }
}

export const DamageShareWidget = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageShareWidgetComponent)