import { useEffect } from 'react'
import { useMO } from './use_mo'


export const useMoCloseWatcher = (
    moName, 
    constraint
) => {

    const {
        moSetInactive
    } = useMO(moName)

    useEffect(
        () => { 
            moSetInactive()
        }, 
        [ 
            moSetInactive,
            constraint            
        ]
    )
}
