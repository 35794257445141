import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { DialogMainContent } from 'application/components/fragments/dialog' 
import { Paragraph } from 'application/components/fragments/typography'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Spacer,PushBottom32 } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import { InfoBox } from 'application/components/controls/info_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'


const DeleteCustomState =
(
    { 
        abort, 
        dialog, 
        onDeleteCustomState
    }
) => {
    const { 
        customStateName, 
        customStateId,
        hasDamages
    } = dialog.payload
        
    const {t} = useTranslation()

    return <React.Fragment>
        <DialogMainContent>
            {
                hasDamages
                    ? <InfoBox 
                        type={INFOBOX.TYPES.ALERT} 
                        direction='row' 
                        cssMargin='0' 
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <span>
                            {t('This custom state cannot be deleted because there are still some damage cases associated with it.')}
                        </span>
                    </InfoBox>
                    : <Paragraph cssPadding='16px 0'>
                        <Trans i18nKey='Are you sure you want to delete this state?'>
                            Do you want to delete <strong>{{customStateName}}</strong> ?
                        </Trans>
                    </Paragraph>
            }
            
            
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            submitText='Delete custom state'
            onSubmit={
                () => onDeleteCustomState(
                    dialog.name, 
                    customStateId, 
                    customStateName
                )
            }
            disabled={hasDamages}
        />
    </React.Fragment>

}

export const DeleteCustomStateComponent = withDialogDataLoader(DeleteCustomState) 