import React, {useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { TabsButtons } from 'application/components/building_blocks/tabs'
import { CustomerSearch } from 'application/components/controls/form/customer_search'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { ActionButton } from 'application/components/controls/action_button'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { FlexBox } from 'application/components/fragments/flex_box'
import { CustomerDataPartialForm } from 'application/components/forms/_partial_forms/customer_data_partial_form'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { exists } from 'application/common/data_helpers'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { emptyCustomer } from 'application/common/empty_customer'


export const CreateDamageCustomerStep = ({
    abort,
    setCustomerData,
    resetCustomerData,
    initialCustomerValues,
    goToNextStep,
    dialog,
    onDataChange = () => {},
    setApplyPreferredVehicleData,
    applyPreferredVehicleData,
    existingCustomerWasChanged,
    setExistingCustomerWasChanged,
    existingCustomer,
    setExistingCustomer
}) => {
    const {t} = useTranslation()
    const [existingCustomerValues, setExistingCustomerValues] = useState(initialCustomerValues)
    const [existingCustomerEditMode, setExistingCustomerEditMode] = useState(false)

    const {
        customerList = []
    } = dialog.payload

    const handleSetExistingCustomer = isSet => {
        setExistingCustomer(isSet)
        resetCustomerData(isSet)
        setExistingCustomerValues(null)
    }

    const defaultValues = {
        ...emptyCustomer,
        ...existingCustomerValues,
        customerId: existingCustomerValues?.id || '',
    }

    const customerFormHook = useStatefulForm({defaultValues})

    const {
        handleSubmit,
        getValues,
        register,
        setValue,
        errors,
        formStateValues,
        updateFormState,
        reset
    } = customerFormHook

    const setForm = () => {
        onDataChange()
        updateFormState()
    }

    const getCustomerValues = () => {
        if(existingCustomer && !existingCustomerWasChanged){
            return existingCustomerValues
        }
        return getValues() //take the values from the form for edited existing customer or new customer
    }

    const onSubmitSuccess = () => {
        setCustomerData({
            existingCustomer,
            customerId: getValues().customerId || null,
            values: getCustomerValues(),
            existingCustomerWasChanged
        })
        goToNextStep()
    }

    const hasPreferredVehicle = useMemo(() => {
        return exists(existingCustomerValues?.preferredVehicleInfo?.licensePlate)
            || exists(existingCustomerValues?.preferredVehicleInfo?.make)
            || exists(existingCustomerValues?.preferredVehicleInfo?.model)
            || exists(existingCustomerValues?.preferredVehicleInfo?.chassisNumber)
    }, [existingCustomerValues?.preferredVehicleInfo])

    const toggleExistingCustomerEditMode = () => {
        setExistingCustomerEditMode(!existingCustomerEditMode)
        setExistingCustomerWasChanged(true)
    }

    const resetDialogStep = isExistingCustomer => {
        handleSetExistingCustomer(isExistingCustomer)
        setExistingCustomerWasChanged(false)
        setExistingCustomerEditMode(false)
        reset(emptyCustomer)
    }

    const initialActiveTabIndex = useMemo(()=>{
        return existingCustomer ? 0 : 1
    },[existingCustomer])

    return <React.Fragment>
        <DialogMainContent>
            <SubHeadline>{t('Vehicle keeper / customer')}
                <TabsButtons
                    smallFont={true}
                    items={[
                        {
                            label: t('Existing customer'),
                            onItemClick: () => { 
                                resetDialogStep(true)
                            }
                        },
                        {
                            label: t('New customer'),
                            onItemClick: () => { 
                                resetDialogStep(false)
                            }
                        }
                    ]}
                    initialActiveTabIndex={initialActiveTabIndex}
                />
            </SubHeadline>

            {
                existingCustomer 
                    ? <React.Fragment>
                        {
                            !existingCustomerEditMode
                                ? <React.Fragment>
                                    <CustomerSearch 
                                        customerList={customerList}
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        errors={errors}
                                        stateForm={formStateValues}
                                        onDataChange={setForm}
                                        dialog={dialog}
                                        onChange={(customerId, customer) => {
                                            setExistingCustomerValues(customer)
                                            reset(customer) //update default values of form hook
                                        }}
                                        initialCustomerData={existingCustomerValues}
                                    />
                                    <FlexBox>
                                        {
                                            existingCustomerValues !== null && <IconButtonWithLabel
                                                iconKey='edit' 
                                                label={t('Edit this customer')} 
                                                onButtonClick={toggleExistingCustomerEditMode} 
                                            />
                                        }
                                        <Spacer />
                                        {
                                            hasPreferredVehicle && <PureCheckBox 
                                                label={t('Apply preferred vehicle data for this damage case')}
                                                checked={applyPreferredVehicleData}
                                                onChange={() => {setApplyPreferredVehicleData(!applyPreferredVehicleData)}}
                                            />
                                        }
                                    </FlexBox>
                                    <PushBottom16 />
                                </React.Fragment>
                                : <CustomerDataPartialForm  
                                    formHook={customerFormHook}
                                    onDataChange={onDataChange}
                                />
                        }
                    </React.Fragment>
                    :  <CustomerDataPartialForm 
                        formHook={customerFormHook}
                        onDataChange={onDataChange}
                    />
            }

        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Continue')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(false))} 
                disabled={existingCustomer && existingCustomerValues === null}
            />
        </StepsLayoutFooter>
    </React.Fragment>

}