import React from 'react'
import { Sidebar, Fragments } from '../_common'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { RoleName } from 'application/components/name_mappings/role_name'
import { PushBottom32 } from 'application/components/pages/_common'
import { RoleDescription } from './descriptions'




const RoleSidebarContent = withTheme(
    (
        {
            onSidebarClose, 
            roleData, 
            theme
        }
    ) => {
        const {t} = useTranslation()

        return <Sidebar 
            onSidebarClose={onSidebarClose} 
            title={t('Permission collection')} 
            sidebarTitleColor={theme.color.rolePrimary}
        >
            <Fragments.HeadlineSidebar>
                <RoleName roleName={roleData.name} />
            </Fragments.HeadlineSidebar>
            <Fragments.ContentSidebar>
                <PushBottom32>
                    <RoleDescription roleData={roleData} />
                </PushBottom32>
            </Fragments.ContentSidebar>
            <Fragments.FooterSidebar/>
        </Sidebar>
    })

export const RoleSidebarComponent = props => {
    if(!props.isVisible) {
        return null
    }
    return <RoleSidebarContent {...props} />
}