import { getFullName } from 'application/common/name_helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const MetaInfo = ({damage, account, branch}) => {
    const {t} = useTranslation()
    const branchAndAccountMatch = account.name === branch.name
    return <React.Fragment>
        &nbsp;&nbsp;
        {account.name}{!branchAndAccountMatch && <React.Fragment>&nbsp;({branch.name})</React.Fragment>},&nbsp;&nbsp;
        <strong>{t('Assignee')}:</strong>&nbsp;{getFullName(damage.assignee)}
    </React.Fragment>
}