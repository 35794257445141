
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'



export const TaskCommentCreatedDetails = (
    {
        activity
    }
) => {

    const {
        task,
        damage
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetail 
            label='Title' 
            value={task.title} 
        />
    </ActivityDetailWrapper>
}

TaskCommentCreatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}