import React from 'react'
import { Trans } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Paragraph } from 'application/components/fragments/typography'
import { withTheme } from 'styled-components'
import { getFullName } from 'application/common/name_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const RemoveUserFromGroup = ({ abort, dialog, onRemoveUser }) => {
    const {user, groupName, groupId} = dialog.payload
    const userName = getFullName(user)

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <Paragraph cssPadding='16px 0'>
                    <Trans i18nKey='Are you sure you want to remove this user from this group?'>
                    Are you sure you want to remove the user <strong>{{userName}}</strong> from the group <strong>{{groupName}}</strong>?
                    </Trans>
                </Paragraph>
         
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={() => onRemoveUser(dialog.name, user.id, groupId)}
        />
    </React.Fragment>

}

export const RemoveUserFromGroupComponent = withDialogDataLoader(
    withTheme(RemoveUserFromGroup)
)