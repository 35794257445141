import { connect } from 'react-redux'
import { DIALOG, WIDGET_CONTEXTS } from 'application/constants'
import {TaskDetailsWorkflowPanelEffects} from './effects'
// import { getUsersForTaskDebounced } from 'application/redux/actions/pages/damage_manager/tasks'
import { registerContext } from 'application/redux/actions/widget_contexts'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { getSelectedTaskWorkflowPanel, hideAttachmentDetailsWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { openDialogByName } from 'application/redux/actions/dialogs'

const mapStateToProps = (state, props) => {
    const selectedTaskLoaded = state.pages.workflowPanel.tasks.selectedTaskLoaded
    const contextLoaded = selectedTaskLoaded 
        ? state.widgetContexts.contexts.some(c => c.key === state.pages.workflowPanel.tasks.selectedTask.id)
        : false
    return {
        auth: state.auth,
        task: state.pages.workflowPanel.tasks.selectedTask,
        selectedTaskLoading: state.pages.workflowPanel.tasks.selectedTaskLoading,
        selectedTaskLoaded,
        attachments:[],// state.attachments.fullAttachments,
        contextLoaded,
        userList: state.pages.workflowPanel.tasks.selectedTaskUserList.users,
        userListLoading: state.pages.workflowPanel.tasks.selectedTaskUserListLoading,
        hasTaskList: state.pages.workflowPanel.tasks.tasksList.length > 0,
        damage: state.pages.workflowPanel.shared.damage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadTask: (taskId, sharedResourceId) => {
            dispatch(getSelectedTaskWorkflowPanel(taskId, sharedResourceId))
            dispatch(refreshWidgetsByContext(taskId))
            dispatch(refreshWidgetsByContext(WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR))
            dispatch(hideAttachmentDetailsWorkflowPanel())
        },
        onDeletetaskWorflowPanel: (task) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_TASK_WORKFLOW_PANEL, 
                true, 
                {task}, 
                'Delete task'
            )
        ),
        onEditTaskWorkflowPanel: (task, sharedResourceId, damage) => dispatch(openDialogByName(DIALOG.NAME.EDIT_TASK_WORKFLOW_PANEL, true, {sharedResourceId, damage, task}, 'Edit task')),
        onRegisterWidgetContext: (widgetContext, widgetList) => {
            dispatch(registerContext(widgetContext, widgetList))
        },
    }
}

export const TaskDetailsWorkflowPanel =  connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDetailsWorkflowPanelEffects)