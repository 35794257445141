
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ActivityDetailBox, ActivityDetailWrapper} from 'application/components/controls/activity_details/common'

export const DefaultDetails = (
    {
        activity
    }
) => {
    const {t} = useTranslation() 

    return <ActivityDetailWrapper>
        <ActivityDetailBox>
            {t('Details unknown') }
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DefaultDetails.propTypes = {
    activity: PropTypes.object.isRequired
}