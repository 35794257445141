import moment from 'moment'

const amountDayToTheDeadline = process.env.REACT_APP_AMOUNT_DAY_CLOSE_TO_THE_DEADLINE

const DiffDateCloseTheDeadline = (deadline) => {
    const today = moment(new Date())
    const theDeadline = moment(deadline)
    const dateDiff = theDeadline.diff(today, 'days')
    return dateDiff < amountDayToTheDeadline
}

const DiffDateOverTheDeadline = (deadline) => {
    const today = moment(new Date())
    const theDeadline = moment(deadline)
    const dateDiff = theDeadline.diff(today, 'days')
    return dateDiff < 0
}

export {
    DiffDateCloseTheDeadline,
    DiffDateOverTheDeadline
}