import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import { DeleteLawfirmComponent } from './component'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onDeleteLawfirm: (dialogName, damageId) => {
            dispatch(sendPayloadRequest(dialogName, null, { damageId }))
        },
    }
}

export const DeleteLawfirm = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteLawfirmComponent)