import React, { useEffect } from 'react'
import { InsuranceSelectComponent } from './component'
import { useDispatch } from 'react-redux'
import { getInsurancesRequest } from 'application/redux/actions/common'
import { INSURANCE_TYPE } from 'application/constants/insurances'
import { useSelector } from 'react-redux'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'

export const InsuranceSelect = props => {
    const { t } = useTranslation()
    const { insuranceType = INSURANCE_TYPE.INSURANCE } = props

    const insurance = useSelector(state => state?.common[insuranceType])
    const companies = insurance?.companies || []
    const companiesLoaded = insurance?.companiesLoaded || false
    const companiesLoading = insurance?.companiesLoading || false
    const shouldLoad = !companiesLoaded && !companiesLoading
    const dispatch = useDispatch()

    useEffect(() => {
        shouldLoad && dispatch(getInsurancesRequest(insuranceType))
    }, [dispatch, insuranceType, shouldLoad])

    if (companiesLoading) return <ThemedPreloader />

    if (companiesLoaded && companies.length === 0)
        return (
            <FlexBox 
                height='75px'
                alignItems="center" 
                justifyContent="center"
                cssRules={`
                    color: rgb(160, 160, 160);
                    font-size: 12px;
                    font-weight: 400;
                    font-size:12px;
                `}
            >
                <span>{t('No companies found')}</span>
            </FlexBox>
        )

    return (
        companiesLoaded &&
    companies.length > 0 && (
            <InsuranceSelectComponent
                {...props}
                insuranceType={insuranceType}
                companies={companies}
            />
        )
    )
}
