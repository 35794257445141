import { BRANCH_MANAGEMENT } from 'application/constants'
import { getConnectedEdges } from './get_connected_edges'

export const setSelectedEdgesStackingOrder = (elements, nodeId, edgeType = BRANCH_MANAGEMENT.ELEMENT_TYPE.EDGE_REMOVABLE) => {
    const connectedEdges = getConnectedEdges(elements, nodeId, edgeType, false)

    const otherElements = elements.filter(e => !connectedEdges.map(c => c.id).includes(e.id))

    return [...otherElements, ...connectedEdges]
}

export const setSingleEdgeStackingOrder = (elements, nodeId) => {
    const edge = elements.find(e => e.id === nodeId)
    const otherElements = elements.filter(e => e.id !== nodeId)

    return [...otherElements, edge]
}