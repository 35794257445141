
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetailBox, ActivityDetailWrapper, BranchDetail, ActivityDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'
import { DateComponent } from '@ec/ui-controls-react'





export const DamageIncidentDateChangedDetails = (
    {
        activity
    }
) => {

    const {
        oldDate = '',
        newDate = '',
        damage
    } = activity.data


    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetailBox>
            <DateComponent date={oldDate} />
            <RightArrow />
            <DateComponent date={newDate} />
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

DamageIncidentDateChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}