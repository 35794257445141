import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'

export const TaskSelectComponent = ({
    name='taskId',
    register,
    setValue,
    menuPlacement='bottom',
    tasksList = [],
    tasksLoading,
    onChange = () => {}
}) => {
    const {t} = useTranslation()
    if(tasksLoading){
        return <ThemedPreloader />
    }
    const noCategoryOption = {
        value: '',
        label: t('Without task')
    }
    const taskOptions = tasksList.map(task => ({
        value: task.id,
        label: task.title
    }))

    const options = [noCategoryOption, ...taskOptions]
    const initialOption = options[0]

    return <Select 
        label={t('Task')}
        name={name}
        register={register}
        setValue={setValue}
        options={options}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        maxMenuHeight={200}
    />
}

TaskSelectComponent.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
    tasksList: PropTypes.array,
    tasksLoading: PropTypes.bool.isRequired
}