import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* editCategorySaga(action) {
    try {
        yield resourceCall(
            api.gateway.categories.updateCategory, 
            {
                categoryId: action.metaData.categoryId
            },
            {
                name: action.payload.categoryName
            }
        )
        yield put(showToast('Category saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}