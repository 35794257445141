import { DIALOG } from 'application/constants'
import { dialogDeleteAccountlogoSaga } from './account'
import { assignPreferredBranchSaga } from './assign_preferred_branch'
import { changeCurrentBranchFromDialogSaga } from './branches/change'
import { createBranchSaga } from './branches/create'
import { deleteBranchFromDialogSaga } from './branches/delete'
import { editBranchSaga } from './branches/edit'
import { dialogCreateUserSaga } from './create_user/create_user_saga'
import { addCategorySaga } from './dialog_add_category_saga'
import { addCustomStateSaga } from './dialog_add_custom_state_saga'
import { dialogCancelPaymentLawyerCommunicationSaga } from './dialog_cancel_payment_lawyer_communication'
import { dialogCommissionDekraExpertSaga } from './dialog_commission_dekra_expert'
import { dialogCommissionOtherExpertSaga } from './dialog_commission_other_expert'
import { dialogCreateCaseNotesSaga } from './dialog_create_case_notes'
import { dialogCreateCostCoverageSaga } from './dialog_create_cost_coverage_saga'
import { dialogCreateCustomerSaga } from './dialog_create_customer_saga'
import { dialogCreateDamageKickstartSaga } from './dialog_create_damage_kickstart_saga'
import { dialogCreateDamageSaga } from './dialog_create_damage_saga'
import { dialogCreateInvoiceLawyerCommunicationSaga } from './dialog_create_invoice_lawyer_communication'
import { dialogCommisionLawfirmSaga } from './dialog_create_lawyers'
import { dialogCreateMessageWorkflowPanelSaga } from './dialog_create_message_workflow_panel'
import { dialogCreatePaymentSaga } from './dialog_create_payment'
import { dialogCreateSmartConnectEmailSaga } from './dialog_create_smart_connect_email'
import { dialogCreateTaskSaga } from './dialog_create_task_saga'
import { dialogCreateTaskWorkflowPanelSaga } from './dialog_create_task_saga_workflow_panel'
import { damageSwitchCustomerSaga } from './dialog_damage_switch_customer_sage'
import { dialogDefaultLoadingSaga } from './dialog_default_saga'
import { dialogDeleteAttachmentSaga } from './dialog_delete_attachment_saga'
import { dialogDeleteAttachmentWorkflowPanelSaga } from './dialog_delete_attachment_workflow_panel_saga'
import { deleteCategorySaga } from './dialog_delete_category_saga'
import { deleteCustomStateSaga } from './dialog_delete_custom_state_saga'
import { dialogDeleteCustomerSaga } from './dialog_delete_customer_saga'
import { dialogDeleteDamageSaga } from './dialog_delete_damage_saga'
import { dialogDeleteInvoiceSaga } from './dialog_delete_invoice'
import { dialogDeleteInvoiceLawyerCommunicationSaga } from './dialog_delete_invoice_lawyer_communication'
import { dialogDeleteMessageSaga } from './dialog_delete_message_saga'
import { dialogDeleteTaskDamageSaga } from './dialog_delete_task_saga'
import { dialogDeleteTaskWorkflowPanelSaga } from './dialog_delete_task_workflow_panel_saga'
import { dialogDeleteUserSaga } from './dialog_delete_user_saga'
import { editCategorySaga } from './dialog_edit_category_saga'
import { editCustomStateSaga } from './dialog_edit_custom_state_saga'
import { dialogEditCustomerFromDialogSaga } from './dialog_edit_customer_from_dialog_saga'
import { dialogEditCustomerSaga } from './dialog_edit_customer_saga'
import { dialogEditTaskSaga } from './dialog_edit_task_saga'
import { dialogEditTaskWorkflowPanelSaga } from './dialog_edit_task_workflow_panel_saga'
import { dialogEditUserNotificationSettingsSaga } from './dialog_edit_user_notification_settings_saga'
import { dialogEditUserSaga } from './dialog_edit_user_saga'
import { dialogFetchDamagesByCustomerSaga } from './dialog_fetch_damages_by_customer_saga'
import { dialogAdminUsersSaga } from './dialog_get_admin_users'
import { dialogGetCostCoverageData } from './dialog_get_cost_coverage_saga'
import { dialogGetCustomerSaga } from './dialog_get_customer_saga'
import { dialogGetDamageInvoicesSaga } from './dialog_get_damage_invoices_saga'
import { dialogGetDamagesByBranchSaga } from './dialog_get_damages_by_branch_saga'
import { dialogGetDataForDekraExpertCommissionSaga } from './dialog_get_data_for_dekra_expert_commission'
import { dialogGetDataForOtherExpertCommissionSaga } from './dialog_get_data_for_other_expert_commission'
import { dialogGetUserNotificationSettingsSaga } from './dialog_get_user_notification_settings_saga'
import { dialogReopenTaskSaga } from './dialog_reopen_task_saga'
import { dialogUpdateUserGroupsSaga } from './dialog_update_user_groups_saga'
import { dialogUploadFilesForTaskSaga } from './dialog_upload_files_for_task_saga'
import { dialogUploadFilesSaga } from './dialog_upload_files_saga'
import { dialogUploadFilesWorkflowPanelSaga } from './dialog_upload_files_workflow_panel_saga'
import { dialogWriteBackToLastDialogSaga } from './dialog_write_back_to_last_dialog_saga'
import { dialogExportToLawfirmFromDocumentSidebarSaga } from './export_to_lawfirm/dialog_export_to_lawfirm_from_document_sidebar'
import { dialogReExportToLawfirmFromDocumentSidebarSaga } from './export_to_lawfirm/dialog_re_export_to_lawfirm_from_document_sidebar'
import { addUsersToGroupSaga } from './groups/dialog_add_users_to_group_saga'
import { createGroupSaga } from './groups/dialog_create_group'
import { deleteGroupSaga } from './groups/dialog_delete_group'
import { dialogDeleteGroupGetMembersSaga } from './groups/dialog_delete_group_get_members_saga'
import { editGroupSaga } from './groups/dialog_edit_group'
import { removeUserFromGroupSaga } from './groups/dialog_remove_user_from_group_saga'
import { dialogMoDamagesChangeStateSaga } from './mo/damages/damages_change_status_saga'
import { dialogMoDamagesDeleteSaga } from './mo/damages/damages_delete_saga'
import { dialogMoDocumentsChangeCategorySaga } from './mo/documents/dialog_mo_documents_change_category_saga'
import { dialogMoDocumentsDeleteSaga } from './mo/documents/dialog_mo_documents_delete_saga'
import { dialogMoDocumentsIsExportedToLawfirmSaga } from './mo/documents/dialog_mo_documents_is_exported_to_lawfirm_saga'
import { dialogMoTasksDeleteSaga } from './mo/tasks/delete'
import { dialogCreateCustomerQRCodeSaga } from './qr_code/dialog_create_customer_qr_code'


//post processors
import * as postProcessors from 'application/redux/saga/sagas/post_processors'
import { updateComprehensiveDataSaga, updateLiabilityInsuranceAndClaimSaga } from '../pages/damage_manager/shared/damage'
import { createComprehensiveDamageTypeSaga } from './dialog_create_comprehensive_damage_type_saga'
import { dialogCreateDamageKickstartCommissionSaga } from './dialog_create_damage_kickstart_commission_saga'
import { dialogCreatePowerOfAttorneySaga } from './dialog_create_power_of_attorney_saga'
import { deleteComprehensiveDamageTypeSaga } from './dialog_delete_comprehensive_damage_type_saga'
import { dialogDeleteLawfirmSaga } from './dialog_delete_lawfirm_saga'
import { editComprehensiveDamageTypeSaga } from './dialog_edit_comprehensive_damage_type_saga'
import { dialogFetchDamagesByComprehensiveDamageTypeSaga } from './dialog_fetch_damages_by_comprehensive_damage_type_saga'
import { dialogFetchDamagesByCustomStateSaga } from './dialog_fetch_damages_by_custom_state_saga'
import { dialogGetBranchKickstartResourceSaga } from './dialog_get_branch_kickstart_resource'
import { dialogGetDeclarationOfAsssigmentDataSaga } from './dialog_get_declaration_of_assigment_data_saga'
import { dialogGetInitialExpertsSaga } from './dialog_get_initial_experts_saga'
import { dialogGetLawfirmCommissionStateSaga } from './dialog_get_lawfirm_commission_state_saga'
import { dialogGetLawfirmsSaga } from './dialog_get_lawfirms_saga'
import { dialogGetPowerOfAttorneyDataSaga } from './dialog_get_power_of_attorney_data_saga'
import { dialogOpenDialogSaga } from './dialog_open_dialog_saga'
import { dialogAssignTaskSaga } from './task/dialog_assign_task'
import { dialogCreateDamageKickstartCommissionDekraSaga } from 'application/redux/saga/sagas/dialogs/dialog_create_damage_kickstart_commission_dekra_saga'








export const getDialogDataSagas = (name) => {
    switch (name) {
    case DIALOG.NAME.DAMAGE_INVOICES:
        return [dialogGetDamageInvoicesSaga]
    case DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST:
    case DIALOG.NAME.DELETE_USER:
        return [dialogAdminUsersSaga]
    case DIALOG.NAME.COMISSION_LAWFIRM:
        return [dialogGetLawfirmsSaga]
    case DIALOG.NAME.CREATE_DAMAGE_KICKSTART:
        return [
            dialogGetInitialExpertsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP: 
        return [dialogDeleteGroupGetMembersSaga]
    case DIALOG.NAME.DELETE_CUSTOMER:
        return [dialogFetchDamagesByCustomerSaga]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH:
        return [dialogGetDamagesByBranchSaga]
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER:
        return [dialogGetDataForDekraExpertCommissionSaga]
    case DIALOG.NAME.COMMISSION_OTHER_EXPERT:
        return [
            dialogGetInitialExpertsSaga,
            dialogGetDataForOtherExpertCommissionSaga,
            dialogGetDeclarationOfAsssigmentDataSaga
        ]
    case DIALOG.NAME.USER_NOTIFICATION_SETTINGS:
        return [dialogGetUserNotificationSettingsSaga]
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE:
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER:
        return [dialogGetCustomerSaga]
    case DIALOG.NAME.CREATE_COST_COVERAGE:
        return [dialogGetCostCoverageData]
    case DIALOG.NAME.CREATE_BRANCH_QR_CODE:
        return [dialogGetBranchKickstartResourceSaga]
    case DIALOG.NAME.DELETE_CUSTOM_STATE:
        return [dialogFetchDamagesByCustomStateSaga]
    case DIALOG.NAME.CREATE_POWER_OF_ATTORNEY:
        return [dialogGetPowerOfAttorneyDataSaga]
    case DIALOG.NAME.DELETE_LAWFIRM:
        return [dialogGetLawfirmCommissionStateSaga]
    case DIALOG.NAME.DELETE_COMPREHENSIVE_DAMAGE_TYPE:
        return [dialogFetchDamagesByComprehensiveDamageTypeSaga]
    default:
        return [dialogDefaultLoadingSaga]
    }
}




export const sendDialogDataSagas = (name) => {
    switch (name) {
    case DIALOG.NAME.CREATE_DAMAGE:
        return [
            dialogCreateDamageSaga,
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets
        ]

    case DIALOG.NAME.CREATE_DAMAGE_AND_CLOSE_DIALOG:
        return [
            dialogCreateDamageSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_KICKSTART_AND_CLOSE_DIALOG:
        return [
            dialogCreateDamageKickstartSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_KICKSTART:
        return [
            dialogCreateDamageKickstartCommissionSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_KICKSTART_AND_COMMISSION_DEKRA:
        return [
            dialogCreateDamageKickstartCommissionDekraSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
        ]
    case DIALOG.NAME.CREATE_DAMAGE_UPDATE_INSURANCE_DATA:
        return [
            updateComprehensiveDataSaga,
            updateLiabilityInsuranceAndClaimSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_MESSAGE:
        return [
            dialogDeleteMessageSaga,
            postProcessors.refreshMessageList,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_DAMAGE:
        return [
            dialogDeleteDamageSaga,
            postProcessors.refreshDamageTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_USER:
        return [
            dialogCreateUserSaga,
            postProcessors.refreshUsersTablesSaga,
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.ASSIGN_PREFERRED_BRANCH:
        return [
            assignPreferredBranchSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_USER:
        return [
            dialogDeleteUserSaga,
            postProcessors.refreshUsersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CURRENT_USER:
        return [
            dialogEditUserSaga,
            postProcessors.syncCurrentUserSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_USER_FROM_LIST:
        return [
            dialogEditUserSaga,
            postProcessors.refreshUsersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_UPLOAD:
        return [
            dialogUploadFilesSaga,
            postProcessors.resetDamageManagerDocumentCenterSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_UPLOAD_WORKFLOW_PANEL:
        return [
            dialogUploadFilesWorkflowPanelSaga,
            postProcessors.refreshWorkflowPanelSelectedTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_SELECT_FROM_TASK_WIDGET:
        return [
            dialogUploadFilesForTaskSaga,
            postProcessors.refreshDamageManagerSelectedTaskSaga,
            postProcessors.refreshDamageManagerTaskWidgetsSaga,
            postProcessors.resetDamageManagerDocumentCenterSaga,
            postProcessors.refreshDamageManagerTasksSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_UPLOAD_FROM_DOCUMENT_CENTER:
        return [
            dialogUploadFilesSaga,
            postProcessors.refreshDamageManagerDocumentCenterCategorySaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.ADD_DOCUMENTS_TO_INVOICE:
        return [
            dialogUploadFilesSaga,
            postProcessors.refreshInvoices,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.refreshDamageManagerDocumentCenterSaga,
            postProcessors.closeAllDialogsSaga,
            dialogOpenDialogSaga
        ]
    case DIALOG.NAME.CREATE_TASK_FROM_DAMAGE_MANAGER:
        return [
            dialogCreateTaskSaga,
            postProcessors.refreshDamageManagerTasksSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.COMISSION_LAWFIRM:
        return [
            dialogEditCustomerSaga,
            dialogCommisionLawfirmSaga,
            postProcessors.refreshDamageCommisionedLawfirmSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT_FROM_DAMAGE_MANAGER:
        return [
            dialogCommissionDekraExpertSaga,
            postProcessors.refreshDamageCommisionedExpertSaga,
            postProcessors.refreshDamageManagerOverviewSaga,
            postProcessors.refreshRepairScheduleWidget,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.COMMISSION_OTHER_EXPERT:
        return [
            dialogCommissionOtherExpertSaga,
            postProcessors.refreshDamageManagerDocumentCenterCategorySaga,
            postProcessors.refreshDamageCommisionedExpertSaga,
            postProcessors.refreshRepairScheduleWidget,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_TASK:
        return [
            dialogDeleteTaskDamageSaga,
            postProcessors.refreshTasksTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_TASK_FROM_DAMAGE_MANAGER:
        return [
            dialogDeleteTaskDamageSaga,
            postProcessors.removeTaskFromTaskList,
            postProcessors.removeSelectedTask,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_CASE_NOTES:
    case DIALOG.NAME.EDIT_CASE_NOTES:
        return [
            dialogCreateCaseNotesSaga,
            postProcessors.refreshDamageManagerOverviewSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_ATTACHMENT:
        return [
            dialogDeleteAttachmentSaga,
            postProcessors.refreshDamageManagerDocumentCenterCategorySaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_ATTACHMENT_WORKFLOW_PANEL:
        return [
            dialogDeleteAttachmentWorkflowPanelSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_TASK_FROM_WORKFLOW_PANEL:
        return [
            dialogCreateTaskWorkflowPanelSaga,
            postProcessors.refreshWorkflowPanelTasksSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_TASK_WORKFLOW_PANEL:
        return [
            dialogDeleteTaskWorkflowPanelSaga,
            postProcessors.removeTaskFromTaskListWorkflowPanel,
            postProcessors.removeSelectedTaskWorkflowPanel,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_TASK_WORKFLOW_PANEL:
        return [
            dialogEditTaskWorkflowPanelSaga,
            postProcessors.refreshWorkflowPanelSelectedTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_INVOICE_WORKFLOW_PANEL:
        return [
            dialogCreateInvoiceLawyerCommunicationSaga,
            dialogUploadFilesSaga,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_INVOICE_LAWYER_COMMUNICATION:
        return [
            dialogCreateInvoiceLawyerCommunicationSaga,
            dialogUploadFilesSaga,
            postProcessors.refreshInvoices,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CANCEL_PAYMENT_WORKFLOW_PANEL:
        return [
            dialogCancelPaymentLawyerCommunicationSaga,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CANCEL_PAYMENT_LAWYER_COMMUNICATION:
        return [
            dialogCancelPaymentLawyerCommunicationSaga,
            postProcessors.refreshInvoices,
            postProcessors.closeAllDialogsSaga,
            dialogOpenDialogSaga
        ]
    case DIALOG.NAME.CREATE_PAYMENT_WORKFLOW_PANEL:
        return [
            dialogCreatePaymentSaga,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_PAYMENT_LAWYER_COMMUNICATION:
        return [
            dialogCreatePaymentSaga, 
            postProcessors.refreshInvoices,
            postProcessors.refreshDamageManagerTasksSaga,
            postProcessors.closeAllDialogsSaga,
            dialogOpenDialogSaga
        ]
    case DIALOG.NAME.DELETE_INVOICE_WORKFLOW_PANEL:
        return [
            dialogDeleteInvoiceSaga,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_INVOICE_LAWYER_COMMUNICATION:
        return [
            dialogDeleteInvoiceLawyerCommunicationSaga,
            postProcessors.refreshInvoices,
            postProcessors.closeAllDialogsSaga,
            dialogOpenDialogSaga
        ]
    case DIALOG.NAME.EDIT_TASK:
        return [
            dialogEditTaskSaga,
            postProcessors.refreshTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_MESSAGE_WORKFLOW_PANEL:
        return [
            dialogCreateMessageWorkflowPanelSaga,
            postProcessors.refreshWorkflowPanelMessagesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_MESSAGE_FOR_LAWFIRM:
        return [
            dialogCreateMessageWorkflowPanelSaga,
            postProcessors.refreshDamageManagerMessageList,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOMER:
        return [
            dialogEditCustomerSaga,
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_CUSTOMER:
        return [
            dialogCreateCustomerSaga,
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOMER_FROM_DIALOG:
        return [
            dialogEditCustomerFromDialogSaga,
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOMER_FROM_WIDGET:
        return [
            dialogEditCustomerSaga,
            postProcessors.refreshDamageCustomerWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_CUSTOMER:
        return [
            dialogDeleteCustomerSaga,
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DOCUMENTS_DELETE:
        return [
            dialogMoDocumentsDeleteSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DOCUMENTS_CHANGE_CATEGORY:
        return [
            dialogMoDocumentsChangeCategorySaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DOCUMENTS_IS_EXPORTED_TO_LAWFIRM:
        return [
            dialogMoDocumentsIsExportedToLawfirmSaga,
            postProcessors.refreshDamageManagerDocumentCenterSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH:
        return [
            changeCurrentBranchFromDialogSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.ADD_DOCUMENT_CATEGORY:
        return [
            addCategorySaga,
            postProcessors.refreshDocumentCategoriesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_DOCUMENT_CATEGORY:
        return [
            deleteCategorySaga,
            postProcessors.refreshDocumentCategoriesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_DOCUMENT_CATEGORY:
        return [
            editCategorySaga,
            postProcessors.refreshDocumentCategoriesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.ADD_CUSTOM_STATE:
        return [
            addCustomStateSaga,
            postProcessors.refreshCustomStatesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOM_STATE:
        return [
            editCustomStateSaga,
            postProcessors.refreshCustomStatesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_CUSTOM_STATE:
        return [
            deleteCustomStateSaga,
            postProcessors.refreshCustomStatesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_BRANCH:
        return [
            editBranchSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_BRANCH:
        return [
            createBranchSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DAMAGES_DELETE:
        return [
            dialogMoDamagesDeleteSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshDamageTablesSaga
        ]
    case DIALOG.NAME.MO_DAMAGES_CHANGE_STATE:
        return [
            dialogMoDamagesChangeStateSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshDamageTablesSaga
        ]
    case DIALOG.NAME.MO_TASKS_DELETE:
        return [
            dialogMoTasksDeleteSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshTasksTablesSaga
        ]
    case DIALOG.NAME.SWITCH_DAMAGE_CUSTOMER:
        return [
            damageSwitchCustomerSaga,
            postProcessors.refreshDamageCustomerWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH:
        return [
            deleteBranchFromDialogSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_USERS_TO_GROUP:
        return [
            addUsersToGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshGroupTablesSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_GROUP:
        return [
            createGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_EDIT_GROUP:
        return [
            editGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]

    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP:
        return [
            deleteGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_USER_FROM_GROUP:
        return [
            removeUserFromGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshGroupTablesSaga
        ]
    case DIALOG.NAME.REOPEN_TASK:
        return [
            dialogReopenTaskSaga,
            postProcessors.refreshTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST:
        return [
            dialogUpdateUserGroupsSaga,
            postProcessors.refreshUsersTablesSaga,
            postProcessors.refreshGroupTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.WRITE_BACK_TO_LAST_DIALOG: //Change to write back to last dialog
        return [
            dialogWriteBackToLastDialogSaga,
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR:
        return [
            dialogExportToLawfirmFromDocumentSidebarSaga,
            postProcessors.refreshDamageManagerSelectedTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.RE_EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR:
        return [
            dialogReExportToLawfirmFromDocumentSidebarSaga,
            postProcessors.refreshDamageManagerSelectedTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.USER_NOTIFICATION_SETTINGS:
        return [
            dialogEditUserNotificationSettingsSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE:
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE_FROM_DAMAGE_MANAGER:
        return [dialogCreateCustomerQRCodeSaga]
    case DIALOG.NAME.CREATE_SMART_CONNECT_EMAIL:
        return [
            dialogCreateSmartConnectEmailSaga,
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.CREATE_COST_COVERAGE:
        return [
            dialogCreateCostCoverageSaga,
            postProcessors.resetDamageManagerDocumentCenterSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_POWER_OF_ATTORNEY:
        return [
            dialogCreatePowerOfAttorneySaga,
            postProcessors.resetDamageManagerDocumentCenterSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_ACCOUNTLOGO:
        return [
            dialogDeleteAccountlogoSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.TASK_ASSIGN_USER:
        return [
            dialogAssignTaskSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.removeQueryFromURLSaga
        ]
    case DIALOG.NAME.DELETE_LAWFIRM:
        return [
            dialogDeleteLawfirmSaga,
            postProcessors.refreshDamageCommisionedLawfirmSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.ADD_COMPREHENSIVE_DAMAGE_TYPE:
        return [
            createComprehensiveDamageTypeSaga,
            postProcessors.refreshComprehensiveDamageTypesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_COMPREHENSIVE_DAMAGE_TYPE:
        return [
            editComprehensiveDamageTypeSaga,
            postProcessors.refreshComprehensiveDamageTypesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_COMPREHENSIVE_DAMAGE_TYPE:
        return [
            deleteComprehensiveDamageTypeSaga,
            postProcessors.refreshComprehensiveDamageTypesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    default:
        return []
    }
}