import { populateCurrentAccountLogo, resetCurrentAccountLogo } from 'application/redux/actions/account_logo'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS, NOTIFICATION } from 'application/constants'



function* requestCurrentAccountlogoByAccountIdSaga(action) {
    const {accountId} = action
    const accountResponse = yield resourceCall(
        api.gateway.account.getAccountById,
        { accountId }
    )

    try {
        const {accountId} = action
        const accountResponse = yield resourceCall(
            api.gateway.account.getAccountById,
            { accountId }
        )
        const {
            hasLogo = false
        } = accountResponse

        let blobUrl = null

        yield put(
            populateCurrentAccountLogo(
                null,
                accountId,
                accountResponse.name
            )
        )

        if (hasLogo) {
            const accountLogoResponse = yield resourceCall(
                api.gateway.account.getAccountLogoByAccountId,
                { accountId }
            )       
            blobUrl = window.URL.createObjectURL(accountLogoResponse)

            yield put(
                populateCurrentAccountLogo(
                    blobUrl,
                    accountId,
                    accountResponse.name
                )
            )
        }
    } catch (e) {
        yield put(
            populateCurrentAccountLogo(
                null,
                accountId,
                accountResponse.name
            )
        )
    }
}






function* uploadCurrentAccountLogoSaga(action){
    try {
        yield resourceCall(
            api.gateway.account.updateAccountLogo, 
            {},
            { file: action.file },
        )
        yield put(
            resetCurrentAccountLogo()
        )
        yield put(
            showToast('Accountlogo successfully uploaded')
        )
    } catch(e) {
        yield put(
            showToast(
                'Accountlogo could not be uploaded',
                NOTIFICATION.LEVEL.WARNING
            )
        )
    }

}


export function* accountLogoWatcher() {
    yield takeLeading(
        ACTIONS.ACCOUNT_LOGO_REQUEST_CURRENT_ACCOUNT_LOGO_BY_ACCOUNT_ID, 
        requestCurrentAccountlogoByAccountIdSaga
    )
    yield takeLeading(
        ACTIONS.ACCOUNT_LOGO_UPLOAD_CURRENT_ACCOUNT_LOGO, 
        uploadCurrentAccountLogoSaga
    )
}
