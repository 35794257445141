import React from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import styled from 'styled-components'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'


export const StyleButtonToggle = styled.button`
    background: transparent;
    color: ${props => props.theme.color.black};
    cursor: ${ props => props.disabled ? 'auto' : 'pointer'};
    padding: 8px 0px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-size:${props => props.theme.fontSize.standard};
    font-weight:${props => props.theme.fontWeight.headLine3};

    span {
        margin-right: 8px;
        font-weight:${props => props.theme.fontWeight.headLine3};
    }
`


const ToggleButtonWithLabelComponent = ( 
    {
        theme,
        toggle,
        active = false,
        label = '',
        disabled = false
    }
) => {
    const { t } = useTranslation()

    const handleToggle = () => {
        !disabled && toggle(!active)
    }

    return <StyleButtonToggle 
        onClick={handleToggle}
        disabled={disabled}
    >
        <span>
            {t(label)}
        </span> 
        {
            !disabled && <React.Fragment>
                {
                    !active 
                        ? <FontIcon 
                            icon='chevron_right' 
                            fontSize={theme.fontSize.medium} 
                            color={theme.color.gray70} 
                        />
                        : <FontIcon 
                            icon='expand_more' 
                            fontSize={theme.fontSize.medium} 
                            color={theme.color.gray70} 
                        />
                }
            </React.Fragment>
        }



    </StyleButtonToggle>
}


ToggleButtonWithLabelComponent.propTypes = {
    theme: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    active: PropTypes.bool,
    label: PropTypes.string
}


export const ToggleButtonWithLabel = withTheme(ToggleButtonWithLabelComponent)