import { connect } from 'react-redux'
import {DamageManagerDocumentsTabbarButtonsC} from './component'
import { downloadDamageDocuments } from 'application/redux/actions/attachments'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'



const mapStateToProps = state => {
    const damage = state.pages.damageManager.shared.damage

    return {
        documentCenterArchiveDownloading: state.pages.damageManager.documentCenter.archiveDownloading,
        damageId: damage.id,
        customerId: damage.vehicle.keeper.customerId,
        hasLawfirm: state.pages.damageManager.shared.hasLawfirm
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDownloadDamageDocuments: (damageId) => dispatch(
            downloadDamageDocuments(
                damageId, 
                `damage-${damageId}.zip`
            )
        ),
        onCreatePowerOfAttorney: (payload) => {
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CREATE_POWER_OF_ATTORNEY, 
                    false, 
                    payload, 
                    'Create power of attorney'
                )
            )
        }
    }
}

export const DamageManagerDocumentsTabbarButtons = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDocumentsTabbarButtonsC)