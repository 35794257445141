import { put, select } from 'redux-saga/effects'
import { api } from 'application/api'
import { workflowPanelSelectedTask } from 'application/redux/selectors'
import { deleteAttachment } from 'application/redux/actions/attachments'
import { showToast } from 'application/redux/actions/notifications'
import { getSelectedTaskWorkflowPanel, hideAttachmentDetailsWorkflowPanel, minusAttachmentsToTaskInListWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { hideAttachmentDetailsDCWorkflowPanel, removeAttachmentFromDocumentCenterWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/documents'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogDeleteAttachmentWorkflowPanelSaga(action) {
    const { payload } = action
    const { sharedResourceId } = payload.match.params
    const selectedTask = yield select(workflowPanelSelectedTask)
    yield put(showWaitingScreen('Deleting document'))
    yield resourceCall(
        api.gateway.attachments.deleteAttachment, 
        {
            attachmentId: payload.attachmentId
        }
    )
    yield put(hideAttachmentDetailsWorkflowPanel())
    yield put(hideAttachmentDetailsDCWorkflowPanel())
    yield put(removeAttachmentFromDocumentCenterWorkflowPanel(payload.attachmentId))
    if(payload.taskId !== null && selectedTask !== null && selectedTask.id === payload.taskId){
        yield put(getSelectedTaskWorkflowPanel(payload.taskId, sharedResourceId))
    }    
    yield put(deleteAttachment(payload.attachmentId))
    yield put(minusAttachmentsToTaskInListWorkflowPanel(payload.taskId))
    yield put(showToast('Document deleted'))
    yield put(hideWaitingScreen())
}