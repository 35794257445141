import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box'
import styled from 'styled-components'

const StyledContractorListElement = styled.div`
    background-color: ${props => props.theme.color.gray10};
    display: flex;
    padding: 8px;
    margin-bottom: 8px;
    cursor: pointer;
`

export const ContractorListElement = ({
    contractor,
    isSelected,
    onChange
}) => {
    
    return <StyledContractorListElement onClick={()=>{
        onChange(contractor.id)
    }}>
        <PureCheckBox 
            label={contractor.name} 
            checked={isSelected} 
        />
    </StyledContractorListElement>
}