import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'

import { apiCall } from 'application/api/helpers'

export const getAccountSettings = (params) => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder('uisettings', HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/uisettings`,
                params.pin,
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
}