import styled from 'styled-components'

export const StyledMapContainer = styled.div`
    width: ${props => props.mapWidth};
    height: ${props => props.mapHeight};
`

export const StyledMapPreloader = styled.div`
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.5);
`

export const StyledLinkBox = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    height: 20px;
`

export const StyledGeoCodingError = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthraciteLight} !important;
    text-align: center;
`