import React from 'react'
import { useTranslation } from 'react-i18next'
import * as filters from 'application/filters'

export const mapFilterName = (filterName) => {
    const objectKey = Object
        .keys(filters.damages)
        .find(key => filters.damages[key].filterKey === filterName)
        
    return filters.damages[objectKey].label
}

export const SearchComboBoxFilterName = ({filterName}) => {
    const {t} = useTranslation()
    
    return <React.Fragment>
        {t(mapFilterName(filterName))}
    </React.Fragment>
}