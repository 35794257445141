import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogDeleteCustomerSaga(action) {
    yield put(showWaitingScreen('Deleting customer'))
    yield resourceCall(
        api.gateway.customer.deleteCustomer, 
        {
            customerId: action.payload.customerId
        }
    )    
    yield put(showToast(action.payload.successMsg))
    yield put(hideWaitingScreen())
}