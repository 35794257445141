import { connect } from 'react-redux'

import {AdministrationSettingsCategoriesC} from './component'

const mapStateToProps = state => {
    const {
        categories,
        categoriesLoaded,
        categoriesLoading
    } = state.common

    return {
        categories,
        categoriesLoaded,
        categoriesLoading
    }
}

export const AdministrationSettingsCategories =  connect(
    mapStateToProps
)(AdministrationSettingsCategoriesC)