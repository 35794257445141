
import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailBox, ActivityDetailWrapper, BranchDetail } from 'application/components/controls/activity_details/common'
import { RightArrow } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'


export const TaskStateChangedDetails = (
    {
        activity
    }
) => {
    const {t} = useTranslation()

    const {
        damage,
        oldState = '',
        newState,
        task
    } = activity.data

    return <ActivityDetailWrapper>
        <BranchDetail 
            activity={activity} 
        />
        <ActivityDetail 
            label='Licenseplate' 
            value={damage.licensePlate} 
        />
        <ActivityDetail 
            label='Title' 
            value={task.title} 
        />
        <ActivityDetailBox>
            {t(oldState)}<RightArrow/>{t(newState)}   
        </ActivityDetailBox>
    </ActivityDetailWrapper>
}

TaskStateChangedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}