import { getDamagesByParams, clearResults } from 'application/redux/actions/damages_search'
import {DamageSearchComponent} from './component'
import { connect } from 'react-redux'


const mapStateToProps = (state) => ({
    damagesList: state.damagesSearch?.results.list,
    damagesListTotal: state.damagesSearch.results.total,
    damagesListLoading: state.damagesSearch.loading,
    damagesListLoaded: state.damagesSearch.loaded
})


const mapDispatchToProps = dispatch => {
    const lastEditedDamageParams = {
        offset: 0, 
        limit: 5, 
        orderBy: 'modifiedat',
        direction: 'desc',
    }

    return {
        searchDamages: (filterQuery) =>  dispatch(
            getDamagesByParams(
                {
                    ...lastEditedDamageParams,
                    filterQuery
                } 
            )
        ),
        loadInitialDamages: () => dispatch(
            getDamagesByParams(
                {
                    ...lastEditedDamageParams,
                    filterQuery: []
                }
            )
        ),
        clearResults: () => dispatch(
            clearResults()
        )
    }
}





export const DamagesSearch = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageSearchComponent)