import { ROUTES } from 'application/constants'
import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { DamageManagerMessageDetailsWidgetComponent } from './component'
import { useTranslation } from 'react-i18next'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { exists } from 'application/common/data_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

export const DamageManagerMessageDetailsWidgetEffects = (props) => {
    const {t} = useTranslation()
    const params = useParams()
    const {
        damageManagerLink
    } = useApplicationLinks()

    const messageId = params.messageId === undefined ? null : params.messageId
    const damageId = params.damageId === undefined ? null : params.damageId
    const { loadMessage, deletedMessages, detailsLoaded } = props

    useEffect(() => {
        messageId !== undefined && messageId !== null && loadMessage(damageId, messageId)
    }, [damageId, loadMessage, messageId])



    if (deletedMessages.includes(messageId)) {
        return <Navigate 
            to={
                damageManagerLink(
                    ROUTES.DAMAGE_MANAGER_INBOX,
                    damageId
                )
            } 
        />
    } 

    if(exists(messageId) && !detailsLoaded){
        return <ThemedPreloader preloaderText={t('Loading')} />
    }
    
    return <DamageManagerMessageDetailsWidgetComponent {...props} />
}