import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const realtimeNotificationsReducer =  (state = defaultStore.realtimeNotifications, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.realtimeNotifications
    }
    case ACTIONS.REALTIME_NOTIFICATIONS_SET_NEW_NOTIFICATIONS_TO_FALSE:
        return {
            ...state,
            hasNewNotifications: false,
            notifications: [],
            previousNotifications: [...state.notifications, ...state.previousNotifications]
        }
    case ACTIONS.REALTIME_NOTIFICATIONS_SHOW_SIDEBAR:
        return {
            ...state,
            sidebarVisible: true,
            hasNewNotifications: false
        }
    case ACTIONS.REALTIME_NOTIFICATIONS_HIDE_SIDEBAR:
        return {
            ...state,
            sidebarVisible: false,
            hasNewNotifications: false,
            notifications: [],
            previousNotifications: [...state.notifications, ...state.previousNotifications]
        }
    case ACTIONS.REALTIME_NOTIFICATIONS_ADD_NOTIFICATION:
        return {
            ...state,
            hasNewNotifications: true,
            notifications: [action.notification, ...state.notifications]
        }
    default:
        return state
    }
}