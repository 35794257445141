import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail, ActivityDetailWrapper } from 'application/components/controls/activity_details/common'
import { CaseFileStatusName } from 'application/components/controls/casefile_status_name'


export const CaseFileInfoUpdatedDetails = (
    {
        activity
    }
) => {
    const {
        referenceId = '',
        caseFileStatus = '',
        note = ''
    } = activity.data.newCaseFileInfo


    return <ActivityDetailWrapper>
        <ActivityDetail 
            label='Name' 
            value={
                <CaseFileStatusName 
                    caseFileStatusString = {caseFileStatus}
                />
            } 
        />
        <ActivityDetail 
            label='File number' 
            value={referenceId} 
        />
        <ActivityDetail 
            label='Note'
            value={note} 
        />
    </ActivityDetailWrapper>
}

CaseFileInfoUpdatedDetails.propTypes = {
    activity: PropTypes.object.isRequired
}