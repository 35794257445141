import React from 'react'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { TableContent } from './table_content'
import { TableWrapper, TableHeaderRow } from './'
import { TableFooter } from 'application/components/tables/common/_common'



export const Table = (
    {
        elementsLoading,
        elementsTotal,
        elementsLoaded,
        elements,
        configuration,
        emptyMessage = 'No elements found',
        selectItem = () => {},
        deselectItem = () => {},
        selectedItemsFromParent = [],
        moName = '',
        moIsActive = false,
        moSelectedItems = [],
        selectItemByMOName = () => {},
        deselectItemByMOName = () => {},
    }
) => {

    return <React.Fragment>
        {
            elementsLoaded && <React.Fragment>
                <TableWrapper>
                    <TableHeaderRow 
                        sorting={configuration.sorting}
                        columns={configuration.columns}
                        configuration={configuration}
                    />
                    <TableContent
                        configuration={configuration}
                        elementsLoading={elementsLoading}
                        elementsLoaded={elementsLoaded}
                        elements={elements}
                        elementsTotal={elementsTotal}
                        emptyMessage={emptyMessage}
                        moName={moName}
                        isSelectable = {configuration?.isSelectable || false} 
                        selectItem = {selectItem}
                        deselectItem = {deselectItem}
                        selectedItemsFromParent = {selectedItemsFromParent}
                        moIsActive = {moIsActive}
                        moSelectedItems = {moSelectedItems}
                        selectItemByMOName ={selectItemByMOName}
                        deselectItemByMOName = {deselectItemByMOName}
                    />
                </TableWrapper>
                <TableFooter
                    tableName={configuration.name}
                    pagination={configuration.pagination}
                    elementsTotal={elementsTotal}
                />
            </React.Fragment>
        }
        
        {
            elementsLoading && <ThemedPreloader />
        }

    </React.Fragment>
}