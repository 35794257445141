import { put, select } from 'redux-saga/effects'
import { getCurrentBranchId } from 'application/redux/selectors'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import * as filters from 'application/filters'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { ROUTE_PARAMETERS } from 'application/constants'
import { ListFilter } from 'application/filters/filter_class'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetBranchSpecificLastRepairAuthorizationsSaga(action){

    const currentBranchId = yield select(getCurrentBranchId)

    const offset = 0
    const limit = 5
    const orderBy = ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LAST_CHANGE
    const direction = ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    const filterQuery = composeFilterQuery([
        new ListFilter(filters.damages.repairAuthorizationGranted),
        new ListFilter(filters.common.branchId).setValue(currentBranchId)
    ])

    const response = yield resourceCall(
        api.gateway.damages.getDamages,
        {
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        }
    )
    
    yield put(
        updateWidget(
            action.key, 
            action.dependentWidgetKeys,
            {
                ...response, 
                isLoaded: true
            }
        )
    )
}