import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getInsurancesRequest } from 'application/redux/actions/common'
import { INSURANCE_TYPE } from 'application/constants/insurances'

const mapStateToProps = state => ({
    insurancesLoaded: state.common.insurance.companiesLoaded,
    insurancesLoading: state.common.insurance.companiesLoading,
    insurances: state.common?.insurance?.companies || [],
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({ 
    onGetInsurances: () => dispatch(getInsurancesRequest(INSURANCE_TYPE.INSURANCE)),
})

const withInsurancesLoaderComponent = Component => ({ ...props }) => {
    const {auth, insurancesLoaded, insurancesLoading, onGetInsurances, insurances} = props

    useEffect(() => { 
        if(auth.loggedIn && !insurancesLoaded && !insurancesLoading ){
            onGetInsurances() 
        }

    }, [auth.loggedIn, insurancesLoaded, insurancesLoading, onGetInsurances])

    return <Component {...props} insurances={insurances} />
}

export const withInsurancesLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withInsurancesLoaderComponent
)