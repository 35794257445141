import React from 'react'
import TruncateString from 'react-truncate-string'
import { IconButton } from '@ec/ui-controls-react'
import * as Fragments from './fragments'
import {AttachmentViewer} from 'application/components/building_blocks/attachments/attachment_viewer'
import {ThumbnailSlider} from 'application/components/building_blocks/attachments/slider'
import {GalleryTree} from './tree'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useParams } from 'react-router-dom'
import { isNull } from 'application/common/data_helpers'
import { useDocumentsByCategories } from 'application/components/hooks/documents_by_categories/use_documents_by_categories'


export const GalleryComponent = (
    {
        gallery, 
        categories, 
        onHideGallery,
        downloadAttachment, 
        onSelectAttachment,
    }
) => {

    const {
        allDocuments
    } = useDocumentsByCategories()

    const {
        damageId,
        sharedResourceId = null
    } = useParams()


    if(!gallery.isVisible){
        return null
    }

    const isExternal = !isNull(sharedResourceId)
    
    const handleDownload = () => {
        downloadAttachment(
            gallery.selectedAttachmentId,
            gallery.selectedAttachmentName, 
            damageId
        )
    }

    const handleSelectAttachment = (att) => {
        onSelectAttachment(
            damageId, 
            att.id, 
            att.fileName
        )
    }

    return <Fragments.StyledOverlayDimmer>
        <Fragments.StyledOverlayContent>
            <Fragments.StyledHeaderRow>
                <Fragments.StyledHeader>
                    <Fragments.StyledSidebarHeaderCaption>
                        <TruncateString 
                            text={gallery.selectedAttachmentName} 
                            truncateAt={40}
                        />
                    </Fragments.StyledSidebarHeaderCaption>
                    <Fragments.StyledSidebarHeaderDownloadButton>
                        <IconButton 
                            className='downloadBtn' 
                            iconKey='file_download' 
                            onButtonClick={handleDownload} 
                            width='auto' 
                            height='auto' 
                        />
                    </Fragments.StyledSidebarHeaderDownloadButton>
                    <Fragments.StyledSidebarHeaderCloseButton>
                        <IconButton 
                            className='closeBtn' 
                            iconKey='close' 
                            onButtonClick={onHideGallery} 
                            width='auto' 
                            height='auto'
                        />
                    </Fragments.StyledSidebarHeaderCloseButton>
                </Fragments.StyledHeader>
            </Fragments.StyledHeaderRow>

            <Fragments.StyledContent overflow='hidden'>
                <FlexBox height='100%'>
                    {
                        !isExternal && <FlexBox 
                            marginLeft='4px' 
                            flexBasis='30%' 
                            flexShrink='0' 
                            flexDirection='column'
                            cssRules='overflow:hidden;' 
                        >
                            {
                                categories.map((c, i) => {
                                    return <GalleryTree
                                        key={i}
                                        category={c}
                                        onAttachmentClick={handleSelectAttachment}
                                        damageId={damageId}
                                        selectedAttachmentId={gallery.selectedAttachmentId}
                                    />
                                })
                            }
                        </FlexBox>
                    }
                    <FlexBox 
                        overflow='hidden' 
                        flexBasis='calc(70% - 4px)' 
                        flexShrink='0'
                    >
                        <AttachmentViewer 
                            damageId={damageId}
                            attachmentId={gallery.selectedAttachmentId}
                            attachmentName={gallery.selectedAttachmentName}
                            taskId={gallery.taskId}
                        />
                    </FlexBox>
                </FlexBox>
            </Fragments.StyledContent>
            <Fragments.StyledFooterRow>
                <ThumbnailSlider
                    thumbnailsForSlider={allDocuments}
                    onThumbnailClick={handleSelectAttachment}
                    selectedThumbnailId={gallery.selectedAttachmentId}
                    match={gallery.match}
                />
            </Fragments.StyledFooterRow>
        </Fragments.StyledOverlayContent>
    </Fragments.StyledOverlayDimmer>
}