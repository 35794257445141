import { DASHBOARD_CONFIGURATION, WIDGET_CONTEXTS } from 'application/constants'
import { UI_SETTINGS } from 'application/constants/ui_settings'
import { layoutBranchSpecificControllingStatistics, widgetsBranchSpecificControllingStatistics } from './contexts/widgets_branch_specific_controlling_statistics'
import { layoutBranchSpecificControllingVacantPositions, widgetsBranchSpecificControllingVacantPositions } from './contexts/widgets_branch_specific_controlling_vacant_positions'
import { layoutBranchSpecificDashboard, widgetsBranchSpecificDashboard } from './contexts/widgets_branch_specific_dashboard'
import { layoutControllingVacantPositions, widgetsControllingVacantPositions } from './contexts/widgets_controlling_vacant_positions'
import { layoutDashboard, widgetsDashboard } from './contexts/widgets_dashboard'
import { getWidgetContextMeta } from './get_widget_context_meta'

export const getWidgetBoardConfigurationFromSetting = (settingKey, settingValue) => {

    switch (settingKey) {
    case UI_SETTINGS.USER.GENERIC_DASHBOARD_CONFIG:

        switch (settingValue) {
        case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES:
            return {
                widgetLayout: layoutDashboard.LARGE,
                widgetList: widgetsDashboard,
                boardConfiguration: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            }
        case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING:
            return {
                widgetLayout: layoutControllingVacantPositions.LARGE,
                widgetList: widgetsControllingVacantPositions,
                boardConfiguration: {
                    ...getWidgetContextMeta(WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS).boardConfiguration,
                    rowHeight: 70 //ToDo: fix layout on page layer to be able to process the correct rowHeight
                }
            }

        default:
            return {
                widgetLayout: layoutDashboard.LARGE,
                widgetList: widgetsDashboard,
                boardConfiguration: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            }
        }    

    case UI_SETTINGS.USER.BRANCH_DASHBOARD_CONFIG:

        switch (settingValue) {
        case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES:
            return {
                widgetLayout: layoutBranchSpecificDashboard.LARGE,
                widgetList: widgetsBranchSpecificDashboard,
                boardConfiguration: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            }
        case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING:
            return {
                widgetLayout: layoutBranchSpecificControllingVacantPositions.LARGE,
                widgetList: widgetsBranchSpecificControllingVacantPositions,
                boardConfiguration: {
                    ...getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS).boardConfiguration,
                    rowHeight: 70 //ToDo: fix layout on page layer to be able to process the correct rowHeight
                }
            }

        default:
            return {
                widgetLayout: layoutBranchSpecificControllingStatistics.LARGE,
                widgetList: widgetsBranchSpecificControllingStatistics,
                boardConfiguration: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            }
        }    

    default:
        break
    }
}
