import React, { 
    useMemo, 
    useState 
} from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { IconButton } from 'application/components/controls/icon_button/component'
import { StyleButtonToggle, StyleDisplayToggle, StyledToggleText, StyledWrapperSubCategory, StyledAttachmentsContainer, StyledToggleTextSecondary } from './fragments'
import { Spacer } from 'application/components/pages/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { MoThumbnail } from 'application/components/building_blocks/attachments/thumbnails/mo'
import { useDocsByCategoriesRegisterDocCategory } from 'application/components/hooks/documents_by_categories/use_register_documents_category'
import { useDocsByCategoriesLoadDocumentsByCategory } from 'application/components/hooks/documents_by_categories/use_load_documents_by_category'
import { ActionButton } from 'application/components/controls/action_button'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useDocumentsResourceFromState } from 'application/components/hooks/documents_by_categories/use_resource_from_state'
import { useDispatch } from 'react-redux'
import { showAttachmentDetailsDCWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/documents'
import { showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'




const DocumentsByCategory = ({
    category, 
    theme, 
    onAddDocument, 
    match,
    moName = null
}) => {
    const {t} = useTranslation()

    const categoryId = useMemo(()=>{
        return category.id
    }, [category.id])

    const { 
        isInternal
    } = useDocumentsResourceFromState()

    useDocsByCategoriesRegisterDocCategory(
        category
    )

    const hasChildren = useMemo(()=>{
        return category.categories !== undefined && category.categories.length > 0
    }, [category.categories])


    const {
        documentsByCategory,
        total,
        documentsByCategoryLoading,
        documentsByCategoryLoaded,
        fetchDocumentsByCategory,
        parentId
    } = useDocsByCategoriesLoadDocumentsByCategory(
        categoryId,
        !hasChildren
    )

    const dispatch = useDispatch()

    const handleOnAttachmentClick = (
        attachment
    ) => {
        dispatch(
            isInternal 
                ? 
                showAttachmentDetails(
                    attachment,
                    parentId
                )
                : 
                showAttachmentDetailsDCWorkflowPanel(
                    attachment.fileName, 
                    attachment.id, 
                    parentId, 
                    attachment.taskId, 
                    attachment.isExportedToLawFirm,
                    categoryId
                )
        )
    }

    const hasContent = total > 0

    
    // open documents list by default if it contains attachments:
    const [isOpen, setIsOpen] = useState(hasChildren)
    
    const handleAddDocument=() => {
        onAddDocument(category.id, parentId)
    }

    const isExtern = match.params.sharedResourceId !== undefined

    return <StyledWrapperSubCategory>
        <StyleButtonToggle 
            className='button-toggle'
            onClick={() => setIsOpen(!isOpen)}
        >
            <FontIcon 
                icon={isOpen ? 'expand_more' : 'chevron_right'} 
                fontSize={theme.fontSize.medium} 
                color={theme.color.gray70} 
            />
            <StyledToggleText>
                {category.name} 
            </StyledToggleText>
            {
                documentsByCategoryLoaded
                && hasContent
                && <StyledToggleTextSecondary>
                        ({total})
                </StyledToggleTextSecondary>
            }
            <Spacer />
            {
                !hasChildren && !isExtern && <IconButton 
                    iconKey='add' 
                    width='24px' 
                    height='24px' 
                    iconSize='24px' 
                    onButtonClick={handleAddDocument}
                />
            }
        </StyleButtonToggle>
        

        {
            isOpen && <StyleDisplayToggle active={isOpen} className='display-toggle'>
                {
                    hasContent 
                        ? <StyledAttachmentsContainer>
                            {
                                documentsByCategory.map(
                                    (ad) => {
                                        return <MoThumbnail
                                            key={ad.id}
                                            attachmentId={ad.id}
                                            attachmentName={ad.name}
                                            onAttachmentClick={handleOnAttachmentClick}
                                            damageId={parentId}
                                            taskId={ad.resourceId}
                                            match={match}
                                            moName={moName}
                                            extendedData={
                                                {
                                                    category,
                                                    isExportedToLawFirm: ad.isExportedToLawFirm
                                                }
                                            }
                                        />
                                    }
                                )
                            }
                            {
                                documentsByCategory.length < total &&  <FlexBox className='load-more-button-bar' paddingTop='16px'>
                                    <ActionButton
                                        buttonText={`${documentsByCategory.length} / ${total} ` + t('Load more')}
                                        onButtonClick={
                                            () => fetchDocumentsByCategory(
                                                parentId,
                                                category.id,
                                                documentsByCategory.length,
                                                10,
                                                'createdAt',
                                                true                                             
                                            )
                                        }
                                    />
                                    {
                                        documentsByCategoryLoading && <ThemedPreloader /> 
                                    }
                                </FlexBox>
                            }
                        </StyledAttachmentsContainer>
                        : 
                        !hasChildren &&  <StyledAttachmentsContainer>
                            {t('No documents in this category')}
                        </StyledAttachmentsContainer>
                }
                {
                    hasChildren && category.categories.map(sc => {
                        return <DocumentsByCategory
                            key={sc.id}
                            category={sc}
                            theme={theme}
                            onAddDocument={onAddDocument}
                            match={match}
                            moName={moName}
                        />
                    })
                }
            </StyleDisplayToggle>
        }
    </StyledWrapperSubCategory>
}

export const DocumentsByCategoryComponent =  withRouter(withTheme(DocumentsByCategory))