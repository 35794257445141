import { connect } from 'react-redux'
import { CreatePaymentComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'



const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCreatePayment: (
            name, 
            payload, 
            metaData
        ) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                metaData
            )
        ),
    }
}

export const CreatePayment = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePaymentComponent)