import React from 'react'
import { Table } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import { useMoCloseWatcher, useTableLoader } from 'application/components/hooks'
import {withRouter} from 'application/components/higher_order_components/with_router'

const TableCustomersComponent = (
    {
        customersListLoading,
        customersListLoaded,
        customersListTotal,
        customersList,
        moName,
        emptyMessage='No customers found',
        match,
        onLoadCustomersList,
        updateConstraint
    }
) => {
    useTableLoader(
        onLoadCustomersList, 
        match, 
        configuration, 
        updateConstraint
    )
    useMoCloseWatcher(
        moName, 
        customersList
    )

    return <Table 
        configuration={configuration}
        elementsLoading={customersListLoading}
        elementsLoaded={customersListLoaded}
        elementsTotal={customersListTotal}
        elements={customersList}
        emptyMessage={emptyMessage}
        moName={moName}
    />
}

export const CustomersTable = withRouter(TableCustomersComponent)
