import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import { isDescendant } from 'react-sortable-tree'
import { FontIcon } from '@ec/ui-controls-react'
import * as Fragments from './fragments'
import { exists } from 'application/common/data_helpers'
import { Spacer } from 'application/components/pages/_common'

const classnames = (...classes) => classes.filter(Boolean).join(' ')

const CustomNode = (props) => {
    const {
        scaffoldBlockPxWidth,
        toggleChildrenVisibility,
        connectDragPreview,
        connectDragSource,
        isDragging,
        canDrop,
        canDrag,
        node,
        title,
        subtitle,
        draggedNode,
        path,
        treeIndex,
        isSearchMatch,
        isSearchFocus,
        className,
        style,
        didDrop,
        treeId,
        isOver, // Not needed, but preserved for other renderers
        parentNode, // Needed for dndManager
        rowDirection,
        onNodeClick = () => {},
        onNodeDeleteClick = () => {},
        ...otherProps
    } = props
    const theme = useContext(ThemeContext)
    const nodeTitle = title || node.title
    const nodeIcon = node.iconName || null
    const nodeSubtitle = subtitle || node.subtitle
    const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
    const isLandingPadActive = !didDrop && isDragging

    return (
        <Fragments.SyledNodeContainer {...otherProps}>
            {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === 'function') && (
                <div>
                    <Fragments.StyledExpandButton
                        type="button"
                        scaffoldBlockPxWidth={scaffoldBlockPxWidth}
                        onClick={() =>
                            toggleChildrenVisibility({
                                node,
                                path,
                                treeIndex,
                            })
                        }
                    >
                        <FontIcon
                            icon={node.expanded ? 'expand_more' : 'chevron_right'}
                            fontSize={theme.fontSize.standard} 
                            color={theme.color.anthracite}
                            isClickable={true}
                        />
                    </Fragments.StyledExpandButton>

                    {node.expanded && !isDragging && (
                        <Fragments.StyledVerticalLine scaffoldBlockPxWidth={scaffoldBlockPxWidth}/>
                    )}
                </div>
            )}

            <div className={classnames('rst__rowWrapper', rowDirectionClass)}>
                {/* Set the row preview to be used during drag and drop */}
                {connectDragPreview(
                    <div
                        className={classnames(
                            'rst__row',
                            isLandingPadActive && 'rst__rowLandingPad',
                            isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                            isSearchMatch && 'rst__rowSearchMatch',
                            isSearchFocus && 'rst__rowSearchFocus',
                            rowDirectionClass,
                            className
                        )}
                        style={{
                            opacity: isDraggedDescendant ? 0.5 : 1,
                            ...style,
                        }}
                    >
                        {
                            canDrag && connectDragSource(<div className="rst__moveHandle">
                                <FontIcon
                                    icon='drag_handle'
                                    fontSize={theme.fontSize.medium} 
                                    color={theme.color.anthracite}
                                />
                            </div>, {
                                dropEffect: 'copy',
                            })
                        }

                        <div
                            className={classnames(
                                'rst__rowContents',
                                !canDrag && 'rst__rowContentsDragDisabled',
                                rowDirectionClass
                            )}
                        >
                            <div className={classnames('rst__rowLabel', rowDirectionClass)}>
                                <span
                                    className={classnames(
                                        'rst__rowTitle',
                                        node.subtitle && 'rst__rowTitleWithSubtitle'
                                    )}
                                >
                                    {
                                        exists(nodeIcon) && <React.Fragment>
                                            <FontIcon 
                                                icon={nodeIcon}
                                                fontSize={theme.fontSize.medium} 
                                                color={theme.color.anthracite}
                                            />
                                            <Spacer basis='8px' />
                                        </React.Fragment>
                                    }

                                    {typeof nodeTitle === 'function'
                                        ? nodeTitle({
                                            node,
                                            path,
                                            treeIndex,
                                        })
                                        : nodeTitle}
                                </span>

                                {nodeSubtitle && (
                                    <span className="rst__rowSubtitle">
                                        {typeof nodeSubtitle === 'function'
                                            ? nodeSubtitle({
                                                node,
                                                path,
                                                treeIndex,
                                            })
                                            : nodeSubtitle}
                                    </span>
                                )}
                            </div>

                            <div className="rst__rowToolbar">
                                <Fragments.StyledButton onClick={(e)=>{
                                    e.preventDefault()
                                    e.stopPropagation()
                                    onNodeClick(node)
                                }}>
                                    <FontIcon
                                        icon='edit'
                                        fontSize={theme.fontSize.medium} 
                                        color={theme.color.anthracite}
                                        isClickable={true}
                                    />
                                </Fragments.StyledButton>
                                {
                                    node.canBeDeleted 
                                        ? <Fragments.StyledButton onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation()
                                            onNodeDeleteClick(node)
                                        }}>
                                            <FontIcon
                                                icon='delete'
                                                fontSize={theme.fontSize.medium} 
                                                color={theme.color.anthracite}
                                                isClickable={true}
                                            />
                                        </Fragments.StyledButton>
                                        : <Fragments.StyledDisabledButton>
                                            <FontIcon
                                                icon='delete'
                                                fontSize={theme.fontSize.medium} 
                                                color={theme.color.gray10}
                                                isClickable={false}
                                            />
                                        </Fragments.StyledDisabledButton>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragments.SyledNodeContainer>
    )
}

CustomNode.defaultProps = {
    isSearchMatch: false,
    isSearchFocus: false,
    canDrag: false,
    toggleChildrenVisibility: null,
    className: '',
    style: {},
    parentNode: null,
    draggedNode: null,
    canDrop: false,
    title: null,
    subtitle: null,
    rowDirection: 'ltr',
}

CustomNode.propTypes = {
    node: PropTypes.shape({}).isRequired,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    isSearchMatch: PropTypes.bool,
    isSearchFocus: PropTypes.bool,
    canDrag: PropTypes.bool,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    toggleChildrenVisibility: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    isDragging: PropTypes.bool.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    // Drop target
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool,

    // rtl support
    rowDirection: PropTypes.string,
}

export {CustomNode}