import React from 'react'
import { useWatch } from 'react-hook-form'


const SwitchFilter = ({ observed, children }) => {
    // filter out only children with a matching prop
    const matchedSection =  children.find(child => {
        return child.props.observed === observed
    })
    return matchedSection?.props?.children || null
}

export const Switch = ({ description, formHook, buildElement }) => {
    const {
        control,
    } = formHook
    // const observe = watch(description.observe, false) ?? false
    const observe = useWatch({
        control: control,
        name: description.observe
    })

    const mappedObserved = observe === null || observe === undefined ? false : observe

    return <SwitchFilter observed={mappedObserved}>
        {description.sections.map((section) => {
            return <div key={`item-${section.observed}`} observed={section.observed} style={description._config?.css}>
                {section.descriptions.map(innerDesc => buildElement(innerDesc, formHook))}
            </div>
        })}
    </SwitchFilter>
}
