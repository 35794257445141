import { removeTaskFromListWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { put } from 'redux-saga/effects'

export function* removeTaskFromTaskListWorkflowPanel(action) {
    const taskId = action.payload.taskId !== undefined ? action.payload.taskId : action.metaData.taskId
    if (taskId !== undefined) {
        yield put(removeTaskFromListWorkflowPanel(taskId))
    } else {
        yield
    }
}
