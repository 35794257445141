import { takeLeading, put } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* moveDocumentCategorySaga(action) {
    try {
        const { 
            nextParentNode, 
            node, 
            treeData
        } = action.payload

        if(nextParentNode === null){ //top level category
            yield resourceCall(
                api.gateway.categories.moveCategoryToTopLevel,
                {
                    categoryId: node.id
                }
            )
            yield resourceCall(
                api.gateway.categories.updateCategoryTopLevelOrder,
                null,
                treeData.map(c => ({id: c.id}))
            )
        } else { //subcategory
            yield resourceCall(
                api.gateway.categories.moveCategoryToParent,
                {
                    categoryId: node.id
                }, 
                {
                    id: nextParentNode.id
                }
            )
            yield resourceCall(
                api.gateway.categories.updateCategoryParentOrder, 
                {
                    categoryId: nextParentNode.id
                },
                nextParentNode.children.map(c => ({id: c.id})))
        }

        yield put(showToast('Category order saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* moveDocumentCategoryWatcher() {
    yield takeLeading(
        ACTIONS.COMMON_MOVE_CATEGORY, 
        moveDocumentCategorySaga
    )
}