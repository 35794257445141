import { connect } from 'react-redux'
import { ForLoggedInUsersComponent } from './component'

const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn,
        permissionsLoaded: state.auth.permissions.length > 0
    }
}

export const ForLoggedInUser =  connect(
    mapStateToProps
)(ForLoggedInUsersComponent)