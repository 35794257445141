import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import { ActivityDamageLink } from './_common/damage_activity_link'


export const DamageTypeChangedMetaContent = (
    {
        notification, 
        onLinkClick, 
        inline
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification
        
    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='State'
            value={`${t(payload.oldType)} → ${t(payload.newType)}`}
        />
        <ActivityDamageLink
            onClick={onLinkClick} 
            damageId={payload.damage.id}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}