import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getRolesRequest } from 'application/redux/actions/common/roles'

const mapStateToProps = state => ({
    rolesLoaded: state.common.rolesLoaded,
    rolesLoading: state.common.rolesLoading,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({ 
    onGetRoles: () => dispatch(getRolesRequest()),
})

const withRolesLoaderComponent = Component => ({ ...props }) => {
    const {auth, rolesLoaded, rolesLoading, onGetRoles} = props

    useEffect(() => { 
        auth.loggedIn 
        && !rolesLoaded 
        && !rolesLoading 
        && onGetRoles() 
    }, [auth.loggedIn, onGetRoles, rolesLoaded, rolesLoading])

    return <Component {...props} />
}

export const withRolesLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withRolesLoaderComponent
)