import {ACTIONS} from 'application/constants'

export const requestModules = (accountId) => ({
    type: ACTIONS.REQUEST_MODULES,
    accountId
})

export const populateModules = (
    accountId,
    appliedModules,
    accountModules,
    branchModules
) => ({
    type: ACTIONS.POPULATE_MODULES,
    accountId,
    appliedModules,
    accountModules,
    branchModules
})