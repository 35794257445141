import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateComprehensiveDamageTypes } from 'application/redux/actions/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getComprehensiveDamageTypesSaga(action) {
    try {
        const response = yield resourceCall(api.gateway.comprehensiveDamageTypes.getComprehensiveDamageTypes)
        yield put(populateComprehensiveDamageTypes(response.types))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getComprehensiveDamageTypeWatcher() {
    yield takeLeading(ACTIONS.COMMON_GET_COMPREHENSIVE_DAMAGE_TYPES_REQUEST, getComprehensiveDamageTypesSaga)
}