import { ACTIONS } from 'application/constants'

export const getRolesRequest = () => ({
    type: ACTIONS.COMMON_GET_ROLES_REQUEST
})

export const populateRoles = (roles) => ({
    type: ACTIONS.COMMON_POPULATE_ROLES,
    roles
})

export const resetRoles = () => ({
    type: ACTIONS.COMMON_RESET_ROLES
})
