import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const userDataReducer =  (state = defaultStore.pages.workflowPanel.userData, action) => {
    switch (action.type) {
    case ACTIONS.WORKFLOW_PANEL_WRITE_USER_DATA: {
        return {
            ...state,
            ...action.userData
        }
    }
        
    default:
        return state
    }
}