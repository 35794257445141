import React, {useEffect} from 'react'
import * as Fragments from './fragments'
import {HeaderWelcomePage} from './sub_comp/header'
import { MainInfoWelcome } from './sub_comp/info_main'
import { IconBarTop } from './sub_comp/icon_bar_top'
import { IconBarBottom } from './sub_comp/icon_bar_bottom'
import { PixelRow } from './sub_comp/pixel_row'
import {FooterWelcome} from './sub_comp/footer'
import { GlobalStyle } from 'application/style'
import { PageTitle } from 'application/components/controls/page_title'
import { useStorageVersion } from 'application/components/hooks/use_storage_version'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'application/constants'




export const WelcomePageComponent = ({auth}) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const returnUrl = urlParams.has('returnUrl') ?  urlParams.get('returnUrl') : ''
    const redirectOnLogout = parseInt(process.env.REACT_APP_REDIRECT_ON_LOGOUT) === 1

    useEffect(()=>{
        if(auth.manualLoggedOut && redirectOnLogout){
            window.location.href = process.env.REACT_APP_LANDING_PAGE_URL
        }
    }, [auth.manualLoggedOut, redirectOnLogout])

    useStorageVersion()

    if(auth.manualLoggedOut && redirectOnLogout){
        return null
    }

    if(!auth.manualLoggedOut && redirectOnLogout){
        return <Navigate to={ROUTES.LOAD} />
    }

    return <React.Fragment>
        <PageTitle />
        {
            returnUrl === '' 
                ? <Fragments.LayoutWelcome>
                    <GlobalStyle />
                    <HeaderWelcomePage />
                    <Fragments.MainWrapper>
                        <MainInfoWelcome />
                        <PixelRow />
                        <IconBarTop />
                        <IconBarBottom />
                        <FooterWelcome />
                    </Fragments.MainWrapper>
                </Fragments.LayoutWelcome>
                : <HeaderWelcomePage />
        }
    </React.Fragment>
}