import React from 'react'
import { useTranslation } from 'react-i18next'
import { useInspectionDatePlausibility, useStatefulForm } from 'application/components/hooks'
import { DialogFooterLeft, DialogFooterRight, DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import {  StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { FontIcon } from '@ec/ui-controls-react'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { DatePicker, TextInput, HiddenField } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { TwoColumns } from 'application/components/forms/_common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { PushBottom24, PushBottom32, Spacer } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import { CheckboxWithLabel } from 'application/components/controls/form'
import { PartialForms } from 'application/components/forms'
import { InfoBox } from 'application/components/controls/info_box'
import { StyledLockedMessage } from 'application/components/building_blocks/account_box/fragments'
import { ActionButton } from 'application/components/controls/action_button'




export const CommissionExpertStep3 = (
    { 
        goToPreviousStep,
        onCommissionExpert,
        step2data,
        setStep2data,
        inspectionAdressIsBranchAdress, 
        setInspectionAdressIsBranchAdress,
        getBranchInfo,
        incidentDate
    }
) => {
    const { t } = useTranslation()

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue,
        control,
        formStateValues,
        updateFormState,
        trigger
    } = useStatefulForm({ defaultValues: step2data })

    const {inspectionDateIsBeforeIncidentDate} = useInspectionDatePlausibility(
        formStateValues.inspectionInfo.date,
        incidentDate
    ) 

    const onSubmitSuccess = () => {
        onCommissionExpert(getValues())
    }

    const changeInspectionAdressIsBranchAddress = (e) => {
        setInspectionAdressIsBranchAdress(!inspectionAdressIsBranchAdress)
    }


    return <React.Fragment>
        <DialogMainContent>
            <FlexBox 
                flexDirection='column' 
                flexWrap='nowrap'
            > 
                <TwoColumns 
                    columnLeft={
                        <DatePicker
                            label={t('Inspection date')}
                            name='inspectionInfo.date'
                            validate={
                                {
                                    notEmpty: validationMethods.notEmpty
                                }
                            }
                            error={errors.inspectionInfo?.date}
                            onDateChange={updateFormState}
                            control={control}
                            register={register}
                            defaultValue={formStateValues.inspectionInfo.date}
                            setValue={setValue}
                            trigger={trigger}
                        />
                    }
                />
            </FlexBox>

            <PushBottom32 />
            <SubHeadline>{ t('Place of inspection') }</SubHeadline>

            <CheckboxWithLabel
                name='inspectionAdressIsBranchAdress'
                label={t('Branch is location of inspection')}
                isRequired={false} 
                isSelected={inspectionAdressIsBranchAdress}
                activeColor={false}
                onCheckboxChange={changeInspectionAdressIsBranchAddress}
                isSmall={true}
                disabled={false}
                fontSize={'12px'}
                isRadioCheckBox={false}
            />
            {
                !inspectionAdressIsBranchAdress && <React.Fragment>
                    <PushBottom24 />
                    <TextInput
                        label={t('Site name')}
                        name='inspectionInfo.name'
                        register={register}
                        validate={{ notEmpty: validationMethods.notEmpty }}
                        error={errors.inspectionInfo?.name}
                        onChange={updateFormState}
                    />
                    <AddressDataPartialForm 
                        onChange={updateFormState}
                        nameStreet = 'inspectionInfo.site.line1'
                        namePostalCode = 'inspectionInfo.site.postalCode'
                        nameCity = 'inspectionInfo.site.locality'
                        register={register}
                        errors={errors}
                        validate={{ notEmpty: validationMethods.notEmpty }}
                        validatePostalCode={{ mustBeGermanPostalCode: validationMethods.mustBeGermanPostalCode,  notEmpty: validationMethods.notEmpty }}
                        setValue={setValue}
                    />
                </React.Fragment>
            }
            
            <PushBottom24 />
            <SubHeadline>{t('Branch data')}</SubHeadline>
            <TextInput
                label={t('Branch name')}
                name='branchInfo.name'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.branchInfo?.name}
                onChange={updateFormState}
            />
            <AddressDataPartialForm 
                onChange={updateFormState}
                nameStreet = 'branchInfo.address.line1'
                namePostalCode = 'branchInfo.address.postalCode'
                nameCity = 'branchInfo.address.locality'
                register={register}
                errors={errors}
                validate={{ notEmpty: validationMethods.notEmpty }}
                validatePostalCode={{ mustBeGermanPostalCode: validationMethods.mustBeGermanPostalCode,  notEmpty: validationMethods.notEmpty }}
                setValue={setValue}
            />
            <PartialForms.ContactDataPartialForm 
                namePhoneNumber = 'branchInfo.contact.phoneNumber'
                nameEmail='branchInfo.contact.email'
                errorNamePhoneNumber={errors.branchInfo?.contact?.phoneNumber}
                errorNameEmail={errors.branchInfo?.contact?.email}
                register={register}
                errors={errors}
                onChange={updateFormState}
                control={control}
                setValue={setValue}
                defaultValuePhoneNumber={formStateValues?.branchInfo?.contact?.phoneNumber || ''}
            />       
            <TextInput 
                label={t('Note')}
                name='note'
                register={register}
                validate={null}
                error={errors.note}
                onChange={updateFormState}
            />
            <HiddenField
                name={'type'}
                register={register} 
            />
        </DialogMainContent>
        <StepsLayoutFooter>
            {
                inspectionDateIsBeforeIncidentDate && <DialogFooterLeft>
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        cssMargin='0 0 16px' 
                        cssPadding='8px 16px 8px 8px'
                        cssMinHeight='unset'
                        direction='row'
                    >
                        <FontIcon icon='error_outline' />
                        <Spacer basis='10px' />
                        <StyledLockedMessage>{t('The viewing date is in the past.')}</StyledLockedMessage>
                    </InfoBox>
                </DialogFooterLeft>

            }
            <DialogFooterRight>
                <IconButtonWithLabel
                    iconKey='arrow_back' 
                    label={t('Back')} 
                    onButtonClick={
                        () => {
                            setStep2data({
                                ...getValues(),
                                inspectionAdressIsBranchAdress,
                                branchInfo: getBranchInfo()
                            })
                            goToPreviousStep()
                        }
                    } 
                />
                <ActionButton
                    buttonText={t('Send commission')} 
                    onButtonClick={
                        handleSubmit(
                            onSubmitSuccess
                        )
                    } 
                    disabled={false}
                />
            </DialogFooterRight>
        </StepsLayoutFooter>
    </React.Fragment>
}