import { takeLatest } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { getStatusListSaga, updateCustomoStatusListSaga } from './get_status_list'

export function* statusListWatcher() {
    yield takeLatest(
        ACTIONS.COMMON_REQUEST_STATUS_LIST, 
        getStatusListSaga
    )
}

export function* updateCustomStatusListWatcher() {
    yield takeLatest(
        ACTIONS.COMMON_UPDATE_CUSTOM_STATUS_LIST, 
        updateCustomoStatusListSaga
    )
}