import React from 'react'
import { ROUTE_PARAMETERS } from 'application/constants'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'
import {DateComponent} from 'application/components/controls/date'
import { LocalTime } from 'application/components/controls/local_time'
import { ActivityAction } from 'application/components/name_mappings/activity_action'
import { getActivityDetailsByType } from 'application/components/controls/activity_details'
import { getActivityLinksByType } from 'application/components/controls/activity_links'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const tableName = STORAGE.TABLE_NAME.ACTIVITIES.ALL

export const configuration = {
    name: tableName,
    filters: {
        match: [
            new ListFilter(filters.common.branchId)
        ],
        query: [
            new ListFilter(filters.activities.activityType),
            new ListFilter(filters.activities.timeRange)
        ],
        property: [],
        static: []
    },
    sorting: {
        sortParamKey: ROUTE_PARAMETERS.ACTIVITIES.ALL.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.ACTIVITIES.ALL.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.ACTIVITIES.ALL.ORDER_BY.VALUES.OCCURED_AT,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.ACTIVITIES.ALL.OFFSET,
        limit: ROUTE_PARAMETERS.ACTIVITIES.ALL.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName)
        }
    },
    href: () => null,
    columns: [
        {
            width: 7,
            label: 'Date',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.ACTIVITIES.ALL.ORDER_BY.VALUES.OCCURED_AT,
            CellContent: ( {element} ) => <DateComponent date={element.occurredAt} />
        },
        {
            width: 6,
            label: 'Time',
            CellContent: ({element}) => <LocalTime date={element.occurredAt} />
        },
        {
            width: 8,
            label: 'Name',
            CellContent: ({element}) => {
                const actorName = element.actor?.name || 'System'
                return  <React.Fragment>
                    {actorName} 
                </React.Fragment>
            }
        },
        {
            width: 15,
            label: 'Action',
            CellContent: ({element}) => <ActivityAction activityType={element.type} />
        },
        {
            width: 45,
            label: 'Details',
            CellContent: ({element}) =>  {
                const ActivityDetails = getActivityDetailsByType(element.type)
                return <ActivityDetails activity={element}/>
            }
        },
        {
            width: 25,
            label: 'Links',
            CellContent: ({element}) =>  {
                const ActivityLinks = getActivityLinksByType(element.type)
                return <ActivityLinks activity={element}/>
            }
        }
    ],
    ContextMenu: null,
    contextMenuWidth: '0px',
    massOperations: {
        available: false //not in use yet
    }
}
