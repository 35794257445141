import React from 'react'
import uniqid from 'uniqid'
import styled from 'styled-components'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/modules/dynamic-form/controls/_common'
import { useRegister } from 'application/modules/dynamic-form/hooks/use_register'

const StyledTextAreaWrapper = styled.div `
    margin-bottom: 16px;
    width: ${props => props.cssWidth};
    display: flex;
    flex-direction: column;
    & label.label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }
`

const StyledTextArea = styled.textarea`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite};
    border: 1px solid ${props => props.cssValidationError ? props.theme.color.red : props.theme.color.gray15};
    box-shadow: none;
    margin-bottom: -6px;
    box-sizing: border-box;
    padding: 9px;
    width: inherit;
    min-height: ${props => props.cssMinHeight};
    resize: none;

    :hover {
        border: 1px solid ${props => props.disabled ?  props.theme.color.gray15 : props.cssValidationError ? props.theme.color.red   : props.theme.color.lightPowderGray};
    }

    :active {
        border: 1px solid ${props => props.disabled ? props.theme.color.gray15   : props => props.theme.color.anthracite};
    }

    :disabled {
        background-color: ${props => props.theme.color.gray5};
        color: ${props => props.theme.color.anthracite50};
        border: 1px solid ${props => props.theme.color.gray15};
    }

    :focus{
        outline: none;
    }


`

export const TextArea =  ({
    label,
    name,
    placeholder='',
    disabled= false,
    minHeight='120px',
    register,
    validate = {},
    error,
    hideValidationMessage = false,
    defaultValue='',
    errorMessageOverride='',
    onChange=()=>{},
    hideValidationBox = false,
}) => {
    const htmlId = uniqid()

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate, onChange)

    return <StyledTextAreaWrapper cssWidth='100%'>
        {
            label !== '' && <label className='label' htmlFor={htmlId}>{label}<RequiredFieldMarker validate={validate}/></label>
        }
        <StyledTextArea
            {...registerProps}
            id={htmlId}
            defaultValue={defaultValue}
            placeholder={placeholder}
            cssMinHeight={minHeight}
            disabled={disabled}
            onChange={onRegisterChange}
            autoComplete="on"
        />
        {
            !hideValidationBox && <ValidationErrorMessage hideValidationMessage={hideValidationMessage} error={error} errorMessageOverride={errorMessageOverride} />
        }
    </StyledTextAreaWrapper>
}