import React from 'react'
import styled from 'styled-components'
import TruncateString from 'react-truncate-string'
import { useTranslation } from 'react-i18next'

export const MetaContentContainer = styled.div`
    background: ${props => props.inline ? 'transparent' : props.theme.color.white};
    padding: ${props => props.inline ? '0' : '6px 8px'};
    border-radius: 2px;
    display: flex;
    overflow: hidden;
    flex-direction: ${props => props.inline ? 'row' : 'column'};
    max-width: 100%;
    align-items: ${props => props.inline ? 'center' : 'flex-start'};
    & * {
        padding: 0; 
        align-items: ${props => props.inline ? 'center' : 'flex-start'};
    }
`

export const ActivityLink = styled.div`
   
    font-size: ${props => props.inline ? 'inherit' : props.theme.fontSize.standard};
    margin-left: ${props => props.inline ? '6px' : '0'};

    &>a {
        text-decoration: none;
        color: ${props => props.theme.color.primary};
    }
    &>a:hover {
        text-decoration: underline;
        color: ${props => props.theme.color.anthracite};
    }
`

export const ActivityLinkDescriptionWrapper = styled.div`
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.inline ? 'inherit' : props.theme.fontSize.standard};
    margin-bottom: ${props => props.inline ? '0' : '2px'};
    display: flex;
    overflow: hidden;
    flex: 1;
    width: 100%;
    
    & strong{
        flex: 0 0 auto;
        min-width: 0;
        &:after{
            content: ":";
            margin-right: 4px;
        }
    }
`

export const ActivityDescriptionValue = styled.span`
    flex: 1;
    min-width: 0;
`


export const ActivityDescription = ({label, value, inline=false}) =>{
    const {t} = useTranslation()

    return <ActivityLinkDescriptionWrapper inline={inline}>
        <strong>{t(label)}</strong>
        <ActivityDescriptionValue title={value}>
            <TruncateString text={t(value)} />
        </ActivityDescriptionValue>
    </ActivityLinkDescriptionWrapper>
}