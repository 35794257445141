import styled from 'styled-components'

export const StyledUserMenu = styled.div`
    display: flex;
    flex-direction: row;
    height: 48px;
    
    & i {
        color: ${props => props.theme.color.antracite}
    }

    &:hover{
        background-color: ${props => props.theme.color.gray15};
    }   

    & ul {
        margin-top: 28px;
    }

`