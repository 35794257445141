import { connect } from 'react-redux'
import {DamageManagerInvoicesEffects} from './effects'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'


const mapStateToProps = state => {
    return {
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damage: state.pages.damageManager.shared.damage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetContexts: (contexts) => {
            for (const context of contexts){
                dispatch(refreshWidgetsByContext(context))
            }
        }
    }
}

export const DamageManagerInvoices = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerInvoicesEffects)