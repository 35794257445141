import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const createTopLevelCategory = () => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder('documentcategories', HTTP_METHOD.POST, payload)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)