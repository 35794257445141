import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { exists } from 'application/common/data_helpers'
import { DateComponent } from 'application/components/controls/date'
import { FullName } from 'application/components/controls/full_name'
import { LicensePlate } from 'application/components/controls/license_plate'
import { PushBottom8, Spacer } from 'application/components/pages/_common'
import {
    StyledDamageDisplayContainer,
    StyledDamageLink,
    StyledDamageLinkContainer,
    StyledDescriptionLabel,
    StyledDescriptionRow,
    StyledDescriptionText,
    StyledSimpleDescriptionRow
} from './fragments'

export const DamageDisplay = ({
    damage = null,
    to = null,
    simplified = true
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    if(!damage){
        return null
    }

    const {
        vehicle
    } = damage

    const customerLabel = vehicle.keeper.isCompany ? 'Business customer' : 'Private customer'
    const showMakeModelRow = exists(vehicle.make) || exists(vehicle.model)

    return <StyledDamageDisplayContainer>
        <LicensePlate licensePlate={vehicle.licensePlate} fontSize={14}/>
        {
            simplified
                ? <React.Fragment>
                    <PushBottom8 />
                    {
                        showMakeModelRow && <StyledSimpleDescriptionRow>
                            {vehicle.make} {vehicle.model}
                        </StyledSimpleDescriptionRow>
                    }
                    <StyledSimpleDescriptionRow>
                        <DateComponent date={damage.incidentDate} />
                    </StyledSimpleDescriptionRow>
                </React.Fragment>
                : <React.Fragment>

                    <StyledDescriptionRow>
                        <StyledDescriptionLabel>
                            {t(customerLabel)}
                        </StyledDescriptionLabel>
                        <StyledDescriptionText>
                            <FullName person={damage.vehicle.keeper}/>
                        </StyledDescriptionText>
                    </StyledDescriptionRow>

                    {
                        showMakeModelRow && <StyledDescriptionRow>
                            <StyledDescriptionLabel>
                                {t('Vehicle make & model')}
                            </StyledDescriptionLabel>
                            <StyledDescriptionText>
                                {vehicle.make} {vehicle.model}
                            </StyledDescriptionText>
                        </StyledDescriptionRow>
                    }

                    <StyledDescriptionRow>
                        <StyledDescriptionLabel>
                            {t('Damage date')}
                        </StyledDescriptionLabel>
                        <StyledDescriptionText>
                            <DateComponent date={damage.incidentDate} />
                        </StyledDescriptionText>
                    </StyledDescriptionRow>

                    <StyledDescriptionRow>
                        <StyledDescriptionLabel>
                            {t('State')}
                        </StyledDescriptionLabel>
                        <StyledDescriptionText>
                            {
                                damage.state.name
                            }
                        </StyledDescriptionText>
                    </StyledDescriptionRow>

                    <StyledDescriptionRow>
                        <StyledDescriptionLabel>
                            {t('Assignee')}
                        </StyledDescriptionLabel>
                        <StyledDescriptionText>
                            <FullName person={damage.assignee} />
                        </StyledDescriptionText>
                    </StyledDescriptionRow>

                </React.Fragment>
        }
        <StyledDamageLinkContainer>
            <StyledDamageLink to={to}>
                <FontIcon icon='local_car_wash' color={theme.color.anthracite} fontSize={theme.fontSize.standard}/><Spacer basis='4px'/>{t('Edit damage')}
            </StyledDamageLink>
        </StyledDamageLinkContainer>
    </StyledDamageDisplayContainer>
}