import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { INVOICE_TYPE } from 'application/constants'
import  { InvoiceName } from 'application/components/name_mappings/invoices'
import { validationMethods } from 'application/common'

export const InvoicesTypeSelect = ({
    name='type',
    register,
    setValue,
    initialValue = INVOICE_TYPE.REPAIR,
    menuPlacement = 'bottom',
    onChange = () => {},
    disabled = false,
    label = 'General communication type',
    hideValidationBox = false
}) => {
    const { t } = useTranslation()

    const invoicesTypepOptions = [
        {
            value: INVOICE_TYPE.UNKNOWN,
            label: <InvoiceName invoicesType={INVOICE_TYPE.UNKNOWN} />
        },
        {
            value: INVOICE_TYPE.REPAIR,
            label: <InvoiceName invoicesType={INVOICE_TYPE.REPAIR} />
        },
        {
            value: INVOICE_TYPE.RENTAL_CAR,
            label: <InvoiceName invoicesType={INVOICE_TYPE.RENTAL_CAR} />
        },
        {
            value: INVOICE_TYPE.TOWING,
            label: <InvoiceName invoicesType={INVOICE_TYPE.TOWING} />
        },
        {
            value: INVOICE_TYPE.EXPERT,
            label: <InvoiceName invoicesType={INVOICE_TYPE.EXPERT} />
        },
        {
            value: INVOICE_TYPE.MISC,
            label: <InvoiceName invoicesType={INVOICE_TYPE.MISC} />
        },
        {
            value: INVOICE_TYPE.FICTITIOUS_REPAIR,
            label: <InvoiceName invoicesType={INVOICE_TYPE.FICTITIOUS_REPAIR} />
        },
        {
            value: INVOICE_TYPE.FLAT_RATE_FEE,
            label: <InvoiceName invoicesType={INVOICE_TYPE.FLAT_RATE_FEE} />
        },
        {
            value: INVOICE_TYPE.IMPAIRMENT,
            label: <InvoiceName invoicesType={INVOICE_TYPE.IMPAIRMENT} />
        },
        {
            value: INVOICE_TYPE.LOSS_OF_USE,
            label: <InvoiceName invoicesType={INVOICE_TYPE.LOSS_OF_USE} />
        },
    ]

    return <Select
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={invoicesTypepOptions}
        defaultValue={invoicesTypepOptions.find(option => option.value === initialValue)}
        validate={{notEmpty: validationMethods.notEmpty}} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        maxMenuHeight={200}
        disabled={disabled}
        hideValidationBox={hideValidationBox}
    />
}

InvoicesTypeSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}