import { takeLeading, put } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { createDamageSuccess, setDocumentsSendingState, sendDocumentsSuccess } from 'application/redux/actions/ext_create_damage'
import { notNull } from 'application/common/data_helpers'

function* sendCreateExternalDamageSaga(action) {
    try {
        const {
            damageData,
            filesData
        } = action

        const {
            customerId
        } = damageData

        const payload = dataMappers.exportMappers.damage.createDamageDataMapper(
            damageData, 
            customerId
        )

        const damageResponse = yield resourceCall(
            api.gateway.damage.createDamage,
            {
                branchId: damageData.branchId
            },
            payload
        )

        const damageId = damageResponse.id

        yield put(createDamageSuccess(damageId))

        if (notNull(filesData)) {
            const {
                files,
                categoryId
            } = filesData
    
            yield put( setDocumentsSendingState())

            for(const file of files){
                yield resourceCall(
                    api.gateway.attachments.postAttachment,
                    {
                        damageId,
                        categoryId
                    },
                    {
                        file: file.metaData
                    }
                )
            }
            yield put(sendDocumentsSuccess())
        }

    } catch (e) {
        yield handleError(e)
    }
}


export function* sendCreateDamageExternalWatcher() {
    yield takeLeading(
        ACTIONS.EXT_CREATE_DAMAGE_REQUEST, 
        sendCreateExternalDamageSaga
    )
}