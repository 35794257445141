import { ACTIONS } from 'application/constants'

export const getAccounts = (
    offset = 0, 
    limit = 10, 
    orderBy = 'id', 
    direction = 'asc', 
    filterQuery
) => ({
    type: ACTIONS.AUTH_REQUEST_ACCOUNTS,
    offset, 
    limit, 
    orderBy, 
    direction, 
    filterQuery
})

export const populateAccounts = (
    accounts, 
    total
) => ({
    type: ACTIONS.AUTH_POPULATE_ACCOUNTS,
    accounts, 
    total
})

export const populateAccountsTotal = (
    total, 
    firstAccountId = null,
    firstAccountLocked
) => ({
    type: ACTIONS.AUTH_POPULATE_ACCOUNTS_TOTAL,
    total, 
    firstAccountId,
    firstAccountLocked
})

export const setNoAccountsFound = () => ({
    type: ACTIONS.AUTH_NO_ACCOUNTS_FOUND
})

export const setAccountsFound = () => ({
    type: ACTIONS.AUTH_ACCOUNTS_FOUND
})