import { DIALOG } from 'application/constants'
import { uploadAccountLogo} from 'application/redux/actions/account_logo'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { useDispatch } from 'react-redux'



export const useAccountLogoHandling = () => {
    const  dispatch = useDispatch()

    const onUploadAccountlogo = (
        file
    ) => dispatch( 
        uploadAccountLogo(file)
    )

    const onRemoveAccountlogo = () => dispatch(
        openDialogByName(
            DIALOG.NAME.DELETE_ACCOUNTLOGO,
            true,
            {},
            'Delete accountlogo'
        )
    )

    return {
        onUploadAccountlogo,
        onRemoveAccountlogo
    }

}