import { put, takeEvery, select } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { populateCurrentBranchPermissions, populateCurrentBranch } from 'application/redux/actions/auth'
import { getCurrentBranchId, getAllPermissions } from 'application/redux/selectors'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* changeCurrentBranchSaga(action) {
    try {
        const currentBranchId = yield select(getCurrentBranchId)

        // only set permissions & branch info if branch really changes
        // necessary to avoid reloading after new tokens are set
        if(currentBranchId !== action.branchId){

            const permissions = yield select(getAllPermissions)
            yield put(populateCurrentBranchPermissions(permissions, action.branchId))
    
            const currentBranch = yield resourceCall(
                api.gateway.auth.getBranchById, 
                {
                    branchId: action.branchId
                }
            )
            yield put(
                populateCurrentBranch(currentBranch)
            )
        }

    } catch (e) {
        yield handleError(e, action)
    }
}


export function* branchesWatcher() {
    yield takeEvery(ACTIONS.AUTH_REQUEST_CURRENT_BRANCH_CHANGE, changeCurrentBranchSaga)
}