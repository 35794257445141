import { takeLeading, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateCustomersList } from 'application/redux/actions/pages/customers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getCustomersSaga(action) {
    try {
        const { 
            offset, 
            limit, 
            orderBy,
            direction,
            filterQuery
        } = action
        const response = yield resourceCall(
            api.gateway.customer.getAllCustomers, 
            {
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery
            }
        )
        yield put(
            populateCustomersList(
                response.customers, 
                response.total
            )
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* getCustomersWatcher() {
    yield takeLeading(
        ACTIONS.CUSTOMERS_GET_CUSTOMERS_REQUEST, 
        getCustomersSaga
    )
}