import styled from 'styled-components'

export const StyledErrorDisplayContainer = styled.div`
    padding: 16px;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: fixed;
    height: calc(100vh - 125px);
    width: 90vw;
    max-width: calc(100vw - 2em - 4px);
    z-index: 9999;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    top: 24px;
    right: calc(1em + 4px);
    overflow: auto;
`

export const StyledError = styled.pre`
    white-space: pre-line;
    word-break: break-all;
`