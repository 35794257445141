import {ACTIONS} from 'application/constants'

export const requestCurrentAccountLogoByAccountId = (accountId) => ({
    type: ACTIONS.ACCOUNT_LOGO_REQUEST_CURRENT_ACCOUNT_LOGO_BY_ACCOUNT_ID,
    accountId
})

export  const populateCurrentAccountLogo = (
    blobUrl,
    accountId,
    accountName
) => ({
    type: ACTIONS.ACCOUNT_LOGO_POPULATE_CURRENT_ACCOUNT_LOGO,
    blobUrl,
    accountId,
    accountName
})

export const uploadAccountLogo = ( 
    file 
) => ({
    type: ACTIONS.ACCOUNT_LOGO_UPLOAD_CURRENT_ACCOUNT_LOGO,
    file
})

export const resetCurrentAccountLogo = () => ({
    type: ACTIONS.ACCOUNT_LOGO_RESET_CURRENT_ACCOUNT_LOGO
})