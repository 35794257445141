import styled from 'styled-components'

export const FormElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.cssWidth ? props.cssWidth : '100%'};
    font-size: ${props => props.theme.fontSize.standard};
    color: ${props => props.disabled ? props.theme.color.anthracite50 : props.theme.color.anthracite};
    margin:  ${props => props.cssMargin ? props.cssMargin : '0'};
    
    & label {
        margin-bottom: 4px;
        font-size: ${props => props.theme.fontSize.small};
    }
`