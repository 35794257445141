import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'



export const changelogReducer =  (
    state = defaultStore.pages.changelog, 
    action
) => {
    switch (action.type) {
    case ACTIONS.CHANGELOG_GET_ANNOUNCEMENTS: {
        return {
            ...state,
            announcements: {
                ...state.announcements,
                loaded: false,
                loading: true
            }
        }

    }
    case ACTIONS.CHANGELOG_POPULATE_ANNOUNCEMENTS:
        return {
            ...state,
            announcements: {
                ...state.announcements,
                data: action.announcementsData,
                loaded: true,
                loading: false
            }
        }
    default:
        return state
    }
}
