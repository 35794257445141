import React from 'react'
import PropTypes from 'prop-types'
import { DamageStatusName } from 'application/components/controls/damage_status_name'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RightArrow } from 'application/components/fragments/typography'

const StatusChangedInfo = (
    {activityData}
) => {

    return <FlexBox justifyContent='flex-start'>
        <DamageStatusName 
            status={activityData.oldState} 
        />
        <RightArrow />
        <DamageStatusName 
            status={activityData.newState} 
        />
    </FlexBox>
}

StatusChangedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {StatusChangedInfo} 