import { all, call, put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api, dataMappers } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'




export function* dialogCommissionOtherExpertSaga(action) {
    const { payload, metaData } = action
    const { damageId } = metaData
    const {
        email,   
        name, 
        inspectionSite, 
        inspectionDate,
        inspectionInfoName
    } = payload.commissionOtherExpert.expert 
    const files = payload.commissionOtherExpert.files

    //upload files
    yield put(showWaitingScreen('Uploading files'))

    const postAttachmentsServerCalls = files.map(
        file => function* () {        
            yield put(
                addNewSubMessage(file.metaData.name)
            )
            const response = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId
                },
                {
                    file: file.metaData
                }
            )
            return response
        }
    )
    const attachmentsResponses = yield all(
        postAttachmentsServerCalls.map(
            sc => call(sc)
        )
    )
    yield put( hideWaitingScreen() )


    //send expert commission
    yield put(showWaitingScreen('Assigning expert'))
    const commissionExpertPayload = dataMappers.exportMappers.expert.commissionOtherExpertDataMapper(
        email, 
        name, 
        inspectionSite, 
        inspectionDate,
        inspectionInfoName,
        attachmentsResponses
    )

    yield resourceCall(
        api.gateway.expert.updateCommissionExpert,
        {
            damageId 
        },
        commissionExpertPayload
    )
    
    yield put(hideWaitingScreen())
    yield put(showToast('Expert commissioned'))
}