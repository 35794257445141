import React, { useEffect } from 'react'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import { useGravatar } from 'application/components/hooks/use_gravatar'



const UserDetailsSidebarComponent = (
    {
        firstName = '',
        lastName = ''
    }
) => {

    const {
        getInitials,
        getFullName,
        setFirstName,
        setLastName
    } = useGravatar(
        firstName,
        lastName
    )

    useEffect(
        ()=>{
            setFirstName(firstName)
        },
        [setFirstName, firstName]
    )

    useEffect(
        ()=>{
            setLastName(lastName)
        },
        [setLastName, lastName]
    )

    return <GravatarComponent
        getInitials = {getInitials}
        getFullName = {getFullName}
        cssWidth = '100%'
        cssHeight = '400px'
    />

}



export {UserDetailsSidebarComponent}