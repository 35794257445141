import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getUserData = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(
                'user',
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `sharedResources/${params.sharedResourceId}/myendpoint`,
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        }
    }
)