import { useState, useEffect } from 'react'
import { geoCode } from 'application/common/address_helper'

export const useGeoCoding = (addressQuery = null, latlong = null) => {
    const [geoCodingLoading, setGeoCodingLoading] = useState(false)
    const [geoCodingSuccessful, setGeoCodingSuccessful] = useState(false)
    const [geoCodingFinished, setGeoCodingFinished] = useState(false)
    const [geoCodingError, setGeoCodingError] = useState(false)
    const [position, setPosition] = useState(latlong)

    useEffect(()=>{
        setGeoCodingLoading(false)
        setGeoCodingSuccessful(false)
        setGeoCodingFinished(false)
        setGeoCodingError(false)
        setPosition(latlong)
    },[latlong, addressQuery])

    useEffect(()=>{

        if(position !== null){
            setGeoCodingSuccessful(true)
            setGeoCodingFinished(true)
        }else if(addressQuery === null && position === null){
            setGeoCodingSuccessful(false)
            setGeoCodingFinished(true)
            setGeoCodingError(true)
        }else if(addressQuery !== null){
            setGeoCodingLoading(true)
            setGeoCodingSuccessful(false)
            setGeoCodingFinished(false)
            setGeoCodingError(false)

            geoCode(
                addressQuery, 
                ()=>{
                    setGeoCodingFinished(true)
                    setGeoCodingLoading(false)
                },
                ({lat, lon}) => {
                    setPosition([lat, lon])
                    setGeoCodingSuccessful(true)
                },
                ()=>{
                    setPosition(null)
                    setGeoCodingError(true)
                }
            )
        }

    }, [addressQuery, position])

    return {
        geoCodingLoading,
        geoCodingFinished,
        geoCodingSuccessful,
        geoCodingError,
        position
    }
}