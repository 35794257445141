import { CreateInvoiceDialog } from './component'
import { connect } from 'react-redux'


const mapStateToProps = (state) => ({
    defaultCategoryId: state.common.categories.length > 0 
        ? state.common.categories[0].id 
        : null
})


export const CreateInvoice = connect(
    mapStateToProps
)(CreateInvoiceDialog)
