
//image sources for static images
const ICONS_BASE_FOLDER = '/images/icons'

export const ICONS = {
    NOT_FOUND: {
        src: ICONS_BASE_FOLDER + '/not_found.png',
        alt: 'not found'
    },
    DIGI_DAMAGEAKTE: {
        src: ICONS_BASE_FOLDER + '/digitaledamageakte.svg',
        alt: 'digitaledamageakte'
    },
    DOKUMENTENCENTER: {
        src: ICONS_BASE_FOLDER + '/dokumentencenter.svg',
        alt: 'dokumentencenter'
    },
    SMARTEKUNDEN: {
        src: ICONS_BASE_FOLDER + '/smartekunden.svg',
        alt: 'smartekunden'
    },
    CONTROLLING: {
        src: ICONS_BASE_FOLDER + '/controlling.svg',
        alt: 'controlling'
    },
    DIGITALE_PROZESS_UNTERSTUETZUNG: {
        src: ICONS_BASE_FOLDER + '/digitaleprozessunterstuetzung.svg',
        alt: 'digitaleprozessunterstuetzung'
    },
    TANSPARENTE_ABWICKLUNG: {
        src: ICONS_BASE_FOLDER + '/transparenteabwicklung.svg',
        alt: 'transparenteabwicklung'
    },
    API: {
        src: ICONS_BASE_FOLDER + '/api.svg',
        alt: 'api'
    }, 
    CLOUD_LOESUNG: {
        src: ICONS_BASE_FOLDER + '/cloud_loesung.svg',
        alt: 'cloud_loesung'
    }, 
    DATENSCHUTZ: {
        src: ICONS_BASE_FOLDER + '/datenschutz.svg',
        alt: 'datenschutz'
    }, 
}