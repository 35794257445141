import React from 'react'
import { ROUTES } from 'application/constants'
import {DamageLicensePlate} from 'application/components/controls/damage_licenseplate'
import { DamageTypeName } from 'application/components/name_mappings/damage_type_name'
import { FullName } from 'application/components/controls/full_name'
import {CustomerNameDamage} from 'application/components/controls/customer_name_damage'
import { ClaimNumber } from 'application/components/controls/claim_number'
import { JobNumber } from 'application/components/controls/job_number'



export const configuration = {
    sorting: {},
    pagination: {
        hidePagination: true
    },
    columns: [
        {
            width: 15,
            label: 'License plate',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DamageLicensePlate damage={element}/>
        },
        {
            width: 15,
            label:  'Customer / owner',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <CustomerNameDamage damage={element} />
        },
        {
            width: 15,
            label: 'Damagetype', 
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => {
                return <DamageTypeName 
                    damageType={element.type} 
                    isSelected={isSelected}
                />
            }
        },
        {
            width: 15,
            label: 'Claim number',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => {
                const claimNumber = element?.comprehensiveData?.claim?.number
                return <ClaimNumber 
                    number={claimNumber} 
                    isSelected={isSelected}
                />

            }
        },
        {
            width: 15,
            label: 'Job number', 
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({
                element, 
                isSelected
            }) => <JobNumber 
                number={element.jobNumber} 
                isSelected={isSelected}
            />
        },
        {
            width: 15,
            label: 'Assignee',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FullName person={element.assignee} />
        }
    ],
    ContextMenu: () => null,
    contextMenuWidth: 0,
    rowHeight: '36px',
    rows: {
        linked: {
            Component: null,
            additionalProps: {
                href: (element, applicationLinksHook) => applicationLinksHook.internalAccountLink(
                    ROUTES.DAMAGE_MANAGER_BASE + element.id + '/overview'
                )
            }
        },
        selectable: null,
        details: null
    },
    massOperations: {
        available: false
    }
}
