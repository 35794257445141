import React from 'react'
import { Table } from 'application/components/tables/refactored/_common'
import { configuration } from './configuration'
import {  useTableLoader } from 'application/components/hooks'
import { withRouter } from 'application/components/higher_order_components'
import * as filters from 'application/filters'
import { isNull } from 'application/common/data_helpers'






const AssigneesTableComponent = (
    {
        usersListLoading,
        usersListLoaded,
        usersListTotal,
        usersList,
        onLoadUsersList,
        match,
        branchId,
        selectedElement,
        setSelectedElement
    }
) => {
    const filterKey = filters.users.getUsersForSpecificBranch.filterKey

    useTableLoader(
        onLoadUsersList, 
        match, 
        configuration,
        null,
        {
            [filterKey]: branchId
        }
    )

    return <Table 
        configuration={configuration}
        elementsLoading={usersListLoading}
        elementsLoaded={usersListLoaded}
        elementsTotal={usersListTotal}
        elements={usersList}
        emptyMessage='No assignees found'
        selectItem = {
            (element) => setSelectedElement(element)
        }
        deselectItem = {
            () => setSelectedElement(null)
        }
        selectedItemsFromParent = {
            isNull(selectedElement) 
                ? [] 
                : [selectedElement]
        }
    />
}

export const AssigneesTable = withRouter(AssigneesTableComponent)