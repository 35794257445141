import styled from 'styled-components'

const MessageSender = styled.p`
    margin: 0;
    color: ${(props) => props.theme.color.black};
    font-size: ${(props) => props.theme.fontSize.medium18};
    font-weight: ${(props) => props.theme.fontWeight.medium18};
`

const MessageReceiver = styled.p`
    display: inline;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: ${(props) => props.theme.fontWeight.medium};
`

const CaseWrapper = styled.div`
    color: ${(props) => props.theme.color.black};
    margin-bottom: 22px;
    background: #f5f5f5;
    padding: 6px 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const MessageTime = styled.p`
    margin: 5px 0;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: ${(props) => props.theme.fontWeight.small};
`

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`
const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
`

const HeaderRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-basis: 30%;
`

const MessageBody = styled.div`
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.medium18};
    font-weight: ${(props) => props.theme.fontWeight.medium18};
    white-space: pre-wrap;
`

const MessageAttachmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 8px;
    background: ${(props) => props.theme.color.gray5};
`

const MessageAttachment = styled.div`
    color: ${(props) => props.theme.color.gray20};
    font-size: ${(props) => props.theme.fontSize.medium18};
    font-weight: ${(props) => props.theme.fontWeight.medium18};
    border: 1px solid ${(props) => props.theme.color.gray20};
    margin-bottom: 8px;
    border-radius: 3px;
    background: ${(props) => props.theme.color.white};
    &:last-child{
        margin-bottom: 0;
    }
`

export {
    MessageSender,
    MessageReceiver,
    MessageTime,
    HeaderWrapper,
    HeaderLeft,
    HeaderRight,
    MessageBody,
    MessageAttachmentWrapper,
    MessageAttachment,
    CaseWrapper
}
