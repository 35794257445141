import { useEffect }  from 'react'
import { useDispatch } from 'react-redux'
import { documentsByCategoryRegisterResource } from 'application/redux/actions/documents_by_categories'
import { useSelector } from 'react-redux'
import { getDocsByCatsResourceId } from 'application/redux/selectors'


export const useDocsByCategoriesRegisterResource = (
    resourceId
) => {
    const dispatch = useDispatch()
    const lastResourceId = useSelector(getDocsByCatsResourceId)
    const resourceHasChanged = lastResourceId !== resourceId

    useEffect(
        () => {
            resourceHasChanged
            && dispatch(
                documentsByCategoryRegisterResource(
                    resourceId
                )
            ) 
        },
        [resourceHasChanged, dispatch, resourceId], 
    )
}