import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'

import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { payment } from 'application/api/data_mappers/export'
import { exists } from 'application/common/data_helpers'


export function* dialogCreatePaymentSaga(action) {
    const {damageId, invoiceId, categoryId} = action.metaData
    const {uploadedFiles} = action.payload

    yield put( showWaitingScreen('Creating payment') )

    const { id: paymentId } = yield resourceCall(
        api.gateway.invoices.postPayment, 
        {
            damageId, 
            invoiceId
        },
        payment.createDataMapper(action.payload)
    )
        
    if(uploadedFiles.length > 0){
        const paymentResponse = yield resourceCall(
            api.gateway.invoices.getPayment,
            {
                damageId,
                invoiceId,
                paymentId
            }
        )

        const taskId = paymentResponse.reductionTask?.id

        if(exists(taskId)){ //task should always exist for payments with reduction, just to be sure

            yield put( showWaitingScreen('Uploading files') )
            
            const payload = categoryId ? { damageId, taskId, categoryId } : { damageId, taskId }

            for(const file of uploadedFiles){
                yield put(addNewSubMessage(file.metaData.name + ' ... '))
                yield resourceCall(
                    api.gateway.attachments.postAttachmentForTask, 
                    payload,
                    {
                        file: file.metaData
                    }
                )
                yield put(addToLastSubMessage('✓'))
            }
        
        }

    }

    yield put(showToast('Payment is created'))
    yield put(hideWaitingScreen())
}