import React from 'react'
import { useTranslation } from 'react-i18next'
import { WidgetInformation } from 'application/components/widgets/_common'
import { TableRow, StyledTableCell } from './fragments'
import { LinkedTableRow, TableCell } from './'
import { MoSelectableTableRow } from './selectable_table_row/mo'
import { SelectableTableRowBase } from './selectable_table_row/base'
import { exists } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'





export const TableContent = (
    {   
        elementsLoading,
        elementsTotal,
        elementsLoaded,
        elements,
        configuration,
        emptyMessage,
        isSelectable = false,
        selectItem = () => {},
        deselectItem = () => {},
        selectedItemsFromParent = [],
        selectItemByMOName = () => {},
        deselectItemByMOName = () => {},
        moName = null,
        moIsActive = false,
        moSelectedItems = []
    }
) => {
    const {t} = useTranslation()
    const contextMenuWidth = configuration.contextMenuWidth || '0px'

    //backwards compatibility:::
    const flexibleCellContainerWidth = !isNaN(contextMenuWidth)
        ? `calc(100% - ${contextMenuWidth}px)`
        : `calc(100% - ${contextMenuWidth})`

    const applicationLinksHook = useApplicationLinks() 
    const selectedItems = moIsActive ? moSelectedItems : selectedItemsFromParent 
    const hasMassOperations =  configuration.massOperations.available

    const checkIfItemIsSelected = (itemId, selectedItems) => {
        const itemFound =  selectedItems.find( sitem => sitem.id === itemId)
        return itemFound !== undefined
    }

    const indexIsEven = (i) => {
        return i % 2 === 0
    } 


    const cssHeight = configuration.rowHeight || '44px'

    return <React.Fragment>
        {
            !elementsLoading 
            && elements.length > 0 
            && elements.map(
                (element, i) => {
                    const isSelected = moIsActive || isSelectable
                        ? checkIfItemIsSelected(element.id, selectedItems) 
                        : false

                    const rowIsColoured = indexIsEven(i)                   
                    const showMoRow =  hasMassOperations && moIsActive
                    const showLinkedRow = configuration.href(element, applicationLinksHook) !== null 

                    if (isSelectable) { 
                        return <SelectableTableRowBase
                            key={element.id}
                            element={element}
                            selectItem= {selectItem}
                            deselectItem = {deselectItem}
                            isSelected={isSelected}
                            isColoured={rowIsColoured}
                            cssHeight={cssHeight}
                        >      
                            <FlexBox
                                width='auto' 
                                flexBasis={flexibleCellContainerWidth} 
                                flexGrow='0' 
                                flexShrink='0'
                                className='selectable-table-row'
                            >                     
                                {
                                    configuration.columns.map(
                                        (column, columnIndex) => {
                                            return <TableCell 
                                                configuration={configuration} 
                                                column={column} 
                                                key={`${element.id}_${columnIndex}`}
                                            >
                                                <column.CellContent 
                                                    element={element} 
                                                    isSelected={isSelected} 
                                                />
                                            </TableCell>
                                        }
                                    )
                                }
                            </FlexBox>
                            {
                                exists(configuration.ContextMenu) && <StyledTableCell 
                                    flexGrow='0' 
                                    cssWidth={`${configuration.contextMenuWidth}`} 
                                    cssMaxWidth={`${configuration.contextMenuWidth}`}
                                >
                                    <configuration.ContextMenu 
                                        element={element} 
                                        moIsActive={moIsActive} 
                                    />
                                </StyledTableCell>
                            }
                        </SelectableTableRowBase>
                    }
                    
                    if (showMoRow) {
                        return <MoSelectableTableRow
                            key={element.id}
                            element={element}
                            selectItemByMOName = {selectItemByMOName}
                            deselectItemByMOName = {deselectItemByMOName}
                            isSelected={isSelected}
                            moName={moName}
                            isColoured={rowIsColoured}
                            cssHeight={cssHeight}
                        >      
                            <FlexBox 
                                width='auto' 
                                flexBasis={flexibleCellContainerWidth} 
                                flexGrow='0' 
                                flexShrink='0'
                                className='mo-selectable-table-row'
                            >                     
                                {
                                    configuration.columns.map(
                                        (column, columnIndex) => {
                                            return <TableCell 
                                                configuration={configuration} 
                                                column={column} 
                                                key={`${element.id}_${columnIndex}`}
                                            >
                                                <column.CellContent 
                                                    element={element} 
                                                    isSelected={isSelected} 
                                                    moIsActive={moIsActive} 
                                                />
                                            </TableCell>
                                        }
                                    )
                                }
                            </FlexBox>
                            {
                                exists(configuration.ContextMenu) && <StyledTableCell 
                                    flexGrow='0' 
                                    cssWidth={`${configuration.contextMenuWidth}`} 
                                    cssMaxWidth={`${configuration.contextMenuWidth}`}
                                >
                                    <configuration.ContextMenu 
                                        element={element} 
                                        moIsActive={moIsActive} 
                                    />
                                </StyledTableCell>
                            }
                        </MoSelectableTableRow>
                    }

                    if (showLinkedRow) {
                        return <LinkedTableRow 
                            key={element.id}
                            href={configuration.href(element, applicationLinksHook)}
                            isColoured={rowIsColoured}
                            cssHeight={cssHeight}
                        >
                            <FlexBox 
                                width='auto' 
                                flexBasis={flexibleCellContainerWidth} 
                                flexGrow='0' 
                                flexShrink='0' 
                                className='linked-table-row'
                            > 
                                {
                                    configuration.columns.map(
                                        (column, columnIndex) => {
                                            return <TableCell 
                                                key={`${element.id}_${columnIndex}`}
                                                configuration={configuration} 
                                                column={column} 
                                            >
                                                <column.CellContent 
                                                    element={element}
                                                    isSelected={isSelected}
                                                    moIsActive={moIsActive}
                                                />
                                            </TableCell>
                                        }
                                    )
                                }
                            </FlexBox>  
                            {
                                exists(configuration.ContextMenu) && <StyledTableCell 
                                    flexGrow='0'
                                    cssWidth={`${configuration.contextMenuWidth}`} 
                                    cssMaxWidth={`${configuration.contextMenuWidth}`}
                                >
                                    <configuration.ContextMenu 
                                        element={element} 
                                        moIsActive={moIsActive} 
                                    />
                                </StyledTableCell>
                            }
                        </LinkedTableRow>
                    }

                    return <TableRow 
                        key={element.id}
                        isColoured={rowIsColoured}
                        cssHeight={cssHeight}
                    >
                        <FlexBox 
                            width='auto' 
                            flexBasis={flexibleCellContainerWidth} 
                            flexGrow='0' 
                            flexShrink='0' 
                            className='table-row'
                        > 
                            {
                                configuration.columns.map(
                                    (column, columnIndex) => {
                                        return <TableCell 
                                            key={`${element.id}_${columnIndex}`}
                                            configuration={configuration} 
                                            column={column}
                                        >
                                            <column.CellContent
                                                element={element} 
                                                isSelected={isSelected}
                                                moIsActive={moIsActive} 
                                            />
                                        </TableCell>
                                    }
                                )
                            }
                        </FlexBox>
                        {
                            exists(configuration.ContextMenu) && <StyledTableCell 
                                flexGrow='0' 
                                cssWidth={`${configuration.contextMenuWidth}`} 
                                cssMaxWidth={`${configuration.contextMenuWidth}`}
                            >
                                <configuration.ContextMenu 
                                    element={element} 
                                    moIsActive={moIsActive} 
                                />
                            </StyledTableCell>
                        }
                    </TableRow>
                }
            )
        }
        {
            elementsLoaded 
            && elementsTotal === 0 
            && <WidgetInformation>
                {t(emptyMessage)}
            </WidgetInformation>
        }
    </React.Fragment>
}