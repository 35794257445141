import { connect } from 'react-redux'
import { DamageManagerMessageDetailsWidgetEffects } from './effects'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { WIDGET_CONTEXTS } from 'application/constants'
import { getMessageByIdRequest } from 'application/redux/actions/pages/inbox'

const mapStateToProps = state => ({
    detailsLoaded: state.pages.inbox.detailsLoaded,
    deletedMessages: state.pages.inbox.deletedMessages
})

const mapDispatchToProps = dispatch => {
    return {
        loadMessage: (damageId, messageId) => {
            dispatch(getMessageByIdRequest(damageId, messageId))
        },
        onMessageNotFound: () => {
            dispatch(refreshWidgetsByContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_INBOX))
        }
    }
}

export const DamageManagerMessageDetailsWidget =  connect(
    mapStateToProps, mapDispatchToProps
)(DamageManagerMessageDetailsWidgetEffects)