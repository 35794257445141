import { validatePinRequest } from 'application/redux/actions/shared_resource'
import { getCategoriesWorkflowPanelRequest } from 'application/redux/actions/pages/workflow_panel/shared'
import { connect } from 'react-redux'
import {LayoutBaseSharedResourceComponent} from './layout_base_workflow_panel'

const mapDispatchToProps = dispatch => {
    return {
        onValidatePin: (pin, sharedResourceId, storePin) => dispatch(validatePinRequest(pin, sharedResourceId, storePin)),
        onGetCategories: (sharedResourceId) => dispatch(getCategoriesWorkflowPanelRequest(sharedResourceId)),
    }
}

export const LayoutBaseSharedResource = connect(
    state => ({
        sharedResource: state.sharedResource,
        waitingScreen: state.waitingScreen,
        categoriesLoaded: state.pages.workflowPanel.shared.categoriesLoaded,
        categoriesLoading: state.pages.workflowPanel.shared.categoriesLoading,
        dialogsOpen: state.dialogs.dialogsOpen
    }),
    mapDispatchToProps
)(LayoutBaseSharedResourceComponent)