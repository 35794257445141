import React from 'react'
import { CurrencyDisplay  } from 'application/components/controls/currency_display'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'



const CreatedInfo = (
    {activityData}
) => {
    const {t}  = useTranslation()

    return <React.Fragment>
        {t('Amount')}: <CurrencyDisplay amount={activityData.amount} /><br />
        {t('Bill number')}: {activityData.invoice.number}
    </React.Fragment>
}

CreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CreatedInfo} 
