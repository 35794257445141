import { connect } from 'react-redux'
import {RegistrationCallbackComponent} from './component'
import { connectUser } from 'application/redux/actions/registration'

const mapDispatchToProps = dispatch => {
    return {
        onConnectUser: (sharedResourceId, ssoAccessToken, identityToken) => dispatch(connectUser(sharedResourceId, ssoAccessToken, identityToken))
    }
}

export const RegistrationCallback = connect(
    state => ({
        userConnected: state.registration.userConnected
    }),
    mapDispatchToProps
)(RegistrationCallbackComponent)