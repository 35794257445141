import React, { useState } from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { DialogStepsLayout } from '../../_common/steps_layout'
import { Step0 } from './step_0'
import { compose } from 'redux'
import { withCommissionOtherSteps } from './with_commission_other_steps'
import { Step1 } from './step_1'



const CommissionOtherDialog = (
    {
        onCommissionExpert,
        dialog,
        setAbortBlocked, 
        setDataChanged,
        // abort,

        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
    }
)=>{
    const {
        damage, 
        branch, 
        customer,
        victimInsuranceData,
        opponentInsuranceData
    } = dialog.payload

    const [expertCommissionData, setExpertCommissionData] = useState(null)
    const [files, setFiles] = useState([])

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const handleOnCommissionExpert = () => {
        const metaData = { 
            damageId: damage.id
        }

        const payload = {
            commissionOtherExpert: {
                expert: expertCommissionData,
                files
            },
        }

        onCommissionExpert(
            dialog.name, 
            payload,
            metaData
        )
    }


    return <DialogStepsLayout getStepsState={getStepsState}>
        {
            getCurrentStep().id === 0 && <Step0
                incidentDate={dialog.payload.damage.incidentDate}
                goToNextStep={goToNextStep}
                dialogName={dialog.name}
                onDataChange={onDataChange}
                expertCommissionData={expertCommissionData}                
                setExpertCommissionData={setExpertCommissionData}
                branchId={branch.id}
                branchName={branch.name}
                branchAddress={branch.address}
            />
        }
        {
            getCurrentStep().id === 1 && <Step1
                onDataChange={onDataChange}
                goToPreviousStep={goToPreviousStep}
                files={files}
                setFiles={setFiles}     
                expertCommissionData={expertCommissionData} 
                handleOnCommissionExpert={handleOnCommissionExpert}   
                victimInsuranceData={victimInsuranceData}
                opponentInsuranceData={opponentInsuranceData} 
                customer={customer}
            />
        }
    </DialogStepsLayout>
}

export const CommissionOtherDialogComponent = compose(
    withCommissionOtherSteps(),
    withDialogDataLoader
)(CommissionOtherDialog)