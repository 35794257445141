import { FilterFragment } from 'application/components/building_blocks/filters/_common'
import { DamageIdleTimeFilter } from 'application/components/building_blocks/filters/damage_idle_time_filter'
import { DamageStateFilterCombo } from 'application/components/building_blocks/filters/damage_state_filter_combo'
import { DamageTypeFilter } from 'application/components/building_blocks/filters/damage_type_filter'
import { TimeRangeFilter } from 'application/components/building_blocks/filters/time_range_filter'
import { MassOperationPanel } from 'application/components/building_blocks/mass_operations'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { useMO, useMoCloseWatcher } from 'application/components/hooks'
import { PushBottom32, SubPageLayout } from 'application/components/pages/_common'
import { TableMyDamagesOverview } from 'application/components/tables/refactored/damages/table_my_damages'
import { configuration } from 'application/components/tables/refactored/damages/table_my_damages/configuration'
import { ROUTE_PARAMETERS, USER_PERMISSONS } from 'application/constants'
import { MassOperationsDamagesSelect } from '../_common/mass_operations/mass_operation_select'





export const DamagesOverviewMyDamagesComponent = ({
    allDamages,
    onLoadDamagesList,
    updateConstraint
}) => {

    const { 
        damagesList, 
        damagesListLoading, 
        damagesListLoaded, 
        damagesListTotal 
    } = allDamages  

    const moName = configuration.massOperations.name
    const moType = configuration.massOperations.name

    useMoCloseWatcher(
        moName,
        damagesList
    )

    const {
        moAddSelectedItem,
        moRemoveSelectedItem,
        moIsActive,
        moSelectedItems
    } = useMO(moName)

    return <SubPageLayout>
        <FilterFragment.FilterContainer>
            <TimeRangeFilter 
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
                label='Damages created between'
                maxPeriod={null}
            />
            <DamageStateFilterCombo
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
                disabled = {damagesListLoading}
            />
            <DamageIdleTimeFilter 
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
            />
            <DamageTypeFilter
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL}
            />
        </FilterFragment.FilterContainer>

        <ForAnyBranchPermissions 
            permissions={
                [
                    USER_PERMISSONS.DAMAGE.UPDATE, 
                    USER_PERMISSONS.DAMAGE.DELETE
                ]
            } 
            allNeeded={false}
        >
            <MassOperationPanel
                moName={moName}
                moType={moType}
                label={'Edit multiple damages'}
                itemDesignation={'damage'}
                itemsDesignation={'damages'}
            >
                <MassOperationsDamagesSelect
                    moName={moName}
                />
            </MassOperationPanel>
        </ForAnyBranchPermissions>
        <PushBottom32 />
        <TableMyDamagesOverview
            damagesList={damagesList}
            damagesListLoaded={damagesListLoaded}
            damagesListLoading={damagesListLoading}
            damagesListTotal={damagesListTotal}
            onLoadDamages={onLoadDamagesList}
            updateConstraint={updateConstraint}
            //mo
            moAddSelectedItem={moAddSelectedItem}
            moRemoveSelectedItem={moRemoveSelectedItem}
            moIsActive={moIsActive}
            moSelectedItems={moSelectedItems}
        />
    </SubPageLayout>
}