import {
    StringEqualsFilter
} from '@ec/filter-sort-page-query-js'
import { FILTERS } from 'application/constants/filters'

const TASKFILTERS = FILTERS.VALUES.TASKS

export const tasks = {

    assigneeId: {
        filterName: 'tasks_assignee.userId',
        filterKey: 'atuid',
        label: 'Assignee',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('assignee.userId', value, true)
        ])
    },

    assigneeCurrentUserId: {
        filterName: 'tasks_assignee.currentUserId',
        filterKey: 'userId',
        label: 'Assignee',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringEqualsFilter('assignee.userId', value, true)
        ])
    },

    state: {
        filterName: 'tasks_state',
        filterKey: 'tas',
        label: 'State',
        defaultValue: TASKFILTERS.STATE.OPEN,
        getFilterQuery: value => (
            value === TASKFILTERS.STATE.ALL
                ? []
                : [
                    new StringEqualsFilter('state', value, true)
                ]
        )
    },

    external: {
        filterName: 'tasks_creator.isExternal',
        filterKey: 'tace',
        label: 'External task',
        defaultValue: null,
        getFilterQuery: value =>  (
            value === TASKFILTERS.IS_EXTERNAL.ALL
                ? []
                : [
                    new StringEqualsFilter('creator.isExternal', value, true)
                ]
        )
    },
}