import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import {ActivityDamageLink} from './_common/damage_activity_link'


export const DamageJobNumberChangedMetaContent = (
    {
        notification, 
        onLinkClick, 
        inline
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification
    const oldNumber = payload.oldNumber ? payload.oldNumber : t('Not set')
    const newNumber = payload.newNumber ? payload.newNumber : t('Not set')
        
    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription 
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription 
            label='Job number'
            value={`${oldNumber} → ${newNumber}`}
        />
        <ActivityDamageLink 
            onClick={onLinkClick} 
            damageId={payload.damage.id}
            inline={inline}
        />
    </Fragments.MetaContentContainer>
}