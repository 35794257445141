import React from 'react'

import { PhoneNumberInput } from 'application/modules/dynamic-form/controls/phone_number_input'
import { _get } from './util'
import { useTranslation } from 'react-i18next'

export const PhoneNumber = ({ description, formHook }) => {
    const {
        register,
        control,
        errors,
        updateFormState,
        setValue,
    } = formHook

    const { t } = useTranslation()

    return <div style={description._config?.css}>
        <PhoneNumberInput
            defaultValue={''}
            validate={description.validate}
            control={control}
            label={t(description.label)}
            name={description.name}
            register={register}
            error={_get(errors, description.name)}
            onChange={updateFormState}
            setValue={setValue}
            allowLandLine={description.elementOptions?.allowLandLine}
        />
    </div>
}
