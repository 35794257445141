import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { PushBottom16, PushBottom24 } from 'application/components/pages/_common'
import { Headline3 } from 'application/components/fragments/typography'
import styled from 'styled-components'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { EditableInvoiceLawfirm } from 'application/components/building_blocks/invoices'
import { IconButton } from 'application/components/controls/icon_button'
import { FlexBox } from 'application/components/fragments/flex_box'



export const LayoutNoInvoice = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: ${props => props.theme.color.gray5};
    padding: 16px;
`




export const LawfirmInvoicesComponent = withRouter(
    ({
        loadingFailed,
        match,
        payload,
        onCreateInvoice,
        isExternal
    }) => {
        const {t} = useTranslation()
        const { sharedResourceId } = match.params

        if(loadingFailed){
            return <WidgetErrorMessage>{t('Invoice data could not be loaded')}</WidgetErrorMessage>
        }
        
        const { incidentDate } = payload.damage
        const { invoices } = payload.data
        const createInvoice = () => onCreateInvoice(sharedResourceId, incidentDate)

        return <FlexBox flexDirection='column'>
            <PushBottom16 />
            {
                invoices.length > 0 ?
                    <Headline3>
                        {t('Invoices')}
                        <IconButton 
                            iconKey='add' 
                            width='12px' 
                            height='16px' 
                            onButtonClick={createInvoice} 
                        />
                    </Headline3> : <LayoutNoInvoice>
                        <FlexBox justifyContent='center'>
                            <IconButtonWithLabel 
                                onButtonClick={createInvoice} 
                                label={t('Add invoice')} 
                                iconKey='add' 
                            />
                        </FlexBox>
                    </LayoutNoInvoice>
            }
            <PushBottom24 />
            {
                invoices.length > 0 
                    ? invoices.sort(
                        (a, b) => a.createdAt < b.createdAt 
                            ? 1 
                            : -1
                    ).map(
                        (iv, i) => {
                            return <React.Fragment key={i}>
                                <EditableInvoiceLawfirm
                                    key={i}
                                    invoiceData={iv}
                                    parentId={sharedResourceId}
                                    isExternal={isExternal}
                                />
                                <PushBottom24 />
                            </React.Fragment>
                        }
                    ) 
                    : <Headline3>
                        {t('No invoices at the moment')}
                    </Headline3>
            }
        </FlexBox>
    }
)

LawfirmInvoicesComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}