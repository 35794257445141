import { FontIcon } from '@ec/ui-controls-react'
import React from 'react'
import * as Fragments from './fragments'
import {DateComponent} from 'application/components/controls/date'
import { MessageSubject } from 'application/components/controls/message_subject'
import { withTheme } from 'styled-components'



export const SingleMessage = withTheme(({ message, isSelected, theme }) => {
    const documentsCount = message.documents?.total || 0
    return <React.Fragment>
        <Fragments.MessageWrapper isSelected={isSelected} >
            <Fragments.MessageSubjectAndAttachmentWrapper attachment={documentsCount > 0} >
                <MessageSubject subject={message.subject} />
                {documentsCount > 0 && <Fragments.MessageAttachment>
                    {documentsCount} <FontIcon icon='attach_file' fontSize={theme.fontSize.medium} />
                </Fragments.MessageAttachment>}
            </Fragments.MessageSubjectAndAttachmentWrapper>
            <Fragments.MessageSenderAndTimeWrapper>
                <Fragments.MessageSender>
                    {message.sender.name}
                </Fragments.MessageSender>
                <Fragments.MessageTime>
                    <DateComponent date={message.createdAt} />
                </Fragments.MessageTime>
            </Fragments.MessageSenderAndTimeWrapper>
        </Fragments.MessageWrapper>
    </React.Fragment>
})