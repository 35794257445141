import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'


export const CommunicationAbortedInfo = (
    { activityData }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        {t('Damage')} : {activityData.damage.licensePlate}, &nbsp; {t('Lawyer')}: {activityData.name}
    </React.Fragment>
}

CommunicationAbortedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}
