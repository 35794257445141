import React from 'react'
import { Table } from 'application/components/tables/common/_common'
import { configuration } from './configuration'
import { useMoCloseWatcher, useTableLoader } from 'application/components/hooks'
import {withRouter} from 'application/components/higher_order_components/with_router'


const TableUsers = (
    {
        usersListLoading,
        usersListLoaded,
        usersListTotal,
        usersList,
        moName,
        match,
        onLoadUsersList,
        updateConstraint,
    }
) => {
    useTableLoader(
        onLoadUsersList, 
        match, 
        configuration, 
        updateConstraint
    )

    useMoCloseWatcher(
        moName, 
        usersList
    )

    return <Table 
        configuration={configuration}
        elementsLoading={usersListLoading}
        elementsLoaded={usersListLoaded}
        elementsTotal={usersListTotal}
        elements={usersList}
        emptyMessage='No users found'
        moName={moName}
    />
}

export const UsersTable = withRouter(TableUsers)