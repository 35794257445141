import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { account } from 'application/api/data_mappers/export'
import { syncCurrentAccount } from 'application/redux/actions/auth/current_account'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'



function* updateAccountSaga(action) {    
    try {
        const { 
            accountData
        } = action
        const payload = account.editAccountDataMapper(accountData)
        yield resourceCall(
            api.gateway.account.updateAccount, 
            null,
            payload
        )
        const addressPayload = account.editAccountAddressDataMapper(
            accountData.address
        )
        yield resourceCall(
            api.gateway.account.updateAccountAddress, 
            null,
            addressPayload
        )        
        const contactPayload = account.editAccountContactDataMapper(
            action.accountData?.contact
        )
        yield resourceCall(
            api.gateway.account.updateAccountContact, 
            null,
            contactPayload
        )
        yield put(
            syncCurrentAccount(accountData)
        )
        yield put(showToast('Account data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateAccountWatcher() {
    yield takeLeading(
        ACTIONS.COMMON_UPDATE_ACCOUNT, 
        updateAccountSaga
    )
}