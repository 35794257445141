import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput, PostalCodeCitySearchCombo } from 'application/components/controls/form'

export const AddressDataPartialForm = ({
    register,
    disabled = false,
    onChange = () => {},
    nameStreet = 'address.line1',
    namePostalCode = 'address.postalCode',
    nameCity = 'address.locality',
    setValue = () => {},
    errorNameStreet = null,
    errorNamePostalCode = null,
    errorNameCity = null,
    validate = null,
    validatePostalCode = null,
    watch = () => {},
    formStateValues={}
}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <TextInput 
            label={t('Street and house number')}
            name={nameStreet}
            register={register}
            validate={validate}
            error={errorNameStreet}
            disabled={disabled}
            onChange={onChange}
        />
        
        <PostalCodeCitySearchCombo 
            register={register}
            disabled={disabled}
            namePostalCode={namePostalCode}
            nameCity={nameCity}
            setValue={setValue}
            errorNamePostalCode={errorNamePostalCode}
            errorNameCity={errorNameCity}
            validate={validate}
            validatePostalCode={validatePostalCode}
            watch={watch}
            formStateValues={formStateValues}
        />
    </React.Fragment>
}