import React from 'react'
import styled from 'styled-components'

const StyledRequiredFieldMarker = styled.span`
    display: ${props => props.hasNotEmptyProperty ? 'inline-block' : 'none'};
    color: ${props => props.theme.color.red};
    font-weight: ${props => props.theme.fontWeight.standardBold};
    font-size: inherit;
`

export const RequiredFieldMarker = (
    {validate}
) => {
    const hasNotEmptyProperty = validate !== null 
    && typeof validate !== 'function' 
        ? validate.hasOwnProperty('notEmpty') 
        : false

    return <StyledRequiredFieldMarker 
        hasNotEmptyProperty={hasNotEmptyProperty}
    >
        *
    </StyledRequiredFieldMarker>
}