import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiFilestream } from 'application/api/helpers'


export const getAccountLogoByAccountId = (params) => ({
    authenticated: () => {
        const request = fetchRequestBuilder(
            `accounts/${params.accountId}/logo`, 
            HTTP_METHOD.GET
        )
        return apiFilestream(request)
    },
    sharedResource: () => {
        const request = fetchRequestBuilderPin(
            `accounts/${params.accountId}/logo`,
            params.pin, 
            HTTP_METHOD.GET
        )
        return apiFilestream(request)
    }
})