import { isDefined } from 'application/common/data_helpers'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledActivityLink } from '../link'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'




export const RelatedDamageLink = (
    {activity}
) => {
    const {t} = useTranslation()
    const {damageManagerLink} = useApplicationLinks()
    const relatedDamage = activity.relatedEntities.find(
        entity => entity.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    )

    if(!isDefined(relatedDamage)){
        return null
    }

    const damageUrl = damageManagerLink(
        ROUTES.DAMAGE_MANAGER, 
        relatedDamage.id
    )

    return <StyledActivityLink
        to={damageUrl}
    >
        &raquo; {t('View damage')}
    </StyledActivityLink>
}