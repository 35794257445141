import { connect } from 'react-redux'
import {PreferredAccountPreloaderComponent} from './component'
import {  getPreferredAccount } from 'application/redux/actions/auth'

const mapStateToProps = (state) => {
    return {
        preferredAccount: state.auth.preferredAccount,
        loggedIn: state.auth.loggedIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadPreferredAccount: () => dispatch(
            getPreferredAccount()
        ),
    }
}

export const PreferredAccountPreloader = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreferredAccountPreloaderComponent)