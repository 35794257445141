import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { ComprehensiveDamageTypeSelect } from 'application/components/controls/form/comprehensive_damage_type_select'
import { CoverageSelect } from 'application/components/controls/form/coverage_select'
import { FILTERS } from 'application/constants'
import { DeductibleInput } from 'application/components/controls/form/deductible_input'
import { TwoColumns } from 'application/components/forms/_common'
import { SubHeadline } from 'application/components/fragments/dialog'
import { PushBottom8 } from 'application/components/pages/_common'
import { InsuranceInputGroup } from 'application/components/building_blocks/insurance_input_group'
import {comprehensiveDataStructure} from 'application/components/forms/_configurations'


export const BasicDamageComprehensiveInsuranceDataForm = ({ 
    resetForm,
    stateFulFormHook,
    setInsuranceSelectionFromList,
    insuranceSelectionFromList
}) => {
    const {t} = useTranslation()
    
    const {
        coverageName,
        deductableName,
        insuranceSelectInputName,
        insuranceTextName,
        certificateNumberName,
        typeName,
        claimName
    } = comprehensiveDataStructure
    
    const {
        register, 
        setValue, 
        setFormStateValues,
        formStateValues, 
        updateFormState,
        // control,
        errors,
        reset
    } = stateFulFormHook
    
    const coverageValue = formStateValues[coverageName] || FILTERS.VALUES.DAMAGES.COVERAGE.NONE
    const isInsured = coverageValue !== FILTERS.VALUES.DAMAGES.COVERAGE.NONE

    return <React.Fragment>
        {      
            <TwoColumns
                columnLeft={
                    <CoverageSelect
                        name= {coverageName}
                        register={register}
                        setValue={setValue}
                        defaultValue={coverageValue}
                        onChange={(option) => {
                            updateFormState()
                            if(option.value === FILTERS.VALUES.DAMAGES.COVERAGE.NONE){
                                resetForm()
                            }
                        }}
                    />  
                }
            />
        }

        {
            isInsured && <React.Fragment>
                <DeductibleInput
                    label={'Deductible'}
                    name = {deductableName}
                    setValue = {setValue}
                    register={register} 
                    // control = {control}
                    onChange={updateFormState}
                    error={errors[deductableName]}
                    initialValue={formStateValues[deductableName]}

                />
                <InsuranceInputGroup 
                    register={register}
                    setValue={setValue}
                    formStateValues={formStateValues}
                    updateFormState={updateFormState}
                    errors={errors}
                    insuranceSelectInputName = {insuranceSelectInputName}
                    insuranceTextName = {insuranceTextName}
                    setInsuranceSelectionFromList = {setInsuranceSelectionFromList}
                    insuranceSelectionFromList={insuranceSelectionFromList}
                    setFormStateValues={setFormStateValues}
                    reset={reset}
                />
                <TwoColumns
                    columnLeft={ 
                        <TextInput 
                            label={t('Insurance certificate number')}
                            name={certificateNumberName}
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty} }
                            error={errors[certificateNumberName]}
                            onChange={updateFormState}
                        />  
                    }
                />
                <SubHeadline >{t('Damage')}</SubHeadline>
                <PushBottom8 />
                <TwoColumns
                    columnLeft={
                        <ComprehensiveDamageTypeSelect 
                            register={register}
                            setValue={setValue}
                            name = {typeName}
                            initialValue={formStateValues[typeName]}
                            onChange={updateFormState}
                            validate={null}
                            error={errors[typeName]}
                            menuPlacement='top'
                        />
                    }
                    columnRight={
                        <TextInput 
                            label={t('Claim number')}
                            name={claimName}
                            register={register}
                            validate={null}
                            error={errors[claimName]}
                            onChange={updateFormState}
                        />  
                    }
                />
            </React.Fragment>
        }
    </React.Fragment>
}