const getY = (layout) => {
    let y = 0
    for(const item of layout){
        y = item.y + item.h > y ? item.y + item.h : y
    }
    return y
}

export const gridItemBuilder = (layout, index, isStatic, width = 1, height = 1) => 
    layout[index] === undefined 
        ? {
            x: 0,
            y: getY(layout),
            w: width,
            h: height,
            static: isStatic            
        } 
        : layout[index]