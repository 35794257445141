import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import * as storage from 'application/storage'
import { setQuery } from 'application/components/hooks/use_query'
import { useNavigate } from 'react-router-dom'



const StyledOption = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 35px;
    cursor: pointer;

    background: ${props => props.isActive ? props.theme.color.primary : 'transparent'};
    color: ${props => props.isActive ? props.theme.color.white : props.theme.color.anthracite};
`


const Option = (
    {
        tableName,
        value,
        offsetKey,
        limitKey,
        isActive = false,
        onOptionClick = () => {}
    }
) => {
    const location = useLocation()
    const navigate = useNavigate()

    const handleClick = () => {
        storage.tableConfig.storeItemsPerPage(tableName, value)
        const offsetValue = 0
        const limitValue = value
        setQuery(
            navigate, 
            location, 
            location.pathname,
            [
                {
                    key: offsetKey,
                    value: offsetValue
                },
                {
                    key: limitKey,
                    value: limitValue
                }
            ],
            true
        )
        onOptionClick(offsetValue,limitValue)
    }

    return <StyledOption
        isActive={isActive}
        onClick={handleClick}>
        {value}
    </StyledOption>
}

Option.propTypes = {
    tableName: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    offsetKey: PropTypes.string.isRequired,
    limitKey: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onOptionClick: PropTypes.func
}

export {Option}