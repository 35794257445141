//reducer for the damage manager title bar
import { combineReducers } from 'redux'

import {documentCenterReducer} from './document_center'
import {tasksReducer} from './tasks'
import {overviewReducer} from './overview'
import {inboxReducer} from './inbox'
import {sharedReducer} from './shared'

export const damageManagerReducer =  combineReducers({
    documentCenter: documentCenterReducer,
    tasks: tasksReducer,
    overview: overviewReducer,
    inbox: inboxReducer,
    shared: sharedReducer
})