import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall, buildListQuery } from 'application/api/helpers'



export const getAnnouncements = (params) => (
    {
        authenticated: () => {
            const {
                offset, 
                limit, 
                orderBy, 
                direction, 
                filterQuery
            } = params

            const queryString = buildListQuery(
                offset, 
                limit, 
                orderBy, 
                direction, 
                filterQuery
            )

            const request = fetchRequestBuilder(
                `announcements${queryString}`, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource:  () => {},
    }
)