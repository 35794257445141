import { useZipCodeSearch } from 'application/components/hooks/use_zip_code_search'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'

export const PostalCodeCitySearchCombo = ({
    register,
    disabled = false,
    namePostalCode = 'address.postalCode',
    nameCity = 'address.locality',
    setValue = () => {},
    errorNamePostalCode = null,
    errorNameCity = null,
    validate = null,
    validatePostalCode = null,
    onChange = () => {}
}) => {
    const {t} = useTranslation()
    const {
        searchCityResult,
        setZipCodeSearchTerm
    } = useZipCodeSearch()

    useEffect(()=>{
        if(searchCityResult !== ''){
            setValue(nameCity, searchCityResult)
        }
    }, [nameCity, searchCityResult, setValue])

    return <FlexBox>
        <FlexBox flexBasis='120px'>
            <TextInput 
                label={t('Postal code')}
                name={namePostalCode}
                register={register}
                validate={validatePostalCode}
                error={errorNamePostalCode}
                disabled={disabled}
                onChange={(e) => {
                    onChange(e)
                    setZipCodeSearchTerm(e.target.value)
                }}
            />
        </FlexBox>
        <FlexBox flexBasis='12px' flexGrow='0' flexShrink='0' />
        <FlexBox >
            <TextInput 
                label={t('City')}
                name={nameCity}
                register={register}
                validate={validate}
                error={errorNameCity}
                disabled={disabled}
                onChange={onChange}
            />
        </FlexBox>
    </FlexBox>

}