import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TabsNavigation } from 'application/components/building_blocks/tabs'
import { BulletList, BulletListItem, BulletListLabel, WidgetErrorMessage, WidgetSubHeadline, WidgetTabsNavigation } from 'application/components/widgets/_common'


export const DamageInsurancesComponent = ({widgetKey, payload, loadingFailed}) => {
    const {t} = useTranslation()
    const [opponentTabVisible, setOpponentTabVisible] = useState(false)

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    const { victimInsurance, opponentInsurance} = payload

    return <React.Fragment>
        <WidgetTabsNavigation>
            <TabsNavigation
                smallFont={true}
                items={[
                    {
                        label: t('Car insurance'),
                        onItemClick: () => { setOpponentTabVisible(false) }
                    },
                    {
                        label: t('Opponent car insurance'),
                        onItemClick: () => { setOpponentTabVisible(true) }
                    }
                ]}
                initialActiveTabIndex={0}
            />
        </WidgetTabsNavigation>

        {
            !opponentTabVisible && <React.Fragment>
                {
                    victimInsurance?.company?.name && <WidgetSubHeadline>{victimInsurance.company.name}</WidgetSubHeadline>
                }
                <BulletList>
                    { victimInsurance?.certificateNumber && <BulletListItem><BulletListLabel cssWidth='80px'>{t('Insurance certificate number')}: </BulletListLabel> {victimInsurance.certificateNumber}</BulletListItem>} 
                </BulletList>
                <BulletList>
                    { victimInsurance?.coverage && <BulletListItem><BulletListLabel cssWidth='80px'>{t('Coverage')}: </BulletListLabel> {t(victimInsurance.coverage)}</BulletListItem>} 
                </BulletList>
            </React.Fragment>
        }

        {
            opponentTabVisible && <React.Fragment>
                {
                    opponentInsurance?.company?.name && <WidgetSubHeadline>{opponentInsurance.company.name}</WidgetSubHeadline>
                }
                <BulletList>
                    { opponentInsurance?.certificateNumber && <BulletListItem><BulletListLabel cssWidth='80px'>{t('Insurance certificate number')}: </BulletListLabel> {opponentInsurance.certificateNumber}</BulletListItem>} 
                </BulletList>
            </React.Fragment>
        }
        
    </React.Fragment>
}

DamageInsurancesComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}