import { WIDGET_TYPES, ROUTE_PARAMETERS } from 'application/constants'

const KEYS = {
    BRANCH_SPECIFIC_INBOX_MESSAGE_DETAILS: 'BRANCH_SPECIFIC_INBOX_MESSAGE_DETAILS',
    BRANCH_SPECIFIC_INBOX_MESSAGE_LIST: 'BRANCH_SPECIFIC_INBOX_MESSAGE_LIST',
}

export const widgetsBranchSpecificInbox = [
    {
        key: KEYS.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST,
        title: 'Message List',
        hideHeadline: true,
        dimension: '30%',
        routeParameters: ROUTE_PARAMETERS.INBOX
    },
    {
        key: KEYS.BRANCH_SPECIFIC_INBOX_MESSAGE_DETAILS,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_DETAILS,
        title: 'Message Details',
        hideHeadline: true,
        dimension: '70%'
    } 
]