import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

const mapStateToProps = state => ({
    damage: state.pages.damageManager.shared.damage,
    damageLoaded: state.pages.damageManager.shared.damageLoaded,
})

const withSharedDamageComponent = Component => ({ ...props }) => {
    return <Component {...props} />
}

export const withSharedDamage = compose(
    connect(mapStateToProps),
    withSharedDamageComponent
)