import React, { useState } from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DescriptionField } from '../../common/setting_description'
import { useTranslation } from 'react-i18next'
import { PushBottom16 } from 'application/components/pages/_common'
import { PureSelect } from 'application/components/controls/form'
import { getFullName } from 'application/common/name_helpers'
import { useKickstartBranchAssignees } from 'application/components/pages/internal/settings/ui_settings/setting_editor_components/edit_kickstart_branch_assignees/use_kickstart_branch_assignees'

export const EditKickstartBranchAssigneesComponent = ({
    definition,
    updateSettings,
    getSetting,
    branches,
    onSearchUser,
    usersList,
    usersListLoading
}) => {
    const {t} = useTranslation()
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls    

    const options = usersList?.users?.map(user => ({
        value: user.id,
        label: getFullName(user),
        user
    })) ?? []

    const {
        mappedBranches,
        onUpdateBranchAssignee
    } = useKickstartBranchAssignees(definition, getSetting, branches, updateSettings)

    const handleChange = (val, branch) => {
        onUpdateBranchAssignee(branch.id, val.user)
    }

    const handleSearchUser = searchTerm => {
        if (userSearchActive && searchTerm.length > 2) {
            onSearchUser(searchTerm)
        }
    }
    return <FlexBox flexDirection='column'>

        <DescriptionField
            description={definition.description}
        />

        <PushBottom16 />

        <FlexBox cssRules='gap: 16px;' justifyContent='flex-end' flexDirection='column'>
            {
                mappedBranches.map((mappedBranch, i) => {
                    return <React.Fragment key={i}>
                        <label>{mappedBranch.name}</label>
                        <PureSelect
                            defaultValue={mappedBranch.assignee ? {
                                label: getFullName(mappedBranch.assignee),
                                value: mappedBranch.assignee.id,
                                user: mappedBranch.assignee
                            }: null}
                            options={options}
                            onChange={(val) => {
                                handleChange(val, mappedBranch)
                            }}
                            onBlur={() => { setUserSearchActive(false) }}
                            onFocus={() => { setUserSearchActive(true) }}
                            isLoading={usersListLoading}
                            noOptionsMessage={() => t('Start typing to search')}
                            placeholder={t('Search employee')}
                            onInputChange={handleSearchUser}
                            isClearable={false}
                        />
                    </React.Fragment>
                })
            }
          
        </FlexBox>

    </FlexBox>
}