import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { populateVehicle } from 'application/redux/actions/pages/damage_manager/shared'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

function* updateVehicleSaga(action) {

    const payload = removePropertiesWithEmptyStrings(action.vehicleData)

    try {
        yield resourceCall(
            api.gateway.damage.updateVehicle, 
            {
                damageId: action.damageId
            }, 
            payload
        )        
        yield put(
            populateVehicle(action.vehicleData)
        )
        yield put(
            showToast('Vehicle data saved')
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateVehicleWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_SHARED_UPDATE_VEHICLE, 
        updateVehicleSaga
    )
}