import { exists } from '../data_helpers'



/**
 * maps statusIconName to google icon name
 * @param {string} statusIconName 
 * @returns {string} googleIconName
 */
export const iconMapper = (statusIconName) => {

    //Legacy: if icon name starts with upper case, use this mapping
    if(statusIconName.charAt(0) === statusIconName.charAt(0).toUpperCase()){
        switch (statusIconName) {
        case 'In Planung':
        case 'Warten auf Gutachten':
            return 'assignment' 
        case 'In Datenaufnahme':
            return 'edit'
        case 'In Reparatur':
            return 'build'
        case 'In Rechnungsstellung':
            return 'euro_symbol'    
        case 'Fertig zur Übergabe':
            return 'vpn_key'
        case 'Abgeschlossen': 
            return 'check'
        default:
            return 'star'
        }
    }

    if(exists(statusIconName)){
        return statusIconName
    }

    return 'star'
}