import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { DIALOG } from 'application/constants'


export const KickstartStep3Component = (
    { 
        //from component
        abort, 
        dialogName,
        goToPreviousStep,
        damageFiles,
        setDamageFiles,
        customerData,
        //from container
        onSendCommission,
        currentBranch,
        tempDamageData,
        tempCommissionData,
        commissionOtherFiles,
        lawfirmFiles
    }
) => {
    const { t } = useTranslation()

    const onSubmit = () => {
        const payloadToSend = {
            damageData: {
                customer: customerData.values,
                damage: tempDamageData,
                files: damageFiles
            },
            commissionOtherExpert: {
                expert: tempCommissionData.expert.selectedExpert,
                commissionExpert: tempCommissionData.expert.commissionExpert,
                commissionDekra: tempCommissionData.expert.commissionDekra,
                files: commissionOtherFiles
            },
            lawfirm: {
                ...tempCommissionData.lawfirm,
                files: lawfirmFiles
            }
        }

        const dName = tempCommissionData?.expert?.commissionDekra ? DIALOG.NAME.CREATE_DAMAGE_KICKSTART_AND_COMMISSION_DEKRA : dialogName

        onSendCommission(
            dName,
            payloadToSend
        )
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>   
                <SubHeadline>{t('Documents')}</SubHeadline>
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={damageFiles}
                    setFiles={setDamageFiles}
                    title={t('Damage related documents')}
                />             
            </FlexBox>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <IconButtonWithLabel
                iconKey='arrow_back' 
                label={t('Back')} 
                onButtonClick={goToPreviousStep} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Hand over and finish')} 
                onButtonClick={onSubmit} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}