import {
    CombinedQuery,
    PagingQuery,
    FilterQuery,
    SortQuery, SortQueryParam
} from '@ec/filter-sort-page-query-js'

export const buildListQuery = (
    offset,
    limit,
    orderBy = null,
    direction = null,
    filterQuery = []
) => {

    if(orderBy === null || direction === null) {
        return new CombinedQuery(
            [
                new PagingQuery(offset, limit),
                new FilterQuery(filterQuery)
            ]
        ).queryString
    }

    return new CombinedQuery(
        [
            new PagingQuery(offset, limit),
            new FilterQuery(filterQuery),
            new SortQuery([
                new SortQueryParam(orderBy, direction)
            ])
        ]
    ).queryString
}