import { currencyToDecimalString } from 'application/common/currency_helpers'


export const createDataMapper = (payload) => {
    
    const {
        amount,
        description,
        payedAt,
        reduction,
        reductionReasons
    } = payload

    const payloadWithoutReduction = {
        amount: currencyToDecimalString(amount),
        description: description === '' ? null : description,
        payedAt,
    }
    
    return reduction 
        ? {
            ...payloadWithoutReduction,
            reductionReasons
        }
        : payloadWithoutReduction

}