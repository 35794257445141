import { connect } from 'react-redux'
import {DamageManagerOverviewSidebarEffects} from './effects'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetContext: (context) => {
            dispatch(refreshWidgetsByContext(context))
        }
    }
}

export const DamageManagerOverviewSidebar = connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerOverviewSidebarEffects)