import React from 'react'
import { EditTaskComponent } from './component'

export const EditTaskDataMapper = (props) => {
    const { dialog, onSaveTask } = props
    const { task } = dialog.payload

    //import
    const formData = {
        ...task
    }

    //export
    const handleOnSave = (
        dialogName, 
        damageId, 
        task, 
        values
    ) => {
        onSaveTask(
            dialogName, 
            damageId, 
            task.id, 
            {
                ...task,
                ...values
            }, 
            task
        )
    }
    return <EditTaskComponent 
        {...props} 
        formData={formData} 
        onSaveTask={handleOnSave} 
    />
}